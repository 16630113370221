import React from 'react';
import { useIntl } from 'react-intl';

import '../ConfigsModal.scss';

interface PasswordSWIFTModalViewProps {
  onAccept: (event: React.MouseEvent) => void;
  onCancel: (event: React.MouseEvent) => void;
  onReceivePasswordChange: (event: { target: HTMLInputElement }) => void;
  onSendPasswordChange: (event: { target: HTMLInputElement }) => void;
  onConfirmSendPasswordChange: (event: { target: HTMLInputElement }) => void;
  onConfirmReceivePasswordChange: (event: { target: HTMLInputElement }) => void;
  lengthSendPasswordError: boolean;
  lengthReceivePasswordError: boolean;
  confirmSendError: boolean;
  confirmReceiveError: boolean;
}

export function PasswordSWIFTModalView({
  onAccept,
  onCancel,
  onConfirmReceivePasswordChange,
  onConfirmSendPasswordChange,
  onReceivePasswordChange,
  onSendPasswordChange,
  lengthReceivePasswordError,
  lengthSendPasswordError,
  confirmReceiveError,
  confirmSendError,
}: PasswordSWIFTModalViewProps): JSX.Element | null {
  const intl = useIntl();

  return (
    <div
      className="font-mono modal z-10 flex justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ height: '100%' }}
    >
      <div className="flex justify-center">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border shadow-sm"
          style={{ width: '600px', height: 'fit-content' }}
        >
          <div className="bg-blue-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="modal-info sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg text-center leading-6 font-medium text-gray-900" id="modal-title">
                  {intl.formatMessage({ id: 'sendReceivePassCAS' })}.
                </h3>
                <fieldset className="fieldset-modal">
                  <legend>{intl.formatMessage({ id: 'send' })}</legend>
                  <div className="m-2 flex items-center mb-3 w-full">
                    <label className="label-modal">{intl.formatMessage({ id: 'sendPassword' })}</label>
                    <input onChange={onSendPasswordChange} className="input-modal border border-grey-lighter" type="text" />
                  </div>
                  {lengthSendPasswordError && (
                    <div className="flex items-center w-full">
                      <label className="error-message-modal pr-1 pt-2 text-red flex text-sm">{intl.formatMessage({ id: 'passUpTo32Char' })}</label>
                    </div>
                  )}
                  <div className="m-2 flex items-center mb-3 w-full">
                    <label className="label-modal">{intl.formatMessage({ id: 'confirmPassword' })}</label>
                    <input
                      onChange={onConfirmSendPasswordChange}
                      className="input-modal border border-grey-lighter"
                      type="password"
                      autoComplete="new-password"
                    />
                  </div>
                  {confirmSendError && (
                    <div className="flex items-center w-full">
                      <label className="error-message-modal pr-1 pt-2 text-red flex text-sm">
                        {intl.formatMessage({ id: 'confirmPasswordError' })}
                      </label>
                    </div>
                  )}
                </fieldset>
                <fieldset className="fieldset-modal">
                  <legend>{intl.formatMessage({ id: 'reception' })}</legend>
                  <div className="m-2 flex items-center mb-3 w-full">
                    <label className="label-modal">{intl.formatMessage({ id: 'receptionPassword' })}</label>
                    <input onChange={onReceivePasswordChange} className="input-modal border border-grey-lighter" type="text" />
                  </div>
                  {lengthReceivePasswordError && (
                    <div className="flex items-center w-full">
                      <label className="error-message-modal pr-1 pt-2 text-red flex text-sm">{intl.formatMessage({ id: 'passUpTo32Char' })}</label>
                    </div>
                  )}
                  <div className="m-2 flex items-center mb-3 w-full">
                    <label className="label-modal">{intl.formatMessage({ id: 'confirmPassword' })}</label>
                    <input
                      onChange={onConfirmReceivePasswordChange}
                      className="input-modal border border-grey-lighter"
                      type="password"
                      autoComplete="new-password"
                    />
                  </div>
                  {confirmReceiveError && (
                    <div className="flex items-center w-full">
                      <label className="error-message-modal pr-1 pt-2 text-red flex text-sm">
                        {intl.formatMessage({ id: 'confirmPasswordError' })}
                      </label>
                    </div>
                  )}
                </fieldset>
              </div>
            </div>
          </div>
          <div className="justify-center bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onAccept}
              type="button"
              className="w-24 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium text-gray-700 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:text-sm"
            >
              {intl.formatMessage({ id: 'accept' })}
            </button>
            <button
              onClick={onCancel}
              type="button"
              className="mt-3 w-24 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm"
            >
              {intl.formatMessage({ id: 'cancel' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
