import React, { ReactChild, useState } from 'react';
import { useIntl } from 'react-intl';
import { USER_NAME } from '../../../../../utils/browserStorage/constants';
import { SelectData } from '../../../../ProcessesManager/components/select/Select';
import {
  ADD_GROUP_MODAL,
  EQUAL_SEARCH,
  EQUAL_SEARCH_SYMBOL,
  GROUP_DESCRIPTION,
  LIKE_SEARCH,
  TYPE_GROUP,
  TYPE_USER,
  USER_INITIALS,
  USER_MODAL,
  USER_USERNAME,
} from '../../../models/constants';
import { GroupsAsTreeMenu } from '../../../models/groupsAsTreeMenu';
import { SelectedGroup } from '../../../models/types';
import { UserBasicInfo } from '../../../models/userBasicInfo';
import { SearchModalView } from './SearchModalView';

export interface SearchModalViewModelProps {
  securityGroups: GroupsAsTreeMenu[];
  onModalChange: (modalOpen: string) => void;
  onSelectGroup: (group: SelectedGroup) => void;
  onIsEditingChange: (isEditing: boolean) => void;
  onSelectedItemChange: (node: SelectedGroup) => void;
}

export function SearchModalViewModel({ securityGroups, onModalChange, onSelectGroup, onIsEditingChange, onSelectedItemChange }: SearchModalViewModelProps): JSX.Element | null {
  const [searchResult, setSearchResult] = useState<SelectedGroup[]>([]);
  const [typeOfSearch, setTypeOfSearch] = useState<string>('');
  const [selectedCoincide, setSelectedCoincide] = useState<string>(loadDefaultCoincideOption());
  const [selectedLike, setSelectedLike] = useState<string>(EQUAL_SEARCH);
  const [searchInput, setSearchInput] = useState<string>('');
  const intl = useIntl();
  const selectCoincideOptions: SelectData[] =
    typeOfSearch === TYPE_GROUP
      ? [
          {
            label: intl.formatMessage({ id: 'group' }),
            value: TYPE_GROUP,
          },
          {
            label: intl.formatMessage({ id: 'description' }),
            value: GROUP_DESCRIPTION,
          },
        ]
      : typeOfSearch === TYPE_USER
      ? [
          {
            label: intl.formatMessage({ id: 'name' }),
            value: USER_NAME,
          },
          {
            label: intl.formatMessage({ id: 'user' }),
            value: USER_USERNAME,
          },
          {
            label: intl.formatMessage({ id: 'userInitials' }),
            value: USER_INITIALS,
          },
        ]
      : [];
  const selectLikeOptions: SelectData[] = [
    {
      label: EQUAL_SEARCH_SYMBOL,
      value: EQUAL_SEARCH,
    },
    {
      label: intl.formatMessage({ id: 'like' }),
      value: LIKE_SEARCH,
    },
  ];

  function loadDefaultCoincideOption(): string {
    return typeOfSearch === TYPE_GROUP ? TYPE_GROUP : USER_NAME;
  }

  function search(): void {
    let result: SelectedGroup[] = [];
    mapSubGroups(securityGroups, result);

    setSearchResult(result);
  }

  function mapSubGroups(groups: SelectedGroup[], result: SelectedGroup[]): void {
    groups.forEach((subGroup) => {
      if (subGroup.type === typeOfSearch) {
        checkInformation(subGroup, result);
      }
      if (subGroup.type === TYPE_GROUP) {
        checkChildren(subGroup as GroupsAsTreeMenu, result);
      }
    });
  }

  function checkChildren(group: GroupsAsTreeMenu, result: SelectedGroup[]): void {
    if (group.subGroups.length !== 0) {
      mapSubGroups(group.subGroups, result);
    }
    if (group.users.length !== 0) {
      mapSubGroups(group.users, result);
    }
  }

  function checkInformation(group: SelectedGroup, result: SelectedGroup[]): void {
    if (selectedLike === EQUAL_SEARCH) {
      checkEqualInformation(group, result);
    } else if (selectedLike === LIKE_SEARCH) {
      checkLikeInformation(group, result);
    }
  }

  function checkEqualInformation(subGroup: SelectedGroup, result: SelectedGroup[]): void {
    const isInformationEqual: boolean =
      (isTypeGroupSelected() && isInputEqual(subGroup.group)) ||
      ((subGroup as GroupsAsTreeMenu) && isTypeDescriptionSelected() && isInputEqual((subGroup as GroupsAsTreeMenu).description)) ||
      (isTypeNameSelected() && isInputEqual(subGroup.name)) ||
      ((subGroup as UserBasicInfo) && isTypeInitialsSelected() && isInputEqual((subGroup as UserBasicInfo).initials)) ||
      ((subGroup as UserBasicInfo) && isTypeUsernameSelected() && isInputEqual((subGroup as UserBasicInfo).username));
    if (isInformationEqual) {
      result.push(subGroup);
    }
  }

  function isInputEqual(info: string): boolean {
    return info === searchInput;
  }

  function isTypeGroupSelected(): boolean {
    return selectedCoincide === TYPE_GROUP;
  }

  function isTypeDescriptionSelected(): Boolean {
    return selectedCoincide === GROUP_DESCRIPTION;
  }

  function isTypeNameSelected(): boolean {
    return selectedCoincide === USER_NAME;
  }

  function isTypeInitialsSelected(): boolean {
    return selectedCoincide === USER_INITIALS;
  }

  function isTypeUsernameSelected(): boolean {
    return selectedCoincide === USER_USERNAME;
  }

  function checkLikeInformation(subGroup: SelectedGroup, result: SelectedGroup[]): void {
    const isInformationLike: boolean =
      (isTypeGroupSelected() && isInputSimilar(subGroup.group)) ||
      ((subGroup as GroupsAsTreeMenu) && isTypeDescriptionSelected() && isInputSimilar((subGroup as GroupsAsTreeMenu).description)) ||
      (isTypeNameSelected() && isInputSimilar(subGroup.name)) ||
      ((subGroup as UserBasicInfo) && isTypeInitialsSelected() && isInputSimilar((subGroup as UserBasicInfo).initials)) ||
      ((subGroup as UserBasicInfo) && isTypeUsernameSelected() && isInputSimilar((subGroup as UserBasicInfo).username));
    if (isInformationLike) {
      result.push(subGroup);
    }
  }

  function isInputSimilar(info: string): boolean {
    return info.toLowerCase().includes(searchInput.toLowerCase());
  }

  function onTypeChange(type: string): void {
    setTypeOfSearch(type);
  }

  function onSearch(): void {
    search();
  }

  function onSelectCoincideChange(coincideSelection: string): void {
    setSelectedCoincide(coincideSelection);
  }

  function onSelectLikeChange(likeSelection: string): void {
    setSelectedLike(likeSelection);
  }

  function onSearchInputChange(newInput: string): void {
    setSearchInput(newInput);
  }

  function onItemClick(result: SelectedGroup): (event: React.MouseEvent) => void {
    return (event) => {
      event.stopPropagation();
      onSelectGroup(result);
      onSelectedItemChange(result);
      onIsEditingChange(true);
      if (result.type === TYPE_GROUP) {
        onModalChange(ADD_GROUP_MODAL);
      } else if (result.type === TYPE_USER) {
        onModalChange(USER_MODAL);
      }
    }
  }

  return (
    <SearchModalView
      onItemClick={onItemClick}
      onTypeChange={onTypeChange}
      onSearch={onSearch}
      searchResult={searchResult}
      selectedCoincide={selectedCoincide}
      onSelectCoincideChange={onSelectCoincideChange}
      selectCoincideOptions={selectCoincideOptions}
      selectedLike={selectedLike}
      onSelectLikeChange={onSelectLikeChange}
      selectLikeOptions={selectLikeOptions}
      onSearchInputChange={onSearchInputChange}
      onModalChange={onModalChange}
    />
  );
}
