/* eslint-disable no-unused-vars */
import React from 'react';
import { useIntl } from 'react-intl';
// import 'bootstrap/dist/css/bootstrap.min.css';

export const SchemaFilter = (props) => {
  const intl = useIntl();

  return (
    <div>
      <div className={'container w-full pl-4 pr-4 border border-solid bg-grey-lighter-lighter rounded relative p-4 '}>
        <div className="rounded pl-4 pr-5 pt-2 pb-2" style={{ border: '1px solid #a6a6a6' }}>
          <div className="row">
            <div className="col-8">
              <div className="row">
                <div className="col-4">
                  <label>
                    {intl.formatMessage({
                      id: 'Rubro',
                      defaultMessage: 'Rubro',
                    })}
                  </label>
                </div>
                <div className="col-8">
                  <input className={'clear-both border-solid ml-2'} style={{ border: '1px solid grey' }}></input>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <div className="row">
                <div className="col-4">
                  <label>
                    {intl.formatMessage({
                      id: 'rubroSalida',
                      defaultMessage: 'Rubro de salida',
                    })}
                  </label>
                </div>
                <div className="col-8">
                  <input className={'clear-both border-solid ml-2'} style={{ border: '1px solid grey' }}></input>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-8"></div>
                <div className="col-4">
                  <button type="button" className="btn btn-outline-info btn-sm">
                    {intl.formatMessage({
                      id: 'filtrar',
                      defaultMessage: 'Filtrar',
                    })}
                  </button>{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <div className="row">
                <div className="col-4">
                  <label>
                    {intl.formatMessage({
                      id: 'field',
                      defaultMessage: 'Campo',
                    })}
                  </label>
                </div>
                <div className="col-8">
                  <input className={'clear-both border-solid ml-2'} style={{ border: '1px solid grey' }}></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchemaFilter;
