import React, { Component } from 'react';
import { getForm, deletePDF, printerRaw } from '../api/report';
import mime from 'mime-types';
import { PulseLoader } from 'halogenium';
import { mimeCheck } from '../../UI-kit/helpers/fileHelper';
import { BASE_API } from '../config';
import ModalMessage from './ModalMessage';
import { injectIntl } from 'react-intl';
import PrinterIcon from '../resources/printer.svg';

const URL_BASE_FORMS = `${BASE_API}/webclient/reports/`;

class PostingFormList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsForms: [],
      selectedIndexForms: null,
      rowsPermissions: [],
      commentPermission: '',
      showDialog: false,
      dialogTitle: '',
      dialogHandleAccept: null,
      showPDF: false,
      srcReport: '',
      mimeTypeReport: '',
      loadingPDF: false,
      postingPermissions: [],
      showMessage: false,
      titleMessage: '',
      showButtonAccept: false,
      buttonAcceptEnabled: false,
      onlyForwardAuthorize: false,
    };

    this.selectRowForms = this.selectRowForms.bind(this);
    this.selectRowPrint = this.selectRowPrint.bind(this);
  }

  async selectRowPrint(index) {
    const { data, intl } = this.props;
    const report = data[index];

    this.setState({ selectedIndexForms: index, loadingPDF: true });

    const formData = await getForm(false, report, true, false);
    if (formData.mensaje) {
      this.setState({ showMessage: true, titleMessage: formData.mensaje });
    } else {
      printerRaw(formData.properties, formData.pages, true).catch(() => {
        this.setState({
          showMessage: true,
          titleMessage: intl.formatMessage({
            id: 'printerRawError',
            defaultMessage: 'Ha ocurrido un error con el servicio de impresión',
          }),
        });
      });
    }
    this.setState({ loadingPDF: false });
  }

  async selectRowForms(index) {
    const { data } = this.props;
    const report = data[index];

    this.setState({ selectedIndexForms: index, loadingPDF: true });

    const formData = await getForm(false, report, false, true);
    if (formData.mensaje) this.setState({ showMessage: true, titleMessage: formData.mensaje });
    else if (typeof formData === 'string') this.setState({ showMessage: true, titleMessage: formData });
    else {
      const fileName = `${formData.name}.${formData.fileExtension}`;
      const mimeType = mime.lookup(fileName);

      let urlReport = fileName;
      if (!report.puedeReimprimir || !report.saveReportData) urlReport += '#toolbar=0&navpanes=0&scrollbar=0&view=FitH';
      else urlReport += '#view=FitH';

      if (mimeCheck(mimeType)) {
        this.setState({
          showPDF: true,
          srcReport: urlReport,
          mimeTypeReport: mimeType,
        });
      } else {
        this.setState({
          showMessage: true,
          showPDF: false,
          titleMessage: `No es posible reconocer el archivo ${fileName}`,
        });
      }

      setTimeout(() => {
        deletePDF(fileName);
      }, 30000);
    }

    this.setState({ loadingPDF: false });
  }

  render() {
    const { intl } = this.props;

    const defaultColumns = [
      { accessor: 'reportSpecID', Header: 'Id', width: 50 },
      {
        accessor: 'description',
        Header: intl.formatMessage({
          id: 'description',
          defaultMessage: 'Descripción',
        }),
      },
    ];

    const { columns = defaultColumns, data, noDataText } = this.props;

    const { showPDF, loadingPDF, srcReport, mimeTypeReport } = this.state;

    if (!data || data.length === 0) {
      return noDataText;
    }

    return (
      <div className="flex">
        <div className="flex-col">
          <ul className="list-reset">
            {data.map((item, index) => (
              // eslint-disable-next-line react/jsx-key
              <div className="flex flex-row">
                <div className="col mt-3 mr-2 w-4 h-5 w-5 hover:shadow-sm">
                  {item.insertion ? (
                    <li className="item cursor-pointer m-auto" title="Reimprimir" key={index} onClick={() => this.selectRowPrint(index)}>
                      <PrinterIcon width="15px" className="m-auto" />
                    </li>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="col">
                  <li
                    className={this.props.data[index].puedeReimprimir ? 'item cursor-pointer p-3 hover:shadow-sm' : 'item p-3'}
                    key={index}
                    onClick={() => {
                      if (this.props.data[index].puedeReimprimir) this.selectRowForms(index);
                    }}
                  >
                    <div className={'flex ' + (this.props.data[index].puedeReimprimir ? 'hover:text-primary' : '')}>
                      {columns.map(({ accessor }, colIndex) => {
                        if (typeof item[accessor] !== 'object') {
                          return (
                            <div className="inline-block mr-1 text-sm" key={`${index}-${colIndex}`}>
                              <span className={`${typeof item[accessor] === 'number' && 'text-xs bg-grey px-1 text-white rounded-sm font-light'}`}>
                                {String(item[accessor])}
                              </span>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </li>
                </div>
              </div>
            ))}
          </ul>
          {loadingPDF && <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />}
        </div>
        <div className="flex-col w-screen h-full">
          {showPDF && !loadingPDF && (
            <ModalMessage
              message={<embed className="w-screen h-full" src={`${URL_BASE_FORMS}${srcReport}`} type={mimeTypeReport} title="Reporte" />}
              handleAccept={() => {
                this.setState({
                  showPDF: false,
                });
              }}
              acceptTitle={intl.formatMessage({
                id: 'close',
                defaultMessage: 'Cerrar',
              })}
              isReport={true}
              largeModal={true}
              closeOutside={true}
              draggable={true}
            />
          )}
          {this.state.showMessage && (
            <ModalMessage
              message={this.state.titleMessage}
              handleAccept={() => {
                this.setState({
                  showMessage: false,
                });
              }}
              acceptTitle={intl.formatMessage({
                id: 'close',
                defaultMessage: 'Cerrar',
              })}
              isReport={true}
              closeOutside={true}
              draggable={true}
            />
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(PostingFormList);
