import { store } from '../../../store';
import { getBitacoraRestricciones } from '../../../api/bitacora';
import { formatDateForServer } from './filterFields';
import { exportToCsv } from './exportCSV';

const exportRestricciones = (filterParams, columns, filterListID) => {
  let {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    grupo,
    usuario,
    bMenu,
    bOperaciones,
    bReportes,
    bPermisos,
    bSucursales,
    codigorestriccion,
    tipodecambio,
    persistenceUnit,
  } = filterParams;

  let filterData = {};
  filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
  store.setState({
    bitacoraApp: {
      ...store.getState().bitacoraApp,
      ...filterData,
    },
  });

  let defaultValue;
  fechaHoraDesde = formatDateForServer(fechaHoraDesde);
  fechaHoraHasta = formatDateForServer(fechaHoraHasta);
  firstResult = firstResult || 0;
  maxResults = 10000;
  grupo = grupo || defaultValue;
  usuario = usuario || defaultValue;
  bMenu = bMenu || defaultValue;
  bOperaciones = bOperaciones || defaultValue;
  bReportes = bReportes || defaultValue;
  bPermisos = bPermisos || defaultValue;
  bSucursales = bSucursales || defaultValue;
  codigorestriccion = codigorestriccion || defaultValue;
  tipodecambio = tipodecambio || defaultValue;
  persistenceUnit = persistenceUnit || 'Local';
  const params = {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    grupo,
    usuario,
    bMenu,
    bOperaciones,
    bReportes,
    bPermisos,
    bSucursales,
    codigorestriccion,
    tipodecambio,
    persistenceUnit,
  };
  getBitacoraRestricciones(params)
    .then((data) => {
      exportToCsv(data, 'restricciones.csv', columns);
    })
    .catch((err) => {
      console.log(err);
    });
};

export { exportRestricciones };
