import { css } from 'aphrodite';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'unistore/react';

import { hasPermission } from '../../../../src/api/clearing';
import { getSessionInfo } from '../../../../src/api/session';
import { storeActions } from './../../../store';
import { useGlobalStyles } from './../../../styles/GlobalStylesContext';
import { Filters } from './../Constants';
import AmountRangeInput from './Fields/AmountRangeInput';
import BranchSelector from './Fields/BranchSelector';
import CurrencyInput from './Fields/CurrencyInput';
import ErrorMessage from './Fields/ErrorMessage';
import ReturnedStatusSelector from './Fields/ReturnedStatusSelector';
import SubmitButton from './Fields/SubmitButton';
import CheckNumberInput from './Fields/CheckNumberInput';
import AccountInput from './Fields/AccountInput';

const RefusedDigitalClearingFilter = (props) => {
  const [branch, setBranch] = useState();
  const [amounts, setAmounts] = useState({
    amountMin: '0',
    amountMax: '999999999999',
  });
  // eslint-disable-next-line no-unused-vars
  const [estadoDevolucion, setEstadoDevolucion] = useState();
  const [account, setAccount] = useState();
  const [checkNumber, setCheckNumber] = useState();
  const [currency, setCurrency] = useState('1');
  const [error, setError] = useState('');
  const intl = useIntl();
  const [permission, setPermission] = useState(false);

  const validate = () => {
    if (!branch || !amounts.amountMin || !amounts.amountMax || !currency) {
      const errMsg = intl.formatMessage({
        id: 'pleaseCompleteAllFields',
        defaultMessage: 'Por favor complete todos los campos',
      });
      throw new Error(errMsg);
    }
    let amountMin = props.parseAmountToFloat(amounts.amountMin);
    let amountMax = props.parseAmountToFloat(amounts.amountMax);
    if (amountMin > amountMax) {
      const errMsg = intl.formatMessage({
        id: 'amountMinIsBiggerThanAmountMax',
        defaultMessage: 'El monto mínimo debe ser menor que el monto máximo',
      });
      throw new Error(errMsg);
    }
  };

  const createData = () => {
    let accountData = account;
    if (!accountData) {
      accountData = -1;
    }
    let checkNumberData = checkNumber;
    if (!checkNumberData) {
      checkNumberData = '-1';
    }
    return {
      causal: parseInt(estadoDevolucion),
      sucursal: parseInt(branch, 10),
      moneda: parseInt(currency, 10),
      cuenta: parseInt(accountData),
      numeroCheque: checkNumberData,
      importeDesde: props.parseAmountToFloat(amounts.amountMin),
      importeHasta: props.parseAmountToFloat(amounts.amountMax),
    };
  };

  useEffect(() => {
    const getSession = async () => {
      hasPermission().then((data) => {
        setPermission(data);
        getSessionInfo().then((dataSession) => {
          let suc = dataSession.topazBranch.toString();
          if (data === false) {
            setBranch(suc);
          }
        });
      });
    };

    getSession();
  }, []);

  useEffect(() => setError(''), [branch, currency, amounts]);

  const onSubmit = (event) => {
    event.preventDefault();
    try {
      validate();
      const data = createData();
      props.setLoadingChecksSearch(true);
      props.getChecks(Filters.REFUSED_DIGITAL_CLEARING, data);
    } catch (exception) {
      setError(exception.message);
    }
  };

  // For amountMin and amountMax
  const onAmountChange = (event) => {
    event.persist && event.persist();
    setAmounts((amounts) => ({
      ...amounts,
      [event.target.name]: event.target.value,
    }));
  };

  const globalStyles = useGlobalStyles();

  return (
    <form onSubmit={onSubmit}>
      <div className={css(globalStyles.fieldsWithoutButton)}>
        <ReturnedStatusSelector status={estadoDevolucion} onStatusChange={setEstadoDevolucion} />
        <CurrencyInput currency={currency} onCurrencyChange={setCurrency} />
        <AmountRangeInput amounts={amounts} onAmountChange={onAmountChange} />
        <BranchSelector selectedBranch={branch} onBranchChange={setBranch} disabled={!permission} />
        <AccountInput account={account} onAccountChange={setAccount} />
        <CheckNumberInput checkNumber={checkNumber} onCheckNumberChange={setCheckNumber} />
      </div>
      <ErrorMessage message={error} />
      <SubmitButton />
    </form>
  );
};

export default connect(null, storeActions)(RefusedDigitalClearingFilter);
