import React from 'react';
import { ContextMenu, MenuItem, SubMenu } from 'react-contextmenu';
import { useIntl } from 'react-intl';
import { TreeMenuItem } from 'react-simple-tree-menu';
import { SelectedProcess } from '../../../../models/types';

export interface ContextData {
  node: SelectedProcess;
  isConditioningBoth: boolean;
  isCondition: boolean;
}

interface ContextPeriodicityProps {
  node: TreeMenuItem;
  onModalChange: (event: React.MouseEvent, data: ContextData) => void;
}

export function ContextPeriodicityOptions({ node, onModalChange }: ContextPeriodicityProps): JSX.Element {
  const intl = useIntl();

  return (
    <ContextMenu id={node.key}>
      <SubMenu title={intl.formatMessage({ id: 'conditionExecution' })}>
        {node.hasNodes && (
          <MenuItem data={{ node: node.item, isConditioningBoth: true, isCondition: true }} onClick={onModalChange}>
            <span>{intl.formatMessage({ id: 'groupAndChild' })}</span>
          </MenuItem>
        )}
        <MenuItem data={{ node: node.item, isConditioningBoth: false, isCondition: true }} onClick={onModalChange}>
          <span>{intl.formatMessage({ id: 'groupOrProcess' })}</span>
        </MenuItem>
      </SubMenu>
      {node.level === 0 && node.item.groupParameters.parametersList.length === 0 && (
        <MenuItem data={{ node: node.item, isConditioningBoth: false, isCondition: false }} onClick={onModalChange}>
          <span>{intl.formatMessage({ id: 'schedule' })}</span>
        </MenuItem>
      )}
      <MenuItem>
        <span>{intl.formatMessage({ id: 'close' })}</span>
      </MenuItem>
    </ContextMenu>
  );
}
