import React, { Component } from 'react';

export class MaskedInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueWithMask: '',
      valueRaw: '',
      mask: '',
    };
  }

  componentDidMount() {
    const { mask, value } = this.props;
    let parsedMask = [];
    for (const pos in mask) {
      if (mask[pos] === ' ') {
        parsedMask[pos] = '*';
      } else {
        parsedMask[pos] = mask[pos];
      }
    }
    this.setState({ mask: parsedMask });
    this.setState({ valueRaw: value });
    let val = this.addMaskToValue(parsedMask, value, true);
    let element = document.getElementById(this.props.id);
    element.value = val;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      let rawValue = this.removeMaskFromValue(this.props.value);
      this.addMaskToValue(this.state.mask, rawValue);
    }
  }

  removeMaskFromValue(value) {
    let rawValue = [];
    let rawValuePos = 0;
    let valuePos = 0;
    let valueRaw;
    var letterNumber = /^[0-9a-zA-Z]+$/;
    if (value !== undefined) {
      if (value.match(letterNumber)) {
        valueRaw = value;
      } else {
        for (const pos in this.state.mask) {
          if (this.state.mask[pos] === '*') {
            if (value[valuePos] && value[valuePos].match(letterNumber)) {
              rawValue[rawValuePos] = value[valuePos];
              rawValuePos++;
              valuePos++;
            } else {
              while (value[valuePos] && !value[valuePos].match(letterNumber)) {
                valuePos++;
              }
              rawValue[rawValuePos] = value[valuePos];
              rawValuePos++;
              valuePos++;
            }
          } else if (this.state.mask[pos] === value[valuePos] && valuePos === parseInt(pos)) {
            valuePos++;
          }
        }
        valueRaw = rawValue.join('');
      }

      this.setState({ valueRaw: valueRaw });
      return valueRaw;
    }
  }

  addMaskToValue(mask, value, shouldFocus = false) {
    let valuePos = 0;
    let valueWithMask = [];
    if (value !== undefined) {
      for (const pos in mask) {
        if (valuePos < value.length) {
          if (mask[pos] === '*') {
            valueWithMask[pos] = value[valuePos];
            valuePos++;
          } else {
            valueWithMask[pos] = mask[pos];
          }
        }
      }
      valueWithMask = valueWithMask.join('');
      this.setState({ valueWithMask: valueWithMask });
    }
    return valueWithMask;
  }

  render() {
    const { helpElement } = this.props;
    return (
      <input
        value={this.state.valueWithMask}
        onChange={(e) => {
          let rawValue = this.removeMaskFromValue(e.target.value);
          this.addMaskToValue(this.state.mask, rawValue);
          let fictionalEvent = e;
          let valueForEvent = rawValue;
          if (this.props.textStyle === 'uppercase') {
            valueForEvent.toUpperCase();
          } else if (this.props.textStyle === 'lowercase') {
            valueForEvent.toLowerCase();
          }
          fictionalEvent.target.value = valueForEvent;
          fictionalEvent.target.rawValue = valueForEvent;
          this.props.updateValues(valueForEvent);
          this.props.onChange(fictionalEvent);
        }}
        onKeyDown={(e) => this.props.onKeyDown(e, helpElement, this.props.textStyle, this.state.valueRaw)}
        maxLength={this.props.maxLength}
        onFocus={(e) => this.props.onFocus(e)}
        onMouseDown={(e) => this.props.onMouseDown(e)}
        id={this.props.id}
        style={this.props.style}
        ref={this.props.inputRef}
        onBlur={(e) => this.props.onBlur(e)}
        readOnly={this.props.readOnly}
        disabled={this.props.disabled}
        className={this.props.className}
      ></input>
    );
  }
}

export default MaskedInput;
