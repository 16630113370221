/**
 * Convert a number color into a [R, G, B] color
 * @param {number} num Integer or Long Color
 */
export const num2rgb = (num) => {
  num >>>= 0;
  var r = (num & 0xFF0000) >>> 16;
  var g = (num & 0xFF00) >>> 8;
  var b = num & 0xFF;

  // If the result must be rgba:
  // var a = ((num & 0xFF000000) >>> 24) / 255;

  return [r, g, b];
}

const _rgbToHex = (rgb) => {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = '0' + hex;
  }
  return hex;
};

/**
 * Convert a RGB array to hex color like #0F0F33
 * @param {array} array An array of RGB color [r, g, b]
 */
export const rgb2hex = ([r, g, b]) => `#${_rgbToHex(r)}${_rgbToHex(g)}${_rgbToHex(b)}`;

/**
 * Convert a number-color like -16760704 into an hex color like #004080
 * @param {number} num Integer or Long Color
 */
export const num2hex = num => rgb2hex(num2rgb(num));
