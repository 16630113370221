import React from 'react';
import { LoginFingerPrint } from './LoginFingerPrintV2';
import { AddFingerPrint } from './AddFingerPrintV2';
import { login, secondStepLogin } from '../../api/fingerprintWS';
import browserStorage from '../../utils/browserStorage';
import { USER_NAME } from '../../config';
import * as LS from './constants/LoginStatus';
import { useIntl } from 'react-intl';

const useHuellaLogin = ({ setLoading, setLoginMessage, setError, handleLoginNumber, handleLogin }) => {
  const [huellaBase64, setHuellaBase64] = React.useState(null);
  const [requireRegisterFingerprint, setRequireRegisterFingerprint] = React.useState(false);
  const intl = useIntl();

  const handleCancel = () => {
    setHuellaBase64(null);
  };

  const getLoginFingerView = (username) => {
    return (
      <div>
        <LoginFingerPrint
          user={username}
          secondLogin={true}
          setSecondLogin={() => {
            /* usado cerrar ventana */
          }}
          tipoLoged={1}
          loginDialog={(username, password, hasHuellaLogin, huellaBase64) => {
            setHuellaBase64(huellaBase64);
          }}
          handleLoginNumber={handleLoginNumber}
          handleCancel={handleCancel}
          intl={intl}
        ></LoginFingerPrint>
      </div>
    );
  };

  const getAddFingerView = ({ username, password, multipleLogin }) => {
    return (
      <div>
        <AddFingerPrint
          user={username}
          password={password}
          multipleLogin={multipleLogin}
          setAddFingerLogin={() => {
            /* usado cerrar ventana */
          }}
          loginDialog={(username, password, hasHuellaLogin, huellaBase64) => {
            setHuellaBase64(huellaBase64);
            handleLogin(huellaBase64);
          }}
          handleLoginNumber={handleLoginNumber}
          setRequireRegisterFingerprint={setRequireRegisterFingerprint}
          tipoLoged={1}
          intl={intl}
        ></AddFingerPrint>
      </div>
    );
  };

  const getHuellaView = ({ username, password, registerFinger, multipleLogin }) => {
    if (registerFinger || requireRegisterFingerprint) {
      return getAddFingerView({ username, password, multipleLogin });
    } else {
      return getLoginFingerView({ username, multipleLogin });
    }
  };

  const performHuellaLogin = async (sessionID, username, pHuellaBase64, isMultipleLogin) => {
    setLoginMessage(null);
    setLoading(true);
    try {
      const handleResponse = (response) => {
        const { errorTypeInfo, mensaje, returnCode } = response.data;
        if (errorTypeInfo) {
          setError(mensaje);
        }
        browserStorage.sessionSet(USER_NAME, username);
        if (!requireRegisterFingerprint) {
          setRequireRegisterFingerprint(returnCode === LS.FINGERPRINT_NOT_REGISTERED);
        }

        handleLoginNumber(returnCode);
      };
      const handleCatch = (err) => {
        const { message } = err.response.data;
        setError(message || err.message || err);
      };

      if (isMultipleLogin) {
        await secondStepLogin(sessionID, username, huellaBase64 || pHuellaBase64)
          .then(handleResponse)
          .catch(handleCatch);
      } else {
        await login(sessionID, username, huellaBase64).then(handleResponse).catch(handleCatch);
      }
    } catch (err) {
      setError(err.message || err);
    } finally {
      setLoading(false);
    }
  };

  return {
    performHuellaLogin,
    getHuellaView,
    hasHuellaLogin: !!huellaBase64,
    requireRegisterFingerprint,
    setRequireRegisterFingerprint,
    huellaBase64,
    setHuellaBase64,
  };
};

export default useHuellaLogin;
