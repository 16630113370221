import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import './LdapModal.scss';

export interface LDAPModalViewProps {
  onCancel: (event: React.MouseEvent) => void;
  onAccept: (event: React.MouseEvent) => void;
  onUserChange: (event: { target: HTMLInputElement }) => void;
  onPasswordChange: (event: { target: HTMLInputElement }) => void;
  onRememberCredentialsCheck: (event: { target: HTMLInputElement }) => void;
}

export function LDAPModalView({
  onCancel,
  onAccept,
  onPasswordChange,
  onUserChange,
  onRememberCredentialsCheck,
}: LDAPModalViewProps): JSX.Element | null {
  const intl = useIntl();

  return (
    <div
      className="font-mono ldap-modal z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ right: 'initial', height: '300px' }}
    >
      <div className="flex justify-center">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border shadow-sm"
          style={{ width: 'fit-content', height: 'fit-content' }}
        >
          <div className="bg-blue-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg text-center leading-6 font-medium text-gray-900" id="modal-title">
                  {intl.formatMessage({ id: 'validate' })} LDAP
                </h3>
                <div className="flex flex-col mt-6">
                  <div className="m-2 flex items-center mb-3 justify-between">
                    <label className="pr-3 text-sm">{intl.formatMessage({ id: 'validatorUser' })}</label>
                    <input onChange={onUserChange} className="border border-grey-lighter" style={{ width: '170px' }} />
                  </div>
                  <div className="m-2 flex items-center mb-3 justify-between">
                    <label className="pr-1  text-sm">{intl.formatMessage({ id: 'passwordLogin' })}</label>
                    <input onChange={onPasswordChange} type="password" className="border border-grey-lighter" />
                  </div>
                  <div className="m-2 flex items-center mb-3 justify-center items-center">
                    <input className="mr-2 ml-2" type="checkbox" value="1" name="rememberCredentials" onChange={onRememberCredentialsCheck} />
                    <label className="pr-1 text-sm">{intl.formatMessage({ id: 'rememberCredentials' })}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="justify-center bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onAccept}
              type="button"
              className="w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium text-gray-700 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {intl.formatMessage({ id: 'validate' })}
            </button>
            <button
              onClick={onCancel}
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {intl.formatMessage({ id: 'cancel' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
