import axios from '../utils/axios';
import { BASE_API } from '../config';
import { encryptAES } from '../utils/aes';
import { USER_SESSION } from '../utils/browserStorage/constants';
import browserStorage from '../utils/browserStorage';

export let startCapture = () => {
  return axios.get(`http://localhost:8733/fingerprint/device/api/finger/capture`);
};

export let startCaptureABM = () => {
  return axios.get(`http://localhost:8733/fingerprint/device/api/finger/register`);
};

export let putVerificationData = (listData, convertBase64) => {
  let fingerdata1 = [];
  let fingerdata2 = [];
  let fingerdata3 = [];
  let fingerdata4 = [];
  if (listData.length > 0) {
    if (convertBase64) {
      fingerdata1 = arrayBufferToBase64(listData[0].fingerdata);
    } else {
      fingerdata1 = listData[0].fingerdata;
    }
  } else {
    fingerdata1 = '';
  }
  if (listData.length > 1) {
    if (convertBase64) {
      fingerdata2 = arrayBufferToBase64(listData[1].fingerdata);
    } else {
      fingerdata2 = listData[1].fingerdata;
    }
  } else {
    fingerdata2 = '';
  }
  if (listData.length > 2) {
    if (convertBase64) {
      fingerdata3 = arrayBufferToBase64(listData[2].fingerdata);
    } else {
      fingerdata3 = listData[2].fingerdata;
    }
  } else {
    fingerdata3 = '';
  }
  if (listData.length > 3) {
    if (convertBase64) {
      fingerdata4 = arrayBufferToBase64(listData[3].fingerdata);
    } else {
      fingerdata4 = listData[3].fingerdata;
    }
  } else {
    fingerdata4 = '';
  }

  const body = { fingerdata1, fingerdata2, fingerdata3, fingerdata4 };
  return axios.post(`http://localhost:8733/fingerprint/device/api/finger/PutVerificationData`, body);
};

export let arrayBufferToBase64 = (buffer) => {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export let getScanned = () => {
  return axios.get(`http://localhost:8733/fingerprint/device/api/finger/scanned`);
};

export let getResultVerification = () => {
  return axios.get(`http://localhost:8733/fingerprint/device/api/finger/Validation`);
};

export let getScannedImages = () => {
  return axios.get(`http://localhost:8733/fingerprint/device/api/finger/GetCapturedImage`);
};

export let getScannedSet = () => {
  return axios.get(`http://localhost:8733/fingerprint/device/api/finger/register/scanned`).then(({ data }) => data);
};

export let getFingerData = () => {
  return axios.get(`http://localhost:8733/fingerprint/device/api/finger/register/capture/fingerdata`).then(({ data }) => data);
};

export let getFingerDataANSI = () => {
  return axios.get(`http://localhost:8733/fingerprint/device/api/finger/register/capture/dataansi`).then(({ data }) => data);
};

export let getFingerDataBMP = () => {
  return axios
    .get(`http://localhost:8733/fingerprint/device/api/finger/register/capture/databmp`)
    .then(({ data }) => data)
    .catch(({ data }) => data);
};

export let getLastBMP = () => {
  return axios
    .get(`http://localhost:8733/fingerprint/device/api/finger/register/capture/lastbmp`)
    .then(({ data }) => data)
    .catch(({ data }) => data);
};

export let reset = () => {
  return axios
    .get(`http://localhost:8733/fingerprint/device/api/finger/reset`)
    .then(({ data }) => data)
    .catch(({ data }) => data);
};

// Login solo con huella
export let login = async (sessionID, user, huellaBase64) => {
  const sessionID2 = browserStorage.sessionGet(USER_SESSION);
  const base64BaseUrl = btoa(BASE_API);
  const body = {
    key: user,
    fingerData: huellaBase64,
    baseUrl: base64BaseUrl,
  };
  return axios.post(`http://localhost:8733/fingerprint/device/api/topaz/login?sessionID=${sessionID2}`, body);
};

// Si login con huella y contraseña (doble), este es el secondStep
export let secondStepLogin = async (sessionID, user, huellaBase64) => {
  const base64BaseUrl = btoa(BASE_API);
  const body = {
    key: user,
    fingerData: huellaBase64,
    baseUrl: base64BaseUrl,
  };
  return axios.post(`http://localhost:8733/fingerprint/device/api/topaz/secondstep/validation?sessionID=${sessionID}`, body);
};

export let loginPasswordAndFingerprint = async (sessionID, user, plainPassword, huellaBase64) => {
  const base64BaseUrl = btoa(BASE_API);
  const password = plainPassword ? encryptAES(plainPassword).base64 : '';
  const sessionID2 = browserStorage.sessionGet(USER_SESSION);

  const body = {
    key: user,
    password: password,
    fingerData: huellaBase64,
    baseUrl: base64BaseUrl,
  };
  return axios.post(`http://localhost:8733/fingerprint/device/api/topaz/full/login?sessionID=${sessionID2}`, body);
};

// Verificación de huella
export let verifyFingerprint = async (user, huellaBase64) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  const base64BaseUrl = btoa(BASE_API);
  const body = {
    key: user?.toString(),
    fingerData: huellaBase64,
    baseUrl: base64BaseUrl,
  };
  return axios.post(`http://localhost:8733/fingerprint/device/api/topaz/personidentification/verify?sessionID=${sessionID}`, body);
};
