import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import ModalMessage from '../../../../src/components/ModalMessage';

const SIZE_THUMBNAILS = 70;

export const CheckContainer = ({
  check = undefined,
  showOptions = false,
  checkId,
  thumbnails = false,
  click = null,
  intl,
  selected = false,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const [clickedCheck, setClickedCheck] = useState(null);

  const switchShowPreview = () => {
    setShowPreview(!showPreview);
  };
  return (
    check &&
    check[0] !== 'data:image/png;base64, null' && (
      <div className={`${thumbnails ? 'p-1' : ''}`}>
        {
          <div
            className={`relative text-center ${click && 'cursor-pointer'} rounded`}
            id="image-container"
            onClick={click}
            style={{ clear: 'both', content: '', display: 'table' }}
          >
            <div
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                width: thumbnails ? '' : '125%',
              }}
            >
              <div style={{ float: 'left', width: '40%', padding: '5px' }}>
                <img
                  className={`block border inline-block bg-white px-2 object-cover mx-auto ${
                    selected ? 'border-primary shadow' : 'border-smoke'
                  }`}
                  src={`${check[0]}`}
                  style={{
                    filter: 'none',
                    height: thumbnails ? SIZE_THUMBNAILS : 180,
                  }}
                  onClick={(e) => {
                    setClickedCheck(check[0]);
                    switchShowPreview();
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </div>
              <div style={{ float: 'left', width: '40%' }}>
                <img
                  className={`block border inline-block bg-white px-2 object-cover mx-auto ${
                    selected ? 'border-primary shadow' : 'border-smoke'
                  }`}
                  src={`${check[1]}`}
                  style={{
                    filter: 'none',
                    height: thumbnails ? SIZE_THUMBNAILS : 180,
                  }}
                  onClick={(e) => {
                    setClickedCheck(check[1]);
                    switchShowPreview();
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
            {!!check[2] && (
              <div style={{ float: 'left', width: '40%' }}>
                <img
                  className={`block border inline-block bg-white px-2 object-cover mx-auto ${
                    selected ? 'border-primary shadow' : 'border-smoke'
                  }`}
                  src={`${check[2]}`}
                  style={{
                    filter: 'none',
                    height: thumbnails ? SIZE_THUMBNAILS : 180,
                  }}
                  onClick={(e) => {
                    setClickedCheck(check[2]);
                    switchShowPreview();
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </div>
            )}
            {showPreview && (
              <ModalMessage
                message={
                  <div className="flex w-full h-full justify-center items-center">
                    <img className="w-full max-h-full" src={clickedCheck} />
                  </div>
                }
                handleAccept={() => {
                  switchShowPreview();
                }}
                largeModal={true}
                closeOutside={true}
                draggable={true}
                acceptTitle={intl.formatMessage({
                  id: 'close',
                  defaultMessage: 'Cerrar',
                })}
              />
            )}
          </div>
        }
      </div>
    )
  );
};

export default injectIntl(CheckContainer);
