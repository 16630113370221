import { css, StyleSheet } from 'aphrodite';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getEstados } from './../../../../api/clearing';
import { useGlobalStyles } from './../../../../styles/GlobalStylesContext';
import { useTheme } from './../../../../themes/ThemeContext';
import Field from './Field';

const ReturnedStatusSelector = ({ onStatusChange, error }) => {
  const intl = useIntl();
  const labelStatus = intl.formatMessage({
    id: 'status',
    defaultMessage: 'Estado',
  });
  const allStatuses = intl.formatMessage({
    id: 'allStatuses',
    defaultMessage: 'Todos los estados',
  });
  const selectABranch = intl.formatMessage({
    id: 'pleaseSelect',
    defaultMessage: 'Seleccione...',
  });

  const initialOptions = [
    {
      value: '',
      text: selectABranch,
    },
    {
      value: -1,
      text: allStatuses,
    },
  ];
  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    const fetchCausesData = async () => {
      const data = await getEstados();
      if (data && data.length > 0) {
        const causes = data.map((cause) => ({
          value: cause.codigo,
          text: cause.descripcion,
        }));
        setOptions((initialOptions) => [...initialOptions, ...causes]);
      }
    };

    fetchCausesData();
  }, []);

  const themeState = useTheme();
  const { error: errorColor } = themeState.theme;

  const styles = StyleSheet.create({
    select: {
      padding: '.2rem',
    },
    error: {
      borderColor: errorColor.error,
      borderWidth: 1,
    },
  });

  const globalStyles = useGlobalStyles();

  return (
    <Field labelText={labelStatus} htmlFor="statusSelect" error={error}>
      <select
        id="statusSelect"
        name="statusSelect"
        required
        className={css([globalStyles.input, styles.select, error && styles.error])}
        onChange={(event) => onStatusChange(event.target.value)}
      >
        {options &&
          Array.isArray(options) &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.value !== '' && option.value !== -1 && `${option.value} - `}
              {option.text}
            </option>
          ))}
      </select>
    </Field>
  );
};
export default ReturnedStatusSelector;
