import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { PulseLoader } from 'halogenium';
import { FormattedMessage, injectIntl } from 'react-intl';

import withReportServices from '../../api/withReportServices';
import { storeActions } from '../../store';
import FieldReport from './FieldReport';

import { BASE_API } from '../../config';
import { StyleSheet, css } from 'aphrodite';

const URL_BASE_REPORTS = `${BASE_API}/webclient/reports/`;

const INTEGER_TYPE = 2;
const NUMBER_TYPE = 3;
const TEXT_TYPE = 4;
const DATE_TYPE = 5;

const valueValid = (value) => value !== undefined && value !== '';

const valuesAreValid = (inferiorValue, superiorValue, range, type) => {
  if ((type !== TEXT_TYPE && !valueValid(inferiorValue)) || (range && type !== TEXT_TYPE && !valueValid(superiorValue))) return false;
  if (type === TEXT_TYPE) return true;
  if (type === NUMBER_TYPE || type === INTEGER_TYPE) {
    if (isNaN(inferiorValue) || (range && isNaN(superiorValue))) return false;
  }
  if (type !== DATE_TYPE && range && (inferiorValue > superiorValue || superiorValue < inferiorValue)) return false;
  return true;
};

export class ReportDisplay extends Component {
  constructor(props) {
    super(props);
    const { report } = props;
    this.state = {
      fields: report.fields,
      fileReport: null,
      loading: false,
      errorMessage: '',
      checkPdf: true,
      checkExcel: false,
      fileReportExcel: null,
    };

    this.assignFieldValue = this.assignFieldValue.bind(this);
    this.handleGenerateReport = this.handleGenerateReport.bind(this);
    this.handleEnableChange = this.handleEnableChange.bind(this);
  }

  assignFieldValue(number, lowerValue = null, higherValue = null, jasperName = null) {
    const { fields } = this.state;
    this.setState({
      fields: fields.map((field) => {
        if (field.jasperName) {
          if (field.jasperName === jasperName) {
            if (lowerValue !== null) {
              if (lowerValue === 'NaNaNaN') {
                lowerValue = null;
              }
              field.inferiorValue = lowerValue;
            }
            if (higherValue !== null) {
              if (higherValue === 'NaNaNaN') {
                higherValue = null;
              }
              field.superiorValue = higherValue;
            }
          }
        } else if (number !== 0 && field.number === number) {
          if (lowerValue !== null) {
            if (lowerValue === 'NaNaNaN') {
              lowerValue = null;
            }
            field.inferiorValue = lowerValue;
          }
          if (higherValue !== null) {
            if (higherValue === 'NaNaNaN') {
              higherValue = null;
            }
            field.superiorValue = higherValue;
          }
        }
        return field;
      }),
    });
  }

  handleEnableChange(event, type) {
    this.setState({
      errorMessage: '',
    });
    if (type === 'pdf') {
      this.setState({
        checkPdf: event.target.checked,
      });
    } else {
      this.setState({
        checkExcel: event.target.checked,
      });
    }
  }

  handleGenerateReport() {
    const { report, getReport, addReportData, setReportOnlyPreview, intl } = this.props;
    const { fields } = this.state;

    for (var i = 0; i < fields.length; i++) {
      if (!valuesAreValid(fields[i].inferiorValue, fields[i].superiorValue, fields[i].range, fields[i].type)) {
        this.setState({
          errorMessage: intl.formatMessage({
            id: 'invalidValues',
            defaultMessage: 'Valores inválidos',
          }),
          loading: false,
        });
        return false;
      }
    }

    const { checkPdf, checkExcel } = this.state;
    if (!checkPdf && !checkExcel) {
      this.setState({
        errorMessage: intl.formatMessage({
          id: 'selectAtLeastOneCheckbox',
          defaultMessage: 'Seleccione al menos un tipo de archivo para generar',
        }),
        loading: false,
      });
      return false;
    }

    this.setState({
      errorMessage: '',
      loading: true,
    });

    let dataReport = report;
    dataReport.fields = fields;
    if (this.state.checkExcel === true && this.state.checkPdf === true) {
      dataReport.destinoWeb = 3;
    } else if (this.state.checkExcel === true && this.state.checkPdf === false) {
      dataReport.destinoWeb = 2;
    } else {
      dataReport.destinoWeb = 0;
    }
    // TODO esto se debe de solucionar de lado servidor, este llamado se debería de quitar.
    if (dataReport.destinoWeb === 3 && dataReport.type === 'TOPAZ') {
      let dataReportPdf = { ...dataReport };
      dataReportPdf.destinoWeb = 0;
      getReport(dataReportPdf, true);
    }
    getReport(dataReport, true)
      .then((data) => {
        if (data.name && (data.fileExtension || data.fileExtensionExcel)) {
          const { name, fileExtension, fileExtensionExcel } = data;
          if (fileExtension) {
            if (fileExtension === 'csv') {
              this.setState({
                fileReport: `${name}.pdf`,
              });
            } else {
              this.setState({
                fileReport: `${name}.${fileExtension}`,
              });
            }
          }
          if (fileExtensionExcel) {
            this.setState({
              fileReportExcel: `${name}.${fileExtensionExcel}`,
            });
          }
          this.setState({
            errorMessage: '',
            loading: false,
          });

          const reportData = {
            description: report.description,
            specificationId: report.specificationId,
            name: name,
            nameExcel: name,
            fileExtension: fileExtension === 'csv' ? 'pdf' : fileExtension,
            fileExtensionExcel:
              fileExtensionExcel || (dataReport.type === 'TOPAZ' && (dataReport.destinoWeb === 3 || dataReport.destinoWeb === 2) ? 'csv' : ''),
          };

          addReportData(reportData);
          setReportOnlyPreview(true);
        } else {
          if (data.name === '' && data.fileExtension === '' && data.fileExtensionExcel === '' && data.serverPath === '') {
            this.setState({ loading: false });
            this.props.handleRemoveTab(this.props.tabIndex);
          } else {
            this.setState({
              errorMessage: intl.formatMessage({
                id: 'errorGettingData',
                defaultMessage: 'Error al obtener los datos',
              }),
              loading: false,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        const { message } = err;
        const { openGlobalModal } = this.props;
        openGlobalModal(
          <div>
            {intl.formatMessage({
              id: 'errorOccurredWhileCreatingReporte',
              defaultMessage: 'Ha ocurrido un error al crear el reporte:',
            })}
            <div className="mt-4">
              <i title={err.config.url}>{message}</i>
            </div>
          </div>,
          false,
        );
      });
  }

  render() {
    const { report, intl } = this.props;
    const { fileReport, loading, errorMessage } = this.state;

    if (this.props.tabIndex !== this.props.currentTabReport) {
      return null;
    }
    if (!report.fields) {
      return (
        <div className="mt-6">
          <p>{report}</p>
        </div>
      );
    }

    const selectExportFileType = intl.formatMessage({
      id: 'selectExportFileType',
      defaultMessage: "Seleccione el tipo de archivo que desea generar y presione el botón 'Generar Reporte'",
    });

    const { checkPdf, checkExcel } = this.state;

    return (
      <div className="mt-6">
        <div className="my-4 p-4 rounded shadow bg-grey-lighter-lighter">
          <h2 className="text-sm mb-4">{`${report.specificationId} - ${report.description}`}</h2>
          {report.fields.map((field, index) => (
            <FieldReport key={field.number} id={index} field={field} assignFieldValue={this.assignFieldValue} />
          ))}
          {fileReport === null && (
            <div>
              {report.type === 'JASPER' ? (
                <div key="chpdf" className="mb-4">
                  <div>
                    <p className={css(styles.selectExportFileType)}>{selectExportFileType}</p>
                  </div>
                  <div className={css(styles.fileTypesInputsContainer)}>
                    <div className={css(styles.fileTypeInputContainer)}>
                      <input id="inputGeneratePdf" type="checkbox" checked={checkPdf} onChange={(e) => this.handleEnableChange(e, 'pdf')} />
                      <label htmlFor="inputGeneratePdf" className={css([styles.checkFileType, checkPdf && styles.checked])}>
                        <FormattedMessage id="pdf" defaultMessage={`PDF`} />
                      </label>
                    </div>
                    {report.generaExcel && (
                      <div className={css(styles.fileTypeInputContainer)}>
                        <input id="inputGenerateExcel" type="checkbox" checked={checkExcel} onChange={(e) => this.handleEnableChange(e, 'excel')} />
                        <label htmlFor="inputGenerateExcel" className={css([styles.checkFileType, checkExcel && styles.checked])}>
                          <FormattedMessage id="excel" defaultMessage={`Excel (XLSX)`} />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div key="chpdf" className="mb-4">
                  <div>
                    <p className={css(styles.selectExportFileType)}>{selectExportFileType}</p>
                  </div>
                  <div className={css(styles.fileTypesInputsContainer)}>
                    <div className={css(styles.fileTypeInputContainer)}>
                      <input id="inputGeneratePdf" type="checkbox" checked={checkPdf} onChange={(e) => this.handleEnableChange(e, 'pdf')} />
                      <label htmlFor="inputGeneratePdf" className={css([styles.checkFileType, checkPdf && styles.checked])}>
                        <FormattedMessage id="pdf" defaultMessage={`PDF`} />
                      </label>
                    </div>
                    {report.generaExcel && (
                      <div className={css(styles.fileTypeInputContainer)}>
                        <input id="inputGenerateExcel" type="checkbox" checked={checkExcel} onChange={(e) => this.handleEnableChange(e, 'csv')} />
                        <label htmlFor="inputGenerateExcel" className={css([styles.checkFileType, checkExcel && styles.checked])}>
                          <FormattedMessage id="excel" defaultMessage={`Excel (CSV)`} />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <button
                className="inline-block border bg-primary rounded py-2 px-8 text-white text-smgit "
                id="generateReportButton"
                onClick={(e) => {
                  if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) {
                    this.handleGenerateReport();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.handleGenerateReport();
                  }
                }}
              >
                {intl.formatMessage({
                  id: 'generateReport',
                  defaultMessage: 'Generar Reporte',
                })}
              </button>
            </div>
          )}
        </div>
        {
          <div id="reportContainer" className="flex m-2">
            {loading ? (
              <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
            ) : errorMessage !== '' ? (
              <div className={css(styles.errorMessage)}>{errorMessage}</div>
            ) : fileReport !== null ? (
              <div>
                {intl.formatMessage({
                  id: 'reportGeneratedSuccessfully',
                  defaultMessage: 'Reporte generado satisfactoriamente.',
                })}
                <a
                  href={`${URL_BASE_REPORTS}${fileReport}`}
                  download={`${report.description || 'ReporteTopaz'}.xlsx`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="flex justify-center"
                  style={{ textDecoration: 'none' }}
                >
                  <button className="border bg-primary rounded py-2 px-8 text-white text-sm p-2 mt-2">
                    {intl.formatMessage({
                      id: 'download',
                      defaultMessage: 'Descargar',
                    })}
                  </button>
                </a>
                <embed
                  className="w-full h-full m-2 bg-white"
                  style={{ height: 400 }}
                  src={`${URL_BASE_REPORTS}${fileReport}#view=FitH&toolbar=0&navpanes=0&scrollbar=0`}
                  type={'application/pdf'}
                  title="Reporte"
                  autoSave={false}
                />
              </div>
            ) : null}
          </div>
        }
      </div>
    );
  }
}

const styles = StyleSheet.create({
  selectExportFileType: {
    fontSize: '.9rem',
  },
  fileTypesInputsContainer: {
    marginTop: '1.2rem',
  },
  fileTypeInputContainer: {
    marginBottom: '.3rem',
  },
  checkFileType: {
    userSelect: 'none',
    marginLeft: '.5rem',
  },
  checked: {
    color: '#090909',
  },
  errorMessage: {
    color: '#850000',
  },
});

const mapStateToProps = (state) => {
  const { currentTabReport } = state;

  return {
    currentTabReport,
  };
};

export default connect(mapStateToProps, storeActions)(withReportServices(injectIntl(ReportDisplay)));
