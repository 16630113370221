import { useEffect, useState } from 'react';

import { getGroupCatalogsAsync, getSpecificGroupCatalogsAsync } from '../../../../../../api/securityManager';
import { RestrictionObject } from '../../../../models/restrictionObject';
import { useRestrictions } from './useRestrictions.hook';

interface UseCatalogs {
  catalogs: CatalogsTree[];
}

interface CatalogsService {
  descripcion: string;
  numerocatalogo: number;
  permisos: number;
}

interface CatalogsGroupService {
  grupo: string;
  numerocatalogo: number;
}

export interface CatalogsTree {
  key: string;
  label: string;
  nodes: CatalogsTree[];
  restriction: RestrictionObject;
}

export const CATALOGS_TYPE: string = 'C';

export function useCatalogs(parentGroup: string, group: string): UseCatalogs {
  const [catalogOptions, setCatalogsOptions] = useState<CatalogsTree[]>([]);
  const { fillNumberWithZeros } = useRestrictions();

  async function getCatalogsAsync(): Promise<void> {
    const catalogs: CatalogsService[] = await getGroupCatalogsAsync();
    const groupCatalogs: CatalogsGroupService[] =
      group !== '' ? await getSpecificGroupCatalogsAsync(group) : await getSpecificGroupCatalogsAsync(parentGroup);
    const options: CatalogsTree[] = [];
    catalogs.forEach((catalogOption, index) => {
      const catalogInfo = groupCatalogs.find((c) => c.numerocatalogo === catalogOption.numerocatalogo);
      const catalogNode = {
        key: `${index} ${catalogOption?.descripcion || ''}`,
        label: `${catalogOption?.numerocatalogo || ''} - ${catalogOption?.descripcion || ''}`,
        nodes: [],
        restriction: {
          id: fillNumberWithZeros(catalogOption?.numerocatalogo || 0, 8),
          description: catalogOption?.descripcion,
          type: CATALOGS_TYPE,
          allows: catalogInfo !== undefined ? 1 : 0,
          ownPermission: catalogInfo !== undefined ? 1 : 0,
          titlePermission: null,
          parentPermission: null,
          catalogNumber: catalogOption.numerocatalogo,
        },
      };

      options.push(catalogNode);
    });

    setCatalogsOptions(options);
  }

  useEffect(() => {
    getCatalogsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return {
    catalogs: catalogOptions,
  };
}
