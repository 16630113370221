import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { addValueTest } from './Testing/HandleLineTest';

export default class WorkflowDropdownBlur extends Component {
  state = {
    isOpen: false,
  };
  timeOutId = null;

  render() {
    const { operations, item, execute } = this.props;
    let itemsMenu = operations.map((oper) => (
      <li key={oper.operationNumber}>
        <button
          id={oper.operationNumber}
          key={oper.operationNumber}
          className="text-xxs text-white bg-grey hover:bg-primary focus:bg-primary cursor-pointer px-2 py-1 uppercase mr-1"
          onClick={(e) => execute(e, item, oper.operationNumber)}
          style={{
            width: '100px',
            height: '30px',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          {oper.operationLabel}
        </button>
      </li>
    ));

    return (
      <div
        onBlur={this.onBlurHandler}
        onFocus={this.onFocusHandler}
        style={{
          display: 'block',
          position: 'relative',
        }}
      >
        <button
          className="text-xxs text-white bg-grey hover:bg-primary focus:bg-primary cursor-pointer px-2 py-1 uppercase mr-1"
          onClick={this.onClickHandler}
          id={'executeWfAction-' + item.TaskID}
          aria-haspopup="true"
          aria-expanded={this.state.isOpen}
          style={{
            width: '50px',
          }}
        >
          . . .
        </button>
        {this.state.isOpen ? (
          <ul
            style={{
              display: 'block',
              position: 'relative',
              listStyle: 'none',
              paddingLeft: 10,
              paddingRight: 10,
              zIndex: 3,
              whiteSpace: 'nowrap',
              left: -60,
            }}
          >
            {itemsMenu}
            <li key="ejecutar">
              <button
                id={'executeWfAction-' + item.TaskID + '-' + 'exec'}
                onClick={(e) => execute(e, item)}
                className="text-xxs text-white bg-grey hover:bg-primary focus:bg-primary cursor-pointer px-2 py-1 rounded-sm uppercase mr-1"
                style={{
                  width: '100px',
                  height: '30px',
                }}
              >
                <FormattedMessage id="execute" defaultMessage={`Ejecutar`} />
              </button>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }

  onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    addValueTest('CLICK', `${e.currentTarget.id}`, '');
    this.setState((currentState) => ({
      isOpen: !currentState.isOpen,
    }));
  };

  // We close the popover on the next tick by using setTimeout.
  // This is necessary because we need to first check if
  // another child of the element has received focus as
  // the blur event fires prior to the new focus event.
  onBlurHandler = () => {
    this.timeOutId = setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    });
  };

  // If a child receives focus, do not close the popover.
  onFocusHandler = () => {
    clearTimeout(this.timeOutId);
  };
}
