import React, { useState } from 'react';
import { connect } from 'unistore/react';
import { css, StyleSheet } from 'aphrodite';
import FocusLock from 'react-focus-lock';
import { addValueTest, addLineEventTest } from '../../../src/components/Testing/HandleLineTest';

import { store, storeActions } from '../../../src/store';
import Draggable from 'react-draggable';
import { useTheme } from '../../../src/themes/ThemeContext';
import Button from './Button';
import { BUTTON_MORE_INFO } from './constants/Buttons';
import InfoTable from '../InfoTable';
import { ABOVE_ALL } from '../../../UI-kit/constants/zIndexValues';
import ButtonData from '../../../UI-kit/components/Modal/services/ButtonData';
import { useIntl } from 'react-intl';
import { getCurrentTabInformation } from '../../../src/selectors';
import { noticeDecided } from '../../../src/api/notice';
import { getTabIdFromLineIDString } from '../../helpers/operationHelper';

export const Modal = (props) => {
  const [modalDragging, setModalDragging] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const intl = useIntl();

  const themeContext = useTheme();

  const styles0 = StyleSheet.create({
    hide: {
      display: 'none',
      zIndex: -1,
    },
  });

  if (!props.message) {
    return <div className={css(styles0.hide)} />;
  }

  const createButtonData = (btnId) => {
    const { defaultButton } = props;
    const autoFocus = defaultButton === btnId;
    const button = new ButtonData(btnId, autoFocus);
    return button.createData(intl);
  };
  const source = props.source;
  const infoObject = props.infoObject;
  const buttons = props.buttons && props.buttons.map((btnId) => createButtonData(btnId));

  const { color, commonButton, modal } = themeContext.theme;

  const styles = StyleSheet.create({
    show: {
      display: 'block',
      zIndex: ABOVE_ALL,
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    modalMain: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '320px',
      maxWidth: '98vw',
      maxHeight: '98vh',
      backgroundColor: modalDragging ? modal.default.background : modal.active.background,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: modalDragging ? modal.active.border : 'transparent',
      opacity: modalDragging ? '75%' : '100%',
      boxShadow: 'rgba(0, 0, 0, 0.33) 0px 2px 8px',
      alignItems: 'center',
      transition: 'border background .2s',
      padding: '.2rem',
    },
    titleContainer: {
      width: '100%',
      minHeight: '1.5rem',
      padding: '.5rem 1rem',
      color: color.grey.dark4,
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    messageContainer: {
      padding: '1.5rem 1rem',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    buttonsContainer: {
      padding: '.5rem .5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    button: {
      padding: '.5rem 1.2rem',
      marginRight: '.5rem',
      borderRadius: 4,
      display: 'block',
      backgroundColor: commonButton.default.background,
      color: commonButton.default.color,
      ':hover': {
        backgroundColor: commonButton.hover.background,
        color: commonButton.hover.color,
      },
      ':focus': {
        backgroundColor: commonButton.focus.background,
        color: commonButton.focus.color,
      },
      ':active': {
        backgroundColor: commonButton.active.background,
        color: commonButton.active.color,
      },
      transition: 'background .2s',
    },
  });

  const titleText = props.title || '';

  const handleButtonClick = (buttonId) => {
    const {
      removeModal,
      nodeId,
      operationID,
      processContext,
      processEventsAfterNotice,
      removeTreeNode,
      removeFromWindowStack,
      setApiLoading,
      tabId,
      refreshFocusElement,
      toggleModalEscapePressed,
      transactionLineLabel,
    } = props;
    const clickedButtonId = buttonId;
    addValueTest('CLICK', buttonId, '');

    if (buttonId === BUTTON_MORE_INFO) {
      const infoObject = props.infoObject;
      if (infoObject) {
        setShowInfo(true);
      }

      setTimeout(() => props.refreshFocusElement(), 100);
      return;
    }
    setApiLoading(true, tabId);
    store.setState({ validationFailed: false });

    removeTreeNode(transactionLineLabel, nodeId);
    removeFromWindowStack(transactionLineLabel);
    toggleModalEscapePressed();

    if (props.modalMessage) {
      removeModal();
    }

    processEventsAfterNotice().then(async () => {
      noticeDecided(operationID, clickedButtonId).then(async (response) => {
        processContext(response, 'Notice Decided', tabId)
          .then(async () => {
            refreshFocusElement();
          })
          .finally(() => {
            setApiLoading(false, tabId);
          });
      });
    });
    if (props.modalMessage && props.modalMessage.eventHandler) {
      props.modalMessage.eventHandler(clickedButtonId);
    }
  };

  /**
   * if there are no buttons to show, hide the modal when pressing Escape.
   * otherwise, indicate in the store that Escape was pressed in the modal.
   * @param {Object} event the event of keydown
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      addLineEventTest(event);
      /* if (!props.lineNumber || props.lineNumber === 0) {
        // si tiene lineNumber el Modal viene de un ConfirmDialog, y ConfirmDialog necesita hacer algun tratamiento antes de borrarlo
        props.flagModalForRemoval();
      } else {
        // si no tiene lineNumber, el Modal no viene del servidor, es agregado por un flujo del web y se remueve directamente
        props.removeModal();
      } */
      if (!buttons || !buttons.length) {
      } else {
        props.toggleModalEscapePressed();
        const cancelButton = buttons.filter((button) => button.text === 'Cancelar' || button.text === 'Cancel');
        if (cancelButton && cancelButton[0]) {
          handleButtonClick(cancelButton[0].buttonId);
        }
      }
      setTimeout(() => props.refreshFocusElement(), 100);
    }
  };

  const { currentApp } = props;
  if (source !== 'GLOBAL' && source !== currentApp) {
    return null;
  }
  return (
    <FocusLock persistentFocus returnFocus>
      <div className={css(styles.modal, styles.show)} onKeyDown={handleKeyDown}>
        <div className={css(styles.modalMain)}>
          <Draggable handle=".drag-section" bounds={{ top: -200, right: 350, bottom: 200, left: -350 }}>
            <div className={`${css(styles.modalContent)}`}>
              <div
                className={`drag-section ${css(styles.titleContainer)}`}
                onMouseDown={() => setModalDragging(true)}
                onMouseUp={() => setModalDragging(false)}
                id={'modalTitleText_' + getTabIdFromLineIDString(props.transactionLineLabel)}
              >
                {titleText}
              </div>
              <div id="modalText" className={css(styles.messageContainer)}>
                {props.message}
              </div>
              <div className={css(styles.buttonsContainer)}>
                {(!buttons || !buttons.length) && (
                  <Button id="defaultModalButton" autoFocus={true} handleClick={() => handleButtonClick()} buttonText="OK" />
                )}
                {buttons &&
                  buttons.length > 0 &&
                  buttons.map((b) => (
                    <Button
                      id={b.buttonId}
                      key={b.buttonId}
                      autoFocus={b.autoFocus}
                      handleClick={(id) => handleButtonClick(id)}
                      buttonText={b.text.toUpperCase()}
                    />
                  ))}
              </div>
            </div>
          </Draggable>
          {showInfo ? (
            <div>
              <InfoTable infoObject={infoObject} setShowInfo={setShowInfo} nodeId={props.nodeId}></InfoTable>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </FocusLock>
  );
};

const mapStateToProps = (state) => {
  const { operationID, operationNumber, focusElement, focusPaused, focusOn, apiLoading, tabID: tabIndex } = getCurrentTabInformation(state);

  const { lastValues } = state;
  const tabId = state.tabs[tabIndex].id;

  return {
    operationID,
    operationNumber,
    focusElement,
    focusPaused,
    lastValues,
    focusOn,
    apiLoading,
    tabId,
  };
};

export default connect(mapStateToProps, storeActions)(Modal);
