import React, { useEffect, useState } from 'react';
import { getAllTransacciones } from '../../../api/schema.js';
import { useIntl } from 'react-intl';

export const HelpTransaccionComponent = (props) => {
  const intl = useIntl();

  const [transaction, setTransaction] = useState([]);
  const [selectedValue, setSelectedValue] = useState(props.currentValue);

  useEffect(() => {
    getAllTransacciones().then(function (response) {
      setTransaction(response);
    });
  }, [selectedValue]);

  useEffect(() => {
    if (props.forceSelectHelp) {
      handleWindowSelect(selectedValue);
    }
  });

  const handleClick = (option) => {
    setSelectedValue(option);
  };

  const handleDoubleClick = (value) => {
    handleWindowSelect(value);
  };

  const handleWindowSelect = (value) => {
    props.setHelpValue(value);
  };
  return (
    <div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow-md sm:rounded-lg">
              <div className="row" style={{ overflowY: 'scroll', maxHeight: '200px', minHeight: '100px', width: '400px', marginTop: '5px' }}>
                <table className="default" style={{ fontSize: '12px' }}>
                  <thead>
                    <tr>
                      <th scope="col">
                        {intl.formatMessage({
                          id: 'transaction',
                          defaultMessage: 'Transaccion',
                        })}
                      </th>
                      <th scope="col">
                        {intl.formatMessage({
                          id: 'name',
                          defaultMessage: 'Nombre',
                        })}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction.map((option, index) => {
                      return (
                        <tr
                          style={
                            selectedValue && option.identificacion === selectedValue[0]
                              ? { backgroundColor: '#9ABAF5' }
                              : { backgroundColor: 'white' }
                          }
                          key={index}
                          onClick={() => handleClick([option.identificacion, option.descripcion])}
                          onDoubleClick={() => handleDoubleClick([option.identificacion, option.descripcion])}
                          className={index % 2 === 0 ? 'table-active' : ''}
                        >
                          <td>{option.identificacion}</td>
                          <td>{option.descripcion}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
