import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';

export let getRolesDescription = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/workflow/getRolesDescription?sessionId=${session}`).then(({ data }) => data);
};

export let getThinTaskList = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/workflow/getThinTaskList?sessionId=${session}`).then(({ data }) => data);
};

export let getProcessDefinitionsInfo = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/workflow/getProcessDefinitionsInfo?sessionId=${session}`).then(({ data }) => data);
};

export let getProcessInstancesData = (bottomIndex, stateName, processDefinitionName, onlyRunning) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/workflow/getProcessInstancesData?sessionId=${session}&bottomIndex=${bottomIndex}&stateName=${stateName}&processDefinitionName=${processDefinitionName}&onlyRunning=${onlyRunning}`,
      { headers },
    )
    .then(({ data }) => data);
};

export let getUsers = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/workflow/getUsers?sessionId=${session}`).then(({ data }) => data);
};

export let getInitialParameters = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/workflow/getInitialParameters?sessionId=${session}`).then(({ data }) => data);
};

export let getGroups = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/workflow/getGroups?sessionId=${session}`).then(({ data }) => data);
};

export let getProcessInstancesDataWithBody = (bottomIndex, stateName, processDefinitionName, onlyRunning, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/workflow/getProcessInstancesData?sessionId=${session}&bottomIndex=${bottomIndex}&stateName=${stateName}&processDefinitionName=${processDefinitionName}&onlyRunning=${onlyRunning}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let couldReassign = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/workflow/couldReassign?sessionId=${session}`).then(({ data }) => data);
};

export let couldPartialReassign = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/workflow/couldPartialReassign?sessionId=${session}`).then(({ data }) => data);
};

export let loadTopazTaskInfos = (searchFieldValues, bottomIndex, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/workflow/loadTopazTaskInfos?sessionId=${session}&searchFieldValues=${searchFieldValues}&bottomIndex=${bottomIndex}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let reassignTaskWithState = (body, actualUser, usersAndGroups) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/workflow/reassignTaskWithSate?sessionId=${session}&usuariosYGrupos=${usersAndGroups}&actualUser=${actualUser}`,
      body,
    )
    .then(({ data }) => data);
};

export let getProcessInstanceHistory = (processInstanceId, showMappedVariablesOnly, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/workflow/getProcessInstanceHistory?sessionId=${session}&processInstanceId=${processInstanceId}&showMappedVariablesOnly=${showMappedVariablesOnly}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let startProcessInstance = (processDefinitionName) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(`${API}TopazWebClientWS/resources/workflow/startProcessInstance?sessionId=${session}&processDefinitionName=${processDefinitionName}`)
    .then(({ data }) => data);
};

export let getInstanceImage = (taskId) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };

  return axios
    .get(`${API}TopazWebClientWS/resources/workflow/getInstanceImage?sessionId=${session}&processInstanceId=${taskId}`, null, headers)
    .then(({ data }) => data);
};
