import { store } from '../../store';

export let addLineEventTest = (e, idParam) => {
  let recordOn = store.getState().recordOn;
  if (recordOn) {
    if (e.key === 'Tab' && e.shiftKey) {
      addLineTest('ESC');
    } else if (
      e.key === 'Esc' ||
      e.key === 'Tab' ||
      e.key === 'Enter' ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight' ||
      e.key === 'Escape' ||
      e.key === 'F1'
    ) {
      addLineTest(e.key.toUpperCase(), e, idParam);
    }
  }
};

export let addWorkFlowValue = (command, id, value) => {
  let recordOn = store.getState().recordOn;
  if (recordOn) {
    let textOperation = JSON.parse(window.sessionStorage.getItem('textOperation'));
    if (textOperation) {
      if (command === 'SENDTEXT') {
        let lastElement = textOperation[textOperation.length - 1];
        let indexCommand = lastElement.indexOf('SENDTEXT');
        if (id === getId(lastElement) && indexCommand > 0) {
          textOperation.pop();
        }
        textOperation.push('CMD:SENDTEXT ID:' + id + ' VALOR:' + value + '\n');
      }

      textOperation = duplicateElementControl(textOperation);
      window.sessionStorage.setItem('textOperation', JSON.stringify(textOperation));
    }
  }
};

export let addValueTest = (command, id, value, allowDuplicates) => {
  let recordOn = store.getState().recordOn;
  if (recordOn) {
    let textOperation = JSON.parse(window.sessionStorage.getItem('textOperation'));
    if (textOperation) {
      if (command === 'SELECT') {
        textOperation.push('CMD:SELECT ID:' + id + ' VALOR:' + value + '\n');
      }
      if (command === 'SELECTVALUE') {
        textOperation.push('CMD:SELECTVALUE ID:' + id + ' VALOR:' + value + '\n');
      }
      if (command === 'SENDTEXT') {
        textOperation.push('CMD:SENDTEXT ID:' + id + ' VALOR:' + value + '\n');
      }
      if (command === 'CLICK') {
        textOperation.push('CMD:CLICK ID:' + id + '\n');
      }
      if (command === 'D_CLICK') {
        textOperation.push('CMD:D_CLICK ID:' + id + '\n');
      }
      if (command === 'R_CLICK') {
        textOperation.push('CMD:R_CLICK ID:' + id + '\n');
      }
      if (command === 'ENTER') {
        textOperation.push('CMD:ENTER ID:' + id + '\n');
      }
      if (!allowDuplicates) {
        textOperation = duplicateElementControl(textOperation);
      }

      window.sessionStorage.setItem('textOperation', JSON.stringify(textOperation));
    }
  }
};

export let addBlurTest = (command, e) => {
  let recordOn = store.getState().recordOn;
  if (recordOn) {
    let textOperation = JSON.parse(window.sessionStorage.getItem('textOperation'));
    if (textOperation) {
      let id = '';
      if (e.target && e.target.id) {
        id = e.target.id;
      }
      if (command === 'SENDTEXT') {
        let lastElement = textOperation.pop();
        if (!isHelp(lastElement, id)) {
          let idLastElement = getId(lastElement);
          if (id === idLastElement) {
            textOperation.push('CMD:SENDTEXT ID:' + id + ' VALOR:' + e.target.defaultValue + '\n');
            textOperation = duplicateElementControl(textOperation);
            textOperation.push(lastElement);
          } else {
            let prevlastElement = textOperation.pop();
            let idPrevLastElement = getId(prevlastElement);
            if (id === idPrevLastElement && idLastElement.length === 1) {
              textOperation.push('CMD:SENDTEXT ID:' + id + ' VALOR:' + e.target.defaultValue + '\n');
              textOperation.push(prevlastElement);
              textOperation.push(lastElement);
            } else {
              textOperation.push(prevlastElement);
              textOperation.push(lastElement);
              textOperation.push('CMD:SENDTEXT ID:' + id + ' VALOR:' + e.target.defaultValue + '\n');
            }
          }
        } else {
          textOperation.push(lastElement);
        }
        window.sessionStorage.setItem('textOperation', JSON.stringify(textOperation));
      }
    }
  }
};

export let addLineTest = (command, e, idParam) => {
  let recordOn = store.getState().recordOn;
  if (recordOn) {
    let textOperation = JSON.parse(window.sessionStorage.getItem('textOperation'));
    if (textOperation) {
      let id = '';
      if (idParam !== undefined) {
        id = idParam;
      } else if (e.target && e.target.id) {
        id = e.target.id;
      }

      if (command === 'SELECT') {
        textOperation.push('CMD:SELECT ID:' + id + ' VALOR:' + e.target.value + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'SELECTVALUE') {
        textOperation.push('CMD:SELECTVALUE ID:' + id + ' VALOR:' + e.target.value + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'ENTER') {
        textOperation.push('CMD:ENTER ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'CLICK') {
        textOperation.push('CMD:CLICK ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'SENDTEXT') {
        textOperation.push('CMD:SENDTEXT ID:' + id + ' VALOR:' + e.target.value + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'TAB') {
        textOperation.push('CMD:TAB ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'ESC' || command === 'ESCAPE') {
        textOperation.push('CMD:ESC ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'ENTER_GRID') {
        textOperation.push('CMD:ENTER_GRID ID:' + id + '\n');
      }
      if (command === 'TABGRID') {
        textOperation.push('CMD:TABGRID ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'F1') {
        textOperation.push('CMD:F1 ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'R_CLICK') {
        textOperation.push('CMD:R_CLICK ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'D_CLICK') {
        textOperation.push('CMD:D_CLICK ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'ARROWDOWN') {
        textOperation.push('CMD:ARROW_DOWN ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'ARROWUP') {
        textOperation.push('CMD:ARROW_UP ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'ARROWLEFT') {
        textOperation.push('CMD:ARROW_LEFT ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      if (command === 'ARROWRIGHT') {
        textOperation.push('CMD:ARROW_RIGHT ID:' + id + '\n');
        textOperation = duplicateElementControl(textOperation);
      }
      window.sessionStorage.setItem('textOperation', JSON.stringify(textOperation));
    }
  }
};

export const duplicateElementControl = (textOperation) => {
  if (textOperation && textOperation.length > 1) {
    let length = textOperation.length;
    if (textOperation[length - 1] === textOperation[length - 2]) {
      if (getId(textOperation[length - 1]).length > 1) {
        textOperation.pop();
      }
    }
  }

  return textOperation;
};

const isHelp = (line, id) => {
  let indexP = id.indexOf('P');
  let idValue = id.substring(0, indexP);

  if (line.indexOf(idValue) === -1) {
    return false;
  }

  let indexR = line.indexOf('_R');
  let indexC = line.indexOf('_C');
  if (indexR > -1 && indexC > -1) {
    return true;
  }

  let indexButton = line.indexOf('helpWindowAcceptButton');
  if (indexButton > -1) {
    return true;
  }

  return false;
};

export const getId = (line) => {
  let index = line.indexOf('ID:');
  let value = line.substring(index + 3);
  let endIndex = value.indexOf(' ');
  let id = '';
  if (endIndex === -1) {
    id = value.replace('\n', '');
  } else {
    id = value.substring(0, endIndex);
  }
  return id;
};

export const getElement = (textOperation, findText) => {
  if (textOperation && textOperation.length > 1) {
    for (let i = textOperation.length; i > 0; i--) {
      if (textOperation[i - 1].indexOf(findText) > -1) {
        return textOperation[i - 1];
      }
    }
  }

  return '';
};

export const replaceElement = (textOperation, findText, text) => {
  if (textOperation && textOperation.length > 1) {
    for (let i = textOperation.length; i > 0; i--) {
      if (textOperation[i - 1].indexOf(findText) > -1) {
        textOperation[i - 1] = text;
        return textOperation;
      }
    }
  }

  return textOperation;
};
