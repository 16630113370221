import React, { useState, useEffect, useRef } from 'react';
import FocusTrap from 'react-focus-trap';
import { connect } from 'unistore/react';
import Overlay from '../../Overlay';
import ReactTable from 'react-table';
import Draggable from 'react-draggable';
import { FormattedMessage } from 'react-intl';
import { ABOVE_ALL } from '../../../constants/zIndexValues';
import IcoMoon from 'react-icomoon';
import CheckImages from './CheckImages';
import MaskedInput from 'react-text-mask';
import {
  getCheckScanner,
  addParameter,
  getParameter,
  initializeApplication,
  getGridDefinition,
  scan,
  validateChecks,
  executeFunction,
  getChecks,
  getDrippedCheckImages,
  deleteChecks,
  getCapturedFrontImage,
  getCapturedRearImage,
  getCapturedMICR,
  cancel,
  insertDrippedChecks,
  insertChecks,
} from '../../../../src/api/checkScanner';
import { getSessionInfo } from '../../../../src/api/session';
import { omit } from 'lodash';
import { store, storeActions, dispatchActions } from '../../../../src/store';
import ModalMessage from '../../../../src/components/ModalMessage';
import { fileArrayToBase64 } from '../../../../UI-kit/helpers/fileHelper';
import dateFormat from 'dateformat';

const Button = ({ title, click, className, disabled = false }) => {
  return (
    <button
      className={`${className} ${disabled ? 'bg-lightgrey' : 'bg-grey'} ${
        disabled ? 'text-black' : 'text-white'
      } rounded shadow m-2 py-2 px-4 text-sm ${disabled ? '' : 'focus:bg-primary hover:bg-primary focus:outline-none'}`}
      onClick={click}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

const SUCURSAL = 'sucursal';
const MONEDA = 'moneda';
const GRIDCONFIGURATION = 'gridconfiguration';
const TOTAL = 'total';
const CANTIDAD_DE_CHEQUES = 'cantidaddecheques';
const POSTING = 'posting';

const ScannerWindowView = (props) => {
  const selectedCellColor = '#dcdcff';
  const defaultCellColor = 'white';
  const timeout = useRef(null);
  let gridStructureName = useRef('gridstructure');
  let lastEditable = useRef(null);
  let parameters = useRef([]);
  let rowsRef = useRef([]);
  let fullRowsRef = useRef([]);
  let totalizedValueRef = useRef(0);
  let bandaField = useRef(null);
  let shouldFocus = useRef(false);
  let shouldFocusField = useRef(null);
  let documentsRef = useRef([]);
  let columnTotalizeRef = useRef(null);
  let [showModal, setShowModal] = useState(false);
  let [showModal2, setShowModal2] = useState(false);
  let msgError = useRef('');
  let [showWindow, setShowWindow] = useState(true);
  let tabId = useRef('');
  let properties = useRef([]);
  let selectedIndex = useRef(-1);
  const [columns, setColumns] = useState([]);
  const [fullColumns, setFullColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [fullRows, setFullRows] = useState([]);
  const [error, setError] = useState('');
  const [scannerTurnedOn, setScannerTurnedOn] = useState(false);
  const [changedTabs, setChangedTabs] = useState(false);
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  let cantidadCheques = useRef(0);
  let totalCheques = useRef(0);

  useEffect(() => setError(''), []);

  useEffect(() => {
    const { data } = props;
    let splittedData = data.split('|');
    let params = [];
    for (const i in splittedData) {
      let split = splittedData[i];
      if (
        split.includes(SUCURSAL) ||
        split.includes(MONEDA) ||
        split.includes(GRIDCONFIGURATION) ||
        split.includes(TOTAL) ||
        split.includes(CANTIDAD_DE_CHEQUES) ||
        split.includes(POSTING)
      ) {
        if (split.includes(GRIDCONFIGURATION)) {
          gridStructureName.current = split.split('=')[1];
        }
        params.push(split.split('=')[1]);

        let valuePair = split.split('=');
        if (valuePair.length === 2) {
          if (valuePair[0] === CANTIDAD_DE_CHEQUES) {
            cantidadCheques.current = parseInt(valuePair[1]);
          } else if (valuePair[0] === TOTAL) {
            totalCheques.current = floatToCurrency(parseFloat(valuePair[1]));
          }
        }
      } else {
        if (i > 0 && split !== '') {
          params.push(split);
        }
      }
    }
    setup(params);
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
        cancel();
        setScannerTurnedOn(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTabIndex = () => {
    let state = store.getState();
    let tmpTabIndex = 0;
    if (tabId.current !== '') {
      tmpTabIndex = state.tabs.filter((tab) => {
        return tab.id === tabId.current;
      });
      if (tmpTabIndex.length > 0) {
        tmpTabIndex = state.tabs.indexOf(tmpTabIndex[0]);
      } else {
        tmpTabIndex = 0;
      }
    } else {
      tmpTabIndex = state.current;
    }

    return tmpTabIndex;
  };

  const setup = async (params) => {
    let state = store.getState();
    state.tabs[state.current].running.selectedRows = [];
    state.tabs[state.current].running.selectedIds = [];
    state.tabs[state.current].running.editableFields = [];
    state.tabs[state.current].running.lastId = 0;
    tabId.current = state.tabs[state.current].id;
    store.setState(state);

    addParameter(params).then(() => {
      getParameter(gridStructureName.current).then(() => {
        let gridConfig = gridStructureName.current;
        initialize(gridConfig).then(() => {
          getGridDefinition().then((gridDefinition) => {
            properties.current = gridDefinition.property;
            let shownIndex = 0;
            let ordenBanda = getOrdenBandaFromProperties();
            gridDefinition.column.forEach((col, index) => {
              let title = col._title;
              let totalize = col._has_totalize && col._totalize;
              let titleWithoutSpaces = title.replace(/ +/g, '');
              let titleWithoutSpacesAndPeriods = titleWithoutSpaces.replace(/\./g, '');
              let parsedTitle = titleWithoutSpacesAndPeriods === '' ? 'id' : titleWithoutSpacesAndPeriods;
              if (totalize) {
                columnTotalizeRef.current = parsedTitle;
              }

              let column = getColumn(col, index, shownIndex);
              let fullColumn = getFullColumn(col, index);
              let stateColumns = columns;
              let stateFullColumns = fullColumns;

              if (fullColumn.visible) {
                stateColumns.push(column);
                shownIndex++;
              }
              stateFullColumns.push(fullColumn);

              setColumns(stateColumns.slice());
              setFullColumns(stateFullColumns.slice());
              if (col.order === ordenBanda) {
                bandaField.current = parsedTitle;
              }
              parameters = params.slice();
            });
            loadChecks().then(() => {
              getSessionInfo().then((response) => {
                let { machineNumber } = response;
                if (scannerTurnedOn === true) {
                  handleTurnOnScanner();
                }
                getCheckScanner(machineNumber).then((scannerInfo) => {});
              });
            });
          });
        });
      });
    });
  };

  const getOrdenBandaFromProperties = () => {
    let ordenBanda;
    if (properties.current) {
      ordenBanda = properties.current.find((e) => e.name === 'ordenBanda');
      if (ordenBanda) {
        ordenBanda = ordenBanda.value;
      }
    }
    return parseInt(ordenBanda);
  };

  const getFullColumn = (col, index) => {
    let constantValue = col._constantValue;
    let titleWithoutSpaces = col._title.replace(/ +/g, '');
    let titleWithoutSpacesAndPeriods = titleWithoutSpaces.replace(/\./g, '');
    if (col._has_totalize && col._totalize) {
      columnTotalizeRef.current = titleWithoutSpacesAndPeriods === '' ? 'id' : titleWithoutSpacesAndPeriods;
      constantValue = 0;
    }
    return {
      accessor: titleWithoutSpacesAndPeriods === '' ? 'id' : titleWithoutSpacesAndPeriods,
      order: col._has_order && col._order,
      title: col._title,
      sizeControl: col._has_sizeControl && col._sizeControl,
      aline: col._aline,
      edit: col._has_edit && col._edit,
      type: col._type || '',
      visible: col._has_visible && col._visible,
      decimal: col._has_decimal && col._decimal,
      initialPosition: col._has_initialPosition && col._initialPosition,
      endPosition: col._has_endPosition && col._endPosition,
      sizeData: col._has_sizeData && col._sizeData,
      field: col._has_field && col._field,
      constantValue: constantValue,
      totalize: col._has_totalize && col._totalize,
      function: col._function,
      format: col._formatt,
      focus: col._has_focus && col._focus,
      key: col._has_key && col._key,
      parameter: col._has_parameter ? col._parameter : 0,
      deleteKey: col._has_deleteKey && col._deleteKey,
      updateImageKey: col._has_updateImageKey && col._updateImageKey,
      reloadAll: col._has_reloadAll && col._reloadAll,
      validatorsToUse: col._validatorsToUse,
      functionsToUse: col._functionsToUse,
      returnColumn: col._has_returnColumn && col.returnColumn,
      filter: col._has_filter && col._filter,
      originalColumn: col,
    };
  };

  const getColumn = (col, index, shownIndex) => {
    let title = col._title;
    let sizeControl = col._has_sizeControl && col._sizeControl;
    let edit = col._has_edit && col._edit;
    let type = col._type;
    let visible = col._has_visible && col._visible;
    let initialPosition = col._has_initialPosition && col._initialPosition;
    let endPosition = col._has_endPosition && col._endPosition;
    let sizeData = col._has_sizeData && col._sizeData;
    let field = col._has_field && col._field;
    let constantValue = col._constantValue;
    let totalize = col._has_totalize && col._totalize;
    let format = col._formatt;
    let titleWithoutSpaces = title.replace(/ +/g, '');
    let titleWithoutSpacesAndPeriods = titleWithoutSpaces.replace(/\./g, '');
    let parsedTitle = titleWithoutSpacesAndPeriods === '' ? 'id' : titleWithoutSpacesAndPeriods;
    if (totalize) {
      columnTotalizeRef.current = parsedTitle;
      constantValue = 0;
    }
    let column;

    if (visible) {
      if (edit && type !== 'D') {
        if (type === 'F') {
          column = {
            accessor: parsedTitle,
            Header: title,
            width: sizeControl * 10,
            field: field,
            type: 'F',
            Cell: renderEditableDate,
            count: index,
            shownIndex: shownIndex,
            constantValue: constantValue,
            initialPosition: initialPosition,
            endPosition: endPosition,
            sizeData: sizeData,
            sortable: false,
          };
        } else {
          column = {
            accessor: parsedTitle,
            Header: title,
            width: sizeControl * 10,
            field: field,
            Cell: renderEditable,
            count: index,
            shownIndex: shownIndex,
            constantValue: constantValue,
            initialPosition: initialPosition,
            endPosition: endPosition,
            sizeData: sizeData,
            sortable: false,
          };
        }
        lastEditable = title;
      } else if (type === 'D') {
        column = {
          accessor: parsedTitle,
          Header: title,
          width: sizeControl * 10,
          field: field,
          Cell: renderCheckBox,
          count: index,
          shownIndex: shownIndex,
          constantValue: constantValue,
          initialPosition: initialPosition,
          endPosition: endPosition,
          sizeData: sizeData,
          sortable: false,
        };
      } else if (format) {
        column = {
          accessor: parsedTitle,
          Header: title,
          width: sizeControl * 10,
          field: field,
          count: index,
          shownIndex: shownIndex,
          constantValue: constantValue,
          initialPosition: initialPosition,
          endPosition: endPosition,
          sizeData: sizeData,
          sortable: false,
          // eslint-disable-next-line react/display-name
          Cell: (props) => <div id={`${getTabIndex()}_${props.column.shownIndex}_${props.index}`}>{props.value.toFixed()}</div>,
        };
      } else {
        column = {
          accessor: parsedTitle,
          Header: title,
          width: sizeControl * 10,
          field: field,
          count: index,
          shownIndex: shownIndex,
          // eslint-disable-next-line react/display-name
          Cell: (props) => (
            <div onKeyDown={(e) => handleArrowKeys(e, props)} id={`${getTabIndex()}_${props.column.shownIndex}_${props.index}`}>
              {props.value}
            </div>
          ),
          constantValue: constantValue,
          initialPosition: initialPosition,
          endPosition: endPosition,
          sizeData: sizeData,
          sortable: false,
        };
      }
      shownIndex++;
    }

    return column;
  };

  const handleArrowKeys = (e, props) => {
    let idToFocus;
    let elementToFocus;
    switch (e.key) {
      case 'ArrowUp':
        idToFocus = `${getTabIndex()}_${props.column.shownIndex}_${props.index - 1}`;
        elementToFocus = document.getElementById(idToFocus);
        elementToFocus.focus();
        break;
      case 'ArrowDown':
        idToFocus = `${getTabIndex()}_${props.column.shownIndex}_${props.index + 1}`;
        elementToFocus = document.getElementById(idToFocus);
        elementToFocus.focus();
        break;
      case 'ArrowLeft':
        e.preventDefault();
        idToFocus = `${getTabIndex()}_${props.column.shownIndex - 1}_${props.index}`;
        elementToFocus = document.getElementById(idToFocus);
        elementToFocus.focus();
        break;
      case 'ArrowRight':
        e.preventDefault();
        idToFocus = `${getTabIndex()}_${props.column.shownIndex + 1}_${props.index}`;
        elementToFocus = document.getElementById(idToFocus);
        elementToFocus.focus();
        break;
      default:
        e.preventDefault();
        break;
    }
  };
  const loadCheckImages = (index) => {
    let row = fullRows.filter((r) => {
      return r.id === index;
    });
    row = row[0];
    let propertiesToSend = {};
    let rowItemSet = [];
    let rowKeyItemSet = [];
    let rowDeleteKeyItemSet = [];
    let rowUpdateKeyItemSet = [];
    let banda = null;
    properties.current.forEach((property) => {
      propertiesToSend[property.name] = property.value;
    });
    fullColumns.forEach((fullColumn) => {
      if (fullColumn.accessor !== 'id') {
        if (fullColumn.accessor === bandaField.current) {
          banda = row[fullColumn.accessor];
        }
        let item = {
          value: row[fullColumn.accessor],
          field: fullColumn.field,
        };
        if (fullColumn.key) {
          rowKeyItemSet.push(item);
        }
        if (fullColumn.deleteKey) {
          rowDeleteKeyItemSet.push(item);
        }
        if (fullColumn.updateImageKey) {
          rowUpdateKeyItemSet.push(item);
        }
        rowItemSet.push(item);
      }
    });
    const checkSet = {
      rowItemSet: rowItemSet,
      rowKeyItemSet: rowKeyItemSet,
      rowDeleteKeyItemSet: rowDeleteKeyItemSet,
      rowUpdateKeyItemSet: rowUpdateKeyItemSet,
      jts_oid: 0,
      bandamagnetica: banda,
    };
    const dataToSend = {
      properties: propertiesToSend,
      checkSet: checkSet,
    };
    getDrippedCheckImages(dataToSend).then((data) => {
      let addCheck = true;
      documentsRef.current.forEach((doc) => {
        if (doc.micr === data.banda) {
          addCheck = false;
        }
      });
      if (addCheck) {
        let newDocument = {
          index: parseInt(index),
          f1Img: 'data:image/png;base64, ' + data.imgAnv || undefined,
          f2Img: undefined,
          r1Img: 'data:image/png;base64, ' + data.imgRev || undefined,
          r2Img: undefined,
          micr: data.banda,
          s1Img: 'data:image/png;base64, ' + data.imgSnipped_1 || undefined,
          s2Img: 'data:image/png;base64, ' + data.imgSnipped_2 || undefined,
          s3Img: 'data:image/png;base64, ' + data.imgSnipped_3 || undefined,
        };
        // documents.push(newDocument);
        documentsRef.current.push(newDocument);
        documentsRef.current.slice();
        // setDocuments(documents.slice());
      }
    });
  };

  const loadChecks = async () => {
    let rowItemSet = [];
    let rowKeyItemSet = [];
    let rowDeleteKeyItemSet = [];
    let rowUpdateKeyItemSet = [];
    for (const fullColumn of fullColumns) {
      getValueFromAPI(fullColumn, null).then((valToSet) => {
        let item;
        if (fullColumn.parameter > 0) {
          item = {
            value: valToSet,
            field: fullColumn.field,
          };
        } else {
          item = {
            field: fullColumn.field,
          };
        }
        if (fullColumn.key) {
          rowKeyItemSet.push(item);
        }
        if (fullColumn.updateImageKey) {
          rowUpdateKeyItemSet.push(item);
        }
        if (fullColumn.deleteKey) {
          rowDeleteKeyItemSet.push(item);
        }
        rowItemSet.push(item);
        if (fullColumn.order === fullColumns.length) {
          let checkSet = {
            rowItemSet: rowItemSet,
            rowKeyItemSet: rowKeyItemSet,
            rowDeleteKeyItemSet: rowDeleteKeyItemSet,
            rowUpdateKeyItemSet: rowUpdateKeyItemSet,
            jts_oid: 0,
            bandamagnetica: '',
          };
          getChecks(checkSet).then((data) => {
            let state = store.getState();
            let tmpLastId = state.tabs[getTabIndex()].running.lastId++;
            data.forEach((check) => {
              addRows(check.rowItemSet, true, tmpLastId);
              tmpLastId++;
            });
            state.tabs[getTabIndex()].running.lastId = tmpLastId;
            store.setState(state);
          });
        }
      });
    }
  };
  const loop = async (scannerInfo) => {
    let state = store.getState();
    if (state.current === getTabIndex()) {
      setChangedTabs(false);
      let joinImagesMode = getJoinImagesModeFromProperties();
      scan().then(async () => {
        const gotValue = await getValues();
        if (!gotValue && joinImagesMode) {
          timeout.current = setTimeout(() => loop(scannerInfo), 50);
        }
      });
      if (!joinImagesMode) {
        timeout.current = setTimeout(() => loop(scannerInfo), 50);
      }
    } else {
      cancel();
      setChangedTabs(true);
      clearTimeout(timeout.current);
      timeout.current = null;
      setScannerTurnedOn(true);
    }
  };

  const validateRow = async (index) => {
    let fullRow = fullRowsRef.current[index];
    let rowItemSet = [];
    let rowKeyItemSet = [];
    let rowDeleteKeyItemSet = [];
    let rowUpdateKeyItemSet = [];
    let columnIndex = 0;
    for (let cell in fullRow) {
      if (Object.prototype.hasOwnProperty.call(fullRow, cell)) {
        if (cell !== 'undefined' && cell !== 'id') {
          let set = {
            value: fullRow[cell],
            field: fullColumns[columnIndex].field,
          };
          if (fullColumns[columnIndex].key === true) {
            rowKeyItemSet.push(set);
          } else {
            rowItemSet.push(set);
          }
          if (fullColumns[columnIndex].deleteKey === true) {
            rowDeleteKeyItemSet.push(set);
          }
          if (fullColumns[columnIndex].updateImageKey === true) {
            rowUpdateKeyItemSet.push(set);
          }
        }
      }
      columnIndex++;
    }
    let value = 0;
    fullRowsRef.current.forEach((fullRow) => {
      if (fullRow[columnTotalizeRef.current] && fullRow[columnTotalizeRef.current].replace) {
        let valCurrent = fullRow[columnTotalizeRef.current];
        if (!isNaN(valCurrent) && valCurrent.indexOf(',') === -1) {
          valCurrent = valCurrent.toString().replaceAll('.', ',');
        }
        value = currencyToFloat(valCurrent) + value;
      }
    });
    totalizedValueRef.current = floatToCurrency(value);
    let checkToValidate = {
      rowItemSet: rowItemSet,
      rowKeyItemSet: rowKeyItemSet,
      rowDeleteKeyItemSet: rowDeleteKeyItemSet,
      rowUpdateKeyItemSet: rowUpdateKeyItemSet,
      jts_oid: 0,
      bandamagnetica: fullRow[bandaField.current],
    };
    if (fullRow[bandaField.current] && fullRow[bandaField.current].length > 0) {
      let ret = await validateChecks(checkToValidate);
      if (ret !== 'OK') {
        msgError.current = ret;
        setShowModal2(true);
      }
    }
  };

  const setUpExecuteFunction = async (column, row) => {
    let columnToSend = omit(column.originalColumn, [
      'field',
      'key',
      'type',
      'valid',
      'parameter',
      'visible',
      'filter',
      'order',
      'title',
      'functionsToUse',
      'initialPosition',
      'validatorsToUse',
      'updateImageKey',
      'constantValue',
      'function',
      'aline',
      'decimal',
      'deleteKey',
      'endPosition',
      'formatt',
      'edit',
      'returnColumn',
      'reloadAll',
      'totalize',
      'focus',
      'sizeControl',
      'sizeData',
    ]);
    let rowItemSet = [];
    let rowKeyItemSet = [];
    let rowDeleteKeyItemSet = [];
    let rowUpdateKeyItemSet = [];
    let columnIndex = 0;
    for (let cell in row) {
      if (Object.prototype.hasOwnProperty.call(row, cell)) {
        if (cell !== 'undefined' && cell !== 'id') {
          let valueForSet = row[cell];
          if (valueForSet) {
            valueForSet = valueForSet.toString();
          }
          let set = {
            value: valueForSet,
            field: fullColumns[columnIndex].field,
          };
          if (fullColumns[columnIndex].key === true) {
            rowKeyItemSet.push(set);
          } else {
            rowItemSet.push(set);
          }
          if (fullColumns[columnIndex].deleteKey === true) {
            rowDeleteKeyItemSet.push(set);
          }
          if (fullColumns[columnIndex].updateImageKey === true) {
            rowUpdateKeyItemSet.push(set);
          }
        }
      }
      columnIndex++;
    }
    if (row && row[bandaField.current]) {
      let checkSet = {
        rowItemSet: rowItemSet,
        rowKeyItemSet: rowKeyItemSet,
        rowDeleteKeyItemSet: rowDeleteKeyItemSet,
        rowUpdateKeyItemSet: rowUpdateKeyItemSet,
        jts_oid: 0,
        bandamagnetica: row[bandaField.current],
      };
      let objectToSend = {
        column: columnToSend,
        checkSet: checkSet,
      };
      let response = await executeFunction(objectToSend);
      if (response.error && response.message && response.message.length > 0) {
        msgError.current = response.message;
        setShowModal2(true);
        return '';
      } else {
        return response;
      }
    }
  };

  const getValueFromAPI = async (fullColumn, fullRow) => {
    let result;
    if (fullColumn.function !== null || fullColumn.functionsToUse !== null) {
      setUpExecuteFunction(fullColumn, fullRow).then((data) => {
        result = data;
        return result;
      });
    } else if (fullColumn.parameter && fullColumn.parameter > 0) {
      let parameter = parameters[fullColumn.parameter - 1];
      result = parameter;
      return result;
    }
  };

  const getValue = (column, banda, fullColumn, valueFromTable) => {
    if (fullColumn.constantValue !== undefined && fullColumn.constantValue !== null) {
      return fullColumn.constantValue;
    } else if (banda && banda.trim() === '') {
      return '';
    } else if (fullColumn.initialPosition === 0 && fullColumn.endPosition === 0 && fullColumn.sizeData === 0) {
      return '';
    } else if ((fullColumn.initialPosition !== 0 || fullColumn.endPosition !== 0) && banda) {
      return banda.slice(fullColumn.initialPosition, fullColumn.endPosition);
    } else if (banda && banda.length <= fullColumn.endPosition) {
      if (column.accessor === bandaField.current && banda) {
        return banda;
      } else {
        return '';
      }
    } else {
      return valueFromTable;
    }
  };

  const createDataToRemove = (fullRow) => {
    let propertiesToSend = {};
    let rowItemSet = [];
    let rowKeyItemSet = [];
    let rowDeleteKeyItemSet = [];
    let rowUpdateKeyItemSet = [];
    let banda = null;
    properties.current.forEach((property) => {
      propertiesToSend[property.name] = property.value;
    });
    fullColumns.forEach((fullColumn) => {
      if (fullColumn.accessor !== 'id') {
        if (fullColumn.accessor === bandaField.current) {
          banda = fullRow[fullColumn.accessor];
        }
        let valueToAssign = fullRow[fullColumn.accessor];
        if (fullColumn.type === 'N') {
          valueToAssign = parseFloat(valueToAssign);
        }
        let item = {
          value: valueToAssign,
          field: fullColumn.field,
        };
        if (fullColumn.key) {
          rowKeyItemSet.push(item);
        }
        if (fullColumn.deleteKey) {
          rowDeleteKeyItemSet.push(item);
        }
        if (fullColumn.updateImageKey) {
          rowUpdateKeyItemSet.push(item);
        }
        rowItemSet.push(item);
      }
    });
    const checkSet = {
      rowItemSet: rowItemSet,
      rowKeyItemSet: rowKeyItemSet,
      rowDeleteKeyItemSet: rowDeleteKeyItemSet,
      rowUpdateKeyItemSet: rowUpdateKeyItemSet,
      jts_oid: 0,
      bandamagnetica: banda,
      imgAnv: null,
      imgRev: null,
      imgSnipped_1: null,
      imgSnipped_2: null,
      imgSnipped_3: null,
    };
    const dataToSend = {
      properties: propertiesToSend,
      checkSet: checkSet,
    };

    return dataToSend;
  };

  const removeRows = () => {
    let newRows = rowsRef.current;
    let newFullRows = fullRowsRef.current;
    let newDocuments = documentsRef.current;
    let checkSetsToDelete = [];
    let rowsToTotalize = [];
    let state = store.getState();
    let selectedIds = state.tabs[getTabIndex()].running.selectedIds ? state.tabs[getTabIndex()].running.selectedIds : [];

    selectedIds.forEach((rowId) => {
      let checkSet = createDataToRemove(newFullRows[rowId]);
      rowsToTotalize.push(newFullRows[rowId]);
      checkSetsToDelete.push(checkSet);
      for (let idx = 0; idx < state.tabs[getTabIndex()].running.editableFields.length; idx++) {
        let field = state.tabs[getTabIndex()].running.editableFields[idx];
        if (field.substr(field.lastIndexOf('_') + 1, field.length - field.lastIndexOf('_')) === rowId.toString()) {
          state.tabs[getTabIndex()].running.editableFields.splice(idx, 1);
          idx--;
        }
      }
      let valueRestar = 0;
      rowsToTotalize.forEach((fullRow) => {
        if (fullRow[columnTotalizeRef.current] && fullRow[columnTotalizeRef.current].replace) {
          let valCurrent = fullRow[columnTotalizeRef.current];
          if (!isNaN(valCurrent) && valCurrent.indexOf(',') === -1) {
            valCurrent = valCurrent.toString().replaceAll('.', ',');
          }
          valueRestar = currencyToFloat(valCurrent) + valueRestar;
        }
      });

      let value = 0;
      fullRowsRef.current.forEach((fullRow) => {
        if (fullRow[columnTotalizeRef.current] && fullRow[columnTotalizeRef.current].replace) {
          let valCurrent = fullRow[columnTotalizeRef.current];
          if (!isNaN(valCurrent) && valCurrent.indexOf(',') === -1) {
            valCurrent = valCurrent.toString().replaceAll('.', ',');
          }
          value = currencyToFloat(valCurrent) + value;
        }
      });
      totalizedValueRef.current = floatToCurrency(value - valueRestar);
    });

    selectedIds.forEach((rowId) => {
      newRows = newRows.filter((obj) => {
        return obj.id.toString() !== rowId.toString();
      });
      newFullRows = newFullRows.filter((obj) => {
        return obj.id.toString() !== rowId.toString();
      });
    });

    selectedIds.forEach((docId) => {
      newDocuments = newDocuments.filter((obj) => {
        if (obj.documentId !== undefined) {
          return obj.documentId.toString() !== docId.toString();
        } else {
          return obj.index && obj.index.toString() !== docId.toString();
        }
      });
    });

    let id = 0;
    newRows.forEach((newRow) => {
      newRow.id = id.toString();
      id++;
    });

    id = 0;
    newFullRows.forEach((newFullRow) => {
      newFullRow.id = id.toString();
      id++;
    });

    let idForDocs = 0;
    newDocuments.forEach((newDocument) => {
      newDocument.documentId = idForDocs;
      idForDocs++;
    });

    let newRowsAux = [];
    let newFullRowsAux = [];
    newRows.forEach((row) => {
      newRowsAux.push(row);
    });
    newFullRows.forEach((row) => {
      newFullRowsAux.push(row);
    });
    checkSetsToDelete.forEach((object) => {
      deleteChecks(object);
    });
    state.tabs[getTabIndex()].running.lastId = id;

    rowsRef.current = newRowsAux;
    fullRowsRef.current = newFullRowsAux;
    setRows(newRows);
    setFullRows(newFullRows);
    documentsRef.current = newDocuments;

    state.tabs[getTabIndex()].running.selectedIds = [];
    state.tabs[getTabIndex()].running.selectedRows = [];
    store.setState(state);
  };

  const currencyToFloat = (strCurrency) => {
    let value = parseFloat(strCurrency.toString().replaceAll('.', '').replaceAll(',', '.'));
    return value;
  };

  const floatToCurrency = (float) => {
    let formatter = new Intl.NumberFormat('es-AR', {
      // en-US
      style: 'currency',
      currency: 'USD',
    });
    let formatted = formatter.format(Number.parseFloat(float)).replace('US$', '').trim();
    return formatted;
  };

  const performExecuteFunctions = (fullColumns, row, fullRow) => {
    let counter = 0;
    let banda = row[bandaField.current];
    columns.forEach((column, index) => {
      let fullColumnIndex = fullColumns.indexOf(fullColumns.find((e) => e.accessor === column.accessor));
      if (fullColumns[fullColumnIndex].accessor === bandaField.current) {
        banda = validateDigitsBanda(banda, fullColumns[fullColumnIndex].sizeData);
        row[bandaField.current] = banda;
        fullRow[bandaField.current] = banda;
      } else if (
        column.accessor !== 'id' &&
        column.accessor !== bandaField.current &&
        (!row[column.accessor] ||
          fullColumns[fullColumnIndex].edit === false ||
          (fullColumns[fullColumnIndex].edit === true &&
            (fullColumns[fullColumnIndex].initialPosition !== null || fullColumns[fullColumnIndex].endPosition !== null)))
      ) {
        if (
          (fullColumns[fullColumnIndex].function !== null ||
            fullColumns[fullColumnIndex].parameter > 0 ||
            fullColumns[fullColumnIndex].functionsToUse !== null) &&
          (fullColumns[fullColumnIndex].function !== null || fullColumns[fullColumnIndex].functionsToUse !== null)
        ) {
          counter++;
          setUpExecuteFunction(fullColumns[fullColumnIndex], fullRow).then((data) => {
            counter--;
            row[column.accessor] = data;
            if (counter === 0) {
              setFullColumns(fullColumns.slice());
            }
          });
        } else if (
          fullColumns[fullColumnIndex].edit === false ||
          (fullColumns[fullColumnIndex].edit === true && (fullColumns[fullColumnIndex].initialPosition || fullColumns[fullColumnIndex].endPosition))
        ) {
          let valueToAssign = getValue(fullColumns[fullColumnIndex], banda, fullColumns[fullColumnIndex]);
          if (valueToAssign !== undefined) {
            row[column.accessor] = valueToAssign;
          }
        }
      }
    });
    setFullColumns(fullColumns.slice());
  };

  const addRows = (doc, isRegistered = false, tmpLastId = null) => {
    const row = {};
    const fullRow = {};
    let state = store.getState();
    if (isRegistered) {
      columns.forEach((column, index) => {
        let value;
        let columnCount = column.count;
        if (column.accessor === 'id') {
          if (tmpLastId != null) {
            value = tmpLastId;
          } else {
            value = state.tabs[getTabIndex()].running.lastId.toString();
          }
          row[column.accessor] = value;
        } else if (column.field === doc[columnCount].field) {
          if (column.type) {
            value = dateFormat(new Date(doc[columnCount].value), 'dd/mm/yyyy');
          } else {
            value = doc[columnCount].value;
          }
          row[column.accessor] = value;
        }
      });

      fullColumns.forEach((fullColumn, index) => {
        let value;
        if (fullColumn.accessor === 'id') {
          if (tmpLastId != null) {
            value = tmpLastId;
          } else {
            value = state.tabs[getTabIndex()].running.lastId.toString();
          }
        } else if (fullColumn.type === 'F') {
          value = dateFormat(new Date(doc[index].value), 'dd/mm/yyyy');
        } else if (fullColumn.type === 'N') {
          if (doc[index].value) {
            value = currencyToFloat(doc[index].value);
            if (fullColumn.decimal) {
              value = doc[index].value;
              value = value.toFixed(fullColumn.decimal);
              value = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            }
          } else {
            value = doc[index].value;
          }
        } else {
          value = doc[index].value;
        }
        fullRow[fullColumn.accessor] = value;
      });

      fullRows.push(fullRow);
      setFullRows(fullRows.slice());
      rows.push(row);
      rowsRef.current = rows;
      fullRowsRef.current = fullRows;
      setRows(rows.slice());

      let value = 0;
      fullRowsRef.current.forEach((fullRow) => {
        if (fullRow[columnTotalizeRef.current]) {
          let valCurrent = fullRow[columnTotalizeRef.current];
          if (!isNaN(valCurrent) && valCurrent.indexOf(',') === -1) {
            valCurrent = valCurrent.toString().replaceAll('.', ',');
          }
          value = currencyToFloat(valCurrent) + value;
        }
      });
      totalizedValueRef.current = floatToCurrency(value);
      // eslint-disable-next-line no-undef
      let clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      let counter = 0;
      let condition = true;
      while (condition) {
        let elementToClick = document.getElementById(`${getTabIndex()}_1_${counter}`);
        if (elementToClick !== null) {
          elementToClick.dispatchEvent(clickEvent);
          counter++;
        } else {
          condition = false;
        }
      }
    } else {
      columns.forEach((column, index) => {
        if (column.accessor === 'id') {
          if (state.tabs[getTabIndex()].running.lastId === 0) {
            row[column.accessor] = doc.documentId;
            state.tabs[getTabIndex()].running.lastId = doc.documentId;
          } else {
            // eslint-disable-next-line standard/computed-property-even-spacing
            row[column.accessor] = state.tabs[getTabIndex()].running.lastId.toString();
          }
        } else {
          if (doc.micr) {
            const banda = doc.micr.slice(0, doc.micr.length);
            row[column.accessor] = getValue(column, banda, fullColumns[column.count]);
          }
        }
      });

      fullColumns.forEach((fullColumn) => {
        if (fullColumn.accessor === 'id') {
          if (state.tabs[getTabIndex()].running.lastId === 0) {
            fullRow[fullColumn.accessor] = doc.documentId;
            state.tabs[getTabIndex()].running.lastId = doc.documentId;
          } else {
            // eslint-disable-next-line standard/computed-property-even-spacing
            fullRow[fullColumn.accessor] = state.tabs[getTabIndex()].running.lastId.toString();
          }
        } else {
          if (doc.micr) {
            const banda = doc.micr.slice(0, doc.micr.length);
            fullRow[fullColumn.accessor] = getValue(fullColumn, banda, fullColumn);
          }
        }
      });
      let bandaFound = fullRows.find((e) => e[bandaField.current] === doc.micr);
      if (row[bandaField.current] !== '' && !bandaFound) {
        let newRowsRef = rowsRef.current;
        newRowsRef.push(row);
        let newFullRowsRef = fullRowsRef.current;
        newFullRowsRef.push(fullRow);
        fullRows.push(fullRow);
        setFullRows(newFullRowsRef.slice());
        rows.push(row);
        setRows(newRowsRef.slice());
      }
    }

    performExecuteFunctions(fullColumns, row, fullRow);
    performExecuteFunctions(fullColumns, fullRow, fullRow);
    store.setState(state);
  };

  const initialize = async (gridConfiguration) => {
    await initializeApplication(gridConfiguration);
  };

  const selectRow = (index) => {
    selectedIndex.current = index;
    forceUpdate();
  };

  const handleTurnOffScanner = async () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
      cancel();
      setScannerTurnedOn(false);
    }
  };

  const handleNewLine = async () => {
    const row = {};
    if (
      (rowsRef.current.length > 0 &&
        rowsRef.current[rowsRef.current.length - 1][bandaField.current] &&
        rowsRef.current[rowsRef.current.length - 1][bandaField.current].length > 0) ||
      rowsRef.current.length === 0
    ) {
      let state = store.getState();
      columns.forEach((column, index) => {
        if (column.accessor === 'id') {
          let idToSet = state.tabs[getTabIndex()].running.lastId !== null ? state.tabs[getTabIndex()].running.lastId.toString() : '0';
          row[column.accessor] = idToSet;
        } else {
          row[column.accessor] = undefined;
        }
      });

      const fullRow = {};
      fullColumns.forEach((fullColumn) => {
        if (fullColumn.accessor === 'id') {
          // eslint-disable-next-line standard/computed-property-even-spacing
          fullRow[fullColumn.accessor] = state.tabs[getTabIndex()].running.lastId.toString();
        } else {
          fullRow[fullColumn.accessor] = undefined;
        }
      });
      state.tabs[getTabIndex()].running.lastId++;
      setFullRows((fullRows) => [...fullRows, fullRow]);

      rowsRef.current.push(row);
      fullRowsRef.current.push(fullRow);
      setRows((rows) => [...rows, row]);

      store.setState(state);
      return row;
    } else if ((rowsRef.current.length > 0 && rowsRef.current[rowsRef.current.length - 1]) || rowsRef.current.length === 0) {
      return rowsRef.current[rowsRef.current.length - 1];
    }
  };

  const handleTurnOnScanner = async () => {
    if (!timeout.current) {
      getSessionInfo().then((response) => {
        scan().then(() => {
          setScannerTurnedOn(true);
          loop();
        });
      });
    }
  };

  const renderEditableDate = (cellInfo) => {
    let idToAssign = `${getTabIndex()}_${cellInfo.column.shownIndex}_${cellInfo.index}`;
    let state = store.getState();
    if (!state.tabs[getTabIndex()].running.editableFields.includes(idToAssign)) {
      state.tabs[getTabIndex()].running.editableFields.push(idToAssign);
      if (shouldFocus.current === true) {
        if (shouldFocusField.current !== null) {
          if (document.getElementById(shouldFocusField.current) !== null) {
            document.getElementById(shouldFocusField.current).focus();
            shouldFocus.current = false;
            shouldFocusField.current = null;
          }
        } else {
          shouldFocusField.current = idToAssign;
        }
      }
      store.setState(state);
    }

    return (
      <MaskedInput
        id={idToAssign}
        type="text"
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        contentEditable
        style={{ minHeight: '15px', background: cellInfo.index === selectedIndex.current ? selectedCellColor : defaultCellColor }}
        suppressContentEditableWarning
        onKeyDown={(e) => {
          if (e.target.textContent.length === 8 && e.key !== 'Tab') {
            e.preventDefault();
          }
        }}
        className="bg-grey-lighter-lighter"
        onBlur={(e) => {
          const data = { ...rowsRef.current };
          const fullData = { ...fullRowsRef.current };
          const accessor = cellInfo.column.id;
          let parsedDate = '';
          if (!e.target.value.includes('/')) {
            for (let i = 0; i < e.target.value.length; i++) {
              parsedDate += e.target.value[i];
              if (i === 1 || i === 3) {
                parsedDate += '/';
              }
            }
          } else {
            parsedDate = e.target.value;
          }
          data[cellInfo.index][accessor] = parsedDate;
          fullData[cellInfo.index][accessor] = parsedDate;
          updateRow2(data[cellInfo.index], fullData[cellInfo.index]);
          if (cellInfo.column.Header === lastEditable) {
            validateRow(cellInfo.index);
          }
        }}
        value={rows[cellInfo.index] && rows[cellInfo.index][cellInfo.column.id]}
      />
    );
  };

  const validateDigitsBanda = (banda, maxlength) => {
    const validDigitProp = properties.current.filter((prop) => prop.name === 'validdigits');
    const validDigitsArray = validDigitProp && validDigitProp[0] ? validDigitProp[0].value.split('') : null;

    if (!validDigitsArray) {
      return banda.replace(/\D/g, '').slice(0, maxlength);
    } else {
      return banda
        .split('')
        .filter((char) => validDigitsArray.includes(char))
        .join('')
        .slice(0, maxlength);

      // for (let idx = 0; idx < banda.length; idx++) {}
    }
  };

  const updateRow2 = (row, fullRow, assignDefaultValue, accesorForDefaultValue, valueFromTable = undefined, fieldAccesor) => {
    let index = 0;
    let banda = row[bandaField.current];

    for (const fullColumn of fullColumns) {
      if (fullColumn.accessor !== 'id') {
        if (
          fullRow[fullColumn.accessor] !== undefined &&
          fullColumn.type === 'N' &&
          fullColumn.edit === true &&
          !fullColumn.initialPosition &&
          !fullColumn.endPosition
        ) {
          let valToParse = fullRow[fullColumn.accessor].toString();
          if (!isNaN(valToParse) && valToParse.indexOf(',') === -1) {
            valToParse = valToParse.toString().replaceAll('.', ',');
          }
          valToParse = currencyToFloat(valToParse).toString();
          if (fullColumn.decimal) {
            if (valToParse.indexOf(',') !== -1) {
              valToParse = valToParse.replaceAll('.', '');
              valToParse = valToParse.replaceAll(',', '.');
            }
            valToParse = valToParse.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            valToParse = floatToCurrency(valToParse);
          } else {
            valToParse = valToParse.toString();
          }
          fullRow[fullColumn.accessor] = valToParse;
        } else if (fullColumn.accessor === bandaField.current) {
          fullRow[bandaField.current] = banda = validateDigitsBanda(banda, fullColumn.sizeData);
        } else if (
          !fullRow[fullColumn.accessor] ||
          fullColumn.edit === false ||
          (fullColumn.edit === true && (fullColumn.endPosition !== null || fullColumn.initialPosition !== null))
        ) {
          if (fullColumns[index].function !== null || fullColumns[index].parameter > 0 || fullColumns[index].functionsToUse !== null) {
            if (fullColumns[index].function !== null || fullColumns[index].functionsToUse !== null) {
              setUpExecuteFunction(fullColumns[index], fullRow).then((data) => {
                fullRow[fullColumn.accessor] = data;
              });
            } else {
              let parameter = parameters[fullColumns[index].parameter - 1];
              fullRow[fullColumn.accessor] = parameter;
            }
          } else if (assignDefaultValue && accesorForDefaultValue && fullColumn.accessor === accesorForDefaultValue) {
            fullRow[fullColumn.accessor] = '0';
          } else {
            if (fullColumn.accessor === fieldAccesor) {
              fullRow[fullColumn.accessor] = getValue(fullColumn, banda, fullColumns[index], valueFromTable);
            } else {
              let valueToAssign = getValue(fullColumn, banda, fullColumns[index]);
              if (valueToAssign !== undefined) {
                fullRow[fullColumn.accessor] = valueToAssign;
              }
            }
          }
        }
      }
      index++;
    }
    index = 0;
    for (const column of columns) {
      if (column.accessor === bandaField.current) {
        row[bandaField.current] = banda = validateDigitsBanda(banda, column.sizeData);
      } else if (column.accessor !== 'id' && column.accessor !== bandaField.current) {
        let fullColumnIndex = fullColumns.indexOf(fullColumns.find((e) => e.accessor === column.accessor));
        if (
          row[column.accessor] !== undefined &&
          fullColumns[fullColumnIndex].type === 'N' &&
          fullColumns[fullColumnIndex].edit === true &&
          !fullColumns[fullColumnIndex].initialPosition &&
          !fullColumns[fullColumnIndex].endPosition
        ) {
          let valToParse = row[column.accessor].toString();
          if (!isNaN(valToParse) && valToParse.indexOf(',') === -1) {
            valToParse = valToParse.toString().replaceAll('.', ',');
          }
          valToParse = currencyToFloat(valToParse).toString();
          if (fullColumns[fullColumnIndex].decimal) {
            if (valToParse.indexOf(',') !== -1) {
              valToParse = valToParse.replaceAll('.', '');
              valToParse = valToParse.replaceAll(',', '.');
            }
            valToParse = valToParse.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            valToParse = floatToCurrency(valToParse);
          } else {
            valToParse = valToParse.toString();
          }
          row[column.accessor] = valToParse;
        } else if (
          !row[column.accessor] ||
          fullColumns[fullColumnIndex].edit === false ||
          (fullColumns[fullColumnIndex].edit === true &&
            (fullColumns[fullColumnIndex].initialPosition !== null || fullColumns[fullColumnIndex].endPosition !== null))
        ) {
          if (
            fullColumns[fullColumnIndex].function !== null ||
            fullColumns[fullColumnIndex].parameter > 0 ||
            fullColumns[fullColumnIndex].functionsToUse !== null
          ) {
            if (fullColumns[fullColumnIndex].function !== null || fullColumns[fullColumnIndex].functionsToUse !== null) {
              setUpExecuteFunction(fullColumns[fullColumnIndex], fullRow).then((data) => {
                row[column.accessor] = data;
              });
            } else {
              let parameter = parameters[fullColumns[fullColumnIndex].parameter - 1];
              row[column.accessor] = parameter;
            }
          } else if (assignDefaultValue && accesorForDefaultValue && column.accessor === accesorForDefaultValue) {
            row[column.accessor] = '0';
          } else {
            if (column.accessor === fieldAccesor) {
              row[column.accessor] = getValue(column, banda, columns[index], valueFromTable);
            } else {
              let valueToAssign = getValue(column, banda, columns[index]);
              if (valueToAssign !== undefined) {
                row[column.accessor] = valueToAssign;
              }
            }
          }
        }
      }
      index++;
    }
    let nfr = fullRowsRef.current.map((old) => {
      if ((old[bandaField.current] && old[bandaField.current].trim().slice(0, 29) !== fullRow[bandaField.current]) || old.id !== fullRow.id) {
        return old;
      } else {
        return fullRow;
      }
    });
    let nr = rowsRef.current.map((old) => {
      if ((old[bandaField.current] && old[bandaField.current].slice(0, 29) !== row[bandaField.current]) || old.id !== row.id) {
        return old;
      } else {
        return row;
      }
    });
    rowsRef.current = nr;
    fullRowsRef.current = nfr;
    setFullRows((oldFullRows) => {
      const newFullRows = oldFullRows.map((old) => {
        if (old.id !== fullRow.id) {
          return old;
        } else {
          return fullRow;
        }
      });
      return newFullRows;
    });
    setRows((oldRows) => {
      const newRows = oldRows.map((old) => {
        if (old.id !== row.id) {
          return old;
        } else {
          return row;
        }
      });
      return newRows;
    });
  };

  const createCheckset = (fullRow) => {
    let propertiesToSend = {};
    let rowItemSet = [];
    let rowKeyItemSet = [];
    let rowDeleteKeyItemSet = [];
    let rowUpdateKeyItemSet = [];
    let banda = null;
    properties.current.forEach((property) => {
      propertiesToSend[property.name] = property.value;
    });
    fullColumns.forEach((fullColumn) => {
      if (fullColumn.accessor !== 'id') {
        if (fullColumn.accessor === bandaField.current) {
          banda = fullRow[fullColumn.accessor];
        }
        if (banda !== null && banda !== '') {
          let valueToAssign = fullRow[fullColumn.accessor];
          if (fullColumn.type === 'N') {
            if (fullColumn.decimal) {
              if (isNaN(valueToAssign)) {
                valueToAssign = currencyToFloat(valueToAssign);
              } else {
                valueToAssign = parseFloat(valueToAssign);
              }
            } else {
              if (valueToAssign) {
                valueToAssign = currencyToFloat(valueToAssign);
              }
              valueToAssign = parseFloat(valueToAssign);
            }
          }

          let item = {
            value: valueToAssign,
            field: fullColumn.field,
          };
          if (fullColumn.key) {
            rowKeyItemSet.push(item);
          }
          if (fullColumn.deleteKey) {
            rowDeleteKeyItemSet.push(item);
          }
          if (fullColumn.updateImageKey) {
            rowUpdateKeyItemSet.push(item);
          }
          rowItemSet.push(item);
        }
      }
    });
    if (rowItemSet.length === 0) {
      return;
    }
    const checkSet = {
      rowItemSet: rowItemSet,
      rowKeyItemSet: rowKeyItemSet,
      rowDeleteKeyItemSet: rowDeleteKeyItemSet,
      rowUpdateKeyItemSet: rowUpdateKeyItemSet,
      jts_oid: 0,
      bandamagnetica: banda,
      imgAnv: null,
      imgRev: null,
      imgSnipped_1: null,
      imgSnipped_2: null,
      imgSnipped_3: null,
    };
    const dataToSend = {
      properties: propertiesToSend,
      checkSet: checkSet,
    };
    let frontImage = null;
    let rearImage = null;
    documentsRef.current.forEach((doc) => {
      if (
        ((doc.documentId || doc.documentId === 0) && doc.documentId.toString() === fullRow.id.toString()) ||
        ((doc.index || doc.index === 0) && doc.index.toString() === fullRow.id.toString())
      ) {
        if (doc.f1Img !== null) {
          if (doc.r1Img !== null) {
            frontImage = doc.f1ba;
            rearImage = doc.r1ba;
          }
        }
      }
    });
    if (frontImage !== null && rearImage !== null) {
      checkSet.imgAnv = frontImage;
      checkSet.imgRev = rearImage;
    } else {
      getDrippedCheckImages(dataToSend).then((data) => {
        let addCheck = true;
        documentsRef.current.forEach((doc) => {
          if (doc.micr === data.banda) {
            addCheck = false;
          }
        });
        if (addCheck) {
          let newDocument = {
            f1Img: data.imgAnv || null,
            f2Img: null,
            r1Img: data.imgRev || null,
            r2Img: null,
            micr: data.banda,
            s1Img: data.imgSnipped_1 || null,
            s2Img: data.imgSnipped_2 || null,
            s3Img: data.imgSnipped_3 || null,
          };
          checkSet.imgAnv = newDocument.f1Img;
          checkSet.imgRev = newDocument.r1Img;
          checkSet.imgSnipped_1 = newDocument.s1Img;
          checkSet.imgSnipped_2 = newDocument.s2Img;
          checkSet.imgSnipped_3 = newDocument.s3Img;

          documentsRef.current.push(newDocument);
          documentsRef.current.slice();
        }
      });
    }

    return checkSet;
  };

  const handleAccept = async () => {
    msgError.current = '';
    if (totalCheques.current && totalizedValueRef.current !== totalCheques.current) {
      msgError.current = (
        <FormattedMessage id="totalNotEqual" defaultMessage={`El total de cheques es inválido. El total debe ser de $ ${totalCheques.current}`} />
      );
      setShowModal2(true);
      return;
    }

    for (let row of fullRowsRef.current) {
      await validateRow(fullRowsRef.current.indexOf(row)).then();
    }
    if (msgError.current === '') {
      let checkSetList = [];
      let errorOccurred = false;
      for (let fullRow of fullRowsRef.current) {
        if (fullRow[bandaField.current] !== undefined) {
          let checkSet = createCheckset(fullRow);
          if (checkSet !== undefined) {
            checkSetList.push(checkSet);
          }
        }
      }
      for (let checkSet of checkSetList) {
        if (checkSet.imgAnv && checkSet.imgAnv.length > 35) {
          let ret = await insertDrippedChecks(checkSet);
          if (ret && ret.returnCode !== 200) {
            msgError.current = ret.message;
            setShowModal2(true);
            errorOccurred = true;
          }
        }
      }
      if (!errorOccurred) {
        let checkSetListNoImage = checkSetList.filter((checkSet) => {
          return !checkSet.imgAnv || checkSet.imgAnv.length <= 35;
        });
        let ret = await insertChecks(checkSetListNoImage);
        if (ret && ret.returnCode !== 200) {
          msgError.current = ret.message;
          setShowModal2(true);
        } else {
          props.removeComponentTree(props.removeTreeNode);
          setShowWindow(false);
          cancel();
          setScannerTurnedOn(false);
        }
      }
    }
  };

  const renderEditable = (cellInfo) => {
    let value;
    if (rowsRef.current[cellInfo.index] !== undefined) {
      value =
        rowsRef.current[cellInfo.index][cellInfo.column.id] !== undefined
          ? rowsRef.current[cellInfo.index][cellInfo.column.id]
          : cellInfo.row[cellInfo.column.Header];
    }
    let idToAssign = `${getTabIndex()}_${cellInfo.column.shownIndex}_${cellInfo.index}`;
    let state = store.getState();
    if (!state.tabs[getTabIndex()].running.editableFields.includes(idToAssign)) {
      state.tabs[getTabIndex()].running.editableFields.push(idToAssign);
      if (shouldFocus.current === true) {
        if (shouldFocusField.current !== null) {
          if (document.getElementById(shouldFocusField.current) !== null) {
            document.getElementById(shouldFocusField.current).focus();
            shouldFocus.current = false;
            shouldFocusField.current = null;
          }
        } else {
          shouldFocusField.current = idToAssign;
        }
      }
      store.setState(state);
    }
    if (fullColumns[cellInfo.column.count].decimal && value) {
      if (typeof value === 'string') {
        value = floatToCurrency(currencyToFloat(value));
      } else {
        value = value.toFixed(fullColumns[cellInfo.column.count].decimal);
        value = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        value = floatToCurrency(value);
      }
    }

    const moveCursor = (targetId, cursorPosition) => {
      let node = document.getElementById(targetId).firstChild;
      let range = document.createRange();
      range.setStart(node, cursorPosition);
      range.setEnd(node, cursorPosition);
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    };

    return (
      <div
        contentEditable
        suppressContentEditableWarning
        id={idToAssign}
        style={{ minHeight: '15px' }}
        onMouseDown={(e) => {
          if (fullColumns[cellInfo.column.count].type === 'N') {
            if (e.currentTarget.textContent === '0' || e.currentTarget.textContent === '0,00') {
              e.currentTarget.textContent = '';
            }
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Tab' || e.key === 'Enter') {
            e.preventDefault();
            var res = idToAssign.split('_');
            let selectedRow = parseInt(res[2]);
            if (
              lastEditable === cellInfo.column.id &&
              !e.shiftKey &&
              rowsRef.current.length === selectedRow + 1 &&
              (!cantidadCheques.current || rowsRef.current.length < cantidadCheques.current)
            ) {
              handleNewLine().then((row) => {
                let fieldToFocus = document.getElementById(getTabIndex() + '_1_' + parseInt(row.id).toString());
                if (fieldToFocus) {
                  fieldToFocus.focus();
                  let rowIdx = parseInt(
                    fieldToFocus.id.substr(fieldToFocus.id.lastIndexOf('_') + 1, fieldToFocus.id.length - fieldToFocus.id.lastIndexOf('_')),
                  );
                  selectRow(rowIdx);
                  fieldToFocus.focus();
                }
              });
            } else {
              let nextField = 1;
              if (e.shiftKey) {
                nextField = -1;
              }

              let editableFieldToFocus = state.tabs[getTabIndex()].running.editableFields.indexOf(idToAssign) + nextField;
              let fieldToFocus = document.getElementById(state.tabs[getTabIndex()].running.editableFields[editableFieldToFocus]);
              fieldToFocus.focus();
            }
          } else if (fullColumns[cellInfo.column.count].accessor === bandaField.current) {
            if (
              e.currentTarget.innerText.length >= fullColumns[cellInfo.column.count].sizeData &&
              e.key !== 'ArrowDown' &&
              e.key !== 'ArrowUp' &&
              e.key !== 'ArrowLeft' &&
              e.key !== 'ArrowRight' &&
              e.key !== 'Backspace' &&
              e.key !== 'Delete' &&
              e.key !== 'Control' &&
              !e.ctrlKey
            ) {
              if (window.getSelection().getRangeAt(0).startOffset >= fullColumns[cellInfo.column.count].sizeData) {
                e.preventDefault();
              } else {
                document.execCommand('forwardDelete', false, true);
              }
            }
          } else if (fullColumns[cellInfo.column.count].type === 'N') {
            // Ignorar estas teclas en el tratamiento, su efecto por defecto es el que se busca
            if (
              e.key !== 'Backspace' &&
              e.key !== 'Delete' &&
              e.key !== 'ArrowDown' &&
              e.key !== 'ArrowUp' &&
              e.key !== 'ArrowLeft' &&
              e.key !== 'ArrowRight' &&
              e.key !== 'Control' &&
              !e.ctrlKey
            ) {
              if (e.currentTarget.textContent === '0' || e.currentTarget.textContent === '0,00') {
                e.currentTarget.textContent = '';
              }
              let cursorPosition = window.getSelection().getRangeAt(0).startOffset;
              let preDecimalMaxLength = fullColumns[cellInfo.column.count].sizeData - fullColumns[cellInfo.column.count].decimal;
              let postDecimalMaxLength = fullColumns[cellInfo.column.count].decimal;
              let textLength = e.currentTarget.innerText.length;
              let preDecimalLength = e.currentTarget.innerText.replace(/\D/g, '').length;
              let decimalPosition = e.currentTarget.innerText.indexOf(',');
              let nanCharsLength = textLength;
              // Si el largo es diferente despues de haber sido stripeado de letras
              if (preDecimalLength !== textLength) {
                // Substraer los decimales para obtener el largo a validar vs sizeData
                preDecimalLength -= postDecimalMaxLength;
                nanCharsLength -= postDecimalMaxLength;
              }
              nanCharsLength -= preDecimalLength;

              // si . o ,
              if (e.keyCode === 110 || e.keyCode === 190) {
                e.preventDefault();
                let startOffset = window.getSelection().getRangeAt(0).startOffset;
                let firstPart = e.target.textContent.substr(0, startOffset);
                let secondPart =
                  e.target.textContent.length < startOffset + 1
                    ? ''
                    : e.target.textContent.substr(startOffset, e.target.textContent.length - startOffset);
                e.target.textContent = firstPart + ',' + secondPart;
                moveCursor(idToAssign, startOffset + 1);
              } else if (
                cursorPosition &&
                cursorPosition !== decimalPosition &&
                (cursorPosition - nanCharsLength >= preDecimalMaxLength + postDecimalMaxLength ||
                  (preDecimalLength === textLength && preDecimalLength >= preDecimalMaxLength))
              ) {
                // Si estoy escribiendo fuera de limites
                e.preventDefault();
                return;
              }

              // Si se escribe en una posicion que no es un numero, saltear posicion
              let modifiedChar = e.currentTarget.textContent[cursorPosition];
              if (cursorPosition && modifiedChar && isNaN(modifiedChar)) {
                cursorPosition++;
                moveCursor(e.currentTarget.id, cursorPosition);
              }

              // Si se esta escribiendo en posicion pre decimal y largo > sizeData o posicion post decimal y <
              if (
                (cursorPosition < decimalPosition && preDecimalLength >= preDecimalMaxLength) ||
                (cursorPosition > decimalPosition && cursorPosition < textLength)
              ) {
                document.execCommand('forwardDelete', false, true);
              }

              // Permitir ingreso de las siguientes teclas solamente
              if (
                !(
                  (e.keyCode >= 48 && e.keyCode <= 57) ||
                  (e.keyCode >= 96 && e.keyCode <= 105) ||
                  e.keyCode === 188 ||
                  e.keyCode === 190 ||
                  e.keyCode === 44 ||
                  e.key === 'Backspace' ||
                  e.key === 'Delete'
                )
              ) {
                e.preventDefault();
              }

              // No permitir ingreso de 0 si el campo esta vacio
              if (e.target.textContent === '' && (e.keyCode === 96 || e.keyCode === 48)) {
                e.preventDefault();
              }
            }
          }
        }}
        onBlur={(e) => {
          onRenderEditableOnBlur(cellInfo, e);
        }}
      >
        {value}
      </div>
    );
  };

  const getJoinImagesModeFromProperties = () => {
    let joinImagesMode;
    if (properties.current) {
      joinImagesMode = properties.current.find((e) => e.name === 'captureCMC7');
      if (joinImagesMode) {
        joinImagesMode = joinImagesMode.value === 'false';
      }
    }
    return joinImagesMode;
  };

  const getValues = async () => {
    let retVal = false;

    // get prop from gridstructure
    let joinImagesMode = getJoinImagesModeFromProperties();
    let micr;
    let frontImage;
    if (joinImagesMode) {
      // if captureCMC7 = false, get micr from selectedLine
      frontImage = await getCapturedFrontImage();
      if (!frontImage || !frontImage.data) {
        return retVal;
      } else {
        cancel();
        clearTimeout(timeout.current);
        timeout.current = null;
        retVal = true;
      }
      if (rows[selectedIndex.current]) {
        // get banda from selected row
        fullColumns.forEach((fullColumn) => {
          if (fullColumn.accessor !== 'id') {
            if (fullColumn.accessor === bandaField.current) {
              micr = { data: rows[selectedIndex.current][fullColumn.accessor] };
            }
          }
        });
      } else {
        // turn off scan if no selected row
        cancel();
        clearTimeout(timeout.current);
        timeout.current = null;

        msgError.current = <FormattedMessage id="noData" defaultMessage={`No hay cheques para asignar el resultado del escan.`} />;
        setShowModal2(true);
        return retVal;
      }
    } else {
      // else, get micr from endpoint
      micr = await getCapturedMICR();
      if (micr) {
        micr.data = validateDigitsBanda(micr.data);
        frontImage = await getCapturedFrontImage();
      }
    }

    // if micr.data and frontImage.data have a response
    if ((micr && micr.data !== '' && !joinImagesMode) || (frontImage && frontImage.data && joinImagesMode)) {
      let state = store.getState();
      let rearImage = await getCapturedRearImage();
      let doc;
      if (!joinImagesMode) {
        doc = documentsRef.current.find((d) => d.micr === micr.data);
      }

      if (joinImagesMode || doc) {
        // if captureCMC7 = false or found doc by micr.data, set endpoint images to selected row/same micr.data line
        if (joinImagesMode) {
          doc = documentsRef.current.find(
            (d) => parseInt(d.index) === parseInt(selectedIndex.current) || parseInt(d.documentId) === parseInt(selectedIndex.current),
          );
        }
        if (doc) {
          doc.f1Img = fileArrayToBase64(frontImage.data);
          doc.r1Img = fileArrayToBase64(rearImage.data);
          doc.f1ba = frontImage.data;
          doc.r1ba = rearImage.data;
          micr.data = doc.micr;
        }

        forceUpdate();
      }

      if ((!joinImagesMode && !doc) || (joinImagesMode && !doc)) {
        // else, create new row
        let documentId = parseInt(state.tabs[getTabIndex()].running.lastId);
        if (joinImagesMode) {
          documentId = parseInt(selectedIndex.current);
        }
        let document = {
          documentId,
          documentType: 0,
          documentLength: 0,
          f1Img: fileArrayToBase64(frontImage.data),
          f2Img: null,
          r1Img: fileArrayToBase64(rearImage.data),
          r2Img: null,
          micr: micr.data,
          s1Img: null,
          s2Img: null,
          s3Img: null,
          f1ba: frontImage.data,
          r1ba: rearImage.data,
        };
        let stateDocs = documentsRef.current;
        stateDocs.push(document);
        documentsRef.current = stateDocs;
        if (!joinImagesMode) {
          addRows(document);
          state.tabs[getTabIndex()].running.lastId++;
        }

        store.setState(state);
      }
    }

    return retVal;
  };

  const onRenderEditableOnBlur = (cellInfo, e) => {
    const data = rowsRef.current;
    const fullData = fullRowsRef.current;
    const accessor = cellInfo.column.id;
    data[cellInfo.index][accessor] = e.target.textContent;
    fullData[cellInfo.index][accessor] = e.target.textContent;
    cellInfo.row[accessor] = e.target.textContent;
    let assignDefaultValue = false;
    let accesorForDefaultValue = null;
    if (fullColumns[cellInfo.column.count].type === 'N' && e.target.textContent.length === 0) {
      data[cellInfo.index][accessor] = '0';
      fullData[cellInfo.index][accessor] = '0';
      assignDefaultValue = true;
      accesorForDefaultValue = accessor;
    }
    updateRow2(data[cellInfo.index], fullData[cellInfo.index], assignDefaultValue, accesorForDefaultValue, e.target.textContent, accessor);
    if (cellInfo.column.Header === lastEditable) {
      validateRow(cellInfo.index);
    }

    performExecuteFunctions(fullColumns, data[cellInfo.index], fullData[cellInfo.index]);
    performExecuteFunctions(fullColumns, fullData[cellInfo.index], fullData[cellInfo.index]);

    e.target.textContent = data[cellInfo.index][accessor];
    let idToAssign = `${getTabIndex()}_${cellInfo.column.shownIndex}_${cellInfo.index}`;
    let state = store.getState();
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        id={idToAssign}
        style={{ minHeight: '15px' }}
        onKeyDown={(e) => {
          if (e.key === 'Tab' || e.key === 'Enter') {
            e.preventDefault();
            if (
              lastEditable === cellInfo.column.id &&
              !e.shiftKey &&
              (!cantidadCheques.current || rowsRef.current.length < cantidadCheques.current)
            ) {
              handleNewLine().then((row) => {
                let fieldToFocus = document.getElementById(getTabIndex() + '_1_' + parseInt(row.id).toString());
                if (fieldToFocus) {
                  fieldToFocus.focus();
                  let rowIdx = parseInt(
                    fieldToFocus.id.substr(fieldToFocus.id.lastIndexOf('_') + 1, fieldToFocus.id.length - fieldToFocus.id.lastIndexOf('_')),
                  );
                  selectRow(rowIdx);
                  fieldToFocus.focus();
                }
              });
            } else {
              let nextField = 1;
              if (e.shiftKey) {
                nextField = -1;
              }
              let fieldToFocus = document.getElementById(
                // eslint-disable-next-line standard/computed-property-even-spacing
                state.tabs[getTabIndex()].running.editableFields[state.tabs[getTabIndex()].running.editableFields.indexOf(idToAssign) + nextField],
              );
              fieldToFocus.focus();
            }
          } else if (fullColumns[cellInfo.column.count].accessor === bandaField.current) {
            if (e.currentTarget.innerText.length >= fullColumns[cellInfo.column.count].sizeData) {
              if (e.currentTarget.selectionStart >= fullColumns[cellInfo.column.count].sizeData) {
                e.preventDefault();
              } else if (
                e.key !== 'Backspace' &&
                e.key !== 'Delete' &&
                e.key !== 'ArrowDown' &&
                e.key !== 'ArrowUp' &&
                e.key !== 'ArrowLeft' &&
                e.key !== 'ArrowRight' &&
                e.key !== 'Control' &&
                !e.ctrlKey
              ) {
                document.execCommand('forwardDelete', false, true);
              }
            }
          } else if (fullColumns[cellInfo.column.count].accessor === 'Importe') {
            let strippedStringLength = e.currentTarget.innerText.replace(/\D/g, '').length;
            if (strippedStringLength !== e.currentTarget.innerText.length) {
              // if length is different a format has already been applied, so subtract 2 for the 0's after decimal
              strippedStringLength -= 2;
            }
            if (strippedStringLength >= fullColumns[cellInfo.column.count].sizeData) {
              let cursorPosition = e.currentTarget.selectionStart;
              if (cursorPosition >= fullColumns[cellInfo.column.count].sizeData + e.currentTarget.innerText.length - strippedStringLength - 3) {
                e.preventDefault();
              } else if (
                e.key !== 'Backspace' &&
                e.key !== 'Delete' &&
                e.key !== 'ArrowDown' &&
                e.key !== 'ArrowUp' &&
                e.key !== 'ArrowLeft' &&
                e.key !== 'ArrowRight' &&
                e.key !== 'Control' &&
                !e.ctrlKey
              ) {
                let modifiedChar = e.currentTarget.textContent[cursorPosition];
                if (isNaN(modifiedChar)) {
                  cursorPosition++;
                  let node = document.getElementById(e.currentTarget.id).firstChild;
                  let range = document.createRange();
                  range.setStart(node, cursorPosition);
                  range.setEnd(node, cursorPosition);
                  let selection = window.getSelection();
                  selection.removeAllRanges();
                  selection.addRange(range);
                }
                document.execCommand('forwardDelete', false, true);
              }
            }
          } else if (fullColumns[cellInfo.column.count].type === 'N') {
            if (e.keyCode === 110) {
              e.keyCode = 44;
            }
            if (
              !(
                (e.keyCode >= 48 && e.keyCode <= 57) ||
                (e.keyCode >= 96 && e.keyCode <= 105) ||
                e.keyCode === 188 ||
                e.keyCode === 190 ||
                e.keyCode === 44 ||
                e.key === 'Backspace'
              )
            ) {
              e.preventDefault();
            }
            if (e.target.textContent === '' && (e.keyCode === 96 || e.keyCode === 48)) {
              e.preventDefault();
            }
          }
        }}
        onBlur={(e) => {
          onRenderEditableOnBlur(cellInfo, e);
        }}
      >
        {data[cellInfo.index][accessor]}
      </div>
    );
  };

  const toggleSelection = (cellInfo, e) => {
    let index = cellInfo.index;
    let state = store.getState();
    let selectedRows = state.tabs[getTabIndex()].running.selectedRows ? state.tabs[getTabIndex()].running.selectedRows : [];
    let selectedIds = state.tabs[getTabIndex()].running.selectedIds ? state.tabs[getTabIndex()].running.selectedIds : [];
    if (selectedRows.includes(index)) {
      selectedRows.splice(selectedRows.indexOf(index), 1);
      selectedIds.splice(selectedIds.indexOf(index), 1);
    } else {
      selectedRows.push(index);
      selectedIds.push(index);
    }

    state.tabs[getTabIndex()].running.selectedRows = selectedRows;
    state.tabs[getTabIndex()].running.selectedIds = selectedIds;
    store.setState(state);
  };

  const renderCheckBox = (cellInfo) => {
    let state = store.getState();
    let checked = state.tabs[getTabIndex()].running.selectedRows ? state.tabs[getTabIndex()].running.selectedRows.includes(cellInfo.index) : false;
    return <input type="checkbox" className="checkbox" checked={checked} onChange={() => toggleSelection(cellInfo)} />;
  };

  const { loading } = props;
  const style = { top: 0 };
  const gridStyle = { height: 275, marginTop: 5 };
  let selectedCheck = documentsRef.current.find(
    (d) => parseInt(d.index) === parseInt(selectedIndex.current) || parseInt(d.documentId) === parseInt(selectedIndex.current),
  );
  let curState = store.getState();

  if (changedTabs && scannerTurnedOn && curState.current === getTabIndex()) {
    handleTurnOnScanner();
    setChangedTabs(false);
  }

  return (
    showWindow && (
      <FocusTrap>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable handle=".handleDragg">
          <div
            className={`border border-solid bg-grey-lighter-lighter rounded shadow absolute p-2 w-full`}
            style={{
              ...style,
              zIndex: ABOVE_ALL,
              maxHeight: '700px',
              overFlowY: 'auto',
            }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move m-1 text-xs" />
            <div className="mb-2 text-center font-light text-primary text-center">
              <FormattedMessage id="checkMaintenance" defaultMessage={`Mantenimiento de Productos Compensables`} />
            </div>
            <div>
              {documentsRef.current && documentsRef.current.length > 0 && selectedCheck !== undefined && <CheckImages checks={selectedCheck} />}
              {(!documentsRef.current || selectedCheck === undefined) && <div style={{ height: '180px' }}></div>}
              {
                <div className="flex w-full">
                  <ReactTable
                    data={rows}
                    // resolveData={(rows) => rows.slice().map((row) => row)}
                    columns={columns}
                    style={gridStyle}
                    defaultPageSize={10}
                    className="-striped -highlight w-5/6 bg-grey-lighter-lighter"
                    noDataText={
                      <p className="text-text-base" style={{ fontSize: 11 }}>
                        <FormattedMessage id="noData" defaultMessage={`No hay datos`} />
                      </p>
                    }
                    getTrProps={(state, rowInfo, column, instance) => {
                      if (typeof rowInfo !== 'undefined') {
                        return {
                          onClick: async (e, handleOriginal) => {
                            let found = false;
                            let rowId = rowInfo.original.id;

                            documentsRef.current.forEach((doc) => {
                              if ((doc.documentId || doc.documentId === 0) && rowId.toString() === doc.documentId.toString()) {
                                found = true;
                              }
                              if ((doc.index || doc.index === 0) && rowId.toString() === doc.index.toString()) {
                                found = true;
                              }
                            });

                            selectRow(parseInt(rowInfo.index));
                            if (!found) {
                              loadCheckImages(rowInfo.original.id);
                            }
                          },
                          style: {
                            background: rowInfo.index === selectedIndex.current ? selectedCellColor : defaultCellColor,
                          },
                        };
                      } else {
                        return {
                          onClick: (e, handleOriginal) => {
                            if (handleOriginal) handleOriginal();
                          },
                          style: { background: 'white', color: 'black' },
                        };
                      }
                    }}
                  />

                  <div className="w-1/6 flex flex-col" style={{ height: 275 }}>
                    {!getJoinImagesModeFromProperties() ? (
                      <div width="100%">
                        <Button
                          width="100%"
                          title="Nuevo cheque"
                          disabled={loading || (cantidadCheques.current && rowsRef.current.length >= cantidadCheques.current)}
                          click={() => {
                            handleNewLine().then((row) => {
                              let fieldToFocus = document.getElementById(getTabIndex() + '_1_' + parseInt(row.id).toString());
                              if (fieldToFocus) {
                                fieldToFocus.focus();
                                let rowIdx = parseInt(
                                  fieldToFocus.id.substr(
                                    fieldToFocus.id.lastIndexOf('_') + 1,
                                    fieldToFocus.id.length - fieldToFocus.id.lastIndexOf('_'),
                                  ),
                                );
                                selectRow(rowIdx);
                              }
                            });
                          }}
                        />
                        <Button width="100%" title="Borrar cheques" disabled={loading} click={removeRows} />
                      </div>
                    ) : null}
                    <div width="100%">
                      <Button width="100%" title="Encender scanner" disabled={loading} click={() => handleTurnOnScanner()} />
                    </div>
                    {!getJoinImagesModeFromProperties() ? (
                      <div width="100%">
                        <Button width="100%" title="Apagar scanner" disabled={loading} click={() => handleTurnOffScanner()} />
                      </div>
                    ) : null}
                    {!getJoinImagesModeFromProperties() ? (
                      <div width="100%">
                        <label style={{ fontSize: 14 }} htmlFor="amount">
                          Total Detalle:
                        </label>
                        <input
                          style={{ fontSize: 14 }}
                          className={totalCheques.current && totalizedValueRef.current !== totalCheques.current ? 'text-red' : ''}
                          id="amount"
                          disabled
                          value={totalizedValueRef.current}
                        />
                      </div>
                    ) : null}

                    <label style={{ fontSize: 14 }} htmlFor="checkAmount">
                      Cantidad de productos compensables:
                    </label>
                    <input
                      style={{ fontSize: 14 }}
                      className={cantidadCheques.current && rowsRef.current.length < cantidadCheques.current ? 'text-red' : ''}
                      type="number"
                      id="checkAmount"
                      disabled
                      value={rowsRef.current.length}
                    />
                  </div>
                </div>
              }

              <div>
                <Button
                  title="Aceptar"
                  disabled={loading || (cantidadCheques.current && rowsRef.current.length !== cantidadCheques.current)}
                  click={() => handleAccept()}
                />
                <Button title="Cancelar" click={() => setShowModal(true)} />
              </div>
              {showModal && (
                <ModalMessage
                  message={
                    <div className="flex w-full h-full justify-center items-center">
                      <FormattedMessage id="unsavedChangesWillBeLost" defaultMessage={`Los cambios ingresados se perderán, desea continuar igual?`} />
                    </div>
                  }
                  handleAccept={() => {
                    setShowModal(false);
                    const { removeComponentTree } = props;
                    removeComponentTree(dispatchActions.removeTreeNode);
                    setShowWindow(false);
                  }}
                  handleCancel={() => {
                    setShowModal(false);
                  }}
                  acceptTitle={'Aceptar'}
                  cancelTitle={'Cancelar'}
                ></ModalMessage>
              )}
              {showModal2 && (
                <ModalMessage
                  message={msgError.current}
                  handleAccept={() => {
                    setShowModal2(false);
                  }}
                  acceptTitle={'Aceptar'}
                ></ModalMessage>
              )}
              {error !== '' && (
                <ModalMessage
                  message={<div className="flex w-full h-full justify-center items-center">{error}</div>}
                  handleAccept={() => {
                    setError('');
                  }}
                  handleCancel={() => {
                    setError('');
                  }}
                  acceptTitle={'Aceptar'}
                  cancelTitle={'Cancelar'}
                />
              )}
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    )
  );
};

export default connect(null, storeActions)(ScannerWindowView);
