import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'unistore/react';
import 'react-table/react-table.css';
import { FormattedMessage } from 'react-intl';
import ListOfSignaturesView from './ListOfSignaturesView';
import { storeActions } from '../../../store';

export const ListOfSignatures = (props) => {
  const { signatures, setSignators } = props;

  const [rows, setRows] = useState([]);
  const [selectedSignatures, setSelectedSignatures] = useState([]);
  const [currentSignatures, setCurrentSignatures] = useState([]);

  const refChecks = useRef([]);
  const refSignatureList = useRef();
  const refListEntry = useRef();

  useEffect(() => {
    let receivedSignatures = signatures;
    setCurrentSignatures(receivedSignatures);
    const crows = createRows(receivedSignatures);
    setRows(crows);
    updateSelectedSignatures(receivedSignatures);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSelectedSignatures = (selSignatures) => {
    let listReturn = [];
    let listSelected = [...selectedSignatures];
    listSelected.map((item) => {
      const sign = selSignatures.filter((element) => item.numeroPersona === element.numeroPersona);
      listReturn.push(sign[0]);
    });
    setSelectedSignatures(listReturn);
    setSignators(listReturn);
  };

  const addToRefs = (r) => {
    if (r && !refChecks.current.includes(r)) {
      refChecks.current.push(r);
    }
  };

  const addSignaturesListRef = (element) => {
    refSignatureList.current = element;
  };

  const handleSelectedSignature = (row, selected) => {
    let listSelected = [...selectedSignatures];
    let listReturn = [];
    const branch = currentSignatures.filter((item) => parseInt(item.numeroPersona) === parseInt(row.numeroPersona, 10));

    if (selected) {
      listReturn = addSelectedSignature(branch[0], listSelected);
    } else {
      listReturn = removeSignature(branch[0], listSelected);
    }
    setSelectedSignatures(listReturn);
    setSignators(listReturn);
  };

  const addSelectedSignature = (signature, listSelected) => {
    listSelected.push(signature);
    return listSelected;
  };

  const removeSignature = (signature, listSelected) => {
    let c = listSelected.filter((element) => element.numeroPersona !== signature.numeroPersona);
    return c;
  };

  const createRows = (signatureList) => {
    let crows = [];
    if (signatureList && signatureList instanceof Array) {
      signatureList.map((data) => {
        let { numeroPersona, tipoDocumento, numeroDocumento, status, nombre } = data;
        let row = {
          numeroPersona: numeroPersona,
          tipoDocumento: tipoDocumento,
          numeroDocumento: numeroDocumento,
          status: status,
          nombre: nombre,
        };
        crows.push(row);
      });
    }
    return crows;
  };

  const addToRefListEntry = (r) => {
    if (r && refListEntry.current && !refListEntry.current.includes(r)) {
      refListEntry.current.push(r);
    }
  };

  return (
    <div>
      <div className="flex-grow mr-4 rounded border border-light flex">
        <ListOfSignaturesView
          data={rows}
          defaultPageSize={30}
          className="-striped -highlight bg-grey-lighter-lighter"
          noDataText={
            <p className="text-sm w-full text-center leading-normal">
              <FormattedMessage id="noBranchFound" defaultMessage={`No se encontraron sucursales`} />
              <br />
            </p>
          }
          addToRefs={addToRefs}
          ref={(ref) => addSignaturesListRef(ref)}
          handleSelectedSignature={async (item, selected) => {
            handleSelectedSignature(item, selected);
          }}
          addToRefListEntry={addToRefListEntry}
        />
      </div>
    </div>
  );
};

export default connect(null, storeActions)(ListOfSignatures);
