import React, { Component } from 'react';
import { getReportToPrint } from '../../src/api/report';
import { connect } from 'unistore/react';
import { dispatchActions, storeActions, store } from '../../src/store';
import { addEmptyTabReport, addTabReport } from '../../src/actions';
import { PATHNAME } from '../../src/config';

let fetchReport = true;

export class ReportProcessor extends Component {
  async reportToPrint(data) {
    let report = await getReportToPrint(data);
    return report;
  }

  async componentDidMount() {
    if (fetchReport) {
      this.reportToPrint(this.props.data.pages).then((infoForm) => {
        let state = store.getState();
        if (state.tabsReport && !(state.tabsReport.length === 1 && state.tabsReport[0].emptyTab)) {
          state = addEmptyTabReport(state);
        }
        const { name, fileExtension } = infoForm;
        state = addTabReport(
          state,
          {
            description: '',
            specificationId: '',
            name,
            fileExtension,
          },
          true,
          false,
        );
        store.setState(state);
        fetchReport = false;
        store.setState(dispatchActions.openGlobalModal(state, 'Ver reporte generado', false, `${PATHNAME}home/reports`, false, false, true));
      });
    }
  }

  render() {
    return <div></div>;
  }
}

export default connect(null, storeActions)(ReportProcessor);
