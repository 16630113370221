import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';

export let getCommissionsList = (operationId, event, sign) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/commissions/getCommissionsList?sessionId=${session}&event=${event}&signo=${sign}&operationID=${operationId}`,
    )
    .then(({ data }) => data);
};

export let getCommissionsListDifer = (operationId, event, sign, difer) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/commissions/getCommissionsListDifer?sessionId=${session}}&event=${event}&signo=${sign}&operationID=${operationId}&difer=${difer}`,
    )
    .then(({ data }) => data);
};

export let getCommissionsListDate = (
  operationId,
  event,
  sign,
  branch,
  posting,
  processDate,
) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/commissions/getCommissionsListFecha?sessionId=${session}}&event=${event}&signo=${sign}&operationID=${operationId}&sucursal=${branch}&asiento=${posting}&fechaproceso=${processDate}`,
    )
    .then(({ data }) => data);
};

export let getCommissionsListDiferDate = (
  operationId,
  event,
  sign,
  branch,
  posting,
  processDate,
  difer,
) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/commissions/getCommissionsListDiferFecha?sessionId=${session}}&event=${event}&signo=${sign}&operationID=${operationId}&sucursal=${branch}&asiento=${posting}&fechaproceso=${processDate}&diferir=${difer}`,
    )
    .then(({ data }) => data);
};

export let recalculateCommissionsList = (operationId, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/commissions/recalculateCommissionsList?sessionId=${session}&operationID=${operationId}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let updateCommissionsList = (operationId, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/commissions/updateCommissionsList?sessionId=${session}&operationID=${operationId}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};
