import browserStorage from './index';
import {
  USER_NAME,
  RECENT_OPERATIONS_COLLAPSIBLE_IS_OPEN,
  RECENT_REPORTS_COLLAPSIBLE_IS_OPEN,
} from './constants';

const getUserName = () => {
  return browserStorage.sessionGet(USER_NAME);
};

export const getRecentOperationsCollapsibleIsOpen = () => {
  return (
    browserStorage.get(
      `${RECENT_OPERATIONS_COLLAPSIBLE_IS_OPEN}_${getUserName()}`,
    ) || false
  );
};

export const setRecentOperationsCollapsibleIsOpen = (collapsibleIsOpen) => {
  const userName = getUserName();
  browserStorage.set(
    `${RECENT_OPERATIONS_COLLAPSIBLE_IS_OPEN}_${userName}`,
    collapsibleIsOpen,
  );
};

export const getRecentReportsCollapsibleIsOpen = () => {
  return (
    browserStorage.get(`${RECENT_REPORTS_COLLAPSIBLE_IS_OPEN}_${getUserName()}`) ||
    false
  );
};

export const setRecentReportsCollapsibleIsOpen = (collapsibleIsOpen) => {
  const userName = getUserName();
  browserStorage.set(
    `${RECENT_REPORTS_COLLAPSIBLE_IS_OPEN}_${userName}`,
    collapsibleIsOpen,
  );
};
