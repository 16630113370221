import React, { Component } from 'react';
import Displayable from './Displayable';
import { BELOW_ALL } from '../constants/zIndexValues';
import { textSize, mapFont, mapColor } from '../helpers/styleHelper';
import { num2hex } from '../../src/utils/ColorMaper';

export default class Line extends Component {
  render() {
    let { style, position = { x: 0, y: 0, width: 'auto', height: 'auto' }, legend, font, componentState, backColor } = this.props;
    const fontSize = textSize(font.fontSize);
    const underline = font.underLine ? 'underline' : '';
    const strikeout = font.strikeOut ? 'line-through' : '';
    const lineStyle = underline + ' ' + strikeout;
    const color = mapColor(`${font.colorRed}, ${font.colorGreen}, ${font.colorBlue}`);
    const tailwindColor = `text-${color}`;
    const fontStyle = mapFont(font.fontStyle);
    let backgroundColor = 'transparent';
    if (backColor !== undefined) {
      backgroundColor = num2hex(backColor.value);
    }
    let legendComponent;
    if (legend) {
      legendComponent = (
        <legend style={{ textDecoration: lineStyle, backgroundColor: backgroundColor }} className={`${fontStyle} ${fontSize} ${tailwindColor}`}>
          {legend}
        </legend>
      );
    }

    if (position.x < 0 || position.y < 0) {
      return null;
    }

    return (
      <Displayable componentState={componentState}>
        <fieldset
          className="absolute text-smoke p-1 border rounded-sm"
          style={{
            ...style,
            top: position.y,
            left: position.x,
            width: position.width,
            height: position.height,
            zIndex: BELOW_ALL,
          }}
        >
          {legendComponent}
        </fieldset>
      </Displayable>
    );
  }
}
