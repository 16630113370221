import React, { useState, useEffect } from 'react';

import { Parameter } from '../../models/parameter';

interface ParametersInputProps {
  parameters: Parameter[];
  onExecuteParametersChange: (executeParameters: Parameter[]) => void;
  onAccept: (event: Object) => void;
  onCancel: (event: Object) => void;
}

export function ParametersInput({ parameters, onExecuteParametersChange, onAccept, onCancel }: ParametersInputProps): JSX.Element {
  const [executeParams, setExecuteParams] = useState<Parameter[]>(parameters);

  useEffect(() => {
    setExecuteParams(parameters);
    onExecuteParametersChange(parameters);
  }, [onExecuteParametersChange, parameters]);

  function onChange(index: number): (e: { target: HTMLInputElement }) => void {
    return (e) => {
      const newParams: Parameter[] = [...executeParams];
      newParams[index].paramValue = e.target.value;
      setExecuteParams(newParams);
      onExecuteParametersChange(newParams);
    };
  }

  const parametersKey = parameters?.map((entry, index) => {
    return (
      <form className="p-2" key={entry.paramName}>
        <label className="flex">
          {entry.paramName}
          &nbsp; {entry.mandatory && <div className="text-red">*</div>}
          &nbsp; {'(' + entry.processPath + ')'}
        </label>
        <input
          id={`parameterInput-${entry.paramName}`}
          type={entry.paramType === 'integer' ? 'number' : entry.paramType}
          min={entry.paramType === 'integer' ? 0 : ''}
          max={entry.paramType === 'date' ? '2999-12-31' : ''}
          placeholder={entry.paramFormat}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && onAccept) {
              e.preventDefault();
              e.stopPropagation();
              onAccept(e);
            } else if (e.key === 'Escape' && onCancel) {
              e.preventDefault();
              e.stopPropagation();
              onCancel(e);
            }
          }}
          onChange={onChange(index)}
          className="px-4 py-2 w-full rounded border border-gray-300 shadow-sm text-base placeholder-gray-500 placeholder-opacity-50"
        ></input>
      </form>
    );
  });

  return <div>{parametersKey}</div>;
}
