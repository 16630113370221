import { PulseLoader } from 'halogenium';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { Select, SelectData } from '../select/Select';

import './ScheduleModal.scss';
import { ScheduleGroup } from './ScheduleModalViewModel';

interface ScheduleModalViewProps {
  groupName: string;
  onCancel: (event: React.MouseEvent) => void;
  onAccept: (event: React.MouseEvent) => void;
  onEdit: (event: React.MouseEvent) => void;
  onRemove: (event: React.MouseEvent) => void;
  frecuencyOptions: SelectData[];
  intervalOptions: SelectData[];
  idOptions: SelectData[];
  onInputChange: (event: { target: HTMLInputElement }) => void;
  onSelectNumberChange: (value: number, name: string) => void;
  onSelectStringChange: (value: string, name?: string) => void;
  scheduleInfo: ScheduleGroup;
  isLoading: boolean;
  onCheck: (event: { target: HTMLInputElement }) => void;
}

export function ScheduleModalView({
  groupName,
  onAccept,
  onCancel,
  onEdit,
  onRemove,
  intervalOptions,
  frecuencyOptions,
  idOptions,
  scheduleInfo,
  onInputChange,
  onSelectNumberChange,
  onSelectStringChange,
  isLoading,
  onCheck,
}: ScheduleModalViewProps): JSX.Element {
  const intl = useIntl();

  return isLoading ? (
    <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
      <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
    </div>
  ) : (
    <div className="schedule-modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="ml-5 mt-5">{intl.formatMessage({ id: 'schedule' }) + '  ' + groupName}</div>
      <div className="separator mt-4 border-t w-full" />
      <div className="schedule-modal-container justify-start">
        <div className="flex flex-col w-full items-center">
          <div className="flex flex-row m-4 justify-center items-center w-2/3">
            <label className="mr-2 text-center">{intl.formatMessage({ id: 'frecuencyId' })}</label>
            <Select
              name="idFrecuency"
              options={idOptions}
              selectedState={scheduleInfo?.idFrecuency || ''}
              onSelectedChange={onSelectStringChange}
              className="input w-1/3"
            />
          </div>
        </div>
        <div className="border border-grey-lighter rounded-lg">
          <div className="flex flex-row justify-around w-full odd-background">
            <div className="flex flex-col m-4">
              <label>{intl.formatMessage({ id: 'labor' })}</label>
              <input
                name="ever"
                onChange={onCheck}
                checked={scheduleInfo?.ever || false}
                type="checkbox"
                className="border rounded self-center w-3/4"
              />
            </div>
            <div className="flex flex-col m-4">
              <label>{intl.formatMessage({ id: 'saturdays' })}</label>
              <input
                name="saturday"
                onChange={onCheck}
                checked={scheduleInfo?.saturday || false}
                type="checkbox"
                className="border rounded self-center w-3/4"
              />
            </div>
            <div className="flex flex-col m-4">
              <label>{intl.formatMessage({ id: 'sundays' })}</label>
              <input
                name="sunday"
                onChange={onCheck}
                checked={scheduleInfo?.sunday || false}
                type="checkbox"
                className="border rounded self-center w-3/4"
              />
            </div>
            <div className="flex flex-col m-4">
              <label>{intl.formatMessage({ id: 'holidays' })}</label>
              <input
                name="holidays"
                onChange={onCheck}
                checked={scheduleInfo?.holidays || false}
                type="checkbox"
                className="border rounded self-center w-3/4"
              />
            </div>
          </div>
          <div className="flex flex-row justify-around w-full even-background">
            <div className="flex flex-col m-4 w-48">
              <label>{intl.formatMessage({ id: 'frecuency' })}</label>
              <Select
                name="type"
                options={frecuencyOptions}
                selectedState={scheduleInfo?.type}
                onSelectedChange={onSelectStringChange}
                className="input"
              />
            </div>
            <div className="flex flex-col m-4 w-48">
              <label>{intl.formatMessage({ id: 'interval' })}</label>
              <Select
                name="interval"
                options={intervalOptions}
                selectedState={scheduleInfo?.interval || ''}
                onSelectedChange={onSelectNumberChange}
                className="input"
              />
            </div>
          </div>
          <div className="flex flex-row justify-around w-full odd-background">
            <div className="flex flex-col m-4">
              <label>{intl.formatMessage({ id: 'initialHour' })}</label>
              <input
                type="time"
                onChange={onInputChange}
                name="beginDateTime"
                value={scheduleInfo?.beginDateTime || ''}
                className="border border-grey-light"
              />
            </div>
            <div className="flex flex-col m-4">
              <label>{intl.formatMessage({ id: 'endHour' })}</label>
              <input
                type="time"
                onChange={onInputChange}
                name="endDateTime"
                value={scheduleInfo?.endDateTime || ''}
                className="border border-grey-light"
              />
            </div>
          </div>
          <div className="flex flex-row justify-around w-full even-background">
            <div className="flex flex-col m-4">
              <label>{intl.formatMessage({ id: 'executionDate' })}</label>
              <input
                value={scheduleInfo?.nextDateTimeExecution ? moment(+scheduleInfo.nextDateTimeExecution).format('yyyy-MM-DD') : ''}
                type="date"
                name="date"
                onChange={onInputChange}
                className="border border-grey-light"
              />
            </div>
            <div className="flex flex-col m-4">
              <label>{intl.formatMessage({ id: 'executionHour' })}</label>
              <input
                value={scheduleInfo?.nextDateTimeExecution !== undefined ? moment(+scheduleInfo.nextDateTimeExecution).format('HH:mm:ss') : ''}
                type="time"
                name="time"
                onChange={onInputChange}
                className="border border-grey-light"
              />
            </div>
          </div>
          <div className="flex flex-row justify-around w-full odd-background">
            <div className="flex flex-col m-4">
              <label>{intl.formatMessage({ id: 'attempts' })}</label>
              <input
                type="number"
                onChange={onInputChange}
                name="retriesCount"
                value={scheduleInfo?.retriesCount || 0}
                className="border border-grey-light"
              />
            </div>
            <div className="flex flex-col m-4">
              <label>{intl.formatMessage({ id: 'interval' }) + '(' + intl.formatMessage({ id: 'minutes' }) + ')'}</label>
              <input
                onChange={onInputChange}
                name="retriesInterval"
                type="number"
                value={scheduleInfo?.retriesInterval || 0}
                className="border border-grey-light"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row px-4 py-3 w-5/6 mt-2 pt-5 justify-center self-center">
        <button
          name="2"
          onClick={onEdit}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {intl.formatMessage({ id: 'modify' })}
        </button>
        <button
          name="0"
          onClick={onAccept}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {intl.formatMessage({ id: 'add' })}
        </button>
        <button
          name="1"
          onClick={onRemove}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {intl.formatMessage({ id: 'remove' })}
        </button>
        <button
          onClick={onCancel}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {intl.formatMessage({ id: 'close' })}
        </button>
      </div>
    </div>
  );
}
