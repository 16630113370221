import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { store, storeActions } from '../../src/store';
import { getCurrentTabInformation, getTabIdByNodeId } from '../../src/selectors';
import MaskedInput from './MaskedInput';
import Help from './Help';
import Displayable from './Displayable';
import HelpWindow from './HelpWindow';
import { createRunBody, createSimpleBody, createBodyGoToLine } from '../../renderer/lib/bodyCreators';
import { run, gotoLineWithBody, undo } from '../../src/api/dialog';
import { textSize, mapColor } from '../helpers/styleHelper';
import { pushToWindowStack } from '../../src/actions';
import { FormulaWindow } from './FormulaWindow';
import { compareTLId, getTL, getTLFromStr } from '../helpers/operationHelper';
import { num2hex } from '../../src/utils/ColorMaper';
import { addLineTest, addLineEventTest, addBlurTest } from '../../src/components/Testing/HandleLineTest';
import { canReceiveFocus } from '../../renderer/lib/focusManager';

export class Input extends Component {
  constructor() {
    super();

    this.toggleWindow = this.toggleWindow.bind(this);
    this.acceptHandler = this.acceptHandler.bind(this);
    this.focusElementFn = this.focusElementFn.bind(this);
    this.input = React.createRef();
    this.state = {
      // inputRawValue: '', // for the value without mask
      lastValue: '',
      showingHelp: false,
      focusGainedBy: '',
      caretPosition: undefined,
    };
    this.inputRawValue = '';
    this.clientProperties = store.getState().clientProperties;
    this.useBackColor = this.clientProperties?.useBackColor;
    // this.clientProperties = { useBackColor:"false" };
  }

  toggleWindow() {
    let newShowingHelp = this.state.showingHelp;
    this.setState({ showingHelp: !newShowingHelp });
  }

  componentDidUpdate() {
    if (this.state.caretPosition !== undefined) {
      if (this.input.current !== undefined) {
        this.input.current.selectionStart = this.state.caretPosition;
        this.input.current.selectionEnd = this.state.caretPosition;
      } else {
        this.input.selectionStart = this.state.caretPosition;
        this.input.selectionEnd = this.state.caretPosition;
      }

      this.setState({ caretPosition: undefined });
    }
  }

  acceptHandler(field) {
    const {
      addFieldValue,
      removeFromWindowStack,
      transactionLineLabel,
      operationNumber,
      operationID,
      transactionNumber,
      lineNumber,
      processContext,
      focusElement,
      fieldData,
      setApiLoading,
      tabId,
    } = this.props;
    let body;
    const inputValue = field[transactionLineLabel].value;
    if (Array.isArray(field[transactionLineLabel].value)) {
      body = createSimpleBody(operationNumber, transactionLineLabel, field[transactionLineLabel].value);
    } else {
      body = createRunBody(operationNumber, transactionLineLabel, '' + field[transactionLineLabel].value);
      this.setLastValue(inputValue);
      addFieldValue(field);
    }
    if (transactionLineLabel !== focusElement) {
      let bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
      gotoLineWithBody(operationID, transactionNumber, lineNumber, bodyGoToLine).then(() => {
        setApiLoading(true, tabId);
        run(operationID, transactionNumber, lineNumber, body)
          .then((res) => {
            this.setLastValueByResponse(res);
            processContext(res, 'run Input Help');
          })
          .finally(() => {
            setApiLoading(false, tabId);
          });
      });
    } else {
      setApiLoading(true, tabId);
      run(operationID, transactionNumber, lineNumber, body)
        .then((res) => {
          this.setLastValueByResponse(res);
          processContext(res, 'run Input Help');
        })
        .finally(() => {
          setApiLoading(false, tabId);
        });
    }
    removeFromWindowStack(transactionLineLabel);
    this.toggleWindow();
  }

  setLastValueByResponse = (response) => {
    const { transactionLineLabel } = this.props;

    if (!response || !response.lista || !response.lista.length) {
      return;
    }
    const valueToSet = response.lista.find((o) => {
      return o['class-name'].includes('ValueToSetEvent') && o.lineID && getTL(o.lineID) === getTLFromStr(transactionLineLabel) && o.value;
    });

    if (valueToSet) {
      this.setLastValue(valueToSet.value);
    }
  };

  focusElementFn() {
    const { transactionLineLabel, focusOn, focusPaused } = this.props;
    let state = store.getState();
    let focusElement = state.tabs[state.current] && state.tabs[state.current].running && state.tabs[state.current].running.focusElement;
    if (
      !(this.state.showingHelp && this.props.inputParameters.type !== 6) &&
      transactionLineLabel === focusElement &&
      focusOn &&
      focusOn[0] !== 'T0L0' &&
      !focusPaused
    ) {
      const tabActive = document.getElementsByClassName('rc-tabs-tabpane-active')[0];
      const input = tabActive && tabActive.querySelector(`#${focusElement}`);
      if (input) {
        input.focus();
        const selectionStart = input.value === ' ' ? 0 : input.selectionStart;
        const selectionEnd = input.selectionEnd || (input.value && input.value.length) || 0;
        input.setSelectionRange && input.setSelectionRange(selectionStart, selectionEnd);
      }
    }
  }

  lastValueListener = (e) => {
    e = e || window.event;
    if (e.ctrlKey && e.keyCode === 76) {
      e.preventDefault();
      e.stopPropagation();
      window.event.returnValue = false;
      window.event.cancelBubble = true;
      const { addFieldValue, lastValues, transactionLineLabel, fieldNumber, componentState } = this.props;
      let lastValue = lastValues && lastValues[fieldNumber];
      if (lastValue) {
        this.inputRawValue = lastValue;
        // this.setState({ inputRawValue: lastValue });
        this.setState({ lastValue: lastValue });
        let valueWithMask = [];
        let lastValuePos = 0;
        for (const pos in this.state.mask) {
          if (typeof this.state.mask[pos] !== 'string') {
            valueWithMask[pos] = lastValue[lastValuePos];
            lastValuePos++;
          } else {
            valueWithMask[pos] = this.state.mask[pos];
          }
        }
        const multiLine = componentState && componentState.multiLine;
        if (multiLine) {
          const tabActive = document.getElementsByClassName('rc-tabs-tabpane-active')[0];
          const input = tabActive.querySelector(`#${transactionLineLabel}`);
          if (input) {
            input.value = lastValue;
          }
        } else if (this.input && this.input.current && this.input.current.inputElement) {
          this.input.current.inputElement.value = valueWithMask;
        }
        let value = {
          [transactionLineLabel]: { value: lastValue },
        };
        if (
          this.props.inputParameters &&
          this.props.inputParameters.mask &&
          this.props.inputParameters.mask !== '>' &&
          this.props.inputParameters.mask !== '<'
        ) {
          value = {
            [transactionLineLabel]: { value: lastValue, rawValue: lastValue },
          };
        }
        addFieldValue(value);
      }
      return false;
    }
  };

  componentDidMount() {
    this.focusElementFn();

    const { componentState, transactionLineLabel } = this.props;
    const multiLine = componentState && componentState.multiLine;

    if (multiLine) {
      const tabActive = document.getElementsByClassName('rc-tabs-tabpane-active')[0];
      const input = tabActive.querySelector(`#${transactionLineLabel}`);
      if (input) {
        input.onkeydown = this.lastValueListener;
      }
    } else if (this.input && this.input.current) {
      if (this.input.current.inputElement) {
        this.input.current.inputElement.onkeydown = this.lastValueListener;
      } else {
        this.input.current.onkeydown = this.lastValueListener;
      }
    }
  }

  componentWillUnmount() {
    const { componentState, transactionLineLabel } = this.props;
    const multiLine = componentState && componentState.multiLine;

    const tabActive = document.getElementsByClassName('rc-tabs-tabpane-active')[0];
    if (multiLine && tabActive) {
      const input = tabActive.querySelector(`#${transactionLineLabel}`);
      if (input) {
        input.removeEventListener('keydown', this.lastValueListener);
      }
    } else if (this.input && this.input.current) {
      if (this.input.current.inputElement) {
        this.input.current.inputElement.removeEventListener('keydown', this.lastValueListener);
      } else {
        this.input.current.removeEventListener('keydown', this.lastValueListener);
      }
    }
  }

  handleKeyDownMultiline = async (e, helpElement) => {
    addLineEventTest(e);
    const goBack = () => {
      if (!e || !e.key) return false;
      return e.key === 'Escape' || (e.shiftKey && e.key === 'Tab');
    };

    const { props, toggleWindow } = this;

    const {
      onKeyDown,
      operationID,
      operationNumber,
      processContext,
      focusElement,
      removeFromWindowStack,
      removeTreeNode,
      transactionLineLabel,
      transactionNumber,
      lineNumber,
      pushToWindowStack,
      mandatoryHelp,
    } = props;

    if (e.ctrlKey && e.key === 'Enter') {
      if (this.input.current) {
        if (this.input.current.inputElement) {
          this.input.current.inputElement.value = this.input.current.inputElement.value + '\n';
        } else {
          this.input.current.value = this.input.current.value + '\n';
        }
      } else if (this.input) {
        this.input.value = this.input.value + '\n';
      }
    }

    // Get the rawValue (the value without the Cleave mask)
    let inputRawValueFromTarget = e.target.value;
    const eventWithRawValue = {
      ...e,
      target: { ...e.target, value: inputRawValueFromTarget },
    };
    const multiLine = true;
    let showingHelp = this.state.showingHelp;
    let help = !!showingHelp; // Check if showingHelp exists
    const openHelp = mandatoryHelp && (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey));
    if (helpElement) {
      if (e.key === 'F1' || openHelp) {
        addBlurTest('SENDTEXT', e);
        pushToWindowStack(transactionLineLabel);
        toggleWindow(); // open help window
        e.preventDefault();
        help = true;
      }
    }
    if ((e.key === 'Enter' && !e.ctrlKey) || e.key === 'Tab') {
      e.preventDefault();
      if (!mandatoryHelp) {
        this.setLastValue(e.target.value);
      }
      onKeyDown(
        eventWithRawValue,
        operationID,
        operationNumber,
        processContext,
        focusElement,
        help,
        multiLine,
        removeFromWindowStack,
        removeTreeNode,
      );
    }

    if (goBack()) {
      e.preventDefault();
      const res = await undo(operationID, transactionNumber, lineNumber);
      processContext(res, 'Input multiline undo');
    }
  };

  applyCapsOption = (textStyle) => {
    if (textStyle) {
      return textStyle === 'uppercase'
        ? this.inputRawValue.toUpperCase()
        : textStyle === 'lowercase'
        ? this.inputRawValue.toLowerCase()
        : this.inputRawValue;
    } else {
      return this.inputRawValue;
    }
  };

  /**
   * Executes the props.onKeyDown event of FieldToDisplay.
   * Also, if applicable, open the help and filter the data.
   */
  handleKeyDown = (e, helpElement, textStyle = undefined, valueMasked = undefined) => {
    const { props, toggleWindow } = this;
    addLineEventTest(e);
    const {
      onKeyDown,
      operationID,
      operationNumber,
      processContext,
      focusElement,
      removeFromWindowStack,
      removeTreeNode,
      transactionLineLabel,
      pushToWindowStack,
      mandatoryHelp,
    } = props;

    const forward = e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey);
    // Get the rawValue (the value without the Cleave mask)
    let inputRawValue = this.inputRawValue !== '' ? this.inputRawValue : e.target.value;

    if (valueMasked !== undefined) {
      inputRawValue = valueMasked;
    } else if (textStyle) {
      this.inputRawValue = inputRawValue;
      inputRawValue = this.applyCapsOption(textStyle);
    }
    if (!mandatoryHelp && forward) {
      e.preventDefault();
      this.setLastValue(this.state.lastValue);
    }

    // Spread the raw value into target.value attribute (target.value is the default value of input)
    const eventWithRawValue = {
      ...e,
      target: { ...e.target, value: inputRawValue },
    };
    let showingHelp = this.state.showingHelp;
    let help = !!showingHelp; // Check if showingHelp exists
    const openHelp = mandatoryHelp && (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey));
    if (helpElement) {
      if (e.key === 'F1' || openHelp) {
        addBlurTest('SENDTEXT', e);
        pushToWindowStack(transactionLineLabel);
        toggleWindow(); // open help window
        e.preventDefault(); // Prevent browser behavior when F1 is pressed
        help = true;
      }
    }

    if (e.key === 'Tab' && e.shiftKey) {
      /**
      To avoid that the focus goes to the previous element,
      because that action will be carried out later,
      from the response of the undo event.
      The backward flow is a responsibility of the API.
      It's the API that decides whether to go to back or not. */
      this.inputRawValue = '';
      // this.setState({ inputRawValue: '' });
      e.preventDefault();
    }

    onKeyDown(eventWithRawValue, operationID, operationNumber, processContext, focusElement, help, removeFromWindowStack, removeTreeNode);

    // Executes the props.onKeyDown event of FieldToDisplay.
  };

  handleBlur = (e) => {
    const { transactionLineLabel } = this.props;
    if (e.relatedTarget === null || e.relatedTarget.classList.contains('contorno')) {
      this.focusElementFn();
    } else {
      const relatedId = e && e.relatedTarget && e.relatedTarget.id ? e.relatedTarget.id : '';

      if (compareTLId(transactionLineLabel, relatedId) <= 0) {
        if (this.inputRawValue !== '') {
          this.inputRawValue = '';
          // this.setState({ inputRawValue: '' });
        }
      } else {
        addBlurTest('SENDTEXT', e);
      }
    }
  };

  performGoToLine = (e) => {
    if (!store.getState().validationFailed && canReceiveFocus(e)) {
      if (this.props.transactionLineLabel !== this.props.focusElement) {
        let body = createSimpleBody(this.props.operationNumber, this.props.transactionLineLabel, this.props.value);
        let bodyGoToLine = createBodyGoToLine(
          this.props.operationNumber,
          this.props.fieldData,
          this.props.transactionLineLabel,
          this.props.focusElement,
          body,
        );
        gotoLineWithBody(this.props.operationID, this.props.transactionNumber, this.props.lineNumber, bodyGoToLine).then((res) => {
          this.props.processContext(res, 'gotoLine Input');
        });
      }
    }
  };

  setLastValue = (valueToSave) => {
    const { setLastValue, fieldNumber } = this.props;
    setLastValue(fieldNumber, valueToSave);
  };

  updateValues = (rawValue) => {
    // this.setState({ inputRawValue: rawValue });
    this.inputRawValue = rawValue;
    this.setState({ lastValue: rawValue });
  };

  getCapsMask = () => {
    let textStyle;
    if (this.props.inputParameters.mask && (this.props.inputParameters.mask === '>' || this.props.inputParameters.mask === '<')) {
      switch (this.props.inputParameters.mask) {
        case '>':
          textStyle = 'uppercase';
          break;
        case '<':
          textStyle = 'lowercase';
          break;
        default:
          textStyle = '';
      }
    }

    return textStyle;
  };

  render() {
    let {
      nodeId,
      label,
      placeholder = '',
      type = 'text',
      onChange,
      help,
      componentState,
      operationNumber,
      operationID,
      processContext,
      position = { x: 0, y: 0, width: 'auto', height: 'auto' },
      labelPosition = { x: 0, y: 0, width: 'auto', height: 'auto' },
      readOnly,
      showPrompt,
      fieldNumber,
      transactionLineLabel,
      lineNumber,
      transactionNumber,
      inputParameters,
      focusElement,
      labelFont,
      promptBackColor,
      font,
      defaultValue,
      fieldData,
      align,
      initiallyVisible,
    } = this.props;

    if (transactionLineLabel !== focusElement && initiallyVisible === false) {
      help = false;
    }

    if (!componentState) return null;
    let value = componentState && componentState.value;
    let disabled = componentState && componentState.disabled;
    if ((value === 0 || value === undefined) && (readOnly === true || disabled === true) && defaultValue !== undefined) {
      value = defaultValue;
    }

    if (componentState.rawValue !== undefined) {
      value = componentState.rawValue;
    }

    let multiLine = componentState && componentState.multiLine;
    if (multiLine === false && value !== null && value !== undefined && value.indexOf && value.indexOf('\n') > -1) {
      multiLine = true;
    }

    let tailwindColorLabel = labelFont && mapColor(`${labelFont.colorRed}, ${labelFont.colorGreen}, ${labelFont.colorBlue}`);
    const tailwindColor = mapColor(`${font.colorRed}, ${font.colorGreen}, ${font.colorBlue}`);
    const fontSize = textSize(font.fontSize);
    const underline = font.underLine ? 'underline' : '';
    const strikeout = font.strikeOut ? 'line-through' : '';
    const lineStyle = underline + ' ' + strikeout;
    const fontStyle = font.fontStyle;
    var backColor = 'transparent';
    if (promptBackColor === 'transparent' && this.useBackColor === 'false') {
      tailwindColorLabel = 'text-base';
    }

    if (promptBackColor !== undefined && promptBackColor !== 'transparent') {
      backColor = num2hex(promptBackColor.value);
    }

    let helpStyle = {
      height: position.height,
    };

    let helpElement =
      help && !readOnly && !disabled ? (
        <Help
          tlid={transactionLineLabel + '_HelpButton'}
          style={helpStyle}
          click={async (e) => {
            let { pushToWindowStack } = this.props;
            addLineTest('CLICK', e);
            pushToWindowStack(transactionLineLabel);
            if (transactionLineLabel !== focusElement) {
              let body = createSimpleBody(operationNumber, transactionLineLabel, value);
              let bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
              const res = await gotoLineWithBody(operationID, transactionNumber, lineNumber, bodyGoToLine);
              processContext(res, 'gotoLine Input Help');
            }
            this.toggleWindow();
          }}
        />
      ) : null;
    let noRoundRight = help && !readOnly && !disabled ? 'rounded-r-none' : '';
    let isInWindowArea =
      componentState && componentState.windowWidth && componentState.windowHeight
        ? componentState.windowWidth > labelPosition.x && componentState.windowHeight > labelPosition.y
        : true;

    const buttonStyle = {
      left: position.x,
      top: position.y,
      width: position.width,
      height: position.height,
      zIndex: 'inherit',
    };

    const labelStyle = {
      left: labelPosition.x,
      top: labelPosition.y,
      width: labelPosition.width,
      height: labelPosition.height,
      zIndex: 'inherit',
      textDecoration: lineStyle,
      backgroundColor: backColor,
    };

    const helpDivStyle = {
      left: position.x + position.width,
      top: position.y,
      zIndex: 'inherit',
    };

    // mask para el coso viejo

    let textStyle = '';
    let maskedInputMask = false;
    if (inputParameters.mask && inputParameters.mask !== '>' && inputParameters.mask !== '<') {
      maskedInputMask = true;
    } else {
      textStyle = this.getCapsMask();
    }
    this.focusElementFn();
    return (
      <Displayable componentState={componentState}>
        <label
          className={`${
            fontStyle === 1 ? 'font-semibold' : fontStyle === 2 ? 'italic' : ''
          } ${fontSize} text-${tailwindColorLabel} absolute bg-grey-lighter-lighter`}
          style={{
            ...labelStyle,
            fontSize: (labelFont && labelFont.fontSize) || 12,
          }}
        >
          {showPrompt && isInWindowArea ? label : ''}
        </label>
        <div
          className="absolute flex"
          style={buttonStyle}
          onMouseDown={(e) => {
            addLineTest('CLICK', e);
            if (readOnly) {
              for (const field in fieldData) {
                if (fieldData[field].position) {
                  let xDiff = Math.abs(parseInt(fieldData[field].position.x) - parseInt(fieldData[transactionLineLabel].position.x));
                  let yDiff = Math.abs(parseInt(fieldData[field].position.y) - parseInt(fieldData[transactionLineLabel].position.y));
                  if (
                    xDiff <= 10 &&
                    yDiff <= 10 &&
                    field !== transactionLineLabel &&
                    fieldData[field].inputType !== 5 &&
                    fieldData[field].hide === false &&
                    fieldData[field].treeNumber === fieldData[transactionLineLabel].treeNumber
                  ) {
                    fieldData[transactionLineLabel].hide = true;
                    this.forceUpdate();

                    const fieldToFocus = document.getElementById(field);

                    setTimeout(() => {
                      // eslint-disable-next-line no-undef
                      let newEvent = new MouseEvent('mousedown', {
                        bubbles: true,
                      });
                      fieldToFocus.dispatchEvent(newEvent);
                      fieldToFocus.focus();
                    }, 50);
                  }
                }
              }
            }
          }}
        >
          {multiLine ? (
            <textarea
              id={transactionLineLabel}
              ref={(input) => {
                this.input = input;
              }}
              onChange={(e) => {
                // onChange(e);
                this.inputRawValue = e.target.value;

                this.inputRawValue =
                  textStyle === 'uppercase'
                    ? this.inputRawValue.toUpperCase()
                    : textStyle === 'lowercase'
                    ? this.inputRawValue.toLowerCase()
                    : this.inputRawValue;
                if (e.target.value !== this.inputRawValue) {
                  let caretPosition = e.target.selectionStart;
                  this.setState({ caretPosition: caretPosition });
                }

                e.target.value = this.inputRawValue;
                onChange(e);
                this.performGoToLine();
                this.setState({ lastValue: this.inputRawValue });
              }}
              disabled={readOnly}
              className={`${fontSize} appearance-none px-1 rounded shadow w-full ${noRoundRight} text-${tailwindColor}`}
              style={{ fontSize: 12, resize: 'none' }}
              value={value}
              onMouseDown={(e) => {
                addLineTest('CLICK', e);
                this.setState({ focusGainedBy: 'click' });
              }}
              onKeyDown={(e) => {
                this.handleKeyDownMultiline(e);
              }}
              onFocus={(e) => {
                if (this.state.focusGainedBy === 'click') {
                  this.performGoToLine(e);
                  this.setState({ focusGainedBy: '' });
                }
              }}
              onBlur={this.handleBlur}
              readOnly={readOnly}
              maxLength={inputParameters.length}
            />
          ) : maskedInputMask ? (
            <MaskedInput
              mask={inputParameters.mask}
              id={transactionLineLabel}
              style={{ textTransform: textStyle, fontSize: 12 }}
              type={type}
              autoComplete="off"
              guide={false}
              inputRef={this.input}
              textStyle={textStyle}
              onChange={onChange}
              updateValues={this.updateValues}
              onMouseDown={(e) => {
                addLineTest('CLICK', e);
                this.setState({ focusGainedBy: 'click' });
              }}
              onFocus={(e) => {
                if (this.state.focusGainedBy === 'click') {
                  this.performGoToLine(e);
                  this.setState({ focusGainedBy: '' });
                }
                let element = document.getElementById(transactionLineLabel);
                if (element) {
                  const selectionEnd = element.value.length;
                  element.setSelectionRange(0, selectionEnd);
                }
              }}
              className={`${fontSize} text-${tailwindColor} appearance-none px-1 rounded shadow w-full ${noRoundRight} ${
                readOnly ? 'bg-grey-lighter-lighter' : ''
              }`}
              value={value}
              disabled={readOnly}
              onKeyDown={this.handleKeyDown}
              helpElement={helpElement}
              /* onKeyDown={(e) => {
                this.handleKeyDown(e, helpElement, showingHelp);
              }} */
              readOnly={readOnly}
              onBlur={this.handleBlur}
              maxLength={inputParameters.length}
            />
          ) : (
            <input
              id={transactionLineLabel}
              style={{
                textTransform: textStyle,
                fontSize: 12,
                textAlign: align === 2 ? 'right' : align === 1 ? 'center' : 'left',
              }}
              type={type}
              ref={this.input}
              autoComplete="off"
              placeholder={placeholder}
              onMouseDown={(e) => {
                addLineTest('CLICK', e);
                this.setState({ focusGainedBy: 'click' });
              }}
              onChange={(e) => {
                let value = e.target.value;
                let rawValue = [];
                rawValue = value;
                rawValue = textStyle === 'uppercase' ? rawValue.toUpperCase() : textStyle === 'lowercase' ? rawValue.toLowerCase() : rawValue;

                if (rawValue !== value) {
                  let caretPosition = this.input.current.selectionStart;
                  this.setState({ caretPosition: caretPosition });
                }
                const eventWithRawValue = {
                  ...e,
                  target: { ...e.target, value: rawValue },
                };

                onChange(eventWithRawValue);
                this.inputRawValue = rawValue;
                this.setState({ lastValue: rawValue });
              }}
              onFocus={(e) => {
                if (this.state.focusGainedBy === 'click') {
                  this.performGoToLine(e);
                  this.setState({ focusGainedBy: '' });
                }
                if (this.input.current.inputElement) {
                  const selectionEnd = this.input.current.inputElement.value.length;
                  this.input.current.inputElement.setSelectionRange(0, selectionEnd);
                } else if (this.input.current) {
                  const selectionEnd = this.input.current.value.length;
                  this.input.current.setSelectionRange(0, selectionEnd);
                }
              }}
              className={`${fontSize} text-${tailwindColor} appearance-none px-1 rounded shadow w-full ${noRoundRight} ${
                readOnly ? 'bg-grey-lighter-lighter' : ''
              }`}
              value={value}
              disabled={readOnly}
              onKeyDown={(e) => {
                this.handleKeyDown(e, helpElement, textStyle);
              }}
              readOnly={readOnly}
              onBlur={this.handleBlur}
              maxLength={inputParameters.length}
            />
          )}
        </div>
        <div className="absolute flex" style={helpDivStyle}>
          {helpElement}
        </div>
        {help && (
          <HelpWindow
            nodeId={nodeId}
            cancelHandler={() => {
              let { removeFromWindowStack } = this.props;
              removeFromWindowStack(transactionLineLabel);
              this.toggleWindow();
            }}
            acceptHandler={this.acceptHandler}
            fieldNumber={fieldNumber}
            onClick={this.onClick}
            transactionLineLabel={transactionLineLabel}
            inputValue={this.inputRawValue !== '' ? this.inputRawValue : value}
            transactionNumber={transactionNumber}
            lineNumber={lineNumber}
            showingHelp={this.state.showingHelp && inputParameters.type !== 6}
            isFocused={transactionLineLabel === focusElement && this.props.focusOn && this.props.focusOn[0] !== 'T0L0'}
          />
        )}
        {this.state.showingHelp && inputParameters.type === 6 && (
          <FormulaWindow
            nodeId={nodeId}
            inputValue={this.inputRawValue !== '' ? this.inputRawValue : value}
            cancelHandler={() => {
              let { removeFromWindowStack } = this.props;
              removeFromWindowStack(transactionLineLabel);
              this.toggleWindow();
            }}
            acceptHandler={this.acceptHandler}
            transactionNumber={transactionNumber}
            lineNumber={lineNumber}
            transactionLineLabel={transactionLineLabel}
            fieldNumber={fieldNumber}
            pushToWindowStack={pushToWindowStack}
          />
        )}
      </Displayable>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { nodeId } = ownProps;
  const tabId = getTabIdByNodeId(state, nodeId);
  const { operationID, operationNumber, focusElement, focusPaused, focusOn } = getCurrentTabInformation(state);

  const { lastValues } = state;

  return {
    lastValues,
    operationID,
    operationNumber,
    focusElement,
    focusPaused,
    focusOn,
    tabId,
  };
};

export default connect(mapStateToProps, storeActions)(Input);
