import DialogToDisplay from '../events/DialogToDisplay';
import MessageToDisplay from '../events/MessageToDisplay';
import FieldToDisplay from '../events/FieldToDisplay';
import NoticeToDisplay from '../events/NoticeToDisplay';
import SimpleRequestToDisplay from '../events/SimpleRequestToDisplay';
import ButtonToDisplay from '../events/ButtonToDisplay';
import RemoteToLaunch from '../events/RemoteToLaunch';
import ApplicationToLaunch from '../events/ApplicationToLaunch';
import PersonIdentification from '../events/PersonIdentification';

const componentDictionary = {
  'com.topsystems.jtopaz.opmgr.topaz41.events.DialogToDisplayEvent': DialogToDisplay,
  'com.topsystems.jtopaz.opmgr.topaz41.events.MessageToDisplayEvent': MessageToDisplay,
  'com.topsystems.jtopaz.opmgr.topaz41.events.FieldToDisplayEvent': FieldToDisplay,
  'com.topsystems.jtopaz.opmgr.topaz41.events.FieldToInputEvent': FieldToDisplay,
  'com.topsystems.jtopaz.opmgr.topaz41.events.NoticeToDisplayEvent': NoticeToDisplay,
  'com.topsystems.jtopaz.opmgr.topaz41.events.OperationToFinishEvent': SimpleRequestToDisplay,
  'com.topsystems.jtopaz.opmgr.topaz41.events.OperationToCancelEvent': SimpleRequestToDisplay,
  'com.topsystems.jtopaz.opmgr.topaz41.events.ButtonToDisplayEvent': ButtonToDisplay,
  'com.topsystems.jtopaz.opmgr.topaz41.events.RemoteToLaunchEvent': RemoteToLaunch,
  'com.topsystems.jtopaz.opmgr.topaz41.events.ApplicationToLaunchEvent': ApplicationToLaunch,
  'com.topsystems.jtopaz.opmgr.topaz41.events.PersonIdentificationEvent': PersonIdentification,
};

const objectToClass = (className) => {
  return componentDictionary[className];
};

module.exports = {
  objectToClass,
};
