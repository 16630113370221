import React, { Component } from 'react';
import IcoMoon from 'react-icomoon';
import Draggable from 'react-draggable';
import Webcam from 'react-webcam';
import { connect } from 'unistore/react';
import { storeActions } from '../../src/store';
import { injectIntl, FormattedMessage } from 'react-intl';

import Overlay from './Overlay';
import { ABOVE_ALL } from '../constants/zIndexValues';
import { getPhysicalPerson, addPhoto } from '../../src/api/signatures';
import { extractInfoBase64 } from '../helpers/fileHelper';
import FocusTrap from 'react-focus-trap';
import { addValueTest } from '../../src/components/Testing/HandleLineTest';

const Button = ({ title, click, className, disabled = false, id = { id } }) => {
  return (
    <button
      id={id}
      className={`${className} ${disabled && 'opacity-75'} bg-grey hover:bg-primary focus:bg-primary text-white rounded shadow m-2 py-2 px-4 text-sm`}
      onClick={(e) => {
        addValueTest('CLICK', id, '');
        click();
      }}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

const RESOLUTIONS = [
  { width: 640, height: 480 },
  { width: 320, height: 240 },
  { width: 176, height: 144 },
];

export class SignatureTakePhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWindow: true,
      physicalPerson: null,
      photoTaken: null,
      videoConstraints: RESOLUTIONS[0],
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.capturePhoto = this.capturePhoto.bind(this);
    this.handleResolution = this.handleResolution.bind(this);
  }

  async componentDidMount() {
    let { numberPhysicalPerson, pauseFocus } = this.props;
    pauseFocus();
    await this.fetchData(numberPhysicalPerson);
  }

  async handleSave() {
    let { numberPhysicalPerson, removeTreeNode, removeComponentTree } = this.props;
    let { photoTaken } = this.state;

    await addPhoto(numberPhysicalPerson, extractInfoBase64(photoTaken));
    this.setState((prevState) => ({ showWindow: !prevState.showWindow }));
    removeComponentTree(removeTreeNode);
  }

  handleCancel() {
    let { removeTreeNode, removeComponentTree, resumeFocus } = this.props;

    this.setState((prevState) => ({
      showWindow: !prevState.showWindow,
    }));
    resumeFocus();
    removeComponentTree(removeTreeNode);
  }

  async fetchData(numberPhysicalPerson) {
    let physicalPerson = await getPhysicalPerson(numberPhysicalPerson);
    this.setState({ physicalPerson });
  }

  capturePhoto() {
    const photoTaken = this.webcam.getScreenshot();
    this.setState({ photoTaken });
  }

  handleResolution(resolution) {
    this.setState({ videoConstraints: resolution });
  }

  render() {
    let { numberPhysicalPerson, intl } = this.props;
    let { showWindow, physicalPerson, photoTaken, videoConstraints } = this.state;

    let style = { width: 800 };

    return (
      <FocusTrap className={`${showWindow ? '' : 'hidden'}`}>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div className="border border-primary bg-grey-lighter-lighter rounded shadow absolute p-2 w-full" style={{ ...style, zIndex: ABOVE_ALL }}>
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move m-1 text-xs" />

            <div className="mb-2 font-light text-primary text-center">
              <FormattedMessage id="takePhotoPerson" defaultMessage={`Captura foto de la persona`} />
            </div>

            <div className="my-4">
              <span className="text-xs">#{numberPhysicalPerson}</span>
              {physicalPerson && physicalPerson[0] && (
                <span className="ml-2 inline-block">
                  {physicalPerson[0].primerNombre} {physicalPerson[0].segundoNombre} {physicalPerson[0].apellidoPaterno}{' '}
                  {physicalPerson[0].apellidoMaterno}
                </span>
              )}
            </div>

            <div className="flex justify-center flex-col">
              <div className="flex items-center mb-2">
                <label className="text-sm">
                  <FormattedMessage id="resolution" defaultMessage={`Resolución`} />:
                </label>
                <div className="flex ml-3" style={{ fontSize: 12 }}>
                  {RESOLUTIONS.map((resolution, index) => (
                    <div className="flex content-center" key={index}>
                      <input
                        type="radio"
                        value={resolution}
                        checked={videoConstraints === resolution}
                        onClick={() => {
                          this.handleResolution(resolution);
                        }}
                      />
                      <span className="ml-1 mr-6">
                        {resolution.height}x{resolution.width}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex w-full bg-grey-lightest rounded my-4 p-6">
              <div className="flex w-full items-center">
                <Webcam
                  className="w-1/2 h-full mr-2 rounded shadow"
                  audio={false}
                  ref={(c) => (this.webcam = c)}
                  screenshotFormat="image/png"
                  videoConstraints={{ ...videoConstraints, facingMode: 'user' }}
                />
                <img className="w-1/2 h-full rounded shadow" src={photoTaken} />
              </div>
            </div>

            <div className="flex justify-center mt-2">
              <Button
                id="signaturePhotoCapture"
                title={intl.formatMessage({
                  id: 'capture',
                  defaultMessage: 'Capturar',
                })}
                click={this.capturePhoto}
              />
              <Button
                id="signaturePhotoSave"
                title={intl.formatMessage({ id: 'save', defaultMessage: 'Guardar' })}
                disabled={!photoTaken}
                click={this.handleSave}
              />
              <Button
                id="signaturePhotoCancel"
                title={intl.formatMessage({
                  id: 'cancel',
                  defaultMessage: 'Cancelar',
                })}
                click={this.handleCancel}
              />
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );
  }
}

export default connect(null, storeActions)(injectIntl(SignatureTakePhoto));
