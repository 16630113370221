import React from 'react';
import { ResultsModalView } from './ResultsModalView';

interface ResultsModalViewModelProps {
  onModalChange: (isOpen: boolean) => void;
  description: string;
  name: string;
}

export function ResultsModalViewModel({ onModalChange, name, description }: ResultsModalViewModelProps): JSX.Element {
  return <ResultsModalView onAccept={onModalChange} name={name} description={description} />;
}
