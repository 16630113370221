import { useEffect, useState } from 'react';
import { getAllow, getGrandParentRestrictions, getParentPermission, getTitlePermission } from '../utils';
import { getGroupMenuAsync } from '../../../../../../api/securityManager';
import { DEFAULT_RESTRICTION_KEY } from '../../../../models/constants';
import { RestrictionObject } from '../../../../models/restrictionObject';
import { Restriction, Restrictions, useRestrictions } from './useRestrictions.hook';

export interface UseMenu {
  menu: MenuTree[];
  defaultRestriction?: Restriction;
}

interface MenuService {
  descripcion: string;
  identificacion: number;
  titulo: number;
}

export interface MenuTree {
  key: string;
  label: string;
  restriction: RestrictionObject;
  nodes: MenuTree[];
}

export const MENU_TYPE: string = 'M';

export function useMenu(parentGroup: string, group: string): Promise<UseMenu> {
  const [defaultRestriction, setDefaultRestriction] = useState<Restriction>();
  const [loaded, setLoaded] = useState(false);
  const [menuOptions, setMenuOptions] = useState<MenuTree[]>([]);
  const [grandParentRestrictions, setGrandParentRestrictions] = useState(null);
  const { fillNumberWithZeros, getMenuRestrictionsAsync } = useRestrictions();

  async function getMenuAsync(): Promise<void> {
    const [menu, parentRestrictions, ownRestrictions] = await Promise.all<MenuService[], Restrictions, Restrictions>([
      getGroupMenuAsync(),
      getMenuRestrictionsAsync(parentGroup),
      getMenuRestrictionsAsync(group),
    ]);

    const options: MenuTree[] = [];
    let lastTitle: number | null = null;
    let lastHeader: MenuTree | null = null;

    let defaultAllow = ownRestrictions[DEFAULT_RESTRICTION_KEY]?.allow || 0;

    let defaultRestrictionVal = ownRestrictions[DEFAULT_RESTRICTION_KEY] || {
      group: group,
      id: DEFAULT_RESTRICTION_KEY,
      allow: 0,
      type: 'M',
      title: DEFAULT_RESTRICTION_KEY,
      fechaHabilitacion: '',
      parentPermission: 0,
    };
    setDefaultRestriction(defaultRestrictionVal);

    menu?.forEach((menuOption, index) => {
      const isHeaderIdentification = menuOption.titulo !== lastTitle || lastTitle === null;
      const restrictionKey = fillNumberWithZeros(menuOption.titulo) + fillNumberWithZeros(menuOption.identificacion);
      const permissions: RestrictionObject = {
        ownPermission: getAllow(ownRestrictions, parentGroup, defaultAllow, restrictionKey, menuOption),
        parentPermission: getParentPermission(restrictionKey, menuOption, ownRestrictions, parentRestrictions, defaultAllow, grandParentRestrictions),
        titlePermission: getTitlePermission(menuOption, ownRestrictions, parentGroup, defaultAllow, menu),
        type: MENU_TYPE,
        allows: getAllow(ownRestrictions, parentGroup, defaultAllow, restrictionKey, menuOption),
        id: restrictionKey,
        title: ownRestrictions[restrictionKey] !== undefined ? ownRestrictions[restrictionKey].title : '',
      };

      let menuNode = {
        key: `${index} ${menuOption.descripcion}`,
        label: isHeaderIdentification
          ? `${menuOption.titulo} - ${menuOption.descripcion}`
          : `${menuOption.identificacion} - ${menuOption.descripcion}`,
        nodes: [],
        restriction: permissions,
      };

      if (isHeaderIdentification) {
        lastHeader = menuNode;
        if (menuOption.identificacion === 0) {
          options.push(menuNode);
        }
      } else {
        if (menuNode.restriction.parentPermission === null || menuNode.restriction.parentPermission === undefined) {
          menuNode.restriction.parentPermission = lastHeader ? lastHeader?.restriction.parentPermission : null;
        }
        if (menuOption.identificacion === 0) {
          if (menuNode.nodes.length > 0) {
            lastHeader?.nodes.push(menuNode);
          }
        } else {
          lastHeader?.nodes.push(menuNode);
        }
      }

      lastTitle = menuOption.titulo;
    });

    setMenuOptions(options);
  }

  useEffect(() => {
    if (loaded) {
      getMenuAsync();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, loaded]);

  const getGrandParentRestrictionsAsync = async () => {
    let restrictions = await getGrandParentRestrictions(parentGroup, null, getMenuRestrictionsAsync);
    setGrandParentRestrictions(restrictions);
    setLoaded(true);
  };

  useEffect(() => {
    setLoaded(false);
    getGrandParentRestrictionsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return {
    menu: menuOptions,
    defaultRestriction: defaultRestriction,
  };
}
