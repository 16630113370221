import React, { useState } from 'react';
import { store } from '../../../store';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFilterField, getFilterFieldValue } from './filterFields';
import { getGroupDescriptions, getUsuariosBasicInfo } from '../../../api/bitacora';
import { getHelpWindow, getHelpButton, getColumnsInicialesMod, getColumnsGrupo } from './helpOperations';
import DateSelector from '../DateSelector';
import { getBitacoraRestriccionesFilterResult } from './filterRestricciones';
import Field from '../components/Field';
import { exportRestricciones } from './exportRestricciones';
import { css, StyleSheet } from 'aphrodite';
import ModalMessage from '../../ModalMessage';
import { createNullDate } from '../../../../UI-kit/helpers/dateHelper';

const ConsultaRestricciones = (props) => {
  const bitacoraApp = store.getState().bitacoraApp;
  let { filterListID } = props;
  const [helpColumns, setHelpColumns] = useState([]);
  const [helpData, setHelpData] = useState([]);
  const [title, setTitle] = useState([]);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  let bMenuInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.bMenu : true;
  let bOperacionesInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.bOperaciones : true;
  let bReportesInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.bReportes : true;
  let bPermisosInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.bPermisos : true;
  let bSucursalesInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.bSucursales : true;
  let bNavegacionesInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.bNavegaciones : true;
  const [menuChecked, setMenuChecked] = useState(bMenuInitialValue);
  const [operacionesChecked, setOperacionesChecked] = useState(bOperacionesInitialValue);
  const [reportesChecked, setReportesChecked] = useState(bReportesInitialValue);
  const [permisosChecked, setPermisosChecked] = useState(bPermisosInitialValue);
  const [sucursalesChecked, setSucursalesChecked] = useState(bSucursalesInitialValue);
  const [navegacionesChecked, setNavegacionesChecked] = useState(bNavegacionesInitialValue);
  const [enabledButton, setEnabledButton] = useState(false);
  const [tipoCambio, setTipoCambio] = useState(0);
  const [disabledButtonPrev, setDisabledButtonPrev] = useState(bitacoraApp[filterListID] ? bitacoraApp[filterListID].buttonPrevState : true);
  const [disabledButtonNext, setDisabledButtonNext] = useState(bitacoraApp[filterListID] ? bitacoraApp[filterListID].buttonNextState : true);
  const [beginData, setBeginData] = useState(0);

  const filterFields = {
    grupo: {
      getData: getGroupDescriptions,
      getColumns: getColumnsGrupo,
      title: intl.formatMessage({
        id: 'Tablas.bitacora_Grupo',
        defaultMessage: 'Grupo',
      }),
    },
    inicialesmodificador: {
      getData: getUsuariosBasicInfo,
      getColumns: getColumnsInicialesMod,
      title: intl.formatMessage({
        id: 'inicialesModificador',
        defaultMessage: 'Iniciales del Modificador',
      }),
    },
  };

  const generateFilterField = (id) => {
    let helpButton = null;
    if (filterFields[id]) {
      helpButton = getHelpButton(id, filterFields[id], setHelpColumns, setHelpData, setLoading, setTitle, helpData ? helpData.length : 0);
    }

    const fieldElement = getFilterField(id, updateFilterInfo, intl, helpButton, filterListID);

    return fieldElement;
  };

  const updateChecks = () => {
    let bMenu = document.getElementById('bMenu') && document.getElementById('bMenu').checked;
    let bOperaciones = document.getElementById('bOperaciones') && document.getElementById('bOperaciones').checked;
    let bReportes = document.getElementById('bReportes') && document.getElementById('bReportes').checked;
    let bPermisos = document.getElementById('bPermisos') && document.getElementById('bPermisos').checked;
    let bSucursales = document.getElementById('bSucursales') && document.getElementById('bSucursales').checked;
    let bNavegaciones = document.getElementById('bNavegaciones') && document.getElementById('bNavegaciones').checked;

    if (!bMenu && !bOperaciones && !bReportes && !bPermisos && !bSucursales && !bNavegaciones) {
      setEnabledButton(true);
    } else {
      setEnabledButton(false);
    }
  };

  const updateFilterInfo = (e, first, max, nullDates) => {
    const dateText = intl.formatMessage({
      id: 'date',
      defaultMessage: 'date',
    });
    const motivoText = intl.formatMessage({
      id: 'motivo',
      defaultMessage: 'motivo',
    });
    let fechaHoraDesde, fechaHoraHasta;
    if (nullDates) {
      let fromTime = createNullDate();
      let toTime = createNullDate();
      fechaHoraDesde = fromTime;
      fechaHoraHasta = toTime;
    } else {
      if (store.getState().bitacoraApp[filterListID] && store.getState().bitacoraApp[filterListID].filterParams) {
        fechaHoraDesde = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraDesde;
        fechaHoraHasta = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraHasta;
      }
    }
    let filterParams = {
      fechaHoraDesde,
      fechaHoraHasta,
      firstResult: first,
      maxResults: max,
      bMenu: getFilterFieldValue('bMenu', e, true),
      bOperaciones: getFilterFieldValue('bOperaciones', e, true),
      bReportes: getFilterFieldValue('bReportes', e, true),
      bPermisos: getFilterFieldValue('bPermisos', e, true),
      bSucursales: getFilterFieldValue('bSucursales', e, true),
      bNavegaciones: getFilterFieldValue('bNavegaciones', e, true),
      codigorestriccion: getFilterFieldValue('codigorestriccion', e),
      tipodecambio: tipoCambio,
      grupo: getFilterFieldValue('grupo', e),
      inicialesmodificador: getFilterFieldValue('inicialesmodificador', e),
      persistenceUnit: 'Local',
      compareVisibleRows: [],
      compareTextAreaFields: dateText + ': {fecha_hora_modificado}\r\n' + motivoText + ': {motivo}\r\n{descripcion_restriccion}',
    };
    if (store.getState().bitacoraApp[filterListID]) {
      filterParams = { ...store.getState().bitacoraApp[filterListID].filterParams, ...filterParams };
    }
    let filterData = {};
    filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
    store.setState({
      bitacoraApp: {
        ...store.getState().bitacoraApp,
        ...filterData,
      },
    });
    closeHelp();
  };

  const setFilterFields = () => {
    document.getElementById('bMenu').checked = true;
    document.getElementById('bOperaciones').checked = true;
    document.getElementById('bReportes').checked = true;
    document.getElementById('bPermisos').checked = true;
    document.getElementById('bSucursales').checked = true;
    document.getElementById('bNavegaciones').checked = true;
    document.getElementById('codigorestriccion').value = '';
    document.getElementById('grupo').value = '';
    document.getElementById('inicialesmodificador').value = '';
    document.getElementById('selectEstado').value = '0';
    setMenuChecked(true);
    setOperacionesChecked(true);
    setReportesChecked(true);
    setPermisosChecked(true);
    setSucursalesChecked(true);
    setNavegacionesChecked(true);
    setEnabledButton(false);
  };

  const closeHelp = () => {
    setHelpColumns([]);
    setHelpData([]);
    setTitle('');
  };

  const helpAcceptHandler = (value, control) => {
    document.getElementById(control).value = value;
    let filterData = {};
    let controlEntry = {};
    controlEntry[control] = value;
    filterData[filterListID] = {
      ...store.getState().bitacoraApp[filterListID],
      filterParams: { ...store.getState().bitacoraApp[filterListID].filterParams, ...controlEntry },
    };
    store.setState({
      bitacoraApp: {
        ...store.getState().bitacoraApp,
        ...filterData,
      },
    });
    closeHelp();
  };

  let fields = [];
  for (let field of Object.keys(filterFields)) {
    fields.push(generateFilterField(field));
  }

  const styles = StyleSheet.create({
    topazDisplay: {
      pointerEvents: loading ? 'none' : 'auto',
      cursor: loading ? 'wait' : 'auto',
    },
    overlay: {
      width: '100%',
      height: '100%',
      minHeight: '100%',
      zIndex: 100,
      position: 'absolute',
      top: 0,
      left: 0,
      cursor: 'wait',
    },
  });

  const handleBack = (e) => {
    let maxResults = store.getState().bitacoraApp[filterListID].filterParams.maxResults;
    let diff = beginData - maxResults;
    if (diff < 0) {
      diff = 0;
    }
    setBeginData(diff);
    updateFilterInfo(e, diff, 201);
    getBitacoraRestriccionesFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
    );
  };

  const handleFilter = (e) => {
    let fechaDesde = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraDesde;
    if (fechaDesde === undefined) {
      fechaDesde = createNullDate();
    }

    let fechaHasta = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraHasta;
    if (fechaHasta === undefined) {
      fechaHasta = createNullDate();
    }
    if (fechaHasta < fechaDesde) {
      setShowModal(true);
    }

    setLoading(true);
    setBeginData(0);
    updateFilterInfo(e, 0, 201);
    getBitacoraRestriccionesFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
      setLoading,
    );
  };

  const handleExportCSV = (e) => {
    let state = store.getState();
    if (!state.bitacoraApp[filterListID].resultColumns || state.bitacoraApp[filterListID].resultColumns.length === 0) {
      props.showModal(<FormattedMessage id="noData" defaultMessage={`No hay datos!`} />);
    } else {
      updateFilterInfo(e, 0, 201);
      exportRestricciones(
        store.getState().bitacoraApp[filterListID].filterParams,
        store.getState().bitacoraApp[filterListID].resultColumns,
        filterListID,
        intl,
      );
    }
  };

  const handleCleanFilters = (e) => {
    setFilterFields();
    updateFilterInfo(e, 0, 201, true);
    getBitacoraRestriccionesFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
    );
  };

  const handleNext = (e) => {
    setBeginData(beginData + 201);
    updateFilterInfo(e, beginData + 201, 201);
    getBitacoraRestriccionesFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
      <div className={css(loading && styles.overlay)}></div>
      <DateSelector filterListID={filterListID} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        <div className="flex content-center mb-2 text-sm">
          {showModal && (
            <ModalMessage
              message={
                <FormattedMessage
                  id="message_desde_no_mayor_que_hasta"
                  defaultMessage={`El valor de la fecha 'Desde' no puede ser mayor que 'Hasta'`}
                />
              }
              handleAccept={async () => {
                setShowModal(false);
              }}
              acceptTitle={'Aceptar'}
              draggable={true}
            />
          )}
          <input
            id="bMenu"
            checked={menuChecked}
            type="checkbox"
            className="focus:shadow-sm-light ml-10 mt-1"
            onChange={(e) => {
              setMenuChecked(!menuChecked);
              updateChecks();
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2  mt-1">
            <FormattedMessage id="Restricciones.Menu" defaultMessage="Menu" />
          </label>
          <input
            id="bOperaciones"
            checked={operacionesChecked}
            type="checkbox"
            className="focus:shadow-sm-light ml-10  mt-1"
            onChange={(e) => {
              setOperacionesChecked(!operacionesChecked);
              updateChecks();
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2  mt-1">
            <FormattedMessage id="Restricciones.Operaciones" defaultMessage="Operaciones" />
          </label>
          <input
            id="bPermisos"
            checked={permisosChecked}
            type="checkbox"
            className="focus:shadow-sm-light ml-10  mt-1"
            onChange={(e) => {
              setPermisosChecked(!permisosChecked);
              updateChecks();
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2  mt-1">
            <FormattedMessage id="Restricciones.Permisos" defaultMessage="Permisos" />
          </label>
          <input
            id="bReportes"
            checked={reportesChecked}
            type="checkbox"
            className="focus:shadow-sm-light ml-10  mt-1"
            onChange={(e) => {
              setReportesChecked(!reportesChecked);
              updateChecks();
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2  mt-1">
            <FormattedMessage id="Restricciones.Reportes" defaultMessage="Reportes" />
          </label>
          <input
            id="bSucursales"
            checked={sucursalesChecked}
            type="checkbox"
            className="focus:shadow-sm-light ml-10  mt-1"
            onChange={(e) => {
              setSucursalesChecked(!sucursalesChecked);
              updateChecks();
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2  mt-1">
            <FormattedMessage id="Restricciones.Sucursales" defaultMessage="Sucursales" />
          </label>
          <input
            id="bNavegaciones"
            checked={navegacionesChecked}
            type="checkbox"
            className="focus:shadow-sm-light ml-10  mt-1"
            onChange={(e) => {
              setNavegacionesChecked(!navegacionesChecked);
              updateChecks();
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2  mt-1">
            <FormattedMessage id="Restricciones.Navegaciones" defaultMessage="Navegaciones" />
          </label>
          <div style={{ width: '25%' }} className="focus:shadow-sm-light ml-10">
            <Field htmlFor="restriccion" labelText={<FormattedMessage id="Restricciones.codigoRestriccion" defaultMessage="Código de Restricción" />}>
              <input
                className="focus:shadow-sm-light ml-2"
                id="codigorestriccion"
                type="text"
                style={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'rgb(220, 224, 230)',
                  borderRadius: '2px',
                  width: '60px',
                }}
              />
            </Field>
          </div>
          <div style={{ width: '25%' }} className="focus:shadow-sm-light ml-10">
            <span className="text-xs">{<FormattedMessage id="Restricciones.tipoCambio" defaultMessage="Tipo de Cambio" />}</span>
            <select id="selectEstado" onChange={(e) => setTipoCambio(e.target.value)}>
              <option value="0">
                {intl.formatMessage({
                  id: 'Restricciones.Estado_Todos',
                  defaultMessage: 'Todos',
                })}
              </option>
              <option value="1">
                {intl.formatMessage({
                  id: 'Restricciones.Estado_Habilitado',
                  defaultMessage: 'Habilitado',
                })}
              </option>
              <option value="2">
                {intl.formatMessage({
                  id: 'Restricciones.Estado_Deshabilitado',
                  defaultMessage: 'Deshabilitado',
                })}
              </option>
              <option value="3">
                {intl.formatMessage({
                  id: 'Restricciones.Estado_Libre',
                  defaultMessage: 'Libre',
                })}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        {fields}
      </div>
      {getHelpWindow(helpData, helpColumns, helpAcceptHandler, closeHelp, title)}

      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        <button
          className={
            disabledButtonPrev
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          disabled={disabledButtonPrev}
          onClick={(e) => handleBack(e)}
        >
          {'<<'}
        </button>
        <button
          className={
            enabledButton
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          onClick={(e) => handleFilter(e)}
        >
          <FormattedMessage id="filtrar" defaultMessage={`Filtrar`} />
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={(e) => handleExportCSV(e)}
        >
          <FormattedMessage id="exportarCSV" defaultMessage={`Exportar CSV`} />
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={(e) => handleCleanFilters(e)}
        >
          <FormattedMessage id="borrarFiltro" defaultMessage={`Limpiar Filtros`} />
        </button>
        <button
          className={
            disabledButtonNext
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          disabled={disabledButtonNext}
          onClick={(e) => handleNext(e)}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default ConsultaRestricciones;
