import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { useGlobalStyles } from '../../../../styles/GlobalStylesContext';
import Field from './Field';

const AccountInput = ({ account, onAccountChange }) => {
  const intl = useIntl();

  const placeholderAccount = intl.formatMessage({
    id: 'account',
    defaultMessage: 'Cuenta',
  });

  const styles = StyleSheet.create({
    accountInput: {
      width: '10rem',
    },
  });

  const globalStyles = useGlobalStyles();

  return (
    <Field labelText={placeholderAccount} htmlFor="account">
      <NumberFormat
        type="text"
        id="account"
        name="account"
        className={css(globalStyles.input, globalStyles.numberInput, styles.accountInput)}
        placeholder={placeholderAccount}
        value={account}
        onChange={(event) => onAccountChange(event.target.value)}
        onFocus={(e) => e.target.select()}
        decimalScale={0} // allow only integers
      />
    </Field>
  );
};

export default AccountInput;
