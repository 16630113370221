/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import { PulseLoader } from 'halogenium';
import { connect } from 'unistore/react';
import 'react-table/react-table.css';
import { useIntl, FormattedMessage } from 'react-intl';
import BranchesListView from './BranchesListView';
import { storeActions } from '../../store';
import { formatDateTimePostings } from '../../helpers/postingManagerHelper';
import { css, StyleSheet } from 'aphrodite';
import { BranchesButtonPanel } from './BranchesButtonPanel';
import { BranchesSelector } from './BranchesSelector';
import { BranchesChangeDate } from './BranchesChangeDate';
import { BranchChangeDate } from './BranchChangeDate';
import ModalMessage from '../ModalMessage';
import { changeDate, updateBranch } from '../../api/branches';
import { dateRunFormat } from '../../../UI-kit/helpers/dateHelper';

const LoaderComponent = (props) =>
  props.loading ? (
    <div className="-loading -active">
      <div className="-loading-inner">
        <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
      </div>
    </div>
  ) : null;

export const BranchesList = (props) => {
  const { branches, virtualBranches, hasVirtual, fetchBranches } = props;

  const intl = useIntl();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeBranches, setactivesdBranches] = useState([]);
  const [buttonStates, setbuttonStates] = useState(' ');
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [tipoSeleccion, setTipoSeleccion] = useState(1);
  const [showDialogDate, setShowDialogDate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [textDialog, setTextDialog] = useState('');
  const [showChangeBranch, setShowChangeBranch] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);

  let buttonSelected = useRef(0);
  const refChecks = useRef([]);
  const refBranchList = useRef();
  const refListEntry = useRef();

  refChecks.current = [];
  refListEntry.current = [];

  /* useEffect(() => {
    fetch(tipoSeleccion);
    buttonManager(selectedBranches);
  }, [branches, fetch, selectedBranches, tipoSeleccion, virtualBranches]);
  */

  useEffect(() => {
    const fetchData = async (tipoSeleccion, selectedBranches) => {
      fetch(tipoSeleccion, false);
      buttonManager(selectedBranches);
    };

    fetchData(tipoSeleccion, selectedBranches);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, virtualBranches]);

  useEffect(() => {
    fetch('1', false);
  }, [fetch]);

  const fetch = (virtuals, change) => {
    setTipoSeleccion(virtuals);
    setLoading(true);
    let activeBranches = branches;
    if (virtuals === '2') {
      activeBranches = virtualBranches;
    }
    setactivesdBranches(activeBranches);
    const crows = createRows(activeBranches);
    setRows(crows);
    setLoading(false);
    updateSelectedBranches(activeBranches, change);
  };

  const clickDoubleHandler = (suc) => {
    const branch = activeBranches.filter((item) => item.number === parseInt(suc.number, 10));
    setItemSelected(branch[0]);
    setShowChangeBranch(true);
  };

  const handleSelectedBranch = (row, selected) => {
    let listSelected = [...selectedBranches];
    let listReturn = [];
    const branch = activeBranches.filter((item) => item.number === parseInt(row.number, 10));

    if (selected) {
      listReturn = addSelectedBranch(branch[0], listSelected);
    } else {
      listReturn = removeBranch(branch[0], listSelected);
    }
    setSelectedBranches(listReturn);
  };

  const addToRefs = (r) => {
    if (r && !refChecks.current.includes(r)) {
      refChecks.current.push(r);
    }
  };

  const addToRefListEntry = (r) => {
    if (r && !refListEntry.current.includes(r)) {
      refListEntry.current.push(r);
    }
  };

  const setShowChangeDateState = (state) => {
    setShowDialogDate(state);
  };

  const setModal = (state, text) => {
    setShowModal(state);
    setTextDialog(text);
  };

  const setModal2 = (state, text) => {
    setShowModal2(state);
    setTextDialog(text);
  };

  const setButtonAcceptModal2 = () => {
    buttonSelected.current = 2;
    setShowModal(false);
    setShowDialogDate(false);
    setShowModal2(false);
  };

  const setButtonCanceltModal2 = () => {
    buttonSelected.current = 1;
    setShowModal(false);
    setShowDialogDate(false);
    setShowModal2(false);
  };

  const updateBranchSelected = async (branch) => {
    await updateBranch(branch);
    reloadBranches();
  };

  const getVeriyLoop = async (selectedBranches, selectedDate) => {
    if (buttonSelected.current === 2) {
      try {
        for (const sData of selectedBranches) {
          try {
            let data = dateRunFormat(selectedDate);
            let ret = await changeDate(data, sData);
            if (ret !== true) {
              setModal(true, ret);
            }
          } catch (ex) {
            setModal(true, 'Error al cambiar la fecha de proceso.');
          }
        }
        reloadBranches();
        buttonSelected.current = 0;
        return;
      } catch (err) {
        buttonSelected.current = 0;
        return;
      }
    }
    if (buttonSelected.current === 1) {
      buttonSelected.current = 0;
      setShowChangeDateState(false);
      return;
    }
    setTimeout(() => getVeriyLoop(selectedBranches, selectedDate), 1000);
  };

  const updateSelectedBranches = (actbr, change) => {
    let listReturn = [];
    if (change === false) {
      let listSelected = [...selectedBranches];
      listSelected.map((item) => {
        const branch = actbr.filter((element) => item && item.number === element.number);
        listReturn.push(branch[0]);
      });
    }
    setSelectedBranches(listReturn);
  };

  const handleASelectedState = (state) => {
    let listSelected = [...selectedBranches];
    let listReturn = [];
    setbuttonStates(' ');
    refListEntry.current.map((data) => {
      const branch = activeBranches.filter((item) => item.number === parseInt(data.props.name, 10));
      if (branch) {
        const dataCheck = refChecks.current.filter((item) => parseInt(item.name, 10) === parseInt(data.props.name, 10));
        if (state === 'N') {
          if (data.getCheckState() === true) {
            dataCheck[0].checked = false;
            data.setStatesChecks(false);
            listReturn = removeBranch(branch[0], listSelected);
          }
        } else {
          if (branch[0].status === state && data.getCheckState() === false) {
            dataCheck[0].checked = true;
            data.setStatesChecks(true);
            listReturn = addSelectedBranch(branch[0], listSelected);
          } else {
            if (branch[0].status !== state && data.getCheckState() === true) {
              dataCheck[0].checked = false;
              data.setStatesChecks(false);
              listReturn = removeBranch(branch[0], listSelected);
            }
          }
        }
      }
      listSelected = listReturn;
    });
    setSelectedBranches(listSelected);
  };

  const handleActiveBranches = (virtuals, change) => {
    setbuttonStates(' ');
    fetch(virtuals, change);
  };

  const addSelectedBranch = (branch, listSelected) => {
    listSelected.push(branch);
    buttonManager(listSelected);
    return listSelected;
  };

  const removeBranch = (branch, listSelected) => {
    let branchs = listSelected.filter((element) => element.number !== branch.number);
    buttonManager(branchs);
    return branchs;
  };

  const buttonManager = (branchesSelected) => {
    const status_A = branchesSelected.filter((item) => item.status === 'A');

    const status_C = branchesSelected.filter((item) => item.status === 'C');

    const status_F = branchesSelected.filter((item) => item.status === 'F');

    if (status_A.length > 0) {
      if (status_C.length === 0 && status_F.length === 0) {
        setbuttonStates('A');
      }
      if (status_C.length > 0 && status_F.length === 0) {
        setbuttonStates('AC');
      }
      if (status_C.length === 0 && status_F.length > 0) {
        setbuttonStates('AF');
      }
    }

    if (status_A.length === 0 && status_C.length > 0) {
      if (status_F.length > 0) {
        setbuttonStates('CF');
      } else {
        setbuttonStates('C');
      }
    }
    if (status_A.length === 0 && status_C.length === 0 && status_F.length > 0) {
      setbuttonStates('F');
    }

    if (branchesSelected.length === 0 || (status_A.length > 0 && status_C.length > 0 && status_F.length > 0)) {
      setbuttonStates(' ');
    }
  };

  const reloadBranches = () => {
    fetchBranches();
  };

  const getBranchs = () => {
    return branches;
  };

  const getSelectedBranches = () => {
    return selectedBranches;
  };

  const addBranchListRef = (element) => {
    refBranchList.current = element;
  };

  const createRows = (branches) => {
    let crows = [];
    if (branches && branches instanceof Array) {
      branches.map((sData) => {
        let { description, number, processDate, previousProcessDate, nextProcessDate, status } = sData;
        let row = {
          status,
          description,
          number,
          previousProcessDate: formatDateTimePostings(previousProcessDate),
          processDate: formatDateTimePostings(processDate),
          nextProcessDate: formatDateTimePostings(nextProcessDate),
        };
        crows.push(row);
      });
    }
    return crows;
  };

  const styles = StyleSheet.create({
    content: {
      display: 'flex',
      height: '100%',
      paddingBottom: '1rem',
      width: '100%',
    },
    branchContainer: {
      position: 'relative',
      width: '100%',
      height: 'calc(100% - 3rem)',
      margin: '1.5rem 0 .5rem',
      padding: '.5rem .5rem 1.5rem .5rem',
    },
  });

  return (
    <div className={css(styles.branchContainer)}>
      <h2 className="text-left text-lg mb-3 font-normal">
        <FormattedMessage id="branchesSidebar" defaultMessage={`Manejador de Sucursales`} />
      </h2>
      {showDialogDate && (
        <BranchesChangeDate
          getSelectedBranches={getSelectedBranches}
          setShowChangeDateState={setShowChangeDateState}
          reloadBranches={reloadBranches}
          getVeriyLoop={getVeriyLoop}
          intl={intl}
          setModal={setModal}
          setModal2={setModal2}
        ></BranchesChangeDate>
      )}
      {showChangeBranch && (
        <BranchChangeDate
          getSelectedBranches={getSelectedBranches}
          setShowChangeDateState={setShowChangeBranch}
          reloadBranches={reloadBranches}
          intl={intl}
          item={itemSelected}
          updateBranch={updateBranchSelected}
        ></BranchChangeDate>
      )}
      {showModal && (
        <ModalMessage
          message={textDialog}
          handleAccept={async () => {
            setShowModal(false);
          }}
          acceptTitle={'Aceptar'}
          draggable={true}
        />
      )}
      {showModal2 && (
        <ModalMessage
          message={textDialog}
          handleAccept={async () => {
            setButtonAcceptModal2();
          }}
          acceptTitle={'Aceptar'}
          handleCancel={() => {
            setButtonCanceltModal2();
          }}
          cancelTitle={'Cancelar'}
          draggable={true}
        />
      )}
      <div className={css(styles.content)}>
        <div className="flex-grow mr-4 rounded border border-light flex">
          <BranchesListView
            data={rows}
            loading={loading}
            LoadingComponent={LoaderComponent}
            defaultPageSize={30}
            className="-striped -highlight bg-grey-lighter-lighter"
            noDataText={
              <p className="text-sm w-full text-center leading-normal">
                <FormattedMessage id="noBranchFound" defaultMessage={`No se encontraron sucursales`} />
                <br />
              </p>
            }
            addToRefs={addToRefs}
            ref={(ref) => addBranchListRef(ref)}
            handleSelectedBranch={async (item, selected) => {
              handleSelectedBranch(item, selected);
            }}
            addToRefListEntry={addToRefListEntry}
            clickDoubleHandler={clickDoubleHandler}
          />
        </div>
      </div>
      {hasVirtual === true ? (
        <div>
          <BranchesSelector handleActiveBranches={handleActiveBranches} />
        </div>
      ) : (
        <div></div>
      )}
      <div>
        <BranchesButtonPanel
          getSelectedBranches={getSelectedBranches}
          getBranchs={getBranchs}
          handleASelectedState={(state) => {
            handleASelectedState(state);
          }}
          reloadBranches={reloadBranches}
          buttonStates={buttonStates}
          setShowChangeDateState={setShowChangeDateState}
          setModal={setModal}
        />
      </div>
    </div>
  );
};

export default connect(null, storeActions)(BranchesList);
