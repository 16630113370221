import { store } from '../../../store';
import { getBitacoras } from '../../../api/bitacora';
import { formatDateForServer } from './filterFields';
import { exportToCsv } from './exportCSV';

const exportBitacora = (filterParams, columns, filterListID) => {
  let {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    codigo,
    descripcion,
    programa,
    usuario,
    mnemotecnico,
    usuariored,
    macaddress,
    maquina,
    sucursal,
    operacion,
    direccionIP,
    persistenceUnit,
  } = filterParams;

  let filterData = {};
  filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
  store.setState({
    bitacoraApp: {
      ...store.getState().bitacoraApp,
      ...filterData,
    },
  });

  let defaultValue;
  fechaHoraDesde = formatDateForServer(fechaHoraDesde);
  fechaHoraHasta = formatDateForServer(fechaHoraHasta);
  firstResult = firstResult || 0;
  maxResults = 10000;
  codigo = codigo || defaultValue;
  descripcion = descripcion || defaultValue;
  programa = programa || defaultValue;
  usuario = usuario || defaultValue;
  mnemotecnico = mnemotecnico || defaultValue;
  usuariored = usuariored || defaultValue;
  macaddress = macaddress || defaultValue;
  direccionIP = direccionIP || defaultValue;
  maquina = maquina || defaultValue;
  sucursal = sucursal || defaultValue;
  operacion = operacion || defaultValue;
  persistenceUnit = persistenceUnit || 'Local';
  const bitacoraParams = {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    codigo,
    descripcion,
    programa,
    usuario,
    mnemotecnico,
    usuariored,
    macaddress,
    maquina,
    sucursal,
    operacion,
    direccionIP,
    persistenceUnit,
  };

  getBitacoras(bitacoraParams)
    .then((data) => {
      exportToCsv(data, 'bitacora.csv', columns);
    })
    .catch((err) => {
      console.log(err);
    });
};

export { exportBitacora };
