import { Input, NumberInput, DateInput, RadioInput, Grid, ComboInput, Checkbox } from '../../UI-kit';
import { run, undo, gotoLineWithBody } from '../../src/api/dialog';
import { createRunBody, createBodyGoToLine } from '../lib/bodyCreators';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { store, dispatchActions } from '../../src/store';
import _ from 'busyman';
import { getTL, generateLineIDString } from '../../UI-kit/helpers/operationHelper';
import { getTabIdByNodeId } from '../../src/selectors';

const TYPE_OF_INPUT = 0;
const TYPE_OF_COMBO_INPUT = 1;
const TYPE_OF_CHECK_BOX = 2;
const TYPE_OF_RADIO_INPUT = 3;
const TYPE_OF_READONLY_INPUT = 5;
const TYPE_OF_GRID = 6;
const TYPE_OF_COMBO_NO_EDIT_INPUT = 7;
const TYPE_OF_EDITABLE_GRID = 8;

const TYPE_INPUT = 32;
const TYPE_INPUT_AND_DISAPPEARS = 66;
const TYPE_INPUT_AND_DISPLAYS_KEY = 69;
const TYPE_PASSWORD = 79;
const TYPE_NO_INPUT = 68;
const TYPE_DISPLAYS_KEY_AND_OPTIONS = 73;
const TYPE_DISPLAYS_KEY = 67;

const MIN_GRID_HEIGHT = 120;

const INPUT_PARAMETER_TEXT_TYPE = 1;
const INPUT_PARAMETER_NUMBER_TYPE = 2;
const INPUT_PARAMETER_CURRENCY_TYPE = 3;
const INPUT_PARAMETER_DATE_TYPE = 4;
const INPUT_PARAMETER_FORMULA_TYPE = 6;

const CURRENCY_EDITION = 'I';
const CURRENCY_US_EDITION = 'i';

export default class FieldToDisplay {
  constructor({ idNode, displayInfo, lineID }) {
    this._nodeId = idNode;
    this._displayInfo = displayInfo;
    this._lineID = lineID;
    this.clientProperties = store.getState().clientProperties;
    this.useBackColor = this.clientProperties?.useBackColor;
  }

  getInputType(number) {
    if (this._displayInfo.inputParameters && this._displayInfo.inputParameters.type) {
      const inputParameters = this._displayInfo.inputParameters;
      const type = inputParameters.type;
      if (number === TYPE_OF_INPUT) {
        return {
          [INPUT_PARAMETER_TEXT_TYPE]: Input,
          [INPUT_PARAMETER_NUMBER_TYPE]: NumberInput,
          [INPUT_PARAMETER_CURRENCY_TYPE]: NumberInput,
          [INPUT_PARAMETER_DATE_TYPE]: DateInput,
          [INPUT_PARAMETER_FORMULA_TYPE]: Input,
        }[type];
      } else if (type === INPUT_PARAMETER_DATE_TYPE && number === TYPE_OF_READONLY_INPUT) {
        return DateInput;
      } else if (number === TYPE_OF_READONLY_INPUT && (type === INPUT_PARAMETER_NUMBER_TYPE || type === INPUT_PARAMETER_CURRENCY_TYPE)) {
        return NumberInput;
      }
    }
    return {
      [TYPE_OF_COMBO_INPUT]: ComboInput,
      [TYPE_OF_RADIO_INPUT]: RadioInput,
      [TYPE_OF_READONLY_INPUT]: Input,
      [TYPE_OF_GRID]: Grid,
      [TYPE_OF_EDITABLE_GRID]: Grid,
      [TYPE_OF_COMBO_NO_EDIT_INPUT]: ComboInput,
      [TYPE_OF_CHECK_BOX]: Checkbox,
    }[number];
  }

  transactionNumber() {
    return this._lineID ? this._lineID.transactionNumber : '';
  }

  lineNumber() {
    return this._lineID ? this._lineID.lineNumber : '';
  }

  font() {
    return this._displayInfo.fieldDisplayInfo.fieldDisplayInfo.font;
  }

  height() {
    return this._displayInfo.fieldDisplayInfo.position.height;
  }

  countIterativeDialog() {
    if (this._lineID && this._lineID.countIterativeDialog !== undefined) {
      return this._lineID.countIterativeDialog;
    }
    return 0;
  }

  countVariableRow() {
    if (this._lineID && this._lineID.countVariableRow !== undefined) {
      return this._lineID.countVariableRow;
    }
    return 0;
  }

  notVariableRow() {
    if (this._lineID && this._lineID.notVariableRow !== undefined) {
      return this._lineID.notVariableRow;
    }
    return false;
  }

  transactionLineLabel(ignoreNotVariableRows) {
    if (ignoreNotVariableRows) {
      return getTL(this._lineID);
    } else {
      return generateLineIDString(this._lineID);
    }
  }

  showBackground() {
    const show =
      (this._displayInfo.type && !(this._displayInfo.type === 73 || this._displayInfo.type === 67)) ||
      (this._displayInfo.fieldDisplayInfo &&
        this._displayInfo.fieldDisplayInfo.border === true &&
        (this._displayInfo.type === 73 || this._displayInfo.type === 67));
    return show;
  }

  showPrompt() {
    const type = this._displayInfo.type;
    const inputField = this._displayInfo.inputField;
    const inputType = this._displayInfo.inputType;
    const labelInfo = this._displayInfo.promptDisplayInfo;
    if (inputType === TYPE_OF_RADIO_INPUT) {
      return (
        (type === TYPE_INPUT ||
          type === TYPE_PASSWORD ||
          type === TYPE_INPUT_AND_DISAPPEARS ||
          type === TYPE_DISPLAYS_KEY ||
          type === TYPE_INPUT_AND_DISPLAYS_KEY ||
          type === TYPE_NO_INPUT) &&
        inputField
      );
    } else {
      if (!labelInfo) return false;
      return (
        inputType !== TYPE_OF_RADIO_INPUT &&
        (type === TYPE_INPUT ||
          type === TYPE_NO_INPUT ||
          type === TYPE_INPUT_AND_DISAPPEARS ||
          type === TYPE_PASSWORD ||
          type === TYPE_DISPLAYS_KEY_AND_OPTIONS)
      );
    }
  }

  goBack = async (focusElement, operationID, processContext) => {
    let res = await undo(operationID, this.transactionNumber(), this.lineNumber());
    const elementsRemove = res.lista.filter((component) => component['class-name'].includes('ItemToDeleteEvent'));
    const state = store.getState();

    elementsRemove.forEach((element) => {
      let lineIDKey = generateLineIDString(element.lineID);
      if (state.countVariableRow > 0 && state.variableRowFields.includes(lineIDKey)) {
        element.lineID.countVariableRow = state.countVariableRow;
        lineIDKey = generateLineIDString(element.lineID);
      } else if (state.countVariableRow > 1 && state.variableRowFields.includes(lineIDKey)) {
        element.lineID.countVariableRow = state.countVariableRow - 1;
        lineIDKey = generateLineIDString(element.lineID);
      }

      store.setState(dispatchActions.removeFromWindowStack(store.getState(), lineIDKey));
    });
    store.setState(dispatchActions.reverseFlow(store.getState()));
    processContext(res, 'Input Undo');
    store.setState(dispatchActions.reverseFlow(store.getState(), false));
  };

  continue = async (operationID, operationNumber, event, processContext) => {
    const inputType = this._displayInfo.inputType;
    const supported = [TYPE_OF_INPUT, TYPE_OF_COMBO_INPUT, TYPE_OF_RADIO_INPUT, TYPE_OF_CHECK_BOX, TYPE_OF_COMBO_NO_EDIT_INPUT];

    // If the current input isn't one of the inputs of the 'supported' array, do nothing
    if (!_.includes(supported, inputType)) return;
    const body = this.createBody(event, operationNumber);

    const tabId = getTabIdByNodeId(store.getState(), this._nodeId);
    store.setState(dispatchActions.setApiLoading(store.getState(), true, tabId));
    const res = await run(operationID, this.transactionNumber(), this.lineNumber(), body);
    processContext(res, 'Input Run').finally(() => {
      store.setState(dispatchActions.setApiLoading(store.getState(), false, tabId));
    });
  };

  showRequiredMessage = () => {
    const required = <FormattedMessage id="errorMandatoryField" defaultMessage={`es de ingreso obligatorio`} />;
    const msg = (
      <div>
        {this._displayInfo.prompt} {required}
      </div>
    );
    store.setState(dispatchActions.openGlobalModal(store.getState(), msg));
  };

  gotoLine = async (operationNumber, operationID, fieldData, processContext, body) => {
    let bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, this.transactionNumber(), this.lineNumber(), body);
    let resLine = await gotoLineWithBody(operationID, this.transactionNumber(), this.lineNumber(), bodyGoToLine);
    processContext(resLine, 'gotoLine input');
  };

  createBody = (event, operationNumber) => {
    const inputType = this._displayInfo.inputType;
    let bodyValue = '';
    if (inputType === TYPE_OF_CHECK_BOX) {
      var checkedChar = this._displayInfo.inputParameters.options[0].programOption;
      var uncheckedChar = this._displayInfo.inputParameters.options[1].userOption;
      bodyValue = event.target.checked ? checkedChar : uncheckedChar;
    } else {
      bodyValue = event.target.value;
    }
    let body = createRunBody(operationNumber, this.transactionLineLabel(true), bodyValue);

    if (this._displayInfo.inputParameters && this._displayInfo.inputParameters.type === INPUT_PARAMETER_CURRENCY_TYPE) {
      if (this._displayInfo.inputParameters.edition === CURRENCY_EDITION) {
        let currencyValue = event.target.value.split('.').join('').replace(/,/g, '.');
        body = createRunBody(operationNumber, this.transactionLineLabel(true), '' + currencyValue);
      } else if (this._displayInfo.inputParameters.edition === CURRENCY_US_EDITION) {
        let currencyUSValue = event.target.value.split(',').join('');
        body = createRunBody(operationNumber, this.transactionLineLabel(true), '' + currencyUSValue);
      }
    }
    return body;
  };

  handleKeyDown = async (params) => {
    const { event, operationID, operationNumber, processContext, focusElement, showingHelp } = params;
    if (showingHelp) {
      return;
    }
    switch (event.key) {
      case 'Escape':
        this.goBack(focusElement, operationID, processContext);
        break;
      case 'Tab':
        if (event.shiftKey) {
          this.goBack(focusElement, operationID, processContext);
        } else {
          this.continue(operationID, operationNumber, event, processContext);
        }
        break;
      case 'Enter':
        this.continue(operationID, operationNumber, event, processContext);
        break;
      default:
        break;
    }
  };

  handleBlur = async (params) => {
    const { event, operationID, operationNumber, processContext } = params;
    // Continues if the focus is on the ACCEPT button, or if the focus is not on any other field
    if (event.relatedTarget === null || event.relatedTarget.id.includes('acceptButton')) {
      this.continue(operationID, operationNumber, event, processContext);
    }
  };

  help = () => {
    const fieldsWithHelp = [TYPE_INPUT, TYPE_DISPLAYS_KEY_AND_OPTIONS, TYPE_INPUT_AND_DISPLAYS_KEY];
    return this._displayInfo.inputType !== 5 && this._displayInfo.help && _.includes(fieldsWithHelp, this._displayInfo.type);
  };

  readOnly = () => {
    return (
      this._displayInfo.inputType === TYPE_OF_READONLY_INPUT ||
      TYPE_DISPLAYS_KEY_AND_OPTIONS === this._displayInfo.type ||
      TYPE_DISPLAYS_KEY === this._displayInfo.type ||
      TYPE_NO_INPUT === this._displayInfo.type
    );
  };

  getValue = (fieldData) => {
    if (fieldData[this.transactionLineLabel()]) {
      return fieldData[this.transactionLineLabel()].value;
    }
  };

  props(fieldData) {
    return {
      nodeId: this._nodeId,
      type: this.getInputType(),
      inputType: this._displayInfo.type,
      label: this._displayInfo.prompt || '',
      position: this._displayInfo.fieldDisplayInfo.position,
      labelPosition: this._displayInfo.promptDisplayInfo && this._displayInfo.promptDisplayInfo.position,
      labelFont: this._displayInfo.promptDisplayInfo && this._displayInfo.promptDisplayInfo.font,
      font: this._displayInfo.fieldDisplayInfo && this._displayInfo.fieldDisplayInfo.font,
      align: this._displayInfo.fieldDisplayInfo && this._displayInfo.fieldDisplayInfo.font.align,
      promptBackColor:
        this._displayInfo.promptDisplayInfo && this.useBackColor === 'true' ? this._displayInfo.promptDisplayInfo.backColor : 'transparent',
      showPrompt: this.showPrompt(),
      showBackground: this.showBackground(),
      fieldNumber: this._displayInfo.fieldNumber,
      lineNumber: this.lineNumber(),
      transactionNumber: this.transactionNumber(),
      countIterativeDialog: this.countIterativeDialog(),
      countVariableRow: this.countVariableRow(),
      value: this.getValue(fieldData),
      readOnly: this.readOnly(),
      help: this.help(),
      multiLine: this._displayInfo.multiLine,
      mandatoryHelp: this._displayInfo.mandatoryHelp,
      transactionLineLabel: this.transactionLineLabel(),
      inputParameters: this._displayInfo.inputParameters,
      defaultValue: this._displayInfo.value,
      multipleSelection: this._displayInfo.multipleSelection,
      optional: this._displayInfo.optional,
      variableRow: this._displayInfo.variableRow,
      initiallyVisible: this._displayInfo.initiallyVisible,
      fieldData,
      onKeyDown: (e, operationID, operationNumber, processContext, focusElement, showingHelp, multiLine = false) => {
        const params = {
          fieldData,
          event: e,
          operationID,
          operationNumber,
          processContext,
          focusElement,
          showingHelp,
          multiLine,
        };
        this.handleKeyDown(params);
      },
      onBlur: (event, fieldData, focusElement, operationID, operationNumber, processContext) => {
        const params = {
          event,
          fieldData,
          focusElement,
          operationID,
          operationNumber,
          processContext,
        };
        this.handleBlur(params);
      },
    };
  }

  style() {
    return {
      height: this.height() < MIN_GRID_HEIGHT ? MIN_GRID_HEIGHT : this.height(),
      backgroundColor: this._displayInfo.backColor ? this.getBackgroundColor(this._displayInfo.guiType, this._displayInfo.backColor) : '#F4F5f7',
    };
  }

  options() {
    if (this._displayInfo.inputParameters && this._displayInfo.inputParameters.options) {
      if (this._displayInfo.inputType === TYPE_OF_COMBO_INPUT || this._displayInfo.inputType === TYPE_OF_COMBO_NO_EDIT_INPUT) {
        let options = this._displayInfo.inputParameters.options.map(({ description, userOption }) => ({
          label: userOption && userOption !== '' && userOption !== ' ' ? `${userOption} ${description}` : `${description}`,
          value: userOption,
        }));
        if (this._displayInfo.optional === true || (this._displayInfo.optional === false && this._displayInfo.value === undefined)) {
          options.unshift({ label: '', value: '' });
        }
        return options;
      } else {
        return this._displayInfo.inputParameters.options.map(({ description, userOption }) => ({
          label: description,
          value: userOption,
        }));
      }
    }
  }

  component() {
    return this.getInputType(this._displayInfo.inputType);
  }
}
