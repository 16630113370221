import React, { useState } from 'react';
import { setGroupsScheduler } from '../../../../api/processesManager';
import { ProcessSchedule } from '../../models/processSchedule';
import { PeriodicityTabView } from './PeriodicityTabView';

interface PeriodicityViewModelProps {
  schedulerList: ProcessSchedule[];
  isLoading: boolean;
  onRefreshData: () => void;
}

const REMOVE_CONDITION: number = 2;

export function PeriodicityTabViewModel({ schedulerList, isLoading, onRefreshData }: PeriodicityViewModelProps): JSX.Element {
  const [isModalConditionOpen, setIsModalConditionOpen] = useState<boolean>(false);
  const [isModalRemoveOpen, setIsModalRemoveOpen] = useState<boolean>(false);
  const [processCondition, setProcessCondition] = useState<ProcessSchedule>();

  function onModalContextChange(item: ProcessSchedule, isEditing: boolean): void {
    setProcessCondition(item);
    if (isEditing) {
      setIsModalConditionOpen(true);
    } else {
      setIsModalRemoveOpen(true);
    }
  }

  function onModalConditionChange(isOpen: boolean): void {
    setIsModalConditionOpen(isOpen);
  }

  function onModalRemoveChange(isAccept: boolean): void {
    setIsModalRemoveOpen(false);
    if (isAccept) {
      onRemoveAccept();
    }
  }

  async function onRemoveAccept(): Promise<void> {
    await setGroupsScheduler(processCondition, REMOVE_CONDITION);
    onRefreshData();
  }

  return (
    <PeriodicityTabView
      onModalContextChange={onModalContextChange}
      isLoading={isLoading}
      schedulerList={schedulerList}
      isModalConditionOpen={isModalConditionOpen}
      onModalConditionChange={onModalConditionChange}
      processCondition={processCondition}
      isModalRemoveOpen={isModalRemoveOpen}
      onModalRemoveChange={onModalRemoveChange}
      onRefreshData={onRefreshData}
    />
  );
}
