import React from 'react';
import NumberFormat from 'react-number-format';
import Slider from 'react-slick';
import { FormattedMessage } from 'react-intl';
import { fileArrayToBase64 } from '../helpers/fileHelper';
import { dateFormatInput, dateFormatSignature, CHANGE_DUE_DATE, CHANGE_DOC_CODE, CHANGE_DOC_NUMBER } from '../../src/helpers/signaturesHelper';
import SignatureContainer from './SignatureContainer';

const MAX_SLIDES_NAV = 10;

const SignaturesImages = ({
  personImages,
  handleChangeData,
  codesDocuments,
  isConsult,
  isSignature,
  isPhoto,
  isDoc,
  isUserAuthorizer,
  handleRemove,
  handleAuthorize,
  handleShowPreview,
  documentNumber,
  documentType,
  addDocument,
}) => {
  const numImages = personImages.filter((personImage) => !personImage.removed).length;
  const count = personImages.length;
  let modificar = false;

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // fade: true,
    arrows: numImages > 1 && true,
    focusOnSelect: true,
  };

  const settingsNavBar = {
    // infinite: false,
    slidesToShow: numImages < MAX_SLIDES_NAV ? numImages : MAX_SLIDES_NAV,
    slidesToScroll: 1,
    speed: 500,
    dots: numImages > 1 && true,
    arrows: numImages > 1 && true,
    centerMode: true,
    focusOnSelect: true,
  };

  let mainSlider = null;
  let navBar = null;
  let valueForDocumentNumber = null;
  let valueForDocumentType = null;
  if (documentNumber !== null) {
    valueForDocumentNumber = documentNumber;
  }
  if (documentType !== null) {
    valueForDocumentType = documentType;
  }
  return (
    <React.Fragment>
      <Slider
        {...settings}
        beforeChange={(oldI, newI) => {
          if (navBar) navBar.slickGoTo(newI);
        }}
        // asNavFor={navBar}
        ref={(slider) => (mainSlider = slider)}
      >
        {personImages.map((personImage, index) => {
          if (count - 1 === index) {
            if (!isConsult && addDocument) {
              modificar = true;
            }
          }
          let imageToRender = null;
          if (isSignature) imageToRender = personImage.firma;
          else if (isPhoto || isDoc) imageToRender = personImage.imagen;
          if (isDoc) {
            if (valueForDocumentNumber !== null && !personImage.numeroDocumento && personImage.numeroDocumento !== valueForDocumentNumber) {
              handleChangeData(personImage.id, valueForDocumentNumber, CHANGE_DOC_NUMBER);
            }
            if (valueForDocumentType !== null && !personImage.codigoDocumento && personImage.codigoDocumento !== valueForDocumentType) {
              handleChangeData(personImage.id, valueForDocumentType, CHANGE_DOC_CODE);
            }
          }

          return !personImage.removed ? (
            <SignatureContainer
              key={index}
              signature={fileArrayToBase64(imageToRender)}
              pending={isSignature && !personImage.autorizador}
              // selected={isConsult ? false : selectedIds.includes(personImage.id)}
              // click={() => { if (!isConsult) handleSelect(personImage.id) }}
              showOptions={true}
              showAuthorize={isUserAuthorizer && isSignature}
              handleRemove={!isConsult ? handleRemove : null}
              handleAuthorize={handleAuthorize}
              signatureId={personImage.id}
              showPreview={true}
              handleShowPreview={handleShowPreview}
            >
              {isSignature && (
                <span className="flex items-center text-sm">
                  <span className="px-1 inline-block w-1/2 text-right text-xxs uppercase text-grey pt-1">
                    <FormattedMessage id="expiration" defaultMessage={`Vencimiento`} />:
                  </span>
                  <input
                    type="date"
                    tabIndex="-1"
                    value={personImage.vencimiento ? dateFormatInput(personImage.vencimiento) : ''}
                    className={isConsult ? 'bg-transparent inline-block w-1/2' : ''}
                    disabled={isConsult}
                    onChange={(e) => {
                      if (!isConsult) {
                        const valueDate = e.target.value.split('-');
                        const newDate = dateFormatSignature(`${valueDate[0]}${valueDate[1]}${valueDate[2]}`);
                        handleChangeData(personImage.id, newDate, CHANGE_DUE_DATE);
                      }
                    }}
                  />
                </span>
              )}
              {isDoc && (
                <div>
                  <div className="flex items-center text-sm">
                    <span className="px-1 inline-block w-1/2 text-right text-xxs uppercase text-grey pt-1">
                      <FormattedMessage id="documentCode" defaultMessage={`Código Documento`} />
                    </span>
                    <select
                      className={`${isConsult ? 'bg-transparent inline-block w-1/2' : ''}`}
                      onChange={(e) => {
                        handleChangeData(personImage.id, e.target.value, CHANGE_DOC_CODE);
                      }}
                      value={valueForDocumentType !== null && !personImage.codigoDocumento ? valueForDocumentType : personImage.codigoDocumento}
                      disabled={!modificar}
                      tabIndex="-1"
                    >
                      {codesDocuments.map((codeDoc, index) => (
                        <option key={index} value={codeDoc.codigoDoc}>
                          {codeDoc.codigoDoc + ' - ' + codeDoc.descDoc}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex items-center text-sm">
                    <span className="px-1 inline-block w-1/2 text-right text-xxs uppercase text-grey pt-1">
                      <FormattedMessage id="documentNumber" defaultMessage={`Número Documento`} />
                    </span>
                    <NumberFormat
                      type={'text'}
                      tabIndex="-1"
                      onChange={(e) => {
                        handleChangeData(personImage.id, e.target.value, CHANGE_DOC_NUMBER);
                      }}
                      className={isConsult ? 'bg-transparent inline-block w-1/2 pl-2' : ''}
                      value={valueForDocumentNumber !== null && !personImage.numeroDocumento ? valueForDocumentNumber : personImage.numeroDocumento}
                      disabled={!modificar}
                      allowNegative={false}
                      decimalScale={0}
                    />
                  </div>
                </div>
              )}
            </SignatureContainer>
          ) : null;
        })}
      </Slider>
      <Slider
        {...settingsNavBar}
        beforeChange={(oldI, newI) => {
          if (mainSlider) mainSlider.slickGoTo(newI);
        }}
        // asNavFor={mainSlider}
        ref={(slider) => (navBar = slider)}
      >
        {personImages.map((personImage, index) => {
          let imageToRender = null;
          if (isSignature) imageToRender = personImage.firma;
          else if (isPhoto || isDoc) imageToRender = personImage.imagen;

          return !personImage.removed ? (
            <SignatureContainer key={index} signature={fileArrayToBase64(imageToRender)} thumbnails={true} click={() => {}} />
          ) : null;
        })}
      </Slider>
    </React.Fragment>
  );
};

export default SignaturesImages;
