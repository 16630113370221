import * as React from 'react';

import './MonitorMenu.scss';
import { GroupExecutionInfoViewModel } from './GroupExecutionInfoViewModel';
import { useIntl } from 'react-intl';
import { ResultsModalViewModel } from '../resultsModal/ResultsModalViewModel';
import { useState, useEffect } from 'react';
import { ALL_STATES_VALUE, FINISHED_PROCESS } from '../../models/constants';
import { MonitorMenuOptionsViewModel } from './filterMenu/MonitorMenuOptionsViewModel';
import moment from 'moment';
import { getGroupProcessInstance } from '../../../../api/processesManager';
import { GroupInstance } from '../../models/groupInstance';
import { FilterDropdown } from './filterMenu/FilterDropDown';
import browserStorage from '../../../../utils/browserStorage';
import { SelectedProcess } from '../../models/types';
import { GroupDefinition } from '../../models/groupDefinition';

interface MonitorMenuProps {
  executedProcesses: number[];
  eliminateExecutedProcessId: (executionId: number) => void;
  processesGroups: GroupDefinition[];
  onSelectProcess: (process: SelectedProcess) => void;
  showModal: () => void;
}

interface ProcessIdState {
  executionId: number;
  state: string;
  date: string;
}

export function MonitorMenuViewModel({
  executedProcesses,
  eliminateExecutedProcessId,
  processesGroups,
  onSelectProcess,
  showModal,
}: MonitorMenuProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [processesData, setProcessesData] = useState<ProcessIdState[]>(loadProcessesInitialData());
  const [processesShowed, setProcessesShowed] = useState<number[]>([]);
  const [selectedState, setSelectedState] = useState<string>(ALL_STATES_VALUE);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedId, setSelectedId] = useState<string>('');
  const [modalDataDescription, setModalDataDescription] = useState<string>('');
  const [modalDataName, setModalDataName] = useState<string>('');
  const intl = useIntl();

  function loadProcessesInitialData(): ProcessIdState[] {
    if (browserStorage.get('monitoredProcesses')) {
      let monitoredProcesses: number[] = browserStorage.get('monitoredProcesses');
      return monitoredProcesses.map<ProcessIdState>(
        (id: number): ProcessIdState => {
          return {
            executionId: id,
            state: '',
            date: '',
          };
        },
      );
    } else {
      return [];
    }
  }

  function reloadProcessesShowed(): void {
    if (browserStorage.get('monitoredProcesses')) {
      let monitoredProcesses: number[] = browserStorage.get('monitoredProcesses');
      setProcessesShowed(monitoredProcesses);
    }
  }

  function onStateChange(id: number, state: string, date: string): void {
    let index = 0;
    const newData = processesData;

    while (index < newData.length) {
      if (newData[index].executionId === id) {
        newData[index].state = state;
        newData[index].date = date;
      }
      index++;
    }
    setProcessesData(newData);
  }

  function onModalChange(isOpen: boolean): void {
    setIsModalOpen(isOpen);
  }

  function onModalDataChange(data: string, name: string): void {
    setModalDataName(name);
    setModalDataDescription(data);
  }

  function onClickEliminateFinished(event: React.MouseEvent): void {
    event.stopPropagation();
    processesData.map((group) => {
      if (group.state === FINISHED_PROCESS) {
        eliminateExecutedProcessId(group.executionId);
      }
    });
  }

  function onDateFilterChange(value: string): void {
    setSelectedDate(value);
  }

  function onIdFilterChange(value: string): void {
    setSelectedId(value);
  }

  function onSelectChange(state: string): void {
    setSelectedState(state);
  }

  function onFilterChange(): void {
    let filterData = processesData;

    if (selectedDate !== '') {
      filterData = filterData.filter((process) => moment(process.date).format('YYYY-MM-DD') === selectedDate);
    }
    if (selectedId !== '') {
      filterData = filterData.filter((process) => process.executionId.toString() === selectedId);
    }
    if (selectedState !== ALL_STATES_VALUE) {
      filterData = filterData.filter((process) => process.state === selectedState);
    }
    setFilteredProcesses(filterData);
  }

  function setFilteredProcesses(processes: ProcessIdState[]): void {
    let newProcesses: number[] = processes.map((process) => process.executionId);
    setProcessesShowed(newProcesses);
  }

  function onResetFilter(): void {
    if (browserStorage.get('monitoredProcesses')) {
      let monitoredProcesses: number[] = browserStorage.get('monitoredProcesses');
      setProcessesShowed(monitoredProcesses);
      setSelectedDate('');
      setSelectedId('');
      setSelectedState(ALL_STATES_VALUE);
    }
  }

  function renderMonitorFilterOptions(): JSX.Element {
    return (
      <MonitorMenuOptionsViewModel
        onClickEliminateFinished={onClickEliminateFinished}
        onDateFilterChange={onDateFilterChange}
        onIdFilterChange={onIdFilterChange}
        onSelectedChange={onSelectChange}
        selectedState={selectedState}
        onResetFilter={onResetFilter}
        selectedId={selectedId}
        selectedDate={selectedDate}
      />
    );
  }

  function deleteAllFinalizedProcesses(): void {
    processesShowed.forEach((entry) => {
      getGroupProcessInstance(entry).then((response: GroupInstance) => {
        if (response.state === FINISHED_PROCESS) {
          eliminateExecutedProcessId(entry);
          if (browserStorage.get('monitoredProcesses')) {
            let monitoredProcesses = browserStorage.get('monitoredProcesses');
            if (monitoredProcesses) {
              monitoredProcesses = monitoredProcesses.filter((proc: number) => proc !== entry);
              browserStorage.set('monitoredProcesses', monitoredProcesses);
            }
          }
        }
      });
    });
  }

  function renderGroupsData(): JSX.Element {
    return (
      <div className="w-full">
        <div className="eliminate-button">
          <button
            className="button-slate hover:bg-blue-dark text-white font-bold py-2 rounded-full text-sm rounded-full text-center h-10 w-48 mt-2 "
            onClick={deleteAllFinalizedProcesses}
          >
            {intl.formatMessage({ id: 'remove' })} {intl.formatMessage({ id: 'finalized' })}
          </button>
        </div>
        {processesShowed.reverse().map((entry) => (
          <div className="w-full" key={entry}>
            <GroupExecutionInfoViewModel
              executionId={entry}
              onModalChange={onModalChange}
              onModalDataChange={onModalDataChange}
              eliminateExecutedProcessId={eliminateExecutedProcessId}
              onStateChange={onStateChange}
              processesGroups={processesGroups}
              onSelectProcess={onSelectProcess}
              showModal={showModal}
            />
          </div>
        ))}
      </div>
    );
  }

  useEffect(() => {
    onFilterChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedId, selectedState]);

  useEffect(() => {
    setProcessesData(loadProcessesInitialData());
    reloadProcessesShowed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executedProcesses]);

  return (
    <div className="monitor-menu-container flex items-center justify-start py-4 h-10 flex-col">
      {isModalOpen && <ResultsModalViewModel name={modalDataName} description={modalDataDescription} onModalChange={onModalChange} />}
      <FilterDropdown>{renderMonitorFilterOptions()}</FilterDropdown>
      {processesShowed.length !== 0 ? (
        renderGroupsData()
      ) : (
        <div className="font-bold text-m text-center mt-32">{intl.formatMessage({ id: 'thereAreNoGroupsToMonitor' })}</div>
      )}
    </div>
  );
}
