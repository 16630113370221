import React from 'react';
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';
import { PulseLoader } from 'halogenium';
import { MODAL_MESSAGE_GLOBAL } from '../../UI-kit/constants/zIndexValues';
import FocusLock from 'react-focus-lock';
import { useIntl } from 'react-intl';
import { addValueTest } from '../../src/components/Testing/HandleLineTest';

const ModalMessage = (props) => {
  const intl = useIntl();

  const cancelMessage = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  const acceptMessage = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });

  const {
    message,
    handleAccept,
    additionalMessage,
    handleCancel,
    redirectTo = null,
    largeModal,
    acceptTitle = acceptMessage,
    cancelTitle = cancelMessage,
    closeOutside,
    draggable,
    loading,
    hideAcceptButton = false,
    isReport,
  } = props;

  let { operationLook } = props;

  let outside = null;

  return (
    <div
      className={`fixed pin overflow-auto flex bg-smoke-light ${largeModal ? 'w-full h-full' : ''} ${draggable && 'justify-center items-center'}`}
      onClick={(e) => {
        if (closeOutside && e.target === outside) {
          return handleCancel ? () => handleCancel() : () => handleAccept();
        }
      }}
      ref={(c) => (outside = c)}
      style={{ zIndex: MODAL_MESSAGE_GLOBAL }}
    >
      <Draggable bounds="parent" handle=".handleDragg" onStart={() => draggable}>
        <div
          className={`border border-solid border-primary  ${operationLook === true ? 'bg-light' : 'bg-grey-lighter-lighter'} rounded relative p-4 ${
            largeModal ? 'w-full' : ''
          } ${isReport ? 'max-w-4xl' : largeModal ? 'max-w-lg' : 'max-w-sm'} ${draggable ? 'm-8' : 'm-auto'} flex-col flex`}
        >
          {draggable && (
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move text-xs" style={{ padding: 1.5 }} />
          )}
          {largeModal && (
            <IcoMoon
              icon="cross"
              className="absolute cursor-pointer rounded h-3 w-3"
              style={{ top: 2, right: 2, boxSizing: 'content-box' }}
              onClick={() => handleAccept()}
            />
          )}
          <div
            className={`flex-auto text-center ${largeModal ? 'h-screen-90' : ''} ${
              isReport ? '  m-1 flex' : largeModal ? 'h-550 m-1 flex' : 'm-2 py-2'
            }`}
          >
            {message}
          </div>
          {additionalMessage && <div className="text-center w-full my-4">{additionalMessage}</div>}
          {loading && (
            <div className="text-center text-sm w-full my-4">
              <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
            </div>
          )}
          {!loading && !hideAcceptButton && (
            <FocusLock className="text-center text-sm w-full" returnFocus>
              {!largeModal && (
                <React.Fragment>
                  {redirectTo ? (
                    <Link
                      id="linkAccept"
                      className={`${
                        operationLook === true ? 'bg-gray' : 'bg-grey'
                      } hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow font-normal`}
                      onClick={(e) => {
                        addValueTest('ENTER', 'linkAccept', '');
                        handleAccept();
                      }}
                      style={{ textDecoration: 'none' }}
                      to={redirectTo}
                    >
                      {acceptTitle}
                    </Link>
                  ) : (
                    <button
                      id="ModalAccept"
                      data-autofocus
                      className={`${
                        operationLook === true ? 'bg-gray' : 'bg-grey'
                      } hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow`}
                      onClick={(e) => {
                        addValueTest('ENTER', 'ModalAccept', '');
                        handleAccept();
                      }}
                    >
                      {acceptTitle}
                    </button>
                  )}
                </React.Fragment>
              )}
              {handleCancel && (
                <button
                  id="ModalCancel"
                  className={`${
                    operationLook === true ? 'bg-gray' : 'bg-grey'
                  } hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow ml-2`}
                  onClick={(e) => {
                    addValueTest('ENTER', 'ModalCancel', '');
                    handleCancel();
                  }}
                >
                  {cancelTitle}
                </button>
              )}
            </FocusLock>
          )}
        </div>
      </Draggable>
    </div>
  );
};

export default ModalMessage;
