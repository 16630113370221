import { css, StyleSheet } from 'aphrodite';
import React, { useState, useEffect } from 'react';
import { fileArrayToBase64 } from '../../../UI-kit/helpers/fileHelper';
import { FormattedMessage } from 'react-intl';
// import { textSize } from '../../../UI-kit/helpers/styleHelper';

// import { useTheme } from '../../../themes/ThemeContext';
// import { fileArrayToBase64 } from './../../../../UI-kit/helpers/fileHelper';

const Checkbox = ({ checked, hovered }) => {
  /* const themeContext = useTheme();
  const { border, color, primary } = themeContext.theme; */

  const styles = StyleSheet.create({
    checkbox: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      borderRadius: '100%',
      width: '1.3rem',
      height: '1.3rem',
      minWidth: '1.3rem',
      maxWidth: '1.3rem',
      padding: '.4rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // backgroundColor: checked && primary.primary,
      borderWidth: hovered && !checked && 1,
      // borderColor: checked ? primary.primary : border.border,
      // color: color.white,
      fontSize: '.75rem',
      margin: '.5rem',
      zIndex: 1,
    },
  });
  return <div className={css(styles.checkbox)}>{checked && <i className="fas fa-check" />}</div>;
};

const Signature = ({ data, selected, toggleSelect, updateInfo }) => {
  /* const themeContext = useTheme();
  const { border, primary } = themeContext.theme; */
  // eslint-disable-next-line no-unused-vars
  const [hovered, setHovered] = useState(false);
  const [signature, setSignature] = useState();
  const [noSignature, setNoSignature] = useState(false);
  useEffect(() => {
    const setup = async () => {
      if (!data.sinFirma && data.firma && Array.isArray(data.firma)) {
        let image = data.firma;
        let base64Image = fileArrayToBase64(image);
        data.firma = base64Image;
        setSignature(data);
        console.log(data);
      } else if (data.sinFirma) {
        setNoSignature(true);
      }
    };

    setup();
  }, [data, data.firma, data.idEntidad, data.name, data.tipoEntidad, data.tipoPoder]);

  const styles = StyleSheet.create({
    signatureContainer: {
      position: 'relative',
      width: '12rem',
      height: '12rem',
      margin: '.4rem .2rem .2rem .4rem',
      borderWidth: data.selected ? 2 : 1,
      borderColor: data.selected ? '#2b53a0' : '#b8c2cc',
      display: 'flex',
      transition: '.05s',
      cursor: 'pointer',
      ':hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 2px 8px 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.08)',
      },
      ':active': {
        transform: 'translateY(0px)',
      },
      boxSizing: 'border-box',
    },
    signatureImageContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    signatureImage: {
      objectFit: 'contain',
      width: '100%',
      maxHeight: '100%',
      userSelect: 'none',
    },
  });

  const msg = <FormattedMessage id="noRegisteredSignature" defaultMessage="Sin firma registrada" />;

  return (
    <div
      className={css(styles.signatureContainer)}
      onClick={() => toggleSelect(data._id)}
      onMouseDown={() => updateInfo(data.vencimiento)}
      // onMouseOver={() => setHovered(true)}
      // onMouseLeave={() => setHovered(false)}
    >
      <Checkbox checked={selected} hovered={hovered} />
      <div className={css(styles.signatureImageContainer)}>
        {noSignature && (
          <div
            className={css(styles.signatureImage)}
            style={{
              filter: 'none',
              height: 200,
              justifyContent: 'center',
              alignItems: 'center',
              textSize: '.875rem',
              fontStyle: 'italic',
              padding: '2.5rem',
              display: 'flex',
            }}
          >
            {msg}
          </div>
        )}
        {signature && signature.firma && <img className={css(styles.signatureImage)} src={signature && signature.firma} />}
      </div>
    </div>
  );
};

export default Signature;
