import React, { useState } from 'react';
import './SecurityManager.scss';
import { PulseLoader } from 'halogenium';
import { SecurityInformation } from './components/securityInformation/SecurityInformation';
import { GroupsAsTreeMenu } from './models/groupsAsTreeMenu';
import { SelectedGroup } from './models/types';
import { SecurityToolsView } from './components/securityTools/SecurityToolsView';
import { UserBasicInfo } from './models/userBasicInfo';
import { ADD_GROUP_MODAL, TYPE_USER, USER_MODAL } from './models/constants';

interface SecurityManagerViewProps {
  securityGroups: GroupsAsTreeMenu[];
  isLoading: boolean;
  onSelectGroup: (group: SelectedGroup) => void;
  selectedGroup?: SelectedGroup;
  onRefresh: () => void;
  removeGroupAsync: (group: GroupsAsTreeMenu) => void;
  removeUserAsync: (user: UserBasicInfo) => void;
  hasGroupPermissions: boolean;
  hasUserPermissions: boolean;
}

export function SecurityManagerView({
  securityGroups,
  isLoading,
  onSelectGroup,
  selectedGroup,
  onRefresh,
  removeUserAsync,
  removeGroupAsync,
  hasGroupPermissions,
  hasUserPermissions,
}: SecurityManagerViewProps): JSX.Element {
  const [modalOpen, setModalOpen] = useState<string>('');
  const [expandedTree, setExpandedTree] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isWarningToEdit, setIsWarningToEdit] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SelectedGroup>();
  const [changesWarning, setChangesWarning] = useState<boolean>(false);
  const [hasModifiedInformation, setHasModifiedInformation] = useState<boolean>(false);
  const [undoChangesResponse, setUndoChangesResponse] = useState<boolean>(null);
  const [isLoadingGroup, setIsLoadingGroup] = useState<boolean>(false);

  function onChangesWarningChange(isShowing: boolean, undoChanges: boolean): void {
    if (isShowing && !hasModifiedInformation) {
      const modalToOpen: string = selectedItem?.type === TYPE_USER ? USER_MODAL : ADD_GROUP_MODAL;

      onChangesWarningChange(false, undoChanges);
      selectedItem && onSelectGroup(selectedItem);
      onIsEditingChange(isWarningToEdit);
      onModalChange(modalToOpen);
      onHasModifiedChange(false);
    } else if (hasModifiedInformation) {
      setChangesWarning(isShowing);
    } else {
      setChangesWarning(false);
      onIsEditingChange(false);
    }
    if (undoChanges) {
      setHasModifiedInformation(false);
    }
    if (undoChangesResponse !== undoChanges) {
      setUndoChangesResponse(undoChanges);
    }
  }

  function onHasModifiedChange(hasModified: boolean): void {
    setHasModifiedInformation(hasModified);
  }

  function onSelectedItemChange(node: SelectedGroup): void {
    setSelectedItem(node);
  }

  function onisWarningToEditChange(isEdit: boolean): void {
    setIsWarningToEdit(isEdit);
  }

  function onModalChange(modalOpenName: string): void {
    setModalOpen(modalOpenName);
  }

  function onIsEditingChange(isEdit: boolean): void {
    setIsEditing(isEdit);
  }

  function onExpand(): void {
    setExpandedTree(!expandedTree);
  }

  function renderSecurityTab(): JSX.Element {
    return (
      <SecurityInformation
        onRefresh={onRefresh}
        setChangesWarning={setChangesWarning}
        changesWarning={changesWarning}
        onChangesWarningChange={onChangesWarningChange}
        onHasModifiedChange={onHasModifiedChange}
        onSelectedItemChange={onSelectedItemChange}
        selectedItem={selectedItem}
        hasModifiedInformation={hasModifiedInformation}
        onIsWarningToEditChange={onisWarningToEditChange}
        isWarningToEdit={isWarningToEdit}
        hasGroupPermissions={hasGroupPermissions}
        hasUserPermissions={hasUserPermissions}
        modalOpen={modalOpen}
        securityGroups={securityGroups}
        onSelectGroup={onSelectGroup}
        selectedGroup={selectedGroup}
        onModalChange={onModalChange}
        expandedTree={expandedTree}
        removeGroupAsync={removeGroupAsync}
        removeUserAsync={removeUserAsync}
        isEditing={isEditing}
        onIsEditingChange={onIsEditingChange}
        undoChangesResponse={undoChangesResponse}
        setUndoChangesResponse={setUndoChangesResponse}
        isLoadingGroup={isLoadingGroup}
        setIsLoadingGroup={setIsLoadingGroup}
      />
    );
  }

  return (
    <React.Fragment>
      <div className="security-container h-auto w-full flex-col">
        <SecurityToolsView
          onIsWarningToEditChange={onisWarningToEditChange}
          onChangesWarningChange={onChangesWarningChange}
          isEditing={isEditing}
          hasGroupPermissions={hasGroupPermissions}
          hasUserPermissions={hasUserPermissions}
          onModalChange={onModalChange}
          onRefresh={onRefresh}
          onExpand={onExpand}
          selectedGroup={selectedGroup}
          onIsEditingChange={onIsEditingChange}
          modalOpen={modalOpen}
          isLoadingGroup={isLoadingGroup}
        />
        {isLoading ? (
          <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
            <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
          </div>
        ) : (
          renderSecurityTab()
        )}
      </div>
    </React.Fragment>
  );
}
