import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { store } from '../../store';
import { css, StyleSheet } from 'aphrodite';
import FocusTrap from 'react-focus-trap';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';
import HelpTable from '../../../UI-kit/components/HelpTable';
import { getBitacorasMovimientos } from '../../api/bitacora';
import { formatDateForServer } from './filters/filterFields';

export const CompareView = (props) => {
  const [upperSection, setUpperSection] = useState(false);
  const [bAlta, setAlta] = useState(false);
  const [bBaja, setBaja] = useState(false);
  const [bModificacion, setModificacion] = useState(false);
  const [bMovimiento, setMovimiento] = useState(false);
  const [bConsulta, setConsulta] = useState(false);
  const [bConsultaSec, setConsultaSec] = useState(false);
  const [bCamposDescriptor, setCamposDescriptor] = useState(false);

  const getTextAreaValue = (selectedIndex, filterListID, textAreaFormat) => {
    let resultData = store.getState().bitacoraApp[filterListID];
    if (resultData && textAreaFormat) {
      let textAreaValue = textAreaFormat;

      for (let pairKey of Object.keys(resultData.resultData[selectedIndex])) {
        let pairValue1 = resultData.resultData[selectedIndex][pairKey];

        if (textAreaValue.includes('{' + pairKey + '}')) {
          textAreaValue = textAreaValue.replace('{' + pairKey + '}', pairValue1);
        }
      }

      return textAreaValue;
    }
  };

  const initializeChecks = () => {
    setAlta(false);
    setBaja(false);
    setModificacion(false);
    setMovimiento(false);
    setConsulta(false);
    setConsultaSec(false);
  };

  const getComparisonGrid = (selectedIndex, filterListID, visibleFields, textAreaFormat) => {
    let resultData = store.getState().bitacoraApp[filterListID];
    if (resultData) {
      let rows = [];
      let highlightedIndexes = [];
      let showGrid = !!resultData.result2;
      let visibleCounter = 0;

      if (resultData.comparisonGridResult) {
        resultData.comparisonGridResult.forEach((row) => {
          rows.push({ campos: row[0], valorAnterior: row[1], valorActual: row[2] });
          if (row[1] !== row[2]) {
            highlightedIndexes.push(resultData.comparisonGridResult.indexOf(row));
          }
        });
      } else {
        for (let pairKey of Object.keys(resultData.resultData[selectedIndex])) {
          let pairValue1 = resultData.resultData[selectedIndex][pairKey];
          if (showGrid) {
            let pairValue2 = resultData.result2[selectedIndex][pairKey];

            if (!visibleFields || !visibleFields.length || visibleFields.includes(pairKey)) {
              let name = intl.formatMessage({
                id: pairKey,
                defaultMessage: pairKey,
              });
              rows.push({ campos: name, valorAnterior: pairValue1, valorActual: pairValue2 });
              if (pairValue1 !== pairValue2) {
                if (!highlightedIndexes.includes(visibleCounter)) {
                  highlightedIndexes.push(visibleCounter);
                }
              }
              visibleCounter++;
            }
          }
        }
      }

      let columns = [
        {
          Header: 'Campos',
          accessor: 'campos',
          decimals: undefined,
          isReturned: true,
          isSortable: false,
          length: 20,
          type: 1,
          control: 'campos',
        },
        {
          Header: 'Valor Anterior',
          accessor: 'valorAnterior',
          decimals: undefined,
          isReturned: true,
          isSortable: false,
          length: 20,
          type: 1,
          control: 'campos',
        },
        {
          Header: 'Valor Actual',
          accessor: 'valorActual',
          decimals: undefined,
          isReturned: true,
          isSortable: false,
          length: 20,
          type: 1,
          control: 'valorActual',
        },
      ];

      return (
        <div
          className="border border-solid border-green-light ml-2"
          style={{ width: '880px', height: textAreaFormat === '{GRID_OPERACIONES}' ? '200px' : '250px' }}
        >
          <HelpTable
            fromApp={true}
            title={'aa'}
            fromApp={true}
            transactionLineLabel={'T1L2'}
            data={rows && rows.map((row, index) => ({ ...row, id: index }))}
            columns={columns}
            goToLineFn={() => {}}
            continue={(rowInfo) => {}}
            getTrProps={(state, rowInfo, runSelection = false) => {
              return {
                onClick: (e, handleOriginal) => {},
              };
            }}
            highlightedIndexes={highlightedIndexes}
          />
        </div>
      );
    }
  };

  const updateComparisonGridResult = (rows, selectedIndex) => {
    let bitacoraApp = store.getState().bitacoraApp;
    let filterData = {};
    if (rows && rows[selectedIndex]) {
      if (bCamposDescriptor && rows[selectedIndex].colaDescriptor) {
        filterData[filterListID] = { ...bitacoraApp[filterListID], comparisonGridResult: rows[selectedIndex].colaDescriptor };
      } else if (rows[selectedIndex].colaParseada) {
        filterData[filterListID] = { ...bitacoraApp[filterListID], comparisonGridResult: rows[selectedIndex].colaParseada };
      }
    } else {
      filterData[filterListID] = { ...bitacoraApp[filterListID], comparisonGridResult: [] };
    }
    store.setState({
      bitacoraApp: {
        ...bitacoraApp,
        ...filterData,
      },
    });
  };

  const getOperationsGrid = (selectedIndex, filterListID, visibleFields, textAreaFormat, intl, setUpperSection) => {
    let row = store.getState().bitacoraApp[filterListID].resultData[selectedIndex];
    let { asiento, sucursal, fechaproceso, persistenceUnit } = row;
    fechaproceso = fechaproceso.substr(3, 2) + '/' + fechaproceso.substr(0, 2) + '/' + fechaproceso.substr(6, 4); // swap month and day
    let fechaprocesoServer = formatDateForServer(new Date(fechaproceso));
    getBitacorasMovimientos(asiento, sucursal, fechaprocesoServer, persistenceUnit, intl).then((data) => {
      let rows = data.filter((row) => {
        let rowType = row.action.substring(0, 1);
        if (
          (bAlta && rowType === 'A') ||
          (bBaja && rowType === 'B') ||
          (bModificacion && rowType === 'M') ||
          (bMovimiento && rowType === 'O') ||
          (bConsulta && rowType === 'C') ||
          (bConsultaSec && rowType === 'S')
        ) {
          return true;
        }
        if (!bAlta && !bBaja && !bModificacion && !bMovimiento && !bConsulta && !bConsultaSec) {
          return true;
        }
      });
      let columns = [
        {
          Header: 'Accion',
          accessor: 'action',
          decimals: undefined,
          isReturned: true,
          isSortable: false,
          length: 20,
          type: 1,
          control: 'action',
        },
        {
          Header: 'Asiento',
          accessor: 'asiento',
          decimals: undefined,
          isReturned: true,
          isSortable: false,
          length: 20,
          type: 2,
          control: 'asiento',
        },
        {
          Header: 'Cola',
          accessor: 'cola',
          decimals: undefined,
          isReturned: true,
          isSortable: false,
          length: 20,
          type: 1,
          control: 'cola',
        },
        {
          Header: 'Descriptor',
          accessor: 'descriptorParseado',
          decimals: undefined,
          isReturned: true,
          isSortable: false,
          length: 20,
          type: 1,
          control: 'descriptorParseado',
        },
        {
          Header: 'Fecha proceso',
          accessor: 'fechaproceso',
          decimals: undefined,
          isReturned: true,
          isSortable: false,
          length: 20,
          type: 4,
          control: 'fechaproceso',
        },
        {
          Header: 'Ordinal',
          accessor: 'ordinal',
          decimals: undefined,
          isReturned: true,
          isSortable: false,
          length: 20,
          type: 2,
          control: 'ordinal',
        },
        {
          Header: 'Sucursal',
          accessor: 'sucursal',
          decimals: undefined,
          isReturned: true,
          isSortable: false,
          length: 20,
          type: 2,
          control: 'sucursal',
        },
        {
          Header: 'Tipo',
          accessor: 'tipo',
          decimals: undefined,
          isReturned: true,
          isSortable: false,
          length: 20,
          type: 1,
          control: 'tipo',
        },
      ];
      let grid = (
        <div>
          <input
            id="bAlta"
            checked={bAlta}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              setUpperSection(false);
              setAlta(!bAlta);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="alta" defaultMessage="Alta" />
          </label>
          <input
            id="bBaja"
            checked={bBaja}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              setUpperSection(false);
              setBaja(!bBaja);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="baja" defaultMessage="Baja" />
          </label>
          <input
            id="bModificacion"
            checked={bModificacion}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              setUpperSection(false);
              setModificacion(!bModificacion);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="modificacion" defaultMessage="Modificacion" />
          </label>
          <input
            id="bMovimiento"
            checked={bMovimiento}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              setUpperSection(false);
              setMovimiento(!bMovimiento);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="movimiento" defaultMessage="Movimiento" />
          </label>
          <input
            id="bConsulta"
            checked={bConsulta}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              setUpperSection(false);
              setConsulta(!bConsulta);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="consulta" defaultMessage="Consulta" />
          </label>
          <input
            id="bConsultaSec"
            checked={bConsultaSec}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              setUpperSection(false);
              setConsultaSec(!bConsultaSec);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="consultaSec" defaultMessage="Consulta secuencial" />
          </label>
          <div>
            <div className="border border-solid border-green-light ml-2" style={{ width: '880px', height: '300px' }}>
              <HelpTable
                fromApp={true}
                title={'aa'}
                transactionLineLabel={'T1L2'}
                data={rows && rows.map((row, index) => ({ ...row, id: index }))}
                columns={columns}
                goToLineFn={() => {}}
                continue={(rowInfo) => {}}
                getTrProps={(state, rowInfo, runSelection = false) => {
                  return {
                    onClick: (e, rowSelectedIndex) => {
                      updateComparisonGridResult(rows, rowSelectedIndex);
                    },
                  };
                }}
              />
            </div>
          </div>
        </div>
      );

      updateComparisonGridResult(rows, 0);
      setUpperSection(grid);
    });
  };

  let { selectedIndex, filterListID, visibleFields, textAreaFormat, intl, hideWindow } = props;
  let resultData = store.getState().bitacoraApp[filterListID];
  if (
    (selectedIndex || selectedIndex === 0) &&
    resultData &&
    resultData.resultData &&
    store.getState().bitacoraApp[store.getState().bitacoraApp.currentTabIndex].filterParams.compareVisibleRows
  ) {
    let showGrid = !!resultData.result2;
    if (!upperSection) {
      if (textAreaFormat === '{GRID_OPERACIONES}') {
        getOperationsGrid(selectedIndex, filterListID, visibleFields, textAreaFormat, intl, setUpperSection);
      } else {
        setUpperSection(
          <pre style={{ width: '880px', height: showGrid ? '300px' : '550px' }}>{getTextAreaValue(selectedIndex, filterListID, textAreaFormat)}</pre>,
        );
      }
    }

    const styles = StyleSheet.create({
      titleContainer: {
        width: '100%',
        minHeight: '1.5rem',
        padding: '.5rem 1rem',
        color: '#6B7280',
        cursor: 'move',
        textAlign: 'left',
        flex: '1 0 auto',
        borderBottom: `1px solid #DCE0E6`,
      },
      helpWrapper: {
        backgroundColor: '#F4F5F7',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
        borderColor: 'transparent',
        borderRadius: '.25rem',
        borderWidth: 1,
        minHeight: '300px',
        minWidth: '400px',
        height: '350px',
        left: '50%',
        position: 'fixed',
        top: '50%',
        width: '900px',
        zIndex: 100,
      },
    });

    const buttonStyles = {
      width: '100%',
    };

    let checkboxCamposDescriptor = (
      <div>
        <input
          id="bCamposDescriptor"
          checked={bCamposDescriptor}
          type="checkbox"
          className="focus:shadow-sm-light ml-10"
          onChange={(e) => {
            setUpperSection(false);
            setCamposDescriptor(!bCamposDescriptor);
            getOperationsGrid(selectedIndex, filterListID, visibleFields, textAreaFormat, intl, setUpperSection);
          }}
        />
        <label htmlFor="all" className="ml-2">
          <FormattedMessage id="camposDescriptor" defaultMessage="Mostrar solo los campos del descriptor" />
        </label>
      </div>
    );

    // Obtengo título para ayuda.
    let helpTitle = intl.formatMessage({
      id: 'Tablas.historial_cambios',
      defaultMessage: 'Historial de Cambios',
    });

    let compareWindow = (
      <FocusTrap>
        <Draggable bounds="" handle=".handleDragg">
          <div
            className="pin border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute"
            style={{ zIndex: 100, width: '900px', top: '10%', left: '25%', height: '650px' }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l text-xs handleDragg text-smoke cursor-move m-1" />
            <div className={`helpHandleDragg ${css(styles.titleContainer)}`}>{helpTitle}</div>
            {upperSection}
            {showGrid || textAreaFormat === '{GRID_OPERACIONES}'
              ? getComparisonGrid(selectedIndex, filterListID, visibleFields, textAreaFormat)
              : null}
            {textAreaFormat === '{GRID_OPERACIONES}' ? checkboxCamposDescriptor : null}
            <div className="absolute flex justify-end mt-3" style={buttonStyles}>
              <button
                className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                onClick={() => {
                  setUpperSection(false);
                  initializeChecks();
                  hideWindow();
                }}
                onBlur={(e) => {}}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    setUpperSection(false);
                    initializeChecks();
                    hideWindow();
                  } else if (e.key === 76 && e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                <FormattedMessage id="close" defaultMessage={`Cerrar`} />
              </button>
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );

    return compareWindow;
  } else {
    return <div></div>;
  }
};
