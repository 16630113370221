/* eslint-disable no-unused-vars */
import { get } from 'busyman';
import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { Filters } from './../components/Clearing/Constants';
import { getUrl, handleError } from './handlers';

export const updateCheck = async (check) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  let body = check;
  try {
    const query = {
      endpoint: 'digitalclearing',
      action: 'actualizarCheque',
      params: `sessionId=${sessionID}`,
    };
    const response = await axios.put(getUrl(query), body);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getChecks = async (filter, data, includeImages = true) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  const body = getBodyByFilter(filter, data);
  try {
    const query = {
      endpoint: 'digitalclearing',
      action: 'getChequesXFiltro',
      params: `sessionId=${sessionID}&procesaImagen=${includeImages}`,
    };
    const response = await axios.post(getUrl(query), body);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const isChequeDiferido = async (micrLine) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'digitalclearing',
      action: 'isChequeDiferido',
      params: `sessionId=${sessionID}&micrLine=${micrLine}`,
    };

    const response = await axios.get(getUrl(query));

    if (response.data === null || response.data === undefined) {
      throw new Error(handleError(response));
    }
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getEstados = async () => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'digitalclearing',
      action: 'getCausalesDevolucion',
      params: `sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));

    if (!response.data) {
      throw new Error(handleError(response));
    }
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const hasPermission = async () => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'digitalclearing',
      action: 'hasPermission',
      params: `sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    if (response.data === null || response.data === undefined) {
      throw new Error(handleError(response));
    }
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getCausales = async () => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'digitalclearing',
      action: 'getCausalesDevolucion',
      params: `sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));

    if (!response.data) {
      throw new Error(handleError(response));
    }
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getSignatoriesByMicrLine = async (micrLine) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);

  try {
    const query = {
      endpoint: 'digitalclearing',
      action: 'getFirmantesXCuentaCheque',
      params: `sessionId=${sessionID}&micrLine=${micrLine}`,
    };

    const response = await axios.get(getUrl(query));

    if (!response.data) {
      throw new Error(handleError(response));
    }
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getBodyByFilter = (filter, data) => {
  switch (filter) {
    case Filters.A_SPECIFIC_CHECK:
      return getByCheck(data);
    case Filters.REFUSED:
      return getRefused(data);
    case Filters.REFUSED_DIGITAL_CLEARING:
      return getRefusedDC(data);
    case Filters.BY_ACCOUNT:
      return getByAccount(data);
    case Filters.ACCEPTED:
      return getAccepted(data);
    case Filters.ACCEPTED_BY_MANAGEMENT:
      return getAcceptedBM(data);
    default:
      return null;
  }
};

export const getCheckByMicrLine = (micrLine) => ({
  nombreFiltro: 'Filtro por Micrline',
  queryName: 'digitalclearing.query_ChequesFiltroPorMicrline',
  parametros: {
    MicrLine: {
      nombre: 'MicrLine',
      valorFiltro: micrLine,
      tipo: 'S',
    },
  },
  indiceParametros: ['MicrLine'],
});

const getByAccount = (data) => ({
  nombreFiltro: 'Cuenta',
  queryName: 'digitalclearing.query_ChequesFiltroXCuenta',
  parametros: {
    Cuenta: {
      nombre: 'Cuenta',
      valorFiltro: data.cuenta,
      tipo: 'I',
    },
    EstadoDevolucion: {
      nombre: 'EstadoDevolucion',
      valorFiltro: '-1',
      tipo: 'I',
    },
  },
  indiceParametros: ['Cuenta', 'EstadoDevolucion', 'EstadoDevolucion'],
});

const getByCheck = (data) => ({
  nombreFiltro: 'Cheque particular',
  queryName: 'digitalclearing.query_ChequesFiltroXNROCheque',
  parametros: {
    NumeroCheque: {
      nombre: 'NumeroCheque',
      valorFiltro: data.numeroCheque,
      tipo: 'S',
    },
  },
  indiceParametros: ['NumeroCheque'],
});

const getRefusedDC = (data) => ({
  nombreFiltro: 'Rechazados clearing digital',
  queryName: 'digitalclearing.query_ChequesFiltroRechazadosClearingDigital',
  parametros: {
    Sucursal: {
      nombre: 'Sucursal',
      valorFiltro: data.sucursal,
      tipo: 'I',
    },
    Moneda: {
      nombre: 'Moneda',
      valorFiltro: data.moneda,
      tipo: 'I',
    },
    CausalDevolucion: {
      nombre: 'Causal',
      valorFiltro: data.causal,
      tipo: 'I',
    },
    ImporteDesde: {
      nombre: 'ImporteDesde',
      valorFiltro: data.importeDesde,
      tipo: 'D',
    },
    ImporteHasta: {
      nombre: 'ImporteHasta',
      valorFiltro: data.importeHasta,
      tipo: 'D',
    },
    Cuenta: {
      nombre: 'Cuenta',
      valorFiltro: data.cuenta,
      tipo: 'I',
    },
    NumeroCheque: {
      nombre: 'micrLine',
      valorFiltro: data.numeroCheque,
      tipo: 'S',
    },
  },
  indiceParametros: [
    'Sucursal',
    'Sucursal',
    'Moneda',
    'Moneda',
    'Cuenta',
    'Cuenta',
    'NumeroCheque',
    'NumeroCheque',
    'CausalDevolucion',
    'CausalDevolucion',
    'ImporteDesde',
    'ImporteHasta',
  ],
});

const getRefused = (data) => ({
  nombreFiltro: 'Rechazados por clearing digital',
  queryName: 'digitalclearing.query_ChequesFiltroRechazados',
  parametros: {
    Sucursal: {
      nombre: 'Sucursal',
      valorFiltro: data.sucursal,
      tipo: 'I',
    },
    Moneda: {
      nombre: 'Moneda',
      valorFiltro: data.moneda,
      tipo: 'I',
    },
    CausalDevolucion: {
      nombre: 'Causal',
      valorFiltro: data.causal,
      tipo: 'I',
    },
    ImporteDesde: {
      nombre: 'ImporteDesde',
      valorFiltro: data.importeDesde,
      tipo: 'D',
    },
    ImporteHasta: {
      nombre: 'ImporteHasta',
      valorFiltro: data.importeHasta,
      tipo: 'D',
    },
    Cuenta: {
      nombre: 'Cuenta',
      valorFiltro: data.cuenta,
      tipo: 'I',
    },
    NumeroCheque: {
      nombre: 'micrLine',
      valorFiltro: data.numeroCheque,
      tipo: 'S',
    },
  },
  indiceParametros: [
    'Sucursal',
    'Sucursal',
    'Moneda',
    'Moneda',
    'Cuenta',
    'Cuenta',
    'NumeroCheque',
    'NumeroCheque',
    'CausalDevolucion',
    'CausalDevolucion',
    'ImporteDesde',
    'ImporteHasta',
  ],
});

const getAcceptedBM = (data) => ({
  nombreFiltro: 'Aceptados por gerencia',
  queryName: 'digitalclearing.query_ChequesFiltroAceptadosXGerencia',
  parametros: {
    Sucursal: {
      nombre: 'Sucursal',
      valorFiltro: data.sucursal,
      tipo: 'I',
    },
    Moneda: {
      nombre: 'Moneda',
      valorFiltro: data.moneda,
      tipo: 'I',
    },
    ImporteDesde: {
      nombre: 'ImporteDesde',
      valorFiltro: data.importeDesde,
      tipo: 'D',
    },
    ImporteHasta: {
      nombre: 'ImporteHasta',
      valorFiltro: data.importeHasta,
      tipo: 'D',
    },
    Cuenta: {
      nombre: 'Cuenta',
      valorFiltro: data.cuenta,
      tipo: 'I',
    },
    NumeroCheque: {
      nombre: 'micrLine',
      valorFiltro: data.numeroCheque,
      tipo: 'S',
    },
  },
  indiceParametros: ['Sucursal', 'Sucursal', 'Moneda', 'Moneda', 'Cuenta', 'Cuenta', 'NumeroCheque', 'NumeroCheque', 'ImporteDesde', 'ImporteHasta'],
});

const getAccepted = (data) => ({
  nombreFiltro: 'Aceptados',
  queryName: 'digitalclearing.query_ChequesFiltroAceptados',
  parametros: {
    Sucursal: {
      nombre: 'Sucursal',
      valorFiltro: data.sucursal,
      tipo: 'I',
    },
    Moneda: {
      nombre: 'Moneda',
      valorFiltro: data.moneda,
      tipo: 'I',
    },
    ImporteDesde: {
      nombre: 'ImporteDesde',
      valorFiltro: data.importeDesde,
      tipo: 'D',
    },
    ImporteHasta: {
      nombre: 'ImporteHasta',
      valorFiltro: data.importeHasta,
      tipo: 'D',
    },
    Cuenta: {
      nombre: 'Cuenta',
      valorFiltro: data.cuenta,
      tipo: 'I',
    },
    NumeroCheque: {
      nombre: 'micrLine',
      valorFiltro: data.numeroCheque,
      tipo: 'S',
    },
  },
  indiceParametros: ['Sucursal', 'Sucursal', 'Moneda', 'Moneda', 'Cuenta', 'Cuenta', 'NumeroCheque', 'NumeroCheque', 'ImporteDesde', 'ImporteHasta'],
});

const getBodyByAccountAndCurrency = (data) => ({
  nombreFiltro: 'Por cuenta y moneda',
  queryName: 'digitalclearing.query_ChequesFiltro5PorCuentaYMoneda',
  parametros: {
    Cuenta: {
      nombre: 'Cuenta',
      valorFiltro: data.cuenta,
      tipo: 'I',
    },
    Moneda: {
      nombre: 'Moneda',
      valorFiltro: data.moneda,
      tipo: 'I',
    },
    Fecha: {
      nombre: 'Fecha',
      valorFiltro: data.fecha,
      tipo: 'F',
    },
  },
  indiceParametros: ['Cuenta', 'Moneda', 'Fecha'],
});

const getBodyGroupByAccountSortByAmount = (data) => ({
  nombreFiltro: 'Agrupados por cuenta, ordenados por importe',
  queryName: 'digitalclearing.query_ChequesFiltro2PorImporteDeCuenta',
  parametros: {
    Sucursal: {
      nombre: 'Sucursal',
      valorFiltro: data.sucursal,
      tipo: 'I',
    },
    Moneda: {
      nombre: 'Moneda',
      valorFiltro: data.moneda,
      tipo: 'I',
    },
    Fecha: {
      nombre: 'Fecha',
      valorFiltro: data.fecha,
      tipo: 'F',
    },
    ImporteDesde: {
      nombre: 'ImporteDesde',
      valorFiltro: data.importeDesde,
      tipo: 'D',
    },
    ImporteHasta: {
      nombre: 'ImporteHasta',
      valorFiltro: data.importeHasta,
      tipo: 'D',
    },
    EstadoExtra: {
      nombre: 'EstadoExtra',
      valorFiltro: data.estadoExtra,
      tipo: 'S',
    },
  },
  indiceParametros: ['EstadoExtra', 'Fecha', 'EstadoExtra', 'Moneda', 'Sucursal', 'Sucursal', 'ImporteDesde', 'ImporteHasta', 'Fecha'],
});

const getBodyCancelledOrBlockedAccounts = (data) => ({
  nombreFiltro: 'De cuentas canceladas o bloqueadas',
  queryName: 'digitalclearing.query_ChequesFiltro1CheqDeCuenCancelYBloq',
  parametros: {
    Sucursal: {
      nombre: 'Sucursal',
      valorFiltro: data.sucursal,
      tipo: 'S',
    },
    EstadoDevolucion: {
      nombre: 'EstadoDevolucion',
      valorFiltro: data.estadoDevolucion,
      tipo: 'I',
    },
    FechaHasta: {
      nombre: 'FechaHasta',
      valorFiltro: data.fechaHasta,
      tipo: 'F',
    },
    FechaDesde: {
      nombre: 'FechaDesde',
      valorFiltro: data.fechaDesde,
      tipo: 'F',
    },
    EstadoExtra: {
      nombre: 'EstadoExtra',
      valorFiltro: data.estadoExtra,
      tipo: 'S',
    },
    Camara: {
      nombre: 'Camara',
      valorFiltro: data.camara,
      tipo: 'I',
    },
  },
  indiceParametros: ['FechaDesde', 'FechaHasta', 'EstadoExtra', 'Sucursal', 'Sucursal', 'Camara', 'Camara', 'EstadoDevolucion'],
});

const getReturnedByBranchManagers = (data) => ({
  nombreFiltro: 'Devueltos por gerentes de sucursales',
  queryName: 'digitalclearing.query_ChequesFiltro3CheqDevPorGerentSucu',
  parametros: {
    MicrLine: {
      nombre: 'micrLine',
      valorFiltro: data.micrLine,
      tipo: 'S',
    },
    Cuenta: {
      nombre: 'cuenta',
      valorFiltro: data.cuenta,
      tipo: 'I',
    },
    Sucursal: {
      nombre: 'Sucursal',
      valorFiltro: data.sucursal,
      tipo: 'S',
    },
    Fecha: {
      nombre: 'Fecha',
      valorFiltro: data.fecha,
      tipo: 'F',
    },
    EstadoExtra: {
      nombre: 'EstadoExtra',
      valorFiltro: data.estadoExtra,
      tipo: 'S',
    },
  },
  indiceParametros: ['EstadoExtra', 'Sucursal', 'Sucursal', 'Fecha'],
});

const getASpecificCheck = (data) => ({
  nombreFiltro: 'Cheque en particular',
  queryName: 'digitalclearing.query_ChequesFiltro4UnChequeEnParticular',
  parametros: {
    Fecha: {
      nombre: 'Fecha',
      valorFiltro: data.fecha,
      tipo: 'F',
    },
    SerieCheque: {
      nombre: 'SerieCheque',
      valorFiltro: data.serieCheque,
      tipo: 'S',
    },
    NumeroCheque: {
      nombre: 'NumeroCheque',
      valorFiltro: data.numeroCheque,
      tipo: 'S',
    },
  },
  indiceParametros: ['SerieCheque', 'NumeroCheque', 'Fecha'],
});

const getBodyEverythingElse = (data) => ({
  nombreFiltro: 'Lo que resta',
  queryName: 'digitalclearing.query_ChequesFiltro7LoQueResta',
  parametros: {
    Sucursal: {
      nombre: 'Sucursal',
      valorFiltro: data.sucursal,
      tipo: 'S',
    },
    Fecha: {
      nombre: 'Fecha',
      valorFiltro: data.fecha,
      tipo: 'F',
    },
  },
  indiceParametros: ['Sucursal', 'Sucursal', 'Fecha'],
});

const getBodyInReview = (data) => ({
  nombreFiltro: 'En revision',
  queryName: 'digitalclearing.query_ChequesFiltro6EnRevision',
  parametros: {
    CodigoUsuario: {
      nombre: 'CodigoUsuario',
      valorFiltro: data.codigoDeUsuario,
      tipo: 'S',
    },
    Fecha: {
      nombre: 'Fecha',
      valorFiltro: data.fecha,
      tipo: 'F',
    },
  },
  indiceParametros: ['CodigoUsuario', 'Fecha'],
});
