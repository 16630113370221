import { store } from '../../../store';
import { getBitacoraServiciosWS } from '../../../api/bitacora';
import { formatDateForServer } from './filterFields';
import { exportToCsv } from './exportCSV';

const exportServiciosWeb = (filterParams, columns, filterListID) => {
  let {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    usuario,
    empresa,
    sucursalEmpresa,
    canal,
    operacion,
    persistenceUnit,
  } = filterParams;

  let filterData = {};
  filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
  store.setState({
    bitacoraApp: {
      ...store.getState().bitacoraApp,
      ...filterData,
    },
  });

  let defaultValue;
  fechaHoraDesde = formatDateForServer(fechaHoraDesde);
  fechaHoraHasta = formatDateForServer(fechaHoraHasta);
  firstResult = firstResult || 0;
  maxResults = maxResults || 201;
  usuario = usuario || defaultValue;
  empresa = empresa || defaultValue;
  sucursalEmpresa = sucursalEmpresa || defaultValue;
  canal = canal || defaultValue;
  operacion = operacion || defaultValue;
  persistenceUnit = persistenceUnit || 'Local';
  const params = {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    usuario,
    empresa,
    sucursalEmpresa,
    canal,
    operacion,
    persistenceUnit,
  };
  getBitacoraServiciosWS(params)
    .then((data) => {
      exportToCsv(data, 'serviciosWeb.csv', columns);
    })
    .catch((err) => {
      console.log(err);
    });
};

export { exportServiciosWeb };
