import React from 'react'
import NotificationView from './NotificationView'

const NotificationViewModel = (props) => {
  // Get notifications from API
  const { collapsed } = props
  const notifications = null; // getNotifications();

  if (!notifications) {
    return null;
  }
  return (
    <NotificationView
      value={notifications}
      collapsed={collapsed}
    />
  );
}

export default NotificationViewModel;
