import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useTheme } from '../../themes/ThemeContext';

const ItemView = ({ itemName, itemId, onClick }) => {
  const themeState = useTheme();
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  const { background } = themeState.theme;

  const styles = StyleSheet.create({
    container: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      position: 'relative',
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      borderBottomWidth: '1px',
      borderColor: '#DCE0E6',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      borderRadius: '0.125rem',
      height: '5rem',
      marginTop: '0.25rem',
      marginBottom: '0.25rem',
      minWidth: '16rem',
      flexBasis: '32.6%',
      backgroundColor: '#FCFCFC',
      ':hover': {
        boxShadow: '0 3px 8px 0 rgba(0,0,0,0.1), 0 2px 4px 0 rgba(0,0,0,0.08)',
        backgroundColor: background.light,
        ':not(:active)': {
          transition: '.08s cubic-bezier(0.165, 0.84, 0.44, 1)',
          transform: 'translateY(-2px)',
        },
      },
      ':focus': {
        boxShadow: '0 3px 8px 0 rgba(0,0,0,0.1), 0 2px 4px 0 rgba(0,0,0,0.08)',
        backgroundColor: background.light,
        ':not(:active)': {
          transition: '.08s cubic-bezier(0.165, 0.84, 0.44, 1)',
          transform: 'translateY(-2px)',
        },
      },
      ':active': {
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
        backgroundColor: '#F4F5f7',
        transition: '.1s cubic-bezier(0.165, 0.84, 0.44, 1)',
        transform: 'translateY(-1px)',
      },
      ':nth-child(3n + 1)': {
        borderTop: '2px solid rgba(135,190,54, 1)',
      },
      ':nth-child(3n + 2)': {
        borderTop: '2px solid rgba(79, 179, 199, 1)',
      },
      ':nth-child(3n + 3)': {
        borderTop: '2px solid rgba(50, 72, 150, 1)',
      },
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: active ? 'inset 0 2px 4px 0 rgba(0,0,0,0.06)' : '',
      backgroundColor: hovered ? (active ? '#DCE0E6' : '#EBECF0') : '#F4F5f7',
      borderRadius: '9999px',
      marginLeft: '0.25rem',
      marginRight: '0.25rem',
      width: '3.25rem',
      height: '3.25rem',
      color: '#3D454D',
    },
    name: {
      marginLeft: '0.25rem',
      marginRight: '0.25rem',
      textAlign: 'left',
      fontSize: '.875rem',
      color: '#3D454D',
      fontFamily: "'Rubik','Roboto','sans-serif'",
    },
  });
  return (
    <button
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
      className={css(styles.container)}
      onClick={() => {
        onClick(itemId, itemName);
      }}
    >
      <div className={css(styles.icon)}>{itemId}</div>
      <div className={css(styles.name)}>{itemName}</div>
    </button>
  );
};

export default ItemView;
