import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { useTheme } from './../../../../themes/ThemeContext';

const Field = ({ labelText, htmlFor, error, children }) => {
  const themeState = useTheme();
  const { error: errorColor, text } = themeState.theme;

  const styles = StyleSheet.create({
    fieldContainer: {
      marginBottom: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      userSelect: 'none',
    },
    fieldLabel: {
      color: error ? errorColor.error : text.text,
      fontSize: '.75rem',
      marginBottom: '.2rem',
    },
    fieldDataContainer: {},
  });

  return (
    <div className={css(styles.fieldContainer)}>
      <div>
        <label htmlFor={htmlFor} className={css(styles.fieldLabel)}>
          {labelText}
        </label>
      </div>
      <div className={css(styles.fieldDataContainer)}>{children}</div>
    </div>
  );
};

export default Field;
