import React, { useEffect, useState } from 'react';
import DateInput from '../Filters/Fields/DateInput';
// import { useIntl } from 'react-intl';
import ActionButton from './ActionButton';
import { getCausales, updateCheck } from '../../../../src/api/clearing';
import ListOfCauses from './ListOfCauses';
import ListOfSignatures from './ListOfSignatures';
import { useHistory } from 'react-router-dom';
import { store } from '../../../store';
import { formatDateDDMMYYYYWithHyphen } from '../../../../UI-kit/helpers/dateHelper';

const RejectionComponent = (props) => {
  // const intl = useIntl();
  const [showCauses, setShowCauses] = useState(false);
  const [showSignatures, setShowSignatures] = useState(false);
  const [emitionDate, setEmitionDate] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [rejectionDescription, setRejectionDescription] = useState(null);
  const [options, setOptions] = useState(null);
  const [mainCause, setMainCause] = useState(null);
  const [causesToSend, setCausesToSend] = useState([]);
  const [signatorsToSend, setSignatorsToSend] = useState([]);
  const history = useHistory();

  const fetchCauses = async () => {
    const data = await getCausales();
    if (data && data.length > 0) {
      const causes = data.map((cause) => ({
        value: cause.codigo,
        text: cause.descripcion,
        type: cause.tipoRechazo,
      }));
      setOptions(causes);
      setMainCause(causes[0]);
    }
  };

  const refuseCheck = async () => {
    let parsedCausalesToSend = [];
    let parsedSignatorsToSend = [];
    console.log(signatorsToSend);
    causesToSend.forEach((cause) => {
      let causalToAdd = {
        codigo: cause.value,
        tipoRechazo: cause.type,
        descripcion: cause.text,
      };
      parsedCausalesToSend.push(causalToAdd);
    });
    signatorsToSend.forEach((signator) => {
      let signatorToAdd = {
        tipoDocumento: signator.tipoDocumento,
        numeroPersona: signator.numeroPersona,
        jtsSaldos: signator.jtsSaldo,
        nombreFirmante: signator.nombre,
        numeroDocumento: signator.numeroDocumento,
      };
      parsedSignatorsToSend.push(signatorToAdd);
    });

    let mainCauseToSend = Array.isArray(mainCause) ? mainCause[0] : mainCause;
    let body = {
      firmas: parsedSignatorsToSend,
      // array con los causales seleccionados (objetos) de los causlaes, si es validado se manda un array vacio
      otrasCausalesDevolucion: parsedCausalesToSend,
      micrLine: props.micrLine,
      cuenta: props.checkData.account,
      fecha: formatDateDDMMYYYYWithHyphen(props.checkData.date),
      producto: props.checkData.product,
      operacion: props.checkData.operation,
      sucursal: props.checkData.branch,
      importe: props.checkData.amount,
      // VALIDADO_SF, VALIDADO
      estado: 'RECHAZADO',
      // usuario que esta loggeado de la sesion
      usuario: props.info.user.name,
      // fecha de proceso
      ultimaModificacion: props.info.fechaProceso.slice(0, props.info.fechaProceso.indexOf(' ')),
      imagenAnverso: props.checkData.frontImage,
      imagenReverso: props.checkData.backImage,
      serie: props.checkData.serie,
      numeroCheque: props.checkData.checkNumber,
      moneda: props.checkData.currency,
      // si rechazo 1, si valido 0
      estadoDevolucion: 3,
      // 'A devolver automático' (recordar internacionalizacion), 'Recibido' si es valido
      descripcionEstadoDevolucion: 'A devolver automático',
      descripcionDevolucion: rejectionDescription,
      codigoCausalDevolucion: mainCauseToSend.value,
      fechaEmision: emitionDate !== null ? emitionDate : null,
      fechaVencimiento: expirationDate !== null ? expirationDate : null,
      // lo que me llega
      saldoJtsOid: props.checkData.saldoJtsOid,
      // false si es rejected, false si es val sin firma, true si valida
      firmasValidadas: false,
    };

    updateCheck(body);
    let state = store.getState();
    let clearingState = state.clearing;
    let checksData = clearingState.searchResult;
    let checkDataToAssign = checksData.filter((check) => check.micrLine !== props.micrLine);
    clearingState.searchResult = checkDataToAssign;
    store.setState({ clearing: clearingState });
    history.goBack();
  };

  useEffect(() => {
    fetchCauses();
  }, []);

  const onCauseChange = (value) => {
    let optionValue = value.split(' ')[0];
    let chosen = options.filter((option) => {
      return option.value === parseInt(optionValue);
    });
    setMainCause(chosen);
  };
  console.log(props.info);

  return (
    <div>
      <div>
        <label>Causal de devolución</label>
        <div>
          <select id="causeSelect" name="causeSelect" required onChange={(event) => onCauseChange(event.target.value)} className="mt-8">
            {options &&
              Array.isArray(options) &&
              options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.value && option.value !== -1 && `${option.value} - `}
                  {option.text}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div>
        <label>Descripción de devolución</label>
        <div>
          <textarea
            value={rejectionDescription}
            onChange={(e) => setRejectionDescription(e.target.value)}
            style={{ border: '1px solid black' }}
            className="mt-8"
          ></textarea>
        </div>
      </div>
      <label>Fecha de emisión</label>
      <DateInput date={emitionDate} onDateChange={setEmitionDate} />
      <label>Fecha de vencimiento</label>
      <DateInput disabled={props.diferido} date={expirationDate} onDateChange={setExpirationDate} />
      <ActionButton onClick={() => setShowCauses(true)}> Otras Causales</ActionButton>
      {showCauses && <ListOfCauses setCauses={setCausesToSend} causes={options} />}
      <ActionButton onClick={() => setShowSignatures(true)}> Agregar Firmantes</ActionButton>
      {showSignatures && <ListOfSignatures setSignators={setSignatorsToSend} signatures={props.signatures} />}
      <ActionButton onClick={() => refuseCheck()}>Confirmar Rechazo</ActionButton>
    </div>
  );
};

export default RejectionComponent;
