import React from 'react';
import { PulseLoader } from 'halogenium';
import { injectIntl } from 'react-intl';
import { StyleSheet, css } from 'aphrodite';
import { useTheme } from '../../themes/ThemeContext';
import { LoginFingerPrint } from './LoginFingerPrint';
import { AddFingerPrint } from './AddFingerPrint';
import ModalMessageGlobal from '../ModalMessageGlobal';

const LoginView = (props) => {
  const {
    inputs,
    onInputChange,
    onSubmit,
    loading,
    error,
    secondLogin,
    setSecondLogin,
    setAddFingerLogin,
    addFingerLogin,
    tipoLoged,
    loginDialog,
    handleLoginNumber,
    loginMessage,
  } = props;

  /********
   * Styles
   */
  const themeState = useTheme();
  const { color, primary, background, border, text, error: errorColor } = themeState.theme;
  const styles = StyleSheet.create({
    container: {},
    inputFormContainer: {
      marginBottom: '1.5rem',
    },
    inputForm: {
      width: '100%',
      padding: '.65rem',
      borderWidth: 2,
      backgroundColor: background.background,
      borderColor: border.light2,
      color: text.text,
      borderRadius: 4,
      transition: '.2s',
      ':hover': {
        backgroundColor: background.dark,
        borderColor: border.dark,
      },
      ':focus': {
        backgroundColor: background.light,
        borderColor: primary.primary,
      },
    },
    loginButton: {
      color: color.white,
      backgroundColor: primary.primary,
      padding: '.75rem 0',
      width: '100%',
      borderRadius: 4,
      boxShadow: '0 2px 0px 0 #00151F',
      transition: '.2',
      ':hover': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.light,
      },
      ':focus': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.light,
      },
      ':active': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.dark,
      },
    },
    loginFooter: {
      marginTop: '1rem',
      maxHeight: '5rem',
      overflow: 'auto',
    },
    errorMessage: {
      color: errorColor.error,
      textAlign: 'center',
    },
    copyrightContainer: {
      marginTop: 20,
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column',
      fontSize: 12,
    },
  });

  const placeholderUsername = props.intl.formatMessage({
    id: 'userLogin',
    defaultMessage: 'Usuario',
  });
  const placeholderPassword = props.intl.formatMessage({
    id: 'passwordLogin',
    defaultMessage: 'Contraseña',
  });
  const buttonText = props.intl.formatMessage({
    id: 'loginButton',
    defaultMessage: 'Entrar',
  });

  return (
    <div className={css(styles.container)}>
      <ModalMessageGlobal />
      <form onSubmit={onSubmit}>
        <div className={css(styles.inputFormContainer)}>
          <input
            className={css(styles.inputForm)}
            type="text"
            name="username"
            placeholder={placeholderUsername}
            value={inputs.username || ''}
            onChange={onInputChange}
            required
          />
        </div>
        <div className={css(styles.inputFormContainer)}>
          <input
            className={css(styles.inputForm)}
            type="password"
            name="password"
            placeholder={placeholderPassword}
            value={inputs.password || ''}
            onChange={onInputChange}
          />
        </div>
        <div>
          <button type="submit" className={css(styles.loginButton)} disabled={loading}>
            {loading ? <PulseLoader color="white" size="6px" /> : buttonText}
          </button>
        </div>
        <div className={css(styles.copyrightContainer)}>
          La información confidencial y propiedad intelectual, pertenecen a Grupo Topaz.
          <span>Todos los derechos reservados.</span>
        </div>
      </form>
      {secondLogin === true ? (
        <div>
          <LoginFingerPrint
            user={inputs.username}
            secondLogin={secondLogin}
            setSecondLogin={setSecondLogin}
            tipoLoged={tipoLoged}
            loginDialog={loginDialog}
            handleLoginNumber={handleLoginNumber}
            loginMessage={loginMessage}
          ></LoginFingerPrint>
        </div>
      ) : (
        <div></div>
      )}
      {addFingerLogin === true ? (
        <div>
          <AddFingerPrint
            user={inputs.username}
            setAddFingerLogin={setAddFingerLogin}
            addFingerLogin={addFingerLogin}
            loginDialog={loginDialog}
            handleLoginNumber={handleLoginNumber}
            loginMessage={loginMessage}
            tipoLoged={tipoLoged}
          ></AddFingerPrint>
        </div>
      ) : (
        <div></div>
      )}
      <div className={css(styles.loginFooter)}>{error && <p className={css(styles.errorMessage)}>{error}</p>}</div>
    </div>
  );
};

export default injectIntl(LoginView);
