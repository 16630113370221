/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';
import { PulseLoader } from 'halogenium';
import { ABOVE_ALL } from '../../../../UI-kit/constants/zIndexValues';
import FocusLock from 'react-focus-lock';
import { useIntl } from 'react-intl';
import {
  getHelpButton,
  getColumnsSucursal,
  getColumnsNumeroMaquina,
  getColumnsGrupoMapa,
  getHelpWindow,
} from './ProductionHelpComponent/productionHelp';
import { useTheme } from '../../../themes/ThemeContext';

import { css, StyleSheet } from 'aphrodite';

import { updateTopazMap, addTopazMap } from '../../../api/productionManager';
import ModalMessage from '../../ModalMessage';

export const TopazMapWindow = (props) => {
  const themeContext = useTheme();

  const { color, commonButton, modal } = themeContext.theme;

  const styles = StyleSheet.create({
    show: {
      display: 'block',
      zIndex: 900,
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    modalMain: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxHeight: '80%',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      // minWidth: '320px',
      // maxWidth: '98vw',
      // maxHeight: '98vh',
      backgroundColor: modal.active.background,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: 'transparent',
      opacity: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.33) 0px 2px 8px',
      // alignItems: 'center',
      transition: 'border background .2s',
      padding: '.2rem',
    },
    titleContainer: {
      width: '100%',
      minHeight: '1.5rem',
      padding: '.5rem 1rem',
      color: color.grey.dark4,
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    messageContainer: {
      padding: '1.5rem 1rem',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    buttonsContainer: {
      padding: '.5rem .5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    button: {
      padding: '.5rem 1.2rem',
      marginRight: '.5rem',
      borderRadius: 4,
      display: 'block',
      backgroundColor: commonButton.default.background,
      color: commonButton.default.color,
      ':hover': {
        backgroundColor: commonButton.hover.background,
        color: commonButton.hover.color,
      },
      ':focus': {
        backgroundColor: commonButton.focus.background,
        color: commonButton.focus.color,
      },
      ':active': {
        backgroundColor: commonButton.active.background,
        color: commonButton.active.color,
      },
      transition: 'background .2s',
    },
  });
  const intl = useIntl();

  const { item, sucursales, mapaDeRed, mapaTopaz, setModifiedItem, addToDataAndSort, actualIndex, handleKeyOnNumber } = props;
  const [modify, setModify] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [helpRows, setHelpRows] = useState([]);
  const [title, setTitle] = useState(false);
  const [helpColumns, setHelpColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [textDialog, setTextDialog] = useState('');

  useEffect(() => {
    if (item) {
      setModify(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelMessage = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  const acceptMessage = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });

  const modifyMessage = intl.formatMessage({
    id: 'modify',
    defaultMessage: 'Modificar',
  });

  let outside = null;
  let columnsSucursal = getColumnsSucursal();
  let columnsNumeroMaquina = getColumnsNumeroMaquina();
  let columnsGrupo = getColumnsGrupoMapa();

  let parsedSucursales = [];

  sucursales.forEach((sucursal) => {
    let toAdd = {
      sucursal: sucursal[0],
      descripcion: sucursal[1],
    };
    parsedSucursales.push(toAdd);
  });

  let helpButtonSucursal = getHelpButton(
    'sucursal',
    {
      rows: parsedSucursales,
      columns: columnsSucursal,
      title: intl.formatMessage({
        id: 'branch',
        defaultMessage: 'Sucursal',
      }),
    },
    setHelpColumns,
    setHelpRows,
    setShowHelp,
    setTitle,
    modify,
  );

  let filteredNetMap = [];

  mapaDeRed.forEach((nodo) => {
    let toAdd = {
      numero: nodo.numerodemaquina,
      descripcion: nodo.descripcion,
    };
    filteredNetMap.push(toAdd);
  });

  let helpButtonNroDeMaquina = getHelpButton(
    'numeroDeMaquina',
    {
      rows: filteredNetMap,
      columns: columnsNumeroMaquina,
      title: intl.formatMessage({
        id: 'machineNumber',
        defaultMessage: 'Número de Máquina',
      }),
    },
    setHelpColumns,
    setHelpRows,
    setShowHelp,
    setTitle,
    modify,
  );
  let filteredGrupo = [];

  mapaTopaz.forEach((nodo) => {
    let toAdd = {
      grupo: nodo.grupomapa,
      comentario: nodo.comentario,
    };
    filteredGrupo.push(toAdd);
  });

  const setModal = (state, text) => {
    setShowModal(state);
    setTextDialog(text);
  };

  let helpButtonGrupo = getHelpButton(
    'Grupo',
    {
      rows: filteredGrupo,
      columns: columnsGrupo,
      title: intl.formatMessage({
        id: 'group',
        defaultMessage: 'Grupo',
      }),
    },
    setHelpColumns,
    setHelpRows,
    setShowHelp,
    setTitle,
    modify,
  );

  const closeHelp = () => {
    setHelpColumns([]);
    setHelpRows([]);
    setShowHelp(false);
    setTitle('');
  };

  const helpAcceptHandler = (value, control) => {
    document.getElementById(control).value = value;
    document.getElementById(control).focus();
    closeHelp();
  };

  let helpWindow = getHelpWindow(helpRows, helpColumns, helpAcceptHandler, closeHelp, modify, title);

  const handleAccept = async () => {
    let nromaq = document.getElementById('NumeroDeMaquina').value;
    let grupomapa = document.getElementById('Grupo').value;
    let nrosucursal = document.getElementById('Sucursal').value;
    let path = document.getElementById('path').value;
    let comentario = document.getElementById('comentario').value;

    const { sucursales, mapaDeRed, index, mapaTopaz } = props;

    if (grupomapa === undefined || grupomapa.trim() === '') {
      const maquinaMessage = intl.formatMessage({
        id: 'gruponulo',
        defaultMessage: 'El valor del campo Grupo no puede ser nulo.',
      });
      setModal(true, maquinaMessage);
      return;
    }

    if (nromaq === undefined || nromaq.trim() === '') {
      const sucursalMessage = intl.formatMessage({
        id: 'maquinanula',
        defaultMessage: 'El valor del campo Número de Maquina no puede ser nulo.',
      });
      setModal(true, sucursalMessage);
      return;
    }

    if (nrosucursal === undefined || nrosucursal.trim() === '') {
      const sucursalMessage = intl.formatMessage({
        id: 'sucursalnula',
        defaultMessage: 'El valor del campo Número de Sucursal no puede ser nulo.',
      });
      setModal(true, sucursalMessage);
      return;
    }

    let rowSuc = sucursales.filter((reg) => {
      return reg[0] === parseInt(nrosucursal);
    });
    let rowMaquina = mapaDeRed.filter((reg) => {
      return reg.numerodemaquina === parseInt(nromaq);
    });
    if (rowSuc.length === 0) {
      const sucursalMessage = intl.formatMessage({
        id: 'noexistesucursal',
        defaultMessage: 'El número de Sucursal especificado no existe en la base de datos',
      });
      setModal(true, sucursalMessage);
      return;
    }

    if (rowMaquina.length === 0) {
      const maquinaMessage = intl.formatMessage({
        id: 'noexistemaquina',
        defaultMessage: 'El número de Maquina especificado no existe en la base de datos',
      });
      setModal(true, maquinaMessage);
      return;
    }

    if (!modify && isDuplicated()) {
      return;
    }

    let topazMap = {
      nromaq: parseInt(nromaq),
      grupomapa: parseInt(grupomapa),
      nrosucursal: parseInt(nrosucursal),
      path: path,
      comentario: comentario,
    };

    if (modify) {
      let res = await updateTopazMap(topazMap);
      if (res === false) {
        let message = intl.formatMessage({
          id: 'erroralactualizar',
          defaultMessage: 'Error al intentar actualizar ',
        });
        message += 'TopazMap';
        setModal(true, message);
        return;
      } else {
        setModifiedItem(topazMap, index);
      }
    } else {
      let res = await addTopazMap(topazMap);
      if (res === false) {
        let message = intl.formatMessage({
          id: 'erroraldardealta',
          defaultMessage: 'Error al intentar dar de alta sobre ',
        });
        message += 'TopazMap';
        setModal(true, message);
        return;
      }
      addToDataAndSort(topazMap);
    }
    props.handleCancel();
  };

  const isDuplicated = () => {
    let nromaq = document.getElementById('NumeroDeMaquina').value;
    if (nromaq !== '') {
      let grupomapa = document.getElementById('Grupo').value;
      if (grupomapa !== '') {
        let nrosucursal = document.getElementById('Sucursal').value;
        if (nrosucursal !== '') {
          let rowExiste = mapaTopaz.filter((reg) => {
            return reg.nromaq === parseInt(nromaq) && reg.nrosucursal === parseInt(nrosucursal) && reg.grupomapa === parseInt(grupomapa);
          });

          if (rowExiste.length > 0) {
            const sucursalMessage = intl.formatMessage({
              id: 'topazmapduplicado',
              defaultMessage: 'Ya existe un registro con igual grupo, número de máquina y sucursal.',
            });
            setModal(true, sucursalMessage);
            return true;
          }
          return false;
        } else {
          const nroSucursalMessage = intl.formatMessage({
            id: 'nrosucursalnull',
            defaultMessage: 'El valor de "Sucursal" no puede ser vacío.',
          });
          setModal(true, nroSucursalMessage);
        }
      } else {
        const grupoMapaMessage = intl.formatMessage({
          id: 'grupomapanull',
          defaultMessage: 'El valor de "Grupo" no puede ser vacío.',
        });
        setModal(true, grupoMapaMessage);
      }
    } else {
      const nroMaqMessage = intl.formatMessage({
        id: 'nromaqnull',
        defaultMessage: 'El valor de "Numero de máquina" no puede ser vacío.',
      });
      setModal(true, nroMaqMessage);
    }
  };

  return (
    <div>
      {showModal && (
        <ModalMessage
          message={textDialog}
          handleAccept={async () => {
            setShowModal(false);
          }}
          acceptTitle={acceptMessage}
          draggable={true}
        />
      )}
      <div>
        <FocusLock persistentFocus returnFocus>
          <div className={css(styles.modal, styles.show)}>
            {/* onKeyDown={handleKeyDown}> */}
            <div className={css(styles.modalMain)}>
              <Draggable handle=".drag-section" bounds={{ top: -200, right: 350, bottom: 200, left: -350 }}>
                <div className={`${css(styles.modalContent)}`}>
                  <div className={`drag-section ${css(styles.titleContainer)}`}></div>
                  <div className={css(styles.messageContainer)}>
                    <div className={`flex-auto text-left m-1 flex `} style={{ height: 'fit-content', justifyContent: 'center' }}>
                      <form className={'overflow-hidden'} style={{ display: 'table' }}>
                        <p style={{ display: 'table-row' }}>
                          <label style={{ display: 'table-cell' }} htmlFor="Grupo">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerGroup',
                              defaultMessage: 'Grupo',
                            })}
                          </label>
                          <input
                            style={{ display: 'table-cell' }}
                            disabled={modify}
                            defaultValue={!modify && actualIndex ? actualIndex : modify ? item.grupomapa : ''}
                            className={'clear-both border  mt-2 ml-2'}
                            id="Grupo"
                            type="number"
                            min="0"
                            onKeyDown={(e) => handleKeyOnNumber(e)}
                            onBlur={() => isDuplicated()}
                          ></input>
                          {helpButtonGrupo}
                        </p>
                        <p style={{ display: 'table-row' }}>
                          <label style={{ display: 'table-cell' }} htmlFor="NumeroDeMaquina">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerMachineNumber',
                              defaultMessage: 'Número de Máquina',
                            })}
                          </label>
                          <input
                            style={{ display: 'table-cell' }}
                            disabled={modify}
                            defaultValue={modify ? item.nromaq : ''}
                            className={'clear-both border  mt-2 ml-2'}
                            id="NumeroDeMaquina"
                            type="number"
                            min="0"
                            onKeyDown={(e) => handleKeyOnNumber(e)}
                            onBlur={() => isDuplicated()}
                          ></input>
                          {helpButtonNroDeMaquina}
                        </p>
                        <p style={{ display: 'table-row' }}>
                          <label style={{ display: 'table-cell' }} htmlFor="Sucursal">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerBranch',
                              defaultMessage: 'Sucursal',
                            })}
                          </label>
                          <input
                            style={{ display: 'table-cell' }}
                            disabled={modify}
                            defaultValue={modify ? item.nrosucursal : ''}
                            className={'clear-both border  mt-2 ml-2'}
                            id="Sucursal"
                            type="number"
                            min="0"
                            onKeyDown={(e) => handleKeyOnNumber(e)}
                            onBlur={() => isDuplicated()}
                          ></input>
                          {helpButtonSucursal}
                        </p>
                        <p style={{ display: 'table-row' }}>
                          <label style={{ display: 'table-cell' }} htmlFor="Descripcion">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerDescription',
                              defaultMessage: 'Descripción',
                            })}
                          </label>
                          <input
                            style={{ display: 'table-cell' }}
                            defaultValue={modify ? item.comentario : ''}
                            className={'clear-both border  mt-2 ml-2'}
                            id="comentario"
                          ></input>
                        </p>

                        <p style={{ display: 'table-row' }}>
                          <label style={{ display: 'table-cell' }} htmlFor="Camino">
                            {intl.formatMessage({
                              id: 'productionManagerPath',
                              defaultMessage: 'Camino',
                            })}
                          </label>
                          <input
                            style={{ display: 'table-cell' }}
                            defaultValue={modify ? item.path : ''}
                            className={'clear-both border  mt-2 ml-2'}
                            id="path"
                          ></input>
                        </p>
                      </form>
                      {showHelp && helpWindow}
                    </div>
                    <div className="flex justify-center pt-2">
                      <button
                        data-autofocus
                        className={`bg-grey
                       hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow`}
                        style={{ width: 'fit-content' }}
                        onClick={() => handleAccept()}
                      >
                        {acceptMessage}
                      </button>

                      <button
                        className={`bg-grey
                   hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow ml-2`}
                        style={{ width: 'fit-content' }}
                        onClick={() => props.handleCancel()}
                      >
                        {cancelMessage}
                      </button>
                    </div>
                  </div>
                </div>
              </Draggable>
            </div>
          </div>
        </FocusLock>
      </div>
    </div>
  );
};

export default TopazMapWindow;
