import React from 'react';
import { useIntl } from 'react-intl';
import { Parameter } from '../../../../models/parameter';

import '../Information.scss';

interface ParamsInformationViewProps {
  params: Parameter[];
  group: string;
}

export function ParamsInformationView({ params, group }: ParamsInformationViewProps): JSX.Element | null {
  const intl = useIntl();

  return (
    <div className="w-full">
      <div className="pl-2">
        <div className="flex items-center justify-between text-xs uppercase text-grey py-2 border-b border-light">
          <div className="grid-title params">{intl.formatMessage({ id: 'process' })}</div>
          <div className="grid-title params">{intl.formatMessage({ id: 'name' })}</div>
          <div className="grid-title params">{intl.formatMessage({ id: 'value' })}</div>
        </div>
        <div>
          {params.map((item, index) => (
            <div className={` ${index % 2 && 'bg-grey-lighter-lighter'} `} key={index}>
              <div className="flex items-center justify-between border-t border-smoke py-2 h-10 cursor-pointer">
                <div className="row-element params">{group}</div>
                <div className="row-element params">{item.paramName}</div>
                <div className="row-element params">{item.paramValue || item.defaultValue}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
