import { getGroupRestrictionsAsync, getSpecificGroupNavigationsAsync } from '../../../../../../api/securityManager';
import { NavigationGroupService } from './useNavigations.hook';

export interface Restrictions {
  [key: string]: Restriction;
}

export interface Navigations {
  [key: string]: Navigation;
}

interface Navigation {
  navigationCode: number;
  sectionCode: number;
  group: string;
  allow: number;
}

export interface Restriction {
  group: string;
  id: string;
  allow: number;
  type: string;
  title: string;
  fechaHabilitacion: string;
  parentPermission: number;
}

interface RestrictionService {
  grupo: string;
  identificacion: string;
  permite: number;
  tipo: string;
  titulo: string;
  fechaHabilitacion: string;
}

export interface UseRestrictions {
  getMenuRestrictionsAsync: (group: string) => Promise<Restrictions>;
  getReportsRestrictionsAsync: (group: string) => Promise<Restrictions>;
  getOperationRestrictionsAsync: (group: string) => Promise<Restrictions>;
  getPermissionRestrictionsAsync: (group: string) => Promise<Restrictions>;
  getNavigationRestrictionsAsync: (group: string) => Promise<Navigations>;
  fillNumberWithZeros: (numberToFill: number, width?: number) => string;
}

const MENU_TYPE = 'M';
const OPERATION_TYPE = 'O';
const PERMISSION_TYPE = 'P';
const REPORT_TYPE = 'Q';

export function useRestrictions(): UseRestrictions {
  function fillNumberWithZeros(numberToFill: number, width: number = 4): string {
    const zero = '0';
    let result = numberToFill?.toString() || zero;

    return result.length >= width ? result : new Array(width - result.length + 1).join(zero) + result;
  }

  async function getMenuRestrictionsAsync(group: string): Promise<Restrictions> {
    try {
      if (group === '') {
        return Promise.resolve({});
      }
      const restrictions: RestrictionService[] = await getGroupRestrictionsAsync(group, MENU_TYPE);
      return mapRestrictionServiceToRestriction(restrictions);
    } catch (_) {
      return {};
    }
  }

  async function getReportsRestrictionsAsync(group: string): Promise<Restrictions> {
    try {
      if (group === '') {
        return Promise.resolve({});
      }
      const restrictions: RestrictionService[] = await getGroupRestrictionsAsync(group, REPORT_TYPE);
      return mapRestrictionServiceToRestriction(restrictions);
    } catch (_) {
      return {};
    }
  }

  async function getNavigationRestrictionsAsync(group: string): Promise<Navigations> {
    try {
      if (group === '') {
        return Promise.resolve({});
      }
      const restrictions: NavigationGroupService[] = await getSpecificGroupNavigationsAsync(group);
      return mapNavigationsServiceToRestriction(restrictions);
    } catch (_) {
      return {};
    }
  }

  async function getOperationRestrictionsAsync(group: string): Promise<Restrictions> {
    try {
      if (group === '') {
        return Promise.resolve({});
      }
      const restrictions: RestrictionService[] = await getGroupRestrictionsAsync(group, OPERATION_TYPE);
      return mapRestrictionServiceToRestriction(restrictions);
    } catch (_) {
      return {};
    }
  }

  async function getPermissionRestrictionsAsync(group: string): Promise<Restrictions> {
    try {
      const restrictions: RestrictionService[] = await getGroupRestrictionsAsync(group, PERMISSION_TYPE);
      return mapRestrictionServiceToRestriction(restrictions);
    } catch (_) {
      return {};
    }
  }

  function mapRestrictionServiceToRestriction(restrictions: RestrictionService[]): Restrictions | PromiseLike<Restrictions> {
    let tituloPrincipal = restrictions.filter((rest) => rest.titulo === '****');
    let parentPermission = 0;
    if (tituloPrincipal && tituloPrincipal.length > 0) {
      parentPermission = tituloPrincipal[0].permite;
    }
    return restrictions.reduce<Restrictions>((result, restriction) => {
      const mappedRestriction: Restriction = {
        allow: restriction.permite,
        group: restriction.grupo,
        id: restriction.identificacion,
        title: restriction.titulo,
        type: restriction.tipo,
        fechaHabilitacion: restriction.fechaHabilitacion,
        parentPermission: parentPermission,
      };
      const key = mappedRestriction.title + mappedRestriction.id;
      result[key] = mappedRestriction;

      return result;
    }, {});
  }

  function mapNavigationsServiceToRestriction(restrictions: NavigationGroupService[]): Navigations | PromiseLike<Navigations> {
    return restrictions.reduce<Navigations>((result, restriction) => {
      const mappedRestriction: Navigation = {
        allow: restriction.permite,
        group: restriction.grupo,
        navigationCode: restriction.codigo_navegacion,
        sectionCode: restriction.codigo_seccion,
      };
      const key = fillNumberWithZeros(mappedRestriction.navigationCode) + fillNumberWithZeros(mappedRestriction.sectionCode);
      result[key] = mappedRestriction;

      return result;
    }, {});
  }

  return {
    getReportsRestrictionsAsync,
    getMenuRestrictionsAsync,
    getOperationRestrictionsAsync,
    fillNumberWithZeros,
    getPermissionRestrictionsAsync,
    getNavigationRestrictionsAsync,
  };
}
