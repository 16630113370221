import React from 'react';
import { CHECKBOX_SELECTION, ERRORS_CHECKBOX, INFORMATION_CHECKBOX, RADIO_OPTION, WARNINGS_CHECKBOX } from '../../../../models/constants';
import { DetailsOptionsView } from './DetailsOptionsView';

interface DetailsOptionsViewModelProps {
  onOptionSelect: (option: string) => void;
  onErrorCheck: () => void;
  onInformationCheck: () => void;
  onWarningCheck: () => void;
  optionSelected: string;
  isErrorSelected: boolean;
  isWarningSelected: boolean;
  isInformationSelected: boolean;
}

export function DetailsOptionsViewModel({
  onOptionSelect,
  onErrorCheck,
  onInformationCheck,
  onWarningCheck,
  optionSelected,
  isErrorSelected,
  isInformationSelected,
  isWarningSelected,
}: DetailsOptionsViewModelProps): JSX.Element | null {
  function onChange(event: { target: HTMLInputElement }): void {
    if (event.target.name === RADIO_OPTION) {
      onOptionSelect(event.target.value);
    } else if (event.target.name === CHECKBOX_SELECTION) {
      updateCheckbox(event.target.value);
    }
  }

  function updateCheckbox(selectedCheckbox: string): void {
    if (selectedCheckbox === ERRORS_CHECKBOX) {
      onErrorCheck();
    } else if (selectedCheckbox === WARNINGS_CHECKBOX) {
      onWarningCheck();
    } else if (selectedCheckbox === INFORMATION_CHECKBOX) {
      onInformationCheck();
    }
  }

  return (
    <DetailsOptionsView
      optionSelected={optionSelected}
      onChange={onChange}
      isWarningSelected={isWarningSelected}
      isErrorSelected={isErrorSelected}
      isInformationSelected={isInformationSelected}
    />
  );
}
