import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'unistore/react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import en from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import { store, dispatchActions, storeActions } from '../../src/store';
import { useIntl, FormattedMessage } from 'react-intl';
import MaskedInput from 'react-text-mask';
import Displayable from './Displayable';
import { getDateFormat, getSelectedDate, dateRunFormat, formatDateFromServer } from '../helpers/dateHelper';
import { run, gotoLineWithBody } from '../../src/api/dialog';
import { getCurrentTabInformation, getTabInformation, getTabIdByNodeId } from '../../src/selectors';
import { createBodyGoToLine, createSimpleBody } from '../../renderer/lib/bodyCreators';
import { doFocusElement, canReceiveFocus } from '../../renderer/lib/focusManager';
import { textSize, mapColor } from '../helpers/styleHelper';
import { css, StyleSheet } from 'aphrodite';
import { useTheme } from './../../src/themes/ThemeContext';
import { compareTLId, getTLFromStr } from './../helpers/operationHelper';
import { num2hex } from '../../src/utils/ColorMaper';
import { includes } from 'busyman';
import { addValueTest, addLineEventTest, addBlurTest } from '../../src/components/Testing/HandleLineTest';

const DateInput = (props) => {
  const getValueFromComponentState = () => {
    let receivedStringValue = props.componentState && props.componentState.value;
    /* (props.componentState.value ||
        (props.componentState.raw && props.componentState.raw.displayInfo && props.componentState.raw.displayInfo.value)); */
    if (receivedStringValue && typeof receivedStringValue === 'string' && receivedStringValue.length > 15) {
      receivedStringValue = new Date(receivedStringValue);
    }
    return receivedStringValue;
  };

  const [selectedDate, setSelectedDate] = useState(getValueFromComponentState());
  const [showDate, setShowDate] = useState(true);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [focusGainedBy, setFocusGainedBy] = useState('');
  // const [validationFailed, setValidationFailed] = useState(false);
  let clientProperties = store.getState().clientProperties;
  let useBackColor = clientProperties?.useBackColor;
  let input = useRef(null);
  const intl = useIntl();

  let {
    label,
    placeholder = '',
    componentState,
    position = { x: 0, y: 0, width: 'auto', height: 'auto' },
    labelPosition = { x: 0, y: 0, width: 'auto', height: 'auto' },
    readOnly,
    showPrompt,
    inputParameters,
    focusElement,
    font,
    labelFont,
    transactionLineLabel,
    defaultValue,
    onKeyDown,
    operationID,
    operationNumber,
    processContext,
    removeFromWindowStack,
    removeTreeNode,
    fieldData,
    promptBackColor,
  } = props;

  let value = selectedDate;
  const disabled = componentState && componentState.disabled;
  if (!value && (readOnly === true || disabled === true) && defaultValue) {
    value = defaultValue;
  }

  let tailwindColorLabel = labelFont && mapColor(`${labelFont.colorRed}, ${labelFont.colorGreen}, ${labelFont.colorBlue}`);
  const tailwindColor = mapColor(`${font.colorRed}, ${font.colorGreen}, ${font.colorBlue}`);
  const fontSize = textSize(font.fontSize);
  const fontStyle = font.fontStyle;
  var backColor = 'transparent';

  if (promptBackColor === 'transparent' && useBackColor === 'false') {
    tailwindColorLabel = 'text-base';
  }
  if (promptBackColor !== undefined && promptBackColor !== 'transparent') {
    backColor = num2hex(promptBackColor.value);
  }

  let dateFormat = getDateFormat(inputParameters.edition);
  // let placeHolderText = dateFormat;
  if ((!value || value === '') && (readOnly || disabled)) {
    showDate && setShowDate(false);
    // placeHolderText = dateFormat = dateFormat.replace(/[a-zA-Z]/g, '0');
  } else {
    !showDate && setShowDate(true);
  }

  const isInWindowArea =
    componentState && componentState.windowWidth && componentState.windowHeight
      ? componentState.windowWidth > labelPosition.x && componentState.windowHeight > labelPosition.y
      : true;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => doFocusElement(props, input), [focusElement, props.transactionLineLabel, props.focusElement, props.focusPaused]);

  React.useEffect(() => {
    const dateInput = input && input.current && input.current.input && input.current.input.inputElement;
    const lastValueListener = (e) => {
      e = e || window.event;
      if (e.ctrlKey && e.keyCode === 76) {
        e.preventDefault();
        e.stopPropagation();
        window.event.returnValue = false;
        window.event.cancelBubble = true;
        const { addFieldValue, lastValues, transactionLineLabel, fieldNumber } = props;
        const lastValue = lastValues && lastValues[fieldNumber];
        if (lastValue) {
          const date = getSelectedDate(lastValue);
          setSelectedDate(date);
          const fieldValue = {
            [transactionLineLabel]: {
              value: lastValue,
            },
          };
          addFieldValue(fieldValue);
        }
        return false;
      }
    };
    if (dateInput) {
      dateInput.onkeydown = lastValueListener;
    }
    return () => {
      if (dateInput) {
        dateInput.removeEventListener('keydown', lastValueListener);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    const state = store.getState();
    const tabId = getTabIdByNodeId(state, props.nodeId);
    const { tabIndex } = getTabInformation(state, tabId);
    if (tabIndex === state.current) {
      const currentTab = state.tabs[tabIndex];
      const value = getValueFromComponentState();
      let valueSeted = includes(currentTab.running.valueSetElement, props.transactionLineLabel);
      if (!selectedDate && value && value !== '18000101' && valueSeted) {
        let dateToSelect = value;
        if (dateToSelect.length && dateToSelect.length === 8) {
          dateToSelect = formatDateFromServer(value);
        }
        const date = getSelectedDate(dateToSelect);
        if (selectedDate - date !== 0) {
          setSelectedDate(date);
        }
        removeFromValueSetElementList();
      } else if (selectedDate === value && valueSeted) {
        removeFromValueSetElementList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, selectedDate]);

  useEffect(() => {
    const { addFieldValue, transactionLineLabel } = props;
    const value = getValueFromComponentState();
    const fieldValue = {
      [transactionLineLabel]: {
        value: value,
      },
    };
    addFieldValue(fieldValue);

    if (props.lenguajeDefecto) {
      if (props.lenguajeDefecto === 'E') {
        registerLocale('es', es);
      } else if (props.lenguajeDefecto === 'P') {
        registerLocale('pt', pt);
      } else if (props.lenguajeDefecto === 'I') {
        registerLocale('en', en);
      }
    } else {
      registerLocale('es', es);
    }
    // La linea siguiente es necesaria para que el funcionamiento sea equivalente al ComponentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFromValueSetElementList = () => {
    let state = store.getState();
    const tabId = getTabIdByNodeId(state, props.nodeId);
    const { tabIndex } = getTabInformation(state, tabId);
    let valueSetElement = state.tabs[tabIndex].running.valueSetElement.filter((e) => {
      return e !== props.transactionLineLabel;
    });

    state.tabs[tabIndex].running.valueSetElement = valueSetElement;
    store.setState(state);
  };

  const onUpdateDate = (date, e) => {
    const {
      operationID,
      processContext,
      lineNumber,
      transactionNumber,
      operationNumber,
      transactionLineLabel,
      focusElement,
      fieldData,
      fieldNumber,
      setLastValue,
    } = props;

    let selectedValue = getSelectedDate(date);
    setLastValue(fieldNumber, selectedValue);
    const chosenDate = date && date !== '' ? dateRunFormat(date) : '18000101';
    let lineIDKey = getTLFromStr(transactionLineLabel);
    const body = {
      operationName: `oper${operationNumber}`,
      requestName: `oper${operationNumber}`,
      fields: chosenDate
        ? {
            [`${lineIDKey}`]: {
              value: `${chosenDate}`,
              originalValue: `${chosenDate}`,
              classNameValue: 8,
              classNameOriginalValue: 8,
            },
          }
        : {},
    };
    if (transactionLineLabel !== focusElement) {
      const bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
      gotoLineWithBody(operationID, transactionNumber, lineNumber, bodyGoToLine).then(() => {
        run(operationID, transactionNumber, lineNumber, body).then((res) => {
          processContext(res, 'Input Date Run');
        });
      });
    } else {
      run(operationID, transactionNumber, lineNumber, body).then((res) => {
        processContext(res, 'Input Date Run');
      });
    }
  };

  const doMask = (format) => {
    switch (format) {
      case 'yyyy/MM/dd': {
        return [/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/];
      }
      case 'dd/MM/yy':
      case 'MM/dd/yy':
      case 'yy/MM/dd': {
        return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/];
      }
      case 'MM/dd/yyyy':
      case 'dd/MM/yyyy': {
        return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
      }
    }
  };

  const validateField = (text) => {
    const { optional } = props;
    let dateFormat = getDateFormat(inputParameters.edition);
    if (!text && text !== 0 && !optional) {
      const { label } = props;
      const required = intl.formatMessage({
        id: 'errorMandatoryField',
        defaultMessage: 'es de ingreso obligatorio',
      });
      const msg = `${label} ${required}`;
      const modalOptions = {
        returnFocus: true,
      };
      props.showModalMessage(msg, modalOptions);
      return false;
    } else {
      return validateDate(dateFormat, text);
    }
  };

  const validateDate = (format, date) => {
    if (date === '') {
      return true;
    }
    const yearFrom = format.indexOf('y');
    const yearLength = format.split('y').length - 1;
    const year = parseInt(date.slice(yearFrom, yearFrom + yearLength));
    const monthFrom = format.indexOf('M');
    const month = parseInt(date.slice(monthFrom, monthFrom + 2));
    const dayFrom = format.indexOf('d');
    const day = parseInt(date.slice(dayFrom, dayFrom + 2));
    if (month > 0 && month < 13) {
      const daysInMonth = new Date(year, month, 0).getDate();
      if (day > 0 && day <= daysInMonth) {
        if (year > 1000) {
          return true;
        }
      }
    }
    const msg = intl.formatMessage({
      id: 'incorrectDateFormat',
      defaultMessage: `${date} es un formato de fecha incorrecto`,
    });
    props.showModalMessage(msg);
    return false;
  };

  const validateYear = (e) => {
    let inputValueYear = parseInt(document.getElementById(props.transactionLineLabel).value.substr(dateFormat.indexOf('yyyy'), 4));
    if (document.getElementById(props.transactionLineLabel).value && inputValueYear < 1000) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      const msg = (
        <span id={`${props.transactionLineLabel}_invalidDate`}>
          <FormattedMessage id="invalidDate" defaultMessage={`Fecha inválida`} />
        </span>
      );
      store.setState(dispatchActions.showModalMessage(store.getState(), msg));

      return false;
    }

    return true;
  };

  const click = (e) => {
    if (!canReceiveFocus(e)) return;
    const { operationID, processContext, lineNumber, transactionNumber, transactionLineLabel, focusElement, focusPaused } = props;
    if (!focusPaused && transactionLineLabel !== focusElement && !store.getState().validationFailed) {
      addValueTest('CLICK', transactionLineLabel, '');
      let body = createSimpleBody(operationNumber, transactionLineLabel, value);
      let bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
      gotoLineWithBody(operationID, transactionNumber, lineNumber, bodyGoToLine).then((r) => {
        processContext(r, 'gotoLine date');
      });
    }
  };

  const toggleCalendar = () => {
    input && input.current && input.current.setOpen(!calendarOpen);
    setCalendarOpen(!calendarOpen);
  };

  const closeCalendar = () => {
    input && input.current && input.current.setOpen(false);
    setCalendarOpen(false);
  };

  const clearValue = () => {
    setSelectedDate('');
    const { transactionLineLabel, addFieldValue } = props;
    const field = {
      [transactionLineLabel]: { value: '' },
    };
    addFieldValue(field);
  };

  const handleChange = (date) => {
    if (validateYear()) {
      const selectedDate = date ? getSelectedDate(date) : '';
      setSelectedDate(selectedDate);
      const { addFieldValue } = props;
      const valueToSet = date === null ? '18000101' : dateRunFormat(date);
      const fieldValue = {
        [transactionLineLabel]: {
          value: valueToSet,
        },
      };
      addFieldValue(fieldValue);
    }
  };

  const handleKeyDown = (e) => {
    addLineEventTest(e);
    const { readOnly } = props;
    const forward = e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey);
    const back = e.key === 'Escape' || (e.key === 'Tab' && e.shiftKey);
    const helpPressed = e.key === 'F1';
    const fromInput = e.target.localName === 'input';

    if (showDate && !readOnly && !disabled) {
      if (helpPressed) {
        e.preventDefault();
        toggleCalendar();
      } else if (forward && fromInput) {
        closeCalendar();
        e.preventDefault();
        e.stopPropagation();
        const dateValue = input.current.input.inputElement.value;

        if ((validateField(dateValue) || dateValue === '') && validateDate(dateFormat, dateValue)) {
          if (dateValue === '') {
            if (props.optional) {
              onUpdateDate(dateValue, e);
            }
            setSelectedDate(dateValue);
          } else {
            onUpdateDate(selectedDate, e);
          }
        }
      } else if (back) {
        if (store.getState().validationFailed) {
          store.setState({ validationFailed: false });
        }
        closeCalendar();
        clearValue();
        onKeyDown(e, operationID, operationNumber, processContext, focusElement, null, removeFromWindowStack, removeTreeNode);
      }
    }
  };

  const dateMask = doMask(dateFormat);

  const themeState = useTheme();
  const { input: inputStyle } = themeState.theme;

  const styles = StyleSheet.create({
    datePicker: {
      width: position.width,
      height: position.height,
      padding: '.25 .5rem',
      fontSize: '.7rem',
      borderRadius: '.25rem',
      color: calendarOpen ? 'transparent' : inputStyle.color,
      shadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
      appearence: 'none',
      textAlign: props.align === 2 ? 'right' : props.align === 1 ? 'center' : 'left',
      backgroundColor: readOnly ? 'bg-grey-lighter-lighter' : '#FFFFFF',
      ':disabled': {
        backgroundColor: '#F4F5F7',
      },
    },
    button: {
      left: position.x,
      top: position.y,
      width: position.width,
      height: position.height,
      zIndex: 'inherit',
      position: 'absolute',
      display: 'flex',
    },
    label: {
      left: labelPosition.x,
      top: labelPosition.y,
      width: labelPosition.width,
      height: labelPosition.height,
      zIndex: 'inherit',
      textDecoration: font.underLine ? 'underline' : font.strikeOut && 'line-through',
      fontSize: font.fontSize || 12,
      fontWeight: fontStyle === 1 && '400',
      fontStyle: fontStyle === 2 && 'italic',
      position: 'absolute',
      lineHeight: 2,
    },
    zIndex: {
      zIndex: 11,
    },
  });

  let dateInputValue = selectedDate;
  if (focusElement !== transactionLineLabel) {
    dateInputValue = getValueFromComponentState();
  }
  if (dateInputValue && dateInputValue.length && dateInputValue.length === 8) {
    if (dateInputValue === '18000101') {
      dateInputValue = '';
    } else {
      dateInputValue = formatDateFromServer(dateInputValue);
    }
  }
  dateInputValue = dateInputValue ? getSelectedDate(dateInputValue) : '';
  if (
    (componentState && componentState.raw && componentState.raw.displayInfo && componentState.raw.displayInfo.value === '18000101') ||
    (typeof dateInputValue === 'object' && dateInputValue.getFullYear() === 1800 && dateInputValue.getMonth() === 0 && dateInputValue.getDate() === 1)
  ) {
    dateInputValue = null;
  }

  if (
    dateInputValue &&
    selectedDate &&
    typeof dateInputValue === 'object' &&
    typeof selectedDate === 'object' &&
    (dateInputValue.getFullYear() !== selectedDate.getFullYear() ||
      dateInputValue.getMonth() !== selectedDate.getMonth() ||
      dateInputValue.getDate() !== selectedDate.getDate())
  ) {
    setSelectedDate(dateInputValue);
  }
  const underline = font.underLine ? 'underline' : '';
  const strikeout = font.strikeOut ? 'line-through' : '';
  const lineStyle = underline + ' ' + strikeout;

  const labelStyle = {
    left: labelPosition.x,
    top: labelPosition.y,
    width: labelPosition.width,
    height: labelPosition.height,
    zIndex: 'inherit',
    textDecoration: lineStyle,
    backgroundColor: backColor,
  };

  let borderFieldDisplayInfo =
    props.componentState &&
    props.componentState.raw &&
    props.componentState.raw.displayInfo &&
    props.componentState.raw.displayInfo.fieldDisplayInfo.border;
  return (
    <Displayable componentState={componentState}>
      <label
        className={`${
          fontStyle === 1 ? 'font-semibold' : fontStyle === 2 ? 'italic' : ''
        } ${fontSize} text-${tailwindColorLabel} absolute bg-grey-lighter-lighter`}
        style={{
          ...labelStyle,
          fontSize: (labelFont && labelFont.fontSize) || 12,
        }}
      >
        {showPrompt && isInWindowArea ? label : ''}
      </label>
      <div
        className={`${css(styles.button)} text-${tailwindColor} ${borderFieldDisplayInfo ? 'shadow' : ''}`}
        onMouseDown={() => {
          setFocusGainedBy('click');
        }}
        onClick={(e) => {
          if (readOnly) {
            for (const field in fieldData) {
              if (fieldData[field].position) {
                let xDiff = Math.abs(parseInt(fieldData[field].position.x) - parseInt(fieldData[transactionLineLabel].position.x));
                let yDiff = Math.abs(parseInt(fieldData[field].position.y) - parseInt(fieldData[transactionLineLabel].position.y));
                if (
                  xDiff <= 10 &&
                  yDiff <= 10 &&
                  field !== transactionLineLabel &&
                  fieldData[field].inputType !== 5 &&
                  fieldData[field].hide === false &&
                  fieldData[field].treeNumber === fieldData[transactionLineLabel].treeNumber
                ) {
                  fieldData[transactionLineLabel].hide = true;
                  this.forceUpdate();

                  const fieldToFocus = document.getElementById(field);

                  setTimeout(() => {
                    // eslint-disable-next-line no-undef
                    let newEvent = new MouseEvent('mousedown', {
                      bubbles: true,
                    });
                    fieldToFocus.dispatchEvent(newEvent);
                    fieldToFocus.focus();
                  }, 50);
                }
              }
            }
          } else {
            if (e.button === 0) {
              click(e);
            }
          }
        }}
      >
        <DatePicker
          id={transactionLineLabel}
          selected={showDate ? dateInputValue : null}
          autoFocus={false}
          customInput={!readOnly && dateMask && <MaskedInput type="text" mask={dateMask} />}
          ref={input}
          placeholderText={readOnly || disabled ? '' : ''}
          placeholder={placeholder}
          showMonthDropdown
          showYearDropdown
          locale={props.lenguajeDefecto === 'E' ? 'es' : props.lenguajeDefecto === 'P' ? 'pt' : 'en'}
          dropdownMode="select"
          autoComplete="off"
          className={css(styles.datePicker)}
          popperClassName={css(styles.zIndex)}
          readOnly={readOnly || disabled}
          disabled={readOnly || disabled}
          dateFormat={dateFormat}
          onChange={(e) => {
            handleChange(e);
          }}
          onChangeRaw={(e) => {
            const inputVal = e.target.value;
            if (inputVal && inputVal.replace('_', '').length === dateFormat.length) {
              validateYear();
              // validateField(inputVal);
            }
          }}
          onFocus={(e) => {
            if (focusGainedBy === 'click') {
              click(e);
              setFocusGainedBy('');
            }
          }}
          onSelect={(date, e) => {
            if (validateYear(e)) {
              if (e.type === 'click' || e.type === 'keydown') {
                const selectedDate = date ? getSelectedDate(date) : '';
                setSelectedDate(selectedDate);
                onUpdateDate(selectedDate, e);
                setCalendarOpen(false);
              } else if (e.type === 'change') {
                const selectedDate = date ? getSelectedDate(date) : '';
                setSelectedDate(selectedDate);
                if (calendarOpen === true) {
                  toggleCalendar();
                }
              }
            }
          }}
          onBlur={(e) => {
            if (e.relatedTarget === null || e.relatedTarget.classList.contains('contorno')) {
              doFocusElement(props, input);
            } else if (e.relatedTarget) {
              let relatedId = e && e.relatedTarget && e.relatedTarget.id ? e.relatedTarget.id : '';
              if (!relatedId) {
                relatedId = e.target.id;
              }
              if (compareTLId(transactionLineLabel, relatedId) > 0) {
                addBlurTest('SENDTEXT', e);
              }
              if (
                compareTLId(transactionLineLabel, relatedId) <= 0 ||
                e.relatedTarget.classList.contains('rc-tabs-bar') ||
                e.relatedTarget.classList.contains('border-smoke') ||
                relatedId === 'option' ||
                relatedId.indexOf('cancelButton') > -1
              ) {
                if (compareTLId(transactionLineLabel, relatedId) !== 0) {
                  if (store.getState().validationFailed) {
                    store.setState({ validationFailed: false });
                  }
                  input.current.clear();
                }
              } else {
                const { showingHelp } = props;
                if (!showingHelp && !validateField(e.target.value)) {
                  e.preventDefault();
                } else {
                  if (store.getState().validationFailed) {
                    store.setState({ validationFailed: false });
                  }
                }
              }
            }
          }}
          preventOpenOnFocus={true}
          shouldCloseOnSelect={true}
          onCalendarOpen={(e) => setCalendarOpen(true)}
          onCalendarClose={(e) => setCalendarOpen(false)}
          onKeyDown={(e) => {
            if (calendarOpen) {
              e.preventDefault();
            } else {
              handleKeyDown(e);

              let key = Number(e.key);
              if (!isNaN(key) && e.key !== null && e.key !== ' ') {
                document.execCommand('forwardDelete', false, true);
              }
            }
            if (e.key === 'F1') {
              e.preventDefault();
              toggleCalendar();
            }
          }}
        />
      </div>
    </Displayable>
  );
};

const mapStateToProps = (state) => {
  const { operationID, operationNumber, focusElement, focusPaused, operationData } = getCurrentTabInformation(state);
  const { lastValues, lenguajeDefecto } = state;
  const { valueSetElement } = operationData;

  return {
    lastValues,
    operationID,
    operationNumber,
    focusElement,
    focusPaused,
    valueSetElement,
    lenguajeDefecto,
  };
};

export default connect(mapStateToProps, storeActions)(DateInput);
