import React, { Component } from 'react';
import { getTabsLength } from '../selectors';
import CloseTabButton from '../../UI-kit/CloseTabButton';
import { connect } from 'unistore/react';
import '../styles/components/Tab.css';

class Tab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabHover: false,
      tabActive: false,
    };
  }
  handleMouseDown = (e) => {
    const { tabIndex, removable, handleRemoveTab, loadingBegin } = this.props;
    if (e.button === 1 && removable && !loadingBegin) {
      handleRemoveTab(tabIndex);
    }
  };
  handleHover = () => {
    if (!this.state.tabHover) {
      this.setState({ tabHover: true });
    }
  };
  handleBlur = () => {
    if (this.state.tabHover) {
      this.setState({ tabHover: false });
    }
  };

  dynamicTabStyle = () => {
    const { tabsLength, sidebarIsCollapsed } = this.props;
    let width = 'Tab';
    let sidebar = sidebarIsCollapsed ? 'collapsed' : 'open';
    if (tabsLength >= 6) {
      width = `Tab${tabsLength}${sidebar}`;
    }
    return `${width} mx-2 font-light text-xs truncate`;
  };

  render() {
    const { tabName, tabNumber, tabIndex, isEmptyTab, removable, newTabMessage, handleRemoveTab, loadingBegin } = this.props;
    return (
      <div>
        <span className={this.dynamicTabStyle()} title={!isEmptyTab ? tabName : undefined} onMouseDown={this.handleMouseDown}>
          {isEmptyTab ? (
            newTabMessage
          ) : (
            <div>
              <span className="font-bold">{tabNumber}</span>
              <span className="mx-1">{'-'}</span>
              <span className="truncate">{tabName}</span>
            </div>
          )}
        </span>
        <span>
          {removable && (
            <CloseTabButton
              handleClick={() => {
                !loadingBegin && handleRemoveTab(tabIndex);
              }}
            />
          )}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const tabsLength = getTabsLength(state);
  const sidebarIsCollapsed = state.sidebarIsCollapsed;
  const currentApp = state.currentApp;
  return { tabsLength, sidebarIsCollapsed, currentApp };
};

export default connect(mapStateToProps)(Tab);
