import React from 'react';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { useIntl } from 'react-intl';
import { TreeMenuItem } from 'react-simple-tree-menu';
import { GroupInstance } from '../../../models/groupInstance';

interface ContextMenuProps {
  node: TreeMenuItem;
  refreshInstance: (event: React.MouseEvent, data: GroupInstance) => void;
  monitorInstance?: (event: React.MouseEvent, data: GroupInstance) => void;
  stopInstance?: (event: React.MouseEvent, data: GroupInstance) => void;
  resumeInstance?: (event: React.MouseEvent, data: GroupInstance) => void;
}

export function ContextMenuOptions({ node, stopInstance, resumeInstance, refreshInstance, monitorInstance }: ContextMenuProps): JSX.Element {
  const intl = useIntl();

  return (
    <ContextMenu id={node.key}>
      <MenuItem data={node.item} onClick={refreshInstance}>
        <span>{intl.formatMessage({ id: 'refresh' })}</span>
      </MenuItem>
      {monitorInstance && (
        <MenuItem data={node.item} onClick={monitorInstance}>
          <span>{intl.formatMessage({ id: 'Monitor' })}</span>
        </MenuItem>
      )}
      {stopInstance && (
        <MenuItem data={node.item} onClick={stopInstance}>
          <span>{intl.formatMessage({ id: 'stop' })}</span>
        </MenuItem>
      )}
      {resumeInstance && (
        <MenuItem data={node.item} onClick={resumeInstance}>
          <span>{intl.formatMessage({ id: 'resume' })}</span>
        </MenuItem>
      )}
      <MenuItem>
        <span>{intl.formatMessage({ id: 'close' })}</span>
      </MenuItem>
    </ContextMenu>
  );
}
