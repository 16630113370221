import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

export class BranchesSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radioSelecting: 1,
    };
    this.handleStateRadio = this.handleStateRadio.bind(this);
  }

  handleStateRadio(e) {
    const { handleActiveBranches } = this.props;
    this.setState({ radioSelecting: e.target.value });
    handleActiveBranches(e.target.value, true);
  }

  render() {
    let { radioSelecting } = this.state;
    return (
      <div className="flex justify-center flex-row mt-5 mb-5">
        <div key="1" className="flex content-center mb-2 text-sm  mr-5">
          <input
            type="radio"
            checked={radioSelecting === 1}
            value={1}
            className="focus:shadow-sm-light"
            onChange={this.handleStateRadio}
            id="type1"
          />
          <label htmlFor="type1" className="ml-2">
            <FormattedMessage id="optionBranches" defaultMessage="Sucursales" />
          </label>
        </div>
        <div key="2" className="flex content-center mb-2 text-sm  ml-5">
          <input
            type="radio"
            checked={radioSelecting === 2}
            value={2}
            className="focus:shadow-sm-light"
            onChange={this.handleStateRadio}
            id="type2"
          />
          <label htmlFor="type2" className="ml-2">
            <FormattedMessage id="optionVirtualBranches" defaultMessage="Sucursales Virtuales" />
          </label>
        </div>
      </div>
    );
  }
}

export default injectIntl(BranchesSelector);
