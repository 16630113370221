import React, { useState } from 'react';
import '../../SecurityManager.scss';
import { SecurityMenu } from '../securityMenu/SecurityMenu';
import { SecurityDetailModal } from '../securityDetailModal/SecurityDetailModal';
import { GroupsAsTreeMenu } from '../../models/groupsAsTreeMenu';

import { SelectedGroup } from '../../models/types';
import { UserBasicInfo } from '../../models/userBasicInfo';

interface SecurityInformationProps {
  securityGroups: GroupsAsTreeMenu[];
  onSelectGroup: (group: SelectedGroup) => void;
  selectedGroup?: SelectedGroup;
  modalOpen: string;
  onModalChange: (modalOpenName: string) => void;
  expandedTree: boolean;
  removeGroupAsync: (group: GroupsAsTreeMenu) => void;
  removeUserAsync: (user: UserBasicInfo) => void;
  isEditing: boolean;
  onIsEditingChange: (isEditing: boolean) => void;
  hasUserPermissions: boolean;
  hasGroupPermissions: boolean;
  isWarningToEdit: boolean;
  onIsWarningToEditChange: (isEdit: boolean) => void;
  selectedItem: SelectedGroup | undefined;
  hasModifiedInformation: boolean;
  changesWarning: boolean;
  onSelectedItemChange: (node: SelectedGroup) => void;
  onChangesWarningChange: (isShowing: boolean, undoChanges: boolean) => void;
  onHasModifiedChange: (hasModified: boolean) => void;
  onRefresh: () => void;
  setChangesWarning: (isShowing: boolean) => void;
  undoChangesResponse: boolean;
  setUndoChangesResponse: (undoChanges: boolean) => void;
  setIsLoadingGroup: (isLoading: boolean) => void;
  isLoadingGroup: boolean;
}

export function SecurityInformation({
  onSelectGroup,
  securityGroups,
  selectedGroup,
  modalOpen,
  onModalChange,
  expandedTree,
  removeUserAsync,
  removeGroupAsync,
  isEditing,
  onIsEditingChange,
  hasGroupPermissions,
  hasUserPermissions,
  isWarningToEdit,
  onIsWarningToEditChange,
  selectedItem,
  onChangesWarningChange,
  onSelectedItemChange,
  onHasModifiedChange,
  hasModifiedInformation,
  changesWarning,
  onRefresh,
  setChangesWarning,
  undoChangesResponse,
  setUndoChangesResponse,
  setIsLoadingGroup,
  isLoadingGroup
}: SecurityInformationProps): JSX.Element {




  return (
    <div className="security-container">
      <div className="menu-container rounded border border-light">
        <SecurityMenu
          setChangesWarning={setChangesWarning}
          onIsWarningToEditChange={onIsWarningToEditChange}
          onIsEditingChange={onIsEditingChange}
          onModalChange={onModalChange}
          selectedItem={selectedItem}
          onSelectedItemChange={onSelectedItemChange}
          onChangesWarningChange={onChangesWarningChange}
          onHasModifiedChange={onHasModifiedChange}
          modalOpen={modalOpen}
          securityGroups={securityGroups}
          onSelectGroup={onSelectGroup}
          expandedTree={expandedTree}
          hasModifiedInformation={hasModifiedInformation}
          undoChangesResponse={undoChangesResponse}
          setUndoChangesResponse={setUndoChangesResponse}
          isLoadingGroup={isLoadingGroup}
        />
      </div>
      <SecurityDetailModal
        onRefresh={onRefresh}
        setChangesWarning={setChangesWarning}
        onHasModifiedChange={onHasModifiedChange}
        hasModifiedInformation={hasModifiedInformation}
        onIsEditingChange={onIsEditingChange}
        isWarningToEdit={isWarningToEdit}
        hasGroupPermissions={hasGroupPermissions}
        hasUserPermissions={hasUserPermissions}
        selectedItem={selectedItem}
        onSelectedItemChange={onSelectedItemChange}
        changesWarning={changesWarning}
        onChangesWarningChange={onChangesWarningChange}
        selectedGroup={selectedGroup}
        modalOpen={modalOpen}
        onModalChange={onModalChange}
        securityGroups={securityGroups}
        removeGroupAsync={removeGroupAsync}
        removeUserAsync={removeUserAsync}
        isEditing={isEditing}
        onSelectGroup={onSelectGroup}
        setIsLoadingGroup={setIsLoadingGroup}
        isLoadingGroup={isLoadingGroup}
      />
    </div>
  );
}
