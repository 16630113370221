/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import '../../styles/components/SchemaView.scss';
import Help from '../../resources/icons/fa/help.svg';
import { HelpCampoComponent } from './HelpWindows/HelpCampoComponent';
import { BasePopupWindow } from './HelpWindows/BasePopupWindow.js';
import { updateEsquemaCampo, addConcepto, addEsquemaCampo, getDescripcionesTable, getDescripcionesCampo } from '../../api/schema.js';
import { addFieldValue } from '../../actions';
import ModalMessage from '../../components/ModalMessage';

export const EditFieldWindow = (props) => {
  const intl = useIntl();
  const ID_HELP_CAMPO = 'Campo';
  const [helpWindowOpen, setHelpWindowOpen] = useState('');
  let [node, setNode] = useState(props.node);
  let [nodoAnterior, setNodoAnterior] = useState(props.node);
  let [forceSelectHelp, setForceSelectHelp] = useState(false);
  let [modalData, setModalData] = useState({});
  let [campo, setCampo] = useState('');
  let [checked, setChecked] = useState(false);
  let [ordinalEs, setOrdinalEs] = useState(props.node.ordinalMayor);
  let [titleHelp, setTitleHelp] = useState('');
  let [campoError, setCampoError] = useState('');
  let [color, setColor] = useState('');

  const helpWindowStructure = {};

  let campoNoEsValido = intl.formatMessage({
    id: 'campoIngresadoNoValido',
    defaultMessage: 'El Campo ingresado no es válido',
  });

  let campoNoPuedeSerVacio = intl.formatMessage({
    id: 'valorCampoNoVacio',
    defaultMessage: 'El valor "Campo" no puede ser vacío',
  });

  useEffect(() => {
    if (JSON.stringify(nodoAnterior) !== JSON.stringify(props.node)) {
      setNode(props.node);
      setNodoAnterior(props.node);
    }
  }, [nodoAnterior, props.node]);

  useEffect(() => {
    if (node.valorRubroSalida === true) {
      document.getElementById('valorRubroDeSalidaCheck').checked = true;
    } else {
      document.getElementById('valorRubroDeSalidaCheck').checked = false;
    }
    getDescripcionesCampo().then(function (response) {
      let esta = false;
      for (let i = 0; i < response.length; i++) {
        if (response[i][0] === node.numero) {
          setCampoError('');
          setCampo(response[i][1]);
          esta = true;
        }
      }
      if (!esta) {
        setCampo('');
      }
    });
  }, [node.numero, node.valorRubroSalida]);

  const handleKeyDown = (e) => {
    if (['-', '+', 'e', '.'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const validoCampo = (campo) => {
    let esta = false;
    let encontro = '';
    getDescripcionesCampo().then(function (response) {
      for (let i = 0; i < response.length; i++) {
        if (response[i][0] === parseInt(campo)) {
          setCampoError('');
          esta = true;
          encontro = response[i][1];
        }
      }
      if (!esta) {
        setCampoError(campoNoEsValido);
        setColor('red');
      } else {
        setCampoError(encontro);
        setColor('grey');
      }
    });
  };

  let toggleWindow = (window) => {
    setHelpWindowOpen(window);
  };

  const setHelpValue = (value) => {
    if (value) {
      if (helpWindowOpen === ID_HELP_CAMPO) {
        setNode({ ...node, numero: value[0] });
        setCampo(value[1]);
      }
    }
    setForceSelectHelp(false);
    toggleWindow('');
  };

  const setForceSelect = (value) => {
    setForceSelectHelp(value);
  };
  helpWindowStructure[ID_HELP_CAMPO] = <HelpCampoComponent currentValue={node.campo} setHelpValue={setHelpValue} forceSelectHelp={forceSelectHelp} />;

  let helpWindow = null;
  if (helpWindowOpen) {
    helpWindow = (
      <BasePopupWindow
        helpWindowOpen={helpWindowStructure[helpWindowOpen]}
        toggleWindow={toggleWindow}
        setForceSelect={setForceSelect}
        title={titleHelp}
      ></BasePopupWindow>
    );
  }
  const updateCampo = () => {
    if (campoError !== campoNoEsValido) {
      let nuevoNodo = {
        proceso: node.proceso,
        rubro: node.rubro,
        moneda: node.moneda,
        linea: node.linea,
        ordinal: node.ordinal,
        cp: node.cp,
        numero: node.numero,
        formula: node.formula,
      };

      updateEsquemaCampo(nuevoNodo).then((response) => {
        if (response === true) {
          props.updateNodo(node.key, 'campo');
          setModalData({
            showDialog: true,
            textDialog: intl.formatMessage({
              id: 'updatedOk',
              defaultMessage: 'Se ha actualizado correctamente',
            }),
          });
        } else {
          setModalData({
            showDialog: true,
            textDialog: intl.formatMessage({
              id: 'updateFieldErr',
              defaultMessage: 'Ocurrio un error al actualizar el campo',
            }),
          });
        }
      });
    } else {
      setModalData({ showDialog: true, textDialog: campoNoEsValido });
    }
  };

  const addField = () => {
    if (node.numero === undefined) {
      setModalData({ showDialog: true, textDialog: campoNoPuedeSerVacio });
      throw new Error(campoNoPuedeSerVacio);
    }
    if (campoError !== campoNoEsValido) {
      let nuevoNodo = {
        proceso: node.proceso,
        rubro: node.rubro,
        moneda: node.moneda,
        linea: node.linea,
        ordinal: calculoOrdinal(checked),
        cp: 'C',
        numero: node.numero,
        formula: node.formula,
      };
      addEsquemaCampo(nuevoNodo).then((response) => {
        if (response !== true) {
          setModalData({
            showDialog: true,
            textDialog: intl.formatMessage({
              id: 'fieldAlreadyExists',
              defaultMessage: 'El Campo específico ya existe',
            }),
          });
        } else {
          setModalData({
            showDialog: true,
            textDialog: intl.formatMessage({
              id: 'addedOk',
              defaultMessage: 'Se ha agregado correctamente',
            }),
          });
          props.updateNodo();
          limpiar();
        }
      });
    } else {
      setModalData({ showDialog: true, textDialog: campoNoEsValido });
    }
  };

  const calculoOrdinal = (checkbox) => {
    let nuevoOrdinal = 0;
    let data = props.data[0].nodes;
    let key = node.key;
    let keyNodo = key.split('/', 3);
    for (let x = 0; x < data.length; x++) {
      if (data[x].key === keyNodo[1]) {
        let nodos = data[x].nodes;
        for (let i = 0; i < nodos.length; i++) {
          if (nodos[i].key === keyNodo[2]) {
            let lineas = nodos[i].nodes;
            for (let l = 0; l < lineas.length; l++) {
              if (checkbox) {
                if (lineas[l].ordinal > 99) {
                  nuevoOrdinal = lineas[l].ordinal;
                }
              } else {
                if (lineas[l].ordinal < 99) {
                  nuevoOrdinal = lineas[l].ordinal;
                }
              }
            }
          }
        }
      }
    }
    if (nuevoOrdinal === 0 && checkbox) {
      nuevoOrdinal = 101;
      return nuevoOrdinal;
    } else if (nuevoOrdinal === 0 && !checkbox) {
      nuevoOrdinal = 1;
      return nuevoOrdinal;
    }
    return nuevoOrdinal + 1;
  };

  const limpiar = () => {
    let vacioNodos = {
      proceso: '',
      rubro: '',
      moneda: '',
      linea: '',
      ordinal: '',
      cp: '',
      numero: '',
      formula: '',
    };
    setNode(vacioNodos);
    setCampo('');
  };

  const bloqueoBotonSiNodeEsIgualANodeEliminado = () => {
    let bloqueo = false;
    if (props.nodesAEliminar.length !== 0) {
      let eliminados = props.nodesAEliminar;
      for (let i = 0; i < eliminados.length; i++) {
        if (eliminados[i].key === node.key) {
          bloqueo = true;
          break;
        }
      }
    }
    return bloqueo;
  };

  return (
    <div>
      {helpWindow}
      <div>
        {props.showWindow === 'EditarF' ? (
          <h6 style={{ fontSize: '20px' }}>
            {intl.formatMessage({
              id: 'field',
              defaultMessage: 'Campo',
            })}
          </h6>
        ) : (
          <h6 style={{ fontSize: '20px' }}>
            {intl.formatMessage({
              id: 'newfield',
              defaultMessage: 'Nuevo campo',
            })}
          </h6>
        )}
      </div>
      <div className={'container'} style={{ marginTop: '10px', paddingLeft: '20px' }}>
        <hr />
        <div className="row" style={{ marginTop: '20px', display: 'flex' }}>
          <div className="col-3">
            <label htmlFor="Campo">
              {intl.formatMessage({
                id: 'field',
                defaultMessage: 'Campo',
              })}
            </label>
          </div>
          <div className="col-5">
            <input
              type="number"
              min="0"
              onKeyDown={(e) => handleKeyDown(e)}
              value={node.numero}
              onChange={(e) => setNode({ ...node, numero: e.target.value })}
              disabled={!props.postData}
              onBlur={(e) => validoCampo(e.target.value)}
              className={'clear-both border-solid rounded'}
              style={{ border: '1px solid #CED0CE', marginLeft: '67px' }}
              id="Campo"
            ></input>
            <button
              className="iconHelp ml-1"
              style={{ width: '25px', outline: 'none' }}
              disabled={!props.postData}
              onClick={() => {
                setTitleHelp(
                  intl.formatMessage({
                    id: 'field',
                    defaultMessage: 'Campo',
                  }),
                );
                toggleWindow(ID_HELP_CAMPO);
              }}
            >
              <Help />
            </button>
          </div>
          <div
            className="col-4"
            style={{ width: '200px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflowY: 'hidden', overflowX: 'hidden', color: 'darkgray' }}
          >
            {campo}
            <label style={{ color: color }}>{campoError}</label>
          </div>
        </div>
        <div className="row" style={{ marginTop: '20px', display: 'flex' }}>
          <div className="col-3">
            <label htmlFor="Formula">
              {intl.formatMessage({
                id: 'formula',
                defaultMessage: 'Fórmula',
              })}
            </label>
          </div>
          <div className="col-9">
            <input
              value={node.formula}
              disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
              onChange={(e) => setNode({ ...node, formula: e.target.value })}
              className={'clear-both border-solid ml-2 rounded'}
              style={{ border: '1px solid #CED0CE', marginLeft: '60px' }}
              id="Formula"
            ></input>
          </div>
        </div>
        <div className="row mt-1" style={{ marginTop: '20px', display: 'flex', marginLeft: '10px' }}>
          <div className="col-3"></div>
          <div className="col-9">
            <input
              type="checkbox"
              disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
              // checked={node.ordinalMayor === 'MA'}
              onChange={(e) => setChecked(!checked)}
              id="valorRubroDeSalidaCheck"
            ></input>
            <label className="ml-1" htmlFor="valorRubroSalida">
              {intl.formatMessage({
                id: 'exitItemValue',
                defaultMessage: 'Valor de Rubro de Salida',
              })}
            </label>
          </div>
        </div>
        <div className="modal__footer mt-4">
          <div className="row d-flex justify-content-between" style={{ marginTop: '50px', float: 'right' }}>
            <div></div>
            <div>
              {props.postData === true ? (
                <button type="submit" onClick={addField} className="btn btn-outline-info btn-sm btnDesign">
                  {intl.formatMessage({
                    id: 'Tablas.movimientos_alta',
                    defaultMessage: 'Alta',
                  })}
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={bloqueoBotonSiNodeEsIgualANodeEliminado()}
                  onClick={updateCampo}
                  className="btn btn-outline-info btn-sm btnDesign"
                >
                  {intl.formatMessage({
                    id: 'modify',
                    defaultMessage: 'Modificar',
                  })}
                </button>
              )}
            </div>
          </div>
        </div>
        {modalData.showDialog && (
          <ModalMessage
            message={modalData.textDialog}
            handleAccept={async () => {
              setModalData({ showDialog: false });
            }}
            acceptTitle={intl.formatMessage({
              id: 'accept',
              defaultMessage: 'Aceptar',
            })}
            draggable={true}
          />
        )}
      </div>
    </div>
  );
};

export default EditFieldWindow;
