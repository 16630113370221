import { API } from '../config';
import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { getUrl, handleError } from './handlers';

const activateMutexSync = true;
const retryTimeoutMS = 20;

export let createHelpSession = (operationID, helpSize = 101) => {
  if (!mutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);

    return axios
      .post(`${API}TopazWebClientWS/resources/operations/createHelpSession?operationID=${operationID}&sessionId=${session}&helpSize=${helpSize}`)
      .then(({ data: helpSessionId }) => {
        return helpSessionId;
      })
      .finally(() => {
        mutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(createHelpSession(operationID, helpSize));
      }, retryTimeoutMS);
    });
  }
};

export let getHelpMetadata = (helpSessionId, fieldNumber) => {
  if (!mutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    return axios
      .post(`${API}TopazWebClientWS/resources/help/getHelpMetadata?fieldNumber=${fieldNumber}&helpID=${helpSessionId}&sessionId=${session}`)
      .then(({ data: helpMetadata }) => {
        return helpMetadata;
      })
      .catch(() => {
        return { fieldInfos: [] };
      })
      .finally(() => {
        mutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(getHelpMetadata(helpSessionId, fieldNumber));
      }, retryTimeoutMS);
    });
  }
};

export let getHelpData = async (helpSessionId, fieldNumber, showAll, numOfRecords) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'help',
      action: 'getHelpData',
      params: `number=${numOfRecords}&helpID=${helpSessionId}&fieldNumber=${fieldNumber}&showAll=${showAll}&sessionId=${session}`,
    };

    const response = await axios.post(getUrl(query));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleError(error);
  }
};

export let getHelpDataWithFilter = (helpSessionId, fieldNumber, columnNumber, operator, value, showAll, numOfRecords) => {
  if (!mutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    var headers = { 'Content-Type': 'application/json' };
    return axios
      .post(
        `${API}TopazWebClientWS/resources/help/getHelpDataWithFilter?number=${numOfRecords}&fieldNumber=${fieldNumber}&columnNumber=${columnNumber}&operator=${operator}&value=${value}&showAll=${showAll}&helpID=${helpSessionId}&sessionId=${session}`,
        null,
        {
          headers,
        },
      )
      .then(({ data: item }) => {
        return item;
      })
      .finally(() => {
        mutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(initHelpWithFilter());
      }, retryTimeoutMS);
    });
  }
};

export let initHelp = async (helpSessionId, fieldNumber, showAll) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'help',
      action: 'init',
      params: `helpID=${helpSessionId}&fieldNumber=${fieldNumber}&showAll=${showAll}&sessionId=${session}`,
    };

    const response = await axios.post(getUrl(query));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleError(error);
  }
};

export let initHelpWithFilter = (helpSessionId, fieldNumber, columnNumber, operator, value, showAll) => {
  if (!mutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    var headers = { 'Content-Type': 'application/json' };
    return axios
      .post(
        `${API}TopazWebClientWS/resources/help/initWithFilter?fieldNumber=${fieldNumber}&columnNumber=${columnNumber}&operator=${operator}&value=${value}&showAll=${showAll}&helpID=${helpSessionId}&sessionId=${session}`,
        null,
        {
          headers,
        },
      )
      .then(({ data: helpId }) => {
        return helpId;
      })
      .finally(() => {
        mutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(initHelpWithFilter());
      }, retryTimeoutMS);
    });
  }
};

export let hasNext = (helpSessionId) => {
  if (!mutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    return axios
      .post(`${API}TopazWebClientWS/resources/help/hasNext?helpID=${helpSessionId}&sessionId=${session}`)
      .then(({ data: hasNext }) => {
        return hasNext;
      })
      .finally(() => {
        mutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(hasNext(helpSessionId));
      }, retryTimeoutMS);
    });
  }
};

export let getNext = (helpSessionId, number) => {
  if (!mutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    return axios
      .post(`${API}TopazWebClientWS/resources/help/next?helpID=${helpSessionId}&number=${number}&sessionId=${session}`)
      .then(({ data: item }) => {
        return item;
      })
      .finally(() => {
        mutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(getNext(helpSessionId, number));
      }, retryTimeoutMS);
    });
  }
};

export let closeHelp = (helpSessionId) => {
  if (!mutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    return axios
      .post(`${API}TopazWebClientWS/resources/help/close?helpID=${helpSessionId}&sessionId=${session}`)
      .then(({ data: item }) => {
        return item;
      })
      .finally(() => {
        mutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(closeHelp(helpSessionId));
      }, retryTimeoutMS);
    });
  }
};

browserStorage.sessionSet('SERVER_COMM_MUTEX_STATE', false);

let mutexState = (set) => {
  if (!activateMutexSync) {
    return false;
  }
  let mutexState = browserStorage.sessionGet('SERVER_COMM_MUTEX_STATE');
  if (mutexState && set) {
    return true;
  } else if (set !== undefined) {
    browserStorage.sessionSet('SERVER_COMM_MUTEX_STATE', set);
    return false;
  } else {
    return mutexState;
  }
};

export let getHelpSize = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'help',
      action: 'getHelpSize',
      params: `sessionId=${session}`,
    };

    const response = await axios.get(getUrl(query));
    if (response.status === 200) {
      return response.data;
    } else {
      return 100;
    }
  } catch (error) {
    return 100;
  }
};
