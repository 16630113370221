import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'unistore/react';
import 'react-table/react-table.css';
import { FormattedMessage } from 'react-intl';
import ListOfCausesView from './ListOfCausesView';
import { storeActions } from '../../../store';
// import { css, StyleSheet } from 'aphrodite';

export const ListOfCauses = (props) => {
  const { causes, setCauses } = props;

  // const intl = useIntl();

  const [rows, setRows] = useState([]);
  const [selectedCauses, setSelectedCauses] = useState([]);
  const [currentCauses, setCurrentCauses] = useState([]);

  const refChecks = useRef([]);
  const refCauseList = useRef();
  const refListEntry = useRef();

  useEffect(() => {
    let receivedCauses = causes;
    setCurrentCauses(receivedCauses);
    const crows = createRows(receivedCauses);
    setRows(crows);
    updateSelectedCauses(receivedCauses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSelectedCauses = (selCauses) => {
    let listReturn = [];
    let listSelected = [...selectedCauses];
    listSelected.map((item) => {
      const cause = selCauses.filter((element) => item.value === element.value);
      listReturn.push(cause[0]);
    });
    setSelectedCauses(listReturn);
    setCauses(listReturn);
  };

  const addToRefs = (r) => {
    if (r && !refChecks.current.includes(r)) {
      refChecks.current.push(r);
    }
  };

  const addCausesListRef = (element) => {
    refCauseList.current = element;
  };

  const handleSelectedCause = (row, selected) => {
    let listSelected = [...selectedCauses];
    let listReturn = [];
    const branch = currentCauses.filter(
      (item) => item.value === parseInt(row.causal.charAt(0)),
    );

    if (selected) {
      listReturn = addSelectedCause(branch[0], listSelected);
    } else {
      listReturn = removeCause(branch[0], listSelected);
    }
    setSelectedCauses(listReturn);
    setCauses(listReturn);
  };

  const addSelectedCause = (cause, listSelected) => {
    listSelected.push(cause);
    return listSelected;
  };

  const removeCause = (cause, listSelected) => {
    let c = listSelected.filter((element) => element.value !== cause.value);
    return c;
  };

  const createRows = (causes) => {
    let crows = [];
    if (causes && causes instanceof Array) {
      causes.map((data) => {
        let { value, text } = data;
        let row = {
          causal: `${value} - ${text}`,
        };
        crows.push(row);
      });
    }
    return crows;
  };

  const addToRefListEntry = (r) => {
    if (r && refListEntry.current && !refListEntry.current.includes(r)) {
      refListEntry.current.push(r);
    }
  };

  return (
    <div>
      <div className="flex-grow mr-4 rounded border border-light flex">
        <ListOfCausesView
          data={rows}
          defaultPageSize={30}
          className="-striped -highlight bg-grey-lighter-lighter"
          noDataText={
            <p className="text-sm w-full text-center leading-normal">
              <FormattedMessage
                id="noBranchFound"
                defaultMessage={`No se encontraron sucursales`}
              />
              <br />
            </p>
          }
          addToRefs={addToRefs}
          ref={(ref) => addCausesListRef(ref)}
          handleSelectedCause={async (item, selected) => {
            handleSelectedCause(item, selected);
          }}
          addToRefListEntry={addToRefListEntry}
        />
      </div>
    </div>
  );
};

export default connect(null, storeActions)(ListOfCauses);
