import React from 'react';
import './processDetail.scss';

import { useIntl } from 'react-intl';
import { GroupDefinition } from '../../../models/groupDefinition';
import { Parameter } from '../../../models/parameter';
import { Process } from '../../../models/process';
import { useProcessManager } from '../../../hooks/useProcessManager';

interface ParameterInformationProps {
  selectedProcess: GroupDefinition | Process;
}

export function ParametersInformation({ selectedProcess }: ParameterInformationProps): JSX.Element {
  const intl = useIntl();
  const { getParameters } = useProcessManager();
  const parameters: Parameter[] = getParameters(selectedProcess);

  function renderMandatoryCell(parameter: Parameter): string {
    return parameter.mandatory ? 'M' : 'O';
  }

  return (
    <div className="w-full">
      <div className="pl-2">
        <div className="flex items-center justify-between text-xs uppercase text-grey py-2 border-b border-light">
          <div className="w-6 pl-4">{intl.formatMessage({ id: 'M/O' })}</div>
          <div className="w-48 pr-2">{intl.formatMessage({ id: 'name' })}</div>
          <div className="w-48 pr-2">{intl.formatMessage({ id: 'type' })}</div>
          <div className="w-48 pr-2">{intl.formatMessage({ id: 'format' })}</div>
        </div>
        <div className="parameters-information">
          {parameters.map((item, index) => (
            <div className={` ${index % 2 && 'bg-grey-lighter-lighter'} `} key={index}>
              <div className="flex items-center justify-between border-t border-smoke py-2 h-10 cursor-pointer">
                <div className="w-6 font-mono text-xs pl-4">{renderMandatoryCell(item)}</div>
                <div className="w-48 font-mono text-xs">{item.paramName}</div>
                <div className="w-48 font-mono text-xs">{item.paramType}</div>
                <div className="w-48 font-mono text-xs">{item.paramFormat}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
