import * as React from 'react';
import TreeMenuWrapper, { ItemComponent, Item } from 'react-simple-tree-menu';

import './ProcessesMenu.scss';

import { GroupDefinition } from '../../../models/groupDefinition';
import { useIntl } from 'react-intl';
import { Process } from '../../../models/process';
import { SelectedProcess } from '../../../models/types';
import closedFolderIcon from '../../../icons/closedFolder';
import openedFolderIcon from '../../../icons/openedFolder';
import excecuteProcessIcon from '../../../icons/excecuteProcess';
import { ContextMenuTrigger } from 'react-contextmenu';
import { ContextData, ContextPeriodicityOptions } from './contextPeriodicity/ContextPeriodicityOptions';
import forkFolderIcon from '../../../icons/forkFolder';

interface GroupToTree {
  key: string;
  label: string;
  item: SelectedProcess;
  nodes: GroupToTree[];
}
interface ProcessesMenuProps {
  processesGroups: GroupDefinition[];
  onSelectProcess: (process: SelectedProcess) => void;
  showModal: () => void;
  selectedProcess: SelectedProcess;
  onModalConditionChange: (isOpen: boolean, isConditioningBoth?: boolean) => void;
  onModalScheduleChange: (isOpen: boolean) => void;
}

const SELECTED_PROCESS_BACKGROUND: string = 'rgba(190, 198, 209, 0.616)';

export function ProcessesMenu({
  processesGroups,
  onSelectProcess,
  showModal,
  selectedProcess,
  onModalConditionChange,
  onModalScheduleChange,
}: ProcessesMenuProps) {
  const intl = useIntl();
  let index = 0;
  const treeData: GroupToTree[] = mapGroupsToTree(processesGroups);

  function mapGroupsToTree(groupNodes: GroupDefinition[]): GroupToTree[] {
    const mappedChild: GroupToTree[] = groupNodes.map((group) => {
      index++;

      return {
        key: group.name + index,
        label: group.name,
        nodes: group.childs.group
          ? group.childs.fork
            ? mapGroupsToTree(group.childs.group).concat(mapGroupsToTree(group.childs.fork))
            : mapGroupsToTree(group.childs.group)
          : group.childs.process
            ? mapProcessToTree(group.childs.process)
            : [],
        item: group,
      };
    });

    return mappedChild;
  }

  function mapProcessToTree(process: Process[]): GroupToTree[] {
    const mappedProcess: GroupToTree[] = process.map((p) => {
      index++;

      return {
        key: p.name + index,
        label: p.name,
        nodes: [],
        item: p,
      };
    });

    return mappedProcess;
  }

  function onClick(node: Item): void {
    onSelectProcess(node.item);
  }

  function onClickExecute(node: Item): () => void {
    return () => {
      onSelectProcess(node.item);
      showModal();
    };
  }

  function onModalChange(event: React.MouseEvent, data: ContextData): void {
    onSelectProcess(data.node);
    if (data.isCondition) {
      onModalConditionChange(true, data.isConditioningBoth);
    } else {
      onModalScheduleChange(true);
    }
  }

  function onClickText(node: Item): void {
    node.onClick();
    if (node.toggleNode !== undefined) {
      node.toggleNode();
    }
  }

  return (
    <div className="processes-menu-container">
      <div className="title">{intl.formatMessage({ id: 'processes' })}</div>
      <TreeMenuWrapper onClickItem={onClick} data={treeData} hasSearch={true}>
        {({ search, items: nodes }) => (
          <>
            <input onChange={(e) => search(e.target.value)} style={{ width: '100%' }} placeholder="Filtrar" />
            <ul className="tree-item-group">
              {nodes.map((node) => (
                <div key={node.key + Math.random().toString()}>
                  <ContextMenuTrigger id={node.key}>
                    {node.level === 0 ? (
                      <div
                        className={`flex rstm-tree-item-level${node.level} items-center border-b justify-between ${selectedProcess === node.item && 'selected-process'
                          }`}
                      >
                        <ItemComponent
                          {...node}
                          openedIcon={node.item.childs.fork ? forkFolderIcon : openedFolderIcon}
                          closedIcon={node.item.childs.fork ? forkFolderIcon : closedFolderIcon}
                          style={{ paddingLeft: '0rem', paddingRight: '10px', backgroundColor: 'transparent', border: 'none' }}
                          onClick={() => onClickText(node)}
                        />
                        <button
                          onClick={onClickExecute(node)}
                          key={node.key + Math.random().toString()}
                          className="button"
                          title={intl.formatMessage({ id: 'execute' })}
                        >
                          {excecuteProcessIcon}
                        </button>
                      </div>
                    ) : (
                      <ItemComponent
                        {...node}
                        openedIcon={openedFolderIcon}
                        closedIcon={closedFolderIcon}
                        style={{ backgroundColor: `${selectedProcess === node.item ? SELECTED_PROCESS_BACKGROUND : ''}` }}
                        onClick={() => onClickText(node)}
                      />
                    )}
                  </ContextMenuTrigger>
                  <ContextPeriodicityOptions onModalChange={onModalChange} node={node} />
                </div>
              ))}
            </ul>
          </>
        )}
      </TreeMenuWrapper>
    </div>
  );
}
