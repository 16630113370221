import React from 'react';

const WIDTH = 19;

const Help = ({ style, click, tlid }) => (
  <button
    id={tlid}
    className="absolute bg-smoke text-xs rounded-r-lg shadow rounded-l-none opacity-75 hover:opacity-100"
    style={{ ...style, width: WIDTH, position: 'relative' }}
    onClick={click}
  >
    ?
  </button>
);

export default Help;
