import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { storeActions } from '../../src/store';
import Displayable from './Displayable';
import { getCurrentTabInformation } from '../../src/selectors';
import { run, gotoLineWithBody } from '../../src/api/dialog';
import { createRunBody, createBodyGoToLine } from '../../renderer/lib/bodyCreators';
import { addLineTest, addLineEventTest } from '../../src/components/Testing/HandleLineTest';
import { canReceiveFocus } from '../../renderer/lib/focusManager';

export class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.focusElement = this.focusElement.bind(this);
    this.state = { isChecked: false };
    this.input = React.createRef();
  }

  focusElement() {
    const { focusPaused, transactionLineLabel, focusElement } = this.props;
    if (!focusPaused && transactionLineLabel === focusElement) {
      if (this.input && this.input.current) {
        this.input.current.focus();
      }
    }
  }

  componentDidMount() {
    this.focusElement();

    const { transactionLineLabel, fieldData, options } = this.props;
    if (fieldData[transactionLineLabel].value !== undefined) {
      if (fieldData[transactionLineLabel].value === options[0].value) {
        this.setState({ isChecked: true });
      } else {
        this.setState({ isChecked: false });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { transactionLineLabel, fieldData, options } = this.props;

    if (!fieldData || !fieldData[transactionLineLabel]) {
      return;
    }

    if (fieldData[transactionLineLabel].value !== undefined) {
      if (fieldData[transactionLineLabel].value === options[0].value) {
        !this.state.isChecked && this.setState({ isChecked: true });
      } else {
        this.state.isChecked && this.setState({ isChecked: false });
      }
    }
  }

  async onInputChange(e) {
    let {
      onChange,
      operationNumber,
      operationID,
      processContext,
      transactionLineLabel,
      lineNumber,
      transactionNumber,
      focusElement,
      fieldData,
      options,
    } = this.props;
    addLineTest('CLICK', e);
    if (!canReceiveFocus(e)) return;
    let valueChecked = e.target.checked === true ? options[0].value : options[1].value;
    onChange({ target: { value: valueChecked } });
    this.setState({ isChecked: !this.state.isChecked });
    let body = createRunBody(operationNumber, transactionLineLabel, valueChecked);
    if (transactionLineLabel !== focusElement) {
      let bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
      let resLine = await gotoLineWithBody(operationID, transactionNumber, lineNumber, bodyGoToLine);
      processContext(resLine, 'Checkbox Input gotoLine');
    }
    let res = await run(operationID, transactionNumber, lineNumber, body);
    processContext(res, 'Checkbox Input Run');
  }

  render() {
    let {
      position,
      labelFont,
      componentState,
      showPrompt,
      label,
      transactionLineLabel,
      onKeyDown,
      operationID,
      operationNumber,
      processContext,
      focusElement,
      readOnly,
      removeFromWindowStack,
      removeTreeNode,
    } = this.props;

    const positionStyle = {
      left: position.x,
      top: position.y,
      width: position.width,
      height: position.height,
    };

    this.focusElement();

    if (!componentState) return null;

    let disabled = componentState && componentState.disabled;

    return (
      <Displayable componentState={componentState}>
        <div style={{ ...positionStyle }} className="relative">
          <input
            id={transactionLineLabel}
            className="focus:shadow-sm-light"
            ref={this.input}
            style={{ marginRight: 5 }}
            type="checkbox"
            checked={this.state.isChecked}
            disabled={readOnly || disabled ? 'disabled' : ''}
            onChange={this.onInputChange}
            onBlur={(e) => {
              if (e.relatedTarget === null || e.relatedTarget.classList.contains('contorno')) {
                this.focusElement();
              }
            }}
            onKeyDown={(e) => {
              addLineEventTest(e);
              if (e.key === 'Escape' || (e.key === 'Tab' && e.shiftKey) || e.key === 'Enter' || e.key === 'Tab') {
                onKeyDown(e, operationID, operationNumber, processContext, focusElement, null, removeFromWindowStack, removeTreeNode);
              }
            }}
          />
          <span className={`${showPrompt ? '' : 'hidden'}`} style={{ fontSize: (labelFont && labelFont.fontSize) || 12, padding: 2 }}>
            {label}
          </span>
        </div>
      </Displayable>
    );
  }
}

const mapStateToProps = (state) => {
  const { operationID, operationNumber, focusElement, focusPaused } = getCurrentTabInformation(state);

  return {
    operationID,
    operationNumber,
    focusElement,
    focusPaused,
  };
};

export default connect(mapStateToProps, storeActions)(Checkbox);
