import React, { PureComponent, Children } from 'react';
import { connect } from 'unistore/react';
import { storeActions, store, dispatchActions } from '../../src/store';
import { undo } from '../../src/api/dialog';
import Draggable from 'react-draggable';
import { FormattedMessage } from 'react-intl';
import { addLineTest, addLineEventTest } from '../../src/components/Testing/HandleLineTest';
import Displayable from './Displayable';
import WindowButton from './WindowButton';
import Overlay from './Overlay';
import { getCurrentTabInformation } from '../../src/selectors';
import { getIterationCountFromLineIDString, getParentDialog } from '../../UI-kit/helpers/operationHelper';

const BUTTONS_RIGHT = 1;

export class Window extends PureComponent {
  render() {
    let {
      title,
      accept,
      cancel,
      style,
      acceptHandler,
      cancelHandler,
      position = { x: 0, y: 0, width: 'auto', height: 'auto' },
      zIndex,
      operationNumber,
      operationID,
      operationLook,
      processContext,
      setApiLoading,
      apiLoading,
      removeTreeFields,
      componentState,
      text,
      removeFromWindowStack,
      focusOn,
      transactionLineLabel,
      buttonsPosition,
      transactionNumber,
      lineNumber,
      goBackToDialog,
      addEmptyTab,
      addTab,
      tabs,
      openGlobalModal,
      addEmptyTabReport,
      addTabReport,
      closeGlobalModal,
      clearStateByNodeId,
    } = this.props;

    const state = store.getState();
    const tabIndex = state.current;
    const currentTab = state.tabs.length && state.tabs[tabIndex];
    const focusElement = currentTab && currentTab.running && currentTab.running.focusElement;

    let tabsReport = store.getState().tabsReport;
    const acceptTitle = <FormattedMessage id="accept" defaultMessage={`Aceptar`} />;
    const cancelTitle = <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />;

    let hasFocus =
      focusOn && focusOn[0] && transactionNumber ? focusOn[0].substring(1, focusOn[0].indexOf('L')) === transactionNumber.toString() : false;

    let childrenLayout = Children.map(this.props.children, (child) => {
      return <div className="w-full px-2">{child}</div>;
    });

    const parentTL = this.props.fieldData ? getParentDialog(this.props.fieldData, transactionLineLabel, true, false, false, null, true) : null;
    let acceptButton = accept ? (
      <WindowButton
        htmlId={`acceptButton_${transactionLineLabel}`}
        isFocused={focusElement === transactionLineLabel && hasFocus}
        className={`${BUTTONS_RIGHT === buttonsPosition ? 'px-4' : 'px-6 z-10'}`}
        title={acceptTitle}
        disabled={apiLoading || (focusOn?.length > 0 && parentTL !== focusOn[0])}
        click={(e, btnRef) => {
          if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0 && parentTL === focusOn[0]) {
            addLineTest('CLICK', e);
            acceptHandler(
              operationNumber,
              operationID,
              processContext,
              addEmptyTab,
              addTab,
              tabs,
              removeFromWindowStack,
              openGlobalModal,
              addEmptyTabReport,
              addTabReport,
              closeGlobalModal,
              tabsReport,
              btnRef,
              setApiLoading,
              apiLoading,
            );
          }
        }}
        onKeyDown={async (e) => {
          addLineEventTest(e);
          if (e.key === 'Escape' || (e.shiftKey && e.key === 'Tab')) {
            let res = await undo(operationID, transactionNumber, lineNumber);
            store.setState(dispatchActions.reverseFlow(store.getState()));
            processContext(res, 'Window Undo');
            store.setState(dispatchActions.reverseFlow(store.getState(), false));
          } else if (e.key === 'Enter') {
            acceptHandler(
              operationNumber,
              operationID,
              processContext,
              addEmptyTab,
              addTab,
              tabs,
              removeFromWindowStack,
              openGlobalModal,
              addEmptyTabReport,
              addTabReport,
              closeGlobalModal,
              tabsReport,
              null,
              setApiLoading,
            );
          } else if (e.keyCode === 76 && e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();
          } else if (e.key === 'ArrowRight') {
            document.getElementById(`cancelButton_${transactionLineLabel}`).focus();
          }
        }}
      />
    ) : null;

    let cancelButton = cancel ? (
      <WindowButton
        htmlId={`cancelButton_${transactionLineLabel}`}
        className={`${BUTTONS_RIGHT === buttonsPosition ? 'px-4' : 'float-right ml-6 px-6 z-10'}`}
        title={cancelTitle}
        isCancel={true}
        disabled={apiLoading || (focusOn?.length > 0 && parentTL !== focusOn[0])}
        click={(e) => {
          if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0 && parentTL === focusOn[0]) {
            addLineTest('CLICK', e);
            cancelHandler(
              operationID,
              processContext,
              removeTreeFields,
              removeFromWindowStack,
              focusOn,
              goBackToDialog,
              clearStateByNodeId,
              apiLoading,
            );
          }
        }}
        onKeyDown={async (e) => {
          addLineEventTest(e);
          if (e.key === 'Escape' || e.key === 'ArrowLeft') {
            document.getElementById(`acceptButton_${transactionLineLabel}`).focus();
          } else if (e.key === 'Tab' && !e.shiftKey) {
            e.preventDefault();
          } else if (e.keyCode === 76 && e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();
          } else if (e.key === 'Enter') {
            cancelHandler(operationID, processContext, removeTreeFields, removeFromWindowStack, focusOn, goBackToDialog, clearStateByNodeId);
          }
        }}
      />
    ) : null;

    const isInIteration = getIterationCountFromLineIDString(this.props.transactionLineLabel) !== '0';
    if (componentState.hide) {
      return (
        <Displayable componentState={componentState}>
          {!isInIteration && <Overlay style={{ zIndex, top: -35 }} />}
          <Draggable handle=".handleDragg" bounds="body">
            <div></div>
          </Draggable>
        </Displayable>
      );
    }
    return (
      <Displayable onKeyDown={(e) => addLineEventTest(e)} componentState={componentState}>
        {!isInIteration && <Overlay style={{ zIndex, top: -35 }} />}
        <Draggable handle=".handleDragg" bounds="body">
          <div
            style={{
              top: position.y,
              width: position.width,
              height: position.height,
              zIndex,
              clear: 'both',
              position: 'absolute',
              left: position.x,
            }}
          >
            <div
              className={`${title !== undefined ? 'p-1' : ''} w-full text-primary absolute flex flex-col justify-between pin-r ${
                operationLook === true ? 'border-0 bg-smoke-white ' : 'border-2 bg-secondary-lightest-low-hue'
              } border-b-0 ${hasFocus ? 'border-primary' : 'border-transparent'} rounded-t cursor-move handleDragg `}
              style={{
                transform: 'translateY(-98%)',
                width: 'inherit',
              }}
            >
              {title}
            </div>
            <div
              onKeyDown={(e) => addLineEventTest(e)}
              className={`absolute ${
                operationLook === true
                  ? 'border-0 bg-smoke-white'
                  : `${title !== undefined ? 'border-2' : 'border-r-2 border-l-2 border-b-2'} bg-grey-lighter-lighter shadow`
              } mt-7 rounded-b ${hasFocus ? 'border-primary' : 'border-transparent'}`}
              style={{
                ...style,
                width: position.width,
                height: position.height,
                zIndex,
                clear: 'both',
                borderTop: 'none',
              }}
            >
              <div className="px-10">
                {text ? <p>{text}</p> : ''}
                {childrenLayout}
              </div>
              <div
                className={`absolute flex ${
                  BUTTONS_RIGHT === buttonsPosition ? 'py-6 px-4 flex-col justify-between pin-r h-full' : 'py-3 px-10 justify-center pin-b w-full'
                }`}
              >
                {acceptButton}
                {cancelButton}
              </div>
            </div>
          </div>
        </Draggable>
      </Displayable>
    );
  }
}

const mapStateToProps = (state) => {
  const tabInfo = getCurrentTabInformation(state);
  const { operationID, operationNumber, operationLook, focusOn, postingNumber, tabs, tabID, apiLoading } = tabInfo;
  const fieldData = tabID > -1 ? tabInfo?.tabs[tabID]?.running?.stateByLineID : null;

  return {
    operationID,
    operationNumber,
    operationLook,
    focusOn,
    postingNumber,
    tabs,
    fieldData,
    apiLoading: apiLoading || state.apiLoading,
  };
};

export default connect(mapStateToProps, storeActions)(Window);
