import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import FocusLock from 'react-focus-lock';
import Draggable from 'react-draggable';
import { useTheme } from '../themes/ThemeContext';
import { changePassword } from '../api/session';
import { getUserName } from '../helpers/login';
import { useIntl } from 'react-intl';

export const ModalChangePassword = ({ setShowModalPassword }) => {
  const handleKeyDown = (e) => {};

  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const username = getUserName();
  const intl = useIntl();

  const passwordChange = intl.formatMessage({
    id: 'passwordChange',
    defaultMessage: 'Cambio de contraseña',
  });
  const confirmPasswordError = intl.formatMessage({
    id: 'confirmPasswordError',
    defaultMessage: 'La nueva contraseña y su confirmación no coinciden',
  });
  const currentPasswordRequired = intl.formatMessage({
    id: 'currentPasswordRequired',
    defaultMessage: 'La contraseña actual es requerida',
  });
  const newPasswordRequired = intl.formatMessage({
    id: 'newPasswordRequired',
    defaultMessage: 'La nueva contraseña es requerida',
  });
  const confirmPasswordRequired = intl.formatMessage({
    id: 'confirmPasswordRequired',
    defaultMessage: 'La confirmación de contraseña es requerida',
  });
  const newPasswordEqualsOld = intl.formatMessage({
    id: 'newPasswordEqualsOld',
    defaultMessage: 'La nueva contraseña no puede ser igual a la anterior',
  });
  const passwordChangedSuccess = intl.formatMessage({
    id: 'passwordChangedSuccess',
    defaultMessage: 'La contraseña se cambió correctamente',
  });
  const placeholderCurrentPassword = intl.formatMessage({
    id: 'currentPassword',
    defaultMessage: 'Contraseña actual',
  });
  const placeholderNewPassword = intl.formatMessage({
    id: 'newPassword',
    defaultMessage: 'Nueva contraseña',
  });
  const placeholderConfirmPassword = intl.formatMessage({
    id: 'confirmPassword',
    defaultMessage: 'Confirmar nueva contraseña',
  });
  const acceptButtonText = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });
  const cancelButtonText = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  const handleButtonClick = (e) => {
    if (e === 'okModalButton') {
      if (success) {
        setShowModalPassword(false);
        return;
      }
      if (validateData() !== '') {
        setError(validateData());
      } else {
        setError('');
        handleSubmit();
      }
    } else if (e === 'cancelModalButton') {
      setShowModalPassword(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const result = await changePassword(username, currentPassword, newPassword);
      if (result === '1') {
        setSuccess(passwordChangedSuccess);
      }
    } catch (err) {
      setError(err.message || err);
    } finally {
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'currentPassword') setCurrentPassword(value);
    if (name === 'newPassword') setNewPassword(value);
    if (name === 'confirmPassword') setConfirmPassword(value);
  };

  const validateData = () => {
    if (currentPassword === '') return currentPasswordRequired;
    if (newPassword === '') return newPasswordRequired;
    if (confirmPassword === '') return confirmPasswordRequired;
    if (newPassword !== confirmPassword) return confirmPasswordError;
    if (currentPassword === newPassword) return newPasswordEqualsOld;
    if (newPassword !== '' && newPassword === confirmPassword) return '';
  };

  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      setShowModalPassword(false);
    }
  });

  // **********************     STYLES      *****************************
  const themeContext = useTheme();
  const { color, commonButton, modal } = themeContext.theme;
  const styles = StyleSheet.create({
    label: {
      fontSize: '11px',
    },
    inputPassword: {
      marginBottom: 10,
      marginTop: 2,
      height: '2rem',
      width: '20rem',
      borderBottom: '1px solid #ccc',
      ':focus': {
        outline: 'none',
        borderBottomColor: '#000',
      },
    },
    errorMessage: {
      color: '#D8000C',
      fontSize: '12px',
      marginTop: '0.5rem',
      paddingBottom: '2rem',
      textAlign: 'center',
      height: 20,
      overflowWrap: 'break-word',
      width: '100%',
      paddingRight: '5px',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    successMessage: {
      color: '#4F8A10',
      fontSize: '12px',
      marginTop: '0.5rem',
      paddingBottom: '2rem',
      textAlign: 'center',
      height: 20,
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    show: {
      display: 'block',
      zIndex: 900,
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    modalMain: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxHeight: '80%',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: modal.active.background,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: 'transparent',
      opacity: '100%',
      width: 370,
      boxShadow: 'rgba(0, 0, 0, 0.33) 0px 2px 8px',
      transition: 'border background .2s',
      padding: '.2rem',
    },
    titleContainer: {
      width: '100%',
      minHeight: '1.5rem',
      padding: '.5rem 1rem',
      color: color.grey.dark4,
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    inputsContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1.5rem 1rem 0rem 1rem',
    },
    buttonsContainer: {
      padding: '.5rem .5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    button: {
      padding: '.5rem 1.2rem',
      marginRight: '.5rem',
      borderRadius: 4,
      display: 'block',
      backgroundColor: commonButton.default.background,
      color: commonButton.default.color,
      ':hover': {
        backgroundColor: commonButton.hover.background,
        color: commonButton.hover.color,
      },
      ':focus': {
        backgroundColor: commonButton.focus.background,
        color: commonButton.focus.color,
      },
      ':active': {
        backgroundColor: commonButton.active.background,
        color: commonButton.active.color,
      },
      transition: 'background .2s',
    },
  });

  return (
    <FocusLock persistentFocus returnFocus>
      <div className={css(styles.modal, styles.show)} onKeyDown={handleKeyDown}>
        <div className={css(styles.modalMain)}>
          <Draggable handle=".drag-section" bounds={{ top: -150, right: 350, bottom: 150, left: -350 }}>
            <div className={`${css(styles.modalContent)}`}>
              <div className={`drag-section ${css(styles.titleContainer)}`}>{passwordChange}</div>
              <div className={css(styles.inputsContainer)}>
                <label className={css(styles.label)} htmlFor="currentPassword">
                  {placeholderCurrentPassword}
                </label>
                <input
                  className={css(styles.inputPassword)}
                  type="password"
                  name="currentPassword"
                  value={currentPassword}
                  onChange={handleChange}
                  required
                />
                <label className={css(styles.label)} htmlFor="newPassword">
                  {placeholderNewPassword}
                </label>
                <input
                  className={css(styles.inputPassword)}
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  onChange={handleChange}
                  required
                />
                <label className={css(styles.label)} htmlFor="confirmPassword">
                  {placeholderConfirmPassword}
                </label>
                <input
                  className={css(styles.inputPassword)}
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleChange}
                  required
                />
              </div>
              {error && <p className={css(styles.errorMessage)}>{error}</p>}
              {success && <p className={css(styles.successMessage)}>{success}</p>}
              <div className={css(styles.buttonsContainer)}>
                <div style={{ display: 'flex' }}>
                  <button
                    id="okModalButton"
                    style={{
                      width: '100px',
                      fontSize: '12px',
                      marginRight: '15px',
                      outline: 'none',
                    }}
                    className={css(styles.button)}
                    onClick={(e) => {
                      if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) handleButtonClick('okModalButton');
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleButtonClick('okModalButton');
                      }
                    }}
                  >
                    {acceptButtonText}
                  </button>
                  <button
                    id="cancelModalButton"
                    style={{ width: '100px', fontSize: '12px' }}
                    className={css(styles.button)}
                    onClick={(e) => {
                      if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) handleButtonClick('cancelModalButton');
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleButtonClick('cancelModalButton');
                      }
                    }}
                  >
                    {cancelButtonText}
                  </button>
                </div>
              </div>
            </div>
          </Draggable>
        </div>
      </div>
    </FocusLock>
  );
};

export default ModalChangePassword;
