import React, { Component } from 'react';
import 'react-table/react-table.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import '../../styles/components/branches.css';
import { openBranch, closeBranch, finalizeBranch } from '../../api/branches';

export class BranchesButtonPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledOpen: true,
      disabledClose: true,
      disabledFinalize: true,
      disabledChangeDate: true,
    };
    this.handleState = this.handleState.bind(this);
    this.handleOpenBranch = this.handleOpenBranch.bind(this);
    this.handleCloseBranch = this.handleCloseBranch.bind(this);
    this.handleFinalizeBranch = this.handleFinalizeBranch.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.setDisabledButtons = this.setDisabledButtons.bind(this);
  }

  setDisabledButtons(stateBranchSelected) {
    if (stateBranchSelected === ' ') {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = {
        disabledOpen: true,
        disabledClose: true,
        disabledFinalize: true,
        disabledChangeDate: true,
      };
    }
    if (stateBranchSelected === 'A') {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = {
        disabledOpen: true,
        disabledClose: false,
        disabledFinalize: false,
        disabledChangeDate: true,
      };
    }
    if (stateBranchSelected === 'C') {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = {
        disabledOpen: false,
        disabledClose: true,
        disabledFinalize: false,
        disabledChangeDate: false,
      };
    }
    if (stateBranchSelected === 'F') {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = {
        disabledOpen: false,
        disabledClose: false,
        disabledFinalize: true,
        disabledChangeDate: false,
      };
    }
    if (stateBranchSelected === 'AC') {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = {
        disabledOpen: true,
        disabledClose: true,
        disabledFinalize: false,
        disabledChangeDate: true,
      };
    }
    if (stateBranchSelected === 'AF') {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = {
        disabledOpen: true,
        disabledClose: false,
        disabledFinalize: true,
        disabledChangeDate: true,
      };
    }
    if (stateBranchSelected === 'CF') {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = {
        disabledOpen: false,
        disabledClose: true,
        disabledFinalize: true,
        disabledChangeDate: false,
      };
    }
  }

  handleState(st) {
    const { getBranchs, handleASelectedState } = this.props;
    const branches = getBranchs();
    const branch = branches.filter((item) => item.status === st);
    if (branch.length === 0 || st === 'N') {
      this.setState({
        disabledOpen: true,
        disabledClose: true,
        disabledChangeDate: true,
        disabledFinalize: true,
      });
      handleASelectedState('N');
      return;
    }

    switch (st) {
      case 'A':
        if (
          this.state.disabledOpen === true &&
          this.state.disabledClose === false &&
          this.state.disabledChangeDate === true &&
          this.state.disabledFinalize === false
        ) {
          handleASelectedState(st);
          return;
        }
        this.setState({
          disabledOpen: true,
          disabledClose: false,
          disabledChangeDate: true,
          disabledFinalize: false,
        });
        break;
      case 'F':
        if (
          this.state.disabledOpen === false &&
          this.state.disabledClose === false &&
          this.state.disabledChangeDate === false &&
          this.state.disabledFinalize === true
        ) {
          handleASelectedState(st);
          return;
        }
        this.setState({
          disabledOpen: false,
          disabledClose: false,
          disabledChangeDate: false,
          disabledFinalize: true,
        });
        break;
      case 'C':
        if (
          this.state.disabledOpen === false &&
          this.state.disabledClose === true &&
          this.state.disabledChangeDate === false &&
          this.state.disabledFinalize === false
        ) {
          handleASelectedState(st);
          return;
        }
        this.setState({
          disabledOpen: false,
          disabledClose: true,
          disabledChangeDate: false,
          disabledFinalize: false,
        });
        break;
    }

    handleASelectedState(st);
  }

  handleOpenBranch = async () => {
    const { reloadBranches, getSelectedBranches, setModal } = this.props;
    const selectedBranches = getSelectedBranches();
    for (const sData of selectedBranches) {
      sData.status = 'A';
      let ret = await openBranch(sData);
      if (ret !== true) {
        setModal(true, ret);
      }
    }
    reloadBranches();
  };

  async handleCloseBranch() {
    const { reloadBranches, getSelectedBranches, setModal } = this.props;
    const selectedBranches = getSelectedBranches();
    for (const sData of selectedBranches) {
      sData.status = 'C';
      let ret = await closeBranch(sData);
      if (ret !== true) {
        setModal(true, ret);
      }
    }
    reloadBranches();
  }

  async handleFinalizeBranch() {
    const { reloadBranches, getSelectedBranches, setModal } = this.props;
    const selectedBranches = getSelectedBranches();
    for (const sData of selectedBranches) {
      sData.status = 'F';
      let ret = await finalizeBranch(sData);
      if (ret !== true) {
        setModal(true, ret);
      }
    }
    reloadBranches();
  }

  async handleChangeDate() {
    const { setShowChangeDateState } = this.props;
    setShowChangeDateState(true);
  }

  render() {
    const { buttonStates } = this.props;
    if (buttonStates && buttonStates.length > 0) {
      this.setDisabledButtons(buttonStates);
    }

    return (
      <div className="wrapper">
        <div className="box">
          <button
            className={
              this.state.disabledOpen
                ? 'button bg-grey text-white rounded py-2 px-4 text-sm opacity-50 cursor-not-allowed'
                : 'button bg-grey hover:bg-primary text-white rounded py-2 px-4 text-sm'
            }
            onClick={() => {
              this.handleOpenBranch();
            }}
            disabled={this.state.disabledOpen}
          >
            <FormattedMessage id="openBranch" defaultMessage={`Abrir Sucursal`} />
          </button>
          <button
            className={
              this.state.disabledClose
                ? 'button bg-grey text-white rounded py-2 px-4 text-sm opacity-50 cursor-not-allowed'
                : 'button bg-grey hover:bg-primary text-white rounded py-2 px-4 text-sm'
            }
            onClick={() => {
              this.handleCloseBranch();
            }}
            disabled={this.state.disabledClose}
          >
            <FormattedMessage id="closeBranch" defaultMessage={`Cerrar Sucursal`} />
          </button>
          <button
            className={
              this.state.disabledFinalize
                ? 'button bg-grey text-white rounded py-2 px-4 text-sm opacity-50 cursor-not-allowed'
                : 'button bg-grey hover:bg-primary text-white rounded py-2 px-4 text-sm'
            }
            onClick={() => {
              this.handleFinalizeBranch();
            }}
            disabled={this.state.disabledFinalize}
          >
            <FormattedMessage id="finalizeBranch" defaultMessage={`Finalizar Sucursal`} />
          </button>
          <button
            className={
              this.state.disabledChangeDate
                ? 'button bg-grey text-white rounded py-2 px-4 text-sm opacity-50 cursor-not-allowed'
                : 'button bg-grey hover:bg-primary text-white rounded py-2 px-4 text-sm'
            }
            onClick={() => {
              this.handleChangeDate();
            }}
            disabled={this.state.disabledChangeDate}
          >
            <FormattedMessage id="changeDate" defaultMessage={`Cambiar Fecha`} />
          </button>
        </div>

        <div className="box">
          <button
            className="button bg-grey hover:bg-primary text-white rounded py-2 px-4 text-sm"
            onClick={(e) => {
              this.handleState('A');
            }}
          >
            <FormattedMessage id="selectOpend" defaultMessage={`Seleccionar Abiertas`} />
          </button>
          <button
            className="button bg-grey hover:bg-primary text-white rounded py-2 px-4 text-sm"
            onClick={(e) => {
              this.handleState('C');
            }}
          >
            <FormattedMessage id="selectClosed" defaultMessage={`Seleccionar Cerradas`} />
          </button>
          <button
            className="button bg-grey hover:bg-primary text-white rounded py-2 px-4 text-sm"
            onClick={(e) => {
              this.handleState('F');
            }}
          >
            <FormattedMessage id="selectedFinalize" defaultMessage={`Seleccionar Finalizadas`} />
          </button>
          <button
            className="button bg-grey hover:bg-primary text-white rounded py-2 px-4 text-sm"
            onClick={(e) => {
              this.handleState('N');
            }}
          >
            <FormattedMessage id="deselectedAll" defaultMessage={`Desmarcar Todas`} />
          </button>
        </div>
      </div>
    );
  }
}

export default injectIntl(BranchesButtonPanel);
