import React from 'react';
import { useIntl } from 'react-intl';
import { Select, SelectData } from '../../select/Select';
import { SelectedGroup } from '../../../models/types';
import groupIcon from '../../../icons/group';
import userIcon from '../../../icons/user';
import { NO_MODAL_OPEN, TYPE_GROUP } from '../../../models/constants';

import './SearchModal.scss';

export interface SearchModalViewProps {
  onTypeChange: (type: string) => void;
  onSearch: () => void;
  searchResult: SelectedGroup[];
  selectCoincideOptions: SelectData[];
  selectedCoincide: string;
  onSelectCoincideChange: (coincideSelected: string) => void;
  selectLikeOptions: SelectData[];
  selectedLike: string;
  onSelectLikeChange: (likeSelected: string) => void;
  onSearchInputChange: (newInput: string) => void;
  onModalChange: (modalOpen: string) => void;
  onItemClick: (result: SelectedGroup) => (event: React.MouseEvent) => void;
}

export function SearchModalView({
  onTypeChange,
  onSearch,
  searchResult,
  selectCoincideOptions,
  selectedCoincide,
  onSelectCoincideChange,
  selectLikeOptions,
  selectedLike,
  onSelectLikeChange,
  onSearchInputChange,
  onModalChange,
  onItemClick,
}: SearchModalViewProps): JSX.Element | null {
  const intl = useIntl();

  function onSearchClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onSearch();
  }

  function onRadioButtonChange(e: { target: HTMLInputElement }): void {
    onTypeChange(e.target.value);
  }

  function renderResults(): JSX.Element {
    const renderedResults = searchResult.map((result, index) => {
      return (
        <div className={` ${index % 2 && 'bg-grey-lighter-lighter'} border-b border-grey-lighter`} key={result.name + index}>
          <button onClick={onItemClick(result)}>
            {result.type === TYPE_GROUP ? groupIcon : userIcon}
            {result.name}
          </button>
        </div>
      );
    });

    return <div className="m-2 w-full">{renderedResults}</div>;
  }

  function onInputChange(): (e: { target: HTMLInputElement }) => void {
    return (e) => {
      onSearchInputChange(e.target.value);
    };
  }

  function onCloseChange(event: React.MouseEvent): void {
    event.preventDefault();
    onModalChange(NO_MODAL_OPEN);
  }

  return (
    <div
      className="font-mono flex z-10 inset-0 overflow-y-auto min-w-full justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border shadow-sm"
          style={{ width: 'fit-content' }}
        >
          <div className="bg-blue-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900 m-2" id="modal-title">
                  {intl.formatMessage({ id: 'searcher' })}
                </h3>
                <fieldset className="rounded border border-light flex relative " style={{ border: '1px solid grey' }}>
                  <legend className="mb-2 ml-2">{intl.formatMessage({ id: 'Criterio de Búsqueda' })}</legend>
                  <div className="flex-col">
                    <div className="flex m-2 items-center mt-3" style={{ width: '30%' }}>
                      <input className="mr-2 ml-2" type="radio" value="GROUP" name="type" onChange={onRadioButtonChange} />
                      {intl.formatMessage({ id: 'groups' })}
                      <input className="mr-2 ml-2" type="radio" value="USER" name="type" onChange={onRadioButtonChange} />
                      {intl.formatMessage({ id: 'users' })}
                    </div>
                    <div className="flex">
                      <div className="flex items-center m-2">
                        <label className="pr-1 pt-2">{intl.formatMessage({ id: 'match' })}:</label>
                        <Select onSelectedChange={onSelectCoincideChange} options={selectCoincideOptions} selectedState={selectedCoincide} />
                        <Select onSelectedChange={onSelectLikeChange} options={selectLikeOptions} selectedState={selectedLike} />
                      </div>
                    </div>
                    <div className="m-2 flex items-center mb-3 ">
                      <label className="pr-1">{intl.formatMessage({ id: 'search' })}:</label>
                      <input className="border border-grey-lighter" onChange={onInputChange()} />
                    </div>
                  </div>
                  <button
                    onClick={onSearchClick}
                    type="button"
                    style={{ height: 'fit-content', marginBlockStart: 'auto', marginBlockEnd: '10px', marginRight: '10px' }}
                    className="w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium text-gray-700 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {intl.formatMessage({ id: 'search' })}
                  </button>
                </fieldset>
              </div>
            </div>
            <fieldset className="results-fieldset rounded border border-light flex relative mt-2">
              <legend className="ml-2">{intl.formatMessage({ id: 'results' })}</legend>
              {searchResult.length !== 0 && renderResults()}
            </fieldset>
          </div>
          <div className="w-full flex justify-end">
            <button
              onClick={onCloseChange}
              type="button"
              className="mr-3 mb-3 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {intl.formatMessage({ id: 'close' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
