import React, { useState, useEffect } from 'react';
import { connect } from 'unistore/react';
import { injectIntl } from 'react-intl';
import { store, storeActions } from '../../store';
import '../../styles/base.css';
import { keepAlive, getParameters } from '../../api/session';
import { verifyUserPermissions, logout } from '../../helpers/login';
import HomeView from './HomeView';
import useFooterState from '../../hooks/useFooterState';
import OpeIcon from './../../resources/icons/fa/bolt.svg';
import ReportIcon from './../../resources/icons/fa/clipboard-check.svg';
import PostingIcon from './../../resources/icons/fa/file-alt.svg';
import InboxIcon from './../../resources/icons/fa/inbox.svg';
import ClearingIcon from './../../resources/icons/fa/clearing.svg';
import BranchesIcon from './../../resources/icons/fa/branches.svg';
import BinnacleIcon from './../../resources/icons/fa/binnacle.svg';
import SecurityIcon from './../../resources/icons/fa/security.svg';
import ProcessIcon from './../../resources/icons/fa/process.svg';
import ProductionIcon from './../../resources/icons/fa/production.svg';
import SchemaIcon from './../../resources/icons/fa/schema.svg';
import { PATHNAME } from '../../../src/config';

const APP_CLASSICCLIENT = 'ClassicClient';
const APP_REPORT = 'tzpquery.exe';
const APP_POSTINGMGR = 'postingList';
const APP_JBPM = 'jbpm';
const APP_CLEARING = 'ClearingDig';
const APP_SUCURSALES = 'branchesMgr';
const APP_BITACORA = 'Tzpbitac.exe';
const APP_PROCESSMANAGER = 'processMgr';
const APP_SECURITY = 'Tzmsegur.exe';
const APP_PRODUCCION = 'Tzmprodu.exe';
const APP_ESQUEMAS = 'Tzmesqco.exe';

let idleTime = 0;
let timeout = 0;

const HomeViewModel = (props) => {
  const { tabs, history } = props;
  const [footerInfo, setFooterInfo] = useFooterState();
  const [sideBarMap, setSideBarMap] = useState([]);
  const [userAllowed, setUserAllowed] = useState(true);
  const [showModalPassword, setShowModalPassword] = useState(false);

  useEffect(() => {
    let interval;
    let idleInterval;
    sidebarData();
    keepAlive().then((data) => {
      interval = setInterval(() => {
        keepAlive();
      }, data.message);
    });
    // eslint-disable-next-line no-unused-vars
    getParameters().then((data) => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeout = data.tiempoCorte;
      if (timeout !== 0) {
        idleInterval = setInterval(timerIncrement, 60000);
      }
      store.setState({ lenguajeDefecto: data.lenguajeDefecto });
    });
    setFooterInfo();

    return () => {
      clearInterval(interval);
      clearInterval(idleInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // empty braces means componentDidMount behavior

  const verifyPermissions = async (programName) => {
    if (programName) {
      let data = await verifyUserPermissions(programName).then(data);
      return data;
    }
  };

  document.addEventListener('keydown', function (e) {
    if (e.ctrlKey && e.code === 'F12') {
      if (!showModalPassword) {
        setShowModalPassword(true);
      }
    }
  });

  const timerIncrement = () => {
    idleTime = idleTime + 1;
    if (idleTime > timeout) {
      logout();
    }
  };

  const sidebarData = async () => {
    let data = [];
    let index = 0;
    let permission = await verifyPermissions(APP_CLASSICCLIENT);
    if (permission === true) {
      data[index] = {
        option: props.intl.formatMessage({
          id: 'operationsSidebar',
          defaultMessage: 'Operaciones',
        }),
        icon: OpeIcon,
        link: `${PATHNAME}home`,
        id: 'idOperaciones',
      };
      props.setCurrentApp('OPERATIONS');
      index++;
    }

    permission = await verifyPermissions(APP_REPORT);
    if (permission === true) {
      data[index] = {
        option: props.intl.formatMessage({
          id: 'reportsSidebar',
          defaultMessage: 'Reportes',
        }),
        icon: ReportIcon,
        link: `${PATHNAME}home/reports`,
        id: 'idReportes',
      };
      if (data.length === 1) {
        props.setCurrentApp('REPORTS');
        history.push(`${PATHNAME}home/reports`);
      }
      index++;
    }

    permission = await verifyPermissions(APP_POSTINGMGR);
    if (permission === true) {
      data[index] = {
        option: props.intl.formatMessage({
          id: 'postingsSidebar',
          defaultMessage: 'Visor de asientos',
        }),
        icon: PostingIcon,
        link: `${PATHNAME}home/viewer`,
        id: 'idAsientos',
      };
      if (data.length === 1) {
        history.push(`${PATHNAME}home/viewer`);
      }
      index++;
    }

    permission = await verifyPermissions(APP_JBPM);
    if (permission === true) {
      data[index] = {
        option: props.intl.formatMessage({
          id: 'workflowSidebar',
          defaultMessage: 'Bandeja de entrada',
        }),
        icon: InboxIcon,
        link: `${PATHNAME}home/inbox`,
        id: 'idWorkflow',
      };
      if (data.length === 1) {
        history.push(`${PATHNAME}home/inbox`);
      }
      index++;
    }

    permission = await verifyPermissions(APP_CLEARING);
    if (permission === true) {
      data[index] = {
        option: props.intl.formatMessage({
          id: 'clearing',
          defaultMessage: 'Clearing digital',
        }),
        icon: ClearingIcon,
        link: `${PATHNAME}home/clearing`,
        id: 'idClearing',
      };
      if (data.length === 1) {
        history.push(`${PATHNAME}home/clearing`);
      }
      index++;
    }

    permission = await verifyPermissions(APP_PROCESSMANAGER);
    if (permission === true) {
      data[index] = {
        option: props.intl.formatMessage({
          id: 'processesManager',
          defaultMessage: 'Processes Manager',
        }),
        icon: ProcessIcon,
        link: `${PATHNAME}home/processes-manager`,
        onClick: () => verifyPermissions(`${PATHNAME}home/processes-manager`),
      };
      if (data.length === 1) {
        history.push(`${PATHNAME}home/clearing`);
      }
      index++;
    }

    permission = await verifyPermissions(APP_SECURITY);
    if (permission === true) {
      data[index] = {
        option: props.intl.formatMessage({
          id: 'securityManager',
          defaultMessage: 'Security Manager',
        }),
        icon: SecurityIcon,
        link: `${PATHNAME}home/security-manager`,
        onClick: () => verifyPermissions(`${PATHNAME}home/security-manager`),
      };
      if (data.length === 1) {
        history.push(`${PATHNAME}home/clearing`);
      }
      index++;
    }

    permission = await verifyPermissions(APP_SUCURSALES);
    if (permission === true) {
      data[index] = {
        option: props.intl.formatMessage({
          id: 'branchesSidebar',
          defaultMessage: 'Manejador de Sucursales',
        }),
        icon: BranchesIcon,
        link: `${PATHNAME}home/branches`,
        id: 'idSucursales',
      };
      if (data.length === 1) {
        history.push(`${PATHNAME}home/branches`);
      }
      index++;
    }

    permission = await verifyPermissions(APP_BITACORA);
    if (permission === true) {
      data[index] = {
        option: props.intl.formatMessage({
          id: 'binnacle',
          defaultMessage: 'Bitácora',
        }),
        icon: BinnacleIcon,
        link: `${PATHNAME}home/binnacle`,
        id: 'idBitacora',
      };
      if (data.length === 1) {
        history.push(`${PATHNAME}home/binnacle`);
      }
      index++;
    }
    permission = await verifyPermissions(APP_PRODUCCION);
    if (permission === true) {
      data[index] = {
        option: props.intl.formatMessage({
          id: 'production',
          defaultMessage: 'Producción',
        }),
        icon: ProductionIcon,
        link: `${PATHNAME}home/production-manager`,
      };
      if (data.length === 1) {
        history.push(`${PATHNAME}home/production-manager`);
      }
      index++;
    }

    permission = await verifyPermissions(APP_ESQUEMAS);
    if (permission === true) {
      data[index] = {
        option: props.intl.formatMessage({
          id: 'schema',
          defaultMessage: 'Esquemas contables',
        }),
        icon: SchemaIcon,
        link: `${PATHNAME}home/schema`,
      };
      if (data.length === 1) {
        history.push(`${PATHNAME}home/schema`);
      }
      index++;
    }

    setUserAllowed(data.length > 0);
    setSideBarMap(data);
  };

  return (
    <React.Fragment>
      <div
        onMouseMove={() => {
          idleTime = 0;
        }}
        onKeyPress={() => {
          idleTime = 0;
        }}
      >
        <HomeView
          userAllowed={userAllowed}
          sidebarData={sideBarMap}
          footerInfo={footerInfo}
          setFooterInfo={setFooterInfo}
          tabs={tabs}
          showModalPassword={showModalPassword}
          setShowModalPassword={setShowModalPassword}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  tabs: state.tabs,
});

export default connect(mapStateToProps, storeActions)(injectIntl(HomeViewModel));
