import mime from 'mime-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'unistore/react';

import {
  addDocument,
  changeDocument,
  generateThumbnail,
  getDocument,
  getListDocuments,
  getThumbnail,
  removeDocument,
} from '../../src/api/documentManager';
import { storeActions } from '../../src/store';
import { formatDateTime } from '../helpers/dateHelper';
import { b64toBlob, formatSizeFile, mimeCheck } from '../helpers/fileHelper';
import DocumentManagerWindow from './DocumentManagerWindow';

const MESSAGE_ERROR = <FormattedMessage id="error" defaultMessage={`Error`} />;

export class DocumentManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      showWindow: true,
      selectedIndex: null,
      showDialog: false,
      dialogMessage: '',
      dialogTitle: null,
      showAdd: false,
      showRemove: false,
      showChange: false,
      loading: false,
      thumbnailImage: null,
    };
    this.setDocumentsRows = this.setDocumentsRows.bind(this);
    this.fetchDocumentsData = this.fetchDocumentsData.bind(this);
    this.toggleWindow = this.toggleWindow.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.selectRow = this.selectRow.bind(this);
    this.changeThumbnail = this.changeThumbnail.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.addFile = this.addFile.bind(this);
    this.handleGetFile = this.handleGetFile.bind(this);
    this.handleGetAllFiles = this.handleGetAllFiles.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleShowRemove = this.handleShowRemove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeFile = this.changeFile.bind(this);
    this.manageGetFile = this.manageGetFile.bind(this);
  }

  selectRow(index) {
    this.setState({ selectedIndex: index });
  }

  _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  changeThumbnail(row) {
    getThumbnail(row.original.id).then((thumbnail) => {
      if (thumbnail && Object.entries(thumbnail).length === 0 && thumbnail.constructor === Object) {
        generateThumbnail(row.original.id, row.original.name).then((generatedThumbnail) => {
          let base64Thumbnail = this._arrayBufferToBase64(thumbnail);
          this.setState({ thumbnailImage: base64Thumbnail });
        });
      } else if (thumbnail === null) {
        this.setState({ thumbnailImage: undefined });
      } else {
        let base64Thumbnail = this._arrayBufferToBase64(thumbnail);
        this.setState({ thumbnailImage: base64Thumbnail });
      }
    });
  }
  handleAdd() {
    this.setState((prevState) => ({
      showAdd: !prevState.showAdd,
    }));
  }

  handleChange() {
    const { selectedIndex, rows } = this.state;
    if (selectedIndex !== null && selectedIndex < rows.length) {
      this.setState((prevState) => ({
        showChange: !prevState.showChange,
      }));
    }
  }

  handleShowRemove() {
    const { selectedIndex, rows } = this.state;
    if (selectedIndex !== null && selectedIndex < rows.length) {
      this.setState((prevState) => ({
        showRemove: !prevState.showRemove,
      }));
    }
  }

  manageGetFile(dataFile, nameFile, downloadFile = true) {
    const { intl } = this.props;
    var mimeType = mime.lookup(nameFile);
    var blob = b64toBlob(dataFile, mimeType);

    var urlBlob = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    if (downloadFile || !mimeCheck(mimeType)) {
      document.body.appendChild(a);
      a.download = nameFile;
      a.href = urlBlob;
      a.click();
    } else {
      var windowOpenFile = window.open(urlBlob);

      if (windowOpenFile === null || typeof windowOpenFile === 'undefined') {
        var message = intl.formatMessage({
          id: 'pleaseDisablePopupBlocking',
          defaultMessage: 'Por favor deshabilita el bloqueo a ventanas emergentes y vuelve a intentarlo',
        });
        this.setState({
          showDialog: true,
          dialogMessage: message,
          dialogTitle: MESSAGE_ERROR,
        });
      }
    }
    setTimeout(() => {
      window.URL.revokeObjectURL(urlBlob);
      if (downloadFile) document.body.removeChild(a);
    }, 0);
  }

  async handleRemove() {
    const { selectedIndex, rows } = this.state;
    if (selectedIndex !== null && selectedIndex < rows.length) {
      const fileId = rows[selectedIndex].id;
      var data = await removeDocument(fileId);
      if (data.mensaje) {
        this.setState({
          showDialog: true,
          dialogMessage: data.mensaje,
          dialogTitle: MESSAGE_ERROR,
        });
      } else this.fetchDocumentsData();
      this.handleShowRemove();
    }
  }

  async handleGetFile(downloadFile = true) {
    const { selectedIndex, rows } = this.state;
    if (selectedIndex !== null && selectedIndex < rows.length) {
      const fileId = rows[selectedIndex].id;
      this.setState({ loading: true });
      const data = await getDocument(fileId);

      if (data.mensaje) {
        this.setState({
          showDialog: true,
          dialogMessage: data.mensaje,
          dialogTitle: MESSAGE_ERROR,
        });
      } else {
        this.manageGetFile(data, rows[selectedIndex].name, downloadFile);
      }
      this.setState({ loading: false });
    }
  }

  async handleGetAllFiles(downloadFile = true) {
    const { intl } = this.props;
    const { rows } = this.state;
    if (rows.length > 0) {
      this.setState({ loading: true });
      for (let i = 0; i < rows.length; i++) {
        let fileId = rows[i].id;
        let data = await getDocument(fileId);

        if (data.mensaje) {
          this.setState({
            showDialog: true,
            dialogMessage: data.mensaje,
            dialogTitle: MESSAGE_ERROR,
          });
        } else {
          this.manageGetFile(data, rows[i].name, downloadFile);
        }
      }
    } else {
      this.setState({
        showDialog: true,
        dialogMessage: intl.formatMessage({ id: 'obtainAllNoData', defaultMessage: 'No hay registros' }),
        dialogTitle: MESSAGE_ERROR,
      });
    }
    this.setState({ loading: false });
  }

  async addFile(groupType, groupNumber, filter = '', name, size, comment, body) {
    const data = await addDocument(groupType, groupNumber, filter, name, size, comment, body);
    if (data.mensaje) {
      this.setState({
        showDialog: true,
        dialogMessage: data.mensaje,
        dialogTitle: MESSAGE_ERROR,
      });
    } else this.fetchDocumentsData();
    this.handleAdd();
  }

  async changeFile(fileId, comment) {
    const data = await changeDocument(fileId, comment);
    if (data.mensaje) {
      this.setState({
        showDialog: true,
        dialogMessage: data.mensaje,
        dialogTitle: MESSAGE_ERROR,
      });
    } else this.fetchDocumentsData();
    this.handleChange();
  }

  async fetchDocumentsData() {
    let { eventData } = this.props;
    let { groupType, groupNumber, filter = '' } = eventData;
    let data = {};
    if (!filter) filter = '';
    if (groupType && groupNumber) {
      data = await getListDocuments(groupType, groupNumber, filter);
    }
    this.setDocumentsRows(data.listDocumentInfo);
  }

  setDocumentsRows(documentsList) {
    let rows = [];
    documentsList.forEach((dData) => {
      let { id, name, size, dateTime, comment } = dData;
      let row = {
        id,
        name,
        size: formatSizeFile(size),
        dateTime: formatDateTime(new Date(dateTime)),
        comment,
      };
      rows.push(row);
    });
    this.setState({ rows });
  }

  componentDidMount() {
    const { pauseFocus } = this.props;
    pauseFocus();
    this.fetchDocumentsData();
  }

  async toggleWindow() {
    let { removeTreeNode, removeComponentTree, resumeFocus } = this.props;
    this.setState((prevState) => ({
      showWindow: !prevState.showWindow,
    }));
    resumeFocus();
    removeComponentTree(removeTreeNode);
  }

  hideDialog() {
    this.setState({ showDialog: false, dialogMessage: '', dialogTitle: null });
  }

  render() {
    const { showWindow, rows, selectedIndex, showDialog, dialogMessage, dialogTitle, showAdd, showRemove, showChange, loading } = this.state;
    let { eventData } = this.props;

    return (
      <DocumentManagerWindow
        eventData={eventData}
        showWindow={showWindow}
        rows={rows}
        selectedIndex={selectedIndex}
        showDialog={showDialog}
        dialogMessage={dialogMessage}
        dialogTitle={dialogTitle}
        showAdd={showAdd}
        showRemove={showRemove}
        showChange={showChange}
        loading={loading}
        selectRow={this.selectRow}
        changeThumbnail={this.changeThumbnail}
        handleAdd={this.handleAdd}
        handleChange={this.handleChange}
        handleShowRemove={this.handleShowRemove}
        handleGetFile={this.handleGetFile}
        handleGetAllFiles={this.handleGetAllFiles}
        toggleWindow={this.toggleWindow}
        hideDialog={this.hideDialog}
        addFile={this.addFile}
        handleRemove={this.handleRemove}
        changeFile={this.changeFile}
        thumbnail={this.state.thumbnailImage}
      />
    );
  }
}

export default connect(null, storeActions)(injectIntl(DocumentManager));
