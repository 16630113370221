import React from 'react';
import { useTheme } from '../../../src/themes/ThemeContext';
import { css, StyleSheet } from 'aphrodite';

const Button = ({ id, autoFocus, handleClick, buttonText }) => {
  const themeContext = useTheme();

  const { commonButton } = themeContext.theme;

  const styles = StyleSheet.create({
    button: {
      padding: '.5rem 1.2rem',
      marginRight: '.5rem',
      borderRadius: 4,
      display: 'block',
      backgroundColor: commonButton.default.background,
      color: commonButton.default.color,
      ':hover': {
        backgroundColor: commonButton.hover.background,
        color: commonButton.hover.color,
      },
      ':focus': {
        backgroundColor: commonButton.focus.background,
        color: commonButton.focus.color,
      },
      ':active': {
        backgroundColor: commonButton.active.background,
        color: commonButton.active.color,
      },
      transition: 'background .2s',
    },
  });

  return (
    <div id={id}>
      <button
        data-autofocus={autoFocus}
        autoFocus={autoFocus}
        className={css(styles.button)}
        onClick={(e) => {
          if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) handleClick(id);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClick(id);
          }
        }}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default Button;
