import React, { useState } from 'react';
import { OPTION_MESSAGES } from '../../../models/constants';
import { GroupInstance } from '../../../models/groupInstance';
import { Info } from '../../../models/processResult';
import { HistoryDetailView } from './HistoryDetailView';

interface HistoryDetailViewModelProps {
  selectedInstance?: GroupInstance;
}

export function HistoryDetailViewModel({ selectedInstance }: HistoryDetailViewModelProps): JSX.Element | null {
  const [optionSelected, setOptionSelected] = useState<string>(OPTION_MESSAGES);
  const [isErrorSelected, setIsErrorSelected] = useState<boolean>(true);
  const [isWarningSelected, setIsWarningSelected] = useState<boolean>(true);
  const [isInformationSelected, setIsInformationSelected] = useState<boolean>(true);
  const [selectedMessage, setSelectedMessage] = useState<Info>();

  function onMessageSelect(message: Info): void {
    setSelectedMessage(message);
  }

  function onOptionSelect(option: string): void {
    setOptionSelected(option);
  }

  function onErrorCheck(): void {
    setIsErrorSelected(!isErrorSelected);
  }

  function onWarningCheck(): void {
    setIsWarningSelected(!isWarningSelected);
  }

  function onInformationCheck(): void {
    setIsInformationSelected(!isInformationSelected);
  }

  return selectedInstance ? (
    <HistoryDetailView
      isWarningSelected={isWarningSelected}
      isErrorSelected={isErrorSelected}
      isInformationSelected={isInformationSelected}
      selectedInstance={selectedInstance}
      optionSelected={optionSelected}
      onErrorCheck={onErrorCheck}
      onInformationCheck={onInformationCheck}
      onOptionSelect={onOptionSelect}
      onWarningCheck={onWarningCheck}
      messageSelected={selectedMessage}
      onMessageSelect={onMessageSelect}
    />
  ) : null;
}
