import { getBitacorasExcel } from '../../../api/bitacora';

export async function exportToExcel(data) {
  let dataExcel = await getBitacorasExcel(data, document.getElementById('descriptor').value, 'Local');
  if (data) {
    let columns = dataExcel[0];
    let rows = dataExcel.slice(1, dataExcel.length);
    exportToCsvAsientos(rows, 'Movimientos.csv', columns);
  }
}
export function exportToCsvAsientos(rows, filename, columns) {
  var processRow = function (row, columns) {
    var finalVal = '';
    for (var j = 0; j < columns.length; j++) {
      var innerValue = row[j] === undefined ? '' : row[j];
      innerValue = innerValue === null ? '' : innerValue;
      innerValue = innerValue.toString().replaceAll('\n', ' ');
      innerValue = innerValue.toString().replaceAll(';', ' ');
      finalVal += innerValue;
      if (j < columns.length - 1) {
        finalVal += ';';
      }
    }
    return finalVal + '\n';
  };

  var processColumns = function (columns) {
    var finalVal = '';

    for (var i = 0; i < columns.length; i++) {
      var result = columns[i];
      finalVal += result;
      if (i < columns.length - 1) {
        finalVal += ';';
      }
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    if (i === 0) {
      csvFile += processColumns(columns);
    }
    csvFile += processRow(rows[i], columns);
  }

  // eslint-disable-next-line no-undef
  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function exportToCsv(rows, filename, columns) {
  var processRow = function (row, columns) {
    var finalVal = '';
    for (var j = 0; j < columns.length; j++) {
      var innerValue = row[columns[j].accessor] === undefined ? '' : row[columns[j].accessor];
      innerValue = innerValue === null ? '' : innerValue;
      innerValue = innerValue.toString().replaceAll('\n', ' ');
      innerValue = innerValue.toString().replaceAll(';', ' ');
      finalVal += innerValue;
      if (j < columns.length - 1) {
        finalVal += ';';
      }
    }
    return finalVal + '\n';
  };

  var processColumns = function (columns) {
    var finalVal = '';

    for (var i = 0; i < columns.length; i++) {
      var result = columns[i].Header;
      finalVal += result;
      if (i < columns.length - 1) {
        finalVal += ';';
      }
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    if (i === 0) {
      csvFile += processColumns(columns);
    }
    csvFile += processRow(rows[i], columns);
  }

  // eslint-disable-next-line no-undef
  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
