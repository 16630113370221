const pad = number => ((`0${number}`).slice(-2))

export const CHANGE_DUE_DATE = 'CHANGE_DUE_DATE'
export const CHANGE_DOC_CODE = 'CHANGE_DOC_CODE'
export const CHANGE_DOC_NUMBER = 'CHANGE_DOC_NUMBER'

export const dateFormatSignature = (value) => {
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  let dateInput = new Date(value.substr(0, 4), value.substr(4, 2) - 1, value.substr(6, 2))

  return `${months[dateInput.getMonth()]} ${dateInput.getDate()}, ${dateInput.getFullYear()} 12:00:00 AM`
}

export const dateFormatInput = (value, type) => {
  const date = new Date(value)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  if (type === 'LABEL') return `${pad(day)}/${pad(month)}/${year}`
  return `${year}-${pad(month)}-${pad(day)}`
}
