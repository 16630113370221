import { useEffect, useState } from 'react';
import { getAllow, getBaseAllow, getGrandParentRestrictions, getParentPermission, getTitlePermission } from '../utils';
import { getGroupReportsAsync } from '../../../../../../api/securityManager';
import { DEFAULT_RESTRICTION_KEY } from '../../../../models/constants';
import { RestrictionObject } from '../../../../models/restrictionObject';
import { Restriction, Restrictions, useRestrictions } from './useRestrictions.hook';

interface UseReports {
  reports: ReportsTree[];
  defaultRestriction?: Restriction;
}

interface ReportService {
  descripcion: string;
  identificacion: number;
  titulo: number;
}

export interface ReportsTree {
  key: string;
  label: string;
  nodes: ReportsTree[];
  restriction: RestrictionObject;
}

export const REPORTS_TYPE: string = 'Q';

export function useReports(parentGroup: string, group: string): UseReports {
  const [defaultRestriction, setDefaultRestriction] = useState<Restriction>();
  const [reportOptions, setReportOptions] = useState<ReportsTree[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [grandParentRestrictions, setGrandParentRestrictions] = useState(null);
  const { fillNumberWithZeros, getReportsRestrictionsAsync } = useRestrictions();

  async function getReportsAsync(): Promise<void> {
    const [reports, titleRestrictions, ownRestrictions, baseAllow] = await Promise.all<ReportService[], Restrictions, Restrictions>([
      getGroupReportsAsync(),
      getReportsRestrictionsAsync(parentGroup),
      getReportsRestrictionsAsync(group),
      getBaseAllow(group, getReportsRestrictionsAsync),
    ]);
    const options: ReportsTree[] = [];
    let lastTitle: number | null = null;
    let lastHeader: ReportsTree | null = null;

    let defaultRestrictionVal = ownRestrictions[DEFAULT_RESTRICTION_KEY] || {
      group: group,
      id: DEFAULT_RESTRICTION_KEY,
      allow: 0,
      type: 'Q',
      title: DEFAULT_RESTRICTION_KEY,
      fechaHabilitacion: '',
      parentPermission: 0,
    };
    setDefaultRestriction(defaultRestrictionVal);
    let defaultAllow = baseAllow || 0;
    reports.forEach((reportOption, index) => {
      const isHeaderIdentification = reportOption.titulo !== lastTitle || lastTitle === null;
      const restrictionKey = fillNumberWithZeros(reportOption.titulo) + fillNumberWithZeros(reportOption.identificacion);
      const allowed = getAllow(ownRestrictions, parentGroup, defaultAllow, restrictionKey, reportOption, 1);
      const titleAllowed = getTitlePermission(reportOption, ownRestrictions, parentGroup, defaultAllow, reports);
      const parentAllowed = getParentPermission(
        restrictionKey,
        reportOption,
        ownRestrictions,
        titleRestrictions,
        defaultAllow,
        grandParentRestrictions,
      );

      const permissions: RestrictionObject = {
        ownPermission: allowed,
        parentPermission: parentAllowed !== null ? parentAllowed : !allowed && !titleAllowed ? defaultAllow : null,
        titlePermission: titleAllowed,
        type: REPORTS_TYPE,
        allows: allowed,
        id: restrictionKey,
      };
      const reportNode = {
        key: `${index} ${reportOption.descripcion}`,
        label: isHeaderIdentification
          ? `${reportOption.titulo} - ${reportOption.descripcion}`
          : `${reportOption.identificacion} - ${reportOption.descripcion}`,
        nodes: [],
        restriction: permissions,
      };

      if (isHeaderIdentification) {
        lastHeader = reportNode;
        options.push(reportNode);
      } else {
        reportNode.restriction.parentPermission = lastHeader ? lastHeader?.restriction.parentPermission : null;
        lastHeader?.nodes.push(reportNode);
      }

      lastTitle = reportOption.titulo;
    });

    setReportOptions(options);
  }

  useEffect(() => {
    if (loaded) {
      getReportsAsync();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, loaded]);

  const getGrandParentRestrictionsAsync = async () => {
    let restrictions = await getGrandParentRestrictions(parentGroup, null, getReportsRestrictionsAsync);
    setGrandParentRestrictions(restrictions);
    setLoaded(true);
  };

  useEffect(() => {
    setLoaded(false);
    getGrandParentRestrictionsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return {
    reports: reportOptions,
    defaultRestriction: defaultRestriction,
  };
}
