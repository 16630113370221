import { CommissionsWindow, DocumentManager } from '../../UI-kit/index'

const TYPE_OF_COMMISSIONS = 'commissions'
const TYPE_OF_DOCUMENTS_MANAGER = 'documentmanager'

export default class RemoteToLaunch {
  constructor ({ eventData, name, idNode }) {
    this._eventData = eventData
    this._name = name
    this._idNode = idNode
  }

  getRemoteType (name) {
    return {
      [TYPE_OF_COMMISSIONS]: CommissionsWindow,
      [TYPE_OF_DOCUMENTS_MANAGER]: DocumentManager,
    }[name]
  }

  props () {
    return {
      eventData: this._eventData,
      removeComponentTree: (removeTreeNode) => {
        removeTreeNode(null, this._idNode)
      }
    }
  }

  component () {
    return this.getRemoteType(this._name)
  }
}
