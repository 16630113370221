import { css } from 'aphrodite';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'unistore/react';
import { storeActions } from '../../../../src/store';
import { useGlobalStyles } from './../../../styles/GlobalStylesContext';
import { Filters } from './../Constants';
import CheckNumberInput from './Fields/CheckNumberInput';
import ErrorMessage from './Fields/ErrorMessage';
import SubmitButton from './Fields/SubmitButton';

const ASpecificCheck = (props) => {
  const [error, setError] = useState('');
  const [checkNumber, setCheckNumber] = useState('0');
  const intl = useIntl();
  // eslint-disable-next-line no-unused-vars
  const hasPermission = true;

  const validate = () => {
    if (!checkNumber) {
      const errMsg = intl.formatMessage({
        id: 'pleaseCompleteAllFields',
        defaultMessage: 'Por favor complete todos los campos',
      });
      throw new Error(errMsg);
    }
  };

  const createData = () => {
    return {
      numeroCheque: checkNumber,
    };
  };

  useEffect(() => setError(''), [checkNumber]);

  const onSubmit = (event) => {
    event.preventDefault();
    try {
      validate();
      const data = createData();
      props.setLoadingChecksSearch(true);
      props.getChecks(Filters.A_SPECIFIC_CHECK, data);
    } catch (exception) {
      setError(exception.message);
    }
  };

  const globalStyles = useGlobalStyles();

  return (
    <form onSubmit={onSubmit}>
      <div className={css(globalStyles.fieldsWithoutButton)}>
        <CheckNumberInput checkNumber={checkNumber} onCheckNumberChange={setCheckNumber} />
      </div>
      <ErrorMessage message={error} />
      <SubmitButton />
    </form>
  );
};

export default connect(null, storeActions)(ASpecificCheck);
