import React from 'react';
import './ProcessDetail.scss';

import { useIntl } from 'react-intl';
import { ParametersInformation } from './ParametersInformation';
import { ConstantsInformation } from './ConstantsInformation';
import { SelectedProcess } from '../../../models/types';
import { GroupDefinition } from '../../../models/groupDefinition';
import { Process } from '../../../models/process';

interface ProcessDetailProps {
  selectedProcess: SelectedProcess;
}

export function ProcessDetail({ selectedProcess }: ProcessDetailProps): JSX.Element | null {
  const intl = useIntl();

  if (!selectedProcess) {
    return null;
  }

  function hasConstants(): boolean {
    return !!(selectedProcess as GroupDefinition).constants?.groupConstants?.length || !!(selectedProcess as Process).constants?.length;
  }

  function hasParameters(): boolean {
    return !!(selectedProcess as GroupDefinition).groupParameters?.parametersList?.length || !!(selectedProcess as Process).parameters?.entry?.length;
  }

  function renderEnabled(): JSX.Element | null {
    if (typeof selectedProcess?.canBeRootGroup !== 'undefined') {
      return (
        <div className="detail-line">
          <div className="w-48">{intl.formatMessage({ id: 'enabled' })}</div>
          <div className="flex-50 font-mono">{`${selectedProcess?.canBeRootGroup}`}</div>
        </div>
      );
    }

    return null;
  }

  function renderClass(): JSX.Element | null {
    const process = selectedProcess as Process;

    if (process.className) {
      return (
        <div className="detail-line">
          <div className="w-48">{intl.formatMessage({ id: 'class' })}</div>
          <div className="flex-50">{process.className}</div>
        </div>
      );
    }

    return null;
  }

  function renderUniqueInstance(): JSX.Element | null {
    const group = selectedProcess as GroupDefinition;

    if (typeof group.singleton !== 'undefined') {
      return (
        <div className="detail-line">
          <div className="w-48">{intl.formatMessage({ id: 'uniqueInstance' })}</div>
          <div className="flex-50">{`${group.singleton}`}</div>
        </div>
      );
    }

    return null;
  }

  function renderTransactional(): JSX.Element | null {
    const group = selectedProcess as GroupDefinition;

    if (typeof group.transactional !== 'undefined') {
      return (
        <div className="detail-line">
          <div className="w-48">{intl.formatMessage({ id: 'transactional' })}</div>
          <div className="flex-50">{`${group.transactional}`}</div>
        </div>
      );
    }

    return null;
  }

  return (
    <div className="process-detail-container">
      <fieldset className="process-detail-information rounded border border-light">
        <legend>{selectedProcess.name}</legend>
        <div className="process-details">
          {renderEnabled()}
          {renderClass()}
          {renderUniqueInstance()}
          {renderTransactional()}
        </div>
      </fieldset>
      {hasParameters() && (
        <fieldset className="process-detail-information rounded border border-light">
          <legend>{intl.formatMessage({ id: 'paramsInformation' })}</legend>
          <ParametersInformation selectedProcess={selectedProcess} />
        </fieldset>
      )}
      {hasConstants() && (
        <fieldset className="process-detail-information rounded border border-light">
          <legend>{intl.formatMessage({ id: 'constantsInformation' })}</legend>
          <ConstantsInformation selectedProcess={selectedProcess} />
        </fieldset>
      )}
    </div>
  );
}
