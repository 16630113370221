import { ButtonMessages } from './../constants/Buttons';

class ButtonData {
  constructor(buttonId, autoFocus = false) {
    this.buttonId = buttonId;
    this.autoFocus = autoFocus;
    this.clicked = false;
  }

  /**
   * Returns an object containing the complete data of the button, to call a modal.
   *
   * @param {object} intl Internationalization hook for button text
   */
  createData = (intl) => {
    const { buttonId, autoFocus, clicked } = this;
    if (!ButtonMessages[buttonId]) {
      throw new Error('Button ID not found. See Modal/constants/Buttons');
    }

    const text = intl.formatMessage({
      id: ButtonMessages[buttonId].messageId,
      defaultMessage: ButtonMessages[buttonId].defaultMessage,
    });

    const data = {
      buttonId,
      autoFocus,
      clicked,
      text,
    };

    return data;
  };
}

export default ButtonData;
