import axios from '../utils/axios';
import querystring from 'querystring';

import browserStorage from '../utils/browserStorage';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { TOPAZ_REST_API } from '../config';
import { handleEndpointError } from './handlers';

export const getProcessesGroups = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/processmanager/groups?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const executeProcess = async (groupName, params) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    let data = { groupParameters: JSON.stringify({ parametersList: params }) };
    if (data) {
      data = querystring.stringify(data);
    }
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    let encodedGroupName = encodeURIComponent(groupName);

    const response = await axios.put(`${TOPAZ_REST_API}rest/processmanager/groups/${encodedGroupName}/execute?sessionId=${sessionID}`, data, config);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupProcessInstance = async (excecutionId) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/processmanager/groups/instances/${excecutionId}/full?sessionId=${sessionID}`);

    return response.data;
  } catch (ex) {
    return ex;
  }
};

export const getGroupInstanceHeaders = async (date) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const data = querystring.stringify({ fromDate: date });
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const response = await axios.put(`${TOPAZ_REST_API}rest/processmanager/groups/getGroupInstanceHeaders?sessionId=${sessionID}`, data, config);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

const handleResponse = async (href) => {
  try {
    const response = await axios.get(href);
    if (response.status === 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const stopProcessInstance = (id) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    return handleResponse(`${TOPAZ_REST_API}rest/processmanager/stop?sessionId=${sessionID}&rootId=${id}`);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const resumeProcessInstance = async (id) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${TOPAZ_REST_API}rest/processmanager/resume?sessionId=${sessionID}&rootId=${id}`);
    return handleResponse(response);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupsScheduler = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const data = querystring.stringify({
      schedulerData: JSON.stringify({}),
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const response = await axios.post(`${TOPAZ_REST_API}rest/processmanager/getSchedulerList?sessionId=${sessionID}`, data, config);

    return response.data;
  } catch (ex) {
    return [{ error: ex }];
  }
};

export const setGroupsScheduler = async (groupConditioned, actionToCondition) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const groupData = {
      nombrePadre: groupConditioned.parentGroup,
      nombreHijo: groupConditioned.childGroup,
      day: groupConditioned.day,
      periodicidad: groupConditioned.periodicity,
      fechaProxEjecucion: groupConditioned.nextExecution,
      habiles: groupConditioned.labor,
      ordinal: groupConditioned.ordinal !== -1 ? groupConditioned.ordinal : undefined,
    };

    const data = querystring.stringify({ schedulerData: JSON.stringify(groupData), action: actionToCondition });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.post(`${TOPAZ_REST_API}rest/processmanager/setSchedulerTable?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupScheduleAsync = async (group) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const encodedGroup = encodeURIComponent(group);

    const response = await axios.get(
      `${TOPAZ_REST_API}rest/processmanager/groups/getGroupScheduleDefinition?sessionId=${sessionID}&groupName=${encodedGroup} `,
    );

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const storeGroupScheduleAsync = async (groupSchedule, action) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);

    const groupScheduleDefinition = {
      beginDateTime: groupSchedule.beginDateTime,
      dayOfMonth: groupSchedule.dayOfMonth,
      dayOfWeek: groupSchedule.dayOfWeeks,
      endDateTime: groupSchedule.endDateTime,
      ever: groupSchedule.ever,
      holidays: groupSchedule.holidays,
      idFrecuency: groupSchedule.idFrecuency,
      interval: groupSchedule.interval,
      nextDateTimeExecution: groupSchedule.nextDateTimeExecution,
      processName: groupSchedule.processName,
      retriesCount: groupSchedule.retriesCount,
      retriesInterval: groupSchedule.retriesInterval,
      saturday: groupSchedule.saturday,
      sunday: groupSchedule.sunday,
      type: groupSchedule.type,
      week: groupSchedule.week,
    };

    const data = querystring.stringify({ groupScheduleDefinition: JSON.stringify(groupScheduleDefinition), state: action });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.put(`${TOPAZ_REST_API}rest/processmanager/groups/storeScheduleDefinition?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const reloadProcessManagerAsync = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);

    await axios.post(`${TOPAZ_REST_API}rest/processmanager/reload?sessionId=${sessionID}`);
  } catch (error) {
    handleEndpointError(error);
  }
};
