import React, { PureComponent } from 'react';
import { connect } from 'unistore/react';
import withOperationServices from '../../api/withOperationServices';
import { store, storeActions } from '../../store';
import { injectIntl } from 'react-intl';
import DownloadIcon from './../../resources/icons/fa/file-download.svg';
import RecordRedIcon from './../../resources/icons/fa/record-button.svg';
import RecordGreenIcon from './../../resources/icons/fa/circle-center.svg';
import PlusIcon from './../../resources/icons/fa/plus-sign.svg';
import SqlIcon from './../../resources/icons/fa/sql-file.svg';
import { AssertComponent } from './AssertComponent';
import { SaveTest } from './SaveTest';
import { SqlComponent } from './SqlComponent';

export class ToolbarAutomaticTest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      recordOn: false,
      showAssert: false,
      saveTest: false,
      showSql: false,
    };
    this.setShowAssert = this.setShowAssert.bind(this);
    this.setSaveTest = this.setSaveTest.bind(this);
    this.setShowSql = this.setShowSql.bind(this);
  }

  setShowAssert(assert) {
    this.setState({ showAssert: false });
  }

  setSaveTest(save) {
    this.setState({ saveTest: false });
  }

  setShowSql(sql) {
    this.setState({ showSql: false });
  }

  handleAccept(record) {
    store.setState({ recordOn: record });
  }

  render() {
    const { isCollapsed } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: isCollapsed ? 'column' : 'row',
            width: '133%',
            flexWrap: 'nowrap',
            overflow: 'visible',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className="item cursor-pointer"
            key="recIcon"
            style={{ width: '30px' }}
            onClick={() => {
              if (this.state.recordOn) {
                let textOperation = [];
                window.sessionStorage.setItem('textOperation', JSON.stringify(textOperation));
              }
              store.setState({ recordOn: !this.state.recordOn });
              this.setState({ recordOn: !this.state.recordOn });
            }}
          >
            {this.state.recordOn === true ? <RecordRedIcon width="20px" /> : <RecordGreenIcon width="20px" />}
          </div>
          <div className="item cursor-pointer" key="downloadIcon" style={{ width: '30px' }}>
            <DownloadIcon
              width="20px"
              onClick={() => {
                if (this.state.recordOn) {
                  this.setState({ saveTest: true });
                }
              }}
            />
          </div>
          <div className="item cursor-pointer" key="assertIcon" style={{ width: '30px' }}>
            <PlusIcon
              width="20px"
              onClick={() => {
                if (this.state.recordOn) {
                  this.setState({ showAssert: true });
                }
              }}
            />
          </div>
          <div className="item cursor-pointer" key="sqlIcon" style={{ width: '30px' }}>
            <SqlIcon
              width="20px"
              onClick={() => {
                if (this.state.recordOn) {
                  this.setState({ showSql: true });
                }
              }}
            />
          </div>
          {this.state.showAssert && <AssertComponent setShowAssert={this.setShowAssert} />}
          {this.state.saveTest && <SaveTest setSaveTest={this.setSaveTest} />}
          {this.state.showSql && <SqlComponent setShowSql={this.setShowSql} />}
        </div>
      </div>
    );
  }
}

export default connect(null, storeActions)(withOperationServices(injectIntl(ToolbarAutomaticTest)));
