import React from 'react';
import { loginWithPassword, validateLogin } from '../../api/session';
import { StyleSheet, css } from 'aphrodite';
import { useIntl } from 'react-intl';
import { useTheme } from '../../themes/ThemeContext';
import * as LS from './constants/LoginStatus';

const usePasswordLogin = ({ setLoading, setLoginMessage, setError, handleLoginNumber, handleLogin }) => {
  const intl = useIntl();
  const theme = useTheme();
  const [password, setPassword] = React.useState('');
  const [requireRegisterFingerprint, setRequireRegisterFingerprint] = React.useState(false);

  const { primary, background, border, text } = theme.theme;

  const resetPwdInfo = () => {
    setPassword('');
    setRequireRegisterFingerprint(false);
  };

  const styles = StyleSheet.create({
    inputForm: {
      width: '100%',
      padding: '.65rem',
      borderWidth: 2,
      backgroundColor: background.background,
      borderColor: border.light2,
      color: text.text,
      borderRadius: 4,
      transition: '.2s',
      ':hover': {
        backgroundColor: background.dark,
        borderColor: border.dark,
      },
      ':focus': {
        backgroundColor: background.light,
        borderColor: primary.primary,
      },
    },
  });

  const placeholderPassword = intl.formatMessage({
    id: 'passwordLogin',
    defaultMessage: 'Contraseña',
  });

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const getPasswordView = () => {
    return (
      <input
        className={css(styles.inputForm)}
        type="password"
        name="password"
        placeholder={placeholderPassword}
        value={password}
        onKeyUp={(e) => {
          if (e.key === 'Enter' && handleLogin) handleLogin();
        }}
        onChange={onPasswordChange}
      />
    );
  };

  const performPasswordLogin = async (username, isMultipleLogin) => {
    setLoginMessage(null);
    setLoading(true);
    try {
      let loginNumber = null;
      if (isMultipleLogin) {
        loginNumber = await validateLogin(username, password);
      } else {
        loginNumber = await loginWithPassword(username, password, false);
      }
      if (loginNumber === LS.FINGERPRINT_NOT_REGISTERED) {
        setRequireRegisterFingerprint(true);
      }
      handleLoginNumber(loginNumber, isMultipleLogin);
      return loginNumber;
    } catch (err) {
      setError(err.message || err);
    } finally {
      setLoading(false);
    }
  };

  return { performPasswordLogin, getPasswordView, requireRegisterFingerprint, password, resetPwdInfo };
};

export default usePasswordLogin;
