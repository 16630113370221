import { generateLineIDString, generateDialogKey } from '../../UI-kit/helpers/operationHelper';

const Node = require('tree-node');
const { find } = require('busyman');

export default class TreePool {
  constructor(elements, existingElements = []) {
    this._roots = [
      ...existingElements,
      ...elements.map((element) => {
        const node = new Node();
        node.data(element);
        return node;
      }),
    ];
  }

  getTreeByNumber(dialogNumber, countIterativeDialog, tabId) {
    let lineID = { countIterativeDialog, tabId };
    let tmpId = generateDialogKey(dialogNumber, lineID);
    let tree = this._roots
      .slice()
      .reverse()
      .find((tree) => {
        return find(tree.data(), (root) => {
          if (!root.number || !tmpId) {
            return false;
          }
          let rootIdWithoutTransaction = root.number.substr(root.number.indexOf('D'));
          let tmpIdWithoutTransaction = tmpId.substr(root.number.indexOf('D'));
          return rootIdWithoutTransaction === tmpIdWithoutTransaction;
        });
      });
    return tree;
  }

  removeTreeByNumber(number) {
    let filteredTrees = this._roots.filter((root) => root.data().number === number);
    if (filteredTrees.length) {
      this._roots = this._roots.filter((root) => root.data().idNode !== filteredTrees[filteredTrees.length - 1].data().idNode);
    }
  }

  get trees() {
    return this._roots;
  }

  appendChildToTree(dialogNumber, child, countIterativeDialog, tabId) {
    let root = this.getTreeByNumber(dialogNumber, countIterativeDialog, tabId);
    if (root) {
      let node = new Node();
      node.data(child);
      root.appendChild(node);
    }
  }

  removeChildFromTree(dialogNumber, childTL, countIterativeDialog, tabId) {
    let root = this.getTreeByNumber(dialogNumber, countIterativeDialog, tabId);
    if (root) {
      let childToRemove;

      for (let key in root._childs) {
        if (generateLineIDString(root._childs[key]._data.lineID) === childTL) {
          childToRemove = key;
        }
      }
      root.removeChild(childToRemove);
    }
  }

  printTrees() {
    this._roots.forEach((root) => {});
  }
}
