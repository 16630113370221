import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { ABOVE_ALL } from '../constants/zIndexValues';
import Overlay from './Overlay';

export class ConfirmSimpleDialog extends Component {
  render() {
    const { title, handleAccept, handleCancel } = this.props;

    return (
      <div className="">
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <div
          className={`border border-solid border-primary bg-grey-lighter-lighter rounded absolute p-2 w-full max-w-md m-auto flex-col flex`}
          style={{ width: 400, left: '25%', top: '30%', transform: 'translate(-50%, -50%)', zIndex: ABOVE_ALL }}
        >
          <div className="flex-auto text-center px-6 py-4 m-100 text-lg">{title}</div>
          <div className="flex justify-between">
            <button className="bg-grey hover:bg-primary text-white rounded shadow m-2 py-2 px-4 text-sm" onClick={handleAccept}>
              <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
            </button>
            <button className="bg-grey hover:bg-primary text-white rounded shadow m-2 py-2 px-4 text-sm" onClick={handleCancel}>
              <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmSimpleDialog;
