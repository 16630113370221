import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components/ItemComponent.css';

const ItemComponent = ({ itemName, itemId, onClick, onRemove, setRecent }) => {
  const handleRemove = (e) => {
    e.stopPropagation();
    if (onRemove) {
      const newRecent = onRemove(itemId);
      setRecent(newRecent);
    }
  };

  return (
    <button
      className="Item-container px-2 relative flex flex-no-wrap items-center
        border-b border-grey-light-lighter shadow rounded-sm h-20 my-1 min-w-64 bg-grey-lightest
        hover:shadow-sm hover:bg-white
        focus:shadow-sm focus:bg-white
        active:shadow active:bg-grey-lighter-lighter"
      onClick={() => onClick(itemId, itemName)}
    >
      <div
        className="Item-icon text-dark flex items-center justify-center flex-no-shrink
        rounded-full mx-1 w-13 h-13 bg-grey-lighter-lighter"
      >
        {itemId}
      </div>
      <div className="mx-1 font-sans-title text-dark text-left text-sm flex-1">{itemName}</div>
      {onRemove && (
        <div className="p-1 hover:bg-gray" onClick={handleRemove}>
          x
        </div>
      )}
    </button>
  );
};

ItemComponent.propTypes = {
  itemName: PropTypes.string.isRequired,
  itemid: PropTypes.number,
  onClickFunction: PropTypes.func,
};

export default ItemComponent;
