/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { ABOVE_ALL } from '../../../../UI-kit/constants/zIndexValues';
import { useIntl } from 'react-intl';
import {
  getHelpButton,
  getColumnsSucursal,
  getColumnsImpresora,
  getColumnsNumeroMaquina,
  getHelpWindow,
  getColumnsNumerador,
} from './ProductionHelpComponent/productionHelp';
import { useTheme } from '../../../themes/ThemeContext';

import { css, StyleSheet } from 'aphrodite';

import Draggable from 'react-draggable';
import FocusLock from 'react-focus-lock';
import ModalMessage from '../../ModalMessage';
import { addNumeratorDefinition, updateNumeratorDefinition } from '../../../api/productionManager';

export const NumeratorWindow = (props) => {
  const themeContext = useTheme();

  const { color, commonButton, modal } = themeContext.theme;

  const styles = StyleSheet.create({
    show: {
      display: 'block',
      zIndex: 900,
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    modalMain: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxHeight: '80%',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      // minWidth: '320px',
      // maxWidth: '98vw',
      // maxHeight: '98vh',
      backgroundColor: modal.active.background,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: 'transparent',
      opacity: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.33) 0px 2px 8px',
      // alignItems: 'center',
      transition: 'border background .2s',
      padding: '.2rem',
    },
    titleContainer: {
      width: '100%',
      minHeight: '1.5rem',
      padding: '.5rem 1rem',
      color: color.grey.dark4,
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    messageContainer: {
      padding: '1.5rem 1rem',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    buttonsContainer: {
      padding: '.5rem .5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    button: {
      padding: '.5rem 1.2rem',
      marginRight: '.5rem',
      borderRadius: 4,
      display: 'block',
      backgroundColor: commonButton.default.background,
      color: commonButton.default.color,
      ':hover': {
        backgroundColor: commonButton.hover.background,
        color: commonButton.hover.color,
      },
      ':focus': {
        backgroundColor: commonButton.focus.background,
        color: commonButton.focus.color,
      },
      ':active': {
        backgroundColor: commonButton.active.background,
        color: commonButton.active.color,
      },
      transition: 'background .2s',
    },
  });

  const intl = useIntl();

  const { item, numerators, setModifiedItem, addToDataAndSort, handleKeyOnNumber } = props;

  const [modify, setModify] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const [helpRows, setHelpRows] = useState([]);
  const [helpColumns, setHelpColumns] = useState([]);
  const [title, setTitle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [textDialog, setTextDialog] = useState('');
  const [checkCentralizado, setCheckCentralizado] = useState(-1);
  const [checkReutilizable, setCheckReutilizable] = useState(-1);
  const [checkPeriodo, setCheckPeriodo] = useState(-1);

  const cancelMessage = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  const acceptMessage = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });

  useEffect(() => {
    let itemToShow = item;
    if (itemToShow) {
      setModify(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let outside = null;

  let columnsNumerador = getColumnsNumerador();

  let parsedNumeradores = [];

  numerators.forEach((numerator) => {
    let toAdd = {
      numero: numerator.numero,
      periodo: numerator.periodo,
    };
    parsedNumeradores.push(toAdd);
  });

  let helpButtonNumerador = getHelpButton(
    'numerador',
    {
      rows: parsedNumeradores,
      columns: columnsNumerador,
      title: intl.formatMessage({
        id: 'numerator',
        defaultMessage: 'Numerador',
      }),
    },
    setHelpColumns,
    setHelpRows,
    setShowHelp,
    setTitle,
    modify,
  );

  const closeHelp = () => {
    setHelpColumns([]);
    setHelpRows([]);
    setShowHelp(false);
    setTitle('');
  };

  const helpAcceptHandler = (value, control) => {
    document.getElementById(control).value = value;
    document.getElementById(control).focus();
    closeHelp();
  };

  let helpWindow = getHelpWindow(helpRows, helpColumns, helpAcceptHandler, closeHelp, modify, title);

  if (checkPeriodo === -1) {
    if (item !== undefined && item !== null) {
      setCheckPeriodo(item.periodo);
    }
  }

  if (checkReutilizable === -1) {
    if (item !== undefined && item !== null) {
      setCheckReutilizable(item.reutilizable);
    } else {
      setCheckReutilizable(0);
    }
  }

  if (checkCentralizado === -1) {
    if (item !== undefined && item !== null) {
      setCheckCentralizado(item.centralizado);
    } else {
      setCheckCentralizado(0);
    }
  }

  const handleCheckReutilizable = () => {
    if (checkReutilizable === 1) {
      setCheckReutilizable(0);
    } else {
      setCheckReutilizable(1);
    }
  };

  const handleCheckCentralizado = () => {
    if (checkCentralizado === 1) {
      setCheckCentralizado(0);
    } else {
      setCheckCentralizado(1);
    }
  };

  const handleCheckPeriodo = (value) => {
    if (checkPeriodo !== value) {
      setCheckPeriodo(value);
    }
  };

  const handleOnBlur = () => {
    let numero = document.getElementById('Numerador').value;
    let rowExiste = numerators.filter((reg) => {
      return reg.numero === parseInt(numero);
    });
    if (rowExiste.length > 0) {
      const sucursalMessage = intl.formatMessage({
        id: 'existenumerador',
        defaultMessage: 'En la base ya existe un Registro con igual Número.',
      });
      setModal(true, sucursalMessage);
    }
  };

  const handleAccept = async () => {
    const { item, numerators, index } = props;

    let numero = document.getElementById('Numerador').value;
    let descripcion = document.getElementById('descripcion').value;
    let incremento = document.getElementById('incremento').value;
    let inival = document.getElementById('inival').value;
    let periodo = checkPeriodo;
    if (periodo === -1) {
      periodo = '';
    }
    let reutilizable = document.getElementById('reutilizable').checked;
    if (reutilizable === true) {
      reutilizable = 1;
    } else {
      reutilizable = 0;
    }
    let maximo = document.getElementById('maximo').value;
    let centralizado = document.getElementById('centralizado').checked;

    let ultimainic = null;
    if (!modify) {
      if (numero === undefined || numero.trim() === '') {
        const sucursalMessage = intl.formatMessage({
          id: 'clavenulanumerador',
          defaultMessage: 'El valor del campo Numerador no puede ser nulo.',
        });
        setModal(true, sucursalMessage);
        return;
      }

      let rowExiste = numerators.filter((reg) => {
        return reg.numero === parseInt(numero);
      });
      if (rowExiste.length > 0) {
        const sucursalMessage = intl.formatMessage({
          id: 'existenumerador',
          defaultMessage: 'En la base ya existe un Registro con igual Número.',
        });
        setModal(true, sucursalMessage);
        return;
      }
    } else {
      ultimainic = item.ultimainic;
    }

    let numeratorDefinition = {
      numero: parseInt(numero),
      descripcion: descripcion,
      inival: parseInt(inival),
      incremento: parseInt(incremento),
      periodo: periodo,
      ultimainic: ultimainic,
      reutilizable: reutilizable ? 1 : 0,
      maximo: parseInt(maximo),
      centralizado: centralizado ? 1 : 0,
    };
    if (modify) {
      let res = await updateNumeratorDefinition(numeratorDefinition);
      if (res === false) {
        let message = intl.formatMessage({
          id: 'erroralactualizar',
          defaultMessage: 'Error al intentar actualizar ',
        });
        message += 'Numeradores';
        setModal(true, message);
        return;
      } else {
        setModifiedItem(numeratorDefinition, index);
      }
    } else {
      let res = await addNumeratorDefinition(numeratorDefinition);
      if (res === false) {
        let message = intl.formatMessage({
          id: 'erroraldardealta',
          defaultMessage: 'Error al intentar dar de alta sobre ',
        });
        message += 'Numeradores';
        setModal(true, message);
        return;
      }
      addToDataAndSort(numeratorDefinition);
    }

    props.handleCancel();
  };

  const setModal = (state, text) => {
    setShowModal(state);
    setTextDialog(text);
  };

  return (
    <div className={`fixed pin overflow-auto flex bg-smoke-light w-full h-full`} ref={(c) => (outside = c)} style={{ zIndex: ABOVE_ALL }}>
      {showModal && (
        <ModalMessage
          message={textDialog}
          handleAccept={async () => {
            setShowModal(false);
          }}
          acceptTitle={acceptMessage}
          draggable={true}
        />
      )}
      <FocusLock persistentFocus returnFocus>
        <div className={css(styles.modal, styles.show)}>
          {/* onKeyDown={handleKeyDown}> */}
          <div className={css(styles.modalMain)}>
            <Draggable handle=".drag-section" bounds={{ top: -200, right: 350, bottom: 200, left: -350 }}>
              <div className={`${css(styles.modalContent)}`}>
                <div className={`drag-section ${css(styles.titleContainer)}`}></div>
                <div className={css(styles.messageContainer)}>
                  <div className={`flex-auto text-left m-1 flex `} style={{ height: 'fit-content', justifyContent: 'center' }}>
                    <form className={'overflow-hidden'} style={{ display: 'table' }}>
                      <p style={{ display: 'table-row' }} className="mt-2 ml-2">
                        <label style={{ display: 'table-cell' }} htmlFor="Numerador">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerNumerator',
                            defaultMessage: 'Numerador',
                          })}
                        </label>
                        <input
                          className={'clear-both border ml-2'}
                          id="Numerador"
                          defaultValue={modify ? item.numero : ''}
                          disabled={modify}
                          style={{ display: 'table-cell' }}
                          autoComplete="off"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                          onBlur={() => handleOnBlur()}
                        ></input>
                        {modify ? '' : helpButtonNumerador}
                      </p>
                      <p style={{ display: 'table-row' }} className="mt-2 ml-2">
                        <label style={{ display: 'table-cell' }} htmlFor="ValorInicial">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerInitialValue',
                            defaultMessage: 'Valor Inicial',
                          })}
                        </label>
                        <input
                          className={'clear-both border mt-2 ml-2'}
                          style={{ display: 'table-cell' }}
                          id="inival"
                          defaultValue={modify ? item.inival : ''}
                          autoComplete="off"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                        ></input>
                      </p>
                      <p style={{ display: 'table-row' }} className="mt-2 ml-2">
                        <label style={{ display: 'table-cell' }} htmlFor="ValorMaximo">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerMaxValue',
                            defaultMessage: 'Valor Máximo',
                          })}
                        </label>
                        <input
                          className={'clear-both border mt-2 ml-2'}
                          id="maximo"
                          defaultValue={modify ? item.maximo : ''}
                          style={{ display: 'table-cell' }}
                          autoComplete="off"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                        ></input>
                      </p>
                      <p style={{ display: 'table-row' }} className="mt-2 ml-2">
                        <label style={{ display: 'table-cell' }} htmlFor="Incremento">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerIncrement',
                            defaultMessage: 'Incremento',
                          })}
                        </label>
                        <input
                          className={'clear-both border mt-2 ml-2'}
                          style={{ display: 'table-cell' }}
                          id="incremento"
                          defaultValue={modify ? item.incremento : ''}
                          autoComplete="off"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                        ></input>
                      </p>
                      <p style={{ display: 'table-row' }} className="mt-2 ml-2">
                        <label style={{ display: 'table-cell' }} htmlFor="Descripcion">
                          {intl.formatMessage({
                            id: 'productionManagerDescription',
                            defaultMessage: 'Descripción',
                          })}
                        </label>
                        <input
                          className={'clear-both border mt-2 ml-2'}
                          style={{ display: 'table-cell' }}
                          id="descripcion"
                          defaultValue={modify ? item.descripcion : ''}
                        ></input>
                      </p>
                      <div style={{ display: 'table-row' }}>
                        <div style={{ display: 'table-cell' }}></div>
                        <div style={{ display: 'table-cell' }}>
                          <input
                            type="checkbox"
                            className={'clear-both mr-2 mt-2 ml-2'}
                            id="centralizado"
                            checked={checkCentralizado === 1}
                            onChange={(e) => handleCheckCentralizado()}
                          ></input>
                          <label htmlFor="Centralizado">
                            {intl.formatMessage({
                              id: 'productionManagerCentralized',
                              defaultMessage: 'Centralizado',
                            })}
                          </label>
                        </div>
                      </div>
                      <div style={{ display: 'table-row' }}>
                        <div style={{ display: 'table-cell' }}></div>
                        <div style={{ display: 'table-cell' }}>
                          <input
                            type="checkbox"
                            className={'clear-both mr-2 mt-2 ml-2'}
                            id="reutilizable"
                            checked={checkReutilizable === 1}
                            onChange={(e) => handleCheckReutilizable()}
                          ></input>
                          <label htmlFor="Reutilizable">
                            {intl.formatMessage({
                              id: 'productionManagerReusable',
                              defaultMessage: 'Reutilizable',
                            })}
                          </label>
                        </div>
                      </div>
                      <div style={{ display: 'table-row' }}>
                        <div style={{ display: 'table-cell' }}></div>
                        <div style={{ display: 'table-cell' }}>
                          <input
                            type="radio"
                            id="Diario"
                            name="periodo"
                            value="D"
                            className="mr-2 mt-2 ml-2"
                            checked={checkPeriodo === 'D'}
                            onChange={(e) => handleCheckPeriodo(e.target.value)}
                          ></input>
                          <label htmlFor="Diario">
                            {intl.formatMessage({
                              id: 'productionManagerDaily',
                              defaultMessage: 'Diario',
                            })}
                          </label>
                        </div>
                      </div>
                      <div style={{ display: 'table-row' }}>
                        <div style={{ display: 'table-cell' }}></div>
                        <div style={{ display: 'table-cell' }}>
                          <input
                            type="radio"
                            id="Mensual"
                            name="periodo"
                            value="M"
                            className="mr-2 ml-2"
                            checked={checkPeriodo === 'M'}
                            onChange={(e) => handleCheckPeriodo(e.target.value)}
                          ></input>
                          <label htmlFor="Mensual">
                            {intl.formatMessage({
                              id: 'productionManagerMonthly',
                              defaultMessage: 'Mensual',
                            })}
                          </label>
                        </div>
                      </div>
                      <div style={{ display: 'table-row' }}>
                        <div style={{ display: 'table-cell' }}></div>
                        <div style={{ display: 'table-cell' }}>
                          <input
                            type="radio"
                            id="Anual"
                            name="periodo"
                            value="A"
                            checked={checkPeriodo === 'A'}
                            className="mr-2 ml-2"
                            onChange={(e) => handleCheckPeriodo(e.target.value)}
                          ></input>
                          <label htmlFor="Anual">
                            {intl.formatMessage({
                              id: 'productionManagerAnnually',
                              defaultMessage: 'Anual',
                            })}
                          </label>
                        </div>
                      </div>
                      <div style={{ display: 'table-row' }}>
                        <div style={{ display: 'table-cell' }}></div>
                        <div style={{ display: 'table-cell' }}>
                          <input
                            type="radio"
                            id="NoActualiza"
                            name="periodo"
                            className="mr-2 ml-2"
                            value=" "
                            checked={checkPeriodo === ' '}
                            onChange={(e) => handleCheckPeriodo(e.target.value)}
                          ></input>
                          <label htmlFor="NoActualiza">
                            {intl.formatMessage({
                              id: 'productionManagerNoUpdates',
                              defaultMessage: 'No Actualiza',
                            })}
                          </label>
                        </div>
                      </div>
                    </form>
                    {showHelp && helpWindow}
                  </div>
                  <div className="flex justify-center mt-2 ml-2">
                    <button
                      data-autofocus
                      className={`bg-grey
                       hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow`}
                      style={{ width: 'fit-content' }}
                      onClick={() => handleAccept()}
                    >
                      {acceptMessage}
                    </button>

                    <button
                      className={`bg-grey
                   hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow ml-2`}
                      style={{ width: 'fit-content' }}
                      onClick={() => props.handleCancel()}
                    >
                      {cancelMessage}
                    </button>
                  </div>
                </div>
              </div>
            </Draggable>
          </div>
        </div>
      </FocusLock>
    </div>
  );
};

export default NumeratorWindow;
