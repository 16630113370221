import React, { Component } from 'react';
import { dateRunFormat } from '../../../UI-kit/helpers/dateHelper';

const INTEGER_TYPE = 2;
const NUMBER_TYPE = 3;
const TEXT_TYPE = 4;
const DATE_TYPE = 5;

const getInputType = (number) => {
  return {
    2: 'integer',
    3: 'number',
    4: 'text',
    5: 'date',
  }[number];
};

const createDefaultInferiorValue = (field) => {
  switch (field.type) {
    case INTEGER_TYPE:
      return 0;
    case NUMBER_TYPE:
      return 0;
    case DATE_TYPE:
      return '1800-01-01';
    case TEXT_TYPE:
      return '';
    default:
      return '';
  }
};

const createDefaultSuperiorValue = (field) => {
  switch (field.type) {
    case INTEGER_TYPE:
      return parseInt('9'.repeat(field.length), 10);
    case NUMBER_TYPE:
      return parseInt('9'.repeat(field.length), 10);
    case DATE_TYPE:
      if (field.range === false) {
        return 'NaNaNaN';
      } else {
        return '9999-12-31';
      }
    case TEXT_TYPE:
      return 'z'.repeat(field.length);
    default:
      return '';
  }
};
export class FieldReport extends Component {
  constructor() {
    super();

    this.state = {
      lowerValue: null,
      higherValue: null,
    };

    this.handleValue = this.handleValue.bind(this);
  }

  componentDidMount() {
    const { field, assignFieldValue } = this.props;
    let defaultInferiorValue = createDefaultInferiorValue(field);
    let defaultSuperiorValue = createDefaultSuperiorValue(field);
    this.setState({
      lowerValue: defaultInferiorValue,
      higherValue: defaultSuperiorValue,
    });
    if (field.type === DATE_TYPE) {
      if (defaultInferiorValue !== 'NaNaNaN') defaultInferiorValue = dateRunFormat(new Date(defaultInferiorValue + 'T00:00:00'));
      if (defaultSuperiorValue !== 'NaNaNaN') defaultSuperiorValue = dateRunFormat(new Date(defaultSuperiorValue + 'T00:00:00'));
    }
    if (field.jasperName) {
      assignFieldValue(field.number, defaultInferiorValue, defaultSuperiorValue, field.jasperName);
    } else {
      assignFieldValue(field.number, defaultInferiorValue, defaultSuperiorValue);
    }
  }

  handleValue(event, extreme, fromOnBlur = false) {
    const { assignFieldValue, field } = this.props;
    let currentValue = event.target.value;
    let finalValue = event.target.value;

    if (field.type === DATE_TYPE) finalValue = dateRunFormat(new Date(currentValue));
    if (field.type === NUMBER_TYPE) finalValue = parseInt(currentValue, 10);
    if (field.type === INTEGER_TYPE) finalValue = parseInt(currentValue, 10);

    if (field.type === DATE_TYPE && fromOnBlur) {
      if (parseInt(finalValue) < 18000101) {
        finalValue = '18000101';
        currentValue = '1800-01-01';
      }
    }
    if (extreme === 'low') {
      field.jasperName ? assignFieldValue(field.number, finalValue, null, field.jasperName) : assignFieldValue(field.number, finalValue, null);
      this.setState({ lowerValue: currentValue });
    } else if (extreme === 'high') {
      field.jasperName ? assignFieldValue(field.number, null, finalValue, field.jasperName) : assignFieldValue(field.number, null, finalValue);
      this.setState({ higherValue: currentValue });
    }
  }

  handleKeyDown(e, id) {
    if (e.key === 'Enter') {
      let fields = document.getElementsByName('fieldReport');
      let currentField = id;
      let nextField = fields[currentField + 1];
      if (nextField) {
        nextField.focus();
        nextField.setSelectionRange(0, nextField.value ? nextField.value.length : 0);
      } else {
        let generateButton = document.getElementById('generateReportButton');
        generateButton.focus();
      }
    }
  }

  validDate(event, value) {
    if (value) {
      let date = new Date(value);
      let defaultDate = new Date('1800-01-01');
      if (date < defaultDate) {
        return false;
      }
    }
    if (value === '') {
      event.target.value = '';
      return false;
    }
    return true;
  }

  render() {
    const { field, id } = this.props;
    const { lowerValue, higherValue } = this.state;

    let numDecimals = 1 / Math.pow(10, field.decimal);
    const typeInput = getInputType(field.type);

    if (typeInput === 'date') {
      numDecimals = undefined;
    }
    return (
      <div className="mb-4">
        <label className="text-sm mb-1">{field.name}</label>
        <div className="flex">
          <div className={`${field.range ? 'w-1/2 pr-1' : ''}`}>
            <input
              className="border border-smoke border-primary rounded shadow text-text-base p-2 w-full"
              name="fieldReport"
              id={id}
              style={{ fontSize: 12 }}
              value={lowerValue}
              type={typeInput}
              step={numDecimals}
              maxLength={field.length}
              onKeyDown={(e) => this.handleKeyDown(e, id)}
              onChange={(e) => this.handleValue(e, 'low')}
              onBlur={(e) => {
                let result = this.validDate(e, e.target.value);
                if (result === false) {
                  this.handleValue(e, 'low', true);
                }
              }}
              required={true}
            />
          </div>
          {field.range ? (
            <div className="w-1/2 pl-1">
              <input
                className="border border-primary border-smoke rounded shadow color-text-base p-2 w-full"
                style={{ fontSize: 12 }}
                value={higherValue}
                type={typeInput}
                step={numDecimals}
                maxLength={field.length}
                onChange={(e) => this.handleValue(e, 'high')}
                onBlur={(e) => {
                  let result = this.validDate(e, e.target.value);
                  if (result === true) {
                    this.handleValue(e, 'high', true);
                  }
                }}
                required={true}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default FieldReport;
