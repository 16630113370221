import React from 'react';
import { useIntl } from 'react-intl';
import {
  CHECKBOX_SELECTION,
  ERRORS_CHECKBOX,
  INFORMATION_CHECKBOX,
  OPTION_MESSAGES,
  OPTION_PARAMETERS,
  RADIO_OPTION,
  WARNINGS_CHECKBOX,
} from '../../../../models/constants';

import './DetailsOptions.scss';

interface DetailsOptionsViewProps {
  optionSelected: string;
  onChange: (event: { target: HTMLInputElement }) => void;
  isErrorSelected: boolean;
  isWarningSelected: boolean;
  isInformationSelected: boolean;
}

export function DetailsOptionsView({
  optionSelected,
  onChange,
  isErrorSelected,
  isInformationSelected,
  isWarningSelected,
}: DetailsOptionsViewProps): JSX.Element | null {
  const intl = useIntl();

  return (
    <div className="options-detail-container">
      <div className="options-row">
        <input
          type="radio"
          className="radio-button"
          name={RADIO_OPTION}
          value={OPTION_MESSAGES}
          onChange={onChange}
          checked={optionSelected === OPTION_MESSAGES}
        />
        <div className="font-mono text-sm">{intl.formatMessage({ id: 'filterMessages' })}</div>
      </div>
      <div className="options-row options-checkboxs">
        <div className="checkbox-field">
          <input
            type="checkbox"
            className="checkbox"
            name={CHECKBOX_SELECTION}
            disabled={optionSelected === OPTION_PARAMETERS}
            value={ERRORS_CHECKBOX}
            onChange={onChange}
            checked={isErrorSelected}
          />
          <div className="font-mono text-sm">{intl.formatMessage({ id: 'eror' })}</div>
        </div>
        <div className="checkbox-field">
          <input
            type="checkbox"
            className="checkbox"
            name={CHECKBOX_SELECTION}
            disabled={optionSelected === OPTION_PARAMETERS}
            value={WARNINGS_CHECKBOX}
            onChange={onChange}
            checked={isWarningSelected}
          />
          <div className="font-mono text-sm">{intl.formatMessage({ id: 'warning' })}</div>
        </div>
        <div className="checkbox-field">
          <input
            type="checkbox"
            className="checkbox"
            name={CHECKBOX_SELECTION}
            disabled={optionSelected === OPTION_PARAMETERS}
            value={INFORMATION_CHECKBOX}
            onChange={onChange}
            checked={isInformationSelected}
          />
          <div className="font-mono text-sm">{intl.formatMessage({ id: 'information' })}</div>
        </div>
      </div>
      <div className="options-row">
        <input
          type="radio"
          className="radio-button"
          name={RADIO_OPTION}
          value={OPTION_PARAMETERS}
          onChange={onChange}
          checked={optionSelected === OPTION_PARAMETERS}
        />
        <div className="font-mono text-sm">{intl.formatMessage({ id: 'executionParameters' })}</div>
      </div>
    </div>
  );
}
