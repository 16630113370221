import React, { Component } from 'react';
import FocusTrap from 'react-focus-trap';
import Draggable from 'react-draggable';
import { FormattedMessage } from 'react-intl';
import Overlay from '../../../UI-kit/components/Overlay';
import DatePicker from 'react-datepicker';
import IcoMoon from 'react-icomoon';
import { formatDateFromServer, formatDate } from '../../../UI-kit/helpers/dateHelper';

export class BranchesChangeDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: null,
    };
  }

  cancelHandler() {
    let { setShowChangeDateState } = this.props;
    setShowChangeDateState(false);
  }

  async acceptHandler() {
    let { getSelectedBranches, setShowChangeDateState, setModal, setModal2, getVeriyLoop } = this.props;
    if (this.state.selectedDate !== null) {
      const selectedBranches = getSelectedBranches();
      const sData = selectedBranches[0];
      let currentDate = '';
      if (sData.processDate !== null) {
        currentDate = new Date(formatDateFromServer(sData.processDate));
      }
      let diff = currentDate.getTime() >= this.state.selectedDate.getTime();

      if (diff === false) {
        setModal2(
          true,
          <div>
            <FormattedMessage id="changeDateControl" defaultMessage="¿Esta usted seguro que desea cambiar la fecha de proceso a: " />
            {formatDate(this.state.selectedDate)}
          </div>,
        );
        getVeriyLoop(selectedBranches, this.state.selectedDate);
      } else {
        setModal(
          true,
          <FormattedMessage id="dateControl" defaultMessage="La nueva fecha de proceso debe ser posterior a la fecha de proceso actual" />,
        );
        setShowChangeDateState(false);
      }
    } else {
      setModal(true, <FormattedMessage id="mustInsertDate" defaultMessage="Debe ingresar la fecha." />);
    }
  }

  handleProcessDate(date) {
    this.setState({ selectedDate: date });
  }

  render() {
    let { getSelectedBranches } = this.props;
    const selectedBranches = getSelectedBranches();
    const sData = selectedBranches[0];
    let processDate = '';
    if (sData.nextProcessDate !== null) {
      try {
        processDate = new Date(formatDateFromServer(sData.nextProcessDate));
        if (!this.state.selectedDate) {
          this.setState({ selectedDate: processDate });
        }
      } catch (ex) {
        console.error(ex);
      }
    }

    return (
      <FocusTrap>
        <Overlay style={{ zIndex: 300, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className="border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute"
            style={{
              zIndex: 300,
              height: 200,
              width: 450,
              top: 300,
              left: 400,
            }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move" style={{ padding: 1.5 }} />
            <div className="ml-10 text-left">
              <div className="border-2 mr-5 mt-10">
                <label className="ml-10">
                  <FormattedMessage id="nuevafechaproceso" defaultMessage="Nueva fecha de proceso:" />
                </label>
                <DatePicker
                  onChange={(date) => {
                    this.handleProcessDate(date);
                  }}
                  className={`w-24 rounded shadow text-sm my-10 ml-2`}
                  selected={this.state.selectedDate}
                  dateFormat={'dd/MM/yyyy'}
                />
              </div>
              <div className="mt-5">
                <button
                  className={`text-white ml-32 rounded  m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                  style={{
                    marginTop: '0rem',
                  }}
                  onClick={() => {
                    this.acceptHandler();
                  }}
                >
                  <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
                </button>
                <button
                  style={{
                    marginTop: '0rem',
                  }}
                  className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                  onClick={() => {
                    this.cancelHandler();
                  }}
                >
                  <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />
                </button>
              </div>
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );
  }
}
