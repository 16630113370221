import { API } from '../config';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { store, dispatchActions } from '../../src/store';
import { logout } from '../helpers/login';

/**
 * Handle the catch errors.
 *
 * @param {object} error Catch error object.
 */
export const handleError = (error) => {
  if (error.response) {
    throw new Error(error.response.data.message || error.response.data || error.response.status || error.response);
  } else if (error.request) {
    throw new Error(error.request);
  } else if (error.message) {
    throw new Error(error.message);
  } else {
    throw new Error(error);
  }
};

/**
 * Returns the api url with the endpoint.
 *
 * @param {string} endpoint Endpoint name. (e.g.: `postingMgrSession`).
 * @param {string} action Action (e.g.: `getBranchGroups`).
 * @param {string} params Parameteres (e.g.: `sessionId=${session}`).
 */
export const getUrl = ({ endpoint, action, params }) => {
  return `${API}TopazWebClientWS/resources/${endpoint}/${action}?${params}`;
};

const checkValuesOnString = (string, arrayOfValues) => {
  return arrayOfValues.some((el) => string.toLowerCase().includes(el.toLowerCase()));
};

export const handleEndpointError = (err) => {
  let errorsToExclude = ['topazexception', 'java.lang'];

  if (err && err.response && err.response.data && err.response.data.callStack && checkValuesOnString(err.response.data.callStack, errorsToExclude)) {
    return;
  }

  let errorsToInclude = ['network error', 'service unavailable', 'request failed'];

  if (err && err.message && checkValuesOnString(err.message, errorsToInclude) && (!err.response || err.response.status !== 400)) {
    const msg = (
      <center>
        <FormattedMessage id="networkError" defaultMessage={`Un error de red a ocurrido durante la comunicación con el servidor`} />
        <br />
        <br />
        <FormattedMessage id="isNecessaryReLogin" defaultMessage={`Es necesario volver a iniciar sesión.`} />
      </center>
    );
    store.setState(
      dispatchActions.openGlobalModal(store.getState(), msg, false, null, false, false, false, () => {
        logout();
      }),
    );
  }
};
