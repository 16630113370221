import React, { Component } from 'react'
import * as fromOperation from './operation'

const withOperationServices = (BaseComponent) => {
  class HOC extends Component {
    render () {
      return (
        <BaseComponent
          {...this.props}
          {...fromOperation}
        />
      )
    }
  }

  return HOC
}

export default withOperationServices
