import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { injectIntl } from 'react-intl';
import { storeActions } from '../../src/store';
import { PATHNAME } from '../../src/config';
import {
  getRolesDescription,
  getThinTaskList,
  getProcessDefinitionsInfo,
  getProcessInstancesData,
  loadTopazTaskInfos,
  getProcessInstanceHistory,
  couldReassign,
  couldPartialReassign,
  getUsers,
  getGroups,
  getProcessInstancesDataWithBody,
  getInstanceImage,
} from '../../src/api/workflow';
import WorkflowWindowLayout from './WorkflowWindowLayout';
import WorkflowReassignWindow from './WorkflowReassignWindow';
import WorkflowSearcherWindow from './WorkflowSearcherWindow';
import { columnsTableParent, columnsTableChild } from '../helpers/workflowHelper';
import { getSessionInfo } from '../api/session';
import { simpleFormatDate } from '../../UI-kit/helpers/dateHelper';
const initialState = {
  loading: true,
  roles: {},
  thinTask: [],
  processDefinitionInfo: [],
  dataInbox: [],
  dataDetails: null,
  rawDataDetails: null,
  columnsDetails: null,
  loadingDetails: false,
  loadingHistory: false,
  dataHistory: null,
  userCouldReassign: false,
  taskToReassign: null,
  showReassignWindow: false,
  usersGroups: null,
  taskSelected: null,
  showSearcherWindow: false,
  inboxSelected: false,
  bottomIndexPage: 0,
  seeAll: true,
  isChild: false,
};

export class WorkflowWindow extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.fetchData = this.fetchData.bind(this);
    this.onSelectList = this.onSelectList.bind(this);
    this.selectDetail = this.selectDetail.bind(this);
    this.handleShowReassignWindow = this.handleShowReassignWindow.bind(this);
    this.handleShowSearcherWindow = this.handleShowSearcherWindow.bind(this);
    this.handleSearchTasks = this.handleSearchTasks.bind(this);
    this.changeBottomIndexPage = this.changeBottomIndexPage.bind(this);
    this.executeFunction = this.executeFunction.bind(this);
    this.reAssignInstance = this.reAssignInstance.bind(this);
    this.getInstanceImage = this.getInstanceImage.bind(this);
    this.handleSeeAll = this.handleSeeAll.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
  }

  handleSeeAll(seeAll) {
    const { taskSelected } = this.state;
    this.onSelectList(taskSelected, 0, null, seeAll);
    this.setState((prevState) => ({ seeAll: !prevState.seeAll }));
  }

  async changeBottomIndexPage(newBottom) {
    const { dataDetails, bottomIndexPage, taskSelected, seeAll } = this.state;

    if (dataDetails) {
      this.setState({ loadingDetails: true });
      let tasksFilter = [];
      if (!Array.isArray(taskSelected.value)) {
        tasksFilter = await getProcessInstancesData(newBottom, taskSelected.stateName || '', taskSelected.value, seeAll);
      } else {
        tasksFilter = await loadTopazTaskInfos('', newBottom, taskSelected.value);
      }

      if ((tasksFilter && tasksFilter.length > 0) || newBottom < bottomIndexPage) {
        this.onSelectList(taskSelected, bottomIndexPage, tasksFilter).then(() => {
          this.setState({ bottomIndexPage: newBottom, loadingDetails: false });
        });
      } else this.setState({ loadingDetails: false });
    }
  }

  async(inboxSelected) {
    this.setState({ inboxSelected });
  }

  handleShowReassignWindow(refresh) {
    this.setState((prevState) => ({
      showReassignWindow: !prevState.showReassignWindow,
    }));
    if (refresh === true) {
      this.fetchData();
    }
  }

  handleShowSearcherWindow() {
    this.setState((prevState) => ({
      showSearcherWindow: !prevState.showSearcherWindow,
    }));
  }

  async handleSearchTasks(names, values, bottomIndex = 0) {
    const { taskSelected, seeAll, isChild } = this.state;

    this.setState({ loadingDetails: true, dataHistory: null, currentState: '' });
    let bodyFilter = { searchColumnNames: names, searchFieldValues: values };
    let tasksFilter = [];
    if (isChild) {
      tasksFilter = await loadTopazTaskInfos(values, bottomIndex, taskSelected.value);
    } else {
      tasksFilter = await getProcessInstancesDataWithBody(bottomIndex, taskSelected.stateName || '', taskSelected.value, seeAll, bodyFilter);
    }

    this.onSelectList(taskSelected, bottomIndex, tasksFilter);
    this.handleShowSearcherWindow();
    this.setState({ loadingDetails: false, bottomIndexPage: 0 });
  }

  async selectDetail(index) {
    const { dataDetails } = this.state;
    this.setState({ loadingHistory: true, dataHistory: [], currentState: '' });
    let dataHistory = await getProcessInstanceHistory(dataDetails[index].detailId, true);
    let currentState = dataDetails[index].currentState;
    this.setState({ dataHistory, loadingHistory: false, currentState });
  }

  async getInstanceImage(detailId) {
    const { openGlobalModal, intl } = this.props;
    const result = await getInstanceImage(detailId);
    if (result?.errorTypeInfo) {
      openGlobalModal(
        `${intl.formatMessage({
          id: 'errorGetImageInstance',
          defaultMessage: 'Error al obtener la imagen de la instancia de proceso',
        })} ${detailId}`,
        false,
      );
    } else {
      openGlobalModal(
        <span className="bg-white" style={{ width: '100%', height: '100%' }} dangerouslySetInnerHTML={{ __html: result }} />,
        false,
        null,
        false,
        true,
      );
    }
  }

  createInputParameters(variables) {
    let inputParameters = {};
    variables.forEach((variable) => {
      const inputParameter = {
        name: variable.variableName,
        value: variable.value,
        type: variable.variableName === 'TaskID' ? 'N' : 'A',
      };
      inputParameters[variable.variableName] = inputParameter;
    });
    return inputParameters;
  }

  createArrayInputParameters(variables) {
    let inputParameters = [];
    variables.forEach((variable) => {
      const inputParameter = {
        name: variable.variableName,
        value: variable.value,
        type: variable.variableName === 'TaskID' ? 'N' : 'A',
      };
      inputParameters.push(inputParameter);
    });
    return inputParameters;
  }

  createServerTasks(variables, taskId, userKey, inputParameters) {
    let serverTasks = [];
    let bpmVariables = {};
    variables.forEach((variable) => {
      bpmVariables[variable.mappedName] = null;
    });
    let serverTask = {
      bpmVariables: bpmVariables,
      userKey: userKey,
      taskInstanceID: taskId.toString(),
      dataParameters: inputParameters,
    };
    serverTasks.push(serverTask);
    return serverTasks;
  }

  executeFunction(detailId, operationNumberParam) {
    const { addTab, tabs, addEmptyTab, setCurrentApp, history } = this.props;
    const { rawDataDetails } = this.state;
    let variables, operationNumber, taskId;
    rawDataDetails.forEach((data) => {
      if (data.processId === detailId) {
        variables = data.variables;
        operationNumber = data.operationNumber;
        taskId = data.taskId;
      }
    });
    if (operationNumberParam && operationNumberParam > 0) {
      operationNumber = operationNumberParam;
    }
    if (variables && operationNumber) {
      getSessionInfo().then((res) => {
        let inputParameters = this.createInputParameters(variables);
        let arrayInputParameters = this.createArrayInputParameters(variables);
        let serverTasks = this.createServerTasks(variables, taskId, res.user.key, inputParameters);
        if (!(tabs.length === 1 && tabs[0].emptyTab)) addEmptyTab();
        addTab(operationNumber, '', '', false, null, true, arrayInputParameters, serverTasks, false, false, false, null, null, null);
        history.push(`${PATHNAME}home`);
      });
      setCurrentApp('OPERATIONS');
    }
  }

  reAssignInstance(item) {
    this.setState({ showReassignWindow: true, taskToReassign: item });
  }

  async onSelectList(info, bottomIndex = 0, dataList, seeAllData = null) {
    const { usersGroups, seeAll } = this.state;

    let value = info.node ? info.node.props.value : info.value;
    let stateName = info.node ? info.node.props.stateName : info.stateName;
    let taskSelected = { value, stateName };

    if (seeAllData === null) seeAllData = seeAll;
    if (taskSelected.stateName) seeAllData = true;
    let columns;
    let rawDataDetails = null;
    if (value !== undefined && value !== null) {
      this.setState({ loadingDetails: true, dataHistory: null, currentState: '' });
      if (!Array.isArray(value)) {
        this.setState({ isChild: false });
        if (dataList) value = dataList;
        else {
          value = await getProcessInstancesData(bottomIndex, stateName || '', value, seeAllData);
          this.setState({ bottomIndexPage: 0 });
        }
        if (value.length > 0) {
          columns = columnsTableParent(value[0].keysPrompt, !(stateName || ''), this.props.intl);
          value = value.map((v) => ({
            ...v,
            assigned: v.assigned.map((userA) => {
              if (usersGroups) {
                const userAssigned = usersGroups.find((user) => user.clave === userA);
                return userAssigned ? userAssigned.descripcion : userA;
              } else {
                return userA;
              }
            }),
            responsible: v.responsible.map((userA) => {
              if (usersGroups) {
                const userAssigned = usersGroups.find((user) => user.clave === userA);
                return userAssigned ? userAssigned.descripcion : userA;
              } else {
                return userA;
              }
            }),
            startDate: v.info.start ? simpleFormatDate(v.info.start) : '',
            endDate: v.info.end ? simpleFormatDate(v.info.end) : '',
            detailId: v.info.id,
          }));
        }
      } else {
        if (dataList) value = dataList;
        else {
          value = await loadTopazTaskInfos('', bottomIndex, value);
          this.setState({ bottomIndexPage: 0 });
        }
        rawDataDetails = value;
        this.setState({ isChild: true });
        if (value.length > 0) {
          columns = columnsTableChild(value[0].variablesMapped);
          let newValue = [];
          value.forEach((c) => {
            let arr = {};
            c.variablesMapped.map((v) => {
              arr[v.variableName] = v.value;
              return arr[v.variableName];
            });
            arr.detailId = c.processId;
            arr.currentState = c.taskName;
            newValue.push(arr);
          });
          value = newValue;
        }
      }
    }
    this.setState({
      rawDataDetails: rawDataDetails,
      dataDetails: value,
      columnsDetails: columns.map((obj) => ({ ...obj, order: '', type: 'A' })),
      loadingDetails: false,
      taskSelected,
    });
  }

  async fetchData() {
    if (this.state.loading) {
      this.setState(initialState);
      try {
        let roles = await getRolesDescription();
        let thinTask = await getThinTaskList();
        let processDefinitionInfo = await getProcessDefinitionsInfo();
        let dataInbox = this.getDataInbox(thinTask, processDefinitionInfo);
        let userCouldReassign = await couldReassign();
        let userCouldPartialReassign = await couldPartialReassign();
        let groups = await getGroups();
        getUsers().then((users) => {
          let usersGroups = users.concat(groups);
          let keysRoles = Object.keys(roles);
          usersGroups = usersGroups.concat(
            keysRoles.map((k) => ({
              clave: k,
              descripcion: roles[k],
            })),
          );
          this.setState({
            usersGroups,
            users,
          });
        });
        this.setState({
          roles,
          thinTask,
          processDefinitionInfo,
          dataInbox,
          userCouldReassign,
          userCouldPartialReassign,
        });
      } finally {
        this.setState({
          loading: false,
        });
      }
    }
  }

  getDataInbox(thinTask, processDefinitionInfo) {
    let listProcessThinTask = {};
    thinTask.length > 0 &&
      thinTask.forEach((task) => {
        let keysList = Object.keys(listProcessThinTask);
        if (task.processName) {
          if (!keysList.includes(task.processName)) listProcessThinTask[task.processName] = [];
          let tasks = [];
          let process = listProcessThinTask[task.processName];
          let foundTask = process.find((t) => t.taskName === task.taskName);
          if (foundTask) tasks = foundTask.tasks;
          tasks.push(task);
          if (!foundTask) process.push({ taskName: task.taskName, tasks });
        }
      });
    let dataInbox = [];
    processDefinitionInfo.forEach((process) => {
      let dataProcessThinTask = listProcessThinTask[process.processName];
      if (dataProcessThinTask) dataInbox.push({ ...process, taskList: dataProcessThinTask });
    });
    return dataInbox;
  }

  setTaskDetail(thisObject, taskDetail) {
    thisObject.setState({ dataDetails: taskDetail });
  }

  render() {
    let {
      loading,
      dataInbox,
      thinTask,
      processDefinitionInfo,
      dataDetails,
      columnsDetails,
      loadingDetails,
      dataHistory,
      loadingHistory,
      showReassignWindow,
      taskToReassign,
      users,
      showSearcherWindow,
      bottomIndexPage,
      seeAll,
      taskSelected,
      rawDataDetails,
    } = this.state;

    return (
      <React.Fragment>
        <WorkflowWindowLayout
          loading={loading}
          getInstanceImage={this.getInstanceImage}
          dataInbox={dataInbox}
          thinTask={thinTask}
          processDefinitionInfo={processDefinitionInfo}
          dataDetails={dataDetails}
          thisObject={this}
          setTaskDetail={this.setTaskDetail}
          rawDataDetails={rawDataDetails}
          columnsDetails={columnsDetails}
          loadingDetails={loadingDetails}
          dataHistory={dataHistory}
          loadingHistory={loadingHistory}
          onSelectList={this.onSelectList}
          selectDetail={this.selectDetail}
          executeFunction={this.executeFunction}
          reAssignInstance={this.reAssignInstance}
          fetchData={this.fetchData}
          bottomIndexPage={bottomIndexPage}
          changeBottomIndexPage={this.changeBottomIndexPage}
          handleShowSearcherWindow={this.handleShowSearcherWindow}
          seeAll={seeAll}
          handleSeeAll={this.handleSeeAll}
          taskSelected={taskSelected}
          currentState={this.state.currentState}
          canReassign={this.state.userCouldReassign || this.state.userCouldPartialReassign}
        />
        {showReassignWindow && (
          <WorkflowReassignWindow
            showWindow={showReassignWindow}
            taskToReassign={taskToReassign}
            usersGroups={users}
            handleCancel={this.handleShowReassignWindow}
          />
        )}
        {showSearcherWindow && (
          <WorkflowSearcherWindow
            showWindow={showSearcherWindow}
            handleCancel={this.handleShowSearcherWindow}
            columnsDetails={columnsDetails}
            handleSearch={this.handleSearchTasks}
            isChild={this.state.isChild}
          />
        )}
      </React.Fragment>
    );
  }
}

export default connect('tabs', storeActions)(injectIntl(WorkflowWindow));
