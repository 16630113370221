import React, { useState } from 'react';

import { GroupDefinition } from './models/groupDefinition';
import { ExecuteModalViewModel } from './components/executeModal/ExecuteModalViewModel';
import { MonitorMenuViewModel } from './components/monitorTab/MonitorMenuViewModel';
import './ProcessesManager.scss';
import { SelectedProcess } from './models/types';

import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { PATHNAME } from '../../config';
import Link from './components/link/Link';
import { ProcessesTab } from './components/processesTab/ProcessesTab';
import { PulseLoader } from 'halogenium';
import { ExecutionHistoryViewModel } from './components/executionHistory/ExecutionHistoryViewModel';
import { PeriodicityTabViewModel } from './components/periodicityTab/PeriodicityTabViewModel';
import { ConditionModalViewModel } from './components/conditionModal/ConditionModalViewModel';
import { ProcessSchedule } from './models/processSchedule';
import { ScheduleModalViewModel } from './components/scheduleModal/ScheduleModalViewModel';

interface ProcessesManagerViewProps {
  processesGroups: GroupDefinition[];
  isLoading: boolean;
  onSelectProcess: (process: SelectedProcess) => void;
  selectedProcess: SelectedProcess;
  executedProcesses: number[];
  addExecutedProcessId: (executionId: number) => void;
  eliminateExecutedProcessId: (executionId: number) => void;
  schedulerList: ProcessSchedule[];
  onPeriodicityTabChange: () => void;
}

export function ProcessesManagerView({
  processesGroups,
  isLoading,
  selectedProcess,
  onSelectProcess,
  executedProcesses,
  addExecutedProcessId,
  eliminateExecutedProcessId,
  schedulerList,
  onPeriodicityTabChange,
}: ProcessesManagerViewProps): JSX.Element {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalConditionOpen, setIsModalConditionOpen] = useState<boolean>(false);
  const [isModalScheduleOpen, setIsModalScheduleOpen] = useState<boolean>(false);
  const [isConditioningBoth, setIsConditioningBoth] = useState<boolean>(false);

  function showModal(): void {
    setIsModalOpen(true);
  }

  const cleanValues = () => {
    let paramaters = (selectedProcess as GroupDefinition).groupParameters.parametersList;
    (selectedProcess as GroupDefinition).groupParameters.parametersList = paramaters.map(p => ({ ...p, paramValue: '' }));
  }

  function onModalChange(isOpenModal: boolean): void {
    if (!isOpenModal) {
      cleanValues();
    }
    setIsModalOpen(isOpenModal);
  }

  function onModalConditionChange(isOpenModal: boolean, isConditioningBoth?: boolean): void {
    setIsModalConditionOpen(isOpenModal);
    setIsConditioningBoth(isConditioningBoth || false);
  }

  function onModalScheduleChange(isOpenModal: boolean): void {
    setIsModalScheduleOpen(isOpenModal);
  }

  function renderProcessesTab(): JSX.Element {
    return (
      <ProcessesTab
        onSelectProcess={onSelectProcess}
        processesGroups={processesGroups}
        showModal={showModal}
        selectedProcess={selectedProcess}
        onModalConditionChange={onModalConditionChange}
        onModalScheduleChange={onModalScheduleChange}
      />
    );
  }

  function renderMonitorMenu(): JSX.Element {
    return (
      <MonitorMenuViewModel 
        executedProcesses={executedProcesses} 
        eliminateExecutedProcessId={eliminateExecutedProcessId}
        processesGroups={processesGroups}
        onSelectProcess={onSelectProcess}
        showModal={showModal}
      />
    );
  }

  function renderExecutionHistoryTab(): JSX.Element {
    return <ExecutionHistoryViewModel />;
  }

  function renderPeriodicityTab(): JSX.Element {
    return <PeriodicityTabViewModel schedulerList={schedulerList} isLoading={isLoading} onRefreshData={onPeriodicityTabChange} />;
  }

  function isCurrentHref(href: string): boolean {
    return window.location.href.endsWith(href);
  }

  return isLoading ? (
    <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
      <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
    </div>
  ) : (
    <React.Fragment>
      <div className="processes-manager-container h-auto w-full flex-col">
        {isModalOpen && (
          <ExecuteModalViewModel selectedProcess={selectedProcess} onModalChange={onModalChange} addExecutedProcessId={addExecutedProcessId} />
        )}
        {isModalConditionOpen && (
          <ConditionModalViewModel
            isConditioningBoth={isConditioningBoth}
            onModalConditionChange={onModalConditionChange}
            selectedProcess={selectedProcess}
            schedulerList={schedulerList}
            onRefreshData={onPeriodicityTabChange}
          />
        )}
        {isModalScheduleOpen && <ScheduleModalViewModel onModalScheduleChange={onModalScheduleChange} selectedProcess={selectedProcess} />}
        <div className={`${isModalConditionOpen || isModalScheduleOpen ? 'blur-info' : 'monitor-wrapper'}`}>
          <div className="tabs-container flex justify-start w-full bg-grey-lightest h-auto pt-3 pl-4">
            <Link
              href={`${PATHNAME}home/processes-manager`}
              className={`tabs text-grey-darkest font-semibold py-2 px-4 border border-black-lightest rounded-t-lg shadow ${
                isCurrentHref('home/processes-manager') ? 'selectedTab' : ''
              }`}
            >
              {intl.formatMessage({ id: 'processes' })}
            </Link>
            <Link
              href={`${PATHNAME}home/processes-manager/monitor`}
              className={`tabs text-grey-darkest font-semibold py-2 px-4 border border-black-lightest rounded-t-lg shadow ${
                isCurrentHref('home/processes-manager/monitor') ? 'selectedTab' : ''
              }`}
            >
              {intl.formatMessage({ id: 'Monitor' })}
            </Link>
            <Link
              href={`${PATHNAME}home/processes-manager/history`}
              className={`tabs text-grey-darkest font-semibold py-2 px-4 border border-black-lightest rounded-t-lg shadow ${
                isCurrentHref('home/processes-manager/history') ? 'selectedTab' : ''
              }`}
            >
              {intl.formatMessage({ id: 'historical' })}
            </Link>
            <Link
              href={`${PATHNAME}home/processes-manager/periodicity`}
              className={`tabs text-grey-darkest font-semibold py-2 px-4 border border-black-lightest rounded-t-lg shadow ${
                isCurrentHref('home/processes-manager/periodicity') ? 'selectedTab' : ''
              }`}
              onTabClick={onPeriodicityTabChange}
            >
              {intl.formatMessage({ id: 'periodicity' })}
            </Link>
          </div>
          <Route exact path={`${PATHNAME}home/processes-manager`} render={() => renderProcessesTab()} />
          <Route exact path={`${PATHNAME}home/processes-manager/monitor`} render={() => renderMonitorMenu()} />
          <Route exact path={`${PATHNAME}home/processes-manager/history`} render={() => renderExecutionHistoryTab()} />
          <Route exact path={`${PATHNAME}home/processes-manager/periodicity`} render={() => renderPeriodicityTab()} />
        </div>
      </div>
    </React.Fragment>
  );
}
