import React, { Component } from 'react';
import { connect } from 'unistore/react';
import withReportServices from '../api/withReportServices';
import { storeActions } from '../store';
import FilterAutosuggest from './FilterAutosuggest';
import { injectIntl } from 'react-intl';
import { getReports } from '../api/report';

// eslint-disable-next-line react/prefer-stateless-function
export class FilterReports extends Component {
  render() {
    const { getReportGenerationSpec, selectReport, intl } = this.props;

    return (
      <FilterAutosuggest
        multipleSections={true}
        getOptions={getReports}
        onSelectOption={selectReport}
        actionSelectOption={(p) => getReportGenerationSpec(p, true)}
        placeholder={intl.formatMessage({
          id: 'placeHolderFilterReports',
          defaultMessage: 'Buscar reportes',
        })}
        title={intl.formatMessage({
          id: 'reports',
          defaultMessage: 'reportes',
        })}
      />
    );
  }
}

export default connect(null, storeActions)(withReportServices(injectIntl(FilterReports)));
