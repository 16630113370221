import React, { Component } from 'react';
import FocusTrap from 'react-focus-trap';
import HelpTable from './HelpTable';
import { FormattedMessage } from 'react-intl';
import { css, StyleSheet } from 'aphrodite';

const Button = ({ title, click, className, disabled = false }) => {
  return (
    <button
      className={`${className} ${disabled && 'opacity-75'} bg-grey hover:bg-primary focus:bg-primary text-white rounded shadow m-2 py-2 px-4 text-sm`}
      onClick={click}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

const styles = StyleSheet.create({
  titleContainer: {
    width: '100%',
    minHeight: '1.5rem',
    padding: '.5rem 1rem',
    color: '#6B7280',
    cursor: 'move',
    textAlign: 'left',
    flex: '1 0 auto',
    borderBottom: `1px solid #DCE0E6`,
  },
  helpWrapper: {
    backgroundColor: '#F4F5F7',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
    borderColor: 'transparent',
    borderRadius: '.25rem',
    borderWidth: 1,
    minHeight: '300px',
    minWidth: '400px',
    height: '346px',
    position: 'fixed',
    top: '-50%',
    width: '1000px',
    zIndex: 100,
  },
});

export class InfoTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedRowInfo: {},
    };

    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel(e) {
    let setShowInfo = this.props.setShowInfo;
    setShowInfo(false);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    let infoObject = this.props.infoObject;
    let rows = getRows(infoObject.data);
    let columns = getColumns();
    return (
      <FocusTrap>
        <div className={css(styles.helpWrapper)}>
          <div className={`helpHandleDragg ${css(styles.titleContainer)}`}>{infoObject.title}</div>
          <div className={`flex border border-smoke rounded`}>
            <HelpTable
              title={infoObject.title}
              transactionLineLabel={'tl1111'}
              data={rows && rows.map((row, index) => ({ ...row, id: index }))}
              columns={columns}
              nodeId={this.props.nodeId}
              goToLineFn={() => {}}
            />
          </div>
          {infoObject.info.map((row, index) => (
            // eslint-disable-next-line react/jsx-key
            <div>{row}</div>
          ))}
          <div className="absolute flex justify-center mt-6" style={{ width: '100%' }}>
            <Button title={<FormattedMessage id="cancel" defaultMessage={'Cancelar'} />} click={() => this.handleCancel()} />
          </div>
        </div>
      </FocusTrap>
    );
  }
}

const getRows = (rows) => {
  const result = Array.from(rows).map(
    ([valor1, valor2, valor3, valor4, valor5, valor6, valor7, valor8, valor9, valor10, valor11, valor12, valor13, valor14]) => ({
      grupo: valor1,
      rubro: valor2,
      cuenta: valor3,
      moneda: valor4,
      sucursal: valor5,
      producto: valor6,
      operacion: valor7,
      ordinal: valor8,
      fechaValor: valor9,
      ajuste: valor10,
      cajaDiario: valor11,
      signo: valor12,
      importe: valor13,
      equivalenteMN: valor14,
    }),
  );
  return result;
};

const getColumns = () => {
  let columns = [
    {
      Header: <FormattedMessage id="Grupo" defaultMessage={'Grupo'} />,
      accessor: 'grupo',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 10,
      type: 2,
    },
    {
      Header: <FormattedMessage id="Rubro" defaultMessage={'Rubro'} />,
      accessor: 'rubro',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 20,
      type: 2,
    },
    {
      Header: <FormattedMessage id="Cuenta" defaultMessage={'Cuenta'} />,
      accessor: 'cuenta',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 50,
      type: 2,
    },
    {
      Header: <FormattedMessage id="Moneda" defaultMessage={'Moneda'} />,
      accessor: 'moneda',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 10,
      type: 2,
    },
    {
      Header: <FormattedMessage id="Sucursal" defaultMessage={'Sucursal'} />,
      accessor: 'sucursal',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 25,
      type: 2,
    },
    {
      Header: <FormattedMessage id="Producto" defaultMessage={'Producto'} />,
      accessor: 'producto',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 25,
      type: 2,
    },
    {
      Header: <FormattedMessage id="Operacion" defaultMessage={'Operacion'} />,
      accessor: 'operacion',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 25,
      type: 2,
    },
    {
      Header: <FormattedMessage id="Ordinal" defaultMessage={'Ordinal'} />,
      accessor: 'ordinal',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 25,
      type: 2,
    },
    {
      Header: <FormattedMessage id="FechaValor" defaultMessage={'Fecha Valor'} />,
      accessor: 'fechaValor',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 40,
      type: 4,
    },
    {
      Header: <FormattedMessage id="Ajuste" defaultMessage={'Ajuste'} />,
      accessor: 'ajuste',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 15,
      type: 1,
    },
    {
      Header: <FormattedMessage id="CajaDiario" defaultMessage={'Caja Diario'} />,
      accessor: 'cajaDiario',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 40,
      type: 1,
    },
    {
      Header: <FormattedMessage id="Signo" defaultMessage={'Signo'} />,
      accessor: 'signo',
      decimals: 0,
      isReturned: false,
      isSortable: false,
      length: 30,
      type: 1,
    },
    {
      Header: <FormattedMessage id="Importe" defaultMessage={'Importe'} />,
      accessor: 'importe',
      decimals: 2,
      isReturned: false,
      isSortable: false,
      length: 40,
      type: 2,
    },
    {
      Header: <FormattedMessage id="EquivalenteMN" defaultMessage={'Equivalente MN'} />,
      accessor: 'equivalenteMN',
      decimals: 2,
      isReturned: false,
      isSortable: false,
      length: 55,
      type: 2,
    },
  ];

  return columns;
};

export default InfoTable;
