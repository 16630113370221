import React from 'react';
import { StyleSheet } from 'aphrodite';
import { useTheme } from './../themes/ThemeContext';

const defaultStyleSheet = StyleSheet.create({});

const GlobalStylesContext = React.createContext(defaultStyleSheet);
const useGlobalStyles = () => React.useContext(GlobalStylesContext);

const GlobalStylesProvider = ({ children }) => {
  const themeState = useTheme();
  const { text, border, background, primary } = themeState.theme;

  const globalStyles = StyleSheet.create({
    input: {
      padding: '.3rem',
      borderWidth: 1,
      backgroundColor: background.light,
      borderColor: border.border,
      color: text.text,
      borderRadius: 2,
      transition: '.2s',
      ':hover': {
        backgroundColor: background.background,
        borderColor: border.dark,
      },
      ':focus': {
        backgroundColor: background.light2,
        borderColor: primary.primary,
      },
    },
    numberInput: {
      width: '8rem',
      textAlign: 'right',
    },
    dateInput: {
      width: '8rem',
    },
    dateTimeInput: {
      width: '11rem',
    },
    fieldsWithoutButton: {
      height: 'auto',
    },
  });

  return <GlobalStylesContext.Provider value={globalStyles}>{children}</GlobalStylesContext.Provider>;
};

export { GlobalStylesProvider, useGlobalStyles };
