import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { useTheme } from './../../../themes/ThemeContext';

const Field = ({ labelText, htmlFor, error, showFieldBelow, children }) => {
  const themeState = useTheme();
  const { error: errorColor, text } = themeState.theme;

  const styles = StyleSheet.create({
    fieldContainer: {
      marginBottom: '1rem',
      display: 'flex',
      userSelect: 'none',
    },
    fieldLabel: {
      color: error ? errorColor.error : text.text,
      fontSize: '.75rem',
      marginBottom: '.2rem',
    },
    fieldDataContainer: {},
  });

  let containerStyle = { margin: '3px' };
  let labelStyle = { width: '70px' };
  if (showFieldBelow) {
    containerStyle = { ...containerStyle, display: 'flex', flexDirection: 'column' };
    labelStyle = {};
  }

  return (
    <div className={css(styles.fieldContainer)} style={containerStyle}>
      <div style={labelStyle}>
        <label htmlFor={htmlFor} className={css(styles.fieldLabel)}>
          {labelText}
        </label>
      </div>
      <div className={css(styles.fieldDataContainer)}>{children}</div>
    </div>
  );
};

export default Field;
