import moment from 'moment-timezone';
import { PulseLoader } from 'halogenium';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import addUserIcon from '../../../icons/addUser';
import threeDotsIcon from '../../../icons/threeDots';
import ModalMessage from '../../../../ModalMessage';

import {
  BRANCH_HELPER,
  CDI_DOC,
  GROUP_HELPER,
  INITIALS_ERROR,
  INITIALSLENGTH_ERROR,
  LOGIN_KEY_INDEX,
  MACHINES_HELPER,
  NO_GROUP_ERROR,
  INMEDIATOSUPERIOR_INVALID,
  PRODUCTION_VALUE,
  SPANISH_VALUE,
  USERNAME_ERROR,
  USER_HELPER,
  INMEDIATOSUPERIOR_HELPER,
  EMPTY_STRING,
} from '../../../models/constants';
import { UserBasicInfo } from '../../../models/userBasicInfo';
import { Select, SelectData } from '../../select/Select';

import '../SecurityToolsModal.scss';
import { LDAPModalViewModel } from './ldapModal/LDAPModalViewModel';
import { WarningModalViewModel } from './warningModal/WarningModalViewModel';
import QuestionMarkIcon from '../../questionMark/QuestionMark';

export interface ModalLDAP {
  showDialog: boolean;
  message: string;
}

export interface UserInfoViewProps {
  onModalChange: (modalOpen: string) => void;
  onAddUserClick: () => void;
  onCancelUserClick: () => void;
  selectedUser: UserBasicInfo;
  docTypeOptions: SelectData[];
  loginTypeOptionsSecond: SelectData[];
  loginTypeOptionsNoSecond: SelectData[];
  contextOptions: SelectData[];
  languageOptions: SelectData[];
  blockingMotiveOptions: SelectData[];
  onHelperChange: (helper: string) => void;
  setCurrentOpenModal: (currentOpenModal: string) => void;
  showInsertRequired: boolean;
  showPasswordWarning: boolean;
  isError: string;
  checkedLDAP: boolean;
  isAddUser: boolean;
  changedDisabledToChecked: boolean;
  onValueStringChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInitialsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onValueDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onValueNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onValueCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onLanguageChange: (language: string) => void;
  onLoginTypeChange: (loginType: number) => void;
  onBlockingMotiveChange: (motive: number) => void;
  onDocTypeChange: (docType: string) => void;
  onContextChange: (context: string) => void;
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUserChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInmediatoSuperiorChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onGroupInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  confirmationPassword: string;
  onPasswordConfirmationInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onLDAPChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEnableCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDisabledPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOneTimeInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAcceptLDAP: (user: string, password: string) => void;
  modalWarningOpen: string;
  isLoadingWarnings: boolean;
  showBlockingMotives: boolean;
  onModalWarningChange: (accept: boolean) => void;
  changesWarning: boolean;
  hasUserPermissions: boolean;
  onRefresh: () => void;
  setChangesWarning: (isShowing: boolean) => void;
  onChangesWarningChange: (isShowing: boolean, undoChanges: boolean) => void;
  handleKeyOnNumber: (event: any) => void;
  modalLDAP: ModalLDAP;
  setModalLDAP: (event: any) => void;
}

export function UserInfoModalView({
  onModalChange,
  onAddUserClick,
  onCancelUserClick,
  docTypeOptions,
  loginTypeOptionsSecond,
  loginTypeOptionsNoSecond,
  contextOptions,
  languageOptions,
  onHelperChange,
  setCurrentOpenModal,
  showInsertRequired,
  isError,
  onValueStringChange,
  onInitialsChange,
  onValueDateChange,
  onValueNumberChange,
  onValueCheckBoxChange,
  onLanguageChange,
  onLoginTypeChange,
  onDocTypeChange,
  onContextChange,
  onNameChange,
  onUserChange,
  onInmediatoSuperiorChange,
  onGroupInputChange,
  onPasswordInputChange,
  confirmationPassword,
  onPasswordConfirmationInputChange,
  onLDAPChange,
  onEnableCheck,
  onDisabledPasswordChange,
  onOneTimeInputChange,
  selectedUser,
  checkedLDAP,
  showPasswordWarning,
  onAcceptLDAP,
  isAddUser,
  modalWarningOpen,
  onModalWarningChange,
  isLoadingWarnings,
  blockingMotiveOptions,
  onBlockingMotiveChange,
  showBlockingMotives,
  changedDisabledToChecked,
  changesWarning,
  hasUserPermissions,
  onRefresh,
  setChangesWarning,
  onChangesWarningChange,
  handleKeyOnNumber,
  modalLDAP,
  setModalLDAP,
}: UserInfoViewProps): JSX.Element | null {
  const [isModalLDAPOpen, setIsModalLDAPOpen] = useState<boolean>(false);
  const intl = useIntl();

  function onCancelClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onCancelUserClick();
  }

  function onAddClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onAddUserClick();
  }

  function onGroupHelperClick(event: React.MouseEvent): void {
    event.stopPropagation();
    setCurrentOpenModal('group');
    onHelperChange(GROUP_HELPER);
  }

  function onInmediatoSuperiorHelperClick(event: React.MouseEvent): void {
    event.stopPropagation();
    setCurrentOpenModal('inmediatosuperior');
    onHelperChange(INMEDIATOSUPERIOR_HELPER);
  }

  function onUserHelperClick(event: React.MouseEvent): void {
    event.stopPropagation();
    setCurrentOpenModal('username');
    onHelperChange(USER_HELPER);
  }

  function onBranchHelperClick(event: React.MouseEvent): void {
    event.stopPropagation();
    setCurrentOpenModal('branchNumber');
    onHelperChange(BRANCH_HELPER);
  }

  function onMachineNumberHelperClick(event: React.MouseEvent): void {
    event.stopPropagation();
    setCurrentOpenModal('machineNumber');
    onHelperChange(MACHINES_HELPER);
  }

  function onLDAPButtonClick(event: React.MouseEvent): void {
    event.stopPropagation();
    setIsModalLDAPOpen(true);
  }

  function onModalLDAPChange(isOpen: boolean): void {
    setIsModalLDAPOpen(isOpen);
  }

  function renderWarningMessages(): JSX.Element {
    return (
      <div>
        {showInsertRequired && (
          <label className="pr-1 pt-2 text-red flex" style={{ marginLeft: '50px' }}>
            {intl.formatMessage({ id: 'completeRequiredFields' })}
          </label>
        )}
        {isError === USERNAME_ERROR && isAddUser && (
          <label className="pr-1 pt-2 text-red flex" style={{ marginLeft: '50px' }}>
            {intl.formatMessage({ id: 'usernameAlreadyExists' })}
          </label>
        )}
        {isError === INITIALS_ERROR && isAddUser && (
          <label className="pr-1 pt-2 text-red flex" style={{ marginLeft: '50px' }}>
            {intl.formatMessage({ id: 'initialsAlreadyExist' })}
          </label>
        )}
        {isError === INITIALSLENGTH_ERROR && isAddUser && (
          <label className="pr-1 pt-2 text-red flex" style={{ marginLeft: '50px' }}>
            {intl.formatMessage({ id: 'initiatlsLengthLimit' })}
          </label>
        )}
        {isError === NO_GROUP_ERROR && (
          <label className="pr-1 pt-2 text-red flex" style={{ marginLeft: '50px' }}>
            {intl.formatMessage({ id: 'notExistsGroup' })}
          </label>
        )}
        {isError === INMEDIATOSUPERIOR_INVALID && (
          <label className="pr-1 pt-2 text-red flex" style={{ marginLeft: '50px' }}>
            {intl.formatMessage({ id: 'invalidInmediatoSuperior' })}
          </label>
        )}
        {isError !== EMPTY_STRING &&
          isError !== USERNAME_ERROR &&
          isError !== INITIALS_ERROR &&
          isError !== INITIALSLENGTH_ERROR &&
          isError !== NO_GROUP_ERROR &&
          isError !== INMEDIATOSUPERIOR_INVALID && (
            <label className="pr-1 pt-2 text-red flex" style={{ marginLeft: '50px' }}>
              {isError}
            </label>
          )}
      </div>
    );
  }

  return (
    <div className="font-mono flex group-info-modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center full-width">
        <div className="fixed" aria-hidden="true"></div>
        <span className="hidden" aria-hidden="true">
          &#8203;
        </span>
        <div className="relative bg-white rounded-lg shadow-xl border shadow-sm user-info-content">
          <div className="bg-blue-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className={`user-info-forms-container ${changesWarning && 'blur-info'}`}>
                <h3 className="flex items-center text-lg leading-6 font-medium text-gray-900 m-2 mb-6" id="modal-title">
                  {addUserIcon} {intl.formatMessage({ id: 'userSidebar' })}
                </h3>
                <div className="flex">
                  {isLoadingWarnings ? (
                    <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
                      <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
                    </div>
                  ) : (
                    modalWarningOpen !== '' && (
                      <WarningModalViewModel username={selectedUser.username} sameNumberOf={modalWarningOpen} onModalChange={onModalWarningChange} />
                    )
                  )}
                  {isModalLDAPOpen && <LDAPModalViewModel onCancel={onModalLDAPChange} onAccept={onAcceptLDAP} />}
                  {modalLDAP.showDialog && (
                    <ModalMessage
                      message={modalLDAP.message}
                      handleAccept={async () => {
                        setModalLDAP({ showDialog: false });
                      }}
                      acceptTitle={'Aceptar'}
                      draggable={true}
                    />
                  )}
                  <div className="fields-container column">
                    <div className="field-container-user w-full" style={{ marginLeft: '30px' }}>
                      <label className=" input ml-4 pr-1 text-sm">{intl.formatMessage({ id: 'name' }) + '*'}</label>
                      <input
                        name="name"
                        onChange={onNameChange}
                        value={selectedUser.name}
                        disabled={!hasUserPermissions}
                        className="input border border-grey-lighter"
                      />
                    </div>
                    <fieldset
                      className="fieldset-user rounded border border-light flex relative m-2 mt-4 full-width"
                      style={{ border: '1px solid grey' }}
                    >
                      <legend className="ml-2 font-bold" style={{ marginBottom: '15px' }}>
                        {intl.formatMessage({ id: 'userGeneralData' })}
                      </legend>
                      <div className="flex-col w-full">
                        <div className="field-container-user  flex items-center mb-3 justify-between">
                          <label className="input pr-1  text-sm">{intl.formatMessage({ id: 'userSidebar' }) + '*'}</label>
                          <div className="flex flex-row input">
                            <input
                              name="username"
                              onChange={onUserChange}
                              disabled={!isAddUser || !hasUserPermissions}
                              value={selectedUser.username}
                              className="input-with-help-button border border-grey-lighter"
                            />
                            {!(!isAddUser || !hasUserPermissions) ? (
                              <QuestionMarkIcon disabled={!hasUserPermissions} className="button-helper" click={onUserHelperClick} />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="field-container-user  flex items-center mb-3 justify-between">
                          <label className="input pr-1  text-sm">{intl.formatMessage({ id: 'userInitials' })}</label>
                          <input
                            name="initials"
                            disabled={!isAddUser || !hasUserPermissions}
                            onChange={onInitialsChange}
                            value={selectedUser.initials}
                            className="input border border-grey-lighter"
                          />
                        </div>
                        <div className="field-container-user  flex items-center mb-3 justify-between">
                          <label className="input pr-1  text-sm">{intl.formatMessage({ id: 'group' })}</label>
                          <div className="flex flex-row input">
                            <input
                              type="text"
                              name="group"
                              disabled={!hasUserPermissions}
                              value={selectedUser.group}
                              onChange={onGroupInputChange}
                              className="input-with-help-button border border-grey-lighter"
                            />
                            {hasUserPermissions ? (
                              <QuestionMarkIcon disabled={!hasUserPermissions} className="button-helper" click={onGroupHelperClick} />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="field-container-user  flex items-center mb-3 justify-between">
                          <label className="input pr-1  text-sm">{intl.formatMessage({ id: 'inmediatosuperior' })}</label>
                          <div className="flex flex-row input">
                            <input
                              name="inmediatosuperior"
                              onChange={onInmediatoSuperiorChange}
                              disabled={!hasUserPermissions}
                              value={selectedUser.inmediatosuperior}
                              className="input-with-help-button border border-grey-lighter"
                            />
                            {hasUserPermissions ? (
                              <QuestionMarkIcon disabled={!hasUserPermissions} className="button-helper" click={onInmediatoSuperiorHelperClick} />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="field-container-user  flex items-center mb-3 justify-between">
                          <label className="input pr-1 pt-2 text-sm">{intl.formatMessage({ id: 'documentType' })}</label>
                          <Select
                            disabled={!hasUserPermissions}
                            options={docTypeOptions}
                            selectedState={selectedUser.documentType ? selectedUser.documentType : CDI_DOC}
                            onSelectedChange={onDocTypeChange}
                            className="input"
                          />
                        </div>
                        <div className="field-container-user  flex items-center mb-3 justify-between">
                          <label className="input pr-1 text-sm">{intl.formatMessage({ id: 'document' })}</label>
                          <input
                            name="document"
                            disabled={!hasUserPermissions}
                            onChange={onValueStringChange}
                            value={selectedUser.document}
                            className="input border border-grey-lighter"
                          />
                        </div>
                        <div className="flex flex-row w-full  flex items-center mb-3 justify-between" style={{ margin: '10px 10px' }}>
                          <div>
                            <input
                              className="field-checkbox-user mr-2 ml-2"
                              type="checkbox"
                              checked={checkedLDAP}
                              name="LDAP"
                              onChange={onLDAPChange}
                              disabled={!hasUserPermissions}
                            />
                            <label className="pr-1 text-sm">{intl.formatMessage({ id: 'LDAP' })}</label>
                          </div>
                          <div className="flex items-center pr-3">
                            <input
                              name="ldapuser"
                              disabled={!checkedLDAP}
                              value={selectedUser.ldapuser}
                              onChange={onValueStringChange}
                              className="border border-grey-lighter mr-2"
                            />
                            <button disabled={!checkedLDAP} onClick={onLDAPButtonClick}>
                              {threeDotsIcon}
                            </button>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="fieldset-user rounded border border-light flex relative m-2 full-width" style={{ display: 'block' }}>
                      <legend className="ml-2" style={{ marginBottom: '15px' }}></legend>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className=" input pr-1 text-sm">{intl.formatMessage({ id: 'registerNumber' })}</label>
                        <input
                          name="registerNumber"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                          value={selectedUser.registerNumber}
                          onChange={onValueNumberChange}
                          disabled={!hasUserPermissions}
                          className=" input border border-grey-lighter"
                        />
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className=" input pr-1 pt-2 text-sm">{intl.formatMessage({ id: 'context' })}</label>
                        <Select
                          disabled={!hasUserPermissions}
                          options={contextOptions}
                          selectedState={selectedUser.mantdes ? selectedUser.mantdes : PRODUCTION_VALUE}
                          onSelectedChange={onContextChange}
                          className="input"
                        />
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between ">
                        <label className=" input pr-1 pt-2 text-sm">{intl.formatMessage({ id: 'language' })}</label>
                        <Select
                          disabled={!hasUserPermissions}
                          options={languageOptions}
                          selectedState={selectedUser.language ? selectedUser.language : SPANISH_VALUE}
                          onSelectedChange={onLanguageChange}
                          className="input"
                        />
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className=" input pr-1 text-sm">{intl.formatMessage({ id: 'branch' })}</label>
                        <div className="flex flex-row input">
                          <input
                            name="branchNumber"
                            type="number"
                            min="0"
                            onKeyDown={(e) => handleKeyOnNumber(e)}
                            value={selectedUser.branchNumber}
                            onChange={onValueNumberChange}
                            disabled={!hasUserPermissions}
                            className="input-with-help-button border border-grey-lighter"
                          />
                          {hasUserPermissions ? (
                            <QuestionMarkIcon disabled={!hasUserPermissions} className="button-helper" click={onBranchHelperClick} />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className=" input pr-1 text-sm">{intl.formatMessage({ id: 'sector' })}</label>
                        <input
                          name="sector"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                          onChange={onValueNumberChange}
                          value={selectedUser.sector}
                          disabled={!hasUserPermissions}
                          className=" input border border-grey-lighter"
                        />
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className=" input pr-1 text-sm">{intl.formatMessage({ id: 'machineNumber' })}</label>
                        <div className="flex flex-row input">
                          <input
                            name="machineNumber"
                            type="number"
                            min="0"
                            onKeyDown={(e) => handleKeyOnNumber(e)}
                            value={selectedUser.machineNumber}
                            onChange={onValueNumberChange}
                            disabled={!hasUserPermissions}
                            className="input-with-help-button border border-grey-lighter"
                          />
                          {hasUserPermissions ? (
                            <QuestionMarkIcon disabled={!hasUserPermissions} className="button-helper" click={onMachineNumberHelperClick} />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <fieldset
                    className="fieldset-user rounded border border-light flex relative fields-container column"
                    style={{ border: '1px solid grey' }}
                  >
                    <legend className="ml-2 font-bold" style={{ marginBottom: '15px' }}>
                      {intl.formatMessage({ id: 'keyData' })}
                    </legend>
                    <div className="flex-col w-full">
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className="input pr-1 text-sm">{intl.formatMessage({ id: 'since' })}</label>
                        <input
                          name="passwordInitialDate"
                          onChange={onValueDateChange}
                          value={selectedUser.passwordInitialDate ? moment(selectedUser.passwordInitialDate).tz('GMT').format('yyyy-MM-DD') : ''}
                          className="input border border-grey-lighter"
                          disabled={!hasUserPermissions}
                          type="date"
                        />
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className="input pr-1 text-sm">{intl.formatMessage({ id: 'to' })}</label>
                        <input
                          name="passwordToDate"
                          onChange={onValueDateChange}
                          disabled={!hasUserPermissions}
                          value={selectedUser.passwordToDate ? moment(selectedUser.passwordToDate).tz('GMT').format('yyyy-MM-DD') : ''}
                          className="input border border-grey-lighter"
                          type="date"
                        />
                      </div>
                      <div className="flex flex-row w-full  flex items-center mb-3" style={{ margin: '10px 10px' }}>
                        <input
                          type="checkbox"
                          checked={selectedUser.iniexphabilitado === 1}
                          disabled={!hasUserPermissions}
                          className="field-checkbox-user mr-2 ml-2"
                          value="Habilita"
                          name="Habilita"
                          onChange={onEnableCheck}
                        />
                        <label className="pr-1 text-sm">{intl.formatMessage({ id: 'enableKey' })}</label>
                        <input
                          type="checkbox"
                          className="field-checkbox-user mr-2 ml-2"
                          checked={selectedUser.inactiveControl === 1}
                          disabled={!hasUserPermissions}
                          name="inactiveControl"
                          onChange={onValueCheckBoxChange}
                        />
                        <label className="pr-1 text-sm">{intl.formatMessage({ id: 'inactiveControl' })}</label>
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className="input pr-1 text-sm">{intl.formatMessage({ id: 'creationDate' })}</label>
                        <input
                          disabled={true}
                          name="passwordCreationDate"
                          onChange={onValueDateChange}
                          value={selectedUser.passwordCreationDate ? moment(selectedUser.passwordCreationDate).tz('GMT').format('yyyy-MM-DD') : ''}
                          className="input border border-grey-lighter"
                          type="date"
                        />
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className="input pr-1 text-sm">{intl.formatMessage({ id: 'expiration' })}</label>
                        <input
                          disabled={true}
                          name="passwordExpiration"
                          onChange={onValueDateChange}
                          value={selectedUser.passwordExpiration ? moment(selectedUser.passwordExpiration).tz('GMT').format('yyyy-MM-DD') : ''}
                          className="input border border-grey-lighter"
                          type="date"
                        />
                      </div>
                      <div className="flex flex-row w-full  flex items-center mb-3" style={{ margin: '10px 10px' }}>
                        <input
                          className="field-checkbox-user mr-2 ml-2"
                          type="checkbox"
                          checked={selectedUser.multiplesLogins === 1}
                          disabled={!hasUserPermissions}
                          name="multiplesLogins"
                          onChange={onValueCheckBoxChange}
                        />
                        <label className="pr-1 text-sm">{intl.formatMessage({ id: 'multipleLogins' })}</label>
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className="input pr-1 text-sm">{intl.formatMessage({ id: 'numberOfLogins' })}</label>
                        <input
                          name="loginsAmount"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                          onChange={onValueNumberChange}
                          value={selectedUser.loginsAmount}
                          disabled={!hasUserPermissions}
                          className="input border border-grey-lighter"
                        />
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className="input pr-1 text-sm">{intl.formatMessage({ id: 'passwordLogin' })}</label>
                        <input
                          type="password"
                          name="password"
                          autoComplete="new-password"
                          value={selectedUser.password}
                          onChange={onPasswordInputChange}
                          disabled={!hasUserPermissions}
                          className="input border border-grey-lighter"
                        />
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className="input pr-1 text-sm">{intl.formatMessage({ id: 'confirmPassword' })}</label>
                        <input
                          type="password"
                          defaultValue={''}
                          autoComplete="off"
                          value={confirmationPassword}
                          onChange={onPasswordConfirmationInputChange}
                          disabled={!hasUserPermissions}
                          className="input border border-grey-lighter"
                        />
                      </div>
                      <div>
                        {showPasswordWarning && (
                          <label className="pr-1 pt-2 text-red" style={{ paddingLeft: '10px' }}>
                            {intl.formatMessage({ id: 'confirmPasswordError' })}
                          </label>
                        )}
                      </div>
                      <div className="flex flex-row w-full  flex items-center mb-3" style={{ margin: '10px 10px' }}>
                        <input
                          className="field-checkbox-user mr-2 ml-2"
                          type="checkbox"
                          onChange={onOneTimeInputChange}
                          disabled={!hasUserPermissions}
                          value="Utilizar una vez"
                          name="Utilizar una vez"
                        />
                        <label className="pr-1 text-sm">{intl.formatMessage({ id: 'useOnce' })}</label>
                        <input
                          className="field-checkbox-user mr-2 ml-2"
                          type="checkbox"
                          checked={selectedUser.secondLogin === 1}
                          name="secondLogin"
                          disabled={!hasUserPermissions}
                          onChange={onValueCheckBoxChange}
                        />
                        <label className="pr-1 text-sm">{intl.formatMessage({ id: 'secondLogin' })}</label>
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className="input pr-1 pt-2 text-sm">{intl.formatMessage({ id: 'loginType' })}</label>
                        <Select
                          disabled={!hasUserPermissions}
                          options={selectedUser.secondLogin === 1 ? loginTypeOptionsSecond : loginTypeOptionsNoSecond}
                          selectedState={selectedUser.loginType ? selectedUser.loginType : LOGIN_KEY_INDEX}
                          onSelectedChange={onLoginTypeChange}
                          className="input"
                        />
                      </div>
                      <div className="field-container-user  flex items-center mb-3 justify-between">
                        <label className="input pr-1 text-sm">{intl.formatMessage({ id: 'attemptsFailed' })}</label>
                        <input
                          name="failedAttempts"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                          onChange={onValueNumberChange}
                          value={selectedUser.failedAttempts}
                          disabled={true}
                          className="input border border-grey-lighter"
                        />
                      </div>
                      <div className="flex flex-row w-full  flex items-center mb-3" style={{ margin: '10px 10px' }}>
                        <input
                          className="field-checkbox-user mr-2 ml-2"
                          type="checkbox"
                          checked={selectedUser.disabled === 1}
                          name="disabled"
                          disabled={!hasUserPermissions}
                          onChange={onDisabledPasswordChange}
                        />
                        <label className="pr-1 text-sm">{intl.formatMessage({ id: 'disabled' })}</label>
                      </div>

                      {showBlockingMotives ? (
                        <div className="mr-5 ml-5 flex flex-row items-center mb-3 justify-between">
                          <div className="pl-5 pr-5" style={{ width: '48%' }}>
                            <label className="w-full pr-1 text-sm">El</label>
                            <input
                              name="blockingDate"
                              disabled={true}
                              onChange={onValueDateChange}
                              value={selectedUser.blockingDate ? moment(selectedUser.blockingDate).format('yyyy-MM-DD') : ''}
                              className="w-full border border-grey-lighter"
                              type="date"
                            />
                          </div>
                          <div className="pr-5 pl-5" style={{ width: '48%' }}>
                            <label className="w-full pr-1 text-sm">{intl.formatMessage({ id: 'blockingMotive' })}</label>
                            <Select
                              disabled={!changedDisabledToChecked || !hasUserPermissions}
                              options={blockingMotiveOptions}
                              selectedState={selectedUser.blockingMotive ? selectedUser.blockingMotive : 1}
                              onSelectedChange={onBlockingMotiveChange}
                              className="w-full"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="field-container-user  flex items-center mb-3 justify-between">
                          <label className="input pr-1 text-sm">El</label>
                          <input
                            name="blockingDate"
                            disabled={true}
                            onChange={onValueDateChange}
                            value={selectedUser.blockingDate ? moment(selectedUser.blockingDate).format('yyyy-MM-DD') : ''}
                            className="input border border-grey-lighter"
                            type="date"
                          />
                        </div>
                      )}
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <div className="justify-center items-center">{renderWarningMessages()}</div>
          <div className="w-full flex justify-end">
            <button
              type="button"
              onClick={onAddClick}
              disabled={!hasUserPermissions}
              className="mb-3 mt-3 w-24 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm"
            >
              {isAddUser ? intl.formatMessage({ id: 'add' }) : intl.formatMessage({ id: 'modify' })}
            </button>
            <button
              type="button"
              onClick={onCancelClick}
              className="mb-3 mt-3 mr-3 w-24 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm"
            >
              {intl.formatMessage({ id: 'cancel' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
