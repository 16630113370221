import React, { useState } from 'react';
import './../src/styles/CloseTabButton.css';
import { injectIntl } from 'react-intl';
import CloseIcon from './../src/resources/icons/fa/close.svg';
import { useTheme } from '../src/themes/ThemeContext';

const CloseTabButton = (props) => {
  const themeState = useTheme();
  const { closeTabButton } = themeState.theme;
  const hoverColor = closeTabButton.hover.color;
  const defaultColor = closeTabButton.default.color;

  const [iconColor, setIconColor] = useState(defaultColor);

  const handleHover = () => {
    setIconColor(hoverColor);
  };
  const handleBlur = () => {
    setIconColor(defaultColor);
  };

  return (
    <div
      className="CloseTabButton ml-1 px-1 flex items-center hover:bg-grey-lighter rounded-sm cursor-pointer"
      onClick={props.handleClick}
      onMouseEnter={handleHover}
      onFocus={handleHover}
      onBlur={handleBlur}
      onMouseLeave={handleBlur}
      title={props.intl.formatMessage({
        id: 'closeTab',
        defaultMessage: 'Cerrar la pestaña',
      })}
    >
      <CloseIcon className="CloseTabButton" width="11px" fill={iconColor} />
    </div>
  );
};

export default injectIntl(CloseTabButton);
