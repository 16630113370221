export const USER_NAME_INDEX: number = 1;
export const USER_GROUP_INDEX: number = 2;
export const USER_USERNAME_INDEX: number = 0;
export const USER_INITIALS_INDEX: number = 3;
export const REMOVE_MODAL: string = 'REMOVE';
export const CONFIGURATIONS_MODAL: string = 'CONFIGURATIONS';
export const PRINT_MODAL: string = 'PRINT';
export const REMOVE_ERROR_MODAL: string = 'REMOVE_ERROR';
export const SEARCH_MODAL: string = 'SEARCH';
export const PASS_DB__MODAL: string = 'PASS_DB';
export const PASS_SWIFT_MODAL: string = 'PASS_SWIFT';
export const CRYPTO_MODAL: string = 'CRYPTO';
export const USER_MODAL: string = 'USER_INFO';
export const NO_MODAL_OPEN: string = '';
export const ADD_GROUP_MODAL: string = 'ADDGROUP';
export const TYPE_GROUP: string = 'GROUP';
export const TYPE_USER: string = 'USER';
export const USER_USERNAME: string = 'USERNAME';
export const USER_NAME: string = 'NAME';
export const USER_INITIALS: string = 'INITIALS';
export const GROUP_DESCRIPTION: string = 'DESCRIPTION';
export const EQUAL_SEARCH: string = 'EQUAL';
export const EQUAL_SEARCH_SYMBOL: string = '=';
export const LIKE_SEARCH: string = 'LIKE';
export const FIRST_LEVEL_NODE: number = 0;
export const SECOND_LEVEL_NODE: number = 1;
export const CDI_DOC: string = 'CDI';
export const CUIL_DOC: string = 'CUIL';
export const DEVELOPMENT_VALUE: string = 'D';
export const PRODUCTION_VALUE: string = 'P';
export const SPANISH_VALUE: string = 'E';
export const ENGLISH_VALUE: string = 'I';
export const USERNAME_ERROR: string = 'USERNAME_ERROR';
export const INITIALS_ERROR: string = 'INITIALS_ERROR';
export const INITIALSLENGTH_ERROR: string = 'INITIALSLENGTH_ERROR';
export const NO_GROUP_ERROR: string = 'NO_GROUP_ERROR';
export const INMEDIATOSUPERIOR_INVALID: string = 'INMEDIATOSUPERIOR_INVALID';
export const GROUP_HELPER: string = 'GROUP';
export const USER_HELPER: string = 'USER';
export const INMEDIATOSUPERIOR_HELPER: string = 'INMEDIATOSUPERIOR';
export const MACHINES_HELPER: string = 'MACHINE_NUMBER';
export const BRANCH_HELPER: string = 'BRANCH';
export const EMPTY_STRING: string = '';
export const LOGIN_KEY_INDEX: number = 0;
export const LOGIN_FINGERPRINT_INDEX: number = 1;
export const LOGIN_BOTH_INDEX: number = 2;
export const NOT_CHECKED: number = 0;
export const CHECKED: number = 1;
export const NO_HELPER_SELECTED: number = -1;
export const NOT_SELECTED: number = 0;
export const PRINT_USERS: string = 'USERS';
export const PRINT_GROUPS: string = 'GROUPS';
export const PRINT_RESTRICTIONS: string = 'RESTRICTIONS';
export const SORT_GROUP: string = 'GROUP';
export const SORT_GROUP_INDEX: number = 1;
export const SORT_NAME_INDEX: number = 2;
export const SORT_INITIALS_INDEX: number = 3;
export const SORT_SECTOR_INDEX: number = 4;
export const SORT_NONE_INDEX: number = 0;
export const SORT_NAME: string = 'NAME';
export const SORT_INITIALS: string = 'INITIALS';
export const SORT_SECTOR: string = 'SECTOR';
export const PRINT_PDF_FILE_EXTENSION: string = '#view=FitH';
export const RADIO_TYPE: string = 'type';
export const TITLE_PRINT: string = 'TITLE';
export const FROM_PRINT: string = 'FROM';
export const TO_PRINT: string = 'TO';
export const ENABLED_PRINT: string = 'ENABLED';
export const DISABLED_PRINT: string = 'DISABLED';
export const MENU_KEY: string = 'Menu';
export const REPORTS_KEY: string = 'Reportes';
export const OPERATIONS_KEY: string = 'Operaciones';
export const PERMISSIONS_KEY: string = 'Permisos';
export const MACHINE_MODAL: string = 'MACHINE';
export const REGISTER_MODAL: string = 'REGISTER';
export const DEFAULT_RESTRICTION_KEY: string = '****';
export const DISABLED_PERMISSION_KEY: string = '0';
export const ENABLED_PERMISSION_KEY: string = '1';
export const FREE_PERMISSION_KEY: string = '2';
export const INITIAL_FROM_PRINT: number = 0;
export const INITIAL_TO_PRINT: number = 99999999;
export const HAS_USER_PERMISSION: number = 36;
export const HAS_GROUP_PERMISSION: number = 35;
