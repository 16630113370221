import React, { Component } from 'react';
import { store } from '../../store';
import DateInput from './components/DateInput';

class DateSelector extends Component {
  constructor(props) {
    super();
    this.filterListID = props.filterListID;
    this.ownChange = false;

    let fromTime = new Date();
    fromTime.setMinutes(0);
    fromTime.setHours(0);
    fromTime.setSeconds(0);

    let toTime = new Date();
    toTime.setMinutes(0);
    toTime.setHours(0);
    toTime.setSeconds(0);

    const filterParams = store.getState().bitacoraApp[this.filterListID].filterParams;
    this.state = {
      fromDate: filterParams.fechaHoraDesde ? filterParams.fechaHoraDesde : fromTime,
      toDate: filterParams.fechaHoraHasta ? filterParams.fechaHoraHasta : toTime,
    };
    if (filterParams) {
      this.updateStateWithParams(filterParams);
    }
  }

  updateStateWithParams(filterParams) {
    if (filterParams.fechaHoraDesde) {
      if (this.state.fromDate !== filterParams.fechaHoraDesde) {
        this.setState({ fromDate: filterParams.fechaHoraDesde });
      }
    }
    if (filterParams.fechaHoraHasta) {
      if (this.state.toDate !== filterParams.fechaHoraHasta) {
        this.setState({ toDate: filterParams.fechaHoraHasta });
      }
    }
  }

  componentDidUpdate() {
    if (!this.ownChange) {
      const filterParams = store.getState().bitacoraApp[this.filterListID].filterParams;
      if (filterParams) {
        this.updateStateWithParams(filterParams);
      }
    }
  }

  updateDates(fechaHoraDesde, fechaHoraHasta) {
    if (fechaHoraDesde) {
      this.setState({ fromDate: fechaHoraDesde });
    }
    if (fechaHoraHasta) {
      this.setState({ toDate: fechaHoraHasta });
    }
    this.ownChange = false;
  }

  updateFilterInfo = (fechaHoraDesdeParam, fechaHoraHastaParam) => {
    let {
      fechaHoraDesde,
      fechaHoraHasta,
      firstResult,
      maxResults,
      codigo,
      descripcion,
      programa,
      usuario,
      mnemotecnico,
      maquina,
      sucursal,
      operacion,
      usuariored,
      macaddress,
      direccionIP,
      persistenceUnit,
    } = store.getState().bitacoraApp[this.filterListID].filterParams;

    if (fechaHoraDesdeParam) {
      fechaHoraDesde = fechaHoraDesdeParam;
      this.setState({ fromDate: fechaHoraDesde });
    }
    if (fechaHoraHastaParam) {
      fechaHoraHasta = fechaHoraHastaParam;
      this.setState({ toDate: fechaHoraHasta });
    }

    const filterParams = {
      fechaHoraHasta,
      fechaHoraDesde,
      firstResult,
      maxResults,
      codigo,
      descripcion,
      programa,
      usuario,
      mnemotecnico,
      maquina,
      sucursal,
      operacion,
      usuariored,
      macaddress,
      direccionIP,
      persistenceUnit,
    };

    let filterData = {};
    filterData[this.filterListID] = { filterParams };
    store.setState({
      bitacoraApp: {
        ...store.getState().bitacoraApp,
        ...filterData,
      },
    });
  };

  render() {
    return (
      <div>
        <DateInput
          id="fromDate"
          date={this.state.fromDate}
          onDateChange={(date) => {
            this.updateFilterInfo(date, null);
            this.updateDates(date, null);
          }}
          labelIntlOptions={{
            id: 'from',
            defaultMessage: 'Desde',
          }}
        />
        <DateInput
          id="toDate"
          date={this.state.toDate}
          onDateChange={(date) => {
            this.updateFilterInfo(null, date);
            this.updateDates(null, date);
          }}
          labelIntlOptions={{
            id: 'to',
            defaultMessage: 'Hasta',
          }}
        />
      </div>
    );
  }
}

export default DateSelector;
