import React, { useEffect, useState } from 'react';
import { ErrorModalView, ModalProps } from './ErrorModalView';

interface ErrorModalViewModelProps {
  onModalChange: (isOpenModal: boolean) => void;
  getErrorMessage: () => string;
}

export function ErrorModalViewModel({ onModalChange, getErrorMessage }: ErrorModalViewModelProps): JSX.Element {
  const [isModalErrorOpen, setIsModalErrorOpen] = useState<boolean>(false);

  function getModalProps(): ModalProps {
    if (isModalErrorOpen) {
      return getModalPropsError();
    } else {
      return getModalPropsDefault();
    }
  }

  function getModalPropsError(): ModalProps {
    return {
      error: `${getErrorMessage()}`,
      onAccept: () => {
        onModalChange(false);
      },
    };
  }

  function getModalPropsDefault(): ModalProps {
    return {
      error: '',
      onAccept: () => {},
    };
  }
  useEffect(() => {
    setIsModalErrorOpen(true);
  }, []);

  return (
    <div>
      <ErrorModalView {...getModalProps()} />
    </div>
  );
}
