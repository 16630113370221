import React, { Component, useRef } from 'react';
import { connect } from 'unistore/react';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';

import { storeActions } from '../../src/store';
import { injectIntl, FormattedMessage } from 'react-intl';
import FocusTrap from 'react-focus-trap';

import { ABOVE_ALL } from '../constants/zIndexValues';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Button = ({ id, title, click, className }) => {
  const button = useRef(null);
  return (
    <button
      id={id}
      className={`${className} btn-sm bg-primary text-white rounded  focus:outlins-nonw`}
      onClick={click}
      ref={button}
      style={{ width: '80px' }}
      onKeyDown={(e) => {
        if (e.ctrlKey && e.keyCode === 76) {
          e.preventDefault();
        }
      }}
      onBlur={(e) => {
        if (e.relatedTarget === null || e.relatedTarget.classList.contains('focus-trap')) {
          button.current.focus();
        }
      }}
    >
      {title}
    </button>
  );
};

export class ExportToExcelWindow extends Component {
  componentDidMount() {
    const exportElement = document.getElementById('btnExportarExcel');
    if (exportElement) {
      exportElement.focus();
      exportElement.style.width = '80px';
    }
  }

  handleKeyDown(e) {
    if (e.key === 'Escape') {
      this.props.cancelHandler();
    }
  }

  render() {
    let { showWindow, intl } = this.props;

    const cancelar = (
      <FormattedMessage
        id="cancelar"
        defaultMessage={intl.formatMessage({
          id: 'cancel',
          defaultMessage: 'Cancelar',
        })}
      />
    );

    if (!showWindow) {
      return null;
    }
    return (
      <div
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <FocusTrap>
          <div
            className={`absolute w-screen-1/4 h-screen-1/2 bg-smoke-light `}
            style={{
              zIndex: showWindow && ABOVE_ALL,
              left: '-8%',
              top: '-40%',
            }}
          />
          <Draggable bounds="body" handle=".handleDraggFilter">
            <div
              className={`border border-secondary bg-grey-lighter-lighter rounded shadow 
            absolute ${showWindow ? '' : 'hidden'}`}
              style={{
                top: 40,
                left: 70,
                width: '240px',
                height: '130px',

                zIndex: ABOVE_ALL,
              }}
            >
              <div className="w-full flex justify-end">
                <IcoMoon icon="enlarge" width="25" className="handleDraggFilter cursor-move m-2" />
              </div>
              <div className=" flex bg-title-grey">
                <div className="flex-grow bg-title-grey text-center pb-2 opacity-75" style={{ fontSize: 15 }}>
                  <FormattedMessage
                    id="exportTitle"
                    defaultMessage={intl.formatMessage({
                      id: 'exportExcel',
                      defaultMessage: 'Exportar excel',
                    })}
                  />
                </div>
              </div>
              <div className="absolute" style={{ marginLeft: '22px', marginTop: '10px' }}>
                <button
                  onClick={() => {
                    let { cancelHandler } = this.props;
                    cancelHandler();
                  }}
                  style={{ outline: 'none ' }}
                  onKeyDown={(e) => {
                    this.handleKeyDown(e);
                  }}
                >
                  <ReactHTMLTableToExcel
                    id="btnExportarExcel"
                    className="float-left text-white rounded m-2 py-1 px-2 text-xs bg-grey hover:bg-primary active:bg-primary focus:bg-primary"
                    table="table"
                    filename="Excel"
                    sheet="pagina 1"
                    buttonText={intl.formatMessage({
                      id: 'download',
                      defaultMessage: 'Descargar',
                    })}
                    autofocus
                  />
                </button>
                <Button
                  id="btnCancelExportExcel"
                  className="float-right text-white rounded  m-2 py-1 px-2 text-xs bg-grey hover:bg-primary"
                  title={cancelar}
                  click={() => {
                    let { cancelHandler } = this.props;
                    cancelHandler();
                  }}
                  onKeyDown={(e) => {
                    this.handleKeyDown(e);
                  }}
                />
              </div>
              <table id="table" style={{ visibility: 'hidden' }}>
                <thead>
                  <tr>
                    {this.props.currentState.columns.map((user, index) => {
                      return <td key={user.Header}>{user.Header}</td>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.props.currentState.rows.map((rows, index) => {
                    return (
                      <>
                        <tr>
                          {this.props.currentState.columns.map((col) => {
                            return <td key={col.accessor + index}>{rows[col.accessor]}</td>;
                          })}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Draggable>
        </FocusTrap>
      </div>
    );
  }
}

export default connect(storeActions)(injectIntl(ExportToExcelWindow));
