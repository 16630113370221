import React, { Component } from 'react';
import { deleteRequest, authorizePermissions, confirm, confirmDeferred } from '../api/postingManager';
import ModalMessage from './ModalMessage';
import { injectIntl, FormattedMessage } from 'react-intl';
import { formatDateSimple } from '../../UI-kit/helpers/dateHelper';
import { connect } from 'unistore/react';
import { storeActions } from '../../src/store';
import { getCurrentTabInformation } from '../../src/selectors';

export const STATE_SEAT_AUTHORIZE = 65;
export const STATE_SEAT_CONFIRM = 67;
export const STATE_SEAT_DEFERRED = 68;

const authRequest = (data) =>
  data.map((auth) => {
    delete auth.reports;
    delete auth.forms;
    return auth;
  });

class PostingPermissionsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authorizations: {},
      showModal: false,
      modalText: '',
      comment: '',
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleConfirmDeferred = this.handleConfirmDeferred.bind(this);
    this.handleAuthorize = this.handleAuthorize.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.askToConfirm = this.askToConfirm.bind(this);
  }

  handleDelete() {
    const { comment } = this.state;
    const {
      postingData: { postingData, bodyGetPostings },
      intl,
    } = this.props;

    const { branch, transactionID } = postingData;

    this.askToConfirm(
      intl.formatMessage({
        id: 'doYouWantUnsubscribePosting',
        defaultMessage: 'Desea dar de baja el asiento?',
      }),
      async () => {
        let response = await deleteRequest(branch, transactionID, comment, false, bodyGetPostings);
        this.closeModalAndGoBackToList();
        if (response && response.mensaje) {
          this.props.openGlobalModal(response.mensaje);
        }
      },
    );
  }

  closeModalAndGoBackToList() {
    this.setState({
      showModal: false,
    });
    this.props.backToList();
  }

  handleConfirm() {
    const { comment } = this.state;
    const {
      postingData: { postingData },
      data,
      intl,
    } = this.props;

    const { branch, transactionID } = postingData;

    this.askToConfirm(
      intl.formatMessage({
        id: 'doYouWantConfirmPosting',
        defaultMessage: 'Desea confirmar el asiento?',
      }),
      () => {
        confirm(branch, transactionID, comment, false, authRequest(data)).then((responseData) => {
          if (responseData && responseData.mensaje) {
            this.setState({
              showModal: true,
              onAccept: () => {
                this.closeModalAndGoBackToList();
              },
              modalText: (
                <div>
                  <FormattedMessage id="postingNotConfirmed" defaultMessage={`No se pudo confirmar el asiento.`} /> <br /> {responseData.mensaje}
                </div>
              ),
            });
          } else {
            this.closeModalAndGoBackToList();
          }
        });
      },
    );
  }

  handleConfirmDeferred() {
    // const { comment } = this.state
    const {
      postingData: { postingData },
      data,
      intl,
    } = this.props;

    const { branch, transactionID } = postingData;

    this.askToConfirm(
      `${intl.formatMessage({
        id: 'doYouWantConfirmPosting',
        defaultMessage: 'Desea confirmar el asiento?',
      })} (${intl.formatMessage({
        id: 'deferred',
        defaultMessage: 'diferido',
      })})`,
      async () => {
        await confirmDeferred(branch, transactionID, authRequest(data));
        this.closeModalAndGoBackToList();
      },
    );
  }

  handleAuthorize() {
    const { comment } = this.state;
    const {
      postingData: { postingData },
      data,
      intl,
    } = this.props;

    const { branch, transactionID } = postingData;
    this.askToConfirm(
      intl.formatMessage({
        id: 'doYouWantAuthorizePosting',
        defaultMessage: 'Desea autorizar el asiento?',
      }),
      () => {
        authorizePermissions(branch, transactionID, comment, false, authRequest(data)).then((responseData) => {
          if (responseData && responseData.mensaje) {
            this.setState({
              showModal: true,
              onAccept: () => {
                this.closeModalAndGoBackToList();
              },
              modalText: (
                <div>
                  <FormattedMessage id="postingNotAutorized" defaultMessage={`No se pudo autorizar el asiento.`} /> <br /> {responseData.mensaje}
                </div>
              ),
            });
          } else {
            this.closeModalAndGoBackToList();
          }
        });
      },
    );
  }

  askToConfirm(text, acceptHandler) {
    this.setState({
      showModal: true,
      modalText: text,
      onAccept: acceptHandler,
    });
  }

  handleCommentChange(e) {
    this.setState({
      comment: e.target.value,
    });
  }

  handleCheckboxChange(e, value, item) {
    const { authorizations } = this.state;
    this.setState({
      authorizations: {
        ...authorizations,
        [value]: e.target.checked,
      },
    });
  }

  enableAuthorize() {
    const { authorizations } = this.state;
    return Object.values(authorizations).filter((item) => !!item).length > 0;
  }

  render() {
    const { data, state, noDataText, intl, disableDelete, action } = this.props;

    const { showModal, modalText, onAccept, comment } = this.state;

    const disableAuthorize = !this.enableAuthorize();
    const showAuth = state === STATE_SEAT_AUTHORIZE && action === 1;

    const showConfirm = state === STATE_SEAT_CONFIRM && action === 2;
    const disableConfirm = false;

    const showConfirmDeferred = state === STATE_SEAT_DEFERRED && action === 4;
    const disableConfirmDeferred = false;

    let content;
    if (!data || data.length === 0) {
      content = noDataText;
    } else {
      content = (
        <div>
          {data.map((item, index) => {
            const { authorizer, description, state, processDate, permite, value } = item;

            if (state !== 1 && state !== 2) {
              return (
                <div key={index} className="mb-4 flex">
                  <div className="pr-2" style={{ color: '#6EB83E' }}>
                    ✔
                  </div>
                  <div className="flex-grow">
                    <div style={{ color: '#6EB83E' }}>
                      <FormattedMessage id="authorizedBy" defaultMessage={`Autorizado por`} /> {authorizer}{' '}
                      <FormattedMessage id="on" defaultMessage={`el`} /> {formatDateSimple(processDate.split(' ')[0])}
                    </div>
                    {description}
                  </div>
                </div>
              );
            }

            return (
              <div key={index} className="mb-4 flex">
                <div className="pr-2">
                  <input
                    id={`chkAutorizar-${index}`}
                    type="checkbox"
                    value={!permite}
                    disabled={state === 2}
                    onChange={(e) => this.handleCheckboxChange(e, value, item)}
                  />
                </div>
                <div className="flex-grow">
                  <div style={{ color: '#B83E3E' }}>
                    <FormattedMessage id="authorizationPending" defaultMessage={`Pendiente de autorización`} />
                  </div>
                  {description}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div>
        {content}
        <div className="my-6">
          <textarea
            id="comment"
            name="comment"
            placeholder={intl.formatMessage({
              id: 'addComment',
              defaultMessage: 'Agregar un comentario',
            })}
            className="w-full h-16 rounded border border-light p-2"
            value={comment}
            onChange={this.handleCommentChange}
          />
          <div className="my-6">
            {showAuth && (
              <button
                id="btnAutorizar"
                className={`uppercase text-sm border rounded text-white bg-primary px-4 py-2 mr-2 ${
                  disableAuthorize && 'opacity-50 cursor-not-allowed'
                }`}
                disabled={disableAuthorize}
                onClick={this.handleAuthorize}
              >
                <FormattedMessage id="authorize" defaultMessage={`Autorizar`} />
              </button>
            )}
            {showConfirm && (
              <button
                id="btnConfirmar"
                className={`uppercase text-sm border rounded text-white bg-primary px-4 py-2 mr-2 ${
                  disableConfirm && 'opacity-50 cursor-not-allowed'
                }`}
                disabled={disableConfirm}
                onClick={this.handleConfirm}
              >
                <FormattedMessage id="confirm" defaultMessage={`Confirmar`} />
              </button>
            )}
            {showConfirmDeferred && (
              <button
                id="btnConfirmarDiferido"
                className={`uppercase text-sm border rounded text-white bg-primary px-4 py-2 mr-2 ${
                  disableConfirmDeferred && 'opacity-50 cursor-not-allowed'
                }`}
                disabled={disableConfirmDeferred}
                onClick={this.handleConfirmDeferred}
              >
                <FormattedMessage id="confirmDeferred" defaultMessage={`Confirmar Diferido`} />
              </button>
            )}
            <button
              id="btnDeletePosting"
              className={`uppercase text-sm border rounded text-white bg-error px-4 py-2 mr-2 ${disableDelete && 'opacity-50 cursor-not-allowed'}`}
              disabled={disableDelete}
              onClick={this.handleDelete}
            >
              <FormattedMessage id="unsubscribe" defaultMessage={`Dar de baja`} />
            </button>
          </div>
        </div>
        {showModal && (
          <ModalMessage
            message={modalText}
            handleAccept={() => {
              if (onAccept) {
                onAccept();
              }
            }}
            handleCancel={() => {
              this.setState({
                showModal: false,
              });
            }}
            draggable={true}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { operationID, operationNumber, focusElement, focusPaused, focusOn, apiLoading } = getCurrentTabInformation(state);

  const { lastValues } = state;

  return {
    operationID,
    operationNumber,
    focusElement,
    focusPaused,
    lastValues,
    focusOn,
    apiLoading,
  };
};

export default connect(mapStateToProps, storeActions)(injectIntl(PostingPermissionsList));
