export const BUTTON_YES = 1;
export const BUTTON_NO = 2;
export const BUTTON_OK = 4;
export const BUTTON_CANCEL = 8;
export const BUTTON_MORE_INFO = 16;

export const ButtonMessages = {
  [BUTTON_YES]: {
    messageId: 'yes',
    defaultMessage: 'Sí',
  },
  [BUTTON_NO]: {
    messageId: 'no',
    defaultMessage: 'No',
  },
  [BUTTON_OK]: {
    messageId: 'accept',
    defaultMessage: 'Aceptar',
  },
  [BUTTON_CANCEL]: {
    messageId: 'cancel',
    defaultMessage: 'Cancelar',
  },
  [BUTTON_MORE_INFO]: {
    messageId: 'moreInfo',
    defaultMessage: 'Más información',
  },
};
