import React from 'react';
import BranchesManager from './BranchesManager';

const BranchesViewer = (props) => {
  //  const [showBranchesManager, setShowBranchesManager] = useState(false);

  return <BranchesManager />;
};

export default BranchesViewer;
