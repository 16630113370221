import React, { useState, useEffect } from 'react';
import { connect } from 'unistore/react';
import { storeActions, store } from '../../store';
import SidebarView from './SidebarView';
import browserStorage from '../../utils/browserStorage';
import { USER_NAME } from '../../utils/browserStorage/constants';
import Option from './OptionView';
import { logout } from './../../helpers/login';
import usePdfCleaner from '../../hooks/usePdfCleaner';
import { addValueTest } from '../../../src/components/Testing/HandleLineTest';

const SidebarViewModel = (props) => {
  const sidebarStatusId = () => {
    return `SIDEBAR_${browserStorage.sessionGet(USER_NAME)}`;
  };

  const bsIsCollapsed = browserStorage.get(sidebarStatusId());
  const isCollapsed = bsIsCollapsed !== undefined ? bsIsCollapsed : true;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [deleteFile] = usePdfCleaner();
  const { data, toggleSidebar } = props;

  useEffect(() => {
    toggleSidebar(isCollapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCurrentApp = (index) => {
    const { setCurrentApp } = props;

    setSelectedIndex(index);

    if (index === 0) {
      setCurrentApp('OPERATIONS'); // props.setCurrentApp
    } else if (index === 1) {
      setCurrentApp('REPORTS');
    } else {
      setCurrentApp('');
    }
  };

  const isSelected = (linkTo, index) => {
    return window.location.pathname ? linkTo === window.location.pathname : index === selectedIndex;
  };

  const handleSidebar = () => {
    const { toggleSidebar, sidebarIsCollapsed } = props;
    toggleSidebar(); // in global state
    browserStorage.set(sidebarStatusId(), !sidebarIsCollapsed); // in local storage
  };

  const handleLogout = () => {
    const { tabsReport } = props;
    tabsReport.forEach((t) => {
      if (t.reportData) {
        const { name, nameExcel, fileExtension, fileExtensionExcel } = t.reportData;
        if (fileExtension) {
          const fileDir = t.reportData && `${name}.${fileExtension}`;
          fileDir && deleteFile(fileDir);
        }
        if (fileExtensionExcel) {
          const fileDirExcel = t.reportData && `${nameExcel}.${fileExtensionExcel}`;
          fileDirExcel && deleteFile(fileDirExcel);
        }
      }
    });

    logout();
  };

  const options = data.map((option, index) => {
    return (
      <Option
        id={option.id}
        title={option.option}
        key={index}
        selected={isSelected(option.link, index)}
        collapsed={props.sidebarIsCollapsed}
        click={(e) => {
          if (option.id === 'idWorkflow') {
            let recordOn = store.getState().recordOn;
            if (recordOn) {
              let textOperation = JSON.parse(window.sessionStorage.getItem('textOperation'));
              if (!textOperation || textOperation.length === 0 || (textOperation.length > 0 && textOperation[0].indexOf('oper') === -1)) {
                textOperation = [];
                textOperation.push('workflow\n');
                textOperation.push('Feature: Workflow\n');
                textOperation.push('Scenario: Workflow\n');
                textOperation.push('CMD:CLICK ID:idWorkflow\n');
                window.sessionStorage.setItem('textOperation', JSON.stringify(textOperation));
              }
            }
          }
          addValueTest('CLICK', option.id, '');
          options.onClick && options.onClick();
          setCurrentApp(index);
        }}
        icon={option.icon}
        linkTo={option.link}
      />
    );
  });

  return <SidebarView onClickMenuButton={handleSidebar} isCollapsed={props.sidebarIsCollapsed} options={options} logout={handleLogout} />;
};

const mapStateToProps = (state) => ({
  tabsReport: state.tabsReport,
  sidebarIsCollapsed: state.sidebarIsCollapsed,
});

export default connect(mapStateToProps, storeActions)(SidebarViewModel);
