import React, { Component } from 'react';
import FocusTrap from 'react-focus-trap';
import Draggable from 'react-draggable';
import { FormattedMessage } from 'react-intl';
import Overlay from '../../../UI-kit/components/Overlay';
import IcoMoon from 'react-icomoon';
import { getElement, replaceElement } from './HandleLineTest';

export class SaveTest extends Component {
  constructor(props) {
    super(props);
    let textOperation = JSON.parse(window.sessionStorage.getItem('textOperation'));
    let lineFeature = getElement(textOperation, 'Feature:');
    let lineScenario = getElement(textOperation, 'Scenario:');

    if (lineFeature !== '') {
      lineFeature = lineFeature.replace('Feature: ', '');
      lineFeature = lineFeature.replace(/\n|\r/g, '');
    }

    if (lineScenario !== '') {
      lineScenario = lineScenario.replace('Scenario: ', '');
      lineScenario = lineScenario.replace(/\n|\r/g, '');
    }

    this.state = {
      assertEqualText: true,
      feature: lineFeature,
      scenario: lineScenario,
    };
    this.handleStateChange = this.handleStateChange.bind(this);
    this.acceptHandler = this.acceptHandler.bind(this);
  }

  componentDidMount() {}

  handleStateChange() {
    this.setState({ assertEqualText: !this.state.assertEqualText });
  }

  handleFeatureChange(name) {
    this.setState({ feature: name });
  }

  handleScenarioChange(name) {
    this.setState({ scenario: name });
  }

  cancelHandler() {
    let { setSaveTest } = this.props;
    setSaveTest(false);
  }

  async acceptHandler() {
    let { setSaveTest } = this.props;

    let textOperation = JSON.parse(window.sessionStorage.getItem('textOperation'));

    let confirmar = document.getElementById('checkConfirmar').checked;
    if (confirmar) {
      textOperation.push('CMD:CONFIRMARASIENTO\n');
    }

    let autorizar = document.getElementById('checkAutorizar').checked;
    if (autorizar) {
      textOperation.push('CMD:AUTORIZARASIENTO\n');
    }

    let baja = document.getElementById('checkBaja').checked;
    if (baja) {
      textOperation.push('CMD:BAJAASIENTO\n');
    }

    let textFile = [...textOperation];

    let textScenario = document.getElementById('idScenario').value;
    if (textScenario !== '') {
      textScenario = 'Scenario: ' + textScenario + '\n';
      textFile = replaceElement(textFile, 'Scenario', textScenario);
    }

    let textFeature = document.getElementById('idFeature').value;
    if (textFeature !== '') {
      textFeature = 'Feature: ' + textFeature + '\n';
      textFile = replaceElement(textFile, 'Feature', textFeature);
    }

    // let textFile = [...store.getState().textOperation];
    let filename = textFile.shift();
    filename = filename.replace(/\n|\r/g, '');
    if (document.getElementById('fileName').value.trim() !== '') {
      filename = document.getElementById('fileName').value.trim() + '_' + filename + '.txt';
    } else {
      filename = '1_' + filename + '.txt';
    }

    if (textFile && textFile.length > 0) {
      // eslint-disable-next-line no-undef
      var blob = new Blob(textFile, { type: 'text/plain;charset=utf-8' });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }

    setSaveTest(false);
  }

  render() {
    return (
      <FocusTrap>
        <Overlay style={{ zIndex: 300, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className="border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute"
            style={{
              zIndex: 300,
              height: 300,
              width: 600,
              top: 150,
              left: 400,
            }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move" style={{ padding: 1.5 }} />
            <div className="ml-5 mt-5 text-left">
              <div className="border-2 mr-5">
                <div
                  className="ml-5 text-left"
                  style={{
                    height: 50,
                  }}
                >
                  <div key="divcheckBaja" className="flex content-center mb-2 mt-2 text-sm">
                    <input type="checkbox" className="focus:shadow-sm-light mt-3" id="checkAutorizar" />
                    <label className="ml-2  mt-3">Autorizar asiento</label>
                    <input type="checkbox" className="focus:shadow-sm-light mt-3 ml-8" id="checkConfirmar" />
                    <label className="ml-2  mt-3">Confirmar asiento</label>
                    <input type="checkbox" className="focus:shadow-sm-light mt-3 ml-8" id="checkBaja" />
                    <label className="ml-2  mt-3">Bajar asiento</label>
                  </div>
                </div>
                <div className="ml-5 mt-5 text-left mb-5">
                  <label className="ml-2">Nombre: </label>
                  <input
                    id="fileName"
                    style={{
                      width: '180px',
                    }}
                    className="text-left appearance-none mr-1 px-1 ml-6 rounded shadow text-sm w-full"
                    type="text"
                    onChange={(event) => {}}
                  />
                </div>
                <div className="ml-5 mt-5 text-left mb-5">
                  <label className="ml-2">Feature: </label>
                  <input
                    id="idFeature"
                    style={{
                      width: '400px',
                    }}
                    value={this.state.feature}
                    className="text-left appearance-none mr-1 px-1 ml-6 rounded shadow text-sm w-full"
                    type="text"
                    onChange={(event) => {
                      this.handleFeatureChange(event.target.value);
                    }}
                  />
                </div>
                <div className="ml-5 mt-5 text-left mb-5">
                  <label className="ml-2">Scenario: </label>
                  <input
                    id="idScenario"
                    style={{
                      width: '400px',
                    }}
                    className="text-left appearance-none mr-1 px-1 ml-5 rounded shadow text-sm w-full"
                    type="text"
                    value={this.state.scenario}
                    onChange={(event) => {
                      this.handleScenarioChange(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={`mt-5 col-md-12 text-center`}>
                <button
                  className={`text-white rounded mt-32 m-2 py-1 px-6 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                  style={{
                    marginTop: '0rem',
                  }}
                  onClick={() => {
                    this.acceptHandler();
                  }}
                >
                  <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
                </button>
                <button
                  style={{
                    marginTop: '0rem',
                  }}
                  className={`text-white rounded m-2 py-1 px-6 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                  onClick={() => {
                    this.cancelHandler();
                  }}
                >
                  <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />
                </button>
              </div>
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );
  }
}
