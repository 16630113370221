import React, { Component } from 'react';
import IcoMoon from 'react-icomoon';
import JSZip from 'jszip';
import { extractInfoBase64 } from '../../helpers/fileHelper';
import WindowFile from './WindowFile';

const MAX_FILE_SIZE = 10 * 1024 * 1024;

export class AddDocument extends Component {
  constructor() {
    super();
    this.state = {
      fileBase64: '',
      fileName: '',
      fileSize: '',
      fileComment: '',
      loading: false,
    };
    this.handleSearchFile = this.handleSearchFile.bind(this);
    this.inputFileChanged = this.inputFileChanged.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);
  }
  handleSearchFile() {
    let input = this.input;
    input.click();
  }
  handleAccept() {
    let { eventData, addFile } = this.props;
    let { fileName, fileSize, fileBase64, fileComment } = this.state;
    let { groupType, groupNumber } = eventData;
    if (fileName !== '' && fileSize !== '' && fileBase64 !== '') {
      this.setState({ loading: true });
      addFile(groupType, groupNumber, '', fileName, fileSize, fileComment, fileBase64)
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  }
  handleChangeComment(e) {
    this.setState({
      fileComment: e.target.value,
    });
  }
  inputFileChanged(e) {
    let file = e.target.files[0];
    if (window.FileReader && file.size <= MAX_FILE_SIZE) {
      let reader = new window.FileReader();
      let self = this;
      reader.onload = (r) => {
        const valueBase64 = extractInfoBase64(r.target.result);
        if (valueBase64) {
          var zip = new JSZip();
          zip.file(file.name, valueBase64, { base64: true });
          zip.generateAsync({ type: 'base64' }).then((content) => {
            self.setState({
              fileBase64: content,
            });
          });
        }
      };
      reader.readAsDataURL(file);
      this.setState({
        fileName: file.name,
        fileSize: file.size,
      });
    }
  }

  render() {
    let { closeWindow } = this.props;
    const { fileName, fileComment, loading } = this.state;
    return (
      <WindowFile
        fileName={fileName}
        fileComment={fileComment}
        closeWindow={closeWindow}
        handleAccept={this.handleAccept}
        handleChangeComment={this.handleChangeComment}
        loading={loading}
      >
        <button
          className="text-white rounded text-sm ml-2 pl-2 pr-2 bg-grey focus:outline-none focus:bg-primary hover:bg-primary"
          onClick={this.handleSearchFile}
        >
          <IcoMoon icon={'folder-open'} width="18" />
        </button>
        <input
          type="file"
          className="rounded shadow"
          ref={(input) => {
            this.input = input;
          }}
          multiple={false}
          style={{ display: 'none' }}
          onChange={this.inputFileChanged}
        />
      </WindowFile>
    );
  }
}

export default AddDocument;
