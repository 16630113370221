import React from 'react';
import { Parameter } from '../../../../models/parameter';
import { ParamsInformationView } from './ParamsInformationView';

interface ParamsInformationViewModelProps {
  params: Parameter[];
  group: string;
}

export function ParamsInformationViewModel({ params, group }: ParamsInformationViewModelProps): JSX.Element | null {
  return <ParamsInformationView params={params} group={group} />;
}
