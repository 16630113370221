import React, { useState } from 'react';
import { encriptData } from '../../../../../../api/securityManager';
import { EMPTY_STRING, NO_MODAL_OPEN } from '../../../../models/constants';

import { CryptogramModalView } from './CryptogramModalView';

export interface CryptogramModalViewModelProps {
  onModalChange: (modalOpen: string) => void;
}

const WHITESPACE: string = ' ';

export function CryptogramModalViewModel({ onModalChange }: CryptogramModalViewModelProps): JSX.Element | null {
  const [text, setText] = useState<string>(EMPTY_STRING);
  const [encryptedText, setEncryptedText] = useState<string>(EMPTY_STRING);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function onAccept(event: React.MouseEvent): void {
    event.stopPropagation();
    encryptText();
  }

  async function encryptText(): Promise<void> {
    setIsLoading(true);
    let textEncripted: string = EMPTY_STRING;
    const words = text.split(WHITESPACE);
    await Promise.all(
      words.map(async (word) => {
        const codification: string = await encriptData(word, word.length);
        textEncripted = textEncripted.concat(codification);
      }),
    );
    setEncryptedText(textEncripted);
    setIsLoading(false);
  }

  function onCancel(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(NO_MODAL_OPEN);
  }

  function onTextChange(event: { target: HTMLInputElement | HTMLTextAreaElement }): void {
    setText(event.target.value);
  }

  return (
    <CryptogramModalView
      onAccept={onAccept}
      onCancel={onCancel}
      text={text}
      encryptedText={encryptedText}
      onTextChange={onTextChange}
      isLoading={isLoading}
    />
  );
}
