import { generateLineIDString } from '../UI-kit/helpers/operationHelper';

export const getCurrentFocusElement = (state) => {
  const { tabs, current } = state;
  const { running = {} } = tabs[current];
  const { focusElement, focusPaused } = running;

  const focus = focusPaused ? '' : focusElement;

  return focus;
};

export const isCurrentTabEmpty = (state) => {
  const { tabs, current } = state;
  if (tabs[current]) {
    return tabs[current].emptyTab;
  }
  return true;
};

export const getCurrentTabInformation = (state) => {
  const { tabs, current, footerInfo } = state;

  if (!tabs[current]) {
    return {
      tabID: current,
      operationID: null,
      operationNumber: null,
      operationName: '',
      operationData: {},
      operationLook: '',
      focusOn: [],
      focusElement: '',
      lastFocusElement: '',
      focusPaused: false,
      subOperation: false,
      parentOperationID: null,
      parentOperationNumber: null,
      operationCreated: false,
      apiLoading: 0,
    };
  }

  const {
    id,
    running = {},
    operationNumber,
    operationName = '',
    postingNumber,
    inputParameters,
    serverTasks,
    overwritePosition,
    operationLook,
    subOperation,
    parentOperationID,
    parentOperationNumber,
    operationCreated,
    apiLoading,
  } = tabs[current];

  const { operationID, focusOn, focusElement, lastFocusElement, focusPaused } = running;

  // const focusElement = getCurrentFocusElement(state);

  return {
    id,
    tabIndex: tabIndexOf({ tabId: id }, tabs),
    tabID: current, // deprecrated `tabID` key. Use `tabIndex` instead
    operationID,
    operationNumber,
    operationName,
    operationData: running,
    operationLook,
    focusOn,
    focusElement,
    lastFocusElement,
    focusPaused,
    postingNumber,
    subOperation,
    inputParameters,
    serverTasks,
    footerInfo,
    overwritePosition,
    tabs,
    parentOperationID,
    parentOperationNumber,
    operationCreated,
    apiLoading,
  };
};

export const getTabInformation = (state, tabId) => {
  const { tabs, current, footerInfo } = state;

  const tabIndex = tabIndexOf({ tabId }, tabs);

  if (!tabs[tabIndex]) {
    return {
      tabID: current,
      operationID: null,
      operationNumber: null,
      operationName: '',
      operationData: {},
      operationLook: '',
      focusOn: [],
      focusElement: '',
      lastFocusElement: '',
      focusPaused: false,
      subOperation: false,
      parentOperationID: null,
      parentOperationNumber: null,
      operationCreated: false,
      apiLoading: 0,
    };
  }

  const {
    id,
    running = {},
    operationNumber,
    operationName = '',
    postingNumber,
    inputParameters,
    serverTasks,
    overwritePosition,
    operationLook,
    subOperation,
    parentOperationID,
    parentOperationNumber,
    operationCreated,
    apiLoading,
  } = tabs[tabIndex];

  const { operationID, focusOn, focusElement, lastFocusElement, focusPaused } = running;

  // const focusElement = getCurrentFocusElement(state);

  return {
    id,
    tabIndex,
    operationID,
    operationNumber,
    operationName,
    operationData: running,
    operationLook,
    focusOn,
    focusElement,
    lastFocusElement,
    focusPaused,
    postingNumber,
    subOperation,
    inputParameters,
    serverTasks,
    footerInfo,
    overwritePosition,
    tabs,
    parentOperationID,
    parentOperationNumber,
    operationCreated,
    apiLoading,
  };
};

export const getTabIndexByOperationID = (state, operationID, operationNumber) => {
  if (!operationID || !operationNumber) {
    return -1;
  }
  return state.tabs.findIndex(
    (tab) =>
      !tab.hiddenTab &&
      tab.running &&
      tab.running.operationID &&
      parseInt(tab.running.operationID) === parseInt(operationID) &&
      tab.operationNumber &&
      parseInt(tab.operationNumber) === parseInt(operationNumber),
  );
};

export const getCategoriesFromItemType = (state, itemType) => {
  switch (itemType) {
    case 'OPERATIONS':
      return state.operationsData && state.operationsData.categories;
    case 'REPORTS':
      return state.reportsData && state.reportsData.categories;
    default:
      return null;
  }
};

export const getTabsLength = (state) => {
  if (state.currentApp === 'OPERATIONS') {
    return state.tabs.filter((t) => !t.hiddenTab).length;
  } else if (state.currentApp === 'REPORTS') {
    return state.tabsReport.length;
  } else {
    return 0;
  }
};

export const getPostingNumberTab = (state, tabID) => {
  const newState = { ...state };
  newState.tabs = [...state.tabs];

  const currentTab = tabID || state.current;
  let posting;
  if (newState.tabs[currentTab] && newState.tabs[currentTab].postingNumber) {
    posting = newState.tabs[currentTab].postingNumber;
  }

  return posting;
};

/**
 * Get tab index of a tab, given the tabId or a pair with Op. Number and Posting Number
 * @param {Object} options Filter options
 * @param {array} tabs Array of tabs
 */
export const tabIndexOf = ({ tabId = null, opNumber = null, postingNumber = null } = {}, tabs) => {
  if (tabId) {
    return tabs.findIndex((t) => t.id === tabId);
  } else if (opNumber && postingNumber) {
    return tabs.findIndex((t) => parseInt(t.operationNumber) === parseInt(opNumber) && t.postingNumber === postingNumber);
  } else {
    return -1;
  }
};

/**
 * Get Tab ID by Node ID.
 * Each Renderable has it own nodeId.
 * This function returns the ID of the tab that contains the Renderable with the nodeId passed as a parameter.
 * @param {object} state
 * @param {string} nodeId The Node ID of Renderable (Dialog, Grid, Field, etc...)
 */
export const getTabIdByNodeId = (state, nodeId) => {
  const { tabs } = state;
  const tab = tabs.find((tab) => {
    if (!tab.running || !tab.running.trees || !tab.running.trees.length) {
      return false;
    }
    const { trees } = tab.running;
    return trees.some((tree) => {
      if (tree.id === nodeId) {
        return true;
      } else {
        if (tree._childIdsList && tree._childIdsList.length) {
          return tree._childIdsList.some((id) => id === nodeId);
        }
      }
    });
  });
  if (!tab || !tab.id) {
    return '';
  }
  return tab.id;
};

export const getLineIDKeyByDialogNumber = (state, tabIndex, dialogNumber) => {
  const dialog = state.tabs[tabIndex].running.trees.find((tree) => tree._data.number === dialogNumber);
  return generateLineIDString(dialog._data.lineID);
};
