import React from 'react';
import { useIntl } from 'react-intl';

import './WarningModal.scss';

export interface WarningModalViewProps {
  question: string;
  onModalChange: (isAccept: boolean) => void;
}

export function WarningModalView({ question, onModalChange }: WarningModalViewProps): JSX.Element | null {
  const intl = useIntl();

  function onCancelClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(false);
  }

  function onAcceptClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(true);
  }

  return (
    <div className="warning-modal-condition z-10 inset-0 " aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center ">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden " aria-hidden="true">
          &#8203;
        </span>
        <div className="modal-info-condition inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all border shadow-sm">
          <div className="bg-blue-200 px-4 pt-5 pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  {intl.formatMessage({ id: 'processesManager' })}
                </h3>
                <div className="mt-4">
                  <p className="modal-question-condition text-m text-gray-500">{question}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end bg-gray-50 px-4 py-3">
            <button
              onClick={onAcceptClick}
              type="button"
              className="w-24 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium text-gray-700 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:text-sm"
            >
              {intl.formatMessage({ id: 'accept' })}
            </button>
            <button
              onClick={onCancelClick}
              type="button"
              className="mt-3 w-24 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm"
            >
              {intl.formatMessage({ id: 'cancel' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
