import React from 'react';
import { useIntl } from 'react-intl';

interface InformationCommentProps {
  comment: string;
}

export function InformationComment({ comment }: InformationCommentProps): JSX.Element {
  const intl = useIntl();

  return (
    <fieldset className="m-2 rounded border border-light mt-3">
      <legend className="ml-5 mb-4">{intl.formatMessage({ id: 'information' })}</legend>
      {comment.split('\n').map((line) => (
        <div key={line} className="mb-3 ml-3 font-mono text-sm">
          {line}
        </div>
      ))}
    </fieldset>
  );
}
