import React from 'react';
import PropTypes from 'prop-types';
import { iconsComponente, iconsComponenteColor } from '../utils/mapSvgToComponent';
import '../styles/components/CategoryComponent.css';

class CategoryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
  }

  onMouseEnterHandler() {
    this.setState({
      isHovered: true,
    });
  }

  onMouseLeaveHandler() {
    this.setState({
      isHovered: false,
    });
  }

  render() {
    return (
      <div
        className={styles.category}
        onClick={() => this.props.onClick(this.props.category)}
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
      >
        <span className={styles.categoryName}>{this.props.categoryName}</span>

        <span className={styles.categoryIcon}>
          {this.state.isHovered
            ? iconsComponenteColor[this.props.iconoTitulo]
            : iconsComponente[this.props.iconoTitulo]}
        </span>
      </div>
    );
  }
}

CategoryComponent.propTypes = {
  iconoTitulo: PropTypes.number,
  categoryName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
};

export default CategoryComponent;

const styles = {
  category: `
    Category h-28 p-4 pr-5 flex flex-no-wrap bg-grey-lightest border-b border-grey-light-lighter shadow rounded-sm
    relative justify-between items-center select-none cursor-pointer
    hover:shadow-sm hover:bg-white
    focus:shadow-sm focus:bg-white
    active:shadow active:bg-grey-lighter-lighter`,
  categoryName: `text-dark font-normal text-lg font-sans-title mr-5`,
  categoryIcon: `min-w-40 max-w-8xs h-10`,
};
