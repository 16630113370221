import React, { useEffect, useState } from 'react';
import { getGroupReportAsync, getRestrictionReportAsync, getUserReportAsync, getTaskStatusId } from '../../../../../api/securityManager';
import {
  DISABLED_PRINT,
  EMPTY_STRING,
  ENABLED_PRINT,
  FROM_PRINT,
  INITIAL_FROM_PRINT,
  INITIAL_TO_PRINT,
  NO_MODAL_OPEN,
  PRINT_GROUPS,
  PRINT_PDF_FILE_EXTENSION,
  PRINT_RESTRICTIONS,
  PRINT_USERS,
  RADIO_TYPE,
  SORT_GROUP,
  SORT_GROUP_INDEX,
  SORT_INITIALS,
  SORT_INITIALS_INDEX,
  SORT_NAME,
  SORT_NAME_INDEX,
  SORT_NONE_INDEX,
  SORT_SECTOR,
  SORT_SECTOR_INDEX,
  TITLE_PRINT,
  TO_PRINT,
  MENU_KEY,
} from '../../../models/constants';

import '../SecurityToolsModal.scss';
import { GroupHelper } from '../SecurityToolsModalViewModel';
import { PrintModalView } from './PrintModalView';

interface Report {
  fileDataBase64: any;
  fileExtension: string;
  fileName: string;
  fullFileName: string;
  name: string;
  serverPath: string;
}

export interface PrintViewModelProps {
  groups: GroupHelper[];
  onModalChange: (modalOpen: string) => void;
}

const REPORT_TASK_MAX_WAIT = 30000;
const REPORT_TASK_INIT_WAIT = 1000;
const REPORT_TASK_WAIT_INCREASE_FACTOR = 1.1;
const REPORT_TASK_COMPLETED = 'COMPLETED';
const REPORT_TASK_FAILED = 'FAILED';

export function PrintModalViewModel({ groups, onModalChange }: PrintViewModelProps): JSX.Element | null {
  const [printType, setPrintType] = useState<string>(PRINT_USERS);
  const [sortBy, setSortBy] = useState<string>(SORT_GROUP);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [includeTitle, setIncludeTitle] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(EMPTY_STRING);
  const [from, setFrom] = useState<number>(INITIAL_FROM_PRINT);
  const [to, setTo] = useState<number>(INITIAL_TO_PRINT);
  const [groupsSelected, setGroupsSelected] = useState<string[]>([]);
  const [optionsSelected, setOptionsSelected] = useState<string[]>([]);
  const [showPDF, setShowPDF] = useState<boolean>(false);
  const [srcReport, setSrcReport] = useState<string>(EMPTY_STRING);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportTaskId, setReportTaskId] = useState<string>();
  const [reportTaskError, setReportTaskError] = useState<string>('');
  const [reportTimeoutId, setReportTimeoutId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (groups?.length) setGroupsSelected([...groupsSelected, groups[0].group]);
  }, []);

  useEffect(() => {
    return () => {
      if (reportTimeoutId) {
        clearTimeout(reportTimeoutId);
      }
    };
  }, [reportTimeoutId]);

  function onCancelModal(): void {
    onModalChange(NO_MODAL_OPEN);
  }

  function startReportTaskLoop(reportData: any, wait?: number): void {
    if (reportData.taskId) {
      setReportTaskId(reportData.taskId);
      const newWait = wait ? wait : REPORT_TASK_INIT_WAIT;
      const repTimeout = setTimeout((waitTimeout) => {
        handleReportTaskLoop(reportData.taskId, waitTimeout > REPORT_TASK_MAX_WAIT ? REPORT_TASK_MAX_WAIT : waitTimeout);
      }, newWait, newWait * REPORT_TASK_WAIT_INCREASE_FACTOR);
      setReportTimeoutId(repTimeout);
    }
  }

  const handleReportTaskLoop = (taskId: any, newWait: number) => {
    getTaskStatusId(taskId).then((response) => {
      if (response.status === REPORT_TASK_COMPLETED) {
        handleReportTaskResponse(response.result);
      } else if (response.status === REPORT_TASK_FAILED) {
        setReportTaskError(response.details);
        setShowPDF(false);
        setIsLoading(false);
      } else {
        startReportTaskLoop({ taskId }, newWait);
      }
    });
  }

  const handleReportTaskResponse = (reportData: any) => {
    setSrcReport(reportData.fileName + PRINT_PDF_FILE_EXTENSION);
    setShowPDF(true);
    setIsLoading(false);
  }

  async function onAcceptModal(): Promise<void> {
    setIsLoading(true);
    setReportTaskError('');

    if (printType === PRINT_USERS) {
      getUserReportAsync(title, getSortByType(), groupsSelected).then(startReportTaskLoop);
    } else if (printType === PRINT_GROUPS) {
      getGroupReportAsync(title, groupsSelected, optionsSelected, enabled, disabled).then(startReportTaskLoop);
    } else if (printType === PRINT_RESTRICTIONS) {
      getRestrictionReportAsync(title, enabled, disabled, from, to, groupsSelected, optionsSelected).then(startReportTaskLoop);
    } else {
      setShowPDF(true);
      setIsLoading(false);
    }
  }

  function getSortByType(): number {
    if (sortBy === SORT_GROUP) {
      return SORT_GROUP_INDEX;
    } else if (sortBy === SORT_NAME) {
      return SORT_NAME_INDEX;
    } else if (sortBy === SORT_INITIALS) {
      return SORT_INITIALS_INDEX;
    } else if (sortBy === SORT_SECTOR) {
      return SORT_SECTOR_INDEX;
    } else {
      return SORT_NONE_INDEX;
    }
  }

  function onRadioChange(radio: string, value: string): void {
    if (radio === RADIO_TYPE) {
      setPrintType(value);
      if (value === PRINT_GROUPS || value === PRINT_RESTRICTIONS) {
        setOptionsSelected([...optionsSelected, MENU_KEY]);
      }
    } else {
      setSortBy(value);
    }
  }

  function onStringChange(name: string, value: string | number): void {
    if (name === TITLE_PRINT) {
      setTitle(value as string);
    } else if (name === FROM_PRINT) {
      setFrom(value as number);
    } else if (name === TO_PRINT) {
      setTo(value as number);
    }
  }

  function onCheckboxChange(checkbox: string): void {
    if (checkbox === TITLE_PRINT) {
      setIncludeTitle(!includeTitle);
      emptyTitle();
    } else if (checkbox === ENABLED_PRINT) {
      setEnabled(!enabled);
    } else if (checkbox === DISABLED_PRINT) {
      setDisabled(!disabled);
    }
  }

  function emptyTitle(): void {
    if (!includeTitle) {
      setTitle(EMPTY_STRING);
    }
  }

  function onGroupsChange(group: string): void {
    if (groupsSelected.includes(group)) {
      setGroupsSelected(
        groupsSelected.filter((groupSelected) => {
          return groupSelected !== group;
        }),
      );
    } else {
      setGroupsSelected([...groupsSelected, group]);
    }
  }

  function onOptionsChange(option: string): void {
    if (optionsSelected.includes(option)) {
      setOptionsSelected(
        optionsSelected.filter((optionSelected) => {
          return optionSelected !== option;
        }),
      );
    } else {
      setOptionsSelected([...optionsSelected, option]);
    }
  }

  function onPDFChange(isOpen: boolean): void {
    setShowPDF(isOpen);
  }

  return (
    <PrintModalView
      groups={groups}
      onCancel={onCancelModal}
      onAccept={onAcceptModal}
      onRadioChange={onRadioChange}
      onCheckboxChange={onCheckboxChange}
      onGroupsChange={onGroupsChange}
      onOptionsChange={onOptionsChange}
      includeTitle={includeTitle}
      groupsSelected={groupsSelected}
      optionsSelected={optionsSelected}
      printType={printType}
      onStringChange={onStringChange}
      title={title}
      onPDFChange={onPDFChange}
      showPDF={showPDF}
      srcReport={srcReport}
      isLoading={isLoading}
      sortBy={sortBy}
      enabled={enabled}
      disabled={disabled}
      errorMessage={reportTaskError}
      setErrorMessage={(err:any) => setReportTaskError(err)}
    />
  );
}
