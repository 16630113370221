import React, { Component } from 'react';
import ReactTable from 'react-table';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';
import { injectIntl, FormattedMessage } from 'react-intl';
import FocusTrap from 'react-focus-trap';
import { AddDocument, ChangeDocument } from './OperationsDocuments';
import ConfirmSimpleDialog from './ConfirmSimpleDialog';
import { PulseLoader } from 'halogenium';
import { ABOVE_ALL } from '../constants/zIndexValues';
import Dialog from './Dialog';
import Overlay from './Overlay';

const Button = ({ title, click, className, disabled = false }) => {
  return (
    <button
      className={`${className} text-white rounded shadow m-2 py-1 px-4 text-sm bg-grey focus:outline-none focus:bg-primary ${
        disabled ? '' : 'hover:bg-primary'
      }`}
      onClick={click}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export class DocumentManagerWindow extends Component {
  render() {
    const heightContain = 290;
    const gridStyle = { height: heightContain, marginTop: 5 };
    let {
      eventData,
      showWindow,
      rows,
      selectedIndex,
      showDialog,
      dialogMessage,
      dialogTitle,
      showAdd,
      showRemove,
      showChange,
      loading,
      selectRow,
      changeThumbnail,
      handleAdd,
      handleChange,
      handleShowRemove,
      handleGetFile,
      handleGetAllFiles,
      toggleWindow,
      hideDialog,
      addFile,
      handleRemove,
      changeFile,
      intl,
      thumbnail,
    } = this.props;
    let { groupType, groupNumber, posibilidadesDelUsuario } = eventData;
    let disableAgregar = false;
    let disableEliminar = false;
    let disableEditar = false;
    if (posibilidadesDelUsuario === 1) {
      disableAgregar = true;
      disableEditar = true;
      disableEliminar = true;
    } else if (posibilidadesDelUsuario === 2) {
      disableAgregar = false;
      disableEditar = true;
      disableEliminar = true;
    }
    const columsData = [
      { accessor: 'id', Header: 'Id' },
      {
        accessor: 'name',
        Header: intl.formatMessage({ id: 'name', defaultMessage: 'Nombre' }),
      },
      {
        accessor: 'size',
        Header: intl.formatMessage({ id: 'size', defaultMessage: 'Tamaño' }),
      },
      {
        accessor: 'dateTime',
        Header: intl.formatMessage({ id: 'date', defaultMessage: 'Fecha' }),
      },
      {
        accessor: 'comment',
        Header: intl.formatMessage({
          id: 'description',
          defaultMessage: 'Descripción',
        }),
      },
    ];
    return (
      <FocusTrap className={`${showWindow ? '' : 'hidden'}`}>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className={`border border-solid ${
              showDialog || showAdd || showRemove || showChange ? 'border-transparent' : 'border-primary'
            } bg-grey-lighter-lighter rounded shadow absolute`}
            style={{ zIndex: ABOVE_ALL, width: 700 }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move m-1 text-xs" />
            <div className="flex">
              <div className="w-full">
                <div className="flex-auto text-left p-2 text-lg">
                  <div className="mb-4 text-center text-base text-primary">
                    <FormattedMessage id="documentManager" defaultMessage={`Administración de Documentos`} />
                  </div>
                  <div className="flex items-center my-3">
                    <label className="text-sm">Grupo:</label>
                    <input type="text" className="ml-4 text-sm w-24 p-2 rounded shadow" value={groupType} readOnly={true} disabled={true} />
                    <input type="text" className="ml-4 text-sm w-32 p-2 rounded shadow" value={groupNumber} readOnly={true} disabled={true} />

                    <div
                      className={'border border-solid border-primary'}
                      style={{
                        width: 128,
                        height: 128,
                        marginLeft: '14rem',
                        objectFit: 'fill',
                      }}
                    >
                      {thumbnail && <img style={{ height: '100%' }} src={`data:image/png;base64,${thumbnail}`} />}
                    </div>

                    {loading && (
                      <div className="w-full flex justify-end">
                        <PulseLoader className="text-primary mr-6" color="currentColor" size="8px" margin="1px" />
                      </div>
                    )}
                  </div>
                  <div className="flex w-full">
                    <ReactTable
                      data={rows}
                      columns={columsData}
                      style={gridStyle}
                      defaultPageSize={10}
                      className="-striped -highlight w-3/4 bg-grey-lighter-lighter"
                      noDataText={
                        <p className="text-text-base" style={{ fontSize: 11 }}>
                          <FormattedMessage id="noData" defaultMessage={`No hay datos`} />
                        </p>
                      }
                      getTrProps={(state, rowInfo, column, instance) => {
                        if (typeof rowInfo !== 'undefined') {
                          return {
                            onClick: async (e, handleOriginal) => {
                              selectRow(rowInfo.index);
                              changeThumbnail(rowInfo);
                              if (handleOriginal) handleOriginal();
                            },
                            style: {
                              background: rowInfo.index === selectedIndex ? '#a8a8a8' : '',
                            },
                          };
                        } else {
                          return {
                            onClick: (e, handleOriginal) => {
                              if (handleOriginal) handleOriginal();
                            },
                            style: { background: 'white', color: 'black' },
                          };
                        }
                      }}
                    />
                    <div className="w-1/4 flex flex-col justify-center" style={{ height: heightContain }}>
                      <Button
                        title={intl.formatMessage({
                          id: 'add',
                          defaultMessage: 'Agregar',
                        })}
                        click={handleAdd}
                        disabled={disableAgregar}
                      />
                      <Button
                        title={intl.formatMessage({
                          id: 'edit',
                          defaultMessage: 'Editar',
                        })}
                        click={handleChange}
                        disabled={disableEditar}
                      />
                      <Button
                        title={intl.formatMessage({
                          id: 'remove',
                          defaultMessage: 'Eliminar',
                        })}
                        click={handleShowRemove}
                        disabled={disableEliminar}
                      />
                      <Button
                        title={intl.formatMessage({
                          id: 'obtain',
                          defaultMessage: 'Descargar',
                        })}
                        click={() => {
                          handleGetFile(true);
                        }}
                      />
                      <Button
                        title={intl.formatMessage({
                          id: 'obtainAll',
                          defaultMessage: 'Descargar todos',
                        })}
                        click={() => {
                          handleGetAllFiles(true);
                        }}
                      />
                      <Button
                        title={intl.formatMessage({
                          id: 'open',
                          defaultMessage: 'Abrir',
                        })}
                        click={() => {
                          handleGetFile(false);
                        }}
                      />
                      <Button
                        title={intl.formatMessage({
                          id: 'close',
                          defaultMessage: 'Cerrar',
                        })}
                        click={toggleWindow}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
        {showDialog && <Dialog hideDialog={hideDialog} message={dialogMessage} dialogTitle={dialogTitle} />}
        {showAdd && <AddDocument closeWindow={handleAdd} addFile={addFile} eventData={eventData} />}
        {showRemove && (
          <ConfirmSimpleDialog
            title={intl.formatMessage({
              id: 'thisActionRemoveDocumentContinue',
              defaultMessage: 'Esta acción eliminará el documento del servidor. ¿Desea continuar?',
            })}
            handleAccept={handleRemove}
            handleCancel={handleShowRemove}
          />
        )}
        {showChange && <ChangeDocument fileSelected={rows[selectedIndex]} closeWindow={handleChange} changeFile={changeFile} />}
      </FocusTrap>
    );
  }
}

export default injectIntl(DocumentManagerWindow);
