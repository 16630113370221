import { PulseLoader } from 'halogenium';
import React from 'react';
import { useIntl } from 'react-intl';
import { BASE_API } from '../../../../../config';
import { GroupHelper } from '../SecurityToolsModalViewModel';
import ModalMessage from '../../../../ModalMessage';
import groupIcon from '../../../icons/group';
import printIcon from '../../../icons/print';
import {
  DISABLED_PRINT,
  ENABLED_PRINT,
  FROM_PRINT,
  MENU_KEY,
  OPERATIONS_KEY,
  PERMISSIONS_KEY,
  PRINT_GROUPS,
  PRINT_RESTRICTIONS,
  PRINT_USERS,
  REPORTS_KEY,
  SORT_GROUP,
  SORT_INITIALS,
  SORT_NAME,
  SORT_SECTOR,
  TITLE_PRINT,
  TO_PRINT,
} from '../../../models/constants';

import '../SecurityToolsModal.scss';
import './PrintModal.scss';

export interface PrintViewProps {
  groups: GroupHelper[];
  includeTitle: boolean;
  title: string;
  groupsSelected: string[];
  optionsSelected: string[];
  printType: string;
  onCancel: () => void;
  onAccept: () => void;
  onRadioChange: (radio: string, value: string) => void;
  onCheckboxChange: (checkbox: string) => void;
  onGroupsChange: (group: string) => void;
  onOptionsChange: (option: string) => void;
  onStringChange: (name: string, value: string | number) => void;
  showPDF: boolean;
  onPDFChange: (isOpen: boolean) => void;
  srcReport: string;
  isLoading: boolean;
  sortBy: string;
  enabled: boolean;
  disabled: boolean;
  errorMessage: string;
  setErrorMessage: (err: any) => void;
}

const URL_BASE_FORMS = `${BASE_API}/webclient/reports/`;

export function PrintModalView({
  groups,
  onCancel,
  onAccept,
  onRadioChange,
  onCheckboxChange,
  onGroupsChange,
  onOptionsChange,
  includeTitle,
  groupsSelected,
  optionsSelected,
  printType,
  onStringChange,
  title,
  showPDF,
  onPDFChange,
  srcReport,
  isLoading,
  sortBy,
  enabled,
  disabled,
  errorMessage,
  setErrorMessage,
}: PrintViewProps): JSX.Element | null {
  const intl = useIntl();

  function onCancelClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onCancel();
  }

  function onAddClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onAccept();
  }

  function onRadioButtonChange(event: { target: HTMLInputElement }): void {
    onRadioChange(event.target.name, event.target.value);
  }

  function onCheckedChange(event: { target: HTMLInputElement }): void {
    onCheckboxChange(event.target.name);
  }

  function onInputChange(): (event: { target: HTMLInputElement }) => void {
    return (event) => {
      onStringChange(event.target.name, event.target.value);
    };
  }

  function onGroupSelection(event): void {
    onGroupsChange(event.target.name);
  }

  function onOptionSelection(event): void {
    onOptionsChange(event.target.name);
  }

  function onAcceptModalMessage(): void {
    onPDFChange(false);
  }

  return (
    <div className="font-mono flex" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center full-width">
        <div className="fixed" aria-hidden="true"></div>
        <span className="hidden" aria-hidden="true">
          &#8203;
        </span>
        {isLoading ? (
          <div className="loader absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
            <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
          </div>
        ) : (
          <div className="relative bg-white rounded-lg shadow-xl border shadow-sm user-info-content">
            <div className="bg-blue-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="flex flex-col w-full">
                  <h3 className="flex items-center text-lg leading-6 font-medium text-gray-900 m-2 mb-6" id="modal-title">
                    {printIcon} {intl.formatMessage({ id: 'print' })} {intl.formatMessage({ id: 'usersAndGroups' })}
                  </h3>
                  {showPDF && (
                    <ModalMessage
                      message={<embed className="w-screen h-full" src={`${URL_BASE_FORMS}${srcReport}`} title="Reporte" />}
                      handleAccept={onAcceptModalMessage}
                      acceptTitle={intl.formatMessage({
                        id: 'close',
                        defaultMessage: 'Cerrar',
                      })}
                      isReport={true}
                      largeModal={true}
                      closeOutside={true}
                      draggable={true}
                    />
                  )}
                  <fieldset className="rounded border border-light flex relative m-2 mt-4 w-full" style={{ border: '1px solid grey' }}>
                    <legend>{intl.formatMessage({ id: 'configuration' })}</legend>
                    <div className="flex flex-col w-full">
                      <div className="flex flex-row">
                        <fieldset className="flex flex-col rounded border border-light flex relative m-2 mt-4 w-1/2">
                          <div className="flex flex-row ml-3 mt-3">
                            <input
                              onChange={onRadioButtonChange}
                              className="field-checkbox-user mr-2 ml-2"
                              type="radio"
                              value={PRINT_USERS}
                              name="type"
                              checked={printType === PRINT_USERS}
                            />
                            {intl.formatMessage({ id: 'users' })}
                          </div>
                          <div className="flex flex-row ml-3 mt-3">
                            <input
                              onChange={onRadioButtonChange}
                              className="field-checkbox-user mr-2 ml-2"
                              type="radio"
                              value={PRINT_GROUPS}
                              name="type"
                              checked={printType === PRINT_GROUPS}
                            />
                            {intl.formatMessage({ id: 'groups' })}
                          </div>
                          <div className="flex flex-row ml-3 mt-3">
                            <input
                              onChange={onRadioButtonChange}
                              className="field-checkbox-user mr-2 ml-2"
                              type="radio"
                              value={PRINT_RESTRICTIONS}
                              name="type"
                              checked={printType === PRINT_RESTRICTIONS}
                            />
                            {intl.formatMessage({ id: 'restrictions' })}
                          </div>
                        </fieldset>
                        <fieldset className="flex flex-col rounded border border-light flex relative m-2 mt-4 w-1/2">
                          <div className="flex flex-row ml-3 mt-3">
                            <input
                              disabled={printType !== PRINT_USERS}
                              onChange={onRadioButtonChange}
                              className="field-checkbox-user mr-2 ml-2"
                              type="radio"
                              value={SORT_GROUP}
                              name="sortBy"
                              checked={sortBy === SORT_GROUP}
                            />
                            {intl.formatMessage({ id: 'by' })} {intl.formatMessage({ id: 'group' })}
                          </div>
                          <div className="flex flex-row ml-3 mt-3">
                            <input
                              disabled={printType !== PRINT_USERS}
                              onChange={onRadioButtonChange}
                              className="field-checkbox-user mr-2 ml-2"
                              type="radio"
                              value={SORT_NAME}
                              name="sortBy"
                              checked={sortBy === SORT_NAME}
                            />
                            {intl.formatMessage({ id: 'by' })} {intl.formatMessage({ id: 'name' })}
                          </div>
                          <div className="flex flex-row ml-3 mt-3">
                            <input
                              disabled={printType !== PRINT_USERS}
                              onChange={onRadioButtonChange}
                              className="field-checkbox-user mr-2 ml-2"
                              type="radio"
                              value={SORT_INITIALS}
                              name="sortBy"
                              checked={sortBy === SORT_INITIALS}
                            />
                            {intl.formatMessage({ id: 'by' })} {intl.formatMessage({ id: 'initials' })}
                          </div>
                          <div className="flex flex-row ml-3 mt-3 mb-3">
                            <input
                              disabled={printType !== PRINT_USERS}
                              onChange={onRadioButtonChange}
                              className="field-checkbox-user mr-2 ml-2"
                              type="radio"
                              value={SORT_SECTOR}
                              name="sortBy"
                              checked={sortBy === SORT_SECTOR}
                            />
                            {intl.formatMessage({ id: 'by' })} {intl.formatMessage({ id: 'sector' })}
                          </div>
                        </fieldset>
                      </div>
                      <fieldset className="flex flex-col rounded border border-light flex relative m-2 mt-4">
                        <div className="flex flex-row m-3">
                          <input className="field-checkbox-user mr-2 ml-2" type="checkbox" name={TITLE_PRINT} onChange={onCheckedChange} />
                          {intl.formatMessage({ id: 'includeTitle' })}
                        </div>
                        <input value={title} disabled={!includeTitle} className="rounded border m-4" onChange={onInputChange()} name={TITLE_PRINT} />
                      </fieldset>
                      <fieldset className="flex flex-row rounded border border-light relative m-2 mt-4 p-4" style={{ maxHeight: '300px' }}>
                        <div key="groups" className="print-list flex flex-col rounded-lg border bg-grey-lighter-lighter w-1/3 overflow-y-scroll">
                          {groups.map((group) => {
                            return (
                              <button
                                onClick={onGroupSelection}
                                name={group.group}
                                key={group.group}
                                className={`${groupsSelected.includes(group.group) && 'bg-grey-light'} flex flex-row items-center p-2`}
                              >
                                {groupIcon}
                                {group.group}
                              </button>
                            );
                          })}
                        </div>
                        <div
                          key="options"
                          className="print-list flex flex-col rounded-lg border bg-grey-lighter-lighter w-1/3 ml-3 items-start overflow-y-scroll"
                        >
                          <button
                            className={`${optionsSelected.includes(MENU_KEY) && 'bg-grey-light'} options-item`}
                            onClick={onOptionSelection}
                            disabled={printType === PRINT_USERS}
                            key={MENU_KEY}
                            name={MENU_KEY}
                          >
                            {intl.formatMessage({ id: 'menu' })}
                          </button>
                          <button
                            key={OPERATIONS_KEY}
                            className={`${optionsSelected.includes(OPERATIONS_KEY) && 'bg-grey-light'} options-item`}
                            onClick={onOptionSelection}
                            disabled={printType === PRINT_USERS}
                            name={OPERATIONS_KEY}
                          >
                            {intl.formatMessage({ id: 'operations' })}
                          </button>
                          <button
                            className={`${optionsSelected.includes(PERMISSIONS_KEY) && 'bg-grey-light'} options-item`}
                            onClick={onOptionSelection}
                            disabled={printType === PRINT_USERS}
                            key={PERMISSIONS_KEY}
                            name={PERMISSIONS_KEY}
                          >
                            {intl.formatMessage({ id: 'permissions' })}
                          </button>
                          <button
                            className={`${optionsSelected.includes(REPORTS_KEY) && 'bg-grey-light'} options-item`}
                            onClick={onOptionSelection}
                            disabled={printType === PRINT_USERS}
                            key={REPORTS_KEY}
                            name={REPORTS_KEY}
                          >
                            {intl.formatMessage({ id: 'reportsSidebar' })}
                          </button>
                        </div>
                        <div className="print-option flex flex-col w-1/3">
                          <div className="ml-2 flex flex-row mt-3">
                            <input
                              disabled={printType === PRINT_USERS}
                              className="field-checkbox-user mr-2"
                              type="checkbox"
                              name={ENABLED_PRINT}
                              onChange={onCheckedChange}
                              defaultChecked={enabled}
                            />
                            {intl.formatMessage({ id: 'enabledPrint' })}
                          </div>
                          <div className="ml-2 flex flex-row mt-3">
                            <input
                              disabled={printType === PRINT_USERS}
                              className="field-checkbox-user mr-2"
                              type="checkbox"
                              name={DISABLED_PRINT}
                              onChange={onCheckedChange}
                              defaultChecked={disabled}
                            />
                            {intl.formatMessage({ id: 'disabledPrint' })}
                          </div>
                          <div className="ml-2 mt-3">Tit(nnnn)+Id(nnnn)</div>
                          <div className="flex flex-row ml-2 mt-3">
                            <label style={{ minWidth: '35px' }}>{intl.formatMessage({ id: 'from' })}</label>
                            <input
                              disabled={printType !== PRINT_RESTRICTIONS}
                              className="rounded border mr-2 ml-3"
                              style={{ width: '130px' }}
                              name={FROM_PRINT}
                              onChange={onInputChange()}
                            />
                          </div>
                          <div className="flex flex-row ml-2 mt-3">
                            <label style={{ minWidth: '35px' }}>{intl.formatMessage({ id: 'to' })}</label>
                            <input
                              disabled={printType !== PRINT_RESTRICTIONS}
                              className="input rounded border mr-2 ml-3"
                              name={TO_PRINT}
                              style={{ width: '130px' }}
                              onChange={onInputChange()}
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            {errorMessage && (
              <ModalMessage
                message={<div className="text-red-500 text-center">{errorMessage}</div>}
                handleAccept={() => setErrorMessage('')}
                acceptTitle={intl.formatMessage({
                  id: 'close',
                  defaultMessage: 'Cerrar',
                })}
                closeOutside={true}
                draggable={true}
              />
            )}
            <div className="w-full flex justify-end">
              <button
                type="button"
                onClick={onAddClick}
                className="mb-3 mt-3 w-24 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm"
              >
                {intl.formatMessage({ id: 'accept' })}
              </button>
              <button
                type="button"
                onClick={onCancelClick}
                className="mb-3 mr-3 mt-3 w-24 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm"
              >
                {intl.formatMessage({ id: 'cancel' })}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
