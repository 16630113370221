import React, { useState } from 'react';
import urlencode from 'urlencode';

import { EMPTY_STRING } from '../../../../models/constants';
import { encryptAES } from '../../../../../../utils/aes';

import './LdapModal.scss';
import { LDAPModalView } from './LDAPModalView';

export interface LDAPModalViewModelProps {
  onCancel: (isOpen: boolean) => void;
  onAccept: (user: string, password: string) => void;
}

export function LDAPModalViewModel({ onCancel, onAccept }: LDAPModalViewModelProps): JSX.Element | null {
  const [user, setUser] = useState<string>(EMPTY_STRING);
  const [password, setPassword] = useState<string>(EMPTY_STRING);
  const [remember, setRemember] = useState<boolean>(false);

  function onCancelClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onCancel(false);
  }

  function onAcceptClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onCancel(false);
    const encodedPwd = urlencode(encryptAES(password).base64);
    onAccept(user, encodedPwd);
  }

  function onUserChange(event: { target: HTMLInputElement }): void {
    setUser(event.target.value);
  }

  function onPasswordChange(event: { target: HTMLInputElement }): void {
    setPassword(event.target.value);
  }

  function onRememberCredentialsCheck(event: { target: HTMLInputElement }): void {
    setRemember(!remember);
  }

  return (
    <LDAPModalView
      onPasswordChange={onPasswordChange}
      onUserChange={onUserChange}
      onCancel={onCancelClick}
      onAccept={onAcceptClick}
      onRememberCredentialsCheck={onRememberCredentialsCheck}
    />
  );
}
