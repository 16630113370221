import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { useTheme } from '../../themes/ThemeContext'

const NotificationView = props => {
  const { value, collapsed } = props
  const themeState = useTheme();

  const { color, error } = themeState.theme;

  const styles = StyleSheet.create({
    circle: {
      display: 'flex',
      borderRadius: '50%',
      width: 18,
      height: 18,
      backgroundColor: error.error,
      boxSizing: 'content-box',
      justifyContent: 'center',
      alignItems: 'center',
      color: color.white
    },
    point: {
      display: 'flex',
      borderRadius: '50%',
      width: 8,
      left: '-5px',
      bottom: '9px',
      position: 'relative',
      height: 8,
      backgroundColor: error.error,
      boxSizing: 'content-box',
    },
    text: {
      fontSize: '13px',
    },
  })

  return (
    <div className={css(collapsed ? styles.point : styles.circle)} title={value}>
      {
        !collapsed &&
        <div className={css(styles.text)}>
          { value }
        </div>
      }
    </div>
  );
};

NotificationView.propTypes = {
  value: PropTypes.number.isRequired,
  collapsed: PropTypes.bool.isRequired,
};

export default NotificationView;
