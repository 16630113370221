import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getGroupScheduleAsync, reloadProcessManagerAsync, storeGroupScheduleAsync } from '../../../../api/processesManager';
import { SelectedProcess } from '../../models/types';
import { SelectData } from '../select/Select';
import { ScheduleModalView } from './ScheduleModalView';

export interface ScheduleGroup {
  beginDateTime: string;
  dayOfMonth: number;
  dayOfWeek: number;
  endDateTime: string;
  ever: boolean;
  holidays: boolean;
  idFrecuency: number;
  interval: number;
  processName: string;
  retriesCount: number;
  retriesInterval: number;
  saturday: boolean;
  sunday: boolean;
  type: number;
  week: number;
  nextDateTimeExecution: string;
  [key: string]: boolean | number | string;
}

interface ScheduleModalViewModelProps {
  selectedProcess?: SelectedProcess;
  onModalScheduleChange: (isOpenModal: boolean) => void;
}

const ADD_SCHEDULE: number = 0;
const REMOVE_SCHEDULE: number = 1;
const EDIT_SCHEDULE: number = 2;
const SECONDS_FRECUENCY: number = 1;
const MINUTES_FRECUENCY: number = 2;
const HOURS_FRECUENCY: number = 3;
const DAYS_FRECUENCY: number = 4;
const MONTHS_FRECUENCY: number = 5;
const FIRST_ELEMENT_LIST: number = 1;
const ID_FRECUENCY: string = 'idFrecuency';
const RETRIES_INTERVAL: string = 'retriesInterval';
const RETRIES_COUNT: string = 'retriesCount';
const DATE_INPUT: string = 'date';
const TIME_INPUT: string = 'time';
const DATE_FORMAT: string = 'YYYY-MM-DD';
const TIME_FORMAT: string = 'HH:mm:ss';

export function ScheduleModalViewModel({ onModalScheduleChange, selectedProcess }: ScheduleModalViewModelProps): JSX.Element {
  const [scheduleInfo, setScheduleInfo] = useState<ScheduleGroup[]>([]);
  const [scheduleSelected, setScheduleSelected] = useState<ScheduleGroup>(scheduleInfo[0]);
  const [idOptions, setIdOptions] = useState<SelectData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');
  const intervalOptions: SelectData[] = loadIntervalOptions();
  const intl = useIntl();
  const frecuencyOptions: SelectData[] = [
    {
      value: SECONDS_FRECUENCY,
      label: intl.formatMessage({ id: 'seconds' }),
    },
    {
      value: MINUTES_FRECUENCY,
      label: intl.formatMessage({ id: 'minutes' }),
    },
    {
      value: HOURS_FRECUENCY,
      label: intl.formatMessage({ id: 'hours' }),
    },
    {
      value: DAYS_FRECUENCY,
      label: intl.formatMessage({ id: 'days' }),
    },
    {
      value: MONTHS_FRECUENCY,
      label: intl.formatMessage({ id: 'months' }),
    },
  ];

  function loadIntervalOptions(): SelectData[] {
    const dateDays: SelectData[] = [];
    for (let index: number = 1; index <= 59; index++) {
      dateDays.push({
        value: index,
        label: `${index}`,
      });
    }

    return dateDays;
  }

  async function loadScheduleGroupInfo(): Promise<void> {
    setIsLoading(true);
    await reloadProcessManagerAsync();
    const groupSchedules = await getGroupScheduleAsync(selectedProcess?.name);
    loadNewScheduleInformation(groupSchedules);
    setIsLoading(false);
  }

  function loadNewScheduleInformation(groupSchedules: ScheduleGroup[]): void {
    setScheduleInfo(groupSchedules);
    loadIdOptions(groupSchedules);
    loadSelectedSchedule(groupSchedules);
  }

  function loadSelectedSchedule(groupSchedules: ScheduleGroup[]): void {
    if (groupSchedules.length === 0) {
      loadNewScheduleGroup();
    } else {
      setSelectedInformation(groupSchedules[0]);
    }
  }

  function setSelectedInformation(groupSchedule: ScheduleGroup): void {
    setScheduleSelected(groupSchedule);
    if (groupSchedule?.nextDateTimeExecution) {
      updateDateAndTime(groupSchedule.nextDateTimeExecution);
    } else {
      setSelectedDate('');
      setSelectedTime('');
    }
  }

  function updateDateAndTime(dateAndTime: string): void {
    const calendarDate = moment(dateAndTime);
    setSelectedDate(calendarDate.format(DATE_FORMAT));
    setSelectedTime(calendarDate.format(TIME_FORMAT));
  }

  function loadNewScheduleGroup(): void {
    const newSchedule: ScheduleGroup = {
      beginDateTime: '',
      dayOfMonth: 0,
      dayOfWeek: 0,
      endDateTime: '',
      ever: false,
      holidays: false,
      idFrecuency: FIRST_ELEMENT_LIST,
      interval: 1,
      processName: selectedProcess?.name || '',
      retriesCount: 0,
      retriesInterval: 0,
      saturday: false,
      sunday: false,
      type: SECONDS_FRECUENCY,
      week: 0,
      nextDateTimeExecution: '',
    };

    setScheduleInfo([newSchedule]);
    setScheduleSelected(newSchedule);
  }

  function loadIdOptions(schedules: ScheduleGroup[]): void {
    const optionIds = schedules?.map((id) => {
      return {
        value: id.idFrecuency,
        label: id.idFrecuency.toString(),
      };
    });

    setIdOptions(optionIds);
  }

  function onCancel(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalScheduleChange(false);
  }

  function onAccept(event: React.MouseEvent): void {
    event.stopPropagation();
    let newSchedule: ScheduleGroup = scheduleSelected;
    setNewIdToSchedule(newSchedule);
    storeSchedule(ADD_SCHEDULE, newSchedule);
  }

  function setNewIdToSchedule(newSchedule: ScheduleGroup): void {
    const newId: number = createNewId();
    newSchedule.idFrecuency = newId;
    setScheduleSelected(newSchedule);
  }

  function createNewId(): number {
    let newId: number = 1;
    let foundNewId: boolean = false;
    while (!foundNewId) {
      const existsId: boolean = alreadyExistsId(newId);
      if (!existsId) {
        foundNewId = true;
      } else {
        newId++;
      }
    }

    return newId;
  }

  function alreadyExistsId(id: number): boolean {
    const exists: boolean = scheduleInfo.some((schedule) => schedule.idFrecuency === id);

    return exists;
  }

  function onRemove(event: React.MouseEvent): void {
    event.stopPropagation();
    storeSchedule(REMOVE_SCHEDULE);
  }

  function onEdit(event: React.MouseEvent): void {
    event.stopPropagation();
    storeSchedule(EDIT_SCHEDULE);
  }

  async function storeSchedule(state: number, scheduleDefinition?: ScheduleGroup): Promise<void> {
    setIsLoading(true);
    await storeGroupScheduleAsync(scheduleDefinition || scheduleSelected, state);
    setIsLoading(false);
    onModalScheduleChange(false);
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const inputType: string = event.target.name;
    event.persist();
    if (isNumberInput(inputType)) {
      setScheduleSelected((current) => ({ ...current, [inputType]: event.target.valueAsNumber }));
    } else if (isDateInput(inputType)) {
      updateDateInput(event.target.value);
    } else if (isTimeInput(inputType)) {
      updateTimeInput(event.target.value);
    } else {
      setScheduleSelected((current) => ({ ...current, [inputType]: event.target.value }));
    }
  }

  function updateDateInput(date: string) {
    const dateToCalendar: string = moment(date).format(DATE_FORMAT);

    setSelectedDate(dateToCalendar);
    setScheduleSelected((current) => ({
      ...current,
      nextDateTimeExecution: moment(dateToCalendar + ' ' + selectedTime, DATE_FORMAT + ' ' + TIME_FORMAT)
        .valueOf()
        .toString(),
    }));
  }

  function updateTimeInput(time: string) {
    const timeToCalendar: string = moment(time, 'HH:mm').format(TIME_FORMAT);
    setSelectedTime(timeToCalendar);
    setScheduleSelected((current) => ({
      ...current,
      nextDateTimeExecution: moment(selectedDate + ' ' + timeToCalendar, DATE_FORMAT + ' ' + TIME_FORMAT)
        .valueOf()
        .toString(),
    }));
  }

  function isNumberInput(inputName: string): boolean {
    return inputName === RETRIES_INTERVAL || inputName === RETRIES_COUNT;
  }

  function isDateInput(inputName: string): boolean {
    return inputName === DATE_INPUT;
  }

  function isTimeInput(inputName: string): boolean {
    return inputName === TIME_INPUT;
  }

  function onSelectNumberChange(value: number, name: string): void {
    setScheduleSelected((current) => ({ ...current, [name]: +value }));
  }

  function findScheduleId(idValue: string): ScheduleGroup | undefined {
    const scheduleId: ScheduleGroup | undefined = scheduleInfo.find((schedule) => schedule.idFrecuency.toString() === idValue);

    return scheduleId;
  }

  function onSelectStringChange(value: string, name?: string): void {
    if (name === ID_FRECUENCY) {
      updateSelectedId(value);
    } else {
      name && setScheduleSelected((current) => ({ ...current, [name]: value }));
    }
  }

  function updateSelectedId(value: string): void {
    const scheduleId = findScheduleId(value);
    if (scheduleId) {
      selectIdSchedule(scheduleId);
    }
  }

  function selectIdSchedule(scheduleId: ScheduleGroup): void {
    const dateAndTimeCalendar = moment(+scheduleId.nextDateTimeExecution);

    setScheduleSelected(scheduleId);
    setSelectedDate(dateAndTimeCalendar.format(DATE_FORMAT));
    setSelectedTime(dateAndTimeCalendar.format(TIME_FORMAT));
  }

  function onCheck(event: { target: HTMLInputElement }): void {
    const scheduleName: string = event.target.name;
    setScheduleSelected((current) => ({ ...current, [scheduleName]: !scheduleSelected[scheduleName] }));
  }

  useEffect(() => {
    loadScheduleGroupInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProcess]);

  return (
    <ScheduleModalView
      groupName={selectedProcess?.name || ''}
      onCancel={onCancel}
      onAccept={onAccept}
      onEdit={onEdit}
      onRemove={onRemove}
      frecuencyOptions={frecuencyOptions}
      intervalOptions={intervalOptions}
      idOptions={idOptions}
      scheduleInfo={scheduleSelected}
      onInputChange={onInputChange}
      onSelectStringChange={onSelectStringChange}
      onSelectNumberChange={onSelectNumberChange}
      isLoading={isLoading}
      onCheck={onCheck}
    />
  );
}
