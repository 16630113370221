import React, { useState, useEffect } from 'react';
import { getAllConceptos } from '../../../api/schema.js';
import { PulseLoader } from 'halogenium';
import { useIntl } from 'react-intl';

export const HelpConceptComponent = (props) => {
  const [result, setResult] = useState([]);
  const [selectedValue, setSelectedValue] = useState(props.currentValue);
  const intl = useIntl();

  useEffect(() => {
    getAllConceptos().then(function (response) {
      setResult(response);
    });
  }, []);

  useEffect(() => {
    if (props.forceSelectHelp) {
      handleWindowSelect(selectedValue);
    }
  });

  const handleClick = (option) => {
    setSelectedValue(option);
  };

  const handleDoubleClick = (value) => {
    handleWindowSelect(value);
  };

  const handleWindowSelect = (value) => {
    props.setHelpValue(value);
  };

  return (
    <div>
      {result && Array.isArray(result) && result.length > 0 ? (
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow-md sm:rounded-lg">
                <div className="col" style={{ overflowY: 'scroll', maxHeight: '200px', minHeight: '100px', width: '400px', marginTop: '5px' }}>
                  <table className="min-w-full" style={{ fontSize: '12px', marginTop: '20px' }}>
                    <thead>
                      <tr>
                        <th scope="col">
                          {intl.formatMessage({
                            id: 'concept',
                            defaultMessage: 'Concepto',
                          })}
                        </th>
                        <th scope="col">
                          {intl.formatMessage({
                            id: 'name',
                            defaultMessage: 'Nombre',
                          })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {result.map((option, index) => {
                        return (
                          <tr
                            style={option.codigo === selectedValue ? { backgroundColor: '#9ABAF5' } : { backgroundColor: 'white' }}
                            key={option[0]}
                            onClick={() => handleClick(option.codigo)}
                            onDoubleClick={() => handleDoubleClick(option.codigo)}
                            className={index % 2 === 0 ? 'table-active' : ''}
                          >
                            <td>{option.codigo}</td>
                            <td>{option.descripcion}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container" style={{ maxHeight: '300px', minHeight: '100px', width: '600px' }}>
          <div className="m-0 row justify-content-center">
            <div className="col-auto p-5 text-center">
              <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
