/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { ABOVE_ALL } from '../../../../UI-kit/constants/zIndexValues';
import { useIntl } from 'react-intl';

export const ProductionPasswordWindow = (props) => {
  const intl = useIntl();

  const { item, setParametros, handleKeyOnNumber } = props;

  useEffect(() => {
    validateInputs();
  }, [validateInputs]);

  const validateInputs = useCallback(() => {
    document.getElementById('pwdcasesensitive').value = props.params.pwdcasesensitive;
  });

  const cancelMessage = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  const acceptMessage = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });

  const modifyMessage = intl.formatMessage({
    id: 'modify',
    defaultMessage: 'Modificar',
  });

  let outside = null;

  const handleAccept = () => {
    let pwdlargomin = document.getElementById('pwdlargomin').value;
    let pwdlargomax = document.getElementById('pwdlargomax').value;
    let pwdcantmincar = document.getElementById('pwdcantmincar').value;
    let pwdcantminnum = document.getElementById('pwdcantminnum').value;
    let pwdcantmaxrep = document.getElementById('pwdcantmaxrep').value;
    let pwdcarmaxrep = document.getElementById('pwdcarmaxrep').value;
    let pwdposmaxrep = document.getElementById('pwdposmaxrep').value;
    let pwdcantcarmaxrep = document.getElementById('pwdcantcarmaxrep').value;
    let pwdcasesensitive = document.getElementById('pwdcasesensitive').value;
    let pwdreglasmin = document.getElementById('pwdreglasmin').value;
    let pwdcarmaxsec = document.getElementById('pwdcarmaxsec').value;

    setParametros({
      pwdlargomin: pwdlargomin,
      pwdlargomax: pwdlargomax,
      pwdcantmincar: pwdcantmincar,
      pwdcantminnum: pwdcantminnum,
      pwdcantmaxrep: pwdcantmaxrep,
      pwdcarmaxrep: pwdcarmaxrep,
      pwdposmaxrep: pwdposmaxrep,
      pwdcantcarmaxrep: pwdcantcarmaxrep,
      pwdcasesensitive: pwdcasesensitive,
      pwdreglasmin: pwdreglasmin,
      pwdcarmaxsec: pwdcarmaxsec,
    });
    props.handleCancel();
  };

  const ifEquals = (item1, item2) => {
    return item1 !== item2 ? item2 : item1;
  };

  return (
    <div className={`fixed pin overflow-auto flex bg-smoke-light w-full h-full`} ref={(c) => (outside = c)} style={{ zIndex: ABOVE_ALL }}>
      <div
        className={`border border-solid border-primary  bg-grey-lighter-lighter rounded relative p-4 w-full m-auto
           flex-col flex`}
        style={{ maxWidth: '35rem' }}
      >
        <div className={`flex-auto text-left m-1 flex `} style={{ height: 'fit-content', justifyContent: 'center' }}>
          <form className={'overflow-hidden'} style={{ display: 'table' }}>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="LargoMinimo">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerMinimumLength',
                  defaultMessage: 'Largo Mínimo de Contraseña',
                })}
              </label>
              <input
                className={'clear-both border ml-2'}
                id="pwdlargomin"
                defaultValue={props.params.pwdlargomin}
                style={{ display: 'table-cell' }}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="LargoMaximo">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerMaximumLength',
                  defaultMessage: 'Largo Máximo de Contraseña',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                style={{ display: 'table-cell' }}
                id="pwdlargomax"
                defaultValue={props.params.pwdlargomax}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="CantMinimaLetras">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerMinimumAmountCharacters',
                  defaultMessage: 'Cantidad Mínima Letras',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                id="pwdcantmincar"
                defaultValue={props.params.pwdcantmincar}
                style={{ display: 'table-cell' }}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="CantMinimaNumeros">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerMinimumAmountNumbers',
                  defaultMessage: 'Cantidad Mínima Números',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                style={{ display: 'table-cell' }}
                id="pwdcantminnum"
                defaultValue={props.params.pwdcantminnum}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="CantContraseñasAnteriores">
                {intl.formatMessage({
                  id: 'productionManagerPreviousStoredPasswords',
                  defaultMessage: 'Cantidad de Contraseñas Anteriores Almacenadas',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                style={{ display: 'table-cell' }}
                id="pwdcantmaxrep"
                defaultValue={props.params.pwdcantmaxrep}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="CantMaxCaracteresRepetidosAnterior">
                {intl.formatMessage({
                  id: 'productionManagerMaxAmountOfRepeatedCharactersAbove',
                  defaultMessage: 'Cantidad Máxima de Caracteres Repetidos (con la anterior)',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                id="pwdcarmaxrep"
                defaultValue={props.params.pwdcarmaxrep}
                style={{ display: 'table-cell' }}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="CantMaxCaracteresRepetidosEnMismaPosAnterior">
                {intl.formatMessage({
                  id: 'productionManagerMaxAmountOfRepeatedCharactersSamePositionAbove',
                  defaultMessage: 'Cantidad Máxima de Caracteres Repetidos en la misma posicion (con la anterior)',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                id="pwdposmaxrep"
                defaultValue={props.params.pwdposmaxrep}
                style={{ display: 'table-cell' }}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="CantMaxRepeticionesCaracter">
                {intl.formatMessage({
                  id: 'productionManagerMaxAmountOfRepetitionsOfCharacter',
                  defaultMessage: 'Cantidad Máxima de Caracteres Repetidos en la misma posicion (con la anterior)',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                id="pwdcantcarmaxrep"
                defaultValue={props.params.pwdcantcarmaxrep}
                style={{ display: 'table-cell' }}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="DistingueMM">
                {intl.formatMessage({
                  id: 'productionManagerCaseSensitive',
                  defaultMessage: 'Distingue mayúsculas de minúsculas',
                })}
              </label>
              <select style={{ display: 'table-cell' }} className={'clear-both border mt-2 ml-2'} id="pwdcasesensitive">
                <option selected={item.pwdcasesensitive === '1'} value="1">
                  {intl.formatMessage({
                    id: 'yes',
                    defaultMessage: 'Si',
                  })}
                </option>
                <option selected={item.pwdcasesensitive === '0'} value="0">
                  {intl.formatMessage({
                    id: 'no',
                    defaultMessage: 'No',
                  })}
                </option>
              </select>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="CantReglasACumplir">
                {intl.formatMessage({
                  id: 'productionManagerMinAmountOfRulesToFollow',
                  defaultMessage: 'Cantidad mínima de reglas a cumplir',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                id="pwdreglasmin"
                defaultValue={props.params.pwdreglasmin}
                style={{ display: 'table-cell' }}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="CantMaxCaracteresConsecutivosRepetidosOSecuencia">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerMaxAmountOfConsecutiveRepeatedChars',
                  defaultMessage: 'Cantidad Máxima de Caracteres Consecutivos Repetidos o en Secuencia',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                style={{ display: 'table-cell' }}
                id="pwdcarmaxsec"
                defaultValue={props.params.pwdcarmaxsec}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
          </form>
        </div>
        <div className="flex justify-center mt-2 ml-2">
          <button
            data-autofocus
            className={`bg-grey
                       hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow`}
            style={{ width: 'fit-content' }}
            onClick={() => handleAccept()}
          >
            {acceptMessage}
          </button>
          <button
            className={`bg-grey
                   hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow ml-2`}
            style={{ width: 'fit-content' }}
            onClick={() => props.handleCancel()}
          >
            {cancelMessage}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductionPasswordWindow;
