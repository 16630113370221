import React from 'react';
import IcoMoon from 'react-icomoon';
import { injectIntl, FormattedMessage } from 'react-intl';

const SIZE_THUMBNAILS = 70;

const NoRegisteredSignature = ({ intl, fromValidation, selected, pending, thumbnails }) => {
  const msg = intl.formatMessage({
    id: 'noRegisteredSignature',
    defaultMessage: 'Sin firma registrada',
  });

  return (
    <div
      className={`flex justify-center items-center text-sm italic ${fromValidation ? 'border-2' : 'border'} justify-center align-c bg-white p-10 ${
        selected ? 'border-primary shadow' : pending ? 'border-error' : 'border-smoke'
      }`}
      style={{
        filter: pending ? 'opacity(50%)' : 'none',
        height: thumbnails ? SIZE_THUMBNAILS : 200,
      }}
    >
      {msg}
    </div>
  );
};

export const SignatureContainer = ({
  signature,
  pending = false,
  children,
  selected = false,
  click = null,
  showOptions = false,
  showAuthorize = false,
  handleRemove = null,
  handleAuthorize,
  signatureId,
  fromValidation,
  thumbnails = false,
  showPreview = false,
  handleShowPreview,
  intl,
}) => (
  <div className={`${thumbnails ? 'p-1' : 'p-4'}`}>
    <div className={`relative text-center ${click && 'cursor-pointer'} rounded`} id={signatureId} onMouseDown={click}>
      {signature ? (
        <img
          className={`block ${fromValidation ? 'border-2' : 'border'} inline-block bg-white p-2 object-cover mx-auto ${
            selected ? 'border-primary shadow' : pending ? 'border-error' : 'border-smoke'
          }`}
          src={signature}
          style={{
            filter: pending ? 'opacity(50%)' : 'none',
            height: thumbnails ? SIZE_THUMBNAILS : 200,
          }}
        />
      ) : (
        <NoRegisteredSignature intl={intl} fromValidation={fromValidation} selected={selected} pending={pending} thumbnails={thumbnails} />
      )}

      {showOptions && (
        <div className="flex flex-col absolute z-50" style={{ top: 5, right: 5, boxSizing: 'content-box' }}>
          {signature && showPreview && (
            <span
              title={intl.formatMessage({
                id: 'preview',
                defaultMessage: 'Vista Previa',
              })}
            >
              <IcoMoon
                icon="zoom-in"
                className="mb-2 cursor-pointer rounded text-white border-4 h-4 w-4 bg-grey border-grey focus:border-primary hover:border-primary focus:bg-primary hover:bg-primary focus:outline-none"
                onClick={(e) => {
                  handleShowPreview(signatureId);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </span>
          )}
          {handleRemove && (
            <span
              title={intl.formatMessage({
                id: 'remove',
                defaultMessage: 'Eliminar',
              })}
            >
              <IcoMoon
                icon="bin"
                className="mb-2 cursor-pointer rounded text-white border-4 h-4 w-4 bg-grey border-grey focus:border-primary hover:border-primary focus:bg-primary hover:bg-primary focus:outline-none"
                onClick={() => {
                  handleRemove(signatureId);
                }}
              />
            </span>
          )}
          {showAuthorize && (
            <span
              title={intl.formatMessage({
                id: 'authorize',
                defaultMessage: 'Autorizar',
              })}
            >
              <IcoMoon
                icon="checkmark"
                className="mb-2 cursor-pointer rounded text-white border-4 h-4 w-4 bg-grey border-grey focus:border-primary hover:border-primary focus:bg-primary hover:bg-primary focus:outline-none"
                onClick={() => {
                  handleAuthorize(signatureId);
                }}
              />
            </span>
          )}
        </div>
      )}

      {pending && (
        <div className="text-error">
          <FormattedMessage id="pendingAuthorization" defaultMessage={`Autorización Pendiente`} />
        </div>
      )}
    </div>
    {children && <div>{children}</div>}
  </div>
);

export default injectIntl(SignatureContainer);
