import React from 'react';
import CheckContainer from './CheckContainer';

const CheckImages = (props) => {
  let checks = props.checks;
  const { f1Img, f2Img, r1Img, r2Img, s1Img, s2Img, s3Img } = checks;

  const images = {
    f1: f1Img,
    f2: f2Img,
    r1: r1Img,
    r2: r2Img,
    s1: s1Img,
    s2: s2Img,
    s3: s3Img,
  };

  return (
    <div>
      {images.f1 && images.f1.length < 35 && <div style={{ height: '180px' }}></div>}
      {images.f1 && images.f1.length > 35 && (
        <React.Fragment>
          <CheckContainer check={[images.f1, images.r1]} showOptions={true} checkId={checks.documentId}></CheckContainer>
        </React.Fragment>
      )}
    </div>
  );
};

export default CheckImages;
