import { PulseLoader } from 'halogenium';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { reloadPermissions } from '../../../../../api/securityManager';
import { CRYPTO_MODAL, NO_MODAL_OPEN, PASS_DB__MODAL, PASS_SWIFT_MODAL } from '../../../models/constants';

import './ConfigsModal.scss';

export interface ConfigsModalViewProps {
  onModalChange: (modalOpen: string) => void;
}

export function ConfigsModalView({ onModalChange }: ConfigsModalViewProps): JSX.Element | null {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const intl = useIntl();

  function onCancel(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(NO_MODAL_OPEN);
  }

  function onPasswordDBClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(PASS_DB__MODAL);
  }

  function onPasswordSWIFTClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(PASS_SWIFT_MODAL);
  }

  async function onMetadataClickAsync(event: React.MouseEvent): Promise<void> {
    event.stopPropagation();
    onModalChange(NO_MODAL_OPEN);
    setIsLoading(true);
    await reloadPermissions();
    setIsLoading(false);
  }

  async function onCryptogramClick(event: React.MouseEvent): Promise<void> {
    event.stopPropagation();
    onModalChange(CRYPTO_MODAL);
  }

  return (
    <div className="configs-modal font-mono z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex justify-center">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className="configs-content inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border shadow-sm">
          <div className="bg-blue-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              {isLoading ? (
                <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
                  <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
                </div>
              ) : (
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" style={{ width: '100%' }}>
                  <h3 className="text-lg text-center leading-6 font-medium text-gray-900" id="modal-title">
                    {intl.formatMessage({ id: 'configuration' })}
                  </h3>
                  <div className="mt-4 border-t w-full" />
                  <div className="config-list flex flex-col mt-6">
                    <div className="m-2 flex items-center mb-3 justify-between">
                      <button onClick={onPasswordDBClick} className=" pr-3 text-sm">
                        {intl.formatMessage({ id: 'passwordLogin' })} DB
                      </button>
                    </div>
                    <div className="m-2 flex items-center mb-3 justify-between">
                      <button onClick={onCryptogramClick} className="pr-3 text-sm">
                        {intl.formatMessage({ id: 'cryptogram' })}
                      </button>
                    </div>
                    <div className="m-2 flex items-center mb-3 justify-between">
                      <button onClick={onPasswordSWIFTClick} className="pr-3 text-sm">
                        {intl.formatMessage({ id: 'passwordLogin' })} SWIFT
                      </button>
                    </div>
                    <div className="m-2 flex items-center mb-3 justify-between">
                      <button onClick={onMetadataClickAsync} className="pr-3 text-sm">
                        {intl.formatMessage({ id: 'updateMetadata' })}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="justify-center bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onCancel}
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {intl.formatMessage({ id: 'cancel' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
