import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { undo, run } from '../../src/api/dialog';
import Displayable from './Displayable';
import { storeActions } from '../../src/store';
import { getCurrentTabInformation } from '../../src/selectors';
import { addLineTest } from '../../src/components/Testing/HandleLineTest';

export class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
    this.buttonInput = React.createRef();
  }
  componentDidUpdate() {
    const { transactionLineLabel, focusElement } = this.props;
    if (transactionLineLabel === focusElement) {
      this.buttonInput.current.scrollIntoView(false);
      this.buttonInput.current.focus();
    }
  }

  render() {
    let {
      text,
      style,
      position = { x: 0, y: 0, width: 'auto', height: 'auto' },
      componentState,
      onClick,
      operationID,
      processContext,
      operationNumber,
      transactionLineLabel,
      transactionNumber,
      lineNumber,
      focusElement,
      tabs,
      addEmptyTab,
      addTab,
    } = this.props;

    let disabled = componentState && componentState.disabled;

    return (
      <Displayable componentState={componentState}>
        <button
          disabled={disabled || this.state.disabled}
          id={transactionLineLabel}
          ref={this.buttonInput}
          className={`${disabled ? 'bg-smoke cursor-not-allowed' : 'bg-grey cursor-pointer focus:outline-none focus:bg-primary hover:bg-primary'}
            text-white rounded shadow text-xs absolute z-10`}
          style={{
            ...style,
            top: position.y,
            left: position.x,
            width: position.width,
            height: position.height,
          }}
          onBlur={(e) => {
            if (e.relatedTarget === null) {
              const { transactionLineLabel, focusElement } = this.props;
              if (transactionLineLabel === focusElement) {
                this.buttonInput.current.scrollIntoView(false);
                this.buttonInput.current.focus();
              }
            }
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Escape' || (e.shiftKey && e.key === 'Tab')) {
              const res = await undo(operationID, transactionNumber, lineNumber);
              processContext(res, 'Input button undo');
            } else if (e.key === 'Tab' || e.key === 'Enter') {
              run(operationID, transactionNumber, lineNumber, {}).then((res) => {
                processContext(res, 'Input run');
              });
            } else if (e.key === 76 && e.ctrlKey) {
              e.preventDefault();
              e.stopPropagation();
            } else if (e.key === ' ') {
              addLineTest('CLICK', e);
              let btnRef = this;
              onClick({
                operationNumber,
                operationID,
                processContext,
                focusElement,
                tabs,
                addEmptyTab,
                addTab,
                btnRef,
              });
            }
          }}
          onClick={(e) => {
            if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) {
              addLineTest('CLICK', e);
              let btnRef = this;
              onClick({
                operationNumber,
                operationID,
                processContext,
                focusElement,
                tabs,
                addEmptyTab,
                addTab,
                btnRef,
              });
            }
          }}
        >
          {text}
        </button>
      </Displayable>
    );
  }
}

const mapStateToProps = (state) => {
  const { operationID, operationNumber, focusElement, tabs } = getCurrentTabInformation(state);

  return {
    operationID,
    operationNumber,
    focusElement,
    tabs,
  };
};

export default connect(mapStateToProps, storeActions)(Button);
