/* eslint-disable no-undef */
import React, { Component } from 'react';
import IcoMoon from 'react-icomoon';
import Draggable from 'react-draggable';
import { connect } from 'unistore/react';
import { storeActions } from '../../src/store';
import { FormattedMessage } from 'react-intl';

import Overlay from './Overlay';
import { ABOVE_ALL } from '../constants/zIndexValues';
import { getPhysicalPerson, addPersonFingerData } from '../../src/api/signatures';
import { setVerifyFinger } from '../../src/api/operation';

import FocusTrap from 'react-focus-trap';
import { fileArrayToBase64 } from '../../UI-kit/helpers/fileHelper';
import { getCurrentTabInformation } from '../../src/selectors';
import {
  startCapture,
  startCaptureABM,
  getFingerData,
  getFingerDataBMP,
  getFingerDataANSI,
  getLastBMP,
  reset,
  getScanned,
  verifyFingerprint,
} from '../../src/api/fingerprintWS';

const SIGNATURE_FINGERPRINT = 'GET_FINGERPRINT';
const SIGNATURE_VERIFYFINGER = 'VERIFY_FINGERPRINT';

const Button = ({ title, click, className, disabled = false }) => {
  return (
    <button
      className={`${className} ${disabled && 'opacity-75'} bg-grey hover:bg-primary focus:bg-primary text-white rounded shadow m-2 py-2 px-4 text-sm`}
      onClick={click}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export class SignatureFingerPrint extends Component {
  constructor(props) {
    super(props);
    const { action, operationID } = this.props;
    this.state = {
      showWindow: true,
      physicalPerson: null,
      amountOfScannedFingerprints: 0,
      lastScannedFingerprint: null,
      lastScannedANSI: null,
      lastScannedBitmap: null,
      ansiArray: [],
      valueToSend: 0,
      timeout: null,
      scanning: false,
      scannedAll: false,
      action: action,
      validationData: null,
      operationID: operationID,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getScannedImagesLoop = this.getScannedImagesLoop.bind(this);
    this.getScannedVerifyLoop = this.getScannedVerifyLoop.bind(this);
  }

  async componentDidMount() {
    let { numberPhysicalPerson, pauseFocus } = this.props;
    pauseFocus();
    await this.fetchData(numberPhysicalPerson);
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
    reset();
  }

  async getScannedImagesLoop() {
    getLastBMP()
      .then((data) => {
        let amountToSet = this.state.amountOfScannedFingerprints;
        if (data !== '') {
          if (data.length === 120) {
            this.resetReadData();
            // eslint-disable-next-line no-undef
            alert('Occurió un error al leer la huella, por favor asegúrese que el lector y el dedo estén limpios y escanee los 4 dedos de nuevo.');
            return;
          } else {
            amountToSet++;
            this.setState({ amountOfScannedFingerprints: amountToSet });
            let receivedBMP = data;
            let base64Image = fileArrayToBase64(receivedBMP);
            this.setState({ lastScannedFingerprint: base64Image });
          }
        }

        if (!this._ismounted) {
        } else if (amountToSet === 4) {
          this.handleReceive();
          this.setState({ timeout: clearTimeout(this.state.timeout) });
        } else {
          this.setState({
            timeout: setTimeout(() => this.getScannedImagesLoop(), 1000),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.resetReadData();
        // eslint-disable-next-line no-undef
        alert('Occurió un error al leer la huella, por favor asegúrese que el lector y el dedo estén limpios y escanee los 4 dedos de nuevo.');
      });
  }

  async getScannedVerifyLoop() {
    const { numberPhysicalPerson } = this.props;
    getScanned()
      .then((data) => {
        if (!this._ismounted) {
          return;
        }
        let status = data.status !== 204 ? '1' : '';
        if (status !== '') {
          let base64Image = 'data:image/png;base64,' + data.data.bmpImage;
          this.setState({ lastScannedFingerprint: base64Image });
          if (status === '1') {
            verifyFingerprint(numberPhysicalPerson, data.data.fingerData).then((res) => {
              if (res.data.verify) {
                this.setState({ verify: 1 });
                // eslint-disable-next-line no-undef
                alert('La huella VERIFICA.');
              } else {
                this.setState({ verify: 0 });
                alert('La huella no VERIFICA.');
              }
            });
          } else {
            this.setState({
              timeout: setTimeout(() => this.getScannedVerifyLoop(), 1000),
            });
          }
        } else {
          this.setState({
            timeout: setTimeout(() => this.getScannedVerifyLoop(), 1000),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        // eslint-disable-next-line no-undef
        alert('Occurió un error al leer la huella, por favor asegúrese que el lector y el dedo estén limpios y escanee los 4 dedos de nuevo.');
      });
  }

  handleSend() {
    let body = this.buildBody();
    addPersonFingerData(body);
  }

  buildBody() {
    let numeroPersonaFisica = this.props.numberPhysicalPerson;
    let fingerkey = this.state.valueToSend;
    let fingerdata = this.state.ansiArray;
    let fingerDataAnsi = this.state.lastScannedANSI;
    let fingerDataBMP = this.state.lastScannedBitmap;

    let body = {
      numeroPersonaFisica,
      fingerkey,
      fingerdata,
      fingerDataAnsi,
      fingerDataBMP,
    };
    return body;
  }

  async handleResume() {
    // llamado al webservice
    if (this.state.action === SIGNATURE_FINGERPRINT) {
      await startCaptureABM();
      this.getScannedImagesLoop();
    } else {
      await startCapture();
      this.getScannedVerifyLoop();
    }

    this.setState({ scanning: true });
  }

  async handleReceive() {
    let fingerData = await getFingerData();
    let fingerDataANSI = await getFingerDataANSI();
    let fingerDataBMP = await getFingerDataBMP();

    if (fingerDataBMP && fingerDataBMP.length > 120) {
      let base64Image = fileArrayToBase64(fingerDataBMP);

      this.setState({
        ansiArray: fingerData,
        lastScannedANSI: fingerDataANSI,
        lastScannedFingerprint: base64Image,
        lastScannedBitmap: fingerDataBMP,
        scanning: false,
        scannedAll: true,
      });
    } else {
      this.resetReadData();
      // eslint-disable-next-line no-undef
      alert('Occurió un error al leer la huella, por favor asegúrese que el lector y el dedo estén limpios y escanee los 4 dedos de nuevo.');
    }
  }

  resetReadData() {
    this.setState({
      ansiArray: [],
      lastScannedANSI: null,
      lastScannedFingerprint: null,
      lastScannedBitmap: null,
      amountOfScannedFingerprints: 0,
      scanning: false,
    });
  }

  async handleSave() {
    let { removeTreeNode, removeComponentTree } = this.props;
    this.handleSend();

    this.setState((prevState) => ({ showWindow: !prevState.showWindow }));
    removeComponentTree(removeTreeNode);
  }

  handleCancel() {
    if (this.state.action === SIGNATURE_VERIFYFINGER) {
      setVerifyFinger(this.state.verify, this.state.operationID);
    }

    let { removeTreeNode, removeComponentTree, resumeFocus } = this.props;

    this.setState((prevState) => ({ showWindow: !prevState.showWindow }));
    resumeFocus();
    removeComponentTree(removeTreeNode);
  }

  async fetchData(numberPhysicalPerson) {
    let physicalPerson = await getPhysicalPerson(numberPhysicalPerson);
    this.setState({ physicalPerson });
    /*
    if (this.state.action === SIGNATURE_VERIFYFINGER) {
      let validationData = await getValidationData(numberPhysicalPerson);
      this.setState({ validationData });
    }
      */
  }

  render() {
    let { numberPhysicalPerson } = this.props;
    let { showWindow, physicalPerson } = this.state;

    let style = { width: 800 };

    let captureMessage = <span className="text-xs mx-10">Haga clic en comenzar el escaneo</span>;
    if (this.state.scanning) {
      captureMessage = <span className="text-xs mx-10">Dedos escaneados : {this.state.amountOfScannedFingerprints}</span>;
    } else if (this.state.scannedAll) {
      captureMessage = <span className="text-xs mx-10">Los 4 dedos han sido escaneados</span>;
    }

    let fingerImage;
    if (this.state.lastScannedFingerprint) {
      fingerImage = <img style={{ maxWidth: '300px', maxHeight: '350px' }} src={`${this.state.lastScannedFingerprint}`} />;
    }

    return (
      <FocusTrap className={`${showWindow ? '' : 'hidden'}`}>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div className="border border-primary bg-grey-lighter-lighter rounded shadow absolute p-2 w-full" style={{ ...style, zIndex: ABOVE_ALL }}>
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move m-1 text-xs" />
            {this.state.action === SIGNATURE_FINGERPRINT ? (
              <div className="mb-2 font-light text-primary text-center">
                <FormattedMessage id="scanFingerPrintPerson" defaultMessage={`Captura huella dactilar de la persona`} />
              </div>
            ) : (
              <div className="mb-2 font-light text-primary text-center">
                <FormattedMessage id="validationFingerPrintPerson" defaultMessage={`Validación huella dactilar de la persona`} />
              </div>
            )}
            <div className="my-4">
              <span className="text-xs">#{numberPhysicalPerson}</span>
              {physicalPerson && physicalPerson[0] && (
                <span className="ml-2 inline-block">
                  {physicalPerson[0].primerNombre} {physicalPerson[0].segundoNombre} {physicalPerson[0].apellidoPaterno}{' '}
                  {physicalPerson[0].apellidoMaterno}
                </span>
              )}
            </div>
            {this.state.action === SIGNATURE_FINGERPRINT ? (
              <div>
                <span className="text-xs">Dedo a escannear</span>
                <select onChange={(e) => this.setState({ valueToSend: e.target.value })}>
                  <option value="0">Pulgar derecho</option>
                  <option value="1">Indice derecho</option>
                  <option value="2">Mayor derecho</option>
                  <option value="3">Anular derecho</option>
                  <option value="4">Meñique derecho</option>
                  <option value="5">Pulgar izquierdo</option>
                  <option value="6">Indice izquierdo</option>
                  <option value="7">Mayor izquierdo</option>
                  <option value="8">Anular izquierdo</option>
                  <option value="9">Meñique izquierdo</option>
                </select>
                {captureMessage}
              </div>
            ) : (
              <div></div>
            )}

            <div>{fingerImage}</div>

            <div className="flex justify-center mt-2">
              <Button title={'Comenzar'} click={() => this.handleResume()} />
            </div>
            <div className="flex justify-center mt-2">
              {this.state.action === SIGNATURE_FINGERPRINT ? (
                <Button title={<FormattedMessage id="save" defaultMessage={'Guardar'} />} click={() => this.handleSave()} />
              ) : (
                ''
              )}
              <Button title={<FormattedMessage id="cancel" defaultMessage={'Cancelar'} />} click={() => this.handleCancel()} />
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );
  }
}

const mapStateToProps = (state) => {
  const { operationID } = getCurrentTabInformation(state);
  return {
    operationID,
  };
};

export default connect(mapStateToProps, storeActions)(SignatureFingerPrint);
