const dictionary = {
  'boolean': 1,
  'number': 5,
  'string': 8,
  'date': 11,
  'long': 7
}

const LIMIT_TYPE_LONG = 2147483647

const isLong = (value) => ((typeof value === 'number') && value % 1 === 0 && value > LIMIT_TYPE_LONG)

const isDate = (value) => (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value))

const typeToValue = (type) => {
  if (isDate(type)) return dictionary['date']
  if (isLong(type)) return dictionary['long']

  let key = typeof type
  return dictionary[key] || 0
}

export default typeToValue
