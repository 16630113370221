import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';

export let getListDocuments = (groupType, groupNumber, filter) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/documentmanager/getList?sessionId=${session}&groupType=${groupType}&groupNumber=${groupNumber}&filter=${filter}`,
    )
    .then(({ data }) => data);
};

export let getDocument = (fileId) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(
      `${API}TopazWebClientWS/resources/documentmanager/get?sessionId=${session}&fileId=${fileId}`,
    )
    .then(({ data }) => data);
};

export let removeDocument = (fileId) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(
      `${API}TopazWebClientWS/resources/documentmanager/remove?sessionId=${session}&fileId=${fileId}`,
    )
    .then(({ data }) => data);
};

export let changeDocument = (fileId, comment) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(
      `${API}TopazWebClientWS/resources/documentmanager/change?sessionId=${session}&fileId=${fileId}&comment=${comment}`,
    )
    .then(({ data }) => data);
};

export let addDocument = (
  groupType,
  groupNumber,
  filter,
  name,
  size,
  comment,
  body,
) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/documentmanager/add?sessionId=${session}&groupType=${groupType}&groupNumber=${groupNumber}&filter=${filter}&name=${name}&size=${size}&comment=${comment}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let generateThumbnail = (fileId, fileOriginalName) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .get(
      `${API}TopazWebClientWS/resources/documentmanager/generarThumbnail?sessionId=${session}&fileId=${fileId}&fileOriginalName=${fileOriginalName}`,
    )
    .then(({ data }) => data);
};

export let getThumbnail = (fileId) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .get(
      `${API}TopazWebClientWS/resources/documentmanager/getThumbnail?sessionId=${session}&fileId=${fileId}`,
    )
    .then(({ data }) => {
      return data;
    });
};
