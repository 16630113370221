import React, { Component } from 'react'
import WindowFile from './WindowFile'

export class ChangeDocument extends Component {
  constructor () {
    super()
    this.state = {
      fileComment: '',
    }
    this.handleAccept = this.handleAccept.bind(this)
    this.handleChangeComment = this.handleChangeComment.bind(this)
  };
  handleAccept () {
    let { changeFile, fileSelected } = this.props
    let { fileComment } = this.state
    changeFile(fileSelected.id, fileComment)
  }
  handleChangeComment (e) {
    this.setState({
      fileComment: e.target.value
    })
  }
  componentDidMount () {
    let { fileSelected } = this.props
    this.setState({
      fileComment: fileSelected.comment
    })
  }

  render () {
    let { closeWindow, fileSelected } = this.props
    const { name: fileName } = fileSelected
    const { fileComment } = this.state
    return (
      <WindowFile fileName={fileName} fileComment={fileComment} closeWindow={closeWindow} handleAccept={this.handleAccept} handleChangeComment={this.handleChangeComment} />
    )
  }
}

export default ChangeDocument
