import React from 'react';
import crossIcon from './cross';
import downArrowIcon from './downArrow';

const crossDownArrowIcon = (
  <div>
    {crossIcon}
    {downArrowIcon}
  </div>
);

export default crossDownArrowIcon;
