import React, { Component } from 'react';
import 'rc-tree/assets/index.css';
import { PulseLoader } from 'halogenium';
import IcoMoon from 'react-icomoon';
import { FormattedMessage } from 'react-intl';
import WorkflowTaskList from './WorkflowTaskList';
import WorkflowTaskDetail from './WorkflowTaskDetail';
import MenuIcon from '../resources/icons/fa/bars.svg';
import { store } from '../../src/store';
import { addValueTest } from '../../src/components/Testing/HandleLineTest';

const TASK_DETAIL_HEIGHT = 500;

const pulseLoader = <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />;

export class WorkflowWindowLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOnlyMine: true,
      showTaskList: true,
      collapsedState: {},
      collapsed: false,
    };

    this.refreshComponent = this.refreshComponent.bind(this);
    this.toggleTaskType = this.toggleTaskType.bind(this);
    this.toggleTaskList = this.toggleTaskList.bind(this);
    this.toggleCollapsedStatus = this.toggleCollapsedStatus.bind(this);

    this.outsideContextMenu = null;
  }

  toggleCollapsedStatus(key) {
    this.setState({
      collapsedState: {
        ...this.state.collapsedState,
        [key]: !this.state.collapsedState[key],
      },
    });
  }

  toggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  selectKeyTree(subSelected) {
    this.setState({ subSelected });
  }

  componentDidUpdate() {
    const { onSelectList, dataInbox } = this.props;
    let state = store.getState();
    if (state.loadLeaf === true && dataInbox.length > 0) {
      let selectedTask = [];
      let tasks = null;
      let workflowTasks = state.workflowTasks;
      dataInbox.forEach((dInbox) => {
        let taskList = dInbox.taskList;
        taskList.forEach((task) => {
          if (selectedTask.length === 0) {
            tasks = task.tasks;
            selectedTask = tasks.filter((t) => t.taskId === parseInt(workflowTasks[0].taskID));
          }
        });
      });
      if (selectedTask.length > 0) {
        this.selectKeyTree(state.workflowTasks[0].subSelected);
        const info = { value: tasks, stateName: selectedTask[0].taskName };
        onSelectList(info);
        state.loadLeaf = false;
        store.setState(state);
      }
    }
  }

  refreshComponent() {
    const { fetchData } = this.props;
    this.setState({
      subSelected: null,
    });
    fetchData();
  }

  toggleTaskType() {
    this.setState({
      showOnlyMine: !this.state.showOnlyMine,
    });
    this.refreshComponent();
  }

  toggleTaskList(newValue) {
    this.setState({
      showTaskList: !!newValue,
    });
  }

  render() {
    const {
      loading,
      dataInbox,
      processDefinitionInfo,
      dataDetails,
      columnsDetails,
      loadingDetails,
      dataHistory,
      loadingHistory,
      onSelectList,
      selectDetail,
      bottomIndexPage,
      changeBottomIndexPage,
      executeFunction,
      reAssignInstance,
      handleShowSearcherWindow,
      getInstanceImage,
      seeAll,
      handleSeeAll,
      taskSelected,
      rawDataDetails,
      currentState,
    } = this.props;

    const { subSelected, showOnlyMine, showTaskList, collapsedState } = this.state;

    return loading ? (
      <div
        className="relative w-full mt-6 p-2 px-6 flex justify-between items-center h-full"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
      </div>
    ) : (
      <div className="relative w-full mt-6 p-2 px-6 text-sm">
        <div className="flex justify-between mb-3">
          <h2 className="text-left text-lg font-normal">
            <FormattedMessage id="inbox" defaultMessage={`Bandeja de Entrada`} />
          </h2>
          <IcoMoon icon="loop2" width="25" className="cursor-pointer" onClick={() => this.refreshComponent()} />
        </div>
        <div className="flex justify-start mb-3">
          <label htmlFor="task-assignment" className="inline-block">
            <input type="checkbox" id="task-assignment" className="mr-1" onChange={() => {}} checked={!showOnlyMine} onClick={this.toggleTaskType} />
            <FormattedMessage id="showAllInstances" defaultMessage={`Mostrar todas las instancias`} />
          </label>
          {taskSelected && !Array.isArray(taskSelected.value) && !loadingDetails && !taskSelected.stateName && (
            <button
              className="cursor-pointer bg-grey focus:outline-none focus:bg-primary hover:bg-primary text-white rounded shadow ml-2 p-1 text-xs focus:outline-none"
              onClick={() => {
                handleSeeAll(!seeAll);
              }}
            >
              {seeAll ? (
                <FormattedMessage id="seeAll" defaultMessage={`Ver Todos`} />
              ) : (
                <FormattedMessage id="seeCurrent" defaultMessage={`Ver Actuales`} />
              )}
            </button>
          )}
        </div>
        <div className="flex">
          <div className="">
            <div>
              <div className="pt-4">
                <button disabled={true}>
                  <MenuIcon onClick={this.toggleCollapse} height="20px" fill="#161617" />
                </button>
                <div
                  className=" bg-grey-lighter-lighter"
                  style={{
                    width: this.state.collapsed ? 0 : 260,
                    transition: '0.5s ease',
                  }}
                >
                  {!showOnlyMine && this.state.collapsed === false && (
                    <div>
                      {processDefinitionInfo.map((process, indexProcess) => {
                        const { prompt, processName } = process;

                        return (
                          <div key={indexProcess} className="border-b border-white p-2 bg-grey-lighter-lighter">
                            <div className="flex w-full items-center justify-between">
                              <div
                                className="cursor-pointer truncate text-sm flex items-center"
                                onClick={() => {
                                  this.selectKeyTree(null);
                                  this.toggleCollapsedStatus(`process-${indexProcess}`);
                                  const info = { value: processName, stateName: '' };
                                  onSelectList(info);
                                }}
                              >
                                <div
                                  className="w-6 h-6 flex items-center justify-center transition-all"
                                  style={{
                                    transform: `${collapsedState[`process-${indexProcess}`] ? 'rotate(0)' : 'rotate(-90deg)'}`,
                                  }}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="8" height="5" viewBox="0 0 8 5">
                                    <polygon points="7.06 0 4 3.09 .94 0 0 .951 4 5 8 .951" />
                                  </svg>
                                </div>
                                <div>{prompt}</div>
                              </div>
                              <button
                                id={`process-${indexProcess}`}
                                onClick={(e) => {
                                  addValueTest('CLICK', e.currentTarget.id, '');
                                  const info = { value: processName, stateName: '' };
                                  onSelectList(info);
                                  handleShowSearcherWindow();
                                }}
                                className="inline-block text-xxs text-primary cursor-pointer"
                              >
                                <FormattedMessage id="search" defaultMessage={`Buscar`} />
                              </button>
                            </div>
                            {collapsedState[`process-${indexProcess}`] && (
                              <div className="items px-1 pl-6">
                                {process.stateNames.map((state, indexState) => {
                                  const { stateName } = state;
                                  return (
                                    <div
                                      key={`${indexProcess}-${indexState}`}
                                      className={`cursor-pointer truncate text-xs block my-2 ${
                                        subSelected === `${indexProcess}-${indexState}` ? 'text-primary' : ''
                                      }`}
                                      onClick={() => {
                                        this.selectKeyTree(`${indexProcess}-${indexState}`);
                                        const info = {
                                          value: processName,
                                          stateName: stateName,
                                        };
                                        onSelectList(info);
                                      }}
                                    >
                                      {`${stateName}`}
                                      <button
                                        id={`${indexProcess}-${indexState}`}
                                        onClick={(e) => {
                                          addValueTest('CLICK', e.currentTarget.id, '');
                                          const info = { value: processName, stateName: stateName };
                                          onSelectList(info);
                                          handleShowSearcherWindow();
                                        }}
                                        className="flex ml-auto p-0 inline-block text-xxs text-primary cursor-pointer"
                                      >
                                        <FormattedMessage id="search" defaultMessage={`Buscar`} />
                                      </button>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {showOnlyMine && this.state.collapsed === false && (
                    <div>
                      {dataInbox.map((dInbox, indexInbox) => {
                        const { prompt, processName, taskList } = dInbox;
                        return (
                          <div key={indexInbox} className="border-b border-white p-2 bg-grey-lighter-lighter">
                            <div className="flex w-full items-center justify-between">
                              <div
                                id={`opcionInbox-${indexInbox}`}
                                className="cursor-pointer truncate text-sm flex items-center"
                                onClick={(e) => {
                                  addValueTest('CLICK', e.currentTarget.id, '');
                                  this.selectKeyTree(null);
                                  this.toggleCollapsedStatus(`inbox-${indexInbox}`);
                                  const info = { value: processName, stateName: '' };
                                  onSelectList(info);
                                }}
                              >
                                <div
                                  className="w-6 h-6 flex items-center justify-center transition-all"
                                  style={{
                                    transform: `${collapsedState[`inbox-${indexInbox}`] ? 'rotate(0)' : 'rotate(-90deg)'}`,
                                  }}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="8" height="5" viewBox="0 0 8 5">
                                    <polygon points="7.06 0 4 3.09 .94 0 0 .951 4 5 8 .951" />
                                  </svg>
                                </div>
                                <div>{prompt}</div>
                              </div>
                              <button
                                id={`inbox-${indexInbox}`}
                                onClick={(e) => {
                                  addValueTest('CLICK', e.currentTarget.id, '');
                                  const info = { value: processName, stateName: '' };
                                  onSelectList(info);
                                  handleShowSearcherWindow();
                                }}
                                className="inline-block text-xxs text-primary cursor-pointer"
                              >
                                <FormattedMessage id="search" defaultMessage={`Buscar`} />
                              </button>
                            </div>
                            {collapsedState[`inbox-${indexInbox}`] && (
                              <div className="items px-1 pl-6">
                                {taskList.map((task, indexTask) => {
                                  const { taskName, tasks } = task;
                                  return (
                                    <div
                                      id={`${indexInbox}-${indexTask}`}
                                      key={`${indexInbox}-${indexTask}`}
                                      className={`align-content-md-end cursor-pointer my-1 text-xs flex ${
                                        subSelected === `${indexInbox}-${indexTask}` ? 'text-primary' : ''
                                      }`}
                                      onClick={(e) => {
                                        addValueTest('CLICK', e.currentTarget.id, '');
                                        this.selectKeyTree(`${indexInbox}-${indexTask}`);
                                        const info = { value: tasks, stateName: taskName };
                                        onSelectList(info);
                                      }}
                                    >
                                      <div style={{ maxWidth: '150px' }} className="pt-1">
                                        {taskName}
                                      </div>
                                      <div className="flex  justify-center items-center">
                                        <div className="px-2 h-4 rounded-full text-white ml-2 bg-text-base text-center text-xxs align-middle flex flex-col justify-center">
                                          <div className="w-full">{tasks.length}</div>
                                        </div>
                                      </div>
                                      <div className="flex ml-auto p-0">
                                        <button
                                          id={`2-${indexInbox}-${indexTask}`}
                                          onClick={(e) => {
                                            addValueTest('CLICK', e.currentTarget.id, '');
                                            const info = { value: tasks, stateName: taskName };
                                            onSelectList(info);
                                            handleShowSearcherWindow();
                                          }}
                                          className=" text-primary cursor-pointer"
                                        >
                                          <FormattedMessage id="search" defaultMessage={`Buscar`} />
                                        </button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex-grow flex items-stretch overflow:scroll rounded border border-light overflow-x-hidden"
            // className='flex-grow'
            style={{ minHeight: TASK_DETAIL_HEIGHT }}
          >
            <div className="w-full p-4 h-full flex">
              {
                <WorkflowTaskList
                  taskSelected={this.props.taskSelected}
                  setTaskDetail={this.props.setTaskDetail}
                  thisObject={this.props.thisObject}
                  tasks={dataDetails}
                  rawDataDetails={rawDataDetails}
                  columns={columnsDetails}
                  loading={loadingDetails}
                  loadingComponent={pulseLoader}
                  subSelected={this.state.subSelected}
                  noDataText={
                    <p className="text-sm text-center w-full self-center">
                      <FormattedMessage id="selectGroupPendingTasks" defaultMessage={`Seleccione un grupo con tareas pendientes.`} />
                    </p>
                  }
                  handleTaskClick={async (e, index) => {
                    selectDetail(index);
                    this.toggleTaskList(false);
                  }}
                  showingOnlyMine={showOnlyMine}
                  bottomIndexPage={bottomIndexPage}
                  changeBottomIndexPage={changeBottomIndexPage}
                  executeFunction={executeFunction}
                  reAssignInstance={reAssignInstance}
                  getInstanceImage={getInstanceImage}
                  canReassign={this.props.canReassign}
                />
              }
            </div>
            <div className="w-px relative">
              <div
                className="h-full p-4 bg-grey-lighter-lighter rounded border pin-r border-light absolute border-l shadow-md transition-all"
                style={{
                  width: '24rem',
                  transition: 'transform 0.15s ease-in-out',
                  transform: !showTaskList && dataHistory ? 'translateX(0)' : 'translateX(115%)',
                }}
              >
                <WorkflowTaskDetail
                  dataHistory={dataHistory}
                  loading={loadingHistory}
                  loadingComponent={pulseLoader}
                  currentState={currentState}
                  handleReturn={async (e, index) => {
                    this.toggleTaskList(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkflowWindowLayout;
