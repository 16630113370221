import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import TreeMenuWrapper, { Item, ItemComponent } from 'react-simple-tree-menu';
import { PulseLoader } from 'halogenium';

import { MenuTree, useMenu } from './hooks/useMenu.hook';
import { OperationsTree, useOperations } from './hooks/useOperations.hook';
import { PermissionsTree, PERMISSIONS_TYPE, usePermissions } from './hooks/usePermissions.hook';
import { ReportsTree, useReports } from './hooks/useReports.hook';
import { BranchesTree, useBranches } from './hooks/useBranches.hook';
import { NavigationsTree, useNavigations } from './hooks/useNavigations.hook';
import { CatalogsTree, useCatalogs } from './hooks/useCatalogs.hook';

import closedFolderIcon from '../../../../ProcessesManager/icons/closedFolder';
import openedFolderIcon from '../../../../ProcessesManager/icons/openedFolder';
import './Restrictions.scss';
import { SelectedGroup, TreeItem } from '../../../models/types';
import openNodeIcon from '../../../icons/openNode';
import closeNodeIcon from '../../../icons/closeNode';
import addUpArrowIcon from '../../../icons/addUpArrow';
import disabledRestrictionIcon from '../../../icons/disabledRestriction';
import enabledRestrictionIcon from '../../../icons/enabledRestriction';
import crossUpArrowIcon from '../../../icons/crossUpArrow';
import crossDownArrowIcon from '../../../icons/crossDownArrow';
import addDownArrowIcon from '../../../icons/addDownArrow';
import noRestrictionsIcon from '../../../icons/noRestrictions';
import { GroupsAsTreeMenu } from '../../../models/groupsAsTreeMenu';
import { DISABLED_PERMISSION_KEY, EMPTY_STRING, ENABLED_PERMISSION_KEY, FREE_PERMISSION_KEY, DEFAULT_RESTRICTION_KEY } from '../../../models/constants';
import { FormulaModalViewModel } from './formulaModal/FormulaModalViewModel';
import { RestrictionObject } from '../../../models/restrictionObject';
import { getSpecificGroupPermissionsAsync } from '../../../../../api/securityManager';
import { Restriction } from './hooks/useRestrictions.hook';

type TreeNodes = MenuTree | OperationsTree | PermissionsTree | ReportsTree | BranchesTree | NavigationsTree | CatalogsTree | Tree;

interface Tree {
  key: string;
  label: string;
  restriction?: RestrictionObject;
  nodes: TreeNodes[];
}

interface Props {
  selectedGroup?: SelectedGroup;
  selectedRestriction?: RestrictionObject[];
  isEditing?: boolean;
  isModalFormulaOpen: boolean;
  onRestrictionsChangedAdd: (restriction: RestrictionObject[]) => void;
  restrictionsChanged: { [id: string]: RestrictionObject };
  onPermissionClick: () => void;
  onItemClick: (node: Item) => void;
  onModalFormulaChange: (isOpen: boolean) => void;
  onRadioChange: (event: { target: HTMLInputElement }) => void;
  onEnabledDateChange: (event: { target: HTMLInputElement }) => void;
  onRestoreChange: () => void;
  onHelperChange: (helper: string) => void;
  groupHelper: string;
  hasGroupPermissions: boolean;
  error: HTMLInputElement;
  menu: TreeNodes[];
  operations: TreeNodes[];
  permissions: TreeNodes[];
  reports: TreeNodes[];
  branches: TreeNodes[];
  navigations: TreeNodes[];
  catalogs: TreeNodes[];
  defaultRestriction: Restriction[];
}

const OPEN_ICON: string = 'OPEN';
const CLOSE_ICON: string = 'CLOSE';
const MENU_RESTRICTIONS: string = 'M';
const OPERATIONS_RESTRICTIONS: string = 'O';
const REPORTS_RESTRICTIONS: string = 'Q';
const CATALOGS_RESTRICTIONS: string = 'C';
const BRANCHES_RESTRICTIONS: string = 'B';
const PERMISSIONS_RESTRICTIONS: string = 'P';
const SELECTED_RESTRICTION_COLOR: string = '#6767ca';
const SELECTED_RESTRICTION_BACKGORUND: string = 'rgba(190, 198, 209, 0.616)';

export function RestrictionsView({
  groupHelper,
  selectedGroup,
  isEditing,
  selectedRestriction,
  onRestrictionsChangedAdd,
  restrictionsChanged,
  onPermissionClick,
  onRadioChange,
  onEnabledDateChange,
  onItemClick,
  onRestoreChange,
  isModalFormulaOpen,
  onModalFormulaChange,
  onHelperChange,
  hasGroupPermissions,
  error,
  menu,
  operations,
  permissions,
  reports,
  branches,
  navigations,
  catalogs,
  defaultRestriction
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const tree: TreeNodes[] = [
    {
      key: 'menu',
      label: intl.formatMessage({ id: 'menu' }),
      nodes: menu,
    },
    {
      key: 'operationsSidebar',
      label: intl.formatMessage({ id: 'operationsSidebar' }),
      nodes: operations,
    },
    {
      key: 'permissions',
      label: intl.formatMessage({ id: 'permissions' }),
      nodes: permissions,
    },
    {
      key: 'reports',
      label: intl.formatMessage({ id: 'reportsSidebar' }),
      nodes: reports,
    },
    {
      key: 'branches',
      label: intl.formatMessage({ id: 'branches' }),
      nodes: branches,
    },
    {
      key: 'catalogs',
      label: intl.formatMessage({ id: 'catalogs' }),
      nodes: catalogs,
    },
    {
      key: 'navigations',
      label: intl.formatMessage({ id: 'navigations' }),
      nodes: navigations,
    },
  ];

  function renderIcon(restriction: RestrictionObject, type: string): JSX.Element {
    return (
      <div className="flex items-center justify-center" style={{ paddingRight: '10px' }}>
        {type === OPEN_ICON ? closeNodeIcon : openNodeIcon}
        {getPermissionIcon(restriction)}
      </div>
    );
  }

  function renderNoChildIcon(restriction: RestrictionObject): JSX.Element {
    return <div style={{ width: '40px', paddingLeft: '10px' }}>{getPermissionIcon(restriction)}</div>;
  }

  function getPermissionIcon(restriction: RestrictionObject): JSX.Element {
    const currentRestriction =
      restrictionsChanged[restriction.id] && restrictionsChanged[restriction.id].type === restriction.type
        ? restrictionsChanged[restriction.id]
        : restriction;

    return (
      <div>
        {currentRestriction.type !== CATALOGS_RESTRICTIONS &&
          currentRestriction.type !== BRANCHES_RESTRICTIONS
          ? renderPermissionIcon(currentRestriction)
          : currentRestriction.allows === 1
            ? enabledRestrictionIcon
            : disabledRestrictionIcon}
      </div>
    );
  }

  function renderPermissionIcon(restriction: RestrictionObject): JSX.Element {
    let permissionIcon = null;

    return (
      <div>
        {restriction.ownPermission === 0
          ? disabledRestrictionIcon
          : restriction.ownPermission === 1
            ? enabledRestrictionIcon
            : restriction.titlePermission === 0
              ? crossUpArrowIcon
              : restriction.titlePermission === 1
                ? addUpArrowIcon
                : restriction.parentPermission === 0
                  ? crossDownArrowIcon
                  : restriction.parentPermission === 1
                    ? addDownArrowIcon
                    : permissionIcon ?
                      permissionIcon
                      : noRestrictionsIcon}
      </div>
    );
  }

  function onSelectChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    if (defaultRestriction) {
      const updatedRestrictions = defaultRestriction.map((restriction) => {
        if (restriction.title === DEFAULT_RESTRICTION_KEY) {
          restriction.id = DEFAULT_RESTRICTION_KEY;
        }
        restriction.allows = event.target.value || "0";
        restriction.allow = event.target.value || "0";
        return { ...restriction };
      });
      onRestrictionsChangedAdd(updatedRestrictions);
    }
  }

  function currentRadioRestriction(restriction?: RestrictionObject[]): string {
    let radioValue: string | null = '';
    if (restriction && restriction.length !== 0) {
      restriction.forEach((r) => {
        const currentRestriction = restrictionsChanged[r.id] || r;
        radioValue = currentRestriction.ownPermission !== null ? currentRestriction.ownPermission.toString() : 'null';
      })
      return radioValue;
    }

    return EMPTY_STRING;
  }
  return (
    <>
      <fieldset className="font-mono rounded border border-light flex relative mt-2 restrictions-field" style={{ border: '1px solid grey' }}>
        <legend className="ml-2">{intl.formatMessage({ id: 'restrictions' })}</legend>
        {isLoading ? (
          <div className="w-full p-2 px-6 flex justify-center items-center h-full">
            <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
          </div>
        ) : (
          <div className="restrictions-container" style={{ maxHeight: "420px", overflowY: "scroll" }}>
            {isModalFormulaOpen && (
              <FormulaModalViewModel
                groupHelper={groupHelper}
                onHelperChange={onHelperChange}
                onModalChange={onModalFormulaChange}
                selectedRestriction={selectedRestriction}
                onRestrictionsChangedAdd={onRestrictionsChangedAdd}
                restrictionsChanged={restrictionsChanged}
              />
            )}
            <div className="restrictions-tree">
              <TreeMenuWrapper data={tree} hasSearch={false} onClickItem={onItemClick}>
                {({ items: nodes }) => (
                  <ul className="tree-item-group">
                    {nodes.map((node) => {
                      const avoidLevel0 = 1;
                      const paddingLeftWithNodes = (node.level + avoidLevel0) * 10 + 'px';
                      const paddingLeftWithoutNodes = (node.level + avoidLevel0) * 10 + 'px';
                      return (
                        <div key={node.key}>
                          {node.level === 0 ? (
                            <div>
                              <ItemComponent
                                {...node}
                                style={{
                                  paddingLeft: node.hasNodes ? paddingLeftWithNodes : paddingLeftWithoutNodes,
                                  backgroundColor: `${selectedRestriction && node.restriction && selectedRestriction.includes(node.restriction) ? SELECTED_RESTRICTION_BACKGORUND : ''
                                    }`,
                                  color: `${selectedRestriction && node.restriction && selectedRestriction.includes(node.restriction) ? SELECTED_RESTRICTION_COLOR : ''}`,
                                }}
                                openedIcon={openedFolderIcon}
                                closedIcon={closedFolderIcon}
                              />
                            </div>
                          ) : node.hasNodes ? (
                            <ItemComponent
                              {...node}
                              style={{
                                paddingLeft: paddingLeftWithNodes,
                                backgroundColor: `${selectedRestriction && node.restriction && selectedRestriction.includes(node.restriction) ? SELECTED_RESTRICTION_BACKGORUND : ''
                                  }`,
                                color: `${selectedRestriction && node.restriction && selectedRestriction.includes(node.restriction) ? SELECTED_RESTRICTION_COLOR : ''}`,
                              }}
                              openedIcon={node.restriction ? renderIcon(node.restriction, OPEN_ICON) : disabledRestrictionIcon}
                              closedIcon={node.restriction ? renderIcon(node.restriction, CLOSE_ICON) : disabledRestrictionIcon}
                            />
                          ) : (
                            <div
                              className={`flex rstm-tree-item-level${node.level} items-center border-b`}
                              style={{
                                paddingLeft: paddingLeftWithoutNodes,
                                backgroundColor: `${selectedRestriction && node.restriction && selectedRestriction.includes(node.restriction) ? SELECTED_RESTRICTION_BACKGORUND : ''
                                  }`,
                                color: `${selectedRestriction && node.restriction && selectedRestriction.includes(node.restriction) ? SELECTED_RESTRICTION_COLOR : ''}`,
                              }}
                            >
                              {node.restriction ? renderNoChildIcon(node.restriction) : disabledRestrictionIcon}
                              <ItemComponent
                                {...node}
                                style={{ paddingLeft: '0rem', paddingRight: '10px', backgroundColor: 'transparent', border: 'none' }}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </ul>
                )}
              </TreeMenuWrapper>
            </div>
            <div className="restriction-options">
              <button
                onClick={onPermissionClick}
                disabled={
                  !selectedRestriction || !selectedRestriction.some(restriction =>
                    restriction.type === PERMISSIONS_RESTRICTIONS &&
                    hasGroupPermissions &&
                    currentRadioRestriction([restriction]) === '1'
                  )
                } className={"buttonStyle font-bold py-2 px-4 rounded"}
              >
                {intl.formatMessage({ id: 'permission' })}...
              </button>
              <div className="rescrictions-select">
                <div>{intl.formatMessage({ id: 'byDefault' })}:</div>
                <select
                  className="border border-grey-lighter"
                  onChange={onSelectChange}
                  disabled={
                    !defaultRestriction || !hasGroupPermissions || (defaultRestriction[0] === null) ||
                    !defaultRestriction.every(restriction =>
                      (restriction === null) ||
                      (restriction.type === MENU_RESTRICTIONS ||
                        restriction.type === OPERATIONS_RESTRICTIONS ||
                        restriction.type === REPORTS_RESTRICTIONS)
                    )
                  }
                  value={
                    defaultRestriction && defaultRestriction.length > 0
                      ? defaultRestriction.map((restriction) => {
                        if (restriction === null)
                          return;
                        return restriction.allow === null
                          ? FREE_PERMISSION_KEY
                          : restriction.allow.toString();
                      })
                      : ""
                  }
                >
                  <option value="1">{intl.formatMessage({ id: 'enabled' })}</option>
                  <option value="0">{intl.formatMessage({ id: 'disabled' })}</option>
                  <option value="2">{intl.formatMessage({ id: 'free' })}</option>
                </select>
              </div>
              <fieldset className="rounded border border-light restriction-state-container">
                <legend>{intl.formatMessage({ id: 'state' })}</legend>
                <div>
                  <div className="radio-container">
                    <input
                      disabled={!selectedRestriction || !hasGroupPermissions}
                      onChange={onRadioChange}
                      type="radio"
                      id="enabled"
                      name="ownPermission"
                      value="1"
                      checked={currentRadioRestriction(selectedRestriction) === '1'}
                    />
                    <label htmlFor="enabled">{intl.formatMessage({ id: 'enabled' })}</label>
                  </div>
                  <div className="radio-container">
                    <input
                      disabled={!selectedRestriction || !hasGroupPermissions}
                      onChange={onRadioChange}
                      type="radio"
                      id="disabled"
                      name="ownPermission"
                      value="0"
                      checked={currentRadioRestriction(selectedRestriction) === '0'}

                    />
                    <label htmlFor="disabled">{intl.formatMessage({ id: 'disabled' })}</label>
                  </div>
                  {
                    (!selectedRestriction || !selectedRestriction.some(restriction =>
                      restriction.type === BRANCHES_RESTRICTIONS || restriction.type === CATALOGS_RESTRICTIONS
                    )) && (
                      <div className="radio-container">
                        <input
                          disabled={!selectedRestriction || !hasGroupPermissions}
                          onChange={onRadioChange}
                          type="radio"
                          id="free"
                          name="ownPermission"
                          value="2"
                          checked={currentRadioRestriction(selectedRestriction) === '2' || currentRadioRestriction(selectedRestriction) === 'null'}
                        />
                        <label htmlFor="free">{intl.formatMessage({ id: 'free' })}</label>
                      </div>
                    )
                  }
                </div>
                <button
                  disabled={!hasGroupPermissions}
                  className={"buttonStyle font-bold py-2 px-4 rounded restrictions-restore-button"}
                  onClick={onRestoreChange}
                >
                  {intl.formatMessage({ id: 'restore' })}
                </button>
              </fieldset>
              <fieldset className="rounded border border-light flex restriction-state-container">
                <legend>{intl.formatMessage({ id: 'enablingLimit' })}</legend>
                <input
                  disabled={
                    !selectedRestriction?.length ||
                    !hasGroupPermissions ||
                    selectedRestriction[0].type !== OPERATIONS_RESTRICTIONS
                  }
                  type="date"
                  onChange={onEnabledDateChange}
                  defaultValue={selectedRestriction && selectedRestriction[0]?.fechaHabilitacion}
                />
              </fieldset>

            </div>
          </div>
        )}
      </fieldset>
      {error}
    </>
  );
}
