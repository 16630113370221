import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SelectedGroup } from '../../models/types';
import { SearchModalViewModel, SearchModalViewModelProps } from './searchModal/SearchModalViewModel';
import { GroupsAsTreeMenu } from '../../models/groupsAsTreeMenu';
import {
  ADD_GROUP_MODAL,
  BRANCH_HELPER,
  CONFIGURATIONS_MODAL,
  CRYPTO_MODAL,
  GROUP_HELPER,
  MACHINES_HELPER,
  NO_MODAL_OPEN,
  PASS_DB__MODAL,
  PASS_SWIFT_MODAL,
  PRINT_MODAL,
  REMOVE_ERROR_MODAL,
  REMOVE_MODAL,
  SEARCH_MODAL,
  TYPE_GROUP,
  TYPE_USER,
  USER_HELPER,
  INMEDIATOSUPERIOR_HELPER,
  USER_MODAL,
} from '../../models/constants';
import { UserBasicInfo } from '../../models/userBasicInfo';
import { Machine } from '../../models/machine';
import { RemoveModalViewProps, RemoveModalView } from './removeModal/RemoveModalView';
import { UserInfoModalViewModel, UserInfoModalViewModelProps } from './userInfoModal/UserInfoModalViewModel';
import { GroupInfoModalViewModel, AddGroupModalViewProps } from './groupInfoModal/GroupInfoModalViewModel';
import { DataHelper, DataHelperProps } from '../dataHelper/DataHelper';
import { getGroupBranchesAsync, getMachinesInfo } from '../../../../api/securityManager';
import { PasswordDBModalViewModel } from './configsModal/passwordDBModal/PasswordDBModalViewModel';
import { PrintModalViewModel, PrintViewModelProps } from './printModal/PrintModalViewModel';
import { ConfigsModalView, ConfigsModalViewProps } from './configsModal/ConfigsModalView';
import { PasswordSWIFTModalViewModel } from './configsModal/passwordSWIFTModal/PasswordSWIFTModalViewModel';
import { CryptogramModalViewModel } from './configsModal/cryptogramModal/CryptogramModalViewModel';
import { WarningModalView, WarningModalViewProps } from './warningModal/WarningModalView';

export interface Column {
  Header: string;
  accessor: string;
  decimals: number;
  isReturned: boolean;
  isSortable: boolean;
  length: number;
  type: number;
}

export interface Branch {
  branchNumber: number;
  description: string;
}

export interface GroupHelper {
  group: string;
  description: string;
}

export interface UserHelper {
  username: string;
  name: string;
}

interface SecurityToolsModalViewModelProps {
  selectedGroup?: SelectedGroup;
  onModalChange: (openModalName: string) => void;
  modalOpen: string;
  securityGroups: GroupsAsTreeMenu[];
  removeGroupAsync: (group: GroupsAsTreeMenu) => void;
  removeUserAsync: (user: UserBasicInfo) => void;
  isEditing: boolean;
  onChangesWarningChange: (isShowing: boolean, undoChanges: boolean) => void;
  changesWarning: boolean;
  onSelectedItemChange: (node: SelectedGroup) => void;
  onSelectGroup: (group: SelectedGroup) => void;
  selectedItem?: SelectedGroup;
  hasGroupPermissions: boolean;
  hasUserPermissions: boolean;
  isWarningToEdit: boolean;
  onIsEditingChange: (isEditing: boolean) => void;
  onHasModifiedChange: (hasModified: boolean) => void;
  hasModifiedInformation: boolean;
  onRefresh: () => void;
  setChangesWarning: (isShowing: boolean) => void;
  setIsLoadingGroup: (isLoading: boolean) => void;


}

export function SecurityToolsModalViewModel({
  modalOpen,
  onModalChange,
  selectedGroup,
  securityGroups,
  removeGroupAsync,
  removeUserAsync,
  isEditing,
  changesWarning,
  onChangesWarningChange,
  onSelectedItemChange,
  onSelectGroup,
  selectedItem,
  hasGroupPermissions,
  hasUserPermissions,
  isWarningToEdit,
  onIsEditingChange,
  onHasModifiedChange,
  hasModifiedInformation,
  onRefresh,
  setChangesWarning,
  setIsLoadingGroup,
  
}: SecurityToolsModalViewModelProps): JSX.Element {
  const [newUserInfo, setNewUserInfo] = useState<UserBasicInfo>(selectedGroup as UserBasicInfo);
  const [helperOpened, setHelperOpened] = useState<string>('');
  const [helperGroup, setHelperGroup] = useState<string>('');
  const [helperUsername, setHelperUsername] = useState<string>('');
  const [helperInmediatoSuperior, setHelperInmediatoSuperior] = useState<string>('');
  const [helperName, setHelperName] = useState<string>('');
  const [helperMachineNumber, setHelperMachineNumber] = useState<number>(-1);
  const [helperBranch, setHelperBranch] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [securityMachines, setSecurityMachines] = useState<Machine[]>([]);
  const [securityBranches, setSecurityBranches] = useState<Branch[]>([]);
  const intl = useIntl();
  const groupsDataRowsHelper: GroupHelper[] = groupsToHelper();

  const groupsDataColumnsHelper: Column[] = [
    {
      Header: intl.formatMessage({ id: 'group' }),
      accessor: 'group',
      decimals: 0,
      isReturned: false,
      isSortable: true,
      length: 10,
      type: 2,
    },
    {
      Header: intl.formatMessage({ id: 'descripcion' }),
      accessor: 'description',
      decimals: 0,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 2,
    },
  ];
  const usersDataRowsHelper: UserHelper[] = usersToHelper();
  const usersDataColumnsHelper: Column[] = [
    {
      Header: intl.formatMessage({ id: 'user' }),
      accessor: 'username',
      decimals: 0,
      isReturned: false,
      isSortable: true,
      length: 10,
      type: 2,
    },
    {
      Header: intl.formatMessage({ id: 'nombre' }),
      accessor: 'name',
      decimals: 0,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 2,
    },
  ];
  const branchesDataRowsHelper: Branch[] = securityBranches;
  const branchesDataColumnsHelper: Column[] = [
    {
      Header: intl.formatMessage({ id: 'sucursal' }),
      accessor: 'branchNumber',
      decimals: 0,
      isReturned: false,
      isSortable: true,
      length: 10,
      type: 2,
    },
    {
      Header: intl.formatMessage({ id: 'descripcion' }),
      accessor: 'description',
      decimals: 0,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 2,
    },
  ];
  const machinesDataRowsHelper: Machine[] = securityMachines;
  const machinesDataColumnsHelper: Column[] = [
    {
      Header: intl.formatMessage({ id: 'numero' }),
      accessor: 'number',
      decimals: 0,
      isReturned: false,
      isSortable: true,
      length: 10,
      type: 2,
    },
    {
      Header: intl.formatMessage({ id: 'sucursal' }),
      accessor: 'branch',
      decimals: 0,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 2,
    },
    {
      Header: intl.formatMessage({ id: 'descripcion' }),
      accessor: 'description',
      decimals: 0,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 2,
    },
  ];

  async function getSecurityMachinesDataAsync(): Promise<void> {
    const machines: [number, number, string][] = await getMachinesInfo(newUserInfo?.branchNumber);
    const mappedMachines: Machine[] = machines.map((machine) => {
      return {
        number: machine[0],
        branch: machine[1],
        description: machine[2],
      };
    });
    setIsLoading(false);
    setSecurityMachines(mappedMachines);
  }

  async function getSecurityBranchesDataAsync(): Promise<void> {
    const branches: Branch[] = await getGroupBranchesAsync();
    setIsLoading(false);
    setSecurityBranches(branches);
  }

  function getModalPropsRemove(): RemoveModalViewProps {
    const type: string = selectedGroup?.type == TYPE_GROUP ? intl.formatMessage({ id: 'group' }) : intl.formatMessage({ id: 'userSidebar' });

    return {
      question: intl.formatMessage({ id: 'areYouSureYouWantToRemove' }) + type + ' ' + `${selectedGroup?.name}` + '?',
      onCancel: onModalChange,
      onAccept: onAcceptModalRemove,
      removeGroup: false,
    };
  }

  function getModalPropsRemoveError(): RemoveModalViewProps {
    const type: string = selectedGroup?.type == TYPE_GROUP ? intl.formatMessage({ id: 'group' }) : intl.formatMessage({ id: 'userSidebar' });

    return {
      question: intl.formatMessage({ id: 'the' }) + ' ' + type + ' ' + `${selectedGroup?.name}` + ' ' + intl.formatMessage({ id: 'cantBeDeleted' }),
      onCancel: onModalChange,
      onAccept: onModalChange,
      removeGroup: true,
    };
  }

  function getModalPropsSearch(): SearchModalViewModelProps {
    return {
      securityGroups: securityGroups,
      onModalChange: onModalChange,
      onSelectGroup,
      onIsEditingChange,
      onSelectedItemChange,
    };
  }

  function getModalPropsUserInfo(): UserInfoModalViewModelProps {
    return {
      onHasModifiedChange: onHasModifiedChange,
      hasModifiedInformation: hasModifiedInformation,
      onModalChange: onModalChange,
      isAddUser: !isEditing,
      selectedUser: selectedGroup as UserBasicInfo,
      selectedGroup: selectedGroup as GroupsAsTreeMenu,
      securityGroups: securityGroups,
      onHelperChange: onHelperChange,
      helperGroup: helperGroup,
      helperBranch: helperBranch,
      helperMachineNumber: helperMachineNumber,
      helperUsername: helperUsername,
      helperInmediatoSuperior: helperInmediatoSuperior,
      helperName: helperName,
      changesWarning: changesWarning,
      hasUserPermissions,
      onRefresh,
      setChangesWarning,
      onChangesWarningChange,
      newUserInfo, 
      setNewUserInfo, 

    };
  }

  function getModalPropsConfigs(): ConfigsModalViewProps {
    return {
      onModalChange: onModalChange,
    };
  }

  function getModalPropsChangesWarning(): WarningModalViewProps {
    return {
      onAccept: onWarningChangesAccept,
      onCancel: onWarningChangesCancel,
      question: intl.formatMessage({ id: 'unsavedChangesWillBeLost' }),
    };
  }

  function getModalPropsGroupHelper(): DataHelperProps {
    return {
      title: intl.formatMessage({ id: 'select' }) + " - " + intl.formatMessage({ id: 'group' }),
      rows: groupsDataRowsHelper,
      columns: groupsDataColumnsHelper,
      onAccept: onHelperChange,
      onCancel: onHelperChange,
      onChange: onGroupHelperChange,
    };
  }

  function getModalPropsUserHelper(helperChangeEvent: (indexSelected: number) => void): DataHelperProps {
    return {
      title: intl.formatMessage({ id: 'select' }) + " - " + intl.formatMessage({ id: 'usuario' }),
      rows: usersDataRowsHelper,
      columns: usersDataColumnsHelper,
      onAccept: onHelperChange,
      onCancel: onHelperChange,
      onChange: helperChangeEvent,
    };
  }

  function getModalPropsBranchHelper(): DataHelperProps {
    return {
      title: intl.formatMessage({ id: 'select' }) + " - " + intl.formatMessage({ id: 'sucursal' }),
      rows: branchesDataRowsHelper,
      columns: branchesDataColumnsHelper,
      onAccept: onHelperChange,
      onCancel: onHelperChange,
      onChange: onBranchHelperChange,
    };
  }

  function getModalPropsMachineNumberHelper(): DataHelperProps {
    return {
      title: intl.formatMessage({ id: 'select' }) + " - " + intl.formatMessage({ id: 'numero' }),
      rows: machinesDataRowsHelper,
      columns: machinesDataColumnsHelper,
      onAccept: onHelperChange,
      onCancel: onHelperChange,
      onChange: onMachineNumberHelperChange,
      isLoading,
    };
  }

  function getModalPropsAddGroup(): AddGroupModalViewProps {
    return {
      onHasModifiedChange: onHasModifiedChange,
      hasModifiedInformation: hasModifiedInformation,
      selectedGroup: selectedGroup as GroupsAsTreeMenu,
      onModalChange,
      helperGroup,
      onHelperChange,
      setIsLoadingGroup: setIsLoadingGroup,
      securityGroups: groupsToHelper(),
      isEditing,
      changesWarning: changesWarning,
      hasGroupPermissions,
      onRefresh,
      setChangesWarning,
      onChangesWarningChange,
    };
  }

  function getModalPropsPrint(): PrintViewModelProps {
    return {
      groups: groupsToHelper(),
      onModalChange,
    };
  }

  function onWarningChangesAccept(): void {
    const modalToOpen: string = selectedItem?.type === TYPE_USER ? USER_MODAL : ADD_GROUP_MODAL;
    onChangesWarningChange(false, true);
    selectedItem && onSelectGroup(selectedItem);
    onIsEditingChange(isWarningToEdit);
    onModalChange(modalToOpen);
    onHasModifiedChange(false);
  }

  function onWarningChangesCancel(): void {
    onChangesWarningChange(false, false);
    selectedGroup && onSelectedItemChange(selectedGroup);
  }

  function onAcceptModalRemove(modalOpenName: string): void {
    onModalChange(modalOpenName);
    const isRemovableNode: boolean =
      selectedGroup?.type === TYPE_USER ||
      ((selectedGroup as GroupsAsTreeMenu).users.length === 0 && (selectedGroup as GroupsAsTreeMenu).subGroups.length === 0);
    if (selectedGroup && isRemovableNode) {
      selectedGroup.type === TYPE_GROUP && removeGroupAsync(selectedGroup as GroupsAsTreeMenu);
      selectedGroup.type === TYPE_USER && removeUserAsync(selectedGroup as UserBasicInfo);
    } else {
      onModalChange(REMOVE_ERROR_MODAL);
    }
  }

  function onGroupHelperChange(data: number): void {
    setHelperGroup(groupsDataRowsHelper[data].group);
  }

  function onUserHelperChange(data: number): void {
    setHelperUsername(usersDataRowsHelper[data].username);
    setHelperName(usersDataRowsHelper[data].name);
  }

  function onInmediatoSuperiorHelperChange(data: number): void {
    setHelperInmediatoSuperior(usersDataRowsHelper[data].username);
  }

  function onBranchHelperChange(data: number): void {
    setHelperBranch(branchesDataRowsHelper[data].branchNumber);
  }

  function onMachineNumberHelperChange(data: number): void {
    setHelperMachineNumber(machinesDataRowsHelper[data].number);
  }

  function onHelperChange(helper: string): void {
    setHelperOpened(helper);
    if (helper) {
      setHelperGroup("")
    }
    if (helper === MACHINES_HELPER) {
      setIsLoading(true);
      getSecurityMachinesDataAsync();
    } else if (helper === BRANCH_HELPER) {
      setIsLoading(true);
      getSecurityBranchesDataAsync();
    }
  }

  function mapGroupToHelper(group: GroupsAsTreeMenu, groupsToHelper: GroupHelper[]): void {
    if (group.subGroups) {
      group.subGroups.forEach((subGroup) => {
        mapGroupToHelper(subGroup, groupsToHelper);
      });
    }
    groupsToHelper.push({
      group: group.group,
      description: group.description,
    });
  }

  function groupsToHelper(): GroupHelper[] {
    const groupsToHelper: GroupHelper[] = [];
    securityGroups.map((group) => {
      mapGroupToHelper(group, groupsToHelper);
    });

    return groupsToHelper;
  }

  function mapUserToHelper(group: GroupsAsTreeMenu, usersToHelper: UserHelper[]): void {
    if (group.users) {
      group.users.forEach((user) => {
        usersToHelper.push({
          username: user.username,
          name: user.name,
        });
      });
    }
    if (group.subGroups) {
      group.subGroups.forEach((subGroup) => {
        mapUserToHelper(subGroup, usersToHelper);
      });
    }
  }

  function usersToHelper(): UserHelper[] {
    const usersToHelper: UserHelper[] = [];
    securityGroups.map((group) => {
      mapUserToHelper(group, usersToHelper);
    });

    return usersToHelper;
  }

  return (
    <div>
      {changesWarning && <WarningModalView {...getModalPropsChangesWarning()} />}
      {modalOpen === REMOVE_MODAL && <RemoveModalView {...getModalPropsRemove()} />}
      {modalOpen === REMOVE_ERROR_MODAL && <RemoveModalView {...getModalPropsRemoveError()} />}
      {modalOpen === SEARCH_MODAL && <SearchModalViewModel {...getModalPropsSearch()} />}
      {modalOpen === USER_MODAL && <UserInfoModalViewModel {...getModalPropsUserInfo()} />}
      {modalOpen === ADD_GROUP_MODAL && <GroupInfoModalViewModel {...getModalPropsAddGroup()} />}
      {modalOpen === PRINT_MODAL && <PrintModalViewModel {...getModalPropsPrint()} />}
      {modalOpen === CONFIGURATIONS_MODAL && <ConfigsModalView {...getModalPropsConfigs()} />}
      {modalOpen === PASS_DB__MODAL && <PasswordDBModalViewModel {...getModalPropsConfigs()} />}
      {modalOpen === PASS_SWIFT_MODAL && <PasswordSWIFTModalViewModel {...getModalPropsConfigs()} />}
      {modalOpen === CRYPTO_MODAL && <CryptogramModalViewModel {...getModalPropsConfigs()} />}
      {helperOpened === GROUP_HELPER && <DataHelper {...getModalPropsGroupHelper()} />}
      {helperOpened === USER_HELPER && <DataHelper {...getModalPropsUserHelper(onUserHelperChange)} />}
      {helperOpened === INMEDIATOSUPERIOR_HELPER && <DataHelper {...getModalPropsUserHelper(onInmediatoSuperiorHelperChange)} />}
      {helperOpened === BRANCH_HELPER && <DataHelper {...getModalPropsBranchHelper()} />}
      {helperOpened === MACHINES_HELPER && <DataHelper {...getModalPropsMachineNumberHelper()} />}
    </div>
  );
}
