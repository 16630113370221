import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { getLogger } from '../logger';
import { handleEndpointError } from './handlers';

const retryTimeoutMS = 20;

export let gotoLine = (operationId, transactionNumber, lineNumber) => {
  if (!getLogger().MutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    const headers = { 'Content-Type': 'application/json' };
    getLogger().PrintLog('gotoLine T' + transactionNumber + ' L' + lineNumber);
    return axios
      .post(
        `${API}TopazWebClientWS/resources/operations/gotoLine?operationID=${operationId}&sessionId=${session}&transactionNumber=${transactionNumber}&lineNumber=${lineNumber}`,
        null,
        { headers },
      )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleEndpointError(err);
      })
      .finally(() => {
        getLogger().MutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(gotoLine(operationId, transactionNumber, lineNumber));
      }, retryTimeoutMS);
    });
  }
};

export let gotoLineWithBody = (operationId, transactionNumber, lineNumber, body) => {
  if (!getLogger().MutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    var headers = { 'Content-Type': 'application/json' };
    getLogger().PrintLog('gotoLineWithBody T' + transactionNumber + ' L' + lineNumber, body);
    return axios
      .post(
        `${API}TopazWebClientWS/resources/operations/gotoLine?operationID=${operationId}&sessionId=${session}&transactionNumber=${transactionNumber}&lineNumber=${lineNumber}`,
        body,
        { headers },
      )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleEndpointError(err);
      })
      .finally(() => {
        getLogger().MutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(gotoLineWithBody(operationId, transactionNumber, lineNumber, body));
      }, retryTimeoutMS);
    });
  }
};

export let acceptDialog = (operationId, dialogNumber, body) => {
  if (!getLogger().MutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    var headers = { 'Content-Type': 'application/json' };
    getLogger().PrintLog('acceptDialog', body);
    return axios
      .post(
        `${API}TopazWebClientWS/resources/operations/acceptDialog?sessionId=${session}&operationID=${operationId}&dialogNumber=${dialogNumber}`,
        body,
        { headers },
      )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleEndpointError(err);
      })
      .finally(() => {
        getLogger().MutexState(false);
      });
  }
};

export let cancelDialog = (operationId, dialogNumber) => {
  if (!getLogger().MutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    getLogger().PrintLog('cancelDialog');
    return axios
      .post(`${API}TopazWebClientWS/resources/operations/cancelDialog?sessionId=${session}&operationID=${operationId}&dialogNumber=${dialogNumber}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleEndpointError(err);
      })
      .finally(() => {
        getLogger().MutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(cancelDialog(operationId, dialogNumber));
      }, retryTimeoutMS);
    });
  }
};

let lastRunParams = {
  transactionNumber: null,
  lineNumber: null,
};

export let run = (operationId, transactionNumber, lineNumber, body) => {
  if (lastRunParams.transactionNumber === transactionNumber && lastRunParams.lineNumber === lineNumber) return;

  if (!getLogger().MutexState(true)) {
    lastRunParams.transactionNumber = transactionNumber;
    lastRunParams.lineNumber = lineNumber;
    let session = browserStorage.sessionGet(USER_SESSION);
    var headers = { 'Content-Type': 'application/json' };
    getLogger().PrintLog('run T' + transactionNumber + ' L' + lineNumber, body);
    return axios
      .post(
        `${API}TopazWebClientWS/resources/operations/run?operationID=${operationId}&sessionId=${session}&transactionNumber=${transactionNumber}&lineNumber=${lineNumber}`,
        body,
        { headers },
      )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleEndpointError(err);
      })
      .finally(() => {
        lastRunParams.transactionNumber = null;
        lastRunParams.lineNumber = null;
        getLogger().MutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(run(operationId, transactionNumber, lineNumber, body));
      }, retryTimeoutMS);
    });
  }
};

export let undo = (operationId, transactionNumber, lineNumber) => {
  if (!getLogger().MutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    getLogger().PrintLog('undo');
    return axios
      .post(
        `${API}TopazWebClientWS/resources/operations/undo?operationID=${operationId}&sessionId=${session}&transactionNumber=${transactionNumber}&lineNumber=${lineNumber}`,
      )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleEndpointError(err);
      })
      .finally(() => {
        getLogger().MutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(undo(operationId, transactionNumber, lineNumber));
      }, retryTimeoutMS);
    });
  }
};

export let noticeDecided = async (operationId, noticeCode) => {
  if (!getLogger().MutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    getLogger().PrintLog('noticeDecided');
    return axios
      .post(`${API}TopazWebClientWS/resources/operations/noticeDecided?operationID=${operationId}&sessionId=${session}&noticeCode=${noticeCode}`, {})
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleEndpointError(err);
      })
      .finally(() => {
        getLogger().MutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(noticeDecided(operationId, noticeCode));
      }, retryTimeoutMS);
    });
  }
};

export let runButton = (operationId, transactionNumber, lineNumber) => {
  if (!getLogger().MutexState(true)) {
    let session = browserStorage.sessionGet(USER_SESSION);
    getLogger().PrintLog('runButton T' + transactionNumber + ' L' + lineNumber);
    return axios
      .post(
        `${API}TopazWebClientWS/resources/operations/runButton?operationID=${operationId}&sessionId=${session}&transactionNumber=${transactionNumber}&lineNumber=${lineNumber}`,
      )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleEndpointError(err);
      })
      .finally(() => {
        getLogger().MutexState(false);
      });
  } else {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve(runButton(operationId, transactionNumber, lineNumber));
      }, retryTimeoutMS);
    });
  }
};
