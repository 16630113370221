import React from 'react';

import mime from 'mime-types';
import { FormattedMessage } from 'react-intl';
import { mimeCheck } from '../../../UI-kit/helpers/fileHelper';
import GenerateExcelButton from './GenerateExcelButton';
import { URL_BASE_REPORTS } from './Constants';
import Axios from 'axios';

const ReportPreview = ({ report }) => {
  const fileName = `${report.name}.${report.fileExtension}`;
  const mimeType = mime.lookup(fileName);
  if (report.fileExtension && report.fileExtension.toUpperCase() === 'TXT') {
    report.destinoWeb = 1;
  }

  const fileNameExcel = report.name.split('.').shift() + '.xlsx';
  const fileNameCSV = report.name.split('.').shift() + '.csv';

  const downloadURI = async (urlFile, filename) => {
    let response = await Axios.get(urlFile);
    // eslint-disable-next-line no-undef
    var blob = new Blob([response.data], { type: 'text/plain;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const getNameWithoutExt = (name) => {
    name.replaceAll('.pdf', '');
  };

  return (
    <div className="flex flex-col w-full h-full p-4 rounded shadow bg-grey-lighter-lighter">
      <h2 className="text-sm mb-4">
        {`${report.specificationId} - ${report.description}`}
        {report.generateExcel && (
          <div className="flex justify-center">
            <GenerateExcelButton body={report.bodyToGenerateReport} />
          </div>
        )}
        {(report.destinoWeb === 2 || report.destinoWeb === 3) && (
          <a
            href={report.type === 'JASPER' ? `${URL_BASE_REPORTS}${fileNameExcel}` : `${URL_BASE_REPORTS}${fileNameCSV}`}
            download={`${getNameWithoutExt(report.description) || 'ReporteTopaz'}.${report.fileExtensionExcel}`}
            rel="noopener noreferrer"
            target="_blank"
            className="flex justify-center"
            style={{ textDecoration: 'none' }}
          >
            <button className="border bg-primary rounded shadow py-2 px-8 text-white text-sm p-2 mt-2">
              <FormattedMessage id="downloadExcel" defaultMessage={`Descargar Excel`} />
            </button>
          </a>
        )}
      </h2>
      {report.destinoWeb !== 2 &&
        (mimeCheck(mimeType) ? (
          report.destinoWeb === 1 ? (
            <div>
              <div>
                <button
                  onClick={(e) => {
                    downloadURI(`${URL_BASE_REPORTS}${fileName}`, fileName);
                  }}
                  className="border bg-primary rounded shadow py-2 px-8 text-white text-sm p-2 mt-2"
                >
                  <FormattedMessage id="download" defaultMessage={`Descargar`} />
                </button>
              </div>
              <embed
                style={{ height: 800 }}
                className="w-full h-full m-2 bg-white"
                src={`${URL_BASE_REPORTS}${fileName}#view=FitH`}
                type={mimeType}
                title="Reporte"
              />
            </div>
          ) : (
            <embed className="w-full h-full m-2 bg-white" src={`${URL_BASE_REPORTS}${fileName}#view=FitH`} type={mimeType} title="Reporte" />
          )
        ) : (
          <a
            href={`${URL_BASE_REPORTS}${fileName}`}
            download={`${report.description || 'ReporteTopaz'}.${report.fileExtension}`}
            rel="noopener noreferrer"
            target="_blank"
            className="flex justify-center"
            style={{ textDecoration: 'none' }}
          >
            <button className="border bg-primary rounded shadow py-2 px-8 text-white text-sm p-2 mt-2">
              <FormattedMessage id="download" defaultMessage={`Descargar`} />
            </button>
          </a>
        ))}
    </div>
  );
};

export default ReportPreview;
