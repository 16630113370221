/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import Draggable from 'react-draggable';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ABOVE_ALL } from '../constants/zIndexValues';
import { addValueTest } from '../../src/components/Testing/HandleLineTest';

import Overlay from './Overlay';
import IcoMoon from 'react-icomoon';
import FocusTrap from 'react-focus-trap';

const dictionaryLevel = {
  0: { text: 'Panic', color: '#FF0000' },
  1: { text: 'Error', color: '#A52A2A' },
  2: { text: 'Adver', color: '#FFD700' },
  3: { text: 'Notif', color: '#8FBC8F' },
  4: { text: 'Fase', color: '#228B22' },
  5: { text: 'Traza', color: '#D3D3D3' },
  6: { text: 'Debug', color: '#transparent' }, // #FFFFFF
};

const CellLevel = ({ level }) => {
  let textLevel = dictionaryLevel[level].text || '';
  let colorLevel = dictionaryLevel[level].color || 'transparent';

  return (
    <div className="w-full h-full text-center" style={{ backgroundColor: colorLevel }}>
      <span>{textLevel}</span>
    </div>
  );
};

const Item = ({ title, value }) => {
  return (
    <div className="flex">
      <label className="font-bold">{title}:</label>
      <div className="ml-1">{value}</div>
    </div>
  );
};

const dateFormat = (value) => {
  const year = value.substr(0, 4);
  const month = value.substr(4, 2);
  const day = value.substr(6, 2);
  return `${day}/${month}/${year}`;
};

const roundDecimal = (value) => Math.round(value * 1000) / 1000;

const getAllInfoinOrder = (commissionsData) => {
  let generalData = commissionsData.info.observaciones;
  commissionsData.commissionList.forEach((comission) => {
    generalData = generalData.concat(comission.info.observaciones);
    comission.taxesResults &&
      comission.taxesResults.forEach((tax) => {
        generalData = generalData.concat(tax.info.observaciones);
      });
  });
  return generalData.sort((valueA, valueB) => valueA.order - valueB.order);
};

export class CommissionsInformation extends Component {
  render() {
    const { showInformation, infoCommission, commissionsData, showAll = false, intl } = this.props;

    const data = showAll ? getAllInfoinOrder(commissionsData) : infoCommission.info['observaciones'];
    const columns = [
      {
        Header: 'Level',
        accessor: 'level',
        width: 100,
        Cell: (props) => <CellLevel level={props.value} />,
      },
      {
        Header: `${intl.formatMessage({
          id: 'observations',
          defaultMessage: 'Observaciones',
        })}`,
        accessor: 'texto',
      },
    ];

    const style = {
      width: 550,
    };

    return (
      <FocusTrap className="">
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className={`border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute p-2 w-full max-w-md m-auto flex-col flex`}
            style={{ top: 40, left: 40, ...style, zIndex: ABOVE_ALL }}
          >
            <IcoMoon icon="enlarge" width="25" className="text-xs absolute pin-t pin-l handleDragg text-smoke cursor-move m-2" />
            <div className="flex leading-normal mb-3 mt-1" style={{ fontSize: 12 }}>
              <div className="flex-1 m-2">
                <Item
                  title={intl.formatMessage({
                    id: 'posting',
                    defaultMessage: 'Asiento',
                  })}
                  value={commissionsData.info.asiento}
                />
                <Item
                  title={intl.formatMessage({
                    id: 'date',
                    defaultMessage: 'Fecha',
                  })}
                  value={dateFormat(commissionsData.info.fecha)}
                />
                <Item
                  title={intl.formatMessage({
                    id: 'eventCharge',
                    defaultMessage: 'Evento/Cargo',
                  })}
                  value={
                    showAll
                      ? intl.formatMessage({
                          id: 'commissions',
                          defaultMessage: 'Comisiones',
                        })
                      : `${infoCommission.info.type} ${infoCommission.chargeDefinition.chargeId}`
                  }
                />
              </div>
              <div className="flex-1 m-2">
                <Item
                  title={intl.formatMessage({
                    id: 'client',
                    defaultMessage: 'Cliente',
                  })}
                  value={commissionsData.info.idCliente}
                />
                <Item
                  title={intl.formatMessage({
                    id: 'taxableBase',
                    defaultMessage: 'Base Imponible',
                  })}
                  value={commissionsData.info.taxableBase}
                />
                <div className="flex">
                  <div className="flex-1">
                    <Item
                      title={intl.formatMessage({
                        id: 'taxableBase',
                        defaultMessage: 'Tarifa Obtenida',
                      })}
                      value={roundDecimal(showAll ? commissionsData.info.tarifaObtenida : infoCommission.info.tarifaObtenida)}
                    />
                  </div>
                  <div>
                    <Item
                      title={intl.formatMessage({
                        id: 'currency',
                        defaultMessage: 'Moneda',
                      })}
                      value={commissionsData.info.calculatedCurrency}
                    />
                  </div>
                </div>
                {!showAll && (
                  <div className="flex">
                    <div className="flex-1">
                      <Item
                        title={intl.formatMessage({
                          id: 'genericRate',
                          defaultMessage: 'Tarifa GenÃ©rica',
                        })}
                        value={roundDecimal(infoCommission.info.tarifaGenerica)}
                      />
                    </div>
                    <div>
                      <Item
                        title={intl.formatMessage({
                          id: 'currency',
                          defaultMessage: 'Moneda',
                        })}
                        value={infoCommission.info.calculatedCurrency}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ReactTable
              data={data}
              columns={columns}
              defaultPageSize={10}
              noDataText={
                <p className="text-text-base" style={{ fontSize: 11 }}>
                  <FormattedMessage id="noData" defaultMessage={`No hay datos`} />
                </p>
              }
            />
            <div className="text-center text-sm w-full m-2">
              <button
                id="commissionsInfoAccept"
                className="bg-grey hover:bg-primary text-white py-2 px-12 rounded shadow focus:bg-primary hover:bg-primary focus:outline-none"
                onClick={() => {
                  addValueTest('CLICK', 'commissionsInfoAccept', '');
                  showInformation(false);
                }}
              >
                <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
              </button>
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );
  }
}

export default injectIntl(CommissionsInformation);
