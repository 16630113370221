import { ConfirmDialog } from '../../UI-kit/index';
import Modal from '../../UI-kit/components/Modal/Modal';
import { noticeDecided } from '../../src/api/notice';

import { openReceivedReports } from '../../src/helpers/operationsHelper';
import { generateLineIDString } from '../../UI-kit/helpers/operationHelper';

export default class NoticeToDisplay {
  constructor({ displayInfo, lineID, idNode, modalMessage }) {
    this._displayInfo = displayInfo;
    this._lineID = lineID;
    this._idNode = idNode;
    this._modalMessage = modalMessage;
  }

  transactionNumber() {
    return this._lineID ? this._lineID.transactionNumber : '';
  }

  lineNumber() {
    return this._lineID ? this._lineID.lineNumber : '';
  }

  transactionLineLabel() {
    return generateLineIDString(this._lineID);
  }

  title() {
    return this._displayInfo.title;
  }

  message() {
    return this._displayInfo.text;
  }

  infoObject() {
    return this._displayInfo.infoObject;
  }

  type() {
    return this._displayInfo.type;
  }

  textIsFormula() {
    return this._displayInfo.textIsFormula;
  }

  buttons() {
    return this._displayInfo.buttons;
  }

  defaultButton() {
    return this._displayInfo.defaultButton;
  }

  acceptButton() {
    return this.buttons()[0];
  }

  cancelButton() {
    return this.buttons()[1];
  }

  style() {
    return {};
  }

  options() {
    return {};
  }

  acceptHandler() {
    this.dialogService.Accept(this.values);
  }

  props(state) {
    return {
      title: this.title(),
      message: this.message(),
      buttons: this.buttons(),
      defaultButton: this.defaultButton(),
      transactionLineLabel: this.transactionLineLabel(),
      nodeId: this._idNode,
      infoObject: this.infoObject(),
      modalMessage: this._modalMessage,
      onAffirmative: (
        operationID,
        processContext,
        removeFromWindowStack,
        removeTreeNode,
        openGlobalModal,
        addEmptyTabReport,
        addTabReport,
        closeGlobalModal,
        tabsReport,
        tabs,
        currentTab,
        currentPostingNumber,
        setPostingNumberTab,
      ) => {
        removeTreeNode(this.transactionLineLabel(), this._idNode);
        removeFromWindowStack(this.transactionLineLabel());
        noticeDecided(operationID, this.acceptButton() || 4).then((res) => {
          if (res && res.lista) {
            const infoPosting = res.lista.find((c) => c.infoType === 1);
            const infoName = res.lista.find((c) => c.infoType === 2);
            const postingNumber = infoPosting ? infoPosting.value : currentPostingNumber || 'SIN NÚMERO';
            const operationName = infoName ? infoName.value : '';
            setPostingNumberTab(currentTab, postingNumber, operationName);
            let finishOperation = res.lista.some((element) => element['class-name'].includes('OperationToFinishEvent'));
            if (finishOperation) {
              openReceivedReports(openGlobalModal, addEmptyTabReport, addTabReport, closeGlobalModal, tabsReport, tabs, currentTab);
            }
          }
          processContext(res, 'Notice Decided: OK');
        });
      },
      onNegative: (operationID, processContext, removeFromWindowStack, removeTreeNode, tabs, tabID, removeTreeFields) => {
        removeTreeNode(this.transactionLineLabel(), this._idNode);
        removeFromWindowStack(this.transactionLineLabel());
        noticeDecided(operationID, this.cancelButton() || 8).then((res) => {
          processContext(res, 'Notice Decided: Cancel');

          const dialogsRemove = tabs[tabID].running.trees.filter((tree) => {
            return res.lista.some((component) => {
              if (component['class-name'].includes('ItemToDeleteEvent')) {
                const lineIDKeyComponent = generateLineIDString(component.lineID);
                const lineIDKeyTree = generateLineIDString(tree._data.lineID);
                return lineIDKeyTree === lineIDKeyComponent;
              }
              return false;
            });
          });
          if (dialogsRemove.length > 0) {
            dialogsRemove.forEach((dialog) => {
              removeTreeFields(dialog._data.number);
            });
          }
        });
      },
    };
  }

  component() {
    return Modal;
  }
}
