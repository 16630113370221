export const Filters = {
  CHECK_BY_MICRLINE: 'checkByMicrLine',
  GROUP_BY_ACCOUNT_SORT_BY_AMOUNT: 'groupByAccountSortByAmount',
  CANCELLED_OR_BLOCKED_ACCOUNTS: 'cancelledOrBlockedAccounts',
  RETURNED_BY_BRANCH_MANAGERS: 'returnedByBranchManagers',
  A_SPECIFIC_CHECK: 'aSpecificCheck',
  BY_ACCOUNT_AND_CURRENCY: 'byAccountAndCurrency',
  IN_REVIEW: 'inReview',
  EVERYTHING_ELSE: 'everythingElse',
  ACCEPTED: 'aceptados',
  ACCEPTED_BY_MANAGEMENT: 'aceptadosPorGerencia',
  REFUSED: 'rechazados',
  REFUSED_DIGITAL_CLEARING: 'rechazadosPorClearingDigital',
  BY_ACCOUNT: 'porCuenta',
};

export const ReturnStatus = {
  RETURNED: 'returned',
  NOT_YET_RETURNED: 'notYetReturned',
};

export const ValidateStatus = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};
