import React, { Component } from 'react';
import { PulseLoader } from 'halogenium';
import IcoMoon from 'react-icomoon';
import Draggable from 'react-draggable';
import FocusTrap from 'react-focus-trap';
import { injectIntl, FormattedMessage, FormattedPlural } from 'react-intl';
import SignaturesImages from './SignaturesImages';
import Overlay from './Overlay';
import Dialog from './Dialog';
import ModalMessage from '../../src/components/ModalMessage';
import SignatureCapture from './SignatureCapture';
import { fileBase64ToArray } from '../helpers/fileHelper';
import { addValueTest } from '../../src/components/Testing/HandleLineTest';

const Button = ({ title, click, className, disabled = false, id }) => {
  return (
    <button
      id={id}
      className={`${className} bg-grey text-white rounded shadow m-2 py-2 px-4 text-sm focus:bg-primary hover:bg-primary focus:outline-none`}
      onClick={(e) => {
        addValueTest('CLICK', id, '');
        click();
      }}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

class SignatureWindowLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSignatureCapture: false,
      addDocument: false,
    };

    this.openSignatureCapture = this.openSignatureCapture.bind(this);
    this.closeSignatureCapture = this.closeSignatureCapture.bind(this);
    this.signatureDoneHandler = this.signatureDoneHandler.bind(this);
  }

  openSignatureCapture() {
    this.setState({
      showSignatureCapture: true,
    });
  }

  closeSignatureCapture() {
    this.setState({
      showSignatureCapture: false,
    });
  }

  signatureDoneHandler(signature) {
    const {
      data: { numeroPersona: numberPhysicalPerson },
    } = this.props;

    if (signature) {
      const { base64 } = signature;
      const arrImg = fileBase64ToArray(`data:image/png};base64,${base64}`);
      const personImage = {
        numeroPersonaFisica: numberPhysicalPerson,
        numeroFirma: 0,
        firma: arrImg,
      };

      this.props.addPersonImage(personImage);
      this.closeSignatureCapture();
    }
  }

  render() {
    const {
      showWindow,
      loading,
      personImages,
      isUserAuthorizer,
      codesDocuments,
      physicalPerson,
      isConsult,
      data: { numeroPersona: numberPhysicalPerson },
      isPhoto,
      isSignature,
      isDoc,
      handleChangeData,
      handleSearchFile,
      handlePasteFile,
      handleRemove,
      handleAuthorize,
      handleCancel,
      handleAccept,
      inputFileChanged,
      hideDialog,
      showDialog,
      dialogMessage,
      showPreview,
      srcSignaturePreview,
      handleShowPreview,
      allowAddOrPaste,
      intl,
    } = this.props;

    const { showSignatureCapture } = this.state;

    const style = { top: 0 };
    let codigoDocumento = null;
    let numeroDocumento = null;
    if (this.props.data.codigoDocumento !== undefined) {
      codigoDocumento = this.props.data.codigoDocumento;
    }
    if (this.props.data.numeroDocumento !== undefined) {
      numeroDocumento = this.props.data.numeroDocumento;
    }

    let inputFile = null;
    const numImages = personImages.filter((personImage) => !personImage.removed).length;

    return (
      <FocusTrap className={`${showWindow ? '' : 'hidden'}`}>
        <Overlay style={{ zIndex: 5, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className={`border border-solid ${
              showDialog ? 'border-transparent' : 'border-primary'
            } bg-grey-lighter-lighter rounded shadow absolute p-2 w-full`}
            style={{ ...style, zIndex: 5 }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move m-1 text-xs" />

            <div className="mb-2 text-center font-light text-primary text-center">
              <FormattedMessage id="signaturesMaintenance" defaultMessage={`Mantenimiento de Firmas`} />
            </div>

            <div className="my-4">
              <span className="text-xs">
                <FormattedMessage id="Person" defaultMessage={`Persona`} />
                {': '}
                {numberPhysicalPerson}
              </span>
              {physicalPerson && physicalPerson[0] && (
                <span className="ml-2 inline-block">
                  {physicalPerson[0].primerNombre} {physicalPerson[0].segundoNombre} {physicalPerson[0].apellidoPaterno}{' '}
                  {physicalPerson[0].apellidoMaterno}
                </span>
              )}
            </div>

            <div className="bg-grey-lightest rounded my-4 p-6">
              {loading && (
                <div className="m-12 text-center px-4">
                  <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
                </div>
              )}
              {personImages && personImages.length > 0 && (
                <div>
                  <div className="mb-2">
                    {`${numImages} `}
                    <FormattedPlural
                      value={numImages}
                      one={<FormattedMessage id="registeredImage" defaultMessage={`imagen registrada`} />}
                      other={<FormattedMessage id="registeredImages" defaultMessage={`imágenes registradas`} />}
                    />
                    :
                  </div>

                  <SignaturesImages
                    personImages={personImages}
                    handleChangeData={handleChangeData}
                    documentType={codigoDocumento}
                    documentNumber={numeroDocumento}
                    codesDocuments={codesDocuments}
                    isConsult={isConsult}
                    isSignature={isSignature}
                    isPhoto={isPhoto}
                    isDoc={isDoc}
                    isUserAuthorizer={isUserAuthorizer}
                    handleRemove={handleRemove}
                    handleAuthorize={handleAuthorize}
                    handleShowPreview={handleShowPreview}
                    addDocument={this.state.addDocument}
                  />
                </div>
              )}

              {personImages.length < 1 && !loading && (
                <div className="h-64 flex items-center">
                  <div>
                    <FormattedMessage id="noPictures" defaultMessage={`No hay imágenes`} />
                  </div>
                </div>
              )}
            </div>

            <div className="text-center">
              {!isConsult && (
                <React.Fragment>
                  <Button
                    id="signatureAdd"
                    title={intl.formatMessage({
                      id: 'add',
                      defaultMessage: 'Agregar',
                    })}
                    className={!allowAddOrPaste ? 'disabled' : ''}
                    disabled={loading || !allowAddOrPaste}
                    click={() => {
                      this.setState({
                        addDocument: true,
                      });
                      handleSearchFile(inputFile);
                    }}
                  />
                  <Button
                    id="signaturePaste"
                    title={intl.formatMessage({
                      id: 'paste',
                      defaultMessage: 'Ctrl + V',
                    })}
                    className={!allowAddOrPaste ? 'disabled' : ''}
                    disabled={loading || !allowAddOrPaste}
                    click={handlePasteFile}
                  />
                  <input
                    value={''}
                    type="file"
                    accept="image/*"
                    ref={(input) => {
                      inputFile = input;
                    }}
                    multiple={false}
                    style={{ display: 'none' }}
                    onChange={inputFileChanged}
                  />

                  {isSignature && (
                    <Button
                      id="signatureCapture"
                      title={intl.formatMessage({
                        id: 'capture',
                        defaultMessage: 'Capturar',
                      })}
                      click={this.openSignatureCapture}
                    />
                  )}
                  <span className="border-r text-smoke"></span>
                </React.Fragment>
              )}
              <Button
                id="signatureAccept"
                title={intl.formatMessage({
                  id: 'accept',
                  defaultMessage: 'Finalizar',
                })}
                disabled={!isConsult && loading}
                click={isConsult ? handleCancel : handleAccept}
              />
              {!isConsult && (
                <Button
                  id="signatureCancel"
                  title={intl.formatMessage({
                    id: 'cancel',
                    defaultMessage: 'Cancelar',
                  })}
                  click={handleCancel}
                />
              )}
            </div>
          </div>
        </Draggable>
        {showDialog && (
          <Dialog
            hideDialog={hideDialog}
            message={dialogMessage}
            dialogTitle={intl.formatMessage({
              id: 'information',
              defaultMessage: 'Información',
            })}
          />
        )}
        {showPreview && (
          <ModalMessage
            message={
              <div className="flex w-full h-full justify-center items-center">
                <img className="w-full max-h-full" src={srcSignaturePreview} />
              </div>
            }
            handleAccept={() => {
              handleShowPreview();
            }}
            acceptTitle={intl.formatMessage({
              id: 'close',
              defaultMessage: 'Cerrar',
            })}
            largeModal={true}
            closeOutside={true}
            draggable={true}
          />
        )}
        {showSignatureCapture && (
          <ModalMessage
            message={
              <SignatureCapture
                signText={intl.formatMessage({
                  id: 'start',
                  defaultMessage: 'Comenzar',
                })}
                clearText={intl.formatMessage({
                  id: 'clear',
                  defaultMessage: 'Reiniciar',
                })}
                submitText={intl.formatMessage({
                  id: 'save',
                  defaultMessage: 'Guardar',
                })}
                closeText={intl.formatMessage({
                  id: 'close',
                  defaultMessage: 'Cancelar',
                })}
                handleClose={this.closeSignatureCapture}
                errorMsg={intl.formatMessage({
                  id: 'sigweb-error',
                  defaultMessage: 'No se pudo acceder al dispositivo. Verifique la conexión USB y que se haya instalado sigweb.exe en su PC',
                })}
                handleSigSubmit={this.signatureDoneHandler}
                btnClass="bg-grey text-white rounded shadow m-2 py-2 px-4 text-sm hover:bg-primary focus:outline-none"
              />
            }
            hideAcceptButton
            closeOutside={true}
            draggable={true}
          />
        )}
      </FocusTrap>
    );
  }
}

export default injectIntl(SignatureWindowLayout);
