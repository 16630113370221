import React, { Component } from 'react';
import { getBranches, hasVirtualBranches } from '../../api/branches';
import { BranchesList } from './BranchesList';

const initialState = {
  hasVirtualBranches: false,
  branchesList: undefined,
  branchVirtualList: undefined,
};

export class BranchesManager extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.fetchBranches = this.fetchBranches.bind(this);
  }

  componentDidMount() {
    this.fetchBranches();
  }

  async fetchBranches() {
    let data = await getBranches();
    let hasVirtual = await hasVirtualBranches();
    let arrayBranches = data.filter((branch) => branch.virtual === 0);
    let arrayVirtuals = data.filter((branch) => branch.virtual === 1);
    this.setState({
      branchesList: arrayBranches,
      hasVirtualBranches: hasVirtual,
      branchVirtualList: arrayVirtuals,
    });
  }

  render() {
    let { branchesList, branchVirtualList, hasVirtualBranches } = this.state;
    // eslint-disable-next-line no-lone-blocks
    {
      if (branchesList) {
        return (
          <div className="relative w-full mr-2 ml-2 mt-6 p-2">
            <BranchesList
              branches={branchesList}
              virtualBranches={branchVirtualList}
              hasVirtual={hasVirtualBranches}
              fetchBranches={this.fetchBranches}
            />
          </div>
        );
      } else {
        return <div> </div>;
      }
    }
  }
}

export default BranchesManager;
