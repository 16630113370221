import React from 'react';
import axios from 'axios';
import browserStorage from '../browserStorage';
import { USER_SESSION } from '../browserStorage/constants';

import { store, dispatchActions } from '../../store';
import { FormattedMessage } from 'react-intl';

const createInstance = () => {
  let instance = axios.create({});

  instance.interceptors.request.use((defaultConfig) => {
    let config = defaultConfig;

    if (config.headers['Content-Type'] === '') {
      config.headers = Object.assign(config.headers, {
        'Content-Type': 'multipart/form-data',
      });
    }

    return config;
  }, Promise.reject);

  instance.interceptors.response.use(
    (response) => {
      const exception = 'TopazException';
      const notLogged = 'sesion';
      const notLoggedException = 'NotLoggedException';
      const helpHelpOperators = 'help.HelpOperators';

      if (response && response.data && response.data.toString().includes(notLoggedException)) {
        const notLoggedMessage = 'Su sesión ha expirado.';
        store.setState(dispatchActions.openGlobalModal(store.getState(), notLoggedMessage, true));
      } else if (response && response.data && response.data.toString().includes(exception) && !response.data.toString().includes(helpHelpOperators)) {
        const shouldLogout = response.data.toString().includes(notLogged);
        if (response.config.url && response.config.url.indexOf('/personidentification/validarApoderados') > -1) {
          store.setState(
            dispatchActions.openGlobalModal(
              store.getState(),
              <FormattedMessage
                id="errorSignaturesApi"
                defaultMessage={`Se produjo un error al validar los poderes de las personas seleccionadas.`}
              />,
              shouldLogout,
            ),
          );
        } else {
          store.setState(dispatchActions.openGlobalModal(store.getState(), response.data, shouldLogout));
        }
      }

      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        browserStorage.sessionClearKey(USER_SESSION);
        window.location = '/';
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

const instance = createInstance();

export default instance;
