import React from 'react';
import { FormattedMessage } from 'react-intl';
import { printForms, getOperationEndedForm } from '../../src/api/report';
import { getTransaction } from '../../src/api/postingManager';
import { getSessionInfo } from '../../src/api/session';
import { store } from '../../src/store';
import { PATHNAME } from '../../src/config';

export const validResponse = (response) => response && typeof response !== 'string' && !response.mensaje;

export async function openReceivedReports(openGlobalModal, addEmptyTabReport, addTabReport, closeGlobalModal, tabsReport, tabs, current) {
  const { operationNumber, postingNumber } = tabs[current];
  tabsReport = store.getState().tabsReport;
  const printForm = await printForms(postingNumber);
  if (validResponse(printForm)) {
    const sessionInfo = await getSessionInfo();
    if (validResponse(sessionInfo)) {
      const transactionData = await getTransaction(sessionInfo.topazBranch, postingNumber, sessionInfo.fechaProceso, null);
      if (validResponse(transactionData) && transactionData.reports.length > 0) {
        openGlobalModal(
          <div>
            <FormattedMessage id="generatingReports" defaultMessage={`Generando reportes para la operación número`} />
            {` ${operationNumber} `}
            <FormattedMessage id="withPostingNumber" defaultMessage={`con número de asiento`} />
            {` ${postingNumber}`}
          </div>,
          false,
          `${PATHNAME}home/reports`,
          true,
        );
        closeGlobalModal();
        let openReports = false;
        for (let i = 0; i < transactionData.reports.length; i++) {
          const report = transactionData.reports[i];
          const infoForm = await getOperationEndedForm(false, report);
          if (validResponse(infoForm)) {
            if (tabsReport && !(tabsReport.length === 1 && tabsReport[0].emptyTab)) addEmptyTabReport();
            const { name, fileExtension } = infoForm;
            addTabReport(
              {
                description: report.description,
                specificationId: report.reportID,
                name,
                fileExtension,
              },
              true,
              false,
            );
            openReports = true;
          }
        }
        if (openReports) {
          openGlobalModal(
            <div>
              <FormattedMessage id="displayGeneratedReports" defaultMessage={`Visualizar reportes generados para la operación número`} />
              {` ${operationNumber} `}
              <FormattedMessage id="withPostingNumber" defaultMessage={`con número de asiento`} />
              {` ${postingNumber}`}
            </div>,
            false,
            `${PATHNAME}home/reports`,
            false,
            false,
            true,
          );
        } else closeGlobalModal();
      }
    }
  }
}
