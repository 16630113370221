import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import HelpTable from '../../../../UI-kit/components/HelpTable';
import FocusTrap from 'react-focus-trap';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';
import { FormattedMessage, createIntl, createIntlCache } from 'react-intl';
import { messages as msg } from './../../../translations';
import { LANGUAGE } from './../../../config';

const getHelpWindow = (helpData, helpColumns, handleAccept, handleCancel, title) => {
  let value = '';
  let control = '';

  const setSelectedValue = (rowInfo) => {
    const columns = helpColumns;
    value = '';
    columns &&
      columns.forEach((column) => {
        if (column.isReturned && rowInfo.original) {
          value = rowInfo.original[column.accessor];
          control = column.control;
        }
      });
  };

  if (helpData.length > -1 && helpColumns && helpColumns.length > 0) {
    const styles = StyleSheet.create({
      titleContainer: {
        width: '100%',
        minHeight: '1.5rem',
        padding: '.5rem 1rem',
        color: '#6B7280',
        cursor: 'arrow',
        textAlign: 'left',
        flex: '1 0 auto',
        borderBottom: `1px solid #DCE0E6`,
      },
      helpWrapper: {
        backgroundColor: '#F4F5F7',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
        borderColor: 'transparent',
        borderRadius: '.25rem',
        borderWidth: 1,
        minHeight: '300px',
        minWidth: '400px',
        height: '350px',
        left: '50%',
        position: 'fixed',
        top: '50%',
        width: '500px',
        zIndex: 100,
      },
    });

    const buttonStyles = {
      width: '100%',
    };

    const cache = createIntlCache();
    let messages = msg[LANGUAGE];
    const intl = createIntl(
      {
        locale: LANGUAGE,
        messages,
      },
      cache,
    );
    const selectText = intl.formatMessage({
      id: 'select',
      defaultMessage: 'Seleccionar',
    });

    return (
      <FocusTrap>
        <Draggable bounds="" handle=".handleDragg">
          <div
            className="pin border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute"
            style={{ zIndex: 100, width: '500px', top: '30%', left: '30%', height: '350px' }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l text-xs handleDragg text-smoke cursor-move m-1" />
            <div className={`${css(styles.titleContainer)}`}>
              {selectText} {title}
            </div>
            <div className="border border-solid border-green-light ml-2" style={{ width: '480px', height: '250px' }}>
              <HelpTable
                fromApp={true}
                title={'aa'}
                transactionLineLabel={'T1L2'}
                data={helpData && helpData.map((row, index) => ({ ...row, id: index }))}
                columns={helpColumns}
                goToLineFn={() => {}}
                focusedTable={true}
                continue={(rowInfo) => {
                  // const value = this.createSelectedValue(rowInfo);
                  // setValueSelected(value);
                }}
                getTrProps={(state, rowInfo, runSelection = false) => {
                  setSelectedValue(rowInfo);
                  return {
                    onClick: (e, handleOriginal) => {},
                  };
                }}
              />
            </div>
            <div className="absolute flex justify-end mt-3" style={buttonStyles}>
              <button
                autoFocus
                className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                onClick={() => {
                  if (handleAccept) {
                    if (value === '' && control === '') {
                      handleCancel();
                    } else {
                      handleAccept(value, control);
                    }
                  }
                }}
                onBlur={(e) => {}}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    if (handleCancel) {
                      handleCancel();
                    }
                  } else if (e.key === 76 && e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
              </button>
              <button
                className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                onClick={() => {
                  if (handleCancel) {
                    handleCancel();
                  }
                }}
                onBlur={(e) => {}}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    if (handleCancel) {
                      handleCancel();
                    }
                  } else if (e.key === 76 && e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />
              </button>
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );
  } else {
    return <div></div>;
  }
};

/* const getGridState = () => {
  const { grids, nodeId } = this.props;
  if (grids && grids[nodeId]) {
    return grids[nodeId];
  } else {
    gridInitialState();
  }
};

const gridInitialState = () => ({
  columns: [],
  rows: [],
  returnedField: '',
  numberOfReturned: 0,
  showAll: false,
  showWindow: false,
  loading: true,
  currentFilterCol: null,
  filtered: false,
  filterOptions: undefined,
}); */

const getHelpButton = (id, getDataFn, setHelpColumns, setHelpData, setLoading, setTitle, dataCount) => {
  let helpButton = null;

  if (getDataFn && getDataFn.getData) {
    helpButton = (
      <button
        id={id + '_helpButton'}
        className="bg-smoke text-xs rounded-r-lg shadow rounded-l-none opacity-75 hover:opacity-100"
        style={{ width: 19 }}
        onClick={async () => {
          if (!dataCount) {
            setLoading(true);
            getDataFn.getData().then((data) => {
              setLoading(false);
              setHelpColumns(getDataFn.getColumns());
              setHelpData(data);
              setTitle(getDataFn.title);
            });
          }
        }}
      >
        ?
      </button>
    );
  }

  return helpButton;
};

const getColumnsBitacoraCodigos = () => {
  const cache = createIntlCache();
  let messages = msg[LANGUAGE];
  const intl = createIntl(
    {
      locale: LANGUAGE,
      messages,
    },
    cache,
  );

  let columns = [
    {
      Header: intl.formatMessage({
        id: 'codigo',
        defaultMessage: 'Codigo',
      }),
      accessor: 'codigo',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'codigo',
    },
    {
      Header: intl.formatMessage({
        id: 'descripcion',
        defaultMessage: 'Descripción',
      }),
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsBitacoraSucursal = () => {
  const cache = createIntlCache();
  let messages = msg[LANGUAGE];
  const intl = createIntl(
    {
      locale: LANGUAGE,
      messages,
    },
    cache,
  );

  let columns = [
    {
      Header: intl.formatMessage({
        id: 'codigo',
        defaultMessage: 'Código',
      }),
      accessor: 'codigo',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'sucursal',
    },
    {
      Header: intl.formatMessage({
        id: 'descripcion',
        defaultMessage: 'Descripción',
      }),
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsgetOperaciones = () => {
  const cache = createIntlCache();
  let messages = msg[LANGUAGE];
  const intl = createIntl(
    {
      locale: LANGUAGE,
      messages,
    },
    cache,
  );

  let columns = [
    {
      Header: intl.formatMessage({
        id: 'operationNumber',
        defaultMessage: 'Número de Operación',
      }),
      accessor: 'identificacion',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'operacion',
    },
    {
      Header: intl.formatMessage({
        id: 'descripcion',
        defaultMessage: 'Descripción',
      }),
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsMaquina = () => {
  const cache = createIntlCache();
  let messages = msg[LANGUAGE];
  const intl = createIntl(
    {
      locale: LANGUAGE,
      messages,
    },
    cache,
  );

  let columns = [
    {
      Header: intl.formatMessage({
        id: 'numero',
        defaultMessage: 'Número',
      }),
      accessor: 'numero',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'maquina',
    },
    {
      Header: intl.formatMessage({
        id: 'sucursal',
        defaultMessage: 'Sucursal',
      }),
      accessor: 'sucursal',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'descripcion',
        defaultMessage: 'Descripción',
      }),
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];
  return columns;
};

const getColumnsUsuarios = () => {
  const cache = createIntlCache();
  let messages = msg[LANGUAGE];
  const intl = createIntl(
    {
      locale: LANGUAGE,
      messages,
    },
    cache,
  );
  let columns = [
    {
      Header: intl.formatMessage({
        id: 'clave',
        defaultMessage: 'Clave',
      }),
      accessor: 'clave',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
      control: 'usuario',
    },
    {
      Header: intl.formatMessage({
        id: 'iniciales',
        defaultMessage: 'Iniciales',
      }),
      accessor: 'iniciales',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'nombre',
        defaultMessage: 'Nombre',
      }),
      accessor: 'nombre',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsIniciales = () => {
  const cache = createIntlCache();
  let messages = msg[LANGUAGE];
  const intl = createIntl(
    {
      locale: LANGUAGE,
      messages,
    },
    cache,
  );
  let columns = [
    {
      Header: intl.formatMessage({
        id: 'iniciales',
        defaultMessage: 'Iniciales',
      }),
      accessor: 'iniciales',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
      control: 'iniciales',
    },
    {
      Header: intl.formatMessage({
        id: 'clave',
        defaultMessage: 'Clave',
      }),
      accessor: 'clave',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'nombre',
        defaultMessage: 'Nombre',
      }),
      accessor: 'nombre',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];
  return columns;
};

const getColumnsInicialesMod = () => {
  const cache = createIntlCache();
  let messages = msg[LANGUAGE];
  const intl = createIntl(
    {
      locale: LANGUAGE,
      messages,
    },
    cache,
  );
  let columns = [
    {
      Header: intl.formatMessage({
        id: 'iniciales',
        defaultMessage: 'Iniciales',
      }),
      accessor: 'iniciales',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
      control: 'inicialesmodificador',
    },
    {
      Header: intl.formatMessage({
        id: 'clave',
        defaultMessage: 'Clave',
      }),
      accessor: 'clave',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'nombre',
        defaultMessage: 'Nombre',
      }),
      accessor: 'nombre',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];
  return columns;
};

const getColumnsGrupo = () => {
  const cache = createIntlCache();
  let messages = msg[LANGUAGE];
  const intl = createIntl(
    {
      locale: LANGUAGE,
      messages,
    },
    cache,
  );
  let columns = [
    {
      Header: intl.formatMessage({
        id: 'grupo',
        defaultMessage: 'Grupo',
      }),
      accessor: 'grupo',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
      control: 'grupo',
    },
    {
      Header: intl.formatMessage({
        id: 'descripcion',
        defaultMessage: 'Descripción',
      }),
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsDescriptor = () => {
  const cache = createIntlCache();
  let messages = msg[LANGUAGE];
  const intl = createIntl(
    {
      locale: LANGUAGE,
      messages,
    },
    cache,
  );
  let columns = [
    {
      Header: intl.formatMessage({
        id: 'descriptor',
        defaultMessage: 'Descriptor',
      }),
      accessor: 'descriptor',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'descriptor',
    },
    {
      Header: intl.formatMessage({
        id: 'descripcion',
        defaultMessage: 'Descripción',
      }),
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsCanal = () => {
  const cache = createIntlCache();
  let messages = msg[LANGUAGE];
  const intl = createIntl(
    {
      locale: LANGUAGE,
      messages,
    },
    cache,
  );
  let columns = [
    {
      Header: intl.formatMessage({
        id: 'descripcion',
        defaultMessage: 'Descripción',
      }),
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'codigo',
        defaultMessage: 'Codigo',
      }),
      accessor: 'codigo',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'codigo',
    },
  ];

  return columns;
};

export {
  getHelpButton,
  getColumnsBitacoraCodigos,
  getColumnsBitacoraSucursal,
  getColumnsgetOperaciones,
  getColumnsMaquina,
  getColumnsIniciales,
  getHelpWindow,
  getColumnsUsuarios,
  getColumnsGrupo,
  getColumnsInicialesMod,
  getColumnsDescriptor,
  getColumnsCanal,
};
