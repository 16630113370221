import * as React from 'react';
import { Select, SelectData } from '../../select/Select';
import { useIntl } from 'react-intl';

import '../MonitorMenu.scss';

interface MonitorMenuOptionsViewProps {
  onClickEliminateFinished: (event: React.MouseEvent) => void;
  options: SelectData[];
  onSelectedChange: (state: string) => void;
  selectedState: string;
  onDateFilterChange: (value: string) => void;
  onIdFilterChange: (value: string) => void;
  onResetFilter: (event: React.MouseEvent) => void;
  selectedId: string;
  selectedDate: string;
}

export function MonitorMenuOptionsView({
  onClickEliminateFinished,
  options,
  onSelectedChange,
  selectedState,
  onDateFilterChange,
  onIdFilterChange,
  onResetFilter,
  selectedDate,
  selectedId,
}: MonitorMenuOptionsViewProps): JSX.Element {
  const intl = useIntl();

  function onChangeDate(): (e: { target: HTMLInputElement }) => void {
    return (e) => {
      onDateFilterChange(e.target.value);
    };
  }

  function onChangeId(): (e: { target: HTMLInputElement }) => void {
    return (e) => {
      onIdFilterChange(e.target.value);
    };
  }

  return (
    <div className="eliminate-button py-4 flex border-t justify-between">
      <div className="flex items-center text-sm">
        <label className="mr-1">{intl.formatMessage({ id: 'date' })}:</label>
        <input
          type="date"
          className="w-auto rounded border border-gray-300 shadow-sm placeholder-gray-500 placeholder-opacity-50 mt-2 rounded-full text-right h-10"
          onChange={onChangeDate()}
          value={selectedDate}
        ></input>
      </div>
      <div className="flex items-center text-sm">
        <label className="mr-1">{intl.formatMessage({ id: 'state' })}:</label>
        <Select
          className="w-auto rounded border border-gray-300 shadow-sm placeholder-gray-500 placeholder-opacity-50 mt-2 rounded-full text-center h-10"
          selectedState={selectedState}
          options={options}
          onSelectedChange={onSelectedChange}
        />
      </div>
      <div className="flex items-center text-sm">
        <label className="mr-1">{intl.formatMessage({ id: 'executionId' })}:</label>
        <input
          type="number"
          className="w-auto rounded border border-gray-300 shadow-smplaceholder-gray-500 placeholder-opacity-50 mt-2 rounded-full text-center h-10"
          onChange={onChangeId()}
          value={selectedId}
        ></input>
      </div>
      <div>
        <button
          className="button-blue hover:bg-blue-dark text-white font-bold py-2 rounded-full text-sm rounded-full text-center h-10 mt-2"
          style={{ width: '7rem' }}
          onClick={onResetFilter}
        >
          {intl.formatMessage({ id: 'removeFilter' })}
        </button>
      </div>
      <div className="border-r mt-2 " />
    </div>
  );
}
