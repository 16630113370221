import React, { useState, useRef, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { FormattedMessage } from 'react-intl';
import { ABOVE_ALL } from '../../UI-kit/constants/zIndexValues';
import { store } from '../store';
import { setLogConfig } from '../api/topazLog';
import useResizable from '../hooks/useResizable';
import useDraggable from '../hooks/useDraggable';
import SaveIcon from '../resources/save.svg';
import SelectAllIcon from '../resources/selectAll.svg';
import CopyIcon from '../resources/copy.svg';
import EraseIcon from '../resources/erase.svg';
import SettingsIcon from '../resources/settings.svg';
import LevelsIcon from '../resources/levels.svg';

const TopazLogViewer = (props) => {
  const [toolbarShown, setToolbarShown] = useState(null);
  const [filter, setFilter] = useState(null);
  const [maxLinesShown, setMaxLinesShown] = useState(0);
  const [maxLinesShownModified, setMaxLinesShownModified] = useState(false);
  const [maxLinesServerModified, setMaxLinesServerModified] = useState(false);
  const { onMouseDownResizable, width, height } = useResizable({ defaultWidth: 600, defaultHeight: 400 });
  const { onMouseDownDraggable, left, top } = useDraggable({ defaultLeft: 75, defaultTop: 75 });
  const endOfLogSectionRef = useRef();

  const toolbarHeight = '28px';
  const titleHeight = '2rem';
  const toolbarTopMargin = '2px';
  const styles = StyleSheet.create({
    windowWrapper: {
      backgroundColor: '#F4F5F7',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
      borderColor: '#2b53a0',
      borderRadius: '.25rem',
      borderWidth: 1,
      minHeight: '100px',
      minWidth: '475px',
      position: 'fixed',
      width: width,
      height: height,
      zIndex: ABOVE_ALL,
      left: left,
      top: top,
      defaultLeft: '50%',
      defaultTop: '50%',
    },
    titleContainer: {
      width: '100%',
      height: titleHeight,
      padding: '0.5rem 1rem',
      color: '#6B7280',
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid #DCE0E6`,
    },
    closeWindowHandle: {
      position: 'absolute',
      top: '0',
      right: '0',
      cursor: 'pointer',
      padding: '0.5rem 1rem',
      userSelect: 'none',
      height: titleHeight,
    },
    toolbar: {
      top: 0,
      width: '100%',
      height: toolbarHeight,
      display: 'flex',
      borderBottom: 'solid 1px lightgray',
      justifyContent: 'space-around',
      marginTop: toolbarTopMargin,
    },
    toolbarButton: {
      margin: '3px',
      ':hover': {
        color: 'skyblue !important',
      },
    },
    toolbarFilter: {
      flexGrow: '1',
    },
    inputValueModified: {
      color: 'red',
    },
    linesInput: {
      maxWidth: '100px',
    },
    selectedOption: {
      color: 'blue',
    },
    logSection: {
      height: `calc(100% - ${titleHeight} - ${toolbarHeight} - ${toolbarTopMargin} ${
        toolbarShown ? ` - ${toolbarHeight} - ${toolbarTopMargin} ` : ''
      })`,
      overflow: 'scroll',
    },
    logLine: {
      marginLeft: '3px',
      marginBottom: '3px',
    },
    resizeHandle: {
      position: 'absolute',
      right: '0',
      bottom: '0',
      cursor: 'nw-resize',
      width: '13px',
      userSelect: 'none',
    },
  });

  useEffect(() => {
    if (endOfLogSectionRef?.current) {
      endOfLogSectionRef.current.scrollIntoView({ behaviour: 'smooth' });
    }
  });

  const state = store.getState();
  if (!state.isLogActive) return null;

  const setConfig = async (logLevel, maxLines) => {
    const newConfig = {
      logLevel: logLevel || state.logConfig.logLevel,
      lines: maxLines || state.logConfig.lines,
      filter: null,
      enabled: true,
    };
    await setLogConfig(newConfig);
    store.setState({ logConfig: newConfig });
  };

  const downloadFile = () => {
    const link = document.createElement('a');
    const content = document.getElementById('logSection').outerText;
    // eslint-disable-next-line no-undef
    const file = new Blob([content], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = 'topazLog.txt';
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const selectText = () => {
    if (document.selection) {
      // IE
      let range = document.body.createTextRange();
      range.moveToElementText(document.getElementById('logSection'));
      range.select();
    } else if (window.getSelection) {
      let range = document.createRange();
      range.selectNode(document.getElementById('logSection'));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
  };

  const copyText = async () => {
    document.execCommand('copy');
  };

  const eraseLogs = async () => {
    store.setState({ logs: [] });
  };

  const closeLog = async () => {
    const newConfig = {
      logLevel: state.logConfig.logLevel,
      lines: state.logConfig.lines,
      filter: null,
      enabled: false,
    };
    await setLogConfig(newConfig);
    store.setState({ isLogActive: false });
  };

  const removeSpecialChars = (str) => {
    try {
      if (!str) return '';
      return str
        .toString()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    } catch (ex) {
      return str.toLowerCase();
    }
  };

  return (
    <div id={'topazLogViewer'}>
      <div className={css(styles.windowWrapper)}>
        <div className={`helpHandleDragg ${css(styles.titleContainer)}`} onMouseDown={onMouseDownDraggable}>
          <FormattedMessage id="logTopaz" defaultMessage={`Log Topaz`} />
          <div className={css(styles.closeWindowHandle)} onMouseDown={closeLog}>
            x
          </div>
        </div>
        <div className={css(styles.toolbar)}>
          <button onClick={downloadFile} className={css(styles.toolbarButton)}>
            <SaveIcon />
          </button>
          <button onClick={selectText} className={css(styles.toolbarButton)}>
            <SelectAllIcon />
          </button>
          <button onClick={copyText} className={css(styles.toolbarButton)}>
            <CopyIcon />
          </button>
          <button onClick={eraseLogs} className={css(styles.toolbarButton)}>
            <EraseIcon />
          </button>
          <input
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            placeholder="Filtro"
            className={css(styles.toolbarFilter)}
            type={'text'}
          />
          <button
            className={`${toolbarShown === 'logLevel' ? css(styles.selectedOption) : '}'} ${css(styles.toolbarButton)}`}
            onClick={() => {
              setToolbarShown(toolbarShown !== 'logLevel' ? 'logLevel' : null);
            }}
          >
            <LevelsIcon />
          </button>
          <button
            className={`${toolbarShown === 'config' ? css(styles.selectedOption) : '}'} ${css(styles.toolbarButton)}`}
            onClick={() => {
              setToolbarShown(toolbarShown !== 'config' ? 'config' : null);
            }}
          >
            <SettingsIcon />
          </button>
        </div>
        {toolbarShown === 'logLevel' ? (
          <div className={css(styles.toolbar)}>
            <button onClick={() => setConfig(1)} className={state.logConfig.logLevel === 1 && css(styles.selectedOption)}>
              {props.intl.formatMessage({
                id: 'error',
                defaultMessage: 'Error',
              })}
            </button>
            <button onClick={() => setConfig(2)} className={state.logConfig.logLevel === 2 && css(styles.selectedOption)}>
              {props.intl.formatMessage({
                id: 'warn',
                defaultMessage: 'Warn',
              })}
            </button>
            <button onClick={() => setConfig(3)} className={state.logConfig.logLevel === 3 && css(styles.selectedOption)}>
              {props.intl.formatMessage({
                id: 'notif',
                defaultMessage: 'Notif',
              })}
            </button>
            <button onClick={() => setConfig(4)} className={state.logConfig.logLevel === 4 && css(styles.selectedOption)}>
              {props.intl.formatMessage({
                id: 'fase',
                defaultMessage: 'Fase',
              })}
            </button>
            <button onClick={() => setConfig(5)} className={state.logConfig.logLevel === 5 && css(styles.selectedOption)}>
              {props.intl.formatMessage({
                id: 'traza',
                defaultMessage: 'Traza',
              })}
            </button>
            <button onClick={() => setConfig(6)} className={state.logConfig.logLevel === 6 && css(styles.selectedOption)}>
              {props.intl.formatMessage({
                id: 'debug',
                defaultMessage: 'Debug',
              })}
            </button>
          </div>
        ) : toolbarShown === 'config' ? (
          <div className={css(styles.toolbar)}>
            <div>
              {props.intl.formatMessage({
                id: 'maxLinesVisor',
                defaultMessage: 'Max lineas visor',
              })}{' '}
              :
            </div>
            <input
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setMaxLinesShown(parseInt(e.target.value));
                  setMaxLinesShownModified(false);
                } else {
                  setMaxLinesShownModified(true);
                }
              }}
              className={`${css(styles.linesInput)} ${maxLinesShownModified ? css(styles.inputValueModified) : ''}`}
              type={'number'}
              defaultValue={maxLinesShown}
              title={props.intl.formatMessage({
                id: 'enterToSetValue',
                defaultMessage: 'Presione Enter para afectar el valor',
              })}
            />
            <div>
              {props.intl.formatMessage({
                id: 'maxLinesServer',
                defaultMessage: 'Max lineas server',
              })}{' '}
              :
            </div>
            <input
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setConfig(null, parseInt(e.target.value));
                  setMaxLinesServerModified(false);
                } else {
                  setMaxLinesServerModified(true);
                }
              }}
              className={`${css(styles.linesInput)} ${maxLinesServerModified ? css(styles.inputValueModified) : ''}`}
              type={'number'}
              defaultValue={state.logConfig.lines}
              title={props.intl.formatMessage({
                id: 'enterToSetValue',
                defaultMessage: 'Presione Enter para afectar el valor',
              })}
            />
          </div>
        ) : null}
        <div id={'logSection'} className={css(styles.logSection)}>
          {state?.logs && filter
            ? state.logs
                .slice(-maxLinesShown)
                .filter((log) => log && removeSpecialChars(log).indexOf(removeSpecialChars(filter)) > -1)
                .map((log, idx) => (
                  <div className={css(styles.logLine)} key={idx}>
                    {log}
                  </div>
                ))
            : state?.logs && !filter
            ? state.logs.slice(-maxLinesShown).map((log, idx) => (
                <div className={css(styles.logLine)} key={idx}>
                  {log}
                </div>
              ))
            : null}
          <div ref={endOfLogSectionRef} />
        </div>
        <div className={css(styles.resizeHandle)} onMouseDown={onMouseDownResizable}>
          +
        </div>
      </div>
    </div>
  );
};

export default TopazLogViewer;
