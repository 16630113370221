import React from 'react';
import theme from './themes.js';
import browserStorage from '../utils/browserStorage';

const defaultContextData = {
  dark: false,
  toggle: () => {},
  theme: {},
};

const ThemeContext = React.createContext(defaultContextData);
const useTheme = () => React.useContext(ThemeContext);

const useEffectDarkMode = () => {
  const [themeState, setThemeState] = React.useState({
    dark: false,
    hasThemeMounted: false,
  });
  React.useEffect((themeState) => {
    const bsDark = browserStorage.sessionGet('DARK_MODE') === true;
    setThemeState({
      ...themeState,
      dark: bsDark,
      hasThemeMounted: true,
    });
  }, []); // empty array [] to makes sure that we only call this effect when the component mounts (like componentDidMount).

  return [themeState, setThemeState];
};

const ThemeProvider = ({ children }) => {
  const [themeState, setThemeState] = useEffectDarkMode();

  if (!themeState.hasThemeMounted) {
    return <div />;
  }

  const toggle = () => {
    const dark = !themeState.dark;
    browserStorage.sessionSet('DARK_MODE', dark);
    setThemeState({ ...themeState, dark });
  };

  const computedTheme = themeState.dark ? theme('DARK_MODE') : theme('light');

  return (
    <ThemeContext.Provider
      value={{
        dark: themeState.dark,
        toggle,
        theme: computedTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, useTheme };
