import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components/ItemsGrid.css';
import Item from './Item/ItemView';
import { store } from '../store';

const propTypes = {
  items: PropTypes.array,
  handleClick: PropTypes.func.isRequired,
};

const ItemsGrid = ({ items, handleClick }) => {
  return (
    <div>
      <div className="ItemsGrid-container">
        {items.map((item, index) => (
          <Item
            key={item.id}
            itemName={item.name}
            itemId={item.id}
            onClick={() => {
              let recordOn = store.getState().recordOn;
              if (recordOn) {
                let textOperation = [];
                textOperation.push('oper' + item.id + '\n');
                textOperation.push('Feature: Operacion: ' + item.id + '\n');
                textOperation.push('Scenario: ' + item.name + '\n');
                window.sessionStorage.setItem('textOperation', JSON.stringify(textOperation));
              }
              handleClick(item.id, item.name, item.look);
            }}
          />
        ))}
      </div>
    </div>
  );
};

ItemsGrid.propTypes = propTypes;

export default ItemsGrid;
