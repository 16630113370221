import './../wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import babelPolyfill from 'babel-polyfill' // eslint-disable-line
import { Provider } from 'unistore/react';
import { store } from './store';
import { IntlProvider } from 'react-intl';
import { messages } from './translations';
import Root from './Root';
import config from '../config';
import { LANGUAGE } from './config';
import browserStorage from './utils/browserStorage';
// import 'bootstrap/dist/css/bootstrap.min.css';

const language = LANGUAGE || navigator.language.split(/[-_]/)[0] || config.defaultLanguage;

document.addEventListener('keypress', function (e) {
  if (e.ctrlKey && e.shiftKey && e.code === 'KeyL') {
    if (browserStorage.get('logsActivated')) {
      console.log('Logs desactivados!');
      browserStorage.set('logsActivated', false);
    } else {
      console.log('Logs activados!');
      browserStorage.set('logsActivated', true);
    }
  } else if (e.ctrlKey && e.shiftKey && e.code === 'KeyK' && browserStorage.get('logsActivated')) {
    if (browserStorage.get('logsCompressed')) {
      console.log('Compresión de logs desactivados!');
      browserStorage.set('logsCompressed', false);
    } else {
      console.log('Compresión de logs activados!');
      browserStorage.set('logsCompressed', true);
    }
  }
});

ReactDOM.render(
  <div>
    <IntlProvider locale={language} messages={messages[language]}>
      <Provider store={store}>
        <Root />
      </Provider>
    </IntlProvider>
  </div>,
  document.getElementById('root'),
);
