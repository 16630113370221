/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { ABOVE_ALL } from '../../../../UI-kit/constants/zIndexValues';
import { useIntl } from 'react-intl';

export const ProductionDateProcessWindow = (props) => {
  const intl = useIntl();

  const { item, setParametros } = props;

  useEffect(() => {}, [item, setParametros]);

  const cancelMessage = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  const acceptMessage = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });

  const modifyMessage = intl.formatMessage({
    id: 'modify',
    defaultMessage: 'Modificar',
  });

  const formatDateForServer = (date) => {
    if (date) {
      date = date.replaceAll('-', '');
      return date;
    }
  };

  const handleAccept = () => {
    let fechaproceso_ = document.getElementById('FechaProceso').value;
    let fechaprocesoanterior_ = document.getElementById('FechaProcesoAnterior').value;
    let fechaproximoproceso_ = document.getElementById('FechaProcesoProximo').value;
    let tipoPlazoCivil_ = document.getElementById('TipoPlazoCivil').checked;
    let tipo = 'C';

    if (tipoPlazoCivil_ === true) {
      tipo = ' ';
    }

    setParametros({
      fechaproceso: fechaproceso_,
      fechaprocesoanterior: fechaprocesoanterior_,
      fechaproximoproceso: fechaproximoproceso_,
      tipoplazo: tipo,
    });
    props.handleCancel();
  };

  let outside = null;
  // radio de tipo plazo tiene checked civil si no manda nada, checked comercial si manda algo
  return (
    <div className={`fixed pin overflow-auto flex bg-smoke-light w-full h-full`} ref={(c) => (outside = c)} style={{ zIndex: ABOVE_ALL }}>
      <div
        className={`border border-solid border-primary  bg-grey-lighter-lighter rounded relative p-4 w-full m-auto
           flex-col flex`}
        style={{ maxWidth: '35rem' }}
      >
        <div className={`flex-auto text-left m-1 flex `} style={{ height: 'fit-content', justifyContent: 'center' }}>
          <form className={'overflow-hidden'} style={{ display: 'table' }}>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="FechaProceso">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerProcessDate',
                  defaultMessage: 'Fecha de Proceso',
                })}
              </label>
              <input
                className={'clear-both border ml-2'}
                id="FechaProceso"
                type="date"
                defaultValue={props.params.fechaproceso}
                style={{ display: 'table-cell' }}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="FechaProcesoAnterior">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerPreviousProcessDate',
                  defaultMessage: 'Fecha de Proceso Anterior',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                style={{ display: 'table-cell' }}
                type="date"
                id="FechaProcesoAnterior"
                defaultValue={props.params.fechaprocesoanterior}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="FechaProcesoProximo">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerNextProcessDate',
                  defaultMessage: 'Fecha de Proceso Proximo',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                id="FechaProcesoProximo"
                defaultValue={props.params.fechaproximoproceso}
                type="date"
                style={{ display: 'table-cell' }}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }}>
                {intl.formatMessage({
                  id: 'productionManagerTermType',
                  defaultMessage: 'Tipo de Plazo',
                })}
              </label>
              {item.tipoplazo !== ' ' ? (
                <input
                  className={'clear-both border mt-2 ml-2'}
                  style={{ display: 'table-cell' }}
                  id="TipoPlazoComercial"
                  name="Tipo"
                  type="radio"
                  value="Comercial"
                  defaultChecked
                ></input>
              ) : (
                <input
                  className={'clear-both border mt-2 ml-2'}
                  style={{ display: 'table-cell' }}
                  id="TipoPlazoComercial"
                  name="Tipo"
                  type="radio"
                  value="Comercial"
                ></input>
              )}

              <label htmlFor="TipoPlazoComercial"> Comercial</label>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }}> </label>
              {item.tipoplazo === ' ' ? (
                <input
                  className={'clear-both border mt-2 ml-2'}
                  style={{ display: 'table-cell' }}
                  id="TipoPlazoCivil"
                  type="radio"
                  name="Tipo"
                  value="Civil"
                  defaultChecked
                ></input>
              ) : (
                <input
                  className={'clear-both border mt-2 ml-2'}
                  style={{ display: 'table-cell' }}
                  id="TipoPlazoCivil"
                  type="radio"
                  name="Tipo"
                  value="Civil"
                ></input>
              )}

              <label htmlFor="TipoPlazoCivil"> Civil</label>
            </p>
          </form>
        </div>
        <div className="flex justify-center mt-2 ml-2">
          <button
            data-autofocus
            className={`bg-grey
                       hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow`}
            style={{ width: 'fit-content' }}
            onClick={() => handleAccept()}
          >
            {acceptMessage}
          </button>

          <button
            className={`bg-grey
                   hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow ml-2`}
            style={{ width: 'fit-content' }}
            onClick={() => props.handleCancel()}
          >
            {cancelMessage}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductionDateProcessWindow;
