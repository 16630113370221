import React, { useState } from 'react';
import { store } from '../../../store';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFilterField, getFilterFieldValue } from './filterFields';
import { getOperacionesFilterResult } from './filterOperaciones';
import { getDescripciones, getSucursalDescriptions, getOperaciones, getUsuariosBasicInfo } from '../../../api/bitacora';
import {
  getHelpWindow,
  getHelpButton,
  getColumnsBitacoraSucursal,
  getColumnsgetOperaciones,
  getColumnsIniciales,
  getColumnsDescriptor,
} from './helpOperations';
import DateSelector from '../DateSelector';
import { exportToExcel } from './exportCSV';
import { css, StyleSheet } from 'aphrodite';
import ModalMessage from '../../ModalMessage';
import { createNullDate } from '../../../../UI-kit/helpers/dateHelper';

const ConsultaOperaciones = (props) => {
  const bitacoraApp = store.getState().bitacoraApp;
  let { filterListID } = props;
  const [helpColumns, setHelpColumns] = useState([]);
  const [helpData, setHelpData] = useState([]);
  const [title, setTitle] = useState([]);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  let bTodosInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.todos : true;
  let bAltaInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.alta : false;
  let bBajaInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.baja : false;
  let bModificacionInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.modificacion : false;
  let bDisabledInitialValue = !(
    bitacoraApp[filterListID] &&
    bitacoraApp[filterListID].filterParams &&
    bitacoraApp[filterListID].filterParams.descriptor !== ''
  );
  const [todosChecked, setTodosChecked] = useState(bTodosInitialValue);
  const [altaChecked, setAltaChecked] = useState(bAltaInitialValue);
  const [bajaChecked, setBajaChecked] = useState(bBajaInitialValue);
  const [modificacionChecked, setModificacionChecked] = useState(bModificacionInitialValue);
  const [disabledCheck, setDisabledCheck] = useState(bDisabledInitialValue);
  const [disabledButton, setDisabledButton] = useState(true);
  const [disabledButtonPrev, setDisabledButtonPrev] = useState(bitacoraApp[filterListID] ? bitacoraApp[filterListID].buttonPrevState : true);
  const [disabledButtonNext, setDisabledButtonNext] = useState(bitacoraApp[filterListID] ? bitacoraApp[filterListID].buttonNextState : true);
  const [beginData, setBeginData] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const filterFields = {
    asiento: null,
    operacion: {
      getData: getOperaciones,
      getColumns: getColumnsgetOperaciones,
      title: intl.formatMessage({
        id: 'Tablas.bitacora_operacion',
        defaultMessage: 'Operación',
      }),
    },
    sucursal: {
      getData: getSucursalDescriptions,
      getColumns: getColumnsBitacoraSucursal,
      title: intl.formatMessage({
        id: 'Tablas.bitacora_sucursal',
        defaultMessage: 'Sucursal',
      }),
    },
    descriptor: {
      getData: getDescripciones,
      getColumns: getColumnsDescriptor,
      title: intl.formatMessage({
        id: 'descriptor',
        defaultMessage: 'Descriptor',
      }),
    },
    iniciales: {
      getData: getUsuariosBasicInfo,
      getColumns: getColumnsIniciales,
      title: intl.formatMessage({
        id: 'iniciales',
        defaultMessage: 'Iniciales',
      }),
    },
  };
  const filterFieldsDescriptor21 = {
    cliente: null,
    cuenta: null,
    moneda: null,
    operacion2: null,
    rubroContable: null,
  };

  const updateChecks = (id) => {
    let alta = document.getElementById('alta') && document.getElementById('alta').checked;
    let baja = document.getElementById('baja') && document.getElementById('baja').checked;
    let modificacion = document.getElementById('modificacion') && document.getElementById('modificacion').checked;
    let todos = document.getElementById('todos') && document.getElementById('todos').checked;
    if (id === 'todos' && todos) {
      setAltaChecked(false);
      setBajaChecked(false);
      setModificacionChecked(false);
    }
    if (id !== 'todos' && (alta || baja || modificacion)) {
      setAltaChecked(alta);
      setBajaChecked(baja);
      setModificacionChecked(modificacion);
      setTodosChecked(false);
    }
  };

  const generateFilterField = (id) => {
    let helpButton = null;
    if (filterFields[id]) {
      helpButton = getHelpButton(id, filterFields[id], setHelpColumns, setHelpData, setLoading, setTitle, helpData ? helpData.length : 0);
    }

    const fieldElement = getFilterField(id, updateFilterInfo, intl, helpButton, filterListID);

    return fieldElement;
  };

  const updateStateChecks = () => {
    let value = document.getElementById('descriptor') && document.getElementById('descriptor').value;
    if (value && value !== '') {
      if (disabledCheck === true) {
        setDisabledCheck(false);
      }
    } else {
      if (disabledCheck === false) {
        setDisabledCheck(true);
      }
    }
  };

  const setDisabledExcel = (e) => {
    if (
      document.getElementById('descriptor') &&
      document.getElementById('descriptor').value &&
      document.getElementById('descriptor').value.trim() !== ''
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const updateFilterInfo = (e, first, max, nullDates) => {
    updateStateChecks();
    let fechaHoraDesde, fechaHoraHasta;
    if (nullDates) {
      let fromTime = createNullDate();
      let toTime = createNullDate();
      fechaHoraDesde = fromTime;
      fechaHoraHasta = toTime;
    } else {
      if (store.getState().bitacoraApp[filterListID] && store.getState().bitacoraApp[filterListID].filterParams) {
        fechaHoraDesde = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraDesde;
        fechaHoraHasta = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraHasta;
      }
    }
    let filterParams = {
      fechaHoraDesde,
      fechaHoraHasta,
      begin: first,
      maxResults: max,
      alta: getFilterFieldValue('alta', e, true),
      baja: getFilterFieldValue('baja', e, true),
      modificacion: getFilterFieldValue('modificacion', e, true),
      todos: getFilterFieldValue('todos', e, true),
      dynamicFilters: undefined,
      asiento: getFilterFieldValue('asiento', e),
      operacion: getFilterFieldValue('operacion', e),
      sucursal: getFilterFieldValue('sucursal', e),
      descriptor: getFilterFieldValue('descriptor', e),
      iniciales: getFilterFieldValue('iniciales', e),
      cliente: getFilterFieldValue('cliente', e),
      cuenta: getFilterFieldValue('cuenta', e),
      moneda: getFilterFieldValue('moneda', e),
      operacion2: getFilterFieldValue('operacion2', e),
      rubroContable: getFilterFieldValue('rubroContable', e),
      persistenceUnit: 'Local',
      compareVisibleRows: [],
      compareTextAreaFields: '{GRID_OPERACIONES}',
    };

    if (store.getState().bitacoraApp[filterListID]) {
      filterParams = { ...store.getState().bitacoraApp[filterListID].filterParams, ...filterParams };
    }
    let filterData = {};
    filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
    store.setState({
      bitacoraApp: {
        ...store.getState().bitacoraApp,
        ...filterData,
      },
    });
    closeHelp();
  };

  const setFilterFields = () => {
    document.getElementById('alta').checked = false;
    document.getElementById('baja').checked = false;
    document.getElementById('modificacion').checked = false;
    document.getElementById('todos').checked = true;
    document.getElementById('asiento').value = '';
    document.getElementById('operacion').value = '';
    document.getElementById('sucursal').value = '';
    document.getElementById('descriptor').value = '';
    document.getElementById('iniciales').value = '';
    setTodosChecked(true);
    setAltaChecked(false);
    setBajaChecked(false);
    setModificacionChecked(false);
    setDisabledCheck(bDisabledInitialValue);
  };

  const closeHelp = () => {
    setHelpColumns([]);
    setHelpData([]);
    setTitle('');
  };

  const exportExcel = () => {
    exportToExcel(store.getState().bitacoraApp[filterListID].resultData);
  };

  const helpAcceptHandler = (value, control) => {
    document.getElementById(control).value = value;
    let filterData = {};
    let controlEntry = {};
    controlEntry[control] = value;
    filterData[filterListID] = {
      ...store.getState().bitacoraApp[filterListID],
      filterParams: { ...store.getState().bitacoraApp[filterListID].filterParams, ...controlEntry },
    };
    store.setState({
      bitacoraApp: {
        ...store.getState().bitacoraApp,
        ...filterData,
      },
    });
    closeHelp();
  };

  let fields = [];
  for (let field of Object.keys(filterFields)) {
    fields.push(generateFilterField(field));
  }
  let fieldsDescriptor21 = [];
  if (bitacoraApp[filterListID] && bitacoraApp[filterListID].filterParams && bitacoraApp[filterListID].filterParams.descriptor === '21') {
    for (let field of Object.keys(filterFieldsDescriptor21)) {
      fieldsDescriptor21.push(generateFilterField(field));
    }
  }
  fieldsDescriptor21 = (
    <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
      {' '}
      {fieldsDescriptor21}
    </div>
  );

  const styles = StyleSheet.create({
    topazDisplay: {
      pointerEvents: loading ? 'none' : 'auto',
      cursor: loading ? 'wait' : 'auto',
    },
    overlay: {
      width: '100%',
      height: '100%',
      minHeight: '100%',
      zIndex: 100,
      position: 'absolute',
      top: 0,
      left: 0,
      cursor: 'wait',
    },
  });

  const handleBackPage = (e) => {
    let maxResults = store.getState().bitacoraApp[filterListID].filterParams.maxResults;
    let diff = beginData - maxResults;
    if (diff < 0) {
      diff = 0;
    }
    setBeginData(diff);
    updateFilterInfo(e, diff, 201);
    getOperacionesFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
    );
  };

  const handleFilter = (e) => {
    let fechaDesde = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraDesde;
    if (fechaDesde === undefined) {
      fechaDesde = createNullDate();
    }

    let fechaHasta = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraHasta;
    if (fechaHasta === undefined) {
      fechaHasta = createNullDate();
    }
    if (fechaHasta < fechaDesde) {
      setShowModal(true);
    }
    setLoading(true);
    updateFilterInfo(e, 0, 201);
    setDisabledExcel();
    getOperacionesFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
      setLoading,
    );
  };

  const handleExportCSV = (e) => {
    let state = store.getState();
    if (!state.bitacoraApp[filterListID].resultColumns || state.bitacoraApp[filterListID].resultColumns.length === 0) {
      props.showModal(<FormattedMessage id="noData" defaultMessage={`No hay datos!`} />);
    } else {
      updateFilterInfo(e, 0, 201);
      exportExcel();
    }
  };

  const handleCleanFilters = (e) => {
    setFilterFields();
    updateFilterInfo(e, 0, 201, true);
    getOperacionesFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
      setLoading,
    );
  };

  const handleNextPage = (e) => {
    setBeginData(beginData + 201);
    updateFilterInfo(e, beginData + 201, 201);
    getOperacionesFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
      <div className={css(loading && styles.overlay)}></div>
      <DateSelector filterListID={filterListID} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        <div className="flex content-center mb-2 text-sm">
          {showModal && (
            <ModalMessage
              message={
                <FormattedMessage
                  id="message_desde_no_mayor_que_hasta"
                  defaultMessage={`El valor de la fecha 'Desde' no puede ser mayor que 'Hasta'`}
                />
              }
              handleAccept={async () => {
                setShowModal(false);
              }}
              acceptTitle={'Aceptar'}
              draggable={true}
            />
          )}
          <input
            id="todos"
            checked={todosChecked}
            disabled={disabledCheck}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              setTodosChecked(!todosChecked);
              updateChecks(e.target.id);
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="Tablas.movimientos_todos" defaultMessage="Todos" />
          </label>
          <input
            id="alta"
            checked={altaChecked}
            disabled={disabledCheck}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              setAltaChecked(!altaChecked);
              updateChecks(e.target.id);
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="Tablas.movimientos_alta" defaultMessage="Alta" />
          </label>
          <input
            id="baja"
            checked={bajaChecked}
            disabled={disabledCheck}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              setBajaChecked(!bajaChecked);
              updateChecks(e.target.id);
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="Tablas.movimientos_baja" defaultMessage="Baja" />
          </label>
          <input
            id="modificacion"
            checked={modificacionChecked}
            disabled={disabledCheck}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              setModificacionChecked(!modificacionChecked);
              updateChecks(e.target.id);
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="Tablas.movimientos_modificacion" defaultMessage="Modificacion/Movimiento" />
          </label>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        {' '}
        {fields}
      </div>
      {fieldsDescriptor21}
      {getHelpWindow(helpData, helpColumns, helpAcceptHandler, closeHelp, title)}
      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        <button
          className={
            disabledButtonPrev
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          disabled={disabledButtonPrev}
          onClick={(e) => handleBackPage(e)}
        >
          {'<<'}
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={(e) => handleFilter(e)}
        >
          <FormattedMessage id="filtrar" defaultMessage={`Filtrar`} />
        </button>
        <button
          // className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          className={
            disabledButton
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          disabled={disabledButton}
          onClick={(e) => handleExportCSV(e)}
        >
          <FormattedMessage id="exportarCSV" defaultMessage={`Exportar CSV`} />
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={(e) => handleCleanFilters(e)}
        >
          <FormattedMessage id="borrarFiltro" defaultMessage={`Limpiar Filtros`} />
        </button>
        <button
          className={
            disabledButtonNext
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          disabled={disabledButtonNext}
          onClick={(e) => handleNextPage(e)}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default ConsultaOperaciones;
