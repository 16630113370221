import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'unistore/react';

import { useTheme } from './../../../../themes/ThemeContext';

const SubmitButton = ({ onClick, loading }) => {
  const intl = useIntl();

  const textButton = intl.formatMessage({
    id: 'search',
    defaultValue: 'Buscar',
  });

  const themeState = useTheme();
  const { color, primary } = themeState.theme;

  const styles = StyleSheet.create({
    button: {
      color: color.white,
      backgroundColor: primary.primary,
      padding: '.6rem',
      width: '100%',
      borderRadius: 4,
      boxShadow: '0 2px 0px 0 #00151F',
      transition: '.2s',
      ':hover': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.light,
      },
      ':focus': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.light,
      },
      ':active': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.dark,
      },
    },
  });

  return (
    <button
      type="submit"
      className={css(styles.button)}
      onClick={onClick}
      disabled={loading}
    >
      {textButton}
    </button>
  );
};

const mapStateToProps = (state) => {
  return { loading: state.clearing && state.clearing.loading };
};

export default connect(mapStateToProps)(SubmitButton);
