import { css, StyleSheet } from 'aphrodite';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import useFormulaValidation from '../../hooks/useFormulaValidation';
import { useTheme } from '../../themes/ThemeContext';
import Signature from './Signature';
import { getProxies, getPersonSignatures } from '../../api/signatures';
import { formatDateDDMMYYYY } from '../../../UI-kit/helpers/dateHelper';

const SignaturesSelector = (props) => {
  const { signatures, setSignatures, setValidatedFormula, currency, amount } = props;
  let { readOnly } = props;

  if (!readOnly) {
    readOnly = false;
  }
  const intl = useIntl();
  const themeContext = useTheme();
  const [signatureData, setSignatureData] = useState(undefined);
  const [proxy, setProxy] = useState(undefined);
  const [pSignatures, setPSignatures] = useState(undefined);
  const [loadingValidation, setLoadingValidation] = useState(false);
  const [formulaStatusText, setFormulaStatusText] = useState('');
  const [selectedSignatures, setSelectedSignatures] = useState([]);
  const [selectedPersonNumbers, setSelectedPersonNumbers] = useState([]);
  const [validationState, validateFormula] = useFormulaValidation();

  const handleOnClick = async (expirationDate) => {
    let categoria = proxy[0].categoria;
    let persona = proxy[0].idPersona;
    let signData = {};
    signData.categoria = categoria;
    signData.persona = persona;
    signData.vencimiento = formatDateDDMMYYYY(expirationDate);
    setSignatureData(signData);
  };

  const toggleSelect = (signatureId) => {
    // shallow copy of the items
    const newSignatures = [...signatures];

    // index of signature selected
    const index = newSignatures.findIndex((s) => s._id === signatureId);

    // shallow copy of the signature selected
    const signature = { ...signatures[index] };

    // property replace
    signature.selected = !signature.selected;

    // put signature back into the array
    newSignatures[index] = signature;

    // set the state to our new copy
    setSignatures(newSignatures);
  };

  useEffect(() => {
    const selected = signatures.filter((s) => s.selected);
    setSelectedSignatures(selected);
    setSelectedPersonNumbers([...new Set(selected.map((s) => s.personNumber))]);
    let personIds = signatures[0].name.replace(/\s/g, '').split(',');
    personIds.forEach(async (personId) => {
      getProxies(signatures[0].idEntidad, signatures[0].tipoPoder, signatures[0].tipoEntidad).then((proxies) => {
        setProxy(proxies);
        getPersonSignatures(proxies[0].idPersona).then((personSignatures) => {
          console.log(personSignatures);
          personSignatures.forEach((sign) => {
            if (sign.numeroPersonaFisica === parseInt(personId)) {
              sign.selected = true;
            }
          });
          setPSignatures(personSignatures);
        });
      });
    });
  }, [signatures]);

  useEffect(() => {
    const validate = async () => {
      setLoadingValidation(true);
      await validateFormula(
        currency,
        amount,
        selectedPersonNumbers,
        selectedSignatures[0].jtsBalance, // TODO
      );
      setLoadingValidation(false);
    };

    if (!readOnly && selectedSignatures.length > 0) {
      validate();
    }
  }, [amount, currency, readOnly, selectedPersonNumbers, selectedSignatures, signatures, validateFormula]);

  useEffect(() => {
    if (readOnly === false) {
      if (loadingValidation) {
        setFormulaStatusText('Validando fórmula...');
      } else if (validationState.validFormula) {
        setFormulaStatusText('Fórmula válida');
        setValidatedFormula(true);
      } else {
        setFormulaStatusText('Fórmula no válida');
        setValidatedFormula(false);
      }
    }
  }, [loadingValidation, readOnly, setValidatedFormula, signatures, validationState]);

  const { border } = themeContext.theme;
  const styles = StyleSheet.create({
    signaturesSelectorContainer: {
      margin: '1rem 0',
      height: '28rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    signatures: {
      height: '100%',
      width: '66%',
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'auto',
      paddingBottom: '1rem',
      ':after': {
        display: 'block',
        height: '1rem',
        width: '100%',
        content: '""',
      },
    },
    selectionInfo: {
      height: '100%',
      borderLeftWidth: 1,
      borderColor: border.dark,
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '1rem',
    },
  });

  const textSelectSignatures = readOnly
    ? intl.formatMessage({
        id: 'viewSignatures',
        defaultMessage: 'Firmas seleccionadas',
      })
    : intl.formatMessage({
        id: 'selectSignatures',
        defaultMessage: 'Seleccione las firmas que coinciden con la firma del cheque',
      });

  return (
    <div>
      <p>{textSelectSignatures}</p>
      <div className={css(styles.signaturesSelectorContainer)}>
        <div className={css(styles.signatures)}>
          {pSignatures &&
            pSignatures.map((signature) => {
              return (
                <Signature
                  key={signature._id}
                  data={signature}
                  selected={signature.selected}
                  toggleSelect={toggleSelect}
                  updateInfo={handleOnClick}
                />
              );
            })}
        </div>
        {!readOnly && (
          <div className={css(styles.selectionInfo)}>
            <p>Total de firmas: {signatures.length}</p>
            <p>Firmas seleccionadas: {selectedSignatures.length}</p>
            <p>{formulaStatusText}</p>
          </div>
        )}
        {readOnly && (
          <div>
            <div className={css(styles.selectionInfo)}>
              <p>Poder: {signatures[0].tipoPoder}</p>
              <p>Saldo: {signatures[0].idEntidad}</p>
            </div>
            <div className={css(styles.selectionInfo)}>
              <p> Categoria:{signatureData && signatureData.categoria}</p>
              <p> Persona:{signatureData && signatureData.persona}</p>
              <p> Vencimiento:{signatureData && signatureData.vencimiento}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignaturesSelector;
