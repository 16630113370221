import { store } from '../../../store';
import { getBitacoraGrupos } from '../../../api/bitacora';
import { formatDateForServer } from './filterFields';
import { exportToCsv } from './exportCSV';

const exportGrupos = (filterParams, columns, filterListID, intl) => {
  let { fechaHoraDesde, fechaHoraHasta, firstResult, maxResults, grupo, usuario, persistenceUnit } = filterParams;

  let filterData = {};
  filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
  store.setState({
    bitacoraApp: {
      ...store.getState().bitacoraApp,
      ...filterData,
    },
  });

  let defaultValue;
  fechaHoraDesde = formatDateForServer(fechaHoraDesde);
  fechaHoraHasta = formatDateForServer(fechaHoraHasta);
  firstResult = firstResult || 0;
  maxResults = 10000;
  grupo = grupo || defaultValue;
  usuario = usuario || defaultValue;
  persistenceUnit = persistenceUnit || 'Local';
  const params = {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    grupo,
    usuario,
    persistenceUnit,
    intl,
  };
  getBitacoraGrupos(params)
    .then((data) => {
      exportToCsv(data.resultData, 'grupos.csv', columns);
    })
    .catch((err) => {
      console.log(err);
    });
};

export { exportGrupos };
