/* eslint-disable no-unused-vars */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import 'rc-tree/assets/index.css';
import { PulseLoader } from 'halogenium';
import ParameterIcon from '../../resources/icons/fa/parameter.svg';
import NetworkIcon from '../../resources/icons/fa/network-map.svg';
import PrinterIcon from '../../resources/icons/fa/printer.svg';
import TopazMapIcon from '../../resources/icons/fa/topaz-map.svg';
import NumeratorIcon from '../../resources/icons/fa/numerator.svg';
import TreeMenuWrapper, { ItemComponent } from 'react-simple-tree-menu';
import { injectIntl } from 'react-intl';
import '../../styles/managerView.scss';
import AngleLeft from './../../resources/icons/fa/angle-left.svg';
import AngleRight from './../../resources/icons/fa/angle-right.svg';

import {
  // CATEGORY_IMPRESORAS,
  // CATEGORY_MAPARED,
  // CATEGORY_MAPATOPAZ,
  // CATEGORY_NUMERADORES,
  // CATEGORY_PARAMETROS,
  ProductionManagerButtons,
} from './ProductionManagerButtons';

import {
  CATEGORY_IMPRESORAS,
  CATEGORY_MAPARED,
  CATEGORY_MAPATOPAZ,
  CATEGORY_NUMERADORES,
  CATEGORY_PARAMETROS,
  ProductionManagerDataList,
} from './ProductionManagerDataList';
import {
  getParametros,
  getNumeratorDefinitions,
  getTopazPrts,
  getNetMaps,
  getTopazMaps,
  getSucursalesSc,
  getHelpMetadataByConcept,
  getMetadataByConceptNew,
  getRecordsByConcept,
} from '../../../src/api/productionManager';
import { PrinterWindow } from './Windows/PrinterWindow';
import { NumeratorWindow } from './Windows/NumeratorWindow';
import TopazMapWindow from './Windows/TopazMapWindow';
import NetMapWindow from './Windows/NetMapWindow';
import ParameterWindow from './Windows/ParameterWindow';

const pulseLoader = <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />;

let collapsed = false;

let Icon = (props) => {
  if (props.eventKey === '0-4') {
    return <NumeratorIcon height="15px"></NumeratorIcon>;
  } else if (props.eventKey === '0-3') {
    return <PrinterIcon height="15px"></PrinterIcon>;
  } else if (props.eventKey === '0-0') {
    return <ParameterIcon height="15px"></ParameterIcon>;
  } else if (props.eventKey === '0-1') {
    return <TopazMapIcon height="15px"></TopazMapIcon>;
  } else if (props.eventKey === '0-2') {
    return <NetworkIcon height="15px"></NetworkIcon>;
  } else {
    return <span></span>;
  }
};

let objectsMin = [
  {
    key: '0-0',
    title: ' ',
    children: [],
  },
  {
    key: '0-1',
    title: ' ',
    children: [],
  },
  {
    key: '0-2',
    title: ' ',
    children: [],
  },
  {
    key: '0-3',
    title: ' ',
    children: [],
  },
  {
    key: '0-4',
    title: ' ',
    children: [],
  },
];
class ProductionManagerView extends Component {
  constructor(props) {
    super(props);
    let keys = ['0-0-0-0'];
    this.state = {
      defaultExpandedKeys: keys,
      defaultSelectedKeys: keys,
      defaultCheckedKeys: keys,
      collapsed: false,
      show: false,
      data: [],
      parameters: [],
      numeratorDefinitions: [],
      printers: [],
      netMap: [],
      mapaTopaz: [],
      dataToDisplay: [],
      sucursales: [],
      monedas: [],
      showWindow: false,
      selectedItem: null,
      selectedIndex: null,
      expandedKeys: [],
      loading: false,
      treeData: [],
      banderita: false,
      titulo: '',
      hideDiv: false,
      buttonHideDos: true,
      posicion: 0,
      actualIndexToFilter: null,
    };

    this.treeRef = React.createRef();
    this.buttonHide = React.createRef();
  }

  setTreeRef = (tree) => {
    this.tree = tree;
  };
  componentDidMount() {
    this.loadData(false);
  }
  compareValuesPrt(a, b) {
    if (a.nrosucursal > b.nrosucursal) {
      return 1;
    } else if (a.nrosucursal < b.nrosucursal) {
      return -1;
    } else if (a.nrosucursal === b.nrosucursal) {
      if (a.nromaq > b.nromaq) {
        return 1;
      } else if (a.nromaq < b.nromaq) {
        return -1;
      } else {
        return 0;
      }
    }
  }
  compareValuesNetMap(a, b) {
    if (a.nrosucursal > b.nrosucursal) {
      return 1;
    } else if (a.nrosucursal < b.nrosucursal) {
      return -1;
    } else if (a.nrosucursal === b.nrosucursal) {
      if (a.numerodemaquina > b.numerodemaquina) {
        return 1;
      } else if (a.numerodemaquina < b.numerodemaquina) {
        return -1;
      } else {
        return 0;
      }
    }
  }

  compareValuesNumerator(a, b) {
    if (a.numero > b.numero) {
      return 1;
    } else if (a.numero < b.numero) {
      return -1;
    }
  }

  compareValues(a, b) {
    if (a.nrosucursal > b.nrosucursal) {
      return 1;
    } else if (a.nrosucursal < b.nrosucursal) {
      return -1;
    } else if (a.nrosucursal === b.nrosucursal) {
      if (a.grupomapa > b.grupomapa) {
        return 1;
      } else if (a.grupomapa < b.grupomapa) {
        return -1;
      } else {
        if (a.nromaq > b.nromaq) {
          return 1;
        } else if (a.nromaq < b.nromaq) {
          return -1;
        } else {
          return 0;
        }
      }
    }
  }

  sortFunctions = {
    topazMapSort: this.compareValues,
    netMapSort: this.compareValuesNetmap,
    printerSort: this.compareValuesPrt,
    numeratorSort: this.compareValuesNumerator,
  };

  loadData = async (refresh) => {
    // Utilzamos promise por que ejecuta todas las apis de una, no espera a que termine una para ejecutar otra, mas rapido
    const [numeratorDefinitions, parameters, printers, netMap, mapaTopaz, sucursales, monedas] = await Promise.all([
      getNumeratorDefinitions(),
      getParametros(),
      getTopazPrts(),
      getNetMaps(),
      getTopazMaps(),
      getSucursalesSc(),
      getRecordsByConcept(3),
    ]);

    let childrenParameters = [];
    let childrenMapaTopaz = [];
    let childrenMapaDeRed = [];
    let childrenImpresoras = [];
    let childrenNumeradores = [];
    let titlesChildrenMapaTopaz = [];
    let titlesChildrenMapaDeRed = [];
    let titlesChildrenImpresoras = [];

    mapaTopaz.sort(this.compareValues);
    printers.sort(this.compareValuesPrt);

    mapaTopaz.forEach((m) => {
      let group = m.grupomapa;
      let comment = m.comentario;
      let childrenTitle = `GrupoMapa: ${group} ${comment}`;
      if (!titlesChildrenMapaTopaz.includes(childrenTitle) && m.nromaq === 0) {
        titlesChildrenMapaTopaz.push(childrenTitle);
      }
    });
    titlesChildrenMapaTopaz.forEach((title, index) => {
      let child = {
        key: `0-1-${index}`,
        label: title,
      };
      childrenMapaTopaz.push(child);
    });
    netMap.forEach((n) => {
      let number = n.nrosucursal;
      let childrenTitle = `NroSucursal: ${number}`;
      if (!titlesChildrenMapaDeRed.includes(childrenTitle)) {
        titlesChildrenMapaDeRed.push(childrenTitle);
      }
    });
    titlesChildrenMapaDeRed.forEach((title, index) => {
      let child = {
        key: `0-2-${index}`,
        label: title,
      };
      childrenMapaDeRed.push(child);
    });
    printers.forEach((p) => {
      let nombreTopaz = p.nombretopaz;
      let numeroMaquina = p.nromaq;
      let childrenTitle = `Nombretopaz: ${nombreTopaz}`;
      if (!titlesChildrenImpresoras.includes(childrenTitle) && numeroMaquina === 0) {
        titlesChildrenImpresoras.push(childrenTitle);
      }
    });
    titlesChildrenImpresoras.forEach((title, index) => {
      let child = {
        key: `0-3-${index}`,
        label: title,
      };
      childrenImpresoras.push(child);
    });

    let objects = [
      {
        key: '0-0',
        label: 'Parámetros',
        nodes: childrenParameters,
      },
      {
        key: '0-1',
        label: 'Mapa Topaz',
        nodes: childrenMapaTopaz,
      },
      {
        key: '0-2',
        label: 'Mapa de Red',
        nodes: childrenMapaDeRed,
      },
      {
        key: '0-3',
        label: 'Impresoras',
        nodes: childrenImpresoras,
      },
      {
        key: '0-4',
        label: 'Numeradores',
        nodes: childrenNumeradores,
      },
    ];
    this.setState({ data: objects });
    this.setState({ parameters: parameters });
    this.setState({ numeratorDefinitions: numeratorDefinitions });
    this.setState({ printers: printers });
    this.setState({ netMap: netMap });
    this.setState({ mapaTopaz: mapaTopaz });
    this.setState({ sucursales: sucursales });
    this.setState({ monedas: monedas });
    this.setState({ loading: true });
    this.setState({ show: true });
    if (!refresh) {
      this.setState({ banderita: true });
      this.setState({ show: true });
      this.setState({ dataToDisplay: [this.state.parameters] });
    }
    this.onSelect({ key: '0-0', label: 'Parámetros', children: [] });
  };

  handleKeyOnNumber = (e) => {
    if (['-', '+', 'e', '.'].includes(e.key)) {
      e.preventDefault();
    }
  };

  toggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
    collapsed = !collapsed;
  };

  setModifiedItem = (item, index) => {
    let datatoModify = this.state.dataToDisplay;
    datatoModify[index] = item;
    this.setState({ dataToDisplay: datatoModify });
    this.setState({ selectedItem: item });
  };

  onExpand = (expandedKeys) => {
    this.setState({ expandedKeys: expandedKeys });
  };

  refreshData = (node, item, sortFunction) => {
    let auxState = null;
    let newData = this.state.dataToDisplay;
    newData.push(item);
    switch (node) {
      case CATEGORY_MAPATOPAZ:
        auxState = this.state.mapaTopaz;
        break;
      case CATEGORY_MAPARED:
        auxState = this.state.netMap;
        break;
      case CATEGORY_NUMERADORES:
        auxState = this.state.numeratorDefinitions;
        break;
      case CATEGORY_IMPRESORAS:
        auxState = this.state.printers;
        break;
      default:
        break;
    }

    auxState.sort(sortFunction);
    newData.sort(sortFunction);
    switch (node) {
      case CATEGORY_MAPATOPAZ:
        this.setState({ mapaTopaz: newData });
        break;
      case CATEGORY_MAPARED:
        this.setState({ netMap: newData });
        break;
      case CATEGORY_NUMERADORES:
        this.setState({ numeratorDefinitions: newData });
        break;
      case CATEGORY_IMPRESORAS:
        this.setState({ printers: newData });
        break;
      default:
        break;
    }
    this.setState({ dataToDisplay: newData });
  };

  onSelect = (node) => {
    this.setState({ titulo: node.label });
    this.setState({ banderita: false });
    this.setState({ show: true });
    let title = node.label;
    let separatedTitle = title.split(' ');
    let indexToFilter = -1;
    let selectedNode = title;
    let pos = node.key;
    let posCortado = pos.split('/');

    switch (posCortado[0]) {
      case '0-0':
        selectedNode = 'Parametros';
        indexToFilter = parseInt(separatedTitle[1]);
        break;
      case '0-1':
        selectedNode = 'Mapa Topaz';
        indexToFilter = parseInt(separatedTitle[1]);
        break;
      case '0-2':
        selectedNode = 'Mapa de Red';
        indexToFilter = parseInt(separatedTitle[1]);

        break;
      case '0-3':
        selectedNode = 'Impresoras';
        indexToFilter = separatedTitle[1];
        break;
      default:
        break;
    }
    this.setState({ selectedNode: selectedNode, collapsed: false });
    this.setState({ actualIndexToFilter: indexToFilter });
    switch (selectedNode) {
      case CATEGORY_PARAMETROS:
        this.setState({ dataToDisplay: [this.state.parameters] });
        break;
      case CATEGORY_MAPATOPAZ:
        dataToDisplay = this.state.mapaTopaz.slice();
        if (indexToFilter >= 0) {
          dataToDisplay = dataToDisplay.filter((row) => row.grupomapa === indexToFilter);
        }
        this.setState({ dataToDisplay: dataToDisplay });
        break;
      case CATEGORY_MAPARED:
        dataToDisplay = this.state.netMap.slice();
        if (indexToFilter >= 0) {
          dataToDisplay = dataToDisplay.filter((row) => row.nrosucursal === indexToFilter);
        }
        this.setState({ dataToDisplay: dataToDisplay });
        break;
      case CATEGORY_NUMERADORES:
        let dataToDisplay = this.state.numeratorDefinitions.slice();
        this.setState({ dataToDisplay: dataToDisplay });
        break;
      case CATEGORY_IMPRESORAS:
        dataToDisplay = this.state.printers.slice();
        if (indexToFilter !== -1 && indexToFilter !== undefined) {
          dataToDisplay = dataToDisplay.filter((row) => row.nombretopaz === indexToFilter);
        }
        this.setState({ dataToDisplay: dataToDisplay });
        break;
      default:
        break;
    }
  };

  openedFolderIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
      />
    </svg>
  );

  closedFolderIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
      />
    </svg>
  );

  ocultarTree = (e, oculto) => {
    // this.setState({ buttonHideDos: false });
    this.setState({ hideDiv: oculto });
  };

  render() {
    const { intl } = this.props;
    return !this.state.loading ? (
      <div
        className="relative w-full mt-6 p-2 px-6 flex justify-between items-center h-full"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
      </div>
    ) : (
      <div
        className="col relative w-full p-2 px-6 text-sm"
        style={{
          transition: 'width 2s',
        }}
      >
        <div className="divHide">
          {!this.state.hideDiv ? (
            <button
              className="btnHide"
              onClick={(e) => {
                this.ocultarTree(this, true);
              }}
            >
              <AngleLeft height="20px" width="20px" />
            </button>
          ) : (
            <button
              ref={this.buttonHide}
              className="btnHide"
              onClick={(e) => {
                this.ocultarTree(e, false);
              }}
            >
              <AngleRight height="20px" width="20px" />
            </button>
          )}
        </div>
        <div
          className="izq"
          style={{
            transition: 'all 1s',
            transform: this.state.hideDiv ? 'translateX(-1000%)' : 'translateX(0)',
            minWidth: this.state.hideDiv ? '0%' : '30%',
            contentVisibility: this.state.hideDiv ? 'hidden' : 'visible',
          }}
        >
          <>
            <div className="processes-menu-container1">
              <div className="title">Mantenimiento de Producción</div>
              <TreeMenuWrapper
                onClickItem={this.onSelect}
                data={this.state.collapsed ? objectsMin : this.state.data}
                hasSearch={true}
                openedIcon={this.openedFolderIcon}
              >
                {({ items: nodes }) => (
                  <ul className="tree-item-group">
                    {nodes.map((node) => (
                      <div key={node.key}>
                        <ItemComponent {...node} openedIcon={this.openedFolderIcon} closedIcon={this.closedFolderIcon} {...node} />
                      </div>
                    ))}
                  </ul>
                )}
              </TreeMenuWrapper>
            </div>
          </>
        </div>
        <div
          className="der"
          style={{
            width: this.state.hideDiv ? '100%' : '65%',
          }}
        >
          <h4 className="miTitulo">{this.state.titulo === 'Parametros' ? 'Parámetros' : this.state.titulo}</h4>
          {/* {!this.state.banderita ? ( */}
          <ProductionManagerDataList
            loadData={this.loadData}
            selected={this.state.selectedNode}
            inScreen={this.state.show}
            data={this.state.dataToDisplay}
            dataPrinter={this.state.printers}
            dataTopazMap={this.state.mapaTopaz}
            dataNetMap={this.state.netMap}
            dataNumerator={this.state.numeratorDefinitions}
            dataParameters={this.state.parameters}
            sortFunctions={this.sortFunctions}
            setShowWindow={(category, item = null, index = null) => {
              if (category === CATEGORY_MAPATOPAZ) {
                this.setState({ showTopazMapWindow: true });
                this.setState({ selectedItem: this.state.dataToDisplay[index] });
                this.setState({ selectedIndex: index });
              } else if (category === CATEGORY_IMPRESORAS) {
                this.setState({ showPrinterWindow: true });
                this.setState({ selectedItem: this.state.dataToDisplay[index] });
                this.setState({ selectedIndex: index });
              } else if (category === CATEGORY_MAPARED) {
                this.setState({ showNetMapWindow: true });
                this.setState({ selectedItem: this.state.dataToDisplay[index] });
                this.setState({ selectedIndex: index });
              } else if (category === CATEGORY_PARAMETROS) {
                this.setState({ showParameterWindow: true });
                this.setState({ selectedItem: this.state.dataToDisplay[index] });
                this.setState({ selectedIndex: index });
              } else if (category === CATEGORY_NUMERADORES) {
                this.setState({ showNumeratorWindow: true });
                this.setState({ selectedItem: this.state.dataToDisplay[index] });
                this.setState({ selectedIndex: index });
              }
            }}
          />
        </div>
        {this.state.showPrinterWindow && (
          <PrinterWindow
            item={this.state.selectedItem}
            sucursales={this.state.sucursales}
            impresoras={this.state.printers}
            mapaDeRed={this.state.netMap}
            handleCancel={() => this.setState({ showPrinterWindow: false })}
            actualIndex={this.state.actualIndexToFilter}
            setModifiedItem={(newItem, index) => {
              this.setModifiedItem(newItem, index);
            }}
            addToDataAndSort={(item) => {
              this.refreshData(CATEGORY_IMPRESORAS, item, this.compareValuesPrt);
            }}
            handleKeyOnNumber={this.handleKeyOnNumber}
          />
        )}
        {this.state.showNetMapWindow && (
          <NetMapWindow
            handleCancel={() => this.setState({ showNetMapWindow: false })}
            mapaDeRed={this.state.netMap}
            item={this.state.selectedItem}
            actualIndex={this.state.actualIndexToFilter}
            index={this.state.selectedIndex}
            sucursales={this.state.sucursales}
            setModifiedItem={(newItem, index) => {
              this.setModifiedItem(newItem, index);
            }}
            addToDataAndSort={(item) => {
              this.refreshData(CATEGORY_MAPARED, item, this.compareValuesNetMap);
            }}
            handleKeyOnNumber={this.handleKeyOnNumber}
          />
        )}
        {this.state.showNumeratorWindow && (
          <NumeratorWindow
            handleCancel={() => this.setState({ showNumeratorWindow: false })}
            numerators={this.state.numeratorDefinitions}
            item={this.state.selectedItem}
            index={this.state.selectedIndex}
            setModifiedItem={(newItem, index) => {
              this.setModifiedItem(newItem, index);
            }}
            addToDataAndSort={(item) => {
              this.refreshData(CATEGORY_NUMERADORES, item, this.compareValuesNumerator);
            }}
            handleKeyOnNumber={this.handleKeyOnNumber}
          />
        )}
        {this.state.showTopazMapWindow && (
          <TopazMapWindow
            handleCancel={() => this.setState({ showTopazMapWindow: false })}
            mapaDeRed={this.state.netMap}
            sucursales={this.state.sucursales}
            mapaTopaz={this.state.mapaTopaz}
            item={this.state.selectedItem}
            actualIndex={this.state.actualIndexToFilter}
            index={this.state.selectedIndex}
            setModifiedItem={(newItem, index) => {
              this.setModifiedItem(newItem, index);
            }}
            addToDataAndSort={(item) => {
              this.refreshData(CATEGORY_MAPATOPAZ, item, this.compareValues);
            }}
            handleKeyOnNumber={this.handleKeyOnNumber}
          />
        )}
        {this.state.showParameterWindow ? (
          <ParameterWindow
            handleCancel={() => this.setState({ showParameterWindow: false })}
            mapaDeRed={this.state.netMap}
            sucursales={this.state.sucursales}
            monedas={this.state.monedas}
            mapaTopaz={this.state.mapaTopaz}
            item={this.state.selectedItem}
            setModifiedItem={(newItem, index) => {
              this.setModifiedItem(newItem, index);
            }}
            handleKeyOnNumber={this.handleKeyOnNumber}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default injectIntl(ProductionManagerView);
