import { Line, Label, Picture } from '../../UI-kit';
import { num2hex } from '../../src/utils/ColorMaper';
import { generateLineIDString } from '../../UI-kit/helpers/operationHelper';

export default class MessageToDisplay {
  constructor({ displayInfo, lineID }) {
    this._displayInfo = displayInfo;
    this._lineID = lineID;
  }

  getInputType(number) {
    return {
      0: Label,
      1: Line,
      4: Picture,
    }[number];
  }

  /**
   * Get the background color for this element
   * only if the guiType is 0.
   * The other types don't need background.
   * @param {enum} guiType The type of the MessageToDisplay
   * @param {number} backColor The number of color
   */
  getBackgroundColor(guiType, backColor) {
    if (guiType === 0 && backColor.value) {
      return num2hex(backColor.value);
    }
    return 'transparent';
  }

  transactionNumber() {
    return this._lineID ? this._lineID.transactionNumber : '';
  }

  lineNumber() {
    return this._lineID ? this._lineID.lineNumber : '';
  }

  countIterativeDialog() {
    if (this._lineID && this._lineID.countIterativeDialog !== undefined) {
      return this._lineID.countIterativeDialog;
    }
    return 0;
  }

  countVariableRow() {
    if (this._lineID && this._lineID.countVariableRow !== undefined) {
      return this._lineID.countVariableRow;
    }
    return 0;
  }

  transactionLineLabel() {
    return generateLineIDString(this._lineID);
  }

  props() {
    return {
      legend: this._displayInfo.value,
      position: this._displayInfo.displayInfo.position,
      font: this._displayInfo.displayInfo.font,
      backgroundColor: this.style().backgroundColor,
      backColor: this._displayInfo.displayInfo.backColor,
    };
  }

  style() {
    let style = {
      backgroundColor: this._displayInfo.displayInfo.backColor
        ? this.getBackgroundColor(this._displayInfo.guiType, this._displayInfo.displayInfo.backColor)
        : '#F4F5f7',
    };
    return style;
  }

  options() {
    return {};
  }

  component() {
    return this.getInputType(this._displayInfo.guiType);
  }
}
