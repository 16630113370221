export const doFocusElement = (propsFromFieldToDisplay, input) => {
  const { transactionLineLabel, focusElement, focusPaused } = propsFromFieldToDisplay;
  if (input && input.current && input.current.input && input.current.input.inputElement && transactionLineLabel === focusElement && !focusPaused) {
    input.current.input.inputElement.scrollIntoView(false);
    input.current.input.inputElement.focus();

    if (input.current.input.inputElement.selectionStart === 0 && input.current.input.inputElement.selectionEnd === 0) {
      input.current.input.inputElement.select();
    }
  }
};

export const canReceiveFocus = (e) => {
  const indexOfUnderscore = document && document.activeElement && document.activeElement.id && document.activeElement.id.indexOf('_');
  let TLPostfix = '';
  if (indexOfUnderscore > 0) {
    TLPostfix = document.activeElement.id.substr(indexOfUnderscore);
  }
  if (TLPostfix === '_Loading') {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    return false;
  }
  return true;
};
