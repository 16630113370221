import React, { Component } from 'react';
import FocusTrap from 'react-focus-trap';
import Draggable from 'react-draggable';
import { FormattedMessage } from 'react-intl';
import Overlay from '../../../UI-kit/components/Overlay';
import IcoMoon from 'react-icomoon';
import { store } from '../../store';
import { duplicateElementControl } from './HandleLineTest';

export class AssertComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assertType: '1',
    };
    this.handleStateChange = this.handleStateChange.bind(this);
    this.acceptHandler = this.acceptHandler.bind(this);
  }

  componentDidMount() {}

  handleStateChange(type) {
    if (this.state.assertType !== type) {
      this.setState({ assertType: type });
    }
  }

  cancelHandler() {
    let { setShowAssert } = this.props;
    setShowAssert(false);
  }

  async acceptHandler() {
    let { setShowAssert } = this.props;

    let recordOn = store.getState().recordOn;
    if (recordOn) {
      let textOperation = JSON.parse(window.sessionStorage.getItem('textOperation'));

      if (this.state.assertType === '1') {
        textOperation.push(
          'CMD:ASSERT ID:' + document.getElementById('assertIdTexto').value + ' VALOR:' + document.getElementById('assertTexto').value + '\n',
        );
      }

      if (this.state.assertType === '2') {
        textOperation.push('CMD:ASSERT ID:' + document.getElementById('assertId').value + '\n');
      }

      if (this.state.assertType === '3') {
        let min = 0;
        if (document.getElementById('numeroDesde').value && document.getElementById('numeroDesde').value !== '') {
          min = parseInt(document.getElementById('numeroDesde').value, 10);
        }

        let max = 0;
        if (document.getElementById('numeroHasta').value && document.getElementById('numeroHasta').value !== '') {
          max = parseInt(document.getElementById('numeroHasta').value, 10);
        }
        let lastElement = textOperation.pop();

        // Elimino el sendtext que se reemplaza por el random
        textOperation.pop();

        textOperation.push(
          'CMD:RANDOM ID:' + document.getElementById('assertIdNumerador').value + ' VALOR:' + min.toString() + ',' + max.toString() + '\n',
        );
        textOperation.push(lastElement);
      }

      textOperation = duplicateElementControl(textOperation);
      console.log(textOperation);
      window.sessionStorage.setItem('textOperation', JSON.stringify(textOperation));
    }

    setShowAssert(false);
  }

  render() {
    return (
      <FocusTrap>
        <Overlay style={{ zIndex: 300, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className="border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute"
            style={{
              zIndex: 300,
              height: 470,
              width: 600,
              top: 150,
              left: 400,
            }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move" style={{ padding: 1.5 }} />
            <div className="ml-5 mt-5 text-left">
              <div className="border-2 mr-5">
                <div
                  className="ml-5 text-left"
                  style={{
                    height: 100,
                  }}
                >
                  <div key="assertText" className="flex content-center mb-2 mt-2 text-sm">
                    <input
                      type="radio"
                      value="1"
                      checked={this.state.assertType === '1'}
                      className="focus:shadow-sm-light"
                      onClick={() => {
                        this.handleStateChange('1');
                      }}
                      id="1"
                    />
                    <label className="ml-2">Existe Texto</label>
                  </div>
                  <div className="ml-10 mt-2 text-left mb-5">
                    <label className="ml-2">Id: </label>
                    <input
                      id="assertIdTexto"
                      style={{
                        width: '400px',
                      }}
                      className="text-left appearance-none mr-1 px-1   ml-6 rounded shadow text-sm w-full"
                      type="text"
                      onChange={(event) => {}}
                      disabled={this.state.assertType !== '1'}
                    />
                  </div>
                  <div className="ml-10 mt-2 text-left mb-5">
                    <label className="ml-2">Texto: </label>
                    <input
                      id="assertTexto"
                      style={{
                        width: '400px',
                      }}
                      className="text-left appearance-none mr-1 px-1 rounded shadow text-sm w-full"
                      type="text"
                      onChange={(event) => {}}
                      disabled={this.state.assertType !== '1'}
                    />
                  </div>
                </div>
              </div>
              <div className="border-2 mr-5 mt-5">
                <div
                  className="ml-5 text-left"
                  style={{
                    height: 70,
                  }}
                >
                  <div key="assertEqual" className="flex content-center mb-2 mt-2 text-sm">
                    <input
                      type="radio"
                      value="2"
                      checked={this.state.assertType === '2'}
                      className="focus:shadow-sm-light"
                      onClick={() => {
                        this.handleStateChange('2');
                      }}
                      id="2"
                    />
                    <label className="ml-2">Existe id</label>
                  </div>
                  <div className="ml-10 mt-2 text-left mb-5">
                    <label className="ml-2">Id: </label>
                    <input
                      id="assertId"
                      style={{
                        width: '400px',
                      }}
                      className="text-left appearance-none mr-1 px-1 ml-6 rounded shadow text-sm w-full"
                      type="text"
                      onChange={(event) => {}}
                      disabled={this.state.assertType !== '2'}
                    />
                  </div>
                </div>
              </div>
              <div className="border-2 mr-5 mt-5">
                <div
                  className="ml-5 text-left"
                  style={{
                    height: 140,
                  }}
                >
                  <div key="assertText" className="flex content-center mb-2 mt-2 text-sm">
                    <input
                      type="radio"
                      value="3"
                      checked={this.state.assertType === '3'}
                      className="focus:shadow-sm-light"
                      onClick={() => {
                        this.handleStateChange('3');
                      }}
                      id="3"
                    />
                    <label className="ml-2">Random Numérico</label>
                  </div>
                  <div className="ml-10 mt-2 text-left mb-5">
                    <label className="ml-2">Id: </label>
                    <input
                      id="assertIdNumerador"
                      style={{
                        width: '400px',
                      }}
                      className="text-left appearance-none mr-1 px-1   ml-6 rounded shadow text-sm w-full"
                      type="text"
                      onChange={(event) => {}}
                      disabled={this.state.assertType !== '3'}
                    />
                  </div>
                  <div className="ml-10 mt-2 text-left mb-5">
                    <label className="ml-2">Desde: </label>
                    <input
                      id="numeroDesde"
                      style={{
                        width: '120px',
                      }}
                      className="text-left appearance-none mr-1 px-1 rounded shadow text-sm w-full"
                      type="number"
                      onChange={(event) => {}}
                      disabled={this.state.assertType !== '3'}
                    />
                  </div>
                  <div className="ml-10 mt-2 text-left mb-5">
                    <label className="ml-2">Hasta: </label>
                    <input
                      id="numeroHasta"
                      style={{
                        width: '120px',
                      }}
                      className="text-left appearance-none mr-1 px-1 rounded shadow text-sm w-full"
                      type="number"
                      onChange={(event) => {}}
                      disabled={this.state.assertType !== '3'}
                    />
                  </div>
                </div>
              </div>
              <div className={`mt-5 col-md-12 text-center`}>
                <button
                  className={`text-white rounded mt-32 m-2 py-1 px-6 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                  style={{
                    marginTop: '0rem',
                  }}
                  onClick={() => {
                    this.acceptHandler();
                  }}
                >
                  <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
                </button>
                <button
                  style={{
                    marginTop: '0rem',
                  }}
                  className={`text-white rounded m-2 py-1 px-6 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                  onClick={() => {
                    this.cancelHandler();
                  }}
                >
                  <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />
                </button>
              </div>
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );
  }
}
