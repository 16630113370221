import React, { useState } from 'react';
import { store } from '../../../store';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFilterField, getFilterFieldValue } from './filterFields';
import { getSucursalDescriptions, getUsuariosBasicInfo, getNetMapDescriptionsPorSucursal } from '../../../api/bitacora';
import { getHelpWindow, getHelpButton, getColumnsBitacoraSucursal, getColumnsIniciales, getColumnsMaquina } from './helpOperations';
import DateSelector from '../DateSelector';
import { getBitacoraLoginFilterResult } from './filterEntradaSalida';
import Field from '../components/Field';
import NumberFormat from 'react-number-format';
import { exportEntradaSalida } from './exportEntradaSalida';
import { css, StyleSheet } from 'aphrodite';
import ModalMessage from '../../ModalMessage';
import { createNullDate } from '../../../../UI-kit/helpers/dateHelper';

const ConsultaEntradasSalidas = (props) => {
  const bitacoraApp = store.getState().bitacoraApp;
  let { filterListID } = props;
  let bLoginInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.bLogin : true;
  let bLogoutInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.bLogout : true;
  let bFallidosInitialValue = bitacoraApp[filterListID] ? bitacoraApp[filterListID].filterParams.bFallidos : true;
  const [helpColumns, setHelpColumns] = useState([]);
  const [helpData, setHelpData] = useState([]);
  const [title, setTitle] = useState([]);
  const [loginChecked, setLoginChecked] = useState(bLoginInitialValue);
  const [logoutChecked, setLogoutChecked] = useState(bLogoutInitialValue);
  const [fallidosChecked, setFallidosChecked] = useState(bFallidosInitialValue);
  const [statusCantidad, setStatusCantidad] = useState(true);
  const [disbledButton, setDisbledButton] = useState(false);
  const [disabledButtonPrev, setDisabledButtonPrev] = useState(bitacoraApp[filterListID] ? bitacoraApp[filterListID].buttonPrevState : true);
  const [disabledButtonNext, setDisabledButtonNext] = useState(bitacoraApp[filterListID] ? bitacoraApp[filterListID].buttonNextState : true);
  const [beginData, setBeginData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [valueCantidad, setValueCantidad] = useState('');

  const intl = useIntl();

  const filterFields = {
    iniciales: {
      getData: getUsuariosBasicInfo,
      getColumns: getColumnsIniciales,
      title: intl.formatMessage({
        id: 'iniciales',
        defaultMessage: 'Iniciales',
      }),
    },
    maquina: {
      getData: getNetMapDescriptionsPorSucursal,
      getColumns: getColumnsMaquina,
      title: intl.formatMessage({
        id: 'Tablas.bitacora_maquina',
        defaultMessage: 'N° de Máquina',
      }),
    },
    sucursal: {
      getData: getSucursalDescriptions,
      getColumns: getColumnsBitacoraSucursal,
      title: intl.formatMessage({
        id: 'Tablas.bitacora_sucursal',
        defaultMessage: 'Sucursal',
      }),
    },
  };

  const generateFilterField = (id) => {
    let helpButton = null;
    if (filterFields[id]) {
      helpButton = getHelpButton(id, filterFields[id], setHelpColumns, setHelpData, setLoading, setTitle, helpData ? helpData.length : 0);
    }

    const fieldElement = getFilterField(id, updateFilterInfo, intl, helpButton, filterListID);

    return fieldElement;
  };

  const updateChecks = (bLogin, blogouts, bfallidos) => {
    setLoginChecked(bLogin);
    setLogoutChecked(blogouts);
    setFallidosChecked(bfallidos);
    if (!bLogin && !blogouts && bfallidos) {
      setStatusCantidad(false);
    } else {
      document.getElementById('cantidad').value = '';
      setValueCantidad('');
      setStatusCantidad(true);
    }

    if (!bLogin && !blogouts && !bfallidos) {
      setDisbledButton(true);
    } else {
      setDisbledButton(false);
    }
  };
  const updateFilterInfo = (e, first, max, nullDates) => {
    if (store.getState().bitacoraApp.currentTabIndex === filterListID) {
      let fechaHoraDesde, fechaHoraHasta;
      if (nullDates) {
        let fromTime = createNullDate();
        let toTime = createNullDate();
        fechaHoraDesde = fromTime;
        fechaHoraHasta = toTime;
      } else {
        if (store.getState().bitacoraApp[filterListID] && store.getState().bitacoraApp[filterListID].filterParams) {
          fechaHoraDesde = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraDesde;
          fechaHoraHasta = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraHasta;
        }
      }

      let filterParams = {
        fechaHoraDesde,
        fechaHoraHasta,
        firstResult: first,
        maxResults: max,
        bLogin: getFilterFieldValue('blogins', e, true),
        bLogout: getFilterFieldValue('blogouts', e, true),
        bFallidos: getFilterFieldValue('bfallidos', e, true),
        cantidadFallos: getFilterFieldValue('cantidad', e),
        iniciales: getFilterFieldValue('iniciales', e),
        maquina: getFilterFieldValue('maquina', e),
        sucursal: getFilterFieldValue('sucursal', e),
        usuariored: undefined,
        macaddress: undefined,
        direccionIP: undefined,
        persistenceUnit: 'Local',
      };
      setValueCantidad(getFilterFieldValue('cantidad', e));
      if (store.getState().bitacoraApp[filterListID]) {
        filterParams = { ...store.getState().bitacoraApp[filterListID].filterParams, ...filterParams };
      }
      let filterData = {};
      filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
      store.setState({
        bitacoraApp: {
          ...store.getState().bitacoraApp,
          ...filterData,
        },
      });
      closeHelp();
    }
  };

  const setFilterFields = () => {
    document.getElementById('blogins').checked = true;
    document.getElementById('blogouts').checked = true;
    document.getElementById('bfallidos').checked = true;
    document.getElementById('cantidad').value = '';
    document.getElementById('iniciales').value = '';
    document.getElementById('maquina').value = '';
    document.getElementById('sucursal').value = '';
    document.getElementById('searchTexto').value = '';

    updateChecks(true, true, true);
  };

  const closeHelp = () => {
    setHelpColumns([]);
    setHelpData([]);
    setTitle('');
  };

  const helpAcceptHandler = (value, control) => {
    document.getElementById(control).value = value;
    let filterData = {};
    let controlEntry = {};
    controlEntry[control] = value;
    filterData[filterListID] = {
      ...store.getState().bitacoraApp[filterListID],
      filterParams: { ...store.getState().bitacoraApp[filterListID].filterParams, ...controlEntry },
    };
    store.setState({
      bitacoraApp: {
        ...store.getState().bitacoraApp,
        ...filterData,
      },
    });
    closeHelp();
  };

  let fields = [];
  for (let field of Object.keys(filterFields)) {
    fields.push(generateFilterField(field));
  }

  const styles = StyleSheet.create({
    topazDisplay: {
      pointerEvents: loading ? 'none' : 'auto',
      cursor: loading ? 'wait' : 'auto',
    },
    overlay: {
      width: '100%',
      height: '100%',
      minHeight: '100%',
      zIndex: 100,
      position: 'absolute',
      top: 0,
      left: 0,
      cursor: 'wait',
    },
  });

  const handleBack = (e) => {
    let maxResults = store.getState().bitacoraApp[filterListID].filterParams.maxResults;
    let diff = beginData - maxResults;
    if (diff < 0) {
      diff = 0;
    }
    setBeginData(diff);
    updateFilterInfo(e, diff, 201);
    getBitacoraLoginFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
    );
  };

  const handleFilter = (e) => {
    let fechaDesde = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraDesde;
    if (fechaDesde === undefined) {
      fechaDesde = createNullDate();
    }

    let fechaHasta = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraHasta;
    if (fechaHasta === undefined) {
      fechaHasta = createNullDate();
    }
    if (fechaHasta < fechaDesde) {
      setShowModal(true);
    }

    setLoading(true);
    setBeginData(0);
    updateFilterInfo(e, 0, 201);
    getBitacoraLoginFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
      setLoading,
    );
  };

  const handleExportCSV = (e) => {
    const state = store.getState();
    if (!state.bitacoraApp[filterListID].resultColumns || state.bitacoraApp[filterListID].resultColumns.length === 0) {
      props.showModal(<FormattedMessage id="noData" defaultMessage={`No hay datos!`} />);
    } else {
      updateFilterInfo(e, 0, 201);
      exportEntradaSalida(state.bitacoraApp[filterListID].filterParams, state.bitacoraApp[filterListID].resultColumns, filterListID);
    }
  };

  const handleCleanFilters = (e) => {
    setFilterFields();
    updateFilterInfo(e, 0, 201, true);
    getBitacoraLoginFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
    );
  };

  const handleNext = (e) => {
    setBeginData(beginData + 201);
    updateFilterInfo(e, beginData + 201, 201);
    getBitacoraLoginFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
      <div className={css(loading && styles.overlay)}></div>
      <DateSelector filterListID={filterListID} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '120%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        <div className="flex content-center mb-2 text-sm">
          {showModal && (
            <ModalMessage
              message={
                <FormattedMessage
                  id="message_desde_no_mayor_que_hasta"
                  defaultMessage={`El valor de la fecha 'Desde' no puede ser mayor que 'Hasta'`}
                />
              }
              handleAccept={async () => {
                setShowModal(false);
              }}
              acceptTitle={'Aceptar'}
              draggable={true}
            />
          )}
          <input
            id="blogins"
            checked={loginChecked}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              // setLoginChecked(!loginChecked);
              updateChecks(!loginChecked, logoutChecked, fallidosChecked);
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="logins" defaultMessage="Logins" />
          </label>
          <input
            id="blogouts"
            checked={logoutChecked}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              updateChecks(loginChecked, !logoutChecked, fallidosChecked);
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="logouts" defaultMessage="Logouts" />
          </label>
          <input
            id="bfallidos"
            checked={fallidosChecked}
            type="checkbox"
            className="focus:shadow-sm-light ml-10"
            onChange={(e) => {
              updateChecks(loginChecked, logoutChecked, !fallidosChecked);
              updateFilterInfo(e);
            }}
          />
          <label htmlFor="all" className="ml-2">
            <FormattedMessage id="fallidos" defaultMessage="Fallidos" />
          </label>
          <div style={{ width: '25%' }} className="focus:shadow-sm-light ml-10">
            <Field htmlFor="cantidad" labelText={<FormattedMessage id="cantidad" defaultMessage="Cantidad:" />}>
              <NumberFormat
                className="focus:shadow-sm-light ml-5"
                id="cantidad"
                type="text"
                disabled={statusCantidad}
                value={valueCantidad}
                maxLength={2}
                decimalScale={0}
                style={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'rgb(220, 224, 230)',
                  borderRadius: '2px',
                }}
              />
            </Field>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        {fields}
      </div>
      {getHelpWindow(helpData, helpColumns, helpAcceptHandler, closeHelp, title)}
      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        <button
          className={
            disabledButtonPrev
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          disabled={disabledButtonPrev}
          onClick={(e) => handleBack(e)}
        >
          {'<<'}
        </button>
        <button
          className={
            disbledButton
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          onClick={(e) => handleFilter(e)}
          disabled={disbledButton}
        >
          <FormattedMessage id="filtrar" defaultMessage={`Filtrar`} />
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={(e) => handleExportCSV(e)}
        >
          <FormattedMessage id="exportarCSV" defaultMessage={`Exportar CSV`} />
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={(e) => handleCleanFilters(e)}
        >
          <FormattedMessage id="borrarFiltro" defaultMessage={`Limpiar Filtros`} />
        </button>
        <button
          className={
            disabledButtonNext
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          disabled={disabledButtonNext}
          onClick={(e) => handleNext(e)}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default ConsultaEntradasSalidas;
