import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { getUrl, handleError, handleEndpointError } from './handlers';
import { API } from '../config';
import { store } from '../store';

export const getEsquemasContables = async () => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'schema',
      action: 'getEsquemasContables',
      params: `sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};

export const getEsquemasContablesRubro = async (rubro, rubroSalida, campo) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'schema',
      action: 'getEsquemasContablesRubro',
      params: `rubro=${rubro}&rubroSalida=${rubroSalida}&campo=${campo}&sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};

export const getEsquemasContablesProceso = async (rubro, proceso, moneda) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'schema',
      action: 'getEsquemasContablesProceso',
      params: `rubro=${rubro}&proceso=${proceso}&moneda=${moneda}&sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};

export const getEsquemaCampo = async (rubro, moneda, linea) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'schema',
      action: 'getEsquemasCampoRubroLinea',
      params: `rubro=${rubro}&moneda=${moneda}&linea=${linea}&sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};

export const getDescriptorDynamicFilter = async () => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'schema',
      action: 'getDescriptorDynamicFilter',
      params: `sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};

export const getAllTransacciones = async () => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    if (store.getState().schemaApp !== undefined) {
      let helpTransactionDescriptions = store.getState().schemaApp.helpTransactionDescriptions;
      if (helpTransactionDescriptions) {
        return helpTransactionDescriptions;
      }
    }

    const query = {
      endpoint: 'schema',
      action: 'getAllTransacciones',
      params: `sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    if (response.status === 200) {
      if (response.data && response.data.length > 0) {
        store.setState({
          schemaApp: {
            ...store.getState().schemaApp,
            helpTransactionDescriptions: response.data,
          },
        });
      }
      return response.data;
    } else {
      handleError(response);
    }
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};

export const getDescripciones = async () => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'schema',
      action: 'getDescripciones',
      params: `sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));

    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};

export const getDescripcionesCampo = async () => {
  try {
    if (store.getState().schemaApp !== undefined) {
      let helpCampoDosDescriptions = store.getState().schemaApp.helpCampoDosDescriptions;
      if (helpCampoDosDescriptions) {
        return helpCampoDosDescriptions;
      }
    }

    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'schema',
      action: 'getDescripcionesCampo',
      params: `sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));

    if (response.status === 200) {
      if (response.data && response.data.length > 0) {
        store.setState({
          schemaApp: {
            ...store.getState().schemaApp,
            helpCampoDosDescriptions: response.data,
          },
        });
      }
      return response.data;
    } else {
      handleError(response);
    }
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};

export const getDescripcionesTable = async (numeroCampo) => {
  try {
    // if (store.getState().schemaApp !== undefined) {
    //   let helpCampoDescriptions = store.getState().schemaApp.helpCampoDescriptions;
    //   if (helpCampoDescriptions) {
    //     return helpCampoDescriptions;
    //   }
    // }

    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'schema',
      action: 'getDescripcionesTable',
      params: `tablaValue=${numeroCampo}&sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));

    // if (response.status === 200) {
    //   if (response.data && response.data.length > 0) {
    //     store.setState({
    //       schemaApp: {
    //         ...store.getState().schemaApp,
    //         helpCampoDescriptions: response.data,
    //       },
    //     });
    //   }
    //   return response.data;
    // } else {
    //   handleError(response);
    // }
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};

export const getDescripcionesTableFiltro = async (numeroCampo) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'schema',
      action: 'getDescripcionesTable',
      params: `tablaValue=${numeroCampo}&sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};

export const getRecordsByConcept = async (conceptNumber, fieldToFilter, operator, value) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    if (conceptNumber === 3) {
      if (store.getState().schemaApp !== undefined) {
        let helpCurrencyDescriptions = store.getState().schemaApp.helpCurrencyDescriptions;
        if (helpCurrencyDescriptions) {
          return helpCurrencyDescriptions;
        }
      }
    } else if (conceptNumber === 4) {
      if (store.getState().schemaApp !== undefined) {
        let helpRubroDescriptions = store.getState().schemaApp.helpRubroDescriptions;
        if (helpRubroDescriptions) {
          return helpRubroDescriptions;
        }
      }
    }

    const query = {
      endpoint: 'schema',
      action: 'getRecordsByConcept',
      params: `conceptNumber=${conceptNumber}&fieldToFilter=${fieldToFilter}&operator=${operator}&value=${value}&sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    if (response.status === 200) {
      if (response.data && response.data.length > 0) {
        if (conceptNumber === 3) {
          store.setState({
            schemaApp: {
              ...store.getState().schemaApp,
              helpCurrencyDescriptions: response.data,
            },
          });
        } else if (conceptNumber === 4) {
          store.setState({
            schemaApp: {
              ...store.getState().schemaApp,
              helpRubroDescriptions: response.data,
            },
          });
        }
      }
      return response.data;
    } else {
      handleError(response);
    }
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};
export const getRecordsByConceptFilter = async (conceptNumber, fieldToFilter, operator, value) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'schema',
      action: 'getRecordsByConceptFilter',
      params: `conceptNumber=${conceptNumber}&fieldToFilter=${value}&operator=${operator}&value=${fieldToFilter}&sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};
export const getHelpMetadataByConcept = async (conceptNumber) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'schema',
      action: 'getHelpMetadataByConcept',
      params: `conceptNumber=${conceptNumber}&sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};

export const updateEsquema = async (esquemaContable) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.put(`${API}TopazWebClientWS/resources/schema/updateEsquemaContable?sessionId=${session}`, esquemaContable);
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export const deleteEsquema = async (esquemaContable) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.put(`${API}TopazWebClientWS/resources/schema/removeEsquemaContable?sessionId=${session}`, esquemaContable);
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export const deleteCampo = async (esquemaCampo) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.put(`${API}TopazWebClientWS/resources/schema/removeEsquemaCampo?sessionId=${session}`, esquemaCampo);
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export const addEsquema = async (esquemaContable) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.put(`${API}TopazWebClientWS/resources/schema/addEsquemaContable?sessionId=${session}`, esquemaContable);
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export const addConcepto = async (esquemaContable) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.put(`${API}TopazWebClientWS/resources/schema/addEsquemaConcepto?sessionId=${session}`, esquemaContable);
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export const addEsquemaCampo = async (esquemaContable) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.put(`${API}TopazWebClientWS/resources/schema/addEsquemaCampo?sessionId=${session}`, esquemaContable);
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export const updateEsquemaCampo = async (esquemaCampo) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.put(`${API}TopazWebClientWS/resources/schema/updateEsquemaCampo?sessionId=${session}`, esquemaCampo);
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export const getAllConceptos = async () => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'schema',
      action: 'getAllConceptos',
      params: `sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    return response.data;
  } catch (error) {
    handleEndpointError(error);
    return handleError(error);
  }
};
