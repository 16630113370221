import * as React from 'react';
import { useIntl } from 'react-intl';
import { ABORTED_PROCESS, ALL_STATES_VALUE, FINISHED_PROCESS, RUNNING_PROCESS, STOPPED_PROCESS } from '../../../models/constants';
import { SelectData } from '../../select/Select';

import { MonitorMenuOptionsView } from './MonitorMenuOptionsView';

interface MonitorMenuOptionsViewModelProps {
  onClickEliminateFinished: (event: React.MouseEvent) => void;
  onDateFilterChange: (value: string) => void;
  onIdFilterChange: (value: string) => void;
  onSelectedChange: (state: string) => void;
  selectedState: string;
  onResetFilter: (event: React.MouseEvent) => void;
  selectedId: string;
  selectedDate: string;
}

export function MonitorMenuOptionsViewModel({
  onClickEliminateFinished,
  onDateFilterChange,
  onIdFilterChange,
  onSelectedChange,
  selectedState,
  onResetFilter,
  selectedId,
  selectedDate,
}: MonitorMenuOptionsViewModelProps): JSX.Element {
  const intl = useIntl();
  const options: SelectData[] = [
    {
      label: intl.formatMessage({ id: 'finalized' }),
      value: FINISHED_PROCESS,
    },
    {
      label: intl.formatMessage({ id: 'aborted' }),
      value: ABORTED_PROCESS,
    },
    {
      label: intl.formatMessage({ id: 'stopped' }),
      value: STOPPED_PROCESS,
    },
    {
      label: intl.formatMessage({ id: 'executing' }),
      value: RUNNING_PROCESS,
    },
    {
      label: intl.formatMessage({ id: 'all' }),
      value: ALL_STATES_VALUE,
    },
  ];

  return (
    <MonitorMenuOptionsView
      onClickEliminateFinished={onClickEliminateFinished}
      options={options}
      onSelectedChange={onSelectedChange}
      selectedState={selectedState}
      onDateFilterChange={onDateFilterChange}
      onIdFilterChange={onIdFilterChange}
      onResetFilter={onResetFilter}
      selectedId={selectedId}
      selectedDate={selectedDate}
    />
  );
}
