import React, { PureComponent } from 'react';
import { connect } from 'unistore/react';
import withOperationServices from '../api/withOperationServices';
import { storeActions } from '../store';
import FilterAutosuggest from './FilterAutosuggest';
import { injectIntl } from 'react-intl';

export class FilterOperations extends PureComponent {
  render() {
    const { getOperations, addTab, intl } = this.props;
    return (
      <FilterAutosuggest
        multipleSections={true}
        getOptions={getOperations}
        onSelectOption={addTab}
        actionSelectOption={null}
        placeholder={intl.formatMessage({
          id: 'placeHolderFilterOperations',
          defaultMessage: 'Buscar operaciones',
        })}
        title={intl.formatMessage({
          id: 'operations',
          defaultMessage: 'operaciones',
        })}
      />
    );
  }
}

export default connect(null, storeActions)(withOperationServices(injectIntl(FilterOperations)));
