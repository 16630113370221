import React from 'react';
import { ABOVE_ALL } from '../../constants/zIndexValues';
import IcoMoon from 'react-icomoon';
import { PulseLoader } from 'halogenium';
import Draggable from 'react-draggable';
import { FormattedMessage } from 'react-intl';
import FocusTrap from 'react-focus-trap';

import Overlay from '../Overlay';

const WindowFile = ({ fileName, fileComment, handleAccept, closeWindow, handleChangeComment, children = null, loading = false }) => {
  const style = {
    width: 400,
  };

  return (
    <FocusTrap>
      <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
      <Draggable bounds="body" handle=".handleDragg">
        <div
          className={`border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute p-2 w-full max-w-md m-auto flex-col flex`}
          style={{ ...style, top: 40, left: 40, zIndex: ABOVE_ALL }}
        >
          <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move m-1 text-xs" />
          <div className="flex-auto text-left">
            <div className="text-primary mb-3 text-center">
              <FormattedMessage id="document" defaultMessage={`Documento`} />
            </div>
            <div className="flex mb-3 justify-between">
              <label className="text-sm font-bold w-32">
                <FormattedMessage id="file" defaultMessage={`Archivo`} />:
              </label>
              <div className="flex-grow">
                <input type="text" className="text-sm rounded shadow py-1" value={fileName} readOnly={true} disabled={true} />
                {children}
              </div>
            </div>
            <div className="flex mb-3">
              <label className="text-sm font-bold w-32">
                <FormattedMessage id="comment" defaultMessage={`Comentario`} />:
              </label>
              <div className="flex-grow">
                <textarea
                  className="text-sm w-full rounded shadow"
                  style={{ resize: 'none' }}
                  value={fileComment}
                  rows="2"
                  onChange={handleChangeComment}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <button
              className="text-white rounded shadow m-2 py-2 px-4 text-sm bg-grey focus:outline-none focus:bg-primary hover:bg-primary"
              onClick={handleAccept}
            >
              <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
            </button>
            {loading && <PulseLoader className="text-primary  m-2 py-2 px-4" color="currentColor" size="8px" margin="1px" />}
            <button
              className="text-white rounded shadow m-2 py-2 px-4 text-sm bg-grey focus:outline-none focus:bg-primary hover:bg-primary"
              onClick={closeWindow}
            >
              <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />
            </button>
          </div>
        </div>
      </Draggable>
    </FocusTrap>
  );
};

export default WindowFile;
