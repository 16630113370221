/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { ABOVE_ALL } from '../../../../UI-kit/constants/zIndexValues';
import { useIntl } from 'react-intl';
import {
  getHelpButton,
  getColumnsSucursal,
  getColumnsImpresora,
  getColumnsNumeroMaquina,
  getHelpWindow,
} from './ProductionHelpComponent/productionHelp';
import { useTheme } from '../../../themes/ThemeContext';

import { css, StyleSheet } from 'aphrodite';

import Draggable from 'react-draggable';
import FocusLock from 'react-focus-lock';
import { addNetMap, updateNetMap } from '../../../api/productionManager';
import ModalMessage from '../../ModalMessage';

export const NetMapWindow = (props) => {
  const themeContext = useTheme();

  const { color, commonButton, modal } = themeContext.theme;

  const styles = StyleSheet.create({
    show: {
      display: 'block',
      zIndex: 900,
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    modalMain: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxHeight: '80%',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      // minWidth: '320px',
      // maxWidth: '98vw',
      // maxHeight: '98vh',
      backgroundColor: modal.active.background,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: 'transparent',
      opacity: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.33) 0px 2px 8px',
      // alignItems: 'center',
      transition: 'border background .2s',
      padding: '.2rem',
    },
    titleContainer: {
      width: '100%',
      minHeight: '1.5rem',
      padding: '.5rem 1rem',
      color: color.grey.dark4,
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    messageContainer: {
      padding: '1.5rem 1rem',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    buttonsContainer: {
      padding: '.5rem .5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    button: {
      padding: '.5rem 1.2rem',
      marginRight: '.5rem',
      borderRadius: 4,
      display: 'block',
      backgroundColor: commonButton.default.background,
      color: commonButton.default.color,
      ':hover': {
        backgroundColor: commonButton.hover.background,
        color: commonButton.hover.color,
      },
      ':focus': {
        backgroundColor: commonButton.focus.background,
        color: commonButton.focus.color,
      },
      ':active': {
        backgroundColor: commonButton.active.background,
        color: commonButton.active.color,
      },
      transition: 'background .2s',
    },
  });

  const intl = useIntl();

  const { item, mapaDeRed, sucursales, setModifiedItem, actualIndex, handleKeyOnNumber } = props;

  const [modify, setModify] = useState(false);
  const [helpRows, setHelpRows] = useState([]);
  const [helpColumns, setHelpColumns] = useState([]);
  const [title, setTitle] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [textDialog, setTextDialog] = useState('');

  useEffect(() => {
    let itemToShow = item;
    if (itemToShow) {
      setModify(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelMessage = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  const acceptMessage = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });

  let outside = null;
  let columnsSucursal = getColumnsSucursal();
  let columnsNumeroMaquina = getColumnsNumeroMaquina();

  let parsedSucursales = [];

  sucursales.forEach((sucursal) => {
    let toAdd = {
      sucursal: sucursal[0],
      descripcion: sucursal[1],
    };
    parsedSucursales.push(toAdd);
  });

  let helpButtonSucursal = getHelpButton(
    'sucursal',
    {
      rows: parsedSucursales,
      columns: columnsSucursal,
      title: intl.formatMessage({
        id: 'branch',
        defaultMessage: 'Sucursal',
      }),
    },
    setHelpColumns,
    setHelpRows,
    setShowHelp,
    setTitle,
    modify,
  );

  let filteredNetMap = [];

  mapaDeRed.forEach((nodo) => {
    let toAdd = {
      numero: nodo.numerodemaquina,
      descripcion: nodo.descripcion,
    };
    filteredNetMap.push(toAdd);
  });

  let helpButtonNroDeMaquina = getHelpButton(
    'numeroDeMaquina',
    {
      rows: filteredNetMap,
      columns: columnsNumeroMaquina,
      title: intl.formatMessage({
        id: 'machineNumber',
        defaultMessage: 'Número de Máquina',
      }),
    },
    setHelpColumns,
    setHelpRows,
    setShowHelp,
    setTitle,
    modify,
  );

  const closeHelp = () => {
    setHelpColumns([]);
    setHelpRows([]);
    setShowHelp(false);
    setTitle('');
  };

  const helpAcceptHandler = (value, control) => {
    document.getElementById(control).value = value;
    document.getElementById(control).focus();
    closeHelp();
  };

  let helpWindow = getHelpWindow(helpRows, helpColumns, helpAcceptHandler, closeHelp, modify, title);

  const handleAccept = async () => {
    const { item, mapaDeRed, sucursales, index, addToDataAndSort } = props;
    let numerodemaquina = document.getElementById('NumeroDeMaquina').value;
    let nrosucursal = document.getElementById('Sucursal').value;
    let descripcion = document.getElementById('descripcion').value;
    let iniciodeldia = document.getElementById('iniciodeldia').checked;
    if (iniciodeldia === true) {
      iniciodeldia = '*';
    } else {
      iniciodeldia = ' ';
    }

    let findeldia = document.getElementById('findeldia').checked;
    if (findeldia === true) {
      findeldia = '*';
    } else {
      findeldia = ' ';
    }

    let usuario = document.getElementById('usuario').value;
    let direccioncomm = document.getElementById('direccioncomm').value;
    let nrodecaja = document.getElementById('nrodecaja').value;
    let direccionip = document.getElementById('direccionip').value;

    if (!modify) {
      if (numerodemaquina === undefined || numerodemaquina.trim() === '' || numerodemaquina === 0) {
        const sucursalMessage = intl.formatMessage({
          id: 'maquinanula',
          defaultMessage: 'El valor del campo Número de Maquina no puede ser nulo.',
        });
        setModal(true, sucursalMessage);
        return;
      }

      if (nrosucursal === undefined || nrosucursal.trim() === '') {
        const sucursalMessage = intl.formatMessage({
          id: 'sucursalnula',
          defaultMessage: 'El valor del campo Número de Sucursal no puede ser nulo.',
        });
        setModal(true, sucursalMessage);
        return;
      }

      let rowSuc = sucursales.filter((reg) => {
        return reg[0] === parseInt(nrosucursal);
      });

      if (rowSuc.length === 0) {
        const sucursalMessage = intl.formatMessage({
          id: 'noexistesucursal',
          defaultMessage: 'El número de Sucursal especificado no existe en la base de datos',
        });
        setModal(true, sucursalMessage);
        return;
      }

      if (!modify && isDuplicated()) {
        return;
      }
    }

    let nombredeenvio = ' ';
    if (modify) {
      nombredeenvio = item.nombredeenvio;
    }

    let topazMap = {
      numerodemaquina: parseInt(numerodemaquina),
      nrosucursal: parseInt(nrosucursal),
      descripcion: descripcion,
      nombredeenvio: nombredeenvio,
      iniciodeldia: iniciodeldia,
      findeldia: findeldia,
      usuario: usuario,
      direccioncomm: direccioncomm,
      nrodecaja: parseInt(nrodecaja),
      direccionip: direccionip,
    };
    if (modify) {
      let res = await updateNetMap(topazMap);
      if (res === false) {
        let message = intl.formatMessage({
          id: 'erroralactualizar',
          defaultMessage: 'Error al intentar actualizar ',
        });
        message += 'topazMap';
        setModal(true, message);
        return;
      } else {
        setModifiedItem(topazMap, index);
      }
    } else {
      let res = await addNetMap(topazMap);
      if (res === false) {
        let message = intl.formatMessage({
          id: 'erroraldardealta',
          defaultMessage: 'Error al intentar dar de alta sobre ',
        });
        message += 'topazMap';
        setModal(true, message);
        return;
      }
      addToDataAndSort(topazMap);
    }

    props.handleCancel();
  };

  const setModal = (state, text) => {
    setShowModal(state);
    setTextDialog(text);
  };

  const isDuplicated = () => {
    let numerodemaquina = document.getElementById('NumeroDeMaquina').value;
    if (numerodemaquina !== '') {
      let nrosucursal = document.getElementById('Sucursal').value;
      let rowExiste = mapaDeRed.filter((reg) => {
        return reg.numerodemaquina === parseInt(numerodemaquina) && reg.nrosucursal === parseInt(nrosucursal);
      });

      if (rowExiste.length > 0) {
        const sucursalMessage = intl.formatMessage({
          id: 'netmapduplicado',
          defaultMessage: 'Ya existe una Maquina con igual número de máquina e igual número de sucursal.',
        });
        setModal(true, sucursalMessage);
        return true;
      }
      return false;
    } else {
      const sucursalMessage = intl.formatMessage({
        id: 'nromaqnull',
        defaultMessage: 'El valor de "Numero de máquina" no puede ser vacío.',
      });
      setModal(true, sucursalMessage);
    }
  };

  const isDuplicatedSucursal = () => {
    let nrosucursal = document.getElementById('Sucursal').value;
    if (nrosucursal === '') {
      const sucursalMessage = intl.formatMessage({
        id: 'nrosucursalnull',
        defaultMessage: 'El valor de "Sucursal" no puede ser vacío.',
      });
      setModal(true, sucursalMessage);
    }
  };

  return (
    <div className={`fixed pin overflow-auto flex bg-smoke-light w-full h-full`} ref={(c) => (outside = c)} style={{ zIndex: ABOVE_ALL }}>
      {showModal && (
        <ModalMessage
          message={textDialog}
          handleAccept={async () => {
            setShowModal(false);
          }}
          acceptTitle={acceptMessage}
          draggable={true}
        />
      )}
      <FocusLock persistentFocus returnFocus>
        <div className={css(styles.modal, styles.show)}>
          {/* onKeyDown={handleKeyDown}> */}
          <div className={css(styles.modalMain)}>
            <Draggable handle=".drag-section" bounds={{ top: -200, right: 350, bottom: 200, left: -350 }}>
              <div className={`${css(styles.modalContent)}`}>
                <div className={`drag-section ${css(styles.titleContainer)}`}></div>
                <div className={css(styles.messageContainer)}>
                  <div className={`flex-auto text-left m-1 flex `} style={{ height: 'fit-content', justifyContent: 'center' }}>
                    <form className={'overflow-hidden'} style={{ display: 'table' }}>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="NumeroDeMaquina">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerMachineNumber',
                            defaultMessage: 'Número de Máquina',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          disabled={modify}
                          className={'border clear-both ml-2 mt-2'}
                          id="NumeroDeMaquina"
                          defaultValue={modify ? item.numerodemaquina : ''}
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                          onBlur={() => isDuplicated()}
                        ></input>
                        {helpButtonNroDeMaquina}
                      </p>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="Descripcion">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerDescription',
                            defaultMessage: 'Descripción',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          defaultValue={modify ? item.descripcion : ''}
                          className={'border clear-both ml-2 mt-2'}
                          id="descripcion"
                        ></input>
                      </p>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="NumeroDeCaja">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerFundNumber',
                            defaultMessage: 'Número de Caja',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          defaultValue={modify ? item.nrodecaja : ''}
                          className={'border clear-both ml-2 mt-2'}
                          id="nrodecaja"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                        ></input>
                      </p>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="Sucursal">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerBranch',
                            defaultMessage: 'Sucursal',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          disabled={modify}
                          defaultValue={!modify && actualIndex ? actualIndex : modify ? item.nrosucursal : ''}
                          className={'border clear-both ml-2 mt-2'}
                          id="Sucursal"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                          onBlur={() => isDuplicatedSucursal()}
                        ></input>
                        {helpButtonSucursal}
                      </p>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="UsuarioActual">
                          {intl.formatMessage({
                            id: 'productionManagerCurrentUser',
                            defaultMessage: 'Usuario Actual',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          disabled={true}
                          defaultValue={modify ? item.usuario : ''}
                          className={'border clear-both ml-2 mt-2'}
                          id="usuario"
                        ></input>
                      </p>

                      <div style={{ display: 'table-row' }}>
                        <div style={{ display: 'table-cell' }}></div>
                        <div style={{ display: 'table-cell' }}>
                          <input
                            // style={{ display: 'table-cell' }}
                            type="checkbox"
                            className={'clear-both mr-2 mt-2 ml-2'}
                            id="iniciodeldia"
                            disabled={true}
                            checked={item && item.iniciodeldia === '*'}
                          ></input>
                          <label htmlFor="InicioDia">
                            {intl.formatMessage({
                              id: 'productionManagerStartDay',
                              defaultMessage: ' Inicio del Día',
                            })}
                          </label>
                        </div>
                      </div>

                      <div style={{ display: 'table-row' }}>
                        <div style={{ display: 'table-cell' }}></div>
                        <div style={{ display: 'table-cell' }}>
                          <input
                            type="checkbox"
                            className={'clear-both mr-2 mt-2 ml-2'}
                            id="findeldia"
                            disabled={true}
                            checked={!modify || item.findeldia === '*'}
                          ></input>
                          <label htmlFor="FinDia">
                            {intl.formatMessage({
                              id: 'productionManagerEndDay',
                              defaultMessage: 'Fin del Día',
                            })}
                          </label>
                        </div>
                      </div>

                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="DireccionIP">
                          {intl.formatMessage({
                            id: 'productionManagerIPAddress',
                            defaultMessage: 'Direccion IP',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          disabled={true}
                          defaultValue={modify ? item.direccionip : ''}
                          className={'border clear-both ml-2 mt-2'}
                          id="direccionip"
                        ></input>
                      </p>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="DireccionCOMM">
                          {intl.formatMessage({
                            id: 'productionManagerCOMMAddress',
                            defaultMessage: 'Direccion COMM',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          disabled={true}
                          defaultValue={modify ? item.direccioncomm : ''}
                          className={'border clear-both ml-2 mt-2'}
                          id="direccioncomm"
                        ></input>
                      </p>
                    </form>
                    {showHelp && helpWindow}
                  </div>
                  <div className="flex justify-center pt-2">
                    <button
                      data-autofocus
                      className={`bg-grey
                       hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow`}
                      style={{ width: 'fit-content' }}
                      onClick={() => handleAccept()}
                    >
                      {acceptMessage}
                    </button>

                    <button
                      className={`bg-grey
                   hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow ml-2`}
                      style={{ width: 'fit-content' }}
                      onClick={() => props.handleCancel()}
                    >
                      {cancelMessage}
                    </button>
                  </div>
                </div>
              </div>
            </Draggable>
          </div>
        </div>
      </FocusLock>
    </div>
  );
};

export default NetMapWindow;
