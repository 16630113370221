// import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { useIntl } from 'react-intl';
import SchemaView from './SchemaView';

const SchemaViewModel = (props) => {
  const intl = useIntl();

  return (
    <>
      <h5 className="mt-4 ml-4">
        {intl.formatMessage({
          id: 'schema',
          defaultMessage: 'Esquemas contables',
        })}
      </h5>
      <div
        style={{
          height: '100%',
          display: 'flex',
        }}
      >
        <div
          className="relative w-full text-sm"
          style={{
            height: '90%',
            display: 'flex',
          }}
        >
          <SchemaView />
        </div>
      </div>
    </>
  );
};

export default SchemaViewModel;
