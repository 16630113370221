/* eslint-disable prettier/prettier */
import { useState } from 'react';

import { getAmountType, validateProxiesByAmount } from './../api/signatures';

const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const POWER_TYPE = 9;
const ENTITY_TYPE = 2;

const useFormulaValidation = () => {
  const [validationFormulaInfo, setValidationFormulaInfo] = useState();
  const [validFormula, setValidFormula] = useState(false);
  const [validAmount, setValidAmount] = useState(false);

  const validateFormula = async (
    checkCurrency,
    checkAmount,
    personNumbers,
    jtsBalance,
  ) => {
    await sleep(1000); // only for developing

    let maxAmountType = await getAmountType(jtsBalance, POWER_TYPE, ENTITY_TYPE);

    let parsedMaxAmountType = maxAmountType.slice(1,maxAmountType.length-1)

    // eslint-disable-next-line no-unused-vars
    const validationResponse = await validateProxiesByAmount(
      checkCurrency,
      checkAmount,
      personNumbers,
      parsedMaxAmountType,
    );


    setValidFormula(validationResponse.validaFormula);
    setValidAmount(validationResponse.validaMonto);
    setValidationFormulaInfo({
      maxAmount: validationResponse.montoMaximo.toFixed(2),
      maxAmountType: validationResponse.tipoMontoMaximo,
      operationAmount: validationResponse.montoOperacion.toFixed(2),
      operationCurrency: validationResponse.monedaOperacion
    });
    // setValidFormula(true);
    // setValidAmount(true);
    // setValidationFormulaInfo({
    //   maxAmount: 3000,
    //   maxAmountType: 'S',
    //   operationAmount: 3000,
    //   operationCurrency: 0,
    // });
  };

  const validationState = {
    validationFormulaInfo,
    validFormula,
    validAmount,
  };

  return [validationState, validateFormula];
};

export default useFormulaValidation;
