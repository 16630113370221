import browserStorage from '../utils/browserStorage';
import { USER_SESSION, USER_NAME } from '../utils/browserStorage/constants';
import { sessionLogout, hasProgramPermission } from '../api/session';
import { store, dispatchActions } from '../../src/store';
import { PATHNAME } from '../../src/config';

export let userIsLogged = () => browserStorage.sessionGet(USER_SESSION);

export let getUserName = () => browserStorage.sessionGet(USER_NAME);

export let verifyUserPermissions = async (programName) => {
  const data = await hasProgramPermission(programName);
  return data && typeof data !== 'string' && !data.mensaje;
};

export const logout = async () => {
  try {
    await sessionLogout();
  } catch (exception) {
    console.warn('logout failed');
    console.warn(exception);
  }
  store.setState({ operationsData: null });
  store.setState({ reportsData: null });
  browserStorage.sessionClearKey(USER_SESSION);
  browserStorage.sessionClearKey(USER_NAME);
  if (PATHNAME[PATHNAME.length - 1] === '/') {
    let slashIndex = window.location.href.indexOf('home');
    window.location.href = window.location.href.slice(0, slashIndex - 1);
  } else {
    window.location.href = PATHNAME;
  }
  store.setState(dispatchActions.reset(store.getState()));
};
