import React, { useState, useLayoutEffect } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useTheme } from '../../src/themes/ThemeContext';

import Draggable from 'react-draggable';
import FocusLock from 'react-focus-lock';
// import { useIntl } from 'react-intl';
// import 'bootstrap/dist/css/bootstrap.css';
import {
  getTopazMapsBK,
  getTopazPrtsBK,
  getNetMapsBK,
  removeTopazMapBk,
  removeNetMapBk,
  removeTopazPrtBk,
  addTopazMap,
  addNetMap,
  addTopazPrt,
} from '../api/productionManager';
import { CATEGORY_IMPRESORAS, CATEGORY_MAPARED, CATEGORY_MAPATOPAZ } from './ProductionManager/ProductionManagerDataList';

const ModalMessageRecover = (props) => {
  // const intl = useIntl();
  const { accionesModal, loadData, addItem } = props;
  const [recoverData, setRecoverData] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  // const [topazMap, setTopazMap] = useState([]);
  const topazMap = [];

  useLayoutEffect(() => {
    cargoPapeleraDeReciclaje();
    // setRecoverData(props.recoverData);
  }, [cargoPapeleraDeReciclaje]);

  const filtrar = () => {
    cargoPapeleraDeReciclaje();
  };
  /* // Se comentan ya que no se están utilizando
  const cancelMessage = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  const acceptMessage = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });
  */
  const themeContext = useTheme();

  const { color, commonButton, modal } = themeContext.theme;
  const styles = StyleSheet.create({
    show: {
      display: 'block',
      zIndex: 900,
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    modalMain: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxHeight: '80%',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      // minWidth: '320px',
      // maxWidth: '98vw',
      // maxHeight: '98vh',
      backgroundColor: modal.active.background,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: 'transparent',
      opacity: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.33) 0px 2px 8px',
      // alignItems: 'center',
      transition: 'border background .2s',
      padding: '.2rem',
    },
    titleContainer: {
      width: '100%',
      minHeight: '1.5rem',
      padding: '.5rem 1rem',
      color: color.grey.dark4,
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    messageContainer: {
      padding: '1.5rem 1rem',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    buttonsContainer: {
      padding: '.5rem .5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    button: {
      padding: '.5rem 1.2rem',
      marginRight: '.5rem',
      borderRadius: 4,
      display: 'block',
      backgroundColor: commonButton.default.background,
      color: commonButton.default.color,
      ':hover': {
        backgroundColor: commonButton.hover.background,
        color: commonButton.hover.color,
      },
      ':focus': {
        backgroundColor: commonButton.focus.background,
        color: commonButton.focus.color,
      },
      ':active': {
        backgroundColor: commonButton.active.background,
        color: commonButton.active.color,
      },
      transition: 'background .2s',
    },
  });

  const cargoPapeleraDeReciclaje = React.useCallback(async () => {
    // let topazMap = [{}];
    let objTopazMap = {};
    let element;

    let rec = [];
    let recuperados = [];
    let topazMaps = await getTopazMapsBK();
    let topazPrts = await getTopazPrtsBK();
    let netMaps = await getNetMapsBK();
    rec = topazMaps.concat(topazPrts);
    recuperados = rec.concat(netMaps);

    for (let option = 0; option < recuperados.length; option++) {
      var date = new Date(recuperados[option].fechahora);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }

      if (recuperados[option].grupomapa >= 0) {
        element = 'Topaz Map';
        objTopazMap = {
          element: element,
          detail:
            recuperados[option].nromaq +
            ' ' +
            recuperados[option].nrosucursal +
            ' ' +
            recuperados[option].grupomapa +
            ' ' +
            recuperados[option].path +
            ' ' +
            recuperados[option].comentario,
          fechaHora: dt + '/' + month + '/' + year,
          user: recuperados[option].username,
          data: recuperados[option],
        };
        topazMap.push(objTopazMap);
      } else if (recuperados[option].nombretopaz) {
        element = 'Printers';
        objTopazMap = {
          element: element,
          detail:
            recuperados[option].nrosucursal +
            ' ' +
            recuperados[option].nromaq +
            ' ' +
            recuperados[option].nombretopaz +
            ' ' +
            recuperados[option].comentario +
            ' ' +
            recuperados[option].nombrewindows,
          fechaHora: dt + '/' + month + '/' + year,
          user: recuperados[option].username,
          data: recuperados[option],
        };
        topazMap.push(objTopazMap);
      } else if (recuperados[option].numerodemaquina >= 0) {
        element = 'Net Map';
        objTopazMap = {
          element: element,
          detail:
            recuperados[option].nrosucursal + ' ' + recuperados[option].numerodemaquina + ' ' + recuperados[option].descripcion ||
            ' ' + ' ' + recuperados[option].findeldia ||
            ' ' + ' ' + recuperados[option].nrodecaja ||
            ' ',
          fechaHora: dt + '/' + month + '/' + year,
          user: recuperados[option].username,
          data: recuperados[option],
        };
        topazMap.push(objTopazMap);
      }
    }
    setRecoverData(topazMap);
  });

  // useEffect(() => {});
  const handleClick = (option) => {
    setSelectedValue(option);
  };

  // const handleDoubleClick = (value) => {
  //   handleWindowSelect(value);
  // };

  return (
    <FocusLock persistentFocus returnFocus>
      <div className={css(styles.modal, styles.show)}>
        {/* onKeyDown={handleKeyDown}> */}
        <div className={css(styles.modalMain)}>
          <Draggable handle=".drag-section" bounds={{ top: -200, right: 350, bottom: 200, left: -350 }}>
            <div className={`${css(styles.modalContent)}`}>
              <div className={`drag-section ${css(styles.titleContainer)}`}>Papelera de reciclaje</div>
              <div className={css(styles.messageContainer)}>
                <div>
                  {recoverData.length > 0 ? (
                    <div className="container">
                      <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow-md sm:rounded-lg">
                              <table className="table table-sm" style={{ fontSize: '12px', width: '100%' }} id="tblPapelera">
                                <thead>
                                  <tr>
                                    <th scope="col">Elemento</th>
                                    <th scope="col">Detalle</th>
                                    <th scope="col">Fecha/Hora</th>
                                    <th scope="col">Usuario</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {recoverData.map((option, index) => {
                                    return (
                                      <tr
                                        style={option === selectedValue ? { backgroundColor: '#9ABAF5' } : { backgroundColor: 'white' }}
                                        key={index}
                                        onClick={() => handleClick(option)}
                                        // onDoubleClick={() => handleDoubleClick(option)}
                                        className={index % 2 !== 0 ? 'table-active' : ''}
                                      >
                                        <td>{option.element}</td>
                                        <td>{option.detail}</td>
                                        <td>{option.fechaHora}</td>
                                        <td>{option.user}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="container"
                      style={{
                        maxHeight: '300px',
                        minHeight: '100px',
                        width: '600px',
                      }}
                    >
                      <div className="row" style={{ alignItems: 'center', display: 'flex', height: '100px', justifyContent: 'center' }}>
                        <h6
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          Papelera de reciclaje vacía{' '}
                        </h6>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={css(styles.buttonsContainer)}>
                {(!props.buttons || !props.buttons.length) && (
                  <div className="row" style={{ display: 'flex' }}>
                    <div className="col-3">
                      <button
                        id="okModalButton"
                        style={{
                          width: '150px',
                          fontSize: '12px',
                          outline: 'none',
                        }}
                        className="btn btn-info btn-sm"
                        handleClick={() => accionesModal('recuperar')}
                        onClick={(e) => {
                          if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) {
                            if (selectedValue.element === 'Net Map') {
                              let objRecuperar = {
                                descripcion: selectedValue.data.descripcion,
                                fechahora: selectedValue.data.fechaHora,
                                iniciodeldia: selectedValue.data.iniciodeldia,
                                nrodecaja: selectedValue.data.nrodecaja,
                                numerodemaquina: selectedValue.data.numerodemaquina,
                                nrosucursal: selectedValue.data.nrosucursal,
                                nombredeenvio: selectedValue.data.nombredeenvio,
                                findeldia: selectedValue.data.findeldia,
                                usuario: selectedValue.data.username,
                                direccioncomm: selectedValue.data.direccioncomm,
                                direccionip: selectedValue.data.direccionip,
                              };
                              addNetMap(objRecuperar).then(function (response) {
                                if (response === true) {
                                  removeNetMapBk(objRecuperar).then(function (response) {
                                    if (response === true) {
                                      if (props.dataToDisplay) {
                                        addItem(props.selectedIndex, objRecuperar, CATEGORY_MAPARED);
                                        loadData(true);
                                      }
                                      filtrar();
                                    }
                                  });
                                }
                              });
                            } else if (selectedValue.element === 'Topaz Map') {
                              let objRecuperar = {
                                nromaq: selectedValue.data.nromaq,
                                grupomapa: selectedValue.data.grupomapa,
                                nrosucursal: selectedValue.data.nrosucursal,
                                biblioteca: selectedValue.data.biblioteca,
                                path: selectedValue.data.path,
                                comentario: selectedValue.data.comentario,
                              };

                              addTopazMap(objRecuperar).then(function (response) {
                                if (response === true) {
                                  removeTopazMapBk(objRecuperar).then(function (response) {
                                    if (response === true) {
                                      if (props.dataToDisplay) {
                                        addItem(props.selectedIndex, objRecuperar, CATEGORY_MAPATOPAZ);
                                        loadData(true);
                                      }
                                      filtrar();
                                    }
                                  });
                                }
                              });
                            } else if (selectedValue.element === 'Printers') {
                              let objRecuperar = {
                                comentario: selectedValue.data.comentario,
                                nromaq: selectedValue.data.nromaq,
                                nombretopaz: selectedValue.data.nombretopaz,
                                biblioteca: selectedValue.data.biblioteca,
                                tipoimpresora: selectedValue.data.tipoimpresora,
                                nombrewindows: selectedValue.data.nombrewindows,
                                nrosucursal: selectedValue.data.nrosucursal,
                                puerto: selectedValue.data.puerto,
                              };
                              addTopazPrt(objRecuperar).then(function (response) {
                                if (response === true) {
                                  removeTopazPrtBk(objRecuperar).then(function (response) {
                                    if (response === true) {
                                      if (props.dataToDisplay) {
                                        addItem(props.selectedIndex, objRecuperar, CATEGORY_IMPRESORAS);
                                        loadData(true);
                                      }
                                      filtrar();
                                    }
                                  });
                                }
                              });
                            }
                          }
                        }}
                        // onKeyDown={(e) => {
                        //   if (e.key === 'Enter') {
                        //     handleButtonClick('okModalButton');
                        //   }
                        // }}
                      >
                        Recuperar
                      </button>
                    </div>
                    <div className="col-3">
                      <button
                        id="cancelModalButton"
                        style={{ width: '150px', fontSize: '12px' }}
                        className="btn btn-info btn-sm"
                        onClick={(e) => {
                          if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) {
                            if (selectedValue.element === 'Net Map') {
                              removeNetMapBk(selectedValue.data).then(function (response) {
                                if (response === true) {
                                  filtrar();
                                }
                              });
                            } else if (selectedValue.element === 'Topaz Map') {
                              removeTopazMapBk(selectedValue.data).then(function (response) {
                                if (response === true) {
                                  filtrar();
                                }
                              });
                            } else if (selectedValue.element === 'Printers') {
                              removeTopazPrtBk(selectedValue.data).then(function (response) {
                                if (response === true) {
                                  filtrar();
                                }
                              });
                            }
                          }
                        }}
                      >
                        Eliminar
                      </button>
                    </div>

                    <div className="col-3">
                      <button
                        id="cancelModalButton"
                        style={{ width: '150px', fontSize: '12px' }}
                        className="btn btn-info btn-sm"
                        onClick={(e) => {
                          if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) {
                            recoverData.map((option, index) => {
                              if (option.element === 'Printers') {
                                removeTopazPrtBk(option.data).then(function (response) {
                                  if (response === true) {
                                    filtrar();
                                  }
                                });
                              } else if (option.element === 'Topaz Map') {
                                removeTopazMapBk(option.data).then(function (response) {
                                  if (response === true) {
                                    filtrar();
                                  }
                                });
                              } else if (option.element === 'Net Map') {
                                removeNetMapBk(option.data).then(function (response) {
                                  if (response === true) {
                                    filtrar();
                                  }
                                });
                              }
                            });
                          }
                        }}
                        // onKeyDown={(e) => {
                        //   if (e.key === 'Enter') {
                        //     handleButtonClick('cancelModalButton');
                        //   }
                        // }}
                      >
                        Eliminar todos
                      </button>
                    </div>
                    <div className="col-3">
                      <button
                        id="cancelModalButton"
                        style={{ width: '150px', fontSize: '12px' }}
                        className="btn btn-info btn-sm"
                        onClick={(e) => {
                          if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) {
                            // props.loadData();

                            accionesModal('cerrar');
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            accionesModal('cerrar');
                          }
                        }}
                      >
                        Cerrar
                      </button>
                    </div>

                    {/* <Button id="okModalButton" autoFocus={true} handleClick={() => handleButtonClick('okModalButton')} buttonText="Aceptar" /> */}
                    {/* <Button id="cancelModalButton" handleClick={() => handleButtonClick('cancelModalButton')} buttonText="Cancelar" /> */}
                  </div>
                )}
                {/* {props.buttons &&
                  props.buttons.length > 0 &&
                  props.buttons.map((b) => (
                    <Button
                      id={b.buttonId}
                      key={b.buttonId}
                      autoFocus={b.autoFocus}
                      handleClick={(id) => handleButtonClick(id)}
                      buttonText={b.text.toUpperCase()}
                    />
                  ))} */}
              </div>
            </div>
          </Draggable>
        </div>
      </div>
    </FocusLock>
  );
};

export default ModalMessageRecover;
