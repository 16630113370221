/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/components/SchemaView.scss';
import Help from '../../resources/icons/fa/help.svg';
import Setting from '../../resources/icons/fa/setting.svg';
import Delete from '../../resources/icons/fa/delete.svg';
import { BasePopupWindow } from './HelpWindows/BasePopupWindow.js';
import { HelpCurrencyComponent } from './HelpWindows/HelpCurrencyComponent';
import { HelpRubroSalidaComponent } from './HelpWindows/HelpRubroSalidaComponent';
import { HelpCampoComponent } from './HelpWindows/HelpCampoComponent';
import {
  updateEsquema,
  addEsquema,
  getDescripcionesTable,
  getRecordsByConcept,
  getDescripcionesCampo,
  getRecordsByConceptFilter,
} from '../../api/schema.js';
import ModalMessage from '../../components/ModalMessage';
import { getNetMapDescriptionsPorSucursal } from '../../api/bitacora';

export const EditSchemaWindow = (props) => {
  const intl = useIntl();
  const [node, setNode] = useState(props.node);
  const [helpWindowOpen, setHelpWindowOpen] = useState('');
  const [forceSelectHelp, setForceSelectHelp] = useState(false);
  const [modalData, setModalData] = useState({});
  const [moneda, setMoneda] = useState('');
  const [campo, setCampo] = useState('');
  const [rubroError, setRubroError] = useState(node.descripcion);
  const [rubroMenaje, setRubroMensaje] = useState('');
  const [campoError, setCampoError] = useState('');
  const [monedaError, setMonedaError] = useState('');
  const [titleHelp, setTitleHelp] = useState('');
  const [colorRubro, setColorRubro] = useState('');
  const [colorCampo, setColorCampo] = useState('');
  const [colorMoneda, setColorMoneda] = useState('');
  const [esError, setEsError] = useState(true);
  const helpWindowStructure = {};
  const ID_HELP_CAMPO = 'Campo';
  const ID_HELP_RUBRO_SALIDA = 'RubroSalida';
  const ID_HELP_CURRENCY = 'Currency';

  //* **Variables con mensaje de error***/
  // let rubroDeSalidaNoEsValido = intl.formatMessage({
  //   id: 'rubroIngresadoNoValido',
  //   defaultMessage: 'El Rubro ingresado no es válido',
  // });

  let campoNoEsValido = intl.formatMessage({
    id: 'campoIngresadoNoValido',
    defaultMessage: 'El Campo ingresado no es válido',
  });

  let monedaNoValida = intl.formatMessage({
    id: 'monedaIngresadaNoValida',
    defaultMessage: 'La Moneda ingresada no es válida',
  });

  let rubroDeSalidaValorDelCampoNoEsValido = intl.formatMessage({
    id: 'valorCampoRubroSalidaNoValido',
    defaultMessage: 'El valor del campo "Rubro Salida" no es válido',
  });

  let rubroDeSalidaNoPuedeSerVacio = intl.formatMessage({
    id: 'valorCampoRubroSalidaNoVacio',
    defaultMessage: 'El valor "Rubro de Salida" no puede ser vacío',
  });

  let monedaNoPuedeSerVacio = intl.formatMessage({
    id: 'valorMonedaNoVacia',
    defaultMessage: 'El valor "Moneda" no puede ser vacío',
  });

  let campoNoPuedeSerVacio = intl.formatMessage({
    id: 'valorCampoNoVacio',
    defaultMessage: 'El valor "Campo" no puede ser vacío',
  });

  useEffect(() => {
    setNode(props.node);
  }, [props.node]);

  //* *****/
  useEffect(() => {
    if (node.moneda !== 0) {
      setEsError(false);
    }
    getDescripcionesCampo().then(function (response) {
      let esta = false;
      for (let i = 0; i < response.length; i++) {
        if (response[i][0] === node.campo) {
          setCampoError('');
          setCampo(response[i][1]);
          esta = true;
        }
      }
      if (!esta) {
        setCampo('');
      }
    });
    if (esError === false) {
      getRecordsByConcept(4).then(function (response) {
        let esta = false;
        for (let i = 0; i < response.length; i++) {
          if (response[i][0].value !== undefined) {
            if (response[i][0].value === node.rubro) {
              setRubroError('');
              setRubroMensaje(response[i][2].value);
              esta = true;
            }
          }
        }
        if (!esta) {
          setRubroMensaje('');
        }
      });
    }
    getRecordsByConcept(3).then(function (response) {
      let esta = false;
      for (let i = 0; i < response.length; i++) {
        if (response[i][0].value === node.moneda) {
          setMonedaError('');
          setMoneda(response[i][1].value);
          esta = true;
        }
      }
      if (!esta) {
        setMoneda('');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  const handleKeyDown = (e) => {
    if (['-', '+', 'e', '.'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const validoRubro = (rubro) => {
    let esta = false;
    if (rubro !== '') {
      getRecordsByConcept(4).then(function (response) {
        for (let i = 0; i < response.length; i++) {
          if (response[i][0].value !== undefined) {
            if (response[i][0].value === parseInt(rubro)) {
              setRubroMensaje(response[i][2].value);
              setColorRubro('grey');
              esta = true;
            }
          }
        }
        if (!esta) {
          setRubroMensaje(rubroDeSalidaValorDelCampoNoEsValido);
          setColorRubro('red');
        }
      });
    } else {
      setRubroMensaje('');
    }
  };

  const validoCampo = (campo) => {
    let esta = false;
    let encontro = '';
    getDescripcionesCampo().then(function (response) {
      for (let i = 0; i < response.length; i++) {
        if (response[i][0] === parseInt(campo)) {
          esta = true;
          setCampoError('');
          encontro = response[i][1];
        }
      }
      if (!esta) {
        setCampoError(campoNoEsValido);
        setColorCampo('red');
      }
    });
  };

  const validoMoneda = (moneda) => {
    let esta = false;
    let encontro = '';
    getRecordsByConcept(3).then(function (response) {
      for (let i = 0; i < response.length; i++) {
        if (response[i][0].value === parseInt(moneda)) {
          encontro = response[i][1].value;
          esta = true;
        }
      }
      if (!esta) {
        setMonedaError(monedaNoValida);
        setColorMoneda('red');
      }
    });
  };

  let mensajeDeErrorRubro = (mensaje) => {
    setRubroError(mensaje);
    setColorRubro('red');
  };

  let toggleWindow = (window) => {
    setHelpWindowOpen(window);
  };

  const setHelpValue = (value) => {
    if (value) {
      if (helpWindowOpen === ID_HELP_CURRENCY) {
        setNode({ ...node, moneda: value[0].value });
        setMoneda(value[1].value);
      } else if (helpWindowOpen === ID_HELP_CAMPO) {
        setNode({ ...node, campo: value[0] });
        setCampo(value[1]);
      } else if (helpWindowOpen === ID_HELP_RUBRO_SALIDA) {
        getRecordsByConceptFilter(4, value[0].value, 2, 6340)
          .then(function (response) {
            if (response === '') {
              setEsError(true);
              mensajeDeErrorRubro(rubroDeSalidaValorDelCampoNoEsValido);
              setNode({ ...node, rubro: value[0].value, descripcion: rubroDeSalidaValorDelCampoNoEsValido });
            } else {
              setEsError(false);
              setNode({ ...node, rubro: value[0].value, descripcion: value[2].value });
            }
          })
          .catch(function (e) {});
      }
    }
    setForceSelectHelp(false);
    toggleWindow('');
  };

  const setForceSelect = (value) => {
    setForceSelectHelp(value);
  };

  helpWindowStructure[ID_HELP_CURRENCY] = (
    <HelpCurrencyComponent currentValue={node.moneda} setHelpValue={setHelpValue} forceSelectHelp={forceSelectHelp} />
  );
  helpWindowStructure[ID_HELP_CAMPO] = <HelpCampoComponent currentValue={node.campo} setHelpValue={setHelpValue} forceSelectHelp={forceSelectHelp} />;
  helpWindowStructure[ID_HELP_RUBRO_SALIDA] = (
    <HelpRubroSalidaComponent currentValue={node.rubroSalida} setHelpValue={setHelpValue} forceSelectHelp={forceSelectHelp} />
  );

  let helpWindow = null;
  if (helpWindowOpen) {
    helpWindow = (
      <BasePopupWindow
        helpWindowOpen={helpWindowStructure[helpWindowOpen]}
        toggleWindow={toggleWindow}
        setForceSelect={setForceSelect}
        title={titleHelp}
      ></BasePopupWindow>
    );
  }

  const updateEsquemaContable = () => {
    if (rubroError !== rubroDeSalidaValorDelCampoNoEsValido) {
      if (campoError !== campoNoEsValido) {
        if (monedaError !== monedaNoValida) {
          let nuevoNodo = {
            proceso: node.proceso,
            rubro: node.rubro,
            moneda: node.moneda,
            linea: node.linea,
            ordinal: node.ordinal,
            descripcion: node.descripcion,
            campo: node.campo,
            rubrosalida: node.rubroSalida,
            cd: node.cajaODiario,
            d_dc: node.debitoGeneraCreditoODebito,
            c_dc: node.creditoGeneraCreditoODebito,
            transaccion: node.transaccion,
            condicion: node.condicion,
            usar_campos_movimientos: node.usarCamposMovimientos,
          };

          updateEsquema(nuevoNodo).then(function (response) {
            if (response === true) {
              props.updateNodo();
              setModalData({
                showDialog: true,
                textDialog: intl.formatMessage({
                  id: 'updatedOk',
                  defaultMessage: 'Se ha actualizado correctamente',
                }),
              });
            } else {
              setModalData({
                showDialog: true,
                textDialog: intl.formatMessage({
                  id: 'updateSchemaErr',
                  defaultMessage: 'Ocurrio un error al actualizar el esquema',
                }),
              });
            }
          });
        } else {
          setModalData({ showDialog: true, textDialog: monedaNoValida });
        }
      } else {
        setModalData({ showDialog: true, textDialog: campoNoEsValido });
      }
    } else {
      setModalData({ showDialog: true, textDialog: rubroDeSalidaValorDelCampoNoEsValido });
    }
  };

  const postEsquemaContable = () => {
    if (rubroError !== rubroDeSalidaValorDelCampoNoEsValido) {
      if (node.rubro === undefined) {
        setModalData({ showDialog: true, textDialog: rubroDeSalidaNoPuedeSerVacio });
        throw new Error(rubroDeSalidaNoPuedeSerVacio);
      } else if (node.moneda === undefined || node.moneda === 0) {
        setModalData({ showDialog: true, textDialog: monedaNoPuedeSerVacio });
        throw new Error(monedaNoPuedeSerVacio);
      } else if (node.campo === undefined) {
        setModalData({ showDialog: true, textDialog: campoNoPuedeSerVacio });
        throw new Error(campoNoPuedeSerVacio);
      }

      if (rubroError !== rubroDeSalidaValorDelCampoNoEsValido) {
        if (campoError !== campoNoEsValido) {
          if (monedaError !== monedaNoValida) {
            let nuevoNodo = {
              proceso: 0,
              rubro: node.rubro,
              moneda: node.moneda,
              linea: 0,
              ordinal: 1,
              descripcion: node.descripcion,
              campo: node.campo,
              rubrosalida: node.rubro,
              cd: null,
              d_dc: 'D',
              c_dc: 'C',
              transaccion: 0,
              condicion: null,
              usar_campos_movimientos: null,
            };
            addEsquema(nuevoNodo).then(function (response) {
              if (response === true) {
                setModalData({
                  showDialog: true,
                  textDialog: intl.formatMessage({
                    id: 'addedOk',
                    defaultMessage: 'Se ha agregado correctamente',
                  }),
                });
                props.loadData();
                limpiar();
              } else {
                setModalData({
                  showDialog: true,
                  textDialog: intl.formatMessage({
                    id: 'schemaAlreadyExists',
                    defaultMessage: 'El Esquema específicado ya existe',
                  }),
                });
              }
            });
          } else {
            setModalData({ showDialog: true, textDialog: monedaNoValida });
          }
        } else {
          setModalData({ showDialog: true, textDialog: campoNoEsValido });
        }
      } else {
        setModalData({ showDialog: true, textDialog: rubroDeSalidaValorDelCampoNoEsValido });
      }
    } else {
      setModalData({ showDialog: true, textDialog: rubroDeSalidaValorDelCampoNoEsValido });
    }
  };

  const limpiar = () => {
    let vacioNodos = {
      proceso: null,
      rubro: '',
      moneda: 0,
      linea: null,
      ordinal: 1,
      descripcion: '',
      campo: '',
      rubrosalida: '',
      cajaODiario: null,
      debitoGeneraCreditoODebito: 'D',
      creditoGeneraCreditoODebito: 'C',
      transaccion: null,
      condicion: null,
      usar_campos_movimientos: null,
    };
    setNode(vacioNodos);
    setCampo('');
    setRubroMensaje('');
    setMoneda('');
  };

  const bloqueoBotonSiNodeEsIgualANodeEliminado = () => {
    let bloqueo = false;
    if (props.nodesAEliminar.length !== 0) {
      let eliminados = props.nodesAEliminar;
      for (let i = 0; i < eliminados.length; i++) {
        if (eliminados[i].key === node.key) {
          bloqueo = true;
          break;
        }
      }
    }
    return bloqueo;
  };

  return (
    <div id="divGeneralSchema">
      {helpWindow}
      {props.showWindow === 'EditarS' ? (
        <h6 style={{ fontSize: '20px' }}>
          {intl.formatMessage({
            id: 'schema',
            defaultMessage: 'Esquema',
          })}
        </h6>
      ) : props.showWindow === 'NuevoEsquema' ? (
        <h6 style={{ fontSize: '20px' }}>
          {intl.formatMessage({
            id: 'newschema',
            defaultMessage: 'Nuevo esquema',
          })}
        </h6>
      ) : (
        ''
      )}
      <div className={'container'} style={{ marginTop: '10px' }}>
        <br />
        <div style={{ borderTop: '1px solid #E2E3E2' }} className="p-4">
          <h3 style={{ width: '100px', fontSize: '12px', marginTop: '-23px', backgroundColor: 'white' }}>
            {intl.formatMessage({
              id: 'itemType',
              defaultMessage: 'Tipo de rubro',
            })}
          </h3>
          <div className="row" style={{ marginTop: '20px', display: 'flex' }}>
            <div style={{ width: '20%' }}>
              <label htmlFor="Rubro">
                {intl.formatMessage({
                  id: 'Rubro',
                  defaultMessage: 'Rubro',
                })}
              </label>
            </div>
            <div style={{ width: '35%' }}>
              <input
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyDown(e)}
                value={node.rubro}
                disabled={!props.postData && node.rubro}
                onChange={(e) => {
                  setNode({ ...node, rubro: e.target.value });
                  validoRubro(e.target.value);
                }}
                className={'clear-both border-solid rounded'}
                id="txtRubro"
                style={{ border: '1px solid #CED0CE', width: '80%' }}
              ></input>
              <button
                className="iconHelp ml-1"
                id="btnHelpCurrency"
                disabled={!props.postData && node.rubro}
                style={{ width: '25px', outline: 'none' }}
                onClick={() => {
                  setTitleHelp(
                    intl.formatMessage({
                      id: 'Rubro',
                      defaultMessage: 'Rubro',
                    }),
                  );
                  toggleWindow(ID_HELP_RUBRO_SALIDA);
                }}
              >
                <Help />
              </button>
            </div>
            <div style={{ width: '45%', textOverflow: 'ellipsis', overflowY: 'hidden', overflowX: 'hidden' }}>
              <label style={{ color: colorRubro }}>{rubroMenaje}</label>
            </div>
          </div>
        </div>
        <div style={{ borderTop: '1px solid #E2E3E2' }} className="mt-2 p-4">
          <h3 style={{ width: '47px', fontSize: '12px', marginTop: '-23px', backgroundColor: 'white' }}>
            {intl.formatMessage({
              id: 'Moneda',
              defaultMessage: 'Moneda',
            })}
          </h3>
          <div className="row mt-3">
            <div className="col-3">
              <input
                type="radio"
                id="rbTodas"
                disabled={props.llevoData}
                checked={node.moneda === -1}
                onChange={(e) => setNode({ ...node, moneda: -1 })}
                className="mr-2"
              ></input>
              <label htmlFor="Todas">
                {intl.formatMessage({
                  id: 'all',
                  defaultMessage: 'Todas',
                })}
              </label>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-3">
              <input
                type="radio"
                id="rbExtranjera"
                disabled={props.llevoData}
                checked={node.moneda === -2}
                className="mr-2"
                onChange={(e) => setNode({ ...node, moneda: -2 })}
              ></input>
              <label htmlFor="Extranjera">
                {intl.formatMessage({
                  id: 'foreign',
                  defaultMessage: 'Extranjera',
                })}
              </label>
            </div>
          </div>
          <div className="row mt-2" style={{ display: 'flex' }}>
            <div style={{ width: '20%' }}>
              <input
                type="radio"
                // disabled={node.moneda}
                id="rbMoneda"
                checked={node.moneda !== -1 && node.moneda !== -2}
                disabled={props.llevoData}
                className="mr-2"
                // onChange={(e) => (!props.postData ? setNode({ ...node, moneda: -2 }) : null)}
                onChange={(e) => setNode({ ...node, moneda: e.target.value })}
              ></input>
              <label htmlFor="Moneda">
                {intl.formatMessage({
                  id: 'Moneda',
                  defaultMessage: 'Moneda',
                })}
              </label>
            </div>
            <div style={{ width: '35%' }}>
              <input
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyDown(e)}
                value={node.moneda !== -1 && node.moneda !== -2 ? (node.moneda === 0 ? '' : node.moneda) : ''}
                // disabled={!props.postData || node.moneda === -2 || node.moneda === -1}
                disabled={true}
                onChange={(e) => setNode({ ...node, moneda: e.target.value })}
                onBlur={(e) => validoMoneda(e.target.value)}
                className={'clear-both border-solid rounded'}
                style={{ border: '1px solid #CED0CE', width: '80%' }}
              ></input>
              <button
                className="iconHelp ml-1"
                style={{ width: '25px', outline: 'none' }}
                disabled={!props.postData || node.moneda === -2 || node.moneda === -1}
                onClick={() => {
                  setTitleHelp(
                    intl.formatMessage({
                      id: 'Moneda',
                      defaultMessage: 'Moneda',
                    }),
                  );
                  toggleWindow(ID_HELP_CURRENCY);
                }}
              >
                <Help />
              </button>
            </div>
            <div style={{ width: '45%', textOverflow: 'ellipsis', overflowY: 'hidden', overflowX: 'hidden', color: 'darkgray' }}>
              {moneda}
              <label style={{ color: colorMoneda }}>{monedaError}</label>
            </div>
          </div>
        </div>
        <div className="mt-2 p-4" style={{ borderTop: '1px solid #E2E3E2' }}>
          <div className="row mt-2" style={{ display: 'flex' }}>
            <div style={{ width: '20%' }}>
              <label style={{}} htmlFor="Descripcion">
                {intl.formatMessage({
                  id: 'description',
                  defaultMessage: 'Descripcion',
                })}
              </label>
            </div>
            <div style={{ width: '80%' }}>
              <input
                maxLength={50}
                value={node.descripcion}
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                onChange={(e) => setNode({ ...node, descripcion: e.target.value })}
                className={'clear-both border-solid rounded'}
                id="Descripcion"
                style={{ border: '1px solid #CED0CE', width: '300px' }}
              ></input>
            </div>
          </div>
        </div>
        <div className="pl-4 pr-4">
          <div className="row" style={{ display: 'flex' }}>
            <div style={{ width: '20%' }}>
              <label style={{}} htmlFor="Campo">
                {intl.formatMessage({
                  id: 'field',
                  defaultMessage: 'Campo',
                })}
              </label>
            </div>
            <div style={{ width: '35%' }}>
              <input
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyDown(e)}
                value={node.campo}
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                onChange={(e) => setNode({ ...node, campo: e.target.value })}
                onBlur={(e) => validoCampo(e.target.value)}
                className={'clear-both border-solid  rounded'}
                id="Campo"
                style={{ border: '1px solid #CED0CE', width: '80%' }}
              ></input>
              <button
                className="iconHelp ml-1"
                style={{ width: '25px', outline: 'none' }}
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                onClick={() => {
                  setTitleHelp(
                    intl.formatMessage({
                      id: 'field',
                      defaultMessage: 'Campo',
                    }),
                  );
                  toggleWindow(ID_HELP_CAMPO);
                }}
              >
                <Help />
              </button>
            </div>
            <div style={{ width: '45%', textOverflow: 'ellipsis', overflowY: 'hidden', overflowX: 'hidden', color: 'darkgray' }}>
              {campo}
              <label style={{ color: colorCampo }}>{campoError}</label>
            </div>
          </div>
        </div>
        <div className="modal__footer mt-4">
          <div className="row ml-0 mr-0 p-1 d-flex justify-content-between" style={{ marginTop: '50px', float: 'right' }}>
            <div></div>
            <div>
              {/* <button type="button" className="btn btn-outline-secondary btn-sm btnDesign">
                Cancelar
              </button>
              &nbsp; */}
              {props.postData === true ? (
                <button type="submit" className="btnDesign" onClick={postEsquemaContable}>
                  {intl.formatMessage({
                    id: 'Tablas.movimientos_alta',
                    defaultMessage: 'Alta',
                  })}
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={bloqueoBotonSiNodeEsIgualANodeEliminado()}
                  className="btn btn-outline-info btn-sm btnDesign"
                  onClick={updateEsquemaContable}
                >
                  {intl.formatMessage({
                    id: 'modify',
                    defaultMessage: 'Modificar',
                  })}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {modalData.showDialog && (
        <ModalMessage
          message={modalData.textDialog}
          handleAccept={async () => {
            setModalData({ showDialog: false });
          }}
          acceptTitle={intl.formatMessage({
            id: 'accept',
            defaultMessage: 'Aceptar',
          })}
          draggable={true}
        />
      )}
    </div>
  );
};

export default EditSchemaWindow;
