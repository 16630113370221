/* eslint-disable no-undef */
const { services = {}, location } = window;
const { protocol, hostname, port, pathname } = location;
let language = navigator.language || navigator.userLanguage;
language = language.slice(0, 2); // e.g.: language 'es-uy' => 'es'

const PROTOCOL = services.API_PROTOCOL || protocol || 'https:';
const HOST = services.API_HOST || hostname || 'localhost';
const PORT = services.API_PORT || port || '';
export const PATHNAME =
  pathname.length === 1 ? pathname : pathname.indexOf('/home') > -1 ? pathname.substr(0, pathname.indexOf('/home') + 1) : `${pathname}/`;

export const LANGUAGE = services.LANG || language || 'es';
export const API = PORT ? `${PROTOCOL}//${HOST}:${PORT}/TopazWeb/` : `${PROTOCOL}//${HOST}/TopazWeb/`;
export const TOPAZ_REST_API = PORT ? `${PROTOCOL}//${HOST}:${PORT}/TopazRestApi/` : `${PROTOCOL}//${HOST}/TopazRestApi/`;
export const BASE_API = PORT ? `${PROTOCOL}//${HOST}:${PORT}` : `${PROTOCOL}//${HOST}`;
export const CLIENT_GUID = services.CLIENT_GUID || process.env.CLIENT_GUID || 'default-client-guid';
export const CLIENT_VERSION_INFO = 'Version: v2.5.41, Fecha: 18/08/2015 12:27:10 p.m.';

export const TOPWEB_VERSION = process.env.TOPWEB_VERSION || '';
