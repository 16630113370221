import React from 'react';
import addIcon from './add';
import upArrowIcon from './upArrow';

const addUpArrowIcon = (
  <div>
    {addIcon}
    {upArrowIcon}
  </div>
);

export default addUpArrowIcon;
