import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useTheme } from '../../themes/ThemeContext';
import MenuIcon from './../../resources/icons/fa/bars.svg';
import { useIntl } from 'react-intl';

const MenuButton = ({ onClick, isCollapsed }) => {
  const intl = useIntl();
  const themeState = useTheme();
  const { option, background } = themeState.theme;
  const hoverColor = option.hover;
  const defaultColor = option.option;

  const [iconColor, setIconColor] = useState(defaultColor);

  const handleHover = () => {
    setIconColor(hoverColor);
  };
  const handleBlur = () => {
    setIconColor(defaultColor);
  };

  const styles = StyleSheet.create({
    menuButtonContainer: {
      paddingLeft: '.25rem',
    },
    menuButton: {
      transition: '.1s ease-out',
      width: '3rem',
      minHeight: '2.5rem',
      maxHeight: '3rem',
      margin: '.5rem .25rem .25rem .25rem',
      padding: '.75rem',
      fontSize: '1.125rem',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: option.option,
      borderRadius: '100%',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: background.dark,
        color: option.hover,
      },
      ':active': {
        backgroundColor: background.dark2,
        color: option.active,
      },
      ':focus': {
        outline: 'none',
      },
    },
  });

  const collapseSidebar = intl.formatMessage({
    id: 'collapseSidebar',
    defaultMessage: 'Colapsar barra lateral',
  });

  const expandSidebar = intl.formatMessage({
    id: 'expandSidebar',
    defaultMessage: 'Expandir barra lateral',
  });

  return (
    <div className={css(styles.menuButtonContainer)} title={isCollapsed ? expandSidebar : collapseSidebar}>
      <div
        onMouseEnter={handleHover}
        onFocus={handleHover}
        onBlur={handleBlur}
        onMouseLeave={handleBlur}
        className={css(styles.menuButton)}
        onClick={onClick}
      >
        <MenuIcon height="18px" fill={iconColor} />
      </div>
    </div>
  );
};

export default MenuButton;
