import React, { useState, useEffect, useReducer } from 'react';
import withSession from '../../api/withSession';
import ChangePasswordView from './ChangePasswordView';
import { getUserName } from '../../helpers/login';
import { CLIENT_GUID, CLIENT_VERSION_INFO } from '../../config';
import { useIntl } from 'react-intl';

/**
 * Update an input value from the Inputs state
 * @param {object} state Inputs state
 * @param {object} action { type: 'theInputToUpdate', payload: 'theUpdatedValue' }
 */
const inputValueReducer = (state, action) => ({
  ...state,
  [action.type]: {
    ...state[action.type],
    value: action.payload,
  },
});

const ChangePasswordViewModel = (props) => {
  const {
    loginInputs,
    isFirstPassword,
    initSession,
    changePassword,
    onPasswordChange,
    onClose,
  } = props;
  const intl = useIntl();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const initialInputsState = {
    currentPassword: {
      value: isFirstPassword ? '' : loginInputs.password || '',
      required: !isFirstPassword,
      disabled: isFirstPassword || !!loginInputs.password,
      autoFocus: !isFirstPassword && !loginInputs.password,
    },
    newPassword: {
      value: isFirstPassword ? loginInputs.password || '' : '',
      required: true,
      disabled: isFirstPassword && !!loginInputs.password,
      autoFocus: isFirstPassword && !isFirstPassword,
    },
    confirmPassword: {
      value: '',
      required: true,
      disabled: false,
      autoFocus: false,
    },
  };
  const [inputs, updateInputValue] = useReducer(
    inputValueReducer,
    initialInputsState,
  );

  /**
   * If the request for change of password comes from the login,
   * the username comes through props.
   * Otherwise, the username can be obtained from the browser storage
   */
  const username = isFirstPassword ? props.loginInputs.username : getUserName();

  /**
   * Delete error when inputs changes
   */
  useEffect(() => setError(''), [inputs]);

  const handleInputChange = (event) => {
    event.persist();
    updateInputValue({ type: event.target.name, payload: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const currentPassword = inputs.currentPassword.value;
    const newPassword = inputs.newPassword.value;
    const confirmPassword = inputs.confirmPassword.value;

    setLoading(true);

    try {
      validatePasswords(newPassword, confirmPassword);
      await initSession(CLIENT_GUID, CLIENT_VERSION_INFO);
      const result = await changePassword(username, currentPassword, newPassword);
      if (result === '1') {
        onPasswordChange(username, newPassword);
      }
    } catch (err) {
      setError(err.message || err);
    } finally {
      setLoading(false);
    }
  };

  const validatePasswords = (newPassword, confirmPassword) => {
    if (newPassword !== confirmPassword) {
      const confirmPasswordError = intl.formatMessage({
        id: 'confirmPasswordError',
        defaultMessage: 'La nueva contraseña y su confirmación no coinciden',
      });
      throw new Error(confirmPasswordError);
    }
  };

  return (
    <ChangePasswordView
      inputs={inputs}
      isFirstPassword={isFirstPassword}
      onInputChange={handleInputChange}
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      onCancel={onClose}
    />
  );
};

export default withSession(ChangePasswordViewModel);
