import { css, StyleSheet } from 'aphrodite';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { hasPermission } from './../../../../api/clearing';
import { getSessionInfo } from './../../../../api/session';
import { getBranchGroups } from './../../../../api/postingManager';
import { useGlobalStyles } from './../../../../styles/GlobalStylesContext';
import { useTheme } from './../../../../themes/ThemeContext';
import Field from './Field';

const BranchSelector = ({ onBranchChange, error, disabled, selectedBranch }) => {
  const intl = useIntl();
  const labelBranch = intl.formatMessage({
    id: 'branch',
    defaultMessage: 'Sucursal',
  });
  const allBranches = intl.formatMessage({
    id: 'allBranches',
    defaultMessage: 'Todas las sucursales',
  });
  const selectABranch = intl.formatMessage({
    id: 'pleaseSelect',
    defaultMessage: 'Seleccione...',
  });

  const initialOptions = [
    {
      value: '',
      text: selectABranch,
    },
    {
      value: -1,
      text: allBranches,
    },
  ];
  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    const fetchBranchsData = async () => {
      const data = await getBranchGroups();
      if (data && data.length > 0) {
        const branches = data.map((branch) => ({
          value: branch.sucursal,
          text: branch.descripcion,
        }));
        setOptions((initialOptions) => [...initialOptions, ...branches]);
      }
    };
    const getSession = async () => {
      hasPermission().then((data) => {
        getSessionInfo().then((dataSession) => {
          let userBranch = dataSession.topazBranch.toString();
          if (data === false) {
            let element = document.getElementById('branchSelect');
            if (disabled) {
              element.value = userBranch;
            }
          }
        });
      });
    };

    fetchBranchsData();
    getSession();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const themeState = useTheme();
  const { error: errorColor } = themeState.theme;

  const styles = StyleSheet.create({
    select: {
      padding: '.2rem',
    },
    error: {
      borderColor: errorColor.error,
      borderWidth: 1,
    },
  });

  const globalStyles = useGlobalStyles();

  return (
    <Field labelText={labelBranch} htmlFor="branchSelect" error={error}>
      <select
        id="branchSelect"
        name="branchSelect"
        required
        disabled={disabled}
        className={css([globalStyles.input, styles.select, error && styles.error])}
        onChange={(event) => onBranchChange(event.target.value)}
      >
        {options &&
          Array.isArray(options) &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.value && option.value !== -1 && `${option.value} - `}
              {option.text}
            </option>
          ))}
      </select>
    </Field>
  );
};
export default BranchSelector;
