/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { connect } from 'unistore/react';
import 'react-table/react-table.css';
import DataList from './DataList';
import ModalMessageRecover from '../ModalMessageRecover.js';
import ModalMessageSearch from '../ModalMessageSearch.js';
import RecoverIcon from './../../resources/icons/fa/recover.svg';
import ModalMessage from '../ModalMessage';
import SearchIcon from './../../resources/icons/fa/search.svg';
import { PulseLoader } from 'halogenium';

// import WorkflowTaskList from './WorkflowTaskList';

import {
  removeNetMap,
  removeNumeratorDefinition,
  removeTopazMap,
  removeTopazPrt,
  validaUsuariosMaquinaSucursal,
  getTopazMapsBK,
  getTopazPrtsBK,
  getNetMapsBK,
  removeTopazMapBk,
  removeTopazPrtBk,
  removeNetMapBk,
  addTopazMap,
  addNetMap,
  addTopazPrt,
} from '../../api/productionManager';
import { useIntl } from 'react-intl';

export const CATEGORY_MAPATOPAZ = 'Mapa Topaz';
export const CATEGORY_IMPRESORAS = 'Impresoras';
export const CATEGORY_MAPARED = 'Mapa de Red';
export const CATEGORY_NUMERADORES = 'Numeradores';
export const CATEGORY_PARAMETROS = 'Parametros';
// eslint-disable-next-line camelcase
export const CATEGORY_MAPATOPAZz = '0-1';
// eslint-disable-next-line camelcase
export const CATEGORY_IMPRESORASs = '0-3';
// eslint-disable-next-line camelcase
export const CATEGORY_MAPAREDd = '0-2';
// export const CATEGORY_NUMERADORESs = 'Numeradores';
// export const CATEGORY_PARAMETROSs = 'Parametros';

export const ProductionManagerDataList = (props) => {
  const { data, inScreen, loadData, selected, setShowWindow, sortFunctions } = props;
  const [rows, setRows] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedIndexA, setSelectedIndexA] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [dataRecover, setDataRecover] = useState([]);
  const [showModal4, setShowModal4] = useState(false);
  const [recoverData, setRecoverData] = useState([]);

  const [textDialog, setTextDialog] = useState('');

  const refListEntry = useRef();
  const refDataList = useRef();
  const intl = useIntl();

  const loading = false;

  useLayoutEffect(() => {
    let receivedData = data;
    setCurrentData(receivedData);
    const crows = createRows(receivedData);
    setRows(crows);
    if (inScreen === false && selectedItem !== null) {
      setSelectedItem(null);
      setSelectedIndex(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inScreen]);

  const deleteItem = async (category, selectedItem) => {
    setShowModal(false);
    if (category === CATEGORY_MAPATOPAZ) {
      let ret = await removeTopazMap(selectedItem);
      if (ret === false) {
        let message = intl.formatMessage({
          id: 'bajaentidad',
          defaultMessage: 'Error al intentar dar de baja sobre',
        });
        message += 'TopazMap';
        setModal2(true, message, false);
      } else {
        data.splice(selectedIndex, 1);
        setSelectedIndexA(selectedIndex);
        setSelectedIndex(null);
        loadData(true);
      }
    } else if (category === CATEGORY_IMPRESORAS) {
      let ret = await removeTopazPrt(selectedItem);
      if (ret === false) {
        let message = intl.formatMessage({
          id: 'bajaentidad',
          defaultMessage: 'Error al intentar dar de baja sobre',
        });
        message += 'TopazPrt';
        setModal2(true, message, false);
      } else {
        data.splice(selectedIndex, 1);
        setSelectedIndexA(selectedIndex);
        setSelectedIndex(null);
        loadData(true);
      }
    } else if (category === CATEGORY_MAPARED) {
      let ret = await removeNetMap(selectedItem);
      if (ret === false) {
        let message = intl.formatMessage({
          id: 'bajaentidad',
          defaultMessage: 'Error al intentar dar de baja sobre',
        });
        message += 'NetMap';
        setModal2(true, message, false);
      } else {
        data.splice(selectedIndex, 1);
        setSelectedIndexA(selectedIndex);
        setSelectedIndex(null);
        loadData(true);
      }
    } else if (category === CATEGORY_NUMERADORES) {
      let ret = await removeNumeratorDefinition(selectedItem);
      if (ret === false) {
        let message = intl.formatMessage({
          id: 'bajaentidad',
          defaultMessage: 'Error al intentar dar de baja sobre',
        });
        message += 'Numeradores';
        setModal2(true, message, false);
      } else {
        data.splice(selectedIndex, 1);
        setSelectedIndexA(selectedIndex);
        setSelectedIndex(null);
        loadData(true);
      }
    }
  };

  const addItem = (selectedIndex, obj, category) => {
    data.splice(selectedIndex, 0, obj);
    setSelectedIndexA(selectedIndex);
    setSelectedIndex(null);

    switch (category) {
      case CATEGORY_MAPATOPAZ:
        data.sort(sortFunctions.topazMapSort);
        break;
      case CATEGORY_MAPARED:
        data.sort(sortFunctions.netMapSort);
        break;
      case CATEGORY_IMPRESORAS:
        data.sort(sortFunctions.printerSort);
        break;
      default:
        break;
    }
  };

  const validoNetMap = async (selectedItem) => {
    const { dataPrinter, dataTopazMap } = props;
    let message = intl.formatMessage({
      id: 'existeentablas',
      defaultMessage: 'No es posible borrar el Mapa de Red seleccionado\nporque esta siendo usando en las siguientes tablas:',
    });

    let rowExisteEnImpresoras = dataPrinter.filter((reg) => {
      return reg.nromaq === parseInt(selectedItem.numerodemaquina) && reg.nrosucursal === parseInt(selectedItem.nrosucursal);
    });

    let rowExisteEnTopazMap = dataTopazMap.filter((reg) => {
      return reg.nromaq === parseInt(selectedItem.numerodemaquina) && reg.nrosucursal === parseInt(selectedItem.nrosucursal);
    });

    let showMessage = false;

    let valida = await validaUsuariosMaquinaSucursal(selectedItem.numerodemaquina, selectedItem.nrosucursal);

    if (rowExisteEnTopazMap.length > 0) {
      message += '\n-TopazMap.';
      showMessage = true;
    }

    if (rowExisteEnImpresoras.length > 0) {
      message += '\n-TopazPrt.';
      showMessage = true;
    }

    if (valida) {
      message += '\n-Usuarios.';
      showMessage = true;
    }

    if (showMessage) {
      setModal2(true, message, false);
      return;
    }
    const deleteMessage = intl.formatMessage({
      id: 'deletemapared',
      defaultMessage: 'Está seguro que desea eliminar el elemento Mapa de Red?',
    });
    setModal(true, deleteMessage);
  };

  const showModalItem = (category, selectedItem) => {
    if (category === CATEGORY_MAPATOPAZ) {
      const deleteMessage = intl.formatMessage({
        id: 'deletemapatopaz',
        defaultMessage: 'Está seguro que desea eliminar el elemento Mapa Topaz?',
      });
      setModal(true, deleteMessage);
    } else if (category === CATEGORY_IMPRESORAS) {
      const deleteMessage = intl.formatMessage({
        id: 'deleteimpresoras',
        defaultMessage: 'Está seguro que desea eliminar el elemento Impresora?',
      });
      setModal(true, deleteMessage);
    } else if (category === CATEGORY_MAPARED) {
      validoNetMap(selectedItem);
    } else if (category === CATEGORY_NUMERADORES) {
      const deleteMessage = intl.formatMessage({
        id: 'deletenumerador',
        defaultMessage: 'Está seguro que desea eliminar el elemento Numerador?',
      });
      setModal(true, deleteMessage);
    }
  };

  const setModal = (state, text) => {
    setShowModal(state);
    setTextDialog(text);
  };

  const setModal2 = (state, text) => {
    setShowModal2(state);
    setTextDialog(text);
  };

  const columnOrders = (type) => {
    let orderToReturn = [];
    if (type === CATEGORY_PARAMETROS) {
      orderToReturn = [
        'Nombre1',
        'Nombre2',
        'Fecha Proceso',
        'Fecha Proceso Anterior',
        'Fecha Próximo Proceso',
        'Días Válidos',
        'Reintentos',
        'Tipo Red',
        'Auto Login',
        'Siglo',
        'Corte Siglo',
        'Lenguaje Defecto',
        'Comunicaciones',
        'Ambiente',
        'Tiempo Corte',
        'Nro Sucursal',
        'MonNac',
        'Movimientos Caja',
        'Tipo Plazo',
        'Pwd Largo Min',
        'Pwd Cant Min Car',
        'Pwd Cant Min Num',
        'Pwd Cant Max Rep',
        'Pwd Car Max Rep',
        'Pwd Pos Max Rep',
        'Complemento Grupo',
        'Pwd Largo Max',
        'Pwd Cant Car Max Rep',
        'Clv Largo Max',
        'Clv Largo Min',
        'Pwd Case Sensitive',
        'Clv Case Sensitive',
        'Pwd Reglas Min',
        'Pwd Car Max Sec',
        'Días Aviso',
      ];
    } else if (type === CATEGORY_MAPATOPAZ) {
      orderToReturn = ['Nro Sucursal', 'Nro Máquina', 'Grupo Mapa', 'Path', 'Comentario'];
    } else if (type === 'Mapa de Red') {
      orderToReturn = [
        'Nro Sucursal',
        'Nro Máquina',
        'Descripción',
        'Inicio del Día',
        'Fin del Día',
        'Usuario',
        'Dirección COMM',
        'Nro de Caja',
        'Dirección IP',
        'Nombre de Envío',
      ];
    } else if (type === CATEGORY_IMPRESORAS) {
      orderToReturn = ['Nro Sucursal', 'Nro Máquina', 'Nombre Topaz', 'Comentario', 'Nombre Windows', 'Tipo Impresora', 'Puerto'];
    } else if (type === CATEGORY_NUMERADORES) {
      orderToReturn = ['Número', 'Descripción', 'IniVal', 'Incremento', 'Período', 'Última Inic', 'Reutilizable', 'Máximo', 'Centralizado'];
    }
    return orderToReturn;
  };

  const createRows = (list) => {
    let crows = [];
    if (list && list instanceof Array) {
      list.map((data) => {});
    }
    return crows;
  };

  const addDataListRef = (element) => {
    refDataList.current = element;
  };

  const addToRefListEntry = (r) => {
    if (r && refListEntry.current && !refListEntry.current.includes(r)) {
      refListEntry.current.push(r);
    }
  };

  const accionesModal = (element) => {
    if (element === 'cerrar') {
      setShowModal3(false);
    }
  };

  const openModalRecover = async () => {
    let rec = [];
    let recuperados = [];

    let topazMaps = await getTopazMapsBK();
    let topazPrts = await getTopazPrtsBK();
    let netMaps = await getNetMapsBK();
    rec = topazMaps.concat(topazPrts);
    recuperados = rec.concat(netMaps);

    setModal3(true, 'Recuperar', recuperados);
  };

  const setModal3 = (state, text, data) => {
    setShowModal3(state);
    setTextDialog(text);
    setDataRecover(data);
  };

  const openModalSearch = async () => {
    setModal4(true);
  };

  const setModal4 = (state) => {
    setShowModal4(state);
  };

  const accionesModalSearch = (element, data) => {
    if (element === 'cerrar') {
      setCurrentData(data);
      setShowModal4(false);
    }
  };

  return (
    <div>
      <div>
        {showModal && (
          <ModalMessage
            message={textDialog}
            handleAccept={async () => {
              deleteItem(selected, selectedItem);
            }}
            acceptTitle={'Aceptar'}
            handleCancel={() => {
              setShowModal(false);
            }}
            cancelTitle={'Cancelar'}
            draggable={true}
          />
        )}
        {showModal2 && (
          <ModalMessage
            message={textDialog}
            handleAccept={async () => {
              setShowModal2(false);
            }}
            acceptTitle={'Aceptar'}
            draggable={true}
          />
        )}
        {showModal3 && (
          <ModalMessageRecover
            message={textDialog}
            handleAccept={async () => {
              setShowModal3(false);
            }}
            recoverData={recoverData}
            accionesModal={accionesModal}
            acceptTitle={'Aceptar'}
            draggable={true}
            data={dataRecover}
            dataToDisplay={data}
            selectedIndex={selectedIndexA}
            loadData={loadData}
            addItem={addItem}
            // papelera={papelera}
          />
        )}
        {showModal4 && (
          <ModalMessageSearch
            message={textDialog}
            handleAccept={async () => {
              setShowModal4(false);
            }}
            acceptTitle={'Aceptar'}
            draggable={true}
            accionesModalSearch={accionesModalSearch}
            data={data}
            dataTopazMap={props.dataTopazMap}
            dataPrinter={props.dataPrinter}
            dataNetMap={props.dataNetMap}
            dataNumerator={props.dataNumerator}
            // data={dataRecover}
            // papelera={papelera}
          />
        )}
        <div className="h-full flex flex-col" style={{ maxWidth: 'max-content' }}>
          <div className={`flex-grow`} style={{ marginLeft: '15px', marginRight: '15px' }}>
            <div>
              <button className="pl-5" onClick={() => setShowWindow(selected)} disabled={selected === CATEGORY_PARAMETROS} title="Agregar nodo">
                ✚
              </button>
              <button
                className="pl-5"
                onClick={() => setShowWindow(selected, selectedItem, selectedIndex)}
                disabled={selectedItem === null}
                title="Editar"
              >
                ✎
              </button>
              <button
                className="pl-5"
                disabled={selectedItem === null || selected === CATEGORY_PARAMETROS}
                onClick={() => showModalItem(selected, selectedItem)}
                title="Eliminar"
              >
                ✖
              </button>
              <button
                className="pl-5"
                onClick={openModalRecover}
                title="Papelera de reciclaje"
                disabled={selected === CATEGORY_PARAMETROS || selected === CATEGORY_NUMERADORES}
              >
                <RecoverIcon />
              </button>
              <button className="pl-5" onClick={openModalSearch} title="Buscar" disabled={selected === CATEGORY_PARAMETROS}>
                <SearchIcon />
              </button>
            </div>

            <div className="w-full h-full flex">
              <DataList
                columns={columnOrders(selected)}
                selectedNode={selected}
                rows={data}
                defaultPageSize={30}
                selectedIndex={selectedIndex}
                className="-striped -highlight bg-grey-lighter-lighter"
                ref={(ref) => addDataListRef(ref)}
                addToRefListEntry={addToRefListEntry}
                setShowWindow={setShowWindow}
                setSelectedItem={(item) => setSelectedItem(item)}
                setSelectedIndex={(index) => setSelectedIndex(index)}
                autoPosition={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionManagerDataList;
