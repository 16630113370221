import { css, StyleSheet } from 'aphrodite';
import React from 'react';
// import { useIntl } from 'react-intl';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import BinnacleView from './BinnacleView';

const BinnacleViewModel = (props) => {
  // const intl = useIntl();
  const match = useRouteMatch();

  // const [filterSelected, setFilterSelected] = useState(Filters.GROUP_BY_ACCOUNT_SORT_BY_AMOUNT);

  const filters = [
    /* {
      id: Filters.GROUP_BY_ACCOUNT_SORT_BY_AMOUNT,
      queryName: 'digitalclearing.query_ChequesFiltro2PorImporteDeCuenta',
      apiName: 'Agrupados por cuenta, ordenados por importe',
      label: intl.formatMessage({
        id: Filters.GROUP_BY_ACCOUNT_SORT_BY_AMOUNT,
        defaultMessage: 'Agrupados por cuenta - ordenados por importe',
      }),
    }, */
  ];

  const styles = StyleSheet.create({
    container: {
      padding: '2rem 1rem 0',
      height: '100%',
    },
  });

  return (
    <div className={css(styles.container)}>
      <Switch>
        <Route path={`${match.path}/check`}></Route>
        <Route path={match.path}>
          <BinnacleView filters={filters} />
        </Route>
      </Switch>
    </div>
  );
};

export default BinnacleViewModel;
