import { PulseLoader } from 'halogenium';
import React from 'react';
import { useIntl } from 'react-intl';

import '../../SecurityToolsModal.scss';
import '../ConfigsModal.scss';

interface CryptogramModalViewProps {
  onAccept: (event: React.MouseEvent) => void;
  onCancel: (event: React.MouseEvent) => void;
  encryptedText: string;
  text: string;
  isLoading: boolean;
  onTextChange: (event: { target: HTMLInputElement | HTMLTextAreaElement }) => void;
}

export function CryptogramModalView({
  onAccept,
  onCancel,
  encryptedText,
  text,
  onTextChange,
  isLoading,
}: CryptogramModalViewProps): JSX.Element | null {
  const intl = useIntl();

  return (
    <div
      className="font-mono modal z-10 flex justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ height: '100%' }}
    >
      <div className="flex justify-center">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className="crypto-modal inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border shadow-sm">
          <div className="bg-blue-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="modal-info sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg text-center leading-6 font-medium text-gray-900" id="modal-title">
                  {intl.formatMessage({ id: 'cryptogram' })}.
                </h3>
                <fieldset className="fieldset-modal">
                  <legend>{intl.formatMessage({ id: 'text' })}</legend>
                  <div className="text-area-container">
                    <label className="pr-1 mb-1">Text</label>
                    <textarea className="border border-grey-lighter" value={text} name="reason" onChange={onTextChange} />
                  </div>
                </fieldset>
                <fieldset className="fieldset-modal">
                  <legend>{intl.formatMessage({ id: 'cryptogram' })}</legend>
                  {isLoading ? (
                    <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
                      <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
                    </div>
                  ) : (
                    <div className="text-area-container">
                      <textarea className="border border-grey-lighter" value={encryptedText || ''} name="reason" disabled={true} />
                    </div>
                  )}
                </fieldset>
              </div>
            </div>
          </div>
          <div className="justify-center bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onAccept}
              type="button"
              className="w-24 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium text-gray-700 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:text-sm"
            >
              {intl.formatMessage({ id: 'codify' })}
            </button>
            <button
              onClick={onCancel}
              type="button"
              className="mt-3 w-24 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm"
            >
              {intl.formatMessage({ id: 'cancel' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
