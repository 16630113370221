import axios from '../utils/axios';
import querystring from 'querystring';

import browserStorage from '../utils/browserStorage';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { TOPAZ_REST_API, API } from '../config';
import { HAS_GROUP_PERMISSION, HAS_USER_PERMISSION } from '../components/SecurityManager/models/constants';
import { handleEndpointError } from './handlers';

export const getSecurityGroups = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/groups/basicgroupsinfos?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getUsersBasicInfo = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getUsuariosBasicInfo?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getUserDetails = async (user) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/users/getUsuario?sessionId=${sessionID}&usuario=${user}`);
    if (response.data.contrasenia) {
      response.data.contrasenia = window.atob(response.data.contrasenia).trim();
    }
    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getMachinesInfo = async (branchNumber) => {
  try {
    const data = {
      nroSucursal: branchNumber,
    };
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(
      `${API}TopazWebClientWS/resources/binnacle/getNetMapDescriptionsPorSucursal?sessionId=${sessionID}&nroSucursal=${data.nroSucursal}`,
    );
    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const removeGroup = async (group) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const data = querystring.stringify({
      grupo: JSON.stringify({
        cargado: true,
        borrado: true,
        grupo: group.group,
        descripcion: group.description,
        grupobase: group.baseGroup,
        tz_lock: 0,
        heredamsg: 0,
      }),
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data,
    };

    await axios.delete(`${TOPAZ_REST_API}rest/security/groups?sessionId=${sessionID}`, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const removeUser = async (user) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const data = querystring.stringify({
      usuario: JSON.stringify({
        clave: user.username,
        nombre: user.name,
        iniciales: user.initials,
        grupo: user.group,
        ldapuser: user.ldapuser,
        lenguaje: user.language,
        multipleslogins: user.multiplesLogins,
        segundologin: user.secondLogin,
        deshabilitado: user.disabled,
        mantdes: user.mantdes,
        intentos: user.tries,
        nrodecaja: user.registerNumber,
        nrosucursal: user.branchNumber,
        iniexphabilitado: user.iniexphabilitado,
        numerodemaquina: user.machineNumber,
        fechabloqueo: user.blockingDate,
        motivoBloqueo: user.blockingMotive,
        tipo_documento: user.documentType,
        documento: user.document,
        controla_inactivo: user.inactiveControl === 1 ? 'S' : 'N',
        loginconad: user.loginconad,
        tipologin: user.loginType,
        cantlogins: user.loginsAmount,
        contrasenia: user.password,
        expiracioncontrasenia: user.passwordExpiration,
        fechacreacion: user.passwordCreationDate,
        fechaultlog: user.dateLastLog,
        sector: user.sector,
        inmediatosuperior: user.inmediatosuperior,
      }),
    });
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data,
    };

    await axios.delete(`${TOPAZ_REST_API}rest/security/users?sessionId=${sessionID}`, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const addUserAsync = async (user) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const data = querystring.stringify({
      usuario: JSON.stringify({
        clave: user.username,
        nombre: user.name,
        iniciales: user.initials,
        grupo: user.group,
        ldapuser: user.ldapuser,
        lenguaje: user.language,
        multipleslogins: user.multiplesLogins,
        segundologin: user.secondLogin,
        deshabilitado: user.disabled,
        mantdes: user.mantdes,
        intentos: user.failedAttempts,
        nrodecaja: user.registerNumber,
        nrosucursal: user.branchNumber,
        iniexphabilitado: user.iniexphabilitado,
        numerodemaquina: user.machineNumber,
        fechabloqueo: user.blockingDate,
        motivobloqueo: user.blockingMotive,
        tipo_documento: user.documentType,
        documento: user.document,
        controla_inactivo: user.inactiveControl === 1 ? 'S' : 'N',
        loginconad: user.loginconad,
        tipologin: user.loginType,
        cantlogins: user.loginsAmount,
        contrasenia: user.password,
        expiracioncontrasenia: user.passwordExpiration,
        fechacreacion: user.passwordCreationDate,
        fechaultlog: user.dateLastLog,
        sector: user.sector,
        fechaini: user.passwordInitialDate,
        fechaexp: user.passwordToDate,
        inmediatosuperior: user.inmediatosuperior,
      }),
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return axios.post(`${TOPAZ_REST_API}rest/security/users/webuser?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const updateUserAsync = async (user) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);

    const data = querystring.stringify({
      usuario: JSON.stringify({
        clave: user.username,
        nombre: user.name,
        iniciales: user.initials,
        grupo: user.group,
        ldapuser: user.ldapuser,
        lenguaje: user.language,
        multipleslogins: user.multiplesLogins,
        segundologin: user.secondLogin,
        deshabilitado: user.disabled,
        mantdes: user.mantdes,
        intentos: user.failedAttempts,
        nrodecaja: user.registerNumber,
        nrosucursal: user.branchNumber,
        iniexphabilitado: user.iniexphabilitado,
        numerodemaquina: user.machineNumber,
        fechabloqueo: user.blockingDate,
        motivobloqueo: user.blockingMotive,
        tipo_documento: user.documentType,
        documento: user.document,
        controla_inactivo: user.inactiveControl === 1 ? 'S' : 'N',
        loginconad: user.loginconad,
        tipologin: user.loginType,
        cantlogins: user.loginsAmount,
        contrasenia: user.password,
        expiracioncontrasenia: user.passwordExpiration,
        fechacreacion: user.passwordCreationDate,
        fechaultlog: user.dateLastLog,
        sector: user.sector,
        fechaini: user.passwordInitialDate,
        fechaexp: user.passwordToDate,
        inmediatosuperior: user.inmediatosuperior,
      }),
    });

    const dataMotivo = querystring.stringify({
      motivo: '',
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return await axios.put(`${TOPAZ_REST_API}rest/security/users/webuser/?sessionId=${sessionID}`, data, dataMotivo, config);
  } catch (ex) {
    return ex;
  }
};

export const getGroupMenuAsync = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/menu?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupBranchesAsync = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/branches?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getSpecificGroupBranchesAsync = async (group) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/branchesgroup?sessionId=${sessionID}&grupo=${group}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupCatalogueAsync = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/reportsadminisrator/catalogos/listaCatalogos?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupPermissionsAsync = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/permissions/getPermissions?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getSpecificGroupPermissionsAsync = async (group) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/permissions/getPermisosGrupo?sessionId=${sessionID}&grupo=${group}`);
    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupCatalogsAsync = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/reportsadminisrator/catalogos/listaCatalogos?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getSpecificGroupCatalogsAsync = async (group) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/catalogos?sessionId=${sessionID}&grupo=${group}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupReportsAsync = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/reports?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupOperationsAsync = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getOperaciones?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupNavigationsAsync = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/navegaciones?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getSpecificGroupNavigationsAsync = async (group) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/navegacionesgrupo?sessionId=${sessionID}&grupo=${group}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const validateUserAsync = async (userValidator, password, userToValidate) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);

    const res = await axios.get(
      `${API}TopazWebClientWS/resources/production/validarUsuarioLDAP?sessionId=${sessionID}&password=${password}&usuarioLDAP=${userValidator}&usuario=${userToValidate}`,
    );

    return res?.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getRestrictionReportAsync = async (title, enabled, disabled, from, to, groups, options) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    let groupsArray = [];
    let optionsArray = [];

    groups.forEach((group) => {
      groupsArray.push(group);
    });

    options.forEach((option) => {
      optionsArray.push(option);
    });

    const data = querystring.stringify({ listGrupos: groupsArray, listRestricciones: optionsArray });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };

    const response = await axios.post(
      `${TOPAZ_REST_API}rest/security/reports/getReportesRestricciones?sessionId=${sessionID}&desde=${from}&hasta=${to}&deshabilitado=${disabled}&habilitado=${enabled}&titulo=${title}`,
      data,
      config,
    );

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const validateUserMachineAsync = async (initials, machine, branch) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);

    const response = await axios.get(
      `${TOPAZ_REST_API}rest/security/users/getUsuariosMaquinaSucursal?sessionId=${sessionID}&nroMaquina=${machine}&nroSucursal=${branch}&iniciales=${initials}`,
    );

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupRestrictionsAsync = async (group, type) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/restrictions/${group}/${type}?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupReportAsync = async (title, groups, options, enabled, disabled) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    let groupsArray = [];
    let optionsArray = [];

    groups.forEach((group) => {
      groupsArray.push(group);
    });

    options.forEach((option) => {
      optionsArray.push(option);
    });
    const data = querystring.stringify({ listGrupos: groupsArray, listRestricciones: optionsArray });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const response = await axios.post(
      `${TOPAZ_REST_API}rest/security/reports/getReportesGrupos?sessionId=${sessionID}&deshabilitado=${disabled}&habilitado=${enabled}&titulo=${title}`,
      data,
      config,
    );

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getUserReportAsync = async (title, type, groups) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    let groupsArray = [];

    groups.forEach((group) => {
      groupsArray.push(group);
    });

    const data = querystring.stringify({ listGrupos: groupsArray });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const response = await axios.post(
      `${TOPAZ_REST_API}rest/security/reports/getReportesUsuarios?sessionId=${sessionID}&tipo=${type}&titulo=${title}`,
      data,
      config,
    );

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const createGroupAsync = async (group) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const grupo = {
      grupo: group.group,
      descripcion: group.description,
      grupobase: group.baseGroup,
      autorizacion: group.authorization,
      confirmacion: group.confirmation,
      heredamsg: Number(JSON.parse(group.areMessagesInherited || 'false')),
    };
    const data = querystring.stringify({ grupo: JSON.stringify(grupo) });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.post(`${TOPAZ_REST_API}rest/security/groups?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getGroupByNameAsync = async (groupName) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/security/groups/getGrupo?sessionId=${sessionID}&grupo=${groupName}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const validateUserRegisterAsync = async (initials, register, branch) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);

    const response = await axios.get(
      `${TOPAZ_REST_API}rest/security/users/getUsuariosMaquinaSucursal?sessionId=${sessionID}&nroCaja=${register}&nroSucursal=${branch}&iniciales=${initials}`,
    );

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const encriptData = async (inputData, length) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(
      `${API}TopazWebClientWS/resources/production/encriptPass2?sessionId=${sessionID}&length=${length}`,
      inputData,
      config,
    );

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getBlockingMotives = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);

    const response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBloqueos?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const updateGroupAsync = async (group) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const data = querystring.stringify({
      grupo: JSON.stringify({
        grupo: group.group || '',
        descripcion: group.description || '',
        grupobase: group.baseGroup || '',
        autorizacion: group.authorization || '',
        confirmacion: group.confirmation || '',
        heredamsg: Number(JSON.parse(group.areMessagesInherited || 'false')) || '',
      }),
      motivo: group.reason || '',
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.put(`${TOPAZ_REST_API}rest/security/groups?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const saveOrUpdateRestrictions = async (restriction, groupName, groupReason) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);

    let json = {
      identificacion: restriction.id || '',
      permisoDefault: restriction.ownPermission !== null ? restriction.ownPermission : 1,
      permisoPadre: restriction.parentPermission !== null ? restriction.parentPermission : 1,
      permisoTitulo: restriction.titlePermission || null,
      permite: restriction.allows === 2 ? null : restriction.allows,
      tipo: restriction.type === 'O' || restriction.type === 'M' || restriction.type === 'Q' ? restriction.type : '',
      grupo: groupName || '',
      descripcion: restriction.description || '',
    };
    if (restriction.fechaHabilitacion) {
      json.fechaHabilitacion = restriction.fechaHabilitacion;
    }
    const data = querystring.stringify({
      restriccion: JSON.stringify(json),
      motivo: groupReason,
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.post(`${TOPAZ_REST_API}rest/security/restrictions?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const saveUpdateOrDeleteListRestricciones = async (restriction, groupName, groupReason) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const mappedRestrictions = Object.keys(restriction).map((key) => {
      return {
        identificacion: restriction[key].id || '',
        grupo: groupName || '',
        tipo: restriction[key].type === 'O' || restriction[key].type === 'M' || restriction[key].type === 'Q' ? restriction[key].type : '',
        biblioteca: restriction[key].library || null,
        descripcion: restriction[key].description || '',
        permite: restriction[key].allows === 2 ? null : restriction[key].allows,
        permisoPadre: restriction[key].parentPermission || null,
        permisoTitulo: restriction[key].titlePermission || null,
        permisoDefault: restriction[key].ownPermission || null,
        fechaHabilitacion: restriction[key].fechaHabilitacion || null,
      };
    });

    const data = querystring.stringify({
      restricciones: JSON.stringify(mappedRestrictions),
      motivo: groupReason,
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    await axios.post(`${TOPAZ_REST_API}rest/security/restrictions/saveUpdateOrDeleteListRestricciones?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const saveUpdateOrDeletePermissionsGroups = async (restriction, groupName, groupReason) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const mappedRestrictions = Object.keys(restriction).map((key) => {
      return {
        permiso: restriction[key].id || null,
        biblioteca: restriction[key].library || null,
        formula: restriction[key].formula || null,
        formulaAnt: restriction[key].formulaAnt || '',
        autorizador: restriction[key].allower || '',
        permite: restriction[key].allows === 2 ? null : restriction[key].allows,
        grupo: groupName || '',
        descripcion: restriction[key].description || '',
      };
    });
    const data = querystring.stringify({
      comodines: JSON.stringify(mappedRestrictions),
      motivo: groupReason,
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.post(`${TOPAZ_REST_API}rest/security/permissions/saveUpdateOrDeletePermisosGrupo?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const setNavigationsGroups = async (restriction, groupName, groupReason) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const mappedRestrictions = Object.keys(restriction).map((key) => {
      return {
        codigo_navegacion: restriction[key].navigationCode || '',
        codigo_seccion: restriction[key].sectionCode || 0,
        permite: !restriction[key].allows && restriction[key].allows !== 0 ? 1 : restriction[key].allows,
        grupo: groupName || '',
      };
    });
    const data = querystring.stringify({
      navegacionesGrupo: JSON.stringify(mappedRestrictions),
      motivo: groupReason,
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.post(`${TOPAZ_REST_API}rest/security/navegacionesgrupo/setNavegacionesGrupos?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const updateNavigationsGroups = async (restriction, groupName, groupReason) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const mappedRestrictions = Object.keys(restriction).map((key) => {
      return {
        codigo_navegacion: restriction[key].navigationCode || '',
        codigo_seccion: restriction[key].sectionCode || 0,
        permite: !restriction[key].allows && restriction[key].allows !== 0 ? 1 : restriction[key].allows,
        grupo: groupName || '',
      };
    });
    const data = querystring.stringify({
      navegacionesGrupo: JSON.stringify(mappedRestrictions),
      motivo: groupReason,
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.post(`${TOPAZ_REST_API}rest/security/navegacionesgrupo/updateNavegacionesGrupos?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const removeNavigationsGroups = async (restriction, groupName) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const mappedRestrictions = Object.keys(restriction).map((key) => {
      return {
        codigo_navegacion: restriction[key].navigationCode || '',
        codigo_seccion: restriction[key].sectionCode || '',
        permite: !restriction[key].allows && restriction[key].allows !== 0 ? 1 : restriction[key].allows,
        grupo: groupName || '',
      };
    });
    const data = querystring.stringify({
      navegacionesGrupo: JSON.stringify(mappedRestrictions),
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.post(`${TOPAZ_REST_API}rest/security/navegacionesgrupo/removeNavegacionesGrupos?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const setBranchesGroups = async (restriction, groupName, motivo) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const mappedRestrictions = Object.keys(restriction).map((key) => {
      return {
        sucursal: restriction[key].branchNumber || null,
        grupo: groupName || '',
      };
    });
    const data = querystring.stringify({
      gruposSucursal: JSON.stringify(mappedRestrictions),
      motivo: motivo,
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.post(`${TOPAZ_REST_API}rest/security/branchesgroup/setGruposSucursal?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const removeBranchesGroups = async (restriction, groupName, motivo) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const mappedRestrictions = Object.keys(restriction).map((key) => {
      return {
        sucursal: restriction[key].branchNumber || null,
        grupo: groupName || '',
      };
    });
    const data = querystring.stringify({
      gruposSucursal: JSON.stringify(mappedRestrictions),
      motivo: motivo,
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.post(`${TOPAZ_REST_API}rest/security/branchesgroup/removeGruposSucursal?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const setCatalogsGroups = async (restriction, groupName) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const mappedRestrictions = Object.keys(restriction).map((key) => {
      return {
        numerocatalogo: restriction[key].catalogNumber,
        grupo: groupName || '',
      };
    });
    const data = querystring.stringify({
      gruposCatalogo: JSON.stringify(mappedRestrictions),
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await axios.post(`${TOPAZ_REST_API}rest/security/catalogos?sessionId=${sessionID}`, data, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const removeCatalogsGroups = async (restriction, groupName) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const mappedRestrictions = Object.keys(restriction).map((key) => {
      return {
        numerocatalogo: restriction[key].catalogNumber || null,
        grupo: groupName || '',
      };
    });
    const data = querystring.stringify({
      gruposCatalogo: JSON.stringify(mappedRestrictions),
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data,
    };

    await axios.delete(`${TOPAZ_REST_API}rest/security/catalogos?sessionId=${sessionID}`, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const reloadPermissions = async () => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await axios.put(`${API}TopazWebClientWS/resources/production/reloadPermissions?sessionId=${sessionID}`, config);
  } catch (error) {
    handleEndpointError(error);
  }
};

export const hasPermissions = async (type) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const permission = type === 'USER' ? HAS_USER_PERMISSION : HAS_GROUP_PERMISSION;
    const response = await axios.post(`${API}TopazWebClientWS/resources/menuSession/hasPermission?sessionID=${sessionID}&permission=${permission}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getTaskStatusId = async (taskId) => {
  try {
    const sessionID = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.get(`${TOPAZ_REST_API}rest/reports/tasks/${taskId}/status?sessionId=${sessionID}`);

    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};
