import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addValueTest } from '../../src/components/Testing/HandleLineTest.js';

import { onSign, onClear, onDone, closingSigWeb } from '../../lib/SigWebTabletCustom.js';

class SignatureCapture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setupError: false,
      enabled: false,
    };
  }

  componentWillUnmount() {
    closingSigWeb();
  }

  render() {
    const { signText, clearText, submitText, closeText, errorMsg, handleSigSubmit, handleClose, btnClass, btnStyles } = this.props;

    const { setupError, enabled } = this.state;

    return (
      <div className="container">
        <div className="canvas" style={{ textAlign: 'center' }}>
          <canvas
            style={{ border: '1px solid #ccc', background: enabled ? 'white' : 'steel' }}
            className=""
            id="cnv"
            name="cnv"
            width="400"
            height="100"
          />
          {setupError}
          {setupError && <p className="mb-2">{errorMsg}</p>}
        </div>
        <div style={{ textAlign: 'center' }}>
          <input
            id="btnSign"
            name="SignBtn"
            className={btnClass}
            style={btnStyles}
            type="button"
            value={signText}
            onClick={() => {
              this.setState({
                enabled: true,
              });
              addValueTest('CLICK', 'btnSign', '');
              try {
                onSign();
              } catch (e) {
                this.setState({
                  setupError: true,
                });
              }
            }}
          />
          <input
            id="btnClear"
            name="ClearBtn"
            className={btnClass}
            style={btnStyles}
            type="button"
            value={clearText}
            onClick={() => {
              addValueTest('CLICK', 'btnClear', '');
              try {
                onClear();
              } catch (e) {
                this.setState({
                  setupError: true,
                });
              }
            }}
          />
          <input
            id="btnDone"
            name="DoneBtn"
            className={btnClass}
            style={btnStyles}
            type="button"
            value={submitText}
            onClick={() => {
              addValueTest('CLICK', 'btnDone', '');
              try {
                onDone().then((result) => {
                  handleSigSubmit(result);
                });
              } catch (e) {
                this.setState({
                  setupError: true,
                });
              }
            }}
          />
          <input
            id="btnClose"
            name="CloseBtn"
            className={btnClass}
            style={btnStyles}
            type="button"
            value={closeText}
            onClick={(e) => {
              addValueTest('CLICK', 'btnClose', '');
              closingSigWeb();
              handleClose(e);
            }}
          />
        </div>
      </div>
    );
  }
}

SignatureCapture.propTypes = {
  signText: PropTypes.string,
  clearText: PropTypes.string,
  submitText: PropTypes.string,
  closeText: PropTypes.string,
  errorMsg: PropTypes.string,
  btnClass: PropTypes.string,
  btnStyles: PropTypes.object,
  handleSigSubmit: PropTypes.func.isRequired,
};

SignatureCapture.defaultProps = {
  signText: 'Start',
  clearText: 'Clear',
  submitText: 'Submit',
  closeText: 'Close',
  btnStyles: {
    marginRight: 10,
  },
};

export default SignatureCapture;
