import React, { useEffect, useState } from 'react';
import { getRecordsByConcept, getHelpMetadataByConcept, getRecordsByConceptFilter } from '../../../api/schema.js';
import { PulseLoader } from 'halogenium';
import { useIntl } from 'react-intl';

export const HelpCurrencyComponent = (props) => {
  const intl = useIntl();

  const inicializarCampo = '6399';
  const inicializarOperador = '1';
  const concepto = 3;

  const [operador, setOperador] = useState(inicializarOperador);
  const [campo, setCampo] = useState(inicializarCampo);
  const [valueFilter, setValueFilter] = useState('');

  const [selectedValue, setSelectedValue] = useState(props.currentValue);

  const [record, setRecord] = useState([]);
  const [help, setHelp] = useState([]);

  useEffect(() => {
    getRecordsByConcept(concepto).then(function (response) {
      setRecord(response);
    });
    getHelpMetadataByConcept(concepto).then(function (response) {
      setHelp(response);
    });
  }, []);

  useEffect(() => {
    if (props.forceSelectHelp) {
      handleWindowSelect(selectedValue);
    }
  });

  const selectDataCampo = (data) => {
    setCampo(data);
  };

  const selectDataOperador = (data) => {
    setOperador(data);
  };

  const filtrar = () => {
    let campoCurrency = campo;
    let op = operador;
    getRecordsByConceptFilter(concepto, valueFilter, op, campoCurrency)
      .then(function (response) {
        if (response.length > 0) {
          setRecord(response);
        }
      })
      .catch(function (e) {});
  };

  const handleClick = (option) => {
    setSelectedValue(option);
  };

  const handleDoubleClick = (value) => {
    handleWindowSelect(value);
  };

  const handleWindowSelect = (value) => {
    props.setHelpValue(value);
  };

  return (
    <div>
      {/* {record && Array.isArray(record) && record.length > 0 ? ( */}
      <div className="container">
        <div className="row" style={{ display: 'flex' }}>
          <div className="col-2 md-2">
            <label>
              {intl.formatMessage({
                id: 'field',
                defaultMessage: 'Campo',
              })}
            </label>
          </div>
          <div className="col-10">
            <select
              onChange={(event) => selectDataCampo(event.target.value)}
              id="slcCurrency"
              name="slcCampo"
              style={{ width: '100%', marginLeft: '55px', border: '1px solid grey', borderRadius: '4px' }}
              required
            >
              {help && Array.isArray(help) && help.length > 0 ? (
                help.map((x) => (
                  <option key={x.fieldNumber} value={x.fieldNumber}>
                    {x.columnName}
                  </option>
                ))
              ) : (
                <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
              )}
            </select>
          </div>
        </div>
        <div className="row" style={{ display: 'flex' }}>
          <div className="col-2">
            <label>
              {intl.formatMessage({
                id: 'operator',
                defaultMessage: 'Operador',
              })}
            </label>
          </div>
          <div className="col-10">
            <select
              onChange={(event) => selectDataOperador(event.target.value)}
              id="slcOperador"
              name="slcOperador"
              style={{ width: '100%', marginLeft: '40px', border: '1px solid grey', borderRadius: '4px', marginTop: '5px' }}
            >
              <option key="1" value="1">
                !=
              </option>
              <option key="2" value="2">
                =
              </option>
              <option key="3" value="3">
                {'>'}
              </option>
              <option key="4" value="4">
                {'>='}
              </option>
              <option key="5" value="5">
                {'<'}
              </option>
              <option key="6" value="6">
                {'<='}
              </option>
              <option key="7" value="7">
                {intl.formatMessage({
                  id: 'startsWith',
                  defaultMessage: 'Comienza con',
                })}
              </option>
              <option key="8" value="8">
                {intl.formatMessage({
                  id: 'like',
                  defaultMessage: 'Like',
                })}
              </option>
              <option key="9" value="9">
                {intl.formatMessage({
                  id: 'no',
                  defaultMessage: 'No',
                }) + ' '}
                {intl.formatMessage({
                  id: 'like',
                  defaultMessage: 'like',
                })}
              </option>
            </select>{' '}
          </div>
        </div>
        <div className="row" style={{ display: 'flex' }}>
          <div className="col-2 md-2">
            <label>
              {intl.formatMessage({
                id: 'value',
                defaultMessage: 'Valor',
              })}
            </label>
          </div>
          <div className="col-8">
            <input
              type="text"
              id="txtFilterValue"
              onChange={(e) => setValueFilter(e.target.value)}
              className="w-full"
              style={{ border: '1px solid grey', marginLeft: '68px', marginTop: '5px', borderRadius: '4px' }}
            ></input>
          </div>
          <div className="col-2">
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={filtrar}
              style={{ fontSize: '12px', padding: '5px', marginLeft: '80px', borderRadius: '4px', border: '1px solid grey' }}
            >
              {intl.formatMessage({
                id: 'filtrar',
                defaultMessage: 'Filtrar',
              })}
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow-md sm:rounded-lg">
                <div className="col mt-1" style={{ overflowY: 'scroll', maxHeight: '200px', minHeight: '200px', width: '400px', marginTop: '5px' }}>
                  <table className="default" style={{ fontSize: '12px' }}>
                    <thead>
                      <tr>
                        <th scope="col">
                          {intl.formatMessage({
                            id: 'moneda',
                            defaultMessage: 'Moneda',
                          })}
                        </th>
                        <th scope="col">
                          {intl.formatMessage({
                            id: 'descripcion',
                            defaultMessage: 'Descripcion',
                          })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {record.map((option, index) => {
                        return (
                          <tr
                            style={option === selectedValue ? { backgroundColor: '#9ABAF5' } : { backgroundColor: 'white' }}
                            key={index}
                            onClick={() => handleClick(option)}
                            onDoubleClick={() => handleDoubleClick(option)}
                            className={index % 2 === 0 ? 'table-active' : ''}
                          >
                            <td>{option[0].string}</td>
                            <td>{option[1].string}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ) : ( */}
      {/* <div className="container" style={{ maxHeight: '300px', minHeight: '100px', width: '700px' }}>
        <div className="m-0 row justify-content-center">
          <div className="col-auto p-5 text-center">
            <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
          </div>
        </div>
      </div> */}
      {/* )} */}
    </div>
  );
};
