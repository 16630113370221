import { ForkProcess } from '../models/forkProcess';

export function isAForkProcess(forks: ForkProcess[], processName: string): boolean {
  let processIsFork: boolean = false;

  forks.forEach((fork) => {
    if (fork.name === processName) {
      processIsFork = true;
    }
  });

  return processIsFork;
}
