export default {
  get: (key) => (window.localStorage[key] ? JSON.parse(window.localStorage[key]) : window.localStorage[key]),
  set: (key, value) => {
    window.localStorage[key] = JSON.stringify(value);
  },
  clearKey: (key) => {
    window.localStorage.removeItem(key);
  },
  clear: () => {
    window.localStorage.clear();
  },
  sessionGet: (key) => (window.sessionStorage[key] ? JSON.parse(window.sessionStorage[key]) : window.sessionStorage[key]),
  sessionSet: (key, value) => {
    window.sessionStorage[key] = JSON.stringify(value);
  },
  sessionClearKey: (key) => {
    window.sessionStorage.removeItem(key);
  },
  sessionClear: () => {
    window.sessionStorage.clear();
  },
};
