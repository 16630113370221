import { PulseLoader } from 'halogenium';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import HelpTable from '../../../../../UI-kit/components/HelpTable';
import { HelperRow } from '../../models/types';
import { Column } from '../securityToolsModal/SecurityToolsModalViewModel';
import Draggable from 'react-draggable';
import FocusLock from 'react-focus-lock';
import { useTheme } from '../../../../themes/ThemeContext';
import { css, StyleSheet } from 'aphrodite';
import './DataHelper.scss';

export interface DataHelperProps {
  title: string;
  rows: HelperRow[];
  columns: Column[];
  onAccept: (helperOpen: string) => void;
  onCancel: (helperOpen: string) => void;
  onChange: (indexSelected: number) => void;
  isLoading?: boolean;
}

export function DataHelper({ title, rows, columns, onAccept, onCancel, onChange, isLoading }: DataHelperProps): JSX.Element | null {
  const intl = useIntl();
  const [helpSelected, setHelpSelected] = useState<number>(-1);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const themeContext = useTheme();

  const { color, commonButton, modal } = themeContext.theme;

  const styles = StyleSheet.create({
    show: {
      display: 'block',
      zIndex: 900,
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    modalMain: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxHeight: '80%',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      // minWidth: '320px',
      // maxWidth: '98vw',
      // maxHeight: '98vh',
      backgroundColor: modal.active.background,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: 'transparent',
      opacity: '100%',
      width: '500px',
      boxShadow: 'rgba(0, 0, 0, 0.33) 0px 2px 8px',
      // alignItems: 'center',
      transition: 'border background .2s',
      padding: '.2rem',
    },
    titleContainer: {
      width: '100%',
      minHeight: '1.5rem',
      padding: '.5rem 1rem',
      color: color.grey.dark4,
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
  });

  function onCancelClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onCancel('');
  }

  function onAcceptClick(event: React.MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    if (helpSelected !== -1) {
      onAccept('');
      onChange(helpSelected);
    } else {
      setShowErrorMessage(true);
    }
  }

  function onHelpSelect(): (index: number) => void {
    return (index) => {
      setHelpSelected(index);
      if (index !== -1) {
        setShowErrorMessage(false);
      }
    };
  }

  return (
    <div className="help-wrapper">
      {isLoading ? (
        <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
          <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
        </div>
      ) : (
        <div>
          <FocusLock persistentFocus returnFocus>
            <div className={css(styles.modal, styles.show)}>
              {/* onKeyDown={handleKeyDown}> */}
              <div className={css(styles.modalMain)}>
                <Draggable handle=".drag-section" bounds={{ top: -200, right: 350, bottom: 200, left: -350 }}>
                  <div className={`${css(styles.modalContent)}`}>
                    <div className="drag-section title-container">{title}</div>
                    <div className="flex border border-smoke rounded table-container">
                      <HelpTable
                        fromApp={true}
                        fromHelp={true}
                        transactionLineLabel={'T1L2'}
                        className="table-content-scroll"
                        tableTitlesStyle="bg-grey-lighter"
                        data={rows && rows.map((row: HelperRow, index: number) => ({ ...row, id: index }))}
                        columns={columns}
                        goToLineFn={() => {}}
                        onHelpSelect={(selection) => {
                          onAcceptClick(null);
                        }}
                        getTrProps={(state: any, rowInfo: any, runSelection = false) => {
                          setHelpSelected(rowInfo.index);
                          return {
                            onClick: (e, rowIndex) => {},
                          };
                        }}
                      />
                    </div>
                    <div className="error-message pr-1 pt-2 ">
                      {showErrorMessage ? (
                        <label className="select-item text-red flex">{intl.formatMessage({ id: 'selectAnItem' })}</label>
                      ) : (
                        <label className="no-error text-red flex bg-white" />
                      )}
                    </div>
                    <div className="buttons">
                      <button
                        type="button"
                        onClick={onAcceptClick}
                        className="buttonStyle mr-3 mb-3 mt-3 w-24 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 sm:mt-0 sm:ml-3 sm:text-sm"
                      >
                        {intl.formatMessage({ id: 'select' })}
                      </button>
                      <button
                        type="button"
                        onClick={onCancelClick}
                        className="buttonStyle mr-3 mb-3 mt-3 w-24l inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 sm:mt-0 sm:ml-3 sm:text-sm"
                      >
                        {intl.formatMessage({ id: 'cancel' })}
                      </button>
                    </div>
                  </div>
                </Draggable>
              </div>
            </div>
          </FocusLock>
        </div>
      )}
    </div>
  );
}
