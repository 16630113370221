/* eslint-disable no-unused-vars */
import { css, StyleSheet } from 'aphrodite';
import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { getChecks, getSignatoriesByMicrLine, updateCheck, isChequeDiferido } from '../../../api/clearing';
import { Filters, ValidateStatus as Status } from '../Constants';
import { getPersonSignatures, getProxies } from './../../../api/signatures';
import { formatDateDDMMYYYYWithHyphen } from './../../../../UI-kit/helpers/dateHelper';
import useQuery from './../../../hooks/useQuery';
import { mapResponseToSignatures } from './../../../services/transformDataFromAPI';
import { useTheme } from './../../../themes/ThemeContext';
import SignatureSelector from './../../SignaturesValidatorClearing/SignaturesSelector';
import ActionButton from './ActionButton';
import CheckImages from './CheckImages';
import CheckInfo from './CheckInfo';
import NavigationButton from './NavigationButton';
import ValidateSignaturesSwitch from './ValidateSignaturesSwitch';
import RejectionComponent from './RejectionComponent';
import { getSessionInfo } from '../../../api/session';
import { store } from '../../../store';
import ModalMessage from '../../ModalMessage';

const CheckDetails = (props) => {
  const intl = useIntl();
  const query = useQuery();
  const history = useHistory();

  const [status, setStatus] = useState();
  const [statusText, setStatusText] = useState(selectASignature);
  const [checkData, setCheckData] = useState({
    frontImage: '',
    backImage: '',
  });
  const [signatures, setSignatures] = useState([]);
  const [validateSignatures, setValidateSignatures] = useState(true);
  const [rejected, setRejected] = useState(false);
  const [allowValidateCheck, setAllowValidateCheck] = useState(true);
  const [currentSignatories, setCurrentSignatories] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [info, setInfo] = useState(null);
  const [disableRejection, setDisableRejection] = useState(false);
  const [disableValidation, setDisableValidation] = useState(false);
  const [diferido, setDiferido] = useState(false);

  const micrLine = query.get('micr');
  const check = props.selectedCheck;

  const themeContext = useTheme();
  const { text } = themeContext.theme;

  const styles = StyleSheet.create({
    header: {
      width: '100%',
    },
    navigationButtons: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      padding: '0 1rem',
      display: 'flex',
      flexDirection: 'column',
      margin: '1rem 0',
      color: text.text,
    },
    titleLabel: {
      userSelect: 'none',
      color: text.light,
      fontSize: '.7rem',
      marginRight: '.4rem',
    },
    titleNumber: {
      fontSize: '1.2rem',
      fontFamily: ['Inconsolata', 'Consolas', 'monospace'],
    },
    statusText: {},
    validateButton: {},
    rejectButton: {},
    checkWrapper: {
      display: 'flex',
      marginBottom: '1.5rem',
      height: '15rem',
    },
    checkInfoWrapper: {
      width: '50%',
      marginRight: '.5rem',
    },
    checkImagesWrapper: {
      width: '60%',
      marginLeft: '.5rem',
      // backgroundColor: background.dark2
    },
  });

  const backToFilterText = intl.formatMessage({
    id: 'backToFilter',
    defaultMessage: 'Volver al filtro',
  });
  const selectASignature = intl.formatMessage({
    id: 'selectASignature',
    defaultMessage: 'Seleccione las firmas que aparecen en el cheque',
  });
  const validateCheckText = intl.formatMessage({
    id: 'validateCheck',
    defaultMessage: 'Validar cheque',
  });
  const rejectCheckText = intl.formatMessage({
    id: 'rejectCheck',
    defaultMessage: 'Rechazar',
  });

  const fetchCheck = async (micrLine) => {
    const response = await getChecks(Filters.CHECK_BY_MICRLINE, micrLine, true);
    const check = response[0];
    const checkData = {
      serie: check.serie,
      checkNumber: check.numeroCheque,
      account: check.cuenta,
      amount: check.importe,
      currency: check.moneda,
      frontImage: check.imagenAnverso,
      backImage: check.imagenReverso,
      saldoJtsOid: check.saldoJtsOid,
      status: check.estado,
      statusDevolucion: `${check.estadoDevolucion} - ${check.descripcionEstadoDevolucion}`,
      causeDevolucion: `${check.codigoCausalDevolucion} - ${check.descripcionCausalDevolucion}`,
    };
    setCheckData(checkData);
  };

  const fetchSignatures = async (micrLine) => {
    const signatories = await getSignatoriesByMicrLine(micrLine);
    setCurrentSignatories(signatories);
    if (signatories && signatories[0]) {
      const jtsBalance = signatories[0].jtsSaldo;
      await getProxies(jtsBalance, 2, 2);
      for (const signatory of signatories) {
        const response = await getPersonSignatures(signatory.numeroPersona);
        const signature = mapResponseToSignatures(response, signatory.numeroPersona, signatory.jtsSaldo);
        const newSignatures = [...signatures, ...signature];
        setSignatures(newSignatures);
      }
    }
  };

  useEffect(() => {
    const checkDataToSet = {
      serie: check.serie,
      checkNumber: check.numeroCheque,
      account: check.cuenta,
      date: check.fecha,
      branch: check.sucursal,
      operation: check.operacion,
      product: check.producto,
      amount: check.importe,
      currency: check.moneda,
      frontImage: check.imagenAnverso,
      backImage: check.imagenReverso,
      status: check.estado,
      statusDevolucion: `${check.estadoDevolucion} - ${check.descripcionEstadoDevolucion}`,
      causeDevolucion: `${check.codigoCausalDevolucion} - ${check.descripcionCausalDevolucion}`,
      descriptionDevolucion: check.descripcionDevolucion !== undefined ? check.descripcionDevolucion : ' ',
    };
    setCheckData(checkDataToSet);

    const isDiferido = async () => {
      const res = await isChequeDiferido(micrLine);
      if (res !== undefined) {
        setDiferido(res);
      }
    };
    isDiferido();
    let state = store.getState();
    let selectedFilter = state.filter;
    if (
      selectedFilter === 'aceptadosPorGerencia' ||
      selectedFilter === 'aSpecificCheck' ||
      selectedFilter === 'rechazados' ||
      selectedFilter === 'porCuenta' ||
      selectedFilter === 'rechazadosPorClearingDigital'
    ) {
      setDisableRejection(true);
    }
    if (
      selectedFilter === 'aceptadosPorGerencia' ||
      selectedFilter === 'aSpecificCheck' ||
      selectedFilter === 'porCuenta' ||
      selectedFilter === 'rechazados' ||
      selectedFilter === 'aceptados'
    ) {
      setDisableValidation(true);
    }
  }, [check, micrLine]);

  useEffect(() => {
    // fetchCheck(micrLine);
    fetchSignatures(micrLine);
    getSessionInfo().then((sessionInfo) => setInfo(sessionInfo));
    // eslint-disable-next-line-exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [micrLine]);

  const backToFilter = () => {
    history.goBack();
  };

  const nextCheck = () => {};

  const toggleRejected = () => {
    setRejected(true);
  };

  const toggleValidateSignatures = () => {
    setValidateSignatures((validateSignatures) => !validateSignatures);
  };

  const onClickValidatecheck = () => {
    if (checkData.status === 'RECHAZADO') {
      setShowMessage(true);
    } else {
      validateCheck();
    }
  };

  const validateCheck = () => {
    /* let {
      location: { pathname },
    } = history;
    const url = `${pathname}/check?micr=${micrLine}/accept`;
    history.push(url); */

    let body = {
      firmas: [],
      // array con los causales seleccionados (objetos) de los causlaes, si es validado se manda un array vacio
      otrasCausalesDevolucion: [],
      micrLine: micrLine,
      cuenta: checkData.account,
      fecha: formatDateDDMMYYYYWithHyphen(checkData.date),
      sucursal: checkData.branch,
      producto: checkData.product,
      operacion: checkData.operation,
      importe: checkData.amount,
      // VALIDADO_SF, VALIDADO
      estado: 'VALIDADO',
      // usuario que esta loggeado de la sesion
      usuario: info.user.name,
      // fecha de proceso
      ultimaModificacion: info.fechaProceso.slice(0, info.fechaProceso.indexOf(' ')),
      imagenAnverso: checkData.frontImage,
      imagenReverso: checkData.backImage,
      serie: checkData.serie,
      numeroCheque: checkData.checkNumber,
      moneda: checkData.currency,
      // si rechazo 1, si valido 0
      estadoDevolucion: 0,
      // 'A devolver automático' (recordar internacionalizacion), 'Recibido' si es valido
      descripcionEstadoDevolucion: 'Recibido',
      descripcionDevolucion: '',
      codigoCausalDevolucion: '0',
      fechaEmision: null,
      fechaVencimiento: null,
      // lo que me llega
      saldoJtsOid: checkData.saldoJtsOid,
      // false si es rejected, false si es val sin firma, true si valida
      firmasValidadas: false,
    };

    updateCheck(body);
    let state = store.getState();
    let clearingState = state.clearing;
    let checksData = clearingState.searchResult;
    let checkDataToAssign = checksData.filter((check) => check.micrLine !== micrLine);
    clearingState.searchResult = checkDataToAssign;
    store.setState({ clearing: clearingState });
    history.goBack();
  };

  return (
    <React.Fragment>
      <div className={css(styles.header)}>
        <div className={css(styles.navigationButtons)}>
          <NavigationButton left text={backToFilterText} onClick={backToFilter} />
        </div>
        <div className={css(styles.title)}>
          <p className={css(styles.titleLabel)}>MICR</p>
          <p className={css(styles.titleNumber)}>{micrLine}</p>
        </div>
      </div>
      <div className={css(styles.checkWrapper)}>
        <div className={css(styles.checkInfoWrapper)}>
          <CheckInfo data={checkData} />
        </div>
        <div className={css(styles.checkImagesWrapper)}>
          <CheckImages images={[check.imagenAnverso, check.imagenReverso]} />
        </div>
      </div>
      <ValidateSignaturesSwitch active={validateSignatures} onChange={toggleValidateSignatures} />
      {validateSignatures && (
        <SignatureSelector
          amount={checkData.amount}
          currency={checkData.currency}
          setValidatedFormula={setAllowValidateCheck}
          setSignatures={setSignatures}
          signatures={signatures}
        />
      )}
      <p
        className={css([
          styles.statusText,
          status === Status.SUCCESS && styles.success,
          status === Status.WARNING && styles.warning,
          status === Status.ERROR && styles.error,
        ])}
      >
        {statusText}
      </p>
      <div className={css(styles.actionButtons)}>
        <ActionButton type="success" onClick={onClickValidatecheck} disabled={disableValidation}>
          {validateCheckText}
        </ActionButton>
        <ActionButton type="error" onClick={toggleRejected} disabled={disableRejection}>
          {rejectCheckText}
        </ActionButton>
        {rejected && <RejectionComponent micrLine={micrLine} checkData={checkData} signatures={currentSignatories} info={info} diferido={diferido} />}
      </div>
      {showMessage && (
        <ModalMessage
          message={
            <div className="flex w-full h-full justify-center items-center">
              <FormattedMessage id="validateRejectedCheck" defaultMessage={`¿Está seguro que desea validar un cheque previamente rechazado?`} />
            </div>
          }
          handleAccept={() => {
            setShowMessage(false);
            validateCheck();
          }}
          handleCancel={() => {
            setShowMessage(false);
          }}
          acceptTitle={'Aceptar'}
          cancelTitle={'Cancelar'}
        />
      )}
    </React.Fragment>
  );
};
export default CheckDetails;
