import { Window } from '../../UI-kit/index';
import { acceptDialog, cancelDialog } from '../../src/api/dialog';
import { createAcceptBody } from '../lib/bodyCreators';
import { store, dispatchActions } from '../../src/store';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { generateLineIDString, getNumberFromDialogKey } from '../../UI-kit/helpers/operationHelper';

export default class DialogToDisplay {
  constructor({ type, displayInfo, number, lineID, idNode }) {
    this._type = type;
    this._displayInfo = {
      ...displayInfo,
      ...displayInfo.displayInfo,
    };
    this._number = this._displayInfo.number;
    this._lineID = lineID;
    this._nodeId = idNode;
  }

  get number() {
    return this._number;
  }

  transactionNumber() {
    return this._lineID ? this._lineID.transactionNumber : '';
  }

  lineNumber() {
    return this._lineID ? this._lineID.lineNumber : '';
  }

  countIterativeDialog() {
    if (this._lineID && this._lineID.countIterativeDialog !== undefined) {
      return this._lineID.countIterativeDialog;
    }
    return 0;
  }

  countVariableRow() {
    if (this._lineID && this._lineID.countVariableRow !== undefined) {
      return this._lineID.countVariableRow;
    }
    return 0;
  }

  transactionLineLabel() {
    return generateLineIDString(this._lineID);
  }

  position() {
    return this._displayInfo.position;
  }

  isDialogBox() {
    return this._displayInfo.isDialogBox;
  }

  title() {
    return this._displayInfo.title;
  }

  acceptButton() {
    return this._displayInfo.acceptButton;
  }

  cancelButton() {
    return this._displayInfo.cancelButton;
  }

  buttonsPosition() {
    return this._displayInfo.buttonsPosition;
  }

  canMove() {
    return this._displayInfo.mobile;
  }

  width() {
    return this._displayInfo.displayInfo.position.width;
  }

  height() {
    return this._displayInfo.displayInfo.position.height;
  }

  isInFront() {
    return this._displayInfo.front;
  }

  hide() {}

  style() {
    return {
      width: this.width(),
      height: this.height(),
    };
  }

  options() {
    return {};
  }

  acceptHandler() {
    this.dialogService.Accept(this.values);
  }

  validateData(field, fieldData) {
    if (
      fieldData[field] &&
      (fieldData[field].hide ||
        fieldData[field].disabled ||
        (fieldData[field].inputType && fieldData[field].inputType === 5) ||
        fieldData[field].optional === undefined ||
        (fieldData[field].optional === false &&
          fieldData[field].type &&
          fieldData[field].type !== 4 &&
          fieldData[field].inputType !== 7 &&
          fieldData[field].value !== '' &&
          fieldData[field].value !== null) ||
        (fieldData[field].optional === false &&
          fieldData[field].type === 4 &&
          fieldData[field].raw.displayInfo.value !== null &&
          fieldData[field].raw.displayInfo.value !== '' &&
          fieldData[field].raw.displayInfo.value !== undefined) ||
        (fieldData[field].optional === false &&
          fieldData[field].inputType &&
          fieldData[field].inputType === 6 &&
          fieldData[field].value !== null &&
          fieldData[field].value !== undefined &&
          fieldData[field].value.length !== 0) ||
        (fieldData[field].inputType && fieldData[field].inputType === 7 && fieldData[field].value !== 0 && fieldData[field].optional === false) ||
        (fieldData[field].optional && fieldData[field].optional === true))
    ) {
      return true;
    }
  }

  props(fieldData) {
    if (!fieldData[this.transactionLineLabel()]) {
      console.log(this.transactionLineLabel());
    }
    return {
      accept: this.acceptButton(),
      cancel: this.cancelButton(),
      buttonsPosition: this.buttonsPosition(),
      title: this.title(),
      position: this._displayInfo.displayInfo.position,
      zIndex: this.zIndex,
      transactionLineLabel: this.transactionLineLabel,
      hide: !!fieldData[this.transactionLineLabel()].hide,
      acceptHandler: (
        opNumber,
        operationID,
        processContext,
        removeFromWindowStack,
        addEmptyTab,
        addTab,
        tabs,
        openGlobalModal,
        addEmptyTabReport,
        addTabReport,
        closeGlobalModal,
        tabsReport,
        btnRef,
        setApiLoading,
        apiLoading,
      ) => {
        if (apiLoading) return;

        if (btnRef) {
          btnRef.setState({ disabled: true });
          document.getElementById(btnRef.buttonInput.current.id).style.cursor = 'wait';
        }

        let goToServer = true;
        const focusElementToCheck = store.getState().tabs[store.getState().current].running.focusElement;
        const focusElementLineNumber = parseInt(focusElementToCheck.slice(focusElementToCheck.indexOf('L') + 1, focusElementToCheck.length));
        if (focusElementLineNumber !== 1) {
          goToServer = false;
          let focusElement = store.getState().tabs[store.getState().current].running.focusElement;
          if (this.validateData(focusElement, fieldData)) {
            goToServer = true;
          } else {
            goToServer = false;
            const required = <FormattedMessage id="errorMandatoryField" defaultMessage={`es de ingreso obligatorio`} />;
            const msg = (
              <div>
                {fieldData[focusElement] ? fieldData[focusElement].prompt : ''} {required}
              </div>
            );
            store.setState(dispatchActions.showModalMessage(store.getState(), msg));
          }
        }

        let body = createAcceptBody(opNumber, fieldData, this._number, focusElementLineNumber !== 1);
        if (goToServer) {
          setApiLoading(true, this._lineID.tabId);
          let acceptDialogPromiseResponse = acceptDialog(operationID, getNumberFromDialogKey(this._number), body);
          if (acceptDialogPromiseResponse) {
            acceptDialogPromiseResponse
              .then((res) => {
                if (res && res.lista) {
                  const elementsRemove = res.lista.filter((component) => component['class-name'].includes('ItemToDeleteEvent'));

                  processContext(res, 'Accept dialog').finally((res) => {
                    setApiLoading(false, this._lineID.tabId);
                  });
                  if (elementsRemove && elementsRemove.length > 0) {
                    elementsRemove.forEach((element) => {
                      const lineIDKey = generateLineIDString(element.lineID);
                      removeFromWindowStack(lineIDKey);
                    });
                  }
                }
              })
              .finally(() => {
                if (btnRef) {
                  btnRef.setState({ disabled: false });
                  if (btnRef.buttonInput.current) {
                    document.getElementById(btnRef.buttonInput.current.id).style.cursor = 'pointer';
                  }
                }
              });
          } else {
            if (btnRef) {
              btnRef.setState({ disabled: false });
              if (btnRef.buttonInput.current) {
                document.getElementById(btnRef.buttonInput.current.id).style.cursor = 'pointer';
              }
            }
          }
        } else {
          if (btnRef) {
            btnRef.setState({ disabled: false });
            if (btnRef.buttonInput.current) {
              document.getElementById(btnRef.buttonInput.current.id).style.cursor = 'pointer';
            }
          }
        }
      },
      cancelHandler: async (
        operationID,
        processContext,
        removeTreeFields,
        removeFromWindowStack,
        focusOn,
        goBackToDialog,
        clearStateByNodeId,
        apiLoading,
      ) => {
        if (apiLoading) return;

        cancelDialog(operationID, getNumberFromDialogKey(this._number)).then((res) => {
          clearStateByNodeId(this._nodeId);
          store.setState(dispatchActions.reverseFlow(store.getState()));
          processContext(res, 'Cancel dialog');
          store.setState(dispatchActions.reverseFlow(store.getState(), false));
          const elementsRemove = res.lista.filter((component) => component['class-name'].includes('ItemToDeleteEvent'));
          const elementToFocus = res.lista.find((component) => component['class-name'].includes('FocusToSetEvent'));
          if (elementToFocus) {
            const { transactionNumber } = elementToFocus.lineID;
            const lineIDKey = generateLineIDString(elementToFocus.lineID);
            const indexToFocus = focusOn.findIndex((elementF) => elementF.substring(1, elementF.indexOf('L')) === transactionNumber.toString());
            if (indexToFocus !== -1) {
              const elementsToRemove = focusOn.slice(0, indexToFocus);
              if (focusOn.length > 1) {
                let newState = goBackToDialog(lineIDKey, this._number, elementsToRemove);
                store.setState(newState);
              }
            }
          }

          if (elementsRemove.length > 1) {
            elementsRemove.forEach((element) => {
              const lineIDKey = generateLineIDString(element.lineID);
              removeFromWindowStack(lineIDKey);
            });
          } else removeFromWindowStack(this.transactionLineLabel());
        });
      },
    };
  }

  component() {
    return Window;
  }
}
