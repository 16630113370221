import React, { useState } from 'react';
import { store } from '../../../store';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFilterField, getFilterFieldValue } from './filterFields';
import { getServiciosWebFilterResult } from './filterServiciosWeb';
import { getCanales, getSucursalDescriptions } from '../../../api/bitacora';
import { getHelpWindow, getHelpButton, getColumnsBitacoraSucursal, getColumnsCanal } from './helpOperations';
import DateSelector from '../DateSelector';
import { exportServiciosWeb } from './exportServiciosWeb';
import { css, StyleSheet } from 'aphrodite';
import ModalMessage from '../../ModalMessage';
import { createNullDate } from '../../../../UI-kit/helpers/dateHelper';

const ConsultaServiciosWeb = (props) => {
  const bitacoraApp = store.getState().bitacoraApp;
  let { filterListID } = props;
  const [helpColumns, setHelpColumns] = useState([]);
  const [helpData, setHelpData] = useState([]);
  const [title, setTitle] = useState([]);
  const [disabledButtonPrev, setDisabledButtonPrev] = useState(bitacoraApp[filterListID] ? bitacoraApp[filterListID].buttonPrevState : true);
  const [disabledButtonNext, setDisabledButtonNext] = useState(bitacoraApp[filterListID] ? bitacoraApp[filterListID].buttonNextState : true);
  const [beginData, setBeginData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const intl = useIntl();

  const filterFields = {
    usuario: null,
    canal: {
      getData: getCanales,
      getColumns: getColumnsCanal,
      title: intl.formatMessage({
        id: 'Tablas.titulo_channel',
        defaultMessage: 'Canal',
      }),
    },
    sucursal: {
      getData: getSucursalDescriptions,
      getColumns: getColumnsBitacoraSucursal,
      title: intl.formatMessage({
        id: 'Tablas.bitacora_sucursal',
        defaultMessage: 'Sucursal',
      }),
    },
    operacion: null,
  };

  const generateFilterField = (id) => {
    let helpButton = null;
    if (filterFields[id]) {
      helpButton = getHelpButton(id, filterFields[id], setHelpColumns, setHelpData, setLoading, setTitle, helpData ? helpData.length : 0);
    }

    const fieldElement = getFilterField(id, updateFilterInfo, intl, helpButton, filterListID);

    return fieldElement;
  };

  const updateFilterInfo = (e, first, max, nullDates) => {
    let fechaHoraDesde, fechaHoraHasta;
    if (nullDates) {
      let fromTime = createNullDate();
      let toTime = createNullDate();
      fechaHoraDesde = fromTime;
      fechaHoraHasta = toTime;
    } else {
      if (store.getState().bitacoraApp[filterListID] && store.getState().bitacoraApp[filterListID].filterParams) {
        fechaHoraDesde = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraDesde;
        fechaHoraHasta = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraHasta;
      }
    }
    let filterParams = {
      fechaHoraDesde,
      fechaHoraHasta,
      firstResult: first,
      maxResults: max,
      empresa: undefined,
      sucursalEmpresa: getFilterFieldValue('sucursal', e),
      usuario: getFilterFieldValue('usuario', e),
      canal: getFilterFieldValue('canal', e),
      sucursal: getFilterFieldValue('sucursal', e),
      operacion: getFilterFieldValue('operacion', e),
      persistenceUnit: 'Local',
    };
    if (store.getState().bitacoraApp[filterListID]) {
      filterParams = { ...store.getState().bitacoraApp[filterListID].filterParams, ...filterParams };
    }
    let filterData = {};
    filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
    store.setState({
      bitacoraApp: {
        ...store.getState().bitacoraApp,
        ...filterData,
      },
    });
    closeHelp();
  };

  const setFilterFields = () => {
    document.getElementById('usuario').value = '';
    document.getElementById('canal').value = '';
    document.getElementById('sucursal').value = '';
    document.getElementById('operacion').value = '';
  };

  const closeHelp = () => {
    setHelpColumns([]);
    setHelpData([]);
    setTitle('');
  };

  const helpAcceptHandler = (value, control) => {
    document.getElementById(control).value = value;
    let filterData = {};
    let controlEntry = {};
    controlEntry[control] = value;
    filterData[filterListID] = {
      ...store.getState().bitacoraApp[filterListID],
      filterParams: { ...store.getState().bitacoraApp[filterListID].filterParams, ...controlEntry },
    };
    store.setState({
      bitacoraApp: {
        ...store.getState().bitacoraApp,
        ...filterData,
      },
    });
    closeHelp();
  };

  let fields = [];
  for (let field of Object.keys(filterFields)) {
    fields.push(generateFilterField(field));
  }

  const styles = StyleSheet.create({
    topazDisplay: {
      pointerEvents: loading ? 'none' : 'auto',
      cursor: loading ? 'wait' : 'auto',
    },
    overlay: {
      width: '100%',
      height: '100%',
      minHeight: '100%',
      zIndex: 100,
      position: 'absolute',
      top: 0,
      left: 0,
      cursor: 'wait',
    },
  });

  const handleBack = (e) => {
    let maxResults = store.getState().bitacoraApp[filterListID].filterParams.maxResults;
    let diff = beginData - maxResults;
    if (diff < 0) {
      diff = 0;
    }
    setBeginData(diff);
    updateFilterInfo(e, diff, 201);
    getServiciosWebFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
    );
  };

  const handleFilter = (e) => {
    let fechaDesde = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraDesde;
    if (fechaDesde === undefined) {
      fechaDesde = createNullDate();
    }

    let fechaHasta = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraHasta;
    if (fechaHasta === undefined) {
      fechaHasta = createNullDate();
    }
    if (fechaHasta < fechaDesde) {
      setShowModal(true);
    }

    setLoading(true);
    setBeginData(0);
    updateFilterInfo(e, 0, 201);
    getServiciosWebFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
      setLoading,
    );
  };

  const handleCleanFilters = (e) => {
    setFilterFields();
    updateFilterInfo(e, 0, 201, true);
    getServiciosWebFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
    );
  };

  const handleNext = (e) => {
    setBeginData(beginData + 201);
    updateFilterInfo(e, beginData + 201, 201);
    getServiciosWebFilterResult(
      store.getState().bitacoraApp[filterListID].filterParams,
      filterListID,
      intl,
      setDisabledButtonPrev,
      setDisabledButtonNext,
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
      <div className={css(loading && styles.overlay)}></div>
      <DateSelector filterListID={filterListID} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        {fields}
      </div>
      {getHelpWindow(helpData, helpColumns, helpAcceptHandler, closeHelp, title)}
      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        {showModal && (
          <ModalMessage
            message={
              <FormattedMessage
                id="message_desde_no_mayor_que_hasta"
                defaultMessage={`El valor de la fecha 'Desde' no puede ser mayor que 'Hasta'`}
              />
            }
            handleAccept={async () => {
              setShowModal(false);
            }}
            acceptTitle={'Aceptar'}
            draggable={true}
          />
        )}
        <button
          className={
            disabledButtonPrev
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          disabled={disabledButtonPrev}
          onClick={(e) => handleBack(e)}
        >
          {'<<'}
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={(e) => handleFilter(e)}
        >
          <FormattedMessage id="filtrar" defaultMessage={`Filtrar`} />
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={(e) => {
            const state = store.getState();
            if (!state.bitacoraApp[filterListID].resultColumns || state.bitacoraApp[filterListID].resultColumns.length === 0) {
              props.showModal(<FormattedMessage id="noData" defaultMessage={`No hay datos!`} />);
            } else {
              updateFilterInfo(e, 0, 201);
              exportServiciosWeb(
                store.getState().bitacoraApp[filterListID].filterParams,
                store.getState().bitacoraApp[filterListID].resultColumns,
                intl,
              );
            }
          }}
        >
          <FormattedMessage id="exportarCSV" defaultMessage={`Exportar CSV`} />
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={(e) => handleCleanFilters(e)}
        >
          <FormattedMessage id="borrarFiltro" defaultMessage={`Limpiar Filtros`} />
        </button>
        <button
          className={
            disabledButtonNext
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          disabled={disabledButtonNext}
          onClick={(e) => handleNext(e)}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default ConsultaServiciosWeb;
