import React, { useEffect, useState } from 'react';
import { EMPTY_STRING, GROUP_HELPER } from '../../../../models/constants';
import { RestrictionObject } from '../../../../models/restrictionObject';

import './FormulaModal.scss';
import { FormulaModalView } from './FormulaModalView';

export interface FormulaModalViewModelProps {
  onModalChange: (isOpen: boolean) => void;
  onRestrictionsChangedAdd: (restriction: RestrictionObject[]) => void;
  restrictionsChanged: { [id: string]: RestrictionObject };
  selectedRestriction?: RestrictionObject[];
  onHelperChange: (helperOpen: string) => void;
  groupHelper: string;
}

const DESCRIPTION_INPUT: string = 'description';
const ALLOWER_INPUT: string = 'allower';
const FORMULA_INPUT: string = 'formula';

export function FormulaModalViewModel({
  groupHelper,
  onModalChange,
  onRestrictionsChangedAdd,
  restrictionsChanged,
  selectedRestriction,
  onHelperChange,
}: FormulaModalViewModelProps): JSX.Element | null {

  const initialRestrictions = selectedRestriction || [];
  const [formula, setFormula] = useState<string[]>(initialRestrictions.map(r => r.formula || EMPTY_STRING));
  const [formulaAnt, setFormulaAnt] = useState<string[]>(initialRestrictions.map(restriction => restriction.formula || EMPTY_STRING));
  const [description, setDescription] = useState<string[]>(initialRestrictions.map(restriction => restriction.description || EMPTY_STRING));
  const [allower, setAllower] = useState<string[]>(initialRestrictions.map(restriction => restriction.allower || EMPTY_STRING));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function onCancelClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(false);
  }

  function onAcceptClick(event: React.MouseEvent): void {
    event.stopPropagation();
    setIsLoading(true);
    if (selectedRestriction && selectedRestriction.length !== 0) {
      selectedRestriction.forEach((r, index) => {
        const currentRestriction: RestrictionObject = restrictionsChanged[r.id] || r;
        const currentFormula = formula[index];
        const currentFormulaAnt = formulaAnt[index];
        const currentDescription = description[index];
        const currentAllower = allower[index];
  
        const updatedRestriction: RestrictionObject = {
          ...currentRestriction,
          formula: currentFormula,
          description: currentDescription,
          allower: currentAllower,
          formulaAnt: currentFormulaAnt,
        };
  
        onRestrictionsChangedAdd([updatedRestriction]);
      });
    }
    setIsLoading(false);
    onModalChange(false);
  }

  function onChange(event: { target: HTMLInputElement }): void {
    if (event.target.name === DESCRIPTION_INPUT) {
      setDescription([event.target.value]);
    } else if (event.target.name === ALLOWER_INPUT) {
      setAllower([event.target.value]);
    } else if (event.target.name === FORMULA_INPUT) {
      setFormula([event.target.value]);
    }
  }

  function onGroupHelperClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onHelperChange(GROUP_HELPER);
  }

  useEffect(() => {
    setAllower([groupHelper]);
  }, [groupHelper]);

  return (
    <FormulaModalView
      onCancel={onCancelClick}
      onAccept={onAcceptClick}
      onChange={onChange}
      isLoading={isLoading}
      formula={formula}
      description={description}
      allower={allower}
      onGroupHelperClick={onGroupHelperClick}
    />
  );
}
