import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { storeActions } from '../../store';
import { FormattedMessage } from 'react-intl';

import { increaseSecondLoginError, verifyFingerLogin } from '../../api/session';

import { startCapture, reset, getScanned } from '../../api/fingerprintWS';

const Button = ({ title, click, className, disabled = false }) => {
  return (
    <button
      className={`bg-primary hover:bg-primary-dark focus:bg-primary text-white rounded shadow m-2 py-2 px-4 text-sm ${disabled && 'bg-grey'}`}
      onClick={click}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export class LoginFingerPrint extends Component {
  constructor(props) {
    super(props);
    const { action } = this.props;
    this.state = {
      showWindow: true,
      lastScannedFingerprint: null,
      lastScannedANSI: null,
      lastScannedBitmap: null,
      ansiArray: [],
      valueToSend: 0,
      timeout: null,
      scanning: false,
      scannedAll: false,
      action: action,
      validationData: null,
      isLooping: false,
    };
    this.getScannedVerifyLoop = this.getScannedVerifyLoop.bind(this);
  }

  async componentDidMount() {
    this._ismounted = true;
    this.handleResume();
  }

  componentWillUnmount() {
    this._ismounted = false;
    reset();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
  }

  async loginWithFinger(base64Image) {
    const { tipoLoged, user, loginDialog } = this.props;
    this._ismounted = false;
    await reset();
    this.setState({ scanning: false });
    await loginDialog(user, '', tipoLoged === 1 || !!base64Image, base64Image);
  }

  async increaseLoginError() {
    const { user, setSecondLogin, handleLoginNumber } = this.props;
    await increaseSecondLoginError(user);
    let loginNumber = await verifyFingerLogin(user);
    if (loginNumber === 1) {
      setSecondLogin(true);
    } else {
      handleLoginNumber(loginNumber);
      setSecondLogin(false);
      this._ismounted = false;
      await reset();
    }
  }

  async getScannedVerifyLoop() {
    if (this.state.isLooping) {
      return;
    }

    this.setState({ isLooping: true });

    try {
      const data = await getScanned();
      if (!this._ismounted) {
        this.setState({ isLooping: false });
        return;
      }

      let status = data.status !== 204 ? '1' : '';
      if (status !== '') {
        let base64Image = 'data:image/png;base64,' + data.data.bmpImage;
        this.setState({ lastScannedFingerprint: base64Image });
        this.setState({ verify: status });
        if (status === '1') {
          setTimeout(() => this.loginWithFinger(data.data.fingerData), 2000);
        } else {
          this.setState({
            timeout: setTimeout(() => this.getScannedVerifyLoop(), 1000),
          });
        }
      } else {
        this.setState({
          timeout: setTimeout(() => this.getScannedVerifyLoop(), 1000),
        });
      }
    } catch (error) {
      await reset();
      this.setState({ scanning: false });
    } finally {
      this.setState({ isLooping: false });
    }
  }

  async handleResume() {
    await startCapture();
    this._ismounted = true;
    this.resetReadData();
    this.props.handleCancel();
    this.setState({
      scanning: true,
      lastScannedANSI: null,
      lastScannedBitmap: null,
      lastScannedFingerprint: null,
      isLooping: false, // Reinicia el estado del loop
    });
    this.getScannedVerifyLoop();
  }

  resetReadData() {
    this.setState({
      lastScannedFingerprint: null,
      lastScannedBitmap: null,
      scanning: false,
    });
  }

  async handleCancel() {
    const { setSecondLogin } = this.props;
    setSecondLogin(false);
    this._ismounted = false;
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
    this.setState({
      showWindow: false,
      fingerImage: null,
      isLooping: false, // Asegúrate de que el loop se detenga
    });
    this.resetReadData();
    await reset();
    this.props.handleCancel();
    this.handleResume();
  }

  render() {
    const { intl } = this.props;
    let fingerImage = null;
    if (this.state.lastScannedFingerprint) {
      fingerImage = <img style={{ maxWidth: '200px', maxHeight: '100px' }} src={`${this.state.lastScannedFingerprint}`} />;
    }

    return (
      <>
        <div
          className="flex justify-center mt-2"
          style={{
            width: '200px',
            height: '100px',
          }}
        >
          {fingerImage || (
            <div
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                fontSize: 12,
              }}
              className="text-center"
            >
              {intl.formatMessage({ id: 'enterFingerprint' })}
            </div>
          )}
        </div>
        {this.props.loginMessage ? (
          <div className="mb-2 font-light text-primary text-center">
            <FormattedMessage id="validationFingerPrintPerson" defaultMessage={this.props.loginMessage} />
          </div>
        ) : (
          <div></div>
        )}

        <div className="flex justify-center fixed-bottom ">
          {!fingerImage ? (
            <Button
              title={<FormattedMessage id="restartCapture" defaultMessage={'Reiniciar captura'} />}
              click={() => this.handleCancel()}
              disabled={!this.state.scanning}
            />
          ) : (
            <Button
              title={<FormattedMessage id="captureAgain" defaultMessage={'Capturar nuevamente'} />}
              click={() => this.handleResume()}
              disabled={this.state.scanning}
            />
          )}
        </div>
      </>
    );
  }
}

export default connect(null, storeActions)(LoginFingerPrint);
