const INTEGER_TYPE = 2;
const NUMBER_TYPE = 3;
const TEXT_TYPE = 4;
const DATE_TYPE = 5;

const createDefaultSuperiorValue = (field) => {
  switch (field.type) {
    case INTEGER_TYPE:
      return parseInt('9'.repeat(field.length), 10);
    case NUMBER_TYPE:
      return parseInt('9'.repeat(field.length), 10);
    case DATE_TYPE:
      return '9999-12-31';
    case TEXT_TYPE:
      return 'z'.repeat(field.length);
    default:
      return '';
  }
};

const indexOfSeparator = (data, separators, from = 0) => {
  let indexs = separators.map((separator) => parseInt(data.indexOf(separator, from), 10));
  indexs = indexs.filter((index) => index > -1).sort((a, b) => a - b);
  if (indexs.length === 0) return -1;
  return indexs[0];
};

const fieldParameters = (originalField, inferiorValue, superiorValue = null) => {
  if (superiorValue === null || superiorValue === undefined) superiorValue = createDefaultSuperiorValue(originalField);
  else if (originalField.type === NUMBER_TYPE) superiorValue = parseInt(superiorValue, 10);
  if (originalField.type === NUMBER_TYPE) inferiorValue = parseInt(inferiorValue, 10);
  return {
    ...originalField,
    inferiorValue,
    superiorValue,
  };
};

const parseFields = (itemData, dataReport) => {
  let dataReportFields = dataReport.fields;
  let isJasper = dataReport.type === 'JASPER';
  let result = [];
  let pos = itemData.indexOf('/C');

  while (pos !== -1) {
    if (itemData.split(' ').indexOf('/CO') === -1) {
      let from = pos + 2;
      let to = itemData.indexOf('|', pos);

      let paramId = itemData.substr(from, to - from);

      let fieldNumber = !parseInt(paramId, 10) ? 0 : parseInt(paramId, 10);

      from = to + 1;

      to = indexOfSeparator(itemData, ['/', '|'], from);

      to = to !== -1 ? to : itemData.length;
      let fieldValue = itemData.substr(from, to - from).trim();
      let originalField;
      if (isJasper) {
        originalField = dataReportFields.find((f) => f.jasperName.toUpperCase() === paramId.trim().toUpperCase());
      } else {
        originalField = dataReportFields.find((f) => f.number === fieldNumber);
      }
      originalField && result.push(fieldParameters(originalField, fieldValue));
    }
    pos = itemData.indexOf('/C', pos + 1);
  }

  pos = itemData.indexOf('/R');
  while (pos !== -1) {
    let from = pos + 2;
    let to = itemData.indexOf('|', pos);

    let paramId = itemData.substr(from, to - from);

    let fieldNumber = !parseInt(paramId, 10) ? 0 : parseInt(paramId, 10);

    from = to + 1;
    to = itemData.indexOf('|', from);
    let inferiorValue = itemData.substr(from, to - from).trim();

    from = to + 1;
    to = indexOfSeparator(itemData, ['/', '|'], from);
    to = to !== -1 ? to : itemData.length;
    let superiorValue = itemData.substr(from, to - from).trim();

    let originalField = dataReportFields.find((f) => f.number === fieldNumber);
    result.push(fieldParameters(originalField, inferiorValue, superiorValue));

    pos = itemData.indexOf('/R', pos + 1);
  }
  if (isJasper) {
    for (let i = 0; i < dataReportFields.length; i++) {
      let exist = result.find((f) => f.jasperName === dataReportFields[i].jasperName);
      if (exist === undefined) {
        let param = fieldParameters(dataReportFields[i], '', undefined);
        result.push(param);
      }
    }
  }
  return result;
};

const parseForce = (itemData) => itemData.includes('/F');

const parseDestination = () => 'UNSPECIFIED';

export const bodyBuildReports = (itemData, dataReport) => {
  let fields = parseFields(itemData, dataReport);
  let force = dataReport.force ? dataReport.force : parseForce(itemData);
  let destination = parseDestination(itemData);

  let bodyReport = {
    ...dataReport,
    force,
    destination,
    fields,
  };

  return bodyReport;
};
