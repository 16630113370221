/*******************************
 * Colors:
 */

const white = '#FFFFFF';
const black = '#161617';
const grey = {
  light4: '#FCFCFC',
  light3: '#F4F5F7',
  light2: '#EBECF0',
  light: '#DCE0E6',
  grey: '#BCC1CC',
  dark: '#6B7280',
  dark2: '#3D4852',
  dark3: '#2D363D',
  dark4: '#050F1A',
};

const blue = {
  dark3: '#00091A',
  dark2: '#051533',
  dark: '#0F2959',
  blue: '#2b53a0',
  light: '',
  light2: '',
  ligh3: '',
};

const red = {
  light2: '#FF4D4D',
  light: '#F22424',
  red: '#B31212',
  dark: '#C40A0A',
  dark2: '#AB0000',
  dark3: '#850000',
  darkMedium: '#AB0000',
  darkest: '#850000',
};

const green = {
  light: '#72CCB0',
  green: '#52B394',
  dark: '#37997A',
};

/*******************************
 * Custom palettes:
 */

const topaz = {
  blue: {
    blue: '#2b53a0',
    light: '#325CA8',
    dark: '#214994',
  },
  turquoise: {
    turquoise: '#0A8FCC',
    light: '#169BD9',
    dark: '#0083BF',
  },
  green: {
    light: '#A4CF23',
    green: '#99C221',
    dark: '#85A81D',
  },
};

/******************************
 * Themes:
 */

const themeLight = {
  color: {
    white,
    black,
    grey,
  },
  primary: {
    primary: topaz.blue.blue,
    dark: topaz.blue.dark,
    light: topaz.blue.light,
  },
  secondary: {
    secondary: topaz.turquoise.turquoise,
    dark: topaz.turquoise.dark,
    light: topaz.turquoise.light,
  },
  background: {
    dark2: grey.light,
    dark: grey.light2,
    background: grey.light3,
    light: grey.light4,
    light2: white,
  },
  border: {
    dark: grey.grey,
    border: grey.light,
    light: grey.light2,
    light2: grey.light3,
  },
  text: {
    dark: grey.dark3,
    text: grey.dark2,
    light: grey.dark,
  },
  input: {
    color: grey.dark2,
  },
  option: {
    option: grey.dark,
    selected: topaz.blue.blue,
    hover: topaz.blue.blue,
    active: topaz.blue.blue,
  },
  icon: {
    icon: grey.dark,
    hover: topaz.blue.blue,
  },
  error: {
    dark: red.dark,
    error: red.red,
    light: red.light,
  },
  success: {
    dark: green.dark,
    success: green.green,
    light: green.light,
  },
  disabled: {
    disabled: grey.light2,
  },
  shadow: {
    shadow: grey.light2,
  },
  commonButton: {
    default: {
      background: grey.light3,
      color: grey.dark2,
    },
    hover: {
      background: topaz.blue.blue,
      color: white,
    },
    focus: {
      background: topaz.blue.blue,
      color: white,
    },
    active: {
      background: topaz.blue.dark,
      color: white,
      border: topaz.blue.blue,
    },
  },
  addTabButton: {
    default: {
      color: grey.light,
    },
    hover: {
      color: topaz.blue.blue,
    },
  },
  closeTabButton: {
    default: {
      color: grey.light3,
    },
    tabHover: {
      color: grey.light,
    },
    tabActive: {
      color: grey.light,
    },
    hover: {
      color: red.dark,
    },
  },
  modal: {
    default: {
      background: 'rgb(250, 250, 250)',
    },
    active: {
      background: 'rgb(255, 255, 255)',
    },
  },
  collapsible: {
    default: {
      color: grey.dark,
    },
    hover: {
      color: topaz.blue.blue,
    },
  },
};

const themeDark = {
  color: {
    white,
    black,
    grey,
  },
  primary: {
    primary: topaz.turquoise.turquoise,
    dark: topaz.turquoise.dark,
    light: topaz.turquoise.light,
  },
  secondary: {
    secondary: topaz.green.green,
    dark: topaz.green.dark,
    light: topaz.green.light,
  },
  background: {
    dark2: blue.light,
    dark: blue.blue,
    background: blue.dark,
    light: blue.dark2,
    light2: blue.dark3,
  },
  border: {
    dark: blue.blue,
    border: blue.blue,
    light: blue.dark,
    light2: blue.dark2,
  },
  text: {
    text: grey.light,
    dark: grey.light4,
    light: grey.dark,
  },
  input: {
    color: grey.light4,
  },
  option: {
    option: grey.grey,
    selected: white,
    hover: white,
    active: white,
  },
  icon: {
    icon: grey.light,
    hover: white,
  },
  error: {
    dark: red.dark2,
    error: red.dark,
    light: red.red,
  },
  success: {
    dark: green.dark,
    success: green.green,
    light: green.light,
  },
  disabled: {
    disabled: grey.dark2,
  },
  shadow: {
    shadow: blue.dark3,
  },
  commonButton: {
    default: {
      background: grey.light,
      color: grey.dark2,
    },
    hover: {
      background: topaz.blue.light,
      color: white,
    },
    focus: {
      background: topaz.blue.blue,
      color: white,
    },
    active: {
      background: topaz.blue.dark,
      color: white,
    },
  },
  modal: {
    default: {
      background: 'rgb(250, 250, 250)',
    },
    active: {
      background: 'rgb(255, 255, 255)',
    },
  },
};

const theme = (mode) => (mode === 'DARK_MODE' ? themeDark : themeLight);

export default theme;
