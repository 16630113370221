import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userIsLogged } from '../src/helpers/login';
import { PATHNAME } from '../src/config';

const SecureRoute = (props) => {
  const { path, component, ...rest } = props;

  if (!userIsLogged()) {
    return <Redirect to={PATHNAME} />;
  }

  return <Route {...rest} path={path} component={component} />;
};

export default SecureRoute;
