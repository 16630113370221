import React, { Component, useRef } from 'react';
import Picker from './Picker';
import { connect } from 'unistore/react';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';
import { storeActions } from '../../src/store';
import { injectIntl, FormattedMessage } from 'react-intl';
import FocusTrap from 'react-focus-trap';
import { addLineTest, addLineEventTest, addValueTest } from '../../src/components/Testing/HandleLineTest';

import { ABOVE_ALL } from '../constants/zIndexValues';

import { getCurrentTabInformation } from '../../src/selectors';

const Button = ({ id, title, click, className, keydown }) => {
  const button = useRef(null);
  return (
    <button
      id={id}
      className={`${className} bg-primary text-white py-2 px-6 rounded shadow text-xs focus:outlins-nonw`}
      onClick={click}
      ref={button}
      onKeyDown={(e) => {
        if (e.ctrlKey && e.keyCode === 76) {
          e.preventDefault();
        }
        if (keydown) {
          keydown(e);
        }
      }}
      onBlur={(e) => {
        if (e.relatedTarget === null || e.relatedTarget.classList.contains('focus-trap')) {
          button.current.focus();
        }
      }}
    >
      {title}
    </button>
  );
};

const Input = ({ id, title, onChange, defaultValue, type, autofocus, closeWindow, enterKeyDown }) => {
  const input = useRef(null);
  let defaultValueToSet = defaultValue;
  if (type === 2) {
    var regex = /[0-9]|\./;
    if (!regex.test(defaultValue)) {
      defaultValueToSet = '';
    }
  }

  return (
    <div className="p-2 flex">
      <label
        className="text-primary w-1/3 p-1"
        style={{
          fontSize: 16,
          paddingLeft: 10,
        }}
      >
        {title}
      </label>
      <input
        id={id}
        autoFocus={autofocus}
        className="w-2/3 appearance-none border border-primary border-primary rounded shadow text-text-base  px-1 py-1"
        onChange={(e) => {
          onChange(e.target.value);
        }}
        ref={input}
        onBlur={(e) => {
          if (e.relatedTarget === null || e.relatedTarget.classList.contains('focus-trap')) {
            input.current.focus();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            addLineEventTest(e);
            enterKeyDown(e.target.value);
          }
          if (e.key === 'Escape') {
            addLineEventTest(e);
            closeWindow();
          }
          if (e.ctrlKey && e.keyCode === 76) {
            e.preventDefault();
          }
          if (type === 2) {
            var regex = /[0-9]|\./;
            if (
              !regex.test(e.key) &&
              e.key !== 'Backspace' &&
              e.key !== 'Enter' &&
              e.key !== 'Tab' &&
              e.key !== 'ArrowLeft' &&
              e.key !== 'ArrowRight' &&
              e.key !== 'ArrowUp' &&
              e.key !== 'ArrowDown' &&
              !e.ctrlKey
            ) {
              e.preventDefault();
            }
          }
        }}
        defaultValue={defaultValueToSet}
        type="text"
      />
    </div>
  );
};

const NUMERIC_TYPE = 2;
const CURRENCY_TYPE = 3;
const EQUAL_OPERATOR = '2';
const STARTS_OPERATOR = '7';

export class FilterWindow extends Component {
  constructor(props) {
    super(props);

    const { defaultColumn } = this.props;

    this.state = {
      field: defaultColumn || '',
      operator: '2',
      value: '',
      operators: [],
    };
    this.setFieldValue = this.setFieldValue.bind(this);
    this.setOperatorValue = this.setOperatorValue.bind(this);
    this.setInputValue = this.setInputValue.bind(this);
    this.setOperations = this.setOperations.bind(this);
  }

  componentDidMount() {
    let { defaultColumn, fields } = this.props;
    if (defaultColumn) {
      let type = this.getType(defaultColumn);
      this.setOperations(type);
    } else if (fields[0]) {
      this.setOperations(fields[0].type);
    }
  }

  getType(accessor) {
    let { fields } = this.props;
    let type = 1;
    fields.forEach((field) => {
      if (field.accessor === accessor) {
        type = field.type;
      }
    });
    return type;
  }

  setOperations(type) {
    const { intl } = this.props;
    let operators = [
      {
        label: intl.formatMessage({
          id: 'startsWith',
          defaultMessage: 'Comienza por',
        }),
        value: 7,
      },
      {
        label: intl.formatMessage({
          id: 'contains',
          defaultMessage: 'Contiene a',
        }),
        value: 8,
      },
      {
        label: intl.formatMessage({
          id: 'doesNotContain',
          defaultMessage: 'No contiene a',
        }),
        value: 9,
      },
      {
        label: intl.formatMessage({
          id: 'equalTo',
          defaultMessage: 'Igual',
        }),
        value: 2,
      },
      {
        label: intl.formatMessage({
          id: 'differentFrom',
          defaultMessage: 'Distinto',
        }),
        value: 1,
      },
      {
        label: intl.formatMessage({
          id: 'largerThan',
          defaultMessage: 'Mayor',
        }),
        value: 3,
      },
      {
        label: intl.formatMessage({
          id: 'largerOrEqualThan',
          defaultMessage: 'Mayor o igual',
        }),
        value: 4,
      },
      {
        label: intl.formatMessage({
          id: 'lessThan',
          defaultMessage: 'Menor',
        }),
        value: 5,
      },
      {
        label: intl.formatMessage({
          id: 'lessOrEqualThan',
          defaultMessage: 'Menor o igual',
        }),
        value: 6,
      },
    ];
    if (type === NUMERIC_TYPE || type === CURRENCY_TYPE) {
      operators = operators.slice(3, 9);
    }
    this.setState({
      operators,
      operator: type === NUMERIC_TYPE || type === CURRENCY_TYPE ? EQUAL_OPERATOR : STARTS_OPERATOR,
    });
  }

  setFieldValue(value) {
    this.setOperations(this.getType(value));
    this.setState({
      field: value,
    });
  }

  setOperatorValue(value) {
    this.setState({
      operator: value,
    });
  }

  setInputValue(value) {
    this.setState({
      value: value,
    });
  }

  formatOptions(options) {
    let newOptions = [];
    options.forEach((option) => {
      let optionObj = { label: option.Header, value: option.accessor };
      newOptions.push(optionObj);
    });
    return newOptions;
  }

  render() {
    let { showWindow, fields, acceptHandler, transactionLineLabel, focusOn, defaultColumn, fromKeyboard, defaultValue } = this.props;

    const cancelMessage = <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />;
    const acceptMessage = <FormattedMessage id="accept" defaultMessage={`Aceptar`} />;
    const valueMessage = <FormattedMessage id="value" defaultMessage={`Valor`} />;
    const fieldMessage = <FormattedMessage id="field" defaultMessage={`Campo`} />;
    const operatorMessage = <FormattedMessage id="operator" defaultMessage={`Operador`} />;
    let hasFocus = focusOn && focusOn[0] === transactionLineLabel;

    let options = this.formatOptions(fields);

    const buttonStyles = {
      bottom: 0,
      padding: '10px',
      width: '100%',
    };

    if (!showWindow) {
      return null;
    }
    return (
      <div
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <FocusTrap>
          <div
            className={`absolute w-screen-1/4 h-screen-1/2 bg-smoke-light ${showWindow ? '' : 'hidden'}`}
            style={{
              zIndex: showWindow && ABOVE_ALL,
              left: '-8%',
              top: '-40%',
            }}
          />
          <Draggable bounds="body" handle=".handleDraggFilter">
            <div
              className={`border border border-primary bg-grey-lighter-lighter rounded shadow ${
                hasFocus ? 'border-primary' : 'border-transparent'
              } absolute ${showWindow ? '' : 'hidden'}`}
              style={{
                top: 40,
                left: 70,
                width: '320px',
                height: '260px',
                zIndex: ABOVE_ALL,
              }}
            >
              <div className="w-full flex justify-end">
                <IcoMoon icon="enlarge" width="25" className="handleDraggFilter cursor-move m-2" />
              </div>
              <div className="flex bg-title-grey">
                <div className="flex-grow bg-title-grey text-center pb-2 opacity-75" style={{ fontSize: 15 }}>
                  <FormattedMessage id="filterTitle" defaultMessage={`Filtrado de registros`} />
                </div>
              </div>
              <div className="p-4">
                <Picker
                  id={'filterField_' + transactionLineLabel}
                  autofocus={!fromKeyboard}
                  title={fieldMessage}
                  options={options}
                  onChange={(value) => this.setFieldValue(value)}
                  selectedValue={defaultColumn}
                  readOnly={fromKeyboard}
                  closeWindow={() => {
                    let { cancelHandler } = this.props;
                    this.setState({
                      filterOptions: {
                        field: '',
                        operator: 7,
                        value: '',
                      },
                    });
                    cancelHandler();
                  }}
                  keydown={(e) => {
                    if (e.key === 'Tab' && e.shiftKey) {
                      addLineEventTest(e);
                      const filterButtonCancelElement = document.getElementById('filterButtonCancel_' + transactionLineLabel);
                      if (filterButtonCancelElement) {
                        e.preventDefault();
                        filterButtonCancelElement.focus();
                      }
                    }
                  }}
                />
                <Picker
                  id={'filterOperator_' + transactionLineLabel}
                  title={operatorMessage}
                  options={this.state.operators}
                  onChange={(value) => this.setOperatorValue(value)}
                  closeWindow={() => {
                    let { cancelHandler } = this.props;
                    this.setState({
                      filterOptions: {
                        field: '',
                        operator: 7,
                        value: '',
                      },
                    });
                    cancelHandler();
                  }}
                />
                <Input
                  id={'filterInput_' + transactionLineLabel}
                  title={valueMessage}
                  onChange={(value) => this.setInputValue(value)}
                  defaultValue={defaultValue}
                  type={this.getType(this.state.field)}
                  autofocus={fromKeyboard}
                  closeWindow={() => {
                    let { cancelHandler } = this.props;
                    this.setState({
                      filterOptions: {
                        field: '',
                        operator: 7,
                        value: '',
                      },
                    });
                    cancelHandler();
                  }}
                  enterKeyDown={(valueIng) => {
                    let filterOptions = {
                      field: this.state.field,
                      operator: this.state.operator,
                      value: valueIng,
                      fromFilter: true,
                    };
                    acceptHandler(filterOptions);
                  }}
                />
              </div>
              <div className="absolute" style={buttonStyles}>
                <Button
                  id={'filterButtonAccept_' + transactionLineLabel}
                  className="float-left text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary"
                  title={acceptMessage}
                  click={(e) => {
                    addValueTest('SELECTVALUE', 'filterField_' + transactionLineLabel, this.state.field);
                    addValueTest('SELECTVALUE', 'filterOperator_' + transactionLineLabel, this.state.operator);
                    addValueTest('SENDTEXT', 'filterInput_' + transactionLineLabel, this.state.value);
                    addLineTest('CLICK', e);
                    let filterOptions = {
                      field: this.state.field,
                      operator: this.state.operator,
                      value: this.state.value,
                      fromFilter: true,
                    };
                    acceptHandler(filterOptions);
                  }}
                />
                <Button
                  id={'filterButtonCancel_' + transactionLineLabel}
                  className="float-right text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary"
                  title={cancelMessage}
                  click={(e) => {
                    addLineTest('CLICK', e);
                    let { cancelHandler } = this.props;
                    this.setState({
                      filterOptions: {
                        field: '',
                        operator: 7,
                        value: '',
                      },
                    });
                    cancelHandler();
                  }}
                  keydown={(e) => {
                    if (e.key === 'Tab' && !e.shiftKey) {
                      addLineEventTest(e);
                      const filterFieldElement = document.getElementById('filterField_' + transactionLineLabel);
                      if (filterFieldElement) {
                        e.preventDefault();
                        filterFieldElement.focus();
                      }
                    }
                  }}
                />
              </div>
            </div>
          </Draggable>
        </FocusTrap>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { focusOn } = getCurrentTabInformation(state);

  return {
    focusOn,
  };
};

export default connect(mapStateToProps, storeActions)(injectIntl(FilterWindow));
