import { SelectedProcess } from '../models/types';
import { Parameter } from '../models/parameter';
import { Process } from '../models/process';
import { GroupDefinition } from '../models/groupDefinition';

export interface ProcessManagerMethods {
  getParameters: (selectedProcess: SelectedProcess) => Parameter[];
  areAllMandatoryParameters: (params: Parameter[]) => boolean;
  areValidParameters: (params: Parameter[]) => boolean;
}

export function useProcessManager(): ProcessManagerMethods {
  function getParameters(selectedProcess: SelectedProcess): Parameter[] {
    if (selectedProcess && (selectedProcess as GroupDefinition).groupParameters) {
      return (selectedProcess as GroupDefinition).groupParameters.parametersList;
    } else if (selectedProcess && (selectedProcess as Process).parameters) {
      const { entry } = (selectedProcess as Process).parameters;
      return entry.map((e) => e.value);
    }

    return [];
  }

  function areAllMandatoryParameters(params: Parameter[]): boolean {
    let valid: boolean = true;
    let i: number = 0;

    while (i < params.length && valid) {
      if (params[i].mandatory) {
        valid = params[i].paramValue !== '';
      }
      i++;
    }

    return valid;
  }

  function areValidParameters(params: Parameter[]): boolean {
    let valid: boolean = true;
    let i: number = 0;

    while (i < params.length && valid) {
      if (params[i].paramType === 'integer' && params[i].mandatory) {
        valid = params[i].paramValue >= '0';
      }
      if (params[i].paramType === 'date') {
        if (params[i].mandatory) {
          valid = params[i].paramValue !== '';
        }
        if (valid) {
          let el = document.getElementById(`parameterInput-${params[i].paramName}`);
          if (el && el.validity.badInput) {
            valid = false;
          }
        }
      }
      i++;
    }

    return valid;
  }

  return { getParameters, areAllMandatoryParameters, areValidParameters };
}
