import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { injectIntl, FormattedMessage, FormattedPlural } from 'react-intl';
import { PulseLoader } from 'halogenium';

import { storeActions } from '../../src/store';
import { getCurrentTabInformation } from '../../src/selectors';
import Overlay from './Overlay';
import IcoMoon from 'react-icomoon';
import Draggable from 'react-draggable';
import { ABOVE_ALL } from '../constants/zIndexValues';
import {
  getRepresentatives,
  getTexts,
  getPowerTypes,
  getPersonSignatures,
  validateRepresentatives,
  validateRepresentativesByAmount,
  getTypeAmount,
  signaturesActionOK,
  signaturesActionCancel,
  getPerson,
} from '../../src/api/operationSignatures';
import SignatureContainer from './SignatureContainer';
import ModalMessage from '../../src/components/ModalMessage';
import { fileArrayToBase64 } from '../helpers/fileHelper';
import { dateFormatInput, dateFormatSignature } from '../../src/helpers/signaturesHelper';
import FocusTrap from 'react-focus-trap';
import { getSessionInfo } from '../../src/api/session';
import { formatDateFromServer } from '../helpers/dateHelper';
import { addValueTest } from '../../src/components/Testing/HandleLineTest';

const SIGNATURE = 'FIRMA';

const Button = ({ title, click, className, disabled = false, refToPass, id }) => {
  return (
    <button
      id={id}
      className={`${className} ${
        !disabled ? 'bg-grey focus:outline-none focus:bg-primary hover:bg-primary' : 'bg-smoke'
      } text-white rounded shadow m-2 py-2 px-4 text-sm`}
      onClick={(e) => {
        addValueTest('CLICK', id, '');
        click();
      }}
      disabled={disabled}
      ref={refToPass}
      onKeyDown={(e) => {
        if (refToPass !== undefined && e.key === 'Escape') {
          click();
        }
      }}
      onBlur={(e) => {
        if (e.relatedTarget && (e.relatedTarget.name !== 'cancelar' || e.relatedTarget.name !== 'aceptar')) {
          e.preventDefault();
          refToPass.current.focus();
        }
      }}
    >
      {title}
    </button>
  );
};

const Item = ({ title, value, color = 'default' }) => {
  return (
    <div>
      <span className="px-1 inline-block w-1/3 text-left text-xxs uppercase text-grey pt-1">{title}:</span>
      <div className={`inline-block ${color === 'danger' && 'text-red'}`}>{value}</div>
    </div>
  );
};

const FooterInfo = ({ footerData }) => {
  return (
    <div className="flex justify-between ml-3 mr-3">
      <span>
        <FormattedMessage id="formula" defaultMessage={`Fórmula`} />:
        {footerData.formValid ? (
          <FormattedMessage id="valid" defaultMessage={`Válido`} />
        ) : (
          <FormattedMessage id="noValid" defaultMessage={`No Válido`} />
        )}
      </span>
      {footerData.amountValid !== undefined && (
        <span>
          <FormattedMessage id="amount" defaultMessage={`Monto`} />:
          {footerData.amountValid ? (
            <FormattedMessage id="valid" defaultMessage={`Válido`} />
          ) : (
            <FormattedMessage id="noValid" defaultMessage={`No Válido`} />
          )}
        </span>
      )}
      {footerData.typeAmountMax !== undefined && (
        <span>
          <FormattedMessage id="typeMaxAmount" defaultMessage={`Tipo Monto Máximo`} />: {footerData.typeAmountMax}
        </span>
      )}
      {footerData.currencyOperation !== undefined && (
        <span>
          <FormattedMessage id="operationCurrency" defaultMessage={`Moneda Operación`} />: {footerData.currencyOperation}
        </span>
      )}
      {footerData.amountOperation !== undefined && (
        <span>
          <FormattedMessage id="operationAmount" defaultMessage={`Monto Operación`} />: {footerData.amountOperation}
        </span>
      )}
      {footerData.amountMax !== undefined && (
        <span>
          <FormattedMessage id="maxAmount" defaultMessage={`Monto Máximo`} />: {footerData.amountMax}
        </span>
      )}
    </div>
  );
};

export class SignatureValidationWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWindow: true,
      texts: [],
      info: {},
      allPersonImages: [],
      selectedIds: [],
      enableAccept: false,
      footerData: null,
      persons: [],
      showPreview: false,
      srcSignaturePreview: null,
      loading: false,
      allPersonsHasSignatures: true,
      processDate: null,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.validateSignatures = this.validateSignatures.bind(this);
    this.handleShowPreview = this.handleShowPreview.bind(this);
    this.cancelRef = React.createRef();
    this.acceptRef = React.createRef();
  }

  async componentDidMount() {
    let { data, pauseFocus } = this.props;
    pauseFocus();
    const { fechaProceso } = await getSessionInfo();
    this.setState({
      processDate: new Date(fechaProceso),
    });
    await this.fetchData(data);
    this.cancelRef.current.focus();
  }

  componentDidUpdate() {
    if (this.state.enableAccept) {
      this.cancelRef.current.blur();
      this.acceptRef.current.focus();
    } else {
      this.acceptRef.current.blur();
      this.cancelRef.current.focus();
    }
  }

  handleShowPreview(signatureId) {
    const { allPersonImages } = this.state;
    const indexImage = allPersonImages.findIndex((personImage) => personImage.id === signatureId);
    let srcSignaturePreview = null;
    if (indexImage !== -1) srcSignaturePreview = fileArrayToBase64(allPersonImages[indexImage].firma);
    this.setState((prevState) => ({
      showPreview: !prevState.showPreview,
      srcSignaturePreview,
    }));
  }

  async handleAccept() {
    let { operationID, resumeFocus, removeTreeNode, removeComponentTree } = this.props;
    let { persons, allPersonsHasSignatures } = this.state;

    const missingASignature = allPersonsHasSignatures ? 0 : 1;
    await signaturesActionOK(operationID, persons.join(', '), missingASignature);
    resumeFocus();

    this.setState((prevState) => ({
      showWindow: !prevState.showWindow,
    }));
    removeComponentTree(removeTreeNode);
  }

  async handleCancel() {
    let { operationID, resumeFocus, removeTreeNode, removeComponentTree } = this.props;
    const { allPersonsHasSignatures } = this.state;
    const missingASignature = allPersonsHasSignatures ? 0 : 1;
    await signaturesActionCancel(operationID, missingASignature);
    resumeFocus();

    this.setState((prevState) => ({
      showWindow: !prevState.showWindow,
    }));
    removeComponentTree(removeTreeNode);
  }

  async getPowerType(typePower) {
    let powerTypes = await getPowerTypes();
    let powerTypeLabel = '';
    powerTypes.forEach((el) => {
      if (el.tipoPoder === typePower) {
        powerTypeLabel = el.descripcion;
      }
    });
    return powerTypeLabel;
  }

  async fetchData(data) {
    let { idEntidad: idEntity, identificacion: identification, tipoPoder: typePower, tipoEntidad: typeEntity } = data;
    if (identification === SIGNATURE) {
      this.setState({ loading: true });
      let allPersonImages = [];
      let represantatives = await getRepresentatives(idEntity, typePower, typeEntity);

      for (let i = 0; i < represantatives.length; i++) {
        const represantative = represantatives[i];
        let personImages = await getPersonSignatures(represantative.idPersona);

        personImages = await Promise.all(
          personImages.map(async (personImage, index) => {
            if (!personImage.firma) {
              this.setState({
                allPersonsHasSignatures: false,
              });
            }
            let personData = await getPerson(personImage.numeroPersonaFisica);
            let namePerson = '';
            if (personData.primerNombre && personData.primerNombre !== null) {
              namePerson += personData.primerNombre + ' ';
            }
            if (personData.segundoNombre && personData.segundoNombre !== null) {
              namePerson += personData.segundoNombre + ' ';
            }
            if (personData.apellidoPaterno && personData.apellidoPaterno !== null) {
              namePerson += personData.apellidoPaterno + ' ';
            }
            if (personData.apellidoMaterno && personData.apellidoMaterno !== null) {
              namePerson += personData.apellidoMaterno + ' ';
            }

            return {
              ...personImage,
              id: index + allPersonImages.length + 1,
              category: represantative.categoria,
              currency: represantative.monedaMontoIndividual,
              amount: represantative.montoMaxindividual,
              name: namePerson,
            };
          }),
        );
        allPersonImages = allPersonImages.concat(personImages);
      }

      let texts = await getTexts(idEntity, typePower, typeEntity);
      let allTexts = texts.map((data) => data.texto);
      let powerTypeLabel = await this.getPowerType(typePower);
      this.setState({
        texts: allTexts,
        info: {
          person: `${typePower} - ${powerTypeLabel}`,
          balance: idEntity,
        },
        allPersonImages,
        represantatives,
        loading: false,
      });
    }
  }

  handleSelect(idSelected) {
    let { selectedIds } = this.state;
    if (selectedIds.includes(idSelected)) selectedIds.splice(selectedIds.indexOf(idSelected), 1);
    else selectedIds.push(idSelected);
    if (selectedIds.length > 0)
      this.validateSignatures(
        selectedIds.map((id) => {
          return parseInt(id.replace('signatureImage', ''));
        }),
      );
    else this.setState({ enableAccept: false, footerData: null, persons: [] });
    this.setState({ selectedIds });
  }

  async validateSignatures(selectedIds) {
    const { allPersonImages } = this.state;
    const { data } = this.props;
    let { idEntidad: idEntity, tipoPoder: typePower, tipoEntidad: typeEntity, monto: amount, monedaMonto: currency } = data;

    let persons = [];
    let categories = [];
    let footerData = null;
    for (let i = 0; i < allPersonImages.length; i++) {
      const personImage = allPersonImages[i];
      if (selectedIds.includes(personImage.id)) {
        let category = personImage.category;
        let person = personImage.numeroPersonaFisica;

        categories.push(category);
        persons.push(person);
      }
    }
    let validate = false;
    let typeAmount = await getTypeAmount(idEntity, typePower, typeEntity);

    // replace \"AMOUNT\" with AMOUNT
    const re = /[\\"]+/g;
    typeAmount = typeAmount.replace(re, '');

    if (amount === 0) {
      validate = await validateRepresentatives(idEntity, typePower, typeEntity, categories);
      footerData = { formValid: validate };
    } else {
      validate = await validateRepresentativesByAmount(currency, amount, {
        personas: persons,
        tipoMontoMaximo: typeAmount,
      });
      footerData = {
        formValid: validate.validaFormula,
        amountValid: validate.validaMonto,
        typeAmountMax: validate.tipoMontoMaximo && validate.tipoMontoMaximo[0],
        currencyOperation: validate.monedaOperacion,
        amountOperation: validate.montoOperacion.toFixed(2),
        amountMax: validate.montoMaximo.toFixed(2),
      };
      validate = validate.validaMonto && validate.validaFormula;
    }

    this.setState({ enableAccept: validate, footerData, persons });
  }

  isSignatureExpired = (dateValue) => {
    const { processDate } = this.state;
    try {
      dateValue = formatDateFromServer(dateValue);
      const signatureDate = new Date(dateValue);
      return processDate >= signatureDate;
    } catch (ex) {
      console.error(ex);
      return false;
    }
  };

  render() {
    let { showWindow, texts, info, allPersonImages, selectedIds, enableAccept, footerData, showPreview, srcSignaturePreview, loading } = this.state;
    const { intl } = this.props;

    let style = { top: -35 };

    let textLayout = texts.map((text, id) => {
      return (
        <li key={id} className="text-sm" style={{ fontSize: 11 }}>
          {' '}
          {text}{' '}
        </li>
      );
    });

    const numImages = allPersonImages.length;
    return (
      <FocusTrap className={`${showWindow ? '' : 'hidden'}`}>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className={`border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute w-full z-50`}
            style={{ ...style, zIndex: ABOVE_ALL }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move m-1 text-xs" />
            <div className="flex">
              <div className="w-full">
                <div className="flex-auto text-left m-1 text-lg pt-2" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                  <div className="mb-2 font-light text-primary text-center">
                    <FormattedMessage id="signatureValidation" defaultMessage={`Validación de Firmas`} />
                  </div>
                  <div className="flex justify-between items-center my-6">
                    <div>{info.person}</div>

                    <div className="flex items-center">
                      <span className="pr-1 inline-blockt text-xxs uppercase text-grey pt-1">
                        <FormattedMessage id="balance" defaultMessage={`Saldo`} />:
                      </span>
                      <span className="ml-1">{info.balance}</span>
                    </div>
                    {numImages > 0 && (
                      <div>
                        {`${numImages} `}
                        <FormattedPlural
                          value={numImages}
                          one={<FormattedMessage id="registeredImage" defaultMessage={`imagen registrada`} />}
                          other={<FormattedMessage id="registeredImages" defaultMessage={`imágenes registradas`} />}
                        />
                        :
                      </div>
                    )}
                  </div>
                  <div className="flex w-full bg-grey-lightest rounded my-4 p-6 overflow-x-scroll">
                    {loading && (
                      <div className="m-12 text-center px-4">
                        <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
                      </div>
                    )}

                    {!loading && numImages === 0 && (
                      <div className="m-12 text-center px-4">
                        <FormattedMessage id="clientNoValidPower" defaultMessage={`El cliente no válida el poder`} />
                      </div>
                    )}

                    {!loading &&
                      numImages > 0 &&
                      allPersonImages.map((personImage, index) => {
                        const signature = personImage.firma && fileArrayToBase64(personImage.firma);

                        let expiration = personImage.vencimiento ? dateFormatInput(dateFormatSignature(personImage.vencimiento), 'LABEL') : '';

                        let color;

                        if (expiration && this.isSignatureExpired(personImage.vencimiento)) {
                          color = 'danger';

                          const expired = intl.formatMessage({
                            id: 'expired',
                            defaultMessage: 'Vencida',
                          });

                          expiration = `${expiration} (${expired.toUpperCase()})`;
                        }

                        return (
                          <div className="min-w-1/3" key={index}>
                            <SignatureContainer
                              key={index}
                              signature={signature}
                              selected={selectedIds.includes('signatureImage' + personImage.id)}
                              click={() => {
                                this.handleSelect('signatureImage' + personImage.id);
                                addValueTest('CLICK', 'signatureImage' + personImage.id, '', true);
                              }}
                              fromValidation={true}
                              showOptions={true}
                              showPreview={true}
                              handleShowPreview={this.handleShowPreview}
                              signatureId={'signatureImage' + personImage.id}
                            >
                              <span className="flex-col" style={{ fontSize: 12 }}>
                                <Item
                                  title={intl.formatMessage({
                                    id: 'nombrefirmante',
                                    defaultMessage: 'Nombre Firmante',
                                  })}
                                  value={personImage.name}
                                />
                                <Item
                                  title={intl.formatMessage({
                                    id: 'person',
                                    defaultMessage: 'Persona',
                                  })}
                                  value={personImage.numeroPersonaFisica}
                                />
                                <Item
                                  title={intl.formatMessage({
                                    id: 'category',
                                    defaultMessage: 'Categoría',
                                  })}
                                  value={personImage.category}
                                />
                                <Item
                                  title={intl.formatMessage({
                                    id: 'expiration',
                                    defaultMessage: 'Vencimiento',
                                  })}
                                  value={expiration}
                                  color={color}
                                />
                              </span>
                            </SignatureContainer>
                          </div>
                        );
                      })}
                  </div>
                  <div className="flex w-full p-4 pb-2 items-center">
                    <label className="text-sm">Textos:</label>
                    <ul> {textLayout} </ul>
                  </div>
                  <div className="flex justify-center">
                    <Button
                      title={intl.formatMessage({
                        id: 'accept',
                        defaultMessage: 'Aceptar',
                      })}
                      id={'acceptValidationSignatureWindow'}
                      disabled={enableAccept ? selectedIds.length === 0 : true}
                      refToPass={this.acceptRef}
                      click={this.handleAccept}
                    />
                    <Button
                      title={intl.formatMessage({
                        id: 'cancel',
                        defaultMessage: 'Cancelar',
                      })}
                      id={'cancelValidationSignatureWindow'}
                      click={this.handleCancel}
                      refToPass={this.cancelRef}
                    />
                  </div>
                </div>
              </div>
            </div>
            <footer style={{ paddingLeft: 10, paddingBottom: 5, fontSize: 12 }}>
              {footerData && selectedIds.length > 0 && <FooterInfo footerData={footerData} />}
            </footer>
          </div>
        </Draggable>
        {showPreview && (
          <ModalMessage
            message={
              <div className="flex w-full h-full justify-center items-center">
                <img className="w-full max-h-full" src={srcSignaturePreview} />
              </div>
            }
            handleAccept={() => {
              this.handleShowPreview();
            }}
            acceptTitle={intl.formatMessage({
              id: 'close',
              defaultMessage: 'Cerrar',
            })}
            largeModal={true}
            closeOutside={true}
            draggable={true}
          />
        )}
      </FocusTrap>
    );
  }
}

const mapStateToProps = (state) => {
  const { operationID, focusOn } = getCurrentTabInformation(state);

  return {
    operationID,
    focusOn,
  };
};

export default connect(mapStateToProps, storeActions)(injectIntl(SignatureValidationWindow));
