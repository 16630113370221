export function timeInSecondsToString(timeInSeconds: number): string {
  let seconds: number = timeInSeconds;
  let minutes: number = Math.floor(seconds / 60);
  seconds = seconds % 60;
  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;

  const stringTime: string =
    hours !== 0 ? hours + 'h' + minutes + 'm' + seconds + 's' : minutes !== 0 ? minutes + 'm' + seconds + 's' : seconds + 's';

  return stringTime;
}
