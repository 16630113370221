import React, { useState } from 'react';

import './MonitorModal.scss';
import { MonitorModalView } from './MonitorModalView';

interface MonitorModalViewModelProps {
  executionId: number;
  onModalMonitoringChange: (isOpen: boolean) => void;
}

export function MonitorModalViewModel({ executionId, onModalMonitoringChange }: MonitorModalViewModelProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalDataDescription, setModalDataDescription] = useState<string>('');
  const [modalDataName, setModalDataName] = useState<string>('');

  function onModalChange(isOpen: boolean): void {
    setIsModalOpen(isOpen);
  }

  function onModalDataChange(data: string, name: string): void {
    setModalDataName(name);
    setModalDataDescription(data);
  }

  function onClose(): void {
    onModalMonitoringChange(false);
  }

  return (
    <MonitorModalView
      executionId={executionId}
      onModalChange={onModalChange}
      onModalDataChange={onModalDataChange}
      modalDataDescription={modalDataDescription}
      modalDataName={modalDataName}
      isModalOpen={isModalOpen}
      onClose={onClose}
    />
  );
}
