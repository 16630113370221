import React, { Component } from 'react';
import Draggable from 'react-draggable';
import Grid from './Grid';
import { connect } from 'unistore/react';
import { storeActions, store, dispatchActions } from '../../src/store';
import { FormattedMessage } from 'react-intl';
import { ABOVE_ALL } from '../constants/zIndexValues';
import { getTabIdByNodeId, getTabInformation } from '../../src/selectors';
import Overlay from './Overlay';
import { css, StyleSheet } from 'aphrodite';
import { addLineTest, addLineEventTest } from '../../src/components/Testing/HandleLineTest';

export class HelpWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueSelected: '',
    };

    this.acceptRef = React.createRef();
    this.cancelRef = React.createRef();
    this.focusOnAccept = this.focusOnAccept.bind(this);
    this.helpRef = React.createRef();
    this.positionY = '-50%';
  }

  focusOnAccept() {
    if (this.acceptRef && this.acceptRef.current) {
      this.acceptRef.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.showingHelp !== prevProps.showingHelp) {
      if (this.props.showingHelp) {
        this.props.pauseFocus();
      } else {
        this.props.resumeFocus();
      }
    }
    this.positionY = this.helpRef.current?.getBoundingClientRect()?.top > 100 ? '-50%' : '-40%';
  }

  render() {
    let {
      acceptHandler,
      cancelHandler,
      fieldNumber,
      focusOn,
      grids,
      inputValue,
      lineNumber,
      nodeId,
      transactionLineLabel,
      transactionNumber,
    } = this.props;
    let hasFocus = focusOn && focusOn[0] === transactionLineLabel;

    const buttonStyles = {
      bottom: 0,
      width: '100%',
    };

    const position = { x: 10, y: 55, width: 'auto', height: 'auto' };
    const gridStyle = { width: '480px', height: '250px' };

    const styles = StyleSheet.create({
      titleContainer: {
        width: '100%',
        minHeight: '1.5rem',
        padding: '.5rem 1rem',
        color: '#6B7280',
        cursor: 'move',
        textAlign: 'left',
        flex: '1 0 auto',
        borderBottom: `1px solid #DCE0E6`,
      },
      helpWrapper: {
        backgroundColor: '#F4F5F7',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
        borderColor: hasFocus ? '#2b53a0' : 'transparent',
        borderRadius: '.25rem',
        borderWidth: 1,
        minHeight: '300px',
        minWidth: '400px',
        height: '346px',
        left: '50%',
        position: 'fixed',
        top: '50%',
        width: '500px',
        zIndex: ABOVE_ALL,
      },
    });

    const title = grids && grids[nodeId] && grids[nodeId].title;

    return (
      <div style={{ visibility: !this.props.showingHelp ? 'hidden' : '' }}>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable bounds="" positionOffset={{ x: '-50%', y: this.positionY }} handle=".helpHandleDragg">
          <div ref={this.helpRef} className={css(styles.helpWrapper)}>
            <div className={`helpHandleDragg ${css(styles.titleContainer)}`}>{title}</div>
            <Grid
              nodeId={nodeId}
              fieldNumber={fieldNumber}
              style={gridStyle}
              position={position}
              fromHelp={true}
              inputValue={inputValue}
              transactionNumber={transactionNumber}
              lineNumber={lineNumber}
              transactionLineLabel={transactionLineLabel}
              acceptButtonRef={this.acceptRef}
              acceptHandler={acceptHandler}
              cancelHandlerHelp={cancelHandler}
              focusedTable
              onChange={(e) => {
                this.setState({ valueSelected: e.target.value });
              }}
              onHelpSelect={(event) => {
                this.setState({ valueSelected: event });
                acceptHandler({ [transactionLineLabel]: { value: event.length === 1 ? event[0] : event } });
              }}
              isFocused={this.props.isFocused}
              showingHelp={this.props.showingHelp}
              readOnly={this.props.readOnly}
            />
            <div className="absolute flex justify-end" style={buttonStyles}>
              <button
                id={'helpWindowAcceptButton_' + transactionLineLabel}
                autoFocus
                ref={this.acceptRef}
                className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                onClick={(e) => {
                  addLineTest('CLICK', e);
                  if (
                    this.state.valueSelected === null ||
                    !this.state.valueSelected & (this.state.valueSelected !== 0) ||
                    this.state.valueSelected.length === 0
                  ) {
                    store.setState(
                      dispatchActions.openGlobalModal(
                        store.getState(),
                        <FormattedMessage id="incorrectRow" defaultMessage={`La fila seleccionada no es correcta`} />,
                      ),
                    );
                  } else {
                    acceptHandler({
                      [transactionLineLabel]: {
                        value: this.state.valueSelected,
                      },
                    });
                  }
                }}
                onBlur={(e) => {
                  if (
                    e.relatedTarget === null ||
                    e.relatedTarget.classList.contains('focus-trap') ||
                    e.relatedTarget.classList.contains('contorno')
                  ) {
                    this.acceptRef.current.focus();
                  }
                }}
                onKeyDown={(e) => {
                  addLineEventTest(e);
                  if (e.key === 'Escape') {
                    cancelHandler();
                  } else if (e.key === 76 && e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
              </button>
              <button
                id={'helpWindowCancelButton_' + transactionLineLabel}
                className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                onClick={(e) => {
                  addLineTest('CLICK', e);
                  cancelHandler();
                }}
                onBlur={(e) => {
                  if (
                    e.relatedTarget === null ||
                    e.relatedTarget.classList.contains('focus-trap') ||
                    e.relatedTarget.classList.contains('contorno')
                  ) {
                    this.cancelRef.current.focus();
                  }
                }}
                ref={this.cancelRef}
                onKeyDown={(e) => {
                  addLineEventTest(e);
                  if (e.key === 'Escape') {
                    cancelHandler();
                  } else if (e.key === 76 && e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                  } else if (e.key === 'Tab' && !e.shiftKey) {
                    const helpTableElement = document.getElementById(transactionLineLabel + '_HelpTable_0');
                    if (helpTableElement) {
                      e.preventDefault();
                      helpTableElement.focus();
                    }
                  }
                }}
              >
                <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />
              </button>
            </div>
          </div>
        </Draggable>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { nodeId } = ownProps;
  const tabId = getTabIdByNodeId(state, nodeId);
  const { operationData, focusOn } = getTabInformation(state, tabId);

  const { grids } = operationData;

  return {
    focusOn,
    grids,
  };
};

export default connect(mapStateToProps, storeActions)(HelpWindow);
