import React, { useState } from 'react';
import { connect } from 'unistore/react';
import { useIntl } from 'react-intl';

import { BUTTON_NO, BUTTON_CANCEL } from './Modal/constants/Buttons';
import ButtonData from './Modal/services/ButtonData';
import { storeActions } from './../../src/store';
import { noticeDecided } from '../../src/api/notice';
import { getCurrentTabInformation, getTabIdByNodeId, getTabInformation } from '../../src/selectors';
import { v4 as uuidv4 } from 'uuid';

const ConfirmDialog = (props) => {
  const intl = useIntl();
  const { transactionLineLabel, buttonsData, escapePressed } = props;
  const [modalId] = useState(uuidv4());
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleButtonClick = async (decisionId) => {
    if (buttonClicked) {
      return;
    } else {
      setButtonClicked(true);
    }

    const {
      removeModal,
      nodeId,
      operationID,
      processContext,
      processEventsAfterNotice,
      removeTreeNode,
      removeFromWindowStack,
      setApiLoading,
      tabId,
      refreshFocusElement,
      toggleModalEscapePressed,
    } = props;

    removeTreeNode(transactionLineLabel, nodeId);
    removeFromWindowStack(transactionLineLabel);
    toggleModalEscapePressed();

    if (props.toRemove) {
      removeModal();
    }
    processEventsAfterNotice().then(async () => {
      setApiLoading(true, tabId);
      noticeDecided(operationID, decisionId).then(async (response) => {
        processContext(response, 'Notice Decided', tabId).then(async () => {
          setApiLoading(false, tabId);
          refreshFocusElement();
        });
      });
    });
  };

  React.useEffect(() => {
    const {
      showModalMessage,
      updateModalMessageId,
      message: messageToShow,
      title: titleToShow,
      buttons: buttonIds,
      currentTabId,
      tabId,
      lineNumber,
    } = props;

    const createButtonData = (btnId) => {
      const { defaultButton } = props;
      const autoFocus = defaultButton === btnId;
      const button = new ButtonData(btnId, autoFocus);
      return button.createData(intl);
    };

    const buttons = buttonIds && buttonIds.map((btnId) => createButtonData(btnId));
    const modalOptions = {
      modalId,
      title: titleToShow,
      buttons: buttons,
      returnFocus: false,
      lineNumber: lineNumber,
    };

    if (tabId === currentTabId) {
      if (!props.modalId) {
        showModalMessage(messageToShow, modalOptions, props.infoObject);
      } else {
        updateModalMessageId(modalId, lineNumber);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * if one of the buttons is clicked, send the id of the clicked button.
   * if Escape was pressed instead, send the id of the first "negative action" button.
   */
  React.useEffect(() => {
    if (props.modalId !== modalId) {
      return;
    }
    // find for a button that has been clicked
    const clickedButton = buttonsData.find((b) => b.clicked);

    /**
     * find for a negative action button.
     * if none is found, get the first button in the list.
     */
    const negativeButton = buttonsData.find(({ buttonId }) => [BUTTON_NO, BUTTON_CANCEL].includes(buttonId)) || buttonsData[0];

    if (clickedButton) {
      handleButtonClick(clickedButton.buttonId);
    } else if (escapePressed && negativeButton) {
      handleButtonClick(negativeButton.buttonId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonsData, escapePressed]);

  return <div id={transactionLineLabel}></div>;
};

const mapStateToProps = (state, ownProps) => {
  const { nodeId } = ownProps;
  const tabId = getTabIdByNodeId(state, nodeId);
  const { operationID } = getTabInformation(state, tabId);
  const { id: currentTabId } = getCurrentTabInformation(state);
  const { modalMessage } = state;
  const tmpModalMessage = modalMessage.find((msg) => msg.lineNumber === ownProps.lineNumber);
  return {
    currentTabId,
    tabId,
    operationID,
    modalId: tmpModalMessage && tmpModalMessage.modalId,
    buttonsData: tmpModalMessage && tmpModalMessage.buttons ? [...tmpModalMessage.buttons] : [],
    escapePressed: tmpModalMessage && tmpModalMessage.escapePressed,
    toRemove: tmpModalMessage && tmpModalMessage.toRemove,
  };
};

export default connect(mapStateToProps, storeActions)(ConfirmDialog);
