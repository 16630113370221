import React, { useState } from 'react';
import './../../src/styles/NewTabButton.css';
import { injectIntl } from 'react-intl';
import { connect } from 'unistore/react';
import { getTabsLength } from '../../src/selectors';
import PlusIcon from './../../src/resources/icons/fa/plus.svg';
import { useTheme } from '../../src/themes/ThemeContext';

const NewTabButton = (props) => {
  const disabled = props.tabsLength >= 15;
  const themeState = useTheme();
  const { addTabButton } = themeState.theme;

  const [iconColor, setIconColor] = useState(addTabButton.default.color);
  const title = {
    default: props.intl.formatMessage({
      id: 'addNewTab',
      defaultMessage: 'Abrir una nueva pestaña',
    }),
    disabled: props.intl.formatMessage({
      id: 'tabsLimitReached',
      defaultMessage:
        'Límite de pestañas permitidas. Cierre alguna pestaña para poder abrir una nueva.',
    }),
  };
  const handleHover = () => {
    if (iconColor !== addTabButton.hover.color) {
      setIconColor(addTabButton.hover.color);
    }
  };

  const handleBlur = () => {
    if (iconColor !== addTabButton.default.color) {
      setIconColor(addTabButton.default.color);
    }
  };

  return (
    <div
      className={`${
        disabled && 'cursor-not-allowed'
      } flex items-center justify-center font-bold cursor-pointer hover:bg-grey-lighter rounded m-1 px-2 focus:outline-none`}
      onClick={!disabled && props.handleClick}
      title={disabled ? title.disabled : title.default}
      onMouseEnter={handleHover}
      onFocus={handleHover}
      onBlur={handleBlur}
      onMouseLeave={handleBlur}
    >
      <PlusIcon className="NewTabButton" height="16px" fill={iconColor} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const tabsLength = getTabsLength(state);
  return { tabsLength };
};

export default connect(mapStateToProps)(injectIntl(NewTabButton));
