import typeToValue from './typeToValue';
import { dateRunFormat } from '../../UI-kit/helpers/dateHelper';
import {
  splitTransactionLineWithCount,
  getParentDialogCount,
  getTLFromStr,
  getIterationCountFromLineIDString,
} from '../../UI-kit/helpers/operationHelper';

const DATE_TYPE = 11;

export let createBodyGoToLine = (opNumber, fieldsObj, transactionLineLabel, focusElement, simpleBody) => {
  let body = createAcceptBody(opNumber, fieldsObj, fieldsObj[transactionLineLabel].treeNumber);
  let keys = Object.keys(body.fields);
  let from = keys.indexOf(getTLFromStr(focusElement));
  let to = keys.indexOf(getTLFromStr(transactionLineLabel));
  let keysReturn = keys.length === 1 ? keys : keys.slice(from, to + 1);
  let fieldsFromLastElement = {};
  if ((from >= 0 && to >= 0 && keysReturn && keysReturn.length > 0) || keysReturn.length === 1) {
    keysReturn.forEach((key) => {
      fieldsFromLastElement[key] = body.fields[key];
    });
  } else return simpleBody;

  return { ...body, fields: fieldsFromLastElement };
};

// OJO : Cualquier modificación a esta función con respecto a los diálogos iterativos debería generalmente ser replicada en Grid.js : componentDidUpdate
export let createAcceptBody = (opNumber, fieldsObj, number, shouldSetBody = true) => {
  let body = setOpNumber(opNumber);
  let onlyValueFields = {};
  let acceptBody = body;
  if (shouldSetBody) {
    let keys = Object.keys(fieldsObj);
    keys = keys.filter((e) => e.substr(0, 1) === 'T' && e.indexOf('L') > 0);
    keys.sort((f, s) => {
      const [t1, l1, c1] = splitTransactionLineWithCount(s);
      const [t2, l2, c2] = splitTransactionLineWithCount(f);

      if (!t1 || !l1 || !t2 || !l2) {
        return -1;
      }

      if (t2 > t1) {
        return 1;
      } else if (t2 < t1) {
        return -1;
      } else if (l2 > l1) {
        return 1;
      } else if (l2 < l1) {
        return -1;
      } else if (c2 > c1) {
        return 1;
      } else if (c2 < c1) {
        return -1;
      } else {
        return 0; // equals
      }
    });
    for (let idx = 0; idx < keys.length; idx++) {
      if (keys[idx] && keys[idx].length > 0 && keys[idx].substr(0, 1) === 'T' && keys[idx].indexOf('L') > 0) {
        // &&
        // Number.isInteger(keys[idx].substr(1, keys[idx].indexOf("L")))) {
        const field = keys[idx];
        if (
          fieldsObj[field].treeNumber &&
          fieldsObj[field].treeNumber.toString() === number.toString() &&
          fieldsObj[field].inputType !== undefined &&
          (fieldsObj[field].hide === undefined || fieldsObj[field].hide === false)
        ) {
          const fieldWithoutCount = getTLFromStr(field);
          const fieldCount = getIterationCountFromLineIDString(field);
          let dialogCount = getParentDialogCount(fieldsObj, null, null, field);

          if (
            ((String(dialogCount) === String(fieldCount) || (!dialogCount && !fieldCount)) &&
              Array.isArray(fieldsObj.repeatedElementIDs) &&
              fieldsObj.repeatedElementIDs.includes(field)) ||
            !fieldsObj.repeatedElementIDs ||
            !fieldsObj.repeatedElementIDs.includes(field)
          ) {
            if (fieldsObj[field].type && fieldsObj[field].type === 4) {
              const dateValueToSend = fieldsObj[field].value || fieldsObj[field].raw.displayInfo.value;
              if (dateValueToSend && dateValueToSend.length !== 8) {
                const valueToParse = new Date(dateValueToSend);
                const valueParsed = dateRunFormat(valueToParse);
                let fieldToSend = fieldsObj[field];
                fieldToSend.raw.displayInfo.value = valueParsed;
                fieldToSend.value = valueParsed;
                onlyValueFields[fieldWithoutCount] = fieldToSend;
              } else if (dateValueToSend && dateValueToSend.length === 8) {
                onlyValueFields[fieldWithoutCount] = dateValueToSend;
              } else {
                onlyValueFields[fieldWithoutCount] = fieldsObj[field];
              }
            } else if (
              fieldsObj[field].inputParameters &&
              fieldsObj[field].inputParameters.edition === 'I' &&
              typeof fieldsObj[field].value === 'string'
            ) {
              let checkForComma = (fieldsObj[field].value.match(/,/g) || []).length;
              if (checkForComma > 0) {
                let currencyValue = fieldsObj[field].value.split('.').join('').replace(/,/g, '.');
                let fieldToSend = fieldsObj[field];
                fieldToSend.value = currencyValue;
                onlyValueFields[fieldWithoutCount] = fieldToSend;
              } else {
                onlyValueFields[fieldWithoutCount] = fieldsObj[field];
              }
            } else if (fieldsObj[field].inputType === 0 && fieldsObj[field].type === 1) {
              if (fieldsObj[field].rawValue) {
                fieldsObj[field].value = fieldsObj[field].rawValue;
              }
              onlyValueFields[fieldWithoutCount] = fieldsObj[field];
            } else if (fieldsObj[field].inputType === 6 && fieldsObj[field].optional === true && fieldsObj[field].value?.length === 0) {
            } else {
              onlyValueFields[fieldWithoutCount] = fieldsObj[field];
            }
          }
        }
      }
    }
    acceptBody = setFields(onlyValueFields, body);
  }
  return acceptBody;
};

export let createRunBody = (opNumber, transactionLineLabel, value) => {
  let body = setOpNumber(opNumber);
  let fieldsObj = {};
  let lineIDKey = getTLFromStr(transactionLineLabel);
  fieldsObj[lineIDKey] = value;
  let runBody = setFields(fieldsObj, body);
  return runBody;
};

const convertDate = (date) => {
  const dateSepareted = date.split('/');
  return `${dateSepareted[2]}-${dateSepareted[1]}-${dateSepareted[0]}`;
};

const setClassNameArrayFieldsBody = (value) => {
  if (typeToValue(value) === DATE_TYPE) {
    const date = convertDate(value);
    return {
      value: date,
      originalValue: date,
      classNameValue: DATE_TYPE,
      classNameOriginalValue: DATE_TYPE,
    };
  }
  return {
    value: value,
    originalValue: value,
    classNameValue: typeToValue(value),
    classNameOriginalValue: typeToValue(value),
  };
};

const getArrayFieldBody = (arrayValues) => {
  return arrayValues.map((arrayValue) => {
    if (Array.isArray(arrayValue)) {
      return arrayValue.map((value) => setClassNameArrayFieldsBody(value));
    } else return setClassNameArrayFieldsBody(arrayValue);
  });
};

export let createSimpleBody = (operationNumber, transactionLineLabel, value) => {
  let valueToSend = Array.isArray(value) ? value : '' + value;
  if (Array.isArray(value)) valueToSend = getArrayFieldBody(value);
  let lineIDKey = getTLFromStr(transactionLineLabel);
  let body = {
    operationName: `oper${operationNumber}`,
    requestName: `oper${operationNumber}`,
    fields: {
      [`${lineIDKey}`]: {
        value: Array.isArray(value) ? valueToSend : value,
        originalValue: Array.isArray(value) ? valueToSend : value,
        classNameValue: typeToValue(value),
        classNameOriginalValue: typeToValue(value),
      },
    },
  };
  return body;
};

let setOpNumber = (opNumber) => {
  let body = {};
  if (opNumber) {
    let operation = `oper${opNumber}`;
    body.operationName = operation;
    body.requestName = operation;
  }
  return body;
};

let setFields = (fieldsObj, body) => {
  if (fieldsObj) {
    body.fields = {};
    for (const prop in fieldsObj) {
      if (fieldsObj[prop] !== undefined) {
        if (Array.isArray(fieldsObj[prop].value)) {
          let valueArray = getFieldBody(fieldsObj[prop]);
          body.fields[prop] = {};
          body.fields[prop].value = valueArray;
          body.fields[prop].originalValue = valueArray;
          body.fields[prop].classNameValue = typeToValue(valueArray);
          body.fields[prop].classNameOriginalValue = typeToValue(valueArray);
        } else {
          const fieldBody = getFieldBody(fieldsObj[prop]);
          if (fieldBody.value !== null) {
            body.fields[prop] = fieldBody;
          }
        }
      }
    }
  }
  return body;
};

let getFieldBody = (data) => {
  let value = data.value !== undefined ? data.value : data.inputParameters && data.inputParameters.type === 4 ? data.raw.displayInfo.value : data;

  if (value && value.treeNumber !== undefined) {
    value = '';
  }
  if (data.type === 4 && value === '') {
    value = '18000101';
  }
  if (Array.isArray(value)) return getArrayFieldBody(value);
  return {
    value: value,
    originalValue: value,
    classNameValue: typeToValue(value),
    classNameOriginalValue: typeToValue(value),
  };
};

export let createBodyBegin = (operationNumber) => ({
  operationName: `oper${operationNumber}`,
  requestName: `oper${operationNumber}`,
  fields: {},
});
