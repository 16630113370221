/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';
import { PulseLoader } from 'halogenium';
import { ABOVE_ALL } from '../../../../UI-kit/constants/zIndexValues';
import FocusLock from 'react-focus-lock';
import { useIntl } from 'react-intl';
import {
  getHelpButton,
  getColumnsSucursal,
  getColumnsImpresora,
  getColumnsNumeroMaquina,
  getHelpWindow,
} from './ProductionHelpComponent/productionHelp';

export const ProductionLoginWindow = (props) => {
  const intl = useIntl();

  const { item, setParametros, handleKeyOnNumber } = props;
  useEffect(() => {
    validateInputs();
  }, [validateInputs]);

  const validateInputs = useCallback(() => {
    document.getElementById('DistingueMM').value = props.params.clvcasesensitive;
    document.getElementById('UsarSeguirdadRed').value = props.params.tipored;
    document.getElementById('LoginAutomatico').value = props.params.autologin;
  });

  const cancelMessage = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  const acceptMessage = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });

  const modifyMessage = intl.formatMessage({
    id: 'modify',
    defaultMessage: 'Modificar',
  });

  let outside = null;

  const handleAccept = () => {
    let diasvalidos = document.getElementById('ValidezContrasenia').value;
    let diasaviso = document.getElementById('DiasPrevios').value;
    let clvlargomax = document.getElementById('LargoMaximo').value;
    let clvlargomin = document.getElementById('LargoMinimo').value;
    let clvcasesensitive = document.getElementById('DistingueMM').value;
    let reintentos = document.getElementById('NroReintentos').value;
    let tipored = document.getElementById('UsarSeguirdadRed').value;
    let autologin = document.getElementById('LoginAutomatico').value;
    let tiempocorte = document.getElementById('MinutosTimeout').value;
    let parametros = {
      diasvalidos: diasvalidos,
      clvlargomax: clvlargomax,
      reintentos: reintentos,
      tiempocorte: tiempocorte,
      diasaviso: diasaviso,
      clvlargomin: clvlargomin,
      clvcasesensitive: clvcasesensitive,
      autologin: autologin,
      tipored: tipored,
    };
    setParametros(parametros);
    props.handleCancel();
  };

  return (
    <div className={`fixed pin overflow-auto flex bg-smoke-light w-full h-full`} ref={(c) => (outside = c)} style={{ zIndex: ABOVE_ALL }}>
      <div
        className={`border border-solid border-primary  bg-grey-lighter-lighter rounded relative p-4 w-full m-auto
           flex-col flex`}
        style={{ maxWidth: '35rem' }}
      >
        <div className={`flex-auto text-left m-1 flex `} style={{ height: 'fit-content', justifyContent: 'center' }}>
          <form className={'overflow-hidden'} style={{ display: 'table' }}>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="ValidezContrasenia">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerPasswordValidity',
                  defaultMessage: 'Validez Contraseña (dias)',
                })}
              </label>
              <input
                className={'clear-both border ml-2'}
                id="ValidezContrasenia"
                defaultValue={props.params.diasvalidos}
                style={{ display: 'table-cell' }}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="DiasPrevios">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerDaysUntilInvalidPasswordReminder',
                  defaultMessage: 'Cantidad de Días Previos Aviso Vencimiento Contraseña',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                style={{ display: 'table-cell' }}
                id="DiasPrevios"
                defaultValue={props.params.diasaviso}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="LargoMaximo">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerMaxKeyLength',
                  defaultMessage: 'Largo Máximo de Clave',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                id="LargoMaximo"
                defaultValue={props.params.clvlargomax}
                style={{ display: 'table-cell' }}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="LargoMinimo">
                {' '}
                {intl.formatMessage({
                  id: 'productionManagerMinKeyLength',
                  defaultMessage: 'Largo Mínimo de Clave',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                style={{ display: 'table-cell' }}
                id="LargoMinimo"
                defaultValue={props.params.clvlargomin}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="DistingueMM">
                {intl.formatMessage({
                  id: 'productionManagerCaseSensitive',
                  defaultMessage: 'Distingue Mayúsculas/Minúsculas',
                })}
              </label>
              <select style={{ display: 'table-cell' }} className={'clear-both border mt-2 ml-2'} id="DistingueMM">
                <option selected={item.clvcasesensitive === 1} value="1">
                  Si
                </option>
                <option selected={item.clvcasesensitive === 0} value="0">
                  No
                </option>
              </select>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="NroReintentos">
                {intl.formatMessage({
                  id: 'productionManagerAmountRetries',
                  defaultMessage: 'Nro. Reintentos',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                id="NroReintentos"
                defaultValue={props.params.reintentos}
                style={{ display: 'table-cell' }}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="UsarSeguirdadRed">
                {intl.formatMessage({
                  id: 'productionManagerUseNetworkSecurity',
                  defaultMessage: 'Usar seguridad de la red',
                })}
              </label>
              <select style={{ display: 'table-cell' }} className={'clear-both border mt-2 ml-2'} id="UsarSeguirdadRed">
                <option selected={item.tipored === ' '} value=" ">
                  {intl.formatMessage({
                    id: 'none',
                    defaultMessage: 'Ninguna',
                  })}
                </option>
                <option selected={item.tipored === 'M'} value="M">
                  Microsoft NT
                </option>
                <option selected={item.tipored === 'N'} value="N">
                  Novell Netware
                </option>
                <option selected={item.tipored === 'O'} value="O">
                  {intl.formatMessage({
                    id: 'other',
                    defaultMessage: 'Otra',
                  })}
                </option>
                <option selected={item.tipored === 'W'} value="W">
                  ABN Uruguay
                </option>
                <option selected={item.tipored === 'A'} value="A">
                  LDAP
                </option>
                <option selected={item.tipored === 'R'} value="R">
                  {intl.formatMessage({
                    id: 'remoteTopazValidation',
                    defaultMessage: 'Validación contra Topaz remoto',
                  })}
                </option>
              </select>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="LoginAutomatico">
                {intl.formatMessage({
                  id: 'productionManagerAutomaticLogin',
                  defaultMessage: 'Login Automático',
                })}
              </label>
              <select style={{ display: 'table-cell' }} className={'clear-both border mt-2 ml-2'} id="LoginAutomatico">
                <option selected={item.autologin === 0} value="0">
                  {intl.formatMessage({
                    id: 'no',
                    defaultMessage: 'No',
                  })}
                </option>
                <option selected={item.autologin === 1} value="1">
                  {intl.formatMessage({
                    id: 'yes',
                    defaultMessage: 'Si',
                  })}
                </option>
                <option selected={item.autologin === 2} value="2">
                  {intl.formatMessage({
                    id: 'both',
                    defaultMessage: 'Ambos',
                  })}
                </option>
              </select>
            </p>
            <p style={{ display: 'table-row' }} className="mt-2 ml-2">
              <label style={{ display: 'table-cell' }} htmlFor="MinutosTimeout">
                {intl.formatMessage({
                  id: 'productionManagerTimeoutLength',
                  defaultMessage: 'Minutos de Timeout',
                })}
              </label>
              <input
                className={'clear-both border mt-2 ml-2'}
                id="MinutosTimeout"
                defaultValue={props.params.tiempocorte}
                style={{ display: 'table-cell' }}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyOnNumber(e)}
              ></input>
            </p>
          </form>
        </div>
        <div className="flex justify-center mt-2 ml-2">
          <button
            data-autofocus
            className={`bg-grey
                       hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow`}
            style={{ width: 'fit-content' }}
            onClick={() => handleAccept()}
          >
            {acceptMessage}
          </button>

          <button
            className={`bg-grey
                   hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow ml-2`}
            style={{ width: 'fit-content' }}
            onClick={() => props.handleCancel()}
          >
            {cancelMessage}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductionLoginWindow;
