import React, { useEffect, useState } from 'react';
import { Item } from 'react-simple-tree-menu';
import { RestrictionObject } from '../../../models/restrictionObject';
import { store } from '../../../../../store';
import { SelectedGroup } from '../../../models/types';
import { RestrictionsView } from './RestrictionsView';
import { createDateFromText } from '../../../../../../UI-kit/helpers/dateHelper';
import { differenceInDays } from 'date-fns/fp';
import { FormattedMessage } from 'react-intl';
import { getSessionInfo } from '../../../../../api/session';
import { forEach } from 'jszip';
import { MenuTree, useMenu } from './hooks/useMenu.hook';
import { useOperations } from './hooks/useOperations.hook';
import { usePermissions } from './hooks/usePermissions.hook';
import { useReports } from './hooks/useReports.hook';
import { useBranches } from './hooks/useBranches.hook';
import { useNavigations } from './hooks/useNavigations.hook';
import { useCatalogs } from './hooks/useCatalogs.hook';
import { GroupsAsTreeMenu } from '../../../models/groupsAsTreeMenu';
import { EMPTY_STRING } from '../../../models/constants';

interface RestrictionsViewModel {
  selectedGroup: SelectedGroup | undefined;
  isEditing?: boolean;
  onRestrictionsChangedAdd: (restriction: RestrictionObject[]) => void;
  onRestrictionsChangedRemove: (restriction?: RestrictionObject[]) => void;
  restrictionsChanged: { [id: string]: RestrictionObject };
  onHelperChange: (helper: string) => void;
  helperGroup: string;
  hasGroupPermissions: boolean;
  errors: [];
  setRestrictionsHasErrors: (hasErrors: boolean) => void;
}

const NOT_ALLOWED: string = '0';
const ALLOWED: string = '1';
type TreeNodes = MenuTree;

export function RestrictionsViewModel({ helperGroup, selectedGroup, isEditing, onRestrictionsChangedAdd, onRestrictionsChangedRemove, restrictionsChanged, onHelperChange, hasGroupPermissions, setRestrictionsHasErrors }: RestrictionsViewModel): JSX.Element {
  const [selectedRestriction, setSelectedRestriction] = useState<RestrictionObject[]>([]);
  const [selectedNode, setSelectedNode] = useState<Item>(null);
  const [isModalFormulaOpen, setIsModalFormulaOpen] = useState<boolean>(false);
  const [fechaProceso, setFechaProceso] = useState(null);
  const [modifiersPressed, setModifiersPressed] = useState({ ctrl: false, shift: false });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { menu, defaultRestriction: defaultRestrictionMenu } = isEditing
    ? useMenu((selectedGroup as GroupsAsTreeMenu).baseGroup, selectedGroup?.name, selectedGroup || '')
    : useMenu(selectedGroup?.name || '', EMPTY_STRING);
  const { operations, defaultRestriction: defaultRestrictionOperation } = !isEditing
    ? useOperations(selectedGroup?.name || '', EMPTY_STRING)
    : useOperations((selectedGroup as GroupsAsTreeMenu).baseGroup, selectedGroup?.name || EMPTY_STRING);
  const { permissions } = isEditing
    ? usePermissions((selectedGroup as GroupsAsTreeMenu).baseGroup, selectedGroup?.name || '', setIsLoading)
    : usePermissions(selectedGroup?.group || '', EMPTY_STRING, setIsLoading);
  const { reports, defaultRestriction: defaultRestrictionReport } = isEditing
    ? useReports((selectedGroup as GroupsAsTreeMenu).baseGroup, selectedGroup?.name || '')
    : useReports(selectedGroup?.group || '', EMPTY_STRING);
  const { branches } = isEditing
    ? useBranches((selectedGroup as GroupsAsTreeMenu).baseGroup, selectedGroup?.name || '')
    : useBranches(selectedGroup?.group || '', EMPTY_STRING);
  const { navigations } = isEditing
    ? useNavigations((selectedGroup as GroupsAsTreeMenu).baseGroup, selectedGroup?.name || '')
    : useNavigations(selectedGroup?.group || '', EMPTY_STRING);
  const { catalogs } = isEditing
    ? useCatalogs((selectedGroup as GroupsAsTreeMenu).baseGroup, selectedGroup?.name || '')
    : useCatalogs(selectedGroup?.group || '', EMPTY_STRING);

  const MENU_RESTRICTIONS: string = 'M';
  const OPERATIONS_RESTRICTIONS: string = 'O';
  const REPORTS_RESTRICTIONS: string = 'Q';
  const CATALOGS_RESTRICTIONS: string = 'C';
  const BRANCHES_RESTRICTIONS: string = 'B';


  const defaultRestriction = selectedRestriction?.map(restriction => {
    switch (restriction.type) {
      case MENU_RESTRICTIONS:
        return defaultRestrictionMenu;
      case OPERATIONS_RESTRICTIONS:
        return defaultRestrictionOperation;
      case REPORTS_RESTRICTIONS:
        return defaultRestrictionReport;
      default:
        return null;
    }
  });


  function onSelectRestriction(node?: Item): void {
    if (node !== undefined) {
      if (node.restriction !== undefined) {
        if (modifiersPressed.ctrl && selectedRestriction.length > 0 && selectedRestriction[0].type === node.restriction.type) { // si es del mismo padre anidamos
          setSelectedRestriction((prevRestriction) => [...prevRestriction, node.restriction]);
        } else if (modifiersPressed.shift) {
          setSelectedRestriction([]);
          onSelectGroupRestriction(node);
        } else {
          setSelectedRestriction([node.restriction]);
          setSelectedNode(node);
        }
      } else {
        setSelectedRestriction([]);
      }
    }
  }


  function onSelectGroupRestriction(node?: Item): void {
    if (node !== undefined && node.restriction) {
      switch (node.restriction.type) {
        case 'M':
          caseShiftPressed(menu, selectedRestriction, node, node.restriction, selectedNode);
          break;
        case 'O':
          caseShiftPressed(operations, selectedRestriction, node, node.restriction, selectedNode);
          break;
        case 'P':
          caseShiftPressed(permissions, selectedRestriction, node, node.restriction, selectedNode);
          break;
        case 'Q':
          caseShiftPressed(reports, selectedRestriction, node, node.restriction, selectedNode);
          break;
        case 'B':
          caseShiftPressed(branches, selectedRestriction, node, node.restriction, selectedNode);
          break;
        case 'C':
          caseShiftPressed(catalogs, selectedRestriction, node, node.restriction, selectedNode);
          break;
        case 'N':
          caseShiftPressed(navigations, selectedRestriction, node, node.restriction, selectedNode);
          break;
        default:
      }
      setModifiersPressed({
        ctrl: false,
        shift: false,
      });
    }
  }

  //Esta funcion se encarga de evaluar que tipos de nodos clickeamos y dependiendo cual sea, vamos a su funcion correspondiente ( no depende del tipo de reestrccion, sino del nivel del nodo que clickeamos)
  function caseShiftPressed(list: TreeNodes[], selectedRestriction: RestrictionObject[], node: Item, restriction: RestrictionObject, firstNodeClicked: Item) {
    let idx1Op = findNodesAndReturnIndex(list, selectedRestriction[0]);
    let idx2Op = findNodesAndReturnIndex(list, restriction);
    let indexParent = getIndexOfFatherNodes(list, node);
    let indexParentFirstNode = getIndexOfFatherNodes(list, firstNodeClicked);
    let keyIndexParentFirstNode = getKeyOfFatherNodes(list, firstNodeClicked);  
    if (idx1Op !== undefined && idx2Op !== undefined) {
      if (firstNodeClicked.level === 1 && node.level === 2) {
        let keyIndexParent = getKeyOfFatherNodes(list, node);
        selectedNodesIfFristNodeIsOneAndSecondNodeIsTwo(list, idx1Op, idx2Op, keyIndexParent, indexParent, indexParentFirstNode);
      } else if (firstNodeClicked.level === 2 && node.level === 1) {
        let keyIndexParent = getKeyOfFatherNodes(list, firstNodeClicked);
        selectedNodesIfFirstNodeIsTwoAndSecondNodeIsOne(list, idx1Op, idx2Op, keyIndexParent, indexParentFirstNode, indexParent);
      } else if (firstNodeClicked.level === 2 && node.level === 2 || firstNodeClicked.level === 1 && node.level === 1) {
        let keyIndexParent = getKeyOfFatherNodes(list, node);
        selectedNodesSameLevel(list, idx1Op, idx2Op, keyIndexParent, node, keyIndexParentFirstNode, indexParent, indexParentFirstNode, firstNodeClicked);
      }
    }
  }

  //Seleccionamos los nodos que el primero sea nivel 2 y el segundo nivel 1
  function selectedNodesIfFirstNodeIsTwoAndSecondNodeIsOne(list: TreeNodes[], idx1Op: number, idx2Op: number, keyIndexParent: string | undefined, indexParentFirstNode: number | undefined, indexParent: number | undefined) {
    let returnList: TreeNodes[] = [];
    if (indexParentFirstNode === undefined || indexParent === undefined) return;
    if (indexParentFirstNode < indexParent) {
      for (let i = indexParentFirstNode; i <= indexParent; i++) {
        const elem = list[i];
        if (elem.key !== keyIndexParent) {
          returnList.push(elem);
          iterateOnlyChildNodesNoInclude(elem.nodes, returnList, null, 0);
        } else {
          iterateOnlyChildNodesNoInclude(elem.nodes, returnList, null, idx1Op);
        }
      }
    } else {
      for (let i = idx2Op; i <= list.length; i++) {
        const elem = list[i];
        if (elem.key !== keyIndexParent) {
          returnList.push(elem);
          iterateOnlyChildNodesNoInclude(elem.nodes, returnList, null, 0);

        } else {
          returnList.push(elem);
          iterateOnlyChildNodesInclude(elem.nodes, returnList, idx1Op, 0);
          break;
        }
      }
    }
    returnGroupArray(returnList);
  }


  //Seleccionamos los nodos que el primero en seleccionar sea nivel 1 y el segundo nivel 2
  function selectedNodesIfFristNodeIsOneAndSecondNodeIsTwo(list: TreeNodes[], idx1Op: number, idx2Op: number, keyIndexParent: string | undefined, indexParent: number | undefined, indexParentFirstNode: number | undefined) {
    let returnList: TreeNodes[] = [];
    if (indexParent === undefined || indexParentFirstNode === undefined) return;
    if (indexParent < indexParentFirstNode) {
      for (let i = indexParent; i <= indexParentFirstNode; i++) {
        const elem = list[i];
        if (elem.key !== keyIndexParent) {
          returnList.push(elem);
          iterateOnlyChildNodesNoInclude(elem.nodes, returnList, null, 0);

        } else {
          iterateOnlyChildNodesNoInclude(elem.nodes, returnList, null, idx2Op);
        }
      }
    } else {
      for (let i = idx1Op; i < list.length; i++) {
        const elem = list[i];
        returnList.push(elem);
        if (elem.key !== keyIndexParent) {
          iterateOnlyChildNodesNoInclude(elem.nodes, returnList, null, 0);

        } else {
          iterateOnlyChildNodesInclude(elem.nodes, returnList, idx2Op, 0);
          break;
        }
      }
    }
    returnGroupArray(returnList);
  }


  //Seleccionamos los nodos que son del mismo nivel, y tambien los que son del mismo nivel pero no son del mismo padre
  function selectedNodesSameLevel(list: TreeNodes[], idx1Op: number, idx2Op: number, keyIndexParent: string | undefined, node: Item, keyIndexParentFirstNode: string | undefined, indexParent: number | undefined, indexParentFirstNode: number | undefined, firstNodeClicked: Item) {
    let returnList: TreeNodes[] = [];
    const isSameParent = keyIndexParent === keyIndexParentFirstNode;
    if (indexParentFirstNode === undefined || indexParent === undefined) return;
    if (!isSameParent && node.level === 2 && firstNodeClicked.level === 2) {
      if (indexParent > indexParentFirstNode) {
        for (let i = indexParentFirstNode; i <= indexParent; i++) {
          const elem = list[i];
          if (elem.key === keyIndexParentFirstNode) {
            iterateOnlyChildNodesNoInclude(elem.nodes, returnList, null, idx1Op);
          } else if (elem.key === keyIndexParent) {
            returnList.push(elem);
            iterateOnlyChildNodesInclude(elem.nodes, returnList, idx2Op, 0);
          } else if (elem.key !== keyIndexParent && elem.key !== keyIndexParentFirstNode) {
            for (let i = indexParentFirstNode; i < indexParent; i++) {
              const elem = list[i];
              if (elem.key !== keyIndexParentFirstNode && elem.key !== keyIndexParent) {
                returnList.push(elem);
                iterateOnlyChildNodesNoInclude(elem.nodes, returnList, null, 0);
              }
            }
          }
        }
      } else if (indexParent < indexParentFirstNode) {
        for (let i = indexParent; i <= indexParentFirstNode; i++) {
          const elem = list[i];
          if (elem.key === keyIndexParent) {
            iterateOnlyChildNodesNoInclude(elem.nodes, returnList, null, idx2Op);
          } else if (elem.key === keyIndexParentFirstNode) {
            returnList.push(elem);
            iterateOnlyChildNodesInclude(elem.nodes, returnList, idx1Op, 0);
          } else if (elem.key !== keyIndexParent && elem.key !== keyIndexParentFirstNode) {
            for (let i = indexParent; i < indexParentFirstNode; i++) {
              const elem = list[i];
              if (elem.key !== keyIndexParentFirstNode && elem.key !== keyIndexParent) {
                returnList.push(elem);
                iterateOnlyChildNodesNoInclude(elem.nodes, returnList, null, 0);
              }
            }
          }
        }
      }
    } else {
      if (indexParent > indexParentFirstNode) {
        iterateElementsAndChildrenAndPushElements(list, idx1Op, idx2Op, indexParent, indexParentFirstNode, returnList, keyIndexParentFirstNode);
      } else if (indexParent < indexParentFirstNode) {
        iterateElementsAndChildrenAndPushElements(list, idx1Op, idx2Op, indexParentFirstNode, indexParent, returnList, keyIndexParentFirstNode);
      } else {
        if (idx1Op < idx2Op) {
          iterateElementsAndChildren(list, idx2Op, idx1Op, indexParentFirstNode, indexParent, returnList, keyIndexParentFirstNode);
        } else {
          iterateElementsAndChildren(list, idx1Op, idx2Op, indexParentFirstNode, indexParent, returnList, keyIndexParentFirstNode);
        }
      }
    }
    returnGroupArray(returnList);
  }

  /*****************   FUNCIONES REUTILIZABLES PARA ITERAR LOS ELEMENTOS E HIJOS *************************/

  //Esta funcion cuando encuentra el elemento no lo pushea, y depende el idx1 o idx2 para recorrer los nodos
  function iterateElementsAndChildren(list: TreeNodes[], idx1: number, idx2: number, indexIterate: number, idx: number, returnList: TreeNodes[], key: string | undefined) {
    for (let i = idx; i <= indexIterate; i++) {
      const elem = list[i];
      if (elem.key === key) {
        for (let n = idx2; n <= idx1; n++) {
          const node = elem.nodes[n];
          returnList.push(node);
        }
      }
    }
  }

  //Esta funcion pushea el elemento cuando lo encuentra y recorre todos los nodos del mismo elemento.
  function iterateElementsAndChildrenAndPushElements(list: TreeNodes[], idx1: number, idx2: number, indexIterate: number, idx: number, returnList: TreeNodes[], key: string | undefined) {
    for (let i = idx; i <= indexIterate; i++) {
      const elem = list[i];
      returnList.push(elem);
      for (let n = 0; n < elem.nodes.length; n++) {
        const node = elem.nodes[n];
        returnList.push(node);
      }
    }
  }

  //Esta funcion solo recorre una lista, va a servir para reutilizar en todas las recorridas de los nodos hijos de los elementos, NO incluyendo con <= el indexIterate
  function iterateOnlyChildNodesNoInclude(list: TreeNodes[], returnList: TreeNodes[], iterate: number | null, idx: number) {
    const indexIterate = iterate === null ? list.length : iterate;
    for (let n = idx; n < indexIterate; n++) {
      const node = list[n];
      returnList.push(node);
    }
  }

  //Esta funcion solo recorre una lista, va a servir para reutilizar en todas las recorridas de los nodos hijos de los elementos, incluyendo con <= el indexIterate
  function iterateOnlyChildNodesInclude(list: TreeNodes[], returnList: TreeNodes[], iterate: number | null, idx: number) {
    const indexIterate = iterate === null ? list.length : iterate;
    for (let n = idx; n <= indexIterate; n++) {
      const node = list[n];
      returnList.push(node);
    }
  }

  /*****************  FIN FUNCIONES REUTILIZABLES PARA ITERAR LOS ELEMENTOS E HIJOS *************************/

  /******* Funciones de ayuda **********/

  //Obtenemos la key de los nodos padres
  function getKeyOfFatherNodes(list: TreeNodes[], node?: Item) {
    if (node !== undefined) {
      let parent = node.level === 1 ? node.key.split('/') : node.parent.split('/');
      let parentKey = parent[1];
      for (let idx = 0; idx < list.length; idx++) {
        const elem = list[idx];
        if (elem.key === parentKey) {
          return elem.key;
        }
      }
    }
  }

  //Obtenemos el indice de los nodos padres
  function getIndexOfFatherNodes(list: TreeNodes[], node?: Item) {
    if (node !== undefined) {
      let parent = node.level === 1 ? node.key.split('/') : node.parent.split('/');
      let parentKey = parent[1];
      for (let idx = 0; idx < list.length; idx++) {
        const elem = list[idx];
        if (elem.key === parentKey) {
          return idx;
        }
      }
    }
  }
  /******* Fin funciones de ayuda **********/


  //Esta funcion se encarga de obtener la lista de nodos que queremos seleccionar, y los setea en selectedRestriction, el cual viaja hacia RestrictionView con todos sus elementos para seleccionar dentro del Arbol.
  function returnGroupArray(array: TreeNodes[]) {
    array.forEach((i) => {
      let description = i.label.split('-').slice(1).join().trim();
      i.restriction.description = description;
      setSelectedRestriction((prevRestriction) => [...prevRestriction, i.restriction]);
    })
  }


  function findNodesAndReturnIndex(array: TreeNodes[], restriction: RestrictionObject): number | undefined {
    for (let idx = 0; idx < array.length; idx++) {
      const elem = array[idx];
      if (elem.restriction.id === restriction?.id) {
        return idx;
      } else {
        const result = findNodesAndReturnIndex(elem.nodes, restriction);
        if (result !== undefined) {
          return result;
        }
      }
    }
    return undefined;
  }

  useEffect(() => {
    const getProcessDate = async () => {
      const { fechaProceso } = await getSessionInfo();
      setFechaProceso(fechaProceso);
    }
    getProcessDate();
    activeEventListenerCtrl();
  }, []);

  const handleKeyDown = (event: KeyboardEvent) => {
    setModifiersPressed({
      ctrl: event.ctrlKey,
      shift: event.shiftKey,
    });
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    setModifiersPressed({
      ctrl: event.ctrlKey,
      shift: event.shiftKey,
    });
  };

  function activeEventListenerCtrl() {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }

  let clientProperties = store.getState().clientProperties;
  let limitDays = clientProperties?.diasInhabilitacionOperacion;
  if (limitDays) limitDays = parseInt(limitDays);

  function onPermissionClick(): void {
    onModalFormulaChange(true);
  }

  function onRadioChange(event: { target: HTMLInputElement }): void {
    let currentRestriction: RestrictionObject | [] = [];
    let ownPermission: number | null = null;
    if (selectedRestriction && selectedRestriction.length !== 0) {
      selectedRestriction.forEach((r) => {
        currentRestriction = restrictionsChanged[r.id] || r;
        ownPermission = event.target.value === NOT_ALLOWED ? 0 : event.target.value === ALLOWED ? 1 : 2;
        onRestrictionsChangedAdd([{ ...currentRestriction, ownPermission, allows: ownPermission }]);
      })
    }
  }

  function onEnabledDateChange(event: { target: HTMLInputElement }): void {
    if (event.target.value !== "") {
      let systemDate = createDateFromText(fechaProceso, 'yyyy-MM-dd');
      let targetDate = createDateFromText(event.target.value, 'yyyy-mm-dd');
      let diff = differenceInDays(systemDate, targetDate);
      if (diff < 0) {
        showErrorDateLessSystemDate();
      } else if (diff > limitDays) {
        showErrorDifference(diff);
      } else {
        setError(null);
        setRestrictionsHasErrors(false);
        let currentRestriction: RestrictionObject | undefined = undefined;

        if (selectedRestriction && selectedRestriction.length !== 0) {
          selectedRestriction.forEach((r) => {
            currentRestriction = restrictionsChanged[r.id] || r;
            onRestrictionsChangedAdd([{ ...currentRestriction, fechaHabilitacion: event.target.value }]);
          });
        }
      }
    } else {
      setError(null);
      setRestrictionsHasErrors(false);
    }
  }

  function showErrorDifference(diff: number) {
    const msg = (
      <div style={{ color: "red" }}>
        <FormattedMessage id="security.operationLimitDaysExceed" defaultMessage={`La fecha limite no puede exceder los`} />
        {` ${limitDays} `}
        <FormattedMessage id="security.operationLimitDaysDifference" defaultMessage={`días, diferencia seleccionada:`} />
        {` ${diff}`}
      </div>
    );

    setError(msg);
    setRestrictionsHasErrors(true);
  };

  function showErrorDateLessSystemDate() {
    const msg = (
      <div style={{ color: "red" }}>
        <FormattedMessage id="security.operationLimitDaysLessSystem" defaultMessage={`La fecha limite no puede ser menor a la fecha de sistema.`} />
      </div>
    );
    setError(msg);
    setRestrictionsHasErrors(true);
  };

  function onItemClick(node: Item): void {
    if (node.level !== 0) {
      let newLabel = node.label.split('-').slice(1).join().trim();
      node.restriction.description = newLabel;
      onSelectRestriction(node);
    }
  }

  function onRestoreChange(): void {
    onRestrictionsChangedRemove(selectedRestriction);
  }

  function onModalFormulaChange(isOpen: boolean): void {
    setIsModalFormulaOpen(isOpen);
  }

  return <RestrictionsView
    hasGroupPermissions={hasGroupPermissions}
    groupHelper={helperGroup}
    onHelperChange={onHelperChange}
    onModalFormulaChange={onModalFormulaChange}
    isModalFormulaOpen={isModalFormulaOpen}
    onRestoreChange={onRestoreChange}
    onItemClick={onItemClick}
    onEnabledDateChange={onEnabledDateChange}
    onRadioChange={onRadioChange}
    onPermissionClick={onPermissionClick}
    restrictionsChanged={restrictionsChanged}
    selectedGroup={selectedGroup}
    isEditing={isEditing}
    selectedRestriction={selectedRestriction}
    onRestrictionsChangedAdd={onRestrictionsChangedAdd}
    error={error}
    menu={menu}
    operations={operations}
    permissions={permissions}
    catalogs={catalogs}
    branches={branches}
    navigations={navigations}
    reports={reports}
    defaultRestriction={defaultRestriction}
  />
}
