import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { injectIntl } from 'react-intl';
import { storeActions } from '../../src/store';
import { getCurrentTabInformation } from '../../src/selectors';
import {
  getPersonSignatures,
  getPhotos,
  getPersonDocPhotoFromNumber,
  getCodesDocuments,
  isUserAuthorizerSignatures,
  getPhysicalPerson,
  saveSignauresInfo,
  savePhotosInfo,
  saveDocumentsImagesInfo,
  permiteAgregarPegarFirmas,
} from '../../src/api/signatures';
import { fileBase64ToArray, fileArrayToBase64 } from '../helpers/fileHelper';
import { getUserName } from '../../src/helpers/login';
import { dateFormatSignature, CHANGE_DUE_DATE, CHANGE_DOC_CODE, CHANGE_DOC_NUMBER } from '../../src/helpers/signaturesHelper';
import SignatureWindowLayout from './SignatureWindowLayout';
import ModalMessage from '../../src/components/ModalMessage';

const GET_SIGNATURE = 'FIRMA_CONSULTA';
const GET_PHOTO = 'FOTO_CONSULTA';
const GET_DOC = 'IMAGEN_DOCUMENTO_CONSULTA';

const MAINTENANCE_SIGNATURE = 'FIRMA_MANTENIMIENTO';
const MAINTENANCE_PHOTO = 'FOTO_MANTENIMIENTO';
const MAINTENANCE_DOC = 'IMAGEN_DOCUMENTO_MANTENIMIENTO';

const MAX_FILE_SIZE = 10 * 1024 * 1024;

export class SignatureWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWindow: true,
      loading: false,
      isUserAuthorizer: false,
      codesDocuments: [],
      personImages: [],
      physicalPerson: null,
      isConsult: false,
      isPhoto: false,
      isSignature: false,
      isDoc: false,
      showDialog: false,
      dialogMessage: '',
      showPreview: false,
      srcSignaturePreview: null,
      allowAddOrPaste: false,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChangeData = this.handleChangeData.bind(this);
    this.handleSearchFile = this.handleSearchFile.bind(this);
    this.handlePasteFile = this.handlePasteFile.bind(this);
    this.onPasteListener = this.onPasteListener.bind(this);
    this.inputFileChanged = this.inputFileChanged.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleAuthorize = this.handleAuthorize.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.handleShowPreview = this.handleShowPreview.bind(this);
    this.handleAddPersonImage = this.handleAddPersonImage.bind(this);
  }

  async componentDidMount() {
    const { data, pauseFocus } = this.props;
    pauseFocus();
    await this.fetchData(data);
  }

  handleShowPreview(signatureId) {
    const { personImages, isSignature, isPhoto, isDoc } = this.state;
    const indexImage = personImages.findIndex((personImage) => personImage.id === signatureId);
    let srcSignaturePreview = null;
    if (indexImage !== -1) {
      if (isSignature) srcSignaturePreview = personImages[indexImage].firma;
      else if (isPhoto || isDoc) srcSignaturePreview = personImages[indexImage].imagen;
      srcSignaturePreview = fileArrayToBase64(srcSignaturePreview);
    }
    this.setState((prevState) => ({
      showPreview: !prevState.showPreview,
      srcSignaturePreview,
    }));
  }

  hideDialog() {
    this.setState({ showDialog: false, dialogMessage: '' });
  }

  async handleAccept() {
    const { personImages, isPhoto, isSignature, isDoc } = this.state;
    const {
      data: { numeroPersona: numberPhysicalPerson },
      intl,
      resumeFocus,
      removeTreeNode,
      removeComponentTree,
    } = this.props;

    let imagesAdded = [];
    let imagesUpdated = [];
    let imagesRemoved = [];
    let dialogMessage = '';

    personImages.forEach((personImageData) => {
      let personImage = Object.assign({}, personImageData);
      const { original, updated, removed } = personImage;
      delete personImage.id;
      delete personImage.original;
      delete personImage.updated;
      delete personImage.removed;
      if (!original) imagesAdded.push(personImage);
      else if (removed) imagesRemoved.push(personImage);
      else if (updated) imagesUpdated.push(personImage);

      if (isDoc && personImage.numeroDocumento === '') {
        dialogMessage = intl.formatMessage({
          id: 'documentNumberCantBeEmpty',
          defaultMessage: 'El número de documento no puede ser una cadena vacía',
        });
      }
    });
    let setShowDialog = false;

    if (dialogMessage === '') {
      this.setState({ loading: true });

      if (isSignature)
        await saveSignauresInfo(numberPhysicalPerson, {
          firmasAgregadas: imagesAdded,
          firmasActualizadas: imagesUpdated,
          firmasEliminados: imagesRemoved,
        });
      else if (isPhoto)
        await savePhotosInfo(numberPhysicalPerson, {
          fotosAgregadas: imagesAdded,
          fotosActualizadas: imagesUpdated,
          fotosEliminados: imagesRemoved,
        });
      else if (isDoc)
        await saveDocumentsImagesInfo(numberPhysicalPerson, {
          documentosAgregadas: imagesAdded,
          documentosActualizadas: imagesUpdated,
          documentosEliminados: imagesRemoved,
        });

      this.setState((prevState) => ({
        showWindow: !prevState.showWindow,
        loading: false,
      }));
    } else {
      setShowDialog = true;
      this.setState({ showDialog: setShowDialog, dialogMessage });
    }

    if (setShowDialog === false) {
      resumeFocus();
      removeComponentTree(removeTreeNode);
    }
  }

  handleAuthorize(selectedId) {
    let { personImages } = this.state;

    const indexSelected = personImages.findIndex((personSignature) => personSignature.id === selectedId);
    personImages[indexSelected].autorizador = getUserName();
    personImages[indexSelected].updated = true;

    this.setState({ personImages });
  }

  handleRemove(selectedId) {
    let { personImages } = this.state;
    const indexImage = personImages.findIndex((personImage) => personImage.id === selectedId);
    if (!personImages[indexImage].original) personImages.splice(indexImage, 1);
    else personImages[indexImage].removed = true;

    this.setState({ personImages });
  }

  handleSearchFile(inputFile) {
    if (inputFile && this.state.allowAddOrPaste) inputFile.click();
  }

  handlePasteFile(inputFile) {
    if (this.state.allowAddOrPaste) document.addEventListener('paste', this.onPasteListener);
  }

  onPasteListener(event) {
    const { personImages, isPhoto, isSignature, isDoc } = this.state;
    let { data } = this.props;
    let { numeroPersona: numberPhysicalPerson } = data;
    var items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (let index in items) {
      var item = items[index];
      if (item.kind === 'file') {
        var blob = item.getAsFile();
        if (window.FileReader && blob.size <= MAX_FILE_SIZE) {
          let reader = new window.FileReader();
          reader.onload = (r) => {
            this.setState({ loading: true });
            let personImage = {};
            personImage.numeroPersonaFisica = numberPhysicalPerson;
            personImage.id = personImages.length + 1;
            personImage = {
              ...personImage,
              original: false,
              updated: false,
              removed: false,
            };
            if (isSignature) {
              personImage = {
                ...personImage,
                firma: fileBase64ToArray(r.target.result),
                numeroFirma: 0,
              };
            } else if (isPhoto) {
              personImage = {
                ...personImage,
                imagen: fileBase64ToArray(r.target.result),
                numeroFoto: 0,
              };
            } else if (isDoc) {
              personImage = {
                ...personImage,
                codigoDocumento: null,
                numeroImagen: 0,
                imagen: fileBase64ToArray(r.target.result),
                numeroDocumento: '',
              };
            }
            personImages.push(personImage);
            this.setState({ personImages, loading: false });
          };
          reader.readAsDataURL(blob);
        }
      }
    }
    document.removeEventListener('paste', this.onPasteListener);
  }

  inputFileChanged(e) {
    const { personImages, isPhoto, isSignature, isDoc } = this.state;
    let { data } = this.props;
    let { numeroPersona: numberPhysicalPerson } = data;

    let file = e.target.files[0];
    if (window.FileReader && file.size <= MAX_FILE_SIZE) {
      let reader = new window.FileReader();
      reader.onload = (r) => {
        this.setState({ loading: true });
        let personImage = {};
        personImage.numeroPersonaFisica = numberPhysicalPerson;
        personImage.id = personImages.length + 1;
        personImage = {
          ...personImage,
          original: false,
          updated: false,
          removed: false,
        };
        if (isSignature) {
          personImage = {
            ...personImage,
            firma: fileBase64ToArray(r.target.result),
            numeroFirma: 0,
          };
        } else if (isPhoto) {
          personImage = {
            ...personImage,
            imagen: fileBase64ToArray(r.target.result),
            numeroFoto: 0,
          };
        } else if (isDoc) {
          personImage = {
            ...personImage,
            codigoDocumento: null,
            numeroImagen: 0,
            imagen: fileBase64ToArray(r.target.result),
            numeroDocumento: '',
          };
        }
        personImages.push(personImage);
        this.setState({ personImages, loading: false });
      };
      reader.readAsDataURL(file);
    }
  }

  handleAddPersonImage(personImage) {
    const { personImages } = this.state;
    let completePersonImage = {};
    completePersonImage.id = personImages.length + 1;
    completePersonImage = {
      ...personImage,
      original: false,
      updated: false,
      removed: false,
    };
    personImages.push(completePersonImage);
    this.setState({
      personImages,
      loading: false,
    });
  }

  handleChangeData(idSelected, value, typeData) {
    let { personImages } = this.state;
    const indexSelected = personImages.findIndex((personImage) => personImage.id === idSelected);
    personImages[indexSelected].updated = true;
    if (typeData === CHANGE_DUE_DATE) personImages[indexSelected].vencimiento = value;
    else if (typeData === CHANGE_DOC_CODE) personImages[indexSelected].codigoDocumento = parseInt(value, 10);
    else if (typeData === CHANGE_DOC_NUMBER) personImages[indexSelected].numeroDocumento = value;
    this.setState({ personImages });
  }

  handleCancel() {
    const { resumeFocus, removeTreeNode, removeComponentTree } = this.props;
    resumeFocus();
    this.setState((prevState) => ({ showWindow: !prevState.showWindow }));
    removeComponentTree(removeTreeNode);
  }

  async fetchData(data) {
    let { numeroPersona: numberPhysicalPerson, codigoDocumento: codeDoc, modo: mode, numeroDocumento: nroDocumento } = data;
    let isConsult = true;
    if (
      mode === MAINTENANCE_SIGNATURE ||
      mode === 'IMAGEN_DOCUMENTO_MANTENIMIENTO_CON_DATOS' ||
      mode === MAINTENANCE_PHOTO ||
      mode === MAINTENANCE_DOC
    )
      isConsult = false;

    let isPhoto = mode === MAINTENANCE_PHOTO || mode === GET_PHOTO;
    let isSignature = mode === MAINTENANCE_SIGNATURE || mode === GET_SIGNATURE;
    let isDoc = mode === MAINTENANCE_DOC || mode === GET_DOC || mode === 'IMAGEN_DOCUMENTO_MANTENIMIENTO_CON_DATOS';

    if (data) {
      let personImages = [];
      let isUserAuthorizer = false;
      let codesDocuments = [];
      let physicalPerson = await getPhysicalPerson(numberPhysicalPerson);
      this.setState({
        loading: true,
        isConsult,
        physicalPerson,
        isPhoto,
        isSignature,
        isDoc,
      });
      if (mode === MAINTENANCE_SIGNATURE || mode === GET_SIGNATURE) {
        personImages = await getPersonSignatures(numberPhysicalPerson);
        isUserAuthorizer = isConsult ? false : await isUserAuthorizerSignatures();
      } else if (mode === MAINTENANCE_PHOTO || mode === GET_PHOTO) {
        personImages = await getPhotos(numberPhysicalPerson);
      } else if (mode === MAINTENANCE_DOC || mode === GET_DOC || mode === 'IMAGEN_DOCUMENTO_MANTENIMIENTO_CON_DATOS') {
        personImages = await getPersonDocPhotoFromNumber(numberPhysicalPerson, codeDoc, nroDocumento);
        codesDocuments = await getCodesDocuments(codeDoc);
      }
      personImages = personImages.map((personImage, index) => {
        if (isSignature && personImage.vencimiento) personImage.vencimiento = dateFormatSignature(personImage.vencimiento);
        return {
          ...personImage,
          id: index,
          original: true,
          updated: false,
          removed: false,
        };
      });
      this.setState({
        personImages,
        isUserAuthorizer,
        codesDocuments,
        loading: false,
      });
    }

    let canAddOrPaste = await permiteAgregarPegarFirmas();
    this.setState({
      allowAddOrPaste: canAddOrPaste,
    });
  }

  render() {
    let {
      showWindow,
      loading,
      personImages,
      isUserAuthorizer,
      physicalPerson,
      isConsult,
      isPhoto,
      isSignature,
      isDoc,
      codesDocuments,
      showDialog,
      dialogMessage,
      showPreview,
      srcSignaturePreview,
    } = this.state;
    const { data } = this.props;

    return (
      <div>
        <SignatureWindowLayout
          showWindow={showWindow}
          loading={loading}
          personImages={personImages}
          isUserAuthorizer={isUserAuthorizer}
          codesDocuments={codesDocuments}
          physicalPerson={physicalPerson}
          isConsult={isConsult}
          data={data}
          isPhoto={isPhoto}
          isSignature={isSignature}
          isDoc={isDoc}
          showDialog={showDialog}
          dialogMessage={dialogMessage}
          handleChangeData={this.handleChangeData}
          handleSearchFile={this.handleSearchFile}
          handlePasteFile={this.handlePasteFile}
          handleRemove={this.handleRemove}
          handleAuthorize={this.handleAuthorize}
          handleCancel={this.handleCancel}
          handleAccept={this.handleAccept}
          inputFileChanged={this.inputFileChanged}
          hideDialog={this.hideDialog}
          showPreview={showPreview}
          srcSignaturePreview={srcSignaturePreview}
          handleShowPreview={this.handleShowPreview}
          addPersonImage={this.handleAddPersonImage}
          allowAddOrPaste={this.state.allowAddOrPaste}
        />
        {showDialog && console.log(showDialog) && (
          <ModalMessage
            message={<div className="flex w-full h-full justify-center items-center">{dialogMessage}</div>}
            acceptTitle={'Aceptar'}
            cancelTitle={'Cancelar'}
            handleAccept={this.setState({ showDialog: false })}
            handleCancel={this.setState({ showDialog: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { operationID, focusOn } = getCurrentTabInformation(state);

  return {
    operationID,
    focusOn,
  };
};

export default connect(mapStateToProps, storeActions)(injectIntl(SignatureWindow));
