import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { useTheme } from './../../../themes/ThemeContext';

const CheckInfo = ({ data }) => {
  const themeContext = useTheme();
  const { background, border, text } = themeContext.theme;

  const styles = StyleSheet.create({
    checkInfoContainer: {
      borderBottom: `1px solid ${border.dark}`,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      textAlign: 'center',
      borderRadius: 2,
      backgroundImage: `linear-gradient(to bottom left, ${background.light2}, ${background.light})`,
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
    },
    checkHeader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    statusLabel: {
      width: '100%',
      padding: '.4rem 0',
      fontSize: '.75rem',
      backgroundColor: '#A5D5AD',
      color: '#3A8C44',
      border: '1px solid #A5D5AD',
    },
    checkBody: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem 1.5rem',
      border: `1px solid ${border.light}`,
      color: text.text,
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'inherit',
    },
    accountInfo: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'space-evenly',
      flex: 1,
    },
    amountInfo: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-evenly',
      flex: 1,
    },
    checkFooter: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    labelCell: {
      fontSize: '.7rem',
      userSelect: 'none',
      color: text.light,
    },
    numberCellSmall: {
      fontSize: '.8rem',
    },
    numberCell: {
      fontSize: '.8rem',
    },
  });

  const checkNumber = data.checkNumber ? new Intl.NumberFormat().format(data.checkNumber) : '';

  const currency = data.currency !== '' && new Intl.NumberFormat().format(data.currency);

  const amount = data.amount
    ? new Intl.NumberFormat(undefined, {
        style: 'decimal',
        useGrouping: 'true',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(data.amount)
    : '';

  return (
    <div className={css(styles.checkInfoContainer)}>
      <div className={css(styles.checkHeader)}>
        <div className={css(styles.statusLabel)}>VALIDADO</div>
      </div>
      <div className={css(styles.checkBody)}>
        <div className={css(styles.accountInfo)}>
          <div className={css(styles.item)}>
            <div className={css(styles.labelCell)}>SERIE</div>
            <div className={css(styles.numberCell)}>{data.serie}</div>
          </div>
          <div className={css(styles.item)}>
            <div className={css(styles.labelCell)}>NÚMERO</div>
            <div className={css(styles.numberCell)}>{checkNumber}</div>
          </div>
          <div className={css(styles.item)}>
            <div className={css(styles.labelCell)}>CUENTA</div>
            <div className={css(styles.numberCell)}>{data.account}</div>
          </div>
        </div>
        <div className={css(styles.amountInfo)}>
          <div className={css(styles.item)}>
            <div className={css(styles.labelCell)}>IMPORTE</div>
            <div className={css(styles.numberCell)}>{amount}</div>
          </div>
          <div className={css(styles.item)}>
            <div className={css(styles.labelCell)}>MONEDA</div>
            <div className={css(styles.numberCell)}>{currency}</div>
          </div>
        </div>
        <div className={css(styles.amountInfo)}>
          <div className={css(styles.item)}>
            <div className={css(styles.labelCell)}>ESTADO</div>
            <div className={css(styles.numberCell)}>{data.status}</div>
          </div>
          <div className={css(styles.item)}>
            <div className={css(styles.labelCell)}>ESTADO DEVOLUCIÓN</div>
            <div className={css(styles.numberCellSmall)}>{data.statusDevolucion}</div>
          </div>
        </div>
        <div className={css(styles.amountInfo)}>
          <div className={css(styles.item)}>
            <div className={css(styles.labelCell)}>DESCRIPCIÓN DEVOLUCIÓN</div>
            <div className={css(styles.numberCell)}>-</div>
          </div>
          <div className={css(styles.item)}>
            <div className={css(styles.labelCell)}>CAUSAL DEVOLUCIÓN</div>
            <div className={css(styles.numberCellSmall)}>{data.causeDevolucion}</div>
          </div>
          <div className={css(styles.item)}>
            <div className={css(styles.labelCell)}>DESCRIPCIÓN DEVOLUCIÓN</div>
            <div className={css(styles.numberCellSmall)}>{data.descriptionDevolucion}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckInfo;
