import React, { Component } from 'react';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';
import { FormattedMessage } from 'react-intl';
import { ABOVE_ALL } from '../../UI-kit/constants/zIndexValues';
import Overlay from '../../UI-kit/components/Overlay';
import DatePicker from 'react-datepicker';
import { dateFormatSearcher } from '../helpers/workflowHelper';
import { addValueTest, addWorkFlowValue } from '../../src/components/Testing/HandleLineTest';

const Button = ({ title, click, className, disabled, id }) => {
  return (
    <button
      id={id}
      className={`${className} ${
        !disabled ? 'bg-grey focus:outline-none focus:bg-primary hover:bg-primary' : 'bg-smoke'
      } text-white rounded shadow m-2 py-2 px-4 text-sm focus:outline-none`}
      onClick={click}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

const FieldData = ({ title, children }) => (
  <div className="flex w-full m-2 justify-between text-sm">
    <label className="text-sm w-32">{title}</label>
    {children}
  </div>
);

export class WorkflowSearcherWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [],
      status: '',
      assigned: '',
      solvedBy: '',
      startDate: null,
      endDate: null,
    };

    this.changeHeaders = this.changeHeaders.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeAssigned = this.changeAssigned.bind(this);
    this.changeSolvedBy = this.changeSolvedBy.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleCancelSearch = this.handleCancelSearch.bind(this);
  }

  componentDidMount() {}

  changeHeaders(indicator, value) {
    let { headers } = this.state;
    headers[indicator] = value;
    this.setState({ headers });
  }
  changeStatus(status) {
    this.setState({ status });
  }
  changeAssigned(assigned) {
    this.setState({ assigned });
  }
  changeSolvedBy(solvedBy) {
    this.setState({ solvedBy });
  }
  changeStartDate(startDate) {
    this.setState({ startDate });
  }
  changeEndDate(endDate) {
    this.setState({ endDate });
  }
  handleCancelSearch() {
    const { handleCancel } = this.props;
    handleCancel();
  }

  handleAccept() {
    let { handleSearch, columnsDetails, isChild } = this.props;
    let { headers, status, assigned, solvedBy, startDate, endDate } = this.state;

    let names = [];
    let headersNames = [];
    if (isChild) {
      names = columnsDetails.map((column) => column.childIndicator || column.Header);

      columnsDetails &&
        columnsDetails.forEach((col) => {
          if (col.childIndicator && !headers[col.childIndicator]) headers[col.childIndicator] = '';
        });

      columnsDetails &&
        columnsDetails.forEach((col) => {
          if (col.childIndicator) {
            headersNames[col.childIndicator] =
              headers[col.childIndicator] !== undefined && headers[col.childIndicator] !== null ? headers[col.childIndicator] : '';
          }
        });
    } else {
      names = columnsDetails.map((column) => column.searchIndicator || column.Header);

      columnsDetails &&
        columnsDetails.forEach((col) => {
          if (col.searchIndicator && !headers[col.searchIndicator]) headers[col.searchIndicator] = '';
        });

      columnsDetails &&
        columnsDetails.forEach((col) => {
          if (col.searchIndicator) {
            headersNames[col.searchIndicator] =
              headers[col.searchIndicator] !== undefined && headers[col.searchIndicator] !== null ? headers[col.searchIndicator] : '';
          }
        });
    }

    let keysHeader = Object.keys(headersNames);
    let valuesHeader = keysHeader.map((k) => headersNames[k]);
    let values = [];
    if (isChild) {
      values = [...valuesHeader];
    } else {
      values = [
        ...valuesHeader,
        status,
        assigned,
        solvedBy,
        startDate ? dateFormatSearcher(startDate) : '',
        endDate ? dateFormatSearcher(endDate) : '',
      ];
    }

    handleSearch(names, values);
  }

  render() {
    const { showWindow } = this.props;
    const { headers, status, assigned, solvedBy, startDate, endDate } = this.state;
    const { columnsDetails, isChild } = this.props;

    const classNameInput = 'appearance-none px-1 rounded shadow text-sm w-full';
    return (
      <div className={`${showWindow ? '' : 'hidden'}`}>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className="border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute"
            style={{ zIndex: ABOVE_ALL, width: 350, top: 100, left: 50 }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l text-xs handleDragg text-smoke cursor-move m-1" />
            <div className="w-full h-full">
              <div className="flex flex-col text-left px-4 py-1 text-lg h-full">
                <div className="mb-2 text-center text-base text-primary">
                  <FormattedMessage id="instanceFinder" defaultMessage={`Buscador de Instancias`} />
                </div>
                <div className="flex flex-col w-full">
                  {columnsDetails &&
                    columnsDetails.map(
                      (col, index) =>
                        (col.searchIndicator || col.childIndicator) && (
                          <FieldData title={col.Header} key={index}>
                            <input
                              id={`Buscador${index}`}
                              type="text"
                              className={`${classNameInput} `}
                              value={headers[col.searchIndicator]}
                              onChange={(e) => {
                                addWorkFlowValue('SENDTEXT', e.currentTarget.id, e.currentTarget.value);
                                if (isChild) {
                                  this.changeHeaders(col.childIndicator, e.target.value);
                                } else {
                                  this.changeHeaders(col.searchIndicator, e.target.value);
                                }
                              }}
                            />
                          </FieldData>
                        ),
                    )}
                  {!isChild ? (
                    <React.Fragment>
                      <FieldData title={<FormattedMessage id="state" defaultMessage={`Estado`} />}>
                        <input
                          id="BuscadorChildEstado"
                          type="text"
                          className={`${classNameInput} `}
                          value={status}
                          onChange={(e) => {
                            addWorkFlowValue('SENDTEXT', e.currentTarget.id, e.currentTarget.value);
                            this.changeStatus(e.target.value);
                          }}
                        />
                      </FieldData>
                      <FieldData title={<FormattedMessage id="assigned" defaultMessage={`Asignado`} />}>
                        <input
                          id="BuscadorChildAsignado"
                          type="text"
                          className={`${classNameInput} `}
                          value={assigned}
                          onChange={(e) => {
                            addWorkFlowValue('SENDTEXT', e.currentTarget.id, e.currentTarget.value);
                            this.changeAssigned(e.target.value);
                          }}
                        />
                      </FieldData>
                      <FieldData title={<FormattedMessage id="solvedBy" defaultMessage={`Resuelto por`} />}>
                        <input
                          id="BuscadorChildResueltoPor"
                          type="text"
                          className={`${classNameInput} `}
                          value={solvedBy}
                          onChange={(e) => {
                            addWorkFlowValue('SENDTEXT', e.currentTarget.id, e.currentTarget.value);
                            this.changeSolvedBy(e.target.value);
                          }}
                        />
                      </FieldData>
                      <FieldData title={<FormattedMessage id="startDate" defaultMessage={`Fecha Inicio`} />}>
                        <DatePicker
                          id="BuscadorChildFechaInicio"
                          className={`${classNameInput} `}
                          dateFormat={'dd/MM/yyyy'}
                          selected={startDate}
                          onChange={(date) => {
                            addWorkFlowValue('SENDTEXT', 'BuscadorChildFechaInicio', date);
                            this.changeStartDate(date);
                          }}
                        />
                      </FieldData>
                      <FieldData title={<FormattedMessage id="endDate" defaultMessage={`Fecha Fin`} />}>
                        <DatePicker
                          id="BuscadorChildFechaFin"
                          className={`${classNameInput} `}
                          dateFormat={'dd/MM/yyyy'}
                          selected={endDate}
                          onChange={(date) => {
                            addWorkFlowValue('SENDTEXT', 'BuscadorChildFechaFin', date);
                            this.changeEndDate(date);
                          }}
                        />
                      </FieldData>
                    </React.Fragment>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="flex w-full">
                  <Button
                    id="SearchAccept"
                    title={<FormattedMessage id="search" defaultMessage={`Buscar`} />}
                    disabled={!columnsDetails}
                    click={(e) => {
                      addValueTest('CLICK', 'SearchAccept', '');
                      this.handleAccept();
                    }}
                  />
                  <Button
                    id="SearchCancel"
                    title={<FormattedMessage id="cancel" defaultMessage={`Cancelar`} />}
                    click={(e) => {
                      addValueTest('CLICK', 'SearchCancel', '');
                      this.handleCancelSearch();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      </div>
    );
  }
}

export default WorkflowSearcherWindow;
