import React, { useState, useEffect } from 'react';
import PostingList from './PostingList';
import PostingManager from './PostingManager';

const PostingViewer = (props) => {
  const { setFooterInfo } = props;

  const [showPostingManager, setShowPostingManager] = useState(false);
  const [postingData, setPostingData] = useState();
  const [bodyGetPostings, setbodyGetPostings] = useState();
  const [showPostingList, setShowPostingList] = useState(true);
  const [dataSearcher, setDataSearcher] = useState({
    pendingOfSearcher: 65,
    bodySearcher: {
      allRecords: true,
      branchGroup: null,
      description: null,
      machineNumber: null,
      operationNumber: null,
      postingBegin: null,
      postingEnd: null,
      processDate: null,
      user: null,
    },
  });

  useEffect(() => {
    return () => {
      setFooterInfo();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePostingManager = (postingData = null, bodyGetPostings = null) => {
    setShowPostingList(false);
    setShowPostingManager(true);
    setPostingData(postingData);
    setbodyGetPostings(bodyGetPostings);
  };

  const hidePostingManager = () => {
    setShowPostingManager(false);
    setShowPostingList(true);
  };

  return showPostingManager && postingData ? (
    <PostingManager postingData={postingData} bodyGetPostings={bodyGetPostings} hidePostingManager={hidePostingManager} />
  ) : (
    <PostingList
      showPostingList={showPostingList}
      setFooterInfo={setFooterInfo}
      dataSearcher={dataSearcher}
      setDataSearcher={setDataSearcher}
      handlePostingManager={handlePostingManager}
    />
  );
};

export default PostingViewer;
