import { store } from '../../../store';
import { getBitacoras, getBitacorasCount } from '../../../api/bitacora';
import { formatDateForServer } from './filterFields';
import { getButtonsState } from './paginado';

const getBitacoraFilterResult = (filterParams, filterListID, intl, setDisabledButtonPrev, setDisabledButtonNext, setLoading) => {
  let {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    codigo,
    descripcion,
    programa,
    iniciales,
    mnemotecnico,
    usuariored,
    macaddress,
    maquina,
    sucursal,
    operacion,
    direccionIP,
    persistenceUnit,
  } = filterParams;

  if (store.getState().bitacoraApp[filterListID]) {
    filterParams = { ...store.getState().bitacoraApp[filterListID].filterParams, ...filterParams };
  }
  let filterData = {};
  filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
  store.setState({
    bitacoraApp: {
      ...store.getState().bitacoraApp,
      ...filterData,
    },
  });

  let defaultValue;
  fechaHoraDesde = formatDateForServer(fechaHoraDesde);
  fechaHoraHasta = formatDateForServer(fechaHoraHasta);
  firstResult = firstResult || 0;
  maxResults = maxResults || 201;
  codigo = codigo || defaultValue;
  descripcion = descripcion || defaultValue;
  programa = programa || defaultValue;
  mnemotecnico = mnemotecnico || defaultValue;
  usuariored = usuariored || defaultValue;
  macaddress = macaddress || defaultValue;
  direccionIP = direccionIP || defaultValue;
  maquina = maquina || defaultValue;
  sucursal = sucursal || defaultValue;
  operacion = operacion || defaultValue;
  persistenceUnit = persistenceUnit || 'Local';
  let usuario = iniciales || defaultValue;

  const params = {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    codigo,
    descripcion,
    programa,
    usuario,
    mnemotecnico,
    usuariored,
    macaddress,
    maquina,
    sucursal,
    operacion,
    direccionIP,
    persistenceUnit,
  };
  const resultColumns = [
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_fechahora',
        defaultMessage: 'Fecha Hora',
      }),
      accessor: 'fechahora',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 4,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_maquina',
        defaultMessage: 'Nº de Máquina',
      }),
      accessor: 'nromaquina',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 50,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_codigo',
        defaultMessage: 'Código',
      }),
      accessor: 'codigo',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_descripcion',
        defaultMessage: 'Descripción',
      }),
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_programa',
        defaultMessage: 'Programa',
      }),
      accessor: 'programa',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_usuario',
        defaultMessage: 'Usuario',
      }),
      accessor: 'usuario',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_iniciales',
        defaultMessage: 'Iniciales',
      }),
      accessor: 'usuario',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_mnemotecnico',
        defaultMessage: 'Mnemotecnico',
      }),
      accessor: 'mnemotecnico',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_sucursal',
        defaultMessage: 'Sucursal',
      }),
      accessor: 'sucursal',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_operacion',
        defaultMessage: 'Operación',
      }),
      accessor: 'operacion',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_usuariored',
        defaultMessage: 'Usuario de Red',
      }),
      accessor: 'usuariored',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_mac_address',
        defaultMessage: 'Mac Address',
      }),
      accessor: 'macaddress',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_ip',
        defaultMessage: 'Dirección IP',
      }),
      accessor: 'direccionip',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
  ];
  getBitacorasCount(params)
    .then((countData) => {
      getBitacoras(params)
        .then((data) => {
          let buttonNextState, buttonPrevState;
          [buttonNextState, buttonPrevState] = getButtonsState(countData, firstResult);
          let filterData = {};
          filterData[filterListID] = {
            ...store.getState().bitacoraApp[filterListID],
            resultColumns,
            resultData: data,
            currentPage: Math.ceil(firstResult / maxResults) + 1,
            pageCount: Math.ceil(countData / maxResults),
            resultCount: countData,
            buttonNextState,
            buttonPrevState,
          };
          setDisabledButtonNext(buttonNextState);
          setDisabledButtonPrev(buttonPrevState);
          store.setState({
            bitacoraApp: {
              ...store.getState().bitacoraApp,
              ...filterData,
            },
          });
          if (setLoading) {
            setLoading(false);
          }
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          console.log(err);
        });
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
      console.log(err);
    });
};

export { getBitacoraFilterResult };
