/* eslint-disable camelcase */
import { store } from '../../../store';
import { getBitacorasUsuarios, getBitacorasUsuariosCount } from '../../../api/bitacora';
import { formatDateForServer } from './filterFields';
import { getButtonsState } from './paginado';

const getUsuarioFilterResult = (filterParams, filterListID, intl, setDisabledButtonPrev, setDisabledButtonNext, setLoading) => {
  let {
    fechaHoraDesde,
    fechaHoraHasta,
    usuario,
    iniciales,
    grupo,
    sucursal,
    maquina,
    inicialesmodificador,
    persistenceUnit,
    begin,
    maxResults,
  } = filterParams;

  let filterData = {};
  filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
  store.setState({
    bitacoraApp: {
      ...store.getState().bitacoraApp,
      ...filterData,
    },
  });

  let defaultValue;
  fechaHoraDesde = formatDateForServer(fechaHoraDesde);
  fechaHoraHasta = formatDateForServer(fechaHoraHasta);
  maxResults = maxResults || 201;
  iniciales = iniciales || defaultValue;
  grupo = grupo || defaultValue;
  begin = begin || 0;
  persistenceUnit = persistenceUnit || 'Local';
  let clave = usuario || defaultValue;
  let nrosucursal = sucursal || defaultValue;
  let numerodemaquina = maquina || defaultValue;
  let clave_modificador = inicialesmodificador || defaultValue;

  const params = {
    fechaHoraDesde,
    fechaHoraHasta,
    clave,
    iniciales,
    grupo,
    nrosucursal,
    numerodemaquina,
    clave_modificador,
    persistenceUnit,
    begin,
    maxResults,
    intl,
  };

  const resultColumns = [
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_fechahora',
        defaultMessage: 'Fecha Hora',
      }),
      accessor: 'fecha_hora_modificado',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 4,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_usuario',
        defaultMessage: 'Usuario',
      }),
      accessor: 'clave',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 50,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'iniciales',
        defaultMessage: 'Iniciales',
      }),
      accessor: 'iniciales',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_Campos_Modificados',
        defaultMessage: 'Campos Modificados',
      }),
      accessor: 'camposModificados',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_Usuario_Modificador',
        defaultMessage: 'Usuario Modificador',
      }),
      accessor: 'clave_modificador',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'inicialesmodificador',
        defaultMessage: 'Iniciales Modificador',
      }),
      accessor: 'inicialesModificador',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_Nombre_Modificador',
        defaultMessage: 'Nombre Modificador',
      }),
      accessor: 'nombreModificador',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_usuariored',
        defaultMessage: 'Usuario de Red',
      }),
      accessor: 'usuarioRed',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_mac_address',
        defaultMessage: 'Mac Address',
      }),
      accessor: 'macaddress',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_ip',
        defaultMessage: 'Dirección IP',
      }),
      accessor: 'direccionip',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
  ];

  getBitacorasUsuariosCount(params)
    .then((countData) => {
      getBitacorasUsuarios(params)
        .then((data) => {
          let buttonNextState, buttonPrevState;
          [buttonNextState, buttonPrevState] = getButtonsState(countData, begin);
          let filterData = {};
          filterData[filterListID] = {
            ...store.getState().bitacoraApp[filterListID],
            resultColumns,
            ...data,
            currentPage: Math.ceil(begin / maxResults) + 1,
            pageCount: Math.ceil(countData / maxResults),
            resultCount: countData,
            buttonNextState,
            buttonPrevState,
          };
          setDisabledButtonNext(buttonNextState);
          setDisabledButtonPrev(buttonPrevState);
          store.setState({
            bitacoraApp: {
              ...store.getState().bitacoraApp,
              ...filterData,
            },
          });
          if (setLoading) {
            setLoading(false);
          }
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          console.log(err);
        });
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};

export { getUsuarioFilterResult };
