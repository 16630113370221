import React, { Component } from 'react';

class CausesListEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
    this.getCheckState = this.getCheckState.bind(this);
    this.setStatesChecks = this.setStatesChecks.bind(this);
  }

  getCheckState() {
    return this.state.selected;
  }

  setStatesChecks(stateCheck) {
    this.setState({ selected: stateCheck });
  }

  render() {
    const { item, clickHandler, addToRefs } = this.props;
    return (
      <div className="flex items-center justify-between border-t border-smoke py-2 h-10 cursor-pointer">
        <div className="w-5 ml-2">
          <input
            name={item.value}
            type="checkbox"
            ref={(ref) => addToRefs(ref)}
            onChange={() => {
              if (this.state.selected) {
                this.setState({ selected: false });
                clickHandler(item, false);
              } else {
                this.setState({ selected: true });
                clickHandler(item, true);
              }
            }}
          />
        </div>
        <div className="w-24 pr-2"></div>
        <div className="flex-grow items-center ml-30 w-10 text-xs text-nowrap">
          {' '}
          {item.causal}
        </div>
      </div>
    );
  }
}

export default CausesListEntry;
