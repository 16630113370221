import * as React from 'react';
import { useIntl } from 'react-intl';
import { THIS_MONTH, THIS_WEEK, TODAY, YESTERDAY } from '../../../models/constants';
import { SelectData } from '../../select/Select';
import { HistoryFilterOptionsView } from './HistoryFilterOptionsView';

interface HistoryFilterOptionsViewModelProps {
  onSelectedChange: (date: string) => void;
  selectedState: string;
}

export function HistoryFilterOptionsViewModel({ onSelectedChange, selectedState }: HistoryFilterOptionsViewModelProps): JSX.Element {
  const intl = useIntl();
  const options: SelectData[] = [
    {
      label: intl.formatMessage({ id: 'today' }),
      value: TODAY,
    },
    {
      label: intl.formatMessage({ id: 'yesterday' }),
      value: YESTERDAY,
    },
    {
      label: intl.formatMessage({ id: 'aWeekAgo' }),
      value: THIS_WEEK,
    },
    {
      label: intl.formatMessage({ id: 'aMonthAgo' }),
      value: THIS_MONTH,
    },
  ];

  return <HistoryFilterOptionsView options={options} onSelectedChange={onSelectedChange} selectedState={selectedState} />;
}
