import React, { Component } from 'react';
// import Draggable from 'react-draggable';
import FocusTrap from 'react-focus-trap';
import Overlay from './Overlay';
import { ABOVE_ALL } from '../constants/zIndexValues';
import FormulaHelpWindow from './FormulaHelpWindow';

export class FormulaWindow extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      formula: props.inputValue,
      showingHelp: false,
    };
    this.addHelpValue = this.addHelpValue.bind(this);
  }

  buttonHandler = (charsToAdd) => {
    let modifiedFormula;
    if (this.input.current.selectionStart || this.input.current.selectionStart === '0') {
      const startPosition = this.input.current.selectionStart;
      const endPosition = this.input.current.selectionEnd;
      modifiedFormula =
        this.input.current.value.substring(0, startPosition) +
        charsToAdd +
        this.input.current.value.substring(endPosition, this.input.current.value.length);
    } else {
      modifiedFormula = this.input.current.value + charsToAdd;
    }
    this.input.current.value = modifiedFormula;
    this.input.current.focus();
  };

  componentDidMount() {
    let { inputValue } = this.props;
    this.input.current.value = inputValue;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inputValue !== this.props.inputValue) {
      this.input.current.value = this.props.inputValue;
    }
  }

  toggleWindow() {
    this.setState({ showingHelp: !this.state.showingHelp });
  }

  addHelpValue(value) {
    this.buttonHandler(value);
    this.toggleWindow();
    this.input.current.focus();
  }

  render() {
    /* let {
      cancelHandler,
      fieldNumber,
      acceptHandler,
      transactionLineLabel,
      inputValue,
      lineNumber,
      transactionNumber,
      focusOn,
    } = this.props; */
    let { nodeId, transactionLineLabel, fieldNumber, transactionNumber, lineNumber, inputValue } = this.props;

    let { cancelHandler, acceptHandler } = this.props;

    // let hasFocus = focusOn[0] === transactionLineLabel;
    if (this.input && this.input.current) this.input.current.focus();
    return (
      <FocusTrap>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <div
          className={`border border-solid bg-grey-lighter-lighter rounded shadow min-w-400 min-h-200 absolute`}
          style={{
            top: 10,
            left: 40,
            width: '500px',
            height: '305px',
            zIndex: ABOVE_ALL,
          }}
        >
          <div className={'flex justify-around'}>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={
                () => {
                  this.buttonHandler('+');
                }
                // call addToFieldFunction
              }
            >
              +
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('-');
              }}
            >
              -
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('*');
              }}
            >
              *
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('F(DivZero, , )');
              }}
            >
              Div
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('^');
              }}
            >
              ^
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('<');
              }}
            >
              &lt;
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('>');
              }}
            >
              &gt;
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('=');
              }}
            >
              =
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('#');
              }}
            >
              &lt; &gt;
            </button>
          </div>
          <div className={'flex justify-around'}>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('(');
              }}
            >
              (
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler(')');
              }}
            >
              )
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('Y');
              }}
            >
              AND
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('O');
              }}
            >
              OR
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('!');
              }}
            >
              NOT
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('F(IfNumeric, , , )');
              }}
            >
              If-N
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('F(IfAlfa, , , )');
              }}
            >
              If-A
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.buttonHandler('F(IfDate, , , )');
              }}
            >
              If-D
            </button>
            <button
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={() => {
                this.toggleWindow();
              }}
            >
              ?
            </button>
          </div>
          <div>
            <textarea
              autoFocus
              ref={this.input}
              className={`px-1 m-2 absolute`}
              style={{
                width: '475px',
                height: '150px',
                maxHeight: '150px',
              }}
              onBlur={(e) => {
                if (e.relatedTarget === null || e.relatedTarget.classList.contains('focus-trap')) {
                  this.input.current.focus();
                }
              }}
            ></textarea>
          </div>
          <div>
            <button
              className={`text-white rounded mt-32 m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              style={{
                marginTop: '12rem',
              }}
              onClick={() => {
                acceptHandler({
                  [transactionLineLabel]: {
                    value: this.input.current.value,
                  },
                });
              }}
            >
              Aceptar
            </button>
            <button
              style={{
                marginTop: '12rem',
              }}
              className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
              onClick={cancelHandler}
            >
              Cancelar
            </button>
          </div>
        </div>
        {this.state.showingHelp && (
          <FormulaHelpWindow
            nodeId={nodeId}
            showingHelp={this.state.showingHelp}
            cancelHandler={() => {
              // removeFromWindowStack(transactionLineLabel);
              this.toggleWindow();
            }}
            acceptHandler={this.addHelpValue}
            fieldNumber={fieldNumber}
            transactionLineLabel={transactionLineLabel}
            inputValue={inputValue}
            transactionNumber={transactionNumber}
            lineNumber={lineNumber}
          />
        )}
      </FocusTrap>
    );
  }
}
