import React from 'react';
import { useTheme } from '../../themes/ThemeContext';
import Option from './OptionView';
import { useIntl } from 'react-intl';
import SignOutIcon from './../../resources/icons/fa/sign-out-alt.svg';
import { css, StyleSheet } from 'aphrodite';

const LogoutButton = ({ isCollapsed, onClick }) => {
  const intl = useIntl();

  const themeState = useTheme();
  const { error } = themeState.theme;

  const title = intl.formatMessage({
    id: 'closeSession',
    defaultMessage: 'Cerrar sesión',
  });

  const styles = StyleSheet.create({
    logoutButton: {
      marginTop: 'auto',
      marginBottom: '1.6rem',
    },
  });

  return (
    <div className={css(styles.logoutButton)}>
      <Option
        title={title}
        collapsed={isCollapsed}
        click={onClick}
        icon={SignOutIcon}
        hoverColor={error.error}
        activeColor={error.error}
      />
    </div>
  );
};

export default LogoutButton;
