import { useEffect, useState } from 'react';

import { getGroupBranchesAsync, getSpecificGroupBranchesAsync } from '../../../../../../api/securityManager';
import { RestrictionObject } from '../../../../models/restrictionObject';
import { useRestrictions } from './useRestrictions.hook';

const HEADER_IDENTIFICATION = 0;

interface UseBranches {
  branches: BranchesTree[];
}

interface BranchService {
  description: string;
  branchNumber: number;
}

interface BranchGroupService {
  grupo: string;
  sucursal: number;
}

export interface BranchesTree {
  key: string;
  label: string;
  nodes: BranchesTree[];
  restriction: RestrictionObject;
}

export const BRANCHES_TYPE: string = 'B';

export function useBranches(parentGroup: string, group: string): UseBranches {
  const [branchOptions, setBranchOptions] = useState<BranchesTree[]>([]);
  const { fillNumberWithZeros } = useRestrictions();

  async function getBranchesAsync(): Promise<void> {
    const branches: BranchService[] = await getGroupBranchesAsync();
    const groupBranches: BranchGroupService[] =
      group !== '' ? await getSpecificGroupBranchesAsync(group) : await getSpecificGroupBranchesAsync(parentGroup);
    const options: BranchesTree[] = [];

    branches.forEach((branchOption, index) => {
      const branchInfo = groupBranches.find((b) => b.sucursal === branchOption.branchNumber);
      const branchNode = {
        key: `${index} ${branchOption?.description}`,
        label: `${branchOption?.branchNumber} - ${branchOption?.description}`,
        nodes: [],
        restriction: {
          id: fillNumberWithZeros(0) + fillNumberWithZeros(branchOption.branchNumber),
          description: branchOption?.description,
          type: BRANCHES_TYPE,
          allows: branchInfo !== undefined ? 1 : 0,
          ownPermission: branchInfo !== undefined ? 1 : 0,
          titlePermission: null,
          parentPermission: null,
          branchNumber: branchOption.branchNumber,
        },
      };

      options.push(branchNode);
    });

    setBranchOptions(options);
  }

  useEffect(() => {
    getBranchesAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return {
    branches: branchOptions,
  };
}
