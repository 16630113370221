import urlencode from 'urlencode';

import { API } from '../config';
import { encryptAES } from '../utils/aes';
import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { USER_NAME, USER_SESSION } from '../utils/browserStorage/constants';
import { getUrl, handleError } from './handlers';

/**
 * Creates a new session on the server and saves the sessionID in the browser storage
 *
 * @param {object} params Object: { clientGuid, clientVersionInfo }
 */
export const initSession = async (clientGuid, clientVersionInfo) => {
  try {
    const query = {
      endpoint: 'login',
      action: 'initSession',
      params: `clientGuid=${clientGuid}&clientVersionInfo="${clientVersionInfo}"`,
    };
    const response = await axios.post(getUrl(query));

    if (!response.data || response.data.includes('topsystems')) {
      handleError(response);
    }
    const sessionID = urlencode(`${response.data}`);
    browserStorage.sessionSet(USER_SESSION, sessionID);

    return sessionID;
  } catch (error) {
    return handleError(error);
  }
};

// Login solo con contraseña tradicional
export let login = async (user, plainPassword, hasHuellaLogin, huellaBase64) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  const password = plainPassword ? encryptAES(plainPassword).base64 : '';

  try {
    const query = {
      endpoint: 'login',
      action: 'login',
      params: `sessionID=${sessionID}&password=${urlencode(password)}&key=${urlencode(user)}&hasHuellaLogin=${urlencode(hasHuellaLogin)}`,
    };
    const response = await axios.post(getUrl(query));
    const { errorTypeInfo, mensaje, returnCode } = response.data;
    if (errorTypeInfo) {
      handleError(mensaje);
    }
    browserStorage.sessionSet(USER_NAME, user);
    return returnCode;
  } catch (error) {
    handleError(error);
  }
};

// Login solo con contraseña (nuevo)
export let loginWithPassword = async (user, plainPassword, hasHuellaLogin, huellaBase64) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  const password = plainPassword ? encryptAES(plainPassword).base64 : '';

  try {
    const query = {
      endpoint: 'login',
      action: 'newinterface/login',
      params: `sessionID=${sessionID}&password=${urlencode(password)}&key=${urlencode(user)}&hasHuellaLogin=${urlencode(hasHuellaLogin)}`,
    };
    const response = await axios.post(getUrl(query));
    const { errorTypeInfo, mensaje, returnCode } = response.data;
    if (errorTypeInfo) {
      handleError(mensaje);
    }
    browserStorage.sessionSet(USER_NAME, user);
    return returnCode;
  } catch (error) {
    handleError(error);
  }
};

// Si login con huella y contraseña (doble), este es el firstStep
export let validateLogin = async (user, plainPassword) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  const password = plainPassword ? encryptAES(plainPassword).base64 : '';

  try {
    const query = {
      endpoint: 'login',
      action: 'firststep/validation',
      params: `sessionID=${sessionID}`,
    };

    const body = {
      password: password,
      username: user,
    };
    const response = await axios.post(getUrl(query), body);
    const { errorTypeInfo, mensaje, returnCode } = response.data;
    if (errorTypeInfo) {
      handleError(mensaje);
    }
    browserStorage.sessionSet(USER_NAME, user);
    return returnCode;
  } catch (error) {
    handleError(error);
  }
};

export let fingerPrintLogin = async (user, plainPassword, hasHuellaLogin, huellaBase64) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);

  try {
    const query = {
      endpoint: 'fingerprint',
      action: 'login',
      params: `sessionID=${sessionID}`,
    };
    const body = { username: user, huella: huellaBase64 };
    const response = await axios.post(getUrl(query), body);
    const { errorTypeInfo, mensaje, returnCode } = response.data;
    if (errorTypeInfo) {
      handleError(mensaje);
    }
    browserStorage.sessionSet(USER_NAME, user);
    return returnCode;
  } catch (error) {
    handleError(error);
  }
};

/**
 * Changes the user's password
 * @param {string} username Username
 * @param {string} currentPassword Current password
 * @param {string} newPassword New password
 */
export const changePassword = async (username, currentPassword, newPassword) => {
  const session = browserStorage.sessionGet(USER_SESSION);

  const currentPasswordAES = currentPassword ? encryptAES(currentPassword).base64 : '';
  const newPasswordAES = newPassword ? encryptAES(newPassword).base64 : '';

  try {
    const query = {
      endpoint: 'login',
      action: 'changePassword',
      params: `key=${username}&currentPassword=${urlencode(currentPasswordAES)}&newPassword=${urlencode(newPasswordAES)}&sessionID=${session}`,
    };
    const response = await axios.post(getUrl(query));
    if (response.data.message !== '1') {
      handleError(response);
    }
    return response.data.message;
  } catch (error) {
    handleError(error);
  }
};

export let getSessionInfo = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/login/getSessionInfo?sessionID=${session}`).then(({ data }) => data);
};

export let sessionLogout = () => {
  const session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/login/logout?sessionID=${session}`).then((response) => response);
};

export let keepAlive = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/login/keepAlive?sessionID=${session}`).then(({ data }) => data);
};

export let getParameters = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/login/getParameters?sessionID=${session}`).then(({ data }) => data);
};

export let hasProgramPermission = (programName) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(`${API}TopazWebClientWS/resources/menuSession/hasProgramPermission?sessionID=${session}&programName=${programName}`)
    .then(({ data }) => data);
};

export let getTipoLogin = (user) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/login/getTipoLogin?sessionID=${session}&key=${user}`).then(({ data }) => data);
};

export let getSegundoLogin = (user) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/login/getSegundoLogin?sessionID=${session}&key=${user}`).then(({ data }) => data);
};

export let getValidationData = (user) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/login/getValidationData?sessionID=${session}&key=${user}`).then(({ data }) => data);
};

export let increaseSecondLoginError = (user) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/login/increaseSecondLoginError?sessionID=${session}&key=${user}`).then(({ data }) => data);
};

export let verifyFingerLogin = (user) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/login/verifyFingerLogin?sessionID=${session}&key=${user}`).then(({ data }) => data);
};

export let preChangeSecondLogin = (user, plainPassword) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  const password = plainPassword ? encryptAES(plainPassword).base64 : '';

  return axios
    .post(`${API}TopazWebClientWS/resources/login/preChangeSecondLogin?sessionID=${session}&key=${user}&password=${urlencode(password)}`)
    .then(({ data }) => data);
};

export let persistFingerData = (body, user) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(`${API}TopazWebClientWS/resources/login/persistFingerData?sessionId=${session}&key=${user}`, body, { headers })
    .then()
    .catch((err) => console.log(err));
};

export let getClientProperties = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/login/getClientProperties?sessionID=${session}`).then(({ data }) => data);
};

export let getImagesFromServer = async (name) => {
  return axios.post(`${API}TopazWebClientWS/resources/login/getImagesFromServer?name=${name}`).then(({ data }) => data);
};
