import { Button } from '../../UI-kit';
import { runButton, gotoLineWithBody } from '../../src/api/dialog';
import { createAcceptBody, createBodyGoToLine } from '../lib/bodyCreators';
import { generateLineIDString, generateDialogKey } from '../../UI-kit/helpers/operationHelper';

export default class ButtonToDisplay {
  constructor({ displayInfo, lineID }) {
    this._displayInfo = displayInfo;
    this._lineID = lineID;
    this._number = generateDialogKey(this._displayInfo.dialogNumber, this._lineID);
  }

  transactionNumber() {
    return this._lineID ? this._lineID.transactionNumber : '';
  }

  lineNumber() {
    return this._lineID ? this._lineID.lineNumber : '';
  }

  countIterativeDialog() {
    if (this._lineID && this._lineID.countIterativeDialog !== undefined) {
      return this._lineID.countIterativeDialog;
    }
    return 0;
  }

  countVariableRow() {
    if (this._lineID && this._lineID.countVariableRow !== undefined) {
      return this._lineID.countVariableRow;
    }
    return 0;
  }

  transactionLineLabel() {
    return generateLineIDString(this._lineID);
  }

  number() {
    return this._number;
  }

  props(fieldData) {
    return {
      text: this._displayInfo.text,
      position: this._displayInfo.displayInfo.position,
      number: this._number,
      transactionLineLabel: this.transactionLineLabel(),
      onClick: async ({ operationNumber: opNumber, operationID, processContext, focusElement, tabs, addEmptyTab, addTab, btnRef }) => {
        if (btnRef) {
          btnRef.setState({ disabled: true });
          document.getElementById(btnRef.buttonInput.current.id).style.cursor = 'wait';
        }

        let body = createAcceptBody(opNumber, fieldData, this._number);
        body = createBodyGoToLine(opNumber, fieldData, this.transactionLineLabel(), focusElement, body);
        let resgoTo = await gotoLineWithBody(operationID, this.transactionNumber(), this.lineNumber(), body);
        processContext(resgoTo, 'gotoLine button');
        runButton(operationID, this.transactionNumber(), this.lineNumber())
          .then((res) => {
            processContext(res, 'Run Button');
          })
          .finally(() => {
            if (btnRef) {
              btnRef.setState({ disabled: false });
              document.getElementById(btnRef.buttonInput.current.id).style.cursor = 'pointer';
            }
          });
      },
    };
  }

  style() {
    return {};
  }

  options() {
    return {};
  }

  component() {
    return Button;
  }
}
