/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { connect } from 'unistore/react';
import 'react-table/react-table.css';
import DataList from './DataList';
import ModalMessage from '../ModalMessage';
import ModalMessageRecover from '../ModalMessageRecover.js';
import ModalMessageSearch from '../ModalMessageSearch.js';
// import ModalMessageRecover from './ModalMessageRecover';
import { PulseLoader } from 'halogenium';
import RecoverIcon from './../../resources/icons/fa/recover.svg';
import SearchIcon from './../../resources/icons/fa/search.svg';

// import WorkflowTaskList from './WorkflowTaskList';

import {
  removeNetMap,
  removeNumeratorDefinition,
  removeTopazMap,
  removeTopazPrt,
  validaUsuariosMaquinaSucursal,
  getTopazMapsBK,
  getTopazPrtsBK,
  getNetMapsBK,
  removeTopazMapBk,
  removeTopazPrtBk,
  removeNetMapBk,
  addTopazMap,
  addNetMap,
  addTopazPrt,
} from '../../api/productionManager';
import { useIntl } from 'react-intl';

export const CATEGORY_MAPATOPAZ = 'Mapa Topaz';
export const CATEGORY_IMPRESORAS = 'Impresoras';
export const CATEGORY_MAPARED = 'Mapa de Red';
export const CATEGORY_NUMERADORES = 'Numeradores';
export const CATEGORY_PARAMETROS = 'Parametros';
// export const CATEGORY_MAPATOPAZz = '0-1';
// export const CATEGORY_IMPRESORASs = '0-3';
// export const CATEGORY_MAPAREDd = '0-2';
// export const CATEGORY_NUMERADORESs = 'Numeradores';
// export const CATEGORY_PARAMETROSs = 'Parametros';

export const ProductionManagerButtons = (props) => {
  const { dataPrinter, dataNumerator, dataNetMap, dataTopazMap, data, inScreen, selected, setShowWindow } = props;
  const [rows, setRows] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [textDialog, setTextDialog] = useState('');
  const [dataRecover, setDataRecover] = useState([]);

  const refListEntry = useRef();
  const refDataList = useRef();
  const intl = useIntl();

  const loading = false;

  useLayoutEffect(() => {
    // props.loadData();
    let receivedData = data;
    setCurrentData(receivedData);
    const crows = createRows(receivedData);
    setRows(crows);
    if (inScreen === false && selectedItem !== null) {
      setSelectedItem(null);
      setSelectedIndex(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inScreen]);

  const deleteItem = async (category, selectedItem) => {
    setShowModal(false);
    if (category === CATEGORY_MAPATOPAZ) {
      let ret = await removeTopazMap(selectedItem);
      if (ret === false) {
        let message = intl.formatMessage({
          id: 'bajaentidad',
          defaultMessage: 'Error al intentar dar de baja sobre',
        });
        message += 'TopazMap';
        setModal2(true, message, false);
      }
    } else if (category === CATEGORY_IMPRESORAS) {
      let ret = await removeTopazPrt(selectedItem);
      if (ret === false) {
        let message = intl.formatMessage({
          id: 'bajaentidad',
          defaultMessage: 'Error al intentar dar de baja sobre',
        });
        message += 'TopazPrt';
        setModal2(true, message, false);
      }
    } else if (category === CATEGORY_MAPARED) {
      let ret = await removeNetMap(selectedItem);
      if (ret === false) {
        let message = intl.formatMessage({
          id: 'bajaentidad',
          defaultMessage: 'Error al intentar dar de baja sobre',
        });
        message += 'NetMap';
        setModal2(true, message, false);
      }
    } else if (category === CATEGORY_NUMERADORES) {
      let ret = await removeNumeratorDefinition(selectedItem);
      if (ret === false) {
        let message = intl.formatMessage({
          id: 'bajaentidad',
          defaultMessage: 'Error al intentar dar de baja sobre',
        });
        message += 'Numeradores';
        setModal2(true, message, false);
      }
    }
  };

  const validoNetMap = async (selectedItem) => {
    const { dataPrinter, dataTopazMap } = props;
    let message = intl.formatMessage({
      id: 'existeentablas',
      defaultMessage: 'No es posible borrar el Mapa de Red seleccionado\nporque esta siendo usando en las siguientes tablas:',
    });

    let rowExisteEnImpresoras = dataPrinter.filter((reg) => {
      return reg.nromaq === parseInt(selectedItem.numerodemaquina) && reg.nrosucursal === parseInt(selectedItem.nrosucursal);
    });

    let rowExisteEnTopazMap = dataTopazMap.filter((reg) => {
      return reg.nromaq === parseInt(selectedItem.numerodemaquina) && reg.nrosucursal === parseInt(selectedItem.nrosucursal);
    });

    let showMessage = false;

    let valida = await validaUsuariosMaquinaSucursal(selectedItem.numerodemaquina, selectedItem.nrosucursal);

    if (rowExisteEnTopazMap.length > 0) {
      message += '\n-TopazMap.';
      showMessage = true;
    }

    if (rowExisteEnImpresoras.length > 0) {
      message += '\n-TopazPrt.';
      showMessage = true;
    }

    if (valida) {
      message += '\n-Usuarios.';
      showMessage = true;
    }

    if (showMessage) {
      setModal2(true, message, false);
      return;
    }
    const deleteMessage = intl.formatMessage({
      id: 'deletemapared',
      defaultMessage: 'Esta seguro que desea eliminar el elemento Mapa de Red?',
    });
    setModal(true, deleteMessage);
  };

  const showModalItem = (category, selectedItem) => {
    if (category === CATEGORY_MAPATOPAZ) {
      const deleteMessage = intl.formatMessage({
        id: 'deletemapatopaz',
        defaultMessage: 'Esta seguro que desea eliminar el elemento Mapa Topaz?',
      });
      setModal(true, deleteMessage);
    } else if (category === CATEGORY_IMPRESORAS) {
      const deleteMessage = intl.formatMessage({
        id: 'deleteimpresoras',
        defaultMessage: 'Esta seguro que desea eliminar el elemento Impresora?',
      });
      setModal(true, deleteMessage);
    } else if (category === CATEGORY_MAPARED) {
      validoNetMap(selectedItem);
    } else if (category === CATEGORY_NUMERADORES) {
      const deleteMessage = intl.formatMessage({
        id: 'deletenumerador',
        defaultMessage: 'Esta seguro que desea eliminar el elemento Numerador?',
      });
      setModal(true, deleteMessage);
    }
  };

  const setModal = (state, text) => {
    setShowModal(state);
    setTextDialog(text);
  };

  const setModal2 = (state, text) => {
    setShowModal2(state);
    setTextDialog(text);
  };

  const setModal3 = (state, text) => {
    setShowModal3(state);
    setTextDialog(text);
  };

  const setModal4 = (state) => {
    setShowModal4(state);
    // setTextDialog(text);
    // setDataRecover(data);
  };

  const columnOrders = (type) => {
    let orderToReturn = [];
    if (type === CATEGORY_PARAMETROS) {
      orderToReturn = [
        'Nombre1',
        'Nombre2',
        'FechaProceso',
        'FechaProcesoAnterior',
        'FechaProximoProceso',
        'DiasValidos',
        'Reintentos',
        'TipoRed',
        'AutoLogin',
        'Siglo',
        'CorteSiglo',
        'LenguajeDefecto',
        'Comunicaciones',
        'Ambiente',
        'TiempoCorte',
        'NumSucursal',
        'MonNac',
        'MovimientosCaja',
        'TipoPlazo',
        'PwdLargoMin',
        'PwdCantMinCar',
        'PwdCantMinNum',
        'PwdCantMaxRep',
        'PwdCarMaxRep',
        'PwdPosMaxRep',
        'ComplementoGrupo',
        'PwdLargoMax',
        'PwdCantCarMaxRep',
        'ClvLargoMax',
        'ClvLargoMin',
        'PwdCaseSensitive',
        'ClvCaseSensitive',
        'PwdReglasMin',
        'PwdCarMaxSec',
        'DiasAviso',
      ];
    } else if (type === CATEGORY_MAPATOPAZ) {
      orderToReturn = ['NroSucursal', 'NroMaq', 'GrupoMapa', 'Path', 'Comentario'];
    } else if (type === 'Mapa de Red') {
      orderToReturn = [
        'NroSucursal',
        'NumeroDeMaquina',
        'Descripcion',
        'InicioDelDia',
        'FinDelDia',
        'Usuario',
        'DireccionCOMM',
        'NroDeCaja',
        'DireccionIP',
        'NombreDeEnvio',
      ];
    } else if (type === CATEGORY_IMPRESORAS) {
      orderToReturn = ['NroSucursal', 'NroMaq', 'NombreTopaz', 'Comentario', 'NombreWindows', 'TipoImpresora', 'Puerto'];
    } else if (type === CATEGORY_NUMERADORES) {
      orderToReturn = ['Numero', 'Descripcion', 'IniVal', 'Incremento', 'Periodo', 'UltimaInic', 'Reutilizable', 'Maximo', 'Centralizado'];
    }
    return orderToReturn;
  };

  const createRows = (list) => {
    let crows = [];
    if (list && list instanceof Array) {
      list.map((data) => {});
    }
    return crows;
  };

  const addDataListRef = (element) => {
    refDataList.current = element;
  };

  const addToRefListEntry = (r) => {
    if (r && refListEntry.current && !refListEntry.current.includes(r)) {
      refListEntry.current.push(r);
    }
  };
  const accionesModalSearch = (element, data) => {
    if (element === 'cerrar') {
      setShowModal4(false);
    }
  };

  const accionesModal = (element, data) => {
    if (element === 'cerrar') {
      setShowModal3(false);
    }
  };

  const openModalRecover = async () => {
    let rec = [];
    let recuperados = [];

    let topazMaps = await getTopazMapsBK();
    let topazPrts = await getTopazPrtsBK();
    let netMaps = await getNetMapsBK();
    rec = topazMaps.concat(topazPrts);
    recuperados = rec.concat(netMaps);

    setModal3(true, 'Recuperar', recuperados);
  };

  const openModalSearch = async () => {
    setModal4(true);
  };

  return (
    <div>
      <div>
        {showModal && (
          <ModalMessage
            message={textDialog}
            handleAccept={async () => {
              deleteItem(selected, selectedItem);
            }}
            acceptTitle={'Aceptar'}
            handleCancel={() => {
              setShowModal(false);
            }}
            cancelTitle={'Cancelar'}
            draggable={true}
          />
        )}
        {showModal2 && (
          <ModalMessage
            message={textDialog}
            handleAccept={async () => {
              setShowModal2(false);
            }}
            acceptTitle={'Aceptar'}
            draggable={true}
          />
        )}
        {showModal3 && (
          <ModalMessageRecover
            message={textDialog}
            handleAccept={async () => {
              setShowModal3(false);
            }}
            accionesModal={accionesModal}
            acceptTitle={'Aceptar'}
            draggable={true}
            data={dataRecover}
            loadData={props.loadData}
            // data={dataRecover}
            // papelera={papelera}
          />
        )}
        {showModal4 && (
          <ModalMessageSearch
            message={textDialog}
            handleAccept={async () => {
              setShowModal4(false);
            }}
            acceptTitle={'Aceptar'}
            draggable={true}
            accionesModalSearch={accionesModalSearch}
            data={data}
            dataTopazMap={dataTopazMap}
            dataPrinter={dataPrinter}
            dataNetMap={dataNetMap}
            dataNumerator={dataNumerator}
            // data={dataRecover}
            // papelera={papelera}
          />
        )}
        <div className="h-full flex flex-col" style={{ maxWidth: 'max-content' }}>
          <div className={`mt-6 flex-grow`} style={{ margin: '15px' }}>
            <div className="pb-2">
              <button className="pl-5" disabled={true} title="Agregar nodo">
                ✚
              </button>
              <button
                className="pl-5"
                onClick={() => {
                  setShowWindow('Parametros', selectedItem, 0);
                }}
              >
                ✎
              </button>
              <button className="pl-5" disabled={true} onClick={deleteItem} title="Eliminar">
                ✖
              </button>
              <button className="pl-5" onClick={openModalRecover} title="Recuperar" disabled>
                <RecoverIcon />
              </button>
              <button className="pl-5" onClick={openModalSearch} title="Buscar" disabled>
                <SearchIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionManagerButtons;
