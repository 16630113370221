import React, { Component } from 'react';
import PostingStatus from './PostingStatus';
import './../styles/postingList.scss';

const ListEntry = (props) => {
  const { item, clickHandler, rowID, columnsWidth } = props;

  const getStyle = (i) => {
    let newStyle = null;
    if (columnsWidth) {
      newStyle = {
        minWidth: `${columnsWidth[1][i]}px`,
        width: `${columnsWidth[1][i]}px`,
        maxWidth: `${columnsWidth[2][i]}px`,
      };
    }
    return newStyle;
  };

  return (
    <div
      id={`rowAsiento${rowID}`}
      className="items-center justify-between border-t border-smoke py-2 h-10 cursor-pointer"
      onClick={(e) => clickHandler(e, rowID)}
    >
      <tr>
        <td style={getStyle(0)}>
          <div className="truncate font-mono text-xs ">{item.transactionID}</div>
        </td>
        <td style={getStyle(1)}>
          <div className="flex-grow items-center mr-3">
            <div className="truncate text-xs" title={`${item.operation} ${item.description}`}>
              {`${item.operation} ${item.description}`}
            </div>
          </div>
        </td>
        <td style={getStyle(2)}>
          <div className="truncate uppercase text-xs">{item.user}</div>
        </td>
        <td style={getStyle(3)}>
          <div className="truncate text-xs">
            <PostingStatus state={item.state} insideRow />
          </div>
        </td>
        <td style={getStyle(4)}>
          <div className="truncate text-xs">
            {item.sector} / {item.branch}
          </div>
        </td>
        <td style={getStyle(5)}>
          <div className="truncate text-xs">{item.finishTime}</div>
        </td>
        <td style={getStyle(6)}>
          <div className="truncate text-xs">{item.processDate.split(' ')[0]}</div>
        </td>
      </tr>
    </div>
  );
};

class PostingListView extends Component {
  constructor(props) {
    super(props);

    let columnas = [];
    for (let col = 0; col < this.props.columns.length; col++) {
      columnas.push({ ...this.props.columns[col], length: 15 });
    }

    this.state = {
      actualResize: null,
      columnsWidth: this.getColumnsWidth(props.data, columnas),
      columna: columnas,
      sueltoMouse: true,
      evento: null,
    };
    this.treeRef = React.createRef();
    this.headerRef = [];

    for (let i = 0; i <= 6; i++) {
      this.headerRef[i] = React.createRef();
    }
    this.handlers = [];
  }

  handleResizeMouseDown(e, index, headerRef) {
    this.handleResize(e, index, headerRef);
  }

  handleResize(evt, index, headerRef) {
    const thisObject = this;
    if (index !== null) {
      const offsetWidth = headerRef[index].current.offsetWidth;
      const evtClientX = evt.clientX;
      thisObject.handlers[index] = (e) => {
        let copyColumnsWidth = thisObject.state.columnsWidth;
        let diff = e.clientX - evtClientX;
        copyColumnsWidth[1][index] = offsetWidth + diff;
        copyColumnsWidth[2][index] = offsetWidth + diff;
        thisObject.setState({ columnsWidth: copyColumnsWidth });
      };
      window.addEventListener('mousemove', thisObject.handlers[index]);
      window.addEventListener(
        'mouseup',
        function (evt) {
          window.removeEventListener('mousemove', thisObject.handlers[index]);
        },
        true,
      );
    }
  }

  getColumnsWidth(children, columnas) {
    let columns = columnas || this.state.columna;
    let minSizes = [];
    let maxSizes = [];
    let defaultSizes = [];
    let columnsWidth = [];
    if (children && columns) {
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        minSizes = [...minSizes, columns[colIndex]?.length * 7];
        defaultSizes = [
          ...defaultSizes,
          (this.state && this.state.columnsWidth && this.state.columnsWidth[1][colIndex]) || columns[colIndex]?.length * 10,
        ];
        maxSizes = [...maxSizes, (this.state && this.state.columnsWidth && this.state.columnsWidth[2][colIndex]) || columns[colIndex]?.length * 10];
      }
      columnsWidth = [minSizes, defaultSizes, maxSizes];
    }
    return columnsWidth;
  }

  getStyle(index) {
    if (this.state.columnsWidth) {
      return {
        minWidth: `${this.state.columnsWidth[1][index]}px`,
        width: `${this.state.columnsWidth[1][index]}px`,
        maxWidth: `${this.state.columnsWidth[2][index]}px`,
      };
    }
  }

  render() {
    const { data, noDataText, clickHandler, intl } = this.props;

    let content = (
      <div className="pl-2" style={{ overflowX: 'auto', maxWidth: '75vw', minWidth: '75vw', maxHeight: '85vh' }}>
        <table style={{ borderCollapse: 'collapse', display: 'grid' }}>
          <thead>
            <div className="flex items-center justify-between text-xs uppercase text-grey py-2 border-b border-light">
              <tr>
                <th className={'th-listTable'} ref={this.headerRef[0]} style={this.getStyle(0)}>
                  <div>
                    {intl.formatMessage({
                      id: 'asientos',
                      defaultMessage: 'Asientos',
                    })}
                  </div>
                  <div
                    onMouseDown={(e) => this.handleResizeMouseDown(e, 0, this.headerRef)}
                    className={`resize-handle ${this.state.actualResize === 0 ? 'active' : 'idle'}`}
                  />
                </th>
                <th className={'th-listTable'} ref={this.headerRef[1]} style={this.getStyle(1)}>
                  <div>
                    {intl.formatMessage({
                      id: 'operacion',
                      defaultMessage: 'Operacion',
                    })}
                  </div>
                  <div
                    onMouseDown={(e) => this.handleResizeMouseDown(e, 1, this.headerRef)}
                    className={`resize-handle ${this.state.actualResize === 1 ? 'active' : 'idle'}`}
                  />
                </th>
                <th className={'th-listTable'} ref={this.headerRef[2]} style={this.getStyle(2)}>
                  <div>
                    {' '}
                    {intl.formatMessage({
                      id: 'usuario',
                      defaultMessage: 'Usuario',
                    })}
                  </div>
                  <div
                    onMouseDown={(e) => this.handleResizeMouseDown(e, 2, this.headerRef)}
                    className={`resize-handle ${this.state.actualResize === 2 ? 'active' : 'idle'}`}
                  />
                </th>
                <th className={'th-listTable'} ref={this.headerRef[3]} style={this.getStyle(3)}>
                  <div>
                    {' '}
                    {intl.formatMessage({
                      id: 'estado',
                      defaultMessage: 'Estado',
                    })}
                  </div>
                  <div
                    onMouseDown={(e) => this.handleResizeMouseDown(e, 3, this.headerRef)}
                    className={`resize-handle ${this.state.actualResize === 3 ? 'active' : 'idle'}`}
                  />
                </th>
                <th className={'th-listTable'} ref={this.headerRef[4]} style={this.getStyle(4)}>
                  <div>
                    <div>
                      {' '}
                      {intl.formatMessage({
                        id: 'sector',
                        defaultMessage: 'Sector',
                      })}
                    </div>
                    <br />
                    <div>
                      {' '}
                      {intl.formatMessage({
                        id: 'sucursal',
                        defaultMessage: 'Sucursal',
                      })}
                    </div>
                  </div>
                  <div
                    onMouseDown={(e) => this.handleResizeMouseDown(e, 4, this.headerRef)}
                    className={`resize-handle ${this.state.actualResize === 4 ? 'active' : 'idle'}`}
                  />
                </th>
                <th className={'th-listTable'} ref={this.headerRef[5]} style={this.getStyle(5)}>
                  <div>
                    {' '}
                    {intl.formatMessage({
                      id: 'fechaInicio',
                      defaultMessage: 'Fecha de inicio',
                    })}
                  </div>
                  <div
                    onMouseDown={(e) => this.handleResizeMouseDown(e, 5, this.headerRef)}
                    className={`resize-handle ${this.state.actualResize === 5 ? 'active' : 'idle'}`}
                  />
                </th>
                <th className={'th-listTable'} ref={this.headerRef[6]} style={this.getStyle(6)}>
                  <div>
                    {' '}
                    {intl.formatMessage({
                      id: 'fechaProceso',
                      defaultMessage: 'Fecha de proceso',
                    })}
                  </div>
                </th>
              </tr>
            </div>
          </thead>
          <tbody>
            <div style={{ height: 480 }}>
              {data.map((item, index) => (
                <div
                  className={` ${index % 2 && 'bg-grey-lighter-lighter'} `}
                  key={index}
                  style={{ backgroundColor: 'var(--background-color-accent)' }}
                >
                  <ListEntry item={item} rowID={index} key={index} clickHandler={clickHandler} columnsWidth={this.state.columnsWidth} />
                </div>
              ))}
            </div>
          </tbody>
        </table>
      </div>
    );

    if (data.length === 0) {
      content = (
        <div className="w-full flex items-center">
          <div className="flex-grow text-center">{noDataText}</div>
        </div>
      );
    }

    return content;
  }
}

export default PostingListView;
