import { fileArrayToBase64 } from './../../UI-kit/helpers/fileHelper';

export const transformOperationsData = (dataFromAPI) => ({
  categories: dataFromAPI.map((categories) => ({
    id: categories.id,
    name: categories.description || categories.name,
    iconoTitulo: categories.iconoTitulo,
    items: categories.itemsList
      .map((operation) => ({
        id: operation.id,
        name: operation.name || operation.description,
        look: operation.utilizaEstilo,
        subOperation: operation.subOperation,
      }))
      .filter((operation) => operation.subOperation === false),
  })),
});

export const transformReportsData = (dataFromAPI) => ({
  categories: dataFromAPI.map((categories) => ({
    id: categories.id,
    name: categories.description || categories.name,
    items: categories.itemsList.map((report) => ({
      id: report.id,
      name: report.name || report.description,
    })),
  })),
});

export const mapResponseToSignatures = (response, personNumber, jtsBalance) => {
  return response.map((signature) => ({
    _id: `${personNumber}${signature.numeroFirma}`,
    personNumber,
    jtsBalance,
    authorizedBy: signature.autorizador || '',
    image: fileArrayToBase64(signature.firma || []),
    selected: false,
  }));
};
