import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import { FormattedMessage } from 'react-intl';
import ItemComponent from './ItemComponent';
import '../styles/components/RecentItems.css';
import HistoryIcon from './../resources/icons/fa/history.svg';
import AngleDownIcon from './../resources/icons/fa/angle-down.svg';
import AngleRightIcon from './../resources/icons/fa/angle-right.svg';
import { useTheme } from './../themes/ThemeContext';
import { StyleSheet, css } from 'aphrodite';
import { store } from '../store';

const propTypes = {
  items: PropTypes.array,
  title: PropTypes.object.isRequired,
  handleItemClick: PropTypes.func.isRequired,
};

const RecentItems = ({ items, title, handleItemClick, collapsibleIsOpen, handleCollapsibleClick, onRemove, setRecent }) => {
  const themeState = useTheme();
  const { collapsible, border } = themeState.theme;

  const handleHover = () => {
    setIconColor(collapsible.hover.color);
  };
  const handleBlur = () => {
    setIconColor(collapsible.default.color);
  };

  const [iconColor, setIconColor] = React.useState(collapsible.default.color);

  const styles = StyleSheet.create({
    collapsibleContainer: {
      marginBottom: '2.5rem',
    },
    collapsibleContainerOpened: {
      borderBottom: '1px solid',
      borderColor: border.light,
    },
    trigger: {
      color: collapsible.default.color,
      cursor: 'pointer',
      paddingBottom: '.5rem',
      borderColor: border.light,
      userSelect: 'none',
      ':hover': {
        color: collapsible.hover.color,
      },
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'pre',
    },
    text: { paddingBottom: '.1rem' },
  });

  return (
    <div>
      {items.length > 0 && (
        <div>
          <Collapsible
            open={collapsibleIsOpen}
            handleTriggerClick={handleCollapsibleClick}
            trigger={
              <div className={css(styles.trigger)} onMouseEnter={handleHover} onMouseLeave={handleBlur}>
                <HistoryIcon height="18px" fill={iconColor} />
                <span> </span>
                <span className={css(styles.text)}>
                  <FormattedMessage id={title.id} defaultMessage={title.defaultMessage} />
                </span>
                <span> </span>
                <AngleRightIcon height="17px" fill={iconColor} />
              </div>
            }
            triggerWhenOpen={
              <div className={css(styles.trigger)} onMouseEnter={handleHover} onMouseLeave={handleBlur}>
                <HistoryIcon height="18px" fill={iconColor} />
                <span> </span>
                <span className={css(styles.text)}>
                  <FormattedMessage id={title.id} defaultMessage={title.defaultMessage} />
                </span>
                <span> </span>
                <AngleDownIcon height="17px" fill={iconColor} />
              </div>
            }
            className={css(styles.collapsibleContainer)}
            openedClassName={css(styles.collapsibleContainer, styles.collapsibleContainerOpened)}
            transitionTime={100}
          >
            <div className="Recent-items-container flex flex-wrap justify-between p-2">
              {items
                ?.filter((value, index, self) => index === self.findIndex((compareObj) => compareObj.id === value.id))
                .map((item) => (
                  <React.Fragment key={'f' + item.id}>
                    <ItemComponent
                      key={item.id}
                      itemName={item.name}
                      itemId={item.id}
                      onClick={() => {
                        let recordOn = store.getState().recordOn;
                        if (recordOn) {
                          let textOperation = [];
                          textOperation.push('oper' + item.id + '\n');
                          textOperation.push('Feature: Operacion: ' + item.id + '\n');
                          textOperation.push('Scenario: ' + item.name + '\n');
                          window.sessionStorage.setItem('textOperation', JSON.stringify(textOperation));
                        }

                        handleItemClick(item.id, item.name, item.look);
                      }}
                      onRemove={onRemove}
                      setRecent={setRecent}
                    ></ItemComponent>
                  </React.Fragment>
                ))}
            </div>
          </Collapsible>
        </div>
      )}
    </div>
  );
};

RecentItems.propTypes = propTypes;

export default RecentItems;
