import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { storeActions } from '../../src/store';
import { connect } from 'unistore/react';

// eslint-disable-next-line react/prefer-stateless-function
export class WorkflowTaskDetail extends Component {
  render() {
    const { dataHistory, loading, loadingComponent, handleReturn } = this.props;

    if (loading) {
      return <div className="text-center">{loadingComponent}</div>;
    }

    if (!dataHistory) {
      return (
        <div className="text-sm text-center w-full flex items-center h-full">
          <div className="absolute pin-r pin-t p-6">
            <div className="float-right text-primary cursor-pointer" onClick={() => handleReturn()}>
              &#10005;
            </div>
          </div>
          <div className="w-full">
            <FormattedMessage id="selectTaskSeeHistory" defaultMessage={`Seleccione una tarea para ver su historial.`} />
          </div>
        </div>
      );
    }

    if (!dataHistory.length) {
      return (
        <div className="text-sm text-center w-full flex items-center h-full">
          <div className="absolute pin-r pin-t p-6">
            <div className="float-right text-primary cursor-pointer" onClick={() => handleReturn()}>
              &#10005;
            </div>
          </div>
          <div className="w-full">
            <FormattedMessage id="noItemsHistoryTask" defaultMessage={`Aún no hay ítems en el historial de esta tarea.`} />
          </div>
        </div>
      );
    }

    return (
      <div className="w-full h-full flex flex-col">
        <div className="clearfix">
          <FormattedMessage id="history" defaultMessage={`Historial`} />
          <div className="float-right text-primary cursor-pointer" onClick={() => handleReturn()}>
            &#10005;
          </div>
        </div>

        <div className={`mt-6 flex-grow p-2 ${dataHistory.length > 3 && 'overflow-y-scroll'}`}>
          {dataHistory.map((item, index) => {
            const {
              name: itemState,
              assigned,
              start,
              end,
              branch,
              variables: { Producto: prodType, 'Nro. producto': prodNumber },
            } = item;
            let dataFilter = dataHistory.filter((element) => element.name === itemState);
            let responsible = '';
            if (dataFilter && dataFilter.length > 0) {
              responsible = dataFilter[0].responsible;
            }
            return (
              <div key={index} className={`pb-8 flex relative border-l ${index === dataHistory.length - 1 ? 'border-transparent' : 'border-grey'}`}>
                <div
                  className="text-2xl w-4 h-4 bg-grey-lighter-lighter rounded-full text-grey"
                  style={{ marginLeft: -9, marginTop: -10, lineHeight: '16px' }}
                >
                  ⚬
                </div>
                <ul className="list-reset leading-normal pl-2 -mt-1" style={{ marginTop: -14 }}>
                  {!!start && (
                    <li>
                      <small className="text-xxs uppercase">
                        <FormattedMessage id="start" defaultMessage={`Comienzo`} />:
                      </small>{' '}
                      {start}
                    </li>
                  )}
                  {!!end && (
                    <li>
                      <small className="text-xxs uppercase">
                        <FormattedMessage id="end" defaultMessage={`Fin`} />:
                      </small>{' '}
                      {end}
                    </li>
                  )}
                  {!!itemState && (
                    <li>
                      <small className="text-xxs uppercase">
                        <FormattedMessage id="state" defaultMessage={`Estado`} />:
                      </small>{' '}
                      {itemState}
                    </li>
                  )}
                  {!!assigned && (
                    <li>
                      <small className="text-xxs uppercase">
                        <FormattedMessage id="assigned" defaultMessage={`Asignado`} />:
                      </small>{' '}
                      {assigned}
                    </li>
                  )}
                  {!!responsible && (
                    <li>
                      <small className="text-xxs uppercase">
                        <FormattedMessage id="solvedBy" defaultMessage={`Resuelto por`} />:
                      </small>{' '}
                      {responsible}
                    </li>
                  )}
                  {!!branch && (
                    <li>
                      <small className="text-xxs uppercase">
                        <FormattedMessage id="branch" defaultMessage={`Sucursal`} />:
                      </small>{' '}
                      {branch}
                    </li>
                  )}
                  {!!prodType && (
                    <li>
                      <small className="text-xxs uppercase">
                        <FormattedMessage id="product" defaultMessage={`Producto`} />:
                      </small>{' '}
                      {prodType}
                    </li>
                  )}
                  {!!prodNumber && (
                    <li>
                      <small className="text-xxs uppercase">
                        <FormattedMessage id="productNumber" defaultMessage={`Nro. Producto`} />:
                      </small>{' '}
                      {prodNumber}
                    </li>
                  )}
                </ul>
                {index.last}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect(null, storeActions)(WorkflowTaskDetail);
