import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { GroupInstance } from '../../models/groupInstance';
import okIcon from '../../icons/ok';
import errorIcon from '../../icons/error';
import { timeInSecondsToString } from '../../helpers/timeHelper';
import { TAB_SPACES } from '../../models/constants';

interface ProcessExecutionInfoProps {
  processInfo?: GroupInstance;
  parentInitial?: string;
  parentEnd?: string;
  whiteSpace: number;
  root: boolean;
  onModalChange: (isOpen: boolean) => void;
  onModalDataChange: (data: string, name: string) => void;
}

export function ProcessExecutionInfo({
  processInfo,
  parentInitial,
  parentEnd,
  whiteSpace,
  root,
  onModalChange,
  onModalDataChange,
}: ProcessExecutionInfoProps): JSX.Element {
  const RUNNING_PROCESS: string = 'R';
  const ABORTED_PROCESS: string = 'A';
  const STOPPED_PROCESS: string = 'S';

  function getInitialTime(): string | undefined {
    const initialTime = processInfo?.initTime ? moment(processInfo?.initTime).format('hh:mm:ss a') : parentInitial;

    return initialTime;
  }

  function getEndTime(): string | undefined {
    const endTime = processInfo?.endTime ? (processInfo?.endTime !== '' ? moment(processInfo?.endTime).format('hh:mm:ss a') : '') : parentEnd;

    return endTime;
  }

  function renderProcessGraph(processInfo: GroupInstance): JSX.Element {
    let widthTranscurred;
    let widthAverage: number = 0;
    let color: string = '';
    const isTranscurredMoreThanAverage: boolean = processInfo.duration > processInfo.averageTime;
    const areInInitialState: boolean = processInfo.duration === 0 && processInfo.averageTime === 0 && !processInfo.endTime;

    if (isTranscurredMoreThanAverage) {
      color = 'red';
      widthTranscurred = 100;
      widthAverage = Math.floor((processInfo.averageTime * 100) / processInfo.duration);
    } else if (areInInitialState) {
      widthAverage = 0;
      widthTranscurred = 0;
    } else {
      color = 'green';
      widthAverage = 100;
      widthTranscurred = Math.floor((processInfo.duration * 100) / processInfo.averageTime);
    }

    return (
      <div>
        <div className={`border-b-4`} style={{ width: `${widthTranscurred}%`, borderColor: `${color}` }}></div>
        <div className={`border-b-4 border-blue`} style={{ width: `${widthAverage}%` }}></div>
      </div>
    );
  }

  function onClick(event: React.MouseEvent) {
    event.stopPropagation();
    if (processInfo?.processResult.description && processInfo?.processResult.description !== (' ' || '')) {
      onModalDataChange(processInfo.processResult.description, processInfo.name);
      onModalChange(true);
    }
  }

  return (
    <div>
      <div className="flex items-center justify-between border-t border-smoke py-2 h-10 text-center">
        <div className={`w-48 font-mono text-xs text-left`} style={{ paddingLeft: `${whiteSpace + 8}px` }}>
          {processInfo?.name}
        </div>
        <div className="w-24 font-mono text-xs pr-2">{processInfo?.state}</div>
        <div className="w-24 font-mono text-xs pr-2">{getInitialTime()}</div>
        <div className="w-24 font-mono text-xs pr-2">{getEndTime()}</div>
        <div className="w-24 font-mono text-xs pr-2">{processInfo && timeInSecondsToString(processInfo.averageTime)}</div>
        <div className="w-24 font-mono text-xs pr-2">{processInfo?.duration + 's'}</div>
        <div className="w-24 font-mono text-xs pr-2">{processInfo && renderProcessGraph(processInfo)}</div>
        <div className="w-24 font-mono text-xs pr-2">
          {processInfo?.state === RUNNING_PROCESS ? (
            ''
          ) : processInfo?.state === ABORTED_PROCESS || processInfo?.state === STOPPED_PROCESS ? (
            <button onClick={onClick}>{errorIcon}</button>
          ) : (
            <button onClick={onClick}>{okIcon}</button>
          )}
        </div>
      </div>
      {!root && (
        <div>
          {processInfo?.type === 'groupInstance' &&
            processInfo?.processInstances.map((item, index) => (
              <div className={` ${item.type === 'groupInstance' ? 'bg-grey-lighter-lighter' : 'bg-white'} `} key={index}>
                <ProcessExecutionInfo
                  processInfo={item}
                  parentInitial={getInitialTime()}
                  parentEnd={getEndTime()}
                  whiteSpace={whiteSpace + TAB_SPACES}
                  root={false}
                  onModalChange={onModalChange}
                  onModalDataChange={onModalDataChange}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
