import React, { useEffect } from 'react';
import { ExecuteModalView, ModalProps } from './ExecuteModalView';
import { SelectedProcess } from '../../models/types';
import { useProcessManager } from '../../hooks/useProcessManager';
import { executeProcess } from '../../../../api/processesManager';
import { Process } from '../../models/process';
import { GroupDefinition } from '../../models/groupDefinition';
import { Parameter } from '../../models/parameter';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { PulseLoader } from 'halogenium';
import browserStorage from '../../../../utils/browserStorage';
import { formatDateYYYYMMDDtoDDMMYYYY } from '../../../../../UI-kit/helpers/dateHelper';

interface ExecuteModalViewModelProps {
  selectedProcess: SelectedProcess;
  onModalChange: (isOpenModal: boolean) => void;
  addExecutedProcessId: (executionid: number) => void;
}

export function ExecuteModalViewModel({ selectedProcess, onModalChange, addExecutedProcessId }: ExecuteModalViewModelProps): JSX.Element {
  const { getParameters, areAllMandatoryParameters, areValidParameters } = useProcessManager();
  const [isModalExecuteOpen, setIsModalExecuteOpen] = useState<boolean>(false);
  const [isModalParametersOpen, setIsModalParametersOpen] = useState<boolean>(false);
  const [isModalMonitorOpen, setIsModalMonitorOpen] = useState<boolean>(false);
  const [missingParameters, setMissingParameters] = useState<boolean>(false);
  const [notValidParameters, setNotValidParameters] = useState<boolean>(false);
  const [executeParameters, setExecuteParameters] = useState<Parameter[]>([]);
  const [lastProcessId, setLastProcessId] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const intl = useIntl();

  function getModalProps(): ModalProps {
    if (isModalExecuteOpen) {
      return getModalPropsExecute();
    } else if (isModalParametersOpen) {
      return getModalPropsParametersActive();
    } else if (isModalMonitorOpen) {
      return getModalPropsMonitor();
    } else {
      return getModalPropsDefault();
    }
  }

  function getModalPropsParametersActive(): ModalProps {
    if (missingParameters) {
      return { ...getModalPropsParameters(), alert: intl.formatMessage({ id: 'insertAllMandatoryParameters' }) };
    } else if (notValidParameters) {
      return { ...getModalPropsParameters(), alert: intl.formatMessage({ id: 'invalidValues' }) };
    } else {
      return getModalPropsParameters();
    }
  }

  function getModalPropsExecute(): ModalProps {
    return {
      question: intl.formatMessage({ id: 'doYouWantToExecute' }) + ' ' + `${selectedProcess?.name.trim()}` + '?',
      onCancel: onModalExecuteChange,
      onAccept: onAcceptModalExecute,
    };
  }

  function getModalPropsParameters(): ModalProps {
    return {
      question: intl.formatMessage({ id: 'insertParametersToExecute' }) + ' ' + `${selectedProcess?.name.trim()}`,
      onCancel: onModalParametersChange,
      onAccept: onAcceptModalParameters,
      parameters: getParameters(selectedProcess),
    };
  }

  function getModalPropsMonitor(): ModalProps {
    return {
      question: `${intl.formatMessage({ id: 'executing' })} ${selectedProcess?.name.trim()} ${intl.formatMessage({
        id: 'executionId',
      })} ${lastProcessId}. \n \n ${intl.formatMessage({ id: 'doYouWantToMonitorIt' })} ?`,
      onCancel: onCancelModalMonitorExecution,
      onAccept: onAcceptModalMonitor,
      isMonitor: true,
    };
  }

  function getModalPropsDefault(): ModalProps {
    return {
      question: '',
      onCancel: () => {},
      onAccept: () => {},
      parameters: [],
    };
  }

  function onModalExecuteChange(isOpen: boolean): void {
    if (isOpen) {
      setExecuteParameters([]);
    }
    setIsModalExecuteOpen(isOpen);
    onModalChange(isOpen);
  }

  function onModalParametersChange(isOpen: boolean): void {
    if (isOpen) {
      setExecuteParameters([]);
    }
    setIsModalParametersOpen(isOpen);
    onModalChange(isOpen);
  }

  function onModalMonitorChange(isOpen: boolean): void {
    if (isOpen) {
      setIsModalParametersOpen(!isOpen);
      setIsModalExecuteOpen(!isOpen);
    }
    setIsModalMonitorOpen(isOpen);
    onModalChange(isOpen);
  }

  function onCancelModalMonitorExecution(isOpen: boolean): void {
    onModalMonitorChange(isOpen);
  }

  function onAcceptModalExecute(isOpen: boolean): void {
    onModalExecuteChange(!isOpen);
    onModalMonitorChange(isOpen);
    callExecuteProcessAsync();
  }

  function checkDateParam(executeParameters: Parameter[]) : void{
    setExecuteParameters(executeParameters.map(
      (parm) => 
        parm.paramType=="date" ? 
        parm.paramValue = formatDateYYYYMMDDtoDDMMYYYY(parm.paramValue) 
        : parm
      ));
  }

  function onAcceptModalParameters(isOpen: boolean): void {
    const mandatoryParams: Boolean = areAllMandatoryParameters(executeParameters);
    setMissingParameters(!mandatoryParams);
    const validParameters: Boolean = areValidParameters(executeParameters);
    setNotValidParameters(!validParameters);
    checkDateParam(executeParameters);
    if (mandatoryParams && validParameters) {
      onModalMonitorChange(isOpen);
      callExecuteProcessAsync();
    }
  }

  function onAcceptModalMonitor(isOpen: boolean): void {
    onModalMonitorChange(!isOpen);
    if(lastProcessId) {
      addExecutedProcessId(lastProcessId);
      saveMonitoredProcessToCookies(lastProcessId);
    }
  }

  async function callExecuteProcessAsync(): Promise<void> {
    const name = (selectedProcess as Process).groupName ? (selectedProcess as Process).groupName : (selectedProcess as GroupDefinition).name;
    setIsLoading(true);
    const id = await executeProcess(name, executeParameters);
    setIsLoading(false);
    setLastProcessId(id);
  }
  
  function saveMonitoredProcessToCookies(id : Number) {
    let monitoredProcesses = browserStorage.get('monitoredProcesses');
    if(!monitoredProcesses) {
      monitoredProcesses = [];
    }
    if(!monitoredProcesses.includes(id)) {
      monitoredProcesses.push(id);
      browserStorage.set('monitoredProcesses', monitoredProcesses);
    }
  }

  function onExecuteParametersChange(executeParams: Parameter[]): void {
    setExecuteParameters(executeParams);
  }

  useEffect(() => {
    if (getParameters(selectedProcess).length !== 0) {
      setIsModalParametersOpen(true);
    } else {
      setIsModalExecuteOpen(true);
    }
  }, []);

  return isLoading ? (
    <div className="absolute w-full mt-6 p-2 px-6 flex items-center h-full justify-center">
      <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
    </div>
  ) : (
    <div>
      <ExecuteModalView {...getModalProps()} onExecuteParametersChange={onExecuteParametersChange} />
    </div>
  );
}
