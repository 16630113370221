import { css, StyleSheet } from 'aphrodite';
import React from 'react';

const FieldsFooterInfo = ({ values }) => {
  let fields = [];
  for (const value in values)
    fields.push(
      <p className={css(styles.element)} key={value}>
        {values[value]}
      </p>,
    );
  return fields;
};

const Footer = (props) => {
  const { footerInfo, operationLook } = props;
  const { sessionInfo, additionalInfo } = footerInfo;

  return (
    <footer
      className={`w-full flex p-1 pin-b text-xs ${operationLook ? 'bg-light' : 'bg-grey-lighter-lighter'} text-text-base fixed`}
      style={{ maxHeight: '20px' }}
    >
      <div className="float-left flex justify-start w-full" style={{ maxWidth: '200px' }}>
        {additionalInfo && <FieldsFooterInfo values={additionalInfo} />}
      </div>
      <div className={`float-right flex justify-end w-full`}>{sessionInfo && <FieldsFooterInfo values={sessionInfo} />}</div>
    </footer>
  );
};

const styles = StyleSheet.create({
  element: {
    margin: '0 .75rem',
    flexShrink: 0,
  },
});

export default Footer;
