import React, { useState } from 'react';
import './loginSelection.css';
import usePasswordLogin from './usePasswordLogin';
import useHuellaLogin from './useHuellaLogin';
import PasswordIcon from '../../resources/icons/fa/psw.svg';
import FingerPrinter from '../../resources/icons/fa/fingerprinter.svg';
import { CLIENT_GUID, CLIENT_VERSION_INFO, USER_NAME } from '../../config';
import { useIntl } from 'react-intl';
import { PulseLoader } from 'halogenium';
import { useTheme } from '../../themes/ThemeContext';
import { css, StyleSheet } from 'aphrodite';
import browserStorage from '../../utils/browserStorage';
import * as LS from './constants/LoginStatus';
import { loginPasswordAndFingerprint } from '../../api/fingerprintWS';

const LoginSelectionView = ({
  initSession,
  loading,
  setLoading,
  setLoginMessage,
  setError,
  handleLoginNumber,
  loginMessage,
  error,
  onInputChange,
}) => {
  const handleLogin = async (huellaBase64) => {
    const sessionID = await handleInitSession();

    if (loginType === 'password') {
      handlePassword();
    } else if (loginType === 'fingerPrinter') {
      handleFingerPrinter(sessionID, huellaBase64);
    } else if (loginType === 'passwordFingerPrinter') {
      handlePasswordFingerPrinter(sessionID);
    }
  };

  const { performPasswordLogin, getPasswordView, password, resetPwdInfo } = usePasswordLogin({
    setLoading,
    setLoginMessage,
    setError,
    handleLoginNumber,
    handleLogin,
  });
  const {
    performHuellaLogin,
    getHuellaView,
    hasHuellaLogin,
    requireRegisterFingerprint,
    setRequireRegisterFingerprint,
    huellaBase64,
    setHuellaBase64,
  } = useHuellaLogin({
    setLoading,
    setLoginMessage,
    setError,
    handleLoginNumber,
    handleLogin,
  });
  const [loginType, setLoginType] = useState('password');
  const [username, setUsername] = useState('');
  const intl = useIntl();
  const themeState = useTheme();
  const { color, primary } = themeState.theme;

  const placeholderUsername = intl.formatMessage({
    id: 'userLogin',
    defaultMessage: 'Usuario',
  });

  const styles = StyleSheet.create({
    loginButton: {
      color: color.white,
      backgroundColor: primary.primary,
      padding: '.75rem 0',
      width: '100%',
      borderRadius: 4,
      boxShadow: '0 2px 0px 0 #00151F',
      transition: '.2',
      ':hover': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.light,
      },
      ':focus': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.light,
      },
      ':active': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.dark,
      },
      ':disabled': {
        backgroundColor: '#6b7280',
        color: color.white,
        cursor: 'not-allowed',
        opacity: 0.75,
      },
    },
  });

  const resetLoginType = (type) => {
    setLoginType(type);
    setError(null);
    resetPwdInfo();
    setRequireRegisterFingerprint(false);
    setHuellaBase64(null);
  };

  const handlePassword = async () => {
    await performPasswordLogin(username);
  };

  const handlePasswordFingerPrinter = async (sessionID) => {
    setLoginMessage(null);
    setLoading(true);

    try {
      const handleResponse = (response) => {
        const { errorTypeInfo, mensaje, returnCode } = response.data;
        if (errorTypeInfo) {
          setError(mensaje);
        }
        browserStorage.sessionSet(USER_NAME, username);
        if (!requireRegisterFingerprint) {
          setRequireRegisterFingerprint(returnCode === LS.FINGERPRINT_NOT_REGISTERED);
        }

        handleLoginNumber(returnCode);
      };
      const handleCatch = (err) => {
        const { message } = err.response.data;
        setError(message || err.message || err);
      };

      await loginPasswordAndFingerprint(sessionID, username, password, huellaBase64).then(handleResponse).catch(handleCatch);
    } catch (err) {
      setError(err.message || err);
    } finally {
      setLoading(false);
    }
  };

  const handleFingerPrinter = async (sessionID, huellaBase64) => {
    await performHuellaLogin(sessionID, username, huellaBase64);
  };

  const handleInitSession = async () => {
    const sessionID = await initSession(CLIENT_GUID, CLIENT_VERSION_INFO);
    return sessionID;
  };

  const renderLoginType = () => {
    return (
      <div className="divLogin">
        <input
          type="text"
          name="username"
          placeholder={placeholderUsername}
          className="logininput"
          onChange={(e) => {
            setUsername(e.target.value);
            onInputChange(e);
          }}
        />
        {loginType === 'password' && getPasswordView()}
        {loginType === 'fingerPrinter' && getHuellaView({ username, password, registerFinger: requireRegisterFingerprint, multipleLogin: false })}
        {loginType === 'passwordFingerPrinter' && (
          <div>
            {getPasswordView()}
            {getHuellaView({ username, password, registerFinger: requireRegisterFingerprint, multipleLogin: true })}
          </div>
        )}
        <button
          disabled={loading || requireRegisterFingerprint || (loginType !== 'password' && !hasHuellaLogin)}
          className={css(styles.loginButton)}
          onClick={handleLogin}
        >
          {loading ? <PulseLoader color="white" size="6px" /> : intl.formatMessage({ id: 'loginButton' })}
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="loginComponent">
        <div className="boxSelectionContainer">
          <div
            className="loginbox"
            onClick={() => resetLoginType('password')}
            style={{ backgroundColor: loginType === 'password' ? 'rgb(235, 236, 240)' : '' }}
          >
            <div className="divUserPasword">
              <PasswordIcon className="iconLoginComponent" />
            </div>
            <p className="loginfont">{intl.formatMessage({ id: 'password' })}</p>
          </div>

          <div
            className="loginbox"
            style={{ backgroundColor: loginType === 'fingerPrinter' ? 'rgb(235, 236, 240)' : '' }}
            onClick={() => resetLoginType('fingerPrinter')}
          >
            <div className="divUserPasword">
              <FingerPrinter className="iconLoginComponent" />
            </div>
            <p className="loginfont">{intl.formatMessage({ id: 'fingerprint' })}</p>
          </div>

          <div
            className="loginbox"
            onClick={() => resetLoginType('passwordFingerPrinter')}
            style={{ backgroundColor: loginType === 'passwordFingerPrinter' ? 'rgb(235, 236, 240)' : '' }}
          >
            <div className="divUserPasword">
              <PasswordIcon className="iconLoginComponent" />
              <FingerPrinter className="iconLoginComponent" />
            </div>
            <p className="loginfont">{intl.formatMessage({ id: 'passwordPlusFingerprint' })}</p>
          </div>
        </div>
        <div className="divider"></div>
        <div className="typeLoginContainer">{renderLoginType()}</div>
      </div>
      <div className="loginFooter">
        {error && <div className="loginerrorMessage">{error}</div>}
        {loginMessage && <div className="loginerrorMessage">{loginMessage}</div>}
        <div className="copyRightContainer">
          La información confidencial y propiedad intelectual, pertenecen a Grupo Topaz.
          <span>Todos los derechos reservados.</span>
        </div>
      </div>
    </>
  );
};

export default LoginSelectionView;
