import React from 'react';
import { GroupDefinition } from '../../models/groupDefinition';
import { SelectedProcess } from '../../models/types';
import { ProcessDetail } from './processDetail/ProcessDetail';
import { ProcessesMenu } from './processesMenu/ProcessesMenu';
import '../../ProcessesManager.scss';

interface ProcessesTabProps {
  processesGroups: GroupDefinition[];
  onSelectProcess: (process: SelectedProcess) => void;
  showModal: () => void;
  selectedProcess: SelectedProcess;
  onModalConditionChange: (isOpen: boolean, isConditioningBoth?: boolean) => void;
  onModalScheduleChange: (isOpen: boolean) => void;
}

export function ProcessesTab({
  onSelectProcess,
  processesGroups,
  showModal,
  selectedProcess,
  onModalConditionChange,
  onModalScheduleChange,
}: ProcessesTabProps): JSX.Element {
  return (
    <div className="processes-manager-container">
      <div className="menu-container rounded border border-light">
        <ProcessesMenu
          selectedProcess={selectedProcess}
          onSelectProcess={onSelectProcess}
          processesGroups={processesGroups}
          showModal={showModal}
          onModalConditionChange={onModalConditionChange}
          onModalScheduleChange={onModalScheduleChange}
        />
      </div>
      <ProcessDetail selectedProcess={selectedProcess} />
    </div>
  );
}
