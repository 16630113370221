import React from 'react';
import { connect } from 'unistore/react';
import { PulseLoader } from 'halogenium';

import { Renderable } from '../../renderer';
import withOperationServices from '../api/withOperationServices';

import { store, storeActions } from '../store';
import { getTabInformation } from '../../src/selectors';
import { FormattedMessage } from 'react-intl';
import { createBodyBegin } from '../../renderer/lib/bodyCreators';
import OperationInfoBar from './OperationInfoBar';
import { css, StyleSheet } from 'aphrodite';
import { generateLineIDString } from '../../UI-kit/helpers/operationHelper';
import TreePool from '../../renderer/lib/TreePool';

const calculateMaxHeight = (trees) => {
  const fixedPaddingOffset = 100;
  let minHeight = 400 + fixedPaddingOffset;

  if (!trees) return minHeight;

  trees.forEach((tree) => {
    if (tree._data.displayInfo && tree._data.displayInfo.displayInfo) {
      if (tree._data.displayInfo.displayInfo.position) {
        const { y, height } = tree._data.displayInfo.displayInfo.position;
        const currentHeight = 0 + y + height + fixedPaddingOffset;

        if (currentHeight > minHeight) {
          minHeight = currentHeight;
        }
      }
    }
  });

  return minHeight;
};

const isErrorServices = (operationID) => {
  if (typeof operationID === 'string') {
    let msg = '';
    let splitted = operationID.split(':');
    if (splitted?.length > 1 && splitted[1]) {
      msg = splitted[1].trim();
      if (splitted.length > 2 && splitted[2]) {
        let splitted2 = splitted[2].split('java.io');
        msg += ':' + splitted2[0];
      }
    } else if (splitted && splitted[1]) {
      msg = splitted[1].trim();
    }
    return msg;
  }
  return false;
};

class TopazDisplay extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      terminated: false,
      operationCreated: false,
    };
    this.mainDiv = React.createRef();
    this.handleBeginOperation = this.handleBeginOperation.bind(this);
  }

  handleBeginOperation(operationID, tabIndex, operationNumber) {
    const { beginOperation, processContext, setPostingNumberTab, setTabLoadingBegin, tabId } = this.props;
    const body = createBodyBegin(operationNumber);

    this.props.setApiLoading(true, tabId);

    beginOperation(operationID, body).then((components) => {
      const infoPosting = components.lista.find((c) => c.infoType === 1);
      const infoName = components.lista.find((c) => c.infoType === 2);
      const postingNumber = infoPosting ? infoPosting.value : 'SIN NÚMERO';
      const operationName = infoName ? infoName.value : '';
      setPostingNumberTab(tabIndex, postingNumber, operationName);
      processContext(components, 'beginOperation').finally(() => {
        this.props.setApiLoading(false, tabId);
      });
      this.setState({
        loading: false,
        operationCreated: false,
      });
      setTabLoadingBegin(tabId, false);
    });
  }

  componentDidMount() {
    const { operationNumber } = this.props;
    if (operationNumber !== null) {
      this.createOperation();
    }
  }

  componentDidUpdate(prevProps) {
    const { operationCreated, operationNumber } = this.props;
    if (prevProps.operationCreated !== operationCreated) {
      if (!operationCreated && !this.state.operationCreated) {
        if (operationNumber !== null) {
          this.createOperation();
        }
      }
    }
  }

  createOperation = () => {
    const {
      createOperation,
      operationData,
      operationNumber,
      subOperation,
      operationLook,
      inputParameters,
      serverTasks,
      setInputParameters,
      setServerTasks,
      overwritePosition,
      TZPCAPTParameters,
      setTZPCAPTParameters,
    } = this.props;

    const { operationID: hasOperationID } = operationData;
    const errorRequireLogout = false;
    this.setState({ operationLook: operationLook });
    if (!hasOperationID) {
      this.setState({ loading: true });

      const { runTab, tabIndex } = this.props;
      if (!overwritePosition && operationNumber !== undefined && operationNumber !== null) {
        createOperation(operationNumber, subOperation)
          .then(async (operationID) => {
            runTab(tabIndex, operationID);
            this.setState({ operationCreated: true });

            const errorServices = isErrorServices(operationID);

            if (errorServices) {
              const { openGlobalModal, removeTab } = this.props;
              openGlobalModal(
                <div>
                  <FormattedMessage id="userSidebar" defaultMessage={`Ha ocurrido un error al crear la operación:`} />
                  <div className="mt-4">
                    <i style={{ overflowWrap: 'break-word' }}>{errorServices}</i>
                  </div>
                </div>,
                errorRequireLogout,
              );
              removeTab(tabIndex, { breakFree: false });
            } else if (TZPCAPTParameters || inputParameters || serverTasks) {
              if (TZPCAPTParameters) {
                await setTZPCAPTParameters(operationID, TZPCAPTParameters);
              }

              if (inputParameters) {
                await setInputParameters(operationID, inputParameters);
              }

              if (serverTasks) {
                await setServerTasks(operationID, serverTasks);
              }

              this.handleBeginOperation(operationID, tabIndex, operationNumber);
            } else {
              this.handleBeginOperation(operationID, tabIndex, operationNumber);
            }
          })
          .catch((err) => {
            const { message } = err;
            const { removeTab } = this.props;
            console.error('Ha ocurrido un error al crear la operación', err, message);
            removeTab(null, { breakFree: false });
          })
          .finally(() => {
            store.setState({ startingOperation: false });
          });
      } else {
        runTab(tabIndex, null);
        this.setState({ loading: false, operationCreated: true });
      }
    }
  };

  addGeneralModalMessages = (modalMessageList, trees) => {
    if (modalMessageList && modalMessageList.length > 0) {
      for (let i = 0; i < modalMessageList.length; i++) {
        const modalMessage = modalMessageList[i];
        if (modalMessage.show) {
          const treePool = new TreePool(
            [
              {
                'class-name': 'com.topsystems.jtopaz.opmgr.topaz41.events.NoticeToDisplayEvent',
                displayInfo: {
                  buttons: [4, 8],
                  defaultButton: 4,
                  infoObject: modalMessage.infoObject,
                  text: modalMessage.message,
                  textIsFormula: true,
                  title: modalMessage.title,
                  type: 1,
                },
                lineID: {
                  countIterativeDialog: false,
                  lineNumber: modalMessage.lineNumber,
                  tabId: this.props.tabId,
                  transactionNumber: 0,
                },
                modalMessage: modalMessage,
              },
            ],
            trees,
          );
          trees = treePool.trees;
        }
      }
    }

    return trees;
  };

  render() {
    const { loading, terminated } = this.state;

    const { operationData, operationNumber, operationName, operationLook, postingNumber, transactionNumber, transactionDescription } = this.props;

    const { trees, stateByLineID } = operationData;

    const minHeight = calculateMaxHeight(trees);

    if (!trees || loading) {
      return (
        <div className="flex justify-center items-center h-screen">
          <PulseLoader className="text-primary" color="currentColor" size="10px" />
        </div>
      );
    }

    if (terminated) {
      return (
        <div className="py-12">
          <FormattedMessage id="finishOperation" defaultMessage={`Operación finalizada`} />
        </div>
      );
    }

    let treesToDisplay = [];

    trees.forEach((e, index) => {
      // if (index > trees.length - this.MAX_SHOWN_DIALOGS - 1) {
      if (e._data.lineID) {
        const lineIdKey = generateLineIDString(e._data.lineID);
        if (e._data && e._data.lineID && stateByLineID[lineIdKey] && !stateByLineID[lineIdKey].deleted && !stateByLineID[lineIdKey].hide) {
          treesToDisplay.push(e);
        }
      }
    });
    if (!treesToDisplay) {
      treesToDisplay = [];
    }

    if (!terminated && trees.length > 0) {
      const indexTreeBlockingDialog = trees.findIndex(
        (tree) =>
          tree._data['class-name'] &&
          (tree._data['class-name'].includes('NoticeToDisplayEvent') ||
            tree._data['class-name'].includes('RemoteToLaunchEvent') ||
            tree._data['class-name'].includes('ApplicationToLaunchEvent') ||
            tree._data['class-name'].includes('PersonIdentificationEvent')),
      );
      if (indexTreeBlockingDialog !== -1) {
        treesToDisplay = trees.slice(0, indexTreeBlockingDialog + 1);
      }
    }

    treesToDisplay = this.addGeneralModalMessages(this.props.modalMessage, treesToDisplay);
    if (this.mainDiv.current !== null) {
      this.mainDiv.current.scrollLeft = 0;
    }

    const { apiLoading } = this.props;

    const styles = StyleSheet.create({
      insideArea: {
        width: '100%',
        height: '100%',
      },
      topazDisplay: {
        pointerEvents: apiLoading ? 'none' : 'auto',
        cursor: apiLoading ? 'wait' : 'auto',
      },
      overlay: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        zIndex: 100,
        position: 'absolute',
        top: 0,
        left: 0,
        cursor: 'wait',
      },
    });

    return (
      <div className={'overflow-x-scroll overflow-y-hidden ' && styles.overlay} ref={this.mainDiv}>
        <OperationInfoBar
          operationNumber={operationNumber}
          operationName={operationName}
          operationPosting={postingNumber}
          transactionNumber={transactionNumber}
          transactionDescription={transactionDescription}
          showTransaction={this.props.state.clientProperties?.showTransaction === 'true'}
        />

        <div className={css(!!apiLoading && styles.overlay)}></div>

        <div className={`${css(styles.topazDisplay)} ${operationLook === true ? 'bg-not-as-white' : ''} p-8 mt-10`}>
          {!terminated && treesToDisplay.length > 0 && (
            <div className="relative" style={{ minHeight }}>
              {treesToDisplay.map((tree, index) => (
                <div className="" key={index}>
                  <Renderable zIndex={index} root={tree} fieldData={stateByLineID} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    tabIndex,
    operationNumber,
    operationName,
    operationLook,
    operationData,
    postingNumber,
    inputParameters,
    serverTasks,
    overwritePosition,
    subOperation,
    operationCreated,
    apiLoading,
  } = getTabInformation(state, ownProps.tabId);

  return {
    state,
    tabIndex,
    operationData,
    operationNumber,
    operationName,
    operationLook,
    postingNumber,
    inputParameters,
    serverTasks,
    overwritePosition,
    subOperation,
    operationCreated,
    apiLoading,
    modalMessage: state.modalMessage,
  };
};

export default connect(mapStateToProps, storeActions)(withOperationServices(TopazDisplay));
