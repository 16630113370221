import React, { useState, useEffect } from 'react';
import { connect } from 'unistore/react';
import { storeActions } from '../store';
import { getRecentReports, getRecentOperations, removeRecentOperation, removeRecentReport } from '../utils/recentValues';
// import '../styles/components/EmptyTab.css'
import MenuGrid from '../containers/MenuGrid';
import FilterOperations from './FilterOperations';
import FilterReports from './FilterReports';
import PropTypes from 'prop-types';
import RecentItems from './RecentItems';

const propTypes = {
  itemsToShow: PropTypes.string.isRequired,
  handleAddNewTab: PropTypes.func,
  handleNewTabReport: PropTypes.func,
  handleAddNewTabReport: PropTypes.func,
  showItemsInsteadOfCategories: PropTypes.bool.isRequired,
  selectedCategory: PropTypes.object,
  showCategories: PropTypes.func.isRequired,
  recentItemsCollapsibleIsOpen: PropTypes.bool.isRequired,
  handleRecentItemsCollapsibleClick: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired,
};

const EmptyTab = (props) => {
  const {
    itemsToShow,
    handleAddNewTab,
    handleNewTabReport,
    handleAddNewTabReport,
    recentItemsCollapsibleIsOpen,
    handleRecentItemsCollapsibleClick,
  } = props;

  const [recentOperations, setRecentOperations] = useState();
  const [recentReports, setRecentReports] = useState();

  useEffect(() => {
    const loadData = async () => {
      let ops = await getRecentOperations();
      setRecentOperations(ops);
      let reps = await getRecentReports();
      setRecentReports(reps);
    };
    loadData();
  }, []);
  return itemsToShow === 'REPORTS' ? (
    <div className="mx-4">
      <div>
        <div className="mb-16 mt-8 mx-auto">
          <FilterReports selectReport={handleNewTabReport} />
        </div>
        <section>
          <RecentItems
            items={recentReports || []}
            title={{
              id: 'frequentReports',
              defaultMessage: 'Reportes frecuentes',
            }}
            handleItemClick={handleAddNewTabReport}
            collapsibleIsOpen={recentItemsCollapsibleIsOpen}
            handleCollapsibleClick={handleRecentItemsCollapsibleClick}
            onRemove={removeRecentReport}
            setRecent={setRecentReports}
          />
        </section>
        <section>
          <MenuGrid itemsToShow={'REPORTS'} />
        </section>
      </div>
    </div>
  ) : (
    <div className="mx-4">
      <div className="container max-w-xl mx-auto">
        <div className="mb-16 mt-8 mx-auto">
          <FilterOperations />
        </div>
        <section>
          <RecentItems
            items={recentOperations || []}
            title={{
              id: 'frequentOperations',
              defaultMessage: 'Operaciones frecuentes',
            }}
            handleItemClick={handleAddNewTab}
            collapsibleIsOpen={recentItemsCollapsibleIsOpen}
            handleCollapsibleClick={handleRecentItemsCollapsibleClick}
            onRemove={removeRecentOperation}
            setRecent={setRecentOperations}
          />
        </section>
        <section>
          <MenuGrid itemsToShow={'OPERATIONS'} />
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tabs: state.tabs,
  current: state.current,
  showItemsInsteadOfCategories: state.menuGridInfo.showItemsInsteadOfCategories,
  selectedCategory: state.menuGridInfo.selectedCategory,
});

EmptyTab.propTypes = propTypes;

export default connect(mapStateToProps, storeActions)(EmptyTab);
