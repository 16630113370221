import { PulseLoader } from 'halogenium';
import React from 'react';
import { useIntl } from 'react-intl';
import { WarningModalView } from './warningModal/WarningModalView';
import { Select, SelectData } from '../select/Select';

import './ConditionModal.scss';
import moment from 'moment';
import { CHECKED, ConditionGroup } from './ConditionModalViewModel';

interface ConditionModalViewProps {
  onCancel: (event: React.MouseEvent) => void;
  onAccept: (event: React.MouseEvent) => void;
  isConditioningBoth?: boolean;
  childs: SelectData[];
  onInputChange: (event: { target: HTMLInputElement }) => void;
  onSelectStringChange: (value: string, name?: string) => void;
  dateOptions: SelectData[];
  periodicityOptions: SelectData[];
  conditionInfo: ConditionGroup;
  errorMessage: boolean;
  isLoading: boolean;
  isModalWarningOpen: boolean;
  onModalWarningChange: (isAccept: boolean) => void;
  isEditing: boolean;
}

const DATE_FORMAT: string = 'YYYY-MM-DD';

export function ConditionModalView({
  onAccept,
  onCancel,
  isConditioningBoth,
  childs,
  dateOptions,
  conditionInfo,
  onInputChange,
  periodicityOptions,
  errorMessage,
  onSelectStringChange,
  isLoading,
  isModalWarningOpen,
  onModalWarningChange,
  isEditing,
}: ConditionModalViewProps): JSX.Element {
  const intl = useIntl();

  return isLoading ? (
    <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
      <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
    </div>
  ) : (
    <div className="condition-modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="ml-5 mt-5">{intl.formatMessage({ id: 'conditionExecution' })}</div>
      {isModalWarningOpen && (
        <WarningModalView question={intl.formatMessage({ id: 'alreadyExistsCondition' })} onModalChange={onModalWarningChange} />
      )}
      <div className="separator mt-4 border-t w-full" />
      <div className={`modal-container justify-start ${isModalWarningOpen && 'blur-info'}`}>
        <div className="flex flex-col w-full">
          <div className="flex flex-row m-4">
            <label className="mr-2 w-1/4 text-center">{intl.formatMessage({ id: 'parentGroup' })}:</label>
            <input disabled={true} value={conditionInfo.parentGroup} className="border rounded w-2/3" />
          </div>
          <div className="flex flex-row m-4">
            <label className="mr-2 w-1/4 text-center">{intl.formatMessage({ id: 'childGroup' })}:</label>
            <Select
              name="childGroup"
              disabled={!isConditioningBoth || isModalWarningOpen || isEditing}
              options={childs}
              selectedState={conditionInfo.childGroup}
              onSelectedChange={onSelectStringChange}
              className="w-2/3 input"
            />
          </div>
        </div>
        <div className="flex flex-row justify-around">
          <div className="flex flex-col m-4">
            <label>{intl.formatMessage({ id: 'periodicity' })}:</label>
            <Select
              disabled={isModalWarningOpen}
              name="periodicity"
              options={periodicityOptions}
              selectedState={conditionInfo.periodicity}
              onSelectedChange={onSelectStringChange}
              className="input"
            />
          </div>
          <div className="flex flex-col m-4">
            <label>{intl.formatMessage({ id: 'day' })}:</label>
            <Select
              name="day"
              disabled={isModalWarningOpen}
              options={dateOptions}
              selectedState={conditionInfo.day}
              onSelectedChange={onSelectStringChange}
              className="input"
            />
          </div>
          <div className="flex flex-col m-4">
            <label>{intl.formatMessage({ id: 'nextExecution' })}:</label>
            <input
              value={moment(conditionInfo.nextExecution).format(DATE_FORMAT)}
              disabled={isModalWarningOpen}
              name="Date"
              type="date"
              className="border rounded"
              onChange={onInputChange}
            />
          </div>
          <div className="flex flex-col m-4">
            <label>{intl.formatMessage({ id: 'labor' })}:</label>
            <input
              name="Labor"
              disabled={isModalWarningOpen}
              onChange={onInputChange}
              checked={conditionInfo.labor === CHECKED}
              type="checkbox"
              className="border rounded self-center w-3/4"
            />
          </div>
        </div>
      </div>
      {errorMessage && <label className="error-message pr-1 pt-2 text-red flex">{intl.formatMessage({ id: 'completeRequiredFields' })}</label>}
      <div className="flex flex-row justify-end bg-gray-50 px-4 py-3">
        <button
          onClick={onAccept}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {intl.formatMessage({ id: 'accept' })}
        </button>
        <button
          onClick={onCancel}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {intl.formatMessage({ id: 'close' })}
        </button>
      </div>
    </div>
  );
}
