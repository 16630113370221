export const USR_OK = 1;
export const PWD_NOT_EXIST = 8;
export const PWD_EXPIRED = 9;
export const PWD_EXPIRES_SOON = 10;
export const USR_ALREADY_LOGGED_ANOTHER_SESSION_LOGGED_OUT = 14;
export const USR_ALREADY_LOGGED_CONFIRMATION = 15;
export const USR_ALREADY_LOGGED_CANT_LOGIN = 16;
export const USR_EXTRAVALIDATIONERROR = 17;
export const USR_NO_PERMISSION = 18;
export const USR_INVALID_DIRECTORY_SERVICE = 19;
export const PWD_INVALID_AD = 20;
export const USR_NOT_EXISTS_AD = 21;
export const USR_PSW_DISABLED = 3;

export const FINGERPRINT_LOGIN_TYPE_ERROR = 96;
export const FINGERPRINT_LOGIN_CONFIGURATED_ERROR = 97;
export const FINGERPRINT_INVALID = 98;
export const FINGERPRINT_NOT_REGISTERED = 99;

export const LoginStatusMessages = {
  [USR_ALREADY_LOGGED_ANOTHER_SESSION_LOGGED_OUT]: {
    messageId: 'usrAlreadyLoggedAnotherSessionLoggedOut',
    defaultMessage: 'Para iniciar esta sesión fue necesario cerrar una de sus sesiones',
  },
  [USR_ALREADY_LOGGED_CONFIRMATION]: {
    messageId: 'usrAlreadyLoggedConfirmation',
    defaultMessage: 'Se ha excedido el límite de sesiones abiertas.\r\n¿Desea cerrar una sesión anterior para iniciar esta sesión?',
  },
  [USR_ALREADY_LOGGED_CANT_LOGIN]: {
    messageId: 'usrAlreadyLoggedCantLogin',
    defaultMessage: 'Se ha excedido el límite de sesiones abiertas',
  },
  [PWD_EXPIRES_SOON]: {
    messageId: 'passwordExpiresSoon',
    defaultMessage: 'Su contraseña expirará pronto.\r\n¿Desea cambiar su contraseña ahora?',
  },
  [PWD_EXPIRED]: {
    messageId: 'passwordExpired',
    defaultMessage: 'Su contraseña ha vencido. Debe ingresar una contraseña nueva',
  },
  [USR_PSW_DISABLED]: {
    messageId: 'passwordDisabled',
    defaultMessage: 'La clave ha sido deshabilitada. Consulte al administrador.',
  },
  [FINGERPRINT_LOGIN_TYPE_ERROR]: {
    messageId: 'userPutFingers',
    defaultMessage: 'Login type error con huella.',
  },
  [FINGERPRINT_LOGIN_CONFIGURATED_ERROR]: {
    messageId: 'userPutFingers',
    defaultMessage: 'Login configuration error con huella.',
  },
  [FINGERPRINT_INVALID]: {
    messageId: 'userPutFingers',
    defaultMessage: 'Login con huella invalido.',
  },
  [FINGERPRINT_NOT_REGISTERED]: {
    messageId: 'userPutFingers',
    defaultMessage: 'Huella no registrada.',
  },
};
