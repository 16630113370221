import React, { Component } from 'react';
import IcoMoon from 'react-icomoon';
import Draggable from 'react-draggable';
import { connect } from 'unistore/react';
import { storeActions } from '../../store';
import { FormattedMessage } from 'react-intl';

import Overlay from '../../../UI-kit/components/Overlay';
import { ABOVE_ALL } from '../../../UI-kit/constants/zIndexValues';
import { increaseSecondLoginError, verifyFingerLogin } from '../../api/session';

import FocusTrap from 'react-focus-trap';
import { startCapture, reset, getScanned } from '../../api/fingerprintWS';

const Button = ({ title, click, className, disabled = false }) => {
  return (
    <button
      className={`${className} ${disabled && 'opacity-75'} bg-grey hover:bg-primary focus:bg-primary text-white rounded shadow m-2 py-2 px-4 text-sm`}
      onClick={click}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export class LoginFingerPrint extends Component {
  constructor(props) {
    super(props);
    const { action } = this.props;
    this.state = {
      showWindow: true,
      lastScannedFingerprint: null,
      lastScannedANSI: null,
      lastScannedBitmap: null,
      ansiArray: [],
      valueToSend: 0,
      timeout: null,
      scanning: false,
      scannedAll: false,
      action: action,
      validationData: null,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.getScannedVerifyLoop = this.getScannedVerifyLoop.bind(this);
  }

  async componentDidMount() {
    this.handleResume();
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
    reset();
  }

  async loginWithFinger(base64Image) {
    const { tipoLoged, user, loginDialog } = this.props;
    this._ismounted = false;
    reset();
    await loginDialog(user, '', tipoLoged === 1 || !!base64Image, base64Image, true);
  }

  async increaseLoginError() {
    const { user, setSecondLogin, handleLoginNumber } = this.props;
    await increaseSecondLoginError(user);
    let loginNumber = await verifyFingerLogin(user);
    if (loginNumber === 1) {
      setSecondLogin(true);
    } else {
      handleLoginNumber(loginNumber);
      setSecondLogin(false);
      this._ismounted = false;
      reset();
    }
  }

  async getScannedVerifyLoop() {
    getScanned()
      .then((data) => {
        if (!this._ismounted) {
          return;
        }
        let status = data.status !== 204 ? '1' : '';
        if (status !== '') {
          let base64Image = 'data:image/png;base64,' + data.data.bmpImage;
          this.setState({ lastScannedFingerprint: base64Image });
          this.setState({ verify: status });
          if (status === '1') {
            setTimeout(() => this.loginWithFinger(data.data.fingerData), 2000);
          } else {
            this.setState({
              timeout: setTimeout(() => this.getScannedVerifyLoop(), 1000),
            });
          }
        } else {
          this.setState({
            timeout: setTimeout(() => this.getScannedVerifyLoop(), 1000),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        // eslint-disable-next-line no-undef
        alert('Occurió un error al leer la huella, por favor asegúrese que el lector y el dedo estén limpios y escanee los 4 dedos de nuevo.');
      });
  }

  async handleResume() {
    await startCapture();
    this.getScannedVerifyLoop();
    this.setState({ scanning: true });
  }

  resetReadData() {
    this.setState({
      lastScannedFingerprint: null,
      lastScannedBitmap: null,
      scanning: false,
    });
  }

  handleCancel() {
    const { setSecondLogin } = this.props;
    setSecondLogin(false);
    this._ismounted = false;
    reset();
    this.setState((prevState) => ({ showWindow: !prevState.showWindow }));
  }

  render() {
    let { showWindow } = this.state;

    let style = { width: 800 };

    let captureMessage = <span className="text-xs mx-10">Haga clic en comenzar el escaneo</span>;
    if (this.state.scanning) {
      captureMessage = <span className="text-xs mx-10">Dedos escaneados : {this.state.amountOfScannedFingerprints}</span>;
    } else if (this.state.scannedAll) {
      // eslint-disable-next-line no-unused-vars
      captureMessage = <span className="text-xs mx-10">Los 4 dedos han sido escaneados</span>;
    }

    let fingerImage;
    if (this.state.lastScannedFingerprint) {
      fingerImage = <img style={{ maxWidth: '200px', maxHeight: '250px' }} src={`${this.state.lastScannedFingerprint}`} />;
    }

    return (
      <FocusTrap className={`${showWindow ? '' : 'hidden'}`}>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className="border border-primary bg-grey-lighter-lighter rounded shadow absolute "
            style={{
              ...style,
              zIndex: ABOVE_ALL,
              width: '350px',
              height: '350px',
              top: 250,
            }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move m-1 text-xs" />
            <div className="mb-2 font-light text-primary text-center">
              <FormattedMessage id="validationFingerPrintPerson" defaultMessage={`Login de Topaz - huella dactilar`} />
            </div>
            <div
              className="flex justify-center mt-2  ml-16"
              style={{
                width: '200px',
                height: '250px',
              }}
            >
              {fingerImage}
            </div>
            {this.props.loginMessage ? (
              <div className="mb-2 font-light text-primary text-center">
                <FormattedMessage id="validationFingerPrintPerson" defaultMessage={this.props.loginMessage} />
              </div>
            ) : (
              <div></div>
            )}

            <div className="flex justify-center fixed-bottom ">
              <Button
                title={<FormattedMessage id="capture" defaultMessage={'Capturar'} />}
                click={() => this.handleResume()}
                disabled={this.state.scanning}
              />
              <Button title={<FormattedMessage id="cancel" defaultMessage={'Cancelar'} />} click={() => this.handleCancel()} />
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );
  }
}

export default connect(null, storeActions)(LoginFingerPrint);
