import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';

/* Maintenance */

export let getPersonSignatures = (numberPhysicalPerson) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/getPersonSingatures?sessionId=${session}&numeroPersonaFisica=${numberPhysicalPerson}`,
    )
    .then(({ data }) => data);
};

export let isUserAuthorizerSignatures = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/personidentification/isUsuarioAutorizadorFirmas?sessionId=${session}`).then(({ data }) => data);
};

export let saveSignauresInfo = (numberPhysicalPerson, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/saveSignauresInfo?sessionId=${session}&numeroPersonaFisica=${numberPhysicalPerson}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let getPhotos = (numberPhysicalPerson) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(`${API}TopazWebClientWS/resources/personidentification/getPhotos?sessionId=${session}&numeroPersona=${numberPhysicalPerson}`)
    .then(({ data }) => data);
};

export let savePhotosInfo = (numberPhysicalPerson, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/savePhotosInfo?sessionId=${session}&numeroPersonaFisica=${numberPhysicalPerson}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let getDocDesc = (codeDoc) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(`${API}TopazWebClientWS/resources/personidentification/getDocDesc?sessionId=${session}&codigoDoc=${codeDoc}`)
    .then(({ data }) => data);
};

export let getPersonDocPhotoFromNumber = (numberPhysicalPerson) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/getPersonDocPhotoFromNumber?sessionId=${session}&personNumber=${numberPhysicalPerson}`,
    )
    .then(({ data }) => data);
};

export let getCodesDocuments = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/personidentification/getCodigosDocumentos?sessionId=${session}`).then(({ data }) => data);
};

export let saveDocumentsImagesInfo = (numberPhysicalPerson, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/saveDocumentsImagesInfo?sessionId=${session}&numeroPersonaFisica=${numberPhysicalPerson}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let getPhysicalPerson = (numberPhysicalPerson) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/getPersonasFisicas?sessionId=${session}&numeroPersona=${numberPhysicalPerson}&primerNombre&segundoNombre&apellidoPaterno&apellidoMaterno`,
    )
    .then(({ data }) => data);
};

/* Validation */

export let getPowerTypes = () => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios.post(`${API}TopazWebClientWS/resources/personidentification/getTiposPoderes?sessionId=${session}`).then(({ data }) => data);
};

export let getRepresentatives = (idEntity, typePower, typeEntity) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/obtenerApoderados?sessionId=${session}&idEntidad=${idEntity}&tipoPoder=${typePower}&tipoEntidad=${typeEntity}`,
    )
    .then(({ data }) => data);
};

export let getTexts = (idEntity, typePower, typeEntity) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/getTextos?sessionId=${session}&idEntidad=${idEntity}&tipoPoder=${typePower}&tipoEntidad=${typeEntity}`,
    )
    .then(({ data }) => data);
};

export let validateRepresentatives = (idEntity, typePower, typeEntity, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/validarApoderados?sessionId=${session}&idEntidad=${idEntity}&tipoPoder=${typePower}&tipoEntidad=${typeEntity}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let validateRepresentativesByAmount = (currency, amount, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/validarApoderadosPorMonto?sessionId=${session}&moneda=${currency}&monto=${amount}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let getTypeAmount = (idEntity, typePower, typeEntity) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/getTipoMonto?sessionId=${session}&idEntidad=${idEntity}&tipoPoder=${typePower}&tipoEntidad=${typeEntity}`,
    )
    .then(({ data }) => data.message);
};

export let signaturesActionOK = (operationID, message, missingASignature = 0) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/signatures/signaturesActionOK?sessionId=${session}&operationID=${operationID}&message=${message}&sinFirma=${missingASignature}`,
    )
    .then(({ data }) => data);
};

export let signaturesActionCancel = (operationID, missingASignature = 0) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/signatures/signaturesActionCancel?sessionId=${session}&operationID=${operationID}&sinFirma=${missingASignature}`,
    )
    .then(({ data }) => data);
};

export let addPhoto = (numberPhysicalPerson, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(`${API}TopazWebClientWS/resources/personidentification/addPhoto?sessionId=${session}&numeroPersona=${numberPhysicalPerson}`, body, {
      headers,
    })
    .then(({ data }) => data);
};

export let getPerson = (person) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(`${API}TopazWebClientWS/resources/personidentification/getPerson?sessionId=${session}&numeroPersona=${person}`)
    .then(({ data }) => data);
};
