/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { storeActions, store } from '../../src/store';
import Displayable from './Displayable';
import { createRunBody, createBodyGoToLine, createSimpleBody } from '../../renderer/lib/bodyCreators';
import { gotoLineWithBody } from '../../src/api/dialog';
import { FormattedMessage } from 'react-intl';
import { getCurrentTabInformation } from '../../src/selectors';
import { textSize, mapColor } from '../helpers/styleHelper';
import { css, StyleSheet } from 'aphrodite';
import { num2hex } from '../../src/utils/ColorMaper';
import { addLineTest, addLineEventTest } from '../../src/components/Testing/HandleLineTest';
import { canReceiveFocus } from '../../renderer/lib/focusManager';

const DEFAULT_FONT_SIZE = 12;

class ComboInput extends Component {
  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.focusElement = this.focusElement.bind(this);
    this.state = {
      value: this.props.defaultValue || undefined,
      menuIsOpen: false,
    };
    this.clientProperties = store.getState().clientProperties;
    this.useBackColor = this.clientProperties?.useBackColor;

    this.input = React.createRef();
  }

  async onInputChange(e) {
    let {
      onChange,
      operationNumber,
      operationID,
      processContext,
      transactionLineLabel,
      lineNumber,
      transactionNumber,
      focusElement,
      fieldData,
      focusPaused,
    } = this.props;
    this.setLastValue(e.target.value);
    onChange(e);
  }

  performGoToLine(value) {
    if (!store.getState().validationFailed) {
      let {
        onChange,
        operationNumber,
        operationID,
        processContext,
        transactionLineLabel,
        lineNumber,
        transactionNumber,
        focusElement,
        fieldData,
        focusPaused,
      } = this.props;

      let body = createRunBody(operationNumber, transactionLineLabel, value);
      if (!focusPaused && transactionLineLabel !== focusElement) {
        let bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
        gotoLineWithBody(operationID, transactionNumber, lineNumber, bodyGoToLine).then((res) => {
          processContext(res, 'Combo Input gotoLine');
        });
      }
    }
  }

  componentDidMount() {
    this.focusElement();

    if (this.input && this.input.current) {
      this.input.current.onkeydown = this.lastValueListener;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focusElement !== this.props.focusElement) {
      if (document.activeElement.id !== this.props.focusElement) {
        this.focusElement();
      }
    }
  }

  componentWillUnmount() {
    if (this.input && this.input.current) {
      this.input.current.removeEventListener('keydown', this.lastValueListener);
    }
  }

  focusElement() {
    const { focusPaused, transactionLineLabel } = this.props;
    const state = store.getState();
    const tabIndex = state.current;
    const currentTab = state.tabs.length && state.tabs[tabIndex];
    const focusElement = currentTab && currentTab.running && currentTab.running.focusElement;
    if (!focusElement) {
      return;
    }
    const { menuIsOpen } = this.state;
    if (!focusPaused && transactionLineLabel === focusElement && !menuIsOpen) {
      if (this.input && this.input.current) {
        if (document.activeElement.id !== focusElement) {
          this.input.current.focus();
        }
      }
    }
  }

  lastValueListener = (e) => {
    e = e || window.event;
    if (e.ctrlKey && e.keyCode === 76) {
      e.preventDefault();
      e.stopPropagation();
      window.event.returnValue = false;
      window.event.cancelBubble = true;
      const { addFieldValue, lastValues, transactionLineLabel, fieldNumber } = this.props;
      const lastValue = lastValues && lastValues[fieldNumber];
      if (lastValue) {
        const value = { [transactionLineLabel]: { value: lastValue } };
        addFieldValue(value);
      }
      return false;
    }
  };

  handleBlur = (e) => {
    if (e.relatedTarget === null || e.relatedTarget.classList.contains('contorno')) {
      this.focusElement();
    }
    this.setMenuIsClosed();
  };

  handleChange = (e) => {
    const { onChange } = this.props;
    addLineTest('SELECT', e);
    this.setMenuIsClosed();

    this.changedValue = true;
    this.setState({ value: e.target.value });
    onChange(e);
  };

  handleClick = (e) => {
    addLineTest('CLICK', e);
    if (!canReceiveFocus(e)) return;
    this.performGoToLine(e.target.value);
    if (this.changedValue) {
      this.onInputChange(e);
      this.changedValue = false;
    }
  };

  handleKeyDown = (e) => {
    addLineEventTest(e);
    const { onKeyDown, operationID, operationNumber, processContext } = this.props;
    if (e.key === 'Esc' || e.key === 'Tab' || e.key === 'Enter' || (e.key === 'Tab' && e.shiftKey)) {
      this.setMenuIsClosed();
    }
    if ((e.key === 'Tab' && !e.shiftKey) || e.key === 'Enter') {
      if (this.validateData(e.target.value)) {
        onKeyDown(e, operationID, operationNumber, processContext);
        if (e.key === 'Tab' || e.key === 'Enter') {
          this.setLastValue(e.target.value);
          e.preventDefault();
        }
      }
    } else {
      onKeyDown(e, operationID, operationNumber, processContext);
    }
  };

  handleMouseDown = (e) => {
    this.setMenuIsOpen();

    const {
      focusPaused,
      transactionLineLabel,
      focusElement,
      operationID,
      operationNumber,
      processContext,
      fieldData,
      transactionNumber,
      lineNumber,
      componentState,
    } = this.props;

    let value;
    if (componentState !== undefined && componentState.value !== undefined) {
      value = componentState.value;
    } else {
      value = this.state.value;
    }
  };

  setLastValue = (valueToSave) => {
    const { setLastValue, fieldNumber } = this.props;
    setLastValue(fieldNumber, valueToSave);
  };

  setMenuIsClosed = () => {
    const { menuIsOpen } = this.state;
    if (menuIsOpen) {
      this.setState({ menuIsOpen: false });
    }
  };

  setMenuIsOpen = () => {
    const { menuIsOpen } = this.state;
    if (!menuIsOpen) {
      this.setState({ menuIsOpen: true });
    }
  };

  validateData(value) {
    if (value !== '') {
      return true;
    } else {
      const required = <FormattedMessage id="errorMandatoryField" defaultMessage={`es de ingreso obligatorio`} />;
      const msg = (
        <div>
          {this.props.label} {required}
        </div>
      );
      this.props.showModalMessage(msg);
      return false;
    }
  }

  render() {
    let {
      showPrompt,
      labelPosition = { x: 0, y: 0, width: 'auto', height: 'auto' },
      label,
      options,
      componentState,
      position,
      promptBackColor,
      font,
      labelFont,
      transactionLineLabel,
      readOnly,
    } = this.props;

    let tailwindColorLabel = labelFont && mapColor(`${labelFont.colorRed}, ${labelFont.colorGreen}, ${labelFont.colorBlue}`);
    const tailwindColor = mapColor(`${font.colorRed}, ${font.colorGreen}, ${font.colorBlue}`);
    const fontSize = textSize(font.fontSize);
    const underline = font.underLine ? 'underline' : '';
    const strikeout = font.strikeOut ? 'line-through' : '';
    const lineStyle = underline + ' ' + strikeout;
    const fontStyle = font.fontStyle;
    let disabled = componentState && componentState.disabled;
    var backColor = 'transparent';
    if (promptBackColor === 'transparent' && this.useBackColor === 'false') {
      tailwindColorLabel = 'text-base';
    }

    if (promptBackColor !== undefined && promptBackColor !== 'transparent') {
      backColor = num2hex(promptBackColor.value);
    }

    let optionLayout = options ? (
      options.map((option, key) => {
        return (
          <option
            style={{ textDecoration: lineStyle }}
            className={`text-${tailwindColor}`}
            key={key}
            value={option.value}
            disabled={readOnly || disabled}
          >
            {option.label}
          </option>
        );
      })
    ) : (
      <option style={{ textDecoration: lineStyle }} className={`text-${tailwindColor}`} disabled={readOnly || disabled}></option>
    );
    let isInWindowArea =
      componentState && componentState.windowWidth && componentState.windowHeight
        ? componentState.windowWidth > labelPosition.x && componentState.windowHeight > labelPosition.y
        : true;

    const labelStyle = {
      left: labelPosition.x,
      top: labelPosition.y,
      width: labelPosition.width,
      height: labelPosition.height,
      textDecoration: lineStyle,
      backgroundColor: backColor,
    };

    const inputStyle = {
      left: position.x,
      top: position.y,
      width: position.width,
      zIndex: 'inherit',
    };

    let addPadding = position.y - labelPosition.y < 0;

    let value;
    if (componentState !== undefined && componentState.value !== undefined) {
      value = componentState.value;
    } else {
      value = this.state.value;
    }
    this.focusElement();
    return (
      <Displayable componentState={componentState}>
        <label
          className={`${
            fontStyle === 1 ? 'font-semibold' : fontStyle === 2 ? 'italic' : ''
          } ${fontSize} text-${tailwindColorLabel} absolute bg-grey-lighter-lighter`}
          style={{
            ...labelStyle,
            fontSize: (labelFont && labelFont.fontSize) || 12,
          }}
        >
          {showPrompt && isInWindowArea ? label : ''}
        </label>
        <div className={`absolute flex ${addPadding ? '' : null}`} style={inputStyle}>
          <select
            id={transactionLineLabel}
            disabled={readOnly || disabled}
            className={`${css(styles.select)} w-full rounded-none shadow text-${tailwindColor} align-middle bg-white`}
            onClick={this.handleClick}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onMouseDown={this.handleMouseDown}
            onBlur={this.handleBlur}
            style={{ fontSize: DEFAULT_FONT_SIZE, padding: '1px' }}
            value={value}
            ref={this.input}
          >
            {optionLayout}
          </select>
        </div>
      </Displayable>
    );
  }
}

const styles = StyleSheet.create({
  select: {
    borderWidth: 2,
    borderColor: 'transparent',
    transition: '.1s',
    ':focus': {
      borderColor: '#6B7280',
    },
  },
});

const mapStateToProps = (state) => {
  const { operationID, operationNumber, focusElement, focusPaused } = getCurrentTabInformation(state);

  const { lastValues } = state;

  return {
    lastValues,
    operationID,
    operationNumber,
    focusElement,
    focusPaused,
  };
};

export default connect(mapStateToProps, storeActions)(ComboInput);
