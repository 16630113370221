export let formatSizeFile = (sizeBytes) => {
  var i = -1
  var byteUnits = ['KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
  do {
    sizeBytes = sizeBytes / 1024
    i++
  } while (sizeBytes > 1024)

  return Math.max(sizeBytes, 0.1).toFixed(1) + ' ' + byteUnits[i]
}

export let b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  var byteCharacters = window.atob(b64Data)
  var byteArrays = []

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize)

    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    var byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  var blob = new window.Blob(byteArrays, { type: contentType })
  return blob
}

export let fileArrayToBase64 = (fileArray, mimeType) => {
  let fileBase64 = ''
  let bytes = new Uint8Array(fileArray)
  for (let i = 0; i < bytes.byteLength; i++) fileBase64 += String.fromCharCode(bytes[i])
  fileBase64 = window.btoa(fileBase64)
  return `data:${mimeType || 'image/png'};base64,${fileBase64}`
}

export let fileBase64ToArray = (fileBase64) => {
  fileBase64 = extractInfoBase64(fileBase64)
  if (fileBase64) return Array.from(Int8Array.from(window.atob(fileBase64), c => c.charCodeAt(0)))
}

export let extractInfoBase64 = (fileBase64) => {
  const valueSearched = ';base64,'
  const posValueSearched = fileBase64.indexOf(valueSearched)
  if (posValueSearched !== -1) return fileBase64.substr(posValueSearched + valueSearched.length)
}

export let mimeCheck = (type) => {
  var supportedTypes = [
    'text/plain',
    'application/pdf',
    'image/gif', 'image/png', 'image/jpeg', 'image/bmp', 'image/webp'
  ]
  return supportedTypes.includes(type)
}
