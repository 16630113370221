/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import CopyIcon from './../../resources/icons/fa/copy.svg';
import PasteIcon from './../../resources/icons/fa/paste.svg';
import DeleteIcon from './../../resources/icons/fa/delete.svg';
import SearchIcon from './../../resources/icons/fa/search.svg';
import ReloadIcon from './../../resources/icons/fa/reload.svg';
import RecoverIcon from './../../resources/icons/fa/eraser-solid.svg';
import AddIcon from './../../resources/icons/fa/plus.svg';
import AddConcept from './../../resources/icons/fa/concept.svg';
import { deleteEsquema, addEsquema, addEsquemaCampo, deleteCampo } from '../../api/schema.js';
import { esconder } from './EditSchemaWindow';
import '../../styles/components/SchemaView.scss';
import ModalMessage from '../../components/ModalMessage';

export const SchemaButtons = (props) => {
  const intl = useIntl();
  const { setShowWindow, node, data, nodeDisabled, disabledButtonCopy, disabledButtonDelete, disabledButtonPaste, selectedNode } = props;
  let [modalData, setModalData] = useState({});
  let [nodeACopiar, copiarNode] = useState({});

  useEffect(() => {
    document.getElementById('btnPaste').disabled = true;
    if (nodeACopiar) {
      if (nodeACopiar.level === 2 && node.level === 1) {
        document.getElementById('btnPaste').disabled = false;
      } else if (nodeACopiar.level === 3 && node.level === 2) {
        document.getElementById('btnPaste').disabled = false;
      }
    }
  });

  const deleteNode = () => {
    setModalData({
      esEliminar: true,
      showDialog: true,
      textDialog:
        intl.formatMessage({
          id: 'validateDeletingRegistry',
          defaultMessage: 'Seguro que desea eliminar este registro? ',
        }) +
        node.label +
        ' "',
    });
  };

  const recoverNode = () => {
    setModalData({
      esRecuperar: true,
      showDialog: true,
      textDialog: intl.formatMessage({
        id: 'validateRecoveringNode',
        defaultMessage: 'Seguro que desea recuperar el nodo seleccionado?',
      }),
    });
  };

  const addNode = () => {
    if (node.level === 1) {
      setShowWindow('NuevaLinea');
    } else if (node.level === 2) {
      setShowWindow('NuevoCampo');
    }
  };

  const addConcept = () => {
    setShowWindow('NuevoConcepto');
  };

  const copyNode = () => {
    document.getElementById('btnPaste').disabled = true;
    copiarNode(node);
  };

  const pasteNode = () => {
    if (nodeACopiar.tipo === 'linea') {
      setModalData({
        esPegar: true,
        showDialog: true,
        textDialog:
          intl.formatMessage({
            id: 'validateCopyingRegistry',
            defaultMessage: 'Seguro que desea copiar este registro? ',
          }) +
          nodeACopiar.descripcion +
          '"',
      });
    } else if (nodeACopiar.tipo === 'campo') {
      setModalData({
        esPegar: true,
        showDialog: true,
        textDialog:
          intl.formatMessage({
            id: 'validateCopyingRegistry',
            defaultMessage: 'Seguro que desea copiar este registro? ',
          }) +
          nodeACopiar.label +
          '"',
      });
    }
  };

  const recorroHijoDeNodoSeleccionado = (key) => {
    let nodoEnElCualQueremosCopiar = {};
    let data = props.data[0].nodes;
    let keyNodo = key.split('/', 2);
    for (let x = 0; x < data.length; x++) {
      if (data[x].key === keyNodo[1]) {
        nodoEnElCualQueremosCopiar = data[x];
      }
    }
    return nodoEnElCualQueremosCopiar;
  };

  const reloadNodes = () => {
    let nodesAEliminar = props.nodesAEliminar;
    if (nodesAEliminar.length !== 0) {
      for (let i = 0; i < nodesAEliminar.length; i++) {
        if (nodesAEliminar[i].tipo === 'campo') {
          let campoAEliminar = {
            proceso: nodesAEliminar[i].proceso,
            rubro: nodesAEliminar[i].rubro,
            moneda: nodesAEliminar[i].moneda,
            linea: nodesAEliminar[i].linea,
            ordinal: nodesAEliminar[i].ordinal,
            cp: nodesAEliminar[i].cp,
            numero: nodesAEliminar[i].numero,
            formula: nodesAEliminar[i].formula,
          };

          deleteCampo(campoAEliminar).then(function () {});
          props.recuperar(false);
        } else if (nodesAEliminar[i].tipo === 'linea' || nodesAEliminar[i].tipo === 'schema') {
          let nodoAEliminar = {
            proceso: nodesAEliminar[i].proceso,
            rubro: nodesAEliminar[i].rubro,
            moneda: nodesAEliminar[i].moneda,
            linea: nodesAEliminar[i].linea,
            ordinal: nodesAEliminar[i].ordinal,
            descripcion: nodesAEliminar[i].descripcion,
            campo: nodesAEliminar[i].campo,
            rubrosalida: nodesAEliminar[i].rubroSalida,
            cd: nodesAEliminar[i].cajaODiario,
            d_dc: nodesAEliminar[i].debitoGeneraCreditoODebito,
            c_dc: nodesAEliminar[i].creditoGeneraCreditoODebito,
            transaccion: nodesAEliminar[i].transaccion,
            condicion: nodesAEliminar[i].condicion,
            usar_campos_movimientos: nodesAEliminar[i].usarCamposMovimientos,
          };

          deleteEsquema(nodoAEliminar).then(function () {});
          props.recuperar(false);
        }
      }
      nodesAEliminar = [];
    }
    // document.getElementById('btnRecover').disabled = true;
    props.loadData();
    props.esconder();
  };

  const postEsquemaContable = (node, linea, rubroSalida, rubro) => {
    let nuevoNodo = {
      proceso: node.proceso,
      rubro: rubro,
      moneda: node.moneda,
      linea: linea + 1,
      ordinal: node.ordinal,
      descripcion: node.descripcion,
      campo: node.campo,
      rubrosalida: rubroSalida,
      cd: node.cajaODiario,
      d_dc: node.debitoGeneraCreditoODebito,
      c_dc: node.creditoGeneraCreditoODebito,
      transaccion: node.transaccion,
      condicion: node.condicion,
      usar_campos_movimientos: node.usarCamposMovimientos,
    };

    addEsquema(nuevoNodo).then(function (response) {
      if (response === true) {
        setModalData({
          showDialog: true,
          textDialog: intl.formatMessage({
            id: 'copiedNodeSuccessful',
            defaultMessage: 'Se ha copiado el nodo satisfactoriamente',
          }),
        });
        props.loadData();
      } else {
        setModalData({
          showDialog: true,
          textDialog: intl.formatMessage({
            id: 'errorCopyingNode',
            defaultMessage: 'Ha ocurrido un error al copiar el nodo',
          }),
        });
      }
    });
  };

  const addField = (node, linea, rubro) => {
    let nuevoNodo = {
      proceso: node.proceso,
      rubro: rubro,
      moneda: node.moneda,
      linea: linea,
      ordinal: calculoOrdinal(node.valorRubroSalida),
      cp: 'C',
      numero: node.numero,
      formula: node.formula,
    };

    addEsquemaCampo(nuevoNodo).then((response) => {
      if (response !== true) {
        setModalData({
          showDialog: true,
          textDialog: intl.formatMessage({
            id: 'errorCopyingNode',
            defaultMessage: 'Ha ocurrido un error al copiar el nodo',
          }),
        });
      } else {
        setModalData({
          showDialog: true,
          textDialog: intl.formatMessage({
            id: 'copiedNodeSuccessful',
            defaultMessage: 'Se ha copiado el nodo satisfactoriamente',
          }),
        });
        props.loadData();
      }
    });
  };

  const calculoOrdinal = (checkbox) => {
    let nuevoOrdinal = 0;
    let data = props.data[0].nodes;
    let key = node.key;
    let keyNodo = key.split('/', 3);
    for (let x = 0; x < data.length; x++) {
      if (data[x].key === keyNodo[1]) {
        let nodos = data[x].nodes;
        for (let i = 0; i < nodos.length; i++) {
          if (nodos[i].key === keyNodo[2]) {
            let lineas = nodos[i].nodes;
            for (let l = 0; l < lineas.length; l++) {
              if (checkbox) {
                if (lineas[l].ordinal > 99) {
                  nuevoOrdinal = lineas[l].ordinal;
                }
              } else {
                if (lineas[l].ordinal < 99) {
                  nuevoOrdinal = lineas[l].ordinal;
                }
              }
            }
          }
        }
      }
    }
    if (nuevoOrdinal === 0 && checkbox) {
      nuevoOrdinal = 101;
      return nuevoOrdinal;
    } else if (nuevoOrdinal === 0 && !checkbox) {
      nuevoOrdinal = 1;
      return nuevoOrdinal;
    }
    return nuevoOrdinal + 1;
  };

  return (
    <div>
      <div className="container" style={{ height: '30px', backgroundColor: 'rgba(157, 185, 211, .432)' }}>
        <div className={'mb-3 clear-both pl-3 pr-3 pt-1 pb-1 '} style={{ margin: '5px' }}>
          <div className="row">
            <div className="col-5"></div>
            <div className="col-7">
              <div className="row" style={{ display: 'flex', float: 'right' }}>
                <div>
                  <button
                    className="iconConcept"
                    disabled={nodeDisabled || node.level === 3 || node.level === 1 || node.level === 0}
                    onClick={addConcept}
                    style={{ width: '20px', outline: 'none', marginRight: '10px' }}
                    id="btnConcepto"
                    title={intl.formatMessage({
                      id: 'addConcept',
                      defaultMessage: 'Agregar concepto',
                    })}
                  >
                    <AddConcept />
                  </button>
                </div>
                <div>
                  <button
                    className="iconHelp"
                    disabled={nodeDisabled || node.level === 3}
                    onClick={addNode}
                    style={{ width: '20px', outline: 'none' }}
                    title={intl.formatMessage({
                      id: 'addNode',
                      defaultMessage: 'Agregar nodo',
                    })}
                  >
                    <AddIcon />
                  </button>
                </div>
                <div>
                  <button
                    className="iconReload"
                    onClick={reloadNodes}
                    style={{ width: '20px', outline: 'none', marginRight: '10px', marginLeft: '10px' }}
                    title={intl.formatMessage({
                      id: 'updateNodes',
                      defaultMessage: 'Actualizar nodos',
                    })}
                  >
                    <ReloadIcon />
                  </button>
                </div>
                <div>
                  <button
                    title={intl.formatMessage({
                      id: 'copy',
                      defaultMessage: 'Copiar',
                    })}
                    className="iconHelp"
                    onClick={copyNode}
                    style={{ width: '20px', outline: 'none' }}
                    disabled={disabledButtonCopy}
                  >
                    <CopyIcon />
                  </button>
                </div>
                <div>
                  <button
                    className="iconHelp"
                    onClick={pasteNode}
                    title={intl.formatMessage({
                      id: 'paste',
                      defaultMessage: 'Pegar',
                    })}
                    id="btnPaste"
                    style={{ width: '20px', outline: 'none', marginRight: '10px', marginLeft: '10px' }}
                  >
                    <PasteIcon />
                  </button>
                </div>
                <div>
                  <button
                    title={intl.formatMessage({
                      id: 'delete',
                      defaultMessage: 'Eliminar',
                    })}
                    className="iconDelete"
                    style={{ width: '20px', outline: 'none' }}
                    onClick={deleteNode}
                    disabled={disabledButtonDelete}
                  >
                    <DeleteIcon />
                  </button>
                </div>
                <div className="col-2">
                  <button
                    className="iconDelete"
                    title={intl.formatMessage({
                      id: 'recover',
                      defaultMessage: 'Recuperar',
                    })}
                    id="btnRecover"
                    style={{ width: '20px', outline: 'none', marginLeft: '10px' }}
                    onClick={recoverNode}
                    disabled={!props.banderitaEliminar}
                  >
                    <RecoverIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {modalData.showDialog && (
          <div className="modalMessage">
            <ModalMessage
              message={modalData.textDialog}
              handleAccept={async () => {
                if (modalData.esPegar) {
                  let hijosNodoSeleccionado = recorroHijoDeNodoSeleccionado(node.key);
                  hijosNodoSeleccionado = hijosNodoSeleccionado.nodes;
                  let ultimoNodo = hijosNodoSeleccionado[hijosNodoSeleccionado.length - 1];
                  let lineaUltimoNodo = ultimoNodo.linea;
                  let rubro = ultimoNodo.rubro;
                  nodeACopiar.moneda = node.moneda;
                  if (nodeACopiar.level === 2) {
                    postEsquemaContable(nodeACopiar, lineaUltimoNodo, nodeACopiar.rubroSalida, rubro);
                  }
                  if (nodeACopiar.level === 3) {
                    addField(nodeACopiar, node.linea, rubro);
                  }
                  setModalData({ showDialog: false });
                } else if (modalData.esEliminar) {
                  props.eliminar(true);
                  setModalData({ showDialog: false });
                } else if (modalData.esRecuperar) {
                  props.recuperar(true, node);
                  setModalData({ showDialog: false });
                }
                setModalData({ showDialog: false });
              }}
              handleCancel={async () => {
                setModalData({ showDialog: false });
              }}
              acceptTitle={intl.formatMessage({
                id: 'accept',
                defaultMessage: 'Aceptar',
              })}
              draggable={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SchemaButtons;
