import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { getUrl, handleError, handleEndpointError } from './handlers';

export let getSucursalesSc = () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);

    return axios
      .get(`${API}TopazWebClientWS/resources/production/getSucursalesSc?sessionId=${session}`)
      .then(({ data }) => data)
      .catch((err) => {
        handleEndpointError(err);
      });
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getParametros = () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);

    return axios
      .get(`${API}TopazWebClientWS/resources/production/getParametros?sessionId=${session}`)
      .then(({ data }) => data)
      .catch((err) => {
        handleEndpointError(err);
      });
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getNumeratorDefinitions = () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);

    return axios
      .get(`${API}TopazWebClientWS/resources/production/getNumeratorDefinitions?sessionId=${session}`)
      .then(({ data }) => data)
      .catch((err) => {
        handleEndpointError(err);
      });
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getTopazPrts = () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);

    return axios
      .get(`${API}TopazWebClientWS/resources/production/getTopazPrts?sessionId=${session}`)
      .then(({ data }) => data)
      .catch((err) => {
        handleEndpointError(err);
      });
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getNetMaps = () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);

    return axios
      .get(`${API}TopazWebClientWS/resources/production/getNetMaps?sessionId=${session}`)
      .then(({ data }) => data)
      .catch((err) => {
        handleEndpointError(err);
      });
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getTopazMaps = () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);

    return axios
      .get(`${API}TopazWebClientWS/resources/production/getTopazMaps?sessionId=${session}`)
      .then(({ data }) => data)
      .catch((err) => {
        handleEndpointError(err);
      });
  } catch (error) {
    handleEndpointError(error);
  }
};

export let updateParametros = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/updateParametros?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let updateTopazMap = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/updateTopazMap?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let addTopazMap = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/addTopazMap?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let removeTopazMap = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/removeTopazMap?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let updateNetMap = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/updateNetMap?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let addNetMap = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/addNetMap?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let removeNetMap = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/removeNetMap?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let removeTopazPrt = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/removeTopazPrt?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let updateTopazPrt = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/updateTopazPrt?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let addTopazPrt = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/addTopazPrt?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let removeNumeratorDefinition = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/removeNumeratorDefinition?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let updateNumeratorDefinition = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/updateNumeratorDefinition?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let addNumeratorDefinition = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/addNumeratorDefinition?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let validaUsuariosMaquinaSucursal = (nroMaquina, nroSucursal) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .get(
      `${API}TopazWebClientWS/resources/production/validaUsuariosMaquinaSucursal?sessionId=${session}&nroMaquina=${nroMaquina}&nroSucursal=${nroSucursal}`,
    )
    .then(({ data }) => data)
    .catch((err) => {
      handleEndpointError(err);
    });
};

export let getTopazMapsBK = () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);

    return axios
      .get(`${API}TopazWebClientWS/resources/production/getTopazMapsBK?sessionId=${session}`)
      .then(({ data }) => data)
      .catch((err) => {
        handleEndpointError(err);
      });
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getTopazPrtsBK = () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);

    return axios
      .get(`${API}TopazWebClientWS/resources/production/getTopazPrtsBK?sessionId=${session}`)
      .then(({ data }) => data)
      .catch((err) => {
        handleEndpointError(err);
      });
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getNetMapsBK = () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);

    return axios
      .get(`${API}TopazWebClientWS/resources/production/getNetMapsBK?sessionId=${session}`)
      .then(({ data }) => data)
      .catch((err) => {
        handleEndpointError(err);
      });
  } catch (error) {
    handleEndpointError(error);
  }
};

export let removeTopazMapBk = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/removeTopazMapBk?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let removeTopazPrtBk = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/removeTopazPrtBk?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export let removeNetMapBk = async (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  try {
    const response = await axios.put(`${API}TopazWebClientWS/resources/production/removeNetMapBk?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return false;
  }
};

export const getHelpMetadataByConcept = async (conceptNumber) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'production',
      action: 'getHelpMetadataByConcept',
      params: `conceptNumber=${conceptNumber}&sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export const getRecordsByConcept = async (conceptNumber, fieldToFilter, operator, value) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);
  try {
    const query = {
      endpoint: 'schema',
      action: 'getRecordsByConcept',
      params: `conceptNumber=${conceptNumber}&fieldToFilter=${fieldToFilter}&operator=${operator}&value=${value}&sessionId=${sessionID}`,
    };
    const response = await axios.get(getUrl(query));
    return response.data;
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getMetadataByConceptNew = (conceptNumber) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .get(`${API}TopazWebClientWS/resources/production/getHelpMetadataByConcept?conceptNumber=${conceptNumber}&sessionId=${session}`)
    .then(({ data }) => data)
    .catch((err) => {
      handleEndpointError(err);
    });
};
