import React, { PureComponent } from 'react';
import { store } from '../../store';
import SearchSection from './SearchSection';
import Results from './Results';
import ConsultaBitacora from './filters/ConsultaBitacora';
import ConsultaEntradasSalidas from './filters/ConsultaEntradasSalidas';
import ConsultaGrupos from './filters/ConsultaGrupos';
import { getNetMapDescriptionsPorSucursal, getSucursalDescriptions, getUsuariosBasicInfo, getBitacoraCodigos } from '../../api/bitacora';
import ConsultaUsuarios from './filters/ConsultaUsuarios';
import ConsultaRestricciones from './filters/ConsultaRestricciones';
import ConsultaOperaciones from './filters/ConsultaOperaciones';
import ConsultaServiciosWeb from './filters/ConsultaServiciosWeb';
import { FormattedMessage } from 'react-intl';
import ModalMessage from '../ModalMessage';
import { PulseLoader } from 'halogenium';

const CONSULTA_BITACORA = 'Consulta bitacora';
const ENTRADAS_SALIDAS = 'Entradas/Salidas';
const MOD_USUARIOS = 'Modificaciones de usuarios';
const MOD_GRUPOS = 'Modificaciones de grupos';
const MOD_RESTRICCIONES = 'Modificaciones de restricciones';
const CON_OPERACIONES = 'Consulta operaciones';
const CON_SERVICIOSWEB = 'Consulta servicios web';

class BinnacleView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      text: undefined,
      index: -1,
      modal: { showing: false, content: null },
    };

    this.findText = this.findText.bind(this);

    this.filterList = {};
    this.filterList[CONSULTA_BITACORA] = <ConsultaBitacora filterListID={CONSULTA_BITACORA} showModal={this.showModal} thisObjForShowModal={this} />;
    this.filterList[ENTRADAS_SALIDAS] = (
      <ConsultaEntradasSalidas filterListID={ENTRADAS_SALIDAS} showModal={this.showModal} thisObjForShowModal={this} />
    );
    this.filterList[MOD_USUARIOS] = <ConsultaUsuarios filterListID={MOD_USUARIOS} showModal={this.showModal} thisObjForShowModal={this} />;
    this.filterList[MOD_GRUPOS] = <ConsultaGrupos filterListID={MOD_GRUPOS} showModal={this.showModal} thisObjForShowModal={this} />;
    this.filterList[MOD_RESTRICCIONES] = (
      <ConsultaRestricciones filterListID={MOD_RESTRICCIONES} showModal={this.showModal} thisObjForShowModal={this} />
    );
    this.filterList[CON_OPERACIONES] = <ConsultaOperaciones filterListID={CON_OPERACIONES} showModal={this.showModal} thisObjForShowModal={this} />;
    this.filterList[CON_SERVICIOSWEB] = (
      <ConsultaServiciosWeb filterListID={CON_SERVICIOSWEB} showModal={this.showModal} thisObjForShowModal={this} />
    );

    store.setState({
      bitacoraApp: {
        currentTabIndex: CONSULTA_BITACORA,
      },
    });
  }

  showModal(content) {
    this.thisObjForShowModal.setState({ modal: { showing: true, content: content } });
  }

  hideModal() {
    this.setState({ modal: { showing: false, content: null } });
  }

  async componentDidMount() {
    await this.storeHelps();
  }

  async storeHelps() {
    let dataIniciales = await getUsuariosBasicInfo();
    let dataMaquina = await getNetMapDescriptionsPorSucursal();
    let dataSucursal = await getSucursalDescriptions();
    let dataCodigo = await getBitacoraCodigos();
    store.setState({
      bitacoraApp: {
        ...store.getState().bitacoraApp,
        currentTabIndex: CONSULTA_BITACORA,
        helpIniciales: dataIniciales,
        helpMaquina: dataMaquina,
        helpSucursal: dataSucursal,
        helpCodigo: dataCodigo,
      },
    });
    this.setState({
      loading: false,
    });
  }

  selectTab(e) {
    if (e.target) {
      store.setState({ bitacoraApp: { ...store.getState().bitacoraApp, currentTabIndex: e.target.id } });
    }
  }

  findText(text, index) {
    this.setState({
      text: text,
      index: index,
    });
  }

  render() {
    const { loading } = this.state;
    let tabs = [];
    if (!loading) {
      for (let key of Object.keys(this.filterList)) {
        tabs.push(
          <div
            style={
              key === store.getState().bitacoraApp.currentTabIndex
                ? {
                    cursor: 'pointer',
                    padding: '10px 20px',
                    margin: '0px 2px',
                    background: '#BFC1C6',
                    display: 'inline-block',
                    color: '#fff',
                    borderRadius: '15px 15px 0px 0px',
                  }
                : {
                    cursor: 'pointer',
                    padding: '10px 20px',
                    margin: '0px 2px',
                    background: '#D6D8DD',
                    display: 'inline-block',
                    color: '#fff',
                    borderRadius: '15px 15px 0px 0px',
                  }
            }
            id={key}
            // className={(key === store.getState().bitacoraApp.currentTabIndex ? 'rc-tabs-tab-active' : '') + ' rc-tabs-tab'}
            onClick={this.selectTab}
          >
            {<FormattedMessage id={key} defaultMessage={key} />}
          </div>,
        );
      }
    }

    const shownFilter = this.filterList[store.getState().bitacoraApp.currentTabIndex];

    return (
      <div>
        {loading ? (
          <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
        ) : (
          <React.Fragment>
            <div>
              <div style={{ display: 'flex', width: '100%' }}>{tabs}</div>
              <div style={{ display: 'flex', width: '100%' }}>
                {shownFilter}
                {store.getState().bitacoraApp.currentTabIndex === CONSULTA_BITACORA ||
                store.getState().bitacoraApp.currentTabIndex === ENTRADAS_SALIDAS ? (
                  <SearchSection findText={this.findText} />
                ) : (
                  <div></div>
                )}
              </div>
              <div style={{ overflowX: 'scroll', display: 'flex', width: '100%' }}>
                <Results transactionLineLabel={'Bitacora'} text={this.state.text} index={this.state.index} />
              </div>
              {this.state.modal.showing && (
                <ModalMessage
                  message={this.state.modal.content}
                  handleAccept={async () => {
                    this.hideModal();
                  }}
                  acceptTitle={'Aceptar'}
                  draggable={true}
                />
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default BinnacleView;
export { CONSULTA_BITACORA, ENTRADAS_SALIDAS, MOD_USUARIOS, MOD_GRUPOS, MOD_RESTRICCIONES, CON_OPERACIONES, CON_SERVICIOSWEB };
