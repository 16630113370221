import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import FocusLock from 'react-focus-lock';
import Draggable from 'react-draggable';
import { useTheme } from '../../../../src/themes/ThemeContext';
import { useIntl } from 'react-intl';

export const BasePopupWindow = (props) => {
  const intl = useIntl();

  // **********************     EVENTS      *****************************
  const handleKeyDown = (e) => {};

  const handleButtonClick = (e) => {
    if (e === 'okModalButton') {
      props.setForceSelect(true);
    } else if (e === 'cancelModalButton') {
      props.toggleWindow('');
    }
  };

  // **********************     STYLES      *****************************
  const themeContext = useTheme();
  const { color, commonButton, modal } = themeContext.theme;
  const styles = StyleSheet.create({
    show: {
      display: 'block',
      zIndex: 900,
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    modalMain: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxHeight: '80%',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      // minWidth: '320px',
      // maxWidth: '98vw',
      // maxHeight: '98vh',
      backgroundColor: modal.active.background,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: 'transparent',
      opacity: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.33) 0px 2px 8px',
      // alignItems: 'center',
      transition: 'border background .2s',
      padding: '.2rem',
    },
    titleContainer: {
      width: '100%',
      minHeight: '1.5rem',
      padding: '.5rem 1rem',
      color: color.grey.dark4,
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    messageContainer: {
      padding: '1.5rem 1rem',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    buttonsContainer: {
      padding: '.5rem .5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    button: {
      padding: '.5rem 1.2rem',
      marginRight: '.5rem',
      borderRadius: 4,
      display: 'block',
      backgroundColor: commonButton.default.background,
      color: commonButton.default.color,
      ':hover': {
        backgroundColor: commonButton.hover.background,
        color: commonButton.hover.color,
      },
      ':focus': {
        backgroundColor: commonButton.focus.background,
        color: commonButton.focus.color,
      },
      ':active': {
        backgroundColor: commonButton.active.background,
        color: commonButton.active.color,
      },
      transition: 'background .2s',
    },
  });

  // **********************     RENDERING      *****************************
  let childHelpWindow = <div>{props.helpWindowOpen}</div>;
  return (
    <FocusLock persistentFocus returnFocus>
      <div className={css(styles.modal, styles.show)} onKeyDown={handleKeyDown}>
        <div className={css(styles.modalMain)}>
          <Draggable handle=".drag-section" bounds={{ top: -200, right: 350, bottom: 200, left: -350 }}>
            <div className={`${css(styles.modalContent)}`}>
              <div className={`drag-section ${css(styles.titleContainer)}`}>
                {intl.formatMessage({
                  id: 'helpFor',
                  defaultMessage: 'Ayuda para',
                })}
                {props.title}
              </div>
              <div className={css(styles.messageContainer)}>{childHelpWindow}</div>
              <div className={css(styles.buttonsContainer)}>
                {(!props.buttons || !props.buttons.length) && (
                  <div style={{ display: 'flex' }}>
                    <button
                      id="okModalButton"
                      style={{
                        width: '100px',
                        fontSize: '12px',
                        marginRight: '15px',
                        outline: 'none',
                        padding: '5px',
                        borderRadius: '4px',
                        border: '1px solid grey',
                      }}
                      className="btn btn-primary btn-sm"
                      handleClick={() => handleButtonClick('okModalButton')}
                      onClick={(e) => {
                        if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) handleButtonClick('okModalButton');
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleButtonClick('okModalButton');
                        }
                      }}
                    >
                      {intl.formatMessage({
                        id: 'accept',
                        defaultMessage: 'Aceptar',
                      })}
                    </button>
                    <button
                      id="cancelModalButton"
                      style={{ width: '100px', fontSize: '12px', padding: '5px', borderRadius: '4px', border: '1px solid grey' }}
                      className="btn btn-outline-primary btn-sm"
                      handleClick={() => handleButtonClick('cancelModalButton')}
                      onClick={(e) => {
                        if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) handleButtonClick('cancelModalButton');
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleButtonClick('cancelModalButton');
                        }
                      }}
                    >
                      {intl.formatMessage({
                        id: 'cancel',
                        defaultMessage: 'Cancelar',
                      })}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Draggable>
        </div>
      </div>
    </FocusLock>
  );
};
