import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { compareTLId } from './../helpers/operationHelper';
import { PulseLoader } from 'halogenium';
import { css, StyleSheet } from 'aphrodite';
import { store } from '../../src/store';
import { getTabInformation, getTabIdByNodeId } from '../../src/selectors';
import { addLineTest, addLineEventTest, addValueTest } from '../../src/components/Testing/HandleLineTest';

const MIN_ZOOM = 0;
const MAX_ZOOM = 4;
const DEFAULT_ZOOM = 1;
const DEFAULT_WIDTH_COLUMN = 80;
const MIN_WIDTH_COLUMN = 5;
const FONT_SIZE = 12;
const MONOSPACE_FONT = 'monospace';
const MONOSPACE_FONT_ASPECT_RATIO = 0.6; // oficialmente es 0.42, pero no queda como el deskop
const FONT_CHAR_WIDTH = FONT_SIZE * MONOSPACE_FONT_ASPECT_RATIO;
const RESIZE_PRESS = 'RESIZE_PRESS';
const RESIZE_MOVE = 'RESIZE_MOVE';
const RESIZE_RELEASED = 'RESIZE_RELEASED';
const HEADER_TITLE = 'HEADER';

export class HelpTable extends Component {
  constructor(props) {
    super(props);

    let preSelectedIndex = 0;
    const state = store.getState();
    const tabId = getTabIdByNodeId(state, this.props.nodeId);
    const { tabIndex } = getTabInformation(state, tabId);
    const fromApp = this.props.fromApp === undefined ? false : this.props.fromApp;
    this.fromApp = fromApp;

    if (fromApp === false && state.tabs[tabIndex].running.selectedIndexes) {
      preSelectedIndex = state.tabs[tabIndex].running.selectedIndexes[this.props.transactionLineLabel];
    }
    this.state = {
      focusedRowInfo: {},
      selectedIndex: preSelectedIndex,
      sortBy: {
        column: null,
        reverse: false,
      },
      zoom: DEFAULT_ZOOM,
      columnsWidth: null,
      minWidthTable: 0,
      multipleSelectionRowsInfo: { index: [], original: [] },
      resizeColumns: true,
    };

    this.flagFetch = false;

    this.rowRefs = {};
    this.columnRefs = [];
    this.columnMove = null;
    this.selectedColumn = 0;
    const { columns } = this.props;
    this.columns = columns;

    this.setRowRef = (element) => {
      if (element) {
        this.rowRefs[element.tabIndex] = element;
      }
    };

    this.handleHeaderClick = this.handleHeaderClick.bind(this);
    this.updateSortBy = this.updateSortBy.bind(this);
    this.getSortFunction = this.getSortFunction.bind(this);

    this.focusOnRow = this.focusOnRow.bind(this);
    this.selectRow = this.selectRow.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setColumnsWidth = this.setColumnsWidth.bind(this);
    this.doFocusElement = this.doFocusElement.bind(this);
  }

  handleHeaderClick(e, colIndex) {
    if (e.button === 0) {
      addLineTest('CLICK', e);
      const { columns } = this.props;
      if (columns[colIndex].isSortable) {
        this.updateSortBy(columns[colIndex].accessor);
      }
    } else if (e.button === 2) {
      addLineTest('R_CLICK', e);
      const { openFilter, columns } = this.props;
      if (openFilter) {
        if (!this.props.fromHelp) {
          this.props.goToLineFn(e);
        }
        openFilter(columns[colIndex].accessor);
      }
    }
    e.stopPropagation();
    e.preventDefault();
  }

  handleHeaderClickContent(e, colIndex) {
    if (e.button === 2) {
      addLineTest('R_CLICK', e);
      const { columns, openExcel } = this.props;
      let clientProperties = store.getState().clientProperties;
      let downloadGridExcel = clientProperties?.downloadGridExcel;
      if (openExcel && downloadGridExcel === 'true') {
        openExcel(columns[colIndex]);
      }
      e.stopPropagation();
      e.preventDefault();
    }
  }

  updateSortBy(filterBy) {
    const {
      sortBy: { column, reverse },
    } = this.state;
    const sortBy = {};

    if (column === filterBy) {
      sortBy.column = column;
      sortBy.reverse = !reverse;
    } else {
      sortBy.column = filterBy;
      sortBy.reverse = false;
    }
    this.setState({ sortBy });
    this.focusOnRow(0, null, 0, false, false, true, sortBy);
  }

  getColumnData(columnAccessor) {
    let columnData;
    this.props.columns.forEach((myColumn) => {
      if ((myColumn.isSortable || this.fromApp) && myColumn.accessor === columnAccessor) {
        columnData = myColumn;
      }
    });
    return columnData;
  }

  // fixed format dd-mm-yyyy
  parseDate(pDate) {
    let dateAux = pDate.substring(0, 10);
    let timeAux = pDate.substring(11, 20);
    let arrDate = dateAux.split('/');
    let arrTime = timeAux.split(':');
    let date = new Date();
    date.setFullYear(arrDate[2]);
    date.setMonth(arrDate[1] - 1);
    date.setDate(arrDate[0]);
    date.setHours(arrTime[0]);
    date.setMinutes(arrTime[1]);
    date.setSeconds(arrTime[2]);
    return date;
  }

  datesCompare(pDate1, pDate2) {
    let date1 = this.parseDate(pDate1);
    let date2 = this.parseDate(pDate2);
    if (date1 >= date2) {
      return -1;
    } else {
      return 1;
    }
  }

  getSortFunction(fromApp, sortBy) {
    const noSort = () => {};

    const {
      sortBy: { column, reverse },
    } = (sortBy && { sortBy }) || this.state;

    if (!column) return noSort;
    let columnData = this.getColumnData(column);
    if (!columnData) {
      return;
    }
    let columnType = columnData.type;

    return (a, b) => {
      if (b[column] === '...' || a[column] === '...') {
        return;
      }
      let asc = parseInt(b[column]) - parseInt(a[column]);

      if (isNaN(asc) || isNaN(b[column]) || isNaN(a[column])) {
        if (!b[column]) {
          b[column] = ' ';
        }
        if (!a[column]) {
          a[column] = ' ';
        }
        if (fromApp) {
          // Tratamiento distinto para columna de tipo Date.
          if (columnType === 4) {
            asc = this.datesCompare(a[column], b[column]);
          } else {
            if (b[column] && a[column] && b[column] && a[column]) {
              asc = b[column].toString().localeCompare(a[column]);
            }
          }
        } else {
          if (b[column] && a[column] && b[column].toLowerCase() && a[column].toLowerCase()) {
            asc = b[column].toLowerCase().localeCompare(a[column].toLowerCase());
          }
        }
      }
      return reverse ? -asc : asc;
    };
  }

  focusOnRow(rowIndex, row, columnIndex, arrowKeys = false, selectionKey = false, preventScroll = false, sortByChanged = false) {
    const { focusedTable, goToLineFn } = this.props;
    const rowInfo = { index: rowIndex, original: row };
    goToLineFn();
    const didOnFocus = true;
    const state = store.getState();
    const tabId = getTabIdByNodeId(state, this.props.nodeId);
    const modalShowing = document.getElementById('modalTitleText_' + tabId);
    if (!modalShowing && !state.apiLoading && (this.fromApp || (this.rowRefs[rowIndex] && focusedTable))) {
      if (this.fromApp || !this.props.ignoreFocus) {
        this.rowRefs[rowIndex].focus();
      }
    }
    this.selectRow(selectionKey, rowInfo, arrowKeys, didOnFocus, rowIndex, sortByChanged);
    if (rowIndex !== this.state.selectedIndex || JSON.stringify(row) !== JSON.stringify(this.state.focusedRowInfo)) {
      this.setState({ selectedIndex: rowIndex, focusedRowInfo: row });
    }
  }

  findOriginalIndex(row) {
    const { data } = this.props;
    let index = -1;
    data.forEach((element, idx) => {
      if (JSON.stringify(element) === JSON.stringify(row)) {
        index = idx;
      }
    });
    return index;
  }

  selectRow(sendSelection = true, rowInfo, arrowKeys = false, didOnFocus = false, rowIndex = undefined, sortByChanged = false) {
    const { getTrProps, fromHelp, data, focusedTable, multipleSelection, focusElement, transactionLineLabel } = this.props;
    let { focusedRowInfo, selectedIndex, multipleSelectionRowsInfo } = this.state;
    let indexChanged = (selectedIndex !== rowIndex && rowIndex !== undefined) || multipleSelection;

    if (rowIndex || rowIndex === 0) {
      selectedIndex = rowIndex;
    }

    if (((fromHelp || focusedTable) && (!focusedRowInfo || Object.keys(focusedRowInfo).length === 0)) || sortByChanged) {
      if (sortByChanged) {
        const sortedData = data.sort(this.getSortFunction(this.fromApp, sortByChanged));
        focusedRowInfo = sortedData && sortedData[0];
        if (multipleSelection) {
          let newMultipleSelectionRowsInfo = { index: [], original: [] };
          multipleSelectionRowsInfo.original.forEach((element) => {
            let index = this.findOriginalIndex(element);
            newMultipleSelectionRowsInfo.index.push(index);
            newMultipleSelectionRowsInfo.original.push(element);
          });
          this.setState({ multipleSelectionRowsInfo: newMultipleSelectionRowsInfo });
        }
        indexChanged = true;
      } else {
        focusedRowInfo = data && data[0];
      }
    }

    if ((!rowInfo || !rowInfo.original) && !multipleSelection) {
      rowInfo = { index: selectedIndex, original: focusedRowInfo };
    }
    if (multipleSelection && !arrowKeys && rowInfo && rowInfo.original) {
      if (!multipleSelectionRowsInfo.index.includes(rowInfo.index) && !sendSelection) {
        multipleSelectionRowsInfo.index.push(rowInfo.index);
        multipleSelectionRowsInfo.original.push(rowInfo.original);
      } else if (multipleSelectionRowsInfo.index.length > 0 && !sendSelection) {
        const indexRemove = multipleSelectionRowsInfo.index.indexOf(rowInfo.index);
        multipleSelectionRowsInfo.index.splice(indexRemove, 1);
        multipleSelectionRowsInfo.original.splice(indexRemove, 1);
      }
      this.setState({ multipleSelectionRowsInfo });
      rowInfo = multipleSelectionRowsInfo;
    }
    if (getTrProps && (focusedTable || didOnFocus)) {
      const ret = getTrProps({}, rowInfo, sendSelection);
      if (
        ((indexChanged || sendSelection || this.state.sortBy?.column) && transactionLineLabel === focusElement) ||
        transactionLineLabel !== focusElement
      ) {
        this.saveSelectionOnStore(selectedIndex, indexChanged);
        return ret.onClick(focusedRowInfo, selectedIndex);
      }
    }
  }

  saveSelectionOnStore(selectedIndex, indexChanged) {
    const state = store.getState();
    const tabId = getTabIdByNodeId(state, this.props.nodeId);
    const { tabIndex } = getTabInformation(state, tabId);

    if (state.tabs[tabIndex] && state.tabs[tabIndex].running && !state.tabs[tabIndex].running.selectedIndexes) {
      state.tabs[tabIndex].running.selectedIndexes = {};
    }

    if (
      state.tabs[tabIndex] &&
      state.tabs[tabIndex].running &&
      (indexChanged || state.tabs[tabIndex].running.selectedIndexes[this.props.transactionLineLabel] !== selectedIndex)
    ) {
      state.tabs[tabIndex].running.selectedIndexes[this.props.transactionLineLabel] = selectedIndex;
      store.setState(state);
    }
  }

  parseForHelpSelect(rowInfo) {
    const { columns } = this.props;
    let valuesToReturn = [];
    columns.forEach((column) => {
      if (column.isReturned && rowInfo) {
        valuesToReturn.push(rowInfo[column.accessor]);
      }
    });
    return valuesToReturn;
  }

  isValidKey(e) {
    if ((e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 191) {
      return true;
    }
    return false;
  }

  handleKeyDown(e, rowIndex, row, columnIndex) {
    let newRowIndex = rowIndex;
    let newColumnIndex = columnIndex;
    let arrowKeys = false;
    let selectionKey = false;
    let tabbing = false;
    let preventScroll = true;
    const { fromHelp, onHelpSelect, cancelHandlerHelp, data, handleKeyDown } = this.props;
    let length = data.length;
    if (e.key === 'ArrowDown' || (e.nativeEvent && e.nativeEvent.view && e.nativeEvent.view.event && e.nativeEvent.view.event.key === 'ArrowDown')) {
      newRowIndex = Math.min(rowIndex + 1, length - 1);
      arrowKeys = true;
      // selectionKey = true;
      this.selectRow(false);
    } else if (
      e.key === 'ArrowUp' ||
      (e.nativeEvent && e.nativeEvent.view && e.nativeEvent.view.event && e.nativeEvent.view.event.key === 'ArrowUp')
    ) {
      newRowIndex = Math.max(rowIndex - 1, 0);
      arrowKeys = true;
      // selectionKey = true;
      this.selectRow(false);
    } else if (
      e.key === 'ArrowRight' ||
      (e.nativeEvent && e.nativeEvent.view && e.nativeEvent.view.event && e.nativeEvent.view.event.key === 'ArrowRight')
    ) {
      newColumnIndex = Math.min(columnIndex + 1, Object.keys(this.columnRefs).length - 1);
      preventScroll = false;
      this.selectedColumn = newColumnIndex;
      arrowKeys = true;
    } else if (
      e.key === 'ArrowLeft' ||
      (e.nativeEvent && e.nativeEvent.view && e.nativeEvent.view.event && e.nativeEvent.view.event.key === 'ArrowLeft')
    ) {
      newColumnIndex = Math.max(columnIndex - 1, 0);
      this.selectedColumn = newColumnIndex;
      arrowKeys = true;
      preventScroll = false;
    } else if (e.key === 'Enter' || (e.nativeEvent && e.nativeEvent.view && e.nativeEvent.view.event && e.nativeEvent.view.event.key === 'Enter')) {
      selectionKey = true;
    } else if (e.key === 'Escape' || (e.key === 'Tab' && e.shiftKey)) {
      const { handleUndo } = this.props;
      if (fromHelp) cancelHandlerHelp();
      else handleUndo(e);
    } else if (e.key === 'Tab') {
      if (fromHelp) {
        tabbing = true;
      } else {
        selectionKey = true;
      }
    } else if (e.key === '-') {
      this.setState({
        zoom: Math.max(MIN_ZOOM, this.state.zoom - 1),
      });
    } else if (e.key === '+') {
      this.setState({
        zoom: Math.min(MAX_ZOOM, this.state.zoom + 1),
      });
    } else if (e.key === '0') {
      this.setState({
        zoom: DEFAULT_ZOOM,
      });
    } else if (this.isValidKey(e) && !e.ctrlKey) {
      const { openFilter, columns } = this.props;
      if (openFilter) {
        openFilter(columns[0].accessor, true, e.key);
      }
    } else if (e.keyCode === 32) selectionKey = true;
    else selectionKey = false;

    if (!tabbing && !e.shiftKey) {
      this.focusOnRow(newRowIndex, data[newRowIndex], newColumnIndex, arrowKeys, selectionKey, preventScroll);
    } else {
      const addFilterButton = document.getElementById('gridAddFilterButton_' + this.props.transactionLineLabel);
      if (addFilterButton) {
        addFilterButton.focus();
        e.preventDefault();
      }
    }

    if (handleKeyDown && !e.shiftKey) {
      let { selectedIndex } = this.state;
      handleKeyDown(e, selectedIndex);
    }

    if (fromHelp) {
      if (e.key === 'Enter' || (e.nativeEvent && e.nativeEvent.view && e.nativeEvent.view.event && e.nativeEvent.view.event.key === 'Enter')) {
        const event = this.parseForHelpSelect(data[newRowIndex]);
        onHelpSelect(event);
      }
    }

    !tabbing && !e.ctrlKey && e.preventDefault && e.preventDefault();
    !tabbing && !e.ctrlKey && e.stopPropagation && e.stopPropagation();
  }

  handleClick(e, rowIndex, row, columnIndex) {
    addLineTest('CLICK', e);
    if (!rowIndex && !row && !columnIndex) {
      const { goToLineFn } = this.props;
      goToLineFn(e);
    } else {
      if (this.props.multipleSelection && e.shiftKey) {
        for (let idx = Math.min(this.state.selectedIndex, rowIndex); idx <= Math.max(this.state.selectedIndex, rowIndex); idx++) {
          if (this.props.data[idx] && idx !== this.state.selectedIndex) {
            this.focusOnRow(idx, this.props.data[idx], columnIndex, false, false, true);
          }
        }
      } else {
        this.focusOnRow(rowIndex, row, columnIndex, false, false, true);
      }
    }
  }

  handleBlur = (e, tlid, rowIndex, row) => {
    const rowInfo = {
      index: rowIndex || 0,
      original: row,
    };

    const tlid1 = tlid;
    const tlid2 = e && e.relatedTarget && e.relatedTarget.id ? e.relatedTarget.id : '';
    /**
     * Focus and select the row only if the transactionLineLabel
     * of the new focused element is bigger than that of the row.
     */
    if (compareTLId(tlid1, tlid2) > 0) {
      this.props.continue(rowInfo);
    }
  };

  doFocusElement(prevProps, fromOnBlur, e) {
    const state = store.getState();
    const tabId = getTabIdByNodeId(state, this.props.nodeId);
    const { tabIndex } = getTabInformation(state, tabId);
    const notShowingModal = !state.globalMessage || (state.globalMessage && !state.globalMessage.visible);
    if (tabIndex === state.current && (this.props.showingHelp || !this.props.fromHelp) && notShowingModal) {
      let fullTLPrefix = document.activeElement.id;
      const indexOfUnderscore = document.activeElement.id.indexOf('_');
      let TLPostfix = '';
      if (indexOfUnderscore > 0) {
        TLPostfix = fullTLPrefix.substr(indexOfUnderscore);
        fullTLPrefix = fullTLPrefix.substr(0, indexOfUnderscore);
      }

      const toggledShowingHelp = prevProps && prevProps.showingHelp !== this.props.showingHelp && this.props.showingHelp;
      const currentIndex = toggledShowingHelp && prevProps === undefined ? 0 : this.rowRefs[this.state.selectedIndex] ? this.state.selectedIndex : 0;
      if (
        toggledShowingHelp ||
        (this.props.transactionLineLabel === this.props.focusElement &&
          (fullTLPrefix !== this.props.transactionLineLabel || TLPostfix.indexOf('_HelpTable') === -1 || fromOnBlur))
      ) {
        if (fromOnBlur) {
          let helpTableElement = document.getElementById(this.props.transactionLineLabel + '_HelpTable_' + currentIndex);
          if (this.props.loadingData) {
            helpTableElement = document.getElementById(this.props.transactionLineLabel + '_Loading');
          }

          if (
            helpTableElement &&
            (e.relatedTarget === null || (e.relatedTarget.classList.contains('contorno') && e.relatedTarget.id !== this.props.transactionLineLabel))
          ) {
            helpTableElement.focus();
            setTimeout(() => {
              helpTableElement.focus();
            }, 100);
          }
        } else {
          const { focusedTable, fromHelp, data, multipleSelection } = this.props;
          if ((!data || data.length === 0 || Object.keys(this.rowRefs).length === 0) && focusedTable && this.noDataRef && !this.props.ignoreFocus) {
            this.noDataRef.focus();
          } else {
            if (this.rowRefs[currentIndex] && focusedTable && !multipleSelection) {
              const sortedData = this.state.sortBy ? data.sort(this.getSortFunction(this.fromApp, this.state.sortBy)) : data;
              this.focusOnRow(currentIndex, sortedData[currentIndex], 0);
            }
            if ((fromHelp || focusedTable) && !multipleSelection && !toggledShowingHelp && prevProps !== undefined) {
              this.selectRow(false);
            }
          }
          let helpTableElement = document.getElementById(this.props.transactionLineLabel + '_HelpTable_' + currentIndex);
          if (this.props.loadingData) {
            helpTableElement = document.getElementById(this.props.transactionLineLabel + '_Loading');
          }
          const modalShowing = document.getElementById('modalTitleText_' + tabId);
          if (helpTableElement && !state.apiLoading && !modalShowing) {
            helpTableElement.focus();
            setTimeout(() => {
              helpTableElement.focus();
            }, 100);
          }
        }
      }
    }
  }

  componentDidMount() {
    this.doFocusElement();
    this.setColumnsWidth();
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    let same = true;
    if (data && data[0] !== undefined && data[0] !== null && prevProps.data && prevProps.data[0] !== undefined) {
      Object.keys(data[0]).forEach(function (key, index) {
        if (data[0][key] !== prevProps.data[0][key]) {
          same = false;
        }
      });
    }

    this.doFocusElement(prevProps);

    if (!same) {
      this.focusOnRow(0, data[0], 0);
      const rowInfo = { index: 0, original: data[0] };

      this.selectRow(false, rowInfo);
    }
  }

  setColumnsWidth(indexColumn, event, value) {
    let { columns } = this.props;
    let { columnsWidth, minWidthTable } = this.state;
    if (indexColumn !== undefined) {
      if (event === RESIZE_PRESS) {
        columnsWidth[indexColumn].pageX = value;
        this.columnMove = indexColumn;
      } else if (event === RESIZE_MOVE) {
        if (columnsWidth[indexColumn].pageX !== null) {
          let diff = value - columnsWidth[indexColumn].pageX;
          const newValue = columnsWidth[indexColumn].value + diff;
          if (newValue >= MIN_WIDTH_COLUMN) {
            columnsWidth[indexColumn].value = newValue;
            columnsWidth[indexColumn].pageX = value;
            minWidthTable = minWidthTable + diff;
          }
        }
      } else if (event === RESIZE_RELEASED) {
        columnsWidth[indexColumn].pageX = null;
        this.columnMove = null;
      }
    } else {
      columnsWidth =
        columns &&
        columns.map((col) => {
          let lengthToUse = col.length;
          if (col.Header && col.Header.length >= lengthToUse) {
            lengthToUse = col.Header.length;
          }
          lengthToUse = lengthToUse + (col.type === 3 ? lengthToUse / 3 : 0);
          return {
            value: lengthToUse
              ? lengthToUse * FONT_CHAR_WIDTH < DEFAULT_WIDTH_COLUMN
                ? DEFAULT_WIDTH_COLUMN
                : lengthToUse * FONT_CHAR_WIDTH
              : DEFAULT_WIDTH_COLUMN,
          };
        });
      let totalWidth = 0;
      columnsWidth.forEach((col) => {
        if (!isNaN(col.value)) {
          totalWidth += col.value;
        }
      });
      minWidthTable = totalWidth;
    }
    this.setState({ columnsWidth, minWidthTable });
  }

  getTextAlignByColumnType = (columnType) => {
    const TEXT_TYPE = 1;
    const NUMBER_TYPE = 2;
    const CURRENCY_TYPE = 3;
    const DATE_TYPE = 4;
    switch (columnType) {
      case TEXT_TYPE:
        return 'text-left';
      case NUMBER_TYPE:
        return 'text-right';
      case CURRENCY_TYPE:
        return 'text-right';
      case DATE_TYPE:
        return 'text-center';
      default:
        return 'text-center';
    }
  };

  parseCellValue = (rawCellValue, colData) => {
    let parsedCellValue = rawCellValue;
    if (colData.type === 3) {
      let options = { minimumFractionDigits: colData.decimals };
      try {
        if (colData.edition === 'i') {
          parsedCellValue = Number.parseFloat(rawCellValue).toLocaleString('en-US', options);
        } else {
          parsedCellValue = Number.parseFloat(rawCellValue).toLocaleString('de-DE', options);
        }
      } catch (ex) {
        console.error(ex);
        return rawCellValue;
      }
    }
    return parsedCellValue;
  };

  removeTabIdFromTL = (strTL) => {
    const retVal = strTL.substr(0, strTL.indexOf('P'));
    return retVal;
  };

  render() {
    const {
      data,
      loadingData,
      columns,
      noDataText = <FormattedMessage id="noData" defaultMessage={'No hay datos'} />,
      transactionLineLabel,
      onKeyDownNoData,
      multipleSelection,
      selectedIndexProp,
      highlightedIndexes,
      focusPaused,
      focusedTable,
    } = this.props;

    const {
      selectedIndex,
      sortBy: { column: sortingByColumnAccessor, reverse: sortingByReversed },
      zoom: currentZoom,
      columnsWidth,
      minWidthTable,
      multipleSelectionRowsInfo,
    } = this.state;
    if (selectedIndexProp !== undefined && selectedIndexProp !== this.state.selectedIndex) {
      this.setState({ selectedIndex: selectedIndexProp });
    }
    let noData = (
      <div
        id={transactionLineLabel + '_NoData'}
        className="container flex items-center bg-white rounded focus:shadow-sm-light"
        style={{ fontSize: FONT_SIZE, fontFamily: MONOSPACE_FONT }}
        ref={(noDataRef) => (this.noDataRef = noDataRef)}
        tabIndex={-1}
        onKeyDown={onKeyDownNoData}
        onClick={(e) => this.handleClick(e)}
        onBlur={(e) => {
          const tlid1 = transactionLineLabel;
          const tlid2 = e && e.relatedTarget && e.relatedTarget.id ? e.relatedTarget.id : '';
          if (compareTLId(tlid1, tlid2) <= 0) {
            e.preventDefault();
          } else {
            if (!focusPaused && focusedTable) onKeyDownNoData({ key: 'Tab', fromBlur: true });
          }
        }}
        autoFocus
      >
        <div className="container text-center">{noDataText}</div>
      </div>
    );

    const loader = (
      <div
        id={transactionLineLabel + '_Loading'}
        className="container flex items-center bg-white rounded focus:shadow-sm-light"
        style={{ fontSize: FONT_SIZE, fontFamily: MONOSPACE_FONT }}
        ref={(noDataRef) => (this.noDataRef = noDataRef)}
        tabIndex={-1}
      >
        <div className="container text-center my-auto">
          <PulseLoader className="text-primary" color="currentColor" size="8px" />
        </div>
      </div>
    );

    if (!columns || columns.length === 0) {
      return noData;
    }

    if (this.state.resizeColumns) {
      this.setColumnsWidth();
      this.setState({ resizeColumns: false });
    }

    const dTrianglef = <span className="px-1">&#x025BE;</span>;
    const uTrianglef = <span className="px-1">&#x025B4;</span>;
    const sortableSymbol = dTrianglef;

    const widthTable = 'auto';
    if (!this.props.ignoreFocus) {
      this.doFocusElement();
    }

    document.getElementById('root').onmousemove = (e) => {
      if (this.columnMove !== null) {
        this.setColumnsWidth(this.columnMove, RESIZE_MOVE, e.pageX);
      }
    };
    document.getElementById('root').onmouseup = (e) => {
      if (this.columnMove !== null) {
        this.setColumnsWidth(this.columnMove, RESIZE_RELEASED);
      }
    };

    return (
      <div
        id={transactionLineLabel}
        className={`flex flex-col w-full h-full rounded-lg overflow-auto contorno`}
        style={{ fontSize: FONT_SIZE, fontFamily: MONOSPACE_FONT }}
        tabIndex={-1}
        onBlur={(e) => {
          if (
            !this.props.ignoreFocus &&
            (!e.relatedTarget || (e.relatedTarget && e.relatedTarget.id !== transactionLineLabel)) &&
            (e.relatedTarget === null || e.relatedTarget.classList.contains('contorno') || e.relatedTarget.classList.contains('focus-trap'))
          ) {
            this.doFocusElement();
          }
        }}
      >
        <div className="sticky pin-t bg-grey-lighter-lighter" style={{ width: widthTable, minWidth: minWidthTable }}>
          <div className="flex">
            {columns.map((col, index) => {
              const styles = {
                width: columnsWidth && columnsWidth[index] && columnsWidth[index].value ? columnsWidth[index].value : DEFAULT_WIDTH_COLUMN,
                flex: '100 0 auto',
              };
              return (
                <div
                  id={transactionLineLabel + `_${HEADER_TITLE}_${col.Header}`}
                  key={`${index}_${col.Header}`}
                  className={`relative truncate text-center border-smoke border-r  p-1 ${col.isSortable ? 'cursor-pointer' : ''}`}
                  style={styles}
                  ref={(c) => (this.columnRefs[index] = c)}
                  onMouseDown={(e) => {
                    if (e.target === this.columnRefs[index] || e.target.id === `${HEADER_TITLE}_${col.Header}`) {
                      this.handleHeaderClick(e, index);
                    }
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  tabIndex={-1}
                >
                  <span className="font-medium" id={`${HEADER_TITLE}_${col.Header}`}>
                    {col.Header}
                  </span>
                  {!(sortingByColumnAccessor === col.accessor) && col.isSortable && sortableSymbol}
                  {sortingByColumnAccessor === col.accessor && (sortingByReversed ? uTrianglef : dTrianglef)}
                  <div
                    className="absolute pin-t pin-r bg-transparent h-full select-none"
                    style={{ width: 5, cursor: 'col-resize' }}
                    onMouseDown={(e) => {
                      this.setColumnsWidth(index, RESIZE_PRESS, e.pageX);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="h-full rounded-b">
          {loadingData
            ? loader
            : !data || data.length === 0
            ? noData
            : data.sort(this.getSortFunction(this.fromApp)).map((row, rowIndex) => {
                const index = rowIndex;
                const columnIndex = this.selectedColumn;
                let isSelected = selectedIndex === index;
                if (multipleSelection) {
                  isSelected = multipleSelectionRowsInfo.index.includes(rowIndex);
                }

                let borderType = 'border-transparent';
                if (isSelected) {
                  borderType = 'border-primary bg-smoke';
                }

                const styles = StyleSheet.create({
                  row: {
                    display: 'flex',
                    outline: 'none',
                    borderWidth: 1,
                    ':focus': {
                      boxShadow: '0 0 0 2px rgba(156,158,253,0.5)',
                    },
                  },
                });

                return (
                  <div
                    id={transactionLineLabel + '_HelpTable_' + index}
                    className={`${css(styles.row)} ${borderType}`}
                    key={index}
                    style={{ width: widthTable, minWidth: minWidthTable }}
                    tabIndex={index}
                    ref={this.setRowRef}
                    onKeyDown={(e) => {
                      let idRow = `${this.removeTabIdFromTL(transactionLineLabel)}_R${index}_C${0}`;
                      addLineEventTest(e, idRow);
                      this.handleKeyDown(e, index, row, columnIndex);
                    }}
                    onMouseDown={(e) => {
                      this.handleHeaderClickContent(e, index);
                    }}
                    onClick={(e) => this.handleClick(e, index, row, columnIndex)}
                    onDoubleClick={(e) => {
                      // Easier to pass the event like this than making double click respond upwards
                      // The key "Enter" is assigned because it executes the same behaviour that
                      // a real double click implementation should have
                      const event = { ...e, key: 'Enter' };
                      this.handleKeyDown(event, index, row, columnIndex);
                      let idRow = `${this.removeTabIdFromTL(transactionLineLabel)}_R${index}_C${0}`;
                      addValueTest('D_CLICK', idRow, '');
                    }}
                    onBlur={(e) => this.doFocusElement(null, true, e) /* (e) => this.handleBlur(e, transactionLineLabel, index, row) */}
                    onFocus={(e) => {
                      if (
                        e &&
                        e.relatedTarget &&
                        (e.relatedTarget.id === 'gridAddFilterButton_' + this.props.transactionLineLabel ||
                          e.relatedTarget.id === 'helpWindowCancelButton_' + this.props.transactionLineLabel)
                      ) {
                        this.focusOnRow(this.state.selectedIndex, data[this.state.selectedIndex], 0);
                      }
                    }}
                  >
                    {columns.map((col, cellIndex) => {
                      const rawCellValue = row[col.accessor];
                      const cellValue = this.parseCellValue(rawCellValue, col);
                      const textAlign = this.getTextAlignByColumnType(col.type);
                      const styles = {
                        width:
                          columnsWidth && columnsWidth[cellIndex] && columnsWidth[cellIndex].value
                            ? columnsWidth[cellIndex].value
                            : DEFAULT_WIDTH_COLUMN,
                        flex: '100 0 auto',
                      };
                      return (
                        <div
                          id={`${this.removeTabIdFromTL(transactionLineLabel)}_R${rowIndex}_C${cellIndex}`}
                          key={cellIndex}
                          style={styles}
                          className={`${textAlign} px-1 py-${currentZoom} truncate ${isSelected && 'bg-primary-light text-white'} ${
                            highlightedIndexes && highlightedIndexes.includes(index) ? 'bg-success' : index % 2 ? '-even' : '-odd'
                          }`}
                          title={cellValue}
                        >
                          {cellValue}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
        </div>
      </div>
    );
  }
}

export default HelpTable;
