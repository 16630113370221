import browserStorage from './browserStorage';
import { getOperations } from './../api/operation';
import { USER_NAME } from './browserStorage/constants';
import { getReports } from '../api/report';

const RECENT_OPERATIONS = 'RECENT_OPERATIONS';
const RECENT_REPORTS = 'RECENT_REPORTS';
const LIMIT_SHOW_OPERATIONS = 9;
const LIMIT_SHOW_REPORTS = 15;
const OPERATION_NUMBER_KEY = 'id';
const OPERATION_NAME_KEY = 'name';
const OPERATION_LOOK_KEY = 'look';
const REPORT_NUMBER_KEY = 'id';
const REPORT_NAME_KEY = 'name';

const filterInvalidResults = (results) => {
  return results?.filter((element) => element && element.id);
};
const filterMaxResultsOperation = (toReturn) => {
  if (toReturn) {
    toReturn = toReturn.sort((valueA, valueB) => valueB?.frequency - valueA?.frequency).slice(0, LIMIT_SHOW_OPERATIONS);
  }
  return toReturn;
};
const filterMaxResultsReport = (toReturn) => {
  if (toReturn) {
    toReturn = toReturn.sort((valueA, valueB) => valueB?.frequency - valueA?.frequency).slice(0, LIMIT_SHOW_REPORTS);
  }
  return toReturn;
};

const recentOperationsUser = () => `${RECENT_OPERATIONS}_${browserStorage.sessionGet(USER_NAME)}`;

const recentReportsUser = () => `${RECENT_REPORTS}_${browserStorage.sessionGet(USER_NAME)}`;

const saveRecent = (recentId, number, name, look) => {
  let recentKey = null;
  let numberId = null;
  let nameId = null;
  let lookId = null;

  if (recentId === RECENT_OPERATIONS) {
    recentKey = recentOperationsUser();
    numberId = OPERATION_NUMBER_KEY;
    nameId = OPERATION_NAME_KEY;
    lookId = OPERATION_LOOK_KEY;
  } else if (recentId === RECENT_REPORTS) {
    recentKey = recentReportsUser();
    numberId = REPORT_NUMBER_KEY;
    nameId = REPORT_NAME_KEY;
  }

  let recent = browserStorage.get(recentKey);
  recent = filterInvalidResults(recent);
  let newRecent = { frequency: 1 };
  newRecent[numberId] = number;
  newRecent[nameId] = name;
  newRecent[lookId] = look;

  if (recent) {
    const found = recent.find((r) => r[numberId] === number);
    if (found) found.frequency = found.frequency + 1;
    else recent.push(newRecent);
  } else recent = [newRecent];

  browserStorage.set(recentKey, recent);
};

const getRecent = (recentId) => {
  let recentKey = null;

  if (recentId === RECENT_OPERATIONS) {
    recentKey = recentOperationsUser();
  } else if (recentId === RECENT_REPORTS) {
    recentKey = recentReportsUser();
  }

  let recent = browserStorage.get(recentKey);
  recent = filterInvalidResults(recent);
  return recent;
};
const removeRecent = (recentId, number) => {
  let recentKey = null;
  let numberId = null;

  if (recentId === RECENT_OPERATIONS) {
    recentKey = recentOperationsUser();
    numberId = OPERATION_NUMBER_KEY;
  } else if (recentId === RECENT_REPORTS) {
    recentKey = recentReportsUser();
    numberId = REPORT_NUMBER_KEY;
  }

  let recent = browserStorage.get(recentKey);
  recent = filterInvalidResults(recent);
  if (recent) {
    const found = recent.find((r) => r[numberId] === number);
    if (found) {
      const idx = recent.indexOf(found);
      delete recent[idx];
      recent = filterInvalidResults(recent);
    }
  }

  browserStorage.set(recentKey, recent);
  if (recentId === RECENT_OPERATIONS) {
    recent = filterMaxResultsOperation(recent);
  } else if (recentId === RECENT_REPORTS) {
    recent = filterMaxResultsReport(recent);
  }
  return recent;
};

export const getRecentOperations = async () => {
  let recentOperations = getRecent(RECENT_OPERATIONS);
  if (recentOperations) {
    let operationsData = await getOperations();
    recentOperations.forEach((recent) => {
      recent.found = false;
      operationsData.forEach((group) => {
        group.itemsList.forEach((item) => {
          if (item.id === recent.id) {
            recent.name = item.name;
            recent.found = true;
          }
        });
      });
    });

    let toReturn = recentOperations.filter((recent) => recent.found && recent[OPERATION_NUMBER_KEY] && recent[OPERATION_NAME_KEY]);
    toReturn = filterMaxResultsOperation(toReturn);
    return toReturn;
  }
};

export const getRecentReports = async () => {
  let recentReports = getRecent(RECENT_REPORTS);
  if (recentReports) {
    let reportsData = await getReports();
    recentReports.forEach((recent) => {
      recent.found = false;
      reportsData.forEach((group) => {
        group.itemsList.forEach((item) => {
          if (item.id === recent.id) {
            recent.description = item.description;
            recent.found = true;
          }
        });
      });
    });

    let toReturn = recentReports.filter((recent) => recent.found && recent[REPORT_NUMBER_KEY] && recent[REPORT_NAME_KEY]);
    toReturn = filterMaxResultsReport(toReturn);
    return toReturn;
  }
};

export const saveRecentOperation = (operationNumber, operationName, operationLook) =>
  saveRecent(RECENT_OPERATIONS, operationNumber, operationName, operationLook);

export const removeRecentOperation = (operationNumber) => removeRecent(RECENT_OPERATIONS, operationNumber);

export const saveRecentReport = (reportNumber, reportName) => saveRecent(RECENT_REPORTS, reportNumber, reportName);

export const removeRecentReport = (reportNumber) => removeRecent(RECENT_REPORTS, reportNumber);
