import React from 'react';
import crossIcon from './cross';
import downArrowIcon from './downArrow';

const crossUpArrowIcon = (
  <div>
    {crossIcon}
    {downArrowIcon}
  </div>
);

export default crossUpArrowIcon;
