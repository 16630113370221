import React, { Component } from 'react';
import Displayable from './Displayable';
import { fileArrayToBase64 } from '../../UI-kit/helpers/fileHelper';

export default class Picture extends Component {
  render() {
    let { style, position = { x: 0, y: 0, width: 'auto', height: 'auto' }, legend, componentState } = this.props;
    let pictureComponent;
    if (legend) {
      let base64img = fileArrayToBase64(legend);
      pictureComponent = <img style={{ maxWidth: `${position.width}`, maxHeight: `${position.height}` }} src={`${base64img}`} />;
    }

    if (position.x < 0 || position.y < 0) {
      return null;
    }

    return (
      <Displayable componentState={componentState}>
        <fieldset
          className="absolute text-smoke p-1"
          style={{
            ...style,
            top: position.y,
            left: position.x,
            width: position.width,
            height: position.height,
          }}
        >
          {pictureComponent}
        </fieldset>
      </Displayable>
    );
  }
}
