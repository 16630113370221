import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { useTheme } from './../../../themes/ThemeContext';

const NavigationButton = ({ left = false, right = false, text, onClick }) => {
  const themeContext = useTheme();
  const { primary, text: colorText } = themeContext.theme;

  const styles = StyleSheet.create({
    buttonContainer: {
      cursor: 'pointer',
      userSelect: 'none',
      color: colorText.text,
      ':hover': {
        color: primary.primary,
      },
      ':active': {
        color: primary.dark,
      },
    },
    arrow: {
      margin: '0 .25rem 0',
    },
    arrowLeft: { marginRight: '.5rem' },
    arrowRight: { marginLeft: '.5rem' },
    text: {
      color: text.text,
    },
  });

  return (
    <div className={css(styles.buttonContainer)} onClick={onClick}>
      {left && (
        <span className={css(styles.arrow, styles.arrowLeft)}>
          <i className="fas fa-arrow-left" />
        </span>
      )}
      <span className={css(styles.text)}>{text}</span>
      {right && (
        <span className={css(styles.arrow, styles.arrowRight)}>
          <i className="fas fa-arrow-right" />
        </span>
      )}
    </div>
  );
};

export default NavigationButton;
