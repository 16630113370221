import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { handleError, handleEndpointError } from './handlers';
import { store } from '../store';
import { getDataFieldModificadosGrupo, getDataFieldModificadosUsuarios, cargarVectorMovimientos } from './camposModificados';
import urlencode from 'urlencode';

export let getGroupDescriptions = async () => {
  try {
    let helpGroupDescriptions = store.getState().bitacoraApp.helpGroupDescriptions;
    if (helpGroupDescriptions) {
      return helpGroupDescriptions;
    }
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getGroupDescriptions?sessionId=${session}`);
    if (response.status === 200) {
      if (response.data && response.data.length > 0) {
        const result = Array.from(response.data).map(([valor1, valor2]) => ({
          grupo: valor1,
          descripcion: valor2,
        }));
        store.setState({
          bitacoraApp: {
            ...store.getState().bitacoraApp,
            helpGroupDescriptions: result,
          },
        });
        return result;
      }
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getUsuariosBasicInfo = async () => {
  let helpDataniciales = store.getState().bitacoraApp.helpIniciales;
  if (helpDataniciales) {
    return helpDataniciales;
  }
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getUsuariosBasicInfo?sessionId=${session}`);
    if (response.status === 200) {
      if (response.data && response.data.length > 0) {
        const result = Array.from(response.data).map(([valor1, valor2, valor3, valor4, valor5]) => ({
          clave: valor1,
          nombre: valor2,
          grupo: valor3,
          iniciales: valor4,
          sucursal: valor5,
        }));
        return result;
      }
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacoraCodigos = async () => {
  try {
    let helpDataCodigo = store.getState().bitacoraApp.helpCodigo;
    if (helpDataCodigo) {
      return helpDataCodigo;
    }

    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacoraCodigos?sessionId=${session}`);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getSucursalDescriptions = async () => {
  let helpDataSucursal = store.getState().bitacoraApp.helpSucursal;
  if (helpDataSucursal) {
    return helpDataSucursal;
  }
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getSucursalDescriptions?sessionId=${session}`);
    if (response.status === 200) {
      if (response.data && response.data.length > 0) {
        const result = Array.from(response.data).map(([valor1, valor2]) => ({ codigo: valor1, descripcion: valor2 }));
        return result;
      }
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getDescripciones = async () => {
  try {
    let helpDataDescripciones = store.getState().bitacoraApp.helpDescripciones;
    if (helpDataDescripciones) {
      return helpDataDescripciones;
    }
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getDescripciones?sessionId=${session}`);
    if (response.status === 200) {
      if (response.data && response.data.length > 0) {
        const result = Array.from(response.data).map(([valor1, valor2]) => ({ descriptor: valor1, descripcion: valor2 }));
        store.setState({
          bitacoraApp: {
            ...store.getState().bitacoraApp,
            helpDescripciones: result,
          },
        });
        return result;
      }
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getDescriptorDynamicFilter = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getDescriptorDynamicFilter?sessionId=${session}`);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getOperaciones = async () => {
  try {
    let helpOperaciones = store.getState().bitacoraApp.helpOperaciones;
    if (helpOperaciones) {
      return helpOperaciones;
    }
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getOperaciones?sessionId=${session}`);
    if (response.status === 200) {
      store.setState({
        bitacoraApp: {
          ...store.getState().bitacoraApp,
          helpOperaciones: response.data,
        },
      });
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getNetMapDescriptionsPorSucursal = async () => {
  let helpDescriptionsPorSucursal = store.getState().bitacoraApp.helpDescriptionsPorSucursal;
  if (helpDescriptionsPorSucursal) {
    return helpDescriptionsPorSucursal;
  }
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getNetMapDescriptionsPorSucursal?sessionId=${session}`);
    if (response.status === 200) {
      if (response.data && response.data.length > 0) {
        const result = Array.from(response.data).map(([value1, value2, value3]) => ({ numero: value1, sucursal: value2, descripcion: value3 }));
        store.setState({
          bitacoraApp: {
            ...store.getState().bitacoraApp,
            helpDescriptionsPorSucursal: result,
          },
        });
        return result;
      }
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBloqueos = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBloqueos?sessionId=${session}`);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getCanales = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getCanales?sessionId=${session}`);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getEmpresas = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getEmpresas?sessionId=${session}`);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getSucursalEmpresaDescriptions = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getSucursalEmpresaDescriptions?sessionId=${session}`);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getEmpresa = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getEmpresa?sessionId=${session}`);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacoraHasLicense = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacoraHasLicense?sessionId=${session}`);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

const formatBitacorasParams = (params) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let paramCodigo = params.codigo ? `&codigo=${params.codigo.trim()}` : '';
  let paramDescripcion = params.descripcion ? `&descripcion=${urlencode(params.descripcion.trim())}` : '';
  let paramPrograma = params.programa ? `&programa=${params.programa.trim()}` : '';
  let paramUsuario = params.usuario ? `&usuario=${params.usuario.trim()}` : '';
  let paramMnemotecnico = params.mnemotecnico ? `&mnemotecnico=${params.mnemotecnico.trim()}` : '';
  let paramUsuariored = params.usuariored ? `&usuariored=${params.usuariored.trim()}` : '';
  let paramMacaddress = params.macaddress ? `&macaddress=${params.macaddress.trim()}` : '';
  let paramMaquina = params.maquina ? `&maquina=${params.maquina.trim()}` : '';
  let paramSucursal = params.sucursal ? `&sucursal=${params.sucursal.trim()}` : '';
  let paramOperacion = params.operacion ? `&operacion=${params.operacion.trim()}` : '';
  let paramDireccionIP = params.direccionIP ? `&direccionIP=${params.direccionIP.trim()}` : '';

  return `sessionId=${session}&fechaHoraDesde=${params.fechaHoraDesde}&fechaHoraHasta=${params.fechaHoraHasta}&firstResult=${
    params.firstResult
  }&maxResults=${
    params.maxResults
  }${paramCodigo}${paramDescripcion}${paramPrograma}${paramUsuario}${paramMnemotecnico}${paramUsuariored}${paramMacaddress}${paramMaquina}${paramSucursal}${paramOperacion}${paramDireccionIP}&persistenceUnit=${params.persistenceUnit.trim()}`;
};

export let getBitacoras = async (params) => {
  try {
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacoras?` + formatBitacorasParams(params));

    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacorasCount = async (params) => {
  try {
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacorasCount?` + formatBitacorasParams(params));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacorasOlvidoPwd = async (
  fechaHoraDesde,
  fechaHoraHasta,
  firstResult,
  maxResults,
  cambios,
  fallidos,
  usuario,
  maquina,
  sucursal,
  operacion,
  direccionIP,
  persistenceUnit,
) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(
      `${API}TopazWebClientWS/resources/binnacle/getBitacorasOlvidoPwd?sessionId=${session}&fechaHoraDesde=${fechaHoraDesde}&fechaHoraHasta=${fechaHoraHasta}&firstResult=${firstResult}&maxResults=${maxResults}&cambios=${cambios}&fallidos=${fallidos}&usuario=${usuario}&maquina=${maquina}&sucursal=${sucursal}&operacion=${operacion}&direccionIP=${direccionIP}&persistenceUnit=${persistenceUnit}`,
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacorasOlvidoPwdCount = async (
  fechaHoraDesde,
  fechaHoraHasta,
  firstResult,
  cambios,
  fallidos,
  usuario,
  maquina,
  sucursal,
  operacion,
  direccionIP,
  persistenceUnit,
) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(
      `${API}TopazWebClientWS/resources/binnacle/getBitacorasOlvidoPwdCount?sessionId=${session}&fechaHoraDesde=${fechaHoraDesde}&fechaHoraHasta=${fechaHoraHasta}&firstResult=${firstResult}&cambios=${cambios}&fallidos=${fallidos}&usuario=${usuario}&maquina=${maquina}&sucursal=${sucursal}&operacion=${operacion}&direccionIP=${direccionIP}&persistenceUnit=${persistenceUnit}`,
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

const formatBitacorasLoginParams = (params) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let paramBLoguin = params.bLogin !== undefined ? `&bLoguin=${params.bLogin}` : '';
  let paramBLogout = params.bLogout !== undefined ? `&bLogout=${params.bLogout}` : '';
  let paramBFallidos = params.bFallidos !== undefined ? `&bFallidos=${params.bFallidos}` : '';
  let paramUsuario = params.usuario !== undefined ? `&usuario=${params.usuario ? params.usuario : ''}` : '';
  let paramCantidadFallos = params.cantidadFallos !== undefined ? `&cantidadFallos=${params.cantidadFallos ? params.cantidadFallos : ''}` : '';
  let paramMaquina = params.maquina !== undefined ? `&maquina=${params.maquina ? params.maquina : ''}` : '';
  let paramSucursal = params.sucursal !== undefined ? `&sucursal=${params.sucursal ? params.sucursal : ''}` : '';

  return `sessionId=${session}&fechaHoraDesde=${params.fechaHoraDesde}&fechaHoraHasta=${params.fechaHoraHasta}&firstResult=${params.firstResult}&maxResults=${params.maxResults}${paramBLoguin}${paramBLogout}${paramBFallidos}${paramUsuario}${paramCantidadFallos}${paramMaquina}${paramSucursal}&persistenceUnit=${params.persistenceUnit}`;
};

export let getBitacorasLogin = async (params) => {
  try {
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacorasLogin?` + formatBitacorasLoginParams(params));
    if (response.status === 200) {
      return getLoginWithDescriptions(response.data);
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacorasLoginCount = async (params) => {
  try {
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacorasLoginCount?` + formatBitacorasLoginParams(params, true));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

const getLoginWithDescriptions = (data) => {
  if (data && data.length > 0) {
    let result = [];
    data.map((value) => {
      let helpDataSucursal = store.getState().bitacoraApp.helpSucursal;
      let helpDataniciales = store.getState().bitacoraApp.helpIniciales;
      let helpDataMaquina = store.getState().bitacoraApp.helpMaquina;

      let dataSucursal = helpDataSucursal.find((c) => c.codigo === value.sucursal);
      let dataMaquina = helpDataMaquina.find((c) => c.numero === value.nromaquina);

      let dataIniciales = helpDataniciales.find((c) => c.clave === value.usuario);

      if (dataSucursal === undefined) {
        value.nombreSucursal = '';
      } else {
        value.nombreSucursal = dataSucursal.descripcion;
      }

      if (dataMaquina === undefined) {
        value.nombreMaquina = '';
      } else {
        value.nombreMaquina = dataMaquina.descripcion;
      }

      if (dataIniciales === undefined) {
        value.nombre = '';
        value.clave = '';
        value.iniciales = '';
      } else {
        value.nombre = dataIniciales.nombre;
        value.clave = dataIniciales.clave;
        value.iniciales = dataIniciales.iniciales;
      }

      result.push(value);
    });

    return result;
  }
  return [];
};

const getRestriccionesWithDescriptions = (data) => {
  if (data && data.length > 0) {
    let result = [];
    data.map((value) => {
      let helpDataniciales = store.getState().bitacoraApp.helpIniciales;

      let dataIniciales = helpDataniciales.find((c) => c.iniciales === value.clave_modificador);
      if (dataIniciales === undefined) {
        value.inicialesModificador = '';
        value.nombreModificador = '';
        value.sucursalModificador = '';
      } else {
        value.usuarioModificador = dataIniciales.clave;
        value.inicialesModificador = value.clave_modificador;
        value.nombreModificador = dataIniciales.nombre;
        value.sucursalModificador = dataIniciales.sucursal;
      }

      if (value.tipo_restriccion === 'O') {
        value.tipo_restriccion = 'Operación';
      } else if (value.tipo_restriccion === 'M') {
        value.tipo_restriccion = 'Menú';
      } else if (value.tipo_restriccion === 'Q') {
        value.tipo_restriccion = 'Reporte';
      } else if (value.tipo_restriccion === 'S') {
        value.tipo_restriccion = 'Sucursal';
      } else if (value.tipo_restriccion === 'P') {
        value.tipo_restriccion = 'Permiso';
      }

      if (value.permite_restriccion === 0) {
        value.tipodecambio = 'Deshabilitado';
      } else if (value.permite_restriccion === 1) {
        value.tipodecambio = 'Habilitado';
      } else if (value.permite_restriccion === 2) {
        value.tipodecambio = 'Libre';
      }
      result.push(value);
    });

    return result;
  }
  return [];
};

const formatBitacorasGruposParams = (params) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let paramGrupo = params.grupo !== undefined ? `&grupo=${params.grupo}` : '';
  let paramUsuario = params.usuario !== undefined ? `&usuario=${params.usuario}` : '';

  return `sessionId=${session}&fechaHoraDesde=${params.fechaHoraDesde}&fechaHoraHasta=${params.fechaHoraHasta}&firstResult=${params.firstResult}&maxResults=${params.maxResults}&persistenceUnit=${params.persistenceUnit}${paramGrupo}${paramUsuario}`;
};

export let getBitacoraGrupos = async (params) => {
  try {
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacoraGrupos?` + formatBitacorasGruposParams(params));
    if (response.status === 200) {
      let dataRet = getDataFieldModificadosGrupo(response.data, params.intl);
      return dataRet;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacoraGruposCount = async (params) => {
  try {
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacoraGruposCount?` + formatBitacorasGruposParams(params));
    if (response.status === 200) {
      if (response.data && !isNaN(response.data)) {
        return response.data / 2;
      } else {
        return response.data;
      }
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

const formatBitacorasServiciosWSParams = (params) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  let paramUsuario = params.usuario !== undefined ? `&usuario=${params.usuario}` : '';
  let paramempresa = params.empresa !== undefined ? `&empresa=${params.empresa}` : '';
  let paramsucursalEmpresa = params.sucursalEmpresa !== undefined ? `&sucursal_empresa=${params.sucursalEmpresa}` : '';
  let paramcanal = params.canal !== undefined ? `&canal=${params.canal}` : '';
  let paramoperacion = params.operacion !== undefined ? `&operacion=${params.operacion}` : '';

  return `sessionId=${session}&fechaHoraDesde=${params.fechaHoraDesde}&fechaHoraHasta=${params.fechaHoraHasta}&firstResult=${params.firstResult}&maxResults=${params.maxResults}&persistenceUnit=${params.persistenceUnit}${paramUsuario}${paramempresa}${paramsucursalEmpresa}${paramcanal}${paramoperacion}`;
};

export let getBitacoraServiciosWS = async (params) => {
  try {
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacoraServiciosWS?` + formatBitacorasServiciosWSParams(params));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacoraServiciosWSCount = async (params) => {
  try {
    let response = await axios.get(
      `${API}TopazWebClientWS/resources/binnacle/getBitacoraServiciosWSCount?` + formatBitacorasServiciosWSParams(params),
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

const formatBitacorasUsuariosParams = (params) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let paramclave = params.clave !== undefined ? `&clave=${params.clave.trim()}` : '';
  let paraminiciales = params.iniciales !== undefined ? `&iniciales=${params.iniciales.trim()}` : '';
  let paramgrupo = params.grupo !== undefined ? `&grupo=${params.grupo.trim()}` : '';
  let paramnrosucursal = params.nrosucursal !== undefined ? `&nrosucursal=${params.nrosucursal.trim()}` : '';
  let paramnumerodemaquina = params.numerodemaquina !== undefined ? `&numerodemaquina=${params.numerodemaquina.trim()}` : '';
  let paramclaveModificador = params.clave_modificador !== undefined ? `&clave_modificador=${params.clave_modificador.trim()}` : '';
  let parambegin = params.begin !== undefined ? `&begin=${params.begin}` : '';

  return `sessionId=${session}&fechaHoraDesde=${params.fechaHoraDesde}&fechaHoraHasta=${params.fechaHoraHasta}&maxResults=${
    params.maxResults
  }&persistenceUnit=${params.persistenceUnit.trim()}${paramclave}${paraminiciales}${paramgrupo}${paramnrosucursal}${paramnumerodemaquina}${paramclaveModificador}${parambegin}`;
};

export let getBitacorasUsuarios = async (params) => {
  try {
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacorasUsuarios?` + formatBitacorasUsuariosParams(params));
    if (response.status === 200) {
      let dataRet = getDataFieldModificadosUsuarios(response.data, params.intl);
      return dataRet;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacorasUsuariosCount = async (params) => {
  try {
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacoras_usuariosCount?` + formatBitacorasUsuariosParams(params));
    if (response.status === 200) {
      if (response.data && !isNaN(response.data)) {
        return response.data / 2;
      } else {
        return response.data;
      }
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

const formatBitacorasRestriccionesParams = (params) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let paramgrupo = params.grupo !== undefined ? `&grupo=${params.grupo}` : '';
  let paramusuario = params.usuario !== undefined ? `&usuario=${params.usuario}` : '';
  let parambMenu = params.bMenu !== undefined ? `&bMenu=${params.bMenu}` : '';
  let paramnbOperaciones = params.bOperaciones !== undefined ? `&bOperaciones=${params.bOperaciones}` : '';
  let paramnbReportes = params.bReportes !== undefined ? `&bReportes=${params.bReportes}` : '';
  let parambPermisos = params.bPermisos !== undefined ? `&bPermisos=${params.bPermisos}` : '';
  let parambSucursales = params.bSucursales !== undefined ? `&bSucursales=${params.bSucursales}` : '';
  let parambNavegaciones = params.bNavegaciones !== undefined ? `&bNavegaciones=${params.bNavegaciones}` : '';
  let parambcodigorestriccion = params.codigorestriccion !== undefined ? `&codigorestriccion=${params.codigorestriccion}` : '';
  let parambtipodecambio = params.tipodecambio !== undefined ? `&tipodecambio=${params.tipodecambio}` : '';
  let paraminicialesmodificador = params.inicialesmodificador !== undefined ? `&inicialesmodificador=${params.inicialesmodificador}` : '';

  return `sessionId=${session}&fechaHoraDesde=${params.fechaHoraDesde}&fechaHoraHasta=${params.fechaHoraHasta}&firstResult=${params.firstResult}&maxResults=${params.maxResults}&persistenceUnit=${params.persistenceUnit}${paramgrupo}${paramusuario}${parambMenu}${paramnbOperaciones}${paramnbReportes}${parambPermisos}${parambSucursales}${parambNavegaciones}${parambcodigorestriccion}${parambtipodecambio}${paraminicialesmodificador}`;
};

export let getBitacoraRestricciones = async (params) => {
  try {
    let response = await axios.get(
      `${API}TopazWebClientWS/resources/binnacle/getBitacoraRestricciones?` + formatBitacorasRestriccionesParams(params),
    );
    if (response.status === 200) {
      return getRestriccionesWithDescriptions(response.data);
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacoraRestriccionesCount = async (params) => {
  try {
    let response = await axios.get(
      `${API}TopazWebClientWS/resources/binnacle/getBitacoraRestriccionesCount?` + formatBitacorasRestriccionesParams(params),
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

const formatBitacorasOperacionesParams = (params) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let paramasiento = params.asiento !== undefined ? `&asiento=${params.asiento}` : '';
  let paramoperacion = params.operacion !== undefined ? `&operacion=${params.operacion}` : '';
  let paramdescriptor = params.descriptor !== undefined ? `&descriptor=${params.descriptor}` : '';
  let paramnusuario = params.usuario !== undefined ? `&usuario=${params.usuario}` : '';
  let parambsucursal = params.sucursal !== undefined ? `&sucursal=${params.sucursal}` : '';
  let paramcliente = params.cliente !== undefined ? `["C0002","${params.cliente}"]` : '';
  let paramcuenta = params.cuenta !== undefined ? `["C0005","${params.cuenta}"]` : '';
  let parammoneda = params.moneda !== undefined ? `["C0003","${params.moneda}"]` : '';
  let paramoperacion2 = params.operacion2 !== undefined ? `["C0006","${params.operacion2}"]` : '';
  let paramrubroContable = params.rubroContable !== undefined ? `["C6326","${params.rubroContable}"]` : '';
  let parambalta = params.alta !== undefined ? `&alta=${params.alta}` : '';
  let parambaja = params.baja !== undefined ? `&baja=${params.baja}` : '';
  let parammodificacion = params.modificacion !== undefined ? `&modificacion=${params.modificacion}` : '';
  let paramdynamicFilters =
    params.cliente !== undefined ||
    params.cuenta !== undefined ||
    params.moneda !== undefined ||
    params.operacion2 !== undefined ||
    params.rubroContable !== undefined
      ? `&dynamicFilters=[${[paramcliente, paramcuenta, parammoneda, paramoperacion2, paramrubroContable].filter((element) => element).join(',')}]`
      : '';
  let parambegin = `&begin=${params.begin}`;

  return `sessionId=${session}&fechaHoraDesde=${params.fechaHoraDesde}&fechaHoraHasta=${params.fechaHoraHasta}${paramasiento}${paramoperacion}${paramdescriptor}${paramnusuario}${parambsucursal}${parambalta}${parambaja}${parammodificacion}${paramdynamicFilters}&persistenceUnit=${params.persistenceUnit}${parambegin}&maxResults=${params.maxResults}`;
};

export let getBitacorasOperaciones = async (params) => {
  try {
    let response = await axios.get(`${API}TopazWebClientWS/resources/binnacle/getBitacorasOperaciones?` + formatBitacorasOperacionesParams(params));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacorasOperacionesCount = async (params) => {
  try {
    let response = await axios.get(
      `${API}TopazWebClientWS/resources/binnacle/getBitacorasOperacionesCount?` + formatBitacorasOperacionesParams(params),
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacorasMovimientos = async (asiento, nrosucursal, fechaproceso, persistenceUnit, intl) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(
      `${API}TopazWebClientWS/resources/binnacle/getBitacorasMovimientos?sessionId=${session}&asiento=${asiento}&nrosucursal=${nrosucursal}&fechaproceso=${fechaproceso}&persistenceUnit=Local`,
    );
    if (response.status === 200) {
      return cargarVectorMovimientos(response.data, intl);
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacorasMovimientosCount = async (asiento, nrosucursal, fechaproceso) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(
      `${API}TopazWebClientWS/resources/binnacle/getBitacorasMovimientosCount?sessionId=${session}&asiento=${asiento}&nrosucursal=${nrosucursal}&fechaproceso=${fechaproceso}`,
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getWSServicesControl = async (transactionId, transactionIdStep) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.get(
      `${API}TopazWebClientWS/resources/binnacle/getWSServicesControl?sessionId=${session}&transactionId=${transactionId}&transactionIdStep=${transactionIdStep}`,
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBitacorasExcel = async (asientos, descriptor, persistenceUnit) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.post(
      `${API}TopazWebClientWS/resources/binnacle/getBitacorasExcel?sessionId=${session}&descriptor=${descriptor}&persistenceUnit=${persistenceUnit}`,
      asientos,
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};
