import React, { Component } from 'react';

export default class Displayable extends Component {
  render() {
    let { componentState, children, position = { x: 0, y: 0, width: 'auto', height: 'auto' } } = this.props;

    let { hide, deleted } = componentState || false;

    if (deleted) return null;

    return (
      <div
        className={`absolute w-full ${hide ? 'hidden' : ''}`}
        style={{
          left: position.x,
          top: position.y,
          width: position.width,
          height: position.height,
        }}
      >
        {children}
      </div>
    );
  }
}
