import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { useTheme } from './../../../themes/ThemeContext';

const ActionButton = ({ type, onClick, disabled, children }) => {
  const themeContext = useTheme();
  const { color, success: successColor, error } = themeContext.theme;

  const success = type === 'success';

  const styles = StyleSheet.create({
    button: {
      color: success ? successColor.dark : error.dark,
      backgroundColor: color.white,
      borderColor: success ? successColor.dark : error.error,
      borderWidth: 1,
      padding: '.5rem .8rem',
      borderRadius: 3,
      margin: '1rem 1rem 1rem 0',
      transition: '.2s',
      ':hover': {
        boxShadow: '0 1px 0px 0 #00151F',
        backgroundColor: disabled ? color.white : success ? successColor.dark : error.error,
        color: !disabled && color.white,
      },
      ':focus': {
        boxShadow: '0 1px 0px 0 #00151F',
        backgroundColor: success ? successColor.dark : error.dark,
        color: color.white,
      },
      ':active': {
        boxShadow: '0 1px 0px 0 #00151F',
        backgroundColor: success ? successColor.dark : error.dark,
        color: color.white,
      },
    },
  });

  return (
    <button className={css(styles.button)} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default ActionButton;
