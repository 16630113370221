import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getTransaction } from '../api/postingManager';
import { formatDateTimePostings } from '../helpers/postingManagerHelper';
import PostingStatus from './PostingStatus';
import PostingFormList from './PostingFormList';
import PostingPermissionsList from './PostingPermissionsList';
import SignaturesSelector from '../../src/components/SignaturesValidator/SignaturesSelector';
import ModalMessage from '../../src/components/ModalMessage';

const getActionVerb = (actionNumber) => {
  if (actionNumber === 1) {
    return 'Registrado';
  } else if (actionNumber === 2) {
    return 'Confirmado';
  } else if (actionNumber === 3) {
    return 'Eliminado';
  } else if (actionNumber === 4) {
    return 'Extornado';
  } else if (actionNumber === 5) {
    return 'Reaplicado';
  } else if (actionNumber === 6) {
    return 'Rechazado';
  } else if (actionNumber === 7) {
    return 'Autorizado';
  } else if (actionNumber === 8) {
    return 'Confirmado (diferida)';
  }
};

export class PostingManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postingInfo: {},
      historyRows: [],
      showSignatureWindow: false,
    };
    this.fetchPostingInfo = this.fetchPostingInfo.bind(this);
    this.setHistoryRows = this.setHistoryRows.bind(this);
  }

  componentDidMount() {
    this.fetchPostingInfo();
  }

  setHistoryRows(histories) {
    let rows = [];
    if (histories) {
      histories.forEach((historyItem) => {
        let { user, processDate, comment, action } = historyItem;
        let row = {
          user,
          date: formatDateTimePostings(processDate),
          comment,
          action,
        };
        rows.push(row);
      });
    }
    this.setState({ historyRows: rows });
  }

  async fetchPostingInfo() {
    const { postingData } = this.props;
    const { branch, processDate, transactionID } = postingData;
    let data = await getTransaction(branch, transactionID, processDate, null);
    let signaturesObject = data.signatures;
    let signaturesArray = [];
    for (const p in signaturesObject) {
      let contains = signaturesObject[p];
      contains.name = p.slice(p.indexOf('-') + 1, p.length);
      signaturesArray.push(contains);
      break;
    }
    data.signatures = signaturesArray;
    this.setState({
      postingInfo: data,
    });
    this.setHistoryRows(data.histories);
  }

  render() {
    const { historyRows, postingInfo } = this.state;
    const { postingData, bodyGetPostings, hidePostingManager } = this.props;

    const { permissions, reports: formList, eraseable, action } = postingInfo;

    const { branch, description, operation, processDate, sector, state: postingState, transactionID } = postingData;
    return (
      <div className="relative w-full mr-2 ml-2 mt-6 p-2">
        <button
          className="text-primary"
          onClick={() => {
            hidePostingManager();
          }}
          title="Volver"
        >
          &larr; <FormattedMessage id="backToList" defaultMessage={`Volver al listado`} />
        </button>

        <div className="flex items-center my-6 ">
          <div className="text-xl font-mono">{transactionID}</div>
          <div className="text-xs font-light inline-block ml-6">
            <span className="uppercase text-smoke">
              <FormattedMessage id="branch" defaultMessage={`Sucursal`} />
            </span>
            : {branch}
          </div>
          <div className="text-xs font-light inline-block ml-6">
            <span className="uppercase text-smoke">
              <FormattedMessage id="sector" defaultMessage={`Sector`} />
            </span>
            : {sector}
          </div>

          <div className="text-xs font-light inline-block ml-6">
            <span className="uppercase text-smoke">
              <FormattedMessage id="dateProcess" defaultMessage={`Fecha de proceso`} />
            </span>
            : {processDate.split(' ')[0]}
          </div>
        </div>

        <div className="text-xl font-normal my-6">
          <PostingStatus state={postingState} />
        </div>

        <div className="text-xl font-normal my-6">
          <div className="text-xs uppercase text-smoke mb-1">
            <FormattedMessage id="operation" defaultMessage={`Operación`} />
          </div>
          <span>{operation}</span>&nbsp;
          <span>{description}</span>
        </div>

        <div className="flex flex-row-reverse pr-6 font-normal">
          <div className="w-1/4 pl-6 border-l border-light">
            <div>
              {this.state.postingInfo.signatures && this.state.postingInfo.signatures.length > 0 && (
                <button onClick={() => this.setState({ showSignatureWindow: true })}>Firmas</button>
              )}
            </div>
            <div className="text-xs uppercase text-smoke mb-1">
              <FormattedMessage id="forms" defaultMessage={`Formularios`} />
            </div>
            <PostingFormList
              data={formList}
              noDataText={
                <p className="text-text-base" style={{ fontSize: 11 }}>
                  <FormattedMessage id="noFormsHaveBeenEntered" defaultMessage={`No se han ingresado formularios`} />
                </p>
              }
            />
          </div>

          <div className="flex-grow pr-6">
            <div className="text-xs font-normal mb-6">
              <div className="text-xs uppercase text-smoke mb-1">
                <FormattedMessage id="historical" defaultMessage={`Histórico`} />
              </div>
              <div>
                {historyRows.map((item, index) => {
                  const { user, date, comment, action } = item;
                  return (
                    <div className="text-xs mb-3 cursor-pointer hover:text-black" key={index} title={comment}>
                      <b className="font-bold">{getActionVerb(action)}</b> <FormattedMessage id="on" defaultMessage={`el`} /> {date.split(' ')[0]}{' '}
                      <FormattedMessage id="by" defaultMessage={`por`} /> {user}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="bg-smoke h-px my-4"></div>
            <div className="text-sm font-normal w-100 my-6">
              <div className="text-xs uppercase text-smoke mb-1">
                <FormattedMessage id="permissions" defaultMessage={`Permisos`} />
              </div>
              <PostingPermissionsList
                disableDelete={!eraseable}
                data={permissions}
                state={postingState}
                action={action}
                postingData={{
                  postingData,
                  bodyGetPostings,
                }}
                backToList={() => {
                  hidePostingManager();
                }}
                noDataText={
                  <p className="text-text-base" style={{ fontSize: 11 }}>
                    <FormattedMessage id="noAuthorizationsRequired" defaultMessage={`No se requieren autorizaciones`} />
                  </p>
                }
              />
              <div>
                {this.state.showSignatureWindow && (
                  <ModalMessage
                    message={<SignaturesSelector signatures={this.state.postingInfo.signatures} readOnly={true}></SignaturesSelector>}
                    largeModal={true}
                    handleAccept={() => {
                      this.setState({ showSignatureWindow: false });
                    }}
                    handleCancel={() => {
                      this.setState({ showSignatureWindow: false });
                    }}
                    acceptTitle={'Aceptar'}
                    cancelTitle={'Cancelar'}
                  ></ModalMessage>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PostingManager;
