import React from 'react';
import Icono1 from '../resources/icons/lineal/1.svg';
import Icono2 from '../resources/icons/lineal/2.svg';
import Icono3 from '../resources/icons/lineal/3.svg';
import Icono4 from '../resources/icons/lineal/4.svg';
import Icono5 from '../resources/icons/lineal/5.svg';
import Icono6 from '../resources/icons/lineal/6.svg';
import Icono7 from '../resources/icons/lineal/7.svg';
import Icono8 from '../resources/icons/lineal/8.svg';
import Icono9 from '../resources/icons/lineal/9.svg';
import Icono10 from '../resources/icons/lineal/10.svg';
import Icono11 from '../resources/icons/lineal/11.svg';
import Icono12 from '../resources/icons/lineal/12.svg';
import Icono13 from '../resources/icons/lineal/13.svg';
import Icono14 from '../resources/icons/lineal/14.svg';
import Icono15 from '../resources/icons/lineal/15.svg';
import Icono16 from '../resources/icons/lineal/16.svg';
import Icono17 from '../resources/icons/lineal/17.svg';
import Icono18 from '../resources/icons/lineal/18.svg';
import Icono19 from '../resources/icons/lineal/19.svg';
import Icono20 from '../resources/icons/lineal/20.svg';
import Icono21 from '../resources/icons/lineal/21.svg';
import Icono22 from '../resources/icons/lineal/22.svg';
import Icono23 from '../resources/icons/lineal/23.svg';
import Icono24 from '../resources/icons/lineal/24.svg';
import Icono25 from '../resources/icons/lineal/25.svg';
import Icono26 from '../resources/icons/lineal/26.svg';
import Icono27 from '../resources/icons/lineal/27.svg';
import Icono28 from '../resources/icons/lineal/28.svg';
import Icono29 from '../resources/icons/lineal/29.svg';
import Icono30 from '../resources/icons/lineal/30.svg';
import Icono31 from '../resources/icons/lineal/31.svg';
import Icono32 from '../resources/icons/lineal/32.svg';
import Icono33 from '../resources/icons/lineal/33.svg';
import Icono34 from '../resources/icons/lineal/34.svg';
import Icono35 from '../resources/icons/lineal/35.svg';
import Icono36 from '../resources/icons/lineal/36.svg';
import Icono37 from '../resources/icons/lineal/37.svg';
import Icono38 from '../resources/icons/lineal/38.svg';
import Icono39 from '../resources/icons/lineal/39.svg';
import Icono40 from '../resources/icons/lineal/40.svg';
import Icono41 from '../resources/icons/lineal/41.svg';
import Icono42 from '../resources/icons/lineal/42.svg';
import Icono43 from '../resources/icons/lineal/43.svg';
import Icono44 from '../resources/icons/lineal/44.svg';
import Icono1Color from '../resources/icons/color/1.svg';
import Icono2Color from '../resources/icons/color/2.svg';
import Icono3Color from '../resources/icons/color/3.svg';
import Icono4Color from '../resources/icons/color/4.svg';
import Icono5Color from '../resources/icons/color/5.svg';
import Icono6Color from '../resources/icons/color/6.svg';
import Icono7Color from '../resources/icons/color/7.svg';
import Icono8Color from '../resources/icons/color/8.svg';
import Icono9Color from '../resources/icons/color/9.svg';
import Icono10Color from '../resources/icons/color/10.svg';
import Icono11Color from '../resources/icons/color/11.svg';
import Icono12Color from '../resources/icons/color/12.svg';
import Icono13Color from '../resources/icons/color/13.svg';
import Icono14Color from '../resources/icons/color/14.svg';
import Icono15Color from '../resources/icons/color/15.svg';
import Icono16Color from '../resources/icons/color/16.svg';
import Icono17Color from '../resources/icons/color/17.svg';
import Icono18Color from '../resources/icons/color/18.svg';
import Icono19Color from '../resources/icons/color/19.svg';
import Icono20Color from '../resources/icons/color/20.svg';
import Icono21Color from '../resources/icons/color/21.svg';
import Icono22Color from '../resources/icons/color/22.svg';
import Icono23Color from '../resources/icons/color/23.svg';
import Icono24Color from '../resources/icons/color/24.svg';
import Icono25Color from '../resources/icons/color/25.svg';
import Icono26Color from '../resources/icons/color/26.svg';
import Icono27Color from '../resources/icons/color/27.svg';
import Icono28Color from '../resources/icons/color/28.svg';
import Icono29Color from '../resources/icons/color/29.svg';
import Icono30Color from '../resources/icons/color/30.svg';
import Icono31Color from '../resources/icons/color/31.svg';
import Icono32Color from '../resources/icons/color/32.svg';
import Icono33Color from '../resources/icons/color/33.svg';
import Icono34Color from '../resources/icons/color/34.svg';
import Icono35Color from '../resources/icons/color/35.svg';
import Icono36Color from '../resources/icons/color/36.svg';
import Icono37Color from '../resources/icons/color/37.svg';
import Icono38Color from '../resources/icons/color/38.svg';
import Icono39Color from '../resources/icons/color/39.svg';
import Icono40Color from '../resources/icons/color/40.svg';
import Icono41Color from '../resources/icons/color/41.svg';
import Icono42Color from '../resources/icons/color/42.svg';
import Icono43Color from '../resources/icons/color/43.svg';
import Icono44Color from '../resources/icons/color/44.svg';

export const iconsComponente = {
  '1': <Icono1 />,
  '2': <Icono2 />,
  '3': <Icono3 />,
  '4': <Icono4 />,
  '5': <Icono5 />,
  '6': <Icono6 />,
  '7': <Icono7 />,
  '8': <Icono8 />,
  '9': <Icono9 />,
  '10': <Icono10 />,
  '11': <Icono11 />,
  '12': <Icono12 />,
  '13': <Icono13 />,
  '14': <Icono14 />,
  '15': <Icono15 />,
  '16': <Icono16 />,
  '17': <Icono17 />,
  '18': <Icono18 />,
  '19': <Icono19 />,
  '20': <Icono20 />,
  '21': <Icono21 />,
  '22': <Icono22 />,
  '23': <Icono23 />,
  '24': <Icono24 />,
  '25': <Icono25 />,
  '26': <Icono26 />,
  '27': <Icono27 />,
  '28': <Icono28 />,
  '29': <Icono29 />,
  '30': <Icono30 />,
  '31': <Icono31 />,
  '32': <Icono32 />,
  '33': <Icono33 />,
  '34': <Icono34 />,
  '35': <Icono35 />,
  '36': <Icono36 />,
  '37': <Icono37 />,
  '38': <Icono38 />,
  '39': <Icono39 />,
  '40': <Icono40 />,
  '41': <Icono41 />,
  '42': <Icono42 />,
  '43': <Icono43 />,
  '44': <Icono44 />,
};

export const iconsComponenteColor = {
  '1': <Icono1Color />,
  '2': <Icono2Color />,
  '3': <Icono3Color />,
  '4': <Icono4Color />,
  '5': <Icono5Color />,
  '6': <Icono6Color />,
  '7': <Icono7Color />,
  '8': <Icono8Color />,
  '9': <Icono9Color />,
  '10': <Icono10Color />,
  '11': <Icono11Color />,
  '12': <Icono12Color />,
  '13': <Icono13Color />,
  '14': <Icono14Color />,
  '15': <Icono15Color />,
  '16': <Icono16Color />,
  '17': <Icono17Color />,
  '18': <Icono18Color />,
  '19': <Icono19Color />,
  '20': <Icono20Color />,
  '21': <Icono21Color />,
  '22': <Icono22Color />,
  '23': <Icono23Color />,
  '24': <Icono24Color />,
  '25': <Icono25Color />,
  '26': <Icono26Color />,
  '27': <Icono27Color />,
  '28': <Icono28Color />,
  '29': <Icono29Color />,
  '30': <Icono30Color />,
  '31': <Icono31Color />,
  '32': <Icono32Color />,
  '33': <Icono33Color />,
  '34': <Icono34Color />,
  '35': <Icono35Color />,
  '36': <Icono36Color />,
  '37': <Icono37Color />,
  '38': <Icono38Color />,
  '39': <Icono39Color />,
  '40': <Icono40Color />,
  '41': <Icono41Color />,
  '42': <Icono42Color />,
  '43': <Icono43Color />,
  '44': <Icono44Color />,
};
