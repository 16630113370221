import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Notification from '../Notification/NotificationViewModel';
import { useTheme } from '../../themes/ThemeContext';
import { StyleSheet, css } from 'aphrodite';

const Option = (props) => {
  const { title, selected = false, collapsed = true, click, icon, linkTo, hoverColor, activeColor, id } = props;

  const themeState = useTheme();
  const { background, option } = themeState.theme;
  const initColor = selected ? option.selected : option.option;
  const [iconColor, setIconColor] = useState(initColor);

  useEffect(() => {
    if (selected) {
      setIconColor(option.selected);
    } else {
      setIconColor(option.option);
    }
  }, [option.option, option.selected, selected]);

  const handleHover = () => {
    setIconColor(hoverColor || option.hover);
  };

  const handleBlur = () => {
    !selected && setIconColor(option.option);
  };

  const styles = StyleSheet.create({
    optionContainer: {
      paddingLeft: '0.25rem',
      minHeight: '2.5rem',
      maxHeight: '3rem',
    },
    optionLink: {
      textDecoration: 'none',
      cursor: 'pointer',
      ':focus': {
        outline: 'none',
      },
    },
    optionWrapper: {
      margin: '.1rem .5rem .25rem .25rem',
      padding: '.75rem',
      transition: '.1s ease-out',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '100px',
      backgroundColor: selected && background.dark,
      color: selected ? option.selected : option.option,
      borderColor: 'transparent',
      ':hover:not(:active)': {
        backgroundColor: background.dark,
        color: hoverColor || option.hover,
      },
      ':active:hover': {
        backgroundColor: background.dark2,
        color: activeColor || option.active,
      },
    },
    iconContainer: {
      flexBasis: '1.4rem',
      fontSize: '1.125rem',
      width: '0.3rem',
      display: 'flex',
      flexShrink: 0,
      justifyContent: 'center',
    },
    titleContainer: {
      whiteSpace: 'nowrap',
      fontSize: '.75rem',
      marginLeft: '.75rem',
      paddingRight: '.75rem',
    },
  });

  const Icon = icon; // to render svg as a React Component

  if (!linkTo) {
    return (
      <div className={css(styles.optionContainer)}>
        <div className={css(styles.optionLink)} onClick={click}>
          <div className={css(styles.optionWrapper)} onMouseEnter={handleHover} onMouseLeave={handleBlur} title={collapsed ? title : undefined}>
            <div className={css(styles.iconContainer)}>
              <Icon height="18px" fill={iconColor} />
            </div>
            {!collapsed && <span className={css(styles.titleContainer)}>{title}</span>}
            {<Notification collapsed={collapsed}></Notification>}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={css(styles.optionContainer)}>
      <Link to={linkTo} className={css(styles.optionLink)} id={id} onClick={click}>
        <div className={css(styles.optionWrapper)} onMouseEnter={handleHover} onMouseLeave={handleBlur} title={collapsed ? title : undefined}>
          <div className={css(styles.iconContainer)}>
            <Icon height="18px" fill={iconColor} />
          </div>
          {!collapsed && <span className={css(styles.titleContainer)}>{title}</span>}
          {<Notification collapsed={collapsed}></Notification>}
        </div>
      </Link>
    </div>
  );
};

export default Option;
