import React, { Component } from 'react';
import BranchesListEntry from './BranchesListEntry';
import { FormattedMessage } from 'react-intl';

class BranchesListView extends Component {
  render() {
    const { data, noDataText, handleSelectedBranch, addToRefs, addToRefListEntry, clickDoubleHandler } = this.props;
    let content = (
      <div className="w-full">
        <div className="pl-2">
          <div className="flex items-center justify-between text-xs uppercase text-grey py-2 border-b border-light">
            <div className="w-24 pr-2"></div>
            <div className="flex-grow items-center text-xs text-nowrap" style={{ width: '300px' }}>
              <FormattedMessage id="description" defaultMessage="Descripción" />
            </div>
            <div className="flex-grow items-center w-10 text-xs text-nowrap">
              <FormattedMessage id="number" defaultMessage="Número" />
            </div>
            <div className="flex-grow items-center w-30 text-xs text-nowrap">
              <FormattedMessage id="previousProcessDate" defaultMessage="Fecha de Proceso Anterior" />
            </div>
            <div className="flex-grow items-center ml-5 w-30 text-xs text-nowrap">
              <FormattedMessage id="processDate" defaultMessage="Fecha de Proceso" />
            </div>
            <div className="flex-grow items-center mr-3 w-30 text-xs text-nowrap">
              <FormattedMessage id="nextProcessDate" defaultMessage="Fecha de Proceso Próxima" />
            </div>
          </div>
          <div className="overflow-y-scroll" style={{ height: 480 }}>
            {data.map((item, index) => (
              <div className={` ${index % 2 && 'bg-grey-lighter-lighter'} `} key={index}>
                <BranchesListEntry
                  item={item}
                  rowID={index}
                  key={item.number}
                  name={item.number}
                  addToRefs={addToRefs}
                  clickHandler={async (item, selected) => {
                    handleSelectedBranch(item, selected);
                  }}
                  ref={(ref) => addToRefListEntry(ref)}
                  clickDoubleHandler={clickDoubleHandler}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );

    if (data.length === 0) {
      content = (
        <div className="w-full flex items-center">
          <div className="flex-grow text-center">{noDataText}</div>
        </div>
      );
    }

    return content;
  }
}

export default BranchesListView;
