import React from 'react';
import { useIntl } from 'react-intl';

import './FilterDropdown.scss';

interface DropdownProps {
  children: JSX.Element;
}

export function FilterDropdown({ children }: DropdownProps): JSX.Element {
  const intl = useIntl();

  return (
    <main className="w-full">
      <section className="shadow row">
        <div className="tabs">
          <div className="border-b tab">
            <div className="border-l-2 border-transparent relative">
              <input className="w-full absolute z-10 cursor-pointer opacity-0 h-5 top-6" type="checkbox" id="chck1" style={{ height: '48px' }} />
              <header className="flex justify-between items-center pl-4 pr-4 pb-3 pt-3 cursor-pointer select-none tab-label">
                <span className="text-grey-darkest font-thin">{intl.formatMessage({ id: 'addFilter' })}</span>
                <div className="rounded-full border border-grey w-7 h-7 flex items-center justify-center test">
                  <svg
                    aria-hidden="true"
                    className=""
                    data-reactid="266"
                    fill="none"
                    height="24"
                    stroke="#606F7B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </div>
              </header>
              <div className="tab-content">
                <div className="pl-4 pr-4 pb-5 text-grey-darkest">
                  <div>{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
