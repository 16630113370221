import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { ABOVE_ALL } from '../constants/zIndexValues';
import Overlay from './Overlay';

export default class Dialog extends Component {
  render() {
    const style = {
      width: 400,
    };
    let { hideDialog, message, dialogTitle } = this.props;
    return (
      <div className="">
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <div
          className={`border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute p-2 w-full max-w-md m-auto flex-col flex`}
          style={{
            ...style,
            left: 150,
            top: 50,
            zIndex: ABOVE_ALL,
          }}
        >
          <div className="flex-auto text-center m-2 font-bold" style={{ fontSize: 14 }}>
            {dialogTitle}
          </div>
          <div className="flex-auto text-center" style={{ fontSize: 12 }}>
            {message}
          </div>
          <div className="text-center text-sm w-full">
            <button className="float-center bg-primary  text-white rounded shadow m-4 py-2 px-4 text-sm" onClick={hideDialog}>
              <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
