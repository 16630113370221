import React from 'react';
import { useIntl } from 'react-intl';
import { GroupDefinition } from '../../../models/groupDefinition';
import { Process } from '../../../models/process';
import { SelectedProcess } from '../../../models/types';

interface ConstantsInformationProps {
  selectedProcess: SelectedProcess;
}

export function ConstantsInformation({ selectedProcess }: ConstantsInformationProps): JSX.Element {
  const intl = useIntl();
  const constants: string[] = getConstants();

  function getConstants(): string[] {
    if ((selectedProcess as GroupDefinition).constants?.groupConstants) {
      return (selectedProcess as GroupDefinition).constants?.groupConstants;
    } else if ((selectedProcess as Process).constants) {
      return (selectedProcess as Process).constants;
    }

    return [];
  }

  function decodeConstants(constants: string[]): any[] {
    const result: string[] = [];

    constants.map((constant) => {
      let parsedConstant = constant.replace(",C;", "[%1Param1%]").replace(",P;", "[%2Param2%]");
      let splittedResult = parsedConstant.replace(/\{|\}/g, '').split(',');
      let mapResult: string[] = [];
      splittedResult.forEach(element => {
        mapResult.push(element.replace("[%1Param1%]", ",C;").replace("[%2Param2%]", ",P;");
      });
      result.push(...mapResult);
    });

    return result;
  }

  function renderRow(constant: string): JSX.Element {
    const values: string[] = constant.split('=');
    let name = values[0];
    let value = values[1];
    if(values.length > 2) {
      value += "=" + values[2];
    }

    return (
      <>
        <div className="flex-50 pl-4 font-mono text-xs">{name}</div>
        <div className="flex-50 font-mono text-xs">{value}</div>
      </>
    );
  }

  return (
    <div className="w-full constants-information-container">
      <div className="pl-2">
        <div className="flex items-center justify-between text-xs uppercase text-grey py-2 border-b border-light">
          <div className="flex-50 pr-2 pl-4">{intl.formatMessage({ id: 'name' })}</div>
          <div className="flex-50 pr-2">{intl.formatMessage({ id: 'value' })}</div>
        </div>
        <div className="constants-information">
          {decodeConstants(constants).map((item, index) => (
            <div className={` ${index % 2 && 'bg-grey-lighter-lighter'} `} key={index}>
              <div className="flex items-center justify-between border-t border-smoke py-2 h-10 cursor-pointer">{renderRow(item)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
