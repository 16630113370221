import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { useGlobalStyles } from './../../../../styles/GlobalStylesContext';
import Field from './Field';

const CurrencyInput = ({ currency, onCurrencyChange }) => {
  const intl = useIntl();

  const placeholderCurrency = intl.formatMessage({
    id: 'currency',
    defaultMessage: 'Moneda',
  });

  const styles = StyleSheet.create({
    currencyInput: {
      width: '5rem',
    },
  });

  const globalStyles = useGlobalStyles();

  return (
    <Field labelText={placeholderCurrency} htmlFor="currency">
      <NumberFormat
        type="text"
        id="currency"
        name="currency"
        className={css(
          globalStyles.input,
          globalStyles.numberInput,
          styles.currencyInput,
        )}
        placeholder={placeholderCurrency}
        value={currency}
        onChange={(event) => onCurrencyChange(event.target.value)}
        onFocus={(e) => e.target.select()}
        required
        decimalScale={0} // allow only integers
      />
    </Field>
  );
};

export default CurrencyInput;
