/* eslint-disable no-unused-vars */
import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import HelpTable from '../../../../../UI-kit/components/HelpTable';
import FocusTrap from 'react-focus-trap';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';
import { FormattedMessage } from 'react-intl';

const getHelpWindow = (helpData, helpColumns, handleAccept, handleCancel, modify, title) => {
  let value = '';
  let control = '';

  const setSelectedValue = (rowInfo) => {
    const columns = helpColumns;
    value = '';
    columns &&
      columns.forEach((column) => {
        if (column.isReturned && rowInfo.original) {
          value = rowInfo.original[column.accessor];
          control = column.control;
        }
      });
  };

  if (helpData && helpData.length > 0) {
    const styles = StyleSheet.create({
      titleContainer: {
        width: '100%',
        minHeight: '1.5rem',
        padding: '.5rem 1rem',
        color: '#6B7280',
        cursor: 'move',
        textAlign: 'left',
        flex: '1 0 auto',
        borderBottom: `1px solid #DCE0E6`,
      },
      helpWrapper: {
        backgroundColor: '#F4F5F7',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
        borderColor: 'transparent',
        borderRadius: '.25rem',
        borderWidth: 1,
        minHeight: '300px',
        minWidth: '400px',
        height: '350px',
        left: '50%',
        position: 'fixed',
        top: '50%',
        width: '500px',
        zIndex: 100,
      },
    });

    const buttonStyles = {
      width: '100%',
    };

    const onAcceptClick = () => {
      handleAccept(value, control);
    };

    return (
      <FocusTrap>
        <Draggable bounds="" handle=".handleDragg">
          <div
            className="pin border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute"
            style={{
              zIndex: 100,
              width: '500px',
              top: '30%',
              left: '30%',
              height: '350px',
            }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l text-xs handleDragg text-smoke cursor-move m-1" />
            <div className={`helpHandleDragg ${css(styles.titleContainer)}`}>{title || 'Ayuda'}</div>
            <div className="border border-solid border-green-light ml-2" style={{ width: '480px', height: '250px' }}>
              <HelpTable
                fromApp={true}
                title={'aa'}
                transactionLineLabel={'T1L2'}
                fromHelp={true}
                data={helpData && helpData.map((row, index) => ({ ...row, id: index }))}
                columns={helpColumns}
                goToLineFn={() => {}}
                onHelpSelect={(selection) => {
                  onAcceptClick(null);
                }}
                focusedTable={true}
                continue={(rowInfo) => {}}
                getTrProps={(state, rowInfo, runSelection = false) => {
                  setSelectedValue(rowInfo);
                  return {
                    onClick: (e, rowInfo) => {},
                  };
                }}
              />
            </div>
            <div className="absolute flex justify-end mt-3" style={buttonStyles}>
              <button
                autoFocus
                className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                onClick={() => {
                  if (handleAccept) {
                    handleAccept(value, control);
                  }
                }}
                onBlur={(e) => {}}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    if (handleCancel) {
                      handleCancel();
                    }
                  } else if (e.key === 76 && e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
              </button>
              <button
                className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                onClick={() => {
                  if (handleCancel) {
                    handleCancel();
                  }
                }}
                onBlur={(e) => {}}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    if (handleCancel) {
                      handleCancel();
                    }
                  } else if (e.key === 76 && e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />
              </button>
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );
  } else {
    return <div></div>;
  }
};

/* const getGridState = () => {
  const { grids, nodeId } = this.props;
  if (grids && grids[nodeId]) {
    return grids[nodeId];
  } else {
    gridInitialState();
  }
};

const gridInitialState = () => ({
  columns: [],
  rows: [],
  returnedField: '',
  numberOfReturned: 0,
  showAll: false,
  showWindow: false,
  loading: true,
  currentFilterCol: null,
  filtered: false,
  filterOptions: undefined,
}); */

const getHelpButton = (id, data, setHelpColumns, setHelpData, setShowHelp, setTitle, modify) => {
  let helpButton = null;
  if (data) {
    helpButton = (
      <button
        type="button"
        id={id + '_helpButton'}
        className="bg-smoke text-xs rounded-r-lg shadow rounded-l-none opacity-75 hover:opacity-100"
        style={{ width: 19 }}
        onClick={() => {
          setHelpColumns(data.columns);
          setHelpData(data.rows);
          setShowHelp(true);
          setTitle(data.title);
        }}
        disabled={modify !== false}
      >
        ?
      </button>
    );
  }

  return helpButton;
};

const getColumnsSucursal = (intl = null) => {
  let columns = [
    {
      Header: 'Sucursal',
      accessor: 'sucursal',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'Sucursal',
    },
    {
      Header: 'Descripción',
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsMoneda = (intl = null) => {
  let columns = [
    {
      Header: 'Moneda',
      accessor: 'moneda',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 4,
      type: 2,
      control: 'Moneda',
    },
    {
      Header: 'Descripción',
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsNumerador = () => {
  let columns = [
    {
      Header: 'Número',
      accessor: 'numero',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'Numerador',
    },
    {
      Header: 'Periodo',
      accessor: 'Periodo',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsGrupoMapa = () => {
  let columns = [
    {
      Header: 'Grupo',
      accessor: 'grupo',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'Grupo',
    },
    {
      Header: 'Comentario',
      accessor: 'comentario',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsNumeroMaquina = () => {
  let columns = [
    {
      Header: 'Numero',
      accessor: 'numero',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'NumeroDeMaquina',
    },
    {
      Header: 'Descripción',
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsImpresora = () => {
  let columns = [
    {
      Header: 'Impresoras',
      accessor: 'impresoras',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 50,
      type: 1,
      control: 'nombretopaz',
    },
    {
      Header: 'Número de Máquina',
      accessor: 'numeroDeMaquina',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
    {
      Header: 'Sucursal',
      accessor: 'sucursal',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsBitacoraSucursal = () => {
  let columns = [
    {
      Header: 'Codigo',
      accessor: 'codigo',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'sucursal',
    },
    {
      Header: 'Descripción',
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsgetOperaciones = () => {
  let columns = [
    {
      Header: 'Número de Operación',
      accessor: 'identificacion',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'operacion',
    },
    {
      Header: 'Descripción',
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsMaquina = () => {
  let columns = [
    {
      Header: 'Número',
      accessor: 'numero',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'maquina',
    },
    {
      Header: 'Sucursal',
      accessor: 'sucursal',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 2,
    },
    {
      Header: 'Descripción',
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];
  return columns;
};

const getColumnsUsuarios = () => {
  let columns = [
    {
      Header: 'Clave',
      accessor: 'clave',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
      control: 'usuario',
    },
    {
      Header: 'Iniciales',
      accessor: 'iniciales',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: 'Nombre',
      accessor: 'nombre',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsIniciales = () => {
  let columns = [
    {
      Header: 'Iniciales',
      accessor: 'iniciales',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
      control: 'iniciales',
    },
    {
      Header: 'Clave',
      accessor: 'clave',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: 'Nombre',
      accessor: 'nombre',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];
  return columns;
};

const getColumnsInicialesMod = () => {
  let columns = [
    {
      Header: 'Iniciales',
      accessor: 'iniciales',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
      control: 'inicialesmodificador',
    },
    {
      Header: 'Clave',
      accessor: 'clave',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: 'Nombre',
      accessor: 'nombre',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
  ];
  return columns;
};

const getColumnsGrupo = () => {
  let columns = [
    {
      Header: 'Grupo',
      accessor: 'grupo',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
      control: 'grupo',
    },
    {
      Header: 'Descripción',
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsDescriptor = () => {
  let columns = [
    {
      Header: 'Descriptor',
      accessor: 'descriptor',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'descriptor',
    },
    {
      Header: 'Descripción',
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 20,
      type: 1,
    },
  ];

  return columns;
};

const getColumnsCanal = () => {
  let columns = [
    {
      Header: 'Descripción',
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: false,
      isSortable: true,
      length: 50,
      type: 1,
    },
    {
      Header: 'Codigo',
      accessor: 'codigo',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
      control: 'codigo',
    },
  ];

  return columns;
};

export {
  getHelpButton,
  getHelpWindow,
  getColumnsSucursal,
  getColumnsNumeroMaquina,
  getColumnsImpresora,
  getColumnsGrupoMapa,
  getColumnsNumerador,
  getColumnsMoneda,
};
