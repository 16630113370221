import React from 'react';
import Field from './components/Field';
import { FormattedMessage, useIntl } from 'react-intl';
import { store } from '../../../src/store';

const SearchSection = ({ findText }) => {
  const intl = useIntl();
  const labelText = intl.formatMessage({
    id: 'text',
    defaultMessage: 'Texto: ',
  });

  return (
    <div style={{ width: '25%', height: '30px' }}>
      <div style={{ width: '25%' }}>
        <Field htmlFor="searchText" labelText={labelText}>
          <input
            className="focus:shadow-sm-light ml-2"
            id="searchTexto"
            type="text"
            style={{
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: 'rgb(220, 224, 230)',
              borderRadius: '2px',
            }}
          />
        </Field>
      </div>
      <input id="inicio" type="checkbox" />
      <label htmlFor="desde" className="inline-block text-sm ml-1 mb-2">
        <FormattedMessage id="desdeInicio" defaultMessage={`Desde el inicio`} />
      </label>
      <button
        className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
        onClick={() => {
          let value = document.getElementById('searchTexto') && document.getElementById('searchTexto').value;
          let inicio = document.getElementById('inicio') && document.getElementById('inicio').checked;
          if (value && value.trim() !== '') {
            let index = store.getState().bitacoraApp.indexBusqueda;
            if (index === undefined) {
              index = -1;
            }
            index++;
            if (inicio && inicio === true) {
              document.getElementById('inicio').checked = false;
              findText(value, -1);
            } else {
              findText(value, index);
            }
          }
        }}
      >
        <FormattedMessage id="search" defaultMessage={`Buscar`} />
      </button>
    </div>
  );
};

export default SearchSection;
