import * as React from 'react';
import { GroupInstance } from '../../../models/groupInstance';
import { InstanceHeaders } from '../ExecutionHistoryViewModel';

import './ExecutionHistoryMenu.scss';
import { ExecutionHistoryMenuView } from './ExecutionHistoryMenuView';

export interface GroupToTree {
  key: string;
  label: string;
  nodes: GroupToTree[];
  state?: string;
  item?: GroupInstance;
}

interface ExecutionHistoryMenuViewModelProps {
  instanceHeaders: InstanceHeaders[];
  onSelectInstance: (instance: GroupInstance) => void;
  selectedInstance?: GroupInstance;
  onRefreshInstanceChange: (instanceToRefresh: GroupInstance) => void;
  onModalMonitoringChange: (isOpen: boolean) => void;
  onStopInstance: (instanceToStop: GroupInstance) => void;
  onResumeInstance: (instanceToResume: GroupInstance) => void;
}

export function ExecutionHistoryMenuViewModel({
  instanceHeaders,
  onSelectInstance,
  selectedInstance,
  onRefreshInstanceChange,
  onModalMonitoringChange,
  onStopInstance,
  onResumeInstance,
}: ExecutionHistoryMenuViewModelProps) {
  let index = 0;
  const treeData: GroupToTree[] = instanceHeaders ? mapNamesToTree(instanceHeaders) : [];

  function mapNamesToTree(groupNodes: InstanceHeaders[]): GroupToTree[] {
    const mappedGroup: GroupToTree[] = groupNodes.map((group: InstanceHeaders) => {
      index++;

      return {
        key: group.groupName + index,
        label: group.groupName,
        state: '',
        nodes: group.instances ? mapInstancesToTree(group.instances) : [],
      };
    });

    return mappedGroup;
  }

  function mapInstancesToTree(instances: GroupInstance[]): GroupToTree[] {
    const mappedInstances: GroupToTree[] = instances.map((instance) => {
      index++;

      return {
        key: instance.executionId.toString() + index,
        label: instance.executionId.toString(),
        state: instance.state,
        item: instance,
        nodes: instance.processInstances ? mapProcessInstancesToTree(instance.processInstances) : [],
      };
    });

    return mappedInstances;
  }

  function mapProcessInstancesToTree(processInstances: GroupInstance[]): GroupToTree[] {
    const mappedInstances: GroupToTree[] = processInstances.map((instance) => {
      index++;

      return {
        key: instance.executionId.toString() + index,
        label: instance.name,
        state: instance.state,
        item: instance,
        nodes: instance.processInstances ? mapProcessInstancesToTree(instance.processInstances) : [],
      };
    });

    return mappedInstances;
  }

  return (
    <ExecutionHistoryMenuView
      onStopInstance={onStopInstance}
      onResumeInstance={onResumeInstance}
      selectedInstance={selectedInstance}
      treeData={treeData}
      onSelectInstance={onSelectInstance}
      onRefreshInstanceChange={onRefreshInstanceChange}
      onModalMonitoringChange={onModalMonitoringChange}
    />
  );
}
