import React, { useEffect } from 'react';

import './SecurityTools.scss';
import { SelectedGroup } from '../../models/types';
import removeIcon from '../../icons/remove';
import refreshIcon from '../../icons/refresh';
import expandIcon from '../../icons/expand';
import searchIcon from '../../icons/search';
import {
  ADD_GROUP_MODAL,
  CONFIGURATIONS_MODAL,
  PRINT_MODAL,
  REMOVE_MODAL,
  SEARCH_MODAL,
  TYPE_GROUP,
  TYPE_USER,
  USER_MODAL,
} from '../../models/constants';
import { useIntl } from 'react-intl';
import addUserIcon from '../../icons/addUser';
import groupIcon from '../../icons/group';
import printIcon from '../../icons/print';
import modifyIcon from '../../icons/modify';
import configurationsIcon from '../../icons/configurations';

interface SecurityToolsViewProps {
  selectedGroup?: SelectedGroup;
  onModalChange: (modalOpen: string) => void;
  onRefresh: () => void;
  onExpand: () => void;
  onIsEditingChange: (isEditing: boolean) => void;
  modalOpen: string;
  hasUserPermissions: boolean;
  hasGroupPermissions: boolean;
  isEditing: boolean;
  onChangesWarningChange: (isOpen: boolean, undoChanges: boolean) => void;
  onIsWarningToEditChange: (isEdit: boolean) => void;
  isLoadingGroup: boolean;
}

export function SecurityToolsView({
  onModalChange,
  onRefresh,
  onExpand,
  selectedGroup,
  onIsEditingChange,
  modalOpen,
  hasGroupPermissions,
  hasUserPermissions,
  isEditing,
  onChangesWarningChange,
  onIsWarningToEditChange,
  isLoadingGroup
}: SecurityToolsViewProps): JSX.Element {
  const intl = useIntl();

  function onRemoveClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(REMOVE_MODAL);
  }

  function onConfigClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(CONFIGURATIONS_MODAL);
  }

  function onModifyClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onIsEditingChange(true);
    if (selectedGroup?.type === TYPE_USER) {
      onModalChange(USER_MODAL);
    } else if (selectedGroup?.type === TYPE_GROUP) {
      onModalChange(ADD_GROUP_MODAL);
    }
  }

  function onRefreshClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onRefresh();
  }

  function onExpandClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onExpand();
  }

  function onSearchClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(SEARCH_MODAL);
  }

  function onAddUserClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onIsEditingChange(false);
    onModalChange(USER_MODAL);
  }

  function onCreateGroupClick(event: React.MouseEvent): void {
    event.stopPropagation();
    if (modalOpen === ADD_GROUP_MODAL || modalOpen === USER_MODAL) {
      onIsWarningToEditChange(false);
      onChangesWarningChange(true, null);
    } else {
      onIsEditingChange(false);
      onModalChange(ADD_GROUP_MODAL);
    }
  }

  function onPrintClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(PRINT_MODAL);
  }

  return (
    <div className="security-tools-container" style={{ pointerEvents: isLoadingGroup ? 'none' : undefined, opacity: isLoadingGroup ? '0.5' : undefined }}>
      <fieldset className="fieldset w-full flex justify-center items-center border border-grey-lighter m-2 rounded-lg">
        <legend className="ml-5">{intl.formatMessage({ id: 'tools' })}</legend>
        <div className="justify-center mt-2 mb-2">
          <button
            disabled={
              !selectedGroup || selectedGroup.type === TYPE_USER || modalOpen === USER_MODAL || !hasGroupPermissions || modalOpen === PRINT_MODAL
            }
            title={intl.formatMessage({ id: 'createGroup' })}
            onClick={onCreateGroupClick}
            className="tool mr-3 icon"
          >
            {groupIcon}
          </button>
          <button
            disabled={
              !selectedGroup || selectedGroup.type === TYPE_USER || modalOpen === USER_MODAL || !hasUserPermissions || modalOpen === PRINT_MODAL
            }
            title={intl.formatMessage({ id: 'addUser' })}
            onClick={onAddUserClick}
            className="tool mr-3"
          >
            {addUserIcon}
          </button>
          <button
            disabled={
              isEditing ||
              !selectedGroup ||
              (selectedGroup.type === TYPE_USER && !hasUserPermissions) ||
              (selectedGroup.type === TYPE_GROUP && !hasGroupPermissions) ||
              modalOpen === PRINT_MODAL
            }
            title={intl.formatMessage({ id: 'modify' })}
            onClick={onModifyClick}
            className="tool mr-3"
          >
            {modifyIcon}
          </button>
          <button
            disabled={!selectedGroup || modalOpen === PRINT_MODAL}
            title={intl.formatMessage({ id: 'remove' })}
            onClick={onRemoveClick}
            className="tool mr-3"
          >
            {removeIcon}
          </button>
          <button title={intl.formatMessage({ id: 'expand' })} onClick={onExpandClick} className="tool mr-3">
            {expandIcon}
          </button>
          <button title={intl.formatMessage({ id: 'update' })} disabled={modalOpen === PRINT_MODAL} onClick={onRefreshClick} className="tool mr-3">
            {refreshIcon}
          </button>
          <button title={intl.formatMessage({ id: 'search' })} onClick={onSearchClick} className="tool mr-3">
            {searchIcon}
          </button>
          <button title={intl.formatMessage({ id: 'print' })} onClick={onPrintClick} className="tool mr-3">
            {printIcon}
          </button>
          <button title={intl.formatMessage({ id: 'configuration' })} onClick={onConfigClick} className="tool mr-3">
            {configurationsIcon}
          </button>
        </div>
      </fieldset>
    </div>
  );
}
