import { store } from '../../../store';
import { getBitacoraRestricciones, getBitacoraRestriccionesCount } from '../../../api/bitacora';
import { formatDateForServer } from './filterFields';
import { getButtonsState } from './paginado';

const getBitacoraRestriccionesFilterResult = (filterParams, filterListID, intl, setDisabledButtonPrev, setDisabledButtonNext, setLoading) => {
  let {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    grupo,
    inicialesmodificador,
    bMenu,
    bOperaciones,
    bReportes,
    bPermisos,
    bSucursales,
    bNavegaciones,
    codigorestriccion,
    tipodecambio,
    persistenceUnit,
  } = filterParams;

  let filterData = {};
  filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
  store.setState({
    bitacoraApp: {
      ...store.getState().bitacoraApp,
      ...filterData,
    },
  });

  let defaultValue;
  fechaHoraDesde = formatDateForServer(fechaHoraDesde);
  fechaHoraHasta = formatDateForServer(fechaHoraHasta);
  firstResult = firstResult || 0;
  maxResults = maxResults || 201;
  grupo = grupo || defaultValue;
  bMenu = bMenu || defaultValue;
  bOperaciones = bOperaciones || defaultValue;
  bReportes = bReportes || defaultValue;
  bPermisos = bPermisos || defaultValue;
  bSucursales = bSucursales || defaultValue;
  bNavegaciones = bNavegaciones || defaultValue;
  codigorestriccion = codigorestriccion || defaultValue;
  tipodecambio = tipodecambio || defaultValue;
  persistenceUnit = persistenceUnit || 'Local';
  let usuario = inicialesmodificador || defaultValue;

  const params = {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    grupo,
    usuario,
    bMenu,
    bOperaciones,
    bReportes,
    bPermisos,
    bSucursales,
    bNavegaciones,
    codigorestriccion,
    tipodecambio,
    persistenceUnit,
  };

  const resultColumns = [
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_fechahora',
        defaultMessage: 'Fecha Hora',
      }),
      accessor: 'fecha_hora_modificado',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 4,
    },
    {
      Header: intl.formatMessage({
        id: 'group',
        defaultMessage: 'Grupo',
      }),
      accessor: 'grupo_codigo',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 50,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Restricciones.tipoRestriccion',
        defaultMessage: 'Tipo Restricción',
      }),
      accessor: 'tipo_restriccion',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Restricciones.identificacion',
        defaultMessage: 'Identificación Restricción',
      }),
      accessor: 'identificacion_restriccion',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Restricciones.descripcionIdentificacion',
        defaultMessage: 'Descripcion Restricción',
      }),
      accessor: 'descripcion_restriccion',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Restricciones.tipoCambio',
        defaultMessage: 'Tipo de Cambio',
      }),
      accessor: 'tipodecambio',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_Usuario_Modificador',
        defaultMessage: 'Usuario Modificados',
      }),
      accessor: 'clave_modificador',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'inicialesmodificador',
        defaultMessage: 'Iniciales Modificador',
      }),
      accessor: 'inicialesModificador',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_Nombre_Modificador',
        defaultMessage: 'Nombre Modificador',
      }),
      accessor: 'nombreModificador',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: 'Sucursal Modificador',
      accessor: 'sucursalModificador',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_usuariored',
        defaultMessage: 'Usuario de Red',
      }),
      accessor: 'usuarioRed',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_mac_address',
        defaultMessage: 'Mac Address',
      }),
      accessor: 'macaddress',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_ip',
        defaultMessage: 'Dirección IP',
      }),
      accessor: 'direccionip',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
  ];

  getBitacoraRestriccionesCount(params)
    .then((countData) => {
      getBitacoraRestricciones(params)
        .then((data) => {
          let buttonNextState, buttonPrevState;
          [buttonNextState, buttonPrevState] = getButtonsState(countData, firstResult);
          let filterData = {};
          filterData[filterListID] = {
            ...store.getState().bitacoraApp[filterListID],
            resultColumns,
            resultData: data,
            currentPage: Math.ceil(firstResult / maxResults) + 1,
            pageCount: Math.ceil(countData / maxResults),
            resultCount: countData,
            buttonNextState,
            buttonPrevState,
          };
          setDisabledButtonNext(buttonNextState);
          setDisabledButtonPrev(buttonPrevState);
          store.setState({
            bitacoraApp: {
              ...store.getState().bitacoraApp,
              ...filterData,
            },
          });
          if (setLoading) {
            setLoading(false);
          }
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          console.log(err);
        });
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
      console.log(err);
    });
};

export { getBitacoraRestriccionesFilterResult };
