import * as React from 'react';
import { Select, SelectData } from '../../select/Select';
import { useIntl } from 'react-intl';

interface HistoryFilterOptionsViewProps {
  options: SelectData[];
  onSelectedChange: (state: string) => void;
  selectedState: string;
}

export function HistoryFilterOptionsView({ options, onSelectedChange, selectedState }: HistoryFilterOptionsViewProps): JSX.Element {
  const intl = useIntl();

  return (
    <div className="py-4 flex border-t justify-between">
      <div className="text-sm">
        <label className="mr-1">{intl.formatMessage({ id: 'history' }) + ' ' + intl.formatMessage({ id: 'since' })}:</label>
        <Select
          className="w-auto rounded border border-gray-300 shadow-sm placeholder-gray-500 placeholder-opacity-50 mt-2 rounded-full text-center h-10"
          selectedState={selectedState}
          options={options}
          onSelectedChange={onSelectedChange}
        />
      </div>
    </div>
  );
}
