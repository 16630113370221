import React, { Component } from 'react';
import FocusTrap from 'react-focus-trap';
import Draggable from 'react-draggable';
import { FormattedMessage } from 'react-intl';
import Overlay from '../../../UI-kit/components/Overlay';
import DatePicker from 'react-datepicker';
import IcoMoon from 'react-icomoon';
import ModalMessage from '../../components/ModalMessage';

import { formatDateFromServer, dateRunFormat } from '../../../UI-kit/helpers/dateHelper';

export class BranchChangeDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processDate: null,
      nextProcessDate: null,
      previousProcessDate: null,
      lastProcessDate: null,
      description: '',
      setModalData: {},
    };
  }

  componentDidMount() {
    let { item } = this.props;
    let processDate = null;
    let previousProcessDate = null;
    let lastProcessDate = null;
    let nextProcessDate = null;

    this.handleBranchName(item.description);

    if (item.processDate !== null) {
      processDate = new Date(formatDateFromServer(item.processDate));
      this.handleProcessDate(processDate);
    }

    if (item.previousProcessDate !== null) {
      previousProcessDate = new Date(formatDateFromServer(item.previousProcessDate));
      this.handlePreviousProcessDate(previousProcessDate);
    }

    if (item.lastProcessDate !== null) {
      lastProcessDate = new Date(formatDateFromServer(item.lastProcessDate));
      this.handleLastProcessDate(lastProcessDate);
    }

    if (item.nextProcessDate !== null) {
      nextProcessDate = new Date(formatDateFromServer(item.nextProcessDate));
      this.handleNextProcessDate(nextProcessDate);
    }
  }

  cancelHandler() {
    let { setShowChangeDateState } = this.props;
    setShowChangeDateState(false);
  }

  buildBody() {
    let { item } = this.props;
    let number = item.number;
    let previousProcessDate = dateRunFormat(this.state.previousProcessDate);
    let processDate = dateRunFormat(this.state.processDate);
    let nextProcessDate = dateRunFormat(this.state.nextProcessDate);
    let lastProcessDate = dateRunFormat(this.state.lastProcessDate);
    let description = this.state.description;
    let status = item.status;
    let empresa = item.empresa;
    let sucursalEmpresa = item.sucursalEmpresa;
    if (description !== '') {
      let body = {
        number,
        previousProcessDate,
        processDate,
        nextProcessDate,
        lastProcessDate,
        description,
        status,
        empresa,
        sucursalEmpresa,
      };
      return body;
    } else {
      throw new Error('No se puede dejar la descripcion vacia');
    }
  }

  async acceptHandler() {
    try {
      let { updateBranch, setShowChangeDateState } = this.props;
      let body = this.buildBody();
      updateBranch(body);
      setShowChangeDateState(false);
    } catch {
      this.setState({ setModalData: { showDialog: true, textDialog: 'Debe llenar todos los campos' } });
    }
  }

  handleProcessDate(date) {
    this.setState({ processDate: date });
  }

  handleNextProcessDate(date) {
    this.setState({ nextProcessDate: date });
  }

  handlePreviousProcessDate(date) {
    this.setState({ previousProcessDate: date });
  }

  handleLastProcessDate(date) {
    this.setState({ lastProcessDate: date });
  }

  handleBranchName(name) {
    this.setState({ description: name });
  }

  render() {
    return (
      <FocusTrap>
        <Overlay style={{ zIndex: 300, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className="border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute"
            style={{
              zIndex: 300,
              height: 400,
              width: 450,
              top: 150,
              left: 400,
            }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move" style={{ padding: 1.5 }} />
            <div className="ml-5 mt-5 text-left">
              <div className="border-2 mr-5">
                <div
                  className="ml-5 text-left"
                  style={{
                    height: 50,
                  }}
                >
                  <label className="ml-2 my-5 text-sm">
                    <FormattedMessage id="previousProcessDate" defaultMessage="Fecha de proceso anterior:" />
                  </label>
                  <DatePicker
                    className="border-2 w-24"
                    wrapperClassName={`w-2 rounded-sm text-sm my-5 ml-2 `}
                    onChange={(date) => {
                      this.handlePreviousProcessDate(date);
                    }}
                    selected={this.state.previousProcessDate}
                    dateFormat={'dd/MM/yyyy'}
                  />
                </div>
                <div
                  className="ml-5 text-left"
                  style={{
                    height: 50,
                  }}
                >
                  <label className="ml-2 my-3 text-sm">
                    <FormattedMessage id="processDate" defaultMessage="Fecha de proceso:" />
                  </label>
                  <DatePicker
                    className="border-2 w-24"
                    onChange={(date) => {
                      this.handleProcessDate(date);
                    }}
                    wrapperClassName={`w-2 rounded-sm text-sm my-3 ml-18`}
                    selected={this.state.processDate}
                    dateFormat={'dd/MM/yyyy'}
                  />
                </div>
                <div
                  className="ml-5 text-left"
                  style={{
                    height: 50,
                  }}
                >
                  <label className="ml-2 my-2 text-sm">
                    <FormattedMessage id="nextProcessDate" defaultMessage="Fecha de próximo proceso:" />
                  </label>
                  <DatePicker
                    className="border-2 w-24"
                    onChange={(date) => {
                      this.handleNextProcessDate(date);
                    }}
                    wrapperClassName={`w-2 rounded-sm text-sm my-2 ml-2`}
                    selected={this.state.nextProcessDate}
                    dateFormat={'dd/MM/yyyy'}
                  />
                </div>
                <div
                  className="ml-5 text-left"
                  style={{
                    height: 50,
                  }}
                >
                  <label className="ml-2 my-1 text-sm">
                    <FormattedMessage id="lastProcessDate" defaultMessage="Fecha de último proceso:" />
                  </label>
                  <DatePicker
                    className="border-2 w-24"
                    onChange={(date) => {
                      this.handleLastProcessDate(date);
                    }}
                    wrapperClassName={`w-2 rounded-sm text-sm my-1 ml-5`}
                    selected={this.state.lastProcessDate}
                    dateFormat={'dd/MM/yyyy'}
                  />
                </div>
              </div>
              <div className="border-2 mr-5 mt-5">
                <div className="ml-20 mt-5 text-left  w-full">
                  <label className="ml-2 my-1 w-full text-sm">
                    <FormattedMessage id="branchName" defaultMessage="Nombre de la Sucursal" />
                  </label>
                </div>
                <div className="ml-10 mt-2 text-left mb-5">
                  <input
                    style={{
                      width: '300px',
                    }}
                    maxLength={25}
                    className="text-left appearance-none mr-1 px-1 rounded shadow text-sm w-full"
                    type="text"
                    value={this.state.description}
                    onChange={(event) => {
                      this.handleBranchName(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={`mt-5`}>
                <button
                  className={`text-white ml-32 rounded mt-32 m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                  style={{
                    marginTop: '0rem',
                  }}
                  onClick={() => {
                    this.acceptHandler();
                  }}
                >
                  <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
                </button>
                <button
                  style={{
                    marginTop: '0rem',
                  }}
                  className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                  onClick={() => {
                    this.cancelHandler();
                  }}
                >
                  <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />
                </button>
              </div>
            </div>
            {this.state.setModalData.showDialog && (
              <ModalMessage
                message={this.state.setModalData.textDialog}
                handleAccept={async () => {
                  this.setState({ setModalData: { showDialog: false } });
                }}
                acceptTitle={'Aceptar'}
                draggable={true}
              />
            )}
          </div>
        </Draggable>
      </FocusTrap>
    );
  }
}
