import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';

/* return example
    {
        "logLevel": 6,
        "lines": 1000,
        "filter": null,
        "enabled": false
    }
*/
export let getLogConfig = () => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let headers = { 'Content-Type': 'application/json' };

  return axios.get(`${API}TopazWebClientWS/resources/log/client/operation/config?sessionId=${session}`, { headers }).then(({ data }) => data);
};

export let setLogConfig = (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let headers = { 'Content-Type': 'application/json' };

  return axios.put(`${API}TopazWebClientWS/resources/log/client/operation/config?sessionId=${session}`, body, { headers }).then(({ data }) => data);
};

/* return example
    {
        "lines": []
    } 
*/
export let getLogs = (operationID) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let headers = { 'Content-Type': 'application/json' };

  return axios
    .get(`${API}TopazWebClientWS/resources/log/client/operation?sessionId=${session}&operationID=${operationID}`, { headers })
    .then(({ data }) => data);
};
