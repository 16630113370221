import React, { useState } from 'react';
import { useTheme } from '../../themes/ThemeContext';
import { StyleSheet, css } from 'aphrodite';
// import ThemeButton from '../../themes/ThemeButton';
import Logo from './LogoView';
import MenuButton from './MenuButtonView';
import LogoutButton from './LogoutButtonView';
import ToolbarAutomaticTest from '../Testing/ToolbarAutomaticTest';
import { store } from '../../store';

const SidebarView = (props) => {
  const { isCollapsed, onClickMenuButton, options, logout } = props;

  /********
   * Styles
   */
  const themeState = useTheme();
  const { background } = themeState.theme;
  const [testActivated, setTestActivated] = useState(false);
  const styles = StyleSheet.create({
    sidebarContainer: {
      backgroundColor: background.background,
      display: 'flex',
      flexDirection: 'column',
      width: isCollapsed ? '4rem' : '15rem',
      transition: '0.2s ease',
    },
  });

  let clientProperties = store.getState().clientProperties;
  let testGenerator = clientProperties?.testGenerator;

  document.addEventListener('keypress', function (e) {
    if (e.ctrlKey && e.shiftKey && e.code === 'KeyF') {
      if (testGenerator === 'true') {
        if (testActivated) {
          setTestActivated(false);
        } else {
          setTestActivated(true);
        }
      }
    }
  });

  return (
    <React.Fragment>
      <div className={css(styles.sidebarContainer)}>
        <Logo isCollapsed={isCollapsed} />
        <MenuButton onClick={onClickMenuButton} isCollapsed={isCollapsed} />
        {options}
        {testActivated && <ToolbarAutomaticTest isCollapsed={isCollapsed} />}
        {/* <ThemeButton /> */}
        <LogoutButton isCollapsed={isCollapsed} onClick={logout} />
      </div>
    </React.Fragment>
  );
};

export default SidebarView;
