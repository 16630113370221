import React from 'react';
import { getReport } from '../../api/report';
import { useIntl } from 'react-intl';
import { css, StyleSheet } from 'aphrodite';
import { useTheme } from '../../themes/ThemeContext';
import { URL_BASE_REPORTS } from './Constants';
import { storeActions } from '../../store';
import { connect } from 'unistore/react';

const GenerateExcelButton = ({ body, addReportData }) => {
  const intl = useIntl();
  const downloadExcelText = intl.formatMessage({
    id: 'downloadExcel',
    defaultMessage: 'Descargar Excel',
  });

  const generatingFile = intl.formatMessage({
    id: 'generatingFile',
    defaultMessage: 'Generando archivo...',
  });

  const [textButton, setTextButton] = React.useState(downloadExcelText);
  const [excelGenerated, setExcelGenerated] = React.useState(false);
  const [fileNameExcel, setFileNameExcel] = React.useState();
  const buttonForDowload = React.useRef(null);

  const handleClick = async () => {
    if (!excelGenerated) {
      await generateExcel();
    }
    buttonForDowload.current.click();
  };

  const generateExcel = async () => {
    const bodyToExcel = {
      ...body,
      destinoWeb: 2,
    };

    try {
      setTextButton(generatingFile);
      const report = await getReport(bodyToExcel);
      if (report.name && (report.fileExtensionExcel || report.fileExtension)) {
        const { name, fileExtensionExcel, fileExtension } = report;

        if (fileExtensionExcel || fileExtension) {
          setFileNameExcel(`${name}.${fileExtensionExcel || fileExtension}`);
          const reportData = {
            nameExcel: name,
            fileExtensionExcel: fileExtensionExcel || fileExtension,
          };

          addReportData(reportData);
          setExcelGenerated(true);
        }
      }
    } catch (err) {
      console.error(err);
      setExcelGenerated(false);
    } finally {
      setTextButton(downloadExcelText);
    }
  };

  const themeState = useTheme();
  const { primary, color } = themeState.theme;

  const styles = StyleSheet.create({
    button: {
      border: '1px',
      backgroundColor: primary.primary,
      borderRadius: '.25rem',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
      padding: '.5rem 3rem',
      color: color.white,
      marginTop: '.5rem',
    },
  });

  return (
    <React.Fragment>
      <a
        ref={buttonForDowload}
        href={`${URL_BASE_REPORTS}${fileNameExcel}`}
        download={fileNameExcel}
        rel="noopener noreferrer"
        target="_blank"
        style={{ display: 'none' }}
      ></a>
      <button className={css(styles.button)} onClick={handleClick}>
        {textButton}
      </button>
    </React.Fragment>
  );
};

export default connect(null, storeActions)(GenerateExcelButton);
