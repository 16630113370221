import React, { Component } from 'react';
import { includes, isArray } from 'busyman';
import { PulseLoader } from 'halogenium';
import { connect } from 'unistore/react';

import { storeActions, store, dispatchActions } from '../../src/store';
import HelpTable from './HelpTable';
import Displayable from './Displayable';
import FilterWindow from './FilterWindow';
import { FormattedMessage } from 'react-intl';

import { getColumns, getRowsV2 } from '../helpers/gridHelper';
import { getParentDialogCount, getIterationCountFromLineIDString, compareTLId } from '../helpers/operationHelper';
import { gotoLineWithBody, run, undo } from '../../src/api/dialog';
import { createSimpleBody, createBodyGoToLine, createRunBody } from '../../renderer/lib/bodyCreators';

import { ABOVE_ALL } from '../constants/zIndexValues';
import { getTabInformation, getTabIdByNodeId } from '../../src/selectors';
import ExportToExcelWindow from './ExportToExcelWindow';
import { addLineTest, addLineEventTest } from '../../src/components/Testing/HandleLineTest';
import { canReceiveFocus } from '../../renderer/lib/focusManager';

const EmptyData = ({ title, style, close }) => {
  return (
    <div
      tabIndex="0"
      autoFocus
      style={style}
      className="bg-white text-center flex items-center justify-center h-full w-100"
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          close();
        }
      }}
    >
      <div>
        <p className="text-text-base" style={{ fontSize: 12 }}>
          {title}
        </p>
      </div>
    </div>
  );
};

const gridInitialState = () => ({
  columns: [],
  rows: [],
  returnedField: '',
  numberOfReturned: 0,
  showAll: false,
  showWindow: false,
  showWindowExcel: false,
  loading: false,
  currentFilterCol: null,
  filtered: false,
  filterOptions: undefined,
});

const DONT_USE_FIELD_NUMBER = 1;

export class GridTable extends Component {
  constructor(props) {
    super(props);

    this.addFilter = this.addFilter.bind(this);
    this.toggleWindow = this.toggleWindow.bind(this);
    this.setFetchDataState = this.setFetchDataState.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onKeyDownNoData = this.onKeyDownNoData.bind(this);

    this.gridDOMElement = React.createRef();
    this.buttonAddFilter = React.createRef();
    this.buttonRemoveFilter = React.createRef();
    this.checkBoxShowAll = React.createRef();

    this.state = {
      dataLoaded: false,
      loadingData: false,
      title: '',
      currentFetchDataCount: 0,
      gridState: gridInitialState(),
      dataFetchHadError: false,
      dataFetchErrorCount: 0,
      lastFocusElementWhenReloaded: false,
    };
  }

  onKeyDownNoData(e) {
    const {
      processContext,
      operationID,
      operationNumber,
      transactionNumber,
      lineNumber,
      transactionLineLabel,
      optional,
      focusElement,
      nodeId,
    } = this.props;

    let state = store.getState();
    const tabId = getTabIdByNodeId(state, nodeId);
    const { tabIndex } = getTabInformation(state, tabId);
    const currentTab = state.tabs[tabIndex];
    if (
      this.state.gridState.loading ||
      !this.state.dataLoaded ||
      this.state.dataFetchHadError ||
      focusElement !== transactionLineLabel ||
      currentTab.apiLoading
    ) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
      if (optional) {
        if (e.fromBlur) return;
        this.props.setApiLoading(true, tabId);
        let bodyRun = createRunBody(operationNumber, transactionLineLabel, '');
        run(operationID, transactionNumber, lineNumber, bodyRun)
          .then((res) => {
            this.props.setApiLoading(true, tabId);
            processContext(res, 'Grid Run').finally(() => {
              this.props.setApiLoading(false, tabId);
            });
          })
          .finally(() => {
            this.props.setApiLoading(false, tabId);
          });
      } else {
        const required = <FormattedMessage id="errorMandatoryField" defaultMessage={`es de ingreso obligatorio`} />;
        const msg = (
          <div>
            {'Grilla'} {required}
          </div>
        );
        store.setState(dispatchActions.showModalMessage(store.getState(), msg));
      }
    } else if (e.key === 'Escape' || (e.key === 'Tab' && e.shiftKey)) {
      undo(operationID, transactionNumber, lineNumber).then((res) => {
        store.setState(dispatchActions.reverseFlow(store.getState()));
        processContext(res, 'undo grid');
        store.setState(dispatchActions.reverseFlow(store.getState(), false));
      });
    }
  }

  updateGridState(changes) {
    this.setState({ gridState: { ...this.state.gridState, ...changes } });
  }

  addFilter(filterOptions) {
    if (filterOptions.field === '') {
      filterOptions.field = this.state.gridState.columns[0].accessor;
    }
    this.fetchData(false, filterOptions, undefined, true);

    let { removeFromWindowStack, transactionLineLabel } = this.props;
    removeFromWindowStack(transactionLineLabel);
    this.toggleWindow();
  }

  toggleWindow(col, fromKeyboard = false, keyPressed = undefined, flagExcel) {
    this.updateGridState({
      showWindow: !this.state.gridState.showWindow,
      currentFilterCol: col,
      fromKeyboard: fromKeyboard,
      keyPressed: keyPressed,
    });
  }

  toggleWindowExcel(col, fromKeyboard = false, keyPressed = undefined, flagExcel) {
    this.updateGridState({
      showWindow: false,
      showWindowExcel: flagExcel,
      currentFilterCol: col,
      fromKeyboard: fromKeyboard,
      keyPressed: keyPressed,
    });
  }

  setFetchDataState(changeShowAll) {
    if (changeShowAll) {
      this.updateGridState({
        showAll: !this.state.gridState.showAll,
      });
    }
  }

  async fetchData(changeShowAll = true, filterOptions, valueSeted, dontResetFilter = false) {
    let {
      operationID,
      numberOfRecords = 100,
      fieldNumber,
      transactionLineLabel,
      focusElement,
      fromHelp,
      optional,
      multipleSelection,
      readOnly,
      tabId,
    } = this.props;
    if (readOnly) {
      return;
    }
    if (this.props.setApiLoading) {
      this.props.setApiLoading(true, tabId);
    }
    const fetchDataCount = this.state.currentFetchDataCount + 1;
    let gridState = fromHelp ? this.state.gridState : gridInitialState();
    this.updateGridState(gridState);
    if (!dontResetFilter && (filterOptions === '-1' || !filterOptions)) {
      if (filterOptions === '-1' || !this.props.inputValue) {
        filterOptions = null;
      } else {
        filterOptions = {
          field: '-1',
          operator: '-1',
          value: this.props.inputValue,
        };
      }
    }
    this.setState({ currentFetchDataCount: fetchDataCount, dataLoaded: false });
    this.updateGridState({ filterOptions });
    getColumns(operationID, fieldNumber, filterOptions).then((res) => {
      if (fetchDataCount !== this.state.currentFetchDataCount) {
        if (this.props.setApiLoading) {
          this.props.setApiLoading(false, tabId);
        }
        return;
      }
      let isSortable = Object.keys(res).map((key) => {
        if (res[key] && res[key].isSortable === true) {
          if (this.props.setApiLoading) {
            this.props.setApiLoading(false, tabId);
          }
          return {
            columnNumber: res[key],
          };
        }
      });

      let currentShowAll = this.state.gridState.showAll;
      if (changeShowAll) {
        currentShowAll = !currentShowAll;
      }
      const { columns: currentColumns } = this.state.gridState;

      let newShowAll = currentShowAll !== res.showAll && currentColumns.length === 0 ? res.showAll : currentShowAll;
      this.updateGridState({
        title: res.title,
        columns: res.columns,
        returnedField: res.returned,
        numberOfReturned: res.numberOfReturned,
        isSortable: isSortable,
        showAll: newShowAll,
        isOptions: res.isOptions,
        filtered: res.filterOptions !== null && res.filterOptions !== undefined,
        helpSize: res.helpSize,
        filterOptions: res.filterOptions,
      });

      if (transactionLineLabel === focusElement || fromHelp || valueSeted) {
        this.setState({ loadingData: true });
        getRowsV2(
          res.helpSessionId,
          res.helpSize || numberOfRecords,
          res.accesors,
          fieldNumber,
          this.state.gridState.showAll,
          res.filterOptions,
          res.types,
          res.isOptions,
        )
          .then((res) => {
            if (fetchDataCount !== this.state.currentFetchDataCount) {
              if (this.props.setApiLoading) {
                this.props.setApiLoading(false, tabId);
              }
              return;
            }
            const currentState = this.state.gridState;
            let { helpSize } = currentState;
            if (res.rows.length === helpSize) {
              Object.keys(res.rows[helpSize - 1]).forEach(function (key) {
                res.rows[helpSize - 1][key] = '...';
              });
            }
            this.updateGridState({
              rows: res.rows,
              loading: false,
            });
            currentState.rows = res.rows;
            currentState.loading = false;
            currentState.filterOptions = res.filterOptions;
            this.props.updateGridData(this.props.nodeId, currentState, this.props.nodeId);

            if (res.rows.length === 0) {
              this.props.onChange({ target: { value: null } });
            } else if (optional && multipleSelection) {
              this.props.onChange({ target: { value: [] } });
            } else {
              const state = store.getState();
              const tabId = getTabIdByNodeId(state, this.props.nodeId);
              const { tabIndex } = getTabInformation(state, tabId);
              const selIdx =
                state.tabs[tabIndex].running.selectedIndexes && state.tabs[tabIndex].running.selectedIndexes[this.props.transactionLineLabel];
              let row = res.rows[selIdx && res.rows[selIdx] ? selIdx : 0];
              let rowsToUpdate = [];
              const currentState = this.state.gridState;
              let { columns, isOptions } = currentState;
              let firstRow = [];
              columns.forEach((column) => {
                if (column.isReturned) {
                  firstRow.push(row[column.accessor]);
                }
              });
              const firstRowCheck = isOptions ? firstRow[0] : firstRow;
              this.props.onChange({
                target: {
                  value: multipleSelection ? rowsToUpdate : firstRowCheck,
                },
              });
            }
            this.setState({ dataLoaded: true, loadingData: false, dataFetchErrorCount: 0 });
          })
          .catch((e) => {
            if (this.state.dataFetchErrorCount > 2) {
              this.setState({ dataFetchHadError: false, dataFetchErrorCount: 0 });
            } else {
              this.setState({ dataFetchHadError: true, dataFetchErrorCount: this.state.dataFetchErrorCount + 1 });
            }
          })
          .finally(() => {
            this.updateGridState({ loading: false });
            if (this.props.setApiLoading) {
              this.props.setApiLoading(false, tabId);
            }
          });
      } else {
        this.updateGridState({
          loading: false,
        });
        if (this.props.setApiLoading) {
          this.props.setApiLoading(false, tabId);
        }
      }
    });
  }

  gotoLine = async (body, opId, transactionNumber, lineNumber) => {
    const { operationNumber, fieldData, transactionLineLabel, focusElement } = this.props;
    const bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
    const res = await gotoLineWithBody(opId, transactionNumber, lineNumber, bodyGoToLine);
    this.props.processContext(res, 'gotoLine grid input');
  };

  componentDidMount() {
    let { inputValue, fromHelp, valueSetElement, transactionLineLabel, focusElement, lastFocusElement } = this.props;
    let valueSeted = includes(valueSetElement, transactionLineLabel);
    let filterOptions = inputValue
      ? {
          field: '-1',
          operator: '-1',
          value: inputValue,
        }
      : null;
    const storeGridState = this.props.grids[this.props.nodeId];
    const currentState = storeGridState || this.state.gridState;
    let columns;
    if (currentState.columns) {
      columns = currentState.columns;
    }
    if (currentState) {
      this.updateGridState(currentState);
    }
    const willValidationToFetchPassOnCompDidUpdate =
      transactionLineLabel === focusElement &&
      this.state.lastFocusElementWhenReloaded !== lastFocusElement &&
      lastFocusElement &&
      focusElement &&
      compareTLId(lastFocusElement, focusElement) > 0;

    let state = store.getState();
    const tabId = getTabIdByNodeId(state, this.props.nodeId);
    const { tabIndex } = getTabInformation(state, tabId);
    const currentTab = state.tabs[tabIndex];
    if (
      transactionLineLabel === focusElement &&
      ((!valueSeted && !columns.length) || fromHelp) &&
      !willValidationToFetchPassOnCompDidUpdate &&
      !currentTab.apiLoading &&
      !state.apiLoading
    ) {
      this.fetchData(false, filterOptions, valueSeted);
    }
  }

  componentWillUnmount() {
    if (this.state.showAll) {
      this.updateGridState({
        showAll: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    let { transactionLineLabel, fromHelp, componentState, nodeId } = this.props;
    let state = store.getState();
    const tabId = getTabIdByNodeId(state, nodeId);
    const { tabIndex, focusElement, lastFocusElement } = getTabInformation(state, tabId);
    if (tabIndex === state.current) {
      const currentTab = state.tabs[tabIndex];
      if (
        this.state.lastFocusElementWhenReloaded !== lastFocusElement &&
        compareTLId(lastFocusElement, focusElement) < 0 &&
        currentTab &&
        currentTab.running.stateByLineID[focusElement] &&
        !currentTab.running.stateByLineID[focusElement].isDialog
      ) {
        this.setState({ lastFocusElementWhenReloaded: lastFocusElement });
      }

      let valueSeted = includes(currentTab.running.valueSetElement, transactionLineLabel);
      const currentlyFocused = transactionLineLabel === focusElement;
      const goingForward =
        this.state.lastFocusElementWhenReloaded !== lastFocusElement &&
        lastFocusElement &&
        focusElement &&
        compareTLId(lastFocusElement, focusElement) > 0;
      const startedShowingHelp = prevProps.showingHelp !== this.props.showingHelp && this.props.showingHelp;
      const propValueChanged = prevProps.inputValue !== this.props.inputValue;
      const propValueDifferentFromFilter =
        this.state.gridState.filterOptions &&
        !this.state.gridState.filterOptions.fromFilter &&
        this.state.gridState.filterOptions.value !== this.props.inputValue &&
        this.props.inputValue;

      const modalShowing = document.getElementById('modalTitleText_' + tabId);
      if (
        (valueSeted && !fromHelp) ||
        (currentlyFocused &&
          propValueChanged &&
          (prevProps.showingHelp === this.props.showingHelp || startedShowingHelp) &&
          !this.state.dataFetchHadError) ||
        (startedShowingHelp &&
          this.state.gridState &&
          !this.state.gridState.filterOptions &&
          (this.props.inputValue || this.state.gridState.rows.length === 0)) ||
        propValueDifferentFromFilter ||
        (this.state.dataFetchHadError && this.props.isFocused) ||
        (currentlyFocused && goingForward && !modalShowing && !currentTab.apiLoading && !state.apiLoading)
      ) {
        this.setState({ dataFetchHadError: false, lastFocusElementWhenReloaded: lastFocusElement });
        this.fetchData(false, null, valueSeted);

        let valueSetElement = currentTab.running.valueSetElement;
        valueSetElement = valueSetElement.filter((e) => {
          return e !== transactionLineLabel;
        });

        state = store.getState();
        state.tabs[tabIndex].running.valueSetElement = valueSetElement;
        store.setState(state);
      }

      if (focusElement && prevProps.focusElement !== focusElement && currentTab) {
        const transactionLineLabelCount = getIterationCountFromLineIDString(transactionLineLabel);
        let currentDiagCount = getParentDialogCount(currentTab.running.stateByLineID, null, null, transactionLineLabel);
        if (!fromHelp && transactionLineLabelCount === currentDiagCount) {
          if (this.gridDOMElement && this.gridDOMElement.current && this.gridDOMElement.current.focus && focusElement !== '') {
            this.gridDOMElement.current.focus();
          }
        }
        if (componentState && componentState.hide && !fromHelp && (Array.isArray(componentState.value) ? componentState.value.length > 0 : true)) {
          this.props.onChange({ target: { value: [] } });
        }
      }
    }
  }

  fillValuesForMultipleSelection = (rowInfo) => {
    const { columns } = this.state.gridState;
    const values = [];

    rowInfo.original.forEach((rowElement) => {
      const valuesRow = [];
      columns.forEach((column) => {
        if (column.isReturned && rowElement) {
          valuesRow.push(rowElement[column.accessor]);
        }
      });
      values.push(valuesRow);
    });
    return values;
  };

  fillValuesForSingleSelection = (rowInfo) => {
    const { columns } = this.state.gridState;
    const values = [];

    columns &&
      columns.forEach((column) => {
        if (column.isReturned && rowInfo.original) {
          values.push(rowInfo.original[column.accessor]);
        }
      });

    return values;
  };

  createSelectedValue = (rowInfo) => {
    let value;
    const currentState = this.state.gridState;

    const { multipleSelection } = this.props;

    if (currentState.numberOfReturned !== DONT_USE_FIELD_NUMBER) {
      let valuesToReturn = [];
      if (multipleSelection) {
        valuesToReturn = this.fillValuesForMultipleSelection(rowInfo);
      } else {
        valuesToReturn = this.fillValuesForSingleSelection(rowInfo);
      }
      value = valuesToReturn;
    } else {
      if (multipleSelection) {
        value = this.fillValuesForMultipleSelection(rowInfo);
      } else if (rowInfo.original) {
        value = rowInfo.original[currentState.returnedField];
      }
    }
    return value;
  };

  continue = async (rowInfo) => {
    const { fromHelp, operationNumber, transactionLineLabel, operationID, transactionNumber, lineNumber, processContext, nodeId } = this.props;
    const value = this.createSelectedValue(rowInfo);
    this.props.onChange({ target: { value } });

    let state = store.getState();
    const tabId = getTabIdByNodeId(state, nodeId);
    const { tabIndex } = getTabInformation(state, tabId);
    const currentTab = state.tabs[tabIndex];

    if (!fromHelp && !currentTab.apiLoading) {
      let body = createSimpleBody(operationNumber, transactionLineLabel, value);
      processContext(await run(operationID, transactionNumber, lineNumber, body), 'Continue for Run Grid');
    }
  };

  getFocusElement = () => {
    const { nodeId } = this.props;
    const state = store.getState();
    const tabId = getTabIdByNodeId(state, nodeId);
    const { focusElement = '' } = getTabInformation(state, tabId);
    return focusElement;
  };

  getContent() {
    const noDataMessage = (
      <FormattedMessage id="noDataGrid" defaultMessage={`No se encontraron datos. Si es una ventana de ayuda, pruebe Quitar Filtro`} />
    );
    let {
      style,
      lineNumber,
      transactionNumber,
      operationID,
      operationNumber,
      transactionLineLabel,
      processContext,
      pushToWindowStack,
      nodeId,
      fromHelp,
      onHelpSelect,
      cancelHandlerHelp,
      multipleSelection,
      focusedTable,
      optional,
      acceptButtonRef,
      focusPaused,
    } = this.props;

    const { loadingData } = this.state;

    const currentState = this.state.gridState;
    let { rows, columns } = currentState;

    if (((columns && columns.length === 0) || (rows && !rows.length)) && fromHelp) {
      acceptButtonRef && acceptButtonRef.current && acceptButtonRef.current.focus();
      return <EmptyData title={noDataMessage} style={style} close={cancelHandlerHelp} />;
    }
    const divStyle = fromHelp ? style : { height: 'inherit' };
    const focusElement = this.getFocusElement();

    const focused = focusedTable || (transactionLineLabel === focusElement && focusElement !== '');

    const { title } = this.state;

    const didValueChange = (currentValue, fieldDataValue) => {
      let valueChanged = false;
      if (isArray(currentValue)) {
        if (JSON.stringify(currentValue) !== JSON.stringify(fieldDataValue)) {
          valueChanged = true;
        }
      } else if (currentValue !== fieldDataValue) {
        valueChanged = true;
      }

      return valueChanged;
    };

    return (
      <div className={`flex border border-smoke rounded`} style={divStyle}>
        <HelpTable
          title={title}
          onKeyDownNoData={this.onKeyDownNoData}
          transactionLineLabel={transactionLineLabel}
          ref={this.gridDOMElement}
          data={rows && rows.map((row, index) => ({ ...row, id: index }))}
          loadingData={loadingData}
          columns={columns}
          fromHelp={fromHelp}
          onHelpSelect={onHelpSelect}
          cancelHandlerHelp={cancelHandlerHelp}
          focusedTable={focused}
          focusPaused={focusPaused}
          multipleSelection={multipleSelection}
          optional={optional}
          focusElement={focusElement}
          nodeId={nodeId}
          showingHelp={this.props.showingHelp}
          openFilter={(col, fromKeyboard = false, keyPressed = null) => {
            pushToWindowStack(transactionLineLabel);
            this.toggleWindow(col, fromKeyboard, keyPressed, false);
          }}
          openExcel={(row, fromKeyboard = false, keyPressed = null) => {
            this.toggleWindowExcel(row, fromKeyboard, keyPressed, true);
          }}
          handleUndo={async () => {
            let undoRes = await undo(operationID, transactionNumber, lineNumber);
            store.setState(dispatchActions.reverseFlow(store.getState()));

            processContext(undoRes, 'undo grid input');
            store.setState(dispatchActions.reverseFlow(store.getState(), false));
          }}
          goToLineFn={async (e) => {
            if (canReceiveFocus(e) && transactionLineLabel !== focusElement && (this.props.showingHelp || !fromHelp)) {
              const res = await this.gotoLine({}, operationID, transactionNumber, lineNumber);
              processContext(res, 'gotoline grid input');
            }
          }}
          continue={this.continue}
          getTrProps={(state, rowInfo, runSelection = false) => {
            if (typeof rowInfo !== 'undefined') {
              return {
                onClick: async (e, handleOriginal) => {
                  const value = this.createSelectedValue(rowInfo);
                  let valueChanged = true;
                  if (this.props.fieldData && this.props.fieldData[this.props.transactionLineLabel]) {
                    valueChanged = didValueChange(value, this.props.fieldData[this.props.transactionLineLabel].value);
                  }
                  let state = store.getState();
                  const tabId = getTabIdByNodeId(state, nodeId);
                  const { tabIndex } = getTabInformation(state, tabId);
                  const currentTab = state.tabs[tabIndex];

                  if (valueChanged) {
                    this.props.onChange({ target: { value } });
                  }
                  if (!fromHelp && !currentTab.apiLoading) {
                    if (runSelection) {
                      const body = createSimpleBody(operationNumber, transactionLineLabel, value);
                      const runRes = await run(operationID, transactionNumber, lineNumber, body);
                      processContext(runRes, 'run grid input');
                    }
                  }
                },
              };
            } else {
              return {
                onClick: (e, handleOriginal) => {
                  if (handleOriginal) {
                    handleOriginal();
                  }
                },
              };
            }
          }}
        />
      </div>
    );
  }

  render() {
    let { componentState, position, fromHelp, style, pushToWindowStack, transactionLineLabel, removeFromWindowStack, cancelHandlerHelp } = this.props;

    const buttonsStyle = {
      zIndex: ABOVE_ALL,
    };

    const currentState = this.state.gridState;

    const loader = (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: style.height,
          width: style.width,
        }}
      >
        <PulseLoader className="text-primary" color="currentColor" size="20px" />
      </div>
    );

    if (!currentState) return null;
    const gridContent = currentState.loading ? loader : this.getContent();
    const isFiltered = currentState.filtered;

    return (
      <Displayable componentState={componentState} position={position}>
        {gridContent}
        <div id="divGrid" style={buttonsStyle} className={`${fromHelp && !currentState.loading ? '' : 'invisible'} absolute flex`}>
          <button
            id={'gridAddFilterButton_' + transactionLineLabel}
            className="text-white rounded shadow m-2 py-1 px-2 text-xs  bg-grey focus:outline-none focus:bg-primary hover:bg-primary"
            onClick={(e) => {
              addLineTest('CLICK', e);
              pushToWindowStack(transactionLineLabel);
              this.toggleWindow();
            }}
            ref={this.buttonAddFilter}
            onBlur={(e) => {
              if (e.relatedTarget === null || e.relatedTarget.classList.contains('focus-trap') || e.relatedTarget.classList.contains('contorno')) {
                this.buttonAddFilter.current.focus();
              }
            }}
            onKeyDown={(e) => {
              addLineEventTest(e);
              if (e.key === 'Escape') {
                cancelHandlerHelp();
              } else if (e.key === 76 && e.ctrlKey) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            <FormattedMessage id="addFilter" defaultMessage={`Agregar Filtro`} />
          </button>
          <button
            id={'gridRemoveFilterButton_' + transactionLineLabel}
            className={`${
              !isFiltered ? '' : 'hover:bg-primary'
            } text-white rounded shadow m-2 py-1 px-2 text-xs  bg-grey focus:outline-none focus:bg-primary`}
            onClick={(e) => {
              addLineTest('CLICK', e);
              let filterOptions = '-1';
              this.fetchData(false, filterOptions);
            }}
            onKeyDown={(e) => {
              addLineEventTest(e);
              if (e.key === 'Escape') {
                cancelHandlerHelp();
              } else if (e.key === 76 && e.ctrlKey) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            ref={this.buttonRemoveFilter}
            disabled={!isFiltered}
            onBlur={(e) => {
              if (e.relatedTarget === null || e.relatedTarget.classList.contains('focus-trap') || e.relatedTarget.classList.contains('contorno')) {
                this.buttonRemoveFilter.current.focus();
              }
            }}
          >
            <FormattedMessage id="removeFilter" defaultMessage={`Quitar Filtro`} />
          </button>
          <div className="text-text-base m-3 text-xs">
            <FormattedMessage id="showAll" defaultMessage={`Mostrar Todos`} />
            <input
              id={'gridShowAllCheck_' + transactionLineLabel}
              className="ml-1 focus:shadow-sm-light"
              type="checkbox"
              checked={currentState.showAll}
              onChange={(e) => {
                addLineTest('SENDTEXT', e);
                let { fromHelp, valueSetElement, transactionLineLabel } = this.props;
                let valueSeted = includes(valueSetElement, transactionLineLabel);
                const currentState = this.state.gridState;
                let filterOptions = currentState.filterOptions;
                let columns;
                if (currentState.columns) {
                  columns = currentState.columns;
                }
                if ((!valueSeted && !columns.length) || fromHelp) {
                  this.fetchData(true, filterOptions, valueSeted, true);
                } else {
                  this.fetchData();
                }
              }}
              ref={this.checkBoxShowAll}
              onBlur={(e) => {
                if (e.relatedTarget === null || e.relatedTarget.classList.contains('focus-trap') || e.relatedTarget.classList.contains('contorno')) {
                  this.checkBoxShowAll.current.focus();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  addLineEventTest(e);
                  cancelHandlerHelp();
                }
              }}
            />
          </div>
        </div>
        {currentState.showWindow && (
          <FilterWindow
            id="filterWindow"
            showWindow={currentState.showWindow}
            defaultColumn={currentState.currentFilterCol}
            cancelHandler={() => {
              removeFromWindowStack(transactionLineLabel);
              this.toggleWindow();
            }}
            fromKeyboard={currentState.fromKeyboard}
            acceptHandler={this.addFilter}
            fields={currentState.columns}
            transactionLineLabel={transactionLineLabel}
            defaultValue={currentState.fromKeyboard ? currentState.keyPressed : ''}
          />
        )}
        {currentState.showWindowExcel && (
          <ExportToExcelWindow
            id="exportToExcelWindow"
            showWindow={currentState.showWindowExcel}
            defaultColumn={currentState.currentFilterCol}
            cancelHandler={() => {
              removeFromWindowStack(transactionLineLabel);
              this.toggleWindowExcel();
            }}
            fromKeyboard={currentState.fromKeyboard}
            exportarExcel={this.exportExcel}
            fields={currentState.columns}
            currentState={currentState}
            transactionLineLabel={transactionLineLabel}
            defaultValue={currentState.fromKeyboard ? currentState.keyPressed : ''}
          />
        )}
      </Displayable>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { nodeId } = ownProps;
  const tabId = getTabIdByNodeId(state, nodeId);
  const { operationID, operationNumber, operationData, focusPaused, focusElement, lastFocusElement } = getTabInformation(state, tabId);

  const { grids, valueSetElement } = operationData;
  return {
    operationID,
    operationNumber,
    focusElement,
    grids,
    focusPaused,
    valueSetElement,
    lastFocusElement,
    tabId,
  };
};

export default connect(mapStateToProps, storeActions)(GridTable);
