import React, { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useTheme } from '../../themes/ThemeContext';
import FullLogo from './../../resources/logo/logo_default_r2.png';
import LogoCollapsed from './../../resources/logo/isologo_default.png';
import { getImagesFromServer } from '../../api/session';

const Logo = ({ isCollapsed }) => {
  const [customLogo, setCustomLogo] = useState(FullLogo);
  const [customLogoCollapsed, setCustomLogoCollapsed] = useState(LogoCollapsed);
  const themeState = useTheme();
  const { primary } = themeState.theme;
  const styles = StyleSheet.create({
    logoContainer: {
      paddingTop: '1rem',
      textAlign: 'left',
      marginLeft: '.75rem',
      marginBottom: '.75rem',
      height: '3rem',
    },
    collapsedLogoLink: {
      display: 'inline-block',
      verticalAlign: 'middle',
      color: primary.primary,
    },
    collapsedLogoImage: {
      height: '2.5rem',
    },
    fullLogoLink: {
      display: 'inline-block',
      textAlign: 'right',
      color: primary.primary,
    },
    fullLogoImage: {
      display: 'block',
      height: '3rem',
      objectFit: 'cover',
      objectPosition: '0 0',
      maxWidth: '180px',
    },
  });

  useEffect(() => {
    loadImagesLogo();
  }, []);

  const loadImagesLogo = async () => {
    let imageLogo = await getImagesFromServer('logo.png');
    setCustomLogo(imageLogo.image ? 'data:image/png;base64, ' + imageLogo.image : FullLogo);
    let imageLogoCollapsed = await getImagesFromServer('logocollapsed.png');
    setCustomLogoCollapsed(imageLogoCollapsed.image ? 'data:image/png;base64, ' + imageLogoCollapsed.image : LogoCollapsed);
  };

  return (
    <div className={css(styles.logoContainer)}>
      <span title="Topaz" className={css(isCollapsed ? styles.collapsedLogoLink : styles.fullLogoLink)}>
        <img src={isCollapsed ? customLogoCollapsed : customLogo} className={css(isCollapsed ? styles.collapsedLogoImage : styles.fullLogoImage)} />
      </span>
    </div>
  );
};

export default Logo;
