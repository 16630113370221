import React, { useState, useEffect } from 'react';

import { SecurityManagerView } from './SecurityManagerView';
import { getSecurityGroups, getUsersBasicInfo, hasPermissions, removeGroup, removeUser } from '../../api/securityManager';
import { GroupsAsTreeMenu } from './models/groupsAsTreeMenu';
import { SecurityGroup } from './models/securityGroup';
import { UserBasicInfo } from './models/userBasicInfo';
import { SelectedGroup } from './models/types';
import { TYPE_GROUP, TYPE_USER, USER_GROUP_INDEX, USER_NAME_INDEX, USER_INITIALS_INDEX, USER_USERNAME_INDEX, EMPTY_STRING } from './models/constants';
import { addUserDetailsAsync } from './hooks/addUserDetailsAsync.hook';

const GROUP_PERMISSION: string = 'GROUP';
const USER_PERMISSION: string = 'USER';

export function SecurityManagerViewModel(): JSX.Element {
  const [securityGroups, setSecurityGroups] = useState<GroupsAsTreeMenu[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<SelectedGroup>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [hasGroupPermissions, setHasGroupPermissions] = useState<boolean>(false);
  const [hasUserPermissions, setHasUserPermissions] = useState<boolean>(false);

  async function loadPermissionsAsync(): Promise<void> {
    setIsLoading(true);
    const [permissionGroup, permissionUser]: boolean = await Promise.all<boolean, boolean>([
      hasPermissions(GROUP_PERMISSION),
      hasPermissions(USER_PERMISSION),
    ]);
    setIsLoading(false);
    setHasGroupPermissions(permissionGroup);
    setHasUserPermissions(permissionUser);
  }

  async function getSecurityGroupsDataAsync(): Promise<void> {
    const response: SecurityGroup[] = await getSecurityGroups();
    const users: [][] = await getUsersBasicInfo();
    setIsLoading(false);
    const usersToObjects: UserBasicInfo[] = mapUsersToObjects(users);
    const responseToTree: GroupsAsTreeMenu[] = mapGroupsAsTree(response, usersToObjects);
    setSecurityGroups(responseToTree);
  }

  function mapUsersToObjects(users: [][]): UserBasicInfo[] {
    const usersObject: UserBasicInfo[] = users.map((user) => {
      return {
        name: user[USER_NAME_INDEX],
        initials: user[USER_INITIALS_INDEX],
        group: user[USER_GROUP_INDEX],
        username: user[USER_USERNAME_INDEX],
        type: TYPE_USER,
      };
    });

    return usersObject;
  }

  function mapGroupsAsTree(groups: SecurityGroup[], users: UserBasicInfo[]): GroupsAsTreeMenu[] {
    const baseGroups: SecurityGroup[] = groups.filter((group) => {
      return !group.baseGroup;
    });

    const baseGroupsAndSubGroups: GroupsAsTreeMenu[] = baseGroups.map((group) => {
      return {
        group: group.group,
        description: group.description,
        subGroups: mapSubGroups(group.group),
        users: mapUsersToGroup(group.group),
        baseGroup: EMPTY_STRING,
        name: group.group,
        type: TYPE_GROUP,
      };
    });

    function mapSubGroups(groupName: string): GroupsAsTreeMenu[] {
      const subGroups: SecurityGroup[] = groups.filter((group) => {
        return group.baseGroup === groupName;
      });

      const subGroupsToTree: GroupsAsTreeMenu[] = subGroups.map((group) => {
        return {
          group: group.group,
          description: group.description,
          subGroups: mapSubGroups(group.group),
          users: mapUsersToGroup(group.group),
          baseGroup: groupName,
          name: group.group,
          type: TYPE_GROUP,
        };
      });

      return subGroupsToTree;
    }

    function mapUsersToGroup(groupName: string) {
      const usersToGroup = users.filter((user) => {
        return user.group === groupName;
      });

      return usersToGroup;
    }

    return baseGroupsAndSubGroups;
  }

  function onSelectGroup(group: SelectedGroup): void {
    setSelectedGroup(group);
  }

  async function onRefresh(): Promise<void> {
    setIsLoading(true);
    setIsLoading(false);
    updateRefresh();
  }

  function updateRefresh(): void {
    setRefresh((currentRefresh) => !currentRefresh);
  }

  async function removeGroupAsync(group: GroupsAsTreeMenu): Promise<void> {
    await removeGroup(group);
    onRefresh();
  }

  async function removeUserAsync(user: UserBasicInfo): Promise<void> {
    const userWithDetails: UserBasicInfo = await addUserDetailsAsync(user);
    await removeUser(userWithDetails);
    onRefresh();
  }

  useEffect(() => {
    setIsLoading(true);
    getSecurityGroupsDataAsync();
    loadPermissionsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <SecurityManagerView
      hasGroupPermissions={hasGroupPermissions}
      hasUserPermissions={hasUserPermissions}
      selectedGroup={selectedGroup}
      onSelectGroup={onSelectGroup}
      securityGroups={securityGroups}
      isLoading={isLoading}
      onRefresh={onRefresh}
      removeGroupAsync={removeGroupAsync}
      removeUserAsync={removeUserAsync}
    />
  );
}
