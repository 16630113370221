import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { PulseLoader } from 'halogenium';
import { useTheme } from '../../themes/ThemeContext';
import { useIntl } from 'react-intl';
import ModalMessageGlobal from '../ModalMessageGlobal';

const ChangePasswordView = (props) => {
  const { inputs, isFirstPassword, onInputChange, onSubmit, loading, error, onCancel } = props;

  /*
   * Styles
   */
  const themeState = useTheme();
  const { color, primary, background, border, text, error: errorColor } = themeState.theme;
  const styles = StyleSheet.create({
    container: {},
    inputFormContainer: {
      marginBottom: '1.5rem',
    },
    inputForm: {
      width: '100%',
      padding: '.65rem',
      borderWidth: 2,
      backgroundColor: background.background,
      borderColor: border.border,
      color: text.text,
      borderRadius: 4,
      transition: '.2s',
      ':hover': {
        backgroundColor: background.dark,
        borderColor: border.dark,
      },
      ':focus': {
        backgroundColor: background.light,
        borderColor: primary.primary,
      },
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonAccept: {
      color: color.white,
      backgroundColor: primary.primary,
      padding: '.75rem 0',
      width: '100%',
      marginBottom: '.5rem',
      borderRadius: 4,
      boxShadow: '0 2px 0px 0 #00151F',
      transition: '.2',
      ':hover': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.light,
      },
      ':focus': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.light,
      },
      ':active': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.dark,
      },
    },
    buttonCancel: {
      color: text.text,
      padding: '.75rem 0',
      width: '100%',
      margin: '.25rem 0',
      borderWidth: '.5px',
      borderColor: 'transparent',
      borderRadius: 4,
      transition: '.2',
      ':hover': {
        color: errorColor.error,
        textDecoration: 'underline',
      },
    },
    footer: {
      marginTop: '.2rem',
    },
    errorMessage: {
      color: errorColor.error,
      textAlign: 'center',
      wordWrap: 'break-word',
    },
  });

  /**
   * Texts
   */
  const intl = useIntl();
  const placeholderCurrentPassword = intl.formatMessage({
    id: 'currentPassword',
    defaultMessage: 'Contraseña actual',
  });
  const placeholderNewPassword = intl.formatMessage({
    id: 'newPassword',
    defaultMessage: 'Nueva contraseña',
  });
  const placeholderConfirmPassword = intl.formatMessage({
    id: 'confirmPassword',
    defaultMessage: 'Confirmar nueva contraseña',
  });
  const acceptButtonText = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });
  const cancelButtonText = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  // const formRef = React.useRef(null);

  // if (formRef && formRef.current) {
  //   if (formRef.current.addEventListener) {
  //     formRef.current.addEventListener(
  //       'submit',
  //       function (evt) {
  //         evt.preventDefault();
  //       },
  //       true,
  //     );
  //   } else {
  //     formRef.current.attachEvent('onsubmit', function (evt) {
  //       evt.preventDefault();
  //     });
  //   }
  // }

  return (
    <div className={css(styles.container)}>
      <ModalMessageGlobal />
      <form onSubmit={onSubmit}>
        {!isFirstPassword && (
          <div className={css(styles.inputFormContainer)}>
            <input
              className={css(styles.inputForm)}
              type="password"
              name="currentPassword"
              placeholder={placeholderCurrentPassword}
              onChange={onInputChange}
              value={inputs.currentPassword.value}
              required={inputs.currentPassword.required}
              disabled={inputs.currentPassword.disabled}
              autoFocus={inputs.currentPassword.autoFocus}
            />
          </div>
        )}
        <div className={css(styles.inputFormContainer)}>
          <input
            className={css(styles.inputForm)}
            type="password"
            name="newPassword"
            title={placeholderNewPassword}
            placeholder={placeholderNewPassword}
            value={inputs.newPassword.value}
            onChange={onInputChange}
            required={inputs.newPassword.required}
            disabled={inputs.newPassword.disabled}
            autoFocus={inputs.newPassword.autoFocus}
          />
        </div>
        <div className={css(styles.inputFormContainer)}>
          <input
            className={css(styles.inputForm)}
            type="password"
            name="confirmPassword"
            placeholder={placeholderConfirmPassword}
            title={placeholderConfirmPassword}
            value={inputs.confirmPassword.value}
            onChange={onInputChange}
            required={inputs.confirmPassword.required}
            disabled={inputs.confirmPassword.disabled}
            autoFocus={inputs.confirmPassword.autoFocus}
          />
        </div>
        <div className={css(styles.buttonsContainer)}>
          <button type="submit" className={css(styles.buttonAccept)} disabled={loading}>
            {loading ? <PulseLoader color="white" size="5px" /> : acceptButtonText}
          </button>
        </div>
      </form>
      <button onClick={onCancel} className={css(styles.buttonCancel)} disabled={loading}>
        {cancelButtonText}
      </button>
      <div className={css(styles.footer)}>{error && <p className={css(styles.errorMessage)}>{error}</p>}</div>
    </div>
  );
};

export default ChangePasswordView;
