import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useTheme } from '../../src/themes/ThemeContext';
import Draggable from 'react-draggable';
import FocusLock from 'react-focus-lock';
import '../../src/styles/managerView.scss';

// import 'bootstrap/dist/css/bootstrap.css';

const ModalMessageSearch = (props) => {
  const { dataNumerator, dataPrinter, dataNetMap, dataTopazMap, accionesModalSearch } = props;
  const [operation, setOperation] = useState('');
  const [table, setTable] = useState('');
  const [selectedValue, setSelectedValue] = useState();

  const [comboField, setComboField] = useState([
    { id: 'nromaq', text: 'Number machine(nromaq)' },
    { id: 'nombretopaz', text: 'Topaz Name (nombretopaz)' },
    { id: 'nrosucursal', text: 'Branch Number (nrosucursal)' },
    { id: 'nombrewindows', text: 'Windows name (nombrewindows)' },
    { id: 'comentario', text: 'Commentary (comentario)' },
    { id: 'tipoimpresora', text: 'Printer Type (tipoimpresora)' },
    { id: 'puerto', text: 'Port (puerto)' },
  ]);

  const themeContext = useTheme();

  const { color, commonButton, modal } = themeContext.theme;
  const styles = StyleSheet.create({
    show: {
      display: 'block',
      zIndex: 900,
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    modalMain: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxHeight: '80%',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      // minWidth: '320px',
      // maxWidth: '98vw',
      // maxHeight: '98vh',
      backgroundColor: modal.active.background,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: 'transparent',
      opacity: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.33) 0px 2px 8px',
      // alignItems: 'center',
      transition: 'border background .2s',
      padding: '.2rem',
    },
    titleContainer: {
      width: '100%',
      minHeight: '1.5rem',
      padding: '.5rem 1rem',
      color: color.grey.dark4,
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    messageContainer: {
      padding: '1.5rem 1rem',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    buttonsContainer: {
      padding: '.5rem .5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    button: {
      padding: '.5rem 1.2rem',
      marginRight: '.5rem',
      borderRadius: 4,
      display: 'block',
      backgroundColor: commonButton.default.background,
      color: commonButton.default.color,
      ':hover': {
        backgroundColor: commonButton.hover.background,
        color: commonButton.hover.color,
      },
      ':focus': {
        backgroundColor: commonButton.focus.background,
        color: commonButton.focus.color,
      },
      ':active': {
        backgroundColor: commonButton.active.background,
        color: commonButton.active.color,
      },
      transition: 'background .2s',
    },
  });

  // useEffect(() => {});
  //   const handleClick = (option) => {
  //     setSelectedValue(option);
  //   };

  // const handleDoubleClick = (value) => {
  //   handleWindowSelect(value);
  // };

  const handleClick = (option) => {
    setSelectedValue(option);
  };

  const selectDataElement = (data) => {
    let printer;
    let topazMap;
    let numerator;
    let netmap;
    if (data === 'printer') {
      printer = [
        { id: 'nromaq', text: 'Number machine(nromaq)' },
        { id: 'nombretopaz', text: 'Topaz Name (nombretopaz)' },
        { id: 'nrosucursal', text: 'Branch Number (nrosucursal)' },
        { id: 'nombrewindows', text: 'Windows name (nombrewindows)' },
        { id: 'comentario', text: 'Commentary (comentario)' },
        { id: 'tipoimpresora', text: 'Printer Type (tipoimpresora)' },
        { id: 'puerto', text: 'Port (puerto)' },
      ];
      setComboField(printer);
    } else if (data === 'topazmap') {
      topazMap = [
        { id: 'nromaq', text: 'Number machine(nromaq)' },
        { id: 'grupomapa', text: 'Map Group (grupomapa)' },
        { id: 'nrosucursal', text: 'Branch Number (nrosucursal)' },
        { id: 'path', text: 'Path (path)' },
        { id: 'comentario', text: 'Commentary (comentario)' },
      ];
      setComboField(topazMap);
    } else if (data === 'numerator') {
      numerator = [
        { id: 'numero', text: 'Number(numero)' },
        { id: 'descripcion', text: 'Description (descripcion)' },
        { id: 'inival', text: 'Initial Value (inival)' },
        { id: 'incremento', text: 'Increase (incremento)' },
        { id: 'periodo', text: 'Period (periodo)' },
        { id: 'ultimainic', text: 'Last Initialization (ultimainic)' },
        { id: 'reutilizable', text: 'Reutelizable (reutilizable)' },
        { id: 'maximo', text: 'Maximo (maximo)' },
      ];
      setComboField(numerator);
    } else if (data === 'netmap') {
      netmap = [
        { id: 'numerodemaquina', text: 'Number machine (numerodemaquina)' },
        { id: 'nrosucursal', text: 'Branch Number (nrosucursal)' },
        { id: 'descripcion', text: 'Description (descripcion)' },
        { id: 'nombredeenvio', text: 'Shipping name (nombredeenvio)' },
        { id: 'iniciodeldia', text: 'Start day (iniciodeldia)' },
        { id: 'findeldia', text: 'End day (findeldia)' },
        { id: 'usuario', text: 'User (usuario)' },
        { id: 'direccioncomm', text: 'Comm Address (direccioncomm)' },
        { id: 'nrodecaja', text: 'Cash number (nrodecaja)' },
        { id: 'direccionip', text: 'IP Address (direccionip)' },
      ];
      setComboField(netmap);
    }
  };

  const selectOperation = (data) => {
    setOperation(data);
  };

  const armoTablaNetMap = (data) => {
    if (data.length > 0) {
      setTable(
        <table id="tbl" className="table table-sm" style={{ fontSize: '11px', overflowX: 'scroll' }}>
          <thead>
            <tr>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Número sucursal
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Número de máquina
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Descripción
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Inicio del día
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Fin del día
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Nombre usuario
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Dirección COMM
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Número de caja
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Dirección IP
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Nombre de envío
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((option, index) => {
              return (
                <tr key={index}>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.nrosucursal}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.numerodemaquina}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.descripcion}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.iniciodeldia}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.findeldia}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.usuario}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.direccioncomm}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.nrodecaja}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.direccionip}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.nombredeenvio}</td>
                </tr>
              );
            })}
            <tr></tr>
          </tbody>
        </table>,
      );
    } else {
      setTable('');
    }
  };

  const armoTablaNumerator = (data) => {
    if (data.length > 0) {
      setTable(
        <table id="tbl" className="table table-sm" style={{ fontSize: '11px' }}>
          <thead>
            <tr>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Número
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Init Var
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Incremento
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Período
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Última inic
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Reutilizable
              </th>
              <th scope="col " style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Máximo
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Centralizado
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((option, index) => {
              return (
                <tr key={index}>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.numero}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.inival}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.incremento}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.periodo}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.ultimainic}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.reutilizable}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.maximo}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.centralizado}</td>
                </tr>
              );
            })}
            <tr></tr>
          </tbody>
        </table>,
      );
    } else {
      setTable('');
    }
  };

  const armoTablaTopazMap = (data) => {
    if (data.length > 0) {
      setTable(
        <table id="tbl" className="table table-sm" style={{ fontSize: '11px' }}>
          <thead>
            <tr>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Número Sucursal
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Número maquina
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Grupo Mapa
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Path
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '300px' }}>
                Comentario
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((option, index) => {
              return (
                <tr key={index}>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px', textAlign: 'right' }}>{option.nrosucursal}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px', textAlign: 'right' }}>{option.nromaq}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px', textAlign: 'right' }}>{option.grupomapa}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px', textAlign: 'left' }}>{option.path}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '300px', textAlign: 'left' }}>{option.comentario}</td>
                </tr>
              );
            })}
            <tr></tr>
          </tbody>
        </table>,
      );
    } else {
      setTable('');
    }
  };

  const armoTablaPrinters = (data) => {
    if (data.length > 0) {
      setTable(
        <table id="tbl" className="table table-sm" style={{ fontSize: '11px' }}>
          <thead>
            <tr>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Número sucursal
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Número máquina
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Nombre Topaz
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Comentario
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Nombre windows
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Tipo impresora
              </th>
              <th scope="col" style={{ border: '0.5px solid darkgrey', width: '150px' }}>
                Puerto
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((option, index) => {
              return (
                <tr
                  style={option === selectedValue ? { backgroundColor: '#9ABAF5' } : { backgroundColor: 'white' }}
                  onClick={() => handleClick(option)}
                  className={index % 2 === 0 ? 'table-active' : ''}
                  key={index}
                >
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.nrosucursal}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.nromaq}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.nombretopaz}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.comentario}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.nombrewindows}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.tipoimpresora}</td>
                  <td style={{ border: '0.5px solid darkgrey', width: '150px' }}>{option.puerto}</td>
                </tr>
              );
            })}
            <tr></tr>
          </tbody>
        </table>,
      );
    } else {
      setTable('');
    }
  };

  const search = () => {
    let slcElemento = document.getElementById('slcElemento').value;
    let slcOperation = document.getElementById('slcOperation').value;
    let field = document.getElementById('slcField').value;
    let texto = document.getElementById('txtTexto').value;
    let table2 = [];
    if (slcElemento === 'printer') {
      table2 = [];
      for (let printers = 0; printers < dataPrinter.length; printers++) {
        if (slcOperation === 'igual') {
          if (field === 'nromaq' || field === 'tipoimpresora') {
            if (dataPrinter[printers][field] === parseInt(texto)) {
              table2.push(dataPrinter[printers]);
            }
          } else {
            if (dataPrinter[printers][field] === texto) {
              table2.push(dataPrinter[printers]);
            }
          }
        } else if (slcOperation === 'distinto') {
          if (field === 'nromaq' || field === 'tipoimpresora') {
            if (dataPrinter[printers][field] !== parseInt(texto)) {
              table2.push(dataPrinter[printers]);
            }
          } else {
            if (dataPrinter[printers][field] !== texto) {
              table2.push(dataPrinter[printers]);
            }
          }
        } else if (slcOperation === 'menor') {
          if (field === 'nromaq' || field === 'tipoimpresora') {
            if (dataPrinter[printers][field] < parseInt(texto)) {
              table2.push(dataPrinter[printers]);
            }
          } else {
            if (dataPrinter[printers][field] < texto) {
              table2.push(dataPrinter[printers]);
            }
          }
        } else if (slcOperation === 'mayor') {
          if (field === 'nromaq' || field === 'tipoimpresora') {
            if (dataPrinter[printers][field] > parseInt(texto)) {
              table2.push(dataPrinter[printers]);
            }
          } else {
            if (dataPrinter[printers][field] > texto) {
              table2.push(dataPrinter[printers]);
            }
          }
        } else if (slcOperation === 'menorIgual') {
          if (field === 'nromaq' || field === 'tipoimpresora') {
            if (dataPrinter[printers][field] <= parseInt(texto)) {
              table2.push(dataPrinter[printers]);
            }
          } else {
            if (dataPrinter[printers][field] <= texto) {
              table2.push(dataPrinter[printers]);
            }
          }
        } else if (slcOperation === 'mayorIgual') {
          if (field === 'nromaq' || field === 'tipoimpresora') {
            if (dataPrinter[printers][field] >= parseInt(texto)) {
              table2.push(dataPrinter[printers]);
            }
          } else {
            if (dataPrinter[printers][field] >= texto) {
              table2.push(dataPrinter[printers]);
            }
          }
        } else if (operation === 'isNull') {
          if (String(dataPrinter[printers][field]) === '' || dataPrinter[printers][field] === null) {
            table2.push(dataPrinter[printers]);
          }
        } else if (operation === 'isNotNull') {
          if (String(dataPrinter[printers][field]) !== '') {
            table2.push(dataPrinter[printers]);
          }
        }
      }
      armoTablaPrinters(table2);
    } else if (slcElemento === 'topazmap') {
      table2 = [];
      for (let topazmap = 0; topazmap < dataTopazMap.length; topazmap++) {
        if (slcOperation === 'igual') {
          if (field === 'nromaq' || field === 'grupomapa' || field === 'nrosucursal') {
            if (dataTopazMap[topazmap][field] === parseInt(texto)) {
              table2.push(dataTopazMap[topazmap]);
            }
          } else {
            if (dataTopazMap[topazmap][field] === texto) {
              table2.push(dataTopazMap[topazmap]);
            }
          }
        } else if (slcOperation === 'distinto') {
          if (field === 'nromaq' || field === 'grupomapa' || field === 'nrosucursal') {
            if (dataTopazMap[topazmap][field] !== parseInt(texto)) {
              table2.push(dataTopazMap[topazmap]);
            }
          } else {
            if (dataTopazMap[topazmap][field] !== texto) {
              table2.push(dataTopazMap[topazmap]);
            }
          }
        } else if (slcOperation === 'menor') {
          if (field === 'nromaq' || field === 'grupomapa' || field === 'nrosucursal') {
            if (dataTopazMap[topazmap][field] < parseInt(texto)) {
              table2.push(dataTopazMap[topazmap]);
            }
          } else {
            if (dataTopazMap[topazmap][field] < texto) {
              table2.push(dataTopazMap[topazmap]);
            }
          }
        } else if (slcOperation === 'mayor') {
          if (field === 'nromaq' || field === 'grupomapa' || field === 'nrosucursal') {
            if (dataTopazMap[topazmap][field] > parseInt(texto)) {
              table2.push(dataTopazMap[topazmap]);
            }
          } else {
            if (dataTopazMap[topazmap][field] > texto) {
              table2.push(dataTopazMap[topazmap]);
            }
          }
        } else if (slcOperation === 'menorIgual') {
          if (field === 'nromaq' || field === 'grupomapa' || field === 'nrosucursal') {
            if (dataTopazMap[topazmap][field] <= parseInt(texto)) {
              table2.push(dataTopazMap[topazmap]);
            }
          } else {
            if (dataTopazMap[topazmap][field] <= texto) {
              table2.push(dataTopazMap[topazmap]);
            }
          }
        } else if (slcOperation === 'mayorIgual') {
          if (field === 'nromaq' || field === 'grupomapa' || field === 'nrosucursal') {
            if (dataTopazMap[topazmap][field] >= parseInt(texto)) {
              table2.push(dataTopazMap[topazmap]);
            }
          } else {
            if (dataTopazMap[topazmap][field] >= texto) {
              table2.push(dataTopazMap[topazmap]);
            }
          }
        } else if (operation === 'isNull') {
          if (String(dataTopazMap[topazmap][field]) === '' || dataTopazMap[topazmap][field] === null) {
            table2.push(dataTopazMap[topazmap]);
          }
        } else if (operation === 'isNotNull') {
          if (String(dataTopazMap[topazmap][field]) !== '') {
            table2.push(dataTopazMap[topazmap]);
          }
        }
      }
      armoTablaTopazMap(table2);
    } else if (slcElemento === 'numerator') {
      table2 = [];
      for (let numerator = 0; numerator < dataNumerator.length; numerator++) {
        if (slcOperation === 'igual') {
          if (
            field === 'inival' ||
            field === 'numero' ||
            field === 'incremento' ||
            field === 'reutilizable' ||
            field === 'centralizado' ||
            field === 'maximo'
          ) {
            if (dataNumerator[numerator][field] === parseInt(texto)) {
              table2.push(dataNumerator[numerator]);
            }
          } else {
            if (dataNumerator[numerator][field] === texto) {
              table2.push(dataNumerator[numerator]);
            }
          }
        } else if (slcOperation === 'distinto') {
          if (
            field === 'inival' ||
            field === 'numero' ||
            field === 'incremento' ||
            field === 'reutilizable' ||
            field === 'centralizado' ||
            field === 'maximo'
          ) {
            if (dataNumerator[numerator][field] !== parseInt(texto)) {
              table2.push(dataNumerator[numerator]);
            }
          } else {
            if (dataNumerator[numerator][field] !== texto) {
              table2.push(dataNumerator[numerator]);
            }
          }
        } else if (slcOperation === 'menor') {
          if (
            field === 'inival' ||
            field === 'numero' ||
            field === 'incremento' ||
            field === 'reutilizable' ||
            field === 'centralizado' ||
            field === 'maximo'
          ) {
            if (dataNumerator[numerator][field] < parseInt(texto)) {
              table2.push(dataNumerator[numerator]);
            }
          } else {
            if (dataNumerator[numerator][field] < texto) {
              table2.push(dataNumerator[numerator]);
            }
          }
        } else if (slcOperation === 'mayor') {
          if (
            field === 'inival' ||
            field === 'numero' ||
            field === 'incremento' ||
            field === 'reutilizable' ||
            field === 'centralizado' ||
            field === 'maximo'
          ) {
            if (dataNumerator[numerator][field] > parseInt(texto)) {
              table2.push(dataNumerator[numerator]);
            }
          } else {
            if (dataNumerator[numerator][field] > texto) {
              table2.push(dataNumerator[numerator]);
            }
          }
        } else if (slcOperation === 'menorIgual') {
          if (
            field === 'inival' ||
            field === 'numero' ||
            field === 'incremento' ||
            field === 'reutilizable' ||
            field === 'centralizado' ||
            field === 'maximo'
          ) {
            if (dataNumerator[numerator][field] <= parseInt(texto)) {
              table2.push(dataNumerator[numerator]);
            }
          } else {
            if (dataNumerator[numerator][field] <= texto) {
              table2.push(dataNumerator[numerator]);
            }
          }
        } else if (slcOperation === 'mayorIgual') {
          if (
            field === 'inival' ||
            field === 'numero' ||
            field === 'incremento' ||
            field === 'reutilizable' ||
            field === 'centralizado' ||
            field === 'maximo'
          ) {
            if (dataNumerator[numerator][field] >= parseInt(texto)) {
              table2.push(dataNumerator[numerator]);
            }
          } else {
            if (dataNumerator[numerator][field] >= texto) {
              table2.push(dataNumerator[numerator]);
            }
          }
        } else if (operation === 'isNull') {
          if (String(dataNumerator[numerator][field]) === '' || dataNumerator[numerator][field] === null) {
            table2.push(dataNumerator[numerator]);
          }
        } else if (operation === 'isNotNull') {
          if (String(dataNumerator[numerator][field]) !== '') {
            table2.push(dataNumerator[numerator]);
          }
        }
      }
      armoTablaNumerator(table2);
    } else if (slcElemento === 'netmap') {
      table2 = [];
      for (let netmap = 0; netmap < dataNetMap.length; netmap++) {
        if (slcOperation === 'igual') {
          if (field === 'nrosucursal' || field === 'numerodemaquina' || field === 'nrodecaja') {
            if (dataNetMap[netmap][field] === parseInt(texto)) {
              table2.push(dataNetMap[netmap]);
            }
          } else {
            if (dataNetMap[netmap][field] === texto) {
              table2.push(dataNetMap[netmap]);
            }
          }
        } else if (slcOperation === 'distinto') {
          if (field === 'nrosucursal' || field === 'numerodemaquina' || field === 'nrodecaja') {
            if (dataNetMap[netmap][field] !== parseInt(texto)) {
              table2.push(dataNetMap[netmap]);
            }
          } else {
            if (dataNetMap[netmap][field] !== texto) {
              table2.push(dataNetMap[netmap]);
            }
          }
        } else if (slcOperation === 'menor') {
          if (field === 'nrosucursal' || field === 'numerodemaquina' || field === 'nrodecaja') {
            if (dataNetMap[netmap][field] < parseInt(texto)) {
              table2.push(dataNetMap[netmap]);
            }
          } else {
            if (dataNetMap[netmap][field] < texto) {
              table2.push(dataNetMap[netmap]);
            }
          }
        } else if (slcOperation === 'mayor') {
          if (field === 'nrosucursal' || field === 'numerodemaquina' || field === 'nrodecaja') {
            if (dataNetMap[netmap][field] > parseInt(texto)) {
              table2.push(dataNetMap[netmap]);
            }
          } else {
            if (dataNetMap[netmap][field] > texto) {
              table2.push(dataNetMap[netmap]);
            }
          }
        } else if (slcOperation === 'menorIgual') {
          if (field === 'nrosucursal' || field === 'numerodemaquina' || field === 'nrodecaja') {
            if (dataNetMap[netmap][field] <= parseInt(texto)) {
              table2.push(dataNetMap[netmap]);
            }
          } else {
            if (dataNetMap[netmap][field] <= texto) {
              table2.push(dataNetMap[netmap]);
            }
          }
        } else if (slcOperation === 'mayorIgual') {
          if (field === 'nrosucursal' || field === 'numerodemaquina' || field === 'nrodecaja') {
            if (dataNetMap[netmap][field] >= parseInt(texto)) {
              table2.push(dataNetMap[netmap]);
            }
          } else {
            if (dataNetMap[netmap][field] >= texto) {
              table2.push(dataNetMap[netmap]);
            }
          }
        } else if (operation === 'isNull') {
          if (String(dataNetMap[netmap][field]) === '' || dataNetMap[netmap][field] === null) {
            table2.push(dataNetMap[netmap]);
          }
        } else if (operation === 'isNotNull') {
          if (String(dataNetMap[netmap][field]) !== '') {
            table2.push(dataNetMap[netmap]);
          }
        }
      }
      armoTablaNetMap(table2);
    }
  };

  return (
    <div>
      <div>
        <FocusLock persistentFocus returnFocus>
          <div className={css(styles.modal, styles.show)}>
            {/* onKeyDown={handleKeyDown}> */}
            <div className={css(styles.modalMain)}>
              <Draggable handle=".drag-section" bounds={{ top: -200, right: 350, bottom: 200, left: -350 }}>
                <div className={`${css(styles.modalContent)}`}>
                  <div className={`drag-section ${css(styles.titleContainer)}`}>Buscador</div>
                  <div className={css(styles.messageContainer)}>
                    <div>
                      <div className="container">
                        <div className="row " style={{ display: 'flex' }}>
                          <div className="col-5">
                            <label>Elemento</label>
                          </div>
                          <div className="col-7">
                            <select
                              onChange={(event) => selectDataElement(event.target.value)}
                              id="slcElemento"
                              name="slcElemento"
                              style={{ width: '500px', marginLeft: '100px', border: '1px solid cadetblue', borderRadius: '5px' }}
                            >
                              <option value={'printer'}>Printer (TopazPrt)</option>
                              <option value={'topazmap'}>Topaz Net (TopazMap)</option>
                              <option value={'numerator'}>Numerator (NumeratorDefinition)</option>
                              <option value={'netmap'}> Net Map (NetMap)</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mt-1" style={{ display: 'flex' }}>
                          <div className="col-5">
                            <label>Campo</label>
                          </div>
                          <div className="col-7">
                            <select
                              id="slcField"
                              name="slcField"
                              style={{ width: '500px', marginLeft: '116px', border: '1px solid cadetblue', borderRadius: '5px' }}
                            >
                              {comboField.map((option, index) => {
                                return (
                                  <option key={index} value={option.id}>
                                    {option.text}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="row mt-1" style={{ display: 'flex' }}>
                          <div className="col-5">
                            <label>Operación</label>
                          </div>
                          <div className="col-7">
                            <select
                              onChange={(event) => selectOperation(event.target.value)}
                              id="slcOperation"
                              name="slcOperation"
                              style={{ width: '500px', marginLeft: '96px', border: '1px solid cadetblue', borderRadius: '5px' }}
                            >
                              {/* <option key="1" value="1">
                            !=
                          </option> */}
                              <option key="1" value="igual">
                                {'='}
                              </option>
                              <option key="2" value="distinto">
                                {'<>'}
                              </option>
                              <option key="3" value="mayor">
                                {'>'}
                              </option>
                              <option key="4" value="mayorIgual">
                                {'>='}
                              </option>
                              <option key="5" value="menor">
                                {'<'}
                              </option>
                              <option key="6" value="menorIgual">
                                {'<='}
                              </option>
                              <option key="7" value="isNull">
                                {'IS NULL'}
                              </option>
                              <option key="8" value="isNotNull">
                                {'IS NOT NULL'}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="row mt-1" style={{ display: 'flex' }}>
                          <div className="col-5">
                            <label>Texto</label>
                          </div>
                          <div className="col-4">
                            <input
                              type="text"
                              style={{
                                border: '1px solid cadetblue',
                                borderRadius: '5px',
                                width: '400px',
                                marginLeft: '127px',
                              }}
                              id="txtTexto"
                            />
                          </div>
                          <div className="col-3" style={{ paddingLeft: '10px' }}>
                            <input
                              className="btn btn-outline-primary btn-sm btnFiltrarSearch"
                              type="button"
                              id="btnSearch"
                              style={{ width: '120px', border: '1px solid cadetblue', borderRadius: '4px' }}
                              value={'Buscar'}
                              onClick={search}
                            />
                          </div>
                        </div>
                        <div
                          className="col"
                          style={{
                            overflowY: 'scroll',
                            maxHeight: '250px',
                            minWidth: '900px',
                            marginTop: '5px',
                          }}
                        >
                          {table !== '' ? table : <div style={{ marginLeft: '40%', marginTop: '10%' }}>No hay información</div>}
                        </div>
                      </div>
                    </div>
                    <div className={css(styles.buttonsContainer)}>
                      {(!props.buttons || !props.buttons.length) && (
                        <div className="row" style={{ display: 'flex' }}>
                          <div className="col-3">
                            <button
                              className="btn btn-info btn-sm cerrarModal"
                              handleClick={() => accionesModalSearch('cerrar')}
                              onClick={(e) => {
                                if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) accionesModalSearch('cerrar');
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  accionesModalSearch('cerrar');
                                }
                              }}
                            >
                              Cerrar
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Draggable>
            </div>
          </div>
        </FocusLock>
      </div>
    </div>
  );
};

export default ModalMessageSearch;
