import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { fileArrayToBase64 } from '../../../UI-kit/helpers/fileHelper';
import { persistFingerData, preChangeSecondLogin } from '../../api/session';
import { startCaptureABM, getScannedSet, reset, getLastBMP } from '../../api/fingerprintWS';
import ModalMessage from '../ModalMessage';
import ConfirmPasswordView from './ConfirmPasswordView';

const Button = ({ title, click, className, disabled = false }) => {
  return (
    <button
      className={`bg-primary hover:bg-primary-dark focus:bg-primary text-white rounded shadow m-2 py-2 px-4 text-sm ${disabled && 'bg-grey'}`}
      onClick={click}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export class AddFingerPrint extends Component {
  constructor(props) {
    super(props);
    const { action } = this.props;
    this.state = {
      showWindow: true,
      lastScannedFingerprint: null,
      lastScannedANSI: null,
      lastScannedBitmap: null,
      ansiArray: [],
      valueToSend: '0',
      timeout: null,
      scanning: true,
      scannedAll: false,
      action: action,
      validationData: null,
      amountOfScannedFingerprints: 0,
      showModal: false,
      showModal2: false,
      msg: '',
      newFinger: false,
      showWindowConfirmPassword: false,
      password: '',
      confirmPassword: '',
      showModalPasswordNotMatch: false,
      showModalPasswordRetry: false,
    };
    this.handleCancelFinger = this.handleCancelFinger.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getScannedImagesLoop = this.getScannedImagesLoop.bind(this);
    this.handleSaveLogin = this.handleSaveLogin.bind(this);
    this.loginWithFinger = this.loginWithFinger.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
    this.setState({
      timeout: clearTimeout(this.state.timeout),
      scanning: false,
      showWindow: false,
      newFinger: false,
      amountOfScannedFingerprints: 0,
    });
    reset();
  }

  async handleSaveLogin() {
    const { intl, user } = this.props;
    if (!this.props.multipleLogin) {
      let code = await preChangeSecondLogin(user, this.state.password);
      if (code > 0) {
        await this.handleSend();
        this.setState({
          newFinger: true,
          showModal2: true,
          password: '',
          confirmPassword: '',
          msg: intl.formatMessage({ id: 'fingerprintSavedSuccessfully' }),
        });
      } else {
        this.setState({
          showModalPasswordRetry: true,
        });
      }
    } else {
      let { password } = this.props;
      let code = await preChangeSecondLogin(user, password);
      if (code > 0) {
        this.handleSend();
        this.setState({
          newFinger: true,
          showModal2: true,
          password: '',
          confirmPassword: '',
          msg: intl.formatMessage({ id: 'fingerprintSavedSuccessfully' }),
        });
      } else {
        this.setState({
          showModalPasswordRetry: true,
        });
      }
    }
  }

  async getScannedImagesLoop() {
    if (this.state.scanning) {
      getLastBMP()
        .then((data) => {
          let amountToSet = this.state.amountOfScannedFingerprints;
          if (data !== '') {
            if (data.length === 120) {
              this.resetReadData();
              // eslint-disable-next-line no-undef
              alert(intl.formatMessage({ id: 'fingerprintReadError' }));
              return;
            } else {
              amountToSet++;
              this.setState({ amountOfScannedFingerprints: amountToSet });
              let receivedBMP = data;
              let base64Image = fileArrayToBase64(receivedBMP);
              this.setState({ lastScannedFingerprint: base64Image });
            }
          }

          if (!this._ismounted) {
          } else if (amountToSet === 4) {
            this.handleReceive();
            this.setState({ timeout: clearTimeout(this.state.timeout) });
          } else {
            this.setState({
              timeout: setTimeout(() => this.getScannedImagesLoop(), 1000),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.resetReadData();
          // eslint-disable-next-line no-undef
          alert(intl.formatMessage({ id: 'fingerprintReadError' }));
        });
    }
  }

  async loginWithFinger() {
    if (this.state.newFinger) {
      const { tipoLoged, user, setAddFingerLogin, loginDialog } = this.props;
      setAddFingerLogin(false);
      this._ismounted = false;
      await reset();
      this.setState((prevState) => ({
        showWindow: !prevState.showWindow,
        newFinger: false,
      }));
      await loginDialog(user, '', tipoLoged === 1, this.state.fingerData);
    }
  }

  async handleSend() {
    let { user } = this.props;
    let body = this.buildBody();
    await persistFingerData(body, user);
  }

  buildBody() {
    let numeroPersonaFisica = 0;
    let fingerkey = this.state.valueToSend;
    let fingerdata = this.state.ansiArray;
    let fingerDataAnsi = this.state.lastScannedANSI;
    let fingerDataBMP = this.state.lastScannedBitmap;

    let body = {
      numeroPersonaFisica,
      fingerkey,
      fingerdata,
      fingerDataAnsi,
      fingerDataBMP,
    };
    return body;
  }

  async handleResume() {
    await reset();
    this._ismounted = true;
    await startCaptureABM();
    this.setState({ scanning: true });
    this.getScannedImagesLoop();
  }

  async handleReceive() {
    const { intl } = this.props;
    const ret = await getScannedSet();

    if (ret.bmpImage && ret.bmpImage.length > 120) {
      this.setState({
        ansiArray: ret.fingerData,
        lastScannedANSI: ret.ansi,
        lastScannedBitmap: ret.bmpImage,
        fingerData: fileArrayToBase64(ret.bmpImage),
        scanning: false,
        scannedAll: true,
      });
    } else {
      this.resetReadData();
      this.handleResume();
      // eslint-disable-next-line no-undef
      alert(intl.formatMessage({ id: 'fingerprintReadError' }));
    }
  }

  resetReadData() {
    this.setState({
      ansiArray: [],
      lastScannedANSI: null,
      lastScannedFingerprint: null,
      lastScannedBitmap: null,
      amountOfScannedFingerprints: 0,
      scanning: false,
      scannedAll: false,
    });
  }

  async handleSave() {
    const { intl, multipleLogin } = this.props;
    if (this.state.amountOfScannedFingerprints === 4) {
      if (multipleLogin) {
        this.handleSaveLogin();
      } else {
        this.setState({
          showWindowConfirmPassword: true,
        });
      }
    } else {
      this.setState({
        showModal: true,
        msg: intl.formatMessage({ id: 'moreSamplesNeeded' }, { count: 4 - this.state.amountOfScannedFingerprints }),
      });
    }
  }

  handleCancelFinger() {
    const { setAddFingerLogin } = this.props;
    setAddFingerLogin(false);
    this._ismounted = false;
    this.resetReadData();
    this.setState({ showWindow: false });
    this.props.setRequireRegisterFingerprint(false);
  }

  async fetchData() {
    this.handleResume();
  }

  render() {
    const { intl } = this.props;
    const fingerOptions = [
      { value: '0', messageId: 'rightThumb' },
      { value: '1', messageId: 'rightIndex' },
      { value: '2', messageId: 'rightMiddle' },
      { value: '3', messageId: 'rightRing' },
      { value: '4', messageId: 'rightPinky' },
      { value: '5', messageId: 'leftThumb' },
      { value: '6', messageId: 'leftIndex' },
      { value: '7', messageId: 'leftMiddle' },
      { value: '8', messageId: 'leftRing' },
      { value: '9', messageId: 'leftPinky' },
    ];

    let captureMessage = <span className="text-xs"></span>;
    if (this.state.scanning) {
      captureMessage = (
        <span className="text-xs mx-6">
          <FormattedMessage id="scanCount" values={{ count: this.state.amountOfScannedFingerprints }} />
        </span>
      );
    } else if (this.state.scannedAll) {
      captureMessage = (
        <span className="text-xs">
          <FormattedMessage id="fingerScannedSuccessfully" />
        </span>
      );
    }

    let fingerImage;
    if (this.state.lastScannedFingerprint) {
      fingerImage = <img style={{ maxWidth: '200px', maxHeight: '100px' }} src={`${this.state.lastScannedFingerprint}`} />;
    }

    return (
      <>
        <div>
          <div>
            <span className="text-xs">
              <FormattedMessage id="fingerToRegister" />
            </span>
            <select
              className="text-xs"
              onChange={(e) => {
                this.setState({ valueToSend: e.target.value });
              }}
            >
              {fingerOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {intl?.formatMessage({ id: option.messageId })}
                </option>
              ))}
            </select>
          </div>
          {captureMessage}
        </div>

        <div
          className="flex justify-center mt-2 ml-16"
          style={{
            width: '200px',
            height: '100px',
          }}
        >
          {fingerImage}
        </div>

        <div className="flex justify-center mt-2">
          <Button title={<FormattedMessage id="save" defaultMessage={'Guardar'} />} click={() => this.handleSave()} />
          <Button title={<FormattedMessage id="cancel" defaultMessage={'Cancelar'} />} click={() => this.handleCancelFinger()} />
        </div>

        {this.state.showModalPasswordNotMatch && (
          <ModalMessage
            message={
              <div className="flex w-full h-full justify-center items-center">
                <FormattedMessage id="passwordsNotMatch" />
              </div>
            }
            handleAccept={() => {
              this.setState({
                showModalPasswordNotMatch: false,
              });
            }}
            acceptTitle={<FormattedMessage id="accept" />}
          ></ModalMessage>
        )}

        {this.state.showModal && (
          <ModalMessage
            message={
              <div className="flex w-full h-full justify-center items-center">
                <FormattedMessage id="moreSamplesNeeded" values={{ count: 4 - this.state.amountOfScannedFingerprints }} />
              </div>
            }
            handleAccept={() => {
              this.setState({
                showModal: false,
                msg: '',
              });
            }}
            acceptTitle={<FormattedMessage id="accept" />}
          ></ModalMessage>
        )}
        {this.state.showModalPasswordRetry && (
          <ModalMessage
            message={
              <div className="flex w-full h-full justify-center items-center">
                <FormattedMessage id="incorrectPassword" />
              </div>
            }
            handleAccept={() => {
              this.setState({
                showModalPasswordRetry: false,
                showWindowConfirmPassword: true,
                password: '',
                confirmPassword: '',
              });
            }}
            handleCancel={() => {
              this.setState({
                showModalPasswordRetry: false,
                password: '',
                confirmPassword: '',
              });
            }}
            acceptTitle={<FormattedMessage id="accept" />}
          ></ModalMessage>
        )}
        {this.state.showWindowConfirmPassword && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            <ConfirmPasswordView
              onPasswordChange={(e) => this.setState({ password: e.target.value })}
              onConfirmChange={(e) => this.setState({ confirmPassword: e.target.value })}
              inputPassword={this.state.password}
              inputConfirm={this.state.confirmPassword}
              fromNewLogin={true}
              handleSave={() => {
                if (this.state.password === this.state.confirmPassword) {
                  this.setState({
                    showWindowConfirmPassword: false,
                    msg: '',
                  });
                  this.handleSaveLogin();
                } else {
                  this.setState({
                    showModalPasswordNotMatch: true,
                  });
                }
              }}
              handleCancel={() => {
                this.setState({
                  showWindowConfirmPassword: false,
                  msg: '',
                  password: '',
                  confirmPassword: '',
                });
              }}
            ></ConfirmPasswordView>
          </div>
        )}
        {this.state.showModal2 && (
          <ModalMessage
            message={<div className="flex w-full h-full justify-center items-center">{this.state.msg}</div>}
            handleAccept={() => {
              if (this.state.newFinger) {
                this.setState({
                  showModal2: false,
                  msg: '',
                });
                this.resetReadData();
                this.handleResume();
              } else {
                this.setState({
                  showModal2: false,
                  msg: '',
                });
                this.resetReadData();
                this.handleResume();
              }
            }}
            handleCancel={() => {
              if (this.state.newFinger) {
                this.loginWithFinger();
              } else {
                this.setState({
                  showModal2: false,
                  msg: '',
                });
                this.handleCancelFinger();
              }
            }}
            acceptTitle={<FormattedMessage id="yes" />}
            cancelTitle={<FormattedMessage id="no" />}
          ></ModalMessage>
        )}
      </>
    );
  }
}

export default AddFingerPrint;
