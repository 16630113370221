import { store } from '../../../store';
import { getBitacorasLogin } from '../../../api/bitacora';
import { formatDateForServer } from './filterFields';
import { exportToCsv } from './exportCSV';

const exportEntradaSalida = (filterParams, columns, filterListID) => {
  let {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    bLogin,
    bLogout,
    bFallidos,
    cantidadFallos,
    usuario,
    maquina,
    sucursal,
    persistenceUnit,
  } = filterParams;

  let filterData = {};
  filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
  store.setState({
    bitacoraApp: {
      ...store.getState().bitacoraApp,
      ...filterData,
    },
  });
  let defaultValue;
  fechaHoraDesde = formatDateForServer(fechaHoraDesde);
  fechaHoraHasta = formatDateForServer(fechaHoraHasta);
  firstResult = firstResult || 0;
  maxResults = 10000;
  cantidadFallos = cantidadFallos || defaultValue;
  usuario = usuario || defaultValue;
  maquina = maquina || defaultValue;
  sucursal = sucursal || defaultValue;
  persistenceUnit = persistenceUnit || 'Local';
  const params = {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    bLogin,
    bLogout,
    bFallidos,
    usuario,
    cantidadFallos,
    maquina,
    sucursal,
    persistenceUnit,
  };

  getBitacorasLogin(params)
    .then((data) => {
      exportToCsv(data, 'entradasSalidas.csv', columns);
    })
    .catch((err) => {
      console.log(err);
    });
};

export { exportEntradaSalida };
