import React, { Component } from 'react';
import Grid from './Grid';
import GridPopup from './GridPopup';
import { connect } from 'unistore/react';
import { storeActions } from '../../src/store';
import { getTabIdByNodeId, getTabInformation } from '../../src/selectors';

export class HelpGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueSelected: '',
    };

    this.acceptRef = React.createRef();
    this.cancelRef = React.createRef();
    this.focusOnAccept = this.focusOnAccept.bind(this);
  }

  focusOnAccept() {
    if (this.acceptRef && this.acceptRef.current) {
      this.acceptRef.current.focus();
    }
  }

  componentDidMount() {
    const { pauseFocus } = this.props;
    pauseFocus();
  }

  componentWillUnmount() {
    const { resumeFocus } = this.props;
    resumeFocus();
  }

  render() {
    let {
      acceptHandler,
      cancelHandler,
      fieldNumber,
      inputValue,
      lineNumber,
      nodeId,
      transactionLineLabel,
      transactionNumber,
      onHelpSelect,
      onChange,
    } = this.props;
    const position = { x: 10, y: 55, width: 'auto', height: 'auto' };
    const gridStyle = { width: '480px', height: '250px' };

    return (
      <GridPopup
        nodeId={nodeId}
        fieldNumber={fieldNumber}
        style={gridStyle}
        position={position}
        fromHelp={true}
        inputValue={inputValue}
        onChange={(e) => {
          onChange(e);
        }}
        transactionNumber={transactionNumber}
        lineNumber={lineNumber}
        transactionLineLabel={transactionLineLabel}
        acceptButtonRef={this.acceptRef}
        onHelpSelect={(event) => {
          onHelpSelect(event);
        }}
        cancelHandlerHelp={cancelHandler}
        focusedTable
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { nodeId } = ownProps;
  const tabId = getTabIdByNodeId(state, nodeId);
  const { operationData, focusOn } = getTabInformation(state, tabId);

  const { grids } = operationData;

  return {
    focusOn,
    grids,
  };
};

export default connect(mapStateToProps, storeActions)(HelpGrid);
