import React, { useEffect, useState } from 'react';
import { Info, ProcessResult } from '../../../../models/processResult';
import { DetailsInformationView } from './DetailsInformationView';

interface DetailsInformationViewModelProps {
  results: ProcessResult;
  isErrorSelected: boolean;
  isWarningSelected: boolean;
  isInformationSelected: boolean;
  onMessageSelect: (message: Info) => void;
}

export function DetailsInformationViewModel({
  results,
  isErrorSelected,
  isWarningSelected,
  isInformationSelected,
  onMessageSelect,
}: DetailsInformationViewModelProps): JSX.Element | null {
  const [resultsShowed, setResultsShowed] = useState<Info[]>(getResultsToMap());

  function getResultsToMap(): Info[] {
    let resultsToMap: Info[] = [];

    resultsToMap = addErrors(resultsToMap);
    resultsToMap = addInfos(resultsToMap);
    resultsToMap = addWarnings(resultsToMap);

    return resultsToMap;
  }

  function addErrors(resultsMapped: Info[]): Info[] {
    if (isErrorSelected) {
      return resultsMapped.concat(results.errors);
    } else {
      return resultsMapped;
    }
  }

  function addInfos(resultsMapped: Info[]): Info[] {
    if (isInformationSelected) {
      return resultsMapped.concat(results.infos);
    } else {
      return resultsMapped;
    }
  }

  function addWarnings(resultsMapped: Info[]): Info[] {
    if (isWarningSelected) {
      return resultsMapped.concat(results.warnings);
    } else {
      return resultsMapped;
    }
  }

  function onSelect(message: Info): () => void {
    return () => {
      onMessageSelect(message);
    };
  }

  useEffect(() => {
    setResultsShowed(getResultsToMap());
  }, [isErrorSelected, isInformationSelected, isWarningSelected, results]);

  return <DetailsInformationView results={resultsShowed} onSelect={onSelect} />;
}
