import { useEffect, useState } from 'react';
import { getAllow, getParentPermission, getGrandParentRestrictions, getTitlePermission, getBaseAllow } from '../utils';
import { getGroupOperationsAsync } from '../../../../../../api/securityManager';
import { DEFAULT_RESTRICTION_KEY } from '../../../../models/constants';
import { RestrictionObject } from './useMenu.hook';
import { Restriction, Restrictions, useRestrictions } from './useRestrictions.hook';

interface UseOperations {
  operations: OperationsTree[];
  defaultRestriction?: Restriction;
}

interface OperationService {
  [key: string]: string | number;
  nombre: string;
  identificacion: number;
  titulo: number;
}

export interface OperationsTree {
  key: string;
  label: string;
  restriction: RestrictionObject;
  nodes: OperationsTree[];
}

export const OPERATIONS_TYPE: string = 'O';

export function useOperations(parentGroup: string, group: string): UseOperations {
  const [defaultRestriction, setDefaultRestriction] = useState<Restriction>();
  const [operationOptions, setOperationOptions] = useState<OperationsTree[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [grandParentRestrictions, setGrandParentRestrictions] = useState(null);
  const { fillNumberWithZeros, getOperationRestrictionsAsync } = useRestrictions();

  async function getOperationsAsync(): Promise<void> {
    const operations: OperationService[] = await getGroupOperationsAsync();
    const restrictions: Restrictions = await getOperationRestrictionsAsync(parentGroup);
    const ownRestrictions: Restrictions = await getOperationRestrictionsAsync(group);
    const baseAllow = await getBaseAllow(group, getOperationRestrictionsAsync);
    const options: OperationsTree[] = [];
    let lastTitle: number | null = null;
    let lastHeader: OperationsTree | null = null;

    let defaultRestrictionVal = ownRestrictions[DEFAULT_RESTRICTION_KEY] || {
      group: group,
      id: DEFAULT_RESTRICTION_KEY,
      allow: 0,
      type: 'O',
      title: DEFAULT_RESTRICTION_KEY,
      fechaHabilitacion: '',
      parentPermission: 0,
    };

    setDefaultRestriction(defaultRestrictionVal);
    Array.from(operations).forEach((operationOption, index) => {
      const isHeaderIdentification = operationOption.titulo !== lastTitle || lastTitle === null;
      const restrictionKey = fillNumberWithZeros(operationOption.titulo) + fillNumberWithZeros(operationOption.identificacion);
      const allowed = getAllow(ownRestrictions, parentGroup, baseAllow, restrictionKey, operationOption);
      const titleAllowed = getTitlePermission(operationOption, ownRestrictions, parentGroup, baseAllow, operations);
      const parentAllowed = getParentPermission(restrictionKey, operationOption, ownRestrictions, restrictions, baseAllow, grandParentRestrictions);
      const permissions: RestrictionObject = {
        ownPermission: allowed,
        parentPermission: parentAllowed,
        titlePermission: titleAllowed,
        type: OPERATIONS_TYPE,
        allows: allowed,
        id: restrictionKey,
        fechaHabilitacion: ownRestrictions[restrictionKey] !== undefined ? ownRestrictions[restrictionKey].fechaHabilitacion : null,
      };

      let operationNode = {
        key: `${index} ${operationOption.nombre}`,
        label: isHeaderIdentification
          ? `${operationOption.titulo} ${operationOption.nombre}`
          : `${operationOption.identificacion} - ${operationOption.nombre}`,
        nodes: [],
        restriction: permissions,
      };

      if (isHeaderIdentification) {
        lastHeader = operationNode;
        options.push(operationNode);
      } else {
        operationNode.restriction.parentPermission = lastHeader ? lastHeader?.restriction.parentPermission : null;
        lastHeader?.nodes.push(operationNode);
      }

      lastTitle = operationOption.titulo;
    });
    setOperationOptions(options);
  }

  useEffect(() => {
    if (loaded) {
      getOperationsAsync();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, loaded]);

  const getGrandParentRestrictionsAsync = async () => {
    let restrictions = await getGrandParentRestrictions(parentGroup, null, getOperationRestrictionsAsync);
    setGrandParentRestrictions(restrictions);
    setLoaded(true);
  };

  useEffect(() => {
    setLoaded(false);
    getGrandParentRestrictionsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return {
    operations: operationOptions,
    defaultRestriction: defaultRestriction,
  };
}
