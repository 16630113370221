import { css, StyleSheet } from 'aphrodite';
import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import useFormulaValidation from '../../hooks/useFormulaValidation';
import { useTheme } from '../../themes/ThemeContext';
import Signature from './Signature';

const SignaturesSelector = (props) => {
  const { signatures, setSignatures, setValidatedFormula, currency, amount } = props;

  const intl = useIntl();
  const themeContext = useTheme();
  const [loadingValidation, setLoadingValidation] = useState(false);
  const [formulaStatusText, setFormulaStatusText] = useState('');
  const [selectedSignatures, setSelectedSignatures] = useState([]);
  const [selectedPersonNumbers, setSelectedPersonNumbers] = useState([]);
  let selectedPerson = useRef([]);
  const [validationState, validateFormula] = useFormulaValidation();

  const toggleSelect = (signatureId) => {
    // shallow copy of the items
    const newSignatures = [...signatures];

    // index of signature selected
    const index = newSignatures.findIndex((s) => s._id === signatureId);

    // shallow copy of the signature selected
    const signature = { ...signatures[index] };

    // property replace
    signature.selected = !signature.selected;

    // put signature back into the array
    newSignatures[index] = signature;

    // set the state to our new copy
    setSignatures(newSignatures);
  };

  useEffect(() => {
    const selected = signatures.filter((s) => s.selected);
    setSelectedSignatures(selected);
    console.log(selected);
    setSelectedPersonNumbers([...new Set(selected.map((s) => s.personNumber))]);
    selectedPerson.current = [...new Set(selected.map((s) => s.personNumber))];
  }, [signatures]);

  useEffect(() => {
    const validate = async () => {
      setLoadingValidation(true);
      console.log(selectedPersonNumbers);
      await validateFormula(
        currency,
        amount,
        selectedPerson.current,
        selectedSignatures[0].jtsBalance, // TODO
      );
      setLoadingValidation(false);
    };

    if (selectedSignatures.length > 0) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSignatures, signatures]);

  useEffect(() => {
    if (loadingValidation) {
      setFormulaStatusText('Validando fórmula...');
    } else if (validationState.validFormula) {
      setFormulaStatusText('Fórmula válida');
      setValidatedFormula(true);
    } else {
      setFormulaStatusText('Fórmula no válida');
      setValidatedFormula(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingValidation, validationState]);

  const { border } = themeContext.theme;
  const styles = StyleSheet.create({
    signaturesSelectorContainer: {
      margin: '1rem 0',
      height: '28rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    signatures: {
      height: '100%',
      width: '66%',
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'auto',
      paddingBottom: '1rem',
      ':after': {
        display: 'block',
        height: '1rem',
        width: '100%',
        content: '""',
      },
    },
    selectionInfo: {
      height: '100%',
      borderLeftWidth: 1,
      borderColor: border.dark,
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '1rem',
    },
  });

  const textSelectSignatures = intl.formatMessage({
    id: 'selectSignatures',
    defaultMessage: 'Seleccione las firmas que coinciden con la firma del cheque',
  });

  return (
    <div>
      <p>{textSelectSignatures}</p>
      <div className={css(styles.signaturesSelectorContainer)}>
        <div className={css(styles.signatures)}>
          {signatures &&
            signatures.map((signature) => {
              return <Signature key={signature._id} data={signature} selected={signature.selected} toggleSelect={toggleSelect} />;
            })}
        </div>
        <div className={css(styles.selectionInfo)}>
          <p>Total de firmas: {signatures.length}</p>
          <p>Firmas seleccionadas: {selectedSignatures.length}</p>
          <p>{formulaStatusText}</p>
        </div>
      </div>
    </div>
  );
};

export default SignaturesSelector;
