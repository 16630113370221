import { store } from '../store';

export let cargarVectorMovimientos = async (data, intl) => {
  let result = [];
  for (var i = 0; i < data.length; i++) {
    let movimiento = data[i];

    if (movimiento.action === 'A') {
      movimiento.action =
        movimiento.action +
        ' - ' +
        intl.formatMessage({
          id: 'Tablas.movimientos_alta',
          defaultMessage: 'Alta',
        });
    } else if (movimiento.action === 'B') {
      movimiento.action =
        movimiento.action +
        ' - ' +
        intl.formatMessage({
          id: 'Tablas.movimientos_baja',
          defaultMessage: 'Baja',
        });
    } else if (movimiento.action === 'M') {
      movimiento.action =
        movimiento.action +
        ' - ' +
        intl.formatMessage({
          id: 'Tablas.movimientos_modificacion',
          defaultMessage: 'Modificación',
        });
    } else if (movimiento.action === 'O') {
      movimiento.action =
        movimiento.action +
        ' - ' +
        intl.formatMessage({
          id: 'Tablas.movimientos_movimiento',
          defaultMessage: 'Movimiento',
        });
    } else if (movimiento.action === 'C') {
      movimiento.action =
        movimiento.action +
        ' - ' +
        intl.formatMessage({
          id: 'Tablas.movimientos_consulta',
          defaultMessage: 'Consulta',
        });
    } else if (movimiento.action === 'S') {
      movimiento.action =
        movimiento.action +
        ' - ' +
        intl.formatMessage({
          id: 'Tablas.movimientos_consultasecuencial',
          defaultMessage: 'Consulta Secuencial',
        });
    }
    result.push(movimiento);
  }
  return result;
};

export let getDataFieldModificadosUsuarios = async (data, intl) => {
  let result1 = [];
  let result2 = [];
  let helpDataniciales = store.getState().bitacoraApp.helpIniciales;
  for (var i = 0; i < data.length; i++) {
    let bitacora = data[i];
    let bitacora2 = null;
    let camposModificados = '';
    if (i + 1 < data.length) {
      bitacora2 = data[i + 1];
    }
    if (sonPares(bitacora, bitacora2)) {
      i = i + 1;
      if (bitacora.nombre !== null && bitacora.nombre !== bitacora2.nombre) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'nombre',
            defaultMessage: 'Nombre',
          }) +
          ', ';
      } else if (bitacora.nombre === null && bitacora2.nombre !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'nombre',
            defaultMessage: 'Nombre',
          }) +
          ',';
      }

      if (bitacora.iniciales !== null && bitacora.iniciales !== bitacora2.iniciales) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'iniciales',
            defaultMessage: 'Iniciales',
          }) +
          ', ';
      } else if (bitacora.iniciales === null && bitacora2.iniciales !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'iniciales',
            defaultMessage: 'Iniciales',
          }) +
          ', ';
      }

      if (bitacora.grupo !== null && bitacora.grupo !== bitacora2.grupo) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'grupo',
            defaultMessage: 'Grupo',
          }) +
          ', ';
      } else if (bitacora.grupo === null && bitacora2.grupo !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'grupo',
            defaultMessage: 'Grupo',
          }) +
          ', ';
      }

      if (bitacora.lenguaje !== null && bitacora.lenguaje !== bitacora2.lenguaje) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'lenguaje',
            defaultMessage: 'Lenguaje',
          }) +
          ', ';
      } else if (bitacora.lenguaje === null && bitacora2.lenguaje !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'lenguaje',
            defaultMessage: 'Lenguaje',
          }) +
          ', ';
      }

      if (bitacora.fechaini !== null && bitacora.fechaini !== bitacora2.fechaini) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'fechaini',
            defaultMessage: 'Fecha Inicial',
          }) +
          ', ';
      } else if (bitacora.fechaini === null && bitacora2.fechaini !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'fechaini',
            defaultMessage: 'Fecha Inicial',
          }) +
          ', ';
      }

      if (bitacora.fechaexp !== null && bitacora.fechaexp !== bitacora2.fechaexp) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'fechaexp',
            defaultMessage: 'Fecha Expiración',
          }) +
          ', ';
      } else if (bitacora.fechaexp === null && bitacora2.fechaexp !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'fechaexp',
            defaultMessage: 'Fecha Expiración',
          }) +
          ', ';
      }

      if (bitacora.multipleslogins !== null && bitacora.multipleslogins !== bitacora2.multipleslogins) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'multipleslogins',
            defaultMessage: 'Multiples Logins',
          }) +
          ', ';
      } else if (bitacora.multipleslogins === null && bitacora2.multipleslogins !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'multipleslogins',
            defaultMessage: 'Multiples Logins',
          }) +
          ', ';
      }

      if (bitacora.expiracioncontrasenia !== null && bitacora.expiracioncontrasenia !== bitacora2.expiracioncontrasenia) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'expiracioncontrasenia',
            defaultMessage: 'Expiracion Contrasenia',
          }) +
          ', ';
      } else if (bitacora.expiracioncontrasenia === null && bitacora2.expiracioncontrasenia !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'expiracioncontrasenia',
            defaultMessage: 'Expiracion Contrasenia',
          }) +
          ', ';
      }

      if (bitacora.deshabilitado !== null && bitacora.deshabilitado !== bitacora2.deshabilitado) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'deshabilitado',
            defaultMessage: 'Deshabilitado',
          }) +
          ', ';
      } else if (bitacora.deshabilitado === null && bitacora2.deshabilitado !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'deshabilitado',
            defaultMessage: 'Deshabilitado',
          }) +
          ', ';
      }

      if (bitacora.mantdes !== null && bitacora.mantdes !== bitacora2.mantdes) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'mantdes',
            defaultMessage: 'Mantenimiento Desarrollo',
          }) +
          ', ';
      } else if (bitacora.mantdes === null && bitacora2.mantdes !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'mantdes',
            defaultMessage: 'Mantenimiento Desarrollo',
          }) +
          ', ';
      }

      if (bitacora.nrodecaja !== null && bitacora.nrodecaja !== bitacora2.nrodecaja) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'nrodecaja',
            defaultMessage: 'Nro De Caja',
          }) +
          ', ';
      } else if (bitacora.nrodecaja === null && bitacora2.nrodecaja !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'nrodecaja',
            defaultMessage: 'Nro De Caja',
          }) +
          ', ';
      }

      if (bitacora.cantlogins !== null && bitacora.cantlogins !== bitacora2.cantlogins) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'cantlogins',
            defaultMessage: 'Cant Logins',
          }) +
          ', ';
      } else if (bitacora.cantlogins === null && bitacora2.cantlogins !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'cantlogins',
            defaultMessage: 'Cant Logins',
          }) +
          ', ';
      }

      if (bitacora.nrosucursal !== null && bitacora.nrosucursal !== bitacora2.nrosucursal) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'nrosucursal',
            defaultMessage: 'Nro. Sucursal',
          }) +
          ', ';
      } else if (bitacora.nrosucursal === null && bitacora2.nrosucursal !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'nrosucursal',
            defaultMessage: 'Nro. Sucursal',
          }) +
          ', ';
      }

      if (bitacora.sector !== null && bitacora.sector !== bitacora2.sector) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'sector',
            defaultMessage: 'Sector',
          }) +
          ', ';
      } else if (bitacora.sector === null && bitacora2.sector !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'sector',
            defaultMessage: 'Sector',
          }) +
          ', ';
      }

      if (bitacora.iniexphabilitado !== null && bitacora.iniexphabilitado !== bitacora2.iniexphabilitado) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'iniexphabilitado',
            defaultMessage: 'Ini. Exp. Habilitado',
          }) +
          ', ';
      } else if (bitacora.iniexphabilitado === null && bitacora2.iniexphabilitado !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'iniexphabilitado',
            defaultMessage: 'Ini. Exp. Habilitado',
          }) +
          ', ';
      }

      if (bitacora.numerodemaquina !== null && bitacora.numerodemaquina !== bitacora2.numerodemaquina) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'numerodemaquina',
            defaultMessage: 'Número de Máquina',
          }) +
          ', ';
      } else if (bitacora.numerodemaquina === null && bitacora2.numerodemaquina !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'numerodemaquina',
            defaultMessage: 'Número de Máquina',
          }) +
          ', ';
      }

      if (bitacora.fechabloqueo !== null && bitacora.fechabloqueo !== bitacora2.fechabloqueo) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'fechabloqueo',
            defaultMessage: 'Fecha Bloqueo',
          }) +
          ', ';
      } else if (bitacora.fechabloqueo === null && bitacora2.fechabloqueo !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'fechabloqueo',
            defaultMessage: 'Fecha Bloqueo',
          }) +
          ', ';
      }

      if (bitacora.motivobloqueo !== null && !bitacora.motivobloqueo === bitacora2.motivobloqueo) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'motivobloqueo',
            defaultMessage: 'Motivo Bloqueo',
          }) +
          ', ';
      } else if (bitacora.motivobloqueo === null && bitacora2.motivobloqueo !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'motivobloqueo',
            defaultMessage: 'Motivo Bloqueo',
          }) +
          ', ';
      }

      if (bitacora.aplic_modif !== null && bitacora.aplic_modif !== bitacora2.aplic_modif) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'aplic_modif',
            defaultMessage: 'Aplica Modificación',
          }) +
          ', ';
      } else if (bitacora.aplic_modif === null && bitacora2.aplic_modif !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'aplic_modif',
            defaultMessage: 'Aplica Modificación',
          }) +
          ', ';
      }

      if (bitacora.segundologin !== null && bitacora.segundologin !== bitacora2.segundologin) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'segundologin',
            defaultMessage: 'Segundo Login',
          }) +
          ', ';
      } else if (bitacora.segundologin === null && bitacora2.segundologin !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'segundologin',
            defaultMessage: 'Segundo Login',
          }) +
          ', ';
      }

      if (bitacora.tipologin !== null && bitacora.tipologin !== bitacora2.tipologin) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'tipologin',
            defaultMessage: 'Tipo Login',
          }) +
          ', ';
      } else if (bitacora.tipologin === null && bitacora2.tipologin !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'tipologin',
            defaultMessage: 'Tipo Login',
          }) +
          ', ';
      }

      if (bitacora.tipo_documento !== null && bitacora.tipo_documento !== bitacora2.tipo_documento) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'tipo_documento',
            defaultMessage: 'Tipo Documento',
          }) +
          ', ';
      } else if (bitacora.tipo_documento === null && bitacora2.tipo_documento !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'tipo_documento',
            defaultMessage: 'Tipo Documento',
          }) +
          ', ';
      }

      if (bitacora.documento !== null && bitacora.documento !== bitacora2.documento) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'documento',
            defaultMessage: 'Documento',
          }) +
          ', ';
      } else if (bitacora.documento === null && bitacora2.documento !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'documento',
            defaultMessage: 'Documento',
          }) +
          ', ';
      }

      if (bitacora.controla_inactivo !== null && bitacora.controla_inactivo !== bitacora2.controla_inactivo) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'controla_inactivo',
            defaultMessage: 'Controla Inactivo',
          }) +
          ', ';
      } else if (bitacora.controla_inactivo === null && bitacora2.controla_inactivo !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'controla_inactivo',
            defaultMessage: 'Controla Inactivo',
          }) +
          ', ';
      }

      if (bitacora.ldapuser !== null && bitacora.ldapuser !== bitacora2.ldapuser) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'ldapuser',
            defaultMessage: 'ldapuser',
          }) +
          ', ';
      } else if (bitacora.ldapuser === null && bitacora2.ldapuser !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'ldapuser',
            defaultMessage: 'ldapuser',
          }) +
          ', ';
      }

      if (bitacora.loginconad !== null && bitacora.loginconad !== bitacora2.loginconad) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'loginconad',
            defaultMessage: 'loginconad',
          }) +
          ', ';
      } else if (bitacora.loginconad === null && bitacora2.loginconad !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'loginconad',
            defaultMessage: 'loginconad',
          }) +
          ', ';
      }

      if (bitacora.usuariored_usr !== null && bitacora.usuariored_usr !== bitacora2.usuariored_usr) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'usuariored_usr',
            defaultMessage: 'Usuario Red',
          }) +
          ', ';
      } else if (bitacora.usuariored_usr === null && bitacora2.usuariored_usr !== null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'usuariored_usr',
            defaultMessage: 'Usuario Red',
          }) +
          ', ';
      }
      if (camposModificados && camposModificados.length > 3) {
        camposModificados = camposModificados.slice(0, camposModificados.length - 2);
      }
    } else {
      camposModificados = 'Errores de pares';
    }
    bitacora.camposModificados = camposModificados;

    let dataIniciales = helpDataniciales.find((c) => c.clave === bitacora.clave_modificador);
    if (dataIniciales) {
      bitacora.inicialesModificador = dataIniciales.iniciales;
      bitacora.nombreModificador = dataIniciales.nombre;
    } else {
      bitacora.inicialesModificador = '';
      bitacora.nombreModificador = '';
    }

    result1.push(bitacora);
    result2.push(bitacora2);
  }
  let dataResult = { resultData: result1, result2: result2 };
  return dataResult;
};

const sonPares = (bitacora, bitacora2) => {
  if (bitacora2 !== null && (bitacora.tipo === 1 || (bitacora2.getTipo && bitacora2.getTipo() === 2))) {
    if (bitacora.fecha_hora_modificado !== null && bitacora2.fecha_hora_modificado !== null) {
      if (bitacora.fecha_hora_modificado === bitacora2.fecha_hora_modificado) {
        if (bitacora.clave_modificador === bitacora2.clave_modificador) {
          return true;
        }
      }
    }
  }
  return false;
};

export let getDataFieldModificadosGrupo = async (data, intl) => {
  let result1 = [];
  let result2 = [];
  let helpDataniciales = store.getState().bitacoraApp.helpIniciales;

  for (var i = 0; i < data.length; i++) {
    let bitacoragrp = data[i];
    let bitacoragrp2 = null;
    let camposModificados = '';
    if (i + 1 < data.length) {
      bitacoragrp2 = data[i + 1];
    }

    if (bitacoragrp2 !== null) {
      i = i + 1;
      if (bitacoragrp.grupo !== null && bitacoragrp.grupo !== bitacoragrp2.grupo) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'grupo',
            defaultMessage: 'Grupo',
          }) +
          ', ';
      } else if (bitacoragrp.grupo === null && bitacoragrp2.grupo != null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'grupo',
            defaultMessage: 'Grupo',
          }) +
          ', ';
      }
      if (bitacoragrp.descripcion !== null && bitacoragrp.descripcion !== bitacoragrp2.descripcion) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'descripcion',
            defaultMessage: 'Descripción',
          }) +
          ', ';
      } else if (bitacoragrp.descripcion === null && bitacoragrp2.descripcion != null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'descripcion',
            defaultMessage: 'Descripción',
          }) +
          ', ';
      }
      if (bitacoragrp.grupobase !== null && bitacoragrp.grupobase !== bitacoragrp2.grupobase) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'grupobase',
            defaultMessage: 'Grupo Base',
          }) +
          ', ';
      } else if (bitacoragrp.grupobase === null && bitacoragrp2.grupobase != null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'grupobase',
            defaultMessage: 'Grupo Base',
          }) +
          ', ';
      }
      if (bitacoragrp.autorizacion !== null && bitacoragrp.autorizacion !== bitacoragrp2.autorizacion) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'autorizacion',
            defaultMessage: 'Autorizador',
          }) +
          ', ';
      } else if (bitacoragrp.autorizacion === null && bitacoragrp2.autorizacion != null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'autorizacion',
            defaultMessage: 'Autorizador',
          }) +
          ', ';
      }
      if (bitacoragrp.confirmacion !== null && bitacoragrp.confirmacion !== bitacoragrp2.confirmacion) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'confirmacion',
            defaultMessage: 'Confirmador',
          }) +
          ', ';
      } else if (bitacoragrp.confirmacion === null && bitacoragrp2.confirmacion != null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'confirmacion',
            defaultMessage: 'Confirmador',
          }) +
          ', ';
      }
      if (bitacoragrp.heredamsg !== null && bitacoragrp.heredamsg !== bitacoragrp2.heredamsg) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'heredamsg',
            defaultMessage: 'Hereda',
          }) +
          ', ';
      } else if (bitacoragrp.heredamsg === null && bitacoragrp2.heredamsg != null) {
        camposModificados =
          camposModificados +
          intl.formatMessage({
            id: 'heredamsg',
            defaultMessage: 'Hereda',
          }) +
          ', ';
      }
      if (camposModificados && camposModificados.length > 3) {
        camposModificados = camposModificados.slice(0, camposModificados.length - 2);
      }
    }

    bitacoragrp.camposModificados = camposModificados;
    let dataIniciales = helpDataniciales.find((c) => c.iniciales === bitacoragrp.clave_modificador);
    if (dataIniciales) {
      bitacoragrp.inicialesModificador = dataIniciales.clave;
      bitacoragrp.nombreModificador = dataIniciales.nombre;
    } else {
      bitacoragrp.inicialesModificador = '';
      bitacoragrp.nombreModificador = '';
    }

    result1.push(bitacoragrp);
    result2.push(bitacoragrp2);
    /* if (bitacoragrp2 !== null) {
      dataIniciales = helpDataniciales.find((c) => c.clave === bitacoragrp2.clave_modificador);
      bitacoragrp2.inicialesModificador = dataIniciales.iniciales;
      bitacoragrp2.nombreModificador = dataIniciales.nombre;
      result.push(bitacoragrp2);
    } */
  }
  let dataResult = { resultData: result1, result2: result2 };
  return dataResult;
};
