import { format } from 'date-fns';

export let getDateFormat = (edition) => {
  if (edition) {
    switch (edition) {
      case 'G':
        return 'yyyy/MM/dd';
      case 'g':
        return 'yy/MM/dd';
      case 'F':
        return 'dd/MM/yyyy';
      case 'f':
        return 'dd/MM/yy';
      case 'D':
        return 'MM/dd/yyyy';
      case 'd':
        return 'MM/dd/yy';
      case 'H':
        return 'hh:mm:ss';
      case 'h':
        return 'hh:mm';
      default:
        return 'yyyy/MM/dd';
    }
  } else {
    return 'yyyy/MM/dd';
  }
};

export let getSelectedDate = (value) => {
  let selectedDate = null;
  if (value) {
    let valueToSelect = value;
    if (Array.isArray(valueToSelect)) {
      selectedDate = new Date(parseInt(valueToSelect[0]), parseInt(valueToSelect[1]) - 1, parseInt(valueToSelect[2]));
    } else {
      selectedDate = new Date(valueToSelect);
    }
  }
  return selectedDate;
};

const pad = (number) => `0${number}`.slice(-2);

export let dateRunFormat = (date) => {
  let runDate = new Date(date.valueOf() + date.getTimezoneOffset() * 60000);
  const year = runDate.getFullYear();
  const month = runDate.getMonth() + 1;
  const day = runDate.getDate();
  return `${year}${pad(month)}${pad(day)}`;
};

export const dateFormatSimple = (value) => {
  let months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];

  let dateInput = value.split('-');

  return `${months[parseInt(dateInput[1], 10) - 1]} ${parseInt(dateInput[2], 10)}, ${dateInput[0]}`;
};

export let simpleFormatDate = (value) => {
  return `${formatDateSimple(value)}${value.substr(8)}`;
};

export let formatDateTime = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  // const milliseconds = date.getMilliseconds().toString().charAt(0)

  return `${year}-${pad(month)}-${pad(day)} ${pad(hours)}:${pad(minutes)}:${pad(seconds)}`; // .${milliseconds}
};

export let formatDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${pad(day)}-${pad(month)}-${year}`;
};

export let formatDateSimple = (value) => {
  const year = value.substr(0, 4);
  const month = value.substr(4, 2);
  const day = value.substr(6, 2);

  return `${year}-${month}-${day}`;
};

export let formatDateFromServer = (value) => {
  const year = value.substr(0, 4);
  const month = value.substr(4, 2);
  const day = value.substr(6, 2);

  return [year, month, day];
};

export let formatDateDDMMYYYY = (value) => {
  const year = value.substr(0, 4);
  const month = value.substr(4, 2);
  const day = value.substr(6, 2);

  return day + '/' + month + '/' + year;
};

export let formatDateDDMMYYYYWithHyphen = (value) => {
  const year = value.substr(0, 4);
  const month = value.substr(4, 2);
  const day = value.substr(6, 2);

  return year + '-' + month + '-' + day;
};

export const formatDateYYYYMMDDtoDDMMYYYY = (value) => {
  const year = value.substr(0, 4);
  const month = value.substr(5, 2);
  const day = value.substr(8, 2);
  return day + month + year;
};

export const dateToFormat = (value, formato) => {
  const year = value.substr(0, 4);
  const month = value.substr(5, 2);
  const day = value.substr(8, 2);
  return format(new Date(year, month, day), formato);
};

export const createNullDate = () => {
  let date = new Date();
  date.setMinutes(0);
  date.setHours(0);
  date.setSeconds(0);
  return date;
};

export const createDateFromText = (pDate, pformat) => {
  let date = new Date();
  let format = pformat.toUpperCase();
  format = format.replaceAll('-', '/');
  switch (format) {
    case 'YYYY/MM/DD':
      date.setFullYear(pDate.substr(0, 4), pDate.substr(5, 2), pDate.substr(8, 2));
      break;
    case 'YY/MM/DD':
      date.setFullYear(pDate.substr(0, 2), pDate.substr(3, 2), pDate.substr(6, 2));
      break;
    case 'DD/MM/YYYY':
      date.setFullYear(pDate.substr(6, 4), pDate.substr(3, 2), pDate.substr(0, 2));
      break;
    case 'DD/MM/YY':
      date.setFullYear(pDate.substr(6, 2), pDate.substr(3, 2), pDate.substr(0, 2));
      break;
    case 'MM/DD/YYYY':
      date.setFullYear(pDate.substr(6, 4), pDate.substr(0, 2), pDate.substr(3, 2));
      break;
    case 'MM/DD/YY':
      date.setFullYear(pDate.substr(6, 2), pDate.substr(0, 2), pDate.substr(3, 2));
      break;
    default:
      date.setFullYear(pDate.substr(0, 4), pDate.substr(5, 2), pDate.substr(8, 2));
  }
  return date;
};
