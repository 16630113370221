import React, { Component } from 'react';
import Draggable from 'react-draggable';
import Grid from './Grid';
import { connect } from 'unistore/react';
import FocusTrap from 'react-focus-trap';
import { storeActions, store, dispatchActions } from '../../src/store';
import { FormattedMessage } from 'react-intl';
import { ABOVE_ALL } from '../constants/zIndexValues';
import IcoMoon from 'react-icomoon';
import { getCurrentTabInformation } from '../../src/selectors';
import Overlay from './Overlay';

export class FormulaHelpWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueSelected: '',
    };

    this.acceptRef = React.createRef();
    this.cancelRef = React.createRef();
    this.focusOnAccept = this.focusOnAccept.bind(this);
  }

  focusOnAccept() {
    if (this.acceptRef && this.acceptRef.current) {
      this.acceptRef.current.focus();
    }
  }

  componentDidMount() {
    const { pauseFocus } = this.props;
    pauseFocus();
  }

  componentWillUnmount() {
    const { resumeFocus } = this.props;
    resumeFocus();
  }

  render() {
    let {
      nodeId,
      cancelHandler,
      fieldNumber,
      acceptHandler,
      transactionLineLabel,
      inputValue,
      lineNumber,
      transactionNumber,
      focusOn,
      showingHelp,
    } = this.props;
    let hasFocus = focusOn && focusOn[0] === transactionLineLabel;

    const buttonStyles = {
      bottom: 0,
      width: '100%',
    };

    const position = { x: 10, y: 15, width: 'auto', height: 'auto' };
    const gridStyle = { width: '480px', height: '250px' };

    if (!showingHelp) {
      return null;
    }
    return (
      <FocusTrap>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable bounds="body" onStart={() => hasFocus} handle=".handleDragg">
          <div
            className={`border border-solid bg-grey-lighter-lighter rounded shadow min-w-400 min-h-200 absolute ${
              hasFocus ? 'border-primary' : 'border-transparent'
            }`}
            style={{
              top: 10,
              left: 40,
              width: '500px',
              height: '305px',
              zIndex: ABOVE_ALL,
            }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move" style={{ padding: 1.5 }} />
            <Grid
              nodeId={nodeId}
              fieldNumber={fieldNumber}
              style={gridStyle}
              position={position}
              fromHelp={true}
              inputValue={inputValue}
              onChange={(e) => {
                this.setState({ valueSelected: e.target.value });
              }}
              transactionNumber={transactionNumber}
              lineNumber={lineNumber}
              transactionLineLabel={transactionLineLabel}
              acceptButtonRef={this.acceptRef}
              onHelpSelect={(event) => {
                this.setState({ valueSelected: event });
                // eslint-disable-next-line prettier/prettier
                acceptHandler({ [transactionLineLabel]: { value: event.length === 1 ? event[0] : event } });
              }}
              cancelHandlerHelp={cancelHandler}
              focusedTable
            />
            <div className="absolute flex justify-end" style={buttonStyles}>
              <button
                autoFocus
                ref={this.acceptRef}
                className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                onClick={() => {
                  if (this.state.valueSelected === null || (this.state.valueSelected && this.state.valueSelected.length === 0)) {
                    store.setState(
                      dispatchActions.openGlobalModal(
                        store.getState(),
                        <FormattedMessage id="incorrectRow" defaultMessage={`La fila seleccionada no es correcta`} />,
                      ),
                    );
                  } else {
                    acceptHandler(this.state.valueSelected);
                  }
                }}
                onBlur={(e) => {
                  if (
                    e.relatedTarget === null ||
                    e.relatedTarget.classList.contains('focus-trap') ||
                    e.relatedTarget.classList.contains('contorno')
                  ) {
                    this.acceptRef.current.focus();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    cancelHandler();
                  }
                }}
              >
                <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
              </button>
              <button
                className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                onClick={() => cancelHandler()}
                onBlur={(e) => {
                  if (
                    e.relatedTarget === null ||
                    e.relatedTarget.classList.contains('focus-trap') ||
                    e.relatedTarget.classList.contains('contorno')
                  ) {
                    this.cancelRef.current.focus();
                  }
                }}
                ref={this.cancelRef}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') cancelHandler();
                }}
              >
                <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />
              </button>
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );
  }
}

const mapStateToProps = (state) => {
  const { focusOn } = getCurrentTabInformation(state);

  return {
    focusOn,
  };
};

export default connect(mapStateToProps, storeActions)(FormulaHelpWindow);
