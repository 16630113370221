import React from 'react';
import { connect } from 'unistore/react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import TopazDisplay from './TopazDisplay';
import { store, storeActions } from '../store';
import EmptyTab from './EmptyTab';
import { injectIntl, FormattedMessage } from 'react-intl';
import { setRecentOperationsCollapsibleIsOpen } from '../utils/browserStorage/services';
import NewTabButton from '../../UI-kit/components/NewTabButton';
import Tab from './Tab';
import { tabIndexOf } from '../selectors';
import { resume, cancel } from '../api/operation';

class TabsOperations extends React.Component {
  constructor(props) {
    super(props);

    this.handleExtraButton = this.handleExtraButton.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleRemoveTab = this.handleRemoveTab.bind(this);
    this.handleAddNewTab = this.handleAddNewTab.bind(this);
    this.toggleRecentOperationsCollapse = this.toggleRecentOperationsCollapse.bind(this);
  }

  componentDidUpdate() {
    const state = store.getState();
    if (state.redirectOnNextRender && !state.apiLoading && !state.startingOperation) {
      this.props.history.push(state.redirectOnNextRender);
    }
  }

  handleExtraButton() {
    const { addEmptyTab } = this.props;
    addEmptyTab();
  }

  showTabBlockedMessage(tabClaimed) {
    // this function is invoked when the tab claimed to access is blocked by another tab
    const { intl, tabs } = this.props;
    const { lockedByTab } = tabs[tabClaimed];
    const lockedIndex = tabIndexOf({ tabId: lockedByTab }, tabs);
    const lockedByOpNumber = tabs[lockedIndex].operationNumber;
    let message = `${intl.formatMessage({
      id: 'tabIsBlocked',
      defaultMessage: 'Esta pestaña está bloqueada por la operación',
    })} ${lockedByOpNumber}. ${intl.formatMessage({
      id: 'closeBlockingTabToAccess',
      defaultMessage: 'Si desea acceder a esta pestaña, finalice la operación que la bloquea.',
    })}`;
    this.props.openGlobalModal(message);
  }

  handleTabChange(tabId) {
    const { setCurrentTab, refreshFocusElement, tabs } = this.props;
    const tabClaimed = tabIndexOf({ tabId }, tabs); // the tab to which access was requested
    if (tabs[tabClaimed].lockedByTab && tabs[tabClaimed].lockedByTab !== null) {
      this.showTabBlockedMessage(tabClaimed);
    } else {
      setCurrentTab(parseInt(tabClaimed, 10));
      setTimeout(() => refreshFocusElement(), 100);
    }
  }

  handleAddNewTab(operationNumber, operationName, operationLook) {
    const { addTab } = this.props;
    addTab(operationNumber, operationName, '', true, operationLook, false, null, null, false, false, false, null, null);
  }

  toggleRecentOperationsCollapse() {
    this.props.toggleRecentOperationsCollapse(); // toggle in store
    setRecentOperationsCollapsibleIsOpen(!this.props.recentOperationsCollapsibleIsOpen); // toggle in browserStorage
  }

  async handleRemoveTab(index, e) {
    const { removeTab, getParentOperationID, processContext } = this.props;
    const tabIndex = parseInt(index, 10);
    const parentOperationID = getParentOperationID(tabIndex);
    removeTab(tabIndex);
    if (parentOperationID) {
      const currentOperationID = this.props.tabs[tabIndex].running.operationID;
      if (currentOperationID !== undefined) {
        await cancel(currentOperationID);
      }
      const RESULT_FINISH = 0;
      const resumeResponse = await resume(parentOperationID, RESULT_FINISH);
      if (resumeResponse.lista && resumeResponse.lista.length) {
        processContext(resumeResponse, 'Resume');
      }
    }
  }

  construct() {
    const { tabs, addEmptyTab, recentOperationsCollapsibleIsOpen } = this.props;
    const newTabMessage = <FormattedMessage id="newTab" defaultMessage={`Nueva operación`} />;
    if (tabs.filter((t) => !t.hiddenTab).length === 0) {
      addEmptyTab();
      return null;
    }
    if (tabs.filter((t) => t.emptyTab).length > 1) {
      const firstEmptyTab = tabs.find((t) => t.emptyTab);
      const { removeTab } = this.props;
      removeTab(firstEmptyTab.tabID);
    }

    return tabs.map((t, index) => {
      const removable = !t.emptyTab || index !== 0;
      if (t.hiddenTab) {
        return null;
      }
      return (
        <TabPane
          key={t.id}
          tab={
            <Tab
              handleRemoveTab={this.handleRemoveTab}
              isEmptyTab={t.emptyTab}
              loadingBegin={t.loadingBegin}
              newTabMessage={newTabMessage}
              removable={removable}
              tabData={t}
              tabIndex={index}
              tabName={t.operationName}
              tabNumber={t.operationNumber}
            />
          }
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100vh - 62px)',
          }}
        >
          <div className="">
            {!t.emptyTab ? (
              <TopazDisplay
                tabId={t.id}
                operationLook={t.utilizaEstilo}
                operationNumber={t.operationNumber}
                operationName={t.operationName}
                transactionNumber={t.transactionNumber}
                transactionDescription={t.transactionDescription}
                TZPCAPTParameters={t.TZPCAPTParameters}
              />
            ) : (
              <EmptyTab
                itemsToShow={'OPERATIONS'}
                handleAddNewTab={this.handleAddNewTab}
                recentItemsCollapsibleIsOpen={recentOperationsCollapsibleIsOpen}
                handleRecentItemsCollapsibleClick={this.toggleRecentOperationsCollapse}
              />
            )}
          </div>
        </TabPane>
      );
    });
  }

  render() {
    const { current, tabs } = this.props;
    const activeKey = tabs[current] ? tabs[current].id : '';

    return (
      <Tabs
        renderTabBar={() => <ScrollableInkTabBar extraContent={<NewTabButton handleClick={this.handleExtraButton} />} />}
        renderTabContent={() => <TabContent animated={false} />}
        activeKey={activeKey}
        onChange={this.handleTabChange}
      >
        {this.construct()}
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tabs: state.tabs,
    current: state.current,
    recentOperationsCollapsibleIsOpen: state.menuGridInfo.recentOperationsCollapsibleIsOpen,
    redirectOnNextRender: state.redirectOnNextRender,
    getParentOperationID: (tabIndex) => {
      const tab = state.tabs[tabIndex];
      if (!tab.overwritePosition) {
        return tab && tab.parentOperationID;
      } else {
        const indexOfParentTab = tabIndexOf({ tabId: tab.parentTabId }, state.tabs);
        if (indexOfParentTab > -1 && state.tabs[indexOfParentTab]) {
          return state.tabs[indexOfParentTab].parentOperationID;
        } else {
          return -1;
        }
      }
    },
  };
};

export default connect(mapStateToProps, storeActions)(injectIntl(TabsOperations));
