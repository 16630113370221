import React, { useState, useEffect } from 'react';
import { getDescripciones, getDescripcionesTableFiltro } from '../../../api/schema.js';
import { PulseLoader } from 'halogenium';
import { useIntl } from 'react-intl';

export const HelpCampoComponent = (props) => {
  const [result, setResult] = useState([]);
  const [selectedValue, setSelectedValue] = useState(props.currentValue);
  const [dataDesc, setData] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    getDescripciones().then(function (response) {
      setResult(response);
    });
    selectData(0);
  }, []);

  useEffect(() => {
    if (props.forceSelectHelp) {
      handleWindowSelect(selectedValue);
    }
  });

  const selectData = (data) => {
    getDescripcionesTableFiltro(data).then(function (response) {
      setData(response);
    });
  };

  const handleClick = (option) => {
    setSelectedValue(option);
  };

  const handleDoubleClick = (value) => {
    handleWindowSelect(value);
  };

  const handleWindowSelect = (value) => {
    props.setHelpValue(value);
  };

  return (
    <div>
      {dataDesc && Array.isArray(dataDesc) && dataDesc.length > 0 ? (
        <div className="container">
          <div className="row" style={{ display: 'flex' }}>
            <div className="col-3">
              <label>Descriptor</label>
            </div>
            <div className="col-9 mx auto">
              <select
                style={{ marginLeft: '20px', border: '1px solid grey', borderRadius: '4px' }}
                onChange={(event) => selectData(event.target.value)}
                id="slcDescriptor"
                name="slcDescriptor"
                required
              >
                <option key="0" value="0">
                  {intl.formatMessage({
                    id: 'freeFields',
                    defaultMessage: '0-Campos libres',
                  })}
                </option>
                {result && Array.isArray(result) && result.length > 0 ? (
                  result.map((option) => (
                    <option key={option[0]} value={option[0]}>
                      {option[0]}-{option[1]}
                    </option>
                  ))
                ) : (
                  <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
                )}
              </select>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow-md sm:rounded-lg">
                  <div className="col" style={{ overflowY: 'scroll', maxHeight: '200px', minHeight: '100px', width: '550px', marginTop: '5px' }}>
                    <table className="default" style={{ fontSize: '12px', marginTop: '20px' }}>
                      <thead>
                        <tr>
                          <th scope="col">
                            {intl.formatMessage({
                              id: 'numero',
                              defaultMessage: 'Número',
                            })}
                          </th>
                          <th scope="col">
                            {intl.formatMessage({
                              id: 'descripcion',
                              defaultMessage: 'Descripcion',
                            })}
                          </th>
                          <th scope="col">
                            {intl.formatMessage({
                              id: 'tipo',
                              defaultMessage: 'Tipo',
                            })}
                          </th>
                          <th scope="col">
                            {intl.formatMessage({
                              id: 'freeFields',
                              defaultMessage: 'Long',
                            })}
                          </th>
                          <th scope="col">
                            {intl.formatMessage({
                              id: 'decimals',
                              defaultMessage: 'Decimales',
                            })}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataDesc.map((option, index) => {
                          return (
                            <tr
                              style={option === selectedValue ? { backgroundColor: '#9ABAF5' } : { backgroundColor: 'white' }}
                              key={option[0]}
                              onClick={() => handleClick(option)}
                              onDoubleClick={() => handleDoubleClick(option)}
                              className={index % 2 === 0 ? 'table-active' : ''}
                            >
                              <td>{option[0]}</td>
                              <td>{option[1]}</td>
                              <td>{option[2]}</td>
                              <td>{option[3]}</td>
                              <td>{option[4]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container" style={{ maxHeight: '300px', minHeight: '100px', width: '600px' }}>
          <div className="m-0 row justify-content-center">
            <div className="col-auto p-5 text-center">
              <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
