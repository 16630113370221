import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { useIntl } from 'react-intl';

import { useGlobalStyles } from './../../../../styles/GlobalStylesContext';
import Field from './Field';

const CheckNumberInput = ({ checkNumber, onCheckNumberChange }) => {
  const intl = useIntl();

  const placeholderCheckNumber = intl.formatMessage({
    id: 'checkNumber',
    defaultMessage: 'Número de cheque',
  });

  const styles = StyleSheet.create({
    checkNumberInput: {
      width: '10rem',
    },
  });

  const globalStyles = useGlobalStyles();

  return (
    <Field labelText={placeholderCheckNumber} htmlFor="checkNumber">
      <input
        type="number"
        id="checkNumber"
        name="checkNumber"
        className={css(globalStyles.input, globalStyles.numberInput, styles.checkNumberInput)}
        placeholder={placeholderCheckNumber}
        value={checkNumber}
        onChange={(event) => onCheckNumberChange(event.target.value)}
        onFocus={(e) => e.target.select()}
        // decimalScale={0} // allow only integers
      />
    </Field>
  );
};

export default CheckNumberInput;
