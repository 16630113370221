export function getButtonsState(length, firstResult, doesNotImplementCountEndpoint) {
  let maxResults = 201;
  let buttonNextState = false;
  let buttonPrevState = false;
  if (length === 0) {
    if (firstResult > 0) {
      buttonNextState = true;
      buttonPrevState = false;
    } else if (firstResult === 0) {
      buttonNextState = true;
      buttonPrevState = true;
    }
  } else if ((doesNotImplementCountEndpoint && length === maxResults) || (!doesNotImplementCountEndpoint && length > firstResult + maxResults)) {
    if (firstResult === 0) {
      buttonNextState = false;
      buttonPrevState = true;
    } else if (firstResult > 0) {
      buttonNextState = false;
      buttonPrevState = false;
    }
  } else {
    if (firstResult === 0) {
      buttonNextState = true;
      buttonPrevState = true;
    } else if (firstResult > 0) {
      buttonNextState = true;
      buttonPrevState = false;
    }
  }

  return [buttonNextState, buttonPrevState];
}
