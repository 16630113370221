import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import Switch from 'react-switch';

import { useTheme } from './../../../themes/ThemeContext';

const ValidateSignaturesSwitch = ({ active, onChange }) => {
  const themeContext = useTheme();
  const { text } = themeContext.theme;

  const styles = StyleSheet.create({
    switchContainer: {
      margin: '3rem 0 1rem',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
    },
    switchLabel: {
      display: 'flex',
      alignItems: 'center',
      color: text.dark,
      fontSize: '1.2rem',
    },
    reactSwitch: {
      marginLeft: '1rem',
    },
  });
  return (
    <div className={css(styles.switchContainer)}>
      <label className={css(styles.switchLabel)}>
        <span>Ver Firmas</span>
        <Switch onChange={onChange} checked={active} className={css(styles.reactSwitch)} height={24} width={48} />
      </label>
    </div>
  );
};

export default ValidateSignaturesSwitch;
