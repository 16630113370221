import React from 'react';
import { useIntl } from 'react-intl';

import groupIcon from '../../../icons/group';

import { NO_MODAL_OPEN } from '../../../models/constants';
import { Group } from '../../../models/group';
import { RestrictionsViewModel } from '../restrictions/RestrictionsViewModel';
import { GroupErrors } from './GroupInfoModalViewModel';
import '../SecurityToolsModal.scss';
import { GroupsAsTreeMenu } from '../../../models/groupsAsTreeMenu';
import { RestrictionObject } from '../../../models/restrictionObject';
import QuestionMarkIcon from '../../questionMark/QuestionMark';

export interface SecurityAddGroupModalViewProps {
  selectedGroup: GroupsAsTreeMenu | undefined;
  onModalChange: (modalOpen: string) => void;
  onAccept: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement) => void;
  openModalHelper: (currentProperty: string) => void;
  onRestrictionsChangedAdd: (restriction: RestrictionObject[]) => void;
  onRestrictionsChangedRemove: (restriction?: RestrictionObject[]) => void;
  onHelperChange: (helper: string) => void;
  group: Group;
  errors: GroupErrors;
  isEditing?: boolean;
  restrictionsChanged: {
    [id: string]: RestrictionObject
  };
  helperGroup: string;
  changesWarning: boolean;
  hasGroupPermissions: boolean;
  onRefresh: () => void;
  setChangesWarning: (isShowing: boolean) => void;
  onChangesWarningChange: (isShowing: boolean, undoChanges: boolean) => void;
  setRestrictionsHasErrors: (hasErrors: boolean) => boolean;
  hasRestrictionsErrors: boolean;
  setIsLoadingGroup: (isLoading: boolean) => void;

}

export function GroupInfoModalView({
  selectedGroup,
  onModalChange,
  openModalHelper,
  onChange,
  group,
  onAccept,
  errors,
  isEditing,
  onRestrictionsChangedAdd,
  onRestrictionsChangedRemove,
  restrictionsChanged,
  onHelperChange,
  helperGroup,
  changesWarning,
  hasGroupPermissions,
  onRefresh,
  setChangesWarning,
  onChangesWarningChange,
  setRestrictionsHasErrors,
  restrictionsHasErrors,
  setIsLoadingGroup

}: SecurityAddGroupModalViewProps): JSX.Element | null {
  const intl = useIntl();

  function onCloseChange(event: React.MouseEvent): void {
    event.preventDefault(); 
    onModalChange(NO_MODAL_OPEN);
    onRestrictionsChangedRemove();
    onChangesWarningChange(false, true);

    if (onRefresh) {
      onRefresh();
    }
  }

  function openHelper(propertyToModify: string): () => void {
    return () => {
      openModalHelper(propertyToModify);
    };
  }

  function handleCheckboxChange(event: { target: HTMLInputElement; value?: boolean }): void {
    onChange({ target: { ...event.target, value: event.target.checked + '', name: event.target.name } });
  }

  function onAcceptClick(event: React.MouseEvent): void {
    event.stopPropagation();
    setIsLoadingGroup(true);
    if (!restrictionsHasErrors) {
      onAccept();
      setChangesWarning(false);
      onChangesWarningChange(false, true);
    }
  }

  return (
    <div className="font-mono flex group-info-modal" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center full-width">
        <div className="fixed"></div>
        <span className="hidden" aria-hidden="true">
          &#8203;
        </span>
        <div className="bg-white rounded-lg shadow-xl border shadow-sm full-width">
          <div className={`bg-blue-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ${changesWarning && 'blur-info'}`}>
            <div className="flex items-center mt-3 text-center sm:mt-0 sm:text-left">
              <div className="flex-initial">{groupIcon}</div>
              <h3 className="flex-initial text-lg leading-6 font-medium text-gray-900 m-2" id="modal-title">
                {intl.formatMessage({ id: 'Grupo' })}
              </h3>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:text-left ">
              <div className="field-container field-separator">
                <label className="pr-1 mb-1">{intl.formatMessage({ id: 'Grupo' })}:</label>
                <div className="input-with-help-group">
                  <input
                    className="border border-grey-lighter"
                    disabled={!hasGroupPermissions || isEditing}
                    value={group.group || ''}
                    name="group"
                    onChange={onChange}
                  />
                </div>
                {errors?.response && <label className="pt-2 text-red flex">{errors?.response}</label>}
                {errors?.group && <label className="pt-2 text-red flex">{errors?.group}</label>}
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:text-left" >
                <fieldset className="rounded border border-light flex relative" style={{ border: '1px solid grey' }}>
                  <legend className="mb-2 ml-2">{intl.formatMessage({ id: 'generalData' })}</legend>
                  <div className="fields-container">
                    <div className="field-container">
                      <label className="pr-1 mb-1">{intl.formatMessage({ id: 'description' })}:</label>
                      <input
                        className="border border-grey-lighter group-description-input"
                        value={group.description || ''}
                        disabled={!hasGroupPermissions}
                        name="description"
                        onChange={onChange}
                        maxLength={30}
                      />
                      {errors?.description && <label className="pr-1 pt-2 text-red flex">{errors?.description}</label>}
                    </div>
                    <div className="field-container">
                      <label className="pr-1 mb-1">{intl.formatMessage({ id: 'baseGroup' })}:</label>
                      <div className="input-with-help-group">
                        <input
                          className="border border-grey-lighter"
                          value={group.baseGroup || ''}
                          disabled={!hasGroupPermissions}
                          name="baseGroup"
                          onChange={onChange}
                        />
                        <QuestionMarkIcon disabled={!hasGroupPermissions} className="button-helper" click={openHelper('baseGroup')} />
                      </div>
                      {errors?.baseGroup && <label className="pr-1 pt-2 text-red flex">{errors?.baseGroup}</label>}
                    </div>
                    <div className="field-container">
                      <label className="pr-1 mb-1">{intl.formatMessage({ id: 'authorization' })}:</label>
                      <div className="input-with-help-group">
                        <input
                          className="border border-grey-lighter"
                          disabled={!hasGroupPermissions}
                          value={group.authorization || ''}
                          name="authorization"
                          onChange={onChange}
                        />
                        <QuestionMarkIcon disabled={!hasGroupPermissions} className="button-helper" click={openHelper('authorization')} />
                      </div>
                    </div>
                    <div className="field-container">
                      <label className="pr-1 mb-1">{intl.formatMessage({ id: 'confirmation' })}:</label>
                      <div className="input-with-help-group">
                        <input
                          className="border border-grey-lighter"
                          disabled={!hasGroupPermissions}
                          value={group.confirmation || ''}
                          name="confirmation"
                          onChange={onChange}
                        />
                        <QuestionMarkIcon disabled={!hasGroupPermissions} className="button-helper" click={openHelper('confirmation')} />
                      </div>
                    </div>
                    <div className="p-2" style={{ width: '50%' }}>
                      <label className="pr-1 mb-1">{intl.formatMessage({ id: 'inheritsMessages' })}</label>
                      <input
                        className="field-checkbox"
                        type="checkbox"
                        disabled={!hasGroupPermissions}
                        defaultChecked={JSON.parse(group.areMessagesInherited || 'false')}
                        name="areMessagesInherited"
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            { }
            <RestrictionsViewModel
              hasGroupPermissions={hasGroupPermissions}
              helperGroup={helperGroup}
              onHelperChange={onHelperChange}
              restrictionsChanged={restrictionsChanged}
              selectedGroup={selectedGroup}
              isEditing={isEditing}
              onRestrictionsChangedAdd={onRestrictionsChangedAdd}
              onRestrictionsChangedRemove={onRestrictionsChangedRemove}
              setRestrictionsHasErrors={setRestrictionsHasErrors}
            />
          </div>
          {isEditing && (
            <div className="text-area-container">
              <label className="pr-1 mb-1">{intl.formatMessage({ id: 'reason' })}:</label>
              <div className="input-with-help-button">
                <textarea className="border border-grey-lighter" value={group.reason || ''} name="reason" onChange={onChange} />
              </div>
            </div>
          )}
          <div className="w-full flex justify-end">
            <button
              type="button"
              onClick={onAcceptClick}
              disabled={!hasGroupPermissions}
              className="mr-3 mb-3 mt-3 w-24 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm"
            >
              {isEditing ? intl.formatMessage({ id: 'modify' }) : intl.formatMessage({ id: 'accept' })}
            </button>
            <button
              type="button"
              onClick={onCloseChange}
              className="mr-6 mb-3 mt-3 w-24 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm"
            >
              {intl.formatMessage({ id: 'cancel' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}