import { css, StyleSheet } from 'aphrodite';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { connect } from 'unistore/react';
import { storeActions } from '../../../src/store';
import CheckDetails from './CheckDetails/CheckDetails';
import ClearingView from './ClearingView';
import { Filters } from './Constants';

const ClearingViewModel = (props) => {
  const intl = useIntl();
  const match = useRouteMatch();

  const [filterSelected, setFilterSelected] = useState(Filters.GROUP_BY_ACCOUNT_SORT_BY_AMOUNT);
  const [micrLine, setMicrLine] = useState({});
  const [selectedCheck, setSelectedCheck] = useState({});

  const changeSelectedFilter = (newSelectedFilter) => {
    setFilterSelected(newSelectedFilter);
    props.emptyChecks();
  };

  const filters = [
    {
      id: Filters.ACCEPTED,
      queryName: 'digitalclearing.query_ChequesFiltroAceptados',
      apiName: 'Aceptados',
      label: intl.formatMessage({
        id: Filters.ACCEPTED,
        defaultMessage: 'Aceptados Controles Automáticos',
      }),
    },
    {
      id: Filters.ACCEPTED_BY_MANAGEMENT,
      queryName: 'digitalclearing.query_ChequesFiltroAceptadosXGerencia',
      apiName: 'Aceptados por gerencia',
      label: intl.formatMessage({
        id: Filters.ACCEPTED_BY_MANAGEMENT,
        defaultMessage: 'Aceptados Ventana Diaria',
      }),
    },
    {
      id: Filters.REFUSED,
      queryName: 'digitalclearing.query_ChequesFiltroRechazados',
      apiName: 'Rechazados',
      label: intl.formatMessage({
        id: Filters.REFUSED,
        defaultMessage: 'Rechazados',
      }),
    },
    {
      id: Filters.REFUSED_DIGITAL_CLEARING,
      queryName: 'digitalclearing.query_ChequesFiltroRechazadosClearingDigital',
      apiName: 'Rechazados clearing digital',
      label: intl.formatMessage({
        id: Filters.REFUSED_DIGITAL_CLEARING,
        defaultMessage: 'Rechazados clearing digital',
      }),
    },
    {
      id: Filters.A_SPECIFIC_CHECK,
      queryName: 'digitalclearing.query_ChequesFiltroXNROCheque',
      apiName: 'Cheque en particular',
      label: intl.formatMessage({
        id: Filters.A_SPECIFIC_CHECK,
        defaultMessage: 'Cheque en particular',
      }),
    },
    {
      id: Filters.BY_ACCOUNT,
      queryName: 'digitalclearing.query_ChequesFiltroXCuenta',
      apiName: 'Por cuenta',
      label: intl.formatMessage({
        id: Filters.BY_ACCOUNT,
        defaultMessage: 'Por cuenta',
      }),
    },
  ];

  const styles = StyleSheet.create({
    container: {
      padding: '2rem 1rem 0',
      height: '100%',
    },
  });

  return (
    <div className={css(styles.container)}>
      <Switch>
        <Route path={`${match.path}/check`}>
          <CheckDetails micrLine={micrLine} selectedCheck={selectedCheck} />
        </Route>
        <Route path={match.path}>
          <ClearingView
            filters={filters}
            setFilterSelected={changeSelectedFilter}
            filterSelected={filterSelected}
            setMicrLine={setMicrLine}
            setSelectedCheck={setSelectedCheck}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default connect(null, storeActions)(ClearingViewModel);
