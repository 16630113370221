import {
  createHelpSession,
  getHelpMetadata,
  initHelp,
  getNext,
  initHelpWithFilter,
  closeHelp,
  getHelpSize,
  getHelpData,
  getHelpDataWithFilter,
} from '../../src/api/help';

const DATE_TYPE = 11;
const TYPE_FIELD_DATE = 4;

export let getColumns = async (operationID, fieldNumber, filterOptions) => {
  let data = {
    columns: [],
    accesors: [],
    types: [],
    returned: '',
    numberOfReturned: 0,
    isOptions: false,
    helpSize: 101,
    filterOptions,
  };
  let size = (await getHelpSize()) + 1;
  let helpSessionId = await createHelpSession(operationID, size);
  let { fieldInfos, showAll, isOptions, description } = await getHelpMetadata(helpSessionId, fieldNumber);
  data.isOptions = isOptions;
  data.showAll = showAll;
  data.title = description;
  data.helpSize = size;
  if (helpSessionId > 0) {
    data.helpSessionId = helpSessionId;
    fieldInfos.forEach((element) => {
      if (element.helpField.isReturned) {
        data.returned = element.helpField.fieldNumber.toString();
        data.numberOfReturned = data.numberOfReturned + 1;
      }
      data.columns.push({
        accessor: element.helpField.fieldNumber.toString(),
        Header: element.helpField.columnName,
        isSortable: element.helpField.isSortable,
        isReturned: element.helpField.isReturned,
        length: element.inputParameters.length,
        decimals: element.inputParameters.decimals,
        type: element.inputParameters.type,
        edition: element.inputParameters.edition,
      });
      data.accesors.push(element.helpField.fieldNumber.toString());
      data.types.push(element.inputParameters.type);
    });
  }
  return data;
};

export let getRows = async (helpSessionId, numOfRecords, accesors, fieldNumber, showAll, filterOptions, types, isOptions) => {
  let data = {
    rows: [],
    filterOptions,
  };
  if (helpSessionId > 0) {
    try {
      if (filterOptions && filterOptions.value && isOptions === false) {
        let { field, operator, value } = filterOptions;
        let res = await initHelpWithFilter(helpSessionId, fieldNumber, field, operator, value, showAll);
        if (res.message !== '1') {
          await initHelp(helpSessionId, fieldNumber, showAll);
        }
      } else {
        await initHelp(helpSessionId, fieldNumber, showAll);
      }
      let next = true;
      next = await getNext(helpSessionId, numOfRecords);
      if (next !== null) {
        next.forEach((rows) => {
          data.rows.push(getRowObject(rows, accesors, types));
        });
      }

      await closeHelp(helpSessionId);
    } catch (error) {
      await closeHelp(helpSessionId);
      throw new Error(error);
    }
  }
  return data;
};

export let getRowsV2 = async (helpSessionId, numOfRecords, accesors, fieldNumber, showAll, filterOptions, types, isOptions) => {
  let data = {
    rows: [],
    filterOptions,
  };
  if (helpSessionId > 0) {
    try {
      let resData;
      if (filterOptions && filterOptions.value && isOptions === false) {
        let { field, operator, value } = filterOptions;
        resData = await getHelpDataWithFilter(helpSessionId, fieldNumber, field, operator, value, showAll, numOfRecords);
      } else {
        resData = await getHelpData(helpSessionId, fieldNumber, showAll, numOfRecords);
      }
      if (resData) {
        resData.forEach((rows) => {
          data.rows.push(getRowObject(rows, accesors, types));
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  }
  return data;
};

let getRowObject = (rows, accesors, types) => {
  let rowObject = {};
  if (rows.length === accesors.length) {
    let i;
    for (i = 0; i < rows.length; i++) {
      rowObject[accesors[i]] = formatValue(rows[i].value, rows[i].classNameValue, types[i]);
    }
  }
  return rowObject;
};

const pad = (number) => `0${number}`.slice(-2);

const dateFormat = (value) => {
  const valueDate = new Date(value);
  const year = valueDate.getFullYear();
  const month = valueDate.getMonth() + 1;
  const day = valueDate.getDate();
  return `${pad(day)}/${pad(month)}/${year}`;
};

const formatValue = (value, classNameValue, type) => {
  if (type === TYPE_FIELD_DATE && classNameValue === -1) return '00/00/0000';
  switch (classNameValue) {
    case DATE_TYPE:
      return dateFormat(value);
    default:
      return value;
  }
};
