import { useEffect, useState } from 'react';

import { getGroupNavigationsAsync } from '../../../../../../api/securityManager';
import { DEFAULT_RESTRICTION_KEY } from '../../../../models/constants';
import { RestrictionObject } from './useMenu.hook';
import { Navigations, useRestrictions } from './useRestrictions.hook';

const HEADER_IDENTIFICATION = 0;

interface UseNavigations {
  navigations: NavigationsTree[];
}

interface NavigationService {
  descripcion: string;
  codigo_seccion: number;
  codigo_navegacion: number;
}

export interface NavigationGroupService {
  permite: number;
  grupo: string;
  codigo_navegacion: number;
  codigo_seccion: number;
}

export interface NavigationsTree {
  key: string;
  label: string;
  nodes: NavigationsTree[];
  restriction: RestrictionObject;
}

export const NAVIGATIONS_TYPE: string = 'N';

export function useNavigations(parentGroup: string, group: string): UseNavigations {
  const [navigationOptions, setNavigationOptions] = useState<NavigationsTree[]>([]);
  const { fillNumberWithZeros, getNavigationRestrictionsAsync } = useRestrictions();

  async function getNavigationsAsync(): Promise<void> {
    const [navigations, titleRestrictions, ownRestrictions] = await Promise.all<NavigationService[], Navigations, Navigations>([
      getGroupNavigationsAsync(),
      getNavigationRestrictionsAsync(parentGroup),
      getNavigationRestrictionsAsync(group),
    ]);

    const options: NavigationsTree[] = [];
    let lastHeader: NavigationsTree | null = null;
    const exists: boolean = Object.keys(titleRestrictions).length !== 0 && Object.keys(ownRestrictions).length !== 0;

    navigations.forEach((navigationOption, index) => {
      const isHeaderIdentification = navigationOption.codigo_seccion === HEADER_IDENTIFICATION;
      const restrictionKey = fillNumberWithZeros(navigationOption.codigo_navegacion) + fillNumberWithZeros(navigationOption.codigo_seccion);
      const permissions: RestrictionObject = {
        ownPermission: ownRestrictions[restrictionKey] !== undefined ? ownRestrictions[restrictionKey].allow : null,
        parentPermission: null,
        titlePermission: !exists
          ? 0
          : titleRestrictions[restrictionKey] !== undefined
          ? titleRestrictions[restrictionKey].allow
          : titleRestrictions[DEFAULT_RESTRICTION_KEY]?.allow,
        type: NAVIGATIONS_TYPE,
        allows: ownRestrictions[restrictionKey] !== undefined ? ownRestrictions[restrictionKey].allow : null,
        id: restrictionKey,
        sectionCode: navigationOption.codigo_seccion,
        navigationCode: navigationOption.codigo_navegacion,
      };

      const navigationNode = {
        key: `${index} ${navigationOption.descripcion}`,
        label: `${navigationOption.codigo_navegacion} - ${navigationOption.descripcion}`,
        nodes: [],
        restriction: permissions,
      };

      if (isHeaderIdentification) {
        lastHeader = navigationNode;
        options.push(navigationNode);
      } else {
        navigationNode.restriction.parentPermission = lastHeader ? lastHeader?.restriction.ownPermission : null;
        lastHeader?.nodes.push(navigationNode);
      }
    });

    setNavigationOptions(options);
  }

  useEffect(() => {
    getNavigationsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return {
    navigations: navigationOptions,
  };
}
