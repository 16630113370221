import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useTheme } from '../../themes/ThemeContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { ABOVE_ALL } from '../../../UI-kit/constants/zIndexValues';

const ConfirmPasswordView = (props) => {
  const { onConfirmChange, onPasswordChange, inputPassword, inputConfirm, handleSave, handleCancel, fromNewLogin } = props;

  const Button = ({ title, click, className, disabled = false }) => {
    return (
      <button
        className={`${className} ${
          disabled && 'opacity-75'
        } bg-grey hover:bg-primary focus:bg-primary text-white rounded shadow m-2 py-2 px-4 text-sm`}
        onClick={click}
        disabled={disabled}
      >
        {title}
      </button>
    );
  };
  /*
   * Styles
   */
  const themeState = useTheme();
  const { primary, background, border, text } = themeState.theme;
  const styles = StyleSheet.create({
    container: {},
    inputFormContainer: {
      marginBottom: '1.5rem',
      marginLeft: '1.5rem',
    },
    inputForm: {
      width: '90%',
      padding: '.65rem',
      borderWidth: 2,
      backgroundColor: background.background,
      borderColor: border.border,
      color: text.text,
      borderRadius: 4,
      transition: '.2s',
      ':hover': {
        backgroundColor: background.dark,
        borderColor: border.dark,
      },
      ':focus': {
        backgroundColor: background.light,
        borderColor: primary.primary,
      },
    },
  });

  /**
   * Texts
   */
  let style = { width: 800 };
  const intl = useIntl();
  const placeholderCurrentPassword = intl.formatMessage({
    id: 'currentPassword',
    defaultMessage: 'Contraseña actual',
  });
  const placeholderConfirmPassword = intl.formatMessage({
    id: 'confirm',
    defaultMessage: 'Confirmar',
  });
  const acceptButtonText = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });
  const cancelButtonText = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  return (
    <div
      className="border border-primary bg-grey-lighter-lighter rounded shadow absolute p-2 w-full"
      style={{
        ...style,
        zIndex: ABOVE_ALL,
        width: '350px',
        height: '250px',
        top: fromNewLogin ? '' : 0,
      }}
    >
      <div className="mb-2 font-light text-primary text-center mt-2">
        <FormattedMessage id="confirmpassww" defaultMessage={`Confirmar Contraseña`} />
      </div>
      <div className={css(styles.inputFormContainer)}>
        <input
          className={css(styles.inputForm)}
          type="password"
          name="currentPassword"
          placeholder={placeholderCurrentPassword}
          onChange={onPasswordChange}
          value={inputPassword || ''}
          required={true}
          disabled={false}
          autoFocus={true}
        />
      </div>
      <div className={css(styles.inputFormContainer)}>
        <input
          className={css(styles.inputForm)}
          type="password"
          name="confirmPassword"
          placeholder={placeholderConfirmPassword}
          title={placeholderConfirmPassword}
          value={inputConfirm || ''}
          onChange={onConfirmChange}
          required={true}
          disabled={false}
        />
      </div>
      <div className="flex justify-center mt-2">
        <Button title={acceptButtonText} click={() => handleSave()} />
        <Button title={cancelButtonText} click={() => handleCancel()} />
      </div>
    </div>
  );
};

export default ConfirmPasswordView;
