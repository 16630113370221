export const splitTransactionLineWithCount = (tl) => {
  const indexOfLine1 = tl.indexOf('L');
  const indexOfIterativeCount = tl.indexOf('S');
  if (indexOfLine1 > 0) {
    const transaction = tl.slice(1, indexOfLine1);
    let line = tl.slice(indexOfLine1 + 1);
    let countIterativeDialog = -1;
    if (indexOfIterativeCount > 0) {
      line = tl.slice(indexOfLine1 + 1, indexOfIterativeCount);
      countIterativeDialog = tl.slice(indexOfIterativeCount + 1);
    }
    return [parseInt(transaction), parseInt(line), parseInt(countIterativeDialog)];
  }
  return [null, null, -1];
};

/**
 * Compare a pair of transaction line ID's;
 * @param {string} tlid1 Transaction Line ID 1
 * @param {string} tlid2 Transaction Line ID 2
 * @return Returns `1` if `tlid2` > `tlid1`
 */
export const compareTLId = (tlid1, tlid2) => {
  let tmpTLId1 = tlid1.replace('acceptButton_', '').replace('cancelButton_', '');
  let tmpTLId2 = tlid2.replace('acceptButton_', '').replace('cancelButton_', '');
  const t1 = getTransactionFromLineIDString(tmpTLId1);
  const l1 = getLineFromLineIDString(tmpTLId1);
  const p1 = getTabIdFromLineIDString(tmpTLId1);
  const t2 = getTransactionFromLineIDString(tmpTLId2);
  const l2 = getLineFromLineIDString(tmpTLId2);
  const p2 = getTabIdFromLineIDString(tmpTLId2);

  if (!t1 || !l1 || !t2 || !l2 || p1 !== p2) {
    return -1;
  }

  if (t2 > t1 || tlid2.indexOf('acceptButton_') > -1) {
    return 1;
  } else if (t2 < t1 || tlid2.indexOf('cancelButton_') > -1) {
    return -1;
  } else if (l2 > l1) {
    return 1;
  } else if (l2 < l1) {
    return -1;
  } else {
    return 0; // equals
  }
};

/**
 * Get string TL by LineID
 * @param {object} lineID (transactionNumber, lineNumber)
 */
export const getTL = ({ transactionNumber, lineNumber }) => `T${transactionNumber}L${lineNumber}`;

export const getParentDialog = (
  stateByLineID,
  strLineIdKey,
  compareIfSameDialog,
  getParentWithSameIterationCount,
  searchPastTransactions,
  dialogNumber,
  startFromEnd,
  getLatestSecuence,
) => {
  let closestLine = 0;
  let closestTransaction = 0;
  let closestSecuence = 0;
  let fieldT, fieldL, fieldS, fieldP;
  const foundDialogNumber = dialogNumber || (stateByLineID[strLineIdKey] && getNumberFromDialogKey(stateByLineID[strLineIdKey].dialogKey));

  if (foundDialogNumber) {
    let tmpArr = [...stateByLineID.openedDialogList];
    if (startFromEnd) {
      tmpArr = tmpArr.reverse();
    }
    let dialogFound;
    for (let openedDialogIdx in tmpArr) {
      const openedDialog = tmpArr[openedDialogIdx];
      if (stateByLineID[openedDialog] && String(foundDialogNumber) === getNumberFromDialogKey(stateByLineID[openedDialog].dialogKey)) {
        if (!getLatestSecuence) {
          return openedDialog;
        }
        dialogFound = openedDialog;
      }
    }
    if (dialogFound) {
      return dialogFound;
    }
  } else {
    if (strLineIdKey) {
      fieldT = getTransactionFromLineIDString(strLineIdKey);
      fieldL = getLineFromLineIDString(strLineIdKey);
      fieldS = getIterationCountFromLineIDString(strLineIdKey);
      fieldP = getTabIdFromLineIDString(strLineIdKey);
      if (stateByLineID.openedDialogList) {
        stateByLineID.openedDialogList.forEach((dialog) => {
          const dialogT = getTransactionFromLineIDString(dialog);
          const dialogL = getLineFromLineIDString(dialog);
          const dialogS = getIterationCountFromLineIDString(dialog);
          const dialogP = getTabIdFromLineIDString(dialog);
          if (
            (searchPastTransactions ? dialogT <= fieldT : dialogT === fieldT) &&
            (!closestLine || dialogT < fieldT || (getLatestSecuence && compareIfSameDialog ? dialogL <= fieldL : dialogL < fieldL)) &&
            (!searchPastTransactions
              ? dialogL > closestLine || (dialogL === closestLine && parseInt(dialogS) > parseInt(closestSecuence))
              : dialogT > closestTransaction || (dialogT === closestTransaction && dialogL > closestLine)) &&
            (getLatestSecuence || !getParentWithSameIterationCount || (getParentWithSameIterationCount && dialogS === fieldS)) &&
            dialogP === fieldP
          ) {
            closestTransaction = dialogT;
            closestLine = dialogL;
            if (getLatestSecuence) {
              closestSecuence = dialogS;
            }
          }
        });
      }
    }

    if (closestLine > 0) {
      let tmpLineId = {
        transactionNumber: searchPastTransactions ? closestTransaction : fieldT,
        lineNumber: closestLine,
        countIterativeDialog: getParentWithSameIterationCount ? fieldS : closestSecuence,
        countVariableRow: 0,
        tabId: fieldP,
      };
      return generateLineIDString(tmpLineId);
    } else {
      return false;
    }
  }
};

export const getDialogKeyWithNoS = (dialogKey) => {
  if (!dialogKey) {
    return dialogKey;
  }
  const newDialogKey = dialogKey.substr(0, dialogKey.indexOf('S') + 1) + '0' + dialogKey.substr(dialogKey.indexOf('P'));
  return newDialogKey;
};

export const getParentDialogCount = (stateByLineID, lineID, dialogNumber, lineIdStr, getLatestSecuence) => {
  let dialogKey;
  let tmpLineIdStr = lineIdStr;
  let iterativeDC = null;
  if (tmpLineIdStr) {
    iterativeDC = getIterationCountFromLineIDString(lineIdStr);
    if (iterativeDC) {
      tmpLineIdStr = tmpLineIdStr.substr(0, tmpLineIdStr.indexOf('S') + 1) + '0' + tmpLineIdStr.substr(tmpLineIdStr.indexOf('V'));
    }
  }
  if (lineID && dialogNumber) {
    dialogKey = generateDialogKey(dialogNumber, lineID);
  } else if (tmpLineIdStr && stateByLineID[tmpLineIdStr]) {
    dialogKey = stateByLineID[tmpLineIdStr].dialogKey || stateByLineID[tmpLineIdStr].treeNumber;
  } else if (lineID) {
    const parentDialogTL = getParentDialog(stateByLineID, generateLineIDString(lineID), true, false, true, dialogNumber, true, getLatestSecuence);
    if (parentDialogTL && stateByLineID[parentDialogTL]) {
      dialogKey = stateByLineID[parentDialogTL].dialogKey || stateByLineID[parentDialogTL].treeNumber;
    }
  }
  return stateByLineID.stateByDialogKey[dialogKey] ? stateByLineID.stateByDialogKey[dialogKey].iterativeDialogCount : iterativeDC;
};

export const getTLFromStr = (strTL) => {
  const fieldWithoutCount = strTL.indexOf('S') > 0 ? strTL.substr(0, strTL.indexOf('S')) : strTL;
  return fieldWithoutCount;
};

export const generateLineIDString = (lineID) => {
  let { transactionNumber, lineNumber, countIterativeDialog, countVariableRow, tabId } = lineID;
  if (!countIterativeDialog) {
    countIterativeDialog = 0;
  }
  if (!countVariableRow) {
    countVariableRow = 0;
  }
  let lineIDKey = `T${transactionNumber}L${lineNumber}S${countIterativeDialog}V${countVariableRow}P${tabId}`;
  return lineIDKey;
};

export const getTransactionFromLineIDString = (strLineIDKey) => {
  const t = Number(strLineIDKey.substr(1, strLineIDKey.indexOf('L') - 1));
  return t;
};

export const getLineFromLineIDString = (strLineIDKey) => {
  const l = Number(
    strLineIDKey.substr(
      strLineIDKey.indexOf('L') + 1,
      strLineIDKey.indexOf('S') > 0 ? strLineIDKey.indexOf('S') - strLineIDKey.indexOf('L') - 1 : strLineIDKey.length - strLineIDKey.indexOf('L'),
    ),
  );
  return l;
};

export const getIterationCountFromLineIDString = (strTL) => {
  const sequenceId = strTL.indexOf('S') > 0 ? strTL.substr(strTL.indexOf('S') + 1, strTL.indexOf('V') - strTL.indexOf('S') - 1) : 0;
  return sequenceId;
};

export const getVariableRowFromLineIDString = (strTL) => {
  const variableRowId = strTL.indexOf('V') > 0 ? strTL.substr(strTL.indexOf('V') + 1, strTL.indexOf('P') - strTL.indexOf('V') - 1) : 0;
  return parseInt(variableRowId);
};

export const getTabIdFromLineIDString = (strTL) => {
  const tabId =
    strTL.indexOf('P') > 0
      ? strTL.substr(
          strTL.indexOf('P') + 1,
          strTL.indexOf('_') !== -1 ? strTL.indexOf('_') - strTL.indexOf('P') - 1 : strTL.length - strTL.indexOf('P') - 1,
        )
      : 0;
  return tabId;
};

export const generateDialogKey = (dialogNumber, lineID) => {
  let { countIterativeDialog, tabId } = lineID;
  if (!countIterativeDialog) {
    countIterativeDialog = 0;
  }
  let dialogKey = `D${dialogNumber}S${countIterativeDialog}P${tabId}`;
  return dialogKey;
};

export const getNumberFromDialogKey = (dialogKey) => {
  if (dialogKey && typeof dialogKey === 'string' && dialogKey.indexOf('D') > -1) {
    const dialogNumber = dialogKey.substr(dialogKey.indexOf('D') + 1, dialogKey.indexOf('S') - dialogKey.indexOf('D') - 1);
    return dialogNumber;
  } else {
    return dialogKey;
  }
};

export const getIterationFromDialogKey = (dialogKey) => {
  if (dialogKey && typeof dialogKey === 'string' && dialogKey.indexOf('S')) {
    const iteration = dialogKey.substr(dialogKey.indexOf('S') + 1, dialogKey.indexOf('P') - dialogKey.indexOf('S') - 1);
    return iteration;
  } else {
    return dialogKey;
  }
};
