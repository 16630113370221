import React from 'react';

interface LinkProps {
  className: string;
  href: string;
  children: JSX.Element | string;
  onTabClick?: () => void;
}

const Link = ({ className, href, children, onTabClick }: LinkProps) => {
  const onClick = (event: React.MouseEvent) => {
    onTabClick && onTabClick();
    if (!event.metaKey && !event.ctrlKey) {
      event.preventDefault();
      window.history.pushState({}, '', href);

      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
  };

  return (
    <a onClick={onClick} className={className} href={href}>
      {children}
    </a>
  );
};

export default Link;
