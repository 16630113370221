/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import '../../styles/components/SchemaView.scss';
import Help from '../../resources/icons/fa/help.svg';
import Delete from '../../resources/icons/fa/delete.svg';
import { BasePopupWindow } from './HelpWindows/BasePopupWindow.js';
import { HelpTransaccionComponent } from './HelpWindows/HelpTransaccionComponent';
import { HelpRubroSalidaComponent } from './HelpWindows/HelpRubroSalidaComponent';
import { HelpCampoComponent } from './HelpWindows/HelpCampoComponent';
import {
  updateEsquema,
  addEsquema,
  getDescripcionesTable,
  getRecordsByConcept,
  getAllTransacciones,
  getDescripcionesCampo,
  getRecordsByConceptFilter,
  getHelpMetadataByConcept,
} from '../../api/schema.js';
import ModalMessage from '../../components/ModalMessage';

export const EditLineWindow = (props) => {
  const intl = useIntl();
  const [helpWindowOpen, setHelpWindowOpen] = useState('');
  let [forceSelectHelp, setForceSelectHelp] = useState(false);
  let [node, setNode] = useState(props.node);
  let [nodoAnterior, setNodoAnterior] = useState(props.node);
  let [campo, setCampo] = useState('');
  let [rubro, setRubro] = useState('');
  let [transaccion, setTransaccion] = useState('');
  let [modalData, setModalData] = useState({});
  let [titleHelp, setTitleHelp] = useState('');
  let [rubroSalidaError, setRubroSalidaError] = useState('');
  let [campoError, setCampoError] = useState('');
  let [transaccionError, setTransaccionError] = useState('');
  let [colorRubro, setColorRubro] = useState('');
  let [colorTransaccion, setColorTransaccion] = useState('');
  let [colorCampo, setColorCampo] = useState('');
  const [campoRubroSalida, setCampoRubroSalida] = useState(0);

  const ID_HELP_CAMPO = 'Campo';
  const ID_HELP_RUBRO_SALIDA = 'RubroSalida';
  const ID_HELP_TRANSACCION = 'Transaccion';
  const helpWindowStructure = {};

  let campoNoEsValido = intl.formatMessage({
    id: 'campoIngresadoNoValido',
    defaultMessage: 'El Campo ingresado no es válido',
  });

  let monedaNoValida = intl.formatMessage({
    id: 'monedaIngresadaNoValida',
    defaultMessage: 'La Moneda ingresada no es válida',
  });

  let rubroDeSalidaValorDelCampoNoEsValido = intl.formatMessage({
    id: 'valorCampoRubroSalidaNoValido',
    defaultMessage: 'El valor del campo "Rubro Salida" no es válido',
  });

  let rubroDeSalidaNoPuedeSerVacio = intl.formatMessage({
    id: 'valorCampoRubroSalidaNoVacio',
    defaultMessage: 'El valor "Rubro de Salida" no puede ser vacío',
  });

  let campoNoPuedeSerVacio = intl.formatMessage({
    id: 'valorCampoNoVacio',
    defaultMessage: 'El valor "Campo" no puede ser vacío',
  });

  let transaccionNoValida = intl.formatMessage({
    id: 'valorTransaccionNoValida',
    defaultMessage: 'El valor "Transacción" no es válido',
  });

  let transaccionNoVacia = intl.formatMessage({
    id: 'valorTransaccionNoVacia',
    defaultMessage: 'El valor "Transacción" no puede ser vacío',
  });

  useEffect(() => {
    getHelpMetadataByConcept(4).then(function (response) {
      for (let i = 0; i < response.length; i++) {
        // devolver primer elemento visible que deberia ser el rubro operacional, ver issue de push por mas informacion sobre los rubros
        if (response[i].isVisible) {
          setCampoRubroSalida(response[i].fieldNumber);
          break;
        }
      }
    });
  }, []);

  useEffect(() => {
    if (JSON.stringify(nodoAnterior) !== JSON.stringify(props.node)) {
      setNode(props.node);
      setNodoAnterior(props.node);
    }
  }, [nodoAnterior, props.node]);

  useEffect(() => {
    validoCampo(node.campo);
    validoRubro(node.rubroSalida);
    validoTransaccion(node.transaccion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.campo, node.rubroSalida, node.transaccion, node.usarCamposMovimientos]);

  const handleKeyDown = (e) => {
    if (['-', '+', 'e', '.'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const validoRubro = (rubro) => {
    if (!rubro) {
      if (rubroSalidaError) {
        setRubroSalidaError('');
      }
      setRubro('');
      return;
    }
    const validateRubroSalidaFn = (response, dontShowNotFound) => {
      let recordFound = false;
      for (let i = 0; i < response.length; i++) {
        if (response[i][0].value !== undefined) {
          if (response[i][0].value === parseInt(rubro)) {
            setRubroSalidaError('');
            setRubro(response[i][2].value);
            recordFound = true;
            break;
          }
        }
      }
      if (!recordFound && !dontShowNotFound) {
        setRubroSalidaError(rubroDeSalidaValorDelCampoNoEsValido);
        setRubro('');
        setColorRubro('red');
      }
      return recordFound;
    };
    getRecordsByConcept(4).then((response) => {
      if (!validateRubroSalidaFn(response, true) && campoRubroSalida) {
        getRecordsByConceptFilter(4, rubro, '2', campoRubroSalida).then(validateRubroSalidaFn);
      }
    });
  };

  const validoCampo = (campo) => {
    let fieldFound = false;
    if (!campo) {
      if (campoError) {
        setCampoError('');
      }
      setCampo('');
      return;
    }
    getDescripcionesCampo().then(function (response) {
      for (let i = 0; i < response.length; i++) {
        if (response[i][0] === parseInt(campo)) {
          setCampoError('');
          fieldFound = true;
          setCampo(response[i][1]);
          break;
        }
      }
      if (!fieldFound) {
        setCampoError(campoNoEsValido);
        setCampo('');
        setColorCampo('red');
      }
    });
  };

  const validoTransaccion = (transaccion) => {
    let transactionFound = false;
    if (!transaccion) {
      if (transaccionError) {
        setTransaccionError('');
      }
      setTransaccion('');
      return;
    }
    getAllTransacciones().then(function (response) {
      for (let i = 0; i < response.length; i++) {
        if (response[i].identificacion === parseInt(transaccion)) {
          setTransaccionError('');
          transactionFound = true;
          setTransaccion(response[i].descripcion);
          break;
        }
      }
      if (!transactionFound) {
        setTransaccionError(transaccionNoValida);
        setTransaccion('');
        setColorTransaccion('red');
      }
    });
  };

  const bloqueoBotonSiNodeEsIgualANodeEliminado = () => {
    let bloqueo = false;
    if (props.nodesAEliminar.length !== 0) {
      let eliminados = props.nodesAEliminar;
      for (let i = 0; i < eliminados.length; i++) {
        if (eliminados[i].key === node.key) {
          bloqueo = true;
          break;
        }
      }
    }
    return bloqueo;
  };

  let toggleWindow = (window) => {
    setHelpWindowOpen(window);
  };

  const setHelpValue = (value) => {
    if (value) {
      if (helpWindowOpen === ID_HELP_CAMPO) {
        setNode({ ...node, campo: value[0], descCampo: value[1] });
        setCampo(value[1]);
      } else if (helpWindowOpen === ID_HELP_RUBRO_SALIDA) {
        setNode({ ...node, rubroSalida: value[0].value });
        setRubro(value[2].value);
      } else if (helpWindowOpen === ID_HELP_TRANSACCION) {
        setNode({ ...node, transaccion: value[0] });
        setTransaccion(value[1]);
      }
      setForceSelectHelp(false);
      toggleWindow('');
    }
  };

  const setForceSelect = (value) => {
    setForceSelectHelp(value);
  };

  helpWindowStructure[ID_HELP_CAMPO] = <HelpCampoComponent currentValue={node.campo} setHelpValue={setHelpValue} forceSelectHelp={forceSelectHelp} />;
  helpWindowStructure[ID_HELP_RUBRO_SALIDA] = (
    <HelpRubroSalidaComponent currentValue={node.rubroSalida} setHelpValue={setHelpValue} forceSelectHelp={forceSelectHelp} />
  );
  helpWindowStructure[ID_HELP_TRANSACCION] = (
    <HelpTransaccionComponent currentValue={node.transaccion} setHelpValue={setHelpValue} forceSelectHelp={forceSelectHelp} />
  );

  let helpWindow = null;
  if (helpWindowOpen) {
    helpWindow = (
      <BasePopupWindow
        helpWindowOpen={helpWindowStructure[helpWindowOpen]}
        toggleWindow={toggleWindow}
        setForceSelect={setForceSelect}
        title={titleHelp}
      ></BasePopupWindow>
    );
  }

  const updateLine = () => {
    if (rubroSalidaError !== rubroDeSalidaValorDelCampoNoEsValido) {
      if (campoError !== campoNoEsValido) {
        if (transaccionError !== transaccionNoValida) {
          let nuevoNodo = {
            proceso: node.proceso,
            rubro: node.rubro,
            moneda: node.moneda,
            linea: node.linea,
            ordinal: node.ordinal,
            descripcion: node.descripcion,
            campo: node.campo,
            rubrosalida: node.rubroSalida,
            cd: node.cajaODiario,
            d_dc: node.debitoGeneraCreditoODebito,
            c_dc: node.creditoGeneraCreditoODebito,
            transaccion: node.transaccion,
            condicion: node.condicion,
            usar_campos_movimientos: node.usarCamposMovimientos || 'N',
          };

          updateEsquema(nuevoNodo).then((response) => {
            if (response === true) {
              props.updateNodo(props.node.key, 'linea');
              setModalData({
                showDialog: true,
                textDialog: intl.formatMessage({
                  id: 'updatedOk',
                  defaultMessage: 'Se ha actualizado correctamente',
                }),
              });
            } else {
              setModalData({
                showDialog: true,
                textDialog: intl.formatMessage({
                  id: 'updateLineErr',
                  defaultMessage: 'Ocurrio un error al actualizar el campo',
                }),
              });
            }
          });
        } else {
          setModalData({ showDialog: true, textDialog: transaccionNoValida });
        }
      } else {
        setModalData({ showDialog: true, textDialog: campoNoEsValido });
      }
    } else {
      setModalData({ showDialog: true, textDialog: rubroDeSalidaValorDelCampoNoEsValido });
    }
  };

  const postLinea = () => {
    if (!node.campo) {
      setModalData({ showDialog: true, textDialog: campoNoPuedeSerVacio });
      throw new Error(campoNoPuedeSerVacio);
    } else if (!node.rubroSalida) {
      setModalData({ showDialog: true, textDialog: rubroDeSalidaNoPuedeSerVacio });
      throw new Error(rubroDeSalidaNoPuedeSerVacio);
    } else if (!node.transaccion) {
      setModalData({ showDialog: true, textDialog: transaccionNoVacia });
      throw new Error(transaccionNoVacia);
    }
    if (rubroSalidaError !== rubroDeSalidaValorDelCampoNoEsValido) {
      if (campoError !== campoNoEsValido) {
        if (transaccionError !== transaccionNoValida) {
          let nuevoNodo = {
            proceso: 0,
            rubro: node.rubro,
            moneda: node.moneda,
            linea: lineaNodo(node.rubro, node.moneda),
            ordinal: 2,
            descripcion: node.descripcion || ' ',
            campo: node.campo,
            rubrosalida: node.rubroSalida,
            cd: node.cajaODiario,
            d_dc: node.debitoGeneraCreditoODebito,
            c_dc: node.creditoGeneraCreditoODebito,
            transaccion: node.transaccion,
            condicion: node.condicion,
            usar_campos_movimientos: node.usarCamposMovimientos || 'N',
          };
          addEsquema(nuevoNodo).then(function (response) {
            if (response === true) {
              setModalData({
                showDialog: true,
                textDialog: intl.formatMessage({
                  id: 'addedOk',
                  defaultMessage: 'Se ha agregado correctamente',
                }),
              });
              props.loadData();
              limpiar();
            } else {
              setModalData({
                showDialog: true,
                textDialog: intl.formatMessage({
                  id: 'lineAlreadyExists',
                  defaultMessage: 'La Línea específico ya existe',
                }),
              });
            }
          });
        } else {
          setModalData({ showDialog: true, textDialog: transaccionNoValida });
        }
      } else {
        setModalData({ showDialog: true, textDialog: campoNoEsValido });
      }
    } else {
      setModalData({ showDialog: true, textDialog: rubroDeSalidaValorDelCampoNoEsValido });
    }
  };

  const lineaNodo = (miRubro, miMoneda) => {
    let lineas = [];
    let ultimaLinea = 0;
    let data = props.data[0].nodes;
    for (let x = 0; x < data.length; x++) {
      let levelDos = data[x].nodes;
      for (let i = 0; i < levelDos.length; i++) {
        if (levelDos[i].rubro === miRubro && levelDos[i].moneda === miMoneda) {
          lineas.push(levelDos[i].linea);
        }
      }
    }
    if (lineas.length === 0) {
      ultimaLinea = 1;
      return ultimaLinea;
    } else {
      // obtenemos la linea del ultimo nodo y le sumamos uno
      ultimaLinea = lineas.pop();
      ultimaLinea++;
      return ultimaLinea;
    }
  };

  const siTieneNodoConOrdinalMayor = (node) => {
    let tieneOrdinalMayor = false;
    let data = props.data[0].nodes;
    let key = node.key;
    let keyNodo = key.split('/', 3);
    for (let x = 0; x < data.length; x++) {
      if (data[x].key === keyNodo[1]) {
        let nodos = data[x].nodes;
        if (nodos.length !== 0) {
          for (let i = 0; i < nodos.length; i++) {
            if (nodos[i].key === keyNodo[2]) {
              let lineas = nodos[i].nodes;
              if (lineas) {
                for (let l = 0; l < lineas.length; l++) {
                  if (lineas[l].ordinal > 99) {
                    tieneOrdinalMayor = true;
                  }
                }
              } else {
                return tieneOrdinalMayor;
              }
            }
          }
        }
      }
    }
    return tieneOrdinalMayor;
  };

  const limpiar = () => {
    let vacioNodos = {
      proceso: null,
      rubro: '',
      moneda: '',
      linea: '',
      ordinal: null,
      descripcion: '',
      campo: '',
      rubroSalida: '',
      cajaODiario: null,
      debitoGeneraCreditoODebito: 'D',
      creditoGeneraCreditoODebito: 'C',
      transaccion: '',
      condicion: '',
      usar_campos_movimientos: '',
      key: node.key,
    };
    setNode(vacioNodos);
    setCampo('');
    setRubro('');
    setTransaccion('');
  };

  return (
    <div>
      {helpWindow}
      <div>
        {props.showWindow === 'EditarL' ? (
          <h6 style={{ fontSize: '20px' }}>
            {intl.formatMessage({
              id: 'line',
              defaultMessage: 'Línea',
            })}
          </h6>
        ) : props.showWindow === 'NuevaLinea' ? (
          <h6 style={{ fontSize: '20px' }}>
            {intl.formatMessage({
              id: 'newline',
              defaultMessage: 'Nueva línea',
            })}
          </h6>
        ) : (
          ''
        )}
        <div className={'container'} style={{ marginTop: '10px', paddingLeft: '20px' }}>
          <hr />
          <div className="row " style={{ marginTop: '20px', display: 'flex' }}>
            <div style={{ width: '20%' }}>
              <label htmlFor="Rubro">
                {intl.formatMessage({
                  id: 'Rubro',
                  defaultMessage: 'Rubro',
                })}
              </label>
            </div>
            <div style={{ width: '80%' }}>
              <input
                disabled={node.rubro}
                value={node.rubro}
                onChange={(e) => setNode({ ...node, rubro: e.target.value })}
                className={'clear-both border-solid rounded'}
                style={{ border: '1px solid #CED0CE', width: '80%' }}
                id="Rubro"
              ></input>
            </div>
          </div>
          <div className="row" style={{ marginTop: '20px', display: 'flex' }}>
            <div style={{ width: '20%' }}>
              <label htmlFor="Moneda">
                {intl.formatMessage({
                  id: 'Moneda',
                  defaultMessage: 'Moneda',
                })}
              </label>
            </div>
            <div style={{ width: '80%' }}>
              <input
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyDown(e)}
                disabled={node.moneda}
                value={node.moneda}
                className={'clear-both border-solid  rounded'}
                onChange={(e) => setNode({ ...node, moneda: e.target.value })}
                style={{ border: '1px solid #CED0CE', width: '80%' }}
                id="Moneda"
              ></input>
            </div>
          </div>
          <div className="row" style={{ marginTop: '20px', display: 'flex' }}>
            <div style={{ width: '20%' }}>
              <label htmlFor="Descripcion">
                {intl.formatMessage({
                  id: 'descripcion',
                  defaultMessage: 'Descripción',
                })}
              </label>
            </div>
            <div style={{ width: '80%' }}>
              <input
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                value={node.descripcion}
                onChange={(e) => setNode({ ...node, descripcion: e.target.value })}
                className={'clear-both border-solid rounded'}
                style={{ border: '1px solid #CED0CE', width: '80%' }}
                id="Descripcion"
              ></input>
            </div>
          </div>
          <div className="row " style={{ marginTop: '20px', display: 'flex' }}>
            <div style={{ width: '20%' }}>
              <label htmlFor="Campo">
                {intl.formatMessage({
                  id: 'field',
                  defaultMessage: 'Campo',
                })}
              </label>
            </div>
            <div style={{ width: '35%' }}>
              <input
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyDown(e)}
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                value={node.campo}
                onChange={(e) => setNode({ ...node, campo: e.target.value })}
                onBlur={(e) => validoCampo(e.target.value)}
                className={'clear-both border-solid rounded'}
                style={{ border: '1px solid #CED0CE', width: '80%' }}
                id="Campo"
              ></input>
              <button
                className="iconHelp ml-1"
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                style={{ width: '25px', outline: 'none' }}
                onClick={() => {
                  setTitleHelp(
                    intl.formatMessage({
                      id: 'field',
                      defaultMessage: 'Campo',
                    }),
                  );
                  toggleWindow(ID_HELP_CAMPO);
                }}
              >
                <Help />
              </button>
            </div>
            <div style={{ width: '45%', textOverflow: 'ellipsis', overflowY: 'hidden', overflowX: 'hidden', color: 'darkgray' }}>
              {campo}
              <label style={{ color: colorCampo }}>{campoError}</label>
            </div>
          </div>
          <div className="row " style={{ marginTop: '20px', display: 'flex' }}>
            <div style={{ width: '20%' }}>
              <label htmlFor="Condicion">
                {intl.formatMessage({
                  id: 'condition',
                  defaultMessage: 'Condición',
                })}
              </label>
            </div>
            <div style={{ width: '80%' }}>
              <input
                value={node.condicion}
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                onChange={(e) => setNode({ ...node, condicion: e.target.value })}
                className={'clear-both border-solid rounded'}
                style={{ border: '1px solid #CED0CE', width: '80%' }}
                id="Condicion"
              ></input>
            </div>
          </div>
          <div style={{ borderTop: '1px solid #E2E3E2', marginRight: '2px', marginLeft: '2px', marginTop: '30px' }} className="row p-2">
            <h3 style={{ width: '80px', fontSize: '12px', marginTop: '-15px', marginLeft: '5px', backgroundColor: 'white' }}>
              {intl.formatMessage({
                id: 'itemType',
                defaultMessage: 'Tipo de rubro',
              })}
            </h3>
          </div>
          <div className="row" style={{ marginTop: '15px', display: 'flex' }}>
            <div style={{ width: '20%' }}>
              <label htmlFor="RubroSalida">
                {intl.formatMessage({
                  id: 'rubroSalida',
                  defaultMessage: 'Rubro de Salida',
                })}
              </label>
            </div>
            <div style={{ width: '35%' }}>
              <input
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyDown(e)}
                value={node.rubroSalida}
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                onChange={(e) => setNode({ ...node, rubroSalida: e.target.value })}
                onBlur={(e) => validoRubro(e.target.value)}
                className={'clear-both border-solid rounded'}
                id="RubroSalida"
                style={{ border: '1px solid #CED0CE', width: '80%' }}
              ></input>
              <button
                className="iconHelp ml-1"
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                style={{ width: '25px', outline: 'none' }}
                onClick={() => {
                  setTitleHelp(
                    intl.formatMessage({
                      id: 'Rubro',
                      defaultMessage: 'Rubro',
                    }),
                  );
                  toggleWindow(ID_HELP_RUBRO_SALIDA);
                }}
              >
                <Help />
              </button>
            </div>
            <div style={{ width: '45%', textOverflow: 'ellipsis', overflowY: 'hidden', overflowX: 'hidden', color: 'darkgray' }}>
              {rubro}
              <label style={{ color: colorRubro }}>{rubroSalidaError}</label>
            </div>
          </div>
          <div className="row mt-2 pl-4 pr-4" style={{ display: 'flex', marginTop: '20px' }}>
            <div style={{ width: '50%' }}>
              <input
                type="checkbox"
                checked={siTieneNodoConOrdinalMayor(node)}
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                className={'clear-both mr-2 mt-2'}
                id="valorRubroSalida"
              ></input>
              <label htmlFor="valorRubroSalida">
                {intl.formatMessage({
                  id: 'exitItemValue',
                  defaultMessage: 'Valor de Rubro de Salida',
                })}
              </label>
            </div>
            <div style={{ width: '50%' }}>
              <input
                type="checkbox"
                checked={node.usarCamposMovimientos === 'S'}
                onChange={(e) => {
                  setNode({ ...node, usarCamposMovimientos: e.target.checked ? 'S' : 'N' });
                }}
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                className={'clear-both mr-2 mt-2'}
                id="chkCamposMovmientos"
              ></input>
              <label htmlFor="CamposMovimientos">
                {intl.formatMessage({
                  id: 'usesMovementFields',
                  defaultMessage: 'Usa Campos de Movimientos',
                })}
              </label>
            </div>
          </div>
          <div className="row" style={{ display: 'flex', marginTop: '15px' }}>
            <div style={{ width: '20%' }}>
              <label htmlFor="Transaccion">
                {intl.formatMessage({
                  id: 'transaction',
                  defaultMessage: 'Transacción',
                })}
              </label>
            </div>
            <div style={{ width: '35%' }}>
              <input
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyDown(e)}
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                value={node.transaccion}
                onChange={(e) => setNode({ ...node, transaccion: e.target.value })}
                onBlur={(e) => validoTransaccion(e.target.value)}
                onClick={() => {
                  setTitleHelp(
                    intl.formatMessage({
                      id: 'transaction',
                      defaultMessage: 'Transacción',
                    }),
                  );
                  toggleWindow(ID_HELP_TRANSACCION);
                }}
                className={'clear-both border-solid rounded'}
                style={{ border: '1px solid #CED0CE', width: '80%' }}
                id="Transaccion"
              ></input>
              <button
                className="iconHelp ml-1"
                disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                style={{ width: '25px', outline: 'none' }}
                onClick={() => {
                  setTitleHelp(
                    intl.formatMessage({
                      id: 'transaction',
                      defaultMessage: 'Transacción',
                    }),
                  );
                  toggleWindow(ID_HELP_TRANSACCION);
                }}
              >
                <Help />
              </button>
            </div>
            <div style={{ width: '45%', textOverflow: 'ellipsis', overflowY: 'hidden', overflowX: 'hidden', color: 'darkgray' }}>
              {transaccion}
              <label style={{ color: colorTransaccion }}>{transaccionError}</label>
            </div>
          </div>
          <div className="row" style={{ display: 'flex', marginTop: '40px', marginLeft: '50px' }}>
            <div className="col-3 p-1  " style={{ borderTop: '1px solid #E2E3E2' }}>
              <h3 style={{ fontSize: '12px', marginTop: '-12px', marginLeft: '5px', backgroundColor: 'white', width: '68px' }}>
                {intl.formatMessage({
                  id: 'nrodecaja',
                  defaultMessage: 'Caja',
                })}
                /
                {intl.formatMessage({
                  id: 'productionManagerDaily',
                  defaultMessage: 'Diario',
                })}
              </h3>
              <p className="mt-2">
                <input
                  type="radio"
                  id="Caja"
                  disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                  checked={node.cajaODiario === 'C'}
                  onChange={(e) => setNode({ ...node, cajaODiario: 'C' })}
                  name="CajaDiario"
                  className="mr-2 ml-2"
                ></input>
                <label htmlFor="Caja">
                  {intl.formatMessage({
                    id: 'nrodecaja',
                    defaultMessage: 'Caja',
                  })}
                </label>
              </p>
              <p className="mt-2">
                <input
                  type="radio"
                  id="Diario"
                  disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                  // value={node.cajaODiario}
                  checked={node.cajaODiario === 'D'}
                  onChange={(e) => setNode({ ...node, cajaODiario: 'D' })}
                  name="CajaDiario"
                  className="mr-2 ml-2"
                ></input>
                <label htmlFor="Diario">
                  {intl.formatMessage({
                    id: 'productionManagerDaily',
                    defaultMessage: 'Diario',
                  })}
                </label>
              </p>
              <p className="mt-2">
                <input
                  type="radio"
                  id="Libre"
                  disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                  checked={node.cajaODiario === null}
                  onChange={(e) => setNode({ ...node, cajaODiario: null })}
                  name="CajaDiario"
                  className="mr-2 ml-2"
                ></input>
                <label htmlFor="Libre">
                  {intl.formatMessage({
                    id: 'free',
                    defaultMessage: 'Libre',
                  })}
                </label>
              </p>
            </div>
            <div className="col-1"></div>
            <div className="col-3 p-1  " style={{ borderTop: '1px solid #E2E3E2', marginLeft: '60px', marginRight: '60px' }}>
              <h3 style={{ fontSize: '12px', marginTop: '-12px', marginLeft: '5px', backgroundColor: 'white', width: '65px' }}>
                {intl.formatMessage({
                  id: 'by',
                  defaultMessage: 'Por',
                }) + ' '}
                {intl.formatMessage({
                  id: 'debit',
                  defaultMessage: 'Débito',
                })}
              </h3>
              <p className="mt-2">
                <input
                  type="radio"
                  disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                  checked={node.debitoGeneraCreditoODebito === 'C'}
                  onChange={(e) => setNode({ ...node, debitoGeneraCreditoODebito: 'C' })}
                  id="Credito"
                  name="PorDebito"
                  className="mr-2 ml-2"
                ></input>
                <label htmlFor="Credito">
                  {intl.formatMessage({
                    id: 'credit',
                    defaultMessage: 'Crédito',
                  })}
                </label>
              </p>
              <p className="mt-2">
                <input
                  type="radio"
                  disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                  checked={node.debitoGeneraCreditoODebito === 'D'}
                  onChange={(e) => setNode({ ...node, debitoGeneraCreditoODebito: 'D' })}
                  id="Debito"
                  name="PorDebito"
                  className="mr-2 ml-2"
                ></input>
                <label htmlFor="Debito">
                  {intl.formatMessage({
                    id: 'debit',
                    defaultMessage: 'Débito',
                  })}
                </label>
              </p>
              <p className="mt-2">
                <input
                  type="radio"
                  disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                  checked={node.debitoGeneraCreditoODebito === null}
                  onChange={(e) => setNode({ ...node, debitoGeneraCreditoODebito: null })}
                  id="Libre"
                  name="PorDebito"
                  className="mr-2 ml-2"
                ></input>
                <label htmlFor="Libre">
                  {intl.formatMessage({
                    id: 'free',
                    defaultMessage: 'Libre',
                  })}
                </label>
              </p>
            </div>
            <div className="col-1"></div>
            <div className="col-3 p-1  " style={{ borderTop: '1px solid #E2E3E2' }}>
              <h3 style={{ fontSize: '12px', marginTop: '-12px', marginLeft: '5px', backgroundColor: 'white', width: '70px' }}>
                {intl.formatMessage({
                  id: 'by',
                  defaultMessage: 'Por',
                }) + ' '}
                {intl.formatMessage({
                  id: 'credit',
                  defaultMessage: 'Crédito',
                })}
              </h3>
              <p className="mt-2">
                <input
                  type="radio"
                  disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                  checked={node.creditoGeneraCreditoODebito === 'C'}
                  onChange={(e) => setNode({ ...node, creditoGeneraCreditoODebito: 'C' })}
                  id="Credito"
                  name="PorCredito"
                  className="mr-2 ml-2"
                ></input>
                <label htmlFor="Credito">
                  {intl.formatMessage({
                    id: 'credit',
                    defaultMessage: 'Crédito',
                  })}
                </label>
              </p>
              <p className="mt-2">
                <input
                  type="radio"
                  disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                  checked={node.creditoGeneraCreditoODebito === 'D'}
                  onChange={(e) => setNode({ ...node, creditoGeneraCreditoODebito: 'D' })}
                  id="Debito"
                  name="PorCredito"
                  className="mr-2 ml-2"
                ></input>
                <label htmlFor="Debito">
                  {intl.formatMessage({
                    id: 'debit',
                    defaultMessage: 'Débito',
                  })}
                </label>
              </p>
              <p className="mt-2">
                <input
                  type="radio"
                  disabled={props.postData === true ? false : !!bloqueoBotonSiNodeEsIgualANodeEliminado()}
                  checked={node.creditoGeneraCreditoODebito === null}
                  onChange={(e) => setNode({ ...node, creditoGeneraCreditoODebito: null })}
                  id="Libre"
                  name="PorCredito"
                  className="mr-2 ml-2"
                ></input>
                <label htmlFor="Libre">
                  {intl.formatMessage({
                    id: 'free',
                    defaultMessage: 'Libre',
                  })}
                </label>
              </p>
            </div>
          </div>
          <div className="modal__footer mt-4">
            <div className="row  p-1 d-flex justify-content-between" style={{ marginTop: '50px', float: 'right' }}>
              <div></div>
              <div>
                {/* {props.banderitaEliminar === false ? ( */}

                {props.postData === true ? (
                  <button type="submit" onClick={postLinea} className="btn btn-outline-info btn-sm btnDesign">
                    {intl.formatMessage({
                      id: 'Tablas.movimientos_alta',
                      defaultMessage: 'Alta',
                    })}
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={bloqueoBotonSiNodeEsIgualANodeEliminado()}
                    className="btn btn-outline-info btn-sm btnDesign"
                    onClick={updateLine}
                  >
                    {intl.formatMessage({
                      id: 'modify',
                      defaultMessage: 'Modificar',
                    })}
                  </button>
                )}
                {/* <button type="button" className="btn btn-outline-secondary btn-sm btnDesign">
                  Cancelar
                </button>
                &nbsp; */}
              </div>
            </div>
          </div>
        </div>
        {modalData.showDialog && (
          <ModalMessage
            message={modalData.textDialog}
            handleAccept={async () => {
              setModalData({ showDialog: false });
            }}
            acceptTitle={intl.formatMessage({
              id: 'accept',
              defaultMessage: 'Aceptar',
            })}
            draggable={true}
          />
        )}
      </div>
    </div>
  );
};

export default EditLineWindow;
