import React, { Component } from 'react';
import { DataListEntry } from './DataListEntry';
import { css, StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  container: {
    marginLeft: '.75rem',
    flex: 1,
    borderRadius: 5,
    borderWidth: 0.5,
    // borderColor: '#000000',
    maxHeight: '90vh',
    // overflow: 'auto',
    overflowX: 'scroll',
    backgroundColor: '#FFFFFF',
  },
  table: {
    display: 'table',
    width: '100%',
  },
  headerRow: {
    display: 'table-header-group',
  },
  headerCell: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '12px',
    position: 'sticky',
    fontWeight: '500',
    top: 0,
  },
  body: {
    display: 'table-row-group',
  },
  row: {
    color: '#000000',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'table-row',
    backgroundColor: '#ebe8e8',
    transition: '.2s',
  },
  evenRow: {
    backgroundColor: '#FFFFFF',
  },
  selectedRow: {
    backgroundColor: '#B8E0F5',
  },
  cell: {
    display: 'table-cell',
    padding: '.6rem',
    fontSize: '12px',
    borderWidth: '0 0 1px 0',
    borderColor: '#FFFFFF',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  iconNoData: {
    fontSize: '1.5rem',
    marginBottom: '.5rem',
    opacity: '70%',
  },
});
class DataList extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      showWindow: false,
    };
  }

  renderColumns(columns) {
    let columnsToRender = columns.map((item, index) => (
      <div key={index} className={css(styles.cell, styles.headerCell)}>
        {item}
      </div>
    ));

    return columnsToRender;
  }

  handleSelectedRow(item, selected, index) {
    const { setSelectedItem, setSelectedIndex } = this.props;
    this.setState({ selectedRow: item });
    setSelectedItem(item);
    setSelectedIndex(index);
  }

  render() {
    const { rows, columns, addToRefListEntry, selectedNode, selectedIndex } = this.props;
    let columnsToRender = this.renderColumns(columns);

    let cont = (
      <div className={css(styles.container)}>
        <div className={css(styles.table)}>
          <div className={css(styles.headerRow)}>{columnsToRender}</div>
          <div className={css(styles.body)}>
            {rows.map((item, index) => {
              return (
                <div className={css(styles.row, index % 2 && styles.evenRow, selectedIndex === index && styles.selectedRow)} key={index}>
                  <DataListEntry
                    item={item}
                    rowID={index}
                    key={item.value}
                    name={item.value}
                    selectedNode={selectedNode}
                    clickHandler={(item, selected) => {
                      this.handleSelectedRow(item, selected, index);
                    }}
                    ref={(ref) => addToRefListEntry(ref)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );

    return cont;
  }
}

export default DataList;
