import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { injectIntl } from 'react-intl';
import { storeActions } from '../../../src/store';
import ProductionManagerView from './ProductionManagerView';
export class ProductionManagerViewModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.fetchData = this.fetchData.bind(this);
    this.onSelectList = this.onSelectList.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
  }
  async fetchData() {
    this.setState({
      loading: false,
    });
  }

  async onSelectList(info, bottomIndex = 0, dataList) {}

  render() {
    return (
      // <React.Fragment>
      <div
        style={{
          height: '100%',
          display: 'flex',
        }}
      >
        <div
          className="relative w-full text-sm"
          style={{
            height: '90%',
            display: 'flex',
          }}
        >
          <ProductionManagerView loading={this.state.loading} />
        </div>
      </div>
      // </React.Fragment>
    );
  }
}

export default connect('tabs', storeActions)(injectIntl(ProductionManagerViewModel));
