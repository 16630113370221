import React from 'react';
import { useIntl } from 'react-intl';
import { OPTION_MESSAGES, OPTION_PARAMETERS } from '../../../models/constants';
import { GroupInstance } from '../../../models/groupInstance';
import { Info } from '../../../models/processResult';
import { DetailsInformationViewModel } from './detailsInformation/DetailsInformationViewModel';
import { DetailsOptionsViewModel } from './detailsOptions/DetailsOptionsViewModel';
import { InformationComment } from './infoComment/InformationComment';
import { ParamsInformationViewModel } from './paramsInfo/ParamsInformationViewModel';
import { ProcessInformation } from './processInformation/ProcessInformation';

interface HistoryDetailViewProps {
  selectedInstance: GroupInstance;
  optionSelected: string;
  onOptionSelect: (option: string) => void;
  onErrorCheck: () => void;
  onWarningCheck: () => void;
  onInformationCheck: () => void;
  isErrorSelected: boolean;
  isWarningSelected: boolean;
  isInformationSelected: boolean;
  messageSelected?: Info;
  onMessageSelect: (message: Info) => void;
}

export function HistoryDetailView({
  selectedInstance,
  onErrorCheck,
  onInformationCheck,
  onOptionSelect,
  onWarningCheck,
  optionSelected,
  isErrorSelected,
  isInformationSelected,
  isWarningSelected,
  messageSelected,
  onMessageSelect,
}: HistoryDetailViewProps): JSX.Element {
  const intl = useIntl();

  function renderComment(): string {
    const comment: string =
      messageSelected && hasInstanceSelectedMessage()
        ? messageSelected.msgDescription
        : selectedInstance && selectedInstance.processResult.description
        ? selectedInstance.processResult.description
        : '';

    return comment;
  }

  function hasInstanceSelectedMessage(): boolean {
    return optionSelected === OPTION_MESSAGES && includesMessage();
  }

  function includesMessage(): boolean {
    return messageSelected
      ? selectedInstance.processResult.infos.includes(messageSelected) ||
          selectedInstance.processResult.warnings.includes(messageSelected) ||
          selectedInstance.processResult.errors.includes(messageSelected)
      : false;
  }

  return (
    <div className="history-detail-container">
      <div className="flex flex-row top-details">
        <fieldset className="process-detail-information rounded border border-light">
          <legend>{selectedInstance?.name}</legend>
          <ProcessInformation selectedInstance={selectedInstance} />
        </fieldset>
        <fieldset className="process-detail-options rounded border border-light">
          <legend>{intl.formatMessage({ id: 'options' })}</legend>
          <DetailsOptionsViewModel
            optionSelected={optionSelected}
            onOptionSelect={onOptionSelect}
            onErrorCheck={onErrorCheck}
            onInformationCheck={onInformationCheck}
            onWarningCheck={onWarningCheck}
            isWarningSelected={isWarningSelected}
            isErrorSelected={isErrorSelected}
            isInformationSelected={isInformationSelected}
          />
        </fieldset>
      </div>
      <fieldset className="process-detail-low rounded border border-light">
        <legend></legend>
        {optionSelected === OPTION_MESSAGES ? (
          <DetailsInformationViewModel
            isWarningSelected={isWarningSelected}
            isErrorSelected={isErrorSelected}
            isInformationSelected={isInformationSelected}
            results={selectedInstance.processResult}
            onMessageSelect={onMessageSelect}
          />
        ) : (
          optionSelected === OPTION_PARAMETERS && (
            <ParamsInformationViewModel
              params={selectedInstance.paramValues ? selectedInstance.paramValues.parametersList : []}
              group={selectedInstance.name}
            />
          )
        )}
      </fieldset>
      <InformationComment comment={renderComment()} />
    </div>
  );
}
