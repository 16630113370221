/* eslint-disable no-unused-vars */
import 'pure-react-carousel/dist/react-carousel.es.css';

import { css, StyleSheet } from 'aphrodite';
import { ButtonBack, ButtonNext, CarouselProvider, DotGroup, ImageWithZoom, Slide, Slider } from 'pure-react-carousel';
import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { fileArrayToBase64 } from './../../../../UI-kit/helpers/fileHelper';
import checkExample from './../../../resources/example-check-copy.jpg';
import { useTheme } from './../../../themes/ThemeContext';

const CheckImages = ({ images }) => {
  const themeContext = useTheme();
  const { border } = themeContext.theme;

  const styles = StyleSheet.create({
    checkImagesContainer: {
      borderBottom: `1px solid ${border.dark}`,
      width: '100%',
      height: '100%',
      borderRadius: 2,
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
    },
    checkImage: {
      objectFit: 'contain',
      height: '15rem',
      ':nth-child(1n) > div': {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    buttonsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      position: 'relative',
      top: '-15rem',
      height: '100%',
    },
    button: {
      boxSizing: 'border-box',
      height: '100%',
      borderWidth: 1,
      padding: '.5em',
      borderRadius: 2,
    },
  });

  const sources = images.map((image) => fileArrayToBase64(image));

  return (
    <CarouselProvider totalSlides={images.length} naturalSlideWidth={280} naturalSlideHeight={100}>
      <Slider className={css(styles.checkImagesContainer)}>
        {sources.map((source, index) => (
          <Slide key={index} index={index}>
            <ImageWithZoom className={css(styles.checkImage)} src={source} />
          </Slide>
        ))}
      </Slider>
      <div className={css(styles.buttonsContainer)}>
        <ButtonBack className={css(styles.button)}>Anterior</ButtonBack>
        <ButtonNext className={css(styles.button)}>Siguiente</ButtonNext>
      </div>
    </CarouselProvider>
  );
};

export default CheckImages;
