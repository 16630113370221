import React, { Component } from 'react'
import * as fromReport from './report'

const withReportServices = (BaseComponent) => {
  class HOC extends Component {
    render () {
      return (
        <BaseComponent
          {...this.props}
          {...fromReport}
        />
      )
    }
  }

  return HOC
}

export default withReportServices
