import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import errorMessageIcon from '../../../../icons/errorMessage';
import informationIcon from '../../../../icons/information';
import warningIcon from '../../../../icons/warning';
import { ERRORS_CHECKBOX, WARNINGS_CHECKBOX } from '../../../../models/constants';
import { Info } from '../../../../models/processResult';

import '../Information.scss';

interface DetailsInformationViewProps {
  results: Info[];
  onSelect: (message: Info) => () => void;
}

export function DetailsInformationView({ results, onSelect }: DetailsInformationViewProps): JSX.Element | null {
  const intl = useIntl();

  function renderIcon(type: string): JSX.Element {
    const icon: JSX.Element = type === ERRORS_CHECKBOX ? errorMessageIcon : type === WARNINGS_CHECKBOX ? warningIcon : informationIcon;

    return icon;
  }

  function renderDescriptionShowed(comment: string): string {
    const commentWithBreaks: string = comment.replace('.', '.\n');
    let commentShowed = commentWithBreaks.split('\n')[0];
    if (commentShowed !== comment) {
      commentShowed += ' ...';
    }

    return commentShowed;
  }

  return (
    <div className="w-full">
      <div className="pl-2">
        <div className="flex items-center justify-between text-xs uppercase text-grey py-2 border-b border-light">
          <div className="grid-title short-info">{intl.formatMessage({ id: 'type' })}</div>
          <div className="grid-title short-info">{intl.formatMessage({ id: 'idProcess' })}</div>
          <div className="grid-title description">{intl.formatMessage({ id: 'description' })}</div>
          <div className="grid-title short-info">{intl.formatMessage({ id: 'date' })}</div>
          <div className="grid-title short-info">{intl.formatMessage({ id: 'hour' })}</div>
          <div className="grid-title short-info">#</div>
        </div>
        <div className="parameters-information">
          {results.map((item, index) => (
            <div className={` ${index % 2 && 'bg-grey-lighter-lighter'} cursor-pointer`} key={index} onClick={onSelect(item)}>
              <div className="flex items-center justify-between border-t border-smoke py-2 h-10 cursor-pointer">
                <div className="row-element short-info">{renderIcon(item.errorType)}</div>
                <div className="row-element short-info">{item.execProcessId}</div>
                <div className="row-element description">{renderDescriptionShowed(item.msgDescription)}</div>
                <div className="row-element short-info ml-1 mr-2">{moment(item.errorDate).format('DD/MM/YYYY')}</div>
                <div className="row-element short-info">{moment(item.errorDate).format('HH:mm:ss')}</div>
                <div className="row-element short-info">{item.sequenceLog}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
