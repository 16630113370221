import React from 'react';

import { WarningModalView } from './WarningModalView';

export interface WarningModalViewModelProps {
  onModalChange: (accept: boolean) => void;
  username: string;
  sameNumberOf: string;
}

export function WarningModalViewModel({ onModalChange, username, sameNumberOf }: WarningModalViewModelProps): JSX.Element | null {
  return <WarningModalView username={username} sameNumberOf={sameNumberOf} onChange={onModalChange} />;
}
