import React, { Component } from 'react';
import SignaturesListEntry from './SignaturesListEntry';
import { FormattedMessage } from 'react-intl';

class ListOfSignaturesView extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    const {
      data,
      noDataText,
      handleSelectedSignature,
      addToRefs,
      addToRefListEntry,
    } = this.props;
    let content = (
      <div className="w-3/5 mt-8">
        <div className="pl-2">
          <div className="flex items-center justify-between text-xs uppercase text-grey py-2 border-b border-light">
            <div className="flex-grow items-center ml-30 w-10 text-xs text-nowrap">
              X
            </div>
            <div className="flex-grow items-center ml-30 w-10 text-xs text-nowrap">
              <FormattedMessage id="numeroPersona" defaultMessage="Numero Persona" />
            </div>
            <div className="flex-grow items-center ml-30 w-10 text-xs text-nowrap">
              <FormattedMessage id="tipoDocumento" defaultMessage="Tipo Documento" />
            </div>
            <div className="flex-grow items-center ml-30 w-10 text-xs text-nowrap">
              <FormattedMessage
                id="numeroDocumento"
                defaultMessage="Numero Documento"
              />
            </div>
            <div className="flex-grow items-center ml-30 w-10 text-xs text-nowrap">
              <FormattedMessage id="status" defaultMessage="Status" />
            </div>
            <div className="flex-grow items-center ml-30 w-10 text-xs text-nowrap">
              <FormattedMessage id="nombre" defaultMessage="Nombre" />
            </div>
          </div>
          <div className="overflow-y-scroll" style={{ height: 480 }}>
            {data.map((item, index) => (
              <div
                className={` ${index % 2 && 'bg-grey-lighter-lighter'}`}
                key={index}
              >
                <SignaturesListEntry
                  item={item}
                  rowID={index}
                  key={item.value}
                  name={item.value}
                  addToRefs={addToRefs}
                  clickHandler={async (item, selected) => {
                    handleSelectedSignature(item, selected);
                  }}
                  ref={(ref) => addToRefListEntry(ref)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );

    if (data.length === 0) {
      content = (
        <div className="w-full flex items-center">
          <div className="flex-grow text-center">{noDataText}</div>
        </div>
      );
    }

    return content;
  }
}

export default ListOfSignaturesView;
