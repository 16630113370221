import React from 'react';
import { connect } from 'unistore/react';
import { store, storeActions } from '../../src/store';
import { createBodyGoToLine, createSimpleBody } from '../../renderer/lib/bodyCreators';
import { gotoLineWithBody } from '../../src/api/dialog';
import { getCurrentTabInformation } from '../../src/selectors';
import { addLineEventTest, addLineTest } from '../../src/components/Testing/HandleLineTest';
import { canReceiveFocus } from '../../renderer/lib/focusManager';

import Displayable from './Displayable';

const RadioInput = (props) => {
  let input = React.useRef(null);
  const [focusGainedBy, setFocusGainedBy] = React.useState('');
  const focus = () => {
    const { transactionLineLabel, focusElement, focusPaused } = props;
    if (input && input.current && transactionLineLabel === focusElement && !focusPaused && transactionLineLabel !== document.activeElement.id) {
      input.current.scrollIntoView(false);
      input.current.focus();
    }
  };

  let {
    options = [],
    onChange,
    componentState,
    lineNumber,
    transactionNumber,
    operationID,
    operationNumber,
    processContext,
    onKeyDown,
    transactionLineLabel,
    position = { x: 0, y: 0, width: 'auto', height: 'auto' },
    focusElement,
    labelFont,
    fieldData,
    showPrompt,
    removeFromWindowStack,
    removeTreeNode,
    readOnly,
  } = props;

  readOnly = readOnly || (componentState && componentState.disabled);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => focus(), [focusElement]);

  React.useEffect(() => {
    const lastValueListener = (e) => {
      e = e || window.event;
      if (e.ctrlKey && e.keyCode === 76) {
        e.preventDefault();
        e.stopPropagation();
        window.event.returnValue = false;
        window.event.cancelBubble = true;
        const { addFieldValue, lastValues, transactionLineLabel, fieldNumber } = props;
        const lastValue = lastValues && lastValues[fieldNumber];
        if (lastValue) {
          const value = { [transactionLineLabel]: { value: lastValue } };
          addFieldValue(value);
        }
        return false;
      }
    };
    if (input.current) {
      input.current.onkeydown = lastValueListener;
    }
    return () => {
      if (input.current) {
        input.current.removeEventListener('keydown', lastValueListener);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const largestRadio = (arr) => {
    return arr.length && arr.reduce((a, b) => (a.label.length > b.label.length ? a : b));
  };

  /**
   * Function used in jDesktop andadapted to topweb
   * to obtain the correct position of the radio inputs.
   * @param {number} radioHeight Number of pixels of the highest radio
   * @param {number} radioWidth Number of pixels of the widest radio
   * @param {number} radiosCount Number of radios
   * @param {number} position Position of Radios provided by the API
   */
  const radioPositions = (radioHeight, radioWidth, radiosCount, position) => {
    function floorDiv(radiosCount, columsCount) {
      if (radiosCount % columsCount === 0) {
        return Math.floor(radiosCount / columsCount);
      } else {
        return Math.floor(radiosCount / columsCount + 1);
      }
    }

    const SEPARACION_X_MIN_OPCIONES = 8;
    let columnCount = 1;
    let radiosPerColumn;

    let offsetX = radioWidth;
    let offsetY = radioHeight;

    // calcular la cantidad de columnas
    while (offsetX + SEPARACION_X_MIN_OPCIONES + radioWidth <= position.width) {
      columnCount++;
      offsetX += SEPARACION_X_MIN_OPCIONES + radioWidth;
    }

    // calcular la cantidad de radio botones por columna
    radiosPerColumn = floorDiv(radiosCount, columnCount);

    // quitar columnas para arreglar de la forma más cuadrada posible
    while (columnCount > 1 && radiosPerColumn * (columnCount - 1) >= radiosCount) {
      columnCount--;
      radiosPerColumn = floorDiv(radiosCount, columnCount);
    }

    // calcular las separaciones entre las columnas y las filas
    let yRadioSeparation = radiosPerColumn > 1 ? (position.height - radioHeight * radiosPerColumn) / (radiosPerColumn - 1) : 0;
    let xRadioSeparation = columnCount > 1 ? (position.width - radioWidth * columnCount) / (columnCount - 1) : 0;

    // posicionar los radio botones
    offsetX = 0;
    offsetY = 0;

    let columnRadioCount = 0;

    const positionsRadios = [];
    for (let i = 0; i < radiosCount; i++) {
      columnRadioCount++;

      const posRadio = { x: offsetX, y: offsetY };
      positionsRadios.push(posRadio);

      if (columnRadioCount === radiosPerColumn) {
        columnRadioCount = 0;
        offsetX += radioWidth + xRadioSeparation;
        offsetY = 0;
      } else {
        offsetY += radioHeight + yRadioSeparation;
      }
    }

    return positionsRadios;
  };

  if (!options.length) {
    return null;
  }

  const largest = largestRadio(options);
  const radiogroup = document.getElementById('auxSpanRadio');
  if (radiogroup) {
    radiogroup.remove();
  }
  const theSpan = document.createElement('span');
  theSpan.id = 'auxSpanRadio';
  theSpan.style.visible = false;
  const radio = document.createElement('input');
  radio.type = 'radio';
  radio.name = 'radiogroup';
  radio.value = 'A';
  theSpan.appendChild(radio);
  const aText = document.createTextNode(largest.label);
  theSpan.appendChild(aText);
  document.body.appendChild(theSpan);
  const largestHeight = theSpan.offsetHeight;
  const largestWidth = theSpan.offsetWidth;

  const positions = radioPositions(
    largestHeight,
    largestWidth / 1.1, // ratio para adaptar el algoritmo que viene de jDesktop
    options.length,
    position,
  );

  const renderRadioButtons = options.map((option, index) => {
    const { componentState } = props;
    const currentValue = componentState && componentState.value;
    const top = positions[index].y;
    const left = positions[index].x;

    const id = `${transactionLineLabel}_${index}`;
    return (
      <div
        key={index}
        className={`${showPrompt ? 'flex content-center' : ''} bg-grey-lighter-lighter `}
        style={{
          position: 'absolute',
          top,
          left,
        }}
      >
        <input
          id={id}
          disabled={readOnly}
          type="radio"
          value={option.value}
          className="focus:shadow-sm-light"
          checked={option.value === currentValue}
          name={transactionLineLabel}
          onChange={() => {}}
          ref={(i) => {
            if (currentValue && option.value === currentValue) {
              input.current = i;
            } else if (index === 0) input.current = i;
          }}
        />
        <label
          className={`ml-2 ${showPrompt ? '' : 'hidden'} bg-grey-lighter-lighter`}
          style={{
            fontSize: (labelFont && labelFont.fontSize) || 13,
            padding: 2,
            whiteSpace: 'nowrap',
          }}
        >
          {option.label}
        </label>
      </div>
    );
  });

  const setLastValue = (valueToSave) => {
    const { setLastValue, fieldNumber } = props;
    setLastValue(fieldNumber, valueToSave);
  };

  const handleMouseDown = async (e) => {
    addLineTest('CLICK', e);
    let state = store.getState();
    if (!canReceiveFocus(e) || readOnly || (state.globalMessage && state.globalMessage.visible)) return;

    if (!e.target.value && e.target.control) {
      e.target.value = e.target.control.value;
    }
    if (e.target.value || e.target.value === 0) {
      onChange(e);
      setLastValue(e.target.value);
      let body = createSimpleBody(operationNumber, transactionLineLabel, e.target.value);
      if (transactionLineLabel !== focusElement) {
        let bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
        let res = await gotoLineWithBody(operationID, transactionNumber, lineNumber, bodyGoToLine);
        processContext(res, 'gotoLine radio input');
      }
    } else {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e) => {
    addLineEventTest(e);
    if (e.key === 'Enter') {
      setLastValue(e.target.value);
    }
    onKeyDown(e, operationID, operationNumber, processContext, focusElement, null, removeFromWindowStack, removeTreeNode);
  };

  return (
    <Displayable componentState={componentState}>
      <div
        className="absolute"
        style={{
          left: position.x,
          top: position.y,
          width: position.width,
          height: position.height,
        }}
        disabled={readOnly}
        readOnly={readOnly}
      >
        <div
          disabled={readOnly}
          readOnly={readOnly}
          className={`h-full w-full`}
          onFocus={(e) => {
            if (focusGainedBy === 'click') {
              handleMouseDown(e);
              setFocusGainedBy('');
            } else {
              canReceiveFocus(e);
            }
          }}
          onMouseDown={handleMouseDown}
          onKeyDown={handleKeyDown}
          onBlur={(e) => {
            if (e.relatedTarget === null || e.relatedTarget.classList.contains('contorno')) {
              focus();
            }
          }}
          id="containerRadio"
        >
          {renderRadioButtons}
        </div>
      </div>
    </Displayable>
  );
};

const mapStateToProps = (state) => {
  const { operationID, operationNumber, focusElement } = getCurrentTabInformation(state);

  const { lastValues } = state;

  return {
    lastValues,
    operationID,
    operationNumber,
    focusElement,
  };
};

export default connect(mapStateToProps, storeActions)(RadioInput);
