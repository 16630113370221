import React from 'react';
import Logo from '../src/components/Sidebar/LogoView';
import './ErrorBoundary.scss';
import { store } from '../src/store';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  /* componentDidCatch(error, errorInfo) {
    // log the error to an error reporting service
  } */

  render() {
    if (this.state.hasError) {
      const state = store.getState();
      const operationInfoShown = state.tabs && state.tabs[state.current];
      return (
        <div className="ErrorComponent">
          <div className="errorLogo">
            <Logo isCollapsed={false} />
          </div>

          <h1>The system encountered an issue</h1>
          <h3>Please contact the development team with a screenshot containing the following information :</h3>

          <table>
            <tbody>
              <tr>
                <td>
                  <div className="labelTitle">System information</div>
                </td>
                {operationInfoShown && (
                  <td>
                    <div className="labelTitle">Operation information</div>
                  </td>
                )}
              </tr>

              <tr>
                <td className="rowCell">
                  <table>
                    <tbody>
                      <tr>
                        <td className="information">Date</td>
                        <td className="value">{new Date().toISOString()}</td>
                      </tr>
                      {process && process.env && (
                        <tr>
                          <td className="information">TopWeb version</td>
                          <td className="value">{process.env.TOPWEB_VERSION}</td>
                        </tr>
                      )}
                      {state && (
                        <tr>
                          <td className="information">Current app</td>
                          <td className="value">{state.currentApp}</td>
                        </tr>
                      )}
                      {document && document.location && (
                        <tr>
                          <td className="information">Host</td>
                          <td className="value">{document.location.host}</td>
                        </tr>
                      )}
                      {navigator && (
                        <tr>
                          <td className="information">Agent</td>
                          <td className="value">{navigator.userAgent}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>

                {operationInfoShown && (
                  <td className="rowCell">
                    <table>
                      <tbody>
                        <tr>
                          <td className="information">Posting number</td>
                          <td className="value">{state.tabs[state.current].postingNumber}</td>
                        </tr>
                        <tr>
                          <td className="information">Operation</td>
                          <td className="value">
                            {state.tabs[state.current].operationNumber} - {state.tabs[state.current].operationName}
                          </td>
                        </tr>
                        <tr>
                          <td className="information">Transaction</td>
                          <td className="value">
                            {state.tabs[state.current].transactionNumber} - {state.tabs[state.current].transactionDescription}
                          </td>
                        </tr>
                        {state.tabs[state.current].running && (
                          <tr>
                            <td className="information">Focus element</td>
                            <td className="value">{state.tabs[state.current].running.focusElement}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </td>
                )}
              </tr>
            </tbody>
          </table>

          {this.state.error && (
            <React.Fragment>
              <div className="labelTitle">System message</div>
              <p>{this.state.error.message}</p>
              <div className="labelTitle">Stacktrace</div>
              <pre>{this.state.error.stack}</pre>
            </React.Fragment>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}
