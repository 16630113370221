import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { getSessionInfo } from '../../src/api/session';
import xml from '../../PaniniXTDeviceParameters';
import { handleError } from './handlers';

export let turnOnScanner = (scannerData) => {
  const scannerIp = scannerData.IP;
  const appInstId = scannerData.APPINSTID;
  return axios.get(`http://${scannerIp}/connect`, {
    headers: { AppInstId: appInstId },
  });
};

export let turnOffScanner = (scannerData) => {
  const scannerIp = scannerData.IP;
  const appInstId = scannerData.APPINSTID;
  return axios.get(`http://${scannerIp}/disconnect`, {
    headers: { AppInstId: appInstId },
  });
};

export let getCheckScanner = (machineNumber) => {
  // reemplazar la ip con la variable api
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .get(`${API}TopazWebClientWS/resources/checks/getEscanerCheques?sessionId=${session}&nroMaquina=${machineNumber}`)
    .then(({ data }) => data);
};

export let addParameter = (params) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios.post(`${API}TopazWebClientWS/resources/checks/addParameters?sessionId=${session}`, params).then(({ data }) => data);
};

export let getParameter = async (param) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  const response = await axios.get(`${API}TopazWebClientWS/resources/checks/getParameter?sessionId=${session}&parameterKey=${param}`);
  return response.data;
};

export let initializeApplication = (fileName) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(`${API}TopazWebClientWS/resources/checks/initializeApplication?sessionId=${session}&fileName=${fileName}`)
    .then(({ data }) => data);
};

export let getGridDefinition = () => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios.get(`${API}TopazWebClientWS/resources/checks/getGridDefinition?sessionId=${session}`).then(({ data }) => data);
};

export let config = async (scannerData) => {
  const scannerIp = scannerData.IP;
  const appInstId = scannerData.APPINSTID;
  const headers = {
    AppInstId: appInstId,
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  await turnOnScanner(scannerData);
  return axios.post(`http://${scannerIp}/config`, xml(), {
    headers: headers,
  });
};

export let scan = () => {
  let scannerIp; //= scannerData.IP;
  // const appInstId = scannerData.APPINSTID;
  scannerIp = 'http://localhost:8000/CheckScannerWebService/Scan';
  /* const headers = {
    AppInstId: appInstId,
  }; */
  return axios.get(scannerIp);
};

export let cancel = () => {
  let scannerIp; //= scannerData.IP;
  // const appInstId = scannerData.APPINSTID;
  scannerIp = 'http://localhost:8000/CheckScannerWebService/Cancel';
  /* const headers = {
    AppInstId: appInstId,
  }; */
  return axios.post(scannerIp);
};

export let getCapturedMICR = () => {
  let scannerIp = 'http://localhost:8000/CheckScannerWebService/getCapturedMICR';
  return axios.post(scannerIp);
};

export let getCapturedFrontImage = () => {
  let scannerIp = 'http://localhost:8000/CheckScannerWebService/getCapturedFrontImage';
  return axios.post(scannerIp);
};

export let getCapturedRearImage = () => {
  let scannerIp = 'http://localhost:8000/CheckScannerWebService/getCapturedRearImage';
  return axios.post(scannerIp);
};

export let currentDeviceStateEx = (scannerData) => {
  const scannerIp = scannerData.IP;
  const appInstId = scannerData.APPINSTID;
  const headers = {
    AppInstId: appInstId,
  };
  return axios
    .get(`http://${scannerIp}/currentdevicestateex`, { headers: headers }, { timeout: 10000 })
    .then(({ data }) => data)
    .catch(() => {
      return 'Network error';
    });
};

export let removeItemFromList = (scannerData, documentId) => {
  const scannerIp = scannerData.IP;
  const appInstId = scannerData.APPINSTID;
  const headers = {
    AppInstId: appInstId,
  };
  return axios.post(`http://${scannerIp}/removeitemfromlist`, `DOCID=${documentId}`, { headers: headers });
};

export let freeTrack = async () => {
  let session = await getSessionInfo();
  let { machineNumber } = session;
  let scannerData = await getCheckScanner(machineNumber);
  const scannerIp = scannerData.IP;
  const appInstId = scannerData.APPINSTID;
  const headers = {
    AppInstId: appInstId,
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  return axios.post(`http://${scannerIp}/freetrack`, `POCKET=1`, {
    headers: headers,
  });
};

export let getImage = async (url) => {
  return axios.get(url, { responseType: 'blob', headers: { 'Content-Type': 'image/jpeg' } }).then(({ data }) => {
    // eslint-disable-next-line no-undef
    let fr = new FileReader();
    return new Promise((resolve, reject) => {
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.readAsDataURL(data);
    });
  });
};

export let validateChecks = async (checkSet) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.post(`${API}TopazWebClientWS/resources/checks/validateChecks?sessionId=${session}`, checkSet);
    if (response.status === 200) {
      if (response.data.status === 0) {
        return 'OK';
      } else {
        return response.data.errorMessage;
      }
    } else {
      handleError(response);
    }
  } catch (error) {
    handleError(error);
  }
};

export let executeFunction = async (params) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(`${API}TopazWebClientWS/resources/checks/executeFunction?sessionId=${session}`, params)
    .then(({ data }) => data.message)
    .catch((err) => {
      return { error: true, message: err.message };
    });
};

export let getChecks = async (checkSet) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios.post(`${API}TopazWebClientWS/resources/checks/getChecks?sessionId=${session}`, checkSet).then(({ data }) => data);
};

export let getDrippedCheckImages = async (params) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios.post(`${API}TopazWebClientWS/resources/checks/getDrippedCheckImages?sessionId=${session}`, params).then(({ data }) => data);
};

export let insertDrippedChecks = async (checkSet) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(`${API}TopazWebClientWS/resources/checks/insertDrippedCheck?sessionId=${session}`, checkSet)
    .then(({ data }) => data)
    .catch((err) => {
      return err.response.data;
    });
};

export let insertChecks = async (checkSetList) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(`${API}TopazWebClientWS/resources/checks/insertChecks?sessionId=${session}`, checkSetList)
    .then(({ data }) => data)
    .catch((error) => {
      return error.response.data;
    });
};

export let deleteChecks = (checkSetList, properties) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios.delete(`${API}TopazWebClientWS/resources/checks/deleteChecks?sessionId=${session}`, { data: checkSetList });
};

export let setDatosDelCheque = async (datos) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios.post(`${API}TopazWebClientWS/resources/checks/setDatosDelCheque?sessionId=${session}`, datos).then(({ data }) => data);
};
