import React from 'react';
import { store } from '../../../store';
import Field from '../components/Field';

const formatDateForServer = (date) => {
  if (date) {
    const pad = (number) => `0${number}`.slice(-2);
    let runDate = new Date(date.valueOf());
    const year = runDate.getFullYear();
    const month = runDate.getMonth() + 1;
    const day = runDate.getDate();
    const hour = runDate.getHours();
    const minute = runDate.getMinutes();
    const second = runDate.getSeconds();
    return `${year}-${pad(month)}-${pad(day)} ${pad(hour)}:${pad(minute)}:${pad(second)}`;
  } else {
    const pad = (number) => `0${number}`.slice(-2);
    let runDate = new Date();
    const year = runDate.getFullYear();
    const month = runDate.getMonth() + 1;
    const day = runDate.getDate();
    const hour = 0;
    const minute = 0;
    const second = 0;
    return `${year}-${pad(month)}-${pad(day)} ${pad(hour)}:${pad(minute)}:${pad(second)}`;
  }
};

const getFilterField = (id, updateFilterInfo, intl, helpButton, filterListID, value) => {
  const bitacoraApp = store.getState().bitacoraApp;

  if (!bitacoraApp[filterListID] || !bitacoraApp[filterListID].filterParams) {
    let filterData = {};
    filterData[filterListID] = {
      ...store.getState().bitacoraApp[filterListID],
      filterParams: {},
    };
    store.setState({
      bitacoraApp: {
        ...store.getState().bitacoraApp,
        ...filterData,
      },
    });
  }

  if (value === undefined && bitacoraApp[filterListID]) {
    value = bitacoraApp[filterListID].filterParams ? bitacoraApp[filterListID].filterParams[id] : '';
  }

  const labelInfo = intl.formatMessage({
    id: id,
    defaultMessage: id,
  });

  return (
    <Field htmlFor={id} labelText={labelInfo} showFieldBelow={true} key={id}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <input
          id={id}
          type="text"
          value={value}
          style={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'rgb(220, 224, 230)',
            borderRadius: '2px',
            width: '100%',
          }}
          onChange={(e) => {
            updateFilterInfo(e);
          }}
        />
        {helpButton}
      </div>
    </Field>
  );
};

const getFilterFieldValue = (id, e, isCheckbox) => {
  if (e && e.target && e.target.id === id) {
    if (isCheckbox) {
      return e.target.checked;
    } else {
      return e.target.value;
    }
  } else if (document.getElementById(id)) {
    if (isCheckbox) {
      return document.getElementById(id).checked;
    } else {
      return document.getElementById(id).value;
    }
  }
};

export { getFilterField, formatDateForServer, getFilterFieldValue };
