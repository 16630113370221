import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CategoriesGrid from '../components/CategoriesGrid';
import { connect } from 'unistore/react';
import { store, storeActions } from '../store';
import { getCategoriesFromItemType } from './../selectors';
import ItemsGrid from '../components/ItemsGrid';
import { getReportGenerationSpec } from '../api/report';
import { FormattedMessage, useIntl } from 'react-intl';
import GridIcon from './../resources/icons/fa/grip-horizontal';
import AngleRightIcon from './../resources/icons/fa/angle-right.svg';
import { useTheme } from './../themes/ThemeContext';
import { StyleSheet, css } from 'aphrodite';

const propTypes = {
  itemsToShow: PropTypes.string.isRequired,
  getOperationsFromAPI: PropTypes.func.isRequired,
  getReportsFromAPI: PropTypes.func.isRequired,
  categories: PropTypes.array,
  showItemsInsteadOfCategories: PropTypes.bool.isRequired,
  selectedCategory: PropTypes.object,
  showCategories: PropTypes.func.isRequired,
};

const MenuGrid = (props) => {
  const {
    addTab,
    addTabReport,
    categories,
    getOperationsFromAPI,
    getReportsFromAPI,
    itemsToShow,
    selectCategory,
    selectedCategory,
    showCategories,
    showItemsInsteadOfCategories,
  } = props;

  const themeState = useTheme();
  const { icon, primary } = themeState.theme;

  const [titleHover, setTitleHover] = useState(false);
  const [gridIconColor, setGridIconColor] = useState(icon.icon);

  const intl = useIntl();

  React.useEffect(() => {
    const state = store.getState();
    if ((itemsToShow === 'OPERATIONS' && !state.operationsData) || (itemsToShow === 'OPERATIONS' && state.operationsData.categories.length === 0)) {
      getOperationsFromAPI();
    } else if (itemsToShow === 'REPORTS') {
      getReportsFromAPI();
    }

    showCategories();

    return () => showCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryClick = (category) => {
    selectCategory(category);
  };
  const handleItemClick = (id, name, look) => {
    if (itemsToShow === 'OPERATIONS') {
      addTab(id, name, '', true, look);
    } else if (itemsToShow === 'REPORTS') {
      getReportGenerationSpec(id, true).then((reportToShow) => addTabReport(reportToShow));
    }
  };

  const handleTitleHover = () => {
    setTitleHover(true);
  };

  const handleTitleBlur = () => {
    setTitleHover(false);
  };

  const titleTooltip = intl.formatMessage({
    id: 'backToCategories',
    defaultMessage: 'Volver a las categorías',
  });

  const styles = StyleSheet.create({
    breadCrumbContainer: {
      fontSize: '1.5rem',
      marginBottom: '.75rem',
      display: 'flex',
      whiteSpace: 'pre',
    },
    titleContainer: {
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
      cursor: showItemsInsteadOfCategories && 'pointer',
      ':hover': {
        color: showItemsInsteadOfCategories && primary.dark,
      },
    },
    titleText: {
      textDecoration: titleHover && showItemsInsteadOfCategories && 'underline',
    },
  });

  useEffect(() => {
    if (!showItemsInsteadOfCategories) {
      setGridIconColor(icon.icon);
    } else if (titleHover) {
      setGridIconColor(icon.hover);
    } else if (gridIconColor !== icon.icon) {
      setGridIconColor(icon.icon);
    }
  }, [gridIconColor, icon.hover, icon.icon, showItemsInsteadOfCategories, titleHover]);

  return (
    <div>
      <div className={css(styles.breadCrumbContainer)} style={{ fontFamily: 'Roboto' }}>
        <span
          className={css(styles.titleContainer)}
          onClick={() => {
            if (showItemsInsteadOfCategories) showCategories();
          }}
          onMouseEnter={handleTitleHover}
          onMouseLeave={handleTitleBlur}
          title={showItemsInsteadOfCategories ? titleTooltip : undefined}
        >
          <GridIcon width="22px" fill={gridIconColor} />
          <span> </span>
          <span className={css(styles.titleText)}>
            <FormattedMessage id="categories" defaultMessage={`Categorías`} />
          </span>
        </span>
        {showItemsInsteadOfCategories ? (
          <React.Fragment>
            <span>
              {' '}
              <AngleRightIcon width="8px" fill={icon.icon} />{' '}
            </span>
            <span className="text-primary-dark select-none">{selectedCategory.name}</span>
          </React.Fragment>
        ) : (
          <span></span>
        )}
      </div>
      {showItemsInsteadOfCategories ? (
        <ItemsGrid items={selectedCategory.items} handleClick={handleItemClick} />
      ) : (
        <CategoriesGrid categories={categories} handleClick={handleCategoryClick} />
      )}
    </div>
  );
};

MenuGrid.propTypes = propTypes;

const mapStateToProps = (state, props) => {
  const { itemsToShow } = props;
  return {
    categories: getCategoriesFromItemType(state, itemsToShow),
    showItemsInsteadOfCategories: state.menuGridInfo.showItemsInsteadOfCategories,
    selectedCategory: state.menuGridInfo.selectedCategory,
  };
};

export default connect(mapStateToProps, storeActions)(MenuGrid);
