import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { handleError, getUrl, handleEndpointError } from './handlers';

export let openBranch = async (body) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.put(`${API}TopazWebClientWS/resources/branches/openBranch?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return error.response.data.message;
  }
};

export let closeBranch = async (body) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.put(`${API}TopazWebClientWS/resources/branches/closeBranch?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return error.response.data.message;
  }
};

export let finalizeBranch = async (body) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const response = await axios.put(`${API}TopazWebClientWS/resources/branches/finalizeBranch?sessionId=${session}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return error.response.data.message;
  }
};

export let getState = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'branches',
      action: 'getState',
      params: `sessionId=${session}`,
    };
    const response = await axios.get(getUrl(query));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let changeDate = async (newDate, body) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    let response = await axios.post(`${API}TopazWebClientWS/resources/branches/changeDate?sessionId=${session}&newDate=${newDate}`, body);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    handleEndpointError(error);
    return error.response.data.message;
  }
};

export let getBranchDates = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'branches',
      action: 'getBranchDates',
      params: `sessionId=${session}`,
    };
    const response = await axios.get(getUrl(query));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getPendings = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'branches',
      action: 'getPendings',
      params: `sessionId=${session}`,
    };
    const response = await axios.get(getUrl(query));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let hasPendings = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'branches',
      action: 'hasPendings',
      params: `sessionId=${session}`,
    };
    const response = await axios.get(getUrl(query));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let changeBranch = async (branch) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'branches',
      action: 'changeBranch',
      params: `branch=${branch}&sessionId=${session}`,
    };
    const response = await axios.put(getUrl(query));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let updateBranch = async (body) => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    return axios.post(`${API}TopazWebClientWS/resources/branches/updateBranch?sessionId=${session}`, body);
  } catch (error) {
    handleEndpointError(error);
  }
};

export let getBranches = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'branches',
      action: 'getBranches',
      params: `sessionId=${session}`,
    };
    const response = await axios.get(getUrl(query));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};

export let hasVirtualBranches = async () => {
  try {
    let session = browserStorage.sessionGet(USER_SESSION);
    const query = {
      endpoint: 'branches',
      action: 'hasVirtualBranches',
      params: `sessionId=${session}`,
    };
    const response = await axios.get(getUrl(query));
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response);
    }
  } catch (error) {
    handleEndpointError(error);
  }
};
