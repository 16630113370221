import React, { Component } from 'react';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';
import { FormattedMessage } from 'react-intl';
import { ABOVE_ALL } from '../../UI-kit/constants/zIndexValues';
import Overlay from '../../UI-kit/components/Overlay';
import Dialog from '../../UI-kit/components/Dialog';
import { reassignTaskWithState } from '../../src/api/workflow';
import { getUserName } from '../helpers/login';
import { PulseLoader } from 'halogenium';

const Button = ({ title, click, className }) => {
  return (
    <button
      className={`${className} bg-grey text-white text-center rounded shadow py-2 px-2 text-sm hover:bg-primary focus:outline-none`}
      onClick={click}
    >
      {title}
    </button>
  );
};

const ListUsersAssign = ({ usersAssign, usersAssignSelected, selectUserAssign, toAssign }) => (
  <ul className="flex-1 list-reset flex-grow flex flex-col overflow-y-auto h-full border border-smoke bg-white">
    {usersAssign.map((user, index) => (
      <li
        key={index}
        className={`${usersAssignSelected.includes(index) ? 'bg-smoke' : ''} block text-sm px-2 py-1 hover:bg-grey-lighter-lighter`}
        onClick={() => {
          selectUserAssign(index, toAssign);
        }}
      >
        {user.value} ({user.name})
      </li>
    ))}
  </ul>
);

const sortUsersAssign = (userssAssign) =>
  userssAssign.sort((a, b) => {
    if (a.value.toUpperCase() > b.value.toUpperCase()) return 1;
    if (a.value.toUpperCase() < b.value.toUpperCase()) return -1;
    return 0;
  });

export class WorkflowReassignWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersToAssign: null,
      usersAssigned: null,
      toAssignSelected: [],
      assignedSelected: [],
      usersFiltered: null,
      filter: '',
      showDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      pulseLoader: <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />,
    };

    this.selectAssign = this.selectAssign.bind(this);
    this.removeAssigned = this.removeAssigned.bind(this);
    this.addAssigned = this.addAssigned.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }

  componentDidMount() {
    this.generateFilteredUserList();
  }
  componentDidUpdate() {
    this.generateFilteredUserList();
  }
  generateFilteredUserList() {
    const { usersGroups } = this.props;
    if (usersGroups && !this.state.usersToAssign) {
      let usersToAssign = usersGroups.map((userG) => ({ name: userG.clave, value: userG.descripcion }));
      usersToAssign = sortUsersAssign(usersToAssign);

      // let usersAssignedMatriz = taskToReassign.assigned;
      let usersAssigned = [];
      /*  if (usersAssignedMatriz) {
        usersAssignedMatriz.forEach((user) => {
          let userAux = usersToAssign.find((userA) => userA.value === user);
          if (user !== '') usersAssigned.push({ name: userAux ? userAux.name : user, value: user });
        });
      } */
      usersToAssign = usersToAssign.filter((userToA) => !usersAssigned.some((userA) => userA.value === userToA.value));
      this.setState({ usersToAssign, usersAssigned, usersFiltered: usersToAssign });
    }
  }

  hideDialog() {
    this.setState({ showDialog: false, dialogMessage: '', dialogTitle: null });
  }

  async handleAssign() {
    const { taskToReassign, handleCancel } = this.props;
    const { usersAssigned } = this.state;
    let usersAndGroups = usersAssigned.map((userA) => userA.name);
    let actualUser = getUserName();
    let listTaskInstances = [];
    listTaskInstances[0] = taskToReassign.detailId;
    let listcurrentState = [];
    listcurrentState[0] = taskToReassign.currentState;
    let taskInstancesState = { listTaskInstances, listcurrentState };

    let reassignTasks = await reassignTaskWithState(taskInstancesState, usersAndGroups, actualUser.toUpperCase());

    if (reassignTasks && reassignTasks.mensaje) {
      let message = <FormattedMessage id="errorReassignTasks" defaultMessage={`Error al intentar reasignar tareas.`} />;
      if (reassignTasks.detalle && reassignTasks.detalle.includes('topsystems.bpm.api.BPMException') && reassignTasks.detalle.includes('at')) {
        message = reassignTasks.detalle.replace('topsystems.bpm.api.BPMException: ', '');
        message = message.substring(0, message.indexOf('at')).trim();
      }
      this.setState({ showDialog: true, dialogMessage: message, dialogTitle: <FormattedMessage id="error" defaultMessage={`Error`} /> });
    } else {
      handleCancel(true);
    }
  }

  applyFilter(e) {
    let { usersToAssign } = this.state;
    let value = e.target.value;
    if (value && value.startsWith('*')) {
      let valueAux = value.substring(1, value.length);
      usersToAssign = usersToAssign.filter((user) => user.value.toUpperCase().indexOf(valueAux.toUpperCase()) !== -1 || valueAux.length === 1);
    } else {
      usersToAssign = usersToAssign.filter((user) => user.value.toUpperCase().startsWith(value.toUpperCase()));
    }
    this.setState({ usersFiltered: usersToAssign, toAssignSelected: [], filter: value });
  }

  selectAssign(idAssign, toAssign = false) {
    const { toAssignSelected, assignedSelected } = this.state;
    let assignSelected = toAssign ? toAssignSelected : assignedSelected;
    if (assignSelected.includes(idAssign)) assignSelected.splice(assignSelected.indexOf(idAssign), 1);
    else assignSelected.push(idAssign);
    this.setState({
      toAssignSelected: toAssign ? assignSelected : toAssignSelected,
      assignedSelected: !toAssign ? assignSelected : assignedSelected,
    });
  }

  removeAssigned(all = false) {
    let { usersToAssign, usersAssigned, assignedSelected } = this.state;
    if (all || (assignedSelected && assignedSelected.length > 0)) {
      let addRoles = [];
      if (all) {
        addRoles = Object.assign([], usersAssigned);
        usersAssigned = [];
      } else {
        assignedSelected.forEach((as) => {
          let newRol = usersAssigned[as];
          usersAssigned[as] = null;
          addRoles.push(newRol);
        });
        usersAssigned = usersAssigned.filter((v) => v !== null);
      }

      addRoles.forEach((r) => {
        usersToAssign.push(r);
      });

      usersToAssign = sortUsersAssign(usersToAssign);

      this.setState({ usersToAssign, usersAssigned, assignedSelected: [], toAssignSelected: [], usersFiltered: usersToAssign, filter: '' });
    }
  }

  addAssigned() {
    let { usersToAssign, usersAssigned, toAssignSelected, usersFiltered } = this.state;
    if (toAssignSelected && toAssignSelected.length > 0) {
      let cloneToAssign = Object.assign([], usersToAssign);
      toAssignSelected.forEach((as) => {
        let newRol = usersFiltered[as];
        let indexNull = usersToAssign.findIndex((r) => newRol.value === r.value);
        cloneToAssign[indexNull] = null;
        usersAssigned.push(newRol);
      });
      usersToAssign = cloneToAssign.filter((v) => v !== null);

      this.setState({ usersToAssign, usersAssigned, toAssignSelected: [], assignedSelected: [], usersFiltered: usersToAssign, filter: '' });
    }
  }

  render() {
    const { showWindow, handleCancel } = this.props;
    const { usersAssigned, toAssignSelected, assignedSelected, usersFiltered, filter, showDialog, dialogMessage, dialogTitle } = this.state;

    return (
      <div className={`${showWindow ? '' : 'hidden'}`}>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className="pin border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute"
            style={{ zIndex: ABOVE_ALL, width: 600, top: 100, left: 50, height: 600 }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l text-xs handleDragg text-smoke cursor-move m-1" />
            <div className="flex h-full">
              <div className="w-full h-full">
                <div className="flex flex-col text-left px-4 py-1 text-sm h-full">
                  <div className="mb-2 text-center text-base text-primary">
                    <FormattedMessage id="reassignmentTasks" defaultMessage={`Reasignacion de Tareas`} />
                  </div>
                  <div className="w-full flex items-center h-full" style={{ height: 500 }}>
                    {!usersFiltered ? (
                      <div className="text-center" style={{ width: '100%' }}>
                        {this.state.pulseLoader}
                      </div>
                    ) : (
                      <ListUsersAssign
                        usersAssign={usersFiltered}
                        usersAssignSelected={toAssignSelected}
                        selectUserAssign={this.selectAssign}
                        toAssign={true}
                      />
                    )}
                    {usersFiltered ? (
                      <div className="flex flex-col m-2">
                        <Button
                          className="my-2"
                          title="<"
                          click={() => {
                            this.removeAssigned(false);
                          }}
                        />
                        <Button
                          className="my-2"
                          title=">"
                          click={() => {
                            this.addAssigned();
                          }}
                        />
                        <Button
                          className="my-2"
                          title="<<"
                          click={() => {
                            this.removeAssigned(true);
                          }}
                        />
                      </div>
                    ) : null}
                    {usersAssigned ? (
                      <ListUsersAssign
                        usersAssign={usersAssigned}
                        usersAssignSelected={assignedSelected}
                        selectUserAssign={this.selectAssign}
                        toAssign={false}
                      />
                    ) : null}
                  </div>
                  <div className="flex w-full my-2">
                    <div className="flex w-full justify-start content-center p-1">
                      <label className="mr-2">
                        <FormattedMessage id="filter" defaultMessage={`Filtro`} />
                      </label>
                      <input
                        type="text"
                        value={filter}
                        className="appearance-none px-1 rounded shadow text-base w-full"
                        onChange={this.applyFilter}
                      />
                    </div>
                    <div className="flex w-full justify-end">
                      <Button className="ml-2" title={<FormattedMessage id="reassign" defaultMessage={`Reasignar`} />} click={this.handleAssign} />
                      <Button className="ml-2" title={<FormattedMessage id="cancel" defaultMessage={`Cancelar`} />} click={handleCancel} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
        {showDialog && <Dialog hideDialog={this.hideDialog} message={dialogMessage} dialogTitle={dialogTitle} />}
      </div>
    );
  }
}

export default WorkflowReassignWindow;
