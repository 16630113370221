import React, { Component } from 'react';
import IcoMoon from 'react-icomoon';
import Draggable from 'react-draggable';
import { connect } from 'unistore/react';
import { storeActions } from '../../store';
import { FormattedMessage } from 'react-intl';

import Overlay from '../../../UI-kit/components/Overlay';
import { ABOVE_ALL } from '../../../UI-kit/constants/zIndexValues';

import FocusTrap from 'react-focus-trap';
import { fileArrayToBase64 } from '../../../UI-kit/helpers/fileHelper';
import { persistFingerData, preChangeSecondLogin } from '../../api/session';

import { startCaptureABM, getScannedSet, getLastBMP, reset } from '../../api/fingerprintWS';
import ConfirmPasswordView from './ConfirmPasswordView';
import ModalMessage from '../ModalMessage';

const Button = ({ title, click, className, disabled = false }) => {
  return (
    <button
      className={`${className} ${disabled && 'opacity-75'} bg-grey hover:bg-primary focus:bg-primary text-white rounded shadow m-2 py-2 px-4 text-sm`}
      onClick={click}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export class AddFingerPrint extends Component {
  constructor(props) {
    super(props);
    const { action } = this.props;
    this.state = {
      showWindow: true,
      showConfirm: false,
      lastScannedFingerprint: null,
      lastScannedANSI: null,
      lastScannedBitmap: null,
      ansiArray: [],
      valueToSend: '0',
      timeout: null,
      scanning: false,
      scannedAll: false,
      action: action,
      validationData: null,
      amountOfScannedFingerprints: 0,
      inputPassword: '',
      inputConfirm: '',
      showModal: false,
      showModal2: false,
      msg: '',
      newFinger: false,
    };
    this.handleCancelFinger = this.handleCancelFinger.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getScannedImagesLoop = this.getScannedImagesLoop.bind(this);
    this.onConfirmChange = this.onConfirmChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.handleSaveLogin = this.handleSaveLogin.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);
    this.loginWithFinger = this.loginWithFinger.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
    reset();
  }

  onConfirmChange(event) {
    event.persist();
    this.setState({ inputConfirm: event.target.value });
  }

  onPasswordChange(event) {
    event.persist();
    this.setState({ inputPassword: event.target.value });
  }

  async handleSaveLogin() {
    if (this.state.inputPassword === '' || this.state.inputConfirm === '') {
      return;
    }
    if (this.state.inputPassword !== this.state.inputConfirm) {
      this.setState({
        showModal: true,
        msg: 'La confirmación de la contraseña no coincide',
        inputPassword: '',
        inputConfirm: '',
      });
      return;
    }

    let { user } = this.props;
    let code = await preChangeSecondLogin(user, this.state.inputPassword);
    if (code > 0) {
      this.handleSend();
      this.setState({
        newFinger: true,
        showModal2: true,
        inputPassword: '',
        inputConfirm: '',
        msg: 'La información de la huella se guardó correctamente. \n Desea tomar una muestra de otro dedo?',
      });
    } else {
      this.setState({
        newFinger: false,
        showModal2: true,
        inputPassword: '',
        inputConfirm: '',
        msg: 'La clave/contraseña es incorrecta. \n Reintentar?',
      });
    }
  }

  handleCancelLogin() {
    this.setState({
      showConfirm: false,
      inputPassword: '',
      inputConfirm: '',
    });
  }

  async getScannedImagesLoop() {
    getLastBMP()
      .then((data) => {
        let amountToSet = this.state.amountOfScannedFingerprints;
        if (data) {
          if (data.length === 120) {
            this.resetReadData();
            // eslint-disable-next-line no-undef
            alert('Occurió un error al leer la huella, por favor asegúrese que el lector y el dedo estén limpios y escanee los 4 dedos de nuevo.');
            return;
          } else {
            amountToSet++;
            this.setState({ amountOfScannedFingerprints: amountToSet });
            let receivedBMP = data;
            let base64Image = fileArrayToBase64(receivedBMP);
            this.setState({ lastScannedFingerprint: base64Image });
          }
        }

        if (!this._ismounted) {
        } else if (amountToSet === 4) {
          this.handleReceive();
          this.setState({ timeout: clearTimeout(this.state.timeout) });
        } else {
          this.setState({
            timeout: setTimeout(() => this.getScannedImagesLoop(), 1000),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.resetReadData();
        // eslint-disable-next-line no-undef
        alert('Occurió un error al leer la huella, por favor asegúrese que el lector y el dedo estén limpios y escanee los 4 dedos de nuevo.');
      });
  }

  async loginWithFinger() {
    if (this.state.newFinger) {
      const { tipoLoged, user, setAddFingerLogin, loginDialog } = this.props;
      setAddFingerLogin(false);
      this._ismounted = false;
      reset();
      this.setState((prevState) => ({
        showWindow: !prevState.showWindow,
        newFinger: false,
      }));
      await loginDialog(user, '', tipoLoged === 1 || tipoLoged === 2);
    }
  }

  handleSend() {
    let { user } = this.props;
    let body = this.buildBody();
    persistFingerData(body, user);
  }

  buildBody() {
    let numeroPersonaFisica = 0;
    let fingerkey = this.state.valueToSend;
    let fingerdata = this.state.ansiArray;
    let fingerDataAnsi = this.state.lastScannedANSI;
    let fingerDataBMP = this.state.lastScannedBitmap;

    let body = {
      numeroPersonaFisica,
      fingerkey,
      fingerdata,
      fingerDataAnsi,
      fingerDataBMP,
    };
    return body;
  }

  async handleResume() {
    await reset();
    await startCaptureABM();
    this.getScannedImagesLoop();
    this.setState({ scanning: true });
  }

  async handleReceive() {
    const ret = await getScannedSet();

    if (ret.bmpImage && ret.bmpImage.length > 120) {
      this.setState({
        ansiArray: ret.fingerData,
        lastScannedANSI: ret.ansi,
        lastScannedBitmap: ret.bmpImage,
        scanning: false,
        scannedAll: true,
      });
    } else {
      this.resetReadData();
      // eslint-disable-next-line no-undef
      alert('Occurió un error al leer la huella, por favor asegúrese que el lector y el dedo estén limpios y escanee los 4 dedos de nuevo.');
    }
  }

  resetReadData() {
    this.setState({
      ansiArray: [],
      lastScannedANSI: null,
      lastScannedFingerprint: null,
      lastScannedBitmap: null,
      amountOfScannedFingerprints: 0,
      scanning: false,
    });
  }

  async handleSave() {
    if (this.state.amountOfScannedFingerprints === 4) {
      this.setState({ showConfirm: true });
    } else {
      this.setState({
        showModal: true,
        msg: 'Se necesitan ' + (4 - this.state.amountOfScannedFingerprints) + ' muestras mas.',
      });
    }
  }

  handleCancelFinger() {
    const { setAddFingerLogin } = this.props;
    setAddFingerLogin(false);
    this._ismounted = false;
    reset();
    this.setState((prevState) => ({ showWindow: !prevState.showWindow }));
  }

  async fetchData() {
    this.handleResume();
  }

  render() {
    let { showWindow, showConfirm } = this.state;

    let style = { width: 800 };

    let captureMessage = <span className="text-xs mx-10"></span>;
    if (this.state.scanning) {
      captureMessage = <span className="text-xs mx-10">Dedos escaneados : {this.state.amountOfScannedFingerprints}</span>;
    } else if (this.state.scannedAll) {
      captureMessage = <span className="text-xs mx-10">Los 4 dedos han sido escaneados</span>;
    }

    let fingerImage;
    if (this.state.lastScannedFingerprint) {
      fingerImage = <img style={{ maxWidth: '300px', maxHeight: '350px' }} src={`${this.state.lastScannedFingerprint}`} />;
    }

    return (
      <FocusTrap className={`${showWindow ? '' : 'hidden'}`}>
        <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className="border border-primary bg-grey-lighter-lighter rounded shadow absolute p-2 w-full"
            style={{
              ...style,
              zIndex: ABOVE_ALL,
              width: '350px',
              height: '400px',
              top: 230,
            }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move m-1 text-xs" />
            <div className="mb-2 font-light text-primary text-center mt-2">
              <FormattedMessage id="scanFingerPrintPerson" defaultMessage={`Identificación de Personas - Ingreso de Huella`} />
            </div>
            <div>
              <span className="text-xs">Seleccione dedo a Ingresar</span>
              <select
                onChange={(e) => {
                  this.setState({ valueToSend: e.target.value });
                }}
              >
                <option value="0">Pulgar derecho</option>
                <option value="1">Indice derecho</option>
                <option value="2">Mayor derecho</option>
                <option value="3">Anular derecho</option>
                <option value="4">Meñique derecho</option>
                <option value="5">Pulgar izquierdo</option>
                <option value="6">Indice izquierdo</option>
                <option value="7">Mayor izquierdo</option>
                <option value="8">Anular izquierdo</option>
                <option value="9">Meñique izquierdo</option>
              </select>
              {captureMessage}
            </div>

            <div
              className="flex justify-center mt-2 ml-16"
              style={{
                width: '200px',
                height: '250px',
              }}
            >
              {fingerImage}
            </div>

            <div className="flex justify-center mt-2">
              <Button title={<FormattedMessage id="save" defaultMessage={'Guardar'} />} click={() => this.handleSave()} />
              <Button title={<FormattedMessage id="cancel" defaultMessage={'Cancelar'} />} click={() => this.handleCancelFinger()} />
            </div>
            <div className={`${showConfirm ? '' : 'hidden'}`}>
              <ConfirmPasswordView
                onPasswordChange={this.onPasswordChange}
                onConfirmChange={this.onConfirmChange}
                inputPassword={this.state.inputPassword}
                inputConfirm={this.state.inputConfirm}
                handleSave={this.handleSaveLogin}
                handleCancel={this.handleCancelLogin}
              ></ConfirmPasswordView>
            </div>
            {this.state.showModal && (
              <ModalMessage
                message={<div className="flex w-full h-full justify-center items-center">{this.state.msg}</div>}
                handleAccept={() => {
                  this.setState({
                    showModal: false,
                    msg: '',
                  });
                }}
                acceptTitle={'Aceptar'}
              ></ModalMessage>
            )}
            {this.state.showModal2 && (
              <ModalMessage
                message={<div className="flex w-full h-full justify-center items-center">{this.state.msg}</div>}
                handleAccept={() => {
                  if (this.state.newFinger) {
                    this.setState({
                      showModal2: false,
                      msg: '',
                      newFinger: false,
                      showConfirm: false,
                    });
                    this.resetReadData();
                    this.handleResume();
                  } else {
                    this.setState({
                      showModal2: false,
                      msg: '',
                    });
                  }
                }}
                handleCancel={() => {
                  if (this.state.newFinger) {
                    this.loginWithFinger();
                  } else {
                    this.setState({
                      showModal2: false,
                      msg: '',
                      showConfirm: false,
                    });
                    this.handleCancelFinger();
                  }
                }}
                acceptTitle={'Aceptar'}
                cancelTitle={'Cancelar'}
              ></ModalMessage>
            )}
          </div>
        </Draggable>
      </FocusTrap>
    );
  }
}

export default connect(null, storeActions)(AddFingerPrint);
