import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
// import { css, StyleSheet } from 'aphrodite';
import TreeMenu, { ItemComponent } from 'react-simple-tree-menu';
import { getEsquemasContables, getEsquemasContablesProceso, getAllTransacciones, getEsquemaCampo } from '../../api/schema.js';
import { SchemaButtons } from './SchemaButtons';
import { EditSchemaWindow } from './EditSchemaWindow';
import { EditFieldWindow } from './EditFieldWindow';
import { EditLineWindow } from './EditLineWindow';
import { SchemaFilter } from './SchemaFilter';
import '../../styles/components/SchemaView.scss';
import EditConceptWindow from './EditConceptWindow.js';
import ModalMessage from '../../components/ModalMessage';

// import { node } from 'prop-types';

export class SchemaView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showWindow: '',
      key: '',
      node: {},
      selectedNode: false,
      clickNodo: false,
      bgColor: '',
      nivel: 0,
      disabledButton: true,
      disabledButtonCopy: true,
      disabledButtonPaste: true,
      disabledButtonDelete: true,
      habilitoPegar: true,
      ordinal: 0,
      escondo: false,
      eliminar: false,
      initialActiveKey: '0',
      nodesAEliminar: [],
      banderitaEliminar: false,
      keyUnaVezQueActualizo: '0',
      keyEsquemaPadre: '0',
      // keyLineaPadre: '0',
      li: '',
      disabledButtonNoseque: false,
      modalData: false,
    };

    this.treeRef = React.createRef();
  }

  componentDidMount() {
    this.loadData();
    this.setState({ showWindow: 'NuevoEsquema' });
  }

  setShowWindow = (window) => {
    if (window === 'NuevaLinea' || window === 'NuevoCampo' || window === 'NuevoEsquema' || window === 'NuevoConcepto') {
      this.setState({ disabledButton: true });
    }
    this.setState({ showWindow: window });
  };

  loadData = async () => {
    const [esquemasContablesRaw] = await Promise.all([getEsquemasContables(), getAllTransacciones()]);
    let esquemasContables = [];
    let contador = 1;
    let root = esquemasContablesRaw[0];
    let rootLabelToDisplay = `${root[1]} ${root[6]} - ${root[5]} `;
    let parsedRoot = {
      proceso: root[0],
      rubro: root[1],
      moneda: root[2],
      linea: root[3],
      ordinal: root[4],
      descripcion: root[5],
      campo: root[6],
      rubroSalida: root[7],
      cajaODiario: root[8],
      debitoGeneraCreditoODebito: root[9],
      creditoGeneraCreditoODebito: root[10],
      condicion: root[11],
      transaccion: root[12],
      usarCamposMovimientos: root[13],
      label: rootLabelToDisplay,
      key: `0`,
      nodes: [],
      tipo: 'root',
      valorRubroSalida: root[4] > 99,
      pp: false,
    };
    esquemasContablesRaw.forEach((esquema) => {
      let labelToDisplay = `${esquema[1]} ${esquema[6]} - ${esquema[5]} `;
      let esquemaToAdd = {
        proceso: esquema[0],
        rubro: esquema[1],
        moneda: esquema[2],
        linea: esquema[3],
        ordinal: esquema[4],
        descripcion: esquema[5],
        campo: esquema[6],
        rubroSalida: esquema[7],
        cajaODiario: esquema[8],
        debitoGeneraCreditoODebito: esquema[9],
        creditoGeneraCreditoODebito: esquema[10],
        condicion: esquema[11],
        transaccion: esquema[12],
        usarCamposMovimientos: esquema[13],
        label: labelToDisplay,
        key: `0-${contador}`,
        nodes: [],
        tipo: 'schema',
        valorRubroSalida: esquema[4] > 99,
        pp: false,
      };

      contador = contador + 1;
      esquemasContables.push(esquemaToAdd);
    });
    esquemasContables.shift();
    parsedRoot.nodes = esquemasContables;
    let rootWithSchemas = [parsedRoot];
    this.setState({ data: rootWithSchemas });
    this.onSelect(this.state.data[0].nodes);
  };
  onSelect = async (nodes) => {
    this.setState({});

    // Crear una lista de promesas para todas las llamadas asíncronas
    const promises = nodes.map(async (node) => {
      if (node.tipo === 'schema') {
        let children = await getEsquemasContablesProceso(node.rubro, node.proceso, node.moneda);
        let parsedChildren = [];
        let contador = 1;
        if (children.length !== 0) {
          for (const c of children) {
            let labelToDisplay = `${c[7]} ${c[6]} - ${c[5]} `;
            let childToAdd = {
              key: `${node.key}-${contador}`,
              label: labelToDisplay,
              proceso: c[0],
              rubro: c[1],
              moneda: c[2],
              linea: c[3],
              ordinal: c[4],
              descripcion: c[5],
              campo: c[6],
              rubroSalida: c[7],
              cajaODiario: c[8],
              debitoGeneraCreditoODebito: c[9],
              creditoGeneraCreditoODebito: c[10],
              condicion: c[11],
              transaccion: c[12],
              usarCamposMovimientos: c[13],
              tipo: 'linea',
              valorRubroSalida: c[4] > 99,
              ordinalMayor: c[4] > 99 ? 'MA' : 'ME',
            };
            contador = contador + 1;
            parsedChildren.push(childToAdd);
          }
        }
        node.nodes = parsedChildren;
        await this.onSelect(parsedChildren);
      } else if (node.tipo === 'linea') {
        let children = await getEsquemaCampo(node.rubro, node.moneda, node.linea);
        let parsedChildren = [];
        let contador = 1;
        children.forEach((c) => {
          let labelToDisplay = `${c[5]} ${c[6]} - ${c[8]} `;
          let childToAdd = {
            key: `${node.key}-${contador}`,
            label: labelToDisplay,
            proceso: c[0],
            rubro: c[1],
            moneda: c[2],
            linea: c[3],
            ordinal: c[4],
            cp: c[5],
            numero: c[6],
            formula: c[7],
            tipo: 'campo',
            valorRubroSalida: c[4] > 99,
            ordinalMayor: c[4] > 99 ? 'MA' : 'ME',
          };
          contador = contador + 1;
          parsedChildren.push(childToAdd);
        });
        node.nodes = parsedChildren;
      }
    });

    // Esperar a que todas las promesas se resuelvan
    await Promise.all(promises);
  };

  getSelected = (node) => {
    this.setState({ escondo: false });
    this.setState({ selectedNode: true });
    if (node.level === 0) {
      this.setState({ showWindow: 'NuevoEsquema' });
      this.setState({ disabledButton: true });
      this.setState({ disabledButtonCopy: true });
      this.setState({ disabledButtonPaste: true });
      this.setState({ disabledButtonDelete: true });
    } else if (node.level === 1) {
      this.setState({ showWindow: 'EditarS' });
      this.setState({ node });
      this.setState({ disabledButton: false });
      this.setState({ disabledButtonCopy: true });
      this.setState({ disabledButtonDelete: false });
      this.setState({ disabledButtonPaste: true });
    } else if (node.level === 2) {
      this.setState({ showWindow: 'EditarL' });
      this.setState({ disabledButton: false });
      this.setState({ disabledButtonCopy: false });
      this.setState({ disabledButtonDelete: false });
      this.setState({ disabledButtonPaste: true });
      this.setState({ node });
    } else if (node.level === 3) {
      if (node.cp === 'C') {
        this.setState({ showWindow: 'EditarF' });
        this.setState({ disabledButton: true });
        this.setState({ disabledButtonCopy: false });
        this.setState({ disabledButtonDelete: false });
        this.setState({ disabledButtonPaste: true });
        this.setState({ node });
      } else {
        this.setState({ showWindow: 'EditarConcepto' });
        this.setState({ disabledButton: true });
        this.setState({ disabledButtonCopy: false });
        this.setState({ disabledButtonDelete: false });
        this.setState({ disabledButtonPaste: true });
        this.setState({ node });
      }
    }
    const li = document.querySelector('.rstm-tree-item--active');
    this.setState({ li: li });
  };

  openedFolderIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
      />
    </svg>
  );

  closedFolderIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
      />
    </svg>
  );

  renderOpenedIcon = () => {
    return (
      <div className="iconTree" style={{ paddingRight: '10px' }}>
        {this.openedFolderIcon}
      </div>
    );
  };

  renderColsedIcon = () => {
    return (
      <div className="iconTree" style={{ paddingRight: '10px' }}>
        {this.closedFolderIcon}
      </div>
    );
  };

  updateNodo = (key, tipo) => {
    if (tipo === 'linea') {
      let padreDeKey = key.split('/');
      let nodoPadre = padreDeKey[0] + '/' + padreDeKey[1];
      let nodoPadreOficial = ['0', nodoPadre];
      this.setState({ keyUnaVezQueActualizo: key });
      this.setState({ keyEsquemaPadre: nodoPadreOficial });
    } else if (tipo === 'campo') {
      let padreDeKey = key.split('/');
      let nodoPadre = padreDeKey[0] + '/' + padreDeKey[1];
      let nodoPadreDos = nodoPadre + '/' + padreDeKey[2];
      let nodoPadreOficial = ['0', nodoPadre, nodoPadreDos];
      this.setState({ keyUnaVezQueActualizo: key });
      this.setState({ keyEsquemaPadre: nodoPadreOficial });
    }
    this.loadData();
  };

  obtengoOrdinal = (ordinal) => {
    this.setState({ ordinal: ordinal });
  };

  esconder = () => {
    this.setState({ escondo: true });
  };

  eliminar = (data) => {
    if (data) {
      this.state.nodesAEliminar.push(this.state.node);
      const li = document.querySelector('.rstm-tree-item--active');
      li.className = 'rstm-tree-item rstm-tree-item-level' + this.state.node.level;
      if (this.state.node.level === 1) {
        if (!this.state.node.hasNodes) {
          li.style = 'background-color: lightcoral; padding-left: 4.5rem;';
        } else {
          li.style = 'background-color: lightcoral; padding-left: 2.5rem;';
        }
      } else if (this.state.node.level === 2) {
        li.style = 'background-color: lightcoral; padding-left: 4.25rem;';
      } else if (this.state.node.level === 3) {
        li.style = 'background-color: lightcoral; padding-left: 8rem;';
      }
    }
    this.setState({ banderitaEliminar: true });
  };

  recuperar = (data, node) => {
    let contador = 0;
    const li = document.querySelectorAll('li');
    if (data) {
      if (this.state.nodesAEliminar.length !== 0) {
        for (let i = 0; i < li.length; i++) {
          for (let n = 0; n < this.state.nodesAEliminar.length; n++) {
            if (this.state.nodesAEliminar[n].key === node.key) {
              contador++;
              if (li[i].style.backgroundColor === 'lightcoral' && li[i].ariaPressed === 'true') {
                li[i].style.backgroundColor = 'rgba(192, 193, 196, .26)';
                this.state.nodesAEliminar.splice(n, 1);
                break;
              }
            }
          }
        }
      }
      if (contador !== 0) {
        if (this.state.nodesAEliminar.length === 0) {
          this.setState({ banderitaEliminar: false });
        }
      } else {
        this.setState({ modalData: true });
      }
    } else {
      for (let i = 0; i < li.length; i++) {
        if (li[i].style.backgroundColor === 'lightcoral') {
          li[i].style = 'background-color: rgba(192, 193, 196, .26)';
          this.loadData();
        }
      }
      this.setState({ banderitaEliminar: false });
    }
    if (this.state.nodesAEliminar.length === 0) {
      this.setState({ banderitaEliminar: false });
    }
  };

  bloqueoBotonSiNodeEsIgualANodeEliminado = () => {
    if (this.state.nodesAEliminar.length !== 0) {
      let eliminados = this.state.nodesAEliminar;
      for (let i = 0; i < eliminados.length; i++) {
        if (eliminados[i].key === this.state.node.key) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  render() {
    return (
      <div className="col relative w-full p-2 px-6 text-sm">
        <div className="izq">
          <SchemaButtons
            node={this.state.node}
            setShowWindow={this.setShowWindow}
            loadData={this.loadData}
            selectedNode={this.state.selectedNode}
            nodeDisabled={this.state.disabledButton}
            disabledButtonCopy={this.state.disabledButtonCopy}
            disabledButtonDelete={this.state.disabledButtonDelete}
            disabledButtonPaste={this.state.disabledButtonPaste}
            habilitoPegar={this.state.habilitoPegar}
            data={this.state.data}
            esconder={this.esconder}
            eliminar={this.eliminar}
            recuperar={this.recuperar}
            nodesAEliminar={this.state.nodesAEliminar}
            banderitaEliminar={this.state.banderitaEliminar}
          />
          <div className="schema-menu-container">
            <TreeMenu
              hasSearch={true}
              data={this.state.data}
              onClickItem={this.getSelected}
              disableKeyboard={false}
              resetOpenNodesOnDataUpdate
              initialOpenNodes={this.state.keyEsquemaPadre === 0 ? ['0'] : this.state.keyEsquemaPadre}
              initialActiveKey={this.state.keyUnaVezQueActualizo}
              initialFocusKey={this.state.keyUnaVezQueActualizo}
            >
              {({ search, items }) => (
                <>
                  <input
                    onChange={(e) => search(e.target.value)}
                    style={{ width: '100%' }}
                    placeholder={this.props.intl.formatMessage({
                      id: 'filtrar',
                      defaultMessage: 'Filtrar',
                    })}
                  />
                  <ul className="tree-item-group">
                    {items.map(({ key, ...props }) =>
                      items.level === 0 ? (
                        <div key={key} id="divItemLevel0">
                          <ItemComponent key={key} {...props} openedIcon={this.renderOpenedIcon()} closedIcon={this.renderColsedIcon()} />
                        </div>
                      ) : (
                        <div key={key} id="divItems">
                          <ItemComponent key={key} {...props} openedIcon={this.renderOpenedIcon()} closedIcon={this.renderColsedIcon()} />
                        </div>
                      ),
                    )}
                  </ul>
                </>
              )}
            </TreeMenu>
          </div>
        </div>
        <div
          className="der"
          style={{
            width: '65%',
            visibility: this.state.escondo === true ? 'hidden' : 'visible',
          }}
        >
          {this.state.showWindow === 'EditarS' && (
            <EditSchemaWindow
              loadData={this.loadData}
              showWindow={this.state.showWindow}
              updateNodo={this.updateNodo}
              postData={false}
              node={this.state.node}
              llevoData={true}
              nodesAEliminar={this.state.nodesAEliminar}
            />
          )}
          {this.state.showWindow === 'NuevoEsquema' && (
            <EditSchemaWindow
              showWindow={this.state.showWindow}
              loadData={this.loadData}
              node={{
                moneda: 0,
                cajaODiario: null,
                debitoGeneraCreditoODebito: 'D',
                creditoGeneraCreditoODebito: 'C',
              }}
              postData={true}
            />
          )}
          {this.state.showWindow === 'EditarF' && (
            <EditFieldWindow
              obtengoOrdinal={this.obtengoOrdinal}
              showWindow={this.state.showWindow}
              loadData={this.loadData}
              updateNodo={this.updateNodo}
              node={this.state.node}
              nodesAEliminar={this.state.nodesAEliminar}
              postData={false}
            />
          )}
          {this.state.showWindow === 'NuevoCampo' && (
            <EditFieldWindow
              loadData={this.loadData}
              showWindow={this.state.showWindow}
              node={{
                rubro: this.state.node.rubro,
                moneda: this.state.node.moneda,
                proceso: this.state.node.proceso,
                key: this.state.node.key,
                linea: this.state.node.linea,
                numero: this.state.node.numero,
              }}
              updateNodo={this.updateNodo}
              postData={true}
              data={this.state.data}
            />
          )}
          {this.state.showWindow === 'EditarL' && (
            <EditLineWindow
              ordinal={this.state.ordinal}
              loadData={this.loadData}
              showWindow={this.state.showWindow}
              updateNodo={this.updateNodo}
              node={this.state.node}
              data={this.state.data}
              postData={false}
              nodesAEliminar={this.state.nodesAEliminar}
            />
          )}
          {this.state.showWindow === 'NuevaLinea' && (
            <EditLineWindow
              loadData={this.loadData}
              showWindow={this.state.showWindow}
              node={{
                rubro: this.state.node.rubro,
                moneda: this.state.node.moneda,
                cajaODiario: null,
                debitoGeneraCreditoODebito: 'D',
                creditoGeneraCreditoODebito: 'C',
                key: this.state.node.key,
              }}
              updateNodo={this.updateNodo}
              data={this.state.data}
              postData={true}
            />
          )}
          {this.state.showWindow === 'NuevoConcepto' && (
            <EditConceptWindow
              loadData={this.loadData}
              showWindow={this.state.showWindow}
              node={this.state.node}
              updateNodo={this.updateNodo}
              postData={true}
              data={this.state.data}
            />
          )}
          {this.state.showWindow === 'EditarConcepto' && (
            <EditConceptWindow
              obtengoOrdinal={this.obtengoOrdinal}
              showWindow={this.state.showWindow}
              loadData={this.loadData}
              updateNodo={this.updateNodo}
              node={this.state.node}
              nodesAEliminar={this.state.nodesAEliminar}
              postData={false}
            />
          )}
          {this.state.showWindow === 'SchFilter' && <SchemaFilter />}
        </div>
        {this.state.modalData && (
          <ModalMessage
            message={this.props.intl.formatMessage({
              id: 'nodeDoesNotMatchDeleted',
              defaultMessage: 'El nodo seleccionado no coincide con los nodos eliminados',
            })}
            handleAccept={() => {
              this.setState({ modalData: false });
            }}
            acceptTitle={this.props.intl.formatMessage({
              id: 'accept',
              defaultMessage: 'Aceptar',
            })}
            draggable={true}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(SchemaView);
