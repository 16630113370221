import createStore from 'unistore';
import devtools from 'unistore/devtools';
import * as actions from './actions';
import { v4 as uuidv4 } from 'uuid';

export const DEFAULT_OPERATION_NUMBER = -1;
export const DEFAULT_REPORT_NUMBER = -1;

export const initialState = () => {
  const state = {
    currentApp: 'OPERATIONS',
    tabs: [],
    current: 0,
    globalMessage: {
      visible: false,
      message: '',
      logoutAfter: false,
    },
    modalMessage: [],
    modalMessageCurrentIndex: 0,
    lastValues: [],
    tabsReport: [],
    currentTabReport: 0,
    footerInfo: {
      sessionInfo: {
        name: '',
        branch: '',
        machine: '',
        processDate: '',
        server: '',
      },
    },
    menuGridInfo: {
      showItemsInsteadOfCategories: false,
      selectedCategory: {},
      recentOperationsCollapsibleIsOpen: true,
      recentReportsCollapsibleIsOpen: true,
    },
    clearing: {
      searchResult: [],
      searched: false,
      loading: false,
    },
    clientProperties: JSON.parse(window.localStorage.getItem('clientProperties')) || null,
  };

  state.tabs.push({
    id: uuidv4(),
    operationName: 'Nueva Pestaña',
    operationNumber: DEFAULT_OPERATION_NUMBER,
    emptyTab: true,
  });

  state.tabsReport.push({
    operationName: 'Nueva Pestaña',
    operationNumber: DEFAULT_REPORT_NUMBER,
    emptyTab: true,
  });

  return state;
};

const isTesting = process.env.NODE_ENV === 'testing';

export let store = isTesting ? createStore(initialState()) : devtools(createStore(initialState()));

export let storeActions = (store) => actions;

export let dispatchActions = storeActions(store);
