import React, { Component } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import { connect } from 'unistore/react';
import { FormattedMessage } from 'react-intl';
import ReportDisplay from './ReportDisplay';
import { store, storeActions } from '../../store';
import ReportPreview from './ReportPreview';
import EmptyTab from '../EmptyTab';
import { getReportGenerationSpec, deletePDF } from '../../api/report';
import { setRecentReportsCollapsibleIsOpen } from '../../utils/browserStorage/services';
import NewTabButton from '../../../UI-kit/components/NewTabButton';
import Tab from '../Tab';

export class Reports extends Component {
  constructor(props) {
    super(props);

    this.handleTabReportChange = this.handleTabReportChange.bind(this);
    this.selectReport = this.selectReport.bind(this);
    this.handleAddNewTabReport = this.handleAddNewTabReport.bind(this);
    this.toggleRecentReportsCollapse = this.toggleRecentReportsCollapse.bind(this);

    this.state = {
      deletedFiles: [],
    };
  }

  componentDidMount() {
    this.props.setCurrentApp('REPORTS');
    window.addEventListener(
      'beforeunload',
      async () => {
        const { tabsReport } = this.props;
        const { deletedFiles } = this.state;
        tabsReport.forEach(async (t) => {
          if (t.reportData) {
            if (t.reportData.fileExtension) {
              const fileDir = `${t.reportData.name}.${t.reportData.fileExtension}`;
              if (fileDir && !deletedFiles.find((d) => d === fileDir)) {
                this.setState({ deletedFiles: [...deletedFiles, fileDir] });
                await deletePDF(fileDir);
              }
            }
            if (t.reportData.fileExtensionExcel) {
              const fileDirExcel = `${t.reportData.nameExcel}.${t.reportData.fileExtensionExcel}`;
              if (fileDirExcel && !deletedFiles.find((d) => d === fileDirExcel)) {
                this.setState({ deletedFiles: [...deletedFiles, fileDirExcel] });
                await deletePDF(fileDirExcel);
              }
            }
          }
        });
      },
      { once: false },
    );
  }

  componentDidUpdate() {
    const state = store.getState();
    if (state.redirectOnNextRender) {
      const urlWithoutSlashes = state.redirectOnNextRender.replace(/\//g, '');
      if (urlWithoutSlashes.indexOf('homereports') > -1) {
        store.setState({ redirectOnNextRender: null });
      }
    }
  }

  handleTabReportChange(index) {
    const { setCurrentTabReport } = this.props;
    setCurrentTabReport(parseInt(index, 10));
  }

  selectReport(report) {
    const { addTabReport } = this.props;
    addTabReport(report);
  }

  handleAddNewTabReport(id, name) {
    getReportGenerationSpec(id, true).then((reportToShow) => this.selectReport(reportToShow));
  }

  toggleRecentReportsCollapse() {
    this.props.toggleRecentReportsCollapse(); // toggle in store
    setRecentReportsCollapsibleIsOpen(!this.props.recentReportsCollapsibleIsOpen); // toggle in browserStorage
  }

  constructTabs() {
    const { tabsReport, addEmptyTabReport, removeTabReport, recentReportsCollapsibleIsOpen } = this.props;
    const newTabMessage = <FormattedMessage id="newReport" defaultMessage={`Nuevo reporte`} />;
    if (tabsReport.length === 0) {
      addEmptyTabReport();
      return null;
    }

    const handleRemoveTab = (tabIndex, tabReport) => {
      removeTabReport(tabIndex);
      if (tabReport.reportData) {
        const { name, nameExcel, fileExtension, fileExtensionExcel } = tabReport.reportData;
        if (fileExtension) {
          const fileDir = `${name}.${fileExtension}`;
          fileDir && deletePDF(fileDir);
        }
        if (fileExtensionExcel) {
          const fileDirExcel = `${nameExcel}.${fileExtensionExcel}`;
          fileDirExcel && deletePDF(fileDirExcel);
        }
      }
    };
    return tabsReport.map((t, index) => {
      return (
        <TabPane
          tab={
            <Tab
              tabName={t.reportName}
              tabNumber={t.reportNumber}
              isEmptyTab={t.emptyTab}
              tabIndex={index}
              newTabMessage={newTabMessage}
              removable={!t.emptyTab || index !== 0}
              handleRemoveTab={() => handleRemoveTab(index, t)}
            />
          }
          key={index}
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100vh - 62px)',
          }}
        >
          <div className={`w-full mx-auto h-full ${t.onlyPreview ? '' : 'max-w-xl'}`}>
            {!t.emptyTab ? (
              t.onlyPreview ? (
                <ReportPreview report={t.reportData} />
              ) : (
                <ReportDisplay report={t.reportData} handleRemoveTab={() => handleRemoveTab(index, t)} tabIndex={index} />
              )
            ) : (
              <EmptyTab
                itemsToShow={'REPORTS'}
                handleNewTabReport={this.selectReport}
                handleAddNewTabReport={this.handleAddNewTabReport}
                recentItemsCollapsibleIsOpen={recentReportsCollapsibleIsOpen}
                handleRecentItemsCollapsibleClick={this.toggleRecentReportsCollapse}
              />
            )}
          </div>
        </TabPane>
      );
    });
  }

  render() {
    const { currentTabReport, addEmptyTabReport } = this.props;

    return (
      <div className="w-full h-full">
        <Tabs
          renderTabBar={() => <ScrollableInkTabBar extraContent={<NewTabButton handleClick={addEmptyTabReport} />} />}
          renderTabContent={() => <TabContent animated={false} />}
          activeKey={currentTabReport.toString()}
          onChange={this.handleTabReportChange}
        >
          {this.constructTabs()}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tabsReport: state.tabsReport,
    currentTabReport: state.currentTabReport,
    recentReportsCollapsibleIsOpen: state.menuGridInfo.recentReportsCollapsibleIsOpen,
  };
};

export default connect(mapStateToProps, storeActions)(Reports);
