import React, { useEffect, useState } from 'react';
import { getGroupProcessInstance } from '../../../../api/processesManager';
import { GroupInstance } from '../../models/groupInstance';
import { GroupExecutionInfoView } from './GroupExecutionInfoView';
import { RUNNING_PROCESS, UNDEFINED_PROCESS } from '../../models/constants';
import { SelectedProcess } from '../../models/types';
import { GroupDefinition } from '../../models/groupDefinition';

interface GroupExecutionInfoViewModelProps {
  executionId: number;
  onModalChange: (isOpen: boolean) => void;
  onModalDataChange: (data: string, name: string) => void;
  eliminateExecutedProcessId?: (executionId: number) => void;
  onStateChange?: (id: number, state: string, date: string) => void;
  processesGroups: GroupDefinition[];
  onSelectProcess: (process: SelectedProcess) => void;
  showModal: () => void;
}

export function GroupExecutionInfoViewModel({
  executionId,
  onModalChange,
  onModalDataChange,
  eliminateExecutedProcessId,
  onStateChange,
  processesGroups,
  onSelectProcess,
  showModal,
}: GroupExecutionInfoViewModelProps): JSX.Element {
  const [groupExecuting, setGroupExecuting] = useState<GroupInstance>();
  const [invalidExecutionId, setInvalidExecutionId] = useState<boolean>(false);

  async function getGroupProcessInstanceDataAsync(): Promise<void> {
    const response: GroupInstance = await getGroupProcessInstance(executionId);

    if (response.response) {
      setInvalidExecutionId(true);
    } else {
      loadStates(response.processInstances, response.state);
      setGroupExecuting(response);
      onStateChange && onStateChange(executionId, response.state, response.initTime);
      if (response.state === RUNNING_PROCESS) {
        setTimeout(getGroupProcessInstanceDataAsync, 1000);
      }
    }
  }

  function loadStates(group: GroupInstance[], state: string): void {
    group?.map((process) => {
      if (process.state === UNDEFINED_PROCESS) {
        process.state = state;
      }
      loadStates(process.processInstances, process.state);
    });
  }

  useEffect(() => {
    getGroupProcessInstanceDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function executeProcess(procName: string): void {
    const proc = processesGroups.find((gr) => gr.name === procName);
    if (proc) {
      onSelectProcess(proc);
      showModal();
    }
  }

  if (invalidExecutionId) {
    return <div></div>;
  } else {
    return (
      <GroupExecutionInfoView
        groupData={groupExecuting}
        onModalChange={onModalChange}
        onModalDataChange={onModalDataChange}
        eliminateExecutedProcessId={eliminateExecutedProcessId}
        executeProcess={executeProcess}
      />
    );
  }
}
