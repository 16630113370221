import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';

export let noticeDecided = (operationID, noticeCode) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(
      `${API}TopazWebClientWS/resources/operations/noticeDecided?operationID=${operationID}&sessionId=${session}&noticeCode=${noticeCode}`,
    )
    .then(({ data }) => data);
};
