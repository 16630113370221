import React from 'react';

export interface SelectData {
  label: String;
  value: string | number;
}

interface SelectProps {
  options: SelectData[];
  selectedState: string | number;
  onSelectedChange: (state) => void;
  className?: string;
  disabled?: boolean;
}

export function Select({ options, onSelectedChange, selectedState, className, disabled }: SelectProps): JSX.Element {
  function onChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    onSelectedChange(event.target.value);
  }

  return (
    <select
      disabled={disabled ? disabled : false}
      id="stateSelect"
      value={selectedState}
      onChange={onChange}
      className={`${className} border border-grey-lighter`}
    >
      {options.map((option) => {
        return (
          <option className="text-xs" value={option.value} key={option.value}>
            {option.label}
          </option>
        );
      })}
    </select>
  );
}
