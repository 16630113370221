import { useState, useEffect, useCallback } from 'react';

const useDraggable = ({ defaultLeft, defaultTop }) => {
  const [left, setLeft] = useState(defaultLeft);
  const [top, setTop] = useState(defaultTop);
  const [isDragging, setIsDragging] = useState(false);

  const onMouseDownDraggable = (e) => {
    setIsDragging(true);
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);

      return () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
      };
    } else {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    }
  }, [top, isDragging, onMouseMove, left]);

  const onMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        setLeft(left + e.movementX);
        setTop(top + e.movementY);
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [top, isDragging, left],
  );

  const onMouseUp = (e) => {
    setIsDragging(false);
    e.stopPropagation();
    e.preventDefault();
  };

  return {
    onMouseDownDraggable,
    left: left + 'px',
    top: top + 'px',
  };
};

export default useDraggable;
