/* eslint-disable no-multi-str */
/* eslint-disable no-tabs */
export let xml = () => {
  let content = `<?xml version="1.0" encoding="UTF-8"?>
	<!-- edited with XML Spy v3.5 NT (http://www.xmlspy.com) by  () -->
	<ScanningConfiguration>
		<DeviceParameters> 
			<MICR>
				<Enabled>TRUE</Enabled>
				<Font>CMC7</Font>
				<RejectSymbol>?</RejectSymbol>
			</MICR>
			<Imaging>
				<Checks>
					<FrontImage1>
						<Format>JPEG</Format>
						<Resolution>200</Resolution>
						<Threshold>75</Threshold>
						<Paging>Single</Paging>
					</FrontImage1>
					<RearImage1>
						<Format>JPEG</Format>
						<Resolution>200</Resolution>
						<Threshold>75</Threshold>
						<Paging>Single</Paging>
					</RearImage1>
					<Snippets>
						<Snippet1>
							<Enabled>TRUE</Enabled>
							<Front>TRUE</Front>
							<XPos>3</XPos>
							<YPos>58</YPos>
							<Width>65</Width>
							<Height>12</Height>
							<Orientation>0</Orientation>
							<Millimiters>TRUE</Millimiters>
							<Color>BW</Color>
							<OCR>
								<Font>20</Font>
							</OCR>
						</Snippet1>
						<Snippet2>
							<Enabled>TRUE</Enabled>
							<Front>TRUE</Front>
							<XPos>3</XPos>
							<YPos>45</YPos>
							<Width>86</Width>
							<Height>12</Height>
							<Orientation>0</Orientation>
							<Millimiters>TRUE</Millimiters>
							<Color>BW</Color>
							<OCR>
								<Font>20</Font>
							</OCR>
						</Snippet2>
						<Snippet3>
							<Enabled>TRUE</Enabled>
							<Front>TRUE</Front>
							<XPos>104</XPos>
							<YPos>14</YPos>
							<Width>65</Width>
							<Height>15</Height>
							<Orientation>0</Orientation>
							<Millimiters>TRUE</Millimiters>
							<Color>BW</Color>
							<OCR>
								<Font>20</Font>
							</OCR>
						</Snippet3>
					</Snippets>
				</Checks>
			</Imaging>
			<Pocketing>
				<Checks>
					<DefaultPocket>1</DefaultPocket>
				</Checks>
			</Pocketing>
		</DeviceParameters>
	</ScanningConfiguration>`;
  return content;
};

export default xml;
