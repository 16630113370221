import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { setGroupsScheduler } from '../../../../api/processesManager';
import { GroupDefinition } from '../../models/groupDefinition';
import { ProcessSchedule } from '../../models/processSchedule';
import { SelectedProcess } from '../../models/types';
import { SelectData } from '../select/Select';
import { ConditionModalView } from './ConditionModalView';

export interface ConditionGroup {
  parentGroup: string;
  childGroup: string;
  day: number;
  periodicity: string;
  nextExecution: string;
  labor: number;
  ordinal: number;
}

interface ConditionModalViewModelProps {
  isEditing?: boolean;
  isConditioningBoth?: boolean;
  selectedProcess?: SelectedProcess;
  onModalConditionChange: (isOpenModal: boolean, isConditioningBoth?: boolean) => void;
  schedulerList?: ProcessSchedule[];
  processCondition?: ProcessSchedule;
  onRefreshData: () => void;
}

const LABOR_INPUT = 'Labor';
const DAY_INPUT = 'day';
const DATE_INPUT = 'Date';
const ADD_CONDITION: number = 0;
const MODIFY_CONDITION: number = 1;
const NOT_CHECKED: number = 0;
export const CHECKED: number = 1;
const MAX_DAYS_MONTH: number = 31;

export function ConditionModalViewModel({
  isConditioningBoth,
  onModalConditionChange,
  selectedProcess,
  schedulerList,
  processCondition,
  isEditing,
  onRefreshData,
}: ConditionModalViewModelProps): JSX.Element {
  const [conditionInfo, setConditionInfo] = useState<ConditionGroup>(loadConditionGroup());
  const [childrenToSelect, setChildrenToSelect] = useState<SelectData[]>([]);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalWarningOpen, setIsModalWarningOpen] = useState<boolean>(false);
  const dateOptions: SelectData[] = loadDateOptions();
  const intl = useIntl();
  const periodicityOptions: SelectData[] = loadPeriodicityOptions();

  function loadConditionGroup(): ConditionGroup {
    return {
      parentGroup: processCondition ? processCondition.parentGroup : isConditioningBoth ? selectedProcess?.name || '' : '',
      childGroup: processCondition ? processCondition.childGroup : !isConditioningBoth ? selectedProcess?.name || '' : '',
      day: processCondition ? processCondition.day : 0,
      periodicity: processCondition ? processCondition.periodicity : 'd',
      nextExecution: processCondition ? moment(processCondition.nextExecution).format('YYYYMMDD') : '',
      labor: processCondition ? processCondition.labor : NOT_CHECKED,
      ordinal: processCondition ? processCondition.ordinal : -1,
    };
  }

  function loadDateOptions(): SelectData[] {
    const dateDays: SelectData[] = [];
    for (let index: number = 0; index <= MAX_DAYS_MONTH; index++) {
      dateDays.push({
        value: `${index}`,
        label: `${index}`,
      });
    }

    return dateDays;
  }

  function loadPeriodicityOptions(): SelectData[] {
    return [
      {
        label: intl.formatMessage({ id: 'daily' }),
        value: 'd',
      },
      {
        label: intl.formatMessage({ id: 'weekly' }),
        value: 's',
      },
      {
        label: intl.formatMessage({ id: 'monthly' }),
        value: 'm',
      },
      {
        label: intl.formatMessage({ id: 'biweekly' }),
        value: 'q',
      },
      {
        label: intl.formatMessage({ id: 'quarterly' }),
        value: 't',
      },
      {
        label: intl.formatMessage({ id: 'biannual' }),
        value: 'S',
      },
      {
        label: intl.formatMessage({ id: 'annual' }),
        value: 'a',
      },
      {
        label: intl.formatMessage({ id: 'endMonth' }),
        value: 'f',
      },
      {
        label: intl.formatMessage({ id: 'endYear' }),
        value: 'F',
      },
    ];
  }

  function loadChilds(): void {
    if (isConditioningBoth) {
      mapChildsToSelect();
    } else {
      mapNameToSelect();
    }
  }

  function mapNameToSelect(): void {
    const processNameSelect: SelectData[] = [
      {
        label: isEditing ? processCondition?.childGroup || '' : selectedProcess?.name || '',
        value: isEditing ? processCondition?.childGroup || '' : selectedProcess?.name || '',
      },
    ];

    setChildrenToSelect(processNameSelect);
  }

  function mapChildsToSelect(): void {
    let childsNames: string[] = [''];
    addGroupChilds(childsNames);
    addGroupProcesses(childsNames);
    addForks(childsNames);
    const mappedChilds: SelectData[] = childsNames.map((child) => {
      return {
        label: child,
        value: child,
      };
    });

    setChildrenToSelect(mappedChilds);
  }

  function addGroupChilds(childsNames: string[]): void {
    (selectedProcess as GroupDefinition) &&
      (selectedProcess as GroupDefinition).childs.group?.forEach((subGroup) => {
        childsNames.push(subGroup.name);
      });
  }

  function addForks(childsNames: string[]): void {
    (selectedProcess as GroupDefinition) &&
      (selectedProcess as GroupDefinition).childs.fork?.forEach((subGroup) => {
        childsNames.push(subGroup.name);
      });
  }

  function addGroupProcesses(childsNames: string[]): void {
    (selectedProcess as GroupDefinition) &&
      (selectedProcess as GroupDefinition).childs.process?.forEach((processChild) => {
        childsNames.push(processChild.name);
      });
  }

  function onCancel(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalConditionChange(false);
  }

  function onAccept(event: React.MouseEvent): void {
    event.stopPropagation();
    if (isEditing) {
      if (!existsGroupAndChildCondition()) {
        onAddCondition();
      } else {
        setIsModalWarningOpen(true);
      }
    } else {
      onAddCondition();
    }
  }

  function onAddCondition(): void {
    if (conditionInfo.nextExecution !== '' && conditionInfo.childGroup !== '') {
      uploadConditionsGroup();
      onModalConditionChange(false);
    } else {
      setErrorMessage(true);
    }
  }

  function existsGroupAndChildCondition(): boolean {
    let exists: boolean = false;
    schedulerList &&
      schedulerList.forEach((process) => {
        if (equalsChildName(process) && equalsParentName(process)) {
          exists = true;
        }
      });

    return exists;
  }

  function equalsChildName(process: ProcessSchedule): boolean {
    return process.childGroup === conditionInfo.childGroup;
  }

  function equalsParentName(process: ProcessSchedule): boolean {
    return process.parentGroup === conditionInfo.parentGroup;
  }

  async function uploadConditionsGroup(): Promise<void> {
    setIsLoading(true);
    if (!isEditing) {
      await setGroupsScheduler(conditionInfo, ADD_CONDITION);
    } else {
      await setGroupsScheduler(conditionInfo, MODIFY_CONDITION);
    }
    onRefreshData();
    setIsLoading(false);
  }

  function onInputChange(event: { target: HTMLInputElement }): void {
    if (event.target.name === LABOR_INPUT) {
      updateLaborCheck();
    } else if (event.target.name === DATE_INPUT) {
      updateExecutionDate(event.target.value);
    }
  }

  function updateExecutionDate(date: string): void {
    const nextExecutionDate: string = moment(date).format('YYYYMMDD');
    setConditionInfo((currentInfo) => ({ ...currentInfo, nextExecution: nextExecutionDate }));
  }

  function updateLaborCheck(): void {
    const newLaborValue: number = conditionInfo.labor === NOT_CHECKED ? CHECKED : NOT_CHECKED;
    setConditionInfo((currentInfo) => ({ ...currentInfo, labor: newLaborValue }));
  }

  function onSelectStringChange(value: string, name?: string): void {
    if (name === DAY_INPUT) {
      setConditionInfo((currentInfo) => ({ ...currentInfo, day: parseInt(value) }));
    } else {
      name && setConditionInfo((currentInfo) => ({ ...currentInfo, [name]: value }));
    }
  }

  function onModalWarningChange(isAccept: boolean): void {
    setIsModalWarningOpen(false);
    if (isAccept) {
      onAddCondition();
    }
  }

  useEffect(() => {
    loadChilds();
    setConditionInfo(loadConditionGroup());
  }, [selectedProcess]);

  return (
    <ConditionModalView
      isModalWarningOpen={isModalWarningOpen}
      onModalWarningChange={onModalWarningChange}
      errorMessage={errorMessage}
      onCancel={onCancel}
      onAccept={onAccept}
      isConditioningBoth={isConditioningBoth}
      childs={childrenToSelect}
      dateOptions={dateOptions}
      periodicityOptions={periodicityOptions}
      onInputChange={onInputChange}
      conditionInfo={conditionInfo}
      onSelectStringChange={onSelectStringChange}
      isLoading={isLoading}
      isEditing={isEditing || false}
    />
  );
}
