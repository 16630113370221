import React, { PureComponent } from 'react';
import Displayable from './Displayable';
import { storeActions } from '../../src/store';
import { getCurrentTabInformation } from '../../src/selectors';
import { connect } from 'unistore/react';
import { mapColor } from '../helpers/styleHelper';

class Label extends PureComponent {
  getAlign = (alignId) => {
    const alignDefault = 'left';
    return (
      {
        0: 'left',
        1: 'center',
        2: 'right',
      }[alignId] || alignDefault
    );
  };
  render() {
    const { getAlign, props } = this;
    const { legend, position, font, componentState, operationLook, backgroundColor } = props;
    if (!font) {
      return null;
    }
    const { fontSize, align } = font;

    const tailwindColor = mapColor(`${font.colorRed}, ${font.colorGreen}, ${font.colorBlue}`);
    const fontStyle = font.fontStyle;
    const underline = font.underLine ? 'underline' : '';
    const strikeout = font.strikeOut ? 'line-through' : '';
    const lineStyle = underline + ' ' + strikeout;

    const labelStyle = {
      left: position.x,
      top: position.y,
      width: position.width,
      height: position.height,
      textAlign: getAlign(align || 0),
      textDecoration: lineStyle,
      backgroundColor: backgroundColor || 'transparent',
      borderRadius: '3px',
    };

    return (
      <Displayable componentState={componentState}>
        <label
          className={`${fontStyle === 1 ? 'font-semibold' : fontStyle === 2 ? 'italic' : ''} text-${tailwindColor} block ${
            operationLook === true ? 'bg-not-as-white' : 'bg-grey-lighter-lighter'
          } relative`}
          style={{
            ...labelStyle,
            fontSize: fontSize || 12,
            color: tailwindColor,
          }}
        >
          {legend}
        </label>
      </Displayable>
    );
  }
}

const mapStateToProps = (state) => {
  const { operationLook } = getCurrentTabInformation(state);
  return {
    operationLook,
  };
};

export default connect(mapStateToProps, storeActions)(Label);
