import React, { useRef } from 'react';

const Picker = ({ id, title, options, onChange, selectedValue, autofocus = false, readOnly, closeWindow, keydown }) => {
  const picker = useRef(null);
  let optionLayout = options.map((option, key) => {
    return (
      <option key={key} value={option.value}>
        {option.label}
      </option>
    );
  });

  return (
    <div className="p-2 flex">
      <label
        className="text-primary w-1/3 p-1"
        style={{
          fontSize: 16,
          paddingLeft: 10,
        }}
      >
        {title}
      </label>
      <div className="relative w-2/3">
        <select
          id={id}
          autoFocus={autofocus}
          className="w-full border border-primary rounded text-text-base p-1 pr-8"
          onChange={(event) => onChange(event.target.value)}
          defaultValue={selectedValue}
          disabled={readOnly}
          ref={picker}
          onBlur={(e) => {
            if (e.relatedTarget === null || e.relatedTarget.classList.contains('focus-trap')) {
              picker.current.focus();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              closeWindow();
            }
            if (e.ctrlKey && e.keyCode === 76) {
              e.preventDefault();
            }
            if (keydown) {
              keydown(e);
            }
          }}
        >
          {optionLayout}
        </select>
      </div>
    </div>
  );
};

export default Picker;
