import React, { Component } from 'react';

class WindowButton extends Component {
  constructor(props) {
    super(props);
    this.buttonInput = React.createRef();
  }

  focusElement = () => {
    if (this.props.htmlId !== document.activeElement.id) {
      document.getElementById(this.props.htmlId).focus();
      // this.buttonInput.current.focus();
    }
  };

  componentDidUpdate() {
    const { isFocused } = this.props;
    if (isFocused) {
      this.focusElement();
    }
  }

  handleBlur = (e) => {
    const { isFocused, isCancel } = this.props;
    if (((isFocused || isCancel) && e.relatedTarget === null) || (e.relatedTarget && e.relatedTarget.classList.contains('contorno'))) {
      this.focusElement();
    }
  };

  render() {
    const { title, click, className, onKeyDown = null, htmlId } = this.props;

    return (
      <button
        id={htmlId}
        className={`${className} ${
          this.props.disabled ? 'bg-light-grey text-grey' : 'bg-grey text-white hover:bg-primary focus:bg-primary focus:outline-none'
        } py-2 rounded shadow text-sm`}
        onClick={(e) => {
          click(e, this);
        }}
        disabled={!!this.props.disabled}
        onBlur={this.handleBlur}
        ref={this.buttonInput}
        onKeyDown={onKeyDown}
      >
        {title}
      </button>
    );
  }
}

export default WindowButton;
