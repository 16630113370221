import React, { useState, useEffect } from 'react';
import { getGroupsScheduler, getProcessesGroups } from '../../api/processesManager';
import { ProcessesManagerView } from './ProcessesManagerView';

import { GroupDefinition } from './models/groupDefinition';
import { SelectedProcess } from './models/types';

import { ForkProcess } from './models/forkProcess';
import { ProcessSchedule, ProcessScheduleService } from './models/processSchedule';
import moment from 'moment';
import browserStorage from '../../utils/browserStorage';

export const ForkContext = React.createContext<ForkProcess[]>([]);

export function ProcessesManagerViewModel(): JSX.Element {
  const [groupDefinitions, setGroupDefinitions] = useState<GroupDefinition[]>([]);
  const [selectedProcess, setSelectedProcess] = useState<SelectedProcess>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [executedProcesses, setExecutedProcesses] = useState<number[]>([]);
  const [forkProcesses, setForkProcesses] = useState<ForkProcess[]>([]);
  const [schedulerList, setSchedulerList] = useState<ProcessSchedule[]>();
  const [isPeriodicityTabOpen, setIsPeriodicityTabOpen] = useState<boolean>(false);

  async function loadSchedulerList(): Promise<void> {
    setIsLoading(true);
    const listReceived: ProcessScheduleService[] = await getGroupsScheduler();
    if (listReceived && listReceived[0] && listReceived[0].error) {
      console.log(listReceived[0].error);
    } else {
      const scheduledGroups: ProcessSchedule[] = mapServiceToProcessSchedule(listReceived);
      setSchedulerList(scheduledGroups);
    }
    setIsLoading(false);
  }

  function mapServiceToProcessSchedule(serviceList: ProcessScheduleService[]): ProcessSchedule[] {
    const mappedService: ProcessSchedule[] = serviceList.map((group) => {
      return {
        day: group.day,
        labor: group.habiles,
        parentGroup: group.nombrePadre,
        childGroup: group.nombreHijo,
        nextExecution: moment(group.fechaProxEjecucion).format('YYYYMMDD'),
        ordinal: group.ordinal,
        periodicity: group.periodicidad,
      };
    });

    return mappedService;
  }

  async function getProcessesGroupsDataAsync(): Promise<void> {
    setIsLoading(true);
    let response: GroupDefinition[] = await getProcessesGroups();
    if (schedulerList) {
      setIsLoading(false);
    }
    setIsLoading(false);
    response.forEach((group) => {
      inheritProperties(group);
      if (group.childs.fork) {
        group.childs.fork.forEach((fork) => setForkProcesses([...forkProcesses, { name: fork.name }]));
      }
    });
    setGroupDefinitions(response);
  }

  function inheritProperties(groupDefinition: GroupDefinition): void {
    groupDefinition.childs?.group?.forEach((group) => inheritGroupProperties(group));

    inheritGroupProperties(groupDefinition);
  }

  function inheritGroupProperties(groupDefinition: GroupDefinition): void {
    groupDefinition.childs?.process?.forEach((process) => {
      process.constants = groupDefinition.constants.groupConstants;
      process.canBeRootGroup = groupDefinition.canBeRootGroup;
      process.groupName = groupDefinition.name;
    });

    groupDefinition.childs?.group?.forEach((group) => inheritGroupProperties(group));
  }

  function addExecutedProcessId(executionId: number): void {
    setExecutedProcesses([...executedProcesses, executionId]);
  }

  function eliminateExecutedProcessId(executionId: number): void {
    if (executedProcesses.includes(executionId)) {
      setExecutedProcesses(executedProcesses.filter((element) => element !== executionId));
    } else {
      if (browserStorage.get('monitoredProcesses')) {
        let monitoredProcesses: number[] = browserStorage.get('monitoredProcesses');
        setExecutedProcesses(monitoredProcesses.filter((element) => element !== executionId));
      }
    }
    if (browserStorage.get('monitoredProcesses')) {
      let monitoredProcesses = browserStorage.get('monitoredProcesses');
      if (monitoredProcesses) {
        monitoredProcesses = monitoredProcesses.filter((proc: number) => proc !== executionId);
        browserStorage.set('monitoredProcesses', monitoredProcesses);
      }
    }
  }

  function onSelectProcess(process: SelectedProcess): void {
    setSelectedProcess(process);
  }

  function onPeriodicityTabChange(): void {
    setIsPeriodicityTabOpen(!isPeriodicityTabOpen);
  }

  useEffect(() => {
    getProcessesGroupsDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadSchedulerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPeriodicityTabOpen]);

  return (
    <ForkContext.Provider value={forkProcesses}>
      <ProcessesManagerView
        selectedProcess={selectedProcess}
        onSelectProcess={onSelectProcess}
        processesGroups={groupDefinitions}
        isLoading={isLoading}
        executedProcesses={executedProcesses}
        addExecutedProcessId={addExecutedProcessId}
        eliminateExecutedProcessId={eliminateExecutedProcessId}
        schedulerList={schedulerList || []}
        onPeriodicityTabChange={onPeriodicityTabChange}
      />
    </ForkContext.Provider>
  );
}
