import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import Sidebar from './../Sidebar/SidebarViewModel';
import TabsOperations from '../TabsOperations';
import Reports from '../Reports/Reports';
import ModalMessageGlobal from '../ModalMessageGlobal';
import PostingViewer from '../PostingViewer';
import WorkflowWindow from '../WorkflowWindow';
import Footer from '../Footer';
import { injectIntl } from 'react-intl';
import SchemaViewModel from '../../components/Schema/SchemaViewModel';
import { useTheme } from '../../themes/ThemeContext';
import { StyleSheet, css } from 'aphrodite';
import { PATHNAME } from '../../../src/config';
import BranchesViewer from '../Branches/BranchesViewer';
import ClearingViewModel from '../../components/Clearing/ClearingViewModel';
import BinnacleViewModel from '../../components/Binnacle/BinnacleViewModel';
import { ProcessesManagerViewModel } from '../ProcessesManager/ProcessesManagerViewModel';
import { SecurityManagerViewModel } from '../SecurityManager/SecurityManagerViewModel';
import { ProductionManagerViewModel } from '../ProductionManager/ProductionManagerViewModel';
import ModalChangePassword from '../ModalChangePassword';
import TopazLogViewer from '../TopazLogViewer';
import { store } from '../../store';
import { getLogConfig, setLogConfig } from '../../api/topazLog';

const HomeView = (props) => {
  const {
    sidebarIsCollapsed,
    onClickMenu,
    userAllowed,
    sidebarData,
    footerInfo,
    setFooterInfo,
    tabs,
    showModalPassword,
    setShowModalPassword,
  } = props;

  /********
   * Styles
   */
  const themeState = useTheme();
  const history = useHistory();
  const { color, primary, background, border, text, error: errorColor } = themeState.theme;
  const styles = StyleSheet.create({
    homeContainer: {
      backgroundColor: background.background,
      display: 'flex',
      height: 'calc(100vh - 20px)',
      width: '100%',
    },
    inputFormContainer: {
      marginBottom: '1.5rem',
    },
    inputForm: {
      width: '100%',
      padding: '.75rem',
      borderWidth: 2,
      borderColor: border.light,
      color: text.text,
      borderRadius: 4,
      backgroundColor: 'red',
      transition: '.2s',
      ':focus': {
        borderColor: primary.primary,
      },
    },
    loginButton: {
      color: color.white,
      backgroundColor: primary.primary,
      padding: '.75rem 0',
      width: '100%',
      borderRadius: 4,
      boxShadow: '0 2px 0px 0 #00151F',
      transition: '.2',
      ':hover': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.light,
      },
      ':focus': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.light,
      },
      ':active': {
        boxShadow: '0 2px 0px 0 #00151F',
        backgroundColor: primary.dark,
      },
    },
    loginFooter: {
      marginTop: '1.5rem',
    },
    errorMessage: {
      color: errorColor.error,
      textAlign: 'center',
    },
    mainContent: {
      backgroundColor: color.white,
      borderRadius: '.25rem',
      flexGrow: 1,
      marginTop: '.5rem',
      marginRight: '1rem',
      minHeight: 'calc(100vh - 38px)',
      overflow: 'auto',
      paddingBottom: '0rem',
      position: 'relative',
    },
  });

  const unauthorizedUser = props.intl.formatMessage({
    id: 'unauthorizedUser',
    defaultMessage: 'Usuario no autorizado',
  });

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const handleKeyDown = (e) => {
    try {
      if (e.ctrlKey && e.shiftKey && (e.key.toLowerCase() === 'd' || e.nativeEvent?.code === 'KeyD')) {
        getLogConfig().then(async (configResponse) => {
          const state = store.getState();
          await setLogConfig({ ...configResponse, enabled: !state.isLogActive });
          store.setState({ isLogActive: !state.isLogActive, logConfig: configResponse });
        });
      }
    } catch (ex) {
      console.log('Error al togglear los logs Topaz :');
      console.log(ex);
    }
  };

  return (
    <div>
      <ModalMessageGlobal />
      {showModalPassword && <ModalChangePassword setShowModalPassword={setShowModalPassword} />}
      <div className={css(styles.homeContainer)}>
        <Sidebar isCollapsed={sidebarIsCollapsed} click={onClickMenu} data={sidebarData} />
        <TopazLogViewer intl={props.intl} />
        <div className={css(styles.mainContent)}>
          {userAllowed ? (
            <React.Fragment>
              <Route exact path={`${PATHNAME}home`} render={() => <TabsOperations tabs={tabs} history={history} />} />
              <Route path={`${PATHNAME}home/reports`} render={() => <Reports />} />
              <Route path={`${PATHNAME}home/viewer`} render={() => <PostingViewer setFooterInfo={setFooterInfo} />} />
              <Route path={`${PATHNAME}home/inbox`} render={() => <WorkflowWindow history={history} />} />
              <Route path={`${PATHNAME}home/clearing`} render={() => <ClearingViewModel />} />
              <Route path={`${PATHNAME}home/branches`} render={() => <BranchesViewer />} />
              <Route path={`${PATHNAME}home/binnacle`} render={() => <BinnacleViewModel />} />
              <Route path={`${PATHNAME}home/production-manager`} render={() => <ProductionManagerViewModel />} />
              <Route path={`${PATHNAME}home/processes-manager`} render={() => <ProcessesManagerViewModel />} />
              <Route path={`${PATHNAME}home/security-manager`} render={() => <SecurityManagerViewModel />} />
              <Route path={`${PATHNAME}home/schema`} render={() => <SchemaViewModel />} />
            </React.Fragment>
          ) : (
            <div className="flex justify-between items-center h-full">
              <div className="border border-solid border-primary bg-grey-lighter-lighter rounded-lg relative p-2 w-full max-w-sm m-auto flex-col flex">
                <div className="flex-auto text-center m-2 py-2 text-lg">{unauthorizedUser}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer footerInfo={footerInfo} />
    </div>
  );
};

export default injectIntl(HomeView);
