import React, { useState } from 'react';
import { encriptData } from '../../../../../../api/securityManager';
import { EMPTY_STRING, NO_MODAL_OPEN } from '../../../../models/constants';
import { downloadFilesGenericBrowsers } from '../helpers/downloadFiles';
import { PasswordDBModalView } from './PasswordDBModalView';

export interface PasswordDBModalViewModelProps {
  onModalChange: (modalOpen: string) => void;
}

const PASS_DB_FILE_NAME: string = 'TzscPass.dat';
const PASS_DB_MAX_USER_LENGTH: number = 8;
const PASS_DB_MAX_PASS_LENGTH: number = 16;
const PASS_DB_MIN_LENGTH: number = 1;

export function PasswordDBModalViewModel({ onModalChange }: PasswordDBModalViewModelProps): JSX.Element | null {
  const [userInput, setUserInput] = useState<string>(EMPTY_STRING);
  const [newPasswordInput, setNewPasswordInput] = useState<string>(EMPTY_STRING);
  const [confirmPasswordInput, setConfirmPasswordInput] = useState<string>(EMPTY_STRING);
  const [passConfirmError, setPassConfirmError] = useState<boolean>(false);
  const [lengthUserError, setLengthUserError] = useState<boolean>(false);
  const [lengthPasswordError, setLengthPasswordError] = useState<boolean>(false);

  function onAccept(event: React.MouseEvent): void {
    event.stopPropagation();
    const errors: boolean = lengthPasswordError || lengthUserError || passConfirmError;
    if (!errors) {
      encriptInformation();
      onModalChange(NO_MODAL_OPEN);
    }
  }

  function base64ToBytesArray(base64: string): Uint8Array {
    let binaryString = window.atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  async function encriptInformation(): Promise<void> {
    let startEncripted = await encriptData('G', PASS_DB_MAX_USER_LENGTH);
    let userEncripted = await encriptData(userInput, PASS_DB_MAX_USER_LENGTH);
    let passEncripted = await encriptData(newPasswordInput, PASS_DB_MAX_PASS_LENGTH);
    const fileParts = [];

    startEncripted = base64ToBytesArray(startEncripted);
    fileParts.push(startEncripted);
    userEncripted = base64ToBytesArray(userEncripted);
    fileParts.push(userEncripted);
    passEncripted = base64ToBytesArray(passEncripted);
    fileParts.push(passEncripted);
    const blobFile = new Blob(fileParts, { type: 'text/html' });

    downloadFilesGenericBrowsers(blobFile, PASS_DB_FILE_NAME);
  }

  function onCancel(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(NO_MODAL_OPEN);
  }

  function onConfirmPasswordChange(event: { target: HTMLInputElement }): void {
    setConfirmPasswordInput(event.target.value);
    setPassConfirmError(event.target.value !== newPasswordInput);
  }

  function onUserChange(event: { target: HTMLInputElement }): void {
    setUserInput(event.target.value);
    setLengthUserError(event.target.value.length > PASS_DB_MAX_USER_LENGTH || event.target.value.length < PASS_DB_MIN_LENGTH);
  }

  function onPasswordChange(event: { target: HTMLInputElement }): void {
    setNewPasswordInput(event.target.value);
    setLengthPasswordError(event.target.value.length > PASS_DB_MAX_PASS_LENGTH || event.target.value.length < PASS_DB_MIN_LENGTH);
    setPassConfirmError(event.target.value !== confirmPasswordInput);
  }

  return (
    <PasswordDBModalView
      onAccept={onAccept}
      onCancel={onCancel}
      onConfirmPasswordChange={onConfirmPasswordChange}
      passConfirmError={passConfirmError}
      lengthUserError={lengthUserError}
      lengthPasswordError={lengthPasswordError}
      onUserChange={onUserChange}
      onPasswordChange={onPasswordChange}
    />
  );
}
