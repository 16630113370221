export const STATE_SEAT_AUTHORIZE = 65;
export const STATE_SEAT_CONFIRM = 67;
export const STATE_SEAT_DEFERRED = 68;

export const iconsState = (filter) => {
  switch (filter) {
    case STATE_SEAT_AUTHORIZE:
      return 'clipboard';
    case STATE_SEAT_CONFIRM:
      return 'checkmark';
    case STATE_SEAT_DEFERRED:
      return 'checkmark2';
    default:
      return 'binoculars';
  }
};

function getStateByValue(num, intl) {
  return {
    65: intl.formatMessage({
      id: 'authorizationPending',
      defaultMessage: 'Pendiente de autorización',
    }),
    66: intl.formatMessage({ id: 'toUnsubscribe', defaultMessage: 'A dar de baja' }),
    67: intl.formatMessage({ id: 'toConfirm', defaultMessage: 'A confirmar' }),
    68: intl.formatMessage({
      id: 'toConfirmDeferred',
      defaultMessage: 'A confirmar diferido',
    }),
    80: intl.formatMessage({ id: 'accept', defaultMessage: 'Aceptar' }),
  }[num];
}

function createFilterMessage(body, statePostings, intl) {
  let { allRecords, branchGroup, description, machineNumber, operationNumber, postingBegin, postingEnd, processDate, user } = body;
  let message = '';
  if (statePostings) {
    if (statePostings !== '66') {
      message =
        message +
        `${intl.formatMessage({
          id: 'state',
          defaultMessage: 'Estado',
        })}: ${getStateByValue(statePostings, intl)}, `;
    }
    if (allRecords === false) {
      message =
        message +
        `${intl.formatMessage({
          id: 'branch',
          defaultMessage: 'Sucursal',
        })}: ${branchGroup}, `;
    }
  }
  if (operationNumber) {
    message =
      message +
      `${intl.formatMessage({
        id: 'operationNumber',
        defaultMessage: 'Número de Operación',
      })}: ${operationNumber}, `;
  }
  if (user) {
    message =
      message +
      `${intl.formatMessage({
        id: 'userInitials',
        defaultMessage: 'Iniciales del Usuario',
      })}: ${user}, `;
  }
  if (machineNumber) {
    message =
      message +
      `${intl.formatMessage({
        id: 'machineNumber',
        defaultMessage: 'Número de Maquina',
      })}: ${machineNumber}, `;
  }
  if (description) {
    message =
      message +
      `${intl.formatMessage({
        id: 'textDescription',
        defaultMessage: 'Texto en Descripción',
      })}: ${description}, `;
  }
  if (processDate) {
    message =
      message +
      `${intl.formatMessage({
        id: 'dateProcess',
        defaultMessage: 'Fecha de Proceso',
      })}: ${processDate.split(' ')[0]}, `;
  }
  if (postingBegin) {
    message =
      message +
      `${intl.formatMessage({
        id: 'postingFrom',
        defaultMessage: 'Asiento Desde',
      })}: ${postingBegin}, `;
  }
  if (postingEnd) {
    message =
      message +
      `${intl.formatMessage({
        id: 'postingTo',
        defaultMessage: 'Asiento Hasta',
      })}: ${postingEnd}, `;
  }
  if (message.length > 0) {
    message = message.substr(0, message.length - 2);
  }
  return message;
}

export function createFooterInfo(rows, body, statePostings, intl) {
  let info = {};
  let filterMessage = createFilterMessage(body, statePostings, intl);
  const numberOfRecords = rows.length;
  const searchedDate = new Date().toLocaleString().split(' ')[1];
  const message = intl.formatMessage({
    id: 'recoveredRecords',
    defaultMessage: 'registro/s recuperado/s a las',
  });
  info.records = `${numberOfRecords} ${message} ${searchedDate}`;
  if (filterMessage !== '')
    info.filterMessage = `${intl.formatMessage({
      id: 'filter',
      defaultMessage: 'Filtro',
    })}: ${filterMessage}`;
  return info;
}

export function formatDateTimePostings(dateTime) {
  dateTime = dateTime.split(' ');
  let date = dateTime[0];
  if (dateTime[1]) {
    return `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)} ${dateTime[1]}`;
  } else {
    return `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
  }
}
