import { store } from '../../../store';
import { getBitacorasLogin, getBitacorasLoginCount } from '../../../api/bitacora';
import { formatDateForServer } from './filterFields';
import { getButtonsState } from './paginado';

const getBitacoraLoginFilterResult = (filterParams, filterListID, intl, setDisabledButtonPrev, setDisabledButtonNext, setLoading) => {
  let {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    bLogin,
    bLogout,
    bFallidos,
    cantidadFallos,
    iniciales,
    maquina,
    sucursal,
    persistenceUnit,
  } = filterParams;

  let filterData = {};
  filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
  store.setState({
    bitacoraApp: {
      ...store.getState().bitacoraApp,
      ...filterData,
    },
  });
  let defaultValue;
  let usuario = iniciales;
  fechaHoraDesde = formatDateForServer(fechaHoraDesde);
  fechaHoraHasta = formatDateForServer(fechaHoraHasta);
  firstResult = firstResult || 0;
  maxResults = maxResults || 201;
  // bLogin = bLogin || defaultValue;
  // bLogout = bLogout || defaultValue;
  // bFallidos = bFallidos || defaultValue;
  cantidadFallos = cantidadFallos || defaultValue;
  usuario = usuario || defaultValue;
  maquina = maquina || defaultValue;
  sucursal = sucursal || defaultValue;
  persistenceUnit = persistenceUnit || 'Local';

  const params = {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    bLogin,
    bLogout,
    bFallidos,
    usuario,
    cantidadFallos,
    maquina,
    sucursal,
    persistenceUnit,
  };

  const resultColumns = [
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_fechahora',
        defaultMessage: 'Fecha Hora',
      }),
      accessor: 'fechahora',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 4,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_usuario',
        defaultMessage: 'Usuario',
      }),
      accessor: 'clave',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 50,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'iniciales',
        defaultMessage: 'Iniciales',
      }),
      accessor: 'iniciales',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_NombreUsr',
        defaultMessage: 'Nombre Usuario Topaz',
      }),
      accessor: 'iniciales',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_descripcion',
        defaultMessage: 'Descripción',
      }),
      accessor: 'descripcion',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_maquina',
        defaultMessage: 'Nº de Máquina',
      }),
      accessor: 'nromaquina',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_NombreMaquina',
        defaultMessage: 'Nombre Máquina',
      }),
      accessor: 'nombreMaquina',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_sucursal',
        defaultMessage: 'Sucursal',
      }),
      accessor: 'sucursal',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_NombreSuc',
        defaultMessage: 'Nombre Sucursal',
      }),
      accessor: 'nombreSucursal',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_usuariored',
        defaultMessage: 'Usuario de Red',
      }),
      accessor: 'usuariored',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_mac_address',
        defaultMessage: 'Mac Address',
      }),
      accessor: 'macaddress',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.bitacora_ip',
        defaultMessage: 'Dirección IP',
      }),
      accessor: 'direccionip',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
  ];

  getBitacorasLoginCount(params)
    .then((countData) => {
      getBitacorasLogin(params)
        .then((data) => {
          let buttonNextState, buttonPrevState;
          [buttonNextState, buttonPrevState] = getButtonsState(countData, firstResult);
          let storeBitacora = store.getState().bitacoraApp;
          let filterData = {};
          let iniciales = storeBitacora.helpIniciales;
          let inicialesDict = {};

          iniciales.forEach((inicial) => {
            inicialesDict[inicial.iniciales] = inicial;
          });
          data.forEach((item) => {
            let inicial = inicialesDict[item.usuario];
            if (inicial) {
              item.clave = inicial.clave;
              item.iniciales = inicial.iniciales;
            }
          });

          filterData[filterListID] = {
            ...store.getState().bitacoraApp[filterListID],
            resultColumns,
            resultData: data,
            currentPage: Math.ceil(firstResult / maxResults) + 1,
            pageCount: Math.ceil(countData / maxResults),
            resultCount: countData,
            buttonNextState,
            buttonPrevState,
          };

          setDisabledButtonNext(buttonNextState);
          setDisabledButtonPrev(buttonPrevState);
          store.setState({
            bitacoraApp: {
              ...store.getState().bitacoraApp,
              ...filterData,
            },
          });
          if (setLoading) {
            setLoading(false);
          }
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          console.log(err);
        });
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
      console.log(err);
    });
};

export { getBitacoraLoginFilterResult };
