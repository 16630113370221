import React, { Component } from 'react';
import { isEmpty } from 'busyman';
import { objectToClass } from '../lib/objectToClass';
import { connect } from 'unistore/react';
import { storeActions } from '../../src/store';
import { getCurrentTabInformation } from '../../src/selectors';
import { css, StyleSheet } from 'aphrodite';
import { getParentDialogCount, generateLineIDString } from '../../UI-kit/helpers/operationHelper';

import { withRouter } from 'react-router-dom';

class Renderable extends Component {
  constructor() {
    super();

    this.updateFields = this.updateFields.bind(this);
  }

  updateFields(field) {
    const { addFieldValue } = this.props;
    addFieldValue(field);
  }

  handleChangeRenderable(event, instance) {
    const { onChange } = this.props;
    instance &&
      instance.transactionLineLabel &&
      onChange({
        [instance.transactionLineLabel()]: { value: event.target.value },
      });
    if (event.target.rawValue !== undefined) {
      instance &&
        instance.transactionLineLabel &&
        onChange({
          [instance.transactionLineLabel()]: { rawValue: event.target.rawValue },
        });
    }
  }

  /* shouldComponentUpdate(nextProps, nextState) {
    return false;
  } */

  render() {
    let { root, fieldData, zIndex } = this.props;
    if (!root || isEmpty(root)) return null;
    let data = root.data()[0] || root.data();
    if (!data || isEmpty(data)) return null;
    let DynamicClass = objectToClass(data['class-name']);
    if (!objectToClass(data['class-name'])) {
      return null;
    }

    data.idNode = root._id;
    data.history = this.props.history;
    let instance = new DynamicClass(data);
    let ToRender = instance.component();

    if (!ToRender) {
      return null;
    }

    let currentIterationChildren = Object.values(root._childs).filter((child) => {
      let parentTL = getParentDialogCount(fieldData, null, null, generateLineIDString(child._data.lineID));
      parentTL = parentTL ? parseInt(parentTL) : null;
      return (
        !parentTL ||
        !child._data.lineID.countIterativeDialog ||
        parentTL === child._data.lineID.countIterativeDialog ||
        parentTL - 1 === child._data.lineID.countIterativeDialog
      );
    });

    let children = currentIterationChildren.map((child, index) => (
      <Renderable key={index} root={child} onChange={this.updateFields} fieldData={fieldData} zIndex={zIndex} />
    ));

    const { apiLoading } = this.props;

    const styles = StyleSheet.create({
      apiLoading: {
        pointerEvents: apiLoading ? 'none' : 'auto',
        cursor: apiLoading ? 'wait' : 'auto',
      },
    });

    return (
      <div className={css(styles.apiLoading)}>
        <ToRender
          {...instance.props(fieldData)}
          style={instance && instance.style && instance.style()}
          options={instance && instance.options && instance.options()}
          onChange={(e) => this.handleChangeRenderable(e, instance)}
          componentState={instance && instance.transactionLineLabel && fieldData[instance.transactionLineLabel()]}
          transactionNumber={instance && instance.transactionNumber && instance.transactionNumber()}
          lineNumber={instance && instance.lineNumber && instance.lineNumber()}
          transactionLineLabel={instance && instance.transactionLineLabel && instance.transactionLineLabel()}
          zIndex={zIndex}
        >
          {children}
        </ToRender>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { apiLoading, operationID, operationNumber, operationLook } = getCurrentTabInformation(state);
  return {
    operationID,
    operationNumber,
    operationLook,
    apiLoading,
  };
};

export default connect(mapStateToProps, storeActions)(withRouter(Renderable));
