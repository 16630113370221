import React, { useContext } from 'react';
import { GroupInstance } from '../../models/groupInstance';
import { ProcessExecutionInfo } from './ProcessExecutionInfo';
import { useIntl } from 'react-intl';

import { ForkContext } from '../../ProcessesManagerViewModel';

import './MonitorMenu.scss';
import { isAForkProcess } from '../../helpers/checkForkProcess';

interface ProcessesExecutionInfoProps {
  groupProcesses?: GroupInstance[];
  group?: GroupInstance;
  onModalChange: (isOpen: boolean) => void;
  onModalDataChange: (data: string, name: string) => void;
}

export function ProcessesExecutionInfo({ groupProcesses, group, onModalChange, onModalDataChange }: ProcessesExecutionInfoProps): JSX.Element {
  const intl = useIntl();
  const forks = useContext(ForkContext);

  return (
    <div className="w-full">
      <div className="pl-2">
        <div className="flex items-center justify-between text-xs uppercase text-grey py-2 border-b border-light text-center">
          <div className="w-48 pr-2 pl-4">{intl.formatMessage({ id: 'nombre' })}:</div>
          <div className="w-24 pr-2">{intl.formatMessage({ id: 'state' })}:</div>
          <div className="w-24 pr-2">{intl.formatMessage({ id: 'start' })}:</div>
          <div className="w-24 pr-2">{intl.formatMessage({ id: 'end' })}:</div>
          <div className="w-24 pr-2">{intl.formatMessage({ id: 'estimated' })}:</div>
          <div className="w-24 pr-2">{intl.formatMessage({ id: 'transcurred' })}:</div>
          <div className="w-24 pr-2">{intl.formatMessage({ id: 'graph' })}:</div>
          <div className="w-24 pr-2">{intl.formatMessage({ id: 'result' })}:</div>
        </div>
        <div className="processes-information">
          <div className="bg-grey-light" key={group?.name}>
            <ProcessExecutionInfo
              processInfo={group}
              whiteSpace={0}
              root={true}
              onModalChange={onModalChange}
              onModalDataChange={onModalDataChange}
            />
          </div>
          {groupProcesses?.map((item, index) => (
            <div
              className={` ${isAForkProcess(forks, item.name) ? 'fork-process' : item.type === 'groupInstance' ? 'bg-grey-lighter' : 'bg-white'} `}
              key={index}
            >
              <ProcessExecutionInfo
                processInfo={item}
                whiteSpace={6}
                root={false}
                onModalChange={onModalChange}
                onModalDataChange={onModalDataChange}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
