import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { handleError, getUrl } from './handlers';

export let createOperation = async (opNumber = 0, subOperation = false) => {
  const sessionID = browserStorage.sessionGet(USER_SESSION);

  try {
    const query = {
      endpoint: 'operations',
      action: 'createOperation',
      params: `sessionId=${sessionID}&operationNumber=${opNumber}&isCall=${!subOperation}`,
    };
    const response = await axios.post(getUrl(query));
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export let beginOperation = (operationId, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let headers = { 'Content-Type': 'application/json' };

  return axios
    .post(`${API}TopazWebClientWS/resources/operations/begin?operationID=${operationId}&sessionId=${session}`, body, { headers })
    .then(({ data: operationComponents }) => operationComponents);
};

export let freeOperationInfo = (operationId) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(`${API}TopazWebClientWS/resources/operations/freeOperationInfo?operationID=${operationId}&sessionId=${session}`)
    .then(({ data }) => data);
};

export let getOperations = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/menuSession/getOperations?sessionID=${session}`).then(({ data }) => data);
};

export let resume = (operationId, status) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(`${API}TopazWebClientWS/resources/operations/resume?sessionId=${session}&operationID=${operationId}&status=${status}`)
    .then(({ data }) => data);
};

export let cancel = (operationId) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/operations/cancel?sessionId=${session}&operationID=${operationId}`).then(({ data }) => data);
};

export let setInputParameters = (operationID, inputParameters) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let headers = { 'Content-Type': 'application/json' };

  return axios
    .post(`${API}TopazWebClientWS/resources/operations/setInputParameters?sessionId=${session}&operationID=${operationID}`, inputParameters, {
      headers,
    })
    .then(({ data }) => data);
};

export let setTZPCAPTParameters = (operationID, inputParameters) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let headers = { 'Content-Type': 'application/json' };

  return axios
    .post(`${API}TopazWebClientWS/resources/operations/setParameters?sessionId=${session}&operationID=${operationID}`, inputParameters, { headers })
    .then(({ data }) => data);
};

export let setServerTasks = (operationID, serverTasks) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let headers = { 'Content-Type': 'application/json' };

  return axios
    .post(`${API}TopazWebClientWS/resources/operations/setServerTasks?sessionId=${session}&operationID=${operationID}`, serverTasks, { headers })
    .then(({ data }) => data);
};

export let setVerifyFinger = (verify, operationId) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  let resVerify = verify === 1;
  return axios
    .post(`${API}TopazWebClientWS/resources/operations/setVerifyFinger?operationID=${operationId}&sessionId=${session}&verify=${resVerify}`)
    .then(({ data }) => data);
};
