import labelsSpanish from './es.json'
import labelsEnglish from './en.json'
import labelsPortuguese from './pt.json'

export const messages = {
  'es': labelsSpanish,
  'en': labelsEnglish,
  'pt-BR': labelsPortuguese,
  'pt': labelsPortuguese
}
