import React from 'react';
import { connect } from 'unistore/react';
import { FormattedMessage } from 'react-intl';

import ModalMessage from './ModalMessage';
import { storeActions } from '../store';
import { logout } from '../helpers/login';
import { MODAL_MESSAGE_GLOBAL } from '../../UI-kit/constants/zIndexValues';

const ModalImage = (props) => {
  const { message, handleAccept } = props;

  return (
    <div className={`fixed pin overflow-auto flex w-full bg-white`} style={{ zIndex: MODAL_MESSAGE_GLOBAL }}>
      <div className={`rounded relative flex-col flex w-full`}>
        <div className="text-right text-primary cursor-pointer mr-6 mt-3" onClick={handleAccept}>
          &#10005;
        </div>
        <div className={`flex-auto text-center h-550 m-1 flex overflow-scroll`}>{message}</div>
      </div>
    </div>
  );
};

class ModalMessageGlobal extends React.PureComponent {
  render() {
    const {
      globalMessage: { visible, message, logoutAfter, redirectTo = null, loading = false, image = false, eventHandler, cancelEventHandler },
      closeGlobalModal,
    } = this.props;

    if (image) return <ModalImage message={message} handleAccept={() => closeGlobalModal()} />;

    return (
      <div>
        {visible && (
          <ModalMessage
            message={message}
            additionalMessage={logoutAfter && <FormattedMessage id="isNecessaryReLogin" defaultMessage={`Es necesario volver a iniciar sesión.`} />}
            handleAccept={() => {
              if (eventHandler) {
                if (Array.isArray(eventHandler)) {
                  eventHandler.forEach((evt) => {
                    if (evt) evt();
                  });
                } else {
                  eventHandler();
                }
              }
              if (logoutAfter) {
                logout();
              }
              closeGlobalModal();
            }}
            redirectTo={redirectTo}
            loading={loading}
            handleCancel={() => {
              closeGlobalModal();
              if (cancelEventHandler) {
                cancelEventHandler();
              }
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ globalMessage = {} }) => ({ globalMessage });

export default connect(mapStateToProps, storeActions)(ModalMessageGlobal);
