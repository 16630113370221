import { PulseLoader } from 'halogenium';
import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { GroupInstance } from '../../models/groupInstance';
import { FilterDropdown } from '../monitorTab/filterMenu/FilterDropDown';

import './ExecutionHistory.scss';
import { InstanceHeaders } from './ExecutionHistoryViewModel';
import { HistoryDetailViewModel } from './historyDetails/HistoryDetailViewModel';
import { HistoryFilterOptionsViewModel } from './historyFilter/HistoryFilterOptionsViewModel';
import { ExecutionHistoryMenuViewModel } from './historyMenu/ExecutionHistoryMenuViewModel';
import { MonitorModalViewModel } from './monitorModal/MonitorModalViewModel';
import { ErrorModalViewModel } from '../../components/errorModal/ErrorModalViewModel';

interface ExecutionHistoryViewProps {
  isLoading: boolean;
  groupsInstances?: InstanceHeaders[];
  onSelectInstance: (instance: GroupInstance) => void;
  onSelectedChange: (date: string) => void;
  selectedDate: string;
  selectedInstance?: GroupInstance;
  onRefreshInstanceChange: (instanceToRefresh: GroupInstance) => void;
  onStopInstance: (instanceToStop: GroupInstance) => void;
  onResumeInstance: (instanceToResume: GroupInstance) => void;
  getErrorMessage: () => string;
  isModalOpen: boolean;
  onModalChange: (isOpenModal: boolean) => void;
}

export function ExecutionHistoryView({
  isLoading,
  groupsInstances,
  onSelectInstance,
  onSelectedChange,
  selectedDate,
  selectedInstance,
  onRefreshInstanceChange,
  onStopInstance,
  onResumeInstance,
  getErrorMessage,
  isModalOpen,
  onModalChange,
}: ExecutionHistoryViewProps): JSX.Element {
  const [isModalMonitoringOpen, setIsModalMonitoringOpen] = useState<boolean>(false);
  const intl = useIntl();

  function onModalMonitoringChange(isOpen: boolean): void {
    setIsModalMonitoringOpen(isOpen);
  }

  function renderHistoryFilter(): JSX.Element {
    return <HistoryFilterOptionsViewModel onSelectedChange={onSelectedChange} selectedState={selectedDate} />;
  }

  return isLoading ? (
    <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
      <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
    </div>
  ) : (
    <div>
      <div className="filter-dropdown">
        <FilterDropdown>{renderHistoryFilter()}</FilterDropdown>
      </div>
      {selectedInstance && isModalMonitoringOpen && (
        <MonitorModalViewModel executionId={selectedInstance.executionId} onModalMonitoringChange={onModalMonitoringChange} />
      )}
      {isModalOpen && <ErrorModalViewModel getErrorMessage={getErrorMessage} onModalChange={onModalChange} />}
      {groupsInstances && groupsInstances?.length !== 0 ? (
        <div className={`history-container-menu ${isModalMonitoringOpen && 'blur-info'}`}>
          <div className="menu-container">
            <ExecutionHistoryMenuViewModel
              onStopInstance={onStopInstance}
              onResumeInstance={onResumeInstance}
              selectedInstance={selectedInstance}
              instanceHeaders={groupsInstances}
              onSelectInstance={onSelectInstance}
              onRefreshInstanceChange={onRefreshInstanceChange}
              onModalMonitoringChange={onModalMonitoringChange}
            />
          </div>
          <div className="process-detail-container">
            <HistoryDetailViewModel selectedInstance={selectedInstance} />
          </div>
        </div>
      ) : (
        <div className="history-container-menu justify-center">
          <div className="font-bold text-m text-center mt-32">{intl.formatMessage({ id: 'thereAreNoGroupsToShow' })}</div>
        </div>
      )}
    </div>
  );
}
