import React from 'react';
import addIcon from './add';
import downArrowIcon from './downArrow';

const addDownArrowIcon = (
  <div>
    {addIcon}
    {downArrowIcon}
  </div>
);

export default addDownArrowIcon;
