/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { PulseLoader } from 'halogenium';
import { connect } from 'unistore/react';
import 'react-table/react-table.css';
import { useIntl, FormattedMessage } from 'react-intl';

import PostingSearcher from './PostingSearcher';
import { storeActions, store } from '../store';
import { createFooterInfo, formatDateTimePostings } from '../helpers/postingManagerHelper';

import PostingListView from './PostingListView';
import { getTransactionsNew } from '../api/postingManager';
import { css, StyleSheet } from 'aphrodite';

const CellTable = ({ children }) => {
  return <div className="flex justify-center items-center w-full h-full">{children}</div>;
};

const LoaderComponent = (props) =>
  props.loading ? (
    <div className="-loading -active">
      <div className="-loading-inner">
        <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
      </div>
    </div>
  ) : null;

export const PostingList = (props) => {
  const { handlePostingManager, dataSearcher, setDataSearcher, setFooterInfo } = props;

  const intl = useIntl();

  const [rows, setRows] = useState([]);
  const [bodyGetPostings, setBodyGetPostings] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async (pendingOfSearcher, bodySearcher) => {
      setLoading(true);
      const state = store.getState();
      if (state.postingFilter) {
        bodySearcher = state.postingFilter;
        state.postingFilter = null;
        store.setState(state);
        pendingOfSearcher = 66;
      }
      const data = await getTransactionsNew(pendingOfSearcher, bodySearcher);
      setBodyGetPostings(bodySearcher);
      const rows = createRows(data);
      setRows(rows);
      const info = createFooterInfo(rows, bodySearcher, pendingOfSearcher.toString(), intl);
      setFooterInfo(info);
      setLoading(false);
    };

    fetch(dataSearcher.pendingOfSearcher, dataSearcher.bodySearcher);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchPostings = async (pendingOf, body, statePostings) => {
    setDataSearcher({ pendingOfSearcher: pendingOf, bodySearcher: body });

    setLoading(true);
    const data = await getTransactionsNew(pendingOf, body);
    setBodyGetPostings(body);
    const rows = createRows(data);
    setRows(rows);
    const info = createFooterInfo(rows, body, statePostings, intl);
    setFooterInfo(info);
    setLoading(false);
  };

  const createRows = (postingsList) => {
    let rows = [];
    if (postingsList && postingsList instanceof Array) {
      postingsList.forEach((sData) => {
        let { state, transactionID, operation, description, sector, branch, user, finishTime, processDate } = sData;
        let row = {
          state,
          transactionID,
          operation,
          description,
          sector,
          branch,
          user,
          finishTime: formatDateTimePostings(finishTime),
          processDate: formatDateTimePostings(processDate),
        };
        rows.push(row);
      });
    }
    return rows;
  };

  const columsData = [
    {
      accessor: 'state',
      Header: 'Estado',
      width: 170,
      Cell: (props) => <CellTable>{props.value}</CellTable>,
    },
    {
      accessor: 'transactionID',
      Header: intl.formatMessage({ id: 'posting', defaultMessage: 'Asiento' }),
      Cell: (props) => <CellTable>{props.value}</CellTable>,
    },
    {
      accessor: 'operation',
      Header: intl.formatMessage({
        id: 'operation',
        defaultMessage: 'Operación',
      }),
      Cell: (props) => <CellTable>{props.value}</CellTable>,
    },
    {
      accessor: 'description',
      Header: intl.formatMessage({
        id: 'description',
        defaultMessage: 'Descripción',
      }),
      width: 120,
      Cell: (props) => <CellTable>{props.value}</CellTable>,
    },
    {
      accessor: 'sector',
      Header: intl.formatMessage({
        id: 'description',
        defaultMessage: 'Sector',
      }),
      width: 80,
      Cell: (props) => <CellTable>{props.value}</CellTable>,
    },
    {
      accessor: 'branch',
      Header: intl.formatMessage({ id: 'branch', defaultMessage: 'Sucursal' }),
      width: 80,
      Cell: (props) => <CellTable>{props.value}</CellTable>,
    },
    {
      accessor: 'user',
      Header: intl.formatMessage({ id: 'user', defaultMessage: 'Usuario' }),
      Cell: (props) => <CellTable>{props.value}</CellTable>,
    },
    {
      accessor: 'finishTime',
      Header: intl.formatMessage({
        id: 'dateCreated',
        defaultMessage: 'Fecha Creado',
      }),
      width: 140,
      Cell: (props) => <CellTable>{props.value}</CellTable>,
    },
    {
      accessor: 'processDate',
      Header: intl.formatMessage({
        id: 'dateProcessed',
        defaultMessage: 'Fecha Procesado',
      }),
      width: 140,
      Cell: (props) => <CellTable>{props.value}</CellTable>,
    },
  ];

  const styles = StyleSheet.create({
    content: {
      display: 'flex',
      height: '100%',
      paddingBottom: '1rem',
      width: '100%',
    },
    postingContainer: {
      position: 'relative',
      width: '100%',
      height: 'calc(100% - 3rem)',
      margin: '1.5rem 0 .5rem',
      padding: '.5rem .5rem 1.5rem .5rem',
    },
  });

  return (
    <div className={css(styles.postingContainer)}>
      <h2 className="text-left text-lg mb-3 font-normal">
        <FormattedMessage id="postingsSidebar" defaultMessage={`Visor de asientos`} />
      </h2>

      <div className={css(styles.content)}>
        <div className="w-64 h-full">
          <PostingSearcher closeWindow={() => props.setShowSearcher(false)} searchPostings={handleSearchPostings} dataSearcher={dataSearcher} />
        </div>
        <div className="flex-grow mr-4 rounded border border-light flex">
          <PostingListView
            data={rows}
            columns={columsData}
            loading={loading}
            LoadingComponent={LoaderComponent}
            defaultPageSize={30}
            className="-striped -highlight bg-grey-lighter-lighter"
            noDataText={
              <p className="text-sm w-full text-center leading-normal">
                <FormattedMessage id="noPostingsFound" defaultMessage={`No se encontraron asientos`} />
                <br />
                <FormattedMessage id="updateFiltersPerformNewSearch" defaultMessage={`Actualice los filtros para realizar una nueva búsqueda`} />
              </p>
            }
            intl={intl}
            clickHandler={async (e, rowID) => {
              handlePostingManager(rows[rowID], bodyGetPostings);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, storeActions)(PostingList);
