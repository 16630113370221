import React from 'react';
import IcoMoon from 'react-icomoon';

export const PAGE_SIZE = 10;

const ColumnUsers = ({ values }) => (
  <div className="flex flex-col">
    {values.map((val, index) => (
      <span key={index}>{val}</span>
    ))}
  </div>
);

const PageComponent = (props) => (
  <IcoMoon {...props} icon={props.icon} width="25" className={`${props.className} ${props.disabled ? 'text-light' : ''} cursor-pointer`} />
);

export const columnsTableParent = (keysPrompt, showState, intl) => {
  let keysPmpt = Object.keys(keysPrompt);
  let columsKeys = keysPmpt.map((k) => ({ Header: keysPrompt[k], accessor: 'keys', searchIndicator: k }));

  if (showState) {
    columsKeys.push({ Header: intl.formatMessage({ id: 'state', defaultMessage: 'Estado' }), accessor: 'currentState' });
  }

  return [
    ...columsKeys,
    {
      Header: intl.formatMessage({ id: 'assigned', defaultMessage: 'Asignado' }),
      accessor: 'assigned',
    Cell: props => <ColumnUsers values={props.value}/> },// eslint-disable-line
    {
      Header: intl.formatMessage({ id: 'solvedBy', defaultMessage: 'Resuelto por' }),
      accessor: 'responsible',
    Cell: props => <ColumnUsers values={props.value}/> },// eslint-disable-line
    { Header: intl.formatMessage({ id: 'startDate', defaultMessage: 'Fecha inicio' }), accessor: 'startDate' },
    { Header: intl.formatMessage({ id: 'endDate', defaultMessage: 'Fecha fin' }), accessor: 'endDate' },
  ];
};

export const columnsTableChild = (variablesMapped) =>
  variablesMapped.map((value) => ({ Header: value.mappedName, accessor: value.variableName, childIndicator: value.variableName }));

export const dateFormatSearcher = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${day}/${month}/${year}`;
};

export const Pagination = ({ bottomIndexPage, changeBottomIndexPage }) => (
  <div className="flex justify-center m-2">
    <div className="mr-4">
      <PageComponent
        icon="arrow-left"
        onClick={() => {
          if (bottomIndexPage === 0) return;
          changeBottomIndexPage(bottomIndexPage - PAGE_SIZE);
        }}
        disabled={bottomIndexPage === 0}
      />
    </div>
    <div className="ml-4">
      <PageComponent
        icon="arrow-right"
        onClick={() => {
          changeBottomIndexPage(bottomIndexPage + PAGE_SIZE);
        }}
      />
    </div>
  </div>
);
