import moment from 'moment';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { getGroupInstanceHeaders, getGroupProcessInstance, resumeProcessInstance, stopProcessInstance } from '../../../../api/processesManager';
import { THIS_WEEK, TODAY, UNDEFINED_PROCESS, YESTERDAY } from '../../models/constants';
import { GroupInstance } from '../../models/groupInstance';

import '../../ProcessesManager.scss';
import { ExecutionHistoryView } from './ExecutionHistoryView';

export interface InstanceHeaders {
  groupName: string;
  instances: GroupInstance[];
}

export function ExecutionHistoryViewModel(): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groupsInstances, setGroupsInstances] = useState<InstanceHeaders[]>();
  const [selectedInstance, setOnSelectedInstance] = useState<GroupInstance>();
  const [dateFilter, setDateFilter] = useState<string>(moment().format('DD/MM/YYYY'));
  const [selectedDate, setSelectedDate] = useState<string>(TODAY);
  const [refreshInstance, setRefreshInstace] = useState<GroupInstance>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  function showModal(): void {
    setIsModalOpen(true);
  }

  function onModalChange(isOpenModal: boolean): void {
    setIsModalOpen(isOpenModal);
  }

  async function refreshProcessAsync(): Promise<void> {
    let updatedInstances = groupsInstances || [];
    updatedInstances = await Promise.all(
      updatedInstances.map(async (groupInstance) => {
        groupInstance.instances = await Promise.all(groupInstance.instances.map(mapInstancesToUpdate));

        return groupInstance;
      }),
    );

    setGroupsInstances(updatedInstances);
  }

  async function mapGroupOfInstance(instance: GroupInstance): Promise<GroupInstance> {
    let mappedGroup: GroupInstance = instance;
    mappedGroup.processInstances = await Promise.all(instance.processInstances.map(mapInstancesToUpdate));

    return mappedGroup;
  }

  async function mapInstancesToUpdate(process: GroupInstance): Promise<GroupInstance> {
    if (process.executionId === refreshInstance?.executionId) {
      return await refreshIdAsync(refreshInstance?.groupExecutionId ? refreshInstance?.groupExecutionId : refreshInstance?.executionId);
    } else if (process.executionId === refreshInstance?.groupExecutionId) {
      return await mapGroupOfInstance(process);
    } else {
      return process;
    }
  }

  async function refreshIdAsync(id: number): Promise<GroupInstance> {
    setIsLoading(true);
    const refreshedProcess: GroupInstance = await getIdDataAsync(id);
    setIsLoading(false);

    return refreshedProcess;
  }

  async function getGroupsHeadersAsync(): Promise<void> {
    setIsLoading(true);
    const response: InstanceHeaders[] = await getGroupInstanceHeaders(dateFilter);
    setIsLoading(false);
    loadChildrenHeadersAsync(response);
  }

  async function loadChildrenHeadersAsync(headers: InstanceHeaders[]): Promise<void> {
    const mappedData: InstanceHeaders[] = await Promise.all(
      headers.map<Promise<InstanceHeaders>>(async (group) => ({
        groupName: group.groupName,
        instances: await Promise.all(mapInstancesAsync(group.instances)),
      })),
    );

    setGroupsInstances(mappedData);
  }

  function mapInstancesAsync(instances: GroupInstance[]): Promise<GroupInstance>[] {
    return instances.map<Promise<GroupInstance>>(async (instance) => getIdDataAsync(instance.executionId));
  }

  async function getIdDataAsync(id: number): Promise<GroupInstance> {
    setIsLoading(true);
    const response = await getGroupProcessInstance(id);
    setIsLoading(false);
    loadStates(response.processInstances, response.state);
    return response;
  }

  function loadStates(group: GroupInstance[], state: string): void {
    group?.map((process) => {
      if (process.state === UNDEFINED_PROCESS) {
        process.state = state;
      }
      loadStates(process.processInstances, process.state);
    });
  }

  function onSelectInstance(instance: GroupInstance): void {
    setOnSelectedInstance(instance);
  }

  function onSelectedChange(date: string): void {
    setSelectedDate(date);
    const today = moment();

    if (date === TODAY) {
      setDateFilter(today.format('DD/MM/YYYY'));
    } else if (date === YESTERDAY) {
      setDateFilter(today.subtract(1, 'days').format('DD/MM/YYYY'));
    } else if (date === THIS_WEEK) {
      setDateFilter(today.subtract(7, 'days').format('DD/MM/YYYY'));
    } else {
      setDateFilter(today.subtract(30, 'days').format('DD/MM/YYYY'));
    }
  }

  function onRefreshInstanceChange(instanceToRefresh: GroupInstance): void {
    setRefreshInstace(instanceToRefresh);
  }

  function getErrorMessage(): string {
    return errorMessage;
  }

  function onStopInstance(instance: GroupInstance): void {
    stopInstanceAsync(instance.groupExecutionId);
    onRefreshInstanceChange(instance);
  }

  async function stopInstanceAsync(instanceId: number): Promise<void> {
    setIsLoading(true);
    const ret = await stopProcessInstance(instanceId);
    if (ret && ret.response && ret.response.data && ret.response.data.mensaje) {
      setErrorMessage(ret.response.data.mensaje);
      showModal();
    }
    setIsLoading(false);
  }

  function onResumeInstance(instance: GroupInstance): void {
    resumeInstanceAsync(instance.groupExecutionId);
    onRefreshInstanceChange(instance);
  }

  async function resumeInstanceAsync(instanceId: number): Promise<void> {
    setIsLoading(true);
    const ret = await resumeProcessInstance(instanceId);
    if (ret && ret.response && ret.response.data && ret.response.data.mensaje) {
      setErrorMessage(ret.response.data.mensaje);
      showModal();
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getGroupsHeadersAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  useEffect(() => {
    refreshProcessAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshInstance]);

  return (
    <ExecutionHistoryView
      selectedInstance={selectedInstance}
      isLoading={isLoading}
      selectedDate={selectedDate}
      onSelectInstance={onSelectInstance}
      onSelectedChange={onSelectedChange}
      groupsInstances={groupsInstances}
      onRefreshInstanceChange={onRefreshInstanceChange}
      onStopInstance={onStopInstance}
      onResumeInstance={onResumeInstance}
      getErrorMessage={getErrorMessage}
      isModalOpen={isModalOpen}
      onModalChange={onModalChange}
    />
  );
}
