import { useState, useEffect, useCallback } from 'react';

const useResizable = ({ defaultWidth, defaultHeight }) => {
  const [width, setWidth] = useState(defaultWidth);
  const [height, setHeight] = useState(defaultHeight);
  const [isResizing, setIsResizing] = useState(false);

  const onMouseDownResizable = (e) => {
    setIsResizing(true);
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);

      return () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
      };
    } else {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    }
  }, [height, isResizing, onMouseMove, width]);

  const onMouseMove = useCallback(
    (e) => {
      if (isResizing) {
        setWidth(width + e.movementX);
        setHeight(height + e.movementY);
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [height, isResizing, width],
  );

  const onMouseUp = (e) => {
    setIsResizing(false);
    // window.removeEventListener('mousemove', onMouseMove);
    // window.removeEventListener('mouseup', onMouseUp);
    e.stopPropagation();
    e.preventDefault();
  };

  return {
    onMouseDownResizable,
    width: width + 'px',
    height: height + 'px',
  };
};

export default useResizable;
