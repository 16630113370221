import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { FormattedMessage } from 'react-intl';

import { storeActions, store } from '../../src/store';
import withOperationServices from '../../src/api/withOperationServices';
import {
  getCurrentTabInformation,
  getTabIndexByOperationID,
} from '../../src/selectors';
import { openReceivedReports } from '../../src/helpers/operationsHelper';

export class SimpleRequestNotice extends Component {
  componentDidMount() {
    let {
      finishOperation,
      cancelOperation,
      resultFinish,
      setCurrentTab,
      tabID,
      parentOperationID,
      parentTabId,
      resume,
      processContext,
      openGlobalModal,
      addEmptyTabReport,
      addTabReport,
      closeGlobalModal,
      tabsReport,
      tabs,
      nodeId,
      removeTabByNodeId,
    } = this.props;

    if (!store.getState().tabs[tabID]) {
      return;
    }

    if (finishOperation || cancelOperation) {
      openReceivedReports(
        openGlobalModal,
        addEmptyTabReport,
        addTabReport,
        closeGlobalModal,
        tabsReport,
        tabs,
        tabID,
      );
      removeTabByNodeId(nodeId);
      if (parentOperationID) {
        if (parentTabId !== -1) {
          setCurrentTab(parentTabId);
        }
        resume(parentOperationID, resultFinish).then((resumeData) => {
          if (Object.keys(resumeData).length) {
            processContext(resumeData, 'Accept dialog');
          }
        });
      }
    }
  }
  render() {
    let { style, finishOperation } = this.props;

    return (
      <div
        className="bg-white absolute"
        style={{
          ...style,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="flex">
          <div className="flex-auto text-center px-6 py-4 m-100 text-lg">
            {finishOperation && (
              <FormattedMessage
                id="finishingOperation"
                defaultMessage={`Finalizando operación...`}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    tabID,
    operationID,
    operationNumber,
    parentOperationID,
    parentOperationNumber,
  } = getCurrentTabInformation(state);

  let parentTabId = -1;

  if (parentOperationID && parentOperationNumber) {
    parentTabId = getTabIndexByOperationID(
      state,
      parentOperationID,
      parentOperationNumber,
    );
  }

  return {
    tabs: state.tabs,
    tabsReport: state.tabsReport,
    tabID,
    operationID,
    operationNumber,
    parentOperationID,
    parentTabId,
  };
};

export default connect(
  mapStateToProps,
  storeActions,
)(withOperationServices(SimpleRequestNotice));
