import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { useTheme } from '../../themes/ThemeContext';

const FilterRadio = ({ id, label, checked, onSelectFilter, styles }) => {
  return (
    <div className={css(styles.filterRadio)}>
      <input
        id={id}
        type="radio"
        checked={checked}
        onChange={() => onSelectFilter(id)}
      />
      <label
        className={css([styles.label, checked && styles.labelChecked])}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

const FilterSelector = ({ filters, filterSelected, onSelectFilter }) => {
  const themeState = useTheme();
  const { text } = themeState.theme;

  const styles = StyleSheet.create({
    filterSelector: {
      marginBottom: '1rem',
    },
    filterSelectorTitle: {
      color: text.text,
      fontWeight: 'normal',
      fontSize: '.75rem',
      marginBottom: '.25rem',
      userSelect: 'none',
    },
    filterRadio: {
      display: 'flex',
      marginBottom: '.5rem',
      fontSize: '.875rem',
    },
    label: {
      marginLeft: '.4rem',
      color: text.text,
      ':active': {
        color: text.dark,
      },
      userSelect: 'none',
    },
    labelChecked: {
      color: text.dark,
    },
  });

  return (
    <div className={css(styles.filterSelector)}>
      <h2 className={css(styles.filterSelectorTitle)}>Filtro:</h2>
      {filters &&
        filters.map((filter) => (
          <FilterRadio
            key={filter.id}
            id={filter.id}
            label={filter.label}
            checked={filter.id === filterSelected}
            onSelectFilter={onSelectFilter}
            styles={styles}
          />
        ))}
    </div>
  );
};

export default FilterSelector;
