import React, { Component } from 'react';
import 'react-table/react-table.css';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getBranchGroups } from '../api/postingManager';
import { dateRunFormat, formatDateFromServer } from '../../UI-kit/helpers/dateHelper';
import { store } from '../store';

const FieldData = ({ title, children }) => {
  return (
    <div className="mb-3">
      <label className="block text-xs mb-1">{title}</label>
      {children}
    </div>
  );
};

const options = [
  {
    value: 65,
    defaultMessage: 'Pendiente de autorización',
    id: 'pendingAuthorization',
  },
  {
    value: 67,
    defaultMessage: 'A confirmar',
    id: 'toConfirm',
  },
  {
    value: 68,
    defaultMessage: 'A confirmar diferido',
    id: 'toConfirmDeferred',
  },
  {
    value: 42,
    defaultMessage: 'A dar de baja',
    id: 'toUnsubscribe',
  },
  {
    value: 66,
    defaultMessage: 'Todos',
    id: 'all',
  },
];

export class PostingSearcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWindow: true,
      statePostings: 65,
      branchs: null,
      branchSelected: '',
      allRecords: true,
      userInitials: '',
      machineNumber: '',
      postingNumberFrom: '',
      postingNumberTo: '',
      operationNumber: '',
      descriptionText: '',
      processDate: null,
    };
    this.handleStatePostings = this.handleStatePostings.bind(this);
    this.fetchBranchsData = this.fetchBranchsData.bind(this);
    this.handleBranchs = this.handleBranchs.bind(this);
    this.handleAllRecords = this.handleAllRecords.bind(this);
    this.handleUserInitials = this.handleUserInitials.bind(this);
    this.handleMachineNumber = this.handleMachineNumber.bind(this);
    this.handlePostingNumberFrom = this.handlePostingNumberFrom.bind(this);
    this.handlePostingNumberTo = this.handlePostingNumberTo.bind(this);
    this.handleOperationNumber = this.handleOperationNumber.bind(this);
    this.handleDescriptionText = this.handleDescriptionText.bind(this);
    this.handleProcessDate = this.handleProcessDate.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    const { pendingOfSearcher, bodySearcher } = this.props.dataSearcher;

    if (pendingOfSearcher && bodySearcher) {
      let { allRecords, postingBegin, postingEnd, description, user, machineNumber, operationNumber, branchGroup, processDate } = bodySearcher;

      let dateValue = '';
      if (processDate !== null) {
        try {
          dateValue = new Date(formatDateFromServer(processDate));
        } catch (ex) {
          console.error(ex);
        }
      }

      this.setState({
        statePostings: pendingOfSearcher.toString(),
        branchSelected: branchGroup === null || allRecords ? '' : branchGroup.toString(),
        allRecords,
        userInitials: user === null ? '' : user.toString(),
        machineNumber: machineNumber === null ? '' : machineNumber.toString(),
        postingNumberFrom: postingBegin === null ? '' : postingBegin.toString(),
        postingNumberTo: postingEnd === null ? '' : postingEnd.toString(),
        operationNumber: operationNumber === null ? '' : operationNumber.toString(),
        descriptionText: description === null ? '' : description.toString(),
        processDate: dateValue,
      });
    }

    this.fetchBranchsData();
    this.handleSearch();
  }

  async fetchBranchsData() {
    const data = await getBranchGroups();
    if (data && data.length > 0) {
      this.setState({
        branchs: data,
        branchSelected: data[0].sucursal,
      });
    }
  }

  handleStatePostings(e) {
    this.setState({ statePostings: e.target.value });
  }

  handleBranchs(e) {
    this.setState({ branchSelected: e.target.value });
  }

  handleAllRecords(e) {
    this.setState({ allRecords: e.target.checked });
  }

  handleUserInitials(e) {
    this.setState({ userInitials: e.target.value });
  }

  handleMachineNumber(e) {
    this.setState({ machineNumber: e.target.value });
  }

  handlePostingNumberFrom(e) {
    this.setState({ postingNumberFrom: e.target.value });
  }

  handlePostingNumberTo(e) {
    this.setState({ postingNumberTo: e.target.value });
  }

  handleOperationNumber(e) {
    this.setState({ operationNumber: e.target.value });
  }

  handleDescriptionText(e) {
    this.setState({ descriptionText: e.target.value });
  }

  handleProcessDate(date) {
    this.setState({ processDate: date });
  }

  handleSearch() {
    const {
      statePostings,
      branchSelected,
      allRecords,
      userInitials,
      machineNumber,
      postingNumberFrom,
      postingNumberTo,
      operationNumber,
      descriptionText,
      processDate,
    } = this.state;
    const { searchPostings } = this.props;
    let pendingOf = parseInt(statePostings, 10);
    let body = {
      allRecords,
      postingBegin: postingNumberFrom === '' ? null : parseInt(postingNumberFrom, 10),
      postingEnd: postingNumberTo === '' ? null : parseInt(postingNumberTo, 10),
      description: descriptionText === '' ? null : descriptionText,
      user: userInitials === '' ? null : userInitials,
      machineNumber: machineNumber === '' ? null : parseInt(machineNumber, 10),
      operationNumber: operationNumber === '' ? null : parseInt(operationNumber, 10),
      branchGroup: branchSelected === '' || allRecords ? null : parseInt(branchSelected, 10),
      processDate: processDate === '' || processDate === null ? null : dateRunFormat(processDate),
    };
    if (pendingOf === 42) {
      pendingOf = 66;
      body.allRecords = false;
    }
    let state = store.getState();

    if (state.postingFilter === undefined || state.postingFilter === null) {
      searchPostings(pendingOf, body, statePostings);
    }
  }
  render() {
    const {
      branchs,
      branchSelected,
      allRecords,
      userInitials,
      machineNumber,
      postingNumberFrom,
      postingNumberTo,
      operationNumber,
      descriptionText,
      processDate,
    } = this.state;

    const { intl } = this.props;

    const renderRadioButtons = options.map((option, index) => {
      let { statePostings } = this.state;
      return (
        <div key={index} className="flex content-center mb-2 text-sm">
          <input
            type="radio"
            value={option.value}
            checked={statePostings === option.value || statePostings === '' + option.value}
            className="focus:shadow-sm-light"
            onChange={this.handleStatePostings}
            id={`type${index}`}
          />
          <label htmlFor={`type${index}`} className="ml-2">
            <FormattedMessage id={option.id} defaultMessage={option.defaultMessage} />
          </label>
        </div>
      );
    });

    const fromText = intl.formatMessage({
      id: 'from',
      defaultMessage: 'Desde',
    });

    const toText = intl.formatMessage({
      id: 'to',
      defaultMessage: 'Hasta',
    });

    return (
      <React.Fragment>
        <div className="bg-grey-lighter-lighter mr-4 p-3 h-full">
          <FieldData
            title={`${intl.formatMessage({
              id: 'state',
              defaultMessage: 'Estado',
            })}:`}
          >
            <div className="flex flex-wrap justify-between flex-col h-full">{renderRadioButtons}</div>
          </FieldData>
          <FieldData
            title={`${intl.formatMessage({
              id: 'branch',
              defaultMessage: 'Sucursal',
            })}:`}
          >
            <input id="all" type="checkbox" checked={allRecords} value={allRecords} onChange={this.handleAllRecords} />
            <label htmlFor="all" className="inline-block text-sm ml-1 mb-2">
              <FormattedMessage id="all" defaultMessage={`Todas`} />
            </label>
            <select
              className={`${allRecords ? 'hidden' : ''} rounded-none text-sm align-middle bg-white w-full`}
              onChange={this.handleBranchs}
              value={branchSelected}
              disabled={allRecords}
            >
              {branchs &&
                Array.isArray(branchs) &&
                branchs.map((branch, key) => (
                  <option key={key} value={branch.sucursal}>
                    {branch.sucursal} - {branch.descripcion}
                  </option>
                ))}
            </select>
          </FieldData>
          <FieldData
            title={`${intl.formatMessage({
              id: 'userInitials',
              defaultMessage: 'Iniciales del Usuario',
            })}:`}
          >
            <input className="text-sm w-full" type="text" value={userInitials} onChange={this.handleUserInitials} />
          </FieldData>
          <FieldData
            title={`${intl.formatMessage({
              id: 'machineNumber',
              defaultMessage: 'Número de Maquina',
            })}:`}
          >
            <NumberFormat type={'text'} onChange={this.handleMachineNumber} className={'appearance-none text-sm w-full'} value={machineNumber} />
          </FieldData>
          <FieldData
            title={`${intl.formatMessage({
              id: 'postingNumber',
              defaultMessage: 'Número de Asiento',
            })}:`}
          >
            <div className="mb-2 flex items-center justify-between">
              <div className="w-24 overflow-hidden">
                <NumberFormat
                  id="asientoDesde"
                  className="w-full appearance-none text-sm"
                  type="text"
                  value={postingNumberFrom}
                  onChange={this.handlePostingNumberFrom}
                  placeholder={fromText}
                />
              </div>
              <div className="w-24 overflow-hidden">
                <NumberFormat
                  id="asientoHasta"
                  className="w-full appearance-none text-sm"
                  type="text"
                  value={postingNumberTo}
                  onChange={this.handlePostingNumberTo}
                  placeholder={toText}
                />
              </div>
            </div>
          </FieldData>
          <FieldData
            title={`${intl.formatMessage({
              id: 'operationNumber',
              defaultMessage: 'Número de Operación',
            })}:`}
          >
            <NumberFormat type={'text'} onChange={this.handleOperationNumber} className={'appearance-none text-sm w-full'} value={operationNumber} />
          </FieldData>
          <FieldData
            title={`${intl.formatMessage({
              id: 'textDescription',
              defaultMessage: 'Texto en Descripción',
            })}:`}
          >
            <input className="text-sm w-full" type="text" value={descriptionText} onChange={this.handleDescriptionText} />
          </FieldData>
          <FieldData
            title={`${intl.formatMessage({
              id: 'dateProcess',
              defaultMessage: 'Fecha de Proceso',
            })}:`}
          >
            <DatePicker
              onChange={(date) => {
                this.handleProcessDate(date);
              }}
              className={`w-full rounded-sm text-sm`}
              selected={processDate}
              dateFormat={'dd/MM/yyyy'}
            />
          </FieldData>
          <div>
            <button className="bg-grey hover:bg-primary text-white rounded py-2 px-4 text-sm" id="btnBuscar" onClick={this.handleSearch}>
              <FormattedMessage id="search" defaultMessage={`Buscar`} />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(PostingSearcher);
