import { PulseLoader } from 'halogenium';
import React from 'react';
import { useIntl } from 'react-intl';
import QuestionMarkIcon from '../../../questionMark/QuestionMark';

import './FormulaModal.scss';

export interface FormulaModalViewProps {
  onCancel: (event: React.MouseEvent) => void;
  onAccept: (event: React.MouseEvent) => void;
  onChange: (event: { target: HTMLInputElement }) => void;
  isLoading: boolean;
  formula: string[];
  description: string[];
  allower: string[];
  onGroupHelperClick: (event: React.MouseEvent) => void;
}

export function FormulaModalView({
  onCancel,
  onAccept,
  isLoading,
  onChange,
  formula,
  description,
  allower,
  onGroupHelperClick,
}: FormulaModalViewProps): JSX.Element | null {
  const intl = useIntl();

  return (
    <div className="font-mono formula-modal z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex justify-center">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        {isLoading ? (
          <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
            <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
          </div>
        ) : (
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border shadow-sm">
            <div className="bg-blue-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg text-center leading-6 font-medium text-gray-900" id="modal-title">
                    {intl.formatMessage({ id: 'formulaEntry' })}
                  </h3>
                  <div className="flex flex-col mt-6">
                    <div className="m-2 flex items-center mb-3 justify-between">
                      <label className="pr-3 text-sm">{intl.formatMessage({ id: 'formula' })}</label>
                      <input value={formula} onChange={onChange} name="formula" className="input border border-grey-lighter" />
                    </div>
                    <div className="m-2 flex items-center mb-3 justify-between">
                      <label className="pr-1  text-sm">{intl.formatMessage({ id: 'description' })}</label>
                      <input value={description} onChange={onChange} name="description" className="input border border-grey-lighter" />
                    </div>
                    <div className="m-2 flex items-center mb-3 justify-between">
                      <label className="pr-1  text-sm">{intl.formatMessage({ id: 'authorizedBy' })}</label>
                      <input value={allower} disabled={true} onChange={onChange} name="allower" className="input border border-grey-lighter" />
                      <QuestionMarkIcon disabled={formula[0] === ''} className="button-helper-formula" click={onGroupHelperClick} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-center bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                onClick={onAccept}
                type="button"
                className="w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium text-gray-700 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {intl.formatMessage({ id: 'accept' })}
              </button>
              <button
                onClick={onCancel}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {intl.formatMessage({ id: 'cancel' })}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
