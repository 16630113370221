import * as React from 'react';
import { useIntl } from 'react-intl';
import TreeMenuWrapper, { ItemComponent, Item } from 'react-simple-tree-menu';
import closedFolderIcon from '../../../icons/closedFolder';
import errorFolderIcon from '../../../icons/errorFolder';
import okFolderIcon from '../../../icons/okFolder';
import openedFolderIcon from '../../../icons/openedFolder';
import { ABORTED_PROCESS, FINISHED_PROCESS, FIRST_LEVEL_NODE, ROOT_LEVEL_NODE, RUNNING_PROCESS, STOPPED_PROCESS } from '../../../models/constants';
import { GroupInstance } from '../../../models/groupInstance';
import { ContextMenuTrigger } from 'react-contextmenu';

import './ExecutionHistoryMenu.scss';
import { GroupToTree } from './ExecutionHistoryMenuViewModel';
import forkFolderIcon from '../../../icons/forkFolder';
import runningProcessIcon from '../../../icons/runningProcess';
import { ContextMenuOptions } from '../contextMenu/ContextMenuOptions';
import { useContext } from 'react';
import { ForkContext } from '../../../ProcessesManagerViewModel';
import { isAForkProcess } from '../../../helpers/checkForkProcess';

interface ExecutionHistoryMenuViewProps {
  treeData: GroupToTree[];
  onSelectInstance: (instance: GroupInstance) => void;
  selectedInstance?: GroupInstance;
  onRefreshInstanceChange: (instanceToRefresh: GroupInstance) => void;
  onModalMonitoringChange: (isOpen: boolean) => void;
  onStopInstance: (instanceToStop: GroupInstance) => void;
  onResumeInstance: (instanceToResume: GroupInstance) => void;
}

const SELECTED_NODE_COLOR: string = '#2149943d';

export function ExecutionHistoryMenuView({
  treeData,
  onSelectInstance,
  selectedInstance,
  onRefreshInstanceChange,
  onModalMonitoringChange,
  onStopInstance,
  onResumeInstance,
}: ExecutionHistoryMenuViewProps) {
  const intl = useIntl();
  const forks = useContext(ForkContext);

  function onClick(node: Item): void {
    node.level !== 0 && onSelectInstance(node.item);
  }

  function monitorInstance(event: React.MouseEvent, data: GroupInstance): void {
    event.stopPropagation();
    onSelectInstance(data);
    onModalMonitoringChange(true);
  }

  function stopInstance(event: React.MouseEvent, data: GroupInstance): void {
    event.stopPropagation();
    onSelectInstance(data);
    onStopInstance(data);
  }

  function resumeInstance(event: React.MouseEvent, data: GroupInstance): void {
    event.stopPropagation();
    onSelectInstance(data);
    onResumeInstance(data);
  }

  function refreshInstance(event: React.MouseEvent, data: GroupInstance): void {
    event.stopPropagation();
    onRefreshInstanceChange(data);
  }


  function onClickText(node: Item): void {
    node.onClick();
    if (node.toggleNode !== undefined) {
      node.toggleNode();
    }
  }

  return (
    <div className="history-container">
      <div className="title">{intl.formatMessage({ id: 'processes' })}</div>
      <TreeMenuWrapper onClickItem={onClick} data={treeData} hasSearch={false}>
        {({ items: nodes }) => (
          <ul className="tree-item-group">
            {nodes.map((node) => (
              <div key={node.key}>
                {node.level !== FIRST_LEVEL_NODE && node.level !== ROOT_LEVEL_NODE ? (
                  <div>
                    <ContextMenuTrigger id={node.key}>
                      <ItemComponent
                        {...node}
                        openedIcon={isAForkProcess(forks, node.item.name) ? forkFolderIcon : openedFolderIcon}
                        closedIcon={isAForkProcess(forks, node.item.name) ? forkFolderIcon : closedFolderIcon}
                        onClick={() => onClickText(node)}

                      />
                    </ContextMenuTrigger>
                    <ContextMenuOptions refreshInstance={refreshInstance} node={node} />
                  </div>
                ) : node.level !== FIRST_LEVEL_NODE ? (
                  <div>
                    <ContextMenuTrigger id={node.key}>
                      <ItemComponent {...node} openedIcon={openedFolderIcon} closedIcon={closedFolderIcon} onClick={() => onClickText(node)}
                      />
                    </ContextMenuTrigger>
                    <ContextMenuOptions refreshInstance={refreshInstance} node={node} />
                  </div>
                ) : node.state === FINISHED_PROCESS ? (
                  <div>
                    <ContextMenuTrigger id={node.key}>
                      <ItemComponent
                        {...node}
                        style={{ backgroundColor: `${selectedInstance && selectedInstance === node.item ? SELECTED_NODE_COLOR : ''}` }}
                        openedIcon={okFolderIcon}
                        closedIcon={okFolderIcon}
                        onClick={() => onClickText(node)}
                      />
                    </ContextMenuTrigger>
                    <ContextMenuOptions node={node} refreshInstance={refreshInstance} monitorInstance={monitorInstance} />
                  </div>
                ) : node.state === RUNNING_PROCESS ? (
                  <div>
                    <ContextMenuTrigger id={node.key}>
                      <ItemComponent
                        {...node}
                        style={{ backgroundColor: `${selectedInstance && selectedInstance === node.item ? SELECTED_NODE_COLOR : ''}` }}
                        openedIcon={runningProcessIcon}
                        closedIcon={runningProcessIcon}
                        onClick={() => onClickText(node)}
                      />
                    </ContextMenuTrigger>
                    <ContextMenuOptions node={node} refreshInstance={refreshInstance} stopInstance={stopInstance} monitorInstance={monitorInstance} />
                  </div>
                ) : node.state === STOPPED_PROCESS ? (
                  <div>
                    <ContextMenuTrigger id={node.key}>
                      <ItemComponent
                        {...node}
                        style={{ backgroundColor: `${selectedInstance && selectedInstance === node.item ? SELECTED_NODE_COLOR : ''}` }}
                        openedIcon={errorFolderIcon}
                        closedIcon={errorFolderIcon}
                        onClick={() => onClickText(node)}
                      />
                    </ContextMenuTrigger>
                    <ContextMenuOptions
                      node={node}
                      refreshInstance={refreshInstance}
                      resumeInstance={resumeInstance}
                      monitorInstance={monitorInstance}
                    />
                  </div>
                ) : (
                  node.state === ABORTED_PROCESS && (
                    <div>
                      <ContextMenuTrigger id={node.key}>
                        <ItemComponent
                          {...node}
                          style={{ backgroundColor: `${selectedInstance && selectedInstance === node.item ? SELECTED_NODE_COLOR : ''}` }}
                          openedIcon={errorFolderIcon}
                          closedIcon={errorFolderIcon}
                          onClick={() => onClickText(node)}
                        />
                      </ContextMenuTrigger>
                      <ContextMenuOptions node={node} refreshInstance={refreshInstance} monitorInstance={monitorInstance} />
                    </div>
                  )
                )}
              </div>
            ))}
          </ul>
        )}
      </TreeMenuWrapper>
    </div>
  );
}
