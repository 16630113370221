/* eslint-disable standard/computed-property-even-spacing */
import _ from 'busyman';
import React from 'react';
import browserStorage from './utils/browserStorage';

import listToTree from '../renderer/lib/listToTree';
import { getChecks as getChecksAPI } from './api/clearing';
import { getReports, printForms, getOperationEndedForm, getReportGenerationSpec, getReport, printerRaw } from './api/report';
import { getLogs } from './api/topazLog';
import { getOperations, freeOperationInfo, resume } from './api/operation';
import { getSessionInfo } from './api/session';
import { transformOperationsData, transformReportsData } from './services/transformDataFromAPI';
import { initialState, store } from './store';
import { saveRecentOperation, saveRecentReport } from './utils/recentValues';
import { getTransaction } from './api/postingManager';
import { FormattedMessage } from 'react-intl';
import { getTabIndexByOperationID, tabIndexOf, getTabInformation, getTabIdByNodeId } from './selectors';
import { v4 as uuidv4 } from 'uuid';
import { bodyBuildReports } from '../UI-kit/helpers/reportsHelper';
import { PATHNAME } from '../src/config';
import { getLogger } from '../src/logger';
import {
  generateLineIDString,
  getTransactionFromLineIDString,
  getLineFromLineIDString,
  getIterationCountFromLineIDString,
  getNumberFromDialogKey,
} from '../UI-kit/helpers/operationHelper';

// const debugMode = true;

export const DEFAULT_OPERATION_NUMBER = -1;
export const DEFAULT_REPORT_NUMBER = -1;
const SIZE_BRANCH_NUMBER = 5;
const SIZE_MACHINE_NUMBER = 4;

// REFACTORY TO DO : UI
export const toggleSidebar = (state, isCollapsed = null) => {
  const newState = { ...state };
  if (isCollapsed !== null) {
    newState.sidebarIsCollapsed = isCollapsed;
  } else if (newState.sidebarIsCollapsed !== undefined) {
    newState.sidebarIsCollapsed = !newState.sidebarIsCollapsed;
  } else {
    newState.sidebarIsCollapsed = false;
  }
  return newState;
};

// REFACTORY TO DO : OP
export const setCurrentApp = (state, application) => {
  const newState = { ...state };
  newState.currentApp = application;
  return newState;
};

const createOperation = (operationID) => ({
  operationID,
  trees: [],
  stateByLineID: {},
  grids: {},
  debug: [],
  focusOn: [],
  focusElement: '',
  lastFocusElement: '',
  valueSetElement: [],
  focusPaused: false,
});

export const reset = (state) => {
  return initialState();
};

// REFACTORY TO DO : TABS
export const addEmptyTab = (state, { openInmediatlyOnRight = false } = {}) => {
  let newState = { ...state };
  const current = newState.current;
  let allTabs = newState.tabs;
  const newTab = {
    id: uuidv4(),
    operationName: 'Nueva PestaÃ±a',
    operationNumber: DEFAULT_OPERATION_NUMBER,
    emptyTab: true,
    hiddenTab: false,
  };

  if (openInmediatlyOnRight) {
    const leftAndCurrent = allTabs.slice(0, current + 1);
    const right = allTabs.slice(current + 1);
    allTabs = [...leftAndCurrent, newTab, ...right];
  } else {
    allTabs = [...allTabs, newTab];
  }

  newState.tabs = allTabs;
  newState.current = openInmediatlyOnRight ? current + 1 : allTabs.length - 1;

  return newState;
};

export const addTab = (
  state,
  operationNumber,
  operationName,
  postingNumber = '',
  saveRecent = true,
  operationLook = false,
  subOperation = false,
  inputParameters = null,
  serverTasks = null,
  synchronize = false,
  toChain = false,
  overwritePosition = false,
  parentTabId = null,
  parentOperationID = null,
  parentOperationNumber = null,
  loadingBegin = true,
  TZPCAPTParameters = null,
) => {
  const newState = { ...state };
  const currentTab = state.current;
  const { id } = newState.tabs[currentTab];
  newState.tabs[currentTab] = {
    emptyTab: false,
    hiddenTab: null,
    id,
    inputParameters,
    loadingBegin,
    lockedByTab: null,
    operationNumber,
    operationName,
    operationLook,
    overwritePosition,
    parentTabId,
    postingNumber,
    parentOperationID,
    parentOperationNumber,
    subOperation,
    serverTasks,
    synchronize,
    toChain,
    TZPCAPTParameters,
  };

  const indexOfParentTab = tabIndexOf({ tabId: parentTabId }, newState.tabs);

  if (overwritePosition) {
    // this new tab hides its parent tab
    newState.tabs[indexOfParentTab] = {
      ...newState.tabs[indexOfParentTab],
      hiddenTab: true,
    };
  }

  if (synchronize) {
    // this new tab locks its parent tab
    newState.tabs[indexOfParentTab] = {
      ...newState.tabs[indexOfParentTab],
      lockedByTab: id,
    };
  }

  if (saveRecent) {
    saveRecentOperation(operationNumber, operationName, operationLook);
  }

  return newState;
};

export const hideTab = (state, tabId) => {
  const newState = { ...state };
  newState.tabs[tabId].hiddenTab = true;

  return newState;
};

export const removeTab = (state, tabIndex = state.current, { breakFree = true, removeAllAssociatedTabs = true } = {}) => {
  let newState = { ...state };
  newState.tabs = [...state.tabs];
  let currentAssigned = false;

  const toDeleteIndex = tabIndex !== undefined && tabIndex !== null ? tabIndex : state.current;

  if (!newState.tabs[toDeleteIndex]) return newState;

  const tabToRemove = newState.tabs[toDeleteIndex];
  if (tabToRemove.lockedByTab !== undefined && tabToRemove.lockedByTab !== null) {
    // this tab cannot be closed, because it is locked by another tab
    return newState;
  } else if (tabToRemove.synchronize) {
    // the tab that this tab was locking will be unlocked
    const lockedTabIndex = tabIndexOf({ tabId: tabToRemove.parentTabId }, newState.tabs);
    newState.tabs[lockedTabIndex].lockedByTab = null;
    newState.current = lockedTabIndex;
    currentAssigned = true;
  } else if (tabToRemove.overwritePosition) {
    const hiddenTabIndex = tabIndexOf({ tabId: tabToRemove.parentTabId }, newState.tabs);
    // the tab that this tab was hidding will be removed too.
    newState.tabs[hiddenTabIndex].hiddenTab = false;
  }

  const operationRunningInfo = newState.tabs[toDeleteIndex].running;

  if (breakFree && operationRunningInfo && operationRunningInfo.operationID) {
    freeOperationInfo(operationRunningInfo.operationID);
    newState.variableRowFields = [];
  }

  newState.tabs = [...newState.tabs.slice(0, toDeleteIndex), ...newState.tabs.slice(toDeleteIndex + 1)];

  if (currentAssigned) {
    return newState;
  }

  if (tabToRemove.overwritePosition) {
    newState.current = tabIndexOf({ tabId: tabToRemove.parentTabId }, newState.tabs);

    if (removeAllAssociatedTabs) {
      newState = removeTab(newState, newState.current, {
        breakFree,
        removeAllAssociatedTabs,
      });
    }
  } else {
    const newCurrent = toDeleteIndex - 1;
    if (newCurrent === -1 && newState.tabs[0]) {
      newState.current = 0;
    } else {
      newState.current = newCurrent;
    }
    newState = correctCurrentTab(newState);
  }

  return newState;
};

export const removeTabByNodeId = (state, nodeId) => {
  let newState = { ...state };
  if (!newState.tabs) {
    return newState;
  }
  const tabIndex = newState.tabs.findIndex((tab) => {
    const { running } = tab;
    if (!running || !running.trees || !running.trees.length) {
      return false;
    }
    return running.trees.some((tree) => {
      return tree._id && tree._id === nodeId;
    });
  });

  if (tabIndex !== -1) {
    newState = removeTab(newState, tabIndex);
  }
  return newState;
};

export const setCurrentTab = (state, tabIndex) => {
  const newState = { ...state };
  if (newState.tabs[tabIndex] && newState.tabs[tabIndex].hiddenTab) {
    newState.tabs[tabIndex].hiddenTab = false;
  }
  if (newState.tabs[tabIndex]) {
    newState.current = tabIndex;
  }

  return newState;
};

export const correctCurrentTab = (state) => {
  const newState = state;
  let currentTabCorrect = false;
  while (!currentTabCorrect) {
    if (newState.tabs[newState.current] && newState.tabs[newState.current].hiddenTab) {
      if (newState.current > 0) {
        newState.current = newState.current - 1;
      } else {
        newState.current = newState.tabs.length - 1;
      }
    } else {
      currentTabCorrect = true;
    }
  }
  return newState;
};

export const setPostingNumberTab = async (state, tabID, postingNumber, operationName) => {
  const newState = { ...state };
  newState.tabs = [...state.tabs];

  const currentTab = tabID || state.current;
  if (newState.tabs[currentTab]) {
    newState.tabs[currentTab].postingNumber = postingNumber;
    newState.tabs[currentTab].operationName = operationName;
  }

  return newState;
};

export const runTab = (state, tabIndex, operationID = null) => {
  const newState = { ...state };
  newState.current = tabIndex;

  if (!newState.tabs[tabIndex]) return newState;

  if (!newState.tabs[tabIndex].running) {
    newState.tabs[tabIndex].running = createOperation(operationID);
    newState.tabs[tabIndex].operationCreated = true;
  }

  return newState;
};

export const setTabLoadingBegin = (state, tabId, loading) => {
  const newState = { ...state };
  const tabIndex = tabIndexOf({ tabId }, newState.tabs);
  if (newState.tabs[tabIndex]) {
    newState.tabs[tabIndex].loadingBegin = loading;
  }
};

// REFACTORY TO DO : STATELINEBYID
export const addFieldValue = (state, fields) => {
  const newState = { ...state };
  const currentTab = newState.current;
  const operationData = newState.tabs[currentTab].running;

  if (operationData) {
    operationData.stateByLineID = _.merge({}, operationData.stateByLineID, fields);
    let keysFields = Object.keys(fields);
    keysFields.forEach((keyField) => {
      if (operationData.stateByLineID[keyField]) {
        operationData.stateByLineID[keyField].value = fields[keyField].value;
      }
    });
  }

  return newState;
};

// REFACTORY TO DO : UI
const addOrRemoveFocus = (state, transactionLineLabel, shouldAddFocus, targetTabId = -1) => {
  const newState = { ...state };
  const tabId = targetTabId === -1 ? newState.current : targetTabId;
  if (!newState.tabs[tabId] || !newState.tabs[tabId].running) {
    return newState;
  }
  const operationData = newState.tabs[tabId].running;
  if (operationData) {
    operationData.focusOn = addOrRemoveFocusFromFocusOn(operationData.focusOn, transactionLineLabel, shouldAddFocus, targetTabId);
  }

  return newState;
};

const addOrRemoveFocusFromFocusOn = (focusOn, transactionLineLabel, shouldAddFocus) => {
  if (!focusOn) {
    return focusOn;
  }
  if (shouldAddFocus) {
    const previousStack = focusOn.filter((el) => el !== transactionLineLabel);
    focusOn = [transactionLineLabel, ...previousStack];
  } else {
    focusOn = [...focusOn].filter((el) => el !== transactionLineLabel);
  }

  return focusOn;
};

export const pushToWindowStack = (state, transactionLineLabel) => {
  return addOrRemoveFocus(state, transactionLineLabel, true);
};

export const removeFromWindowStack = (state, transactionLineLabel) => {
  return addOrRemoveFocus(state, transactionLineLabel, false);
};

// REFACTORY TO DO : TREEPOOL
export const removeTreeFields = (state, treeNumber) => {
  const newState = { ...state };
  const currentTab = newState.current;
  const operationData = newState.tabs[currentTab].running;

  const newStateByLineID = {};

  Object.keys(operationData.stateByLineID).forEach((key) => {
    if (!(operationData.stateByLineID[key].treeNumber === treeNumber)) {
      newStateByLineID[key] = operationData.stateByLineID[key];
    }
  });

  operationData.stateByLineID = newStateByLineID;

  return newState;
};

export const removeValuesFromTree = (state, treeNumber) => {
  const newState = { ...state };
  const currentTab = newState.current;
  const operationData = newState.tabs[currentTab].running;

  Object.keys(operationData.stateByLineID).forEach((key) => {
    if (operationData.stateByLineID[key].treeNumber === treeNumber) {
      if (operationData.stateByLineID[key].value !== undefined) {
        delete operationData.stateByLineID[key].value;
      }
    }
  });

  return newState;
};

// REFACTORY TO DO : STATELINEBYID
export const clearStateByNodeId = (state, nodeId) => {
  const newState = { ...state };
  const currentTab = newState.current;
  const { running } = newState.tabs[currentTab];
  const dialog = running.trees.find((tree) => tree.id === nodeId);
  const clearables = getClearableFieldKeysByDialog(dialog);
  if (clearables && clearables.length) {
    clearables.forEach((key) => delete running.stateByLineID[key]);
  }
  return newState;
};

const getClearableFieldKeysByDialog = (dialog) => {
  if (!dialog || !dialog._childs) {
    return {};
  }

  const isClearableInput = (data) => {
    const isInput = (inputType) => {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8].includes(inputType);
    };
    const { displayInfo, lineID } = data;
    const { inputType } = displayInfo;
    return (data['class-name'].includes('FieldToDisplayEvent') || data['class-name'].includes('FieldToInputEvent')) && !!lineID && isInput(inputType);
  };

  let clearableInputs = [];
  for (const key in dialog._childs) {
    if (isClearableInput(dialog._childs[key]._data)) {
      clearableInputs.push(generateLineIDString(dialog._childs[key]._data.lineID));
    }
  }
  return clearableInputs;
};

// REFACTORY TO DO : TREEPOOL
export const removeTreeNode = (state, transactionLineLabel, idNode) => {
  const newState = { ...state };
  const currentTab = newState.current;
  const operationData = newState.tabs[currentTab].running;

  /**
   * All trees are iterated to remove any nodes that
   * are stored more than once in the tree array
   */
  if (operationData) {
    operationData.trees.forEach((tree) => {
      const indexTreeNode = operationData.trees.findIndex((tree) => {
        if (idNode && tree._id === idNode) {
          return true;
        } else if (tree._data.lineID) {
          const lineIDKey = generateLineIDString(tree._data.lineID);
          if (lineIDKey === transactionLineLabel) {
            return true;
          }
        } else {
          return false;
        }
      });

      if (indexTreeNode !== -1) operationData.trees.splice(indexTreeNode, 1);
    });

    newState.tabs[currentTab].running = operationData;
  }
  return newState;
};

// REFACTORY TO DO : UI
export const goBackToDialog = (state, transactionLineLabel, numberDialog, elementsToRemove, tabIndex = null) => {
  let newState = { ...state };
  const index = tabIndex !== null ? tabIndex : newState.current;
  const operationData = newState.tabs[index].running;
  if (!operationData) return newState;
  const indexGoBack = operationData.trees.findIndex((tree) => {
    if (!tree._data.lineID) {
      return false;
    }
    const lineIDKey = generateLineIDString(tree._data.lineID);
    return transactionLineLabel === lineIDKey;
  });

  if (elementsToRemove) {
    const dialogGoBack = elementsToRemove || operationData.focusOn.slice(1, indexGoBack + 1);

    dialogGoBack.forEach((element) => {
      const treeDialog = operationData.trees.find((tree) => {
        if (!tree._data.lineID) {
          return false;
        }
        const lineIDKey = generateLineIDString(tree._data.lineID);
        return lineIDKey === element;
      });
      newState = removeTreeNode(newState, element);
      newState = removeFromWindowStack(newState, element);
      if (treeDialog) {
        newState = clearStateByNodeId(newState, treeDialog._data.idNode);
      }
      const idx = operationData.stateByLineID.openedDialogList.indexOf(element);
      if (idx > -1) operationData.stateByLineID.openedDialogList.splice(idx, 1);
    });
  } else if (indexGoBack !== -1 /* IF itemtodelete on dialog was received */) {
    const tree = operationData.trees[indexGoBack];
    const tlid = generateLineIDString(tree._data.lineID);
    newState = removeTreeNode(newState, tlid);
    newState = removeFromWindowStack(newState, tlid);
    if (tree) {
      newState = clearStateByNodeId(newState, tree._data.idNode);
    }
    const idx = operationData.stateByLineID.openedDialogList.indexOf(tlid);
    if (idx > -1) operationData.stateByLineID.openedDialogList.splice(idx, 1);
  }

  return newState;
};

// REFACTORY TO DO : UI
export const updateGridData = (state, gridID, gridData, nodeId) => {
  const newState = { ...state };
  let currentTab;

  const tabId = getTabIdByNodeId(state, nodeId);
  const { tabIndex } = getTabInformation(state, tabId);
  currentTab = state.tabs[tabIndex];
  if (!currentTab) {
    const currentIndex = newState.current;
    currentTab = newState.tabs[currentIndex];
  }

  if (!currentTab) {
    return newState;
  }

  const operationData = currentTab.running;
  if (!operationData) {
    return newState;
  }

  const { grids } = operationData;
  if (!grids[gridID]) {
    grids[gridID] = {};
  }

  grids[gridID] = {
    ...grids[gridID],
    ...gridData,
  };

  return newState;
};

export const removeFocusElement = (state) => {
  const newState = { ...state };
  const currentTab = newState.current;
  const operationData = newState.tabs[currentTab].running;
  if (operationData) {
    operationData.focusElement = '';
  }

  return newState;
};

export const pauseFocus = (state) => {
  const newState = { ...state };
  const currentTab = newState.current;
  if (newState.tabs[currentTab]) {
    const operationData = newState.tabs[currentTab].running;
    if (operationData) {
      operationData.focusPaused = true;
    }
  }

  return newState;
};

export const resumeFocus = (state) => {
  const newState = { ...state };
  const currentTab = newState.current;
  if (newState.tabs[currentTab] && newState.tabs[currentTab].running) {
    const operationData = newState.tabs[currentTab].running;
    if (operationData) {
      operationData.focusPaused = false;
    }
  }

  return newState;
};

export const reverseFlow = (state, cancel = true) => {
  const newState = { ...state };
  newState.tabs[newState.current].reverseFlow = cancel;
  return newState;
};

/**
 * Returns the incoming dialogs that already exists in operation
 */
const getDialogsToGoBack = (state, events, tabIndex) => {
  const newState = { ...state };
  const operationData = newState.tabs[tabIndex].running;

  const currentDialogs = operationData.trees
    .filter((item) => {
      return item && item._data && item._data.displayInfo && item._data.displayInfo.number;
    })
    .map((item) => {
      return {
        number: item._data.displayInfo.number,
        lineID: item._data.lineID,
        title: item._data.displayInfo.title,
      };
    });

  const incomingDialogs = events.filter((element) => element['class-name'].includes('ItemToDeleteEvent'));

  const dialogToGoBack = incomingDialogs.filter((i) => {
    return currentDialogs.some((c) => c.lineID.transactionNumber === i.lineID.transactionNumber && c.lineID.lineNumber === i.lineID.lineNumber);
  });
  return dialogToGoBack;
};

// REFACTORY TO DO : OP
const updateOperationData = async (state, tabIndex, events, source) => {
  const isInGoToLine = source && source.toLowerCase().includes('gotoline');
  let { trees, stateByLineID, focusElement, lastFocusElement, valueSetElement, newState } = await listToTree(
    { ...state },
    events,
    tabIndex,
    isInGoToLine,
    addOrRemoveFocusFromFocusOn,
  );
  const operation = newState.tabs[tabIndex];
  operation.running.trees = trees;
  operation.running.stateByLineID = _.merge({}, operation.running.stateByLineID, stateByLineID);

  try {
    if (state.isLogActive) {
      const logResponse = await getLogs(operation.running.operationID);
      if (!newState.logs) newState.logs = [];
      newState.logs = [...newState.logs, ...logResponse.lines];
    }
  } catch (ex) {
    console.log('Error al obtener los logs');
    console.log(ex);
  }

  // perform removeComponentStatus
  events.forEach((item, indexItem) => {
    if (item && item.lineID) {
      const itemClass = item['class-name'] || '';
      const lineIDKey = generateLineIDString(item.lineID);
      if (
        itemClass.includes('ItemToDeleteEvent') &&
        (!operation.running.stateByLineID[lineIDKey] ||
          (operation.running.stateByLineID[lineIDKey] && !operation.running.stateByLineID[lineIDKey].displayInfoHistory))
      ) {
        removeComponentStatus(operation.running.stateByLineID, item.lineID);
      }
    }
  });

  operation.running.debug = [
    ...operation.running.debug,
    {
      source,
      events,
      trees,
      stateByLineID,
    },
  ];
  operation.running.focusElement = focusElement;
  operation.running.lastFocusElement = lastFocusElement;
  operation.running.valueSetElement = valueSetElement;
  newState.variableRowFields = operation.running.stateByLineID.variableRowFields;
  newState.countVariableRow = operation.running.stateByLineID.countVariableRow;
  return newState;
};

// REFACTORY TO DO : UI
const removeComponentStatus = (state, lineID) => {
  let lineIDKey = generateLineIDString(lineID);
  if (state[lineIDKey]) {
    // if the item to remove is a dialog, remove child components
    if (state.openedDialogList.includes(lineIDKey)) {
      let lineIdList = Object.keys(state);
      const transactionNumber = getTransactionFromLineIDString(lineIDKey);
      const lineNumber = getLineFromLineIDString(lineIDKey);
      const countNumber = getIterationCountFromLineIDString(lineIDKey);

      // get next closest open dialog
      let nextOpenedDialogLine = null;
      for (let tmpComponent of lineIdList) {
        const tmpTransactionNumber = getTransactionFromLineIDString(tmpComponent);
        const tmpLineNumber = getLineFromLineIDString(tmpComponent);
        if (
          state.openedDialogList.includes(tmpComponent) &&
          transactionNumber === tmpTransactionNumber &&
          lineNumber < tmpLineNumber &&
          (!nextOpenedDialogLine || tmpLineNumber < nextOpenedDialogLine)
        ) {
          nextOpenedDialogLine = getLineFromLineIDString(tmpComponent);
        }
      }

      // remove all components between dialogs
      for (let tmpComponent of lineIdList) {
        const tmpTransactionNumber = getTransactionFromLineIDString(tmpComponent);
        const tmpLineNumber = getLineFromLineIDString(tmpComponent);
        const tmpCountNumber = getIterationCountFromLineIDString(tmpComponent);

        if (
          transactionNumber === tmpTransactionNumber &&
          lineNumber < tmpLineNumber &&
          (!nextOpenedDialogLine || tmpLineNumber < nextOpenedDialogLine) &&
          countNumber === tmpCountNumber
        ) {
          delete state[tmpComponent];
        }
      }
    }

    // remove component
    delete state[lineIDKey];
  }
};

const moveMacroToLastPosition = (list) => {
  const indexEvent = list.findIndex((item) => item['class-name'].includes('ApplicationToLaunchEvent'));
  if (
    indexEvent > -1 &&
    list[indexEvent] &&
    list[indexEvent].name &&
    list[indexEvent].name.toUpperCase() === 'COMMAND' &&
    list[indexEvent].data &&
    (list[indexEvent].data.toUpperCase().indexOf('TZPQUERY.EXE') > -1 || list[indexEvent].data.toUpperCase().indexOf('/SHOW') > -1)
  ) {
    const firstPart = list.slice(0, indexEvent);
    const lastPart = list.slice(indexEvent + 1);
    list = [...firstPart, ...lastPart, list[indexEvent]];
  }

  return list;
};

// REFACTORY TO DO : PROCESSOR
export const processContext = async (state, response, source, tabId = null) => {
  let newState = { ...state };
  const tabIndex = tabId ? tabIndexOf({ tabId }, newState.tabs) : newState.current;

  if (!newState.tabs[tabIndex] || !newState.tabs[tabIndex].running) {
    return newState;
  }

  if (response && response.lista && response.lista.length > 0) {
    response.lista = moveMacroToLastPosition(response.lista);

    let eventsToProcess = response.lista;
    getLogger().LogEventsToProcess(eventsToProcess);

    if (source === 'Notice Decided' && newState.noticeDecidedToCache) {
      if (!newState.noticeDecidedCache) {
        newState.noticeDecidedCache = {};
      }
      newState.noticeDecidedCache = {
        lista: [...newState.noticeDecidedCache.lista, ...response.lista],
        tabId: tabId,
      };
      store.setState({ noticeDecidedCache: newState.noticeDecidedCache });
      return newState;
    }

    const indexNotice = response.lista.findIndex((item) => item['class-name'].includes('NoticeToDisplay'));
    const indexOpToRun = response.lista.findIndex((item) => item['class-name'].includes('OperationToRun'));

    if (indexNotice >= 0) {
      if (indexOpToRun === -1 || indexNotice < indexOpToRun) {
        /* if the server doesnt start a sub operation inside the events received
         * process only the events up to the NoticeToDecided,
         * since the other events will be processed when the user closes the message.
         */
        const eventsUpToNotice = response.lista.slice(0, indexNotice + 1);
        eventsToProcess = eventsUpToNotice;

        const eventsAfterNotice = response.lista.slice(indexNotice + 1);
        newState.eventsAfterNotice = eventsAfterNotice;
      } else if (response.lista.findIndex((item) => item['class-name'].includes('OperationToFinish')) === -1) {
        /* if the server starts a sub operation but doesnt finish it in the same event list
         * set flag to put inside cache events received from noticeDecided until an operationToFinish/Cancel is received
         */
        newState.noticeDecidedToCache = true;
        newState.noticeDecidedCache = { tabId: null, lista: [] };
        browserStorage.set('noticeDecidedCache', newState.noticeDecidedCache);
      }
    }

    const indexOpeChange = eventsToProcess.findIndex((item) => item['class-name'].includes('OperationChange'));

    if (indexOpeChange >= 0) {
      newState = processOperationChange(newState, eventsToProcess, indexOpeChange, source);
    } else {
      // there is no OperationToChangeEvent in the response.
      const dialogsToGoBack = getDialogsToGoBack(newState, eventsToProcess, tabIndex);
      if (dialogsToGoBack.length) {
        dialogsToGoBack.forEach((dialog) => {
          dialog.lineID.tabId = newState.tabs[tabIndex].id;
          let lineIDKey = generateLineIDString(dialog.lineID);
          const dialogKey =
            newState.tabs[tabIndex].running.stateByLineID[lineIDKey] && newState.tabs[tabIndex].running.stateByLineID[lineIDKey].dialogKey;
          if (
            newState.tabs[tabIndex].running.stateByLineID.stateByDialogKey[dialogKey] &&
            newState.tabs[tabIndex].running.stateByLineID.stateByDialogKey[dialogKey].iterativeDialogCount > 0
          ) {
            dialog.lineID.countIterativeDialog = newState.tabs[tabIndex].running.stateByLineID.stateByDialogKey[dialogKey].iterativeDialogCount;
            dialog.lineID.notVariableRow = true;
            // If more than 1 dialog, it means the user pressed "Cancel" and the countIterativeDialog should be decreased here, if only 1 dialog, the countIterativeDialog is decreased in listToTree's itemToDeleteEvent handler
            if (
              dialogsToGoBack.filter(
                (e) => e.lineID.transactionNumber === dialog.lineID.transactionNumber && e.lineID.lineNumber === dialog.lineID.lineNumber,
              ).length > 1
            ) {
              newState.tabs[tabIndex].running.stateByLineID.stateByDialogKey[dialogKey].iterativeDialogCount--;
            }
          }
          lineIDKey = generateLineIDString(dialog.lineID);
          newState = goBackToDialog(newState, lineIDKey, dialog.number);
        });
      }

      newState = await updateOperationData(newState, tabIndex, eventsToProcess, source);

      const finish = eventsToProcess.some((item) => item['class-name'].includes('OperationToFinish'));
      const cancel = eventsToProcess.some((item) => item['class-name'].includes('OperationToCancel'));
      const runInfo = eventsToProcess.find((item) => item['class-name'].includes('OperationToRun'));
      const appToLaunch = eventsToProcess.filter((item) => item['class-name'].includes('ApplicationToLaunch'));
      const tab = newState.tabs[tabIndex];
      // processing appToLaunch is before the finish
      // so that the current tab info is still in the state
      if (appToLaunch) {
        for (let app of appToLaunch) {
          newState = await proccessAppToLaunch(newState, app, tab);
        }
      }
      newState = await processDialogsVisibility(newState, eventsToProcess, tab);
      const updatedState = store.getState();
      newState.lastValues = updatedState.lastValues;
      newState.modalMessage = updatedState.modalMessage;
      newState.apiLoading = updatedState.apiLoading;

      // se efectua un primer setState con los cambios para forzar un render porque en el componentDidUpdate se efectua el setLastValue de las ayudas (desde el input), despues se recarga el valor de lastValues
      // store.setState(newState);
      newState.lastValues = updatedState.lastValues;

      if (finish) {
        newState = await finishCurrentOperation(newState, tab, 1);
        newState.variableRowFields = [];
      } else if (cancel) {
        newState = await finishCurrentOperation(newState, tab, 0);
        newState.variableRowFields = [];
      }

      if (runInfo) {
        newState = await runOperationFromTab(newState, tab, runInfo, finish);
      }

      if (finish && newState.noticeDecidedToCache) {
        let tmpState = store.getState().noticeDecidedCache;
        if (tmpState.lista) {
          let resp = { lista: [...tmpState.lista] };
          newState.noticeDecidedCache = {};
          newState.noticeDecidedToCache = false;
          newState = await processContext(newState, resp, 'Notice Decided', tmpState.tabId);
        }
      }
    }
  }

  return newState;
};

export const processEventsAfterNotice = async (state) => {
  let newState = { ...state };

  const tabIndex = newState.current;
  if (!newState.tabs.length) {
    return newState;
  }
  const tab = newState.tabs[tabIndex];
  if (!tab || !tab.running) {
    return newState;
  }
  const eventsAfterNotice = newState.eventsAfterNotice;

  if (!eventsAfterNotice || !eventsAfterNotice.length) {
    return newState;
  }

  // the structure needed for proccessContext
  const context = { lista: eventsAfterNotice };

  const source = 'Events after Notice';
  const preProcessEventsAfterNoticeCount = newState.eventsAfterNotice.length;

  newState = await processContext(newState, context, source, null, true);
  if (newState.eventsAfterNotice && preProcessEventsAfterNoticeCount === newState.eventsAfterNotice.length) {
    newState.eventsAfterNotice = [];
  }

  return newState;
};

// REFACTORY TO DO : UI/PROCESSOR
const processDialogsVisibility = async (state, events, tab) => {
  let newState = { ...state };
  const dialogsToHide = events.filter((item) => item['class-name'].includes('DialogToHide'));
  if (dialogsToHide && dialogsToHide.length) {
    newState = await toggleDialogsVisibility(newState, tab, dialogsToHide, true);
  }

  const dialogsToUnhide = events.filter((item) => item['class-name'].includes('DialogToDisplay') || item['class-name'].includes('DialogToUnhide'));
  if (dialogsToUnhide && dialogsToUnhide.length) {
    newState = await toggleDialogsVisibility(newState, tab, dialogsToUnhide, false);
  }
  return newState;
};

// REFACTORY TO DO : OP
export const processOperationChange = async (state, events, indexOpeChange, source) => {
  let newState = { ...state };
  const eventsForCurrentOpe = events.slice(0, indexOpeChange);
  const opeChangeEvent = events[indexOpeChange];
  const eventsForNextOpe = events.slice(indexOpeChange + 1);
  if (eventsForCurrentOpe.length) {
    const dialogsToGoBack = getDialogsToGoBack(newState, eventsForCurrentOpe, newState.current);
    if (dialogsToGoBack.length) {
      dialogsToGoBack.forEach((dialog) => {
        const lineIDKey = generateLineIDString(dialog.lineID);
        newState = goBackToDialog(newState, lineIDKey, dialog.number);
      });
    }

    newState = await updateOperationData(newState, newState.current, eventsForCurrentOpe, source);
    const appToLaunch = eventsForCurrentOpe.filter((item) => item['class-name'].includes('ApplicationToLaunch'));
    const tab = newState.tabs[newState.current];
    if (appToLaunch) {
      for (let app of appToLaunch) {
        newState = await proccessAppToLaunch(newState, app, tab);
      }
    }

    newState = await processDialogsVisibility(newState, eventsForCurrentOpe, tab);
  }

  const currentPostingNumber = newState.tabs[newState.current].postingNumber;
  const nextOpeTabIndex = tabIndexOf(
    {
      opNumber: opeChangeEvent.operationNumber,
      postingNumber: currentPostingNumber,
    },
    newState.tabs,
  );
  if (nextOpeTabIndex >= 0) {
    if (nextOpeTabIndex !== newState.current) {
      // operation to change is already open
      const tabIndexToClose = newState.current;
      newState = setCurrentTab(newState, nextOpeTabIndex);
      newState = removeTab(newState, tabIndexToClose, {
        breakFree: false,
        removeAllAssociatedTabs: false,
      });
    }
  } else {
    // create a new operation
    newState = await changeToNewOperation(newState, newState.current, opeChangeEvent.operationNumber);
  }
  if (eventsForNextOpe.length) {
    const dialogsToGoBack = getDialogsToGoBack(newState, eventsForNextOpe, newState.current);
    if (dialogsToGoBack.length) {
      dialogsToGoBack.forEach((dialog) => {
        const lineIDKey = generateLineIDString(dialog.lineID);
        newState = goBackToDialog(newState, lineIDKey, dialog.number);
      });
    }

    newState = await updateOperationData(newState, newState.current, eventsForNextOpe, source);
    const appToLaunch = eventsForNextOpe.filter((item) => item['class-name'].includes('ApplicationToLaunch'));
    const tab = newState.tabs[newState.current];
    if (appToLaunch) {
      for (let app of appToLaunch) {
        newState = await proccessAppToLaunch(newState, app, tab);
      }
    }

    newState = await processDialogsVisibility(newState, eventsForNextOpe, tab);

    const finishOper = eventsForNextOpe.some((item) => item['class-name'].includes('OperationToFinish'));
    const runInfo = eventsForNextOpe.find((item) => item['class-name'].includes('OperationToRun'));

    const cancelOper = eventsForNextOpe.some((item) => item['class-name'].includes('OperationToCancel'));

    const dialogsToUnhide = events.filter((item) => item['class-name'].includes('DialogToDisplay'));

    if (finishOper) {
      newState = await finishCurrentOperation(newState, tab, 1, dialogsToUnhide && dialogsToUnhide.length);
    } else if (cancelOper) {
      newState = await finishCurrentOperation(newState, tab, 0, dialogsToUnhide && dialogsToUnhide.length);
    }

    if (runInfo) {
      newState = await runOperationFromTab(newState, tab, runInfo, finishOper);
    }

    store.setState(newState);
  }
  return newState;
};

// REFACTORY TO DO : UI
export const toggleDialogsVisibility = async (state, tab, dialogs, hide) => {
  let newState = { ...state };
  const tabIndex = tabIndexOf({ tabId: tab.id }, newState.tabs);

  const getLineIDKeyByDialogNumber = (dialogNumber, subindex) => {
    const dialogs = newState.tabs[tabIndex].running.trees.filter((tree) => tree._data.number === dialogNumber);
    let dialog;
    if (subindex) {
      dialogs.forEach((diag) => {
        if (diag._data.lineID.countIterativeDialog && diag._data.lineID.countIterativeDialog === subindex) {
          dialog = diag;
        }
      });
    } else {
      let stateLineByID = newState.tabs[tabIndex].running.stateByLineID;
      dialogs
        .slice()
        .reverse()
        .forEach((element) => {
          let lineIDKey = generateLineIDString(element._data.lineID);
          if (stateLineByID[lineIDKey] && !stateLineByID[lineIDKey].deleted && !dialog) {
            dialog = element;
          }
        });
    }
    if (!dialog) {
      dialog = dialogs[dialogs.length - 1];
    }
    if (dialog) {
      return generateLineIDString(dialog._data.lineID);
    } else {
      return false;
    }
  };

  dialogs.forEach((item) => {
    const dialogNumber = item.dialogKey ? getNumberFromDialogKey(item.dialogKey) : item.number;
    if (dialogNumber) {
      const lineIDKey = getLineIDKeyByDialogNumber(dialogNumber, item.lineID.countIterativeDialog);
      if (lineIDKey !== false) {
        newState.tabs[tabIndex].running.stateByLineID[lineIDKey].hide = hide;
      }
    }
  });
  return newState;
};

// REFACTORY TO DO : OP
export const finishCurrentOperation = async (state, tab, stateResume) => {
  let newState = { ...state };
  const tabIndex = tabIndexOf({ tabId: tab.id }, newState.tabs);
  const { operationNumber, postingNumber, parentOperationID, parentOperationNumber } = tab;
  newState = await removeTab(newState, tabIndex);

  const parentTabIndex = getTabIndexByOperationID(newState, parentOperationID, parentOperationNumber);
  if (parentTabIndex !== -1) {
    newState = setCurrentTab(newState, parentTabIndex);
    store.setState(newState);
    const resumeResponse = await resume(parentOperationID, stateResume);
    if (resumeResponse.lista.length) {
      const { id: parentTabId } = newState.tabs[parentTabIndex];
      newState = await processContext(newState, resumeResponse, 'Resume', parentTabId);
    }
  }

  newState = await openReceivedReports(newState, operationNumber, postingNumber);

  if (newState.modalMessage.length > 0 && !newState.globalMessage?.visible) {
    newState = openGlobalModal(newState, newState.modalMessage[0].message, false, null, false, false, false, newState.modalMessage[0].eventHandler);
    newState = removeModal(newState);
  }

  return newState;
};

export const runOperationFromTab = async (state, currentOperation, runInfo, finish) => {
  let newState = { ...state };
  const {
    id: parentTabId,
    operationLook: parentOperationLook,
    postingNumber: parentPostingNumber,
    operationName: parentOperationName,
    operationNumber: parentOperationNumber,
  } = currentOperation;
  const parentOperationID = !finish ? currentOperation.running.operationID : null;
  const { number: operationNumber, parameterList, toChain, synchronize } = runInfo;

  if (!(state.tabs.length === 1 && state.tabs[0].emptyTab)) {
    newState = addEmptyTab(newState);
  }

  newState = addTab(
    newState,
    operationNumber,
    parentOperationName,
    parentPostingNumber,
    false,
    parentOperationLook,
    true,
    parameterList,
    null,
    synchronize,
    toChain,
    false,
    parentTabId,
    parentOperationID,
    parentOperationNumber,
  );

  return newState;
};

export const changeToNewOperation = (state, parentTabIndex, opeNumberFromNewOpe) => {
  let newState = { ...state };
  const {
    id: parentTabId,
    operationLook: parentOperationLook,
    postingNumber: parentPostingNumber,
    operationName: parentOperationName,
    operationNumber: parentOperationNumber,
    running: parentOperationData,
  } = newState.tabs[parentTabIndex];

  const parentOperationID = parentOperationData.operationID;

  if (!(state.tabs.length === 1 && state.tabs[0].emptyTab)) {
    newState = addEmptyTab(newState, { openInmediatlyOnRight: true });
  }

  newState = addTab(
    newState,
    opeNumberFromNewOpe,
    parentOperationName,
    parentPostingNumber,
    false,
    parentOperationLook,
    true,
    null,
    null,
    false,
    false,
    true,
    parentTabId,
    parentOperationID,
    parentOperationNumber,
    false,
  );

  newState = runTab(newState, newState.current, parentOperationID);

  return newState;
};

// REFACTORY TO DO : PROCESSOR
const proccessAppToLaunch = (state, event, currentOperation) => {
  let newState = { ...state };
  const runOperationFromAppToLaunch = (operationNumber, TZPCAPTParameters) => {
    const { operationLook: parentOperationLook, postingNumber: parentPostingNumber, operationName: parentOperationName } = currentOperation;
    if (!(state.tabs.length === 1 && state.tabs[0].emptyTab)) {
      newState = addEmptyTab(newState);
    }

    newState = addTab(
      newState,
      operationNumber,
      parentOperationName,
      parentPostingNumber,
      false,
      parentOperationLook,
      true,
      null,
      null,
      false,
      false,
      false,
      null,
      null,
      null,
      true,
      TZPCAPTParameters,
    );

    return newState;
  };

  if (event['name'] === 'command') {
    const dataCommand = event['data'].split(' ');
    if (dataCommand.length === 3 && dataCommand[0].toUpperCase() === 'TZPCAPT.EXE') {
      if (dataCommand[0].toUpperCase() === 'TZPCAPT.EXE' && !isNaN(dataCommand[2])) {
        return runOperationFromAppToLaunch(dataCommand[2]);
      }
    } else if (dataCommand.length === 2 && dataCommand[0].toUpperCase() === 'TZPCAPT.EXE') {
      const operationStringToOpen = dataCommand[1].replace(/\//g, '').replace(/\s/g, '').replace(/O/g, '');
      const operationToOpen = parseInt(operationStringToOpen, 10);
      if (dataCommand[0].toUpperCase() === 'TZPCAPT.EXE' && !isNaN(operationToOpen)) {
        return runOperationFromAppToLaunch(operationToOpen);
      }
    } else if (dataCommand.length > 3 && dataCommand[0].toUpperCase() === 'TZPCAPT.EXE') {
      let curIdx = 3;
      let TZPCAPTParameters = [];
      let exitWhile = false;
      while (!exitWhile) {
        if (dataCommand[curIdx] && dataCommand[curIdx].length > 2 && dataCommand[curIdx].substr(0, 2) === '/C' && dataCommand[curIdx + 1]) {
          TZPCAPTParameters.push({
            fieldNumber: dataCommand[curIdx].substr(2, dataCommand[curIdx].length - 2),
            fieldValue: dataCommand[curIdx + 1],
          });
        }
        curIdx += 2;
        if (!dataCommand[curIdx] || !dataCommand[curIdx + 1]) {
          exitWhile = true;
        }
      }
      return runOperationFromAppToLaunch(dataCommand[2], TZPCAPTParameters);
    } else if (dataCommand[0].toUpperCase() === 'TZPQUERY.EXE' || dataCommand[0].toUpperCase() === 'TZPQUERY') {
      return openReport(newState, event['data'].trim());
    } else if (dataCommand[0] === 'checks') {
      return newState;
    }
  }
  return newState;
};

// REFACTORY TO DO : REPORT
const openReport = async (state, itemData) => {
  let newState = { ...state };
  const { tabs, current } = newState;
  const { operationNumber } = tabs[current];

  let from = itemData.indexOf('/Q');
  const showReport = itemData.indexOf('/DS') >= 0 || itemData.indexOf('/A');
  from = from + 2;
  let to = itemData.indexOf(' ', from);
  if (from === to) to = itemData.indexOf(' ', to + 1);
  if (to === -1) to = itemData.length;
  let specID = parseInt(itemData.substr(from, to - from).trim(), 10);

  store.setState({
    globalMessage: {
      visible: true,
      message: (
        <div>
          <FormattedMessage id="generatingReportNumber" defaultMessage={`Generando reporte nÃºmero`} />
          {` ${specID} `}
          <FormattedMessage id="forOperationNumber" defaultMessage={`para la operaciÃ³n nÃºmero`} />
          {` ${operationNumber}`}
        </div>
      ),
      logoutAfter: false,
      redirectTo: `${PATHNAME}home/reports`,
      loading: true,
      image: false,
      cancelButton: false,
    },
  });

  const dataReport = await getReportGenerationSpec(specID);
  if (typeof dataReport === 'string') {
    if (dataReport.includes('Exception')) {
      let error = dataReport.slice(dataReport.indexOf(':'), dataReport.length);
      const message = (
        <div>
          <div>
            <FormattedMessage id="reportParameterError" defaultMessage={`No se puede obtener la lista de parÃ¡metros del reporte `} />
            {` ${specID}. `}
          </div>
          <div>
            <FormattedMessage id="causedBy" defaultMessage={`Causa: `} />
            {` ${error}`}
          </div>
        </div>
      );
      newState = openGlobalModal(newState, message, false, false, false, false, false);
    }
  } else {
    let report = bodyBuildReports(itemData, dataReport);
    const infoReport = await getReport(report);
    if (showReport) {
      if (infoReport && typeof infoReport !== 'string' && !infoReport.mensaje) {
        const { name, fileExtension, fileExtensionExcel } = infoReport;
        const reportTabJson = {
          description: report.description,
          specificationId: report.specificationId,
          name,
          fileExtension,
          fileExtensionExcel,
          generateExcel: dataReport.generaExcel,
          bodyToGenerateReport: { ...report },
        };
        if (state.clientProperties?.showReportsConfirmation === 'true') {
          const message = (
            <div>
              <FormattedMessage id="displayReportNumber" defaultMessage={`¿Desea visualizar el reporte número`} />
              {` ${specID} (${report.description}) `}
              <FormattedMessage id="generatedForOperationNumber" defaultMessage={`generado para la operación número`} />
              {` ${operationNumber}? `}
            </div>
          );
          newState = openGlobalModal(newState, message, false, `${PATHNAME}home/reports`, false, false, true, (e) => {
            let newState = { ...store.getState() };
            newState = addReportWithValidation(newState, reportTabJson);
            store.setState(newState);
          });
        } else {
          newState = addReportWithValidation(newState, reportTabJson);
          newState.redirectOnNextRender = `${PATHNAME}home/reports`;
        }
      }
    }
  }

  return newState;
};

const addReportWithValidation = (state, report) => {
  if (!(state.tabsReport.length === 1 && state.tabsReport[0].emptyTab)) {
    state = addEmptyTabReport(state);
  }
  state = addTabReport(state, report, true, false);
  return state;
};

// REFACTORY TO DO : REPORT
export const validResponse = (response) => response && typeof response !== 'string' && !response.mensaje;

export const openReceivedReports = async (state, operationNumber, postingNumber) => {
  let newState = { ...state };
  try {
    const printForm = await printForms(postingNumber);
    if (validResponse(printForm)) {
      const sessionInfo = await getSessionInfo();
      if (validResponse(sessionInfo)) {
        const transactionData = await getTransaction(sessionInfo.topazBranch, postingNumber, sessionInfo.fechaProceso, null);
        if (validResponse(transactionData) && transactionData.reports.length > 0) {
          store.setState({
            globalMessage: {
              visible: true,
              message: (
                <div>
                  <FormattedMessage id="generatingReports" defaultMessage={`Generando reportes para la operaciÃ³n nÃºmero`} />
                  {` ${operationNumber}...`}
                </div>
              ),
              logoutAfter: false,
              redirectTo: '',
              loading: true,
              image: false,
              cancelButton: false,
            },
          });
          let openReports = false;
          let showPrinterRawModalMessage = false;
          let printerRawReports = [];
          let reportDataListJson = [];
          for (let i = 0; i < transactionData.reports.length; i++) {
            const report = transactionData.reports[i];
            const infoForm = await getOperationEndedForm(false, report, false, false);
            if (validResponse(infoForm)) {
              if (infoForm.fileExtension && infoForm.fileExtension === 'raw') {
                const modalOptions = {
                  buttons: [],
                  source: 'OPERATIONS',
                  eventHandler: (e) => {
                    printerRaw(infoForm.properties, infoForm.pages);
                  },
                  showAfterFinishOperation: true,
                };
                let message = '';
                if (infoForm.genericPopupMessage) {
                  message = <div>{` ${infoForm.genericPopupMessage} `}</div>;
                } else {
                  message = (
                    <div>
                      <FormattedMessage id="printRawReport" defaultMessage={`Inserte el formulario`} />
                      {`: ${infoForm.pages.description} `}
                    </div>
                  );
                }
                newState = showModalMessage(newState, message, modalOptions);
              } else {
                const { name, fileExtension } = infoForm;
                const reportDataJson = {
                  description: report.description,
                  specificationId: report.reportID,
                  name,
                  fileExtension,
                };
                if (!(newState.clientProperties?.showReportsConfirmation === 'true')) {
                  newState = addReportWithValidation(newState, reportDataJson);
                  newState.redirectOnNextRender = `${PATHNAME}home/reports`;
                } else {
                  reportDataListJson.push(reportDataJson);
                }

                openReports = true;
              }
            }
          }
          if (showPrinterRawModalMessage) {
            // const ok = new ButtonData(BUTTON_OK, true);
            const modalOptions = {
              buttons: [],
              source: 'OPERATIONS',
              eventHandler: (e) => {
                printerRawReports.forEach((infoForm) => {
                  printerRaw(infoForm.properties, infoForm.pages);
                });
              },
            };
            /*
                const msg = intl.formatMessage({
                  id: 'noFees',
                  defaultMessage: `No hay cargos asociados al evento`,
                }); */
            newState = showModalMessage(newState, 'test', modalOptions);
          }
          if (openReports) {
            if (state.clientProperties?.showReportsConfirmation === 'true') {
              newState = openGlobalModal(
                newState,
                <div>
                  <FormattedMessage
                    id="displayGeneratedReports"
                    defaultMessage={`Â¿Desea visualizar los reportes generados para la operaciÃ³n nÃºmero`}
                  />
                  {` ${operationNumber} `}
                  <FormattedMessage id="withPostingNumber" defaultMessage={`con nÃºmero de asiento`} />
                  {` ${postingNumber}?`}
                </div>,
                false,
                `${PATHNAME}home/reports`,
                false,
                false,
                true,
                () => {
                  reportDataListJson.forEach((reportDataJson) => {
                    let newState = store.getState();
                    newState = addReportWithValidation(newState, reportDataJson);
                    newState.redirectOnNextRender = `${PATHNAME}home/reports`;
                    store.setState(newState);
                  });
                },
              );
            }
          } else {
            newState = closeGlobalModal(newState);
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    store.setState({
      globalMessage: {
        visible: false,
      },
    });
  }

  return newState;
};

// REFACTORY TO DO : UI
export const updateModalMessageId = (state, modalId, lineNumber) => {
  const newState = { ...state };
  let tmpModalMessage = newState.modalMessage.find((msg) => msg.lineNumber === lineNumber);
  tmpModalMessage.modalId = modalId;
  tmpModalMessage.escapePressed = false;
};

export const showModalMessage = (state, message, options, infoObject) => {
  const newState = { ...state };

  if (message === undefined || message === null) {
    return newState;
  }

  let modalMessage = {};
  modalMessage.message = message;
  modalMessage.show = true;
  modalMessage.infoObject = infoObject;
  modalMessage.escapePressed = false;

  if (!options) {
    modalMessage.source = 'OPERATIONS';
  } else {
    if (options.modalId) {
      modalMessage.modalId = options.modalId;
    } else {
      modalMessage.modalId = '';
    }
    if (options.title) {
      modalMessage.title = options.title;
    } else {
      modalMessage.title = '';
    }
    if (options.buttons) {
      modalMessage.buttons = options.buttons;
    } else {
      modalMessage.buttons = [{ buttonId: 'defaultModalButton', autoFocus: true, text: 'OK' }];
    }
    if (options.source) {
      modalMessage.source = options.source;
    } else {
      modalMessage.source = 'OPERATIONS';
    }
    if (options.returnFocus) {
      modalMessage.returnFocus = options.returnFocus;
    }
    if (options.eventHandler) {
      modalMessage.eventHandler = options.eventHandler;
    }
    if (options.lineNumber || options.lineNumber === 0) {
      modalMessage.lineNumber = options.lineNumber;
    }
    modalMessage.escapePressed = !!options.escapePressed;
    if (options.showAfterFinishOperation) {
      modalMessage.showAfterFinishOperation = options.showAfterFinishOperation;
      modalMessage.show = false;
    }
  }

  newState.modalMessage.push(modalMessage);
  // para mostrar el ultimo indice
  // newState.modalMessageCurrentIndex = newState.modalMessage ? newState.modalMessage.length : 0;
  newState.modalMessageCurrentIndex = 0;

  return pauseFocus(newState);
};

export const removeModal = (state, messageToFind = null) => {
  const newState = { ...state };
  if (!messageToFind) {
    newState.modalMessage = newState.modalMessage.slice(1);
  } else {
    newState.modalMessage = newState.modalMessage.filter((modalMsg) => modalMsg.message !== messageToFind);
  }
  return resumeFocus(newState);
};

export const flagModalForRemoval = (state) => {
  const newState = { ...state };
  // un hook de render en ConfirmDialog borra del array al modal, esta mal hecho, todo ese tratamiento deberia estar en objetos Modal/Tree
  newState.modalMessage[newState.modalMessageCurrentIndex].toRemove = true;
  return newState;
};

export const hideModalMessage = (state) => {
  const newState = { ...state };
  const { modalMessage } = newState;
  if (modalMessage[newState.modalMessageCurrentIndex]) {
    modalMessage[newState.modalMessageCurrentIndex].show = false;
  }
  return resumeFocus(newState);
};

export const setModalButtonClick = (state, id) => {
  const newState = { ...state };
  const { modalMessage } = newState;

  if (modalMessage[newState.modalMessageCurrentIndex]) {
    const clickedButton = modalMessage[newState.modalMessageCurrentIndex].buttons.find((b) => b.buttonId === id);
    clickedButton.clicked = true;

    // so the focus doesn't go inmediatly to the other button with autofocus
    clickedButton.autoFocus = true;
  }

  return newState;
};

export const toggleModalEscapePressed = (state) => {
  const newState = { ...state };
  const { modalMessage } = newState;

  if (modalMessage[newState.modalMessageCurrentIndex]) {
    modalMessage[newState.modalMessageCurrentIndex].escapePressed = !modalMessage[newState.modalMessageCurrentIndex].escapePressed;
  }

  return newState;
};

export const openGlobalModal = (
  state,
  message,
  logoutAfter = false,
  redirectTo = null,
  loading = false,
  image = false,
  cancelButton = false,
  eventHandler = null,
  cancelEventHandler = null,
) => {
  let eventHandlerArray = [eventHandler];
  if (state.globalMessage && state.globalMessage.eventHandler && state.globalMessage.visible) {
    if (Array.isArray(state.globalMessage.eventHandler)) {
      eventHandlerArray = state.globalMessage.eventHandler;
      eventHandlerArray.push(eventHandler);
    } else {
      eventHandlerArray = [state.globalMessage.eventHandler, eventHandler];
    }
  }
  return {
    ...state,
    globalMessage: {
      visible: true,
      message,
      logoutAfter,
      redirectTo,
      loading,
      image,
      cancelButton,
      eventHandler: eventHandlerArray,
      cancelEventHandler,
    },
  };
};

export const closeGlobalModal = (state) => {
  let newState = {
    ...state,
    globalMessage: {
      visible: false,
      message: '',
      logoutAfter: false,
      loading: false,
    },
  };
  if (newState.modalMessage.length > 0) {
    newState = openGlobalModal(newState, newState.modalMessage[0].message, false, null, false, false, false, newState.modalMessage[0].eventHandler);
    newState = removeModal(newState);
  }
  return newState;
};

/* TABS REPORTS */

// REFACTORY TO DO : TABS/REPORT
export const setCurrentTabReport = (state, tabID) => {
  const newState = { ...state };
  if (newState.tabsReport[tabID]) {
    newState.currentTabReport = tabID;
  }

  return newState;
};

export const addEmptyTabReport = (state) => {
  const newState = { ...state };
  newState.tabsReport = [
    ...state.tabsReport,
    {
      operationName: 'Nueva PestaÃ±a',
      operationNumber: DEFAULT_REPORT_NUMBER,
      emptyTab: true,
    },
  ];

  newState.currentTabReport = newState.tabsReport.length - 1;

  return newState;
};

export const removeTabReport = async (state, tabID) => {
  const newState = { ...state };
  newState.tabsReport = [...state.tabsReport];

  const toDeleteID = tabID !== undefined && tabID !== null ? tabID : state.currentTabReport;

  newState.tabsReport = [...newState.tabsReport.slice(0, toDeleteID), ...newState.tabsReport.slice(toDeleteID + 1)];

  let newCurrentTabReport = toDeleteID - 1;
  if (newCurrentTabReport < 0) {
    newCurrentTabReport = newState.currentTabReport - 1;
  }
  if (newCurrentTabReport >= 0) newState.currentTabReport = newCurrentTabReport;

  return newState;
};

export const addTabReport = (state, report, onlyPreview = false, saveRecent = true) => {
  let newState = { ...state };
  const currentTabReport = state.currentTabReport;
  if (report.description) {
    newState.tabsReport[currentTabReport] = {
      reportName: report.description,
      reportNumber: report.specificationId,
      reportData: report,
      emptyTab: false,
      onlyPreview,
    };
  } else if (typeof report === 'string') {
    newState = openGlobalModal(newState, report);
  }

  if (saveRecent) saveRecentReport(report.specificationId, report.description);

  return newState;
};

export const addReportData = (state, newReportData) => {
  const newState = { ...state };
  const tab = newState.currentTabReport;
  newState.tabsReport[tab].reportData = {
    ...newState.tabsReport[tab].reportData,
    ...newReportData,
  };
  return newState;
};

export const setReportOnlyPreview = (state, onlyPreview) => {
  const newState = { ...state };
  const tab = newState.currentTabReport;
  newState.tabsReport[tab].onlyPreview = onlyPreview;
  return newState;
};

/* FOOTER INFO */

// REFACTORY TO DO : PROCESSOR
function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
}

export const setFooterInfo = async (state) => {
  const newState = { ...state };
  const { serverAddress, machineNumber, topazBranch, user, fechaProceso } = await getSessionInfo();

  let sessionInfo = {
    name: user.initials,
    branch: `S${zeroPad(topazBranch, SIZE_BRANCH_NUMBER)}`,
    machine: `M${zeroPad(machineNumber, SIZE_MACHINE_NUMBER)}`,
    processDate: fechaProceso.split(' ')[0],
    server: serverAddress,
  };

  newState.footerInfo = { sessionInfo };
  return newState;
};

// REFACTORY TO DO : OP
export const toggleRecentOperationsCollapse = (state) => {
  const newState = { ...state };
  newState.menuGridInfo.recentOperationsCollapsibleIsOpen = !newState.menuGridInfo.recentOperationsCollapsibleIsOpen;
  return newState;
};

// REFACTORY TO DO : REPORT
export const toggleRecentReportsCollapse = (state) => {
  const newState = { ...state };
  newState.menuGridInfo.recentReportsCollapsibleIsOpen = !newState.menuGridInfo.recentReportsCollapsibleIsOpen;
  return newState;
};

// REFACTORY TO DO : OP
export const getOperationsFromAPI = async (state) => {
  if (state.operationsData && state.operationsData.categories.length !== 0) {
    return state;
  }
  const newState = { ...state };
  const dataFromAPI = await getOperations();
  var operationsData = transformOperationsData(dataFromAPI);
  newState.operationsData = { ...operationsData };
  return newState;
};

// REFACTORY TO DO : REPORT
export const getReportsFromAPI = async (state) => {
  if (state.reportsData && state.reportsData.categories.length !== 0) {
    return state;
  }
  const newState = { ...state };
  const dataFromAPI = await getReports();
  var reportsData = transformReportsData(dataFromAPI);

  newState.reportsData = { ...reportsData };
  return newState;
};

// REFACTORY TO DO : PROCESSOR
export const selectCategory = (state, category) => {
  const newState = { ...state };

  newState.menuGridInfo = {
    ...state.menuGridInfo,
    selectedCategory: category,
    showItemsInsteadOfCategories: true,
  };
  return newState;
};

export const showCategories = (state) => {
  const newState = { ...state };
  newState.menuGridInfo = {
    ...state.menuGridInfo,
    selectedCategory: {},
    showItemsInsteadOfCategories: false,
  };
  return newState;
};

export const emptyChecks = async (state, filter, data) => {
  const newState = { ...state };
  newState.clearing = {
    searchResult: [],
    searched: false,
    loading: false,
  };
  return newState;
};

export const getChecks = async (state, filter, data) => {
  const newState = { ...state };
  const checks = await getChecksAPI(filter, data);
  newState.clearing = {
    searchResult: [...checks],
    searched: true,
    loading: false,
  };
  return newState;
};

export const setCheckSelected = (state, check) => {
  const newState = { ...state };
  newState.clearing = {
    ...newState.clearing,
    checkSelected: check,
  };
  return newState;
};

export const setLoadingChecksSearch = (state, loading) => {
  const newState = { ...state };
  newState.clearing.loading = loading;
  return newState;
};

export const setLastValue = (state, fieldNumber, valueToSave = '') => {
  const newState = { ...state };
  let { lastValues } = newState;
  const last = lastValues && lastValues[fieldNumber];
  const isNewValue = !last || last !== valueToSave;
  if (isNewValue && valueToSave !== null) {
    newState.lastValues = {
      ...lastValues,
      [fieldNumber]: valueToSave.toString(),
    };
  }

  return newState;
};

/**
 * This action is executed so that the components that are observing
 * the focusElement react to the change of state and try to focus on
 * the field again, for example when closing a modal.
 * @param {object} state State
 */
// REFACTORY TO DO : UI
export const refreshFocusElement = (state) => {
  const newState = { ...state };
  const currentTab = newState.tabs[newState.current];
  if (!currentTab || !currentTab.running) {
    return newState;
  }
  const { focusElement } = currentTab.running;
  currentTab.running.focusElement = focusElement;
  return newState;
};

export const setApiLoading = (state, isLoading, tabId = null) => {
  const newState = { ...state };
  const getNewLoadingVal = (apiLoading, isLoading) => {
    let loadingVal = 0;
    if (!apiLoading && isLoading) {
      loadingVal = 1;
    } else if (isLoading) {
      loadingVal = apiLoading + 1;
    } else if (apiLoading && !isLoading) {
      loadingVal = apiLoading - 1;
    }
    return loadingVal;
  };
  newState.apiLoading = getNewLoadingVal(newState.apiLoading, isLoading);

  let tabIndex;
  if (tabId !== null) {
    tabIndex = tabIndexOf({ tabId }, newState.tabs);
  } else {
    tabIndex = newState.current;
  }
  const currentTab = newState.tabs[tabIndex];
  if (!currentTab) {
    return newState;
  }
  currentTab.apiLoading = getNewLoadingVal(currentTab.apiLoading, isLoading);
  return newState;
};
