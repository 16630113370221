import { store } from '../../../store';
import { getBitacoraServiciosWS, getBitacoraServiciosWSCount } from '../../../api/bitacora';
import { formatDateForServer } from './filterFields';
import { getButtonsState } from './paginado';

const getServiciosWebFilterResult = (filterParams, filterListID, intl, setDisabledButtonPrev, setDisabledButtonNext, setLoading) => {
  let {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    usuario,
    empresa,
    sucursal,
    sucursalEmpresa,
    canal,
    operacion,
    persistenceUnit,
  } = filterParams;

  let filterData = {};
  filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
  store.setState({
    bitacoraApp: {
      ...store.getState().bitacoraApp,
      ...filterData,
    },
  });

  let defaultValue;
  fechaHoraDesde = formatDateForServer(fechaHoraDesde);
  fechaHoraHasta = formatDateForServer(fechaHoraHasta);
  firstResult = firstResult || 0;
  maxResults = maxResults || 201;
  usuario = usuario || defaultValue;
  empresa = empresa || defaultValue;
  sucursal = sucursal || defaultValue;
  sucursalEmpresa = sucursalEmpresa || defaultValue;
  canal = canal || defaultValue;
  operacion = operacion || defaultValue;
  persistenceUnit = persistenceUnit || 'Local';
  const params = {
    fechaHoraDesde,
    fechaHoraHasta,
    firstResult,
    maxResults,
    usuario,
    empresa,
    sucursal,
    sucursalEmpresa,
    canal,
    operacion,
    persistenceUnit,
  };
  const resultColumns = [
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_datetime_init',
        defaultMessage: 'Fecha Inicial',
      }),
      accessor: 'datetime_init',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_datetime_end',
        defaultMessage: 'Fecha Final',
      }),
      accessor: 'datetime_end',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 50,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_transaction_id',
        defaultMessage: 'TransaccionId',
      }),
      accessor: 'transaction_id',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_channel',
        defaultMessage: 'Canal',
      }),
      accessor: 'channel',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_usuario',
        defaultMessage: 'Usuario',
      }),
      accessor: 'usuario',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_sucursal_empresa',
        defaultMessage: 'Sucursal',
      }),
      accessor: 'sucursal_empresa',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_ope_number',
        defaultMessage: 'Operacion',
      }),
      accessor: 'ope_number',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_ip_address',
        defaultMessage: 'IP',
      }),
      accessor: 'ip_address',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_mac_address',
        defaultMessage: 'Mac',
      }),
      accessor: 'mac_address',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_response_http_code',
        defaultMessage: 'Response',
      }),
      accessor: 'response_http_code',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_service_type',
        defaultMessage: 'Tipo',
      }),
      accessor: 'service_type',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.titulo_service_method',
        defaultMessage: 'Metodo',
      }),
      accessor: 'service_method',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
  ];

  getBitacoraServiciosWSCount(params)
    .then((countData) => {
      getBitacoraServiciosWS(params)
        .then((data) => {
          let buttonNextState, buttonPrevState;
          [buttonNextState, buttonPrevState] = getButtonsState(countData, firstResult);
          let filterData = {};
          filterData[filterListID] = {
            ...store.getState().bitacoraApp[filterListID],
            resultColumns,
            resultData: data,
            currentPage: Math.ceil(firstResult / maxResults) + 1,
            pageCount: Math.ceil(countData / maxResults),
            resultCount: countData,
            buttonNextState,
            buttonPrevState,
          };
          setDisabledButtonNext(buttonNextState);
          setDisabledButtonPrev(buttonPrevState);
          store.setState({
            bitacoraApp: {
              ...store.getState().bitacoraApp,
              ...filterData,
            },
          });
          if (setLoading) {
            setLoading(false);
          }
        })
        .catch((err) => {
          if (setLoading) {
            setLoading(false);
          }
          console.log(err);
        });
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
      console.log(err);
    });
};

export { getServiciosWebFilterResult };
