import { API } from '../config';
import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { getUrl, handleError } from './handlers';

export const getPersonSignatures = async (personPhysicalNumber) => {
  let sessionId = browserStorage.sessionGet(USER_SESSION);

  try {
    const query = {
      endpoint: 'personidentification',
      action: 'getPersonSingatures',
      params: `sessionId=${sessionId}&numeroPersonaFisica=${personPhysicalNumber}`,
    };

    const response = await axios.post(getUrl(query));

    if (!response.data) {
      throw new Error(handleError(response));
    }
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getValidationData = async (personPhysicalNumber) => {
  let sessionId = browserStorage.sessionGet(USER_SESSION);

  try {
    const query = {
      endpoint: 'personidentification',
      action: 'getValidationData',
      params: `sessionId=${sessionId}&numeroPersonaFisica=${personPhysicalNumber}`,
    };

    const response = await axios.post(getUrl(query));

    if (!response.data) {
      throw new Error(handleError(response));
    }
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export let isUserAuthorizerSignatures = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/personidentification/isUsuarioAutorizadorFirmas?sessionId=${session}`).then(({ data }) => data);
};

export let saveSignauresInfo = (numberPhysicalPerson, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/saveSignauresInfo?sessionId=${session}&numeroPersonaFisica=${numberPhysicalPerson}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let addPersonFingerData = (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(`${API}TopazWebClientWS/resources/personidentification/addPersonFingerData?sessionId=${session}`, body, { headers })
    .then()
    .catch((err) => console.log(err));
};

export let getPhotos = (numberPhysicalPerson) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(`${API}TopazWebClientWS/resources/personidentification/getPhotos?sessionId=${session}&numeroPersona=${numberPhysicalPerson}`)
    .then(({ data }) => data);
};

export let savePhotosInfo = (numberPhysicalPerson, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/savePhotosInfo?sessionId=${session}&numeroPersonaFisica=${numberPhysicalPerson}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let getDocDesc = (codeDoc) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(`${API}TopazWebClientWS/resources/personidentification/getDocDesc?sessionId=${session}&codigoDoc=${codeDoc}`)
    .then(({ data }) => data);
};

export let getPersonDocPhotoFromNumber = (numberPhysicalPerson, codeDoc, nroDocumento) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  if (nroDocumento) {
    return axios
      .post(
        `${API}TopazWebClientWS/resources/personidentification/getPersonDocPhoto?sessionId=${session}&numPersona=${numberPhysicalPerson}&numDoc=${nroDocumento}&codigoDoc=${codeDoc}`,
      )
      .then(({ data }) => data);
  } else {
    return axios
      .post(
        `${API}TopazWebClientWS/resources/personidentification/getPersonDocPhotoFromNumber?sessionId=${session}&personNumber=${numberPhysicalPerson}`,
      )
      .then(({ data }) => data);
  }
};

export let getCodesDocuments = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/personidentification/getCodigosDocumentos?sessionId=${session}`).then(({ data }) => data);
};

export let saveDocumentsImagesInfo = (numberPhysicalPerson, body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/saveDocumentsImagesInfo?sessionId=${session}&numeroPersonaFisica=${numberPhysicalPerson}`,
      body,
      { headers },
    )
    .then(({ data }) => data);
};

export let getPhysicalPerson = (numberPhysicalPerson) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/getPersonasFisicas?sessionId=${session}&numeroPersona=${numberPhysicalPerson}&primerNombre&segundoNombre&apellidoPaterno&apellidoMaterno`,
    )
    .then(({ data }) => data);
};

/* Validation */

export let getPowerTypes = () => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios.post(`${API}TopazWebClientWS/resources/personidentification/getTiposPoderes?sessionId=${session}`).then(({ data }) => data);
};

export let getProxies = (entityId, powerType, entityType) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/obtenerApoderados?sessionId=${session}&idEntidad=${entityId}&tipoPoder=${powerType}&tipoEntidad=${entityType}`,
    )
    .then(({ data }) => data);
};

export let getTexts = (entityId, powerType, entityType) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/getTextos?sessionId=${session}&idEntidad=${entityId}&tipoPoder=${powerType}&tipoEntidad=${entityType}`,
    )
    .then(({ data }) => data);
};

export const validateProxies = async (entityId, powerType, entityType, body) => {
  const sessionId = browserStorage.sessionGet(USER_SESSION);

  try {
    const query = {
      endpoint: 'personidentification',
      action: 'validarApoderados',
      params: `sessionId=${sessionId}&idEntidad=${entityId}&tipoPoder=${powerType}&tipoEntidad=${entityType}`,
    };

    const response = await axios.post(getUrl(query), body);

    if (!response.data) {
      throw new Error(handleError(response));
    }
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const validateProxiesByAmount = async (currency, amount, personNumbers, maxAmountType) => {
  const sessionId = browserStorage.sessionGet(USER_SESSION);

  try {
    const query = {
      endpoint: 'personidentification',
      action: 'validarApoderadosPorMonto',
      params: `sessionId=${sessionId}&moneda=${currency}&monto=${amount}`,
    };
    const body = {
      personas: personNumbers,
      tipoMontoMaximo: maxAmountType,
    };
    const response = await axios.post(getUrl(query), body);

    if (!response.data) {
      throw new Error(handleError(response));
    }
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getAmountType = async (entityId, powerType, entityType) => {
  const sessionId = browserStorage.sessionGet(USER_SESSION);

  try {
    const query = {
      endpoint: 'personidentification',
      action: 'getTipoMonto',
      params: `sessionId=${sessionId}&idEntidad=${entityId}&tipoPoder=${powerType}&tipoEntidad=${entityType}`,
    };
    const response = await axios.post(getUrl(query));

    if (!response.data) {
      throw new Error(handleError(response));
    }
    return response.data.message;
  } catch (error) {
    return handleError(error);
  }
};

export let getTypeAmount = (idEntity, typePower, typeEntity) => {
  const session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/getTipoMonto?sessionId=${session}&idEntidad=${idEntity}&tipoPoder=${typePower}&tipoEntidad=${typeEntity}`,
    )
    .then(({ data }) => data);
};

export let signaturesActionOK = (operationID, message) => {
  const session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(`${API}TopazWebClientWS/resources/signatures/signaturesActionOK?sessionId=${session}&operationID=${operationID}&message=${message}`)
    .then(({ data }) => data);
};

export let signaturesActionCancel = (operationID) => {
  const session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(`${API}TopazWebClientWS/resources/signatures/signaturesActionCancel?sessionId=${session}&operationID=${operationID}`)
    .then(({ data }) => data);
};

export let addPhoto = (numberPhysicalPerson, body) => {
  const session = browserStorage.sessionGet(USER_SESSION);

  var headers = { 'Content-Type': 'application/json' };
  return axios
    .post(`${API}TopazWebClientWS/resources/personidentification/addPhoto?sessionId=${session}&numeroPersona=${numberPhysicalPerson}`, body, {
      headers,
    })
    .then(({ data }) => data);
};

export let getRepresentatives = (idEntity, typePower, typeEntity) => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios
    .post(
      `${API}TopazWebClientWS/resources/personidentification/obtenerApoderados?sessionId=${session}&idEntidad=${idEntity}&tipoPoder=${typePower}&tipoEntidad=${typeEntity}`,
    )
    .then(({ data }) => data);
};

export let permiteAgregarPegarFirmas = () => {
  let session = browserStorage.sessionGet(USER_SESSION);

  return axios.post(`${API}TopazWebClientWS/resources/personidentification/permiteAgregarPegarFirmas?sessionId=${session}`).then(({ data }) => data);
};
