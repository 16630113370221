import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from '../src/components/Home/HomeViewModel';
import ErrorBoundary from '../UI-kit/ErrorBoundary';
import Landing from '../src/components/Landing/LandingView';
import SecureRoute from './SecureRoute';
import { GlobalStylesProvider } from './styles/GlobalStylesContext';
import { ThemeProvider } from './themes/ThemeContext';
import { PATHNAME } from '../src/config';

export default class Root extends React.PureComponent {
  render() {
    return (
      <ThemeProvider>
        <GlobalStylesProvider>
          <ErrorBoundary>
            <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''}>
              <Switch>
                <Route path={`${PATHNAME}`} component={Landing} exact />
                <SecureRoute path={`${PATHNAME}home`} component={Home} />
                <Route render={() => <div>Page not found</div>} />
              </Switch>
            </BrowserRouter>
          </ErrorBoundary>
        </GlobalStylesProvider>
      </ThemeProvider>
    );
  }
}
