import React from 'react'
import * as fromSession from './session'

const withSession = (BaseComponent) => {
  const HOC = (props) => {
    return (
      <BaseComponent
        {...props}
        {...fromSession}
      />
    )
  }

  return HOC
}

export default withSession
