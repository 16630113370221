import React from 'react';
import { useIntl } from 'react-intl';
import { GroupExecutionInfoViewModel } from '../../monitorTab/GroupExecutionInfoViewModel';
import { ResultsModalViewModel } from '../../resultsModal/ResultsModalViewModel';

import './MonitorModal.scss';

interface MonitorModalViewProps {
  isModalOpen: boolean;
  modalDataName: string;
  modalDataDescription: string;
  executionId: number;
  onModalChange: (isOpen: boolean) => void;
  onModalDataChange: (data: string, name: string) => void;
  onClose: () => void;
}

export function MonitorModalView({
  isModalOpen,
  modalDataName,
  onModalChange,
  onModalDataChange,
  modalDataDescription,
  executionId,
  onClose,
}: MonitorModalViewProps): JSX.Element {
  const intl = useIntl();

  return (
    <div className="monitoring-modal">
      <div className="ml-5 mt-5">{intl.formatMessage({ id: 'Monitor' })}</div>
      <div className="separator mt-4 border-t w-full" />
      <div className="monitor-container justify-start">
        {isModalOpen && <ResultsModalViewModel name={modalDataName} description={modalDataDescription} onModalChange={onModalChange} />}
        <GroupExecutionInfoViewModel executionId={executionId} onModalChange={onModalChange} onModalDataChange={onModalDataChange} />
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          onClick={onClose}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {intl.formatMessage({ id: 'close' })}
        </button>
      </div>
    </div>
  );
}
