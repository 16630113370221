import { css } from 'aphrodite';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'unistore/react';

import { storeActions } from '../../../../src/store';
import { useGlobalStyles } from './../../../styles/GlobalStylesContext';
import { Filters } from './../Constants';
import AccountInput from './Fields/AccountInput';
import ErrorMessage from './Fields/ErrorMessage';
import SubmitButton from './Fields/SubmitButton';

const ByAccountFilter = (props) => {
  const [error, setError] = useState('');
  const [account, setAccount] = useState('0');
  const intl = useIntl();
  // eslint-disable-next-line no-unused-vars
  const hasPermission = true;

  const validate = () => {
    if (!account) {
      const errMsg = intl.formatMessage({
        id: 'pleaseCompleteAllFields',
        defaultMessage: 'Por favor complete todos los campos',
      });
      throw new Error(errMsg);
    }
  };

  const createData = () => {
    return {
      cuenta: account,
    };
  };

  useEffect(() => setError(''), [account]);

  const onSubmit = (event) => {
    event.preventDefault();
    try {
      validate();
      const data = createData();
      props.setLoadingChecksSearch(true);
      props.getChecks(Filters.BY_ACCOUNT, data);
    } catch (exception) {
      setError(exception.message);
    }
  };

  const globalStyles = useGlobalStyles();

  return (
    <form onSubmit={onSubmit}>
      <div className={css(globalStyles.fieldsWithoutButton)}>
        <AccountInput account={account} onAccountChange={setAccount} />
      </div>
      <ErrorMessage message={error} />
      <SubmitButton />
    </form>
  );
};

export default connect(null, storeActions)(ByAccountFilter);
