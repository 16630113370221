export const RUNNING_PROCESS: string = 'R';
export const UNDEFINED_PROCESS: string = 'U';
export const ABORTED_PROCESS: string = 'A';
export const STOPPED_PROCESS: string = 'S';
export const FINISHED_PROCESS: string = 'F';
export const TAB_SPACES: number = 6;
export const ALL_STATES_VALUE: string = '1';
export const TODAY: string = 't';
export const YESTERDAY: string = 'y';
export const THIS_WEEK: string = 'w';
export const THIS_MONTH: string = 'm';
export const FIRST_LEVEL_NODE: number = 1;
export const ROOT_LEVEL_NODE: number = 0;
export const RADIO_OPTION: string = 'option';
export const CHECKBOX_SELECTION: string = 'checkbox';
export const ERRORS_CHECKBOX: string = 'E';
export const WARNINGS_CHECKBOX: string = 'W';
export const INFORMATION_CHECKBOX: string = 'I';
export const RESULT_OK: string = 'OK';
export const RESULT_ERROR: string = 'ERROR';
export const RESULT_WARNING: string = 'WARNING';
export const OPTION_MESSAGES: string = '1';
export const OPTION_PARAMETERS: string = '2';
