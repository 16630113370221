import { css, StyleSheet } from 'aphrodite';
import { PulseLoader } from 'halogenium';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'unistore/react';

import { storeActions } from './../../actions';
import { useTheme } from './../../themes/ThemeContext';

const ListOfChecks = (props) => {
  const { checksData, searched, loading, onSelectCheck, setSelectedCheck } = props;
  const intl = useIntl();
  const themeState = useTheme();
  const history = useHistory();

  const { background, border, text } = themeState.theme;

  const styles = StyleSheet.create({
    container: {
      marginLeft: '.75rem',
      flex: 1,
      color: text.text,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: border.border,
      maxHeight: '90vh',
      overflow: 'auto',
      backgroundColor: background.light2,
    },
    table: {
      display: 'table',
      width: '100%',
    },
    headerRow: {
      display: 'table-header-group',
    },
    headerCell: {
      backgroundColor: background.light2,
      color: text.dark,
      fontSize: '1rem',
      position: 'sticky',
      top: 0,
    },
    body: {
      display: 'table-row-group',
    },
    row: {
      color: text.text,
      textDecoration: 'none',
      cursor: 'pointer',
      display: 'table-row',
      backgroundColor: background.light,
      transition: '.2s',
      ':hover': {
        backgroundColor: background.background,
        color: text.dark,
      },
    },
    evenRow: {
      backgroundColor: background.light2,
    },
    cell: {
      display: 'table-cell',
      padding: '.6rem',
      fontSize: '.85rem',
      borderWidth: '0 0 1px 0',
      borderColor: border.light,
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
    },
    iconNoData: {
      fontSize: '1.5rem',
      marginBottom: '.5rem',
      opacity: '70%',
    },
  });
  const labelMICR = intl.formatMessage({
    id: 'micr',
    defaultMessage: 'MICR',
  });
  const labelAccount = intl.formatMessage({
    id: 'account',
    defaultMessage: 'Cuenta',
  });
  const labelAmount = intl.formatMessage({
    id: 'amount',
    defaultMessage: 'Importe',
  });
  const labelNoData = intl.formatMessage({
    id: 'noChecksData',
    defaultMessage: 'No hay cheques para el filtro seleccionado',
  });
  const tryNewSearch = intl.formatMessage({
    id: 'tryNewSearch',
    defaultMessage: 'Intente realizar una nueva búsqueda',
  });

  const onCheckClick = (micrLine) => {
    onSelectCheck(micrLine); // Save selected Check on State
    let {
      location: { pathname },
    } = history;
    if (pathname.endsWith('/')) {
      pathname = pathname.slice(0, -1);
    }
    const url = `${pathname}/check?micr=${micrLine}`;
    history.push(url);
    console.log(history);
  };

  if (loading) {
    return (
      <div className={css(styles.container, styles.center)}>
        <PulseLoader color={text.text} size="10px" />
      </div>
    );
  }
  if (!searched) {
    return (
      <div className={css(styles.container, styles.center)}>
        <p>{tryNewSearch}</p>
      </div>
    );
  }
  if (checksData.length === 0) {
    return (
      <div className={css(styles.container, styles.center)}>
        <div className={css(styles.iconNoData)}>
          <i className="fas fa-exclamation-triangle" />
        </div>
        <p>
          {labelNoData} <br /> {tryNewSearch}
        </p>
      </div>
    );
  }
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.table)}>
        <div className={css(styles.headerRow)}>
          <div className={css(styles.cell, styles.headerCell)}>{labelMICR}</div>
          <div className={css(styles.cell, styles.headerCell)}>{labelAccount}</div>
          <div className={css(styles.cell, styles.headerCell)}>{labelAmount}</div>
        </div>
        <div className={css(styles.body)}>
          {checksData.map((check, index) => {
            return (
              <div
                key={check.micrLine}
                className={css(styles.row, index % 2 && styles.evenRow)}
                onClick={() => {
                  onCheckClick(check.micrLine);
                  setSelectedCheck(check);
                }}
              >
                <div className={css(styles.cell)}>{check.micrLine}</div>
                <div className={css(styles.cell)}>{check.cuenta}</div>
                <div className={css(styles.cell)}>{check.importe}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { searchResult, searched, loading } = state.clearing;
  return {
    checksData: searchResult,
    searched,
    loading,
  };
};

export default connect(mapStateToProps, storeActions)(ListOfChecks);
