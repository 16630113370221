import { dispatchActions, store, storeActions } from '../../src/store';
import { SignatureWindow, SignatureValidationWindow, ReportProcessor } from '../../UI-kit';
import ScannerWindowView from '../../UI-kit/components/CheckScanner/Views/ScannerWindowView';
import { PATHNAME } from '../../src/config';
import { connect } from 'unistore/react';
import { injectIntl } from 'react-intl';

const SIGNATURE_NAME = 'person_identification';
const SIGNATURE_NAME_VALIDATION = 'person_identification_validacion_poderes';
const CHECK_NAME = 'checks';
const REPORT_TO_PRINT = 'report_to_print';
const REPORT = 'report';

export default class ApplicationToLaunch {
  constructor({ name, data, idNode, history }) {
    this.name = name;
    this.data = data;
    this._idNode = idNode;
    this.history = history;
  }
  // cambiar name por el data
  // en el data pedir name y si los names no coinciden fijarse en data y hacer split
  // buscando checks en el primer lugar del array resultante
  getApplicationToLaunchType(name, data) {
    if (name === SIGNATURE_NAME) {
      return SignatureWindow;
    } else if (name === SIGNATURE_NAME_VALIDATION) {
      return SignatureValidationWindow;
    } else if (name === REPORT_TO_PRINT || name === REPORT) {
      return ReportProcessor;
      // let infoForm = await this.reportToPrint(data.pages);
      /* this.reportToPrint(data.pages).then((infoForm) => {
        debugger;
        let state = store.getState();
        if (
          state.tabsReport &&
          !(state.tabsReport.length === 1 && state.tabsReport[0].emptyTab)
        ) {
          state = addEmptyTabReport(state);
        }
        const { name, fileExtension } = infoForm;
        state = addTabReport(
          state,
          {
            name,
            fileExtension,
          },
          true,
          false,
        );
        store.setState(state);
        debugger;
        return null;
      }); */
      /* if (validResponse(infoForm)) {
        if (tabsReport && !(tabsReport.length === 1 && tabsReport[0].emptyTab)) {
          addEmptyTabReport();
        }
        const { name, fileExtension } = infoForm;
        addTabReport(
          {
            name,
            fileExtension,
          },
          true,
          false,
        );
        openReports = true;
      } */
    } else if (name === 'posting_manager') {
      let date = data.DATE_KEY.slice(0, 8);
      let posting = data.POSTING_KEY;
      let branch = data.BRANCH_KEY;
      let postingFilter = {
        allRecords: false,
        branchGroup: branch,
        description: null,
        machineNumber: null,
        operationNumber: null,
        postingBegin: posting,
        postingEnd: posting,
        processDate: date,
      };
      this.history.push(`${PATHNAME}home/viewer`);
      const state = store.getState();
      state.postingFilter = postingFilter;
      let filterInfo = store.getState().tabs[store.getState().current].running.trees.filter((element) => element.id !== this._idNode);
      state.tabs[store.getState().current].running.trees = filterInfo;
      store.setState(state);
    } else if (data.split(' ')[0] === CHECK_NAME) {
      return ScannerWindowView;
    } else if (name === 'command' && data && data.split(' ')[0] === 'jbpm' && data.split(' ')[1] === '/show') {
      this.history.push(`${PATHNAME}home/inbox`);
      let filterInfo = store.getState().tabs[store.getState().current].running.trees.filter((element) => element.id !== this._idNode);
      const state = store.getState();
      let taskID = data.split(' ')[2];
      let workflowTasks = state.workflowTasks;
      workflowTasks[0].taskID = taskID;
      state.workflowTasks = workflowTasks;
      state.tabs[store.getState().current].running.trees = filterInfo;
      state.loadLeaf = true;
      store.setState(state);
    } else {
      return null;
    }
  }

  props() {
    return {
      name: this.name,
      data: this.data,
      removeComponentTree: (removeTreeNode) => {
        if (this.data.split && this.data.split(' ')[0] === CHECK_NAME) {
          store.setState(dispatchActions.removeTreeNode(store.getState(), null, this._idNode));
        } else {
          removeTreeNode(null, this._idNode);
        }
      },
    };
  }

  component() {
    return this.getApplicationToLaunchType(this.name, this.data);
  }
}

connect(null, storeActions)(injectIntl(ApplicationToLaunch));
