import { SimpleRequestNotice } from '../../UI-kit/index';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generateLineIDString } from '../../UI-kit/helpers/operationHelper';

export default class SimpleRequestToDisplay {
  constructor(props) {
    const { displayInfo = {}, lineID, 'class-name': requestClassName, idNode } = props;
    this._displayInfo = displayInfo;
    this._lineID = lineID;
    this._requestClassName = requestClassName;
    this._nodeId = idNode;
  }

  nodeId() {
    return this._nodeId;
  }

  requestClassName() {
    return this._requestClassName;
  }

  transactionNumber() {
    return this._lineID ? this._lineID.transactionNumber : '';
  }

  lineNumber() {
    return this._lineID ? this._lineID.lineNumber : '';
  }

  transactionLineLabel() {
    return generateLineIDString(this._lineID);
  }

  title() {
    return <FormattedMessage id="finishingOperation" defaultMessage={`Finalizando operación...`} />;
  }

  message() {
    return this._displayInfo.text;
  }

  type() {
    return this._displayInfo.type;
  }

  textIsFormula() {
    return this._displayInfo.textIsFormula;
  }

  buttons() {
    return this._displayInfo.buttons;
  }

  defaultButton() {
    return this._displayInfo.defaultButton;
  }

  style() {
    return {};
  }

  options() {
    return {};
  }

  acceptHandler() {
    this.dialogService.Accept(this.values);
  }

  props(state) {
    return {
      nodeId: this.nodeId(),
      finishOperation: this.requestClassName() === 'com.topsystems.jtopaz.opmgr.topaz41.events.OperationToFinishEvent',
      cancelOperation: this.requestClassName() === 'com.topsystems.jtopaz.opmgr.topaz41.events.OperationToCancelEvent',
      resultFinish:
        this.requestClassName() === 'com.topsystems.jtopaz.opmgr.topaz41.events.OperationToFinishEvent'
          ? 1
          : this.requestClassName() === 'com.topsystems.jtopaz.opmgr.topaz41.events.OperationToCancelEvent'
          ? 0
          : undefined,
    };
  }

  component() {
    return SimpleRequestNotice;
  }
}
