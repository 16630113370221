import React, { Component } from 'react';
import HelpGrid from './HelpGrid';
import { connect } from 'unistore/react';
import { storeActions } from '../../src/store';
import { ABOVE_ALL } from '../constants/zIndexValues';
import { getTabIdByNodeId, getTabInformation } from '../../src/selectors';
import { css, StyleSheet } from 'aphrodite';
import GridPopup from './GridPopup';

export class HelpPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueSelected: '',
    };

    this.acceptRef = React.createRef();
    this.cancelRef = React.createRef();
    this.focusOnAccept = this.focusOnAccept.bind(this);
  }

  focusOnAccept() {
    if (this.acceptRef && this.acceptRef.current) {
      //  this.acceptRef.current.focus();
    }
  }

  componentDidMount() {
    const { pauseFocus } = this.props;
    // pauseFocus();
  }

  componentWillUnmount() {
    const { resumeFocus } = this.props;
    // resumeFocus();
  }

  render() {
    let { acceptHandler, cancelHandler, fieldNumber, focusOn, inputValue, lineNumber, nodeId, transactionLineLabel, transactionNumber } = this.props;
    let hasFocus = focusOn && focusOn[0] === transactionLineLabel;

    const position = { width: 'auto', height: 'auto' };
    const gridStyle = { width: '480px', height: '250px' };

    const styles = StyleSheet.create({
      titleContainer: {
        width: '100%',
        minHeight: '1.5rem',
        padding: '.5rem 1rem',
        color: '#6B7280',
        cursor: 'move',
        textAlign: 'left',
        flex: '1 0 auto',
        borderBottom: `1px solid #DCE0E6`,
      },
      helpWrapper: {
        minHeight: '300px',
        minWidth: '400px',
        height: '346px',
        position: 'absolute',
        top: '105%',
        width: '500px',
        zIndex: ABOVE_ALL,
      },
    });

    return (
      <div className={this.props.showingHelpPopup && css(styles.helpWrapper)}>
        <GridPopup
          nodeId={nodeId}
          fieldNumber={fieldNumber}
          style={gridStyle}
          position={position}
          fromHelp={true}
          inputValue={inputValue}
          transactionNumber={transactionNumber}
          lineNumber={lineNumber}
          transactionLineLabel={transactionLineLabel}
          acceptButtonRef={this.acceptRef}
          acceptHandler={acceptHandler}
          cancelHandlerHelp={cancelHandler}
          onChange={(e) => {
            this.setState({ valueSelected: e.target.value });
          }}
          onHelpSelect={(event) => {
            this.setState({ valueSelected: event });
            acceptHandler({ [transactionLineLabel]: { value: event.length === 1 ? event[0] : event } });
          }}
          focusedTable
          showingHelpPopup={this.props.showingHelpPopup}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { nodeId } = ownProps;
  const tabId = getTabIdByNodeId(state, nodeId);
  const { operationData, focusOn } = getTabInformation(state, tabId);

  const { grids } = operationData;

  return {
    focusOn,
    grids,
  };
};

export default connect(mapStateToProps, storeActions)(HelpPopup);
