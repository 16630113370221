import React, { Component } from 'react';
import { connect } from 'unistore/react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
// import IcoMoon from 'react-icomoon'
import { PulseLoader } from 'halogenium';
import { injectIntl, FormattedMessage } from 'react-intl';

import { store, storeActions } from '../store';

const escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestions = (options, value, multipleSections) => {
  const escapedValue = escapeRegexCharacters(value.trim());
  if (options === null) return [];
  const regex = new RegExp(escapedValue, 'i');
  if (multipleSections) {
    var filtered = options
      .map((option) => {
        return {
          subOptions: option.subOptions
            .filter((subOption) => (regex.test(`${subOption.id}`) || regex.test(`${subOption.name}`)) && subOption.subOperation === false)
            .sort((subA, subB) => subA.id - subB.id),
        };
      })
      .filter((option) => option.subOptions.length > 0);
    var subOptions = [];
    if (filtered && filtered.length) {
      for (var i = 0; i < filtered.length; i++) {
        if (filtered[i].subOptions && filtered[i].subOptions.length) {
          for (var j = 0; j < filtered[i].subOptions.length; j++) {
            subOptions.push(filtered[i].subOptions[j]);
          }
        }
      }
    }
    subOptions.sort((subA, subB) => subA.id - subB.id);
    var retorno = [];
    retorno[0] = { subOptions: subOptions };
    return retorno;
  }
  return options
    .filter((option) => (regex.test(`${option.id}`) || regex.test(`${option.name}`)) && option.subOperation === false)
    .sort((optA, optB) => optA.id.toString().length - optB.id.toString().length);
};

const getSuggestionValue = (suggestion) => `${suggestion.id} - ${suggestion.name}`;

const shouldRenderSuggestions = () => true;

const renderSectionTitle = (section) => <strong>{section.title}</strong>;

const getSectionSuggestions = (section) => section.subOptions;

const renderSuggestion = (suggestion, { query }) => {
  const matches = AutosuggestHighlightMatch(`${suggestion.id} - ${suggestion.name}`, query);
  const parts = AutosuggestHighlightParse(`${suggestion.id} - ${suggestion.name}`, matches);

  return (
    <span>
      {parts.map((part, index) => {
        const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;
        return (
          <span className={className} key={index}>
            {part.text}
          </span>
        );
      })}
    </span>
  );
};

const renderSuggestionsContainer = ({ containerProps, children, query }) => <div {...containerProps}>{children}</div>;

export class FilterAutosuggest extends Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: [],
      suggestionSelected: null,
      options: null,
      loading: false,
      errorMessage: '',
      operationNumberSearch: '',
    };

    this.mounted = false;
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
  }

  componentDidMount() {
    const { getOptions, multipleSections, title, intl } = this.props;
    this.mounted = true;

    this.mounted &&
      this.setState({
        errorMessage: '',
        loading: true,
      });

    getOptions()
      .then((data) => {
        if (data && data instanceof Array && data.length === 0) {
          this.mounted &&
            this.setState({
              options: null,
              errorMessage: `${intl.formatMessage({
                id: 'youDontHavePermissionObtainList',
                defaultMessage: 'Usted no tiene permisos para obtener el listado de',
              })} ${title.toLowerCase()}`,
              loading: false,
            });
        } else if (data && data instanceof Array) {
          let options = null;
          if (multipleSections) {
            options = data.map((option) => ({
              id: option.id,
              name: option.description || option.name,
              subOptions: option.itemsList.map((subOption) => ({
                id: subOption.id,
                name: subOption.description,
                look: subOption.utilizaEstilo,
                subOperation: subOption.subOperation,
              })),
            }));
          } else {
            options = data.map((option) => ({
              id: option.id,
              name: option.name || option.description,
            }));
          }
          this.mounted &&
            this.setState({
              options,
              errorMessage: '',
              loading: false,
            });
        } else {
          this.mounted &&
            this.setState({
              options: null,
              errorMessage: `ERROR${data ? `: ${data}` : null}`,
              loading: false,
            });
        }
      })
      .catch((err) => {
        const { message } = err;
        this.mounted &&
          this.setState({
            errorMessage: message,
            loading: false,
          });
        const { openGlobalModal } = this.props;
        this.mounted &&
          openGlobalModal(
            <div>
              <FormattedMessage id="errorOccurredObtainingList" defaultMessage={`Ha ocurrido un error al obtener el listado de`} />{' '}
              {title.toLowerCase()}:
              <div className="mt-4">
                <i title={err.config.url}>{message}</i>
              </div>
            </div>,
            true,
          );
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onChange(event, { newValue, method }) {
    this.setState({
      value: newValue,
    });
  }

  onSuggestionsFetchRequested({ value }) {
    const { options } = this.state;
    const { multipleSections } = this.props;
    const suggestions = getSuggestions(options, value, multipleSections);

    const isInputBlank = value.trim() === '';

    const noSuggestions = !isInputBlank && suggestions.length === 0;

    this.setState({
      suggestions,
      noSuggestions,
    });
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  onKeyUpHandler(e) {
    if (e.key !== 'Enter' && !isNaN(e.key)) {
      const operationNumberSearch = this.state.operationNumberSearch + e.key;
      this.setState({ operationNumberSearch });
    }
    if (
      e.key === 'Enter' &&
      this.state.suggestions &&
      this.state.suggestions[0].subOptions &&
      !this.state.suggestions[0].subOptions.length &&
      !this.state.notShowing
    ) {
      this.onSuggestionSelected(null, { suggestion: { id: this.state.operationNumberSearch } });
    } else if (this.state.notShowing) {
      this.setState({ notShowing: false });
    }
  }

  onSuggestionSelected(event, { suggestion }) {
    const { actionSelectOption, onSelectOption } = this.props;
    let recordOn = store.getState().recordOn;
    if (recordOn) {
      let textOperation = [];
      textOperation.push('oper' + suggestion.id + '\n');
      textOperation.push('Feature: Operacion: ' + suggestion.id + '\n');
      textOperation.push('Scenario: ' + suggestion.name + '\n');
      window.sessionStorage.setItem('textOperation', JSON.stringify(textOperation));
    }

    if (actionSelectOption && onSelectOption) {
      actionSelectOption(suggestion.id).then((data) => {
        onSelectOption(data);
      });
    } else if (onSelectOption) {
      onSelectOption(
        suggestion.id, // opNumber
        suggestion.name, // opName
        null, // postingNumber
        true, // saveRecent
        suggestion.look, // opLook
      );
    }
    this.setState({
      value: '',
      operationNumberSearch: '',
      suggestionSelected: suggestion,
    });
  }

  render() {
    const { placeholder, multipleSections } = this.props;
    const { value, suggestions, noSuggestions, options, loading, errorMessage } = this.state;

    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange,
      id: 'inputOperationNumber',
    };

    return (
      <div id="optionsContainer" onKeyUp={(e) => this.onKeyUpHandler(e)}>
        {loading ? (
          <div className="h-12 text-center pt-4">
            <PulseLoader className="text-primary" color="currentColor" size="8px" margin="1px" />
          </div>
        ) : errorMessage !== '' ? (
          `${errorMessage}`
        ) : (
          <div className="flex justify-between items-center rounded-sm search-input" id="autosuggestContainer">
            <div className="w-full text-left text-base">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                shouldRenderSuggestions={shouldRenderSuggestions}
                renderSuggestion={renderSuggestion}
                renderSuggestionsContainer={renderSuggestionsContainer}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
                highlightFirstSuggestion={true}
                focusInputOnSuggestionClick={false}
                multiSection={multipleSections}
                renderSectionTitle={renderSectionTitle}
                getSectionSuggestions={getSectionSuggestions}
              />
            </div>
            <div
              className={`${options === null ? `text-primary` : noSuggestions ? `text-red` : `text-primary`} absolute font-icons text-lg relative`}
              style={{ left: -35 }}
            ></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ globalMessage = {} }) => globalMessage;

export default connect(mapStateToProps, storeActions)(injectIntl(FilterAutosuggest));
