import React, { Component } from 'react';
import { connect } from 'unistore/react';
import { store, storeActions } from '../../../src/store';
import { FormattedMessage, injectIntl, createIntl, createIntlCache } from 'react-intl';
import { getTabInformation, getTabIdByNodeId } from '../../../src/selectors';
import HelpTable from '../../../UI-kit/components/HelpTable';
import { CompareView } from './CompareView';
import PropTypes from 'prop-types';
import { messages as msg } from '../../translations';
import { LANGUAGE } from '../../config';

const cache = createIntlCache();
let messages = msg[LANGUAGE];
const intl = createIntl(
  {
    locale: LANGUAGE,
    messages,
  },
  cache,
);

const EmptyData = ({ title, style, close }) => {
  return (
    <div
      tabIndex="0"
      autoFocus
      style={style}
      className="bg-white text-center flex items-center justify-center h-full w-100"
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          close();
        }
      }}
    >
      <div>
        <p className="text-text-base" style={{ fontSize: 12 }}>
          {title}
        </p>
      </div>
    </div>
  );
};

class Results extends Component {
  constructor(props, context) {
    super();
    this.state = {
      shownData: this.getCurrentResultData(),
      shownColumns: this.getCurrentResultColumns(),
      selectedIndexRow: undefined,
      selectedIndexRowDoubleClick: undefined,
    };

    this.findSelectedText = this.findSelectedText.bind(this);
    this.intl = intl;
  }

  componentDidUpdate = () => {
    if (this.state.shownData !== this.getCurrentResultData()) {
      this.setState({
        shownData: this.getCurrentResultData(),
        shownColumns: this.getCurrentResultColumns(),
      });
    }
  };

  getCurrentResultData = () => {
    const state = store.getState();
    if (state.bitacoraApp.currentTabIndex) {
      if (state.bitacoraApp[state.bitacoraApp.currentTabIndex]) {
        return state.bitacoraApp[state.bitacoraApp.currentTabIndex].resultData;
      }

      return [];
    }
  };

  getCurrentResultColumns = () => {
    const state = store.getState();
    if (state.bitacoraApp.currentTabIndex) {
      if (state.bitacoraApp[state.bitacoraApp.currentTabIndex]) {
        return state.bitacoraApp[state.bitacoraApp.currentTabIndex].resultColumns;
      }

      return [];
    }
  };

  refreshUI = () => {
    this.forceUpdate();
  };

  findSelectedText = (text, index, rows) => {
    if (text === undefined || rows === undefined || index === undefined) {
      return;
    }

    var i = index;
    if (i === -1) {
      i = 0;
    }
    for (i; i < rows.length; i++) {
      let row = rows[i];
      if (row.descripcion && row.descripcion.indexOf(text) > -1) {
        return i;
      }
    }
  };

  getContent = () => {
    const noDataMessage = (
      <FormattedMessage id="noDataGrid" defaultMessage={`No se encontraron datos. Si es una ventana de ayuda, pruebe Quitar Filtro`} />
    );
    let { transactionLineLabel, text, index } = this.props;
    let columns = this.state.shownColumns ? this.state.shownColumns : [];
    let rows = this.state.shownData ? this.state.shownData : [];

    if ((columns && columns.length === 0) || (rows && !rows.length)) {
      return <EmptyData title={noDataMessage} close={() => {}} />;
    }

    let indexResult = this.findSelectedText(text, index, rows);
    if (store.getState().bitacoraApp.indexBusqueda !== indexResult) {
      store.setState({
        bitacoraApp: {
          ...store.getState().bitacoraApp,
          indexBusqueda: indexResult,
        },
      });
      this.setState({ selectedIndexRow: indexResult });
      let element = document.getElementById(`_R${indexResult}_C0`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
    return (
      <div className={`flex border border-smoke rounded`} style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'nowrap' }}>
        <HelpTable
          fromApp={true}
          title={'asd'}
          transactionLineLabel={transactionLineLabel}
          data={rows && rows.map((row, index) => ({ ...row, id: index }))}
          columns={columns}
          goToLineFn={() => {}}
          selectedIndexProp={this.state.selectedIndexRow}
          getTrProps={(state, rowInfo, runSelection = false) => {
            this.setState({ selectedIndexRow: rowInfo.index });
            return {
              onClick: async (e, handleOriginal) => {},
            };
          }}
          handleKeyDown={(e, selectedIndex) => {
            // Event triggered by HelpTable as simulation for doubleClick
            if (e.key === 'Enter' && store.getState().bitacoraApp[store.getState().bitacoraApp.currentTabIndex].filterParams.compareVisibleRows) {
              this.setState({ selectedIndexRowDoubleClick: selectedIndex });
            }
          }}
          continue={(rowInfo) => {}}
        />
      </div>
    );
  };

  gridInitialState = () => ({
    columns: [],
    rows: [],
    returnedField: '',
    numberOfReturned: 0,
    showAll: false,
    showWindow: false,
    loading: true,
    currentFilterCol: null,
    filtered: false,
    filterOptions: undefined,
  });

  getGridState = () => {
    const { grids, nodeId } = this.props;
    if (grids && grids[nodeId]) {
      return grids[nodeId];
    } else {
      return this.gridInitialState();
    }
  };

  hideWindow = () => {
    this.setState({ selectedIndexRowDoubleClick: null });
  };

  render() {
    const gridContent = this.getContent();
    let bitacoraApp = store.getState().bitacoraApp;
    /* let compareWindow = null;
     if (bitacoraApp && bitacoraApp[bitacoraApp.currentTabIndex] && bitacoraApp[bitacoraApp.currentTabIndex].filterParams.compareVisibleRows) {
      compareWindow = bitacoraApp[bitacoraApp.currentTabIndex].compareWindow;
    } */

    return (
      <div style={{ width: '100%' }}>
        <CompareView
          selectedIndex={this.state.selectedIndexRowDoubleClick}
          filterListID={bitacoraApp.currentTabIndex}
          visibleFields={bitacoraApp && bitacoraApp[bitacoraApp.currentTabIndex].filterParams.compareVisibleRows}
          textAreaFormat={bitacoraApp && bitacoraApp[bitacoraApp.currentTabIndex].filterParams.compareTextAreaFields}
          intl={this.intl}
          hideWindow={this.hideWindow}
        />
        <div style={{ textAlign: 'right' }}>
          {bitacoraApp[bitacoraApp.currentTabIndex].pageCount
            ? `${bitacoraApp[bitacoraApp.currentTabIndex].currentPage} / ${bitacoraApp[bitacoraApp.currentTabIndex].pageCount} (${
                bitacoraApp[bitacoraApp.currentTabIndex].resultCount
              })`
            : ` `}
        </div>

        <br />
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'nowrap' }}>{gridContent}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { nodeId } = ownProps;
  const tabId = getTabIdByNodeId(state, nodeId);
  const { operationID, operationNumber, operationData, focusElement } = getTabInformation(state, tabId);

  const { grids, valueSetElement } = operationData;

  return {
    operationID,
    operationNumber,
    focusElement,
    grids,
    valueSetElement,
  };
};

Results.contextTypes = {
  intl: PropTypes.object,
};
injectIntl(Results);

export default connect(mapStateToProps, storeActions)(Results);
