import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { API } from '../config';
import { USER_SESSION } from '../utils/browserStorage/constants';

export let getReports = () => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios.post(`${API}TopazWebClientWS/resources/menuSession/getReports?sessionID=${session}`).then(({ data }) => data);
};

export let getReportGenerationSpec = (specID, launchedFromReportsTab) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  const application = launchedFromReportsTab ? '&application=reports' : '';
  return axios
    .post(`${API}TopazWebClientWS/resources/reports/getReportGenerationSpec?specID=${specID}&sessionId=${session}` + application)
    .then(({ data }) => data);
};

export let getReport = (body, launchedFromReportsTab) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  const application = launchedFromReportsTab ? '&application=reports' : '';
  return axios.post(`${API}TopazWebClientWS/resources/reports/getReport?sessionId=${session}` + application, body).then(({ data }) => data);
};

export let getForm = (preservDestination, body, insertion, forcepdf) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(
      `${API}TopazWebClientWS/resources/reports/getForm?sessionId=${session}&preservDestination=${preservDestination}&insertion=${insertion}&forcepdf=${forcepdf}`,
      body,
    )
    .then(({ data }) => data);
};

export let printerRaw = (properties, pages, reprint) => {
  return axios.post(`http://localhost:8733/ServiceWebProxy/rawPrinter?properties=${properties}&reprint=${reprint || false}`, pages);
};

export let deletePDF = (fileName) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios.post(`${API}TopazWebClientWS/resources/reports/deletePdf?&sessionId=${session}`, { fileName: fileName }).then(({ data }) => data);
};

export let printForms = (postingNumber) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(`${API}TopazWebClientWS/resources/reports/printForms?&sessionId=${session}&postingNumber=${postingNumber}`)
    .then(({ data }) => data);
};

export let getReportToPrint = (body) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(`${API}TopazWebClientWS/resources/reports/getReportToPrint?sessionId=${session}&preservDestination`, body)
    .then(({ data }) => data);
};

export let getOperationEndedForm = (preservDestination, body, insertion, forcepdf) => {
  let session = browserStorage.sessionGet(USER_SESSION);
  return axios
    .post(
      `${API}TopazWebClientWS/resources/reports/getOperationEndedForm?sessionId=${session}&preservDestination=${preservDestination}&insertion=${insertion}&forcepdf=${forcepdf}`,
      body,
    )
    .then(({ data }) => data);
};
