import React from 'react';
import { injectIntl } from 'react-intl';

const STATE_FIN = 77;
const STATE_CONF_1 = 67;
const STATE_CONF_2 = 68;
const STATE_AUTH = 65;
const STATE_DEL = 42;

const colorGreen = '#6EB83E';
const colorRed = '#B83E3E';
const colorYellow = '#FFCA00';

const PostingStatus = (props) => {
  const { state, insideRow, intl } = props;

  let color = '#ccc';
  let stateText = 'N/A';

  if (state === STATE_FIN) {
    color = colorGreen;
    stateText = intl.formatMessage({
      id: 'finalized',
      defaultMessage: 'Finalizado',
    });
  } else if (state === STATE_CONF_1) {
    color = colorYellow;
    stateText = intl.formatMessage({
      id: 'toConfirm',
      defaultMessage: 'A confirmar',
    });
  } else if (state === STATE_CONF_2) {
    color = colorYellow;
    stateText = intl.formatMessage({
      id: 'toConfirmDeferred',
      defaultMessage: 'A confirmar diferido',
    });
  } else if (state === STATE_AUTH) {
    color = colorRed;
    stateText = intl.formatMessage({
      id: 'authorizationPending',
      defaultMessage: 'Pendiente de autorización',
    });
  } else if (state === STATE_DEL) {
    color = colorGreen;
    stateText = intl.formatMessage({ id: 'low', defaultMessage: 'Baja' });
  }

  return (
    <div className={`flex items-center ${insideRow && 'w-48 pr-6'}`}>
      <div className="text-4xl leading-none -mt-1" style={{ color }}>
        &bull;
      </div>
      <div className="text-sm pl-1 font-light">{stateText}</div>
    </div>
  );
};

export default injectIntl(PostingStatus);
