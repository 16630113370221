import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { storeActions, store } from '../../src/store';
import { connect } from 'unistore/react';
import { getInitialParameters } from '../../src/api/workflow';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownBlur from './WorkflowDropdownBlur';
import { addValueTest } from '../../src/components/Testing/HandleLineTest';

const RESIZE_PRESS = 'RESIZE_PRESS';
const RESIZE_MOVE = 'RESIZE_MOVE';
const RESIZE_RELEASED = 'RESIZE_RELEASED';
const MIN_WIDTH_COLUMN = 5;
const DEFAULT_WIDTH_COLUMN = 80;
const RESIZE_CLICKABLE_AREA_WIDTH = 5;
const RESIZE_CLICKABLE_AREA_WITH_PADDING_WIDTH = RESIZE_CLICKABLE_AREA_WIDTH * 2;

export class WorkflowTaskList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      pageSize: 10,
      hideTaskId: false,
      columnsWidth: [],
      taskData: null,
      columnData: [],
      idx: null,
    };

    this.execute = this.execute.bind(this);
    this.reAssign = this.reAssign.bind(this);
    this.menuOper = this.menuOper.bind(this);
    this.setColumnsWidth = this.setColumnsWidth.bind(this);

    this.columnMove = null;
  }

  async componentDidMount() {
    let initialParam = await getInitialParameters();
    this.setState({ pageSize: initialParam.DisplayPageSize, hideTaskId: initialParam.HideTaskId });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.columnsWidth || !this.state.columnsWidth.length) {
      this.setColumnsWidth();
    }
  }

  setColumnsWidth(indexColumn, event, value) {
    let { columns } = this.props;
    let { columnsWidth, minWidthTable } = this.state;
    if (!columns || !columns.length) return;

    if (indexColumn !== undefined) {
      if (event === RESIZE_PRESS) {
        columnsWidth[indexColumn].pageX = value;
        this.columnMove = indexColumn;
      } else if (event === RESIZE_MOVE) {
        if (columnsWidth[indexColumn].pageX !== null) {
          let diff = value - columnsWidth[indexColumn].pageX;
          const newValue = columnsWidth[indexColumn].value + diff;
          if (newValue >= MIN_WIDTH_COLUMN) {
            columnsWidth[indexColumn].value = newValue;
            columnsWidth[indexColumn].pageX = value;
            minWidthTable = minWidthTable + diff;
          }
        }
      } else if (event === RESIZE_RELEASED) {
        columnsWidth[indexColumn].pageX = null;
        this.columnMove = null;
      }
    } else {
      const percentageSoAllEqualWidth = columns && columns.length ? Math.round(100 / (columns.length + 1)) / 100 : DEFAULT_WIDTH_COLUMN;
      columnsWidth =
        columns &&
        columns.map((col) => {
          let lengthToUse = col.length;
          if (col.Header && col.Header.length >= lengthToUse) {
            lengthToUse = col.Header.length;
          }
          lengthToUse = lengthToUse + (col.type === 3 ? lengthToUse / 3 : 0);
          return {
            value:
              percentageSoAllEqualWidth * document.getElementById('workflowTaskListContainer').parentNode.offsetWidth -
              RESIZE_CLICKABLE_AREA_WITH_PADDING_WIDTH * columnsWidth.length,
          };
        });
      columnsWidth[columnsWidth.length] = {
        value:
          percentageSoAllEqualWidth * document.getElementById('workflowTaskListContainer').parentNode.offsetWidth -
          RESIZE_CLICKABLE_AREA_WITH_PADDING_WIDTH * columnsWidth.length,
      };
      let totalWidth = 0;
      columnsWidth.forEach((col) => {
        if (!isNaN(col.value)) {
          totalWidth += col.value;
        }
      });
      minWidthTable = totalWidth;
    }
    this.setState({ columnsWidth, minWidthTable });
  }

  execute(e, item, operationNumber) {
    let isLiItem = e.currentTarget.id && e.currentTarget.id.length > 4 && e.currentTarget.id.substring(e.currentTarget.id.length - 4) === 'exec';
    if (operationNumber) {
      addValueTest('CLICK', `${operationNumber}`, '');
    } else if (isLiItem) {
      addValueTest('CLICK', `executeWfAction-${item.TaskID}-exec`, '');
    } else {
      addValueTest('CLICK', e.currentTarget.id, '');
    }
    const { executeFunction, subSelected } = this.props;
    let state = store.getState();
    let workflowTask = {
      taskID: item.TaskID,
      subSelected: subSelected,
    };
    let workflowTasks = [];
    if (state.workflowTasks !== undefined) {
      workflowTasks = state.workflowTasks;
    }
    workflowTasks.push(workflowTask);
    state.workflowTasks = workflowTasks;
    store.setState(state);
    executeFunction(item.detailId, operationNumber);
    e.preventDefault();
    e.stopPropagation();
  }

  instanceImage(e, item) {
    const { getInstanceImage } = this.props;
    getInstanceImage(item.detailId);
    e.preventDefault();
    e.stopPropagation();
  }

  reAssign(e, item) {
    const { reAssignInstance } = this.props;
    reAssignInstance(item);
    e.preventDefault();
    e.stopPropagation();
  }

  menuOper(auxOper, item) {
    let initMenu = (
      <div
        style={{
          position: 'relative',
          whiteSpace: 'nowrap',
          alignContent: 'center',
        }}
      >
        <Dropdown />
        <DropdownBlur operations={auxOper} item={item} execute={this.execute} />
      </div>
    );

    return initMenu;
  }

  cuentoArray(array) {
    let cont = 0;
    for (let x = 0; x < array.length; x++) {
      cont++;
    }
    return cont;
  }

  render() {
    const {
      tasks,
      columns,
      noDataText,
      handleTaskClick,
      bottomIndexPage,
      changeBottomIndexPage,
      loading,
      loadingComponent,
      showingOnlyMine,
      rawDataDetails,
      taskSelected,
    } = this.props;
    let pag = 0;

    if (taskSelected !== null) {
      pag = this.cuentoArray(taskSelected.value) / 10;
      if (this.cuentoArray(taskSelected.value) % 10 !== 0) {
        pag = Math.ceil(pag);
      }
    }
    // eslint-disable-next-line no-unused-vars
    const columnCount = columns ? columns.length : 1;
    const percentageSoAllEqualWidth = columns && columns.length ? Math.round(100 / (columns.length + 1)) / 100 : DEFAULT_WIDTH_COLUMN;
    const { selected, hideTaskId, columnsWidth } = this.state;

    let existOpers = false;

    document.getElementById('root').onmousemove = (e) => {
      if (this.columnMove !== null) {
        this.setColumnsWidth(this.columnMove, RESIZE_MOVE, e.pageX);
      }
    };
    document.getElementById('root').onmouseup = (e) => {
      if (this.columnMove !== null) {
        this.setColumnsWidth(this.columnMove, RESIZE_RELEASED);
      }
    };

    if (loading) {
      return <div className="text-center">{loadingComponent}</div>;
    }

    if (!tasks || !tasks.length) {
      return noDataText;
    }

    const handleChangeSort = (el, index) => {
      let dataToSort = [...tasks];
      if (dataToSort) {
        let sortedList = [];
        const copyHeaders = [...columns];
        let objIndex = copyHeaders.findIndex((obj) => obj === el);
        if (el.order === '') {
          console.log(columns);
          console.log(dataToSort);
          let objIndex = copyHeaders.findIndex((obj) => obj === el);
          sortedList = [...dataToSort].sort((a, b) => {
            if (columns[index].type === 'A') return a[copyHeaders[objIndex].accessor] > b[copyHeaders[objIndex].accessor]?.toLowerCase() ? 1 : -1;
            if (columns[index].type === 'N')
              return parseInt(a[copyHeaders[objIndex].accessor]) > parseInt(b[copyHeaders[objIndex].accessor]) ? 1 : -1;
            return a[copyHeaders[objIndex].accessor].toLowerCase() > b[copyHeaders[objIndex].accessor].toLowerCase() ? 1 : -1;
          });
          copyHeaders[objIndex].order = 'ASC';
        } else if (el.order === 'ASC' || !el.order || el.order.length === 0) {
          sortedList = [...dataToSort].sort((a, b) => {
            if (columns[index].type === 'A')
              return a[copyHeaders[objIndex].accessor].toLowerCase() < b[copyHeaders[objIndex].accessor]?.toLowerCase() ? 1 : -1;
            if (columns[index].type === 'N')
              return parseInt(a[copyHeaders[objIndex].accessor]) < parseInt(b[copyHeaders[objIndex].accessor]) ? 1 : -1;

            return a[copyHeaders[objIndex].accessor]?.toLowerCase() < b[copyHeaders[objIndex].accessor]?.toLowerCase() ? 1 : -1;
          });
          copyHeaders[objIndex].order = 'DSC';
        } else {
          sortedList = tasks;
          copyHeaders[objIndex].order = '';
        }
        if (this.state.idx !== index) {
          copyHeaders[this.state.idx || 0].order = '';
        }
        this.setState({ idx: index });
        this.props.setTaskDetail(this.props.thisObject, sortedList);
        // this.setState({ taskData: sortedList });
      }
    };

    const getHeaderDescription = (el, actualSort) => {
      let header = el.Header;
      if (actualSort === this.state.idx) {
        if (el.order) {
          if (el.order === 'DSC') {
            header += ' 🔽';
          } else {
            header += ' 🔼';
          }
        }
      }
      return header;
    };

    return (
      <div id="workflowTaskListContainer" style={{ width: '100%' }}>
        <div className="flex items-center py-2 border-b border-smoke w-full text-xxs uppercase text-smoke">
          {columns &&
            columns.map((column, index) => {
              const { accessor } = column;
              let visibleColumn = accessor.toUpperCase() !== 'TASKID' ? 'Visible' : 'hidden';
              let colW = percentageSoAllEqualWidth;
              if (visibleColumn === 'hidden' && hideTaskId === true && columns.length > 1) {
                // eslint-disable-next-line no-unused-vars
                colW = 0;
              } else {
                visibleColumn = 'visible';
              }
              return (
                <div
                  key={index}
                  style={{ display: 'flex' }}
                  onClick={() => handleChangeSort(column, index)}
                  className={`relative truncate border-smoke border-r p-1 cursor-pointer select-none`}
                >
                  <div
                    key={index}
                    style={{
                      width:
                        columnsWidth && columnsWidth[index] && columnsWidth[index].value
                          ? columnsWidth[index].value - RESIZE_CLICKABLE_AREA_WITH_PADDING_WIDTH
                          : 50,
                      visibility: `${visibleColumn}`,
                    }}
                    className="px-2"
                  >
                    {getHeaderDescription(column, index)}
                  </div>
                  <div
                    className="absolute pin-t pin-r bg-transparent h-full select-none"
                    style={{ width: RESIZE_CLICKABLE_AREA_WIDTH, cursor: 'col-resize' }}
                    onMouseDown={(e) => {
                      this.setColumnsWidth(index, RESIZE_PRESS, e.pageX);
                    }}
                  />
                </div>
              );
            })}
          <div>
            <div
              style={{
                width: columnsWidth && columnsWidth[columns.length] && columnsWidth[columns.length].value ? columnsWidth[columns.length].value : 50,
              }}
              className="text-right"
            >
              Acciones
            </div>
          </div>
        </div>
        {tasks &&
          columns &&
          tasks.map((item, index) => {
            return (
              <div
                key={index}
                onClick={(e) => {
                  handleTaskClick(e, index);
                  this.setState({
                    selected: index,
                  });
                }}
                className={`flex items-center py-2 border-b border-light w-full border-smoke cursor-pointer ${
                  index === selected && 'selected bg-grey-lighter-lighter'
                }`}
              >
                {columns &&
                  columns.map((column, index) => {
                    const { accessor, searchIndicator } = column;
                    const itemValue = searchIndicator ? item[accessor][searchIndicator] : item[accessor];
                    let visibleColumn = accessor.toUpperCase() !== 'TASKID' ? 'Visible' : 'hidden';
                    let colW = percentageSoAllEqualWidth * 100;
                    if (visibleColumn === 'hidden' && hideTaskId === true && columns.length > 1) {
                      // eslint-disable-next-line no-unused-vars
                      colW = 0;
                    } else {
                      visibleColumn = 'visible';
                    }
                    return (
                      <div
                        key={index}
                        style={{
                          width: columnsWidth && columnsWidth[index] && columnsWidth[index].value ? columnsWidth[index].value : 50,
                          visibility: `${visibleColumn}`,
                        }}
                        className="truncate px-2"
                        title={itemValue}
                      >
                        {Array.isArray(itemValue) ? (
                          <div className="flex flex-col">
                            {itemValue.map((v, index) => (
                              <span className="truncate" key={index}>
                                {v}
                              </span>
                            ))}
                          </div>
                        ) : (
                          itemValue
                        )}
                      </div>
                    );
                  })}
                <div
                  key={index}
                  style={{
                    width:
                      columnsWidth && columnsWidth[columnsWidth.length] && columnsWidth[columnsWidth.length].value
                        ? columnsWidth[columnsWidth.length].value
                        : 50,
                    overflow: 'visible',
                  }}
                  className="truncate text-xs text-right"
                >
                  {showingOnlyMine && item.TaskID && (
                    <div>
                      {rawDataDetails &&
                        rawDataDetails.map((data) => {
                          if (data.taskId.toString() === item.TaskID) {
                            existOpers = data.auxOperations && data.auxOperations.length > 0;
                            if (existOpers) {
                              return this.menuOper(data.auxOperations, item);
                            }
                          }
                        })}

                      {!existOpers && (
                        <button
                          id={`execute-${index}`}
                          onClick={(e) => this.execute(e, item)}
                          className="text-xxs text-white bg-grey hover:bg-primary focus:bg-primary cursor-pointer px-2 py-1 rounded-sm uppercase mr-1"
                        >
                          <FormattedMessage id="execute" defaultMessage={`Ejecutar`} />
                        </button>
                      )}
                    </div>
                  )}
                  {!showingOnlyMine && (
                    <div className="flex flex-col items-end mt-1">
                      {(!item.endDate || item.endDate === '') && this.props.canReassign && (
                        <button
                          onClick={(e) => this.reAssign(e, item)}
                          className="text-xxs text-white bg-grey hover:bg-primary cursor-pointer px-2 py-1 rounded-sm uppercase"
                          style={{ width: 70 }}
                        >
                          <FormattedMessage id="reassign" defaultMessage={`Re-asignar`} />
                        </button>
                      )}
                      <button
                        onClick={(e) => this.instanceImage(e, item)}
                        className="text-xxs text-white bg-grey hover:bg-primary cursor-pointer px-2 py-1 rounded-sm uppercase"
                        style={{ marginTop: 1, width: 70 }}
                      >
                        <FormattedMessage id="seeFlow" defaultMessage={`Ver flujo`} />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        {typeof taskSelected.value !== 'string' ? (
          <div className="flex items-center uppercase w-full items-center justify-center mt-6 text-xs">
            <div
              className="hover:text-primary cursor-pointer"
              style={{ pointerEvents: bottomIndexPage === 0 ? 'none' : 'auto' }}
              onClick={() => changeBottomIndexPage(Math.max(bottomIndexPage - this.state.pageSize, 0))}
            >
              {`ᐊ `}
              <FormattedMessage id="previous" defaultMessage={`Anterior`} />
            </div>
            <div className="mx-4">
              <FormattedMessage id="page" defaultMessage={`PÃ¡gina`} />
              {`: ${(bottomIndexPage + this.state.pageSize) / this.state.pageSize}/${pag}`}
            </div>
            <div
              className="hover:text-primary cursor-pointer"
              style={{ pointerEvents: (bottomIndexPage + this.state.pageSize) / this.state.pageSize === pag ? 'none' : 'auto' }}
              onClick={() => changeBottomIndexPage(bottomIndexPage + this.state.pageSize)}
            >
              <FormattedMessage id="following" defaultMessage={`Siguiente`} />
              {` ᐅ`}
            </div>
          </div>
        ) : (
          <div className="flex items-center uppercase w-full items-center justify-center mt-6 text-xs">
            <div
              className="hover:text-primary cursor-pointer"
              onClick={() => changeBottomIndexPage(Math.max(bottomIndexPage - this.state.pageSize, 0))}
            >
              {`ᐊ `}
              <FormattedMessage id="previous" defaultMessage={`Anterior`} />
            </div>
            <div className="mx-4">
              <FormattedMessage id="page" defaultMessage={`PÃ¡gina`} />
              {`: ${(bottomIndexPage + this.state.pageSize) / this.state.pageSize}`}
            </div>
            <div className="hover:text-primary cursor-pointer" onClick={() => changeBottomIndexPage(bottomIndexPage + this.state.pageSize)}>
              <FormattedMessage id="following" defaultMessage={`Siguiente`} />
              {` ᐅ`}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, storeActions)(WorkflowTaskList);
