import { css } from 'aphrodite';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useIntl } from 'react-intl';
import { useGlobalStyles } from './../../../styles/GlobalStylesContext';
import Field from './Field';
import es from 'date-fns/locale/es';

registerLocale('es', es);

const DateInput = ({ date, onDateChange, labelIntlOptions, id }) => {
  const intl = useIntl();
  const globalStyles = useGlobalStyles();

  const placeholderDate = intl.formatMessage(labelIntlOptions);

  return (
    <Field htmlFor="date" labelText={placeholderDate}>
      <DatePicker
        id={id}
        required
        placeholderText="DD/MM/AAAA hh:mm:ss"
        dateFormat="dd/MM/yyyy hh:mm aa"
        onChange={onDateChange}
        onFocus={(e) => e.target.select()}
        showTimeSelect={true}
        timeFormat="hh:mm aa"
        locale="es"
        timeCaption="Hora"
        selected={date}
        className={css(globalStyles.input, globalStyles.dateTimeInput)}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        autoComplete="off"
      />
    </Field>
  );
};

export default DateInput;
