const crypto = require('crypto');

const cbcDefaultConfig = {
  key: process.env.AES_CBC_SECRET || '0123456789abcdef',
  iv: process.env.AES_CBC_IV || 'fedcba9876543210',
}

export const encryptAES = (text, cbcConfig = cbcDefaultConfig) => {
  // Configure AES CBC object
  const { key } = cbcConfig
  const nonce = crypto.randomBytes(12);
  const cipher = crypto.createCipheriv('aes-128-gcm', key, nonce);
  const ciphertext = Buffer.concat([cipher.update(text, 'utf8'), cipher.final()]);
  let resultText = Buffer.concat([nonce, ciphertext, cipher.getAuthTag()]).toString('base64');

  return {
    base64: resultText
  }
}
