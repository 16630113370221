import { store } from '../../../store';
import { getBitacorasOperaciones } from '../../../api/bitacora';
import { formatDateForServer } from './filterFields';
import { getButtonsState } from './paginado';

const getOperacionesFilterResult = (filterParams, filterListID, intl, setDisabledButtonPrev, setDisabledButtonNext, setLoading) => {
  let {
    fechaHoraDesde,
    fechaHoraHasta,
    asiento,
    operacion,
    descriptor,
    iniciales,
    cliente,
    cuenta,
    moneda,
    operacion2,
    rubroContable,
    sucursal,
    alta,
    baja,
    modificacion,
    dynamicFilters,
    begin,
    maxResults,
    persistenceUnit,
  } = filterParams;

  let filterData = {};
  filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
  store.setState({
    bitacoraApp: {
      ...store.getState().bitacoraApp,
      ...filterData,
    },
  });

  let defaultValue;
  fechaHoraDesde = formatDateForServer(fechaHoraDesde);
  fechaHoraHasta = formatDateForServer(fechaHoraHasta);
  maxResults = maxResults || 201;
  asiento = asiento || defaultValue;
  operacion = operacion || defaultValue;
  descriptor = descriptor || defaultValue;
  cliente = cliente || defaultValue;
  cuenta = cuenta || defaultValue;
  moneda = moneda || defaultValue;
  operacion2 = operacion2 || defaultValue;
  rubroContable = rubroContable || defaultValue;
  sucursal = sucursal || defaultValue;
  alta = alta || defaultValue;
  baja = baja || defaultValue;
  modificacion = modificacion || defaultValue;
  dynamicFilters = dynamicFilters || defaultValue;
  begin = begin || 0;
  persistenceUnit = persistenceUnit || 'Local';
  let usuario = iniciales || defaultValue;

  const params = {
    fechaHoraDesde,
    fechaHoraHasta,
    asiento,
    operacion,
    descriptor,
    usuario,
    sucursal,
    cliente,
    cuenta,
    moneda,
    operacion2,
    rubroContable,
    alta,
    baja,
    modificacion,
    dynamicFilters,
    begin,
    maxResults,
    persistenceUnit,
  };
  const resultColumns = [
    {
      Header: intl.formatMessage({
        id: 'Tablas.movimientos_asiento',
        defaultMessage: 'Asiento',
      }),
      accessor: 'asiento',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.movimientos_operacion',
        defaultMessage: 'Operación',
      }),
      accessor: 'operacion',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 50,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.movimientos_sucursal',
        defaultMessage: 'Sucursal',
      }),
      accessor: 'sucursal',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 2,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.movimientos_fechainicio',
        defaultMessage: 'Fecha Inicio',
      }),
      accessor: 'horainicio',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.movimientos_fechafin',
        defaultMessage: 'Fecha Fin',
      }),
      accessor: 'horafin',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'Tablas.movimientos_fechaproceso',
        defaultMessage: 'Fecha Proceso',
      }),
      accessor: 'fechaproceso',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
    {
      Header: intl.formatMessage({
        id: 'iniciales',
        defaultMessage: 'Iniciales',
      }),
      accessor: 'iniusr',
      decimals: undefined,
      isReturned: true,
      isSortable: true,
      length: 20,
      type: 1,
    },
  ];
  getBitacorasOperaciones(params)
    .then((data) => {
      let buttonNextState, buttonPrevState;
      [buttonNextState, buttonPrevState] = getButtonsState(data.length, begin, true);
      let filterData = {};
      filterData[filterListID] = {
        ...store.getState().bitacoraApp[filterListID],
        resultColumns,
        resultData: data,
        resultCount: data.length,
        buttonNextState,
        buttonPrevState,
      };
      setDisabledButtonNext(buttonNextState);
      setDisabledButtonPrev(buttonPrevState);
      store.setState({
        bitacoraApp: {
          ...store.getState().bitacoraApp,
          ...filterData,
        },
      });
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      if (setLoading) {
        setLoading(false);
      }
      console.log(err);
    });
};

export { getOperacionesFilterResult };
