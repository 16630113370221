import { PulseLoader } from 'halogenium';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import errorIcon from '../../icons/error';
import modifyIcon from '../../icons/modify';
import okIcon from '../../icons/ok';
import { ProcessSchedule } from '../../models/processSchedule';
import { ConditionModalViewModel } from '../conditionModal/ConditionModalViewModel';
import { WarningModalView } from '../conditionModal/warningModal/WarningModalView';

import './PeriodicityTab.scss';

interface PeriodicityViewProps {
  isLoading: boolean;
  schedulerList: ProcessSchedule[];
  isModalConditionOpen: boolean;
  isModalRemoveOpen: boolean;
  onModalConditionChange: (isOpen: boolean) => void;
  onModalRemoveChange: (isOpen: boolean) => void;
  onModalContextChange: (data: ProcessSchedule, isEditing: boolean) => void;
  processCondition?: ProcessSchedule;
  onRefreshData: () => void;
}

export function PeriodicityTabView({
  isLoading,
  schedulerList,
  isModalConditionOpen,
  onModalConditionChange,
  onModalContextChange,
  processCondition,
  isModalRemoveOpen,
  onModalRemoveChange,
  onRefreshData,
}: PeriodicityViewProps): JSX.Element {
  const intl = useIntl();

  function renderPeriodicity(periodicity: string): string {
    let periodTime: string = '';
    switch (periodicity) {
      case 'd':
        periodTime = intl.formatMessage({ id: 'daily' });
        break;
      case 's':
        periodTime = intl.formatMessage({ id: 'weekly' });
        break;
      case 'm':
        periodTime = intl.formatMessage({ id: 'monthly' });
        break;
      case 'q':
        periodTime = intl.formatMessage({ id: 'biweekly' });
        break;
      case 't':
        periodTime = intl.formatMessage({ id: 'quarterly' });
        break;
      case 'S':
        periodTime = intl.formatMessage({ id: 'biannual' });
        break;
      case 'a':
        periodTime = intl.formatMessage({ id: 'annual' });
        break;
      case 'f':
        periodTime = intl.formatMessage({ id: 'endMonth' });
        break;
      case 'F':
        periodTime = intl.formatMessage({ id: 'endYear' });
        break;
    }

    return periodTime;
  }

  function onModifyClick(item: ProcessSchedule): (event: React.MouseEvent) => void {
    return () => {
      onModalContextChange(item, true);
    };
  }

  function onRemoveClick(item: ProcessSchedule): (event: React.MouseEvent) => void {
    return () => {
      onModalContextChange(item, false);
    };
  }

  return isLoading ? (
    <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
      <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
    </div>
  ) : (
    <div className="condition-container pl-2 m-10 pb-12 overflow-auto">
      {isModalConditionOpen && (
        <ConditionModalViewModel
          isEditing={true}
          onModalConditionChange={onModalConditionChange}
          processCondition={processCondition}
          onRefreshData={onRefreshData}
        />
      )}
      {isModalRemoveOpen && <WarningModalView onModalChange={onModalRemoveChange} question={intl.formatMessage({ id: 'sureToRemoveCondition' })} />}
      <div className="flex items-center justify-between text-xs uppercase text-grey py-2 border-b border-light">
        <div className="group-name">{intl.formatMessage({ id: 'parentGroup' })}</div>
        <div className="group-name">{intl.formatMessage({ id: 'childGroup' })}</div>
        <div className="info-process">{intl.formatMessage({ id: 'ordinal' })}</div>
        <div className="info-process">{intl.formatMessage({ id: 'periodicity' })}</div>
        <div className="info-process">{intl.formatMessage({ id: 'day' })}</div>
        <div className="info-process">{intl.formatMessage({ id: 'labor' })}</div>
        <div className="info-process">{intl.formatMessage({ id: 'nextExecution' })}</div>
        <div className="info-process"></div>
      </div>
      <div>
        {schedulerList.map((item, index) => (
          <div className={` ${index % 2 && 'bg-grey-lighter-lighter'} `} key={index}>
            <div className="flex items-center justify-between border-t border-smoke py-2 h-10">
              <div className="group-name font-mono text-xs">{item.parentGroup}</div>
              <div className="group-name font-mono text-xs">{item.childGroup}</div>
              <div className="info-process data font-mono text-xs">{item.ordinal}</div>
              <div className="info-process data font-mono text-xs">{renderPeriodicity(item.periodicity)}</div>
              <div className="info-process data font-mono text-xs">{item.day}</div>
              <div className="info-process data font-mono text-xs">{item.labor === 1 ? okIcon : errorIcon}</div>
              <div className="info-process data font-mono text-xs">{moment(item.nextExecution).format('DD-MMM-YYYY')}</div>
              <div className="info-process data font-mono text-xs">
                <button
                  title={intl.formatMessage({ id: 'modify' })}
                  className="edit-button"
                  onClick={onModifyClick(item)}
                  disabled={isModalConditionOpen || isModalRemoveOpen}
                >
                  {modifyIcon}
                </button>
                <button
                  title={intl.formatMessage({ id: 'remove' })}
                  className="remove-button"
                  onClick={onRemoveClick(item)}
                  disabled={isModalConditionOpen || isModalRemoveOpen}
                >
                  {errorIcon}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
