import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { useTheme } from '../../themes/ThemeContext';
import Filter from './Filters/Filter';
import FilterSelector from './FilterSelector';
import ListOfChecks from './ListOfChecks';

const ClearingView = ({ filters, filterSelected, setFilterSelected, setMicrLine, setSelectedCheck }) => {
  const themeState = useTheme();
  const { background, border } = themeState.theme;

  const styles = StyleSheet.create({
    clearingContainer: {
      display: 'flex',
    },
    searchContainer: {
      padding: '.75rem 1rem',
      width: '25rem',
      borderWidth: 1,
      borderRadius: 2,
      borderColor: border.border,
      backgroundColor: background.background,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  });

  return (
    <React.Fragment>
      <div className={css(styles.clearingContainer)}>
        <div className={css(styles.searchContainer)}>
          <FilterSelector filters={filters} filterSelected={filterSelected} onSelectFilter={setFilterSelected} />
          <Filter type={filterSelected} />
        </div>
        <ListOfChecks onSelectCheck={setMicrLine} setSelectedCheck={setSelectedCheck} />
      </div>
    </React.Fragment>
  );
};

export default ClearingView;
