/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { ABOVE_ALL } from '../../../../UI-kit/constants/zIndexValues';
import {
  getHelpButton,
  getColumnsSucursal,
  getColumnsImpresora,
  getColumnsNumeroMaquina,
  getHelpWindow,
} from './ProductionHelpComponent/productionHelp';
import { useTheme } from '../../../themes/ThemeContext';

import { css, StyleSheet } from 'aphrodite';

import Draggable from 'react-draggable';
import FocusLock from 'react-focus-lock';

import { useIntl } from 'react-intl';
import ModalMessage from '../../ModalMessage';
import { addTopazPrt, updateTopazPrt } from '../../../api/productionManager';

export const PrinterWindow = (props) => {
  const themeContext = useTheme();

  const { color, commonButton, modal } = themeContext.theme;

  const styles = StyleSheet.create({
    show: {
      display: 'block',
      zIndex: 900,
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    modalMain: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxHeight: '80%',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: modal.active.background,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: 'transparent',
      opacity: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.33) 0px 2px 8px',
      // alignItems: 'center',
      transition: 'border background .2s',
      padding: '.2rem',
    },
    titleContainer: {
      width: '100%',
      minHeight: '1.5rem',
      padding: '.5rem 1rem',
      color: color.grey.dark4,
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    messageContainer: {
      padding: '1.5rem 1rem',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    buttonsContainer: {
      padding: '.5rem .5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    button: {
      padding: '.5rem 1.2rem',
      marginRight: '.5rem',
      borderRadius: 4,
      display: 'block',
      backgroundColor: commonButton.default.background,
      color: commonButton.default.color,
      ':hover': {
        backgroundColor: commonButton.hover.background,
        color: commonButton.hover.color,
      },
      ':focus': {
        backgroundColor: commonButton.focus.background,
        color: commonButton.focus.color,
      },
      ':active': {
        backgroundColor: commonButton.active.background,
        color: commonButton.active.color,
      },
      transition: 'background .2s',
    },
  });

  const intl = useIntl();
  const { item, sucursales, impresoras, mapaDeRed, setModifiedItem, actualIndex, handleKeyOnNumber } = props;

  const [modify, setModify] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const [helpRows, setHelpRows] = useState([]);
  const [helpColumns, setHelpColumns] = useState([]);
  const [title, setTitle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [textDialog, setTextDialog] = useState('');
  const [checkTipoImpresora, setcheckTipoImpresora] = useState(0);
  const [nombresImpresoras, setNombresImpresoras] = useState([]);

  if (checkTipoImpresora === 0) {
    if (modify) {
      setcheckTipoImpresora(item.tipoimpresora);
    } else {
      if (item === null) {
        setcheckTipoImpresora(2);
      }
    }
  }

  const cancelMessage = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  const acceptMessage = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });

  useEffect(() => {
    if (item) {
      setModify(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let columnsSucursal = getColumnsSucursal(intl);
  let columnsNumeroMaquina = getColumnsNumeroMaquina();
  let columnsImpresora = getColumnsImpresora();

  let parsedSucursales = [];

  sucursales.forEach((sucursal) => {
    let toAdd = {
      sucursal: sucursal[0],
      descripcion: sucursal[1],
    };
    parsedSucursales.push(toAdd);
  });

  let helpButtonSucursal = getHelpButton(
    'sucursal',
    {
      rows: parsedSucursales,
      columns: columnsSucursal,
      title: intl.formatMessage({
        id: 'branch',
        defaultMessage: 'Sucursal',
      }),
    },
    setHelpColumns,
    setHelpRows,
    setShowHelp,
    setTitle,
    modify,
  );

  let filteredImpresoras = [];

  impresoras.forEach((impresora) => {
    let toAdd = {
      impresoras: impresora.nombretopaz,
      numeroDeMaquina: impresora.nromaq,
      sucursal: impresora.nrosucursal,
    };
    filteredImpresoras.push(toAdd);

    let toAddNombre = {
      nombre: impresora?.nombrewindows,
    };

    let temp = nombresImpresoras;
    if (!temp.some((e) => e.nombre === toAddNombre.nombre)) {
      temp.push(toAddNombre);
      setNombresImpresoras(temp);
    }
  });

  let helpButtonImpresoras = getHelpButton(
    'impresoras',
    {
      rows: filteredImpresoras,
      columns: columnsImpresora,
      title: intl.formatMessage({
        id: 'printer',
        defaultMessage: 'Impresora',
      }),
    },
    setHelpColumns,
    setHelpRows,
    setShowHelp,
    setTitle,
    modify,
  );

  let filteredNetMap = [];

  mapaDeRed.forEach((nodo) => {
    let toAdd = {
      numero: nodo.numerodemaquina,
      descripcion: nodo.descripcion,
    };
    filteredNetMap.push(toAdd);
  });

  let helpButtonNroDeMaquina = getHelpButton(
    'numeroDeMaquina',
    {
      rows: filteredNetMap,
      columns: columnsNumeroMaquina,
      title: intl.formatMessage({
        id: 'machineNumber',
        defaultMessage: 'Número de Máquina',
      }),
    },
    setHelpColumns,
    setHelpRows,
    setShowHelp,
    setTitle,
    modify,
  );

  const handleChecks = (value) => {
    if (value !== checkTipoImpresora) {
      setcheckTipoImpresora(value);
    }
  };

  const closeHelp = () => {
    setHelpColumns([]);
    setHelpRows([]);
    setShowHelp(false);
    setTitle('');
  };

  const helpAcceptHandler = (value, control) => {
    document.getElementById(control).value = value;
    document.getElementById(control).focus();
    closeHelp();
  };

  let helpWindow = getHelpWindow(helpRows, helpColumns, helpAcceptHandler, closeHelp, modify, title);

  const handleAccept = async () => {
    const { item, impresoras, index, addToDataAndSort } = props;

    let nromaq = document.getElementById('NumeroDeMaquina').value;
    let nombretopaz = document.getElementById('nombretopaz').value;
    let nrosucursal = document.getElementById('Sucursal').value;
    let biblioteca = ' '; // document.getElementById('biblioteca').value;
    let nombrewindows = document.getElementById('nombrewindows').value;
    let comentario = document.getElementById('comentario').value;
    let puerto = document.getElementById('puerto').value;

    let tipoimpresora = -1;
    if (document.getElementById('Financiera').checked) {
      tipoimpresora = -1;
    } else if (document.getElementById('Comun').checked) {
      tipoimpresora = 1;
    } else if (document.getElementById('DeTexto').checked) {
      tipoimpresora = 2;
    }

    if (!modify) {
      if (nromaq === undefined || nromaq.trim() === '') {
        const sucursalMessage = intl.formatMessage({
          id: 'maquinanula',
          defaultMessage: 'El valor del campo Número de Máquina no puede ser nulo.',
        });
        setModal(true, sucursalMessage);
        return;
      }

      if (nrosucursal === undefined || nrosucursal.trim() === '') {
        const sucursalMessage = intl.formatMessage({
          id: 'sucursalnula',
          defaultMessage: 'El valor del campo Número de Sucursal no puede ser nulo.',
        });
        setModal(true, sucursalMessage);
        return;
      }

      let rowSuc = sucursales.filter((reg) => {
        return reg[0] === parseInt(nrosucursal);
      });

      if (rowSuc.length === 0) {
        const sucursalMessage = intl.formatMessage({
          id: 'noexistesucursal',
          defaultMessage: 'El número de Sucursal especificado no existe en la base de datos',
        });
        setModal(true, sucursalMessage);
        return;
      }

      if (parseInt(nromaq) !== 0) {
        let rowExiste = impresoras.filter((reg) => {
          return reg.nromaq === 0 && reg.nombretopaz.toUpperCase() === nombretopaz.toUpperCase();
        });
        if (rowExiste.length === 0) {
          const sucursalMessage = intl.formatMessage({
            id: 'noexistemaquinazero',
            defaultMessage: 'No existe maquina número 0 definida para el nombre de impresora ingresado.',
          });
          setModal(true, sucursalMessage);
          return;
        }
      }
    }

    let topazPrt = {
      nromaq: parseInt(nromaq),
      nombretopaz: nombretopaz,
      nrosucursal: parseInt(nrosucursal),
      biblioteca: biblioteca,
      nombrewindows: nombrewindows,
      comentario: comentario,
      tipoimpresora: parseInt(tipoimpresora),
      puerto: puerto,
    };
    if (modify) {
      let res = await updateTopazPrt(topazPrt);
      if (res === false) {
        let message = intl.formatMessage({
          id: 'erroralactualizar',
          defaultMessage: 'Error al intentar actualizar ',
        });
        message += 'TopazPrt';
        setModal(true, message);
        return;
      } else {
        setModifiedItem(topazPrt, index);
      }
    } else {
      let res = await addTopazPrt(topazPrt);
      if (res === false) {
        let message = intl.formatMessage({
          id: 'erroraldardealta',
          defaultMessage: 'Error al intentar dar de alta sobre ',
        });
        message += 'TopazPrt';
        setModal(true, message);
        return;
      }
      addToDataAndSort(topazPrt);
    }

    props.handleCancel();
  };

  const setModal = (state, text) => {
    setShowModal(state);
    setTextDialog(text);
  };

  return (
    <div>
      {showModal && (
        <ModalMessage
          message={textDialog}
          handleAccept={async () => {
            setShowModal(false);
          }}
          acceptTitle={acceptMessage}
          draggable={true}
        />
      )}
      <div>
        <FocusLock persistentFocus returnFocus>
          <div className={css(styles.modal, styles.show)}>
            {/* onKeyDown={handleKeyDown}> */}
            <div className={css(styles.modalMain)}>
              <Draggable handle=".drag-section" bounds={{ top: -200, right: 350, bottom: 200, left: -350 }}>
                <div className={`${css(styles.modalContent)}`}>
                  <div className={`drag-section ${css(styles.titleContainer)}`}></div>
                  <div className={css(styles.messageContainer)}>
                    <div className={`flex-auto text-left m-1 flex `} style={{ height: 'fit-content', justifyContent: 'center' }}>
                      <form className={'overflow-hidden'} style={{ display: 'table' }}>
                        <p style={{ display: 'table-row' }} className="mt-2 ml-2">
                          <label style={{ display: 'table-cell' }} htmlFor="Impresoras">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerPrinters',
                              defaultMessage: 'Impresoras',
                            })}
                          </label>
                          <input
                            className={'clear-both border ml-2'}
                            disabled={modify}
                            id="nombretopaz"
                            defaultValue={modify ? item.nombretopaz : actualIndex}
                            style={{ display: 'table-cell' }}
                          ></input>
                          {helpButtonImpresoras}
                        </p>
                        <p style={{ display: 'table-row' }} className="mt-2 ml-2">
                          <label style={{ display: 'table-cell' }} htmlFor="NumeroDeMaquina">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerMachineNumber',
                              defaultMessage: 'Número de Máquina',
                            })}
                          </label>
                          <input
                            disabled={modify}
                            className={'clear-both border mt-2 ml-2'}
                            style={{ display: 'table-cell' }}
                            id="NumeroDeMaquina"
                            defaultValue={modify ? item.nromaq : ''}
                            type="number"
                            min="0"
                            onKeyDown={(e) => handleKeyOnNumber(e)}
                          ></input>
                          {helpButtonNroDeMaquina}
                        </p>
                        <p style={{ display: 'table-row' }} className="mt-2 ml-2">
                          <label style={{ display: 'table-cell' }} htmlFor="Sucursal">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerBranch',
                              defaultMessage: 'Sucursal',
                            })}
                          </label>
                          <input
                            disabled={modify}
                            className={'clear-both border mt-2 ml-2'}
                            id="Sucursal"
                            defaultValue={modify ? item.nrosucursal : ''}
                            style={{ display: 'table-cell' }}
                            type="number"
                            min="0"
                            onKeyDown={(e) => handleKeyOnNumber(e)}
                          ></input>
                          {helpButtonSucursal}
                        </p>
                        <p style={{ display: 'table-row' }} className="mt-2 ml-2">
                          <label style={{ display: 'table-cell' }} htmlFor="Descripcion">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerDescription',
                              defaultMessage: 'Descripción',
                            })}
                          </label>
                          <input
                            className={'clear-both border mt-2 ml-2'}
                            style={{ display: 'table-cell' }}
                            id="comentario"
                            defaultValue={modify ? item.comentario : ''}
                          ></input>
                        </p>
                        <div style={{ display: 'table-row' }} className="mt-2 ml-2">
                          <p style={{ display: 'table-cell' }}>
                            <label htmlFor="Dispositivo">
                              {intl.formatMessage({
                                id: 'productionManagerDevice',
                                defaultMessage: 'Dispositivo',
                              })}
                            </label>
                          </p>
                          <p style={{ display: 'table-cell' }}>
                            <input
                              list="nombres"
                              id="nombrewindows"
                              className="mt-2 ml-2"
                              defaultValue={item?.nombrewindows ? item.nombrewindows : ''}
                            />
                            <datalist id="nombres">
                              {nombresImpresoras.map((impresora, index) => {
                                return (
                                  <option key={index} value={impresora.nombre}>
                                    {impresora.nombre}
                                  </option>
                                );
                              })}
                            </datalist>
                          </p>
                        </div>
                        <p style={{ display: 'table-row' }} className="mt-2 ml-2">
                          <label style={{ display: 'table-cell' }} htmlFor="Puerto">
                            {intl.formatMessage({
                              id: 'productionManagerPort',
                              defaultMessage: 'Puerto',
                            })}
                          </label>
                          <input
                            className={'clear-both border mt-2 ml-2'}
                            id="puerto"
                            defaultValue={modify ? item.puerto : ''}
                            style={{ display: 'table-cell' }}
                            type="text"
                          ></input>
                        </p>
                        <div style={{ display: 'table-row' }} className="mt-2 ml-2">
                          <div style={{ display: 'table-cell' }}></div>
                          <div style={{ display: 'table-cell' }}>
                            <p className="mt-2">
                              <input
                                type="radio"
                                id="Financiera"
                                name="Tipo"
                                value="-1"
                                checked={checkTipoImpresora === -1}
                                onChange={() => handleChecks(-1)}
                                className="mr-2 ml-2"
                              ></input>

                              <label htmlFor="Financiera">
                                {intl.formatMessage({
                                  id: 'productionManagerFinancial',
                                  defaultMessage: ' Financiera',
                                })}
                              </label>
                            </p>
                            <p>
                              <input
                                type="radio"
                                id="Comun"
                                name="Tipo"
                                value="1"
                                checked={checkTipoImpresora === 1}
                                onChange={() => handleChecks(1)}
                                className="mr-2 ml-2"
                              ></input>
                              <label htmlFor="Comun">
                                {intl.formatMessage({
                                  id: 'productionManagerCommon',
                                  defaultMessage: ' Común',
                                })}
                              </label>
                            </p>
                            <p>
                              <input
                                type="radio"
                                id="DeTexto"
                                name="Tipo"
                                value="2"
                                checked={checkTipoImpresora === 2}
                                onChange={() => handleChecks(2)}
                                className="mr-2 ml-2"
                              ></input>
                              <label htmlFor="DeTexto">
                                {intl.formatMessage({
                                  id: 'productionManagerText',
                                  defaultMessage: ' de Texto',
                                })}
                              </label>
                            </p>
                          </div>
                        </div>
                      </form>
                      {showHelp && helpWindow}
                    </div>
                    <div className="flex justify-center mt-2 ml-2">
                      <button
                        data-autofocus
                        className={`bg-grey
            hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow`}
                        style={{ width: 'fit-content' }}
                        onClick={() => handleAccept()}
                      >
                        {acceptMessage}
                      </button>

                      <button
                        className={`bg-grey
                   hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow ml-2`}
                        style={{ width: 'fit-content' }}
                        onClick={() => props.handleCancel()}
                      >
                        {cancelMessage}
                      </button>
                    </div>
                  </div>
                </div>
              </Draggable>
            </div>
          </div>
        </FocusLock>
      </div>
    </div>
  );
};

export default PrinterWindow;
