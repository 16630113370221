import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import TreeMenuWrapper, { ItemComponent, Item } from 'react-simple-tree-menu';

import './SecurityMenu.scss';

import { SelectedGroup } from '../../models/types';
import userIcon from '../../icons/user';
import groupIcon from '../../icons/group';
import openNodeIcon from '../../icons/openNode';
import closeNodeIcon from '../../icons/closeNode';
import { ADD_GROUP_MODAL, FIRST_LEVEL_NODE, NO_MODAL_OPEN, SECOND_LEVEL_NODE, TYPE_USER, USER_MODAL } from '../../models/constants';
import { GroupsAsTreeMenu } from '../../models/groupsAsTreeMenu';
import { UserBasicInfo } from '../../models/userBasicInfo';

interface GroupToTree {
  key: string;
  label: string;
  item: SelectedGroup;
  nodes: GroupToTree[];
  isUser: boolean;
}

interface SecurityMenuProps {
  securityGroups: GroupsAsTreeMenu[];
  onSelectGroup: (group: SelectedGroup) => void;
  expandedTree: boolean;
  modalOpen: string;
  onChangesWarningChange: (isShowing: boolean, undoChanges: boolean) => void;
  onSelectedItemChange: (node: SelectedGroup) => void;
  selectedItem?: SelectedGroup;
  onModalChange: (modalOpenName: string) => void;
  onIsEditingChange: (isEditing: boolean) => void;
  onIsWarningToEditChange: (isEdit: boolean) => void;
  setChangesWarning: (isShowing: boolean) => void;
  hasModifiedInformation: boolean;
  onHasModifiedChange: (hasModified: boolean) => void;
  undoChangesResponse: boolean;
  setUndoChangesResponse: (undoChanges: boolean) => void;
  isLoadingGroup: boolean;
}

export function SecurityMenu({
  securityGroups,
  onSelectGroup,
  expandedTree,
  modalOpen,
  onChangesWarningChange,
  onSelectedItemChange,
  selectedItem,
  onModalChange,
  onIsEditingChange,
  onIsWarningToEditChange,
  setChangesWarning,
  onHasModifiedChange,
  hasModifiedInformation,
  undoChangesResponse,
  setUndoChangesResponse,
  isLoadingGroup
}: SecurityMenuProps) {
  const intl = useIntl();
  let index = 0;
  const treeData: GroupToTree[] = mapGroupsToTree(securityGroups);
  const [pendingChanges, setPendingChanges] = useState<Item>(null);

  if (undoChangesResponse !== null && pendingChanges) {
    if (undoChangesResponse) {
      switchNodes(pendingChanges);
    }
    setPendingChanges(null);
    setUndoChangesResponse(null);
  }

  function mapGroupsToTree(securityGroups: GroupsAsTreeMenu[]): GroupToTree[] {
    const mappedChild: GroupToTree[] = securityGroups.map((group) => {
      index++;

      return {
        key: group.group + index,
        label: group.group + ' - ' + group.description,
        nodes: mapGroupsToTree(group.subGroups).concat(mapUsersToTree(group.users)),
        item: group,
        isUser: false,
      };
    });

    return mappedChild;
  }

  function mapUsersToTree(users: UserBasicInfo[]): GroupToTree[] {
    const mappedUsers: GroupToTree[] = users.map((user) => {
      index++;

      return {
        key: user.name + index,
        label: user.name,
        nodes: [],
        item: user,
        isUser: true,
      };
    });

    return mappedUsers;
  }

  function onClick(node: Item): void {
    if (hasModifiedInformation === false) {
      switchNodes(node);
    } else {
      setPendingChanges(node);
      onChangesWarningChange(true, null);
    }
  }

  function switchNodes(node: Item): void {
    onSelectedItemChange(node.item);
    if (hasModifiedInformation && (modalOpen === ADD_GROUP_MODAL || modalOpen === USER_MODAL)) {
      onChangesWarningChange(true, null);
    }
    onSelectGroup(node.item);
    const modalToOpen: string = node.item.type === TYPE_USER ? USER_MODAL : ADD_GROUP_MODAL;
    onIsEditingChange(true);
    onModalChange(modalToOpen);
  }

  function calculatePadding(level: number): string {
    let padding: string = '';

    if (level === FIRST_LEVEL_NODE) {
      padding = '0.75rem';
    } else if (level === SECOND_LEVEL_NODE) {
      padding = '2.5rem';
    } else {
      padding = (level * 2).toString() + '.25rem';
    }

    return padding;
  }

  function renderOpenedIcon(node: SelectedGroup): JSX.Element {
    return (
      <div className="flex items-center justify-center" style={{ paddingRight: '10px' }}>
        {closeNodeIcon}
        {groupIcon}
      </div>
    );
  }

  function renderClosedIcon(node: SelectedGroup): JSX.Element {
    return (
      <div className="flex items-center justify-center">
        {openNodeIcon}
        {groupIcon}
      </div>
    );
  }

  function renderOpenedNodes(): string[] {
    let openedNodes: string[] = treeData.map((node) => {
      return node.key;
    });
    treeData.forEach((node) => {
      node.nodes?.length !== 0 && mapChildren(node.nodes, node.key, openedNodes);
    });

    function mapChildren(children: GroupToTree[], keyParent: string, opened: string[]): void {
      children?.forEach((child) => {
        if (child.nodes?.length !== 0) {
          mapChildren(child.nodes, keyParent + '/' + child.key, opened);
          opened.push(keyParent + '/' + child.key);
        }
      });
    }

    return expandedTree ? openedNodes : [];
  }

  return (
    <div className="security-menu-container">
      <div className="title">{intl.formatMessage({ id: 'securityManager' })}</div>
      <TreeMenuWrapper
        onClickItem={onClick}
        data={treeData}
        hasSearch={false}
        resetOpenNodesOnDataUpdate={expandedTree}
        initialOpenNodes={renderOpenedNodes()}
      >
        {({ items: nodes }) => (
          <ul className="tree-item-group" style={{ pointerEvents: isLoadingGroup ? 'none' : undefined, opacity: isLoadingGroup ? '0.5' : undefined }}>
            {nodes.map((node) => (
              <div key={node.key}>
                {node.isUser ? (
                  <div
                    className={`flex rstm-tree-item-level${node.level} items-center border-b`}
                    style={{
                      paddingLeft: calculatePadding(node.level),
                      backgroundColor: `${selectedItem === node.item ? 'rgba(190, 198, 209, 0.616)' : ''}`,
                      color: `${selectedItem === node.item ? '#6767ca' : ''}`,
                    }}
                  >
                    <div style={{ width: '40px', paddingLeft: '10px' }}>{userIcon}</div>
                    <ItemComponent {...node} style={{ paddingLeft: '0rem', paddingRight: '10px', backgroundColor: 'transparent', border: 'none' }} />
                  </div>
                ) : !node.hasNodes ? (
                  <div
                    className={`flex rstm-tree-item-level${node.level} items-center border-b`}
                    style={{
                      paddingLeft: calculatePadding(node.level),
                      backgroundColor: `${selectedItem === node.item ? 'rgba(190, 198, 209, 0.616)' : ''}`,
                      color: `${selectedItem === node.item ? '#6767ca' : ''}`,
                    }}
                  >
                    <div style={{ width: '40px', paddingLeft: '10px' }}>{groupIcon}</div>
                    <ItemComponent {...node} style={{ paddingLeft: '0rem', paddingRight: '10px', backgroundColor: 'transparent', border: 'none' }} />
                  </div>
                ) : (
                  <ItemComponent
                    {...node}
                    openedIcon={renderOpenedIcon(node.item)}
                    closedIcon={renderClosedIcon(node.item)}
                    style={{
                      paddingRight: '10px',
                      backgroundColor: `${selectedItem === node.item ? 'rgba(190, 198, 209, 0.616)' : ''}`,
                      color: `${selectedItem === node.item ? '#6767ca' : ''}`,
                    }}
                  />
                )}
              </div>
            ))}
          </ul>
        )}
      </TreeMenuWrapper>
    </div>
  );
}
