import React from 'react';
import { Help } from '../../../../../UI-kit';

import './QuestionMark.scss';

interface QuestionMarkProps {
  click: (element?: any) => void;
  disabled: boolean;
  className: string;
}

const QuestionMarkIcon = ({ click, disabled = false, className }: QuestionMarkProps) => (
  <Help style={null} click={click} tlid={undefined} disabled={disabled} className={'help-button' + ' ' + className} />
);

export default QuestionMarkIcon;
