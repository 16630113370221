import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { useTheme } from './../../../../themes/ThemeContext';

const ErrorMessage = ({ message }) => {
  const themeState = useTheme();
  const { error } = themeState.theme;

  const opacityKeyframes = {
    from: {
      opacity: 0,
    },

    to: {
      opacity: 1,
    },
  };

  const styles = StyleSheet.create({
    error: {
      margin: '.5rem 0 .5rem',
      textAlign: 'center',
      color: error.error,
      transition: '.5s',
    },
    fadein: {
      animationName: [opacityKeyframes],
      animationDuration: '.5s',
    },
  });

  if (message) {
    return <p className={css(styles.error, styles.fadein)}>{message}</p>;
  } else {
    return null;
  }
};

export default ErrorMessage;
