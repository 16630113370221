import React, { useState } from 'react';
import { store } from '../../../store';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFilterField, getFilterFieldValue } from './filterFields';
import { getUsuariosBasicInfo, getGroupDescriptions } from '../../../api/bitacora';
import { getHelpWindow, getHelpButton, getColumnsGrupo, getColumnsInicialesMod } from './helpOperations';
import DateSelector from '../DateSelector';
import { getBitacoraGrupoFilterResult } from './filterGrupo';
import { exportGrupos } from './exportGrupos';
import { css, StyleSheet } from 'aphrodite';
import ModalMessage from '../../ModalMessage';
import { createNullDate } from '../../../../UI-kit/helpers/dateHelper';

const ConsultaGrupos = (props) => {
  const bitacoraApp = store.getState().bitacoraApp;
  let { filterListID } = props;
  const [helpColumns, setHelpColumns] = useState([]);
  const [helpData, setHelpData] = useState([]);
  const [title, setTitle] = useState([]);
  const intl = useIntl();
  const [disabledButtonPrev, setDisabledButtonPrev] = useState(bitacoraApp[filterListID] ? bitacoraApp[filterListID].buttonPrevState : true);
  const [disabledButtonNext, setDisabledButtonNext] = useState(bitacoraApp[filterListID] ? bitacoraApp[filterListID].buttonNextState : true);
  const [beginData, setBeginData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const filterFields = {
    grupo: {
      getData: getGroupDescriptions,
      getColumns: getColumnsGrupo,
      title: intl.formatMessage({
        id: 'Tablas.bitacora_Grupo',
        defaultMessage: 'Grupo',
      }),
    },
    inicialesmodificador: {
      getData: getUsuariosBasicInfo,
      getColumns: getColumnsInicialesMod,
      title: intl.formatMessage({
        id: 'inicialesModificador',
        defaultMessage: 'Iniciales del Modificador',
      }),
    },
  };

  const setFilterFields = () => {
    document.getElementById('grupo').value = '';
    document.getElementById('inicialesmodificador').value = '';
  };

  const generateFilterField = (id) => {
    let helpButton = null;
    if (filterFields[id]) {
      helpButton = getHelpButton(id, filterFields[id], setHelpColumns, setHelpData, setLoading, setTitle, helpData ? helpData.length : 0);
    }

    const fieldElement = getFilterField(id, updateFilterInfo, intl, helpButton, filterListID);

    return fieldElement;
  };

  const updateFilterInfo = (e, first, max, nullDates) => {
    if (store.getState().bitacoraApp.currentTabIndex === filterListID) {
      let fechaHoraDesde, fechaHoraHasta;
      if (nullDates) {
        let fromTime = createNullDate();
        let toTime = createNullDate();
        fechaHoraDesde = fromTime;
        fechaHoraHasta = toTime;
      } else {
        if (store.getState().bitacoraApp[filterListID] && store.getState().bitacoraApp[filterListID].filterParams) {
          fechaHoraDesde = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraDesde;
          fechaHoraHasta = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraHasta;
        }
      }
      let filterParams = {
        fechaHoraDesde,
        fechaHoraHasta,
        firstResult: first,
        maxResults: max,
        grupo: getFilterFieldValue('grupo', e),
        inicialesmodificador: getFilterFieldValue('inicialesmodificador', e),
        persistenceUnit: 'Local',
        compareVisibleRows: ['grupo', 'descripcion', 'grupobase', 'autorizacion', 'confirmacion', 'heredamsg'],
        compareTextAreaFields: '{motivo}',
      };
      if (store.getState().bitacoraApp[filterListID]) {
        filterParams = { ...store.getState().bitacoraApp[filterListID].filterParams, ...filterParams };
      }
      let filterData = {};
      filterData[filterListID] = { ...store.getState().bitacoraApp[filterListID], filterParams };
      store.setState({
        bitacoraApp: {
          ...store.getState().bitacoraApp,
          ...filterData,
        },
      });
      closeHelp();
    }
  };

  const closeHelp = () => {
    setHelpColumns([]);
    setHelpData([]);
    setTitle('');
  };

  const helpAcceptHandler = (value, control) => {
    document.getElementById(control).value = value;
    let filterData = {};
    let controlEntry = {};
    controlEntry[control] = value;
    filterData[filterListID] = {
      ...store.getState().bitacoraApp[filterListID],
      filterParams: { ...store.getState().bitacoraApp[filterListID].filterParams, ...controlEntry },
    };
    store.setState({
      bitacoraApp: {
        ...store.getState().bitacoraApp,
        ...filterData,
      },
    });
    closeHelp();
  };

  let fields = [];
  for (let field of Object.keys(filterFields)) {
    fields.push(generateFilterField(field));
  }

  const styles = StyleSheet.create({
    topazDisplay: {
      pointerEvents: loading ? 'none' : 'auto',
      cursor: loading ? 'wait' : 'auto',
    },
    overlay: {
      width: '100%',
      height: '100%',
      minHeight: '100%',
      zIndex: 100,
      position: 'absolute',
      top: 0,
      left: 0,
      cursor: 'wait',
    },
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
      <div className={css(loading && styles.overlay)}></div>
      <DateSelector filterListID={filterListID} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        {fields}
      </div>
      {getHelpWindow(helpData, helpColumns, helpAcceptHandler, closeHelp, title)}
      <div style={{ display: 'flex', flexDirection: 'row', width: '133%', flexWrap: 'nowrap', overflow: 'visible', justifyContent: 'center' }}>
        {showModal && (
          <ModalMessage
            message={
              <FormattedMessage
                id="message_desde_no_mayor_que_hasta"
                defaultMessage={`El valor de la fecha 'Desde' no puede ser mayor que 'Hasta'`}
              />
            }
            handleAccept={async () => {
              setShowModal(false);
            }}
            acceptTitle={'Aceptar'}
            draggable={true}
          />
        )}
        <button
          className={
            disabledButtonPrev
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          disabled={disabledButtonPrev}
          onClick={(e) => {
            let maxResults = store.getState().bitacoraApp[filterListID].filterParams.maxResults;
            let diff = beginData - maxResults;
            if (diff < 0) {
              diff = 0;
            }
            setBeginData(diff);
            updateFilterInfo(e, diff, 201);
            getBitacoraGrupoFilterResult(
              store.getState().bitacoraApp[filterListID].filterParams,
              filterListID,
              intl,
              setDisabledButtonPrev,
              setDisabledButtonNext,
            );
          }}
        >
          {'<<'}
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={(e) => {
            let fechaDesde = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraDesde;
            if (fechaDesde === undefined) {
              fechaDesde = createNullDate();
            }

            let fechaHasta = store.getState().bitacoraApp[filterListID].filterParams.fechaHoraHasta;
            if (fechaHasta === undefined) {
              fechaHasta = createNullDate();
            }
            if (fechaHasta < fechaDesde) {
              setShowModal(true);
            }
            setLoading(true);
            updateFilterInfo(e, 0, 201);
            getBitacoraGrupoFilterResult(
              store.getState().bitacoraApp[filterListID].filterParams,
              filterListID,
              intl,
              setDisabledButtonPrev,
              setDisabledButtonNext,
              setLoading,
            );
          }}
        >
          <FormattedMessage id="filtrar" defaultMessage={`Filtrar`} />
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={() => {
            const state = store.getState();
            if (!state.bitacoraApp[filterListID].resultColumns || state.bitacoraApp[filterListID].resultColumns.length === 0) {
              props.showModal(<FormattedMessage id="noData" defaultMessage={`No hay datos!`} />);
            } else {
              updateFilterInfo();
              exportGrupos(
                store.getState().bitacoraApp[filterListID].filterParams,
                store.getState().bitacoraApp[filterListID].resultColumns,
                filterListID,
                intl,
              );
            }
          }}
        >
          <FormattedMessage id="exportarCSV" defaultMessage={`Exportar CSV`} />
        </button>
        <button
          className={`text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
          onClick={(e) => {
            setFilterFields();
            updateFilterInfo(e, 0, 201, true);
            getBitacoraGrupoFilterResult(
              store.getState().bitacoraApp[filterListID].filterParams,
              filterListID,
              intl,
              setDisabledButtonPrev,
              setDisabledButtonNext,
              setLoading,
            );
          }}
        >
          <FormattedMessage id="borrarFiltro" defaultMessage={`Limpiar Filtros`} />
        </button>
        <button
          className={
            disabledButtonNext
              ? 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary opacity-50 cursor-not-allowed'
              : 'text-white rounded m-2 py-1 px-2 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary'
          }
          disabled={disabledButtonNext}
          onClick={(e) => {
            setBeginData(beginData + 201);
            updateFilterInfo(e, beginData + 201, 201);
            getBitacoraGrupoFilterResult(
              store.getState().bitacoraApp[filterListID].filterParams,
              filterListID,
              intl,
              setDisabledButtonPrev,
              setDisabledButtonNext,
            );
          }}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default ConsultaGrupos;
