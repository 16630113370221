import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  ABORTED_PROCESS,
  FINISHED_PROCESS,
  RESULT_ERROR,
  RESULT_OK,
  RESULT_WARNING,
  RUNNING_PROCESS,
  STOPPED_PROCESS,
} from '../../../../models/constants';
import { GroupInstance } from '../../../../models/groupInstance';

interface ProcessInformationProps {
  selectedInstance?: GroupInstance;
}

const OK_RESULT_CODE: number = 1;
const ERROR_RESULT_CODE: number = 2;
const WARNING_RESULT_CODE: number = 3;

export function ProcessInformation({ selectedInstance }: ProcessInformationProps): JSX.Element {
  const intl = useIntl();

  function renderState(): string {
    const stateProcess: string =
      selectedInstance?.state === ABORTED_PROCESS
        ? intl.formatMessage({ id: 'aborted' })
        : selectedInstance?.state === RUNNING_PROCESS
        ? intl.formatMessage({ id: 'executing' })
        : selectedInstance?.state === STOPPED_PROCESS
        ? intl.formatMessage({ id: 'stopped' })
        : selectedInstance?.state === FINISHED_PROCESS
        ? intl.formatMessage({ id: 'finalized' })
        : '';

    return stateProcess;
  }

  function renderResultCode(): string {
    const resultCode: string =
      selectedInstance?.processResult.resultCode === OK_RESULT_CODE
        ? RESULT_OK
        : selectedInstance?.processResult.resultCode === ERROR_RESULT_CODE
        ? RESULT_ERROR
        : selectedInstance?.processResult.resultCode === WARNING_RESULT_CODE
        ? RESULT_WARNING
        : '';

    return resultCode;
  }

  return (
    <div className="w-full">
      <div className="pl-2">
        <div className="bg-grey-lighter-lighter">
          <div className="info-row-container bg-white">
            <div className="info-row">{intl.formatMessage({ id: 'state' })}</div>
            <div className="info-row">{renderState()}</div>
          </div>
          <div className="info-row-container">
            <div className="info-row">{intl.formatMessage({ id: 'executionId' })}</div>
            <div className="info-row">{selectedInstance?.executionId}</div>
          </div>
          <div className="info-row-container bg-white">
            <div className="info-row">{intl.formatMessage({ id: 'baseGroupId' })}</div>
            <div className="info-row">{selectedInstance?.parentGroupId || selectedInstance?.executionId}</div>
          </div>
          <div className="info-row-container">
            <div className="info-row">{intl.formatMessage({ id: 'user' })}</div>
            <div className="info-row">{selectedInstance?.username}</div>
          </div>
          <div className="info-row-container bg-white">
            <div className="info-row">{intl.formatMessage({ id: 'start' })}</div>
            <div className="info-row">{selectedInstance && moment(selectedInstance?.initTime).format('DD-MM-YYYY HH:mm:ss')}</div>
          </div>
          <div className="info-row-container">
            <div className="info-row">{intl.formatMessage({ id: 'end' })}</div>
            <div className="info-row">{selectedInstance && moment(selectedInstance?.endTime).format('DD-MM-YYYY HH:mm:ss')}</div>
          </div>
          <div className="info-row-container bg-white">
            <div className="info-row">{intl.formatMessage({ id: 'resultCode' })}</div>
            <div className="info-row">{selectedInstance && renderResultCode()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
