import { getGroupByNameAsync, getSpecificGroupPermissionsAsync } from '../../../../../api/securityManager';
import { DEFAULT_RESTRICTION_KEY } from '../../../models/constants';

function fillNumberWithZeros(numberToFill, width = 4) {
  const zero = '0';
  let result = numberToFill?.toString() || zero;

  return result.length >= width ? result : new Array(width - result.length + 1).join(zero) + result;
}

export const getAllow = (ownRestrictions, parentGroup, defaultAllow, restrictionKey, option, type) => {
  if (ownRestrictions[restrictionKey] !== undefined) {
    return ownRestrictions[restrictionKey].allow;
  }

  // Para títulos si no tiene valor y es grupo base toma el por defecto.
  if (!parentGroup && parseInt(option?.identificacion) === 0 && defaultAllow !== null && defaultAllow !== undefined) {
    return defaultAllow;
  }

  return null;
};

export const getGrandParentRestrictions = async (parentGroup, parentGroups, callbackFunction) => {
  if (!parentGroup) {
    return null;
  }
  let dataParentGroup = await getGroupByNameAsync(parentGroup);
  let restrictions = await callbackFunction(dataParentGroup?.grupobase);
  if (restrictions && dataParentGroup?.grupobase) {
    if (parentGroups === null) {
      parentGroups = [restrictions];
    } else {
      parentGroups.push(restrictions);
    }
  }
  if (!dataParentGroup?.grupobase) {
    return parentGroups;
  } else {
    return getGrandParentRestrictions(dataParentGroup?.grupobase, parentGroups, callbackFunction);
  }
};

export const getRecursivePermissions = async (parentGroup, permissions) => {
  let parentPermissions = await getSpecificGroupPermissionsAsync(parentGroup);
  let dataParentGroup = await getGroupByNameAsync(parentGroup);
  if (parentPermissions) {
    if (permissions === null) {
      permissions = [parentPermissions];
    } else {
      permissions.push(parentPermissions);
    }
  }
  if (!dataParentGroup?.grupobase) {
    return permissions;
  } else {
    return getRecursivePermissions(dataParentGroup?.grupobase, permissions);
  }
};

export const getParentPermissionExclusive = (parentPermissionsArray, permission) => {
  let result = null;
  let i = 0;
  while (result === null && i < parentPermissionsArray.length) {
    let parentPermissions = parentPermissionsArray[i];
    if (parentPermissions) {
      let parentPermission = parentPermissions.find((p) => p.permiso === permission.numero);
      if (parentPermission) {
        result = parentPermission.permite;
      }
    }
    i++;
  }

  return result;
};

export const getGrandParentPermission = (restrictionKey, option, grandParentRestrictions, defaultAllow) => {
  let permission = null;
  let i = 0;

  if (grandParentRestrictions) {
    while ((permission === null || permission === undefined) && grandParentRestrictions[i]) {
      let restrictions = grandParentRestrictions[i];
      if (option.identificacion === 0) {
        let titleKey = restrictionKey;
        if (restrictions[titleKey] !== undefined) {
          permission = restrictions[titleKey].allow;
        } else {
          permission = defaultAllow;
        }
      }
      if (restrictions[restrictionKey] !== undefined && restrictions[restrictionKey] !== null) {
        permission = restrictions[restrictionKey].allow;
      }
      i++;
    }
  }
  if (permission === undefined || permission === null) {
    permission = defaultAllow;
  }
  return permission;
};

export const getBaseAllow = async (group, callbackFunction) => {
  let allow = null;
  let baseGroup = await getBaseGroup(group);
  if (baseGroup) {
    let restrictions = await callbackFunction(baseGroup);
    if (restrictions[DEFAULT_RESTRICTION_KEY]) {
      allow = restrictions[DEFAULT_RESTRICTION_KEY].allow;
    }
  }

  return allow;
};

export const getBaseGroup = async (group) => {
  let groupData = await getGroupByNameAsync(group);
  if (!groupData?.grupobase) {
    return group;
  } else {
    return getBaseGroup(groupData?.grupobase);
  }
};

export const getParentPermission = (restrictionKey, option, ownRestrictions, parentRestrictions, defaultAllow, grandParentRestrictions) => {
  let permission = null;
  if (ownRestrictions[restrictionKey] !== undefined && ownRestrictions[restrictionKey].parentPermission !== null) {
    permission = ownRestrictions[restrictionKey].parentPermission;
  }
  if (permission === null || permission === undefined) {
    if (option.identificacion === 0) {
      let titleKey = restrictionKey;
      if (parentRestrictions[titleKey] !== undefined) {
        permission = parentRestrictions[titleKey].allow;
      } else {
        if (permission === null) {
          let grandParentPermission = getGrandParentPermission(restrictionKey, option, grandParentRestrictions, defaultAllow);
          if (grandParentPermission !== undefined && grandParentPermission !== null) {
            permission = grandParentPermission;
          } else {
            permission = defaultAllow;
          }
        }
      }
    }
  }

  if (permission === null || permission === undefined) {
    if (parentRestrictions[restrictionKey] !== undefined && parentRestrictions[restrictionKey] !== null) {
      permission = parentRestrictions[restrictionKey].allow;
    }
  }
  if (permission === null || permission === undefined) {
    let grandParentPermission = getGrandParentPermission(restrictionKey, option, grandParentRestrictions, defaultAllow);
    if (grandParentPermission) {
      permission = grandParentPermission;
    }
  }
  return permission;
};

export const getTitlePermission = (option, ownRestrictions, parentGroup, defaultAllow, items) => {
  let titleRestrictions = items.filter((m) => m.identificacion === 0);
  let titulo = titleRestrictions.find((t) => t?.titulo === option?.titulo);
  let titleKey = fillNumberWithZeros(titulo?.titulo) + fillNumberWithZeros(titulo?.identificacion);
  return getAllow(ownRestrictions, parentGroup, defaultAllow, titleKey, titulo);
};
