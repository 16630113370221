import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import Draggable from 'react-draggable';
import IcoMoon from 'react-icomoon';
import FocusTrap from 'react-focus-trap';
import { ABOVE_ALL } from '../constants/zIndexValues';
import { connect } from 'unistore/react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { storeActions } from '../../src/store';
import ModalMessage from '../../src/components/ModalMessage';
import { addValueTest } from '../../src/components/Testing/HandleLineTest';

import {
  getCommissionsList,
  getCommissionsListDifer,
  getCommissionsListDate,
  getCommissionsListDiferDate,
  recalculateCommissionsList,
  updateCommissionsList,
} from '../../src/api/commissions';
import CommissionsInformation from './CommissionsInformation';
import NumberFormat from 'react-number-format';
import Dialog from './Dialog';
import Overlay from './Overlay';

import { getCurrentTabInformation } from '../../src/selectors';

const Button = ({ title, click, className, disabled, id }) => {
  return (
    <button
      id={id}
      className={`${className} ${
        !disabled ? 'bg-grey focus:outline-none focus:bg-primary hover:bg-primary' : 'bg-smoke'
      } text-white rounded shadow m-4 py-2 px-4 text-sm focus:outline-none`}
      onClick={click}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

const CommissionsWindow = ({
  buttonsData,
  eventData,
  escapePressed,
  intl,
  modalId,
  operationID,
  operationLook,
  pauseFocus,
  removeComponentTree,
  removeTreeNode,
  resumeFocus,
  removeModal,
}) => {
  const [commissionsData, setCommissionsData] = useState({});
  const [fetchLoading, setFetchLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [showWindow, setShowWindow] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [recalculateEnabled, setRecalculateEnabled] = useState(false);
  const [acceptEnabled, setAcceptEnabled] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState(null);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    pauseFocus();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEnableChange = (event, index) => {
    commissionsData.commissionList[index].enabled = event.target.checked;
    setCommissionsData(commissionsData);
    setAcceptEnabled(false);
    setRecalculateEnabled(true);
    setCommissionRows(commissionsData.commissionList);
  };

  const handleModifyValueChange = (event, index) => {
    let id = rows[index].id;
    let indexOK = 0;
    for (let i = 0; i < commissionsData.commissionList.length; i++) {
      if (id === commissionsData.commissionList[i].chargeDefinition.chargeId) {
        indexOK = i;
        break;
      }
    }

    if (event.target.value === '') {
      event.target.value = '0';
    }

    let editIsValid = validateMinMaxPercentage(
      commissionsData.commissionList[indexOK].chargeDefinition,
      event.target.value,
      commissionsData.commissionList[indexOK].calculatedValue,
    );
    if (editIsValid) {
      commissionsData.commissionList[indexOK].modifiedValue = event.target.value;

      setCommissionsData(commissionsData);
      setAcceptEnabled(false);
      setRecalculateEnabled(true);

      setCommissionRows(commissionsData.commissionList);
    }
  };

  const handleRecalculate = async () => {
    let commData = getCommisionData(commissionsData);
    const data = await recalculateCommissionsList(operationID, commData);
    if (data.commissionList) {
      setCommissionsData(data);
      setCommissionRows(data.commissionList);
    } else {
      fetchData();
      if (data.mensaje) {
        setShowDialog(true);
        setDialogMessage(data.mensaje);
        setDialogTitle(<FormattedMessage id="error" defaultMessage={`Error`} />);
      }
    }
    setAcceptEnabled(true);
    setRecalculateEnabled(false);
  };

  const fetchCommissionsData = async (operationID, event, sign, difer, posting, branch, processDate, fieldCommissionsValue, commissionsValue) => {
    setFetchLoading(true);

    let data = {};
    if (processDate) {
      if (difer) {
        data = await getCommissionsListDiferDate(operationID, event, sign, branch, posting, processDate, true);
      } else {
        data = await getCommissionsListDate(operationID, event, sign, branch, posting, processDate);
      }
    } else {
      if (difer) {
        data = await getCommissionsListDifer(operationID, event, sign, true);
      } else {
        data = await getCommissionsList(operationID, event, sign);
      }
    }

    if (!data.commissionList.length) {
      const msg = intl.formatMessage({
        id: 'noFees',
        defaultMessage: `No hay cargos asociados al evento`,
      });
      setModalData({ showModal: true, modalText: msg });
    }

    setCommissionsData(data);
    setCommissionRows(data.commissionList);
    setFetchLoading(false);
  };

  const fetchData = async () => {
    let {
      event,
      signo: sign,
      diferir: difer,
      asiento: posting,
      sucursal: branch,
      fproceso: processDate,
      fieldValorCargos: fieldCommissionsValue,
      valorCargos: commissionsValue,
    } = eventData;
    fetchCommissionsData(operationID, event, sign, difer, posting, branch, processDate, fieldCommissionsValue, commissionsValue);
  };

  const formatCurrency = (value, decimalCount = 2, decimal = '.', thousands = ',') => {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    let i = parseInt((value = Math.abs(Number(value) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return (
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(value - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  };

  const hideDialog = () => {
    setShowDialog(false);
    setDialogMessage('');
    setDialogTitle(null);
  };

  const setCommissionRows = (commissionsList) => {
    let rows = [];
    commissionsList.forEach((cData) => {
      let { calculatedCurrency, calculatedValue, modifiedValue, chargeDefinition, info, enabled } = cData;
      let { chargeId, description, exonerable, updatable } = chargeDefinition;
      let row = {
        id: chargeId,
        description: description,
        coin: calculatedCurrency,
        amount: formatCurrency(calculatedValue),
        modified: { modifiedValue, updatable },
        enable: { exonerable, enabled },
        info,
        chargeDefinition: { chargeId },
      };
      rows.push(row);
      if (cData.taxesResults) {
        cData.taxesResults.forEach((cDataTax) => {
          let { calculatedCurrency, calculatedValue, modifiedValue, chargeDefinition, info, enabled } = cDataTax;
          let { chargeId, description, exonerable, updatable } = chargeDefinition;
          let rowResult = {
            id: chargeId,
            description: description,
            coin: calculatedCurrency,
            amount: calculatedValue,
            modified: { modifiedValue, updatable },
            enable: { exonerable, enabled },
            info,
            chargeDefinition: { chargeId },
          };
          rows.push(rowResult);
        });
      }
      setRows(rows);
    });
  };

  const getCommisionData = (commissionsData) => {
    let commissionsDataReturn = commissionsData;
    commissionsDataReturn.commissionList.forEach((cData) => {
      if (isNaN(cData.modifiedValue)) {
        cData.modifiedValue = cData.modifiedValue.replaceAll(',', '');
      }
    });
    return commissionsDataReturn;
  };

  const removeDisabledCommissions = (commissionsData) => {
    let commissionsDataReturn = { ...commissionsData };
    let idx = 0;
    while (idx < commissionsDataReturn.commissionList.length) {
      if (commissionsData.commissionList[idx].enabled) {
        idx++;
      } else {
        commissionsDataReturn.commissionList.splice(idx);
      }
    }
    return commissionsDataReturn;
  };

  const showInformation = (showInfo, showAll = false) => {
    setShowInfo(showInfo);
    setShowAll(showAll);
  };

  const toggleWindow = async () => {
    const commissionList = removeDisabledCommissions(commissionsData);
    if (Object.keys(commissionList).length > 0) {
      await updateCommissionsList(operationID, commissionList);
    }
    setShowWindow((showWindow) => !showWindow);
    removeComponentTree(removeTreeNode);
    resumeFocus();
  };

  const validateMinMaxPercentage = (chargeDefinition, modifiedValue, calculatedValue) => {
    let maxPercentage = 0;
    let minPercentage = 0;
    let maxAmount = 0;
    let minAmount = 0;
    let { porcentajeMaximo: cdMaxPercentage, porcentajeMinimo: cdMinPercentage, floorValue, ceilValue } = chargeDefinition;
    if (calculatedValue === 0) {
      return true;
    }
    if (cdMaxPercentage !== 0) {
      maxPercentage = cdMaxPercentage;
      maxAmount = calculatedValue + calculatedValue * (maxPercentage / 100);
    }
    if (cdMinPercentage !== 0) {
      minPercentage = cdMinPercentage;
      minAmount = calculatedValue - calculatedValue * (minPercentage / 100);
    }
    if (maxPercentage === 0 && minPercentage === 0) {
      return true;
    }
    if ((modifiedValue >= minAmount || minPercentage === 0) && (modifiedValue <= maxAmount || maxPercentage === 0)) {
      return true;
    } else {
      if (floorValue > 0 && floorValue > minAmount) {
        minAmount = floorValue;
      }
      if (ceilValue > 0 && ceilValue < maxAmount) {
        maxAmount = ceilValue;
      }
      let message = `${intl.formatMessage({
        id: 'amountEnteredOutsideRange',
        defaultMessage:
          'El monto ingresado estÃ¡ fuera del rango definido por porcentaje para el cargo. Se utiliza el lÃ­mite mÃ¡s cercano al valor.',
      })}
      ${intl.formatMessage({
        id: 'max',
        defaultMessage: 'Maximo',
      })}: ${maxAmount}
      ${intl.formatMessage({
        id: 'min',
        defaultMessage: 'Minimo',
      })}: ${minAmount}`;
      let dialogTitle = intl.formatMessage({
        id: 'maxMinControlPercentage',
        defaultMessage: 'Control MÃ¡ximo-MÃ­nimo del cargo por porcentaje',
      });
      setShowDialog(true);
      setDialogMessage(message);
      setDialogTitle(dialogTitle);
      return false;
    }
  };

  const style = {
    width: 700,
    height: 380,
  };
  const buttonStyles = {
    bottom: 0,
    width: '100%',
  };
  const gridStyle = {
    height: 270,
    width: '100%',
    marginTop: 5,
  };

  const { commissionList } = commissionsData;

  if ((fetchLoading || (commissionList && !commissionList.length)) && (!modalData || !modalData.showModal)) {
    return null;
  }

  const columsData = [
    { accessor: 'id', Header: 'Id' },
    {
      accessor: 'description',
      Header: `${intl.formatMessage({
        id: 'description',
        defaultMessage: 'DescripciÃ³n',
      })}`,
    },
    {
      accessor: 'coin',
      Header: `${intl.formatMessage({
        id: 'coin',
        defaultMessage: 'Moneda',
      })}`,
    },
    {
      accessor: 'amount',
      Header: `${intl.formatMessage({
        id: 'amount',
        defaultMessage: 'Importe',
      })}`,
    },
    {
      accessor: 'modified',
      Header: `${intl.formatMessage({
        id: 'modified',
        defaultMessage: 'Modificado',
      })}`,
      Cell: function renderCell(props) {
        return (
          <NumberFormat
            type="text"
            decimalSeparator="."
            thousandSeparator=","
            fixedDecimalScale={true}
            disabled={!props.value.updatable}
            value={props.value.modifiedValue}
            onBlur={(e) => handleModifyValueChange(e, props.index)}
            decimalScale={2}
            allowNegative={false}
          />
        );
      },
    },
    {
      accessor: 'enable',
      Header: `${intl.formatMessage({
        id: 'enable',
        defaultMessage: 'Habilitar',
      })}`,
      Cell: function renderCell(props) {
        return (
          <div className="flex justify-center">
            <input
              type="checkbox"
              disabled={!props.value.exonerable}
              checked={props.value.enabled}
              onChange={(e) => handleEnableChange(e, props.index)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <FocusTrap>
      {!(fetchLoading || (commissionList && !commissionList.length)) && (
        <div className={`${showWindow ? '' : 'hidden'}`}>
          <Overlay style={{ zIndex: ABOVE_ALL, top: -35 }} />
          <Draggable bounds="body" handle=".handleDragg">
            <div
              className={`border border-solid ${showInfo || showDialog ? 'border-transparent' : 'border-primary'} ${
                operationLook === true ? 'bg-light' : 'bg-grey-lighter-lighter'
              } rounded shadow absolute`}
              style={{ ...style, zIndex: ABOVE_ALL }}
            >
              <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move m-1 text-xs" />
              <div className="flex w-full">
                <div className="flex-auto text-left p-2 text-lg w-full">
                  <div className="text-base mb-4 text-center">
                    <FormattedMessage id="commissionRecalculation" defaultMessage={`RecÃ¡lculo de comisiones`} />
                  </div>
                  <ReactTable
                    data={rows}
                    columns={columsData}
                    style={gridStyle}
                    defaultPageSize={10}
                    className="-striped -highlight bg-grey-lighter-lighter"
                    noDataText={
                      <p className="text-text-base" style={{ fontSize: 11 }}>
                        <FormattedMessage id="noData" defaultMessage={`No hay datos`} />
                      </p>
                    }
                    getTrProps={(state, rowInfo, column, instance) => {
                      if (typeof rowInfo !== 'undefined') {
                        return {
                          onClick: async (e, handleOriginal) => {
                            console.log(rowInfo.index);
                            setSelectedIndex(rowInfo.index);
                            if (handleOriginal) handleOriginal();
                          },
                          style: {
                            background: rowInfo.index === selectedIndex ? '#a8a8a8' : '',
                          },
                        };
                      } else {
                        return {
                          onClick: (e, handleOriginal) => {
                            if (handleOriginal) handleOriginal();
                          },
                          style: { background: 'white', color: 'black' },
                        };
                      }
                    }}
                  />
                </div>
              </div>
              <div className="flex-auto text-center px-6 py-4 m-100 text-lg"></div>
              <div className="absolute" style={buttonStyles}>
                <Button
                  id="commisionsAccept"
                  className="float-left"
                  title={intl.formatMessage({
                    id: 'accept',
                    defaultMessage: 'Aceptar',
                  })}
                  click={(e) => {
                    addValueTest('CLICK', 'commisionsAccept', '');
                    toggleWindow();
                  }}
                  disabled={!acceptEnabled}
                />
                <Button
                  id="commisionsRecalculate"
                  className="float-left"
                  title={intl.formatMessage({
                    id: 'recalculate',
                    defaultMessage: 'Recalcular',
                  })}
                  click={(e) => {
                    addValueTest('CLICK', 'commisionsRecalculate', '');
                    handleRecalculate();
                  }}
                  disabled={!recalculateEnabled}
                />
                <Button
                  id="commisionsWatchAll"
                  className="float-right"
                  title={intl.formatMessage({
                    id: 'seeAll',
                    defaultMessage: 'Ver Todos',
                  })}
                  click={(e) => {
                    addValueTest('CLICK', 'commisionsWatchAll', '');
                    if (rows.length > 0) showInformation(true, true);
                  }}
                />
                <Button
                  id="commisionsInfo"
                  className="float-right"
                  title={intl.formatMessage({
                    id: 'information',
                    defaultMessage: 'InformaciÃ³n',
                  })}
                  click={(e) => {
                    addValueTest('CLICK', 'commisionsInfo', '');
                    if (rows.length > 0 && selectedIndex !== null) showInformation(true, false);
                  }}
                />
              </div>
            </div>
          </Draggable>
          {showInfo && (
            <CommissionsInformation
              infoCommission={rows[selectedIndex]}
              commissionsData={commissionsData}
              showInformation={showInformation}
              showAll={showAll}
            />
          )}
          {showDialog && <Dialog hideDialog={hideDialog} message={dialogMessage} dialogTitle={dialogTitle} />}
        </div>
      )}
      {modalData && modalData.showModal && (
        <ModalMessage
          message={modalData.modalText}
          handleAccept={() => {
            resumeFocus();
            setModalData({
              showModal: false,
            });
            removeComponentTree(removeTreeNode);
          }}
          draggable={true}
        />
      )}
    </FocusTrap>
  );
};

const mapStateToProps = (state) => {
  const { operationID, operationNumber, focusElement } = getCurrentTabInformation(state);

  const { modalMessage, modalMessageCurrentIndex } = state;

  return {
    buttonsData:
      modalMessage && modalMessage[modalMessageCurrentIndex] && modalMessage[modalMessageCurrentIndex].buttons
        ? [...modalMessage[modalMessageCurrentIndex].buttons]
        : [],
    escapePressed: modalMessage && modalMessage[modalMessageCurrentIndex] && modalMessage[modalMessageCurrentIndex].escapePressed,
    focusElement,
    modalId: modalMessage && modalMessage[modalMessageCurrentIndex] && modalMessage[modalMessageCurrentIndex].modalId,
    operationID,
    operationNumber,
  };
};

export default connect(mapStateToProps, storeActions)(injectIntl(CommissionsWindow));
