import { getUserDetails } from '../../../api/securityManager';
import { EMPTY_STRING, NOT_SELECTED } from '../models/constants';
import { UserBasicInfo } from '../models/UserBasicInfo';
import { UserDetails } from '../models/userDetails';

export async function addUserDetailsAsync(user: UserBasicInfo): Promise<UserBasicInfo> {
  const userDetails: UserDetails = await getUserDetails(user.username);
  if (!userDetails) return { ...user };
  return {
    ...user,
    ldapuser: userDetails.ldapuser || EMPTY_STRING,
    language: userDetails.lenguaje || EMPTY_STRING,
    multiplesLogins: userDetails.multipleslogins || NOT_SELECTED,
    secondLogin: userDetails.segundologin || NOT_SELECTED,
    disabled: userDetails.deshabilitado || NOT_SELECTED,
    mantdes: userDetails.mantdes || EMPTY_STRING,
    failedAttempts: userDetails.intentos || NOT_SELECTED,
    registerNumber: userDetails.nrodecaja,
    branchNumber: userDetails.nrosucursal || NOT_SELECTED,
    iniexphabilitado: userDetails.iniexphabilitado || NOT_SELECTED,
    machineNumber: userDetails.numerodemaquina,
    blockingDate: userDetails.fechabloqueo || EMPTY_STRING,
    blockingMotive: userDetails.motivoBloqueo || NOT_SELECTED,
    documentType: userDetails.tipo_documento || EMPTY_STRING,
    document: userDetails.documento || EMPTY_STRING,
    inactiveControl: userDetails.controla_inactivo === 'S' ? 1 : 0 || NOT_SELECTED,
    loginconad: userDetails.loginconad || NOT_SELECTED,
    loginType: userDetails.tipologin || NOT_SELECTED,
    loginsAmount: userDetails.cantlogins || NOT_SELECTED,
    password: userDetails.contrasenia || EMPTY_STRING,
    passwordToDate: userDetails.fechaexp || EMPTY_STRING,
    passwordInitialDate: userDetails.fechaini || EMPTY_STRING,
    passwordExpiration: userDetails.expiracioncontrasenia || EMPTY_STRING,
    passwordCreationDate: userDetails.fechacreacion || EMPTY_STRING,
    dateLastLog: userDetails.fechaultlog || EMPTY_STRING,
    sector: userDetails.sector,
    inmediatosuperior: userDetails.inmediatosuperior || EMPTY_STRING,
  };
}
