import React, { Component } from 'react';
import FocusTrap from 'react-focus-trap';
import Draggable from 'react-draggable';
import { FormattedMessage } from 'react-intl';
import Overlay from '../../../UI-kit/components/Overlay';
import IcoMoon from 'react-icomoon';
import { store } from '../../store';
import { duplicateElementControl, getId } from './HandleLineTest';

export class SqlComponent extends Component {
  constructor(props) {
    super(props);
    this.acceptHandler = this.acceptHandler.bind(this);
    this.replaceElement = this.replaceElement.bind(this);
    this.state = {
      activeButton: 'ingresar',
    };
  }

  cancelHandler() {
    let { setShowSql } = this.props;
    setShowSql(false);
  }

  radioChangeHandler(btn) {
    this.setState({ activeButton: btn });
  }

  replaceElement(textOperation, id, line) {
    for (let i = textOperation.length; i > 0; i--) {
      let idArray = getId(textOperation[i - 1]);
      if (id === idArray && textOperation[i - 1].indexOf('SENDTEXT') > 0) {
        textOperation[i - 1] = line;
        return textOperation;
      }
    }
    textOperation.push(line);
    return textOperation;
  }

  async acceptHandler() {
    let { setShowSql } = this.props;

    let recordOn = store.getState().recordOn;
    if (recordOn) {
      let textOperation = JSON.parse(window.sessionStorage.getItem('textOperation'));
      let id = document.getElementById('sqlId').value;
      let commandType = document.querySelector('input[name="radio_test_sql"]:checked').value;
      let line = '';
      if (commandType === 'ingresar') {
        line = 'CMD:QUERY ID:' + id + ' VALOR:' + document.getElementById('sqlQuery').value + '\n';
      } else if (commandType === 'comparar') {
        line = 'CMD:QUERYASSERT ID:' + id + ' VALOR:' + document.getElementById('sqlQuery').value + '\n';
      } else {
        line = 'CMD:QUERY_EXECUTE' + ' VALOR:' + document.getElementById('sqlQuery').value + '\n';
      }

      textOperation = this.replaceElement(textOperation, id, line);

      textOperation = duplicateElementControl(textOperation);
      window.sessionStorage.setItem('textOperation', JSON.stringify(textOperation));
    }

    setShowSql(false);
  }

  render() {
    return (
      <FocusTrap>
        <Overlay style={{ zIndex: 9999, top: -35 }} />
        <Draggable bounds="body" handle=".handleDragg">
          <div
            className="border border-solid border-primary bg-grey-lighter-lighter rounded shadow absolute"
            style={{
              zIndex: 9999,
              height: 525,
              width: 800,
              top: 150,
              left: 400,
            }}
          >
            <IcoMoon icon="enlarge" width="25" className="absolute pin-t pin-l handleDragg text-smoke cursor-move" style={{ padding: 1.5 }} />
            <div className="ml-5 mt-5 text-left">
              <div className="border-2 mr-5">
                <div
                  className="text-left"
                  style={{
                    height: 420,
                  }}
                >
                  <div className="ml-5 mt-5 text-left mb-5">
                    <input
                      onClick={() => this.radioChangeHandler('ingresar')}
                      className="ml-2 mr-1"
                      type="radio"
                      id="html"
                      name="radio_test_sql"
                      value="ingresar"
                      checked={this.state.activeButton === 'ingresar'}
                    />
                    <label htmlFor="html">Ingresar valor</label>
                    <input
                      onClick={() => this.radioChangeHandler('comparar')}
                      className="ml-2 mr-1"
                      type="radio"
                      id="css"
                      name="radio_test_sql"
                      value="comparar"
                      checked={this.state.activeButton === 'comparar'}
                    />
                    <label htmlFor="css">Comparar</label>
                    <input
                      onClick={() => this.radioChangeHandler('ejecutar')}
                      className="ml-2 mr-1"
                      type="radio"
                      id="css"
                      name="radio_test_sql"
                      value="ejecutar"
                      checked={this.state.activeButton === 'ejecutar'}
                    />
                    <label htmlFor="css">Ejecutar</label>
                  </div>
                  <div className="ml-5 mt-5 text-left mb-5">
                    <label className="ml-2">Id: </label>
                    <input
                      id="sqlId"
                      style={{
                        width: '400px',
                      }}
                      className="text-left appearance-none mr-1 px-1 ml-6 rounded shadow text-sm w-full"
                      type="text"
                      onChange={(event) => {}}
                      disabled={this.state.activeButton === 'ejecutar'}
                    />
                  </div>
                  <div className="ml-5 mt-2 text-left mb-5">
                    <label className="ml-2">Consulta: </label>
                    <textarea
                      id="sqlQuery"
                      className="text-sm border border-smoke w-full rounded-sm p-2"
                      style={{ width: '710px', resize: 'none' }}
                      rows="18"
                    />
                  </div>
                </div>
              </div>

              <div className={`mt-5 col-md-12 text-center`}>
                <button
                  className={`text-white rounded mt-32 m-2 py-1 px-6 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                  style={{
                    marginTop: '0rem',
                  }}
                  onClick={() => {
                    this.acceptHandler();
                  }}
                >
                  <FormattedMessage id="accept" defaultMessage={`Aceptar`} />
                </button>
                <button
                  style={{
                    marginTop: '0rem',
                  }}
                  className={`text-white rounded m-2 py-1 px-6 text-xs bg-grey focus:outline-none focus:bg-primary hover:bg-primary`}
                  onClick={() => {
                    this.cancelHandler();
                  }}
                >
                  <FormattedMessage id="cancel" defaultMessage={`Cancelar`} />
                </button>
              </div>
            </div>
          </div>
        </Draggable>
      </FocusTrap>
    );
  }
}
