import React, { Component } from 'react';
import CausesListEntry from './CausesListEntry';
import { FormattedMessage } from 'react-intl';

class ListOfCausesView extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    const {
      data,
      noDataText,
      handleSelectedCause,
      addToRefs,
      addToRefListEntry,
    } = this.props;
    let content = (
      <div className="w-3/5  mt-8">
        <div className="pl-2">
          <div className="flex items-center justify-between text-xs uppercase text-grey py-2 border-b border-light">
            <div className="pr-2"></div>
            <div className="flex-grow items-center ml-30 w-10 text-xs flex-nowrap">
              X
            </div>
            <div className="pr-2"></div>
            <div className="flex-grow items-center ml-30 w-10 text-xs flex-nowrap">
              <FormattedMessage
                id="causalDevolucion"
                defaultMessage="Causal Devolucion"
              />
            </div>
          </div>
          <div className="overflow-y-scroll" style={{ height: 480 }}>
            {data.map((item, index) => (
              <div
                className={` ${index % 2 && 'bg-grey-lighter-lighter'}`}
                key={index}
              >
                <CausesListEntry
                  item={item}
                  rowID={index}
                  key={item.value}
                  name={item.value}
                  addToRefs={addToRefs}
                  clickHandler={async (item, selected) => {
                    handleSelectedCause(item, selected);
                  }}
                  ref={(ref) => addToRefListEntry(ref)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );

    if (data.length === 0) {
      content = (
        <div className="w-full flex items-center">
          <div className="flex-grow text-center">{noDataText}</div>
        </div>
      );
    }

    return content;
  }
}

export default ListOfCausesView;
