import React, { useState } from 'react';
import { encriptData } from '../../../../../../api/securityManager';
import { EMPTY_STRING, NO_MODAL_OPEN } from '../../../../models/constants';
import { downloadFilesGenericBrowsers } from '../helpers/downloadFiles';

import { PasswordSWIFTModalView } from './PasswordSWIFTModalView';

export interface PasswordSWIFTModalViewModelProps {
  onModalChange: (modalOpen: string) => void;
}

const PASS_SWIFT_FILE_NAME: string = 'TzsSWTPWD.dat';
const PASS_SWIFT_MIN_LENGTH: number = 1;
const PASS_SWIFT_MAX_LENGTH: number = 23;

export function PasswordSWIFTModalViewModel({ onModalChange }: PasswordSWIFTModalViewModelProps): JSX.Element | null {
  const [sendPassword, setSendPassword] = useState<string>(EMPTY_STRING);
  const [receivePassword, setReceivePassword] = useState<string>(EMPTY_STRING);
  const [confirmSendPassword, setConfirmSendPasswordInput] = useState<string>(EMPTY_STRING);
  const [confirmReceivePassword, setConfirmReceivePassword] = useState<string>(EMPTY_STRING);
  const [lengthReceivePasswordError, setLengthReceivePasswordError] = useState<boolean>(false);
  const [lengthSendPasswordError, setLengthSendPasswordError] = useState<boolean>(false);
  const [confirmSendError, setConfirmSendError] = useState<boolean>(false);
  const [confirmReceiveError, setConfirmReceiveError] = useState<boolean>(false);

  function onAccept(event: React.MouseEvent): void {
    event.stopPropagation();
    const errors: boolean = confirmReceiveError || confirmSendError || lengthReceivePasswordError || lengthSendPasswordError;
    if (!errors) {
      encriptInformation();
      onModalChange(NO_MODAL_OPEN);
    }
  }

  function onCancel(event: React.MouseEvent): void {
    event.stopPropagation();
    onModalChange(NO_MODAL_OPEN);
  }

  async function encriptInformation(): Promise<void> {
    let sendPassEncripted = await encriptData(sendPassword, PASS_SWIFT_MAX_LENGTH);
    let receivePassEncripted = await encriptData(receivePassword, PASS_SWIFT_MAX_LENGTH);
    sendPassEncripted = atob(sendPassEncripted);
    receivePassEncripted = atob(receivePassEncripted);
    const fileParts = [`${sendPassEncripted} \n ${receivePassEncripted}`];
    const blobFile = new Blob(fileParts, { type: 'text/html' });
    downloadFilesGenericBrowsers(blobFile, PASS_SWIFT_FILE_NAME);
  }

  function onConfirmSendPasswordChange(event: { target: HTMLInputElement }): void {
    setConfirmSendPasswordInput(event.target.value);
    setConfirmSendError(event.target.value !== sendPassword);
  }

  function onConfirmReceivePasswordChange(event: { target: HTMLInputElement }): void {
    setConfirmReceivePassword(event.target.value);
    setConfirmReceiveError(event.target.value !== receivePassword);
  }

  function onSendPasswordChange(event: { target: HTMLInputElement }): void {
    setSendPassword(event.target.value);
    setLengthSendPasswordError(event.target.value.length > PASS_SWIFT_MAX_LENGTH || event.target.value.length < PASS_SWIFT_MIN_LENGTH);
    setConfirmSendError(event.target.value !== confirmSendPassword);
  }

  function onReceivePasswordChange(event: { target: HTMLInputElement }): void {
    setReceivePassword(event.target.value);
    setLengthReceivePasswordError(event.target.value.length > PASS_SWIFT_MAX_LENGTH || event.target.value.length < PASS_SWIFT_MIN_LENGTH);
    setConfirmReceiveError(event.target.value !== confirmReceivePassword);
  }

  return (
    <PasswordSWIFTModalView
      onAccept={onAccept}
      onCancel={onCancel}
      onReceivePasswordChange={onReceivePasswordChange}
      onSendPasswordChange={onSendPasswordChange}
      onConfirmSendPasswordChange={onConfirmSendPasswordChange}
      onConfirmReceivePasswordChange={onConfirmReceivePasswordChange}
      lengthReceivePasswordError={lengthReceivePasswordError}
      lengthSendPasswordError={lengthSendPasswordError}
      confirmReceiveError={confirmReceiveError}
      confirmSendError={confirmSendError}
    />
  );
}
