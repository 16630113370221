export const textSize = (fontSize) => {
  switch (fontSize) {
    case 12: {
      return 'text-xs'
    }
    case 13: {
      return 'text-sm'
    }
    case 14: {
      return 'text-sm'
    }
    case 16: {
      return 'text-base'
    }
    case 18: {
      return 'text-lg'
    }
    case 20: {
      return 'text-lg'
    }
    case 24: {
      return 'text-xl'
    }
    case 30: {
      return 'text-2xl'
    }
    case 36: {
      return 'text-3xl'
    }
    case 48: {
      return 'text-4xl'
    }
    default: return 'text-base'
  }
}

export const mapFont = (type) => {
  switch (type) {
    case 1:
      return 'font-semibold';
    case 2:
      return 'italic'
    default:
      return 'font-light'
  }
}

export const mapColor = (rgbcolor) => {
  switch (rgbcolor) {
    case '255, 0, 0': {
      return 'red'
    }
    case '0, 0, 0': {
      return 'text-base'
    }
    case '128, 0, 0': {
      return 'dark-red'
    }
    case '0, 128, 0': {
      return 'dark-green'
    }
    case '128, 128, 0': {
      return 'olive'
    }
    case '0, 0, 128': {
      return 'navy-blue'
    }
    case '128, 0, 128': {
      return 'purple'
    }
    case '0, 128, 128': {
      return 'turquoise'
    }
    case '128, 128, 128': {
      return 'gray'
    }
    case '192, 192, 192': {
      return 'grey-light'
    }
    case '0, 255, 0': {
      return 'light-green'
    }
    case '255, 255, 0': {
      return 'yellow'
    }
    case '0, 0, 255': {
      return 'blue'
    }
    case '255, 0, 255': {
      return 'fuchsia'
    }
    case '0, 255, 255': {
      return 'aquamarine'
    }
    case '255, 255, 255': {
      return 'white'
    }
  }
}
