import _ from 'busyman';
import { dispatchActions } from '../../src/store';
import TreePool from './TreePool';
import {
  getParentDialog,
  getParentDialogCount,
  getTLFromStr,
  generateLineIDString,
  generateDialogKey,
  getTransactionFromLineIDString,
  getLineFromLineIDString,
  getNumberFromDialogKey,
  getVariableRowFromLineIDString,
  getIterationFromDialogKey,
  getDialogKeyWithNoS,
  compareTLId,
} from '../../UI-kit/helpers/operationHelper';

const { filter } = require('busyman');

const TYPE_INPUT = 0;
const INPUT_PARAMETER_DATE_TYPE = 4;

// Components
const isDialog = (className) => className.includes('DialogToDisplayEvent');
const isMessage = (className) => className.includes('MessageToDisplayEvent');
const isButton = (className) => className.includes('ButtonToDisplayEvent');
const isField = (className) => className.includes('FieldToDisplayEvent') || className.includes('FieldToInputEvent');
const isNotice = (className) => className.includes('NoticeToDisplayEvent');
const isRemoteToLaunch = (className) => className.includes('RemoteToLaunchEvent');
const isApplicationToLaunch = (className) => className.includes('ApplicationToLaunchEvent');
const isPersonIdentification = (className) => className.includes('PersonIdentificationEvent');
const isOperationChange = (className) => className.includes('OperationChangeEvent');

const listToTree = async (newState, events, tabIndex, isInGoToLine = false, addOrRemoveFocusFromFocusOn) => {
  let tabId = newState.tabs[newState.current].id;
  let operationData = newState.tabs[tabIndex].running;
  let stateByLineID = { ...operationData.stateByLineID };
  let { focusElement, lastFocusElement } = operationData;
  let valueSetElement = [];
  let transactionNumber = newState.tabs[tabIndex].transactionNumber;
  let transactionDescription = newState.tabs[tabIndex].transactionDescription;

  let maxWindowWidth = 0;
  let maxWindowHeight = 0;
  let newElements = [];
  let variableRowsToRemove = [];
  let dialogsBeingDeleted = [];
  if (!stateByLineID.openedDialogList) {
    stateByLineID.openedDialogList = [];
  }
  if (!stateByLineID.repeatedElementIDs) {
    stateByLineID.repeatedElementIDs = [];
  }
  if (!stateByLineID.repeatedDialogIDs) {
    stateByLineID.repeatedDialogIDs = [];
  }
  if (!stateByLineID.stateByDialogKey) {
    stateByLineID.stateByDialogKey = {};
  }

  await events.forEach((item, indexItem) => {
    let { lineID } = item;
    let parentDialogTL;
    let parentDialogCount;
    let lineIDKey = null;

    if (lineID) {
      [parentDialogTL, parentDialogCount, lineIDKey] = evaluateLineIdKey(stateByLineID, item, lineID, tabId);
      newElements = getNewElements(stateByLineID, item, lineID, events, indexItem, newElements, lineIDKey, parentDialogTL);
    }

    [
      variableRowsToRemove,
      valueSetElement,
      dialogsBeingDeleted,
      stateByLineID,
      newElements,
      focusElement,
      lastFocusElement,
      transactionNumber,
      transactionDescription,
      newState,
    ] = performStateChanges(
      newState,
      tabIndex,
      stateByLineID,
      item,
      lineID,
      events,
      indexItem,
      newElements,
      maxWindowWidth,
      maxWindowHeight,
      parentDialogTL,
      parentDialogCount,
      isInGoToLine,
      focusElement,
      lastFocusElement,
      variableRowsToRemove,
      valueSetElement,
      dialogsBeingDeleted,
      lineIDKey,
      transactionNumber,
      transactionDescription,
    );
  });
  if (newState.tabs[tabIndex]) {
    newState.tabs[tabIndex].transactionNumber = transactionNumber;
    newState.tabs[tabIndex].transactionDescription = transactionDescription;
  }

  let treePool = generateTreePool(
    stateByLineID,
    operationData,
    events,
    newElements,
    variableRowsToRemove,
    dialogsBeingDeleted,
    tabId,
    addOrRemoveFocusFromFocusOn,
  );

  const runOperation = events.some((item) => item['class-name'].includes('OperationToRun'));
  if (runOperation) newState.startingOperation = true;

  const trees = treePool.trees;
  const toReturn = {
    trees,
    stateByLineID,
    focusElement,
    lastFocusElement,
    valueSetElement,
    newState,
  };

  return toReturn;
};

const evaluateLineIdKey = (stateByLineID, item, lineID, tabId) => {
  let parentDialogTL, parentDialogCount, lineIDKey;
  lineID.tabId = tabId;
  item.lineID.tabId = tabId;
  const isDialog = item['class-name'] && item['class-name'].includes('DialogToDisplayEvent');
  const dialogNumber = item.displayInfo && (isDialog && item.displayInfo.number ? item.displayInfo.number : item.displayInfo.dialogNumber);
  if (
    item.displayInfo &&
    item.displayInfo.isDialogBox &&
    (!stateByLineID.repeatedDialogIDs || (stateByLineID.repeatedDialogIDs && !stateByLineID.repeatedDialogIDs.includes(generateLineIDString(lineID))))
  ) {
    parentDialogTL = generateLineIDString(lineID);
    parentDialogCount = '';
  } else {
    parentDialogTL = getParentDialog(stateByLineID, generateLineIDString(lineID), true, false, true, dialogNumber, undefined, true);
    parentDialogCount = getParentDialogCount(stateByLineID, lineID, dialogNumber, generateLineIDString(lineID), true);
  }
  lineIDKey = generateLineIDString(lineID);

  // Las validaciones para countVariableRow deben ser hechas con lineID S/countIterativeDialog = 0
  setCountVariableRow(stateByLineID, parentDialogTL, lineID, lineIDKey);

  lineID.countIterativeDialog = parentDialogCount;
  lineIDKey = generateLineIDString(lineID);

  if (stateByLineID[lineIDKey] && parentDialogCount > 0 && isElementInsideRepeatingDialog(stateByLineID, lineID)) {
    if (!stateByLineID.repeatedElementIDs) {
      stateByLineID.repeatedElementIDs = [];
    }
    if (!stateByLineID.repeatedElementIDs.includes(lineIDKey)) {
      stateByLineID.repeatedElementIDs.push(lineIDKey);
    }
    lineID.countIterativeDialog = parentDialogCount;
    lineID.notVariableRow = true;
    lineIDKey = generateLineIDString(lineID);
    if (!stateByLineID.repeatedElementIDs.includes(lineIDKey)) {
      stateByLineID.repeatedElementIDs.push(lineIDKey);
    }
  }

  return [parentDialogTL, parentDialogCount, lineIDKey];
};

const setCountVariableRow = (stateByLineID, parentDialogTL, lineID, lineIDKey) => {
  if (stateByLineID[parentDialogTL] && stateByLineID[parentDialogTL].countVariableRow === undefined) {
    stateByLineID[parentDialogTL].countVariableRow = 0;
  }
  if (stateByLineID[parentDialogTL] && stateByLineID[parentDialogTL].variableRowFields === undefined) {
    stateByLineID[parentDialogTL].variableRowFields = [];
  }
  if (
    stateByLineID[parentDialogTL] &&
    stateByLineID[parentDialogTL].countVariableRow > 0 &&
    stateByLineID[parentDialogTL].variableRowFields.includes(lineIDKey)
  ) {
    lineID.countVariableRow = stateByLineID[parentDialogTL].countVariableRow;
  } else if (
    stateByLineID[parentDialogTL] &&
    stateByLineID[parentDialogTL].countVariableRow > 1 &&
    stateByLineID[parentDialogTL].variableRowFields.includes(lineIDKey)
  ) {
    lineID.countVariableRow = stateByLineID[parentDialogTL].countVariableRow - 1;
  }
};

const getNewElements = (stateByLineID, item, lineID, events, indexItem, newElements, lineIDKey, parentDialogTL) => {
  const itemClass = item['class-name'] || '';
  let mustBeRendered = true;
  let variableRowsParentDialog;
  let similarItemExists = events
    .slice(indexItem + 1)
    .find(
      (element) =>
        (isMessage(element['class-name'] || '') ||
          isButton(element['class-name'] || '') ||
          isField(element['class-name'] || '') ||
          element['class-name'].includes('ItemToDeleteEvent')) &&
        element.lineID.transactionNumber === item.lineID.transactionNumber &&
        element.lineID.lineNumber === item.lineID.lineNumber,
    );
  let lineKey = generateLineIDString(lineID);
  if (stateByLineID[parentDialogTL] && stateByLineID[parentDialogTL].countVariableRow === undefined) {
    stateByLineID[parentDialogTL].countVariableRow = 0;
  }
  if (stateByLineID[parentDialogTL] && stateByLineID[parentDialogTL].variableRowFields === undefined) {
    stateByLineID[parentDialogTL].variableRowFields = [];
  }
  if (
    stateByLineID[parentDialogTL] &&
    !similarItemExists &&
    stateByLineID[parentDialogTL].countVariableRow > 1 &&
    stateByLineID[parentDialogTL].variableRowFields.includes(lineKey)
  ) {
    similarItemExists = events
      .slice(1)
      .find(
        (element) =>
          (isMessage(element['class-name'] || '') ||
            isButton(element['class-name'] || '') ||
            isField(element['class-name'] || '') ||
            element['class-name'].includes('ItemToDeleteEvent')) &&
          element.lineID.transactionNumber === item.lineID.transactionNumber &&
          element.lineID.lineNumber === item.lineID.lineNumber,
      );
  }
  mustBeRendered = !(similarItemExists && similarItemExists['class-name'].includes('ItemToDeleteEvent'));

  // This if adds the loop count that a generic sequence is on
  if (
    ((similarItemExists &&
      similarItemExists.lineID &&
      similarItemExists.displayInfo &&
      similarItemExists.displayInfo.variableRow &&
      similarItemExists.displayInfo.variableRow === true) ||
      ((item['class-name'].includes('FieldToDisplayEvent') || item['class-name'].includes('FieldToInputEvent')) &&
        item.displayInfo &&
        item.displayInfo.variableRow &&
        item.displayInfo.variableRow === true)) &&
    !item['class-name'].includes('ItemToDeleteEvent')
  ) {
    lineIDKey = generateLineIDString(lineID);
    if (stateByLineID[parentDialogTL]) {
      let variableRowFieldsFromState = stateByLineID[parentDialogTL].variableRowFields;
      lineID.countVariableRow = stateByLineID[parentDialogTL].countVariableRow;

      if (stateByLineID[parentDialogTL].variableRowFields.includes(lineIDKey)) {
        lineID.countVariableRow = stateByLineID[parentDialogTL].countVariableRow + 1;
        lineIDKey = generateLineIDString(lineID);
        stateByLineID[parentDialogTL].countVariableRow = lineID.countVariableRow;
        if (!variableRowFieldsFromState.includes(lineIDKey)) {
          variableRowFieldsFromState.push(lineIDKey);
        }
        lineIDKey = generateLineIDString(lineID);
      }
      if (!variableRowFieldsFromState.includes(lineIDKey)) {
        variableRowFieldsFromState.push(lineIDKey);

        const parentDialog = getParentDialogEvent(events, lineID);
        if (parentDialog.length) {
          variableRowsParentDialog = parentDialog[0].displayInfo.number;
          stateByLineID.variableRowsParentDialog = variableRowsParentDialog;
        }
        stateByLineID[parentDialogTL].variableRowFields = variableRowFieldsFromState;
      }
    }
  } else {
    if (similarItemExists && isMessage(item['class-name'])) {
      item = similarItemExists;
    }
  }

  if (
    (mustBeRendered &&
      (item['class-name'].includes('FieldToDisplayEvent') || item['class-name'].includes('FieldToInputEvent')) &&
      !newElements.includes(lineIDKey)) ||
    (mustBeRendered &&
      (item['class-name'].includes('FieldToDisplayEvent') || item['class-name'].includes('FieldToInputEvent')) &&
      item.displayInfo.inputParameters &&
      item.displayInfo.inputParameters.type === 4)
  ) {
    if (stateByLineID[lineIDKey] && item && item.displayInfo && !item.displayInfo.variableRow && hasVariableRowSiblings(events, lineID)) {
      let currentData = { ...stateByLineID[lineIDKey] };
      currentData.displayInfoHistory = null;
      if (!stateByLineID[lineIDKey].displayInfoHistory) {
        stateByLineID[lineIDKey].displayInfoHistory = [currentData];
      } else {
        stateByLineID[lineIDKey].displayInfoHistory.push(currentData);
      }
    } else {
      changeComponentStatus(stateByLineID, lineID, lineIDKey, { raw: item });
    }
  }
  if (
    (similarItemExists &&
      !itemClass.includes('ItemToDeleteEvent') &&
      (!newElements.includes(lineIDKey) || (similarItemExists.variableRow && similarItemExists.variableRow === true))) ||
    (item &&
      (itemClass.includes('FieldToDisplayEvent') || itemClass.includes('FieldToInputEvent')) &&
      (!newElements.includes(lineIDKey) || (item.variableRow && item.variableRow === true)))
  ) {
    newElements.push(lineIDKey);
  }

  if (item.displayInfo && item.displayInfo.dialogNumber && mustBeRendered) {
    changeComponentStatus(stateByLineID, lineID, lineIDKey, {
      treeNumber: generateDialogKey(item.displayInfo.dialogNumber, item.lineID),
    });
    changeComponentStatus(stateByLineID, lineID, lineIDKey, {
      deleted: false,
      hide: false,
    });
  }

  return newElements;
};

const hasVariableRowSiblings = (events, lineID) => {
  let retVal = false;
  events.forEach((item) => {
    if (
      item &&
      item['class-name'] &&
      item['class-name'].includes('FieldToDisplayEvent') &&
      item.displayInfo &&
      item.displayInfo.variableRow &&
      item.lineID.transactionNumber === lineID.transactionNumber
    ) {
      retVal = true;
    }
  });
  return retVal;
};

export const getParentDialogEvent = (events, lineID) => {
  let closestLine = 0;
  let previousTransaction = false;
  if (events) {
    events.forEach((evt) => {
      if (evt['class-name'].includes('DialogToDisplayEvent')) {
        if (
          evt.lineID.transactionNumber === lineID.transactionNumber &&
          evt.lineID.lineNumber <= lineID.lineNumber &&
          evt.lineID.lineNumber > closestLine
        ) {
          closestLine = evt.lineID.lineNumber;
          previousTransaction = false;
        } else if (!closestLine && evt.lineID.transactionNumber < lineID.transactionNumber && evt.lineID.lineNumber > closestLine) {
          closestLine = evt.lineID.lineNumber;
          previousTransaction = evt.lineID.transactionNumber;
        }
      }
    });
  }

  if (closestLine > 0) {
    const parentDialogEvent = events.filter(
      (element) =>
        element &&
        element.lineID &&
        element.lineID.transactionNumber === (previousTransaction || lineID.transactionNumber) &&
        element.lineID.lineNumber === closestLine,
    );
    return parentDialogEvent;
  } else {
    return false;
  }
};

const performStateChanges = (
  newState,
  tabIndex,
  stateByLineID,
  item,
  lineID,
  events,
  indexItem,
  newElements,
  maxWindowWidth,
  maxWindowHeight,
  parentDialogTL,
  parentDialogCount,
  isInGoToLine,
  focusElement,
  lastFocusElement,
  variableRowsToRemove,
  valueSetElement,
  dialogsBeingDeleted,
  lineIDKey,
  transactionNumber,
  transactionDescription,
) => {
  const itemClass = item['class-name'] || '';

  if (item.displayInfo && item.displayInfo.prompt) {
    changeComponentStatus(stateByLineID, lineID, lineIDKey, {
      prompt: item.displayInfo.prompt,
    });
  }

  if (item.displayInfo && (item.displayInfo.variableRow === false || item.displayInfo.variableRow === true)) {
    changeComponentStatus(stateByLineID, lineID, lineIDKey, {
      variableRow: item.displayInfo.variableRow,
    });
  }

  if (item.displayInfo && (item.displayInfo.optional === false || item.displayInfo.optional === true)) {
    changeComponentStatus(stateByLineID, lineID, lineIDKey, {
      optional: item.displayInfo.optional,
    });
  }

  let elementAlreadyExists = false;
  if (isMessage(itemClass || '') || isButton(itemClass || '') || isField(itemClass || '')) {
    const hasCountAttribute = item.lineID.countIterativeDialog !== undefined;
    const elementFound = newElements.find(
      (element) =>
        element['class-name'] === itemClass &&
        element.lineID.transactionNumber === item.lineID.transactionNumber &&
        element.lineID.lineNumber === item.lineID.lineNumber &&
        element.lineID.countIterativeDialog === item.lineID.countIterativeDialog,
    );
    if (elementFound && !hasCountAttribute) {
      elementAlreadyExists = true;
    } else {
      newElements.push(item);
    }
  }

  if (itemClass.includes('DialogToDisplayEvent')) {
    let newWidth = item.displayInfo.displayInfo.position.width;
    let newHeight = item.displayInfo.displayInfo.position.height;
    maxWindowWidth = maxWindowWidth > newWidth ? maxWindowWidth : newWidth;
    maxWindowHeight = maxWindowHeight > newHeight ? maxWindowHeight : newHeight;
    lineIDKey = generateLineIDString(lineID);
    const lineIDKeyS0 = generateLineIDString({ ...lineID, countIterativeDialog: 0 });
    let dialogKey = generateDialogKey(item.number, { ...lineID, countIterativeDialog: 0 });
    if (!stateByLineID.openedDialogList.includes(lineIDKey)) {
      stateByLineID.openedDialogList.push(lineIDKey);
    }
    if (stateByLineID.stateByDialogKey[dialogKey] && ((stateByLineID[lineIDKey] && !stateByLineID[lineIDKey].deleted) || !stateByLineID[lineIDKey])) {
      if (stateByLineID[lineIDKey]) {
        stateByLineID.stateByDialogKey[dialogKey].iterativeDialogCount++;
      }
      lineID.countIterativeDialog = stateByLineID.stateByDialogKey[dialogKey].iterativeDialogCount;
      // dialogKey = generateDialogKey(item.number, lineID);
      // si comento esto todos los dialogKey tienen S = 0, y el primer D44 se borra al borrar el ultimo
      // sino el contador no es tomado correctamente en actions linea 794 porque agarra el count del ultimo
      lineID.notVariableRow = true;
      if (!stateByLineID.repeatedElementIDs.includes(lineIDKey)) {
        stateByLineID.repeatedElementIDs.push(lineIDKey);
      }
      if (!stateByLineID.repeatedDialogIDs.includes(lineIDKey)) {
        stateByLineID.repeatedDialogIDs.push(lineIDKey);
      }
      lineIDKey = generateLineIDString(lineID);
      if (!stateByLineID.repeatedElementIDs.includes(lineIDKey)) {
        stateByLineID.repeatedElementIDs.push(lineIDKey);
        stateByLineID.repeatedDialogIDs.push(lineIDKey);
      }
      if (!stateByLineID.openedDialogList.includes(lineIDKey)) {
        stateByLineID.openedDialogList.push(lineIDKey);
      }
    } else {
      stateByLineID.stateByDialogKey[dialogKey] = { iterativeDialogCount: 0 };
    }

    if (item.number !== undefined) {
      item.number = generateDialogKey(item.number, lineID);
    }
    if (item.displayInfo && item.displayInfo.number !== undefined) {
      item.displayInfo.number = generateDialogKey(item.displayInfo.number, lineID);
    }
    changeComponentStatus(stateByLineID, lineID, lineIDKey, { hide: false, deleted: false, dialogKey: dialogKey });
    if (lineIDKeyS0 !== lineIDKey && !stateByLineID[lineIDKeyS0]) {
      changeComponentStatus(stateByLineID, { ...lineID, countIterativeDialog: 0 }, lineIDKeyS0, {
        hide: false,
        deleted: false,
        dialogKey: dialogKey,
      });
    }
  }

  if (
    itemClass.includes('FieldToDisplayEvent') ||
    itemClass.includes('FieldToInputEvent') ||
    itemClass.includes('MessageToDisplayEvent') ||
    itemClass.includes('ButtonToDisplayEvent')
  ) {
    if (item.displayInfo && !item.displayInfo.initiallyVisible && !elementAlreadyExists) {
      lineIDKey = generateLineIDString(lineID);
      const dialogKey = generateDialogKey(item.displayInfo.dialogNumber, lineID);
      if (stateByLineID[lineIDKey] && (itemClass.includes('FieldToDisplayEvent') || itemClass.includes('FieldToInputEvent'))) {
        if (!stateByLineID.repeatedElementIDs) {
          stateByLineID.repeatedElementIDs = [];
        }
        if (
          stateByLineID.stateByDialogKey[dialogKey] &&
          stateByLineID.stateByDialogKey[dialogKey].iterativeDialogCount > 0 &&
          isElementInsideRepeatingDialog(stateByLineID, lineID)
        ) {
          if (!stateByLineID.repeatedElementIDs.includes(lineIDKey)) {
            stateByLineID.repeatedElementIDs.push(lineIDKey);
          }
          lineID.countIterativeDialog = stateByLineID.stateByDialogKey[dialogKey].iterativeDialogCount;
          lineID.notVariableRow = true;
          lineIDKey = generateLineIDString(lineID);
          if (!stateByLineID.repeatedElementIDs.includes(lineIDKey)) {
            stateByLineID.repeatedElementIDs.push(lineIDKey);
          }
        }
      }
      changeComponentStatus(stateByLineID, lineID, lineIDKey, { hide: true, dialogKey });
    }
  }

  if (stateByLineID[parentDialogTL] && stateByLineID[parentDialogTL].countVariableRow === undefined) {
    stateByLineID[parentDialogTL].countVariableRow = 0;
  }
  if (stateByLineID[parentDialogTL] && stateByLineID[parentDialogTL].variableRowFields === undefined) {
    stateByLineID[parentDialogTL].variableRowFields = [];
  }
  if (itemClass.includes('ValueToSetEvent')) {
    const { transactionNumber, lineNumber } = lineID;
    const element = newElements.find((e) => {
      if (!e.lineID) return false;
      const { transactionNumber: t, lineNumber: l } = e.lineID;
      return t === transactionNumber && l === lineNumber;
    });
    const countVariableRow = element && element.lineID && element.lineID.countVariableRow;

    if (countVariableRow) {
      lineID.countVariableRow = countVariableRow;
    }

    lineIDKey = generateLineIDString(lineID);
    if (stateByLineID[lineIDKey]) {
      if (
        stateByLineID[lineIDKey].type &&
        stateByLineID[lineIDKey].inputType === TYPE_INPUT &&
        typeof item.value === 'string' &&
        item.value !== ' '
      ) {
        item.value = item.value.trim();
        if (
          stateByLineID[lineIDKey].inputParameters &&
          stateByLineID[lineIDKey].inputParameters.mask &&
          stateByLineID[lineIDKey].inputParameters.mask !== '>' &&
          stateByLineID[lineIDKey].inputParameters.mask !== '<'
        ) {
          item.rawValue = item.value.trim();
        }
      }

      if (stateByLineID[lineIDKey].type && stateByLineID[lineIDKey].type === INPUT_PARAMETER_DATE_TYPE) {
        changeComponentStatus(stateByLineID, lineID, lineIDKey, {
          value: item.value,
          raw: { displayInfo: { value: item.value } },
        });
      } else if (stateByLineID[lineIDKey].inputType && stateByLineID[lineIDKey].inputType === 2) {
        const checkedUserOption = stateByLineID[lineIDKey].inputParameters.options[0].userOption;
        const uncheckedUserOption = stateByLineID[lineIDKey].inputParameters.options[1].userOption;
        if (item.value === checkedUserOption || item.value === uncheckedUserOption) {
          changeComponentStatus(stateByLineID, lineID, lineIDKey, { value: item.value });
        }
      } else if (stateByLineID[lineIDKey].inputType && stateByLineID[lineIDKey].inputType === 3) {
        const options = stateByLineID[lineIDKey].inputParameters.options;
        const checkValid = (option) => option.userOption === item.value.toString();
        const isValidOption = options.some(checkValid);
        if (isValidOption && !isInGoToLine) {
          changeComponentStatus(stateByLineID, lineID, lineIDKey, {
            value: item.value.toString(),
          });
        }
      } else if (
        stateByLineID[lineIDKey].inputParameters &&
        stateByLineID[lineIDKey].inputParameters.type === 2 &&
        stateByLineID[lineIDKey].inputParameters.mask &&
        stateByLineID[lineIDKey].inputParameters.mask.length > 1 &&
        item.value === 0
      ) {
        changeComponentStatus(stateByLineID, lineID, lineIDKey, { value: '' });
      } else if (
        (stateByLineID[lineIDKey].type &&
          (stateByLineID[lineIDKey].type !== 5 ||
            (stateByLineID[lineIDKey].type === 5 && item.value !== '' && !isInGoToLine) ||
            (stateByLineID[lineIDKey].type === 5 && stateByLineID[lineIDKey].inputType && stateByLineID[lineIDKey].inputType === 1))) ||
        (stateByLineID[lineIDKey].inputParameters &&
          stateByLineID[lineIDKey].inputParameters.type === 2 &&
          stateByLineID[lineIDKey].inputParameters.mask &&
          stateByLineID[lineIDKey].inputParameters.mask.length <= 2)
      ) {
        changeComponentStatus(stateByLineID, lineID, lineIDKey, { value: item.value });
        if (item.rawValue !== undefined) {
          changeComponentStatus(stateByLineID, lineID, lineIDKey, {
            rawValue: item.rawValue,
          });
        }
      }
      valueSetElement.push(lineIDKey);
    }
  } else if (itemClass.includes('ItemToHideEvent')) {
    changeComponentStatus(stateByLineID, lineID, lineIDKey, { hide: true });
  } else if (itemClass.includes('ItemToUnhideEvent')) {
    changeComponentStatus(stateByLineID, lineID, lineIDKey, { hide: false });
  } else if (itemClass.includes('DialogToHideEvent')) {
    const parentDialogLineID = getParentDialog(stateByLineID, generateLineIDString(lineID), true, true);
    changeComponentStatus(stateByLineID, parentDialogLineID, parentDialogLineID, { hide: true });
  } else if (itemClass.includes('DialogToUnhideEvent')) {
    const parentDialogLineID = getParentDialog(stateByLineID, generateLineIDString(lineID), true, false, undefined, undefined, undefined, true);
    changeComponentStatus(stateByLineID, parentDialogLineID, parentDialogLineID, { hide: false });
  } else if (itemClass.includes('ItemToDeleteEvent')) {
    let tmpLineIdStr = generateLineIDString(lineID);
    let dialogKey = stateByLineID[tmpLineIdStr] && (stateByLineID[tmpLineIdStr].dialogKey || stateByLineID[tmpLineIdStr].treeNumber);
    let baseDialogKey = getDialogKeyWithNoS(dialogKey);

    if (stateByLineID[parentDialogTL] && stateByLineID[parentDialogTL].variableRowFields.includes(tmpLineIdStr)) {
      lineID.countVariableRow = stateByLineID[parentDialogTL].countVariableRow;
      tmpLineIdStr = generateLineIDString(lineID);
      const idx = stateByLineID[parentDialogTL].variableRowFields.indexOf(tmpLineIdStr);

      if (idx > -1) {
        stateByLineID[parentDialogTL].variableRowFields.splice(idx);
        variableRowsToRemove.push(tmpLineIdStr);
        let otherElementFound = false;
        for (let i = 0; i < stateByLineID[parentDialogTL].variableRowFields.length; i++) {
          let elementVariableRow = getVariableRowFromLineIDString(stateByLineID[parentDialogTL].variableRowFields[i]);
          if (elementVariableRow === stateByLineID[parentDialogTL].countVariableRow) {
            otherElementFound = true;
            break;
          }
        }

        if (!otherElementFound && stateByLineID[parentDialogTL].countVariableRow) {
          stateByLineID[parentDialogTL].countVariableRow--;
        }
      }
    } else if (stateByLineID[tmpLineIdStr]?.dialogKey && baseDialogKey && stateByLineID.stateByDialogKey[baseDialogKey]) {
      if (parentDialogCount > 0) {
        lineID.countIterativeDialog = parentDialogCount;
        if (!dialogHasOtherElementsBeforeCurrentTL(stateByLineID, tmpLineIdStr)) {
          parentDialogCount--;
        }
      } else {
        lineID.countIterativeDialog = 0;
      }
      lineID.notVariableRow = true;
      stateByLineID.stateByDialogKey[baseDialogKey].iterativeDialogCount = parentDialogCount;
    }

    if (
      stateByLineID[lineIDKey] &&
      !stateByLineID[lineIDKey].variableRow &&
      stateByLineID[lineIDKey].displayInfoHistory &&
      stateByLineID[lineIDKey].displayInfoHistory.length
    ) {
      let restoredData = { ...stateByLineID[lineIDKey].displayInfoHistory.pop() };
      restoredData.displayInfoHistory = stateByLineID[lineIDKey].displayInfoHistory;
      stateByLineID[lineIDKey] = restoredData;
    } else {
      if (stateByLineID.openedDialogList.includes(tmpLineIdStr)) {
        dialogsBeingDeleted.push({
          strLineID: [tmpLineIdStr],
          dialogKey: dialogKey,
        });
      }

      if (stateByLineID[lineIDKey] && stateByLineID[lineIDKey].displayInfoHistory && !stateByLineID[lineIDKey].displayInfoHistory.length) {
        stateByLineID[lineIDKey].displayInfoHistory = null;
      }

      changeComponentStatus(stateByLineID, lineID, lineIDKey, { deleted: true });
    }
  } else if (itemClass.includes('ItemToDisableEvent')) {
    changeComponentStatus(stateByLineID, lineID, lineIDKey, { disabled: true }, true);
  } else if (itemClass.includes('ItemToEnableEvent')) {
    changeComponentStatus(stateByLineID, lineID, lineIDKey, { disabled: false });
  } else if (itemClass.includes('DialogToDisplayEvent')) {
    lineIDKey = generateLineIDString(lineID);
    newState = dispatchActions.pushToWindowStack(newState, lineIDKey);
    changeComponentStatus(stateByLineID, lineID, lineIDKey, {
      deleted: false,
      isDialog: true,
      dialogKey: item.displayInfo.number,
    });
  } else if (itemClass.includes('FocusToSetEvent')) {
    lineIDKey = generateLineIDString(lineID);
    if (parentDialogCount > 0 && stateByLineID.repeatedElementIDs && stateByLineID.repeatedElementIDs.includes(lineIDKey)) {
      lineID.countIterativeDialog = parentDialogCount;
      lineIDKey = generateLineIDString(lineID);
    }
    lastFocusElement = focusElement;
    focusElement = lineIDKey;
  } else if (itemClass.includes('FieldToDisplayEvent') || itemClass.includes('FieldToInputEvent')) {
    lineIDKey = generateLineIDString(lineID);
    const dialogKey = generateDialogKey(item.displayInfo.dialogNumber, lineID);
    if (stateByLineID[lineIDKey] && isElementInsideRepeatingDialog(stateByLineID, lineID)) {
      if (!stateByLineID.repeatedElementIDs) {
        stateByLineID.repeatedElementIDs = [];
      }
      if (parentDialogCount > 0) {
        if (!stateByLineID.repeatedElementIDs.includes(getTLFromStr(lineIDKey))) {
          stateByLineID.repeatedElementIDs.push(getTLFromStr(lineIDKey));
        }
        lineID.countIterativeDialog = parentDialogCount;
        lineID.notVariableRow = true;
        lineIDKey = generateLineIDString(lineID);
        if (!stateByLineID.repeatedElementIDs.includes(lineIDKey)) {
          stateByLineID.repeatedElementIDs.push(lineIDKey);
        }
      }
    }
    changeComponentStatus(stateByLineID, lineID, lineIDKey, {
      dialogKey,
      windowWidth: maxWindowWidth,
      windowHeight: maxWindowHeight,
      position: item.displayInfo.fieldDisplayInfo.position,
      inputType: item.displayInfo.inputType !== undefined ? item.displayInfo.inputType : null,
      type: item.displayInfo.inputParameters && item.displayInfo.inputParameters.type !== undefined ? item.displayInfo.inputParameters.type : null,
      mandatoryHelp: !!item.displayInfo.mandatoryHelp,
      multiLine: !!item.displayInfo.multiLine,
      inputParameters: item.displayInfo.inputParameters,
      value:
        item.displayInfo.inputType && item.displayInfo.inputType === 2 && item.displayInfo.value === undefined
          ? item.displayInfo.inputParameters.options[1].userOption
          : item.displayInfo.inputType === 3 && item.displayInfo.value === undefined
          ? item.displayInfo.inputParameters.options[0].userOption
          : item.displayInfo.value !== undefined
          ? item.displayInfo.value
          : '',
    });
  } else if (itemClass.includes('TransactionCreatedEvent') || itemClass.includes('TransactionActivatedEvent')) {
    transactionNumber = item.number;
    transactionDescription = item.displayInfo.description;
  }
  return [
    variableRowsToRemove,
    valueSetElement,
    dialogsBeingDeleted,
    stateByLineID,
    newElements,
    focusElement,
    lastFocusElement,
    transactionNumber,
    transactionDescription,
    newState,
  ];
};

const changeComponentStatus = (stateByLineID, lineID, lineIDKey, status, verifyIfPresent) => {
  lineIDKey = lineID;
  if (typeof lineID !== 'string') {
    lineIDKey = generateLineIDString(lineID);
  }
  if (verifyIfPresent) {
    if (stateByLineID[lineIDKey]) {
      stateByLineID[lineIDKey] = _.merge({}, stateByLineID[lineIDKey], status);
    }
  } else {
    stateByLineID[lineIDKey] = _.merge({}, stateByLineID[lineIDKey], status);
  }
};

const isElementInsideRepeatingDialog = (stateByLineID, lineID) => {
  let foundParentDialog = false;
  if (stateByLineID.repeatedDialogIDs) {
    stateByLineID.repeatedDialogIDs.forEach((e) => {
      const dialogTransactionNumber = getTransactionFromLineIDString(e);
      const dialogLineNumber = getLineFromLineIDString(e);
      if (dialogTransactionNumber === lineID.transactionNumber && dialogLineNumber < lineID.lineNumber) {
        foundParentDialog = true;
        stateByLineID.openedDialogList.forEach((element) => {
          const dialogTransactionNumber2 = getTransactionFromLineIDString(element);
          const dialogLineNumber2 = getLineFromLineIDString(element);
          if (
            dialogTransactionNumber2 === lineID.transactionNumber &&
            dialogLineNumber2 < lineID.lineNumber &&
            dialogLineNumber2 > dialogLineNumber
          ) {
            foundParentDialog = false;
          }
        });
      }
    });
  }
  return foundParentDialog;
};

const generateTreePool = (
  stateByLineID,
  operationData,
  events,
  newElements,
  variableRowsToRemove,
  dialogsBeingDeleted,
  tabId,
  addOrRemoveFocusFromFocusOn,
) => {
  const finishOperation = events.some((item) => item['class-name'].includes('OperationToFinish'));
  const runOperation = events.some((item) => item['class-name'].includes('OperationToRun'));
  const appToLaunchList = events.filter((item) => item['class-name'].includes('ApplicationToLaunch'));
  const dialogs = filter(events, (element) => {
    const _isDialog = isDialog(element['class-name'] || '');
    return _isDialog;
  });

  let mainElements = events.filter((element) => {
    let appToLaunchIsReport = false;
    appToLaunchList.forEach((e) => {
      if (e.name === 'report' || e.name === 'report_to_print') {
        appToLaunchIsReport = true;
      }
    });
    if (!appToLaunchIsReport && finishOperation && runOperation) {
      return false;
    }
    return (
      isOperationChange(element['class-name'] || '') ||
      isRemoteToLaunch(element['class-name'] || '') ||
      (isApplicationToLaunch(element['class-name'] || '') &&
        (element['name'] !== 'command' || element['data'].split(' ')[0] === 'checks' || element['data'].split(' ')[0] === 'jbpm')) ||
      isPersonIdentification(element['class-name'] || '') ||
      isNotice(element['class-name'] || '')
    );
  });

  let rootElements = [...dialogs, ...mainElements].filter((el) => el != null);

  const treePool = new TreePool(rootElements, operationData.trees);
  appendCollectionToTrees(newElements, treePool, stateByLineID, tabId);
  if (stateByLineID.variableRowsParentDialog) {
    removeCollectionFromTrees(variableRowsToRemove, treePool, stateByLineID.variableRowsParentDialog, tabId);
  }
  if (dialogsBeingDeleted.length > 0) {
    dialogsBeingDeleted.forEach((dialogInfo) => {
      treePool.removeTreeByNumber(dialogInfo.dialogKey);
      if (dialogInfo.strLineID && dialogInfo.strLineID.length > 0) {
        operationData.focusOn = addOrRemoveFocusFromFocusOn(operationData.focusOn, dialogInfo.strLineID[0], false);
      }
      delete stateByLineID.stateByDialogKey[dialogInfo.dialogKey];
    });
  }

  return treePool;
};

const appendCollectionToTrees = (collection, treePool, stateByLineID, tabId) => {
  collection.forEach((element) => {
    if (element.displayInfo) {
      treePool.appendChildToTree(element.displayInfo.dialogNumber, element, element.lineID.countIterativeDialog, tabId);
    }
  });
};

const removeCollectionFromTrees = (collection, treePool, dialogNumber, tabId) => {
  collection.forEach((element) => {
    treePool.removeChildFromTree(getNumberFromDialogKey(dialogNumber), element, getIterationFromDialogKey(dialogNumber), tabId);
  });
};

const dialogHasOtherElementsBeforeCurrentTL = (stateByLineID, currentTL) => {
  let found = false;
  const dialogKey = stateByLineID[currentTL].dialogKey;
  for (let idx = 0; idx < Object.keys(stateByLineID).length; idx++) {
    const tmpTL = Object.keys(stateByLineID)[idx];
    const element = stateByLineID[tmpTL];
    const tmpDialogKey = element.dialogKey;
    if (tmpDialogKey === dialogKey && !element.isDialog && compareTLId(currentTL, tmpTL) < 0) {
      found = true;
      break;
    }
  }

  return found;
};

export default listToTree;
