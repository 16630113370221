/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import moment from 'moment';
import { ABOVE_ALL } from '../../../../UI-kit/constants/zIndexValues';
import FocusLock from 'react-focus-lock';
import { useIntl } from 'react-intl';
import {
  getHelpButton,
  getColumnsSucursal,
  getColumnsMoneda,
  getColumnsNumeroMaquina,
  getColumnsGrupoMapa,
  getHelpWindow,
} from './ProductionHelpComponent/productionHelp';
import { useTheme } from '../../../themes/ThemeContext';

import { css, StyleSheet } from 'aphrodite';
import { ProductionLoginWindow } from './ProductionLoginWindow';
import { ProductionDateProcessWindow } from './ProductionDateProcessWindow';
import { ProductionPasswordWindow } from './ProductionPasswordWindow';
import { updateParametros } from '../../../api/productionManager';
import ModalMessage from '../../ModalMessage';

export const ParameterWindow = (props) => {
  const themeContext = useTheme();

  const { color, commonButton, modal } = themeContext.theme;

  const styles = StyleSheet.create({
    show: {
      display: 'block',
      zIndex: 900,
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    modalMain: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxHeight: '80%',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      // minWidth: '320px',
      // maxWidth: '98vw',
      // maxHeight: '98vh',
      backgroundColor: modal.active.background,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: 'transparent',
      opacity: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.33) 0px 2px 8px',
      // alignItems: 'center',
      transition: 'border background .2s',
      padding: '.2rem',
    },
    titleContainer: {
      width: '100%',
      minHeight: '1.5rem',
      padding: '.5rem 1rem',
      color: color.grey.dark4,
      cursor: 'move',
      textAlign: 'left',
      flex: '1 0 auto',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    messageContainer: {
      padding: '1.5rem 1rem',
      borderBottom: `1px solid ${color.grey.light2}`,
    },
    buttonsContainer: {
      padding: '.5rem .5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    button: {
      padding: '.5rem 1.2rem',
      marginRight: '.5rem',
      borderRadius: 4,
      display: 'block',
      backgroundColor: commonButton.default.background,
      color: commonButton.default.color,
      ':hover': {
        backgroundColor: commonButton.hover.background,
        color: commonButton.hover.color,
      },
      ':focus': {
        backgroundColor: commonButton.focus.background,
        color: commonButton.focus.color,
      },
      ':active': {
        backgroundColor: commonButton.active.background,
        color: commonButton.active.color,
      },
      transition: 'background .2s',
    },
  });

  const intl = useIntl();

  const { item, sucursales, monedas, mapaDeRed, mapaTopaz, setModifiedItem, handleKeyOnNumber } = props;

  const [modify, setModify] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [title, setTitle] = useState(false);
  const [showLoginWindow, setShowLoginWindow] = useState(false);
  const [showDateProcessWindow, setShowDateProcessWindow] = useState(false);
  const [showPasswordWindow, setShowPasswordWindow] = useState(false);
  const [helpRows, setHelpRows] = useState([]);
  const [helpColumns, setHelpColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [textDialog, setTextDialog] = useState('');
  const [paramFechaPanel, setParamFechaPanel] = useState({
    fechaproceso: item.fechaproceso,
    fechaprocesoanterior: item.fechaprocesoanterior,
    fechaproximoproceso: item.fechaproximoproceso,
    tipoplazo: item.tipoplazo,
  });
  const [paramLoginPanel, setParamLoginPanel] = useState({
    diasvalidos: item.diasvalidos,
    clvlargomax: item.clvlargomax,
    reintentos: item.reintentos,
    tiempocorte: item.tiempocorte,
    diasaviso: item.diasaviso,
    clvlargomin: item.clvlargomin,
    clvcasesensitive: item.clvcasesensitive,
    autologin: item.autologin,
    tipored: item.tipored,
  });
  const [paramPaswwordPanel, setParamPaswwordPanel] = useState({
    pwdlargomin: item.pwdlargomin,
    pwdlargomax: item.pwdlargomax,
    pwdcantmincar: item.pwdcantmincar,
    pwdcantminnum: item.pwdcantminnum,
    pwdcantmaxrep: item.pwdcantmaxrep,
    pwdcarmaxrep: item.pwdcarmaxrep,
    pwdposmaxrep: item.pwdposmaxrep,
    pwdcantcarmaxrep: item.pwdcantcarmaxrep,
    pwdcasesensitive: item.pwdcasesensitive,
    pwdreglasmin: item.pwdreglasmin,
    pwdcarmaxsec: item.pwdcarmaxsec,
  });

  useEffect(() => {
    if (item) {
      setModify(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelMessage = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancelar',
  });

  const acceptMessage = intl.formatMessage({
    id: 'accept',
    defaultMessage: 'Aceptar',
  });

  const modifyMessage = intl.formatMessage({
    id: 'modify',
    defaultMessage: 'Modificar',
  });

  let outside = null;
  let columnsSucursal = getColumnsSucursal();
  let parsedSucursales = [];

  sucursales.forEach((sucursal) => {
    let toAdd = {
      sucursal: sucursal[0],
      descripcion: sucursal[1],
    };
    parsedSucursales.push(toAdd);
  });

  let helpButtonSucursal = getHelpButton(
    'sucursal',
    {
      rows: parsedSucursales,
      columns: columnsSucursal,
      title: intl.formatMessage({
        id: 'branch',
        defaultMessage: 'Sucursal',
      }),
    },
    setHelpColumns,
    setHelpRows,
    setShowHelp,
    setTitle,
    false,
  );

  const handleBlurSucursal = (e) => {
    if (((typeof e === 'object' && e && e.target) || typeof e !== 'object') && parsedSucursales) {
      const value = typeof e === 'object' ? e.target.value : e;
      const sucFound = parsedSucursales.find((suc) => suc.sucursal + '' === value);
      if (!sucFound) {
        if (e === 'object') e.preventDefault();
        let message = intl.formatMessage({
          id: 'noexistesucursal',
          defaultMessage: 'No existe sucursal',
        });
        setModal(true, message);
        return false;
      }
    }
    return true;
  };

  let columnsMoneda = getColumnsMoneda();
  let parsedMonedas = [];
  monedas.forEach((moneda) => {
    let toAdd = {
      moneda: moneda[0].value,
      descripcion: moneda[1].value,
    };
    parsedMonedas.push(toAdd);
  });

  let helpButtonMoneda = getHelpButton(
    'moneda',
    {
      rows: parsedMonedas,
      columns: columnsMoneda,
      title: intl.formatMessage({
        id: 'coin',
        defaultMessage: 'Moneda',
      }),
    },
    setHelpColumns,
    setHelpRows,
    setShowHelp,
    setTitle,
    false,
  );

  const handleBlurMoneda = (e) => {
    if (((typeof e === 'object' && e && e.target) || typeof e !== 'object') && parsedMonedas) {
      const value = typeof e === 'object' ? e.target.value : e;
      const monFound = parsedMonedas.find((mon) => mon.moneda + '' === value);
      if (!monFound) {
        if (e === 'object') e.preventDefault();
        let message = intl.formatMessage({
          id: 'noexistemoneda',
          defaultMessage: 'No existe moneda',
        });
        setModal(true, message);
        return false;
      }
    }
    return true;
  };

  const closeHelp = () => {
    setHelpColumns([]);
    setHelpRows([]);
    setShowHelp(false);
    setTitle('');
  };

  const helpAcceptHandler = (value, control) => {
    document.getElementById(control).value = value;
    closeHelp();
  };

  let helpWindow = getHelpWindow(helpRows, helpColumns, helpAcceptHandler, closeHelp, modify, title);

  const formatDateForServer = (date) => {
    if (date) {
      date = date.replaceAll('-', '');
      return date;
    }
  };

  const handleAccept = async () => {
    let nombre1 = document.getElementById('Nombre1').value;
    let nombre2 = document.getElementById('Nombre2').value;
    let numsucursal = document.getElementById('Sucursal').value;
    let siglo = document.getElementById('Siglo').value;
    let monnac = document.getElementById('Moneda').value;
    let complementogrupo = document.getElementById('ComplementoGrupo').value;
    let cortesiglo = document.getElementById('CorteDeSiglo').value;
    let ambiente = document.getElementById('Entorno').value;
    let lenguajedefecto = document.getElementById('Idioma').value;
    let movimientoscaja = document.getElementById('MovsCaja').value;
    let comunicaciones = document.getElementById('Comunicaciones').checked;
    if (comunicaciones === true) {
      comunicaciones = 1;
    } else {
      comunicaciones = 0;
    }
    if (!handleBlurSucursal(numsucursal) || !handleBlurMoneda(monnac)) return;

    // let fechaproceso = formatDateForServer(paramFechaPanel.fechaproceso);
    // let fechaprocesoanterior = formatDateForServer(paramFechaPanel.fechaprocesoanterior);
    // let fechaproximoproceso = formatDateForServer(paramFechaPanel.fechaproximoproceso);
    let fechaproceso = paramFechaPanel.fechaproceso;
    let fechaprocesoanterior = paramFechaPanel.fechaprocesoanterior;
    let fechaproximoproceso = paramFechaPanel.fechaproximoproceso;
    let tipoplazo = paramFechaPanel.tipoplazo;

    const fechaprocesoServerFormat = moment(fechaproceso, 'YYYY-MM-DD').tz('GMT').format('YYYYMMDD');
    const fechaprocesoanteriorServerFormat = moment(fechaprocesoanterior, 'YYYY-MM-DD').format('YYYYMMDD');
    const fechaproximoprocesoServerFormat = moment(fechaproximoproceso, 'YYYY-MM-DD').tz('GMT').format('YYYYMMDD');

    let diasvalidos = paramLoginPanel.diasvalidos;
    let clvlargomax = paramLoginPanel.clvlargomax;
    let reintentos = paramLoginPanel.reintentos;
    let tiempocorte = paramLoginPanel.tiempocorte;
    let diasaviso = paramLoginPanel.diasaviso;
    let clvlargomin = paramLoginPanel.clvlargomin;
    let clvcasesensitive = paramLoginPanel.clvcasesensitive;
    let autologin = paramLoginPanel.autologin;
    let tipored = paramLoginPanel.tipored;

    let pwdlargomin = paramPaswwordPanel.pwdlargomin;
    let pwdlargomax = paramPaswwordPanel.pwdlargomax;
    let pwdcantmincar = paramPaswwordPanel.pwdcantmincar;
    let pwdcantminnum = paramPaswwordPanel.pwdcantminnum;
    let pwdcantmaxrep = paramPaswwordPanel.pwdcantmaxrep;
    let pwdcarmaxrep = paramPaswwordPanel.pwdcarmaxrep;
    let pwdposmaxrep = paramPaswwordPanel.pwdposmaxrep;
    let pwdcantcarmaxrep = paramPaswwordPanel.pwdcantcarmaxrep;
    let pwdcasesensitive = paramPaswwordPanel.pwdcasesensitive;
    let pwdreglasmin = paramPaswwordPanel.pwdreglasmin;
    let pwdcarmaxsec = paramPaswwordPanel.pwdcarmaxsec;

    let iniciodeldia = item.iniciodeldia;
    let findeldia = item.findeldia;
    let iniciodesarrollo = item.iniciodesarrollo;
    let versiondesarrollo = item.versiondesarrollo;

    let parametros = {
      nombre1: nombre1,
      nombre2: nombre2,
      numsucursal: parseInt(numsucursal),
      siglo: parseInt(siglo),
      monnac: parseInt(monnac),
      complementogrupo: complementogrupo,
      cortesiglo: parseInt(cortesiglo),
      ambiente: ambiente,
      lenguajedefecto: lenguajedefecto,
      movimientoscaja: movimientoscaja,
      comunicaciones: parseInt(comunicaciones),
      fechaproceso: fechaprocesoServerFormat,
      fechaprocesoanterior: fechaprocesoanteriorServerFormat,
      fechaproximoproceso: fechaproximoprocesoServerFormat,
      tipoplazo: tipoplazo,
      diasvalidos: parseInt(diasvalidos),
      clvlargomax: clvlargomax,
      reintentos: parseInt(reintentos),
      tiempocorte: parseInt(tiempocorte),
      diasaviso: parseInt(diasaviso),
      clvlargomin: clvlargomin,
      clvcasesensitive: clvcasesensitive,
      autologin: parseInt(autologin),
      tipored: tipored,
      pwdlargomin: parseInt(pwdlargomin),
      pwdlargomax: parseInt(pwdlargomax),
      pwdcantmincar: parseInt(pwdcantmincar),
      pwdcantminnum: parseInt(pwdcantminnum),
      pwdcantmaxrep: parseInt(pwdcantmaxrep),
      pwdcarmaxrep: parseInt(pwdcarmaxrep),
      pwdposmaxrep: parseInt(pwdposmaxrep),
      pwdcantcarmaxrep: parseInt(pwdcantcarmaxrep),
      pwdcasesensitive: parseInt(pwdcasesensitive),
      pwdreglasmin: parseInt(pwdreglasmin),
      pwdcarmaxsec: parseInt(pwdcarmaxsec),
      iniciodeldia: iniciodeldia,
      findeldia: findeldia,
      iniciodesarrollo: iniciodesarrollo,
      versiondesarrollo: versiondesarrollo,
    };

    let res = await updateParametros(parametros);
    if (res === false) {
      let message = intl.formatMessage({
        id: 'erroralactualizar',
        defaultMessage: 'Error al intentar actualizar ',
      });
      message += 'Parametros';
      setModal(true, message);
      return;
    }

    parametros.fechaproceso = fechaproceso;
    parametros.fechaprocesoanterior = fechaprocesoanterior;
    parametros.fechaproximoproceso = fechaproximoproceso;
    setModifiedItem(parametros, 0);
    props.handleCancel();
  };

  const setModal = (state, text) => {
    setShowModal(state);
    setTextDialog(text);
  };

  return (
    <div className={`fixed pin overflow-auto flex bg-smoke-light w-full h-full`} ref={(c) => (outside = c)} style={{ zIndex: ABOVE_ALL }}>
      {showModal && (
        <ModalMessage
          message={textDialog}
          handleAccept={async () => {
            setShowModal(false);
          }}
          acceptTitle={acceptMessage}
          draggable={true}
        />
      )}
      <FocusLock persistentFocus returnFocus>
        <div className={css(styles.modal, styles.show)}>
          {/* onKeyDown={handleKeyDown}> */}
          <div className={css(styles.modalMain)}>
            <Draggable handle=".drag-section" bounds={{ top: -200, right: 350, bottom: 200, left: -350 }}>
              <div className={`${css(styles.modalContent)}`}>
                <div className={`drag-section ${css(styles.titleContainer)}`}></div>
                <div className={css(styles.messageContainer)}>
                  <div className={`flex-auto text-left m-1 flex `} style={{ height: 'fit-content', justifyContent: 'center' }}>
                    <form className={'overflow-hidden'} style={{ display: 'table' }}>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="Nombre1">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerName1',
                            defaultMessage: 'Nombre 1',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          defaultValue={modify ? item.nombre1 : ''}
                          className={'clear-both border ml-2 mt-2'}
                          id="Nombre1"
                        ></input>
                      </p>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="Nombre2">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerName2',
                            defaultMessage: 'Nombre 2',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          defaultValue={modify ? item.nombre2 : ''}
                          className={'clear-both border ml-2 mt-2'}
                          id="Nombre2"
                        ></input>
                      </p>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="Sucursal">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerBranch',
                            defaultMessage: 'Sucursal',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          defaultValue={modify ? item.numsucursal : ''}
                          className={'clear-both border ml-2 mt-2'}
                          id="Sucursal"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                          onBlur={handleBlurSucursal}
                        ></input>
                        {helpButtonSucursal}
                      </p>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="Siglo">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerCentury',
                            defaultMessage: 'Siglo',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          defaultValue={modify ? item.siglo : ''}
                          className={'clear-both border ml-2 mt-2'}
                          id="Siglo"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                        ></input>
                      </p>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="Moneda">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerCurrency',
                            defaultMessage: 'Moneda',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          defaultValue={modify ? item.monnac : ''}
                          className={'clear-both border ml-2 mt-2'}
                          id="Moneda"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                          onBlur={handleBlurMoneda}
                        ></input>
                        {helpButtonMoneda}
                      </p>
                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="ComplementoGrupo">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerSupplementGroup',
                            defaultMessage: 'Complemento Grupo',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          defaultValue={modify ? item.complementogrupo : ''}
                          className={'clear-both border ml-2 mt-2'}
                          id="ComplementoGrupo"
                        ></input>
                      </p>

                      <p style={{ display: 'table-row' }}>
                        <label style={{ display: 'table-cell' }} htmlFor="CorteDeSiglo">
                          {' '}
                          {intl.formatMessage({
                            id: 'productionManagerCutOfCentury',
                            defaultMessage: 'Corte de Siglo',
                          })}
                        </label>
                        <input
                          style={{ display: 'table-cell' }}
                          defaultValue={modify ? item.cortesiglo : ''}
                          className={'clear-both border ml-2 mt-2'}
                          id="CorteDeSiglo"
                          type="number"
                          min="0"
                          onKeyDown={(e) => handleKeyOnNumber(e)}
                        ></input>
                      </p>
                      <p style={{ display: 'table-row' }} className="pt-2">
                        <label style={{ display: 'table-cell' }} htmlFor="Entorno">
                          {intl.formatMessage({
                            id: 'productionManagerEnvironment',
                            defaultMessage: 'Entorno',
                          })}
                        </label>
                        <select style={{ display: 'table-cell' }} className={'clear-both border ml-2 mt-2 border'} id="Entorno">
                          <option selected={item.ambiente === 'D'} value="D">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerDevelopment',
                              defaultMessage: 'Desarrollo',
                            })}
                          </option>
                          <option selected={item.ambiente === 'P'} value="P">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerProduction',
                              defaultMessage: 'Producción',
                            })}
                          </option>
                        </select>
                      </p>
                      <p style={{ display: 'table-row' }} className="pt-2">
                        <label style={{ display: 'table-cell' }} htmlFor="Idioma">
                          {intl.formatMessage({
                            id: 'productionManagerDefaultLanguage',
                            defaultMessage: 'Idioma por Defecto',
                          })}
                        </label>
                        <select style={{ display: 'table-cell' }} className={'clear-both border ml-2 mt-2 border'} id="Idioma">
                          <option selected={item.lenguajedefecto === 'E'} value="E">
                            {' '}
                            Español
                          </option>
                          <option selected={item.lenguajedefecto === 'I'} value="I">
                            {' '}
                            Ingles
                          </option>
                          <option selected={item.lenguajedefecto === 'P'} value="P">
                            {' '}
                            Portugues
                          </option>
                        </select>
                      </p>
                      <p style={{ display: 'table-row' }} className="pt-2">
                        <label style={{ display: 'table-cell' }} htmlFor="MovsCaja">
                          {intl.formatMessage({
                            id: 'productionManagerMovsFund',
                            defaultMessage: 'Movs. Caja',
                          })}
                        </label>
                        <select style={{ display: 'table-cell' }} className={'clear-both border ml-2 mt-2 border'} id="MovsCaja">
                          <option selected={item.movimientoscaja === ' '} value="">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerNothing',
                              defaultMessage: 'Nada',
                            })}
                          </option>
                          <option selected={item.movimientoscaja === 'T'} value="T">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerGeneratesPrintsTotal',
                              defaultMessage: 'Genera e Imprime por Total',
                            })}
                          </option>
                          <option selected={item.movimientoscaja === 't'} value="t">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerTotalVirtual',
                              defaultMessage: 'Virtual por Total',
                            })}
                          </option>
                          <option selected={item.movimientoscaja === 'D'} value="D">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerGeneratesPrintsDetail',
                              defaultMessage: 'Genera e Imprime por Detalle',
                            })}
                          </option>
                          <option selected={item.movimientoscaja === 'd'} value="d">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerVirtualByDetails',
                              defaultMessage: 'Virtual por Detalle',
                            })}
                          </option>
                        </select>
                      </p>
                      <div style={{ display: 'table-row' }}>
                        <div style={{ display: 'table-cell' }}></div>
                        <div style={{ display: 'table-cell' }}>
                          <input
                            className={'clear-both border ml-2 mt-2'}
                            id="Comunicaciones"
                            type="checkbox"
                            defaultChecked={item.comunicaciones !== 0}
                          ></input>
                          <label htmlFor="Comunicaciones">
                            {' '}
                            {intl.formatMessage({
                              id: 'productionManagerCommunications',
                              defaultMessage: 'Comunicaciones',
                            })}
                          </label>
                        </div>
                      </div>
                    </form>
                    {showHelp && helpWindow}
                    {showLoginWindow && (
                      <ProductionLoginWindow
                        item={item}
                        handleCancel={() => setShowLoginWindow(false)}
                        params={paramLoginPanel}
                        setParametros={setParamLoginPanel}
                      />
                    )}
                    {showDateProcessWindow && (
                      <ProductionDateProcessWindow
                        item={item}
                        handleCancel={() => setShowDateProcessWindow(false)}
                        setParametros={setParamFechaPanel}
                        params={paramFechaPanel}
                      />
                    )}
                    {showPasswordWindow && (
                      <ProductionPasswordWindow
                        item={item}
                        handleCancel={() => setShowPasswordWindow(false)}
                        params={paramPaswwordPanel}
                        setParametros={setParamPaswwordPanel}
                      />
                    )}
                  </div>
                  <div className="flex justify-center pt-2">
                    <button
                      className={`bg-grey
                       hover:bg-primary focus:bg-primary text-white py-2 px-12 m-2 rounded shadow`}
                      style={{ width: 'fit-content' }}
                      onClick={() => setShowDateProcessWindow(true)}
                    >
                      {intl.formatMessage({
                        id: 'productionManagerProcessDates',
                        defaultMessage: 'Fechas de Procesos',
                      })}
                    </button>
                    <button
                      className={`bg-grey
                       hover:bg-primary focus:bg-primary text-white py-2 px-12 m-2 rounded shadow`}
                      style={{ width: 'fit-content' }}
                      onClick={() => setShowLoginWindow(true)}
                    >
                      Login
                    </button>
                    <button
                      className={`bg-grey
                       hover:bg-primary focus:bg-primary text-white py-2 px-12 m-2 rounded shadow`}
                      style={{ width: 'fit-content' }}
                      onClick={() => setShowPasswordWindow(true)}
                    >
                      {intl.formatMessage({
                        id: 'productionManagerPassword',
                        defaultMessage: 'Contraseña',
                      })}
                    </button>
                  </div>
                  <div className="flex justify-center pt-2">
                    <button
                      data-autofocus
                      className={`bg-grey
                       hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow`}
                      style={{ width: 'fit-content' }}
                      onClick={() => handleAccept()}
                    >
                      {acceptMessage}
                    </button>

                    <button
                      className={`bg-grey
                   hover:bg-primary focus:bg-primary text-white py-2 px-12 rounded shadow ml-2`}
                      style={{ width: 'fit-content' }}
                      onClick={() => props.handleCancel()}
                    >
                      {cancelMessage}
                    </button>
                  </div>
                </div>
              </div>
            </Draggable>
          </div>
        </div>
      </FocusLock>
    </div>
  );
};

export default ParameterWindow;
