import React from 'react';
import { connect } from 'unistore/react';
import { store, storeActions } from '../../src/store';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { addLineTest, addLineEventTest, addBlurTest } from '../../src/components/Testing/HandleLineTest';
import Help from './Help';
import Displayable from './Displayable';
import HelpWindow from './HelpWindow';
import HelpPopup from './HelpPopup';
import { createRunBody, createSimpleBody, createBodyGoToLine } from '../../renderer/lib/bodyCreators';
import { run, gotoLineWithBody, undo } from '../../src/api/dialog';
import { getCurrentTabInformation, getTabIdByNodeId } from '../../src/selectors';
import { textSize, mapColor } from '../helpers/styleHelper';
import { compareTLId, generateLineIDString, getTL, getTLFromStr } from '../helpers/operationHelper';
import { css, StyleSheet } from 'aphrodite';
import browserStorage from '../../src/utils/browserStorage';
import { num2hex } from '../../src/utils/ColorMaper';
import { canReceiveFocus } from '../../renderer/lib/focusManager';

const INPUT_PARAMETER_NUMBER_TYPE = 2;
const INPUT_PARAMETER_CURRENCY_TYPE = 3;

// eslint-disable-next-line no-unused-vars
const LEFT_ARROW_CHARCODE = 37;
const UP_ARROW_CHARCODE = 38;
// eslint-disable-next-line no-unused-vars
const RIGHT_ARROW_CHARCODE = 39;
const DOWN_ARROW_CHARCODE = 40;

const COMMA = ',';
const POINT = '.';
const CURRENCY = 'I';

export const NumberInput = (props) => {
  let input = React.useRef(null);
  const [showingHelp, setShowingHelp] = React.useState(false);
  const [helpAccepted, setHelpAccepted] = React.useState(false);
  const [helpCanceled, setHelpCanceled] = React.useState(false);
  const [showingHelpPopup, setShowingHelpPopup] = React.useState(false);
  const [focusGainedBy, setFocusGainedBy] = React.useState('');
  const intl = useIntl();
  let clientProperties = store.getState().clientProperties;
  let useBackColor = clientProperties?.useBackColor;
  let {
    nodeId,
    label,
    placeholder = '',
    help,
    componentState,
    operationNumber,
    operationID,
    processContext,
    position = { x: 0, y: 0, width: 'auto', height: 'auto' },
    labelPosition = { x: 0, y: 0, width: 'auto', height: 'auto' },
    readOnly,
    showPrompt,
    showBackground,
    fieldNumber,
    transactionLineLabel,
    lineNumber,
    transactionNumber,
    promptBackColor,
    inputParameters,
    focusElement,
    labelFont,
    font,
    defaultValue,
    fieldData,
    focusPaused,
    setApiLoading,
    apiLoading,
    initiallyVisible,
  } = props;

  if (transactionLineLabel !== focusElement && initiallyVisible === false) {
    help = false;
  }

  const doFocusElement = () => {
    const { transactionLineLabel, focusOn, focusPaused } = props;
    const state = store.getState();
    let focusElement = state.tabs[state.current] && state.tabs[state.current].running && state.tabs[state.current].running.focusElement;
    if (
      !showingHelp &&
      input &&
      input.current &&
      transactionLineLabel === focusElement &&
      focusOn &&
      focusOn[0] !== 'T0L0' &&
      !focusPaused &&
      transactionLineLabel !== document.activeElement.id &&
      !helpAccepted &&
      (helpCanceled ||
        (document.activeElement.id.indexOf(transactionLineLabel + '_HelpTable') === -1 && !helpAccepted && !helpCanceled && !apiLoading)) &&
      document.getElementById(transactionLineLabel)
    ) {
      if (helpCanceled) {
        setHelpCanceled(false);
      }
      document.getElementById(transactionLineLabel).focus();
      let tmpState = browserStorage.get('onKeyDownEnter');
      if (!tmpState) {
        setTimeout(function () {
          if (input.current) {
            const selectionStart = input.current.value === ' ' || parseInt(input.current.value) === 0 ? 0 : input.current.selectionStart;
            const selectionEnd = input.current.selectionEnd || input.current.value.length;
            input.current.setSelectionRange(selectionStart, selectionEnd);
          }
        }, 200);
      } else {
        const selectionStart = input.current.value === ' ' || parseInt(input.current.value) === 0 ? 0 : input.current.selectionStart;
        const selectionEnd = input.current.selectionEnd || input.current.value.length;
        document.getElementById(transactionLineLabel).setSelectionRange(selectionStart, selectionEnd);
      }
    }
  };

  React.useEffect(() => {
    const lastValueListener = (e) => {
      e = e || window.event;
      if (e.ctrlKey && e.keyCode === 76) {
        e.preventDefault();
        e.stopPropagation();
        window.event.returnValue = false;
        window.event.cancelBubble = true;
        const { addFieldValue, lastValues, transactionLineLabel, fieldNumber } = props;
        let lastValue = lastValues && lastValues[fieldNumber];
        if (lastValue) {
          lastValue = formatValueForServer(lastValue.toString());
          const value = { [transactionLineLabel]: { value: lastValue } };
          addFieldValue(value);
        }
        return false;
      }
    };
    if (document.getElementById(transactionLineLabel)) {
      document.getElementById(transactionLineLabel).onkeydown = lastValueListener;
    }
    return () => {
      if (document.getElementById(transactionLineLabel)) {
        document.getElementById(transactionLineLabel).removeEventListener('keydown', lastValueListener);
      }
    };
  });

  if (!componentState) return null;
  const toggleWindow = () => {
    let newShowingHelp = showingHelp;
    setShowingHelp(!newShowingHelp);
  };

  const acceptHandler = (field) => {
    const {
      addFieldValue,
      removeFromWindowStack,
      transactionLineLabel,
      operationNumber,
      operationID,
      transactionNumber,
      lineNumber,
      processContext,
      focusElement,
      fieldData,
      tabId,
    } = props;
    let body;
    const inputValue = field[transactionLineLabel].value;
    setHelpAccepted(true);
    if (Array.isArray(field[transactionLineLabel].value)) {
      body = createSimpleBody(operationNumber, transactionLineLabel, field[transactionLineLabel].value);
    } else {
      setLastValue(inputValue);
      addFieldValue(field);
      body = createRunBody(operationNumber, transactionLineLabel, '' + field[transactionLineLabel].value);
    }
    if (transactionLineLabel !== focusElement) {
      let bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
      gotoLineWithBody(operationID, transactionNumber, lineNumber, bodyGoToLine).then(() => {
        setApiLoading(true, tabId);
        run(operationID, transactionNumber, lineNumber, body)
          .then((res) => {
            setLastValueByResponse(res);
            processContext(res, 'run Input Help');
          })
          .catch(() => setApiLoading(false, tabId))
          .finally(() => {
            setApiLoading(false, tabId);
          });
      });
    } else {
      setApiLoading(true, tabId);
      run(operationID, transactionNumber, lineNumber, body)
        .then((res) => {
          setLastValueByResponse(res);
          processContext(res, 'run Input Help');
        })
        .catch(() => setApiLoading(false, tabId))
        .finally(() => {
          setApiLoading(false, tabId);
        });
    }
    removeFromWindowStack(transactionLineLabel);
    toggleWindow();
  };

  const validateField = (text) => {
    const { optional } = props;

    if (!text && text !== 0 && !optional) {
      const { label } = props;
      const required = intl.formatMessage({
        id: 'errorMandatoryField',
        defaultMessage: 'es de ingreso obligatorio',
      });
      const msg = `${label} ${required}`;
      const modalOptions = {
        returnFocus: true,
      };
      props.showModalMessage(msg, modalOptions);
      return false;
    }
    return true;
  };

  const setLastValue = (valueToSave) => {
    const { setLastValue, fieldNumber } = props;
    setLastValue(fieldNumber, valueToSave);
  };

  /**
   * Executes the props.onKeyDown event of FieldToDisplay.
   * Also, if applicable, open the help and filter the data.
   */
  const handleKeyDown = async (e, hasCharacterMask = false) => {
    const {
      onKeyDown,
      operationID,
      operationNumber,
      processContext,
      reverseFlow,
      focusElement,
      removeFromWindowStack,
      removeTreeNode,
      transactionLineLabel,
      pushToWindowStack,
      mandatoryHelp,
    } = props;
    let help = !!showingHelp; // Check if showingHelp exists
    const forward = e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey);
    const openHelp = mandatoryHelp && forward;
    addLineEventTest(e);
    if (helpElement) {
      if (e.key === 'F1' || openHelp) {
        addBlurTest('SENDTEXT', e);
        pushToWindowStack(transactionLineLabel);
        toggleWindow(); // open help window
        e.preventDefault();
        help = true;
      }
    }

    if (!help && forward && !validateField(e.target.value)) {
      e.preventDefault();
      return;
    }
    if (!help && forward) {
      e.preventDefault();
    }

    if (e.key === 'Escape' || (e.shiftKey && e.key === 'Tab')) {
      let res = await undo(operationID, transactionNumber, lineNumber);

      const elementsRemove = res && res.lista && res.lista.filter((component) => component['class-name'].includes('ItemToDeleteEvent'));

      if (elementsRemove) {
        elementsRemove.forEach((element) => {
          const lineIDKey = generateLineIDString(element.lineID);
          removeFromWindowStack(lineIDKey);
        });
      }

      reverseFlow();
      processContext(res, 'NumberIput Escape');
      reverseFlow(false);
    } else {
      let fictionalEvent = e;
      if (!mandatoryHelp && forward) {
        if (hasCharacterMask) {
          let value;
          if (e.target.value) {
            value = e.target.value.replace(/\D/g, '');
          }
          setLastValue(value);
          let fictionalEvent = e;
          fictionalEvent.target.value = value;
        } else {
          setLastValue(e.target.value);
        }
      }

      // Executes the props.onKeyDown event of FieldToDisplay.
      onKeyDown(fictionalEvent, operationID, operationNumber, processContext, focusElement, help, removeFromWindowStack, removeTreeNode);
    }
  };

  const setLastValueByResponse = (response) => {
    const { transactionLineLabel } = props;

    if (!response || !response.lista || !response.lista.length) {
      return;
    }
    const valueToSet = response.lista.find((o) => {
      return o['class-name'].includes('ValueToSetEvent') && o.lineID && getTL(o.lineID) === getTLFromStr(transactionLineLabel) && o.value;
    });

    if (valueToSet) {
      setLastValue(valueToSet.value);
    }
  };

  const handleBlur = (e) => {
    const tlid1 = props.transactionLineLabel;
    const tlid2 = e && e.relatedTarget && e.relatedTarget.id ? e.relatedTarget.id : '';

    if (compareTLId(tlid1, tlid2) <= 0 || (!showingHelp && props.tl === props.focusElement && !validateField(e.target.value))) {
      e.preventDefault();
    } else {
      addBlurTest('SENDTEXT', e);
    }
  };

  const handleChange = (e) => {
    if (hasCharacterMask) {
      let fictionalEvent = e;
      if (e.target.value) {
        fictionalEvent.target.value = formatValueForServer(e.target.value.toString().replace(/\D/g, ''));
      }
      props.onChange(fictionalEvent);
    } else {
      if (e.target.value === undefined) {
        e.target.value = 0;
      }
      if (typeof e.target.value === 'string') {
        let fictionalEvent = { target: { value: formatValueForServer(e.target.value) } };
        props.onChange(fictionalEvent);
      } else {
        props.onChange(e);
      }
    }
  };

  const formatValueForServer = (val) => {
    let thousandSeparator =
      type === INPUT_PARAMETER_NUMBER_TYPE ? '' : type === INPUT_PARAMETER_CURRENCY_TYPE && edition === CURRENCY ? POINT : COMMA;
    let decimalSeparator =
      type === INPUT_PARAMETER_NUMBER_TYPE ? POINT : type === INPUT_PARAMETER_CURRENCY_TYPE && edition === CURRENCY ? COMMA : POINT;
    let valRet = parseFloat(val.toString().replaceAll(thousandSeparator, '').replace(decimalSeparator, '.'));
    return valRet;
  };

  let disabled = componentState && componentState.disabled;
  let tailwindColorLabel = labelFont && mapColor(`${labelFont.colorRed}, ${labelFont.colorGreen}, ${labelFont.colorBlue}`);
  const tailwindColor = mapColor(`${font.colorRed}, ${font.colorGreen}, ${font.colorBlue}`);
  const fontSize = textSize(font.fontSize);
  const underline = font.underLine ? 'underline' : '';
  const strikeout = font.strikeOut ? 'line-through' : '';
  const lineStyle = underline + ' ' + strikeout;
  const fontStyle = font.fontStyle;
  var backColor = 'transparent';

  if (promptBackColor === 'transparent' && useBackColor === 'false') {
    tailwindColorLabel = 'text-base';
  }

  if (promptBackColor !== undefined && promptBackColor !== 'transparent') {
    backColor = num2hex(promptBackColor.value);
  }

  let value =
    componentState && componentState.value !== undefined && componentState.value !== null
      ? componentState.value
      : defaultValue !== undefined
      ? defaultValue
      : '';
  if (value === 0 && (readOnly === true || disabled === true) && defaultValue) {
    value = defaultValue;
  }

  if (readOnly && (value === '' || value === undefined) && inputParameters.type === INPUT_PARAMETER_CURRENCY_TYPE) {
    value = 0;
  }
  if (value) {
    if (!isNaN(value) && typeof value === 'string') {
      if (value.toString().includes('e')) {
        // eslint-disable-next-line no-new-wrappers
        let valueAux = new Number(value.toString());
        if (inputParameters.decimals) {
          value = Number.parseFloat(valueAux).toFixed(inputParameters.decimals);
        }
      }

      let { type, edition, decimals } = inputParameters;
      // el formato depende del tipo number/currency? o de una configuracion servidor?
      // decimal    i = .   I = ,
      let thousandSeparator =
        type === INPUT_PARAMETER_NUMBER_TYPE ? '' : type === INPUT_PARAMETER_CURRENCY_TYPE && edition === CURRENCY ? POINT : COMMA;
      let decimalSeparator =
        type === INPUT_PARAMETER_NUMBER_TYPE ? POINT : type === INPUT_PARAMETER_CURRENCY_TYPE && edition === CURRENCY ? COMMA : POINT;

      if (thousandSeparator === '.' && decimalSeparator === ',') {
        value = value.toString();
        if (decimals > 0) {
          value = value.replace('.', ',');
        } else {
          value = value.replace('.', '');
        }
      }
    }
  }
  let helpStyle = {
    height: position.height,
    cursor: !!apiLoading && 'wait',
    pointerEvents: !!apiLoading && 'none',
  };

  let helpElement =
    help && !readOnly && !disabled ? (
      <Help
        tlid={transactionLineLabel + '_HelpButton'}
        style={helpStyle}
        click={async (e) => {
          if (apiLoading || !canReceiveFocus(e)) return false;
          let { pushToWindowStack } = props;
          addLineTest('CLICK', e);
          pushToWindowStack(transactionLineLabel);
          let noticeMessage = false;
          if (transactionLineLabel !== focusElement) {
            let body = createSimpleBody(operationNumber, transactionLineLabel, value);
            let bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
            let restGoToLine = await gotoLineWithBody(operationID, transactionNumber, lineNumber, bodyGoToLine);
            processContext(restGoToLine, 'gotoLine Input Help');
            noticeMessage = restGoToLine.lista.find((element) => element['class-name'].includes('NoticeToDisplayEvent'));
          }
          if (!noticeMessage) toggleWindow();
        }}
      />
    ) : null;
  let noRoundRight = help && !readOnly && !disabled ? 'rounded-r-none' : '';
  let isInWindowArea =
    componentState && componentState.windowWidth && componentState.windowHeight
      ? componentState.windowWidth > labelPosition.x && componentState.windowHeight > labelPosition.y
      : true;

  const buttonStyle = {
    left: position.x,
    top: position.y,
    width: position.width,
    height: position.height,
    zIndex: 'inherit',
  };

  const labelStyle = {
    left: labelPosition.x,
    top: labelPosition.y,
    width: labelPosition.width,
    height: labelPosition.height,
    zIndex: 'inherit',
    textDecoration: lineStyle,
    backgroundColor: backColor,
  };

  const helpDivStyle = {
    left: position.x + position.width,
    top: position.y,
    zIndex: 'inherit',
  };

  let { type, edition, decimals, length, mask } = inputParameters;

  let thousandSeparator = type === INPUT_PARAMETER_NUMBER_TYPE ? '' : type === INPUT_PARAMETER_CURRENCY_TYPE && edition === CURRENCY ? POINT : COMMA;
  let decimalSeparator =
    type === INPUT_PARAMETER_NUMBER_TYPE ? POINT : type === INPUT_PARAMETER_CURRENCY_TYPE && edition === CURRENCY ? COMMA : POINT;

  const styles = StyleSheet.create({
    apiLoading: {
      pointerEvents: apiLoading ? 'none' : 'auto',
      cursor: apiLoading ? 'wait' : 'auto',
    },
  });
  let lengthToAssign = length;
  if (thousandSeparator && thousandSeparator !== '') {
    // lengthToAssign = lengthToAssign + (length - decimals) / 3;

    var quotient = Math.floor((length - decimals) / 3);
    var remainder = (length - decimals) % 3;
    if (remainder === 0) {
      quotient = quotient - 1;
    }
    if (decimals > 0) {
      lengthToAssign = length + 1 + quotient;
    } else {
      lengthToAssign = length + quotient;
    }
  }

  let format = mask === '*' || mask === '?' || mask === '_' || mask === '#' ? mask.replace(/ /g, '#') : null;
  let hasCharacterMask = false;
  if (mask && mask.length > 1 && format === null) {
    let parsedMask = [];
    for (var i = 0; i < mask.length; i++) {
      let character = mask[i];
      if (/\s/.test(character)) {
        parsedMask[i] = '#';
      } else {
        parsedMask[i] = character;
      }
    }
    format = parsedMask.join('');
    hasCharacterMask = true;
  }

  doFocusElement();

  if (helpAccepted && !focusPaused) {
    setHelpAccepted(false);
  }

  return (
    <React.Fragment>
      <Displayable componentState={componentState}>
        <label
          className={`${
            fontStyle === 1 ? 'font-semibold' : fontStyle === 2 ? 'italic' : ''
          } ${fontSize} text-${tailwindColorLabel} absolute bg-grey-lighter-lighter`}
          style={{
            ...labelStyle,
            fontSize: (labelFont && labelFont.fontSize) || 12,
          }}
        >
          {showPrompt && isInWindowArea ? label : ''}
        </label>
        <div
          className={`${css(styles.apiLoading)} absolute flex`}
          style={buttonStyle}
          onMouseDown={(e) => {
            if (props.apiLoading) return false;
            if (readOnly) {
              for (const field in fieldData) {
                if (fieldData[field].position) {
                  let xDiff = Math.abs(parseInt(fieldData[field].position.x) - parseInt(fieldData[transactionLineLabel].position.x));
                  let yDiff = Math.abs(parseInt(fieldData[field].position.y) - parseInt(fieldData[transactionLineLabel].position.y));
                  if (
                    xDiff <= 10 &&
                    yDiff <= 10 &&
                    field !== transactionLineLabel &&
                    fieldData[field].inputType !== 5 &&
                    fieldData[field].hide === false &&
                    fieldData[field].treeNumber === fieldData[transactionLineLabel].treeNumber
                  ) {
                    fieldData[transactionLineLabel].hide = true;
                    this.forceUpdate();
                  }
                }
              }
            }
          }}
        >
          <NumberFormat
            id={transactionLineLabel}
            getInputRef={(element) => {
              input.current = element;
            }}
            placeholder={placeholder}
            className={`${fontSize} text-${tailwindColor} appearance-none px-1 rounded ${showBackground ? 'shadow' : ''} w-full ${noRoundRight} ${
              readOnly ? 'bg-grey-lighter-lighter' : ''
            }`}
            style={{
              fontSize: 12,
              background: showBackground ? '' : 'rgba(0,0,0,0)',
              border: showBackground ? '' : '0',
              textAlign: props.align === 2 ? 'right' : props.align === 1 ? 'center' : 'left',
              paddingRight: '.5rem',
            }}
            onMouseDown={(e) => {
              addLineTest('CLICK', e);
              setFocusGainedBy('click');
            }}
            value={value}
            onValueChange={(e) => {
              if (value !== e.floatValue) {
                let fictionalEvent = { target: { value: e.floatValue } };
                handleChange(fictionalEvent);
              }
            }}
            onFocus={(e) => {
              if (focusGainedBy === 'click') {
                setFocusGainedBy('');
                if (props.apiLoading || !canReceiveFocus(e)) return false;
                if (!store.getState().validationFailed) {
                  if (!focusPaused && transactionLineLabel !== focusElement) {
                    let body = createSimpleBody(operationNumber, transactionLineLabel, value);
                    let bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
                    gotoLineWithBody(operationID, transactionNumber, lineNumber, bodyGoToLine).then((res) => {
                      processContext(res, 'gotoLine Input');
                    });
                  }
                }
              }
              if (input && input.current) {
                const selectionEnd = input.current.value.length;
                input.current.setSelectionRange(0, selectionEnd);
              }
            }}
            onBlur={(e) => {
              if (e.relatedTarget === null || e.relatedTarget.classList.contains('contorno')) {
                if (input && input.current) {
                  if (document.activeElement.id !== input.current.id) {
                    document.getElementById(transactionLineLabel).focus();
                  }
                }
              } else {
                browserStorage.set('onKeyDownEnter', false);
                if (typeof e.target.value === 'string') {
                  let fictionalEvent = e;
                  let formattedVal = e.target.value;
                  fictionalEvent.target.value = formatValueForServer(e.target.value);
                  handleBlur(fictionalEvent);
                  e.target.value = formattedVal;
                } else {
                  handleBlur(e);
                }
              }
            }}
            onKeyDown={(e) => {
              if (props.apiLoading) return false;
              let tmpState = browserStorage.get('onKeyDownEnter');
              if (!tmpState) {
                browserStorage.set('onKeyDownEnter', true);
              }
              if (e.which === 13 && e.shiftKey) {
                setShowingHelpPopup(!showingHelpPopup);
                return;
              }
              let pressedChar = e.charCode || e.which;

              if (showingHelpPopup) {
                if (pressedChar === UP_ARROW_CHARCODE || pressedChar === DOWN_ARROW_CHARCODE || pressedChar === 13) {
                  let gridPopupElement = document.getElementById(transactionLineLabel + '_HelpTable_0');
                  var keyboardEvent = document.createEvent('KeyboardEvent');
                  var initMethod = typeof keyboardEvent.initKeyboardEvent !== 'undefined' ? 'initKeyboardEvent' : 'initKeyEvent';

                  keyboardEvent[initMethod](
                    'keydown', // event type: keydown, keyup, keypress
                    true, // bubbles
                    true, // cancelable
                    window, // view: should be window
                    false, // ctrlKey
                    false, // altKey
                    false, // shiftKey
                    false, // metaKey
                    pressedChar, // keyCode: unsigned long - the virtual key code, else 0
                    pressedChar, // charCode: unsigned long - the Unicode character associated with the depressed key, else 0
                  );
                  gridPopupElement.dispatchEvent(keyboardEvent);
                }
              } else {
                if (
                  (pressedChar >= 96 && pressedChar <= 105) ||
                  (pressedChar >= 48 && pressedChar <= 57) ||
                  pressedChar === 110 ||
                  pressedChar === 188 ||
                  pressedChar === 190
                ) {
                  const decimalPosition = input.current.value ? input.current.value.indexOf(decimalSeparator) : 0;
                  let cursorPosition = input.current.selectionStart;
                  if (cursorPosition === decimalPosition && (pressedChar === 110 || pressedChar === 188 || pressedChar === 190)) {
                    e.preventDefault();
                    e.stopPropagation();
                    document.getElementById(transactionLineLabel).setSelectionRange(cursorPosition + 1, cursorPosition + 1);
                  }

                  if (
                    (cursorPosition > decimalPosition || cursorPosition < decimalPosition) &&
                    cursorPosition !== input.current.value.length &&
                    input.current.value.length >= Math.floor(lengthToAssign)
                  ) {
                    let valueModified = input.current.value;
                    let modifiedChar = valueModified[cursorPosition];
                    // si se modifica el lugar de un separador de grupos, saltearlo y modificar el valor 1 indice mas adelante
                    if (isNaN(modifiedChar)) {
                      cursorPosition++;

                      // si se escribe el mismo caracter que ya esta escrito, saltear la posicion y no hacer nada (por alguna razon, escribir el mismo caracter sobre un separador de grupos en , genera un bug donde ese separador de grupos se vuelve un separador decimal)
                      modifiedChar = valueModified[cursorPosition];
                      if (e.key === modifiedChar) {
                        document.getElementById(transactionLineLabel).setSelectionRange(cursorPosition + 1, cursorPosition + 1);
                        e.preventDefault();
                        return;
                      }
                    }
                    valueModified =
                      valueModified.slice(0, cursorPosition) /* + e.key */ + valueModified.slice(cursorPosition + 1, valueModified.length);
                    input.current.value = valueModified;

                    document.getElementById(transactionLineLabel).setSelectionRange(cursorPosition, cursorPosition);
                  } else if (
                    (cursorPosition === decimalPosition || cursorPosition === input.current.value.length) &&
                    input.current.value.length >= Math.floor(lengthToAssign)
                  ) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                } else {
                  handleKeyDown(e, hasCharacterMask);
                }
              }
            }}
            readOnly={readOnly || disabled}
            disabled={readOnly}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={decimals}
            fixedDecimalScale={true}
            format={format}
            autoComplete="off"
            isNumericString={true}
          />

          {false && help && (
            <HelpPopup
              nodeId={nodeId}
              cancelHandler={() => {
                let { removeFromWindowStack } = props;
                removeFromWindowStack(transactionLineLabel);
                toggleWindow();
              }}
              acceptHandler={acceptHandler}
              fieldNumber={fieldNumber}
              transactionLineLabel={transactionLineLabel}
              inputValue={value}
              transactionNumber={transactionNumber}
              lineNumber={lineNumber}
              showingHelpPopup={showingHelpPopup}
            />
          )}
        </div>
        <div className="absolute flex" style={helpDivStyle}>
          {helpElement}
        </div>
        {help && (
          <HelpWindow
            nodeId={nodeId}
            cancelHandler={() => {
              let { removeFromWindowStack } = props;
              setHelpCanceled(true);
              removeFromWindowStack(transactionLineLabel);
              toggleWindow();
            }}
            acceptHandler={acceptHandler}
            fieldNumber={fieldNumber}
            transactionLineLabel={transactionLineLabel}
            inputValue={value}
            transactionNumber={transactionNumber}
            lineNumber={lineNumber}
            showingHelp={showingHelp}
            isFocused={
              input && input.current && transactionLineLabel === focusElement && props.focusOn && props.focusOn[0] !== 'T0L0' && !focusPaused
            }
            readOnly={readOnly}
          />
        )}
      </Displayable>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { nodeId } = ownProps;
  const tabId = getTabIdByNodeId(state, nodeId);
  const { operationID, operationNumber, focusElement, focusPaused, focusOn, apiLoading } = getCurrentTabInformation(state);

  const { lastValues } = state;

  return {
    operationID,
    operationNumber,
    focusElement,
    focusPaused,
    lastValues,
    focusOn,
    apiLoading,
    tabId,
  };
};

export default connect(mapStateToProps, storeActions)(NumberInput);
