import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import withSession from '../../api/withSession';
import LoginView from './LoginView';
import ChangePasswordViewModel from '../ChangePassword/ChangePasswordViewModel';
import { CLIENT_GUID, CLIENT_VERSION_INFO, PATHNAME } from '../../config';
import * as LS from './constants/LoginStatus';
import { useIntl } from 'react-intl';
import { connect } from 'unistore/react';
import { store, storeActions } from '../../store';
import { BUTTON_YES, BUTTON_NO, BUTTON_OK } from '../../../UI-kit/components/Modal/constants/Buttons';
import { getClientProperties } from '../../api/session';
import LoginSelectionView from './LoginSelectionView';
import { login as loginWithFingerprint, loginPasswordAndFingerprint } from '../../api/fingerprintWS';

const LoginViewModel = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const [firstPassword, setFirstPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [loginNumber, setLoginNumber] = useState(null);
  const intl = useIntl();
  const [noticeDecided, setNoticeDecided] = useState(false);
  const [secondLogin, setSecondLogin] = useState(false);
  const [addFingerLogin, setAddFingerLogin] = useState(false);
  const [tipoLoged, setTipoLoged] = useState(0);
  const [loginMessage, setLoginMessage] = useState(null);
  const [showUserDefinedLogin, setShowUserDefinedLogin] = useState(false);
  const [huellaBase64, setHuellaBase64] = useState(null);
  const { buttonsData, escapePressed } = props;

  React.useEffect(() => {
    getClientProperties().then((clientProperties) => {
      if (clientProperties.showUserDefinedLogin === 'true') {
        props.setNewLogin(true);
        setShowUserDefinedLogin(true);
        store.setState({ clientProperties });
        window.localStorage.setItem('clientProperties', JSON.stringify(clientProperties));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Delete error when inputs changes
   */
  React.useEffect(() => setError(''), [inputs]);

  React.useEffect(() => {
    const noticeListener = () => {
      const { hideModalMessage, removeModal } = props;
      const clickedButton = buttonsData.find((b) => b.clicked);
      if (clickedButton || escapePressed) {
        switch (loginNumber) {
          case LS.USR_ALREADY_LOGGED_CONFIRMATION: {
            if (clickedButton) {
              if (clickedButton.buttonId === BUTTON_YES) {
                const { history } = props;
                history.push(`${PATHNAME}home`);
                removeModal();
                hideModalMessage();
                setNoticeDecided(true);
              }
            } else if (escapePressed) {
              removeModal();
              hideModalMessage();
              setNoticeDecided(true);
            }
            break;
          }
          case LS.PWD_EXPIRED: {
            if (clickedButton || escapePressed) {
              removeModal();
              hideModalMessage();
              setNoticeDecided(true);
            }
            break;
          }
          case LS.PWD_EXPIRES_SOON: {
            if (clickedButton) {
              if (clickedButton.buttonId === BUTTON_YES) {
                setChangePassword(true);
                removeModal();
                hideModalMessage();
                setNoticeDecided(true);
              } else if (clickedButton.buttonId === BUTTON_NO) {
                const { history } = props;
                history.push(`${PATHNAME}home`);
                removeModal();
                hideModalMessage();
                setNoticeDecided(true);
              }
            } else if (escapePressed) {
              const { history } = props;
              history.push(`${PATHNAME}home`);
              removeModal();
              hideModalMessage();
              setNoticeDecided(true);
            }
            break;
          }
          case LS.FINGERPRINT_NOT_REGISTERED: {
            if (clickedButton) {
              if (clickedButton.buttonId === BUTTON_OK) {
                setAddFingerLogin(true);
              }
            }
            break;
          }
          default:
            break;
        }
      }
    };

    if (!noticeDecided) {
      noticeListener();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonsData, escapePressed, noticeDecided]);

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    const { initSession } = props;
    event.preventDefault();
    setLoading(true);
    try {
      const clientProperties = await getClientProperties();
      await initSession(CLIENT_GUID, CLIENT_VERSION_INFO);
      const username = inputs.username?.trim();
      store.setState({ clientProperties });
      window.localStorage.setItem('clientProperties', JSON.stringify(clientProperties));

      const tipoLog = await tipoLogin(username);
      setTipoLoged(tipoLog);
      const segundoL = await segundoLogin(username);
      if (segundoL === 1 && (tipoLog === 1 || tipoLog === 2)) {
        setSecondLogin(true);
      } else {
        await loginDialog(username, inputs.password, false);
      }
    } catch (err) {
      setError(err.message || err);
    } finally {
      setLoading(false);
    }
  };

  const loginDialog = async (username, password, hasHuellaLogin, pHuellaBase64, fromLogin) => {
    const { login } = props;
    setLoginMessage(null);
    setLoading(true);
    try {
      let loginNumber = null;

      const handleResponse = (response) => {
        const { returnCode } = response.data;
        if (returnCode === LS.FINGERPRINT_NOT_REGISTERED) {
          openGlobalModal('No existen registros para el usuario. Se debe registrar la huella', false, null, false, false, false, () => {
            setAddFingerLogin(true);
          });
        }
        handleLoginNumber(returnCode);
      };

      if (fromLogin) {
        setHuellaBase64(pHuellaBase64);
      }

      let fingerprint = fromLogin ? pHuellaBase64 : huellaBase64;

      if (hasHuellaLogin) {
        if (tipoLoged === 1 || tipoLoged === 2) {
          if (tipoLoged === 1) {
            loginWithFingerprint(null, username, fingerprint)
              .then(handleResponse)
              .catch((err) => {
                setError(err?.response?.data?.message || err.message || err);
              });
          } else {
            loginPasswordAndFingerprint(null, username, inputs.password, fingerprint)
              .then(handleResponse)
              .catch((err) => {
                setError(err?.response?.data?.message || err.message || err);
              });
          }
        }
      } else {
        loginNumber = await login(username, password, hasHuellaLogin);
        handleLoginNumber(loginNumber);
      }
      handleLoginNumber(loginNumber);
    } catch (err) {
      setError(err.message || err);
    } finally {
      setLoading(false);
    }
  };

  const tipoLogin = async (username) => {
    const { getTipoLogin } = props;
    let tipo = 0;
    try {
      tipo = await getTipoLogin(username);
      return tipo;
    } catch (err) {
      setError(err.message || err);
    }
  };

  const segundoLogin = async (username) => {
    const { getSegundoLogin } = props;
    let segundoL = 0;
    try {
      segundoL = await getSegundoLogin(username);
      return segundoL;
    } catch (err) {
      setError(err.message || err);
    }
  };

  const handleLoginNumber = (loginNumber, ignoreForward) => {
    const { history } = props;
    store.setState({ history: history });
    setLoginNumber(loginNumber);
    setNoticeDecided(false);
    switch (loginNumber) {
      case LS.USR_OK: {
        if (!ignoreForward) history.push(`${PATHNAME}home`);
        break;
      }
      case LS.PWD_NOT_EXIST: {
        setFirstPassword(true);
        break;
      }
      case LS.PWD_EXPIRED: {
        showLoginMessage(loginNumber);
        setChangePassword(true);
        break;
      }
      case LS.USR_ALREADY_LOGGED_ANOTHER_SESSION_LOGGED_OUT: {
        showLoginMessage(loginNumber, `${PATHNAME}home`);
        break;
      }
      case LS.USR_ALREADY_LOGGED_CONFIRMATION: {
        showLoginMessage(loginNumber);
        break;
      }
      case LS.USR_ALREADY_LOGGED_CANT_LOGIN: {
        showLoginMessage(loginNumber);
        break;
      }
      case LS.PWD_EXPIRES_SOON: {
        showLoginMessage(loginNumber);
        break;
      }
      case LS.USR_PSW_DISABLED: {
        showLoginMessage(loginNumber);
        break;
      }
      case LS.FINGERPRINT_NOT_REGISTERED: {
        setAddFingerLogin(true);
        break;
      }
      case LS.FINGERPRINT_LOGIN_TYPE_ERROR: {
        showLoginMessage(loginNumber);
        break;
      }
      case LS.FINGERPRINT_LOGIN_CONFIGURATED_ERROR: {
        showLoginMessage(loginNumber);
        break;
      }
      case LS.FINGERPRINT_INVALID: {
        showLoginMessage(loginNumber);
        break;
      }
      default:
        break;
    }
  };

  const showLoginMessage = (loginNumber, redirectTo) => {
    const { openGlobalModal } = props;

    const message = intl.formatMessage({
      id: LS.LoginStatusMessages[loginNumber].messageId,
      defaultMessage: LS.LoginStatusMessages[loginNumber].defaultMessage,
    });

    setLoginMessage(message);
    switch (loginNumber) {
      case LS.USR_ALREADY_LOGGED_ANOTHER_SESSION_LOGGED_OUT: {
        openGlobalModal(message, false, redirectTo);
        break;
      }
      case LS.USR_ALREADY_LOGGED_CONFIRMATION: {
        openGlobalModal(message);
        break;
      }
      case LS.USR_ALREADY_LOGGED_CANT_LOGIN: {
        openGlobalModal(message);
        break;
      }
      case LS.PWD_EXPIRES_SOON: {
        openGlobalModal(
          message,
          false,
          null,
          false,
          false,
          true,
          () => {
            setChangePassword(true);
          },
          () => {
            props.history.push(`${PATHNAME}home`);
          },
        );
        break;
      }
      case LS.PWD_EXPIRED: {
        openGlobalModal(message);
        break;
      }
      case LS.USR_PSW_DISABLED: {
        openGlobalModal(message);
        break;
      }
      case LS.FINGERPRINT_LOGIN_TYPE_ERROR: {
        openGlobalModal(message);
        break;
      }
      case LS.FINGERPRINT_LOGIN_CONFIGURATED_ERROR: {
        openGlobalModal(message);
        break;
      }
      case LS.FINGERPRINT_INVALID: {
        openGlobalModal(message);
        break;
      }
      default:
        break;
    }
  };

  const handleChangePasswordClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFirstPassword(false);
    setChangePassword(false);
  };

  if (changePassword || firstPassword) {
    return (
      <ChangePasswordViewModel
        isFirstPassword={firstPassword}
        loginInputs={inputs}
        onPasswordChange={loginDialog}
        onClose={handleChangePasswordClose}
      />
    );
  }

  const { openGlobalModal } = props;

  return showUserDefinedLogin ? (
    <LoginSelectionView
      loginMessage={loginMessage}
      error={error}
      loading={loading}
      setLoading={setLoading}
      setLoginMessage={setLoginMessage}
      onInputChange={handleInputChange}
      setError={setError}
      handleLoginNumber={handleLoginNumber}
      initSession={props.initSession}
    />
  ) : (
    <LoginView
      inputs={inputs}
      onInputChange={handleInputChange}
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      secondLogin={secondLogin}
      setSecondLogin={setSecondLogin}
      setAddFingerLogin={setAddFingerLogin}
      addFingerLogin={addFingerLogin}
      tipoLoged={tipoLoged}
      loginDialog={loginDialog}
      handleLoginNumber={handleLoginNumber}
      openGlobalModal={openGlobalModal}
      loginMessage={loginMessage}
    />
  );
};

const mapStateToProps = ({ modalMessage, modalMessageCurrentIndex }) => ({
  buttonsData:
    modalMessage && modalMessage[modalMessageCurrentIndex] && modalMessage[modalMessageCurrentIndex].buttons
      ? [...modalMessage[modalMessageCurrentIndex].buttons]
      : [],
  escapePressed: modalMessage && modalMessage[modalMessageCurrentIndex] && modalMessage[modalMessageCurrentIndex].escapePressed,
});

export default connect(mapStateToProps, storeActions)(withRouter(withSession(LoginViewModel)));
