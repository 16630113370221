import { css } from 'aphrodite';
import React from 'react';
import DatePicker from 'react-datepicker';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { useGlobalStyles } from './../../../../styles/GlobalStylesContext';
import Field from './Field';

const DateInput = ({ date, onDateChange, disabled }) => {
  const intl = useIntl();
  const globalStyles = useGlobalStyles();

  const placeholderDate = intl.formatMessage({
    id: 'date',
    defaultMessage: 'Fecha',
  });

  return (
    <Field labelText={placeholderDate} htmlFor="date">
      <DatePicker
        id="date"
        required
        placeholderText="DD/MM/AAAA"
        customInput={<NumberFormat format="##/##/####" placeholder="DD/MM/AAAA" mask={['D', 'D', 'M', 'M', 'A', 'A', 'A', 'A']} />}
        dateFormat="dd/MM/yyyy"
        onChange={onDateChange}
        onFocus={(e) => e.target.select()}
        selected={date}
        disabled={!disabled}
        className={css(globalStyles.input, globalStyles.dateInput)}
      />
    </Field>
  );
};

export default DateInput;
