/* eslint-disable no-undef */
import { PulseLoader } from 'halogenium';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { addUserDetailsAsync } from '../../../hooks/addUserDetailsAsync.hook';
import {
  addUserAsync,
  validateUserAsync,
  validateUserMachineAsync,
  validateUserRegisterAsync,
  updateUserAsync,
  getBlockingMotives,
} from '../../../../../api/securityManager';
import { getParametros } from '../../../../../api/productionManager';
import {
  CDI_DOC,
  CHECKED,
  CUIL_DOC,
  DEVELOPMENT_VALUE,
  EMPTY_STRING,
  ENGLISH_VALUE,
  INITIALS_ERROR,
  INITIALSLENGTH_ERROR,
  LOGIN_BOTH_INDEX,
  LOGIN_FINGERPRINT_INDEX,
  LOGIN_KEY_INDEX,
  MACHINE_MODAL,
  NOT_CHECKED,
  NO_GROUP_ERROR,
  INMEDIATOSUPERIOR_INVALID,
  NO_HELPER_SELECTED,
  NO_MODAL_OPEN,
  PRODUCTION_VALUE,
  REGISTER_MODAL,
  SPANISH_VALUE,
  TYPE_GROUP,
  TYPE_USER,
  USERNAME_ERROR,
} from '../../../models/constants';
import { GroupsAsTreeMenu } from '../../../models/groupsAsTreeMenu';
import { UserBasicInfo } from '../../../models/userBasicInfo';
import { SelectData } from '../../select/Select';
import { UserInfoModalView } from './UserInfoModalView';
import { func } from 'prop-types';
import { store } from '../../../../../store';

export interface UserInfoModalViewModelProps {
  onModalChange: (modalOpen: string) => void;
  isAddUser: boolean;
  selectedUser: UserBasicInfo;
  selectedGroup: GroupsAsTreeMenu;
  securityGroups: GroupsAsTreeMenu[];
  onHelperChange: (helper: string) => void;
  helperGroup: string;
  helperUsername: string;
  helperInmediatoSuperior: string;
  helperName: string;
  helperMachineNumber: number;
  helperBranch: number;
  changesWarning: boolean;
  hasUserPermissions: boolean;
  onHasModifiedChange: (hasModified: boolean) => void;
  hasModifiedInformation: boolean;
  onRefresh: () => void;
  setChangesWarning: (isShowing: boolean) => void;
  onChangesWarningChange: (isShowing: boolean, undoChanges: boolean) => void;
  handleKeyOnNumber: (e: any) => void;
  newUserInfo: UserBasicInfo;
  setNewUserInfo: (userInfo: UserBasicInfo) => void;
}

const BLOCKING_MOTIVE_S: string = 'S';
const ADD_BLOCKING_MOTIVE_INDEX: number = 2;
const BLOCKING_MOTIVE_NAME_INDEX: number = 0;
const BLOCKING_MOTIVE_VALUE: number = 1;

export function UserInfoModalViewModel({
  onModalChange,
  isAddUser,
  selectedUser,
  selectedGroup,
  securityGroups,
  onHelperChange,
  helperBranch,
  helperGroup,
  helperMachineNumber,
  helperUsername,
  helperInmediatoSuperior,
  helperName,
  changesWarning,
  hasUserPermissions,
  onHasModifiedChange,
  onRefresh,
  setChangesWarning,
  onChangesWarningChange,
  hasModifiedInformation,
  newUserInfo,
  setNewUserInfo,
}: UserInfoModalViewModelProps): JSX.Element | null {
  const [confirmationPassword, setConfirmationPassword] = useState<string>(EMPTY_STRING);
  const [checkedLDAP, setCheckedLDAP] = useState<boolean>(false);
  const [modalLDAP, setModalLDAP] = useState<any>({});
  const [checkedOneTime, setCheckedOneTime] = useState<boolean>(false);
  const [showInsertRequired, setShowInsertRequired] = useState<boolean>(false);
  const [showPasswordWarning, setShowPasswordWarning] = useState<boolean>(false);
  const [modalWarningOpen, setModalWarningOpen] = useState<string>(NO_MODAL_OPEN);
  const [isLoadingWarnings, setIsLoadingWarnings] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>(NO_MODAL_OPEN);
  const [blockingMotives, setBlockingMotives] = useState<SelectData[]>([]);
  const [showBlockingMotives, setShowBlockingMotives] = useState<boolean>(false);
  const [changedDisabledToChecked, setChangedDisabledToChecked] = useState<boolean>(false);
  const [currentOpenModal, setCurrentOpenModal] = useState<string>(EMPTY_STRING);
  const [currentModalStep, setCurrentModalStep] = useState<number>(0);
  const [parameters, setParameters] = useState<string[][]>([]);
  const intl = useIntl();
  const docTypeOptions: SelectData[] = [
    {
      label: CDI_DOC,
      value: CDI_DOC,
    },
    {
      label: CUIL_DOC,
      value: CUIL_DOC,
    },
  ];
  const contextOptions: SelectData[] = [
    {
      label: intl.formatMessage({ id: 'development' }),
      value: DEVELOPMENT_VALUE,
    },
    {
      label: intl.formatMessage({ id: 'production' }),
      value: PRODUCTION_VALUE,
    },
  ];

  const languageOptions: SelectData[] = [
    {
      label: intl.formatMessage({ id: 'spanish' }),
      value: SPANISH_VALUE,
    },
    {
      label: intl.formatMessage({ id: 'english' }),
      value: ENGLISH_VALUE,
    },
  ];

  const loginTypeOptionsSecond: SelectData[] = [
    {
      label: intl.formatMessage({ id: 'key' }),
      value: LOGIN_KEY_INDEX,
    },
    {
      label: intl.formatMessage({ id: 'fingerprint' }),
      value: LOGIN_FINGERPRINT_INDEX,
    },
    {
      label: intl.formatMessage({ id: 'both' }),
      value: LOGIN_BOTH_INDEX,
    },
  ];

  const loginTypeOptionsNoSecond: SelectData[] = [
    {
      label: intl.formatMessage({ id: 'key' }),
      value: LOGIN_KEY_INDEX,
    },
  ];

  function loadUserInfo(): void {
    let userInfo: UserBasicInfo;
    if (isAddUser) {
      userInfo = {
        name: EMPTY_STRING,
        group: selectedGroup.group,
        initials: EMPTY_STRING,
        username: EMPTY_STRING,
        type: TYPE_USER,
        language: SPANISH_VALUE,
        documentType: CDI_DOC,
        mantdes: PRODUCTION_VALUE,
        loginType: LOGIN_KEY_INDEX,
        secondLogin: NOT_CHECKED,
        multiplesLogins: NOT_CHECKED,
        disabled: NOT_CHECKED,
      };

      setNewUserInfo(userInfo);
    } else {
      fillUserInfo();
    }
  }

  function getFechaProceso(): string {
    const state = store.getState();
    return state.sessionInfo.processDate;
  }

  function mapBlockingMotiveOptions(motives: string[][]): void {
    motives = motives.filter((motive) => {
      return motive[ADD_BLOCKING_MOTIVE_INDEX] === BLOCKING_MOTIVE_S;
    });
    const mappedMotives: SelectData[] = motives.map((motive) => {
      return {
        label: motive[BLOCKING_MOTIVE_NAME_INDEX],
        value: motive[BLOCKING_MOTIVE_VALUE],
      };
    });
    setShowBlockingMotives(mappedMotives.length !== 0);
    setBlockingMotives(mappedMotives);
  }

  async function getMotivesBlockingAsync(): Promise<void> {
    setIsLoading(true);
    const motives: string[][] = await getBlockingMotives();
    setIsLoading(false);
    mapBlockingMotiveOptions(motives);
  }

  async function fillUserInfo(): Promise<UserBasicInfo> {
    let userInfo: UserBasicInfo = selectedUser;
    userInfo = await addUserDetailsAsync(userInfo);
    setNewUserInfo(userInfo);
    setCheckedLDAP(userInfo.loginconad == 1);
    setConfirmationPassword(userInfo.password);
    return userInfo;
  }
  async function onCancelUserClickAsync(): void {
    onModalChange(NO_MODAL_OPEN);
    onHasModifiedChange(false);
  }

  async function onAddUserClickAsync(): void {
    if (!showInsertRequired && !showPasswordWarning) {
      if (isAddUser) {
        const noInitialsInput: boolean = newUserInfo.initials === EMPTY_STRING;
        noInitialsInput && fillInitialsInput();
        fillCreationDate();
        const warning: boolean = checkWarnings();
        if (!warning) {
          const response = await addUserAsync(newUserInfo);
          if (!response?.data) {
            setIsLoadingWarnings(true);
            onModalChange(NO_MODAL_OPEN);
            onHasModifiedChange(false);
            onRefresh();
          } else {
            setIsError(response.data);
          }
        }
      } else {
        const existsGroupInserted: boolean = existsGroup(newUserInfo.group);
        if (!existsGroupInserted) {
          setIsError(NO_GROUP_ERROR);
        } else {
          performNextSaveState(0);
        }
      }
    }
  }

  async function performNextSaveState(startingModalStep: number): Promise<void> {
    let modalStep = startingModalStep;
    if (modalStep === 0) {
      const user: string = await validateMachineAsync();
      if (user !== EMPTY_STRING) {
        setCurrentModalStep(modalStep + 1);
        setModalWarningOpen(user);
      } else {
        modalStep++;
      }
    }

    if (modalStep === 1) {
      const user: string = await validateRegisterAsync();
      if (user !== EMPTY_STRING) {
        setCurrentModalStep(modalStep + 1);
        setModalWarningOpen(user);
      } else {
        modalStep++;
      }
    }

    if (modalStep === 2 || isAddUser) {
      let didSave = false;
      if (isAddUser) {
        didSave = await addUser();
      } else {
        didSave = await updateUser();
      }

      if (didSave) {
        setIsLoadingWarnings(true);
        onModalChange(NO_MODAL_OPEN);
        onHasModifiedChange(false);
        onRefresh();
      }
    }
  }

  async function validateMachineAsync(): Promise<string> {
    const user: string = await validateUserMachineAsync(newUserInfo.initials, newUserInfo.machineNumber, newUserInfo.branchNumber);

    return user;
  }

  async function validateRegisterAsync(): Promise<string> {
    const user: string = await validateUserRegisterAsync(newUserInfo.initials, newUserInfo.registerNumber, newUserInfo.branchNumber);

    return user;
  }

  function onModalWarningChange(accept: boolean): void {
    setModalWarningOpen(NO_MODAL_OPEN);
    if (accept) {
      performNextSaveState(currentModalStep);
    }
  }

  async function addUser(): Promise<boolean> {
    if (!showInsertRequired && !showPasswordWarning) {
      fillInitialsInput();
      fillCreationDate();
      const warning: boolean = checkWarnings();
      !warning && (await addUserAsync(newUserInfo));
      setIsLoading(false);
      return !warning;
    }
    return false;
  }

  async function updateUser(): Promise<boolean> {
    const warning: boolean = checkWarnings();

    if (!warning) {
      let res = await updateUserAsync(newUserInfo);
      if (res && res.response && res.response.data && res.response.data.mensaje) {
        setIsError(res.response.data.mensaje);
        setIsLoading(false);
        return false;
      }
    }
    setIsLoading(false);
    return !warning;
  }

  function fillInitialsInput(): void {
    const hasInitialsInput: boolean = newUserInfo.initials !== EMPTY_STRING;
    if (!hasInitialsInput) {
      setNewUserInfo((currentUser) => ({ ...currentUser, initials: newUserInfo.username }));
    }
  }

  function fillCreationDate(): void {
    setNewUserInfo((currentUser) => ({ ...currentUser, passwordCreationDate: moment(getFechaProceso()).format('yyyy-MM-DD') }));
  }

  function checkWarnings(): boolean {
    const existsUsername: boolean = !newUserInfo.username ? true : isAddUser ? existsUserUsername(newUserInfo.username) : false;
    const existsInitials: boolean = isAddUser ? existsUserInitials(newUserInfo.initials) : false;
    const initialsLengthError: boolean = newUserInfo.initials.length > 8;
    const notExistsGroup: boolean = !existsGroup(newUserInfo.group);
    const notExistsInmediatoSuperior: boolean = newUserInfo.inmediatosuperior ? !existsUserUsername(newUserInfo.inmediatosuperior) : false;

    if (existsUsername || existsInitials || notExistsGroup || notExistsInmediatoSuperior) {
      existsUsername && setIsError(USERNAME_ERROR);
      existsInitials && setIsError(INITIALS_ERROR);
      notExistsGroup && setIsError(NO_GROUP_ERROR);
      notExistsInmediatoSuperior && setIsError(INMEDIATOSUPERIOR_INVALID);
      initialsLengthError && setIsError(INITIALSLENGTH_ERROR);

      return true;
    } else {
      return false;
    }
  }

  function existsGroup(groupName: string): boolean {
    let groupFound: boolean = checkGroupName(groupName, securityGroups);

    return groupFound;
  }

  function checkGroupName(groupName: string, groups: GroupsAsTreeMenu[]): boolean {
    let found: boolean = false;

    groups.forEach((group) => {
      if (group.type === TYPE_GROUP && group.name === groupName) {
        found = true;
      }
      if (!found) {
        found = checkGroupName(groupName, group.subGroups);
      }
    });

    return found;
  }

  function existsUserInitials(initials: string): boolean {
    const found: boolean = checkGroupsInitials(securityGroups, initials);

    return found;
  }

  function checkGroupsInitials(groups: GroupsAsTreeMenu[], initials: string): boolean {
    let foundUsers: boolean = false;
    let foundGroups: boolean = false;
    let found: boolean = false;

    groups.forEach((group) => {
      if (group.users.length !== 0) {
        foundUsers = checkUsersInitials(group.users, initials);
      }
      if (!foundUsers && group.subGroups.length !== 0) {
        foundGroups = checkGroupsInitials(group.subGroups, initials);
      }
      if (foundGroups || foundUsers) {
        found = true;
      }
    });

    return found;
  }

  function checkUsersInitials(users: UserBasicInfo[], initials: string): boolean {
    let found: boolean = false;

    users.forEach((user) => {
      if (user.initials?.toUpperCase() === initials.toUpperCase()) {
        found = true;
      }
    });

    return found;
  }

  function existsUserUsername(username: string): boolean {
    const found: boolean = checkGroupsUsername(securityGroups, username);

    return found;
  }

  function checkGroupsUsername(groups: GroupsAsTreeMenu[], username: string): boolean {
    let foundUsers: boolean = false;
    let foundGroups: boolean = false;
    let found: boolean = false;

    groups.forEach((group) => {
      if (group.users.length !== 0) {
        foundUsers = checkUsersUsername(group.users, username);
      }
      if (!foundUsers && group.subGroups.length !== 0) {
        foundGroups = checkGroupsUsername(group.subGroups, username);
      }
      if (foundGroups || foundUsers) {
        found = true;
      }
    });

    return found;
  }

  function checkUsersUsername(users: UserBasicInfo[], username: string): boolean {
    let found: boolean = false;

    users.forEach((user) => {
      if (user.username === username) {
        found = true;
      }
    });

    return found;
  }

  function updateHasModifiedInformation(): void {
    if (!hasModifiedInformation) {
      onHasModifiedChange(true);
    }
  }

  function onValueDateChange(event: React.ChangeEvent<HTMLInputElement>): void {
    updateHasModifiedInformation();
    event.persist();
    const dateValParsed = moment(event.target.value).tz('GMT').toISOString();
    setNewUserInfo((currentUser) => ({ ...currentUser, [event.target.name]: dateValParsed }));
  }

  function onInitialsChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event.target.value.length <= 8) {
      updateHasModifiedInformation();
      event.persist();
      setNewUserInfo((currentUser) => ({ ...currentUser, [event.target.name]: event.target.value }));
    }
  }

  function onValueStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event.target.value.length <= parameters.pwdlargomax) {
      updateHasModifiedInformation();
      event.persist();
      setNewUserInfo((currentUser) => ({ ...currentUser, [event.target.name]: event.target.value }));
    }
  }

  function onValueNumberChange(event: React.ChangeEvent<HTMLInputElement>): void {
    updateHasModifiedInformation();
    event.persist();
    setNewUserInfo((currentUser) => ({ ...currentUser, [event.target.name]: event.target.valueAsNumber }));
  }

  function onValueCheckBoxChange(event: React.ChangeEvent<HTMLInputElement>): void {
    updateHasModifiedInformation();
    const value: number = newUserInfo[event.target.name] === NOT_CHECKED ? CHECKED : NOT_CHECKED;
    event.persist();
    setNewUserInfo((currentUser) => ({ ...currentUser, [event.target.name]: value }));
  }

  function onLanguageChange(language: string): void {
    updateHasModifiedInformation();
    setNewUserInfo((currentUser) => ({ ...currentUser, language: language }));
  }

  function onBlockingMotiveChange(motive: number): void {
    updateHasModifiedInformation();
    setNewUserInfo((currentUser) => ({ ...currentUser, blockingMotive: motive }));
  }

  function onLoginTypeChange(loginType: number): void {
    updateHasModifiedInformation();
    setNewUserInfo((currentUser) => ({ ...currentUser, loginType: loginType }));
  }

  function onUserChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event.target.value.length <= parameters.clvlargomax) {
      updateHasModifiedInformation();
      event.persist();
      setNewUserInfo((currentUser) => ({ ...currentUser, username: event.target.value }));
    }
    if ((newUserInfo.initials === EMPTY_STRING || event.target.value.includes(newUserInfo.initials)) && newUserInfo.initials.length < 8) {
      setNewUserInfo((currentUser) => ({ ...currentUser, initials: event.target.value }));
    }
    const requiredInfo: boolean = newUserInfo.name !== EMPTY_STRING && event.target.value !== EMPTY_STRING;
    setShowInsertRequired(!requiredInfo);
  }

  function onInmediatoSuperiorChange(event: React.ChangeEvent<HTMLInputElement>): void {
    updateHasModifiedInformation();
    setNewUserInfo((currentUser) => ({ ...currentUser, inmediatosuperior: event.target.value }));
    event.persist();
  }

  function onNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    updateHasModifiedInformation();
    setNewUserInfo((currentUser) => ({ ...currentUser, name: event.target.value }));
    event.persist();
    const requiredInfo: boolean = event.target.value !== EMPTY_STRING && newUserInfo.username !== EMPTY_STRING;
    setShowInsertRequired(!requiredInfo);
  }

  function onGroupInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    updateHasModifiedInformation();
    event.persist();
    setNewUserInfo((currentUser) => ({ ...currentUser, group: event.target.value.toUpperCase() }));
  }

  function onPasswordInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event.target.value.length <= parameters.pwdlargomax) {
      updateHasModifiedInformation();
      event.persist();
      setNewUserInfo((currentUser) => ({ ...currentUser, password: event.target.value }));
      if (event.target.value !== EMPTY_STRING) {
        const passEquals: boolean = event.target.value === confirmationPassword;
        setShowPasswordWarning(!passEquals);
      } else {
        delete newUserInfo.password;
        checkPasswordWarning();
      }
    }
  }

  function checkPasswordWarning(): void {
    if (confirmationPassword !== EMPTY_STRING || (newUserInfo.password && newUserInfo.password !== EMPTY_STRING)) {
      setShowPasswordWarning(true);
    } else {
      setShowPasswordWarning(false);
    }
  }

  function onPasswordConfirmationInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event.target.value.length <= parameters.pwdlargomax) {
      updateHasModifiedInformation();
      setConfirmationPassword(event.target.value);
      event.persist();
      const passEquals: boolean = newUserInfo.password === event.target.value;
      setShowPasswordWarning(!passEquals);
    }
  }

  function onLDAPChange(event: React.ChangeEvent<HTMLInputElement>): void {
    updateHasModifiedInformation();
    setCheckedLDAP((current) => {
      if (current) {
        setNewUserInfo((currentUser) => ({ ...currentUser, loginconad: 0 }));
      } else {
        setNewUserInfo((currentUser) => ({ ...currentUser, loginconad: 1 }));
      }
      return !current;
    });
  }

  function onEnableCheck(event: React.ChangeEvent<HTMLInputElement>): void {
    updateHasModifiedInformation();
    const value: number = newUserInfo.iniexphabilitado === NOT_CHECKED ? CHECKED : NOT_CHECKED;
    setNewUserInfo((currentUser) => ({ ...currentUser, iniexphabilitado: value }));
  }

  function onDisabledPasswordChange(event: React.ChangeEvent<HTMLInputElement>): void {
    updateHasModifiedInformation();
    const value: number = newUserInfo.disabled === NOT_CHECKED ? CHECKED : NOT_CHECKED;
    if (newUserInfo.disabled === NOT_CHECKED) {
      setChangedDisabledToChecked(true);
      setNewUserInfo((currentUser) => ({ ...currentUser, blockingDate: moment(getFechaProceso()).format() }));
    } else {
      setChangedDisabledToChecked(false);
      setNewUserInfo((currentUser) => ({ ...currentUser, blockingDate: EMPTY_STRING }));
    }
    setNewUserInfo((currentUser) => ({ ...currentUser, disabled: value }));
  }

  function onOneTimeInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    updateHasModifiedInformation();
    setCheckedOneTime(!checkedOneTime);
    if (!checkedOneTime) {
      const newDate = moment(getFechaProceso()).subtract(1, 'days').tz('GMT').toISOString();
      setNewUserInfo((currentUser) => ({ ...currentUser, passwordExpiration: newDate }));
    } else {
      setNewUserInfo((currentUser) => ({ ...currentUser, passwordExpiration: EMPTY_STRING }));
    }
  }

  function onDocTypeChange(docType: string): void {
    updateHasModifiedInformation();
    setNewUserInfo((currentUser) => ({ ...currentUser, documentType: docType }));
  }

  function onContextChange(context: string): void {
    updateHasModifiedInformation();
    setNewUserInfo((currentUser) => ({ ...currentUser, mantdes: context }));
  }

  async function onAcceptLDAP(user: string, password: string): void {
    updateHasModifiedInformation();
    let res = await validateUserAsync(newUserInfo.ldapuser, password, user);
    setModalLDAP({ showDialog: true, message: res?.mensaje });
  }

  function addHelper(type: string, newData: string | number): void {
    setNewUserInfo((currentUser) => ({ ...currentUser, [type]: newData }));
  }

  function handleKeyOnNumber(e: any): void {
    if (['-', '+', 'e', '.'].includes(e.key)) {
      e.preventDefault();
    }
  }

  async function getParametersAsync(): Promise<void> {
    const res: string[][] = await getParametros();

    if (res) {
      setParameters(res);
    }
  }

  useEffect(() => {
    loadUserInfo();
    getMotivesBlockingAsync();
    getParametersAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  useEffect(() => {
    if (helperGroup !== EMPTY_STRING && currentOpenModal && newUserInfo[currentOpenModal] !== helperGroup) {
      addHelper('group', helperGroup);
      updateHasModifiedInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helperGroup]);

  useEffect(() => {
    if (helperUsername !== EMPTY_STRING && currentOpenModal && newUserInfo[currentOpenModal] !== helperUsername) {
      addHelper('username', helperUsername);
      addHelper('name', helperName);
      updateHasModifiedInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helperUsername, helperName]);

  useEffect(() => {
    if (helperInmediatoSuperior !== EMPTY_STRING && currentOpenModal && newUserInfo[currentOpenModal] !== helperInmediatoSuperior) {
      addHelper('inmediatosuperior', helperInmediatoSuperior);
      updateHasModifiedInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helperInmediatoSuperior]);

  useEffect(() => {
    helperBranch !== NO_HELPER_SELECTED && addHelper('branchNumber', helperBranch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helperBranch]);

  useEffect(() => {
    if (helperMachineNumber !== NO_HELPER_SELECTED && currentOpenModal && newUserInfo[currentOpenModal] !== helperGroup) {
      addHelper('machineNumber', helperMachineNumber);
      updateHasModifiedInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helperMachineNumber]);

  return (
    <div>
      {isLoading ? (
        <div className="absolute w-full mt-6 p-2 px-6 flex justify-center items-center h-full" style={{ left: '100px' }}>
          <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
        </div>
      ) : (
        <UserInfoModalView
          hasUserPermissions={hasUserPermissions}
          changesWarning={changesWarning}
          onModalChange={onModalChange}
          onAddUserClick={onAddUserClickAsync}
          onCancelUserClick={onCancelUserClickAsync}
          selectedUser={newUserInfo}
          docTypeOptions={docTypeOptions}
          loginTypeOptionsSecond={loginTypeOptionsSecond}
          loginTypeOptionsNoSecond={loginTypeOptionsNoSecond}
          contextOptions={contextOptions}
          languageOptions={languageOptions}
          onHelperChange={onHelperChange}
          setCurrentOpenModal={setCurrentOpenModal}
          isError={isError}
          showInsertRequired={showInsertRequired}
          onContextChange={onContextChange}
          onDisabledPasswordChange={onDisabledPasswordChange}
          onDocTypeChange={onDocTypeChange}
          onEnableCheck={onEnableCheck}
          onGroupInputChange={onGroupInputChange}
          onLDAPChange={onLDAPChange}
          onLanguageChange={onLanguageChange}
          onLoginTypeChange={onLoginTypeChange}
          onNameChange={onNameChange}
          onOneTimeInputChange={onOneTimeInputChange}
          confirmationPassword={confirmationPassword}
          onPasswordConfirmationInputChange={onPasswordConfirmationInputChange}
          onPasswordInputChange={onPasswordInputChange}
          onUserChange={onUserChange}
          onInmediatoSuperiorChange={onInmediatoSuperiorChange}
          showPasswordWarning={showPasswordWarning}
          checkedLDAP={checkedLDAP}
          onValueStringChange={onValueStringChange}
          onInitialsChange={onInitialsChange}
          onValueDateChange={onValueDateChange}
          onValueNumberChange={onValueNumberChange}
          onValueCheckBoxChange={onValueCheckBoxChange}
          onAcceptLDAP={onAcceptLDAP}
          modalWarningOpen={modalWarningOpen}
          onModalWarningChange={onModalWarningChange}
          isLoadingWarnings={isLoadingWarnings}
          isAddUser={isAddUser}
          blockingMotiveOptions={blockingMotives}
          showBlockingMotives={showBlockingMotives}
          onBlockingMotiveChange={onBlockingMotiveChange}
          changedDisabledToChecked={changedDisabledToChecked}
          onRefresh={onRefresh}
          setChangesWarning={setChangesWarning}
          onChangesWarningChange={onChangesWarningChange}
          handleKeyOnNumber={handleKeyOnNumber}
          modalLDAP={modalLDAP}
          setModalLDAP={setModalLDAP}
        />
      )}
    </div>
  );
}
