import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Group } from '../../../models/group';
import { GroupInfoModalView } from './GroupInfoModalView';

import { EMPTY_STRING, GROUP_HELPER, NO_MODAL_OPEN } from '../../../models/constants';
import {
  createGroupAsync,
  getGroupByNameAsync,
  removeBranchesGroups,
  removeCatalogsGroups,
  saveOrUpdateRestrictions,
  saveUpdateOrDeletePermissionsGroups,
  setBranchesGroups,
  setCatalogsGroups,
  setNavigationsGroups,
  updateGroupAsync,
  updateNavigationsGroups,
  saveUpdateOrDeleteListRestricciones
} from '../../../../../api/securityManager';
import { PulseLoader } from 'halogenium';
import { GroupHelper } from '../SecurityToolsModalViewModel';
import { GroupsAsTreeMenu } from '../../../models/groupsAsTreeMenu';
import { MENU_TYPE } from '../restrictions/hooks/useMenu.hook';
import { NAVIGATIONS_TYPE } from '../restrictions/hooks/useNavigations.hook';
import { BRANCHES_TYPE } from '../restrictions/hooks/useBranches.hook';
import { CATALOGS_TYPE } from '../restrictions/hooks/useCatalogs.hook';
import { OPERATIONS_TYPE } from '../restrictions/hooks/useOperations.hook';
import { REPORTS_TYPE } from '../restrictions/hooks/useReports.hook';
import { RestrictionObject } from '../../../models/restrictionObject';
import { PERMISSIONS_TYPE } from '../restrictions/hooks/usePermissions.hook';

export interface AddGroupModalViewProps {
  selectedGroup: GroupsAsTreeMenu | undefined;
  onModalChange: (modalOpen: string) => void;
  helperGroup: string;
  onHelperChange: (helper: string) => void;
  isEditing?: boolean;
  securityGroups: GroupHelper[];
  changesWarning: boolean;
  hasGroupPermissions: boolean;
  onHasModifiedChange: (hasModified: boolean) => void;
  hasModifiedInformation: boolean;
  setChangesWarning: (isShowing: boolean) => void;
  onRefresh: () => void;
  onChangesWarningChange: (isShowing: boolean, undoChanges: boolean) => void;
  setIsLoadingGroup: (isLoading: boolean) => void;

}

export interface GroupErrors {
  [key: string]: string;
}

export function GroupInfoModalViewModel({
  selectedGroup,
  onModalChange,
  onHelperChange,
  helperGroup,
  isEditing,
  securityGroups,
  changesWarning,
  hasGroupPermissions,
  onHasModifiedChange,
  hasModifiedInformation,
  setChangesWarning,
  onRefresh,
  onChangesWarningChange,
  setIsLoadingGroup,
}: AddGroupModalViewProps) {
  const [group, setGroup] = useState<Group>(getInitialGroupState());
  const [errors, setErrors] = useState<GroupErrors>({});
  const [isLoading, setIsLoading] = useState<boolean>(!!isEditing);
  const [currentOpenModal, setCurrentOpenModal] = useState<string>(EMPTY_STRING);
  const [restrictionsChanged, setRestrictionsChanged] = useState<{ [id: string]: RestrictionObject }>({});
  const [restrictionsHasErrors, setRestrictionsHasErrors] = useState<boolean>(false);
  const intl = useIntl();

  function updateHasModifiedInformation(): void {
    if (!hasModifiedInformation) {
      onHasModifiedChange(true);
    }
  }


  function onRestrictionsChangedAdd(restriction: RestrictionObject[]): void {
    restriction.forEach((r) => {
      setRestrictionsChanged((current) => ({ ...current, [r.id]: r }));
    });
  }

  function onRestrictionsChangedRemove(restriction?: RestrictionObject[]): void {
    if (restriction !== undefined && restriction.length > 0) {
      setRestrictionsChanged((current) => {
        const copyRestrictions = { ...current };
        restriction.forEach((r) => {
          delete copyRestrictions[r.id];
        });
        return copyRestrictions;
      });
    }
  }

  function getInitialGroupState(): Group {
    if (isEditing) {
      return {
        baseGroup: selectedGroup?.baseGroup,
        description: selectedGroup?.description,
        group: selectedGroup?.group,
        reason: selectedGroup?.reason,
      };
    }

    return {
      baseGroup: selectedGroup?.name,
    };
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    updateHasModifiedInformation();
    if (event?.target) {
      event.persist();
      setErrors({});
      setGroup((currentGroup) => ({ ...currentGroup, [event.target.name]: event.target.value }));
    }
  }

  function openModalHelper(currentProperty: string): void {
    setCurrentOpenModal(currentProperty);
    onHelperChange(GROUP_HELPER);
  }

  async function onAcceptAsync(): Promise<void> {
    let ok = true;
    try {
      if (isEditing) {
        ok = !!(await updateGroup());
      } else {
        ok = !!(await createGroup());
      }
      if (ok && !restrictionsHasErrors) {
        updateOrSetRestrictionsAsync();
        cleanRestrictionsChanged();
        onModalChange(NO_MODAL_OPEN);
        setChangesWarning(false);

      }
    } catch (error) {
      console.error('Error en onAcceptAsync:', error);
    }
  }

  function cleanRestrictionsChanged(): void {
    Object.keys(restrictionsChanged).forEach((key) => {
      if (!isNavigations(key) && !isBranch(key)) {
        delete restrictionsChanged[key];
      }
    });
  }

  async function updateOrSetRestrictionsAsync(): Promise<void> {
    Promise.all([
      updateMOQ(),
      updateNavigations(),
      updatePermissions(),
      updateBranches(),
      updateCatalogs()
    ]).then(() => {
      setIsLoadingGroup(false);
      if (onRefresh) {
        onRefresh();
      }
    });
  }

  async function updatePermissions(): Promise<void> {
    let restrictionsPermissions: { [id: string]: RestrictionObject } = {};

    Object.keys(restrictionsChanged).forEach((key) => {
      if (isPermissions(key)) {
        restrictionsPermissions = { ...restrictionsPermissions, [key]: restrictionsChanged[key] };
      }
    });
    try {
      if (Object.keys(restrictionsPermissions).length !== 0) {
        await saveUpdateOrDeletePermissionsGroups(restrictionsPermissions, group?.group, group?.reason);
      }
    } catch { }
  }

  async function updateMOQ(): Promise<void> {
    let restrictionsMOQ: { [id: string]: RestrictionObject } = {};
    Object.keys(restrictionsChanged).forEach((key) => {
      if (isMOQ(key)) {
        restrictionsMOQ = { ...restrictionsMOQ, [key]: restrictionsChanged[key] };
      }
    });
    try {
      if (Object.keys(restrictionsMOQ).length !== 0) {
        await saveUpdateOrDeleteListRestricciones(restrictionsMOQ, group?.group, group?.reason);
      }
    } catch { }
  }


  async function updateBranches(): Promise<void> {
    const keyList = Object.keys(restrictionsChanged);
    for (let idx = 0; idx < keyList.length; idx++) {
      const key = keyList[idx];
      if (isBranch(key)) {
        if (restrictionsChanged[key].allows) {
          try {
            await setBranchesGroups({ [key]: restrictionsChanged[key] }, group?.group, group?.reason);
            delete restrictionsChanged[key];
          } catch { }
        } else {
          try {
            await removeBranchesGroups({ [key]: restrictionsChanged[key] }, group?.group, group?.reason);
            delete restrictionsChanged[key];
          } catch { }
        }
      }
    }
  }

  async function updateCatalogs(): Promise<void> {
    let restrictionsCatalogsAdd: { [id: string]: RestrictionObject } = {};
    let restrictionsCatalogsRemove: { [id: string]: RestrictionObject } = {};

    Object.keys(restrictionsChanged).forEach((key) => {
      if (isCatalogAdding(key)) {
        restrictionsCatalogsAdd = { ...restrictionsCatalogsAdd, [key]: restrictionsChanged[key] };
      } else if (isCatalogRemoving(key)) {
        restrictionsCatalogsRemove = { ...restrictionsCatalogsRemove, [key]: restrictionsChanged[key] };
      }
    });
    try {
      Object.keys(restrictionsCatalogsRemove).length !== 0 && (await removeCatalogsGroups(restrictionsCatalogsRemove, group?.group));
      Object.keys(restrictionsCatalogsAdd).length !== 0 && (await setCatalogsGroups(restrictionsCatalogsAdd, group?.group));
    } catch { }
  }

  async function updateNavigations(): Promise<void> {
    const keyList = Object.keys(restrictionsChanged);
    for (let idx = 0; idx < keyList.length; idx++) {
      const key = keyList[idx];
      if (isNavigations(key)) {
        try {
          await setOrUpdateNavigationsAsync({ [key]: restrictionsChanged[key] });
          delete restrictionsChanged[key];
        } catch { }
      }
    }
  }

  function isCatalogAdding(key: string): boolean {
    return restrictionsChanged[key].type === CATALOGS_TYPE && restrictionsChanged[key].allows === 1;
  }

  function isCatalogRemoving(key: string): boolean {
    return restrictionsChanged[key].type === CATALOGS_TYPE && restrictionsChanged[key].allows === 0;
  }

  function isBranch(key: string): boolean {
    return restrictionsChanged[key].type === BRANCHES_TYPE;
  }

  function isNavigations(key: string): boolean {
    return restrictionsChanged[key].type === NAVIGATIONS_TYPE;
  }

  function isPermissions(key: string): boolean {
    return restrictionsChanged[key].type === PERMISSIONS_TYPE;
  }

  function isMOQ(key: string): boolean {
    return (
      restrictionsChanged[key].type === MENU_TYPE ||
      restrictionsChanged[key].type === OPERATIONS_TYPE ||
      restrictionsChanged[key].type === REPORTS_TYPE
    );
  }

  async function setOrUpdateNavigationsAsync(restrictionsNav: { [id: string]: RestrictionObject }): Promise<void> {
    await setNavigationsGroups(restrictionsNav, group?.group, group?.reason);
  }

  async function updateGroup() {
    try {
      if (isGroupValid() && !restrictionsHasErrors) {
        await updateGroupAsync(group);
        onModalChange(NO_MODAL_OPEN);
        return true;
      }
    } catch (_) {
      setErrors({ response: intl.formatMessage({ id: 'createGroupError' }) + group.group });
    }
    return false;
  }

  async function createGroup() {
    try {
      if (isGroupValid()) {
        const myGroup = await getGroupByNameAsync(group.group);
        if (!myGroup) {
          await createGroupAsync(group);
          onModalChange(NO_MODAL_OPEN);
          return true;
        } else {
          setErrors({ response: intl.formatMessage({ id: 'groupDuplicated' }) });
        }
      }
    } catch (_) {
      setErrors({ response: intl.formatMessage({ id: 'createGroupError' }) + group.group });
    }
    return false;
  }

  function isGroupValid(): boolean {
    if (!group.group) {
      setErrors({ group: intl.formatMessage({ id: 'requiredGroupName' }) });
      return false;
    } else if (group.group.length > 8) {
      setErrors({ group: intl.formatMessage({ id: 'errorGroupMaxChar' }) });
      return false;
    } else if (!group.description) {
      setErrors({ description: intl.formatMessage({ id: 'requiredGroupDescription' }) });
      return false;
    } else if (!isBaseGroupValid()) {
      setErrors({ baseGroup: intl.formatMessage({ id: 'baseGroupError' }) });
      return false;
    }
    return true;
  }

  function isBaseGroupValid(): boolean {
    if (group.baseGroup) {
      return securityGroups.some((securityGroup) => securityGroup.group === group.baseGroup);
    }

    return true;
  }

  async function getGroupAsync(): Promise<void> {
    try {
      const group = await getGroupByNameAsync(selectedGroup?.group);
      group &&
        setGroup((currentGroup) => ({
          ...currentGroup,
          areMessagesInherited: group.heredamsg + '',
          description: group.descripcion || '',
          authorization: group.autorizacion || '',
          confirmation: group.confirmacion || '',
          reason: group.reason || '',
        }));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (helperGroup) {
      if (group && currentOpenModal && group[currentOpenModal] !== helperGroup) {
        updateHasModifiedInformation();
      }
      setGroup((currentGroup) => ({ ...currentGroup, [currentOpenModal]: helperGroup }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpenModal, helperGroup]);

  useEffect(() => {
    setGroup(getInitialGroupState());
    if (isEditing) {
      getGroupAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    setGroup(getInitialGroupState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  return isLoading ? (
    <div className="w-full mt-6 p-2 px-6 flex justify-center items-center h-full">
      <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
    </div>
  ) : (
    <GroupInfoModalView
      hasGroupPermissions={hasGroupPermissions}
      helperGroup={helperGroup}
      onHelperChange={onHelperChange}
      errors={errors}
      group={group}
      selectedGroup={selectedGroup}
      onModalChange={onModalChange}
      openModalHelper={openModalHelper}
      onChange={onChange}
      onAccept={onAcceptAsync}
      isEditing={isEditing}
      setIsLoadingGroup={setIsLoadingGroup}
      onRestrictionsChangedAdd={onRestrictionsChangedAdd}
      onRestrictionsChangedRemove={onRestrictionsChangedRemove}
      restrictionsChanged={restrictionsChanged}
      changesWarning={changesWarning}
      setChangesWarning={setChangesWarning}
      onChangesWarningChange={onChangesWarningChange}
      setRestrictionsHasErrors={setRestrictionsHasErrors}
      restrictionsHasErrors={restrictionsHasErrors}
    />
  );
}
