import browserStorage from './utils/browserStorage';

browserStorage.sessionSet('SERVER_COMM_MUTEX_STATE', false);

const activateMutexSync = true;

let loggerInstance;
export const getLogger = () => {
  if (!loggerInstance) {
    loggerInstance = new Logger();
  }
  return loggerInstance;
};

export class Logger {
  LogEventsToProcess(eventsToProcess) {
    if (browserStorage.get('logsActivated')) {
      const d = new Date();
      console.log('--->>>  ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + ' ::: eventsToProcess');

      if (browserStorage.get('logsCompressed')) {
        console.log(eventsToProcess);
      } else {
        console.log(JSON.stringify(eventsToProcess));
      }
    }
  }

  MutexState(set) {
    if (!activateMutexSync) {
      return false;
    }
    let mutexState = browserStorage.sessionGet('SERVER_COMM_MUTEX_STATE');
    if (mutexState && set) {
      return true;
    } else if (set !== undefined) {
      browserStorage.sessionSet('SERVER_COMM_MUTEX_STATE', set);
      return false;
    } else {
      return mutexState;
    }
  }

  PrintLog(functionInfo, body) {
    if (browserStorage.get('logsActivated')) {
      const d = new Date();
      console.log('<<<---  ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + ' ::: ' + functionInfo);
      if (body) {
        if (browserStorage.get('logsCompressed')) {
          console.log(body);
        } else {
          console.log(JSON.stringify(body));
        }
      }
    }
  }
}
