import React from 'react';
import '../styles/components/OperationInfoBar.css';

export class OperationInfoBar extends React.PureComponent {
  render() {
    let { operationName, operationPosting, transactionNumber, transactionDescription, showTransaction } = this.props;
    return (
      <div className="Bar bg-white py-1 px-2 pl-2 text-xs flex items-center justify-between">
        <span className="font-sans-title uppercase">{operationName} </span>
        {showTransaction && <span className="font-sans-title uppercase">{`Transacción ${transactionNumber} - ${transactionDescription}`}</span>}
        <span id="idOperacionAsiento" className="bg-grey-lighter-lighter p-1 font-mono rounded">{`ASIENTO ${operationPosting}`}</span>
      </div>
    );
  }
}

export default OperationInfoBar;
