import { SignatureTakePhoto, SignatureFingerPrint } from '../../UI-kit';

const SIGNATURE_TAKE_PHOTO = 'TOMAR_FOTO';
const SIGNATURE_FINGERPRINT = 'GET_FINGERPRINT';
const SIGNATURE_VERIFYFINGER = 'VERIFY_FINGERPRINT';

export default class ApplicationToLaunch {
  constructor({ action, numeroPer: numberPhysicalPerson, idNode }) {
    this.action = action;
    this.numberPhysicalPerson = numberPhysicalPerson;
    this._idNode = idNode;
  }

  getApplicationToLaunchType(action) {
    if (action === SIGNATURE_TAKE_PHOTO) {
      return SignatureTakePhoto;
    } else if (action === SIGNATURE_FINGERPRINT) {
      return SignatureFingerPrint;
    } else if (action === SIGNATURE_VERIFYFINGER) {
      return SignatureFingerPrint;
    } else {
      return null;
    }
  }

  props() {
    return {
      action: this.action,
      numberPhysicalPerson: this.numberPhysicalPerson,
      removeComponentTree: (removeTreeNode) => {
        removeTreeNode(null, this._idNode);
      },
    };
  }

  component() {
    return this.getApplicationToLaunchType(this.action);
  }
}
