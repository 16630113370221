import { useState } from 'react';
import { deletePDF } from '../api/report';

const usePdfCleaner = () => {
  const [deletedFiles, setdeletedFile] = useState([]);

  const deleteFile = async (fileName) => {
    if (!deletedFiles.find((d) => d === fileName)) {
      setdeletedFile(...deletedFiles, fileName);
      deletePDF(fileName);
    }
  };

  return [deleteFile];
};

export default usePdfCleaner;
