import { css, StyleSheet } from 'aphrodite';
import React from 'react';

import { Filters } from './../Constants';
import ASpecificCheck from './ASpecificCheck';
import AcceptedByManagementFilter from './AcceptedByManagementFilter';
import AcceptedFilter from './AcceptedFilter';
import ByAccountFilter from './ByAccountFilter';
import RefusedDigitalClearingFilter from './RefusedDigitalClearingFilter';
import RefusedFilter from './RefusedFilter';
import { store } from '../../../../src/store';

const Filter = ({ type }) => {
  const styles = StyleSheet.create({
    filterContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
  });

  const parseAmountToFloat = (amount) => {
    let tmpAmount = amount;
    if (typeof tmpAmount === 'string') tmpAmount = parseFloat(tmpAmount.replaceAll(',', ''));
    return tmpAmount;
  };

  const getFilterComponentByType = (type) => {
    let state = store.getState();
    switch (type) {
      case Filters.A_SPECIFIC_CHECK:
        if (!state.filter) {
          state.filter = Filters.A_SPECIFIC_CHECK;
          store.setState(state);
        }
        if (state.filter && state.filter !== Filters.A_SPECIFIC_CHECK) {
          state.filter = Filters.A_SPECIFIC_CHECK;
          store.setState(state);
        }

        return <ASpecificCheck />;
      case Filters.ACCEPTED:
        if (!state.filter) {
          state.filter = Filters.ACCEPTED;
          store.setState(state);
        }
        if (state.filter && state.filter !== Filters.ACCEPTED) {
          state.filter = Filters.ACCEPTED;
          store.setState(state);
        }
        return <AcceptedFilter parseAmountToFloat={parseAmountToFloat} />;
      case Filters.ACCEPTED_BY_MANAGEMENT:
        if (!state.filter) {
          state.filter = Filters.ACCEPTED_BY_MANAGEMENT;
          store.setState(state);
        }
        if (state.filter && state.filter !== Filters.ACCEPTED_BY_MANAGEMENT) {
          state.filter = Filters.ACCEPTED_BY_MANAGEMENT;
          store.setState(state);
        }
        return <AcceptedByManagementFilter parseAmountToFloat={parseAmountToFloat} />;
      case Filters.BY_ACCOUNT:
        if (!state.filter) {
          state.filter = Filters.BY_ACCOUNT;
          store.setState(state);
        }
        if (state.filter && state.filter !== Filters.BY_ACCOUNT) {
          state.filter = Filters.BY_ACCOUNT;
          store.setState(state);
        }
        return <ByAccountFilter />;
      case Filters.REFUSED:
        if (!state.filter) {
          state.filter = Filters.REFUSED;
          store.setState(state);
        }
        if (state.filter && state.filter !== Filters.REFUSED) {
          state.filter = Filters.REFUSED;
          store.setState(state);
        }
        return <RefusedFilter parseAmountToFloat={parseAmountToFloat} />;
      case Filters.REFUSED_DIGITAL_CLEARING:
        if (!state.filter) {
          state.filter = Filters.REFUSED_DIGITAL_CLEARING;
          store.setState(state);
        }
        if (state.filter && state.filter !== Filters.REFUSED_DIGITAL_CLEARING) {
          state.filter = Filters.REFUSED_DIGITAL_CLEARING;
          store.setState(state);
        }

        return <RefusedDigitalClearingFilter parseAmountToFloat={parseAmountToFloat} />;
      default:
        return null;
    }
  };

  return <div className={css(styles.filterContainer)}>{getFilterComponentByType(type)}</div>;
};

export default Filter;
