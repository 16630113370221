import moment from 'moment';
import React from 'react';
import { GroupInstance } from '../../models/groupInstance';
import { ProcessesExecutionInfo } from './ProcessesExecutionInfo';
import { useIntl } from 'react-intl';

import './MonitorMenu.scss';
import { ABORTED_PROCESS, FINISHED_PROCESS, RUNNING_PROCESS, STOPPED_PROCESS } from '../../models/constants';
import { timeInSecondsToString } from '../../helpers/timeHelper';
import closeIcon from '../../icons/close';
import executeProcessIcon from '../../icons/excecuteProcess';

interface GroupExecutionInfoViewProps {
  groupData?: GroupInstance;
  onModalChange: (isOpen: boolean) => void;
  onModalDataChange: (data: string, name: string) => void;
  eliminateExecutedProcessId?: (executionId: number) => void;
  executeProcess: (procName: string) => void;
}

export function GroupExecutionInfoView({
  groupData,
  onModalChange,
  onModalDataChange,
  eliminateExecutedProcessId,
  executeProcess,
}: GroupExecutionInfoViewProps): JSX.Element {
  const intl = useIntl();

  function getState(): string {
    let state: string = '';

    if (groupData?.state === RUNNING_PROCESS) {
      state = intl.formatMessage({ id: 'executing' });
    } else if (groupData?.state === ABORTED_PROCESS) {
      state = intl.formatMessage({ id: 'aborted' });
    } else if (groupData?.state === STOPPED_PROCESS) {
      state = intl.formatMessage({ id: 'stopped' });
    } else if (groupData?.state === FINISHED_PROCESS) {
      state = intl.formatMessage({ id: 'finalized' });
    }

    return state;
  }

  function renderGroupGraph(groupInfo: GroupInstance): JSX.Element {
    const hasFinished: boolean = groupInfo.state === FINISHED_PROCESS;
    let percentage: number =
      groupInfo.duration !== 0 && groupInfo.averageTime !== 0 && groupInfo.duration < groupInfo.averageTime
        ? Math.ceil((groupInfo.duration / groupInfo.averageTime) * 100)
        : hasFinished
        ? 100
        : groupInfo.duration > groupInfo.averageTime
        ? 99
        : 0;
    let color: string = '';
    const correctTime: boolean = groupInfo.duration === groupInfo.averageTime || groupInfo.duration < groupInfo.averageTime;

    if (groupInfo.duration === groupInfo?.averageTime) {
      percentage = 100;
    }

    if (percentage > 100) {
      color = 'red';
    } else if (hasFinished && correctTime) {
      percentage = 100;
      color = 'green';
    } else {
      color = 'blue';
    }

    return (
      <div>
        <div className="border border-grey-lighter bg-white w-full h-4 relative">
          <div className={`absolute h-full`} style={{ width: `${percentage}%`, backgroundColor: `${color}` }} />
          <div className="absolute w-full h-full text-white">{percentage}%</div>
        </div>
      </div>
    );
  }

  function onClick(event: React.MouseEvent): void {
    event.stopPropagation();
    groupData && eliminateExecutedProcessId && eliminateExecutedProcessId(groupData.executionId);
  }

  return (
    <div className="text-xs flex w-full">
      <fieldset className="monitor-information rounded border border-light flex relative w-full">
        <legend className="font-bold">
          {groupData?.name + '(' + moment(groupData?.initTime).format('ll') + ')'}
          {groupData && groupData?.state !== RUNNING_PROCESS && (
            <button className="align-middle" onClick={() => executeProcess(groupData.name)}>
              {executeProcessIcon}
            </button>
          )}
        </legend>
        <div className="monitor-information monitor-left-information rounded border border-light text-grey-dark text-center bg-grey-lightest px-4 py-2 m-1">
          <fieldset className="monitor-information-group flex-col font-mono w-full">
            <div className="element border-t border-grey-lighter">
              <span>{intl.formatMessage({ id: 'executionId' })}:</span>
              <span>{groupData?.executionId}</span>
            </div>
            <div className="element">
              <span>{intl.formatMessage({ id: 'user' })}:</span>
              <span>{groupData?.username}</span>
            </div>
            {groupData?.state !== RUNNING_PROCESS && (
              <div className="element">
                <span>{intl.formatMessage({ id: 'result' })}:</span>
                <span>{groupData?.state === ABORTED_PROCESS || groupData?.state === STOPPED_PROCESS ? 'ERROR' : 'OK'}</span>
              </div>
            )}
            <div className="element">
              <span>{intl.formatMessage({ id: 'start' })}:</span>
              <span>{moment(groupData?.initTime).format('hh:mm:ss')}</span>
            </div>
            {groupData?.state !== RUNNING_PROCESS && (
              <div className="element">
                <span>{intl.formatMessage({ id: 'end' })}:</span>
                <span>{moment(groupData?.endTime).format('hh:mm:ss')}</span>
              </div>
            )}
            <div className="element">
              <span>{intl.formatMessage({ id: 'estimated' })}:</span>
              <span>{groupData && timeInSecondsToString(groupData?.averageTime)} </span>
            </div>
            <div className="element">
              <span>{intl.formatMessage({ id: 'transcurred' })}:</span>
              <span>{groupData?.duration + 's'}</span>
            </div>
            <div className="element">
              <span>{intl.formatMessage({ id: 'state' })}:</span>
              <span>{getState()}</span>
            </div>
            <div>{groupData && renderGroupGraph(groupData)}</div>
          </fieldset>
        </div>
        <fieldset className="monitor-information monitor-right-information rounded border border-light">
          <legend>{intl.formatMessage({ id: 'processes' })}:</legend>
          <ProcessesExecutionInfo
            groupProcesses={groupData?.processInstances}
            group={groupData}
            onModalChange={onModalChange}
            onModalDataChange={onModalDataChange}
          />
        </fieldset>
        {eliminateExecutedProcessId && (
          <div className="eliminate-group">
            <button onClick={onClick}>{closeIcon}</button>
          </div>
        )}
      </fieldset>
    </div>
  );
}
