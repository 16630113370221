import React, { Component } from 'react';
import { includes } from 'busyman';
import { PulseLoader } from 'halogenium';
import { connect } from 'unistore/react';

import { storeActions, store, dispatchActions } from '../../src/store';
import HelpTable from './HelpTable';
import Displayable from './Displayable';
import { FormattedMessage } from 'react-intl';

import { getColumns, getRowsV2 } from '../helpers/gridHelper';
import { getParentDialogCount, getIterationCountFromLineIDString } from '../helpers/operationHelper';
import { gotoLineWithBody, run, undo, gotoLine } from '../../src/api/dialog';
import { createSimpleBody, createBodyGoToLine, createRunBody } from '../../renderer/lib/bodyCreators';

import { getTabInformation, getTabIdByNodeId } from '../../src/selectors';

const EmptyData = ({ title, style, close }) => {
  return (
    <div
      tabIndex="0"
      autoFocus
      style={style}
      className="bg-white text-center flex items-center justify-center h-full w-100"
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          close();
        }
      }}
    >
      <div>
        <p className="text-text-base" style={{ fontSize: 12 }}>
          {title}
        </p>
      </div>
    </div>
  );
};

const gridInitialState = () => ({
  columns: [],
  rows: [],
  returnedField: '',
  numberOfReturned: 0,
  showAll: false,
  showWindow: false,
  loading: true,
  currentFilterCol: null,
  filtered: false,
  filterOptions: undefined,
});

const DONT_USE_FIELD_NUMBER = 1;

export class GridTable extends Component {
  constructor(props) {
    super(props);

    this.addFilter = this.addFilter.bind(this);
    this.toggleWindow = this.toggleWindow.bind(this);
    this.setFetchDataState = this.setFetchDataState.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onKeyDownNoData = this.onKeyDownNoData.bind(this);

    this.gridDOMElement = React.createRef();
    this.buttonAddFilter = React.createRef();
    this.buttonRemoveFilter = React.createRef();
    this.checkBoxShowAll = React.createRef();

    this.state = {
      loadingData: false,
      title: '',
      currentFetchDataCount: 0,
      gridState: gridInitialState(),
    };
  }

  onKeyDownNoData(e) {
    const { processContext, operationID, operationNumber, transactionNumber, lineNumber, transactionLineLabel, optional } = this.props;
    if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
      if (optional) {
        let bodyRun = createRunBody(operationNumber, transactionLineLabel, '');
        run(operationID, transactionNumber, lineNumber, bodyRun).then((res) => {
          processContext(res, 'Grid Run');
        });
      } else {
        const required = <FormattedMessage id="errorMandatoryField" defaultMessage={`es de ingreso obligatorio`} />;
        const msg = (
          <div>
            {'Grilla'} {required}
          </div>
        );
        store.setState(dispatchActions.showModalMessage(store.getState(), msg));
      }
    } else if (e.key === 'Escape' || (e.key === 'Tab' && e.shiftKey)) {
      undo(operationID, transactionNumber, lineNumber).then((res) => {
        store.setState(dispatchActions.reverseFlow(store.getState()));
        processContext(res, 'undo grid');
        store.setState(dispatchActions.reverseFlow(store.getState(), false));
      });
    }
  }

  updateGridState(changes) {
    this.setState({ gridState: { ...this.state.gridState, ...changes } });
  }

  addFilter(filterOptions) {
    if (filterOptions.field === '') {
      const currentState = this.state.gridState;
      filterOptions.field = currentState.columns[0].accessor;
    }

    let { removeFromWindowStack, nodeId } = this.props;
    removeFromWindowStack(nodeId);
    this.toggleWindow();
    this.fetchData(false, filterOptions);
  }

  toggleWindow(col, fromKeyboard = false, keyPressed = undefined, type) {
    const currentState = this.state.gridState;
    this.updateGridState({
      showWindow: !currentState.showWindow,
      currentFilterCol: col,
      fromKeyboard: fromKeyboard,
      keyPressed: keyPressed,
    });
  }

  setFetchDataState(changeShowAll) {
    if (changeShowAll) {
      const currentState = this.state.gridState;
      const { showAll } = currentState;
      this.updateGridState({
        showAll: !showAll,
      });
    }
  }

  async fetchData(changeShowAll = true, filterOptions, valueSeted) {
    let { operationID, numberOfRecords = 25, fieldNumber, transactionLineLabel, focusElement, fromHelp, optional, multipleSelection } = this.props;

    const fetchDataCount = this.state.currentFetchDataCount + 1;
    let gridState = fromHelp ? this.state.gridState : gridInitialState();
    const { showAll: prevShowAll } = gridState !== undefined && gridState;
    this.updateGridState(gridState);
    if (changeShowAll) this.updateGridState({ showAll: !prevShowAll });
    if (filterOptions === '-1' || !filterOptions) {
      filterOptions = this.props.inputValue
        ? {
            field: '-1',
            operator: '-1',
            value: this.props.inputValue,
          }
        : null;

      this.setState({ currentFetchDataCount: fetchDataCount });
    }
    this.updateGridState({ filterOptions });

    getColumns(operationID, fieldNumber).then((res) => {
      if (fetchDataCount !== this.state.currentFetchDataCount) {
        return;
      }
      let isSortable = Object.keys(res).map((key) => {
        if (res[key] && res[key].isSortable === true) {
          return {
            columnNumber: res[key],
          };
        }
      });
      const { showAll: currentShowAll, columns: currentColumns } = this.state.gridState;
      let newShowAll = currentShowAll !== res.showAll && currentColumns.length === 0 ? res.showAll : currentShowAll;
      this.updateGridState({
        title: res.title,
        columns: res.columns,
        returnedField: res.returned,
        numberOfReturned: res.numberOfReturned,
        isSortable: isSortable,
        showAll: newShowAll,
        isOptions: res.isOptions,
        filtered: filterOptions !== null && filterOptions !== undefined,
        helpSize: res.helpSize,
      });

      const currentState = this.state.gridState;
      const { showAll } = currentState;
      if (transactionLineLabel === focusElement || fromHelp || valueSeted) {
        this.setState({ loadingData: true });
        getRowsV2(res.helpSessionId, numberOfRecords, res.accesors, fieldNumber, showAll, filterOptions, res.types, res.isOptions)
          .then(async (res) => {
            if (fetchDataCount !== this.state.currentFetchDataCount) {
              return;
            }
            this.setState({ loadingData: false });
            const currentState = this.state.gridState;
            let { helpSize } = currentState;
            if (res.rows.length === helpSize) {
              Object.keys(res.rows[helpSize - 1]).forEach(function (key) {
                res.rows[helpSize - 1][key] = '...';
              });
            }
            await this.updateGridState({
              rows: res.rows,
              loading: false,
            });
            if (res.rows.length === 0) {
              this.props.onChange({ target: { value: null } });
            } else if (optional && multipleSelection) {
              this.props.onChange({ target: { value: [] } });
            } else {
              let row = res.rows[0];
              let rowsToUpdate = [];
              const currentState = this.state.gridState;
              let { columns, isOptions } = currentState;
              let firstRow = [];
              // if(columns) {
              columns.forEach((column) => {
                if (column.isReturned) {
                  firstRow.push(row[column.accessor]);
                }
              });
              // }
              const firstRowCheck = isOptions ? firstRow[0] : firstRow;
              this.props.onChange({
                target: {
                  value: multipleSelection ? rowsToUpdate : firstRowCheck,
                },
              });
            }
          })
          .finally(() => {
            this.updateGridState({ loading: false });
            this.setState({ loadingData: false });
          });
      } else {
        this.updateGridState({
          loading: false,
        });
      }
    });
  }

  gotoLine = async (body, opId, transactionNumber, lineNumber) => {
    const { operationNumber, fieldData, transactionLineLabel, focusElement } = this.props;
    const bodyGoToLine = createBodyGoToLine(operationNumber, fieldData, transactionLineLabel, focusElement, body);
    const res = await gotoLineWithBody(opId, transactionNumber, lineNumber, bodyGoToLine);
    this.props.processContext(res, 'gotoLine grid input');
  };

  componentDidMount() {
    let { inputValue, fromHelp, valueSetElement, transactionLineLabel } = this.props;
    let valueSeted = includes(valueSetElement, transactionLineLabel);
    let filterOptions = inputValue
      ? {
          field: '-1',
          operator: '-1',
          value: inputValue,
        }
      : null;
    const currentState = this.state.gridState;
    let columns;
    if (currentState.columns) {
      columns = currentState.columns;
    }
    if ((!valueSeted && !columns.length) || fromHelp) {
      this.fetchData(false, filterOptions, valueSeted);
    }
  }

  componentDidUpdate(prevProps) {
    let { transactionLineLabel, focusElement, fromHelp, valueSetElement, componentState, nodeId, showingHelpPopup } = this.props;
    const state = store.getState();
    const tabId = getTabIdByNodeId(state, nodeId);
    const { tabIndex } = getTabInformation(state, tabId);
    const currentTab = state.tabs[tabIndex];

    let valueSeted = includes(valueSetElement, transactionLineLabel);
    if (showingHelpPopup && ((valueSeted && !fromHelp) || prevProps.inputValue !== this.props.inputValue)) {
      this.fetchData(false, null, valueSeted);

      if (valueSeted && !fromHelp) {
        valueSetElement = valueSetElement.filter((e) => {
          return e !== transactionLineLabel;
        });

        state.tabs[tabIndex].running.valueSetElement = valueSetElement;
        store.setState(state);
      }
    }

    if (focusElement && prevProps.focusElement !== focusElement) {
      const transactionLineLabelCount = getIterationCountFromLineIDString(transactionLineLabel);
      let currentDiagCount = getParentDialogCount(currentTab.running.stateByLineID, null, null, transactionLineLabel);
      if (!fromHelp && transactionLineLabelCount === currentDiagCount) {
        if (this.gridDOMElement && this.gridDOMElement.current && this.gridDOMElement.current.focus && focusElement !== '') {
          // this.gridDOMElement.current.focus();
        }
      }
      if (componentState && componentState.hide && !fromHelp && (Array.isArray(componentState.value) ? componentState.value.length > 0 : true)) {
        this.props.onChange({ target: { value: [] } });
      }
    }
  }

  componentWillUnmount() {
    this.updateGridState({
      showAll: false,
    });
  }

  fillValuesForMultipleSelection = (rowInfo) => {
    const { columns } = this.state.gridState;
    const values = [];

    rowInfo.original.forEach((rowElement) => {
      const valuesRow = [];
      columns.forEach((column) => {
        if (column.isReturned && rowElement) {
          valuesRow.push(rowElement[column.accessor]);
        }
      });
      values.push(valuesRow);
    });
    return values;
  };

  fillValuesForSingleSelection = (rowInfo) => {
    const { columns } = this.state.gridState;
    const values = [];

    columns &&
      columns.forEach((column) => {
        if (column.isReturned && rowInfo.original) {
          values.push(rowInfo.original[column.accessor]);
        }
      });

    return values;
  };

  createSelectedValue = (rowInfo) => {
    let value;
    const currentState = this.state.gridState;

    const { multipleSelection } = this.props;

    if (currentState.numberOfReturned !== DONT_USE_FIELD_NUMBER) {
      let valuesToReturn = [];
      if (multipleSelection) {
        valuesToReturn = this.fillValuesForMultipleSelection(rowInfo);
      } else {
        valuesToReturn = this.fillValuesForSingleSelection(rowInfo);
      }
      value = valuesToReturn;
    } else {
      if (multipleSelection) {
        value = this.fillValuesForMultipleSelection(rowInfo);
      } else if (rowInfo.original) {
        value = rowInfo.original[currentState.returnedField];
      }
    }
    return value;
  };

  continue = async (rowInfo) => {
    const { fromHelp, operationNumber, transactionLineLabel, operationID, transactionNumber, lineNumber, processContext } = this.props;
    const value = this.createSelectedValue(rowInfo);
    this.props.onChange({ target: { value } });

    if (!fromHelp) {
      let body = createSimpleBody(operationNumber, transactionLineLabel, value);
      processContext(await run(operationID, transactionNumber, lineNumber, body), 'Continue for Run Grid');
    }
  };

  getFocusElement = () => {
    const { nodeId } = this.props;
    const state = store.getState();
    const tabId = getTabIdByNodeId(state, nodeId);
    const { focusElement = '' } = getTabInformation(state, tabId);
    return focusElement;
  };

  getContent() {
    const noDataMessage = (
      <FormattedMessage id="noDataGrid" defaultMessage={`No se encontraron datos. Si es una ventana de ayuda, pruebe Quitar Filtro`} />
    );
    let {
      style,
      lineNumber,
      transactionNumber,
      operationID,
      operationNumber,
      transactionLineLabel,
      processContext,
      pushToWindowStack,
      nodeId,
      fromHelp,
      onHelpSelect,
      cancelHandlerHelp,
      multipleSelection,
      focusedTable,
      optional,
      focusPaused,
    } = this.props;

    const { loadingData } = this.state;

    const currentState = this.state.gridState;
    let { rows, columns } = currentState;

    if (((columns && columns.length === 0) || (rows && !rows.length)) && fromHelp) {
      return <EmptyData title={noDataMessage} style={style} close={cancelHandlerHelp} />;
    }
    const divStyle = fromHelp ? style : { height: 'inherit' };
    const focusElement = this.getFocusElement();

    const focused = focusedTable || (transactionLineLabel === focusElement && focusElement !== '');

    const { title } = this.state;

    return (
      <div className={`flex border border-smoke rounded`} style={divStyle}>
        <HelpTable
          title={title}
          onKeyDownNoData={this.onKeyDownNoData}
          transactionLineLabel={transactionLineLabel}
          ref={this.gridDOMElement}
          data={rows && rows.map((row, index) => ({ ...row, id: index }))}
          loadingData={loadingData}
          columns={columns}
          fromHelp={fromHelp}
          onHelpSelect={onHelpSelect}
          cancelHandlerHelp={cancelHandlerHelp}
          focusedTable={focused}
          focusPaused={focusPaused}
          multipleSelection={multipleSelection}
          optional={optional}
          focusElement={focusElement}
          ignoreFocus={true}
          openFilter={(col, fromKeyboard = false, keyPressed = null) => {
            pushToWindowStack(nodeId);
            this.toggleWindow(col, fromKeyboard, keyPressed);
          }}
          handleUndo={async () => {
            let undoRes = await undo(operationID, transactionNumber, lineNumber);
            store.setState(dispatchActions.reverseFlow(store.getState()));

            processContext(undoRes, 'undo grid input');
            store.setState(dispatchActions.reverseFlow(store.getState(), false));
          }}
          goToLineFn={async () => {
            if (transactionLineLabel !== focusElement) {
              const res = await gotoLine(operationID, transactionNumber, lineNumber);
              processContext(res, 'gotoline grid input');
            }
          }}
          continue={this.continue}
          getTrProps={(state, rowInfo, runSelection = false) => {
            if (typeof rowInfo !== 'undefined') {
              return {
                onClick: async (e, handleOriginal) => {
                  const value = this.createSelectedValue(rowInfo);
                  this.props.onChange({ target: { value } });
                  if (!fromHelp) {
                    if (runSelection) {
                      const body = createSimpleBody(operationNumber, transactionLineLabel, value);
                      const runRes = await run(operationID, transactionNumber, lineNumber, body);
                      processContext(runRes, 'run grid input');
                    }
                  }
                },
              };
            } else {
              return {
                onClick: (e, handleOriginal) => {
                  if (handleOriginal) {
                    handleOriginal();
                  }
                },
              };
            }
          }}
        />
      </div>
    );
  }

  render() {
    if (!this.props.showingHelpPopup) {
      return null;
    }

    let { componentState, position, style } = this.props;

    const currentState = this.state.gridState;

    const loader = (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: style.height,
          width: style.width,
        }}
      >
        <PulseLoader className="text-primary" color="currentColor" size="20px" />
      </div>
    );

    if (!currentState) return null;
    const gridContent = currentState.loading ? loader : this.getContent();

    return (
      <Displayable componentState={componentState} position={position}>
        {gridContent}
      </Displayable>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { nodeId } = ownProps;
  const tabId = getTabIdByNodeId(state, nodeId);
  const { operationID, operationNumber, operationData, focusPaused, focusElement } = getTabInformation(state, tabId);

  const { grids, valueSetElement } = operationData;
  return {
    operationID,
    operationNumber,
    focusElement,
    grids,
    focusPaused,
    valueSetElement,
  };
};

export default connect(mapStateToProps, storeActions)(GridTable);
