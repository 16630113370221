import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  container: {
    marginLeft: '.75rem',
    flex: 1,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: '#000000',
    maxHeight: '90vh',
    overflow: 'auto',
    backgroundColor: '#FFFFFF',
  },
  table: {
    display: 'table',
    width: '100%',
  },
  headerRow: {
    display: 'table-header-group',
  },
  headerCell: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '1rem',
    position: 'sticky',
    top: 0,
  },
  body: {
    display: 'table-row-group',
  },
  row: {
    color: '#000000',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'table-row',
    backgroundColor: '#FFFFFF',
    transition: '.2s',
  },
  evenRow: {
    backgroundColor: '#FFFFFF',
  },
  cell: {
    display: 'table-cell',
    padding: '.6rem',
    fontSize: '12px',
    borderWidth: '0 0 1px 0',
    borderColor: '#FFFFFF',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  iconNoData: {
    fontSize: '1.5rem',
    marginBottom: '.5rem',
    opacity: '70%',
  },
});

const renderParametros = (item, clickHandler) => {
  return (
    <>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.nombre1}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.nombre2}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.fechaproceso}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.fechaprocesoanterior}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.fechaproximoproceso}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.diasvalidos}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.reintentos}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.tipored}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.autologin}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.siglo}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.cortesiglo}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.lenguajedefecto}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.comunicaciones}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.ambiente}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.tiempocorte}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.numsucursal}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.monnac}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.movimientoscaja}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.tipoplazo}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.pwdlargomin}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.pwdcantmincar}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.pwdcantminnum}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.pwdcantmaxrep}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.pwdcarmaxrep}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.pwdposmaxrep}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.complementogrupo}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.pwdlargomax}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.pwdcantcarmaxrep}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.clvlargomax}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.clvlargomin}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.pwdcasesensitive}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.clvcasesensitive}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.pwdreglasmin}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.pwdcarmaxsec}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.diasaviso}
      </div>
    </>
  );
};

const renderMapaTopaz = (item, clickHandler) => {
  return (
    <>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.nrosucursal}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.nromaq}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.grupomapa}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.path}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.comentario}
      </div>
    </>
  );
};

const renderMapaDeRed = (item, clickHandler) => {
  return (
    <>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.nrosucursal}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.numerodemaquina}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.descripcion}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.iniciodeldia}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.findeldia}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.usuario}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.direccioncomm}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.nrodecaja}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.direccionip}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.nombredeenvio}
      </div>
    </>
  );
};

const renderPrinters = (item, clickHandler) => {
  return (
    <>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)} style={{ textAlign: 'right' }}>
        {' '}
        {item.nrosucursal}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)} style={{ textAlign: 'right' }}>
        {' '}
        {item.nromaq}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.nombretopaz}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.comentario}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item?.nombrewindows}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)} style={{ textAlign: 'right' }}>
        {' '}
        {item.tipoimpresora}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.puerto}
      </div>
    </>
  );
  /* return (
    <div className="flex items-center justify-between border-t border-smoke py-2 h-10 cursor-pointer">
      <div className="w-24 pr-2"></div>
      <div className="flex-grow items-center ml-30 w-10 text-xs flex-nowrap">
        {' '}
        {item.nrosucursal}
      </div>
      <div className="flex-grow items-center ml-30 w-10 text-xs flex-nowrap">
        {' '}
        {item.nromaq}
      </div>
      <div className="flex-grow items-center ml-30 w-10 text-xs flex-nowrap">
        {' '}
        {item.nombretopaz}
      </div>
      <div className="flex-grow items-center ml-30 w-10 text-xs flex-nowrap">
        {' '}
        {item.comentario}
      </div>
      <div className="flex-grow items-center ml-30 w-10 text-xs flex-nowrap">
        {' '}
        {item.nombrewindows}
      </div>
      <div className="flex-grow items-center ml-30 w-10 text-xs flex-nowrap">
        {' '}
        {item.tipoimpresora}
      </div>
      <div className="flex-grow items-center ml-30 w-10 text-xs flex-nowrap">
        {' '}
        {item.puerto}
      </div>
    </div>
  ); */
};

const renderNumerators = (item, clickHandler) => {
  return (
    <>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.numero}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.descripcion}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.inival}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.incremento}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.periodo}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.ultimainic}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.reutilizable}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.maximo}
      </div>
      <div onClick={() => clickHandler(item, true)} className={css(styles.cell)}>
        {' '}
        {item.centralizado}
      </div>
    </>
  );
};

export class DataListEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
  }

  renderItem(item, selectedNode, clickHandler) {
    let toRender = <div></div>;
    switch (selectedNode) {
      case 'Parametros':
        toRender = renderParametros(item, clickHandler);
        break;
      case 'Mapa Topaz':
        toRender = renderMapaTopaz(item, clickHandler);
        break;
      case 'Mapa de Red':
        toRender = renderMapaDeRed(item, clickHandler);
        break;
      case 'Impresoras':
        toRender = renderPrinters(item, clickHandler);
        break;
      case 'Numeradores':
        toRender = renderNumerators(item, clickHandler);
        break;
      default:
        break;
    }
    return toRender;
  }
  render() {
    // eslint-disable-next-line no-unused-vars
    const { item, clickHandler, addToRefs, selectedNode } = this.props;

    let itemToRender = this.renderItem(item, selectedNode, clickHandler);
    return itemToRender;
  }
}

export default DataListEntry;
