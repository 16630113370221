import React from 'react';
import { useIntl } from 'react-intl';
import warningIcon from '../../../../icons/warning';

import './WarningModal.scss';

export interface WarningModalViewProps {
  onChange: (accept: boolean) => void;
  username: string;
  sameNumberOf: string;
}

export function WarningModalView({ onChange, username, sameNumberOf }: WarningModalViewProps): JSX.Element | null {
  const intl = useIntl();

  function onAcceptClick(event: React.MouseEvent): void {
    onChange(true);
  }

  function onCancelClick(event: React.MouseEvent): void {
    onChange(false);
  }

  return (
    <div
      className="font-mono warning-modal z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ right: 'initial', height: '300px' }}
    >
      <div className="flex justify-center">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border shadow-sm"
          style={{ width: 'fit-content', height: 'fit-content' }}
        >
          <div className="bg-blue-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <div className="text-center">
                  <div className="items-center mr-3">{warningIcon}</div>
                  {intl.formatMessage({ id: 'existsUserWithSameNumber' })}{' '}
                  {sameNumberOf !== null && sameNumberOf !== undefined ? sameNumberOf.toString().toLowerCase() : ''}{' '}
                  {intl.formatMessage({ id: 'andBranch' })}
                </div>
                <div className="text-left">
                  {intl.formatMessage({ id: 'key' })}: {username}
                </div>
                <div className="text-center">{intl.formatMessage({ id: 'continueWithChange' })}</div>
              </div>
            </div>
          </div>
          <div className="justify-center bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onAcceptClick}
              type="button"
              className="w-24 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium text-gray-700 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:text-sm"
            >
              {intl.formatMessage({ id: 'yes' })}
            </button>
            <button
              onClick={onCancelClick}
              type="button"
              className="mt-3 w-24 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm"
            >
              {intl.formatMessage({ id: 'no' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
