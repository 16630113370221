import React, { useEffect, useState } from 'react';
import './SecurityDetailModal.scss';
import { PulseLoader } from 'halogenium';
import { SelectedGroup } from '../../models/types';
import { SecurityToolsModalViewModel } from '../securityToolsModal/SecurityToolsModalViewModel';
import { GroupsAsTreeMenu } from '../../models/groupsAsTreeMenu';
import { UserBasicInfo } from '../../models/userBasicInfo';
import { NO_MODAL_OPEN } from '../../models/constants';

interface ProcessDetailProps {
  selectedGroup?: SelectedGroup;
  modalOpen: string;
  onModalChange: (modalOpenName: string) => void;
  securityGroups: GroupsAsTreeMenu[];
  removeGroupAsync: (group: GroupsAsTreeMenu) => void;
  removeUserAsync: (user: UserBasicInfo) => void;
  isEditing: boolean;
  onChangesWarningChange: (isShowing: boolean, undoChanges: boolean) => void;
  changesWarning: boolean;
  onSelectedItemChange: (node: SelectedGroup) => void;
  onSelectGroup: (group: SelectedGroup) => void;
  selectedItem?: SelectedGroup;
  hasGroupPermissions: boolean;
  hasUserPermissions: boolean;
  isWarningToEdit: boolean;
  onIsEditingChange: (isEditing: boolean) => void;
  onHasModifiedChange: (hasModified: boolean) => void;
  hasModifiedInformation: boolean;
  onRefresh: () => void;
  setChangesWarning: (isShowing: boolean) => void;
  setIsLoadingGroup: (isLoading: boolean) => void;
  isLoadingGroup: boolean;
}

export function SecurityDetailModal({
  selectedGroup,
  modalOpen,
  onModalChange,
  securityGroups,
  removeGroupAsync,
  removeUserAsync,
  isEditing,
  changesWarning,
  onChangesWarningChange,
  onSelectedItemChange,
  onSelectGroup,
  selectedItem,
  hasGroupPermissions,
  hasUserPermissions,
  isWarningToEdit,
  onIsEditingChange,
  hasModifiedInformation,
  onHasModifiedChange,
  onRefresh,
  setChangesWarning,
  setIsLoadingGroup,
  isLoadingGroup,
}: ProcessDetailProps): JSX.Element | null {


  return (
    <div className="group-detail-container">
      <fieldset className="group-detail-information rounded border border-light" style={{ display: 'content' }}>
        {isLoadingGroup &&
          <div className='w-full flex justify-center items-center'>
            <PulseLoader className="text-primary mr-6" color="currentColor" size="10px" margin="5px" />
          </div>
        }
        {modalOpen !== NO_MODAL_OPEN && (
          <SecurityToolsModalViewModel
            setChangesWarning={setChangesWarning}
            onRefresh={onRefresh}
            onHasModifiedChange={onHasModifiedChange}
            hasModifiedInformation={hasModifiedInformation}
            onIsEditingChange={onIsEditingChange}
            isWarningToEdit={isWarningToEdit}
            hasGroupPermissions={hasGroupPermissions}
            hasUserPermissions={hasUserPermissions}
            selectedItem={selectedItem}
            onSelectGroup={onSelectGroup}
            onSelectedItemChange={onSelectedItemChange}
            onChangesWarningChange={onChangesWarningChange}
            changesWarning={changesWarning}
            selectedGroup={selectedGroup}
            modalOpen={modalOpen}
            onModalChange={onModalChange}
            securityGroups={securityGroups}
            removeGroupAsync={removeGroupAsync}
            removeUserAsync={removeUserAsync}
            isEditing={isEditing}
            setIsLoadingGroup={setIsLoadingGroup}
          />
        )}
      </fieldset>
    </div>
  );
}
