import { Dispatch, useEffect, useState } from 'react';

import { getGroupPermissionsAsync, getSpecificGroupPermissionsAsync } from '../../../../../../api/securityManager';
import { EMPTY_STRING, DEFAULT_RESTRICTION_KEY } from '../../../../models/constants';
import { RestrictionObject } from '../../../../models/restrictionObject';
import { getParentPermissionExclusive, getRecursivePermissions } from '../utils';
import { useRestrictions, Restrictions } from './useRestrictions.hook';

interface UsePermissions {
  permissions: PermissionsTree[];
}

interface PermissionService {
  descripcion: string;
  numero: number;
}

interface PermissionGroupService {
  grupo: string;
  autorizador: string;
  biblioteca?: string;
  formula?: string;
  permite: number | null;
  permiso: number | null;
}

export interface PermissionsTree {
  key: string;
  label: string;
  nodes: PermissionsTree[];
  restriction: RestrictionObject;
}

export const PERMISSIONS_TYPE: string = 'P';

export function usePermissions(parentGroup: string, group: string, setIsLoading: Dispatch<SetStateAction<boolean>>): UsePermissions {
  const [permissionOptions, setPermissionOptions] = useState<PermissionsTree[]>([]);
  const [grandParentPermissions, setGrandParentPermissions] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { fillNumberWithZeros, getPermissionRestrictionsAsync } = useRestrictions();

  async function getPermissionsAsync(): Promise<void> {
    const permissions: PermissionService[] = await getGroupPermissionsAsync();
    const groupPermissions: PermissionGroupService[] =
      group !== '' ? await getSpecificGroupPermissionsAsync(group) : await getSpecificGroupPermissionsAsync(parentGroup);

    const options: PermissionsTree[] = [];
    const restrictions: Restrictions = await getPermissionRestrictionsAsync(parentGroup);

    permissions.forEach((permissionOption, index) => {
      const permissionInfo = groupPermissions.find((p) => p.permiso === permissionOption.numero);
      const restrictionKey = fillNumberWithZeros(0) + fillNumberWithZeros(permissionOption.numero);
      const permissionNode = {
        key: `${index} ${permissionOption.descripcion}`,
        label: `${permissionOption.numero} - ${permissionOption.descripcion}`,
        nodes: [],
        restriction: {
          id: fillNumberWithZeros(0) + fillNumberWithZeros(permissionOption.numero),
          description: permissionOption.descripcion,
          type: PERMISSIONS_TYPE,
          allows:
            permissionInfo !== undefined
              ? permissionInfo.permite
              : getParentPermissionExclusive(grandParentPermissions, permissionOption) === null
                ? 0
                : null,
          ownPermission:
            permissionInfo !== undefined
              ? permissionInfo.permite
              : getParentPermissionExclusive(grandParentPermissions, permissionOption) === null
                ? 0
                : null,
          titlePermission:
            restrictions[restrictionKey] !== undefined ? restrictions[restrictionKey].allow : restrictions[DEFAULT_RESTRICTION_KEY]?.allow,
          parentPermission: getParentPermissionExclusive(grandParentPermissions, permissionOption),
          library: permissionInfo?.biblioteca || EMPTY_STRING,
          allower: permissionInfo?.autorizador || EMPTY_STRING,
          formula: permissionInfo?.formula || EMPTY_STRING,
          permission: permissionInfo?.permiso,
        },
      };
      options.push(permissionNode);
    });

    setPermissionOptions(options);
    setIsLoading(false);
  }

  useEffect(() => {
    if (loaded) {
      setIsLoading(true);
      getPermissionsAsync();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, loaded]);

  const getGrandParentPermissionsAsync = async () => {
    let permissions = await getRecursivePermissions(parentGroup, null);
    setGrandParentPermissions(permissions);
    setLoaded(true);
  };

  useEffect(() => {
    setLoaded(false);
    getGrandParentPermissionsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return {
    permissions: permissionOptions,
  };
}
