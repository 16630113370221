import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { useGlobalStyles } from './../../../../styles/GlobalStylesContext';
import { useTheme } from './../../../../themes/ThemeContext';
import Field from './Field';

const AmountRangeInput = ({ amounts, onAmountChange }) => {
  const intl = useIntl();

  const labelAmount = intl.formatMessage({
    id: 'amount',
    defaultMessage: 'Importe',
  });
  const placeholderFrom = intl.formatMessage({
    id: 'from',
    defaultMessage: 'Desde',
  });
  const placeholderTo = intl.formatMessage({
    id: 'to',
    defaultMessage: 'Hasta',
  });

  const themeState = useTheme();
  const { text } = themeState.theme;

  const styles = StyleSheet.create({
    amountRange: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    amountMin: {
      marginRight: '.5rem',
    },
    amountMax: {
      marginLeft: '.5rem',
    },
    subLabel: {
      color: text.text,
      fontSize: '.6rem',
      marginRight: '.25rem',
    },
  });

  const globalStyles = useGlobalStyles();

  return (
    <Field labelText={labelAmount} htmlFor="amountMin">
      <div className={css(styles.amountRange)}>
        <div className={css(styles.amountMin)}>
          <label htmlFor="amountMin" className={css(styles.subLabel)}>
            {placeholderFrom}
          </label>
          <NumberFormat
            required
            type="text"
            thousandSeparator
            name="amountMin"
            id="amountMin"
            className={css(globalStyles.input, globalStyles.numberInput)}
            placeholder={placeholderFrom}
            value={amounts.amountMin}
            onChange={onAmountChange}
            onFocus={(e) => e.target.select()}
          />
        </div>
        <div className={css(styles.amountMax)}>
          <label htmlFor="amountMax" className={css(styles.subLabel)}>
            {placeholderTo}
          </label>
          <NumberFormat
            required
            type="text"
            thousandSeparator
            name="amountMax"
            id="amountMax"
            className={css(globalStyles.input, globalStyles.numberInput)}
            placeholder={placeholderTo}
            value={amounts.amountMax}
            onChange={onAmountChange}
            onFocus={(e) => e.target.select()}
          />
        </div>
      </div>
    </Field>
  );
};

export default AmountRangeInput;
