import React from 'react';
import PropTypes from 'prop-types';
import CategoryComponent from './CategoryComponent';
import '../styles/components/CategoriesGrid.css';

const propTypes = {
  categories: PropTypes.array,
  handleClick: PropTypes.func.isRequired,
};

const CategoriesGrid = ({ categories, handleClick }) => (
  <div className="Category-container">
    {categories &&
      categories.map(
        (category) =>
          category.items.length > 0 && (
            <CategoryComponent
              key={category.id}
              iconoTitulo={category.iconoTitulo}
              onClick={handleClick}
              categoryName={category.name}
              category={category}
            ></CategoryComponent>
          ),
      )}
  </div>
);

CategoriesGrid.propTypes = propTypes;

export default CategoriesGrid;
