import axios from '../utils/axios';
import browserStorage from '../utils/browserStorage';
import { USER_SESSION } from '../utils/browserStorage/constants';
import { handleError, getUrl } from './handlers';
import urlencode from 'urlencode';

export const getBranchGroups = async () => {
  const session = browserStorage.sessionGet(USER_SESSION);

  try {
    const query = {
      endpoint: 'postingMgrSession',
      action: 'getBranchGroups',
      params: `sessionId=${session}`,
    };
    const response = await axios.post(getUrl(query));
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Return events by user Id.
 *
 * @param {string} id User Id.
 */
export const getTransactionsNew = async (pendingOf, body) => {
  const session = browserStorage.sessionGet(USER_SESSION);
  const headers = { 'Content-Type': 'application/json' };

  try {
    const query = {
      endpoint: 'postingMgrSession',
      action: 'getTransactionsNew',
      params: `sessionId=${session}&pendingOf=${pendingOf}`,
    };
    const response = await axios.post(getUrl(query), body, { headers });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getTransaction = async (branch, postingNumber, processDate, body) => {
  const session = browserStorage.sessionGet(USER_SESSION);
  const headers = { 'Content-Type': 'application/json' };

  try {
    const query = {
      endpoint: 'postingMgrSession',
      action: 'getTransaction',
      params: `sessionId=${session}&branch=${branch}&postingNumber=${postingNumber}&processDate=${processDate}`,
    };
    const response = await axios.post(getUrl(query), body, { headers });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteRequest = async (
  branch,
  transactionId,
  comment,
  closeBranchNotification,
  body,
) => {
  const session = browserStorage.sessionGet(USER_SESSION);
  const headers = { 'Content-Type': 'application/json' };
  comment = urlencode(comment);

  try {
    const query = {
      endpoint: 'postingMgrSession',
      action: 'delete',
      params: `sessionId=${session}&transactionID=${transactionId}&comment=${comment}&closeBranchNotification=${closeBranchNotification}&branch=${branch}`,
    };
    const response = await axios.post(getUrl(query), body, { headers });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const authorizePermissions = async (
  branch,
  transactionNumber,
  comment,
  closeBranchNotification,
  body,
) => {
  body = body.filter((permission) => permission.state === 1);
  const session = browserStorage.sessionGet(USER_SESSION);
  const headers = { 'Content-Type': 'application/json' };
  comment = urlencode(comment);

  try {
    const query = {
      endpoint: 'postingMgrSession',
      action: 'authorizePermissions',
      params: `sessionId=${session}&transactionNumber=${transactionNumber}&comment=${comment}&closeBranchNotification=${closeBranchNotification}&branch=${branch}`,
    };
    const response = await axios.post(getUrl(query), body, { headers });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const authorizePermissionsToForward = async (
  branch,
  transactionNumber,
  comment,
  closeBranchNotification,
  body,
) => {
  const session = browserStorage.sessionGet(USER_SESSION);
  const headers = { 'Content-Type': 'application/json' };
  comment = urlencode(comment);

  try {
    const query = {
      endpoint: 'postingMgrSession',
      action: 'authorizePermissionsToForward',
      params: `sessionId=${session}&transactionNumber=${transactionNumber}&comment=${comment}&closeBranchNotification=${closeBranchNotification}&branch=${branch}`,
    };
    const response = await axios.post(getUrl(query), body, { headers });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const confirm = async (
  branch,
  transactionId,
  comment,
  closeBranchNotification,
  body,
) => {
  const session = browserStorage.sessionGet(USER_SESSION);
  const headers = { 'Content-Type': 'application/json' };
  comment = urlencode(comment);

  try {
    const query = {
      endpoint: 'postingMgrSession',
      action: 'confirm',
      params: `sessionId=${session}&transactionID=${transactionId}&comment=${comment}&closeBranchNotification=${closeBranchNotification}&branch=${branch}`,
    };
    const response = await axios.post(getUrl(query), body, { headers });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const confirmDeferred = async (branch, transactionId, body) => {
  const session = browserStorage.sessionGet(USER_SESSION);
  const headers = { 'Content-Type': 'application/json' };

  try {
    const query = {
      endpoint: 'postingMgrSession',
      action: 'confirmDeferred',
      params: `sessionId=${session}&transactionID=${transactionId}&branch=${branch}`,
    };
    const response = await axios.post(getUrl(query), body, { headers });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
