import React, { Component } from 'react';
import DoorOpenIcon from './../../resources/icons/fa/door-open.svg';
import DoorCloseIcon from './../../resources/icons/fa/door-close.svg';
import DoorFinalizeIcon from './../../resources/icons/fa/door-finalize.svg';

class BranchesListEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };

    this.getCheckState = this.getCheckState.bind(this);
    this.setStatesChecks = this.setStatesChecks.bind(this);
  }

  getCheckState() {
    return this.state.selected;
  }

  setStatesChecks(stateCh) {
    this.setState({ selected: stateCh });
  }

  render() {
    const { item, clickHandler, addToRefs, clickDoubleHandler } = this.props;
    return (
      <div
        className="flex items-center justify-between border-t border-smoke py-2 h-10 cursor-pointer"
        onDoubleClick={(e) => clickDoubleHandler(item)}
      >
        <div className="w-5 ml-2">
          <input
            name={item.number}
            type="checkbox"
            ref={(ref) => addToRefs(ref)}
            onChange={() => {
              if (this.state.selected) {
                this.setState({ selected: false });
                clickHandler(item, false);
              } else {
                this.setState({ selected: true });
                clickHandler(item, true);
              }
            }}
          />
        </div>
        {item.status === 'F' ? (
          <div className="w-10">
            <DoorFinalizeIcon height="18px" />
          </div>
        ) : item.status === 'A' ? (
          <div className="w-10">
            <DoorOpenIcon height="18px" />
          </div>
        ) : (
          <div className="w-10">
            <DoorCloseIcon height="18px" />
          </div>
        )}
        <div className="flex-grow items-center ml-30 text-xs text-nowrap" style={{ width: '300px' }}>
          {item.description}
        </div>
        <div className="flex-grow items-center w-10 text-xs">{item.number}</div>
        <div className="flex-grow items-center  w-30  text-xs">{item.previousProcessDate.split(' ')[0]}</div>
        <div className="flex-grow items-center mr-3 w-20  text-xs">{item.processDate.split(' ')[0]}</div>
        <div className="flex-grow items-center mr-3 w-30  text-xs">{item.nextProcessDate.split(' ')[0]}</div>
      </div>
    );
  }
}

export default BranchesListEntry;
