import React, { useState } from 'react';
import logo from '../../resources/logo/logo_default.png';
import Login from '../Login/LoginViewModel';
import { useTheme } from '../../themes/ThemeContext';
import { StyleSheet, css } from 'aphrodite';
import { getImagesFromServer } from '../../api/session';

// import ThemeButton from '../../themes/ThemeButton';

const Landing = (props) => {
  const [loginLogo, setLoginlogo] = useState(logo);
  const themeState = useTheme();
  const { background, border, text, shadow } = themeState.theme;
  const [newLogin, setNewLogin] = useState(false);
  const styles = StyleSheet.create({
    container: {
      width: '100vw',
      height: '100vh',
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `radial-gradient(circle, ${background.light} 0%, ${background.light} 35%, ${background.background} 100%)`,
      color: text.text,
    },
    logo: {
      width: newLogin ? '60%' : '100%',
      height: 'auto',
      marginBottom: '2rem',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    authWrapper: {
      width: newLogin ? '36rem' : '22rem',
      padding: '2rem 2.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      borderColor: border.light,
      borderRadius: 1,
      borderWidth: 1,
      backgroundColor: background.lightest,
      boxShadow: `-1px 1px 10px ${shadow.shadow}`,
    },
  });

  const loadImagesLogo = async () => {
    let imageLogo = await getImagesFromServer('login.png');
    setLoginlogo(imageLogo.image ? 'data:image/png;base64, ' + imageLogo.image : logo);
  };

  loadImagesLogo();

  return (
    <React.Fragment>
      <div className={css(styles.container)}>
        {/* <ThemeButton /> */}
        <div className={css(styles.authWrapper)}>
          <img src={loginLogo} className={css(styles.logo)} />
          <Login setNewLogin={setNewLogin} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Landing;
