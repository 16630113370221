import { useState } from 'react';
import { store } from '../store';
import { getSessionInfo } from '../api/session';
import { TOPWEB_VERSION } from './../config';

const SIZE_BRANCH_NUMBER = 5;
const SIZE_MACHINE_NUMBER = 4;

const zeroPad = (num, places) => {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
};

const useFooterState = () => {
  const [additionalInfo, setAdditionalInfo] = useState({
    TOPWEB_VERSION,
  });
  const [sessionInfo, setSessionInfo] = useState({
    name: '',
    branch: '',
    machine: '',
    processDate: '',
    server: '',
  });

  const setFooterInfo = async (_additionalInfo = {}) => {
    const additionalInfo = {
      TOPWEB_VERSION,
      ..._additionalInfo,
    };
    const { serverAddress, machineNumber, topazBranch, user, fechaProceso } = await getSessionInfo();

    const sessionInfo = {
      name: user.initials,
      branch: `S${zeroPad(topazBranch, SIZE_BRANCH_NUMBER)}`,
      machine: `M${zeroPad(machineNumber, SIZE_MACHINE_NUMBER)}`,
      processDate: fechaProceso.split(' ')[0],
      server: serverAddress,
    };

    setSessionInfo(sessionInfo);
    setAdditionalInfo(additionalInfo);
    store.setState({ sessionInfo });
  };

  const footerInfo = { sessionInfo, additionalInfo };

  return [footerInfo, setFooterInfo];
};

export default useFooterState;
